<template>
  <div>
    <data-table
      :headers="headers"
      :items="productComputed"
      :pageSize="(productComputed || []).length"
      :checkbox="isCheckbox"
      @selected:checkbox="selectProductItem"
      @selected:change="changeProductItem"
    >
      <!-- Custom Card Header  -->
      <template slot="card-header">
        <f7-card-content>
          <f7-list>
            <f7-searchbar
              placeholder="Search in items"
              class="search-list-popup"
              :clear-button="true"
              disable-button-text
              :value="searchText"
              @input="
                searchText = $event.target.value;
                onSearch();
              "
              @searchbar:clear="
                searchText = '';
                onSearch();
              "
              @searchbar:disable="
                searchText = '';
                onSearch();
              "
            >
              <f7-button
                outline
                color="primary"
                class="margin-left-half"
                style="min-width: 120px"
                @click="openCustomizeTablePopup"
                >Customize</f7-button
              >
            </f7-searchbar>
          </f7-list>
        </f7-card-content>
      </template>

      <template v-slot:body="{ item }">
        <td v-for="(header, index) in headers" :key="index">
          <div v-if="header.value === 'sku'">{{ item.sku }}</div>
          <div v-else-if="header.value === 'productItem'">
            {{ item.productItem }}
          </div>
          <div v-else-if="header.value === 'manufacturer'">
            {{ item.manufacturer }}
          </div>
          <div v-else-if="header.value === 'vendor'">{{ item.vendorName }}</div>
          <div v-else-if="header.value === 'roofType'">
            {{ productRoofTypeNames(item.roofTypes) }}
          </div>
          <div v-else-if="header.value === 'productCategory'">
            {{ item.categoryName }}
          </div>
          <div v-else-if="header.value === 'subCategory'">
            {{ item.subCategoryName }}
          </div>
          <div v-else-if="header.value === 'technicalData'">
            <ul class="technical-data">
              <li
                v-for="(attach, index) in item.technicalData || []"
                :key="index"
                :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`">
                <a class="display-block" @click.stop="openUrlLink(item, attach)">
                  {{ attach.name }}
                </a>
              </li>
            </ul>
          </div>
          <div v-else-if="header.value === 'color'">
            <f7-link
              v-for="(color, index) in item.colorPhotos || []"
              :key="index"
              @click.stop="clickPhoto(color)"
              :id="`${id}-${item.id}-${color.id}`"
            >
              {{
                `${color.colorName}${
                  index + 1 === (item.colorPhotos || []).length ? "" : ",&nbsp;"
                }`
              }}
            </f7-link>
          </div>
          <div v-else-if="header.value === 'packaging'">
            {{ item.packaging }}
          </div>
          <div v-else-if="header.value === 'unitPack'">{{ item.unitPack }}</div>
          <div v-else-if="header.value === 'unitSize'">{{ item.unitSize }}</div>
          <div v-else-if="header.value === 'uom'">{{ item.uom }}</div>
          <div v-else-if="header.value === 'price'">
            <div>{{ item.price | currencyUSD }}</div>
          </div>
          <div v-else-if="header.value === 'actions'" class="text-align-center">
            <a href="#" @click.stop="openConfirmDelete(item)">
              <i class="f7-icons" style="color: red;">trash</i>
            </a>
          </div>
        </td>
      </template>

      <template slot="paging-footer">
        <div
          v-if="productComputed.length > 0"
          class="data-table-footer"
          :class="$device.desktop ? '' : 'mobile-container'"
        >
          <div class="data-table-rows-select">
            Per page:
            <div class="input input-dropdown">
              <select
                @input="onChangeLimit($event.target.value.trim())"
                :value="productList.hitsPerPage"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
          <div
            class="data-table-pagination"
            :style="$device.desktop ? '' : 'margin-left:-20px'"
          >
            <span
              v-if="productList.page === 0"
              class="display-flex align-items-center margin-left color-gray"
              :class="$device.desktop ? 'margin-left' : ''"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</span
            >
            <a
              v-else
              @click="onGoToPage('first')"
              class="display-flex align-items-center margin-left "
              :class="$device.desktop ? 'margin-left' : ''"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</a
            >

            <span v-if="productList.page === 0" class="margin-left color-gray"
              >Previous</span
            >
            <a v-else @click="onGoToPage('prev')" class="margin-left"
              >Previous</a
            >

            <span class="data-table-pagination-label margin-left">{{
              pagingMessage
            }}</span>

            <a
              v-if="productList.page < productList.nbPages - 1"
              @click="onGoToPage('next')"
              class="margin-left"
              >Next</a
            >
            <span v-else class="margin-left color-gray">Next</span>

            <a
              v-if="productList.page < productList.nbPages - 1"
              @click="onGoToPage('last')"
              class="display-flex align-items-center margin-left"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></a>
            <span
              v-else
              class="display-flex align-items-center margin-left color-gray"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></span>
          </div>
        </div>
      </template>
    </data-table>
    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
    <customize-table-popup
      ref="customizeTablePopup"
      :tableType="CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT"
      :defaultColumns="defaultColumns"
      :customTable="customTable"
    ></customize-table-popup>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import CustomizeTablePopup from "../../../../components/popups/CustomizeTablePopup.vue";
import { comparationSort } from "../../../../utility/filter-tools";
import {
  CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
  STATUS_PRODUCT_ITEM_ACTIVE
} from "../../../../utility/const";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { isVideo, isImage, createMediaArray, openAttackLink } from "@/utility/common";
export default {
  components: {
    DataTable,
    CustomizeTablePopup
  },

  props: {
    id: String,
    dataType: String,
    filters: String,
    isCheckbox: { type: Boolean, default: false },
    isShowActions: { type: Boolean, default: false }
  },

  data() {
    return {
      searchText: "",
      gridFilter: {
        vendorId: "",
        categoryId: "",
        subCategoryId: "",
        roofType: ""
      },
      vendorIds: [],
      productPhotos: [],
      checkedProductList: [],
      CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT
    };
  },

  watch: {
    "currentBuilding.vendorIds": {
      deep: true,
      immediate: true,
      handler(val) {
        this.vendorIds = val;
        this.onSearch();
      }
    },
    productsDefault: {
      handler(productsDefault) {
        if (productsDefault && productsDefault.hits.length > 0) {
          this.checkedProductList = _.cloneDeep(productsDefault.hits);
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    ...mapGetters("common/app-constant", ["roofTypeList"]),
    ...mapGetters("estimate/estimate-page/price-list", ["currentPriceList"]),
    ...mapGetters("estimate/estimate-page/product-default", [
      "productsByPL",
      "productsDefault"
    ]),
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),
    ...mapGetters({
      categories: "estimate/estimate-page/estimate/category/objectList",
      subCategories: "estimate/estimate-page/estimate/sub-category/objectList",
      vendors: "estimate/estimate-page/estimate/vendor/objectList"
    }),
    ...mapGetters("common/custom-table", ["customTable"]),
    ...mapGetters("common/app-constant", ["defaultColumns"]),
    ...mapGetters({
      currentBuilding: "estimate/estimate-page/estimate/currentBuilding"
    }),

    vendorFilterOptions() {
      if (_.isEmpty(this.vendors)) return [];

      return this.vendors.filter(r =>
        (this.productList.vendorIdFilterList || []).includes(r.id)
      );
    },

    vendorOptions() {
      return (
        this.vendorFilterOptions
          .map(r => ({
            id: r.id,
            value: r.companyName
          }))
          .sort((a, b) => comparationSort(a.value, b.value)) || []
      );
    },

    vendorTitle() {
      if (
        _.isEmpty(this.vendorIds) ||
        _.isEmpty(this.vendorOptions) ||
        this.vendorFilterOptions.every(item =>
          this.vendorIds.includes(item.id)
        ) ||
        this.vendorIds.find(r => r === "All vendors")
      )
        return "All vendors";

      const vendorListName = [];

      for (let id of this.vendorIds) {
        const item = this.vendorOptions.find(e => e.id === id);
        if (item) vendorListName.push(item.value);
      }

      if (_.isEmpty(vendorListName)) {
        return "All vendors";
      }

      return vendorListName.join(",");
    },

    headers() {
      let self = this;
      let headers = [];
      if (_.isEmpty(this.customTable)) {
        headers = _.cloneDeep(this.defaultColumns) || [];
      } else {
        headers =
          _.cloneDeep(this.customTable.sortedColumns).filter(r => r.checked) ||
          [];
      }

      headers = headers.map(r => {
        switch (r.value) {
          case "vendor":
            r.filter = {
              type: "popover",
              title: this.vendorTitle,
              selectSource: [
                { id: "All vendors", value: "All vendors" },
                ...(!_.isEmpty(this.vendorOptions) ? this.vendorOptions : [])
              ],
              selectedList: (this.vendorFilterOptions || []).every(item =>
                (this.vendorIds || []).includes(item.id)
              )
                ? [
                    "All vendors",
                    ...(!_.isEmpty(this.vendorIds) ? this.vendorIds : [])
                  ]
                : [...(!_.isEmpty(this.vendorIds) ? this.vendorIds : [])],
              onChange: (checked, id) => {
                this.onSelectedVendor(checked, id);
              }
            };
            break;
          case "roofType":
            r.filter = {
              type: "select",
              value: self.gridFilter.roofType,
              selectSource: [
                { value: "", text: "All roof types" },
                ...this.roofTypeFilterOptions
                  .map(r => ({
                    value: r.value,
                    text: r.displayName
                  }))
                  .sort((a, b) => comparationSort(a, b))
              ],
              onChange: value => {
                self.gridFilter.roofType = value;
                this.onSearch();
              }
            };
            break;
          case "productCategory":
            r.filter = {
              type: "select",
              value: self.gridFilter.categoryId,
              selectSource: [
                { value: "", text: "All categories" },
                ...this.categoriesFilterOptions
                  .map(r => ({
                    value: r.id,
                    text: r.name
                  }))
                  .sort((a, b) => comparationSort(a.text, b.text))
              ],
              onChange: value => {
                self.gridFilter.categoryId = value;
                this.onSearch();
              }
            };
            break;
          case "subCategory":
            r.filter = {
              type: "select",
              value: self.gridFilter.subCategoryId,
              selectSource: [
                { value: "", text: "All sub categories" },
                ...this.subCategoriesFilterOptions
                  .map(r => ({
                    value: r.id,
                    text: r.name
                  }))
                  .sort((a, b) => comparationSort(a.text, b.text))
              ],
              onChange: value => {
                self.gridFilter.subCategoryId = value;
                this.onSearch();
              }
            };
            break;
        }
        return {
          ...r,
          text: r.title,
          value: r.value,
          sortable: false,
          align: "left"
        };
      });
      if (this.isShowActions) {
        headers.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }
      return headers;
    },
    productList() {
      if (this.dataType === "products-by-price-list") {
        return this.productsByPL || {};
      } else if (this.dataType === "products-default") {
        return this.productsDefault || {};
      } else {
        return {};
      }
    },
    categoriesFilterOptions() {
      return this.categories.filter(r =>
        (this.productList.categoryIdFilterList || []).includes(r.id)
      );
    },
    subCategoriesFilterOptions() {
      return this.subCategories.filter(r =>
        (this.productList.subCategoryIdFilterList || []).includes(r.id)
      );
    },
    roofTypeFilterOptions() {
      return this.roofTypeList.filter(r =>
        (this.productList.roofTypeFilterList || []).includes(r.value)
      );
    },

    isAllRoofType() {
      return (
        this.currentBuilding.roofType !== "" &&
        this.currentBuilding.roofType === "all-roof-type"
      );
    },

    pagingMessage() {
      const fromRow = this.productList.page * this.productList.hitsPerPage + 1;
      const toRow =
        this.productList.page * this.productList.hitsPerPage +
        this.productList.hits.length;
      return `${fromRow} - ${toRow} of ${this.productList.nbHits}`;
    },

    compileConditionsToFilterDataByPL() {
      // filter
      const filters = [`status:${STATUS_PRODUCT_ITEM_ACTIVE}`];
      if (!this.isAllRoofType) {
        if (this.dataType === "products-by-price-list") {
          filters.push(`roofTypes:${this.currentBuilding.roofType}`);
          if (this.currentPriceList) {
            filters.push(`priceListRefs:${this.currentPriceList.id}`);
          }
        } else {
          filters.push(
            `estServiceDefaultForTypes:${this.currentBuilding.roofType}`
          );
        }
      }
      return {
        filters: `${filters.join(" AND ")}${
          this.filters ? ` AND ${this.filters}` : ""
        }`
      };
    },

    compileConditionsToSearchByPL() {
      // filter
      const filters = [`status:${STATUS_PRODUCT_ITEM_ACTIVE}`];
      if (!this.isAllRoofType) {
        if (this.dataType === "products-by-price-list") {
          filters.push(`roofTypes:${this.currentBuilding.roofType}`);
          if (this.currentPriceList) {
            filters.push(`priceListRefs:${this.currentPriceList.id}`);
          }
        } else {
          filters.push(
            `estServiceDefaultForTypes:${this.currentBuilding.roofType}`
          );
        }
      }
      const { vendorId, categoryId, subCategoryId, roofType } = this.gridFilter;

      if (vendorId) filters.push(`vendorId:${vendorId}`);
      if (categoryId) filters.push(`categoryId:${categoryId}`);
      if (subCategoryId) filters.push(`subCategoryId:${subCategoryId}`);
      if (roofType) filters.push(`roofTypes:${roofType}`);
      if (!_.isEmpty(this.vendorIds) && this.vendorTitle !== "All vendors") {
        let vendorIdFilter = "( ";

        const newVendorIds = this.vendorIds.filter(r => r !== "All vendors");

        for (let i = 0; i < newVendorIds.length; i++) {
          vendorIdFilter += `vendorId:${newVendorIds[i]}`;
          if (i < newVendorIds.length - 1) {
            vendorIdFilter += " OR ";
          }
        }

        vendorIdFilter += " )";

        filters.push(vendorIdFilter);
      }

      return {
        query: this.searchText,
        filters: `${filters.join(" AND ")}${
          this.filters ? ` AND ${this.filters}` : ""
        }`
      };
    },
    productComputed() {
      const productRefs =
        (this.currentPriceList && this.currentPriceList.productRefs) || [];

      const productList = (this.productList.hits || []).map(r => {
        const overrideProduct =
          productRefs.find(p => p.productId === r.id) || {};
        let data = {
          ...r,
          vendorPrice: r.price || 0,
          markup: overrideProduct.markup || { value: 0, type: "percent" },
          price: overrideProduct.price || 0,
          internalNote: overrideProduct.internalNote || "",
          proposalNote: overrideProduct.proposalNote || "",
          estServiceDefaultForTypes:
            overrideProduct.estServiceDefaultForTypes || []
        };
        if (this.isCheckbox) {
          data.checked = this.checkedProductList.some(
            item => item.id === overrideProduct.productId
          );
        }

        return data;
      });

      return productList;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/price-list", ["updatePriceList"]),
    ...mapActions("estimate/estimate-page/product-default", [
      "searchProductItems",
      "getFilterData",
      "setProductNumberOfRows",
      "goToPage",
      "algoliaDeleteItem",
      "algoliaUpdateItemsWithDataList"
    ]),

    resetData() {
      this.searchText = "";
      this.gridFilter = {
        vendorId: "",
        categoryId: "",
        subCategoryId: "",
        roofType: ""
      };
      this.productPhotos = [];
      this.vendorIds = this.currentBuilding.vendorIds || [];
    },

    onSelectedVendor(checked, id) {
      const allVendorText = "All vendors";

      if (id === allVendorText) {
        this.vendorIds = checked
          ? [allVendorText, ...(this.vendorOptions || []).map(r => r.id)]
          : [];
      } else {
        this.vendorIds = checked
          ? [...(this.vendorIds || []), id]
          : (this.vendorIds || []).filter(r => r !== id && r !== allVendorText);
      }

      const checkIntersection = _.intersection(
        this.vendorIds,
        this.vendorOptions.map(r => r.id)
      );

      if (checkIntersection.length === this.vendorOptions.length) {
        this.vendorIds.push(allVendorText);
      }

      this.onSearch();
    },

    productRoofTypeNames(roofTypes = []) {
      const rt = (this.roofTypeList || []).filter(r =>
        roofTypes.includes(r.value)
      );
      return rt.map(r => r.displayName).join(", ");
    },

    openUrlLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },
    createColorPhotoTooltip() {
      this.productComputed.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: photo ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
      this.productComputed.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          if (el1) {
            this.$f7.tooltip.destroy(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)? `<video class="tooltip-image-show" src="${item?.url}" ></video>`:`<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass: isImage(item.url) || isVideo(item.url) ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
    },

    selectProductItem(products) {
      if (this.isCheckbox) {
        this.$emit("onSelectCheckBox", {
          products: products || [],
          page: this.productList.page
        });
      }
    },

    changeProductItem(product) {
      if (product.checked) {
        const founded = this.checkedProductList.find(
          item => item.id === product.id
        );
        if (!founded) {
          this.checkedProductList.push(product);
        }
      } else {
        const index = this.checkedProductList.findIndex(
          item => item.id === product.id
        );
        if (index > -1) {
          this.checkedProductList.splice(index, 1);
        }
      }
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows({
        dataType: this.dataType,
        value
      });
      this.onSearch();
    },

    async getFilterDataProduct() {
      if (this.dataType === "products-default") {
        await this.getFilterData({
          dataType: this.dataType,
          ...this.compileConditionsToFilterDataByPL
        });
      }

      const { vendorId, categoryId, subCategoryId, roofType } = this.gridFilter;
      const {
        vendorIdFilterList,
        categoryIdFilterList,
        subCategoryIdFilterList,
        roofTypeFilterList
      } = this.productList;
      if (!vendorIdFilterList.includes(vendorId)) this.gridFilter.vendorId = "";
      if (!categoryIdFilterList.includes(categoryId))
        this.gridFilter.categoryId = "";
      if (!subCategoryIdFilterList.includes(subCategoryId))
        this.gridFilter.subCategoryId = "";
      if (!roofTypeFilterList.includes(roofType)) this.gridFilter.roofType = "";
    },

    async onSearch() {
      this.$f7.preloader.show();
      await this.getFilterDataProduct();
      await this.searchProductItems({
        dataType: this.dataType,
        ...this.compileConditionsToSearchByPL
      });
      this.createColorPhotoTooltip();
      this.$f7.preloader.hide();
    },

    async loadCurrentProductPage() {
      this.$f7.preloader.show();
      await this.getFilterDataForPriceListTab();
      await this.searchProductItems({
        dataType: this.dataType,
        pageNumber: this.productList.page,
        ...this.compileConditionsToSearchByPL
      });
      const { hits, nbPages } = _.cloneDeep(this.productList);
      if (!hits.length && nbPages > 0) {
        await this.searchProductItems({
          dataType: this.dataType,
          pageNumber: nbPages - 1,
          ...this.compileConditionsToSearchByPL
        });
      }
      this.createColorPhotoTooltip();
      this.$f7.preloader.hide();
    },

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        dataType: this.dataType,
        pageName,
        ...this.compileConditionsToSearchByPL
      }).finally(() => {
        this.createColorPhotoTooltip();
        this.$f7.preloader.hide();
      });
    },

    openConfirmDelete(product) {
      this.$ri.dialog.openWarningDialog({
        title: "Delete product item",
        content: "Are you sure you want to delete the product item?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            // remove estServiceDefaultForTypes field for productRefs in PL
            product.estServiceDefaultForTypes = (
              product.estServiceDefaultForTypes || []
            ).filter(r => r !== this.currentBuilding.roofType);
            this.$f7.preloader.show();
            const promises = [];
            const productRefs = (
              _.cloneDeep(this.currentPriceList.productRefs) || []
            ).map(r => {
              if (r.productId === product.id) {
                if (_.isEmpty(product.estServiceDefaultForTypes)) {
                  // remove item in algolia
                  promises.push(
                    this.algoliaDeleteItem({
                      productId: product.id,
                      priceListId: this.currentPriceList.id
                    })
                  );
                } else {
                  promises.push(
                    this.algoliaUpdateItemsWithDataList([
                      {
                        ...product,
                        priceListId: this.currentPriceList.id
                      }
                    ])
                  );
                }
                const p = _.cloneDeep(r);
                p.estServiceDefaultForTypes = product.estServiceDefaultForTypes;
                return p;
              } else {
                return r;
              }
            });

            promises.push(
              this.updatePriceList({
                id: this.currentPriceList.id,
                doc: { productRefs }
              })
            );
            Promise.all(promises).then(() => {
              this.$f7.preloader.hide();
              _sefl.app.dialog.close();
              this.$emit("doAfterDelete");
            });
          }
        }
      });
    },

    openCustomizeTablePopup() {
      this.$refs.customizeTablePopup.open();
    }
  }
};
</script>

<style>
.mobile-container {
  justify-content: center;
  flex-direction: column-reverse;
}
.mobile-container > * {
  padding: 10px 0;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

 .technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
