<template>
  <div @click="$emit('onClick')" :class="`user-db-tooltip-${uid}`">
    <img
      v-if="imageUrl"
      class="avatar"
      :style="avatarClass"
      :src="imageUrl"
      :alt="name"
    />
    <div
      v-else
      class="avatar"
      :style="
        `${avatarClass} background: var(--f7-page-bg-color); ${
          border
            ? `border-color: ${
                selected ? 'red' : 'gray'
              }; border-width: 1px !important; border-style: solid;`
            : ''
        }`
      "
    >
      <span
        v-if="!iconAdd"
        :style="
          `font-size: ${avatarSize / 2}px; color: grey; font-weight: bold;`
        "
        >{{ nameSymbol }}</span
      >
      <span
        v-else
        class="display-flex align-items-center"
        :style="
          `font-size: ${avatarSize /
            2}px; color: var(--f7-theme-color); font-weight: bold;`
        "
        ><f7-icon f7="plus"></f7-icon
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    uid: String,
    imageUrl: String,
    avatarSize: Number,
    name: String,
    iconAdd: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },
  data() {
    return {
      tooltip: null
    };
  },
  watch: {
    uid(newUid) {
      this.createTooltip(newUid);
    }
  },
  mounted() {
    this.createTooltip(this.uid);
  },
  methods: {
    createTooltip(uid) {
      if (!this.$device.desktop) return;

      if (this.tooltip) {
        this.tooltip.destroy();
      }
      this.$nextTick(() => {
        this.tooltip = this.$f7.tooltip.create({
          targetEl: `.user-db-tooltip-${uid}`,
          text: this.name
        });
      });
    }
  },
  computed: {
    avatarClass() {
      const { avatarSize } = this;
      return `width: ${avatarSize}px; height: ${avatarSize}px;`;
    },
    nameSymbol() {
      if (this.imageUrl) return null;
      if (!this.name) return "";
      if (!this.name.trim().length) return "";
      let words = this.name.split(" ");
      if (words.length == 1) {
        return this.name.substring(0, 2).toUpperCase();
      }
      return (words[0][0] + "" + words[words.length - 1][0]).toUpperCase();
    }
  },
  beforeDestroy() {
    if (this.tooltip) {
      this.tooltip.destroy();
    }
  }
};
</script>
<style scoped>
.avatar {
  vertical-align: middle;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
