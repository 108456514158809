<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
      <form v-if="$f7route.query.mode === 'resetPassword'">
        <f7-login-screen-title>Type your new password!</f7-login-screen-title>
        <f7-list id="newPasswordForm" form>
          <f7-list-input ref="newPassword" type="password" label="New password" placeholder="New password" clear-button
            :value="newPassword" @input="newPassword = $event.target.value.trim()" validate-on-blur required
            error-message="Minimum eight characters, at least one letter, one number and one special character"
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"></f7-list-input>

          <f7-list-input ref="reNewPassword" type="password" label="Confirm password" placeholder="Confirm password"
            clear-button :value="reNewPassword" @input="reNewPassword = $event.target.value.trim()" validate-on-blur
            required error-message="Minimum eight characters, at least one letter, one number and one special character"
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"></f7-list-input>
        </f7-list>
        <f7-block>
          <f7-button round raised fill @click.native="resetPassword">Submit</f7-button>
        </f7-block>
      </form>
    </f7-page>
  </f7-view>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { auth } from "../services/firebase.service";
import { useVuelidate } from '@vuelidate/core'
import { required, email } from "@vuelidate/validators";
import {
  getLoginTenantId,
  updateEmailVerification
} from "../services/authentication.service";
import axios from "axios";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  data: () => {
    return {
      email: "",

      newPassword: "",
      reNewPassword: ""
    };
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      email: {
        required,
        commonEmailRule: email
      }
    };
  },

  beforeMount() {
    if (this.$f7route.query) {
      switch (this.$f7route.query.mode) {
        case "verifyEmail":
          return this.verifyEmail();
        // break;

        case "resetPassword":
          break;

        case "acceptInvitation":
          return this.acceptInvitation();

        default:
          this.$f7router.navigate("/login", {
            pushState: true,
            reloadAll: true
          });
          break;
      }
    }
  },

  computed: {
    ...mapState("setting/app/profile", ["dark"]),
    emailErrorMessage() {
      if (!this.v$.email.$error) return null;
      if (this.v$.email.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.email.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    }
  },

  methods: {
    ...mapActions("setting/app/profile", ["updateUserProfile"]),

    sendResetPasswordEmail() {
      // Validate input controls
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$f7.preloader.show();
      getLoginTenantId(this.email).then(tenantIds => {
        const loginTenantId = tenantIds[0] || "";
        if (loginTenantId !== "") {
          auth.tenantId = loginTenantId;
        }

        return auth
          .sendPasswordResetEmail(this.email)
          .then(() => {
            this.$f7router.navigate("/email-sent", {
              pushState: true,
              reloadAll: true
            });
          })
          .finally(() => {
            this.$f7.preloader.hide();
          });
      });
    },

    toLoginPage() {
      // window.location.href = "/login";
      this.$f7router.navigate("/login", {
        pushState: true,
        reloadAll: true
      });
    },

    verifyEmail() {
      const loginTenantId = this.$f7route.query.tenantId || "";
      if (loginTenantId !== "") {
        auth.tenantId = loginTenantId;
      }
      this.$f7.preloader.show();
      return updateEmailVerification(this.$f7route.query.code)
        .then(() => {
          this.$f7.preloader.hide();
          return this.$ri.dialog.openWarningDialog({
            title: "Email Verify",
            content: "Your email is verified.",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$f7router.navigate("/login", {
                  pushState: true,
                  reloadAll: true
                });
              }
            }
          });
        })
        .catch(() => {
          this.$f7.preloader.hide();
          return this.$ri.dialog.openErrorDialog({
            title: "Email verify failed",
            content: "Email verify is failed, please try again!",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$f7router.navigate("/login", {
                  pushState: true,
                  reloadAll: true
                });
              }
            }
          });
        });
    },

    resetPassword() {
      // Validate input controls
      this.$f7.input.validateInputs("#newPasswordForm");
      // Check validation
      if (
        this.$refs.newPassword.$refs.inputEl.validity.valid &&
        this.$refs.reNewPassword.$refs.inputEl.validity.valid
      ) {
        // Compare 2 passwords
        if (this.newPassword === this.reNewPassword) {
          // Show loading
          this.$f7.preloader.show();

          const loginTenantId = this.$f7route.query.tenantId || "";
          if (loginTenantId !== "") {
            auth.tenantId = loginTenantId;
          }

          auth
            .confirmPasswordReset(this.$f7route.query.oobCode, this.newPassword)
            .then(() => {
              this.$f7.preloader.hide();
              // Password reset has been confirmed and new password updated.
              const self = this;
              this.$ri.dialog.openSuccessDialog({
                title: "Change password success",
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    self.$f7router.navigate("/login", {
                      pushState: true,
                      reloadAll: true
                    });
                  }
                }
              });
            })
            .catch(e => {
              // Error occurred during confirmation. The code might have
              // expired or the password is too weak.
              const notificationClickToClose = this.$f7.notification.create({
                icon:
                  "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
                title: "Change password failure",
                text: e.message,
                closeOnClick: true,
                closeButton: true,
                closeTimeout: 10000
              });
              notificationClickToClose.open();
            })
            .finally(() => {
              // Hide loading
              this.$f7.preloader.hide();
            });
        } else {
          // 2 passwords not equal
          const notificationClickToClose = this.$f7.notification.create({
            icon:
              "<i class='color-red icon f7-icons'>exclamationmark_triangle_fill</i>",
            title: "Change password failure",
            text: "Password and confirm password does not match",
            closeOnClick: true,
            closeButton: true,
            closeTimeout: 7000
          });
          notificationClickToClose.open();
        }
      }
    },

    acceptInvitation() {
      const tenantId = this.$f7route.query.tenantId;
      const uid = this.$f7route.query.uid;
      const idToken = this.$f7route.query.idToken;
      if (tenantId && idToken) {
        axios
          .post(
            `${import.meta.env.VITE_BASE_API}/admin/accept-invitation/${uid}`,
            {},
            {
              headers: {
                id_token: idToken,
                login_tenant: tenantId
              }
            }
          )
          .then(() => {
            return this.$ri.dialog.openSuccessDialog({
              title: "Email Verify",
              content: "Your email is verified.",
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  this.$f7router.navigate("/login", {
                    pushState: true,
                    reloadAll: true
                  });
                }
              }
            });
          })
          .catch(error => {
            return this.$ri.dialog.openSuccessDialog({
              title: "Email Verify",
              content: error.message,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  this.$f7router.navigate("/login", {
                    pushState: true,
                    reloadAll: true
                  });
                }
              }
            });
          });
      }
    }
  }
};
</script>

<style></style>
