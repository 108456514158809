import {
  FirebaseActions,
  firestore,
  firebase
} from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import invoiceService from "../../../../services/invoice.service";
import algoliaService from "../../../../services/algolia.service";
import { searchClient } from "../../../../services/search.service";
import * as types from "./types";

export default tenant => {
  const invoiceActions = new FirebaseActions(
    `/system_client/${tenant}/invoice`,
    "invoice"
  );

  const invoiceIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const invoiceIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindInvoiceList: invoiceActions.bindCollection,
    bindInvoiceListBys: invoiceActions.bindCollectionBys,
    unbindInvoiceList: invoiceActions.unbindCollection,

    bindInvoice: invoiceActions.bindDocument,
    unbindInvoice: invoiceActions.unbindDocument,

    createInvoice: invoiceActions.createDocument,
    saveInvoice: invoiceActions.updateDocument,

    getInvoiceById: invoiceActions.getDocument,
    getInvoiceBys: invoiceActions.getDocumentBys,

    async getInvoiceByGroupId({ commit, dispatch }, invoiceGroupId) {
      if (!invoiceGroupId) return;
      const invoiceList = await dispatch("getInvoiceBys", [
        { prop: "invoiceGroupId", op: "==", val: invoiceGroupId }
      ]);

      commit(types.SET_INVOICE_LIST, invoiceList);
    },

    getInvoiceByTenant(context, { id, tenant }) {
      let collectionPath = `/system_client/${tenant}/invoice`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    },

    updateInvoiceViewed(context, { id, tenant }) {
      let doc = {};
      doc.status = "in-viewed";
      doc.viewedAt = firebase.firestore.FieldValue.serverTimestamp();
      let collectionPath = `/system_client/${tenant}/invoice`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .update(doc);
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    async searchInvoice(
      { state, commit, getters },
      { attributesToRetrieve, pageNumber }
    ) {
      const index = state.order === "asc" ? invoiceIndexAsc : invoiceIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_INVOICE} AND isProjectArchived:false`,
        attributesToRetrieve: constant.INVOICE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.INVOICE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage({ state, commit, getters }, { pageName }) {
      const index = state.order === "asc" ? invoiceIndexAsc : invoiceIndexDesc;
      const filters = getters.queryFilters;
      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = state.page + 1;
          break;

        case "prev":
          pageNumber = state.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_INVOICE} AND isProjectArchived:false`,
        attributesToRetrieve: constant.INVOICE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.INVOICE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreInvoice(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index = state.order === "asc" ? invoiceIndexAsc : invoiceIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_INVOICE} AND isProjectArchived:false`,
        attributesToRetrieve: constant.INVOICE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.INVOICE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setInvoiceNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setInvoiceSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    // Allow search desc only
    // setInvoiceSearchOrder({ commit }, payload) {
    //   commit(types.SET_SEARCH_ORDER, payload);
    // },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async loadCurrentPage({ state, dispatch }) {
      const statusFilters = state.selectedStatusFilter
        .map(s => `status:${s}`)
        .join(" OR ");
      await dispatch("searchInvoice", {
        query: state.searchText,
        pageNumber: state.page,
        filters: statusFilters
      });
      const { hits, nbPages } = state;
      if (!hits.length && nbPages > 0) {
        await dispatch("searchInvoice", {
          query: state.searchText,
          pageNumber: nbPages - 1,
          filters: statusFilters
        });
      }
    },

    async createNewInvoice(
      { dispatch, commit, rootGetters },
      { invoice, isInvoiceGrid = false }
    ) {
      const user = rootGetters["setting/app/profile/user"];
      const doc = await invoiceService.addInvoiceDoc(user.tenantId, invoice);
      if (isInvoiceGrid) {
        await algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: "invoice",
          id: doc.id
        });
        await dispatch("loadCurrentPage");
      } else {
        commit(types.INVOICE_LIST_ITEM_ADD, {
          ...invoice,
          id: doc.id,
          invoiceNumber: doc.docNumber,
          createdAt: doc.createdAt
        });
      }
      return doc;
    },

    async updateInvoice({ dispatch, commit }, { id, doc }) {
      if (doc.projectId) {
        doc.isProjectArchived = await dispatch(
          "common/common/getIsProjectArchived",
          doc.projectId,
          { root: true }
        );
      }
      commit(types.INVOICE_LIST_ITEM_UPDATE, {
        id,
        doc
      });

      return dispatch("saveInvoice", { id, doc });
    },
    deleteInvoice({ dispatch, commit }, id) {
      return dispatch("saveInvoice", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      }).then(() => {
        return commit(types.INVOICE_LIST_ITEM_REMOVE, id);
      });
    },
    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    }
  };
};
