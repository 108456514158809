<template>
  <div>
    <f7-block
      strong
      :inset="$device.desktop"
      :class="{ 'no-margin': $device.desktop }"
      class="margin-bottom"
    >
      <f7-block-header>Main Dimension</f7-block-header>
      <f7-list inline-labels>
        <f7-list-input
          label="a1"
          type="text"
          placeholder="Enter value"
          :value="data.a1"
          @change="setField('a1', $event.target.value.trim())"
        ></f7-list-input>
        <f7-list-input
          label="b1"
          type="text"
          placeholder="Enter value"
          :value="data.b1"
          @change="setField('b1', $event.target.value.trim())"
        ></f7-list-input>
        <f7-list-input
          label="d1"
          type="text"
          placeholder="Enter value"
          :value="data.d1"
          @change="setField('d1', $event.target.value.trim())"
        ></f7-list-input>
      </f7-list>
    </f7-block>
    <f7-block
      strong
      :inset="$device.desktop"
      :class="{ 'no-margin': $device.desktop }"
      class="margin-bottom"
    >
      <f7-block-header>Other Dimension</f7-block-header>
      <f7-list accordion-list>
        <f7-list-item
          accordion-item
          :accordion-item-opened="photoSelected === 'photo1'"
          @accordion:opened="$emit('setPhotoSelected', 'photo1')"
          @accordion:closed="$emit('setPhotoSelected', '')"
          title="Coping"
        >
          <f7-accordion-content
            :style="
              `height: ${photoSelected === 'photo1' ? 'auto' : '0 !important'}`
            "
          >
            <f7-list inline-labels>
              <f7-list-input
                label="a1"
                type="text"
                placeholder="Enter value"
                :value="data.a1"
                @change="setField('a1', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="b1"
                type="text"
                placeholder="Enter value"
                :value="data.b1"
                @change="setField('b1', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="c1"
                type="text"
                placeholder="Enter value"
                :value="data.c1"
                @change="setField('c1', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="d1"
                type="text"
                placeholder="Enter value"
                :value="data.d1"
                @change="setField('d1', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="e1"
                type="text"
                placeholder="Enter value"
                :value="data.e1"
                @change="setField('e1', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="f1"
                type="text"
                placeholder="Enter value"
                :value="data.f1"
                @change="setField('f1', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="angle"
                type="text"
                placeholder="Enter value"
                :value="data.angle"
                @change="setField('angle', $event.target.value.trim())"
              ></f7-list-input>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          :accordion-item-opened="photoSelected === 'photo2'"
          @accordion:opened="$emit('setPhotoSelected', 'photo2')"
          @accordion:closed="$emit('setPhotoSelected', '')"
          title="Cleat"
        >
          <f7-accordion-content
            :style="
              `height: ${photoSelected === 'photo2' ? 'auto' : '0 !important'}`
            "
          >
            <f7-list inline-labels>
              <f7-list-input
                label="a2"
                type="text"
                placeholder="Enter value"
                :value="data.a2"
                @change="setField('a2', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="b2"
                type="text"
                placeholder="Enter value"
                :value="data.b2"
                @change="setField('b2', $event.target.value.trim())"
              ></f7-list-input>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item
          accordion-item
          :accordion-item-opened="photoSelected === 'photo3'"
          @accordion:opened="$emit('setPhotoSelected', 'photo3')"
          @accordion:closed="$emit('setPhotoSelected', '')"
          title="Fasterners Installation"
        >
          <f7-accordion-content
            :style="
              `height: ${photoSelected === 'photo3' ? 'auto' : '0 !important'}`
            "
          >
            <f7-list inline-labels>
              <f7-list-input
                label="a3"
                type="text"
                placeholder="Enter value"
                :value="data.a3"
                @change="setField('a3', $event.target.value.trim())"
              ></f7-list-input>
              <f7-list-input
                label="b3"
                type="text"
                placeholder="Enter value"
                :value="data.b3"
                @change="setField('b3', $event.target.value.trim())"
              ></f7-list-input>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-block>
  </div>
</template>

<script>
import { evaluate } from "mathjs";
import canvasMixin from "../../utility/canvas-mixin";

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    photoSelected: String
  },

  methods: {
    setField(name, value) {
      this.$emit("setFields", {
        name,
        value,
        callback: (name, value) => {
          switch (name) {
            case "a1":
              this.$emit("setFields", {
                name: "a2",
                value: `${evaluate(this.convertStringToExpression(value)) - 2}"`
              });
              break;
            case "f1":
              this.$emit("setFields", { name: "b2", value });
              break;
          }
        }
      });
    }
  }
};
</script>
