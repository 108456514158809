<template>
  <f7-popup :opened="popupOpened" @popup:closed="close" @popup:opened="handlePopupOpened">
    <div class="page" v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select {{ title }}</f7-nav-title>
        <f7-nav-right v-if="!isHiddenAddNew">
          <f7-link icon-f7="plus" @click.native="onAdd"></f7-link>
          <f7-link v-if="isShowSelectAll" @click.native="handleSelectCompany">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar disable-button-text :placeholder="`Search ${title}`" :clear-button="true" :value="companySearchText"
        @input="
          setCompanySearchText($event.target.value);
          onSearch();
        " @searchbar:disable="onSearch()" @searchbar:clear="onSearch()"
        class="search-list-popup"></f7-searchbar>
      <div v-if="isShowSelectAll && isShowCheckbox" class="page-nav">
        <div class="select-all-container">
          <f7-checkbox :id="'selectAllCheckbox'" :checked="selectAllChecked"
            @change="handleSelectAllChange"></f7-checkbox>
          <label :for="'selectAllCheckbox'">Select All</label>
        </div>
      </div>
      <div class="page-content" :class="isShowSelectAll && isShowCheckbox ? 'margin-page' : ''" @scroll="onScroll">
        <f7-list class="search-list searchbar-found contact-list">
          <f7-list-group v-for="(group, index) in companyGroup" :key="index">
            <f7-list-item :title="group.key" group-title></f7-list-item>
            <f7-list-item no-chevron :link="!isShowSelectAll" :checkbox="isShowSelectAll"
              v-for="(company, index) in group.data" :key="index" :title="company.companyName"
              @click.native="!isShowSelectAll && select(company.id)" :checked="selectedItems.some(p => p.id === company.id && p.checked)
                " @change.native="
                  handleCheckboxChange($event.target.checked, company)
                  ">
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
    </div>

    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New {{ title }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Company Name -->
        <f7-list-input class="first-input" type="text" placeholder="Company name" clear-button
          :value="currentCompany.companyName" @input="set('companyName', $event.target.value.trim())"
          error-message-force :error-message="companyNameErrorMessage" @blur="v$.$touch()"></f7-list-input>
      </f7-list>

      <template v-if="companyTypes.length !== 1 || defaultTypesSelected.length">
        <f7-block-header>Company Types</f7-block-header>
        <f7-list>
          <f7-list-item v-for="(type, index) in companyTypeList" :key="index" checkbox :title="type.displayName"
            :value="type.value" :checked="isCheckedCompanyType(type.value)"
            :disabled="isReadOnlyCompanyTypeField(type.value)" name="company-type-checkbox"
            @change="changeCompanyType($event.target.value)"></f7-list-item>
        </f7-list>
      </template>

      <!-- account number -->
      <f7-list v-show="isVendor">
        <f7-list-input type="text" label="Account Number" placeholder="Your account number with this Vendor"
          clear-button :value="currentCompany.accountNumber" @input="set('accountNumber', $event.target.value.trim())">
        </f7-list-input>
      </f7-list>

      <!-- tax code -->
      <f7-list>
        <f7-list-input
          placeholder="Company Tax Code"
          ref="companyTaxCodeInput"
          :value="currentCompany.companyTaxCode"
          @input="set('companyTaxCode', $event.target.value.trim())"
          clear-button
          v-mask="'##-#######'"
          error-message-force
          :error-message="companyTaxCodeErrorMessage"
        ></f7-list-input>
      </f7-list>

      <!-- Phone -->
      <phone-input ref="phoneInput" isRequired :value="currentCompany.phones" @input="setPhones($event)"></phone-input>

      <!-- Address -->
      <address-auto-complete-input ref="addressInput" :value="currentCompany.addresses"
        @input="setAddresses($event)"></address-auto-complete-input>

      <!-- Other -->
      <other-input ref="otherInput" :value="currentCompany.others" @input="setOthers($event)"></other-input>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PhoneInput from "../inputs/PhoneInput.vue";
import AddressAutoCompleteInput from "../inputs/AddressAutoCompleteInput.vue";
import OtherInput from "../inputs/OtherInput.vue";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from "@vuelidate/validators";
import _ from "lodash";
import Vue from "vue";
import { VALIDATION_MESSAGE } from '@/utility/const';
import { mask } from "vue-the-mask";

export default {
  props: {
    title: { type: String, default: "Company" },
    companyTypes: { type: Array, default: () => [] },
    contactId: String,
    queryFilters: String,
    isHiddenAddNew: { type: Boolean, default: false },
    forBranch: { type: Boolean, default: false }, // this prop help to select the store path
    isShowSelectAll: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    defaultTypesSelected: { type: Array, default: () => [] }
  },

  components: {
    PhoneInput,
    AddressAutoCompleteInput,
    OtherInput
  },
  data() {
    return {
      popupOpened: false,
      isNew: false,
      currentCompany: {
        companyName: "",
        companyTypes: []
      },
      onlyAddNew: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,

      selectAllChecked: false,
      toggleSelectChecked: false,
      selectedItems: [],
      isChecked: false
    };
  },

  computed: {
    ...mapState({
      storePath() {
        return `${this.forBranch ? "branch" : "company"}`;
      },
      companyGroup(state, getters) {
        return getters[`common/${this.storePath}/companyGroup`];
      },
      hits(state, getters) {
        return getters[`common/${this.storePath}/hits`];
      },
      nbPages(state, getters) {
        return getters[`common/${this.storePath}/nbPages`];
      },
      page(state, getters) {
        return getters[`common/${this.storePath}/page`];
      },
      companyTypeList(state, getters) {
        return getters[`common/${this.storePath}/companyTypeList`];
      },
      companySearchText(state, getters) {
        return getters[`common/${this.storePath}/companySearchText`];
      }
    }),

    isVendor() {
      return (
        this.currentCompany.companyTypes.findIndex(item => {
          return item === "vendor";
        }) > -1
      );
    },

    companyNameErrorMessage() {
      if (!this.v$.currentCompany.companyName.$error) return null;
      if (this.v$.currentCompany.companyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    companyTaxCodeErrorMessage() {
      if (!this.v$.currentCompany.companyTaxCode.$error) return null;
      if (this.v$.currentCompany.companyTaxCode.validateEIN.$invalid)
        return "Invalid tax code";
      return null;
    },
    isShowCheckbox() {
      return this.page + 1 === this.nbPages;
    }
  },

  methods: {
    ...mapActions("common/contact", ["getContact"]),

    ...mapActions({
      searchCompany(dispatch, payload) {
        return dispatch(`common/${this.storePath}/searchCompany`, payload);
      },

      loadMoreCompany(dispatch, payload) {
        return dispatch(`common/${this.storePath}/loadMoreCompany`, payload);
      },
      createCompany(dispatch, payload) {
        return dispatch(`common/${this.storePath}/createCompany`, payload);
      },
      resetSearch(dispatch, payload) {
        return dispatch(`common/${this.storePath}/resetSearch`, payload);
      },
      getCompanyListBys(dispatch, payload) {
        return dispatch(`common/${this.storePath}/getCompanyListBys`, payload);
      },
      getCompanyTypeList(dispatch, payload) {
        return dispatch(`common/${this.storePath}/getCompanyTypeList`, payload);
      },
      setCompanyTypeList(dispatch, payload) {
        return dispatch(`common/${this.storePath}/setCompanyTypeList`, payload);
      },
      setCompanySearchText(dispatch, payload) {
        return dispatch(
          `common/${this.storePath}/setCompanySearchText`,
          payload
        );
      },
      setQueryFilters(dispatch, payload) {
        return dispatch(`common/${this.storePath}/setQueryFilters`, payload);
      }
    }),
    handleSelectAllChange() {
      this.selectAllChecked = !this.selectAllChecked;
      this.selectedItems = this.selectAllChecked
        ? this.hits.map(item => ({ ...item, checked: true }))
        : [];
    },
    handleCheckboxChange(checked, item) {
      const newItem = { ...item, checked };

      if (checked && !this.selectedItems.includes(item)) {
        this.selectedItems.push(newItem);
      } else {
        this.selectedItems = this.selectedItems.filter(r => r.id !== item.id);
      }
    },
    handleSelectCompany() {
      const itemsRemove = this.items.filter(
        item => !this.selectedItems.some(r => item.id === r.id)
      );
      if (itemsRemove.length > 0) {
        itemsRemove.forEach(item => {
          this.$emit("onRemoved", item.id);
        });
      }
      this.selectedItems.forEach(item => {
        this.$emit("onSelected", item.id);
      });
      this.close();
    },
    async autoFillCompany() {
      const contact = this.contactId
        ? await this.getContact(this.contactId)
        : null;
      if (contact && !_.isEmpty(contact.companyRefs)) {
        try {
          await this.searchCompany({});
          if (!_.isEmpty(this.hits)) {
            this.select(this.hits[0].id);
          } else {
            this.select("");
          }
        } catch (e) {
          this.select("");
        }
      } else {
        this.select("");
      }
    },
    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),
    handleSearch(isFirst = false) {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchCompany({})
        .then(() => {
          if (isFirst && this.queryFilters && _.isEmpty(this.hits)) {
            this.setQueryFilters("");
            return this.searchCompany({});
          }
        })
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        });
    },

    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreCompany({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    set(prop, value) {
      Vue.set(this.currentCompany, prop, value);
    },

    async open(onlyAddNew = false) {
      if (onlyAddNew) {
        if (this.companyTypes.length !== 1) {
          await this.getCompanyTypeList(this.companyTypes);
        }
        this.isNew = true;
        this.onlyAddNew = true;
        this.popupOpened = true;

        if (this.defaultTypesSelected.length) {
          Vue.set(
            this.currentCompany,
            "companyTypes",
            _.cloneDeep(this.defaultTypesSelected)
          );
          return;
        }

        Vue.set(
          this.currentCompany,
          "companyTypes",
          this.companyTypes.length === 1 ? this.companyTypes : []
        );
      } else {
        this.$f7.preloader.show();
        this.setQueryFilters(this.queryFilters);
        this.handleSearch(true)
          .then(() => {
            this.popupOpened = true;
            this.selectedItems =
              this.items.map(item => ({
                ...item,
                checked: true
              })) || [];
          })
          .finally(() => {
            this.$f7.preloader.hide();
          });
      }
    },

    close() {
      this.popupOpened = false;
      this.searchValue = "";
      this.isNew = false;
      this.currentCompany = {
        companyName: "",
        companyTypes: []
      };
      this.onlyAddNew = false;
      this.setCompanySearchText("");

      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.hasData = true;
      this.v$.$reset();
      this.$refs.phoneInput.resetData();
      this.resetSearch();
      this.setCompanyTypeList([]);
    },

    select(companyId) {
      this.$emit("onSelected", companyId);
      this.close();
    },

    async onAdd() {
      Vue.set(
        this.currentCompany,
        "companyTypes",
        !_.isEmpty(this.companyTypes) ? this.companyTypes : []
      );
      if (this.companyTypes.length !== 1) {
        await this.getCompanyTypeList(this.companyTypes);
      }
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector(".first-input input").focus();
        }
      });
    },

    onAddCancel() {
      this.isNew = false;
      this.currentCompany = {
        companyName: "",
        companyTypes: []
      };
      if (this.onlyAddNew) {
        this.popupOpened = false;
        this.onlyAddNew = false;
      }
      this.v$.$reset();
      this.$refs.phoneInput.resetData();
    },

    setPhones(val) {
      Vue.set(this.currentCompany, "phones", val);
    },

    setAddresses(val) {
      Vue.set(this.currentCompany, "addresses", val);
    },

    setOthers(val) {
      Vue.set(this.currentCompany, "others", val);
    },

    changeCompanyType(val) {
      if (this.currentCompany.companyTypes.some(item => item === val)) {
        Vue.set(
          this.currentCompany,
          "companyTypes",
          this.currentCompany.companyTypes.filter(item => item != val)
        );
      } else {
        this.currentCompany.companyTypes.push(val);
      }
    },

    async existsCompany() {
      const companies = await this.getCompanyListBys([
        {
          prop: "companyName",
          val: this.currentCompany.companyName,
          op: "=="
        },
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        },
      ]);

      if (!_.isEmpty(companies)) {
        this.$ri.dialog.openWarningDialog({
          title: "Please choose another one",
          content: "That file name has already existed!",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      }

      return !_.isEmpty(companies);
    },

    async create() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.otherInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.otherInput.v$.$invalid ||
        this.v$.$invalid ||
        (await this.existsCompany())
      )
        return;

      this.$f7.preloader.show();
      this.createCompany(this.currentCompany)
        .then(id => {
          this.$f7.preloader.hide();
          id && this.$emit("onCreated", id);
        })
        .finally(() => {
          this.close();
        });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
        if (this.onlyAddNew) {
          this.$el.querySelector(".first-input input").focus();
        }
      }
    },

    isCheckedCompanyType(type) {
      return this.currentCompany.companyTypes.some(item => item === type);
    },

    isReadOnlyCompanyTypeField(type) {
      return this.defaultTypesSelected.some(item => item === type);
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  directives: { mask },

  validations() {
     const validateEIN = helpers.withParams(
      { type: "validateEIN" },
      value => {
        if(!value) return true
        return  /^\d{2}-\d{7}$/.test(value)
      }
    );
    return {
      currentCompany: {
        companyName: {
          required
        },
        companyTaxCode: {
          validateEIN
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.page-nav {
  display: flex;
  padding: 16px 16px 10px 16px;
  justify-content: space-between;
  position: fixed;
  margin-top: 70px;
  width: 100%;
  z-index: 9999;
  background-color: var(--f7-list-group-title-bg-color);
}

.select-all-container,
.toggle-select-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.select-all-container label,
.toggle-select-container label {
  color: #ef4e3a;
  background-color: var(--f7-list-group-title-bg-color);
}

.item-checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.item-checkbox label {
  cursor: pointer;
}

.margin-page {
  margin-top: 40px;
}
</style>
