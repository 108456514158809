import { FirebaseActions } from "../../../../services/firebase.service";

import * as types from "./types";

export default tenant => {
  const ScheduleActions = new FirebaseActions(
    `/system_client/${tenant}/schedule_value_setting`,
    "scheduleSetting"
  );

  return {
    setScheduleInfo({ commit }, payload) {
      commit(types.SET_SCHEDULE_INFO, payload);
    },

    bindScheduleSettingList: ScheduleActions.bindCollection,
    unbindScheduleSettingList: ScheduleActions.unbindCollection,

    createScheduleSetting: ScheduleActions.createDocument,
    updateScheduleSetting: ScheduleActions.updateDocument,
    deleteScheduleSetting: ScheduleActions.deleteDocument
  };
};
