<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Employee Time Log Detail </f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>

      <time-log-detail></time-log-detail>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TimeLogDetail from "../details/TimeLogDetail.vue";
import _ from "lodash";

export default {
  components: {
    TimeLogDetail
  },

  data: () => {
    return {
      popupOpened: false
    };
  },

  computed: {
    ...mapGetters("setting/app/system", ["setting"]),
    ...mapGetters('common/app-constant', ['tenantId']),
  },

  methods: {
    ...mapActions("setting/app/system", ["bindSetting"]),
    ...mapMutations({
      setDetailUid: "time-tracking/time-tracking/setDetailUid"
    }),

    open(uid) {
      this.setDetailUid(uid);
      if (!_.isEmpty(this.setting)) {
        this.bindSetting(this.tenantId);
      }
      this.popupOpened = true;
    },

    close() {
      this.popupOpened = false;
    }
  }
};
</script>
