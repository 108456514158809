<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
  >
    <f7-page>
      <f7-navbar title="Billing history">
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <data-table
        :headers="headers"
        :items="paymentHistory"
        :pageSize="paymentHistory.length"
      >
        <template v-slot:body="{ item }">
          <td>{{ item.billId }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.status | billingStatus }}</td>
          <td class="numeric-cell">{{ item.total | currencyUSD }}</td>
        </template>
      </data-table>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    DataTable
  },
  data() {
    return {
      popupOpened: false
    };
  },
  filters: {
    billingStatus(v) {
      return {
        succeeded: "Success",
        requires_action: "Failed",
        requires_payment_method: "Failed"
      }[v];
    }
  },
  computed: {
    ...mapGetters("setting/app/system", ["paymentHistory"]),
    headers() {
      return [
        {
          text: "Bill ID",
          align: "left",
          sortable: false,
          value: "billId"
        },
        {
          text: "Date",
          value: "date",
          sortable: false,
          align: "left"
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "left"
        },
        {
          text: "Total charges",
          value: "total",
          sortable: false,
          align: "right"
        }
      ];
    }
  },

  methods: {
    ...mapActions("setting/app/system", ["getPaymentHistory"]),
    async open() {
      this.$f7.preloader.show();
      this.getPaymentHistory()
        .catch(err => {
          this.showErrorMessage(err.message);
        })
        .finally(() => {
          this.$f7.preloader.hide();
          this.popupOpened = true;
        });
    },

    showErrorMessage(messages) {
      this.$ri.dialog.openSuccessDialog({
        title: "Connect to Stripe Online",
        content: messages,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            window.location.href = "/administration/subscription";
          }
        }
      });
    }
  }
};
</script>

<style></style>
