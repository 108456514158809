import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import SpreadsheetWriter from "../../../utility/spreadsheet-writer";
import { currencyUSD } from "../../../utility/config";
function getExcelWorkbook({ headers, values, totals, purchaseOrderInfo }) {
  const { taskNumber } = purchaseOrderInfo;
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Purchase Order");
  var writer = new SpreadsheetWriter(worksheet);
  writer.write("Purchase number:");
  writer.mergeNext();
  writer.right();
  writer.right();
  writer.write(taskNumber);
  writer.mergeNext(7);
  writer.bold();
  writer.enter();

  const columns = headers;
  //header
  columns.forEach(x => {
    writer.write(x["header"]);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.right();
  });
  values.forEach(y => {
    writer.enter();
    [
      "sku",
      "items",
      "manufacturer",
      "orderQty",
      "receivedQty",
      "packaging",

      "unitSize",
      "uom",
      "price",
      "subTotal"
    ].forEach(z => {
      writer.write(y[z]);
      writer.border();
      writer.right();
    });
  });

  writer.enter();
  writer.right(8);
  writer.write("Total");
  writer.border();
  writer.right();
  writer.write(currencyUSD(totals));
  writer.border();
  writer.bold();
  writer.enter();
  writer.enter();
  return workbook;
}
function exportPurchaseOrder({ headers, values, totals, purchaseOrderInfo }) {
  const workbook = getExcelWorkbook({
    headers,
    values,
    totals,
    purchaseOrderInfo
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `purchase-order-${new Date().getTime()}.xlsx`
    );
  });
}

function getEstimateSummaryExcelWorkbook({ headers, estimateNumber, values }) {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${estimateNumber} Summary`);
  var writer = new SpreadsheetWriter(worksheet);
  //header
  headers.forEach(x => {
    writer.write(x.text);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.mergeNext();
    writer.right();
    writer.right();
  });
  values.forEach(v => {
    writer.enter();
    headers.forEach(h => {
      if (h.field === "name") {
        writer.write(v[h.field]);
      } else if (h.field === "profitMargin" && v.name !== "Total") {
        writer.write(
          `${currencyUSD(v[h.field])} (${(
            v.crossProfitMarginRatio || 0
          ).toFixed(3)}%)`
        );
      } else {
        writer.write(currencyUSD(v[h.field]));
      }
      if (v.isBold) {
        writer.bold();
      }
      writer.border();
      writer.mergeNext();
      writer.right();
      writer.right();
    });
  });

  return workbook;
}
function exportEstimateSummaryToExcel({ headers, estimateNumber, values }) {
  const workbook = getEstimateSummaryExcelWorkbook({
    headers,
    estimateNumber,
    values
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `${estimateNumber}-summary-${new Date().getTime()}.xlsx`
    );
  });
}

export {
  getExcelWorkbook,
  getEstimateSummaryExcelWorkbook,
  exportEstimateSummaryToExcel
};

export default exportPurchaseOrder;
