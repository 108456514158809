<template>
  <div>
    <f7-block-header>Purchase Orders</f7-block-header>
    <f7-list>
      <!-- Main list item -->

      <f7-list-item
        link="#"
        @click="handlePOLink(po.id)"
        v-for="(po, index) in purchaseOrderList"
        :key="index"
        ><div slot="title" class="po-text-color">
          {{ po.taskNumber }}
        </div>
        <f7-chip
          slot="after"
          :text="purchaseOrderStatusBy(po.status).displayName"
          :color="purchaseOrderStatusBy(po.status).color"
        ></f7-chip>
      </f7-list-item>

      <!-- Add list item -->
      <f7-list-item @click.native="handleNewPO">
        <div slot="media" class="add-button-icon cursor-pointer">
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <div class="cursor-pointer" slot="title">{{ title }}</div>
      </f7-list-item>
    </f7-list>

    <!-- Popup -->
    <purchase-order-detail-popup
      ref="purchaseOrderDetailPopup"
      @navigateToBoard="() => {}"
      :jobObject="value"
      :fromServiceBoard="fromServiceBoard"
    ></purchase-order-detail-popup>

    <add-new-purchase-order-popup
      ref="addNewPurchaseOrderPopup"
      @openPODetail="openPODetail"
      @navigateToBoard="() => {}"
      :jobObject="value"
      :fromServiceBoard="fromServiceBoard"
    ></add-new-purchase-order-popup>
  </div>
</template>

<script>
import { toDisplayDateString } from "@/utility/datetime";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import AddNewPurchaseOrderPopup from "../popup/AddNewPurchaseOrderPopup.vue";
import PurchaseOrderDetailPopup from "../popup/PurchaseOrderDetailPopup.vue";

export default {
  components: {
    AddNewPurchaseOrderPopup,
    PurchaseOrderDetailPopup
  },

  props: {
    value: { type: Object, default: () => {} },
    title: { type: String, default: "Add an Purchase Order" },
    fromServiceBoard: { type: Boolean, default: false }
    // estimateList: { type: Array, default: () => [] },
    // action: { type: Object, default: () => {} },
    // boardId: { type: String, default: "" },
    // activeTab: { type: String, default: "" }
  },

  data() {
    return {};
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initDisplayValues();
      }
    }
  },

  computed: {
    ...mapGetters("dashboard/project", ["card"]),
    ...mapGetters("dashboard/work-order", ["purchaseOrderList"]),
    ...mapGetters("dashboard/app-constant", [
      "purchaseOrderStatusBy"
      // "roofTypeBy"
    ]),

    project() {
      if (this.fromServiceBoard) {
        return this.value;
      }
      return this.card;
    }
  },

  methods: {
    ...mapActions("dashboard/work-order", ["getPurchaseOrderListByProjectId"]),
    ...mapActions("dashboard/app-constant", ["getConstantTypeList"]),

    initDisplayValues() {
      if (!_.isEmpty(this.project)) {
        this.getPurchaseOrderListByProjectId(this.project?.id);
      }

      this.getConstantTypeList();
    },

    handlePOLink(poId) {
      this.$refs.purchaseOrderDetailPopup.open(poId);
    },

    handleNewPO() {
      this.$refs.addNewPurchaseOrderPopup.openPopup();
    },

    async openPODetail(poId) {
      await this.getPurchaseOrderListByProjectId(this.project?.id);
      this.$refs.purchaseOrderDetailPopup.open(poId);
    },

    getStatusString(status) {
      switch (status) {
        case "active":
          return "Active";

        case "inactive":
          return "Inactive";

        default:
          return "Draft";
      }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(estimate) {
      const self = this;
      const boardId = this.boardId;
      const actionId = this.action.id;
      const projectId = this.project.id;
      const contactId = estimate.contactId;
      const estimateNumber = estimate.estimateNumber;

      this.$emit("closePopup", () => {
        const url = `/dashboard/type/${self.$f7route.route.meta.boardType ||
          "swimlane"}/business/${
          self.$f7route.route.meta.businessCode
        }/board/${boardId}/action/${actionId}/project/${projectId}/estimate/contact/${contactId}/${estimateNumber}${
          self.activeTab ? `?activeTab=${self.activeTab}` : ""
        }`;

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true
        });
      });
    },

    onRadioChange(estimateId, value) {
      this.$emit("onUpdateEstimate", {
        id: estimateId,
        doc: {
          isDefault: value
        }
      });
    },

    toNewEstimate() {
      this.$emit("onCreateNewEstimate");
    },

    removeEstimate(estimate) {
      this.$emit("onUpdateEstimate", {
        id: estimate.id,
        doc: {
          projectId: ""
        }
      });
      this.$emit("onDeleteEstimate", { id: estimate.id });
    },

    showEstimateSumPopup() {
      this.$refs.estimateSumPopup.show();
    }
  }
};
</script>

<style lang="scss" scoped>
.po-text-color {
  color: var(--f7-theme-color);
}
</style>
