<template>
  <f7-popup tablet-fullscreen class="demo-popup" :opened="popupOpened" @popup:closed="cancel"
    @popup:opened="handlePopupOpened">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close @click.native="handleBack">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ isEdit ? "Edit" : "New" }} Invoice</f7-nav-title>
        <f7-nav-right>
          <f7-button outline @click="save()">Save </f7-button>
          <f7-button fill @click="completeInvoice()">Complete</f7-button>
        </f7-nav-right>
      </f7-navbar>
      <f7-list :inset="$device.desktop" :class="{
        'no-margin margin-top-half': !$device.desktop
      }">
        <!-- company info -->
        <div class="row">
          <!-- Block  Project/Job Name-->
          <div class="col-100 medium-50">
            <f7-list :inline-labels="$device.desktop" :class="{
              'no-margin margin-top': !$device.desktop,
              'margin-half': $device.desktop
            }" no-hairlines-md>
              <f7-list-input :disabled="isDisable ||
                ($f7route.params.projectId || hasInvoiceGroup
                  ? true
                  : false)
                " label="Project/Job Name" placeholder="Select Job"
                :value="newInvoiceGroup.jobName || 'Select a Project/Job'" type="text" readonly
                @click.native="selectProject" error-message-force
                :error-message="requireErrorMessageInvoiceGroup('jobName')">
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input :disabled="isDisable || hasInvoiceGroup" label="Property Name"
                placeholder="Type or select a Project/Job first" :value="newInvoiceGroup.propertyName" type="text"
                @change="
                  onChangeInvoiceProp('propertyName', $event.target.value)
                  ">
              </f7-list-input>
              <f7-list-input :disabled="isDisable || hasInvoiceGroup" label="Property Address"
                placeholder="Type or select a Project/Job first" :value="newInvoiceGroup.propertyAddress" type="text"
                @change="
                  onChangeInvoiceProp('propertyAddress', $event.target.value)
                  ">
              </f7-list-input>
            </f7-list>

            <!-- Date time -->
            <f7-list :inline-labels="$device.desktop" :class="{
              'no-margin margin-top': !$device.desktop,
              'margin-half': $device.desktop
            }" no-hairlines-md>
              <f7-list-input label="Invoice Date" type="datepicker" placeholder="MM/DD/YYYY" :calendar-params="{
                backdrop: true,
                openIn: 'customModal',
                header: true,
                footer: false,
                dateFormat: 'mm/dd/yyyy',
                disabled: {
                  to: new Date(new Date().getTime() - 86400000) // yesterdat
                }
              }" :value="newInvoice.invoiceDate || [new Date()]" @calendar:change="
                handleCalendarChange($event);
              $f7.calendar.close();
              " error-message-force :error-message="requireErrorMessageInvoice('invoiceDate')">
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input label="Payment Terms" placeholder="Select Payment Terms" :value="newInvoice.paymentTerm"
                type="select" @change="onSelectPaymentTerms($event.target.value)" error-message-force
                :error-message="requireErrorMessageInvoice('paymentTerm')">
                <required-asterisk slot="label" />
                <option value="">Select payment terms</option>
                <option v-for="item in PAYMENT_TERMS_OPTIONS" :key="item.id" :value="item.value">
                  {{ item.displayName }}
                </option>
              </f7-list-input>
              <f7-list-input v-show="newInvoice.paymentTerm" :disabled="newInvoice.paymentTerm !== 'custom'"
                label="Number of Days" placeholder="Enter Number of Days" :value="newInvoice.paymentTerm == 'custom'
                  ? numberOfDays
                  : newInvoice.paymentTerm
                  " @change="onChangeNumberOfDays($event.target.value)" type="number" :min="0" error-message-force
                :error-message="numberOfDaysErrorMessage">
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input label="Due Date" type="datepicker" placeholder="MM/DD/YYYY" :calendar-params="{
                backdrop: true,
                openIn: 'customModal',
                header: true,
                footer: false,
                dateFormat: 'mm/dd/yyyy',
                disabled: {
                  to: new Date(new Date().getTime() - 86400000) // yesterdat
                },
                on: {
                  change: (c, value) => handleSelectedDueDate(value),
                  opened: onCalendarOpened
                }
              }" :value="newInvoice.dueDate || []" error-message-force :error-message="dueDateErrorMessage">
                <required-asterisk slot="label" />
              </f7-list-input>
            </f7-list>
            <!-- End Date time -->
            <!-- Estimate  -->
            <f7-list :inline-labels="$device.desktop" :class="{
              'margin-half': $device.desktop,
              'no-margin margin-top': !$device.desktop
            }" no-hairlines-md>
              <f7-list-input placeholder="Select Estimate" :value="newInvoice.estimateIds && newInvoice.estimateIds.length
                ? getDisplayEstimateListName(newInvoice.estimateIds)
                : 'Select Estimate'
                " type="text" readonly @click.native="selectEstimate">
                <div slot="label" style="display: flex; align-items: center; gap: 4px">
                  <span>Estimate</span>
                  <f7-icon f7="info_circle_fill" size="14" class="cursor-pointer estimate-icon"></f7-icon>
                </div>
              </f7-list-input>

              <f7-list-input label="Table Content Type" type="select" :value="newInvoice.tableContentType"
                @change="changeTableContentType($event.target.value)">
                <option v-for="c in TABLE_CONTENT_TYPES" :key="c.value" :value="c.value">{{ c.name }}</option>
                <required-asterisk slot="label" />
              </f7-list-input>
            </f7-list>
            <!-- End Estimate  -->
          </div>
          <div class="col-100 medium-50">
            <!-- Roofing Info -->
            <f7-list :inline-labels="$device.desktop" :class="{
              'no-margin margin-top': !$device.desktop,
              'margin-half': $device.desktop
            }" no-hairlines-md>
              <f7-list-input :disabled="true" label="Roofing Company Name" placeholder="Enter Roofing Company Name"
                :value="newInvoiceGroup.roofingCompanyName" @change="
                  onChangeInvoiceGroupProp(
                    'roofingCompanyName',
                    $event.target.value
                  )
                  " type="text" error-message-force :error-message="requireErrorMessageInvoiceGroup('roofingCompanyName')
                    ">
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input :disabled="true" label="Address" placeholder="Enter Address"
                :value="newInvoiceGroup.roofingCompanyAddress" @change="
                  onChangeInvoiceGroupProp(
                    'roofingCompanyAddress',
                    $event.target.value
                  )
                  " type="text">
              </f7-list-input>
              <f7-list-input :disabled="true" label="Phone" placeholder="Enter Phone"
                :value="newInvoiceGroup.roofingCompanyPhone" @change="
                  onChangeInvoiceGroupProp(
                    'roofingCompanyPhone',
                    $event.target.value
                  )
                  " type="text">
              </f7-list-input>
              <f7-list-input :disabled="true" label="Email" placeholder="Enter Email"
                :value="newInvoiceGroup.roofingCompanyEmail" @change="
                  onChangeInvoiceGroupProp(
                    'roofingCompanyEmail',
                    $event.target.value
                  )
                  " type="text" error-message-force :error-message="roofingCompanyEmailErrorMessage()">
              </f7-list-input>

              <f7-list-input :disabled="true" label="Tax code" placeholder="Enter Tax code"
                :value="newInvoiceGroup.roofingCompanyTaxCode" @change="
                  onChangeInvoiceGroupProp(
                    'roofingCompanyTaxCode',
                    $event.target.value
                  )
                  " type="text" v-mask="'##-#######'" error-message-force
                :error-message="roofingCompanyTaxCodeErrorMessage()">
              </f7-list-input>
            </f7-list>
            <!-- End roofing Info -->

            <!-- Client info  -->
            <f7-list :inline-labels="$device.desktop" :class="{
              'no-margin margin-top': !$device.desktop,
              'margin-half': $device.desktop
            }" no-hairlines-md>
              <f7-list-input :disabled="isDisable || hasInvoiceGroup" label="Client Name"
                placeholder="Enter Client Name" :value="newInvoice.clientName"
                @change="onChangeInvoiceProp('clientName', $event.target.value)" type="text" error-message-force
                :error-message="requireErrorMessageInvoiceGroup('clientName')">
                <required-asterisk slot="label" />
              </f7-list-input>
              <f7-list-input :disabled="isDisable || hasInvoiceGroup" label="Address" placeholder="Enter Address"
                :value="newInvoice.clientAddress" @change="
                  onChangeInvoiceProp('clientAddress', $event.target.value)
                  " type="text">
              </f7-list-input>
              <f7-list-input :disabled="isDisable || hasInvoiceGroup" label="Phone" placeholder="Enter Phone"
                :value="newInvoice.clientPhoneNumber" @input="
                  onChangeInvoiceProp(
                    'clientPhoneNumber',
                    $event.target.value.trim()
                  )
                  " @blur="v$.newInvoice.clientPhoneNumber.$touch()" v-mask="'(+1) ###-###-####'" error-message-force
                :error-message="clientPhoneNumberErrorMessage()">
              </f7-list-input>
              <f7-list-input :disabled="(newInvoice.clientEmail !== '' && !isEditedEmail)"
                label="Email" placeholder="Enter Email" :value="newInvoice.clientEmail" @change="
                  onChangeInvoiceProp('clientEmail', $event.target.value)
                  isEditedEmail = true
                  " type="text" error-message-force :error-message="clientEmailErrorMessage()" 
                  @blur="v$.newInvoice.clientEmail.$touch()">
                <required-asterisk slot="label" />
              </f7-list-input>

              <f7-list-input :disabled="hasInvoiceGroup && newInvoice.clientCompanyTaxCode !== ''
                " label="Tax code" placeholder="Enter Tax code" :value="newInvoice.clientCompanyTaxCode" @change="
                  onChangeInvoiceProp(
                    'clientCompanyTaxCode',
                    $event.target.value
                  )
                  " type="text" v-mask="'##-#######'" error-message-force
                :error-message="clientCompanyTaxCodeErrorMessage()">
              </f7-list-input>
            </f7-list>
            <!-- End Client info  -->
          </div>
        </div>
      </f7-list>

      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <component :is="newInvoice.tableContentType === 'itemize'
          ? 'ItemizeTable'
          : newInvoice.tableContentType === 'simple-summary'
            ? 'SimpleSummaryTable'
            : 'LaborAndMaterialTable'
          "
          :items="newInvoice.itemDetails" 
          :invoiceDetail="newInvoice"
          :buildingList="dataBuildingList"
          ref="productItemTable" 
          @onChangeItems="
            onChangeInvoiceProp('itemDetails', $event);
          handleUpdateItemByTableType($event);
          setAdjustedTotalAmount();
          " @onSelectCategory="selectCategory($event)" @onChangeDiscountType="onChangeDiscountType($event)">
          <FooterTable slot="summary" :invoice="newInvoice" ref="summaryFooter"
            @onChangeSummaryFooter="onChangeSummaryFooter">
          </FooterTable>
        </component>
      </f7-block>

      <!-- Note -->
      <f7-block-title>Notes</f7-block-title>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <text-editor class="no-margin-horizontal" :data="newInvoice.notes"
          @change="onChangeInvoiceProp('notes', $event)" :isResizable="true" placeholder="Enter Notes"></text-editor>
      </f7-block>
      <!-- Term and Condition -->
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <text-editor :data="newInvoice.termsAndConditions"
          @change="onChangeInvoiceProp('termsAndConditions', $event)"></text-editor>
      </f7-block>
      <!-- Photo sections -->
      <f7-block-title>Before</f7-block-title>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <div class="row flex-start">
          <image-item v-for="item in newInvoice.beforeImages" :key="item.id" :photo="item"
            :projectId="newInvoiceGroup.projectId || ''" :selectedPhotos="newInvoice.beforeImages"
            @onDelete="photo => onDeletePhoto(photo, 'beforeImages')" @onChangePhotoProp="(prop, value) =>
              onChangePhotoProp(prop, value, item.id, 'beforeImages')
              "></image-item>
          <image-add-item :projectId="newInvoiceGroup.projectId || ''" :selectedPhotos="newInvoice.beforeImages"
            @onSelectPhotos="photos => onSelectPhotos(photos, 'beforeImages')"></image-add-item>
        </div>
      </f7-block>
      <f7-block-title>After</f7-block-title>
      <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
        <div class="row flex-start">
          <image-item v-for="item in newInvoice.afterImages" :key="item.id" :selectedPhotos="newInvoice.afterImages"
            :photo="item" :projectId="newInvoiceGroup.projectId || ''"
            @onDelete="photo => onDeletePhoto(photo, 'afterImages')" @onChangePhotoProp="(prop, value) =>
              onChangePhotoProp(prop, value, item.id, 'afterImages')
              "></image-item>
          <image-add-item :projectId="newInvoiceGroup.projectId || ''" :selectedPhotos="newInvoice.afterImages"
            @onSelectPhotos="photos => onSelectPhotos(photos, 'afterImages')"></image-add-item>
        </div>
      </f7-block>
    </f7-page>

    <project-list-popup ref="selectProject" @onSelected="onSelectedProject($event)"></project-list-popup>
    <select-estimate-popup ref="selectEstimate" :isMultiEst="true" @onSelected="onSelectedEstimate($event)"
      :selectedIds="newInvoice.estimateIds">
    </select-estimate-popup>

    <send-email-popup ref="sendEmailPopup" :cancelPopup="'Cancel'" @closePopup="cancel(); $emit('done')"></send-email-popup>
  </f7-popup>
</template>

<script>
import ProjectListPopup from "@/components/popups/ProjectListPopup.vue";
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, minLength, required } from "@vuelidate/validators";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters } from "vuex";
import { convertDateUTCToLocalTimeZone, toDateFirebase } from "../../../../utility/datetime";
import SummaryFooter from "../footer/SummaryFooter.vue";
import ImageAddItem from "../inputs/ImageAddItem.vue";
import ImageItem from "../inputs/ImageItem.vue";
import TextEditor from "../inputs/TextEditor.vue";
import SummaryTable from "../tables/SummaryTable.vue";
import SelectEstimatePopup from "./SelectEstimatePopup.vue";
import SendEmailPopup from "./SendEmailPopup.vue";

import { auth, firebase } from "@/services/firebase.service";
import { getFullAddress } from "@/utility/address";
import {
DEFAULT_STATUS_INVOICE,
TABLE_CONTENT_TYPES,
TABLE_CONTENT_TYPE_ITEMIZE,
TABLE_CONTENT_TYPE_LABOR_MATERIAL,
TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
VALIDATION_MESSAGE
} from "@/utility/const";

import commonMixins from "../../mixins/common-mixin";
import invoiceMixins from "../../mixins/invoice-mixin";
import { INCURRED_ITEM, PAYMENT_TERMS_OPTIONS } from "../../utility/const";

import FooterTable from "../tables/FooterTable.vue";
import ItemizeTable from "../tables/ItemizeTable.vue";
import LaborAndMaterialTable from "../tables/LaborAndMaterialTable.vue";
import SimpleSummaryTable from "../tables/SimpleSummaryTable.vue";

import summaryFooterMixins from "../../mixins/summary-footer-mixin";

const initFieldNewProductItem = Object.freeze({
  quantity: 1,
  discount: false,
  tax: false,
  isAutoAdded: true,
  crossProfitMarginRatio: 0,
  miscPercent: 0,
  discountAmount: 0,
  taxPercent: 0,
  taxAmount: 0
});

export default {
  props: {
    isEdit: Boolean,
    propProjectId: {
      type: String,
      default: ""
    },
    invoiceGroup: {
      type: Object,
      default: null
    },
    isDisable: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ProjectListPopup,
    TextEditor,
    ImageItem,
    ImageAddItem,
    SelectEstimatePopup,
    SummaryFooter,
    SendEmailPopup,
    SummaryTable,
    LaborAndMaterialTable,
    ItemizeTable,
    SimpleSummaryTable,
    FooterTable
  },

  mixins: [invoiceMixins, commonMixins, summaryFooterMixins],

  directives: { mask },

  data: () => {
    return {
      TABLE_CONTENT_TYPES,
      TABLE_CONTENT_TYPE_ITEMIZE,
      TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
      PAYMENT_TERMS_OPTIONS,
      popupOpened: false,
      originalInvoice: {},
      newInvoiceGroup: {
        projectId: "",
        projectNumber: "",
        propertyAddress: "",
        propertyName: "",
        roofingCompanyAddress: "",
        roofingCompanyName: "",
        roofingCompanyPhone: "",
        jobName: "",
        roofingCompanyTaxCode: ""
      },
      newInvoice: {
        discount: {
          type: "percent",
          value: 0
        },
        tax: {
          type: "percent",
          value: 0
        },
        shippingCharge: {
          type: "cash",
          value: 0
        },
        itemDetails: [],
        termsAndConditions: "",
        beforeImages: [],
        afterImages: [],
        notes: "",
        adjustedTotalAmount: 0,
        isEditedAmount: true,
        tableContentType: TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
      },
      redundantPhotos: [],
      crossProfitMarginRatio: 0,
      miscPercent: 0,
      project: {},
      tempItemsDetails: [],
      numberOfDays: 0,
      isSelectPaymentTerm: true,
      sortedSections: [],
      dataBuildingList: [],
      keyValueDict: {
        "Invoice Number": "&nbsp;",
        "Invoice Date": "&nbsp;",
        "Due Date": "&nbsp;",

        "Client Name": "",
        "Client Phone": "",
        "Client Address": "",
        "Client Email": "",
        "Client Company Tax Code": "&nbsp;",
        "Total Cost": "&nbsp;",
        "Payment Status": "&nbsp;",

        "Project Name": "&nbsp;",
        "Property Address": "&nbsp;",
        "Property Name": "&nbsp;",

        "Items Detail Table": "&nbsp;",

        Notes: "&nbsp;",
        "Terms And Conditions": "&nbsp;",
        "Project Photos": "&nbsp;",
        "Roofing Company Tax Code": "&nbsp;"
      },
      isEditedEmail: false
    };
  },

  mounted() {
    this.createTooltip();
  },
  
  created() { 
    if (_.isEmpty(this.objectList)) {
      this.getCategories();
    }
  },

  computed: {
    ...mapGetters("invoices/invoices", ["invoice"]),
    ...mapGetters("invoices/invoice-template", ["invoiceDefaultTemplate"]),

    ...mapGetters({
      setting: "setting/app/system/setting",
      estimateList: "invoices/estimate/estimateList",
      estimateById: "invoices/estimate/estimateById",
      projectPhotoList: "invoices/photo/projectPhotoList"
    }),
    ...mapGetters("common/template", ["invoiceTemplate"]),
    ...mapGetters("invoices/category", ["objectList"]),

    ...mapGetters('common/app-constant', ['tenantId']),

    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    dueDateErrorMessage() {
      if (!this.v$.newInvoice.dueDate.$error) return null;
      if (this.v$.newInvoice.dueDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (!this.v$.newInvoice.dueDate.timeRules)
        return VALIDATION_MESSAGE.DUE_DATE_GREATER_THAN_INVOICE_DATE;
      return null;
    },
    numberOfDaysErrorMessage() {
      if (!this.v$.numberOfDays.$error) return null;
      if (this.v$.numberOfDays.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    getDisplayEstimateListName() {
      return estimateIds => {
        return estimateIds
          .map(
            estimateId =>
              this.estimateList.find(r => r.id === estimateId)
                ?.estimateNumber || ""
          )
          .join(", ");
      };
    },
    isServiceType() {
      return (
        (
          this.estimateList.find(
            r =>
              r.id ==
              (this.newInvoice.estimateIds
                ? this.newInvoice.estimateIds[0]
                : "")
          ) || {}
        ).businessCode === "service"
      );
    },
    // this computed property is used to check if the invoice group has existed
    // for the case of invoice group has existed, we will disable the some fields
    hasInvoiceGroup() {
      return !!this.invoiceGroup?.id;
    },

    buildingListOptions() {
      let estimates = this.newInvoice.estimateIds.map(id =>
        this.estimateById(id)
      );

      let buildings = [];
      estimates.forEach(estimate => {
        if (estimate && estimate.buildings) {
          buildings = buildings.concat(estimate.buildings);
        }
      });
      return buildings.map(r => ({
        id: r.id,
        value: r.buildingName
      }));
    },
    isDisableCompanyTaxCodeInput() {
      if (!this.hasInvoiceGroup) return false;

      if (!this.company?.taxCode) return false;

      return true;
    },

    convertDataPhotos() {
      return (photos, isPhotoDeleted) => {
        return photos.map(e => {
          if (isPhotoDeleted) {
            return {
              id: e.id,
              photoFullPath: e.fullPath,
              notes: e.notes,
              photoUrl: e.photoUrl
            };
          }
          return {
            id: e.id,
            photoFullPath: e.photoFullPath,
            notes: e.notes,
            photoUrl: e.photoUrl,
            createdAt: e.createdAt,
            createdBy: e.createdBy
          };
        });
      };
    }
  },

  methods: {
    ...mapActions("invoices/invoice-group", [
      "createNewInvoiceGroup",
      "getInvoiceGroupByProjectId"
    ]),

    ...mapActions("invoices/invoices", [
      "createNewInvoice",
      "updateInvoice",
      "bindInvoice",
      "unbindInvoice"
    ]),

    ...mapActions("invoices/project", ["getProjectById"]),
    ...mapActions("invoices/property", ["getPropertyById"]),
    ...mapActions({
      bindSetting: "setting/app/system/bindSetting",
      getContactById: "invoices/contact/getContactById",
      getCompanyById: "invoices/company/getCompanyById"
    }),

    ...mapActions({
      bindInvoiceTemplateList:
        "invoices/invoice-template/bindInvoiceTemplateList",
      copyPhoto: "invoices/photo/copyPhoto",
      removePhoto: "invoices/photo/removePhoto"
    }),
    ...mapActions("invoices/category", ["getCategories"]),

    ...mapActions({
      bindSetting: "setting/app/system/bindSetting",
      bindEstimateListBys: "invoices/estimate/bindEstimateListBys",
      getBuilding: "invoices/estimate/getBuilding",
      getCompanyById: "invoices/company/getCompanyById",
      getContactById: "invoices/contact/getContactById"
    }),
    ...mapActions("common/template", ["getTemplateList"]),
    onCalendarOpened() {
      this.isSelectPaymentTerm = false;
    },
    handleSelectedDueDate(value) {
      this.onChangeInvoiceProp("dueDate", value);
      if (!this.isSelectPaymentTerm) {
        this.onChangeInvoiceProp("paymentTerm", "custom");
        let invoiceDate = this.newInvoice.invoiceDate[0];
        let dueDate = moment(value[0]);
        this.numberOfDays = dueDate
          .startOf("day")
          .diff(moment(invoiceDate).startOf("day"), "days");
      }
      this.$f7.calendar.close();
    },
    handleUpdateItemByTableType(event) {
      if (
        this.newInvoice.tableContentType !==
        TABLE_CONTENT_TYPE_SIMPLE_SUMMARY &&
        this.newInvoice.tableContentType !== TABLE_CONTENT_TYPE_LABOR_MATERIAL
      ) {
        this.onChangeTempItemDetails(event);
      }
    },
    onChangeNumberOfDays(value) {
      this.numberOfDays = value;
      let invoiceDate = this.newInvoice.invoiceDate[0];
      let dueDate = moment(invoiceDate).add(value, "days");
      this.onChangeInvoiceProp("dueDate", [dueDate.toDate()]);
    },

    handlePopupOpened() {
      if (this.propProjectId) {
        this.onSelectedProject(this.propProjectId);
      }
    },

    onSelectPaymentTerms(value) {
      const paymentTermValues = PAYMENT_TERMS_OPTIONS.map(r => r.value);
      if (paymentTermValues.includes(value)) {
        this.onChangeInvoiceProp("paymentTerm", value);
      }
      //get invoice date and plus value of payment term to get due date
      if (value !== "custom") {
        this.isSelectPaymentTerm = true;
        this.onChangeNumberOfDays(value);
      }
    },

    setDefaultBuilding() {
      let buildingIDs = this.buildingListOptions.map(r => r.id);
      this.changeBuildings(buildingIDs);
    },

    changeBuildings(values) {
      this.onChangeInvoiceProp("buildingIDs", values);
      this.getItemDetailFromBuilding(values);
    },

    changeTableContentType(value) {
      const oldType = this.newInvoice.tableContentType;

      if (oldType === TABLE_CONTENT_TYPE_ITEMIZE) {
        this.tempItemsDetails = this.newInvoice.itemDetails;
      }

      this.onChangeInvoiceProp("tableContentType", value);

      // if have EST
      if (this.newInvoice.estimateIds?.length) {
        this.getItemDetailsFromEstimate();
      } else {
        // if have not EST
        this.getItemDetailsFromInvoice();
      }
    },

    getFirstDataItem() {
      if (
        this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
      ) {
        const newServiceItem = this.getItemServiceInFirst();
        this.onChangeInvoiceProp("itemDetails", [newServiceItem]);
      }
    },

    getItemDetailsFromEstimate() {
      if (
        this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE &&
        this.tempItemsDetails.length
      ) {
        this.onChangeInvoiceProp("itemDetails", this.tempItemsDetails);
        return;
      }

      const estimateIds = this.newInvoice.estimateIds;
      const buildingIDs = this.newInvoice.buildingIDs;

      const promises = [];

      estimateIds.forEach(estimateId => {
        buildingIDs.forEach(buildingId => {
          promises.push(
            this.getBuilding({
              estimateId: estimateId,
              buildingId: buildingId
            })
          );
        });
      });

      Promise.all(promises).then(data => {
        this.dataBuildingList = data
        const dataBuilding = data.filter(r => !_.isEmpty(r)) || [];

        if (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE) {
          this.getItemDetailsForItemize(dataBuilding);
        }

        if (
          this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ) {
          if (!this.tempItemsDetails.length) {
            this.getItemDetailsForItemize(dataBuilding, true);
          }

          const priceTotal = this.getDataItemsLaborAndMaterial(
            this.tempItemsDetails
          );

          const serviceRow = {
            ...initFieldNewProductItem,
            adjustedItemAmount: 0,
            productName: "Service",
            priceWithProfitAndMisc: priceTotal.totalPriceWithProfitAndMisc,
            amount: priceTotal.totalPriceWithProfitAndMisc,
            netSales: priceTotal.totalPriceWithProfitAndMisc,
            intoMoney: priceTotal.totalIntoMoney,
            taxAmount: priceTotal.totalTaxAmount
          };

          this.onChangeInvoiceProp("isEditedAmount", true);
          this.onChangeInvoiceProp("itemDetails", [serviceRow]);
        }

        if (
          this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL
        ) {
          if (!this.tempItemsDetails.length) {
            this.getItemDetailsForItemize(dataBuilding, true);
          }
          this.getItemDetailsForLaborMaterial(dataBuilding);
        }
      });
    },

    setAdjustedTotalAmount() {
      this.onChangeInvoiceProp(
        "adjustedTotalAmount",
        this.totalInvoice(this.newInvoice)
      );
    },

    onChangeDiscountType(value) {
      this.onChangeInvoiceProp("discountType", value);
    },

    getItemDetailsForItemize(dataBuilding, isUpdateTempOnly = false) {
      let products = [];

      dataBuilding.forEach(building => {
        if (!_.isEmpty(building)) {
          let productList = this.getProductList(building);
          // filter out items that are missing required fields
          products = products.concat(
            productList
              .filter(r => {
                return r.productName && r.quantity && r.price;
              })
              .map(item => {
                const discountAmount = item.discountAmount || 0;
                const crossProfitMarginRatio = item.crossProfitMarginRatio || 0;

                const miscPercent =
                  item.category !== "Labor" ? item.miscPercent || 0 : 0;

                const priceWithProfitAndMisc = item.priceWithProfitAndMisc
                  ? item.priceWithProfitAndMisc
                  : item.price /
                  ((100 - (crossProfitMarginRatio + miscPercent)) / 100) ||
                  0;

                const amount =
                  (item.quantity || 1) * (priceWithProfitAndMisc || 0);

                const netSales = amount - (item.discountAmount || 0);

                const taxPercent = building.saleTax || 0;

                const intoMoney = netSales + (netSales * taxPercent) / 100;

                return {
                  ...item,
                  priceWithProfitAndMisc,
                  amount,
                  discountAmount,
                  taxPercent,
                  netSales,
                  intoMoney,
                  buildingId: building.id,
                  buildingName: building.buildingName
                };
              })
          );
        }
      });

      let itemManualAdded = this.tempItemsDetails.filter(r => !r.isAutoAdded);

      products = products.concat(itemManualAdded || []);

      if (isUpdateTempOnly) {
        this.tempItemsDetails = products;
      } else {
        this.onChangeInvoiceProp("itemDetails", products);
      }
    },

    getItemDetailsForLaborMaterial(dataBuilding) {
      const products = _.clone(this.tempItemsDetails);

      const items = [];

      // group product by buildingId
      dataBuilding.forEach(building => {
        const laborItems = [];

        const materialItems = [];

        const productsOfBuilding = products.filter(
          r => r.buildingId === building.id
        );

        // group product by type (labor/material)
        productsOfBuilding.forEach(item => {
          if (item.category === "Labor") {
            laborItems.push(item);
          } else {
            materialItems.push(item);
          }
        });

        const materialCost = this.getDataItemsLaborAndMaterial(materialItems);
        const laborCost = this.getDataItemsLaborAndMaterial(laborItems);

        const itemsBuilding = [
          {
            ...initFieldNewProductItem,
            productName: `Material Cost of ${building.buildingName}`,
            priceWithProfitAndMisc: materialCost.totalPriceWithProfitAndMisc,
            amount: materialCost.totalPriceWithProfitAndMisc,
            netSales: materialCost.totalPriceWithProfitAndMisc,
            intoMoney: materialCost.totalIntoMoney,
            taxPercent: building.saleTax
          },
          {
            ...initFieldNewProductItem,
            productName: `Labor Cost of ${building.buildingName}`,
            priceWithProfitAndMisc: laborCost.totalPriceWithProfitAndMisc,
            amount: laborCost.totalPriceWithProfitAndMisc,
            netSales: laborCost.totalPriceWithProfitAndMisc,
            intoMoney: laborCost.totalIntoMoney,
            taxPercent: building.saleTax
          }
        ];

        items.push(...itemsBuilding);
      });

      // handle item out building (add new)
      const itemsManualAdded = products.filter(r => {
        return !r.buildingId;
      });

      if (itemsManualAdded.length > 0) {
        const manualAddedCost = this.getDataItemsLaborAndMaterial(
          itemsManualAdded
        );
        items.push({
          ...initFieldNewProductItem,
          productName: INCURRED_ITEM,
          priceWithProfitAndMisc: manualAddedCost.totalPriceWithProfitAndMisc,
          amount: manualAddedCost.totalPriceWithProfitAndMisc,
          netSales: manualAddedCost.totalPriceWithProfitAndMisc,
          intoMoney: manualAddedCost.totalPriceWithProfitAndMisc
        });
      }

      this.onChangeInvoiceProp("itemDetails", items);
    },

    getDataItemsLaborAndMaterial(items) {
      if (!items.length)
        return {
          totalPriceWithProfitAndMisc: 0,
          taxPercent: 0,
          totalIntoMoney: 0
        };

      const taxPercent = items[0]?.taxPercent || 0;

      let totalPriceWithProfitAndMisc = items.reduce((total, item) => {
        return total + item.priceWithProfitAndMisc * item.quantity;
      }, 0);

      totalPriceWithProfitAndMisc = parseFloat(
        totalPriceWithProfitAndMisc.toFixed(2)
      );

      const totalTaxAmount = (totalPriceWithProfitAndMisc * taxPercent) / 100;

      const totalIntoMoney = totalPriceWithProfitAndMisc + totalTaxAmount;

      return {
        totalPriceWithProfitAndMisc,
        totalIntoMoney,
        taxPercent,
        totalTaxAmount
      };
    },

    getTotalPriceFromItemDetails(details) {
      if (!details || details.length === 0) return 0;
      return details.reduce((acc, cur) => {
        return acc + cur.priceWithProfitAndMisc * cur.quantity;
      }, 0);
    },

    getItemDetailsFromInvoice() {
      const type = this.newInvoice.tableContentType;

      if (type === TABLE_CONTENT_TYPE_ITEMIZE) {
        this.onChangeInvoiceProp("itemDetails", this.tempItemsDetails);
      }

      if (type === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY) {
        let dataItems = this.getDataItemsLaborAndMaterial(
          this.tempItemsDetails
        );

        const serviceRow = {
          ...initFieldNewProductItem,
          adjustedItemAmount: dataItems.totalPriceWithProfitAndMisc,
          productName: "Service",
          priceWithProfitAndMisc: dataItems.totalPriceWithProfitAndMisc,
          amount: dataItems.totalPriceWithProfitAndMisc,
          netSales: dataItems.totalPriceWithProfitAndMisc,
          intoMoney: dataItems.totalPriceWithProfitAndMisc
        };

        this.onChangeInvoiceProp("isEditedAmount", true);

        this.onChangeInvoiceProp("itemDetails", [serviceRow]);
      }

      if (type === TABLE_CONTENT_TYPE_LABOR_MATERIAL) {
        this.setItemLaborAndMateialFromInvoice();
      }
    },

    getItemServiceInFirst() {
      const serviceItem = this.newInvoice.itemDetails.find(
        item => item.productName === "Service"
      );

      const priceWithProfitAndMisc = serviceItem?.priceWithProfitAndMisc || 0;
      const amount = serviceItem?.amount || 0;
      const netSales = serviceItem?.netSales || 0;
      const intoMoney = serviceItem?.intoMoney || 0;
      const adjustedItemAmount = serviceItem?.adjustedItemAmount || 0;
      const discountValue = serviceItem?.discountValue || 0;
      const taxPercent = serviceItem?.taxPercent || 0;
      const taxAmount = serviceItem?.taxAmount || 0;
      const discountType = serviceItem?.discountType || "percent";

      return {
        ...initFieldNewProductItem,
        adjustedItemAmount,
        productName: "Service",
        priceWithProfitAndMisc,
        amount,
        netSales,
        intoMoney,
        discountValue,
        taxPercent,
        taxAmount,
        discountType
      };
    },

    setItemLaborAndMaterialFromInvoice() {
      const products = _.clone(this.tempItemsDetails);

      // in first show invoice in labor and material table type
      if (this.isEdit) {
        return;
      }

      const laborItems = [];

      const materialItems = [];

      // group product by type (labor/material)
      products.forEach(item => {
        if (item.category === "Labor") {
          laborItems.push(item);
        } else {
          materialItems.push(item);
        }
      });

      const materialCost = this.getDataItemsLaborAndMaterial(materialItems);
      const laborCost = this.getDataItemsLaborAndMaterial(laborItems);

      const itemsBuilding = [
        {
          ...initFieldNewProductItem,
          productName: `Material Cost`,
          priceWithProfitAndMisc: materialCost.totalPriceWithProfitAndMisc,
          amount: materialCost.totalPriceWithProfitAndMisc,
          netSales: materialCost.totalPriceWithProfitAndMisc,
          intoMoney: materialCost.totalPriceWithProfitAndMisc
        },
        {
          ...initFieldNewProductItem,
          productName: `Labor Cost`,
          priceWithProfitAndMisc: laborCost.totalPriceWithProfitAndMisc,
          amount: laborCost.totalPriceWithProfitAndMisc,
          netSales: laborCost.totalPriceWithProfitAndMisc,
          intoMoney: laborCost.totalPriceWithProfitAndMisc
        }
      ];

      this.onChangeInvoiceProp("itemDetails", itemsBuilding);
    },

    getItemDetailFromBuilding(buidingIds) {
      if (_.isEmpty(buidingIds)) {
        let itemManualAdded = this.newInvoice.itemDetails.filter(
          r => !r.isAutoAdded
        );

        this.onChangeInvoiceProp("itemDetails", itemManualAdded);

        return;
      }
      let products = [];

      let promises = [];

      this.newInvoice.estimateIds.forEach(estimateId => {
        buidingIds.forEach(buildingId => {
          promises.push(
            this.getBuilding({
              estimateId: estimateId,
              buildingId: buildingId
            })
          );
        });
      });

      Promise.all(promises).then(data => {
        const dataBuilding = data.filter(r => !_.isEmpty(r));

        if (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE) {
          (dataBuilding || []).forEach(building => {
            if (!_.isEmpty(building)) {
              const saleTax = building.saleTax || 0;

              let productList = this.getProductList(building);
              // filter out items that are missing required fields
              products = products.concat(
                productList
                  .filter(r => {
                    return r.productName && r.quantity && r.price;
                  })
                  .map(productItem => {
                    return {
                      ...productItem,
                      taxPercent: saleTax,
                      buildingId: building.id,
                      buildingName: building.buildingName
                    };
                  })
              );
            }
          });

          // keep items manual added
          let itemManualAdded = this.newInvoice.itemDetails.filter(
            r => !r.isAutoAdded
          );

          products = products.concat(itemManualAdded || []);

          this.onChangeInvoiceProp("itemDetails", products);
        } else {
          // get estimate material cost
          let estimates = this.newInvoice.estimateIds.map(
            id => this.estimateById(id) || {}
          );

          let itemManualAdded = this.newInvoice.itemDetails.filter(
            r => !r.isAutoAdded
          );

          let combinedSummary = [...itemManualAdded];

          estimates.forEach(estimate => {
            let filteredBuildings = (dataBuilding || []).filter(
              building => building.estimateId === estimate.id
            );

            if (filteredBuildings.length > 0) {
              let summary = this.summaryEstimate(
                this.project,
                filteredBuildings,
                estimate
              );

              combinedSummary = combinedSummary.concat(summary);
            }
          });

          this.onChangeInvoiceProp("itemDetails", combinedSummary);
        }
      });
    },

    selectCategory(data) {
      const itemDetails = _.cloneDeep(this.newInvoice.itemDetails);

      if (itemDetails?.length > data.index) {
        itemDetails[data.index].category = data.categoryName;
      }

      this.onChangeInvoiceProp("itemDetails", itemDetails);
    },

    getProductList(building) {
      const estimate = this.estimateById(building.estimateId) || {};

      const doesApplyTaxForMaterial = this.doesApplyTaxForMaterial(
        this.project,
        building,
        estimate
      );

      const doesApplyTaxForLabor = this.doesApplyTaxForLabor(
        this.project,
        building,
        estimate
      );

      let products = [];

      (building.productData || []).forEach(element => {
        let sectionProduct = (element.productList || [])
          .filter(r => !!r.actualQty)
          .map(r => {
            const priceWithProfitAndMisc =
              r.price /
              ((100 -
                (estimate.crossProfitMarginRatio ||
                  0 + estimate.miscPercent ||
                  0)) /
                100) || 0;

            const amount =
              (this.orderQty(r, building) || 1) * (priceWithProfitAndMisc || 0);

            let discountValue = 0;

            const discountAmount =
              this.discountType === "percent"
                ? amount * (discountValue / 100)
                : discountValue;

            const netSales = amount - discountAmount;

            const taxPercent = building.saleTax || 0;

            const intoMoney = netSales + (netSales * taxPercent) / 100;

            return {              
              id: r.id,
              productName: r.productItem,
              quantity: this.orderQty(r, building),
              price: r.price || 0,
              unit: r.uom || "",
              category: element.category,
              discount: false,
              tax:
                element.category === "Labor"
                  ? doesApplyTaxForLabor
                  : doesApplyTaxForMaterial,
              isAutoAdded: true,
              crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
              miscPercent: estimate.miscPercent || 0,
              priceWithProfitAndMisc,
              discountValue,
              netSales,
              intoMoney,
              amount,
              discountAmount
            };
          });

        products = products.concat(sectionProduct);
      });

      return products;
    },

    handleBack() {
      if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
        this.navigateToBoard();
      } else {
        // remove before photos
        let promises = [];
        const orginalBeforeImages = this.originalInvoice.beforeImages || [];
        const newBeforeImages = this.newInvoice.beforeImages || [];
        const beforeImagesRemove = _.differenceWith(
          newBeforeImages,
          orginalBeforeImages,
          _.isEqual
        );
        // remove after photos
        const orginalAfterImages = this.originalInvoice.afterImages || [];
        const newAfterImages = this.newInvoice.afterImages || [];
        const afterImagesRemove = _.differenceWith(
          newAfterImages,
          orginalAfterImages,
          _.isEqual
        );
        // remove redundant images
        const redundantImagesRemove = _.differenceWith(
          this.redundantPhotos,
          orginalBeforeImages.concat(orginalAfterImages),
          _.isEqual
        );
        const itemsRemove = beforeImagesRemove.concat(
          afterImagesRemove,
          redundantImagesRemove
        );
        for (const photo of itemsRemove) {
          promises.push(this.removePhoto(photo.fullPath));
        }

        Promise.all(promises);
        this.cancel(!!this.newInvoice?.id, this.newInvoice?.id || "");
      }
    },

    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: ".setup-email-icon",
        text: "Auto-sends at 8:00 AM on invoice date"
      });
      this.$f7.tooltip.create({
        targetEl: ".estimate-icon",
        text: "estimate icon tooltip"
      });
    },

    async onSelectPhotos(photos, propName) {
      this.$f7.preloader.show();
      let items = this.newInvoice[propName] || [];
      //Get all image ids in project
      const allIdsPhotoByProject = this.projectPhotoList.map(r => r.id);

      const beforeIds = items.map(r => r.id);
      const afterIds = photos.map(r => r.id);

      //Find list of deleted photo ids on roofing cam
      const idsPhotoDeleted = _.differenceWith(
        beforeIds,
        allIdsPhotoByProject,
        _.isEqual
      );

      //Recover deleted photo information
      const photoDeletedList = this.convertDataPhotos(
        items.filter(img => idsPhotoDeleted.includes(img.id)),
        true
      );

      //Create a new photo list including selected photos and deleted photos
      const newPhotosList = this.convertDataPhotos(photos, false).concat(
        photoDeletedList
      );

      const idsRemove = _.differenceWith(beforeIds, afterIds, _.isEqual);

      const idsAdd = _.differenceWith(afterIds, beforeIds, _.isEqual).concat(
        idsPhotoDeleted
      );

      let promises = [];

      // delete photo in storage
      for (const id of idsRemove) {
        const photo = items.find(r => r.id === id);
        this.redundantPhotos.push(photo);
        items = _.remove(items, item => item.id != id);
      }

      // add photo
      for (const id of idsAdd) {
        const photo = newPhotosList.find(r => r.id === id);
        promises.push(
          this.copyPhoto(photo).then(({ fullPath, url }) => {
            items.push({
              id: photo.id,
              photoUrl: url,
              fullPath: fullPath,
              notes: photo.notes,
              createdAt: photo.createdAt,
              createdBy: photo.createdBy
            });
          })
        );
      }

      Promise.all(promises).then(() => {
        this.onChangeInvoiceProp(propName, items);
        this.$f7.preloader.hide();
      });
    },

    onDeletePhoto(photo, propName) {
      //remote photo from storage
      this.redundantPhotos.push(photo);
      let items = this.newInvoice[propName] || [];
      items = _.remove(items, item => item.id != photo.id);
      this.onChangeInvoiceProp(propName, items);
    },

    onChangePhotoProp(prop, value, id, invoiceProp) {
      let items = this.newInvoice[invoiceProp] || [];
      let index = items.findIndex(r => r.id == id);
      let photo = { ...items[index] };
      if (!photo) return;
      photo[prop] = value;
      Vue.set(items, index, photo);
      this.onChangeInvoiceProp(invoiceProp, items);
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: true,
          closeTimeout: 5000
        })
        .open();
    },
    async initKeyData() {
      if (!_.isEmpty(this.newInvoice)) {
        if (this.newInvoice.invoiceNumber) {
          this.keyValueDict["Invoice Number"] = this.newInvoice.invoiceNumber;
        }
        if (this.newInvoice.invoiceDate) {
          this.keyValueDict["Invoice Date"] = toDateFirebase(
            this.newInvoice.invoiceDate
          );
        }
        if (this.newInvoice.dueDate) {
          this.keyValueDict["Due Date"] = toDateFirebase(
            this.newInvoice.dueDate
          );
        }
        if (this.newInvoice.clientName) {
          this.keyValueDict["Client Name"] = this.newInvoice.clientName;
        }
        if (this.newInvoice.clientAddress) {
          this.keyValueDict["Client Address"] = this.newInvoice.clientAddress;
        }
        if (this.newInvoice.clientPhoneNumber) {
          this.keyValueDict["Client Phone"] = this.newInvoice.clientPhoneNumber;
        }
        if (this.newInvoice.clientEmail) {
          this.keyValueDict["Client Email"] = this.newInvoice.clientEmail;
        }
        if (this.newInvoice.clientCompanyTaxCode) {
          this.keyValueDict[
            "Client Company Tax Code"
          ] = this.newInvoice.clientCompanyTaxCode;
        }
        if (this.invoiceGroup && this.invoiceGroup.roofingCompanyTaxCode) {
          this.keyValueDict[
            "Roofing Company Tax Code"
          ] = this.invoiceGroup.roofingCompanyTaxCode;
        }
        if (this.newInvoice) {
          const totalCost = this.totalInvoice(this.newInvoice);
          const formattedTotalCost = Vue.filter("currencyUSD")(totalCost);
          this.keyValueDict["Total Cost"] = formattedTotalCost;
        }
        if (this.paymentStatus) {
          this.keyValueDict[
            "Payment Status"
          ] = `<span style="border: 1px solid;border-radius: 9999px;padding: 3px 8px 3px 8px;font-weight: 700;border-color: ${this.paymentStatus.color}; background-color: ${this.paymentStatus.color}">${this.paymentStatus.text}</span>`;
        }
        if (this.newInvoiceGroup && this.newInvoiceGroup.jobName) {
          this.keyValueDict["Project Name"] =
            this.newInvoiceGroup && this.newInvoiceGroup.jobName;
        }
        if (this.newInvoiceGroup && this.newInvoiceGroup.propertyAddress) {
          this.keyValueDict["Property Address"] =
            this.newInvoiceGroup && this.newInvoiceGroup.propertyAddress;
        }
        if (this.newInvoiceGroup && this.newInvoiceGroup.propertyName) {
          this.keyValueDict["Property Name"] =
            this.newInvoiceGroup && this.newInvoiceGroup.propertyName;
        }
        if (this.newInvoice.termsAndConditions) {
          this.keyValueDict[
            "Terms And Conditions"
          ] = this.newInvoice.termsAndConditions;
        }
        if (this.newInvoice.notes) {
          this.keyValueDict["Notes"] = this.newInvoice.notes;
        }
        // if (this.newInvoice.Notes) {
        //   this.keyValueDict["Project Photos"] =
        //   this.newInvoice.Notes;
        // }
      }
    },

    async compileSectionData() {
      await this.initKeyData();

      if (!this.invoiceTemplate) return [];

      const templateSections = _.cloneDeep(this.invoiceTemplate.sections).sort(
        (a, b) => a.index - b.index
      );

      const desSections = [];

      for (const orgSection of templateSections) {
        desSections.push(this.compileSection(orgSection));
      }

      return desSections;
    },
    compileSection(orgSection) {
      let htmlValue = this.parseHtmlMetaContent(
        _.cloneDeep(orgSection.htmlValue)
      );
      return {
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: orgSection.sectionName,
        index: orgSection.index
      };
    },

    parseHtmlMetaContent(value) {
      if (!value) return "";
      if (typeof value === "object") return value;
      let keys = Object.keys(this.keyValueDict);
      keys.forEach(key => {
        if (key === "Items Detail Table") {
          let rows = "";
          let rowsNetSales = "";
          let rowsTax = "";

          const displayItems = _.cloneDeep(this.newInvoice.itemDetails || []);
          const taxList = this.getTaxList(this.newInvoice || {}) || [];
          const totalNetSales = taxList.reduce(
            (acc, item) => acc + item.netSalesAmount,
            0
          );
          const totalTax = taxList.reduce(
            (acc, item) => acc + item.taxAmount,
            0
          );
          const totalDiscount = this.getTotalDiscount(this.newInvoice) || 0;
          const shippingChargeType =
            this.newInvoice &&
              this.newInvoice.shippingCharge &&
              this.newInvoice.shippingCharge.type === "cash"
              ? "$"
              : "%";
          // Render Total Net Sales
          for (let index = 0; index < taxList.length; index++) {
            let taxItem = taxList[index];
            rowsNetSales += `
              <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                <div style="width: 40%;">Total Net Sales ${taxItem.taxName
              }</div>
                <div style="width: 50%; text-align: right;">$${taxItem.netSalesAmount.toFixed(
                2
              )}</div>
              </div>
            `;
          }

          // Render Total Tax
          for (let index = 0; index < taxList.length; index++) {
            let taxItem = taxList[index];
            rowsTax += `
              <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                <div style="width: 40%;">Total Tax ${taxItem.taxName}</div>
                <div style="width: 50%; text-align: right;">$${taxItem.taxAmount.toFixed(
              2
            )}</div>
              </div>
            `;
          }

          for (let index = 0; index < displayItems.length; index++) {
            let product = displayItems[index];
            rows +=
              `<tr style="border-bottom: 1px solid #e5e7f2; font-size:14px">` +
              `<td style="padding: 5px;">${product.productName}</td>` +
              (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                ? `<td style="text-align: right;padding: 5px;">${product.category}</td>`
                : "") +
              (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                ? `<td style="text-align: right;padding: 5px;">${product.quantity}</td>`
                : "") +
              (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                ? `<td style="text-align: right;padding:5px">${Vue.filter(
                  "currencyUSD"
                )(product.priceWithProfitAndMisc)}</td>`
                : "") +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                "currencyUSD"
              )(product.amount)}</td>` +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                "currencyUSD"
              )(product.discountAmount)}</td>` +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                "currencyUSD"
              )(product.netSales)}</td>` +
              `<td style="text-align: right;padding:5px;">${(product.taxPercent
                ? product.taxPercent.toLocaleString("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
                : "0") + "%"}</td>` +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                "currencyUSD"
              )(product.intoMoney)}</td>`;
          }
          const htmlValue =
            `<table style="width: 100%; border: 0px; border-collapse:collapse;box-shadow:0px 1px 2px rgba(0,0,0,0.15);border-radius:4px">
        <tr style="background-color: #a8a8a8; color: white; font-weight:500; font-size: 14px;">` +
            `<th style="text-align: left; padding: 5px;">Item Details</th>` +
            (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
              ? `<th style="text-align: right; padding: 5px;">Category</th>`
              : "") +
            (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
              ? `<th style="text-align: right; padding: 5px;">Quantity</th>`
              : "") +
            (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
              ? `<th style="text-align: right; padding: 5px;">Price</th>`
              : "") +
            `<th style="text-align: right; padding: 5px;">Amount</th>` +
            `<th style="text-align: right; padding: 5px;">Discount</th>` +
            `<th style="text-align: right; padding: 5px;">Net Sales</th>` +
            `<th style="text-align: right; padding: 5px;">Tax</th>` +
            `<th style="text-align: right; padding: 5px;">Total</th>` +
            `</tr>
        ${rows}
        </table>
        <div style="display: flex; justify-content: flex-end; font-size:14px">
          <div style="padding-top: 20px; width:40%;">
            <!-- net sale -->
            ${rowsNetSales}
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Net Sales</div>
              <div style="width: 50%; text-align: right;">$${totalNetSales.toFixed(
              2
            )}</div>
            </div>

            <!-- tax -->
            ${rowsTax}
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Tax</div>
              <div style="width: 50%; text-align: right;">$${totalTax.toFixed(
              2
            )}</div>
            </div>

            <!-- discount -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Discount</div>
              <div style="width: 50%; text-align: right;">$${totalDiscount.toFixed(
              2
            )}</div>
            </div>

            <!-- shipping charge -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Shipping Charge</div>
              <div style="width: 20%;text-align: right;">${
                shippingChargeType === "%"
                  ? this.newInvoice.shippingCharge &&
                    this.newInvoice.shippingCharge.value + "%"
                  : "$" + this.newInvoice.shippingCharge &&
                    this.newInvoice.shippingCharge.value
              }</div>
              <div style="width: 30%; text-align: right;">$${this.getShippingChargeValue(
                this.newInvoice
              )}</div>
            </div>

            <!-- total -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;"><b>Total</b></div>
              <div style="width: 50%; text-align: right;"><b>$${Vue.filter(
                "currencyUSD"
              )(this.totalInvoice(this.newInvoice))}</b></div>
            </div>
          </div>
        </div>`;
          value = value.replace(
            `<span style="background-color: #f05034;">[${key}]</span>`,
            htmlValue
          );
        } else {
          value = value.replaceAll(
            `<span style="background-color: #f05034;">[${key}]</span>`,
            this.keyValueDict[key]
          );
          if (
            key === "Client Company Tax Code" &&
            !this.newInvoice.clientCompanyTaxCode
          ) {
            const searchValue = `<div>Tax code:&nbsp;</div>`;
            const replaceValue = ``;

            value = this.replaceKeyInContent(value, searchValue, replaceValue);
          }
        }
      });
      return value;
    },

    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },

    convertDateTime(date) {
      return moment(new Date(this.dateToValue(date))).toDate();
    },

    replaceKeyInContent(value, searchValue, replaceValue) {
      const firstIndex = value.indexOf(searchValue);
      if (firstIndex === -1) {
        return value;
      }

      const secondIndex = value.indexOf(
        searchValue,
        firstIndex + searchValue.length
      );
      if (secondIndex === -1) {
        return value;
      }

      return (
        value.slice(0, secondIndex) +
        replaceValue +
        value.slice(secondIndex + searchValue.length)
      );
    },

    // handle open popup and init data
    async open(id, isNeedBindDetail = false) {
      this.popupOpened = true;
      this.$f7.preloader.show();
      if (_.isEmpty(this.invoiceTemplate)) {
        await this.getTemplateList();
      }
      if (this.invoiceGroup?.id) {
        // if invoice group has existed
        this.newInvoice.jobName = this.invoiceGroup.jobName || "";
        this.newInvoice.projectId = this.invoiceGroup.projectId || "";
        this.newInvoiceGroup = {
          ...this.newInvoiceGroup,
          ...this.invoiceGroup
        };
        await this.onSelectedProject(this.invoiceGroup.projectId);
      }

      if (id) {
        // for edit invoice
        if (isNeedBindDetail) {
          await this.bindInvoice(id);
        }
        this.sortedSections = _.cloneDeep(this.invoice.sections);
        const currentInvoice = _.cloneDeep(this.invoice);
        const invoiceDate = this.invoice.invoiceDate.toDate
          ? new Date(convertDateUTCToLocalTimeZone(this.invoice.invoiceDate.toDate()))
          : new Date();
        const dueDate = this.invoice.dueDate.toDate
          ? this.invoice.dueDate.toDate()
          : new Date(this.invoice.dueDate);
        this.newInvoice = {
          ...currentInvoice,
          invoiceDate: [invoiceDate],
          dueDate: [dueDate],
          clientCompanyTaxCode: this.newInvoice.clientCompanyTaxCode || ""
        };
        if (this.invoice.paymentTerm === "custom") {
          const timeDiff = dueDate - invoiceDate;
          const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          this.onChangeNumberOfDays(daysDiff);
        } else {
          this.onChangeNumberOfDays(Number(this.invoice.paymentTerm));
        }
        this.originalInvoice = _.cloneDeep(this.newInvoice);
      } else {
        //for new invoice
        const refs = [];
        if (_.isEmpty(this.setting)) {
          refs.push(this.bindSetting(this.tenantId));
        }
        if (_.isEmpty(this.invoiceDefaultTemplate)) {
          refs.push(this.bindInvoiceTemplateList());
        }
        await Promise.all(refs);

        //populate data for brand new invoice
        this.onChangeInvoiceGroupProp(
          "roofingCompanyName",
          this.setting ? this.setting.companyName : ""
        );
        this.onChangeInvoiceGroupProp(
          "roofingCompanyAddress",
          this.setting ? this.setting.address : ""
        );
        this.onChangeInvoiceGroupProp(
          "roofingCompanyPhone",
          this.setting ? this.setting.phoneNumber : ""
        );
        this.onChangeInvoiceGroupProp(
          "roofingCompanyEmail",
          this.setting ? this.setting.companyEmail : ""
        );
        this.onChangeInvoiceGroupProp(
          "roofingCompanyTaxCode",
          this.setting ? this.setting.companyTaxCode : ""
        );
        //from templte
        this.onChangeInvoiceProp("logo", this.invoiceDefaultTemplate.logo);
        let userEmail = auth.currentUser.email || "example@email.com";
        let userName = auth.currentUser.displayName || "{ User Name }";
        let termsAndConditions = this.invoiceDefaultTemplate.termsAndConditions.replace(
          "{user email}",
          userEmail
        );
        termsAndConditions = termsAndConditions.replace(
          "{user name}",
          userName
        );
        this.onChangeInvoiceProp("termsAndConditions", termsAndConditions);

        this.onChangeInvoiceProp(
          "tableContentType",
          TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        );
        this.onChangeInvoiceProp("discountType", "percent");
      }

      // handle items data
      this.getFirstDataItem();

      this.createTooltip();
      this.$f7.preloader.hide();
    },

    cancel(reloadOnClosed = true, bindingInvoiceId = null) {
      this.popupOpened = false;

      this.originalInvoice = {};
      this.newInvoiceGroup = {
        projectId: "",
        projectNumber: "",
        propertyAddress: "",
        propertyName: "",
        roofingCompanyAddress: "",
        roofingCompanyName: "",
        roofingCompanyPhone: "",
        jobName: "",
        roofingCompanyTaxCode: ""
      };
      this.newInvoice = {
        discount: {
          type: "percent",
          value: 0
        },
        tax: {
          type: "percent",
          value: 0
        },
        shippingCharge: {
          type: "cash",
          value: 0
        },
        itemDetails: [],
        termsAndConditions: "",
        beforeImages: [],
        afterImages: [],
        clientCompanyTaxCode: "",
        notes: "",
        isEditedAmount: true,
        tableContentType: TABLE_CONTENT_TYPE_ITEMIZE
      };
      this.redundantPhotos = [];
      this.crossProfitMarginRatio = 0;
      this.miscPercent = 0;
      this.numberOfDays = 0;
      this.project = {};
      this.sortedSections = [];
      this.company = {};
      this.tempItemsDetails = [];
      this.isEditedEmail = false;
      this.isEdit = false;
      // this.unbindInvoice();
      this.newInvoice.notes = "";
      if (typeof reloadOnClosed === "boolean") {
        this.$emit("onClose", {
          reloadOnClosed,
          invoiceId: bindingInvoiceId
        });
      }

      this.v$.$reset();
    },

    selectProject() {
      this.$refs.selectProject.open();
    },

    selectEstimate() {
      if (this.newInvoiceGroup.projectId) {
        this.$refs.selectEstimate.open(this.newInvoiceGroup.projectId);
      }
    },

    async onSelectedProject(projectId) {
      this.$f7.preloader.show();
      // get info
      let project = await this.getProjectById(projectId);

      // get company info
      this.project = project || {};
      let companyId = project.companyId || "";
      let company = companyId ? await this.getCompanyById(companyId) : {};
      this.company = _.cloneDeep(company);

      let projectAddress = project.projectAddress;
      let companyMap = {};
      if (company) {
        let { addresses, phones, others } = company;
        companyMap = {
          companyName: company ? company.companyName : "",
          address: getFullAddress((addresses || [])[0]),
          phoneNumber: ((phones || [])[0] || {}).value || "",
          email: (others || []).find(r => r.code == "email")
            ? (others || []).find(r => r.code == "email").value
            : "",
          companyTaxCode: company.companyTaxCode || ""
        };
      }
      // get contact info
      let contactId = project.contactId || "";
      let contact = contactId ? await this.getContactById(contactId) : null;
      let contactMap = {};
      if (contact) {
        let { addresses, phones, emails } = contact;
        contactMap = {
          contactName: contact
            ? `${contact.firstName} ${contact.lastName}`
            : "",
          address: getFullAddress((addresses || [])[0]),
          phoneNumber: ((phones || [])[0] || {}).value || "",
          email: (emails || []).find(r => r.code == "main")
            ? (emails || []).find(r => r.code == "main").value
            : ""
        };
      }
      // get property info
      let propertyId = project.propertyId || "";
      let property = propertyId ? await this.getPropertyById(propertyId) : null;
      let propertyMap = {};
      if (property) {
        let { addresses } = property;
        propertyMap = {
          propertyName: property ? property.propertyName : "",
          address: getFullAddress((addresses || [])[0])
        };
      }

      let invoiceGroup = await this.getInvoiceGroupByProjectId(projectId);

      // if client is company get from company first otherwise get from contact
      this.newInvoiceGroup = {
        ...this.newInvoiceGroup,
        projectId,
        projectNumber: project.cardNumber,
        jobName: project.title || "",

        propertyName:
          propertyMap.propertyName || getFullAddress(projectAddress) || "",
        propertyAddress:
          propertyMap.address || getFullAddress(projectAddress) || "",
        id: invoiceGroup?.id || "",
        clientName: companyMap.companyName || contactMap.contactName || ""
      };

      this.newInvoice = {
        ...this.newInvoice,
        jobName: project.title || "",
        projectId: projectId,
        clientCompanyId: company && company.id ? company.id : "",
        clientContactId: contact && contact.id ? contact.id : "",
        clientName: companyMap.companyName || contactMap.contactName || "",
        clientAddress: companyMap.address || contactMap.address || "",
        clientPhoneNumber:
          companyMap.phoneNumber || contactMap.phoneNumber || "",
        clientEmail: companyMap.email || contactMap.email || "",
        clientCompanyTaxCode: companyMap.companyTaxCode || ""
      };
      this.isEditedEmail = false;
      // this.newInvoice.isEditedAmount = false;
      this.$f7.preloader.hide();
    },

    async onSelectedEstimate(estimateIds) {
      this.onChangeInvoiceProp("estimateIds", estimateIds);
      // this.newInvoice.isEditedAmount = false;
      // if (this.newInvoice.tableContentType !== TABLE_CONTENT_TYPE_ITEMIZE) {
      //   this.onChangeInvoiceProp(
      //     "tableContentType",
      //     TABLE_CONTENT_TYPE_ITEMIZE
      //   );
      // }
      this.setDefaultBuilding();

      this.getItemDetailsFromEstimate();
      return;
    },

    ...mapActions("invoices/estimate", [
      "clearEstimateList",
      "getEstimateListByProjectId"
    ]),
    isStringNumber(str) {
      return !isNaN(str) && !isNaN(parseFloat(str));
    },
    onChangeInvoiceProp(prop, value) {
      Vue.set(this.newInvoice, prop, value);
    },

    onChangeInvoiceGroupProp(prop, value) {
      Vue.set(this.newInvoiceGroup, prop, value);
    },

    onChangeSummaryFooter({ prop, subprop, value }) {
      let fieldDetail = this.newInvoice[prop];
      fieldDetail = {
        ...fieldDetail,
        [subprop]: value
      };
      Vue.set(this.newInvoice, prop, fieldDetail);
      this.setAdjustedTotalAmount();
    },

    validate() {
      let isProductItemsValid = this.$refs.productItemTable.validate();
      this.v$.$touch();

      if (this.v$.$invalid || !isProductItemsValid) {
        return false;
      }
      return true;
    },

    // save invoice
    async save() {
      if (!this.validate()) return;
      const { newInvoice, newInvoiceGroup } = await this.getDateBeforeSave();

      this.$f7.preloader.show();
      this.createOrUpdate(newInvoice, newInvoiceGroup).then(async doc => {
        this.$f7.preloader.hide();
        // remove redundant photos
        for (const photo of this.redundantPhotos) {
          this.removePhoto(photo.fullPath);
        }
        this.redundantPhotos = []
        if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
          this.navigateToBoard();
        }
        this.cancel(true, doc.id);
        this.$emit("done");
      });
    },

    async completeInvoice() {
      if (!this.validate()) return;
      const { newInvoice, newInvoiceGroup } = await this.getDateBeforeSave();
      const currentDate = moment(new Date(), "MM/DD/YYYY");
      const invoiceDate = moment(newInvoice.invoiceDate, "MM/DD/YYYY");
      const invoiceDays = currentDate.diff(invoiceDate, "day");
      const showPreloader = () => {
        if (
          currentDate.isAfter(invoiceDate) &&
          currentDate.day() !== invoiceDate.day()
        ) {
          this.$f7.dialog.preloader(
            "Changing invoice date to today. Please wait..."
          );
        } else {
          this.$f7.preloader.show();
        }
      };
      const hidePreloader = () => {
        if (
          currentDate.isAfter(invoiceDate) &&
          currentDate.day() !== invoiceDate.day()
        ) {
          this.$f7.dialog.close();
        } else {
          this.$f7.preloader.hide();
        }
      };

      const handleCreateOrUpdate = async () => {
        const doc = await this.createOrUpdate(
          newInvoice,
          newInvoiceGroup,
          true
        );
        if (doc && doc.id) {
          await this.bindInvoice(doc.id);
          const currentInvoice = _.cloneDeep(this.invoice);
          this.newInvoice = {
            ...currentInvoice,
            invoiceDate: this.newInvoice.invoiceDate,
            dueDate: this.newInvoice.dueDate
          };
          this.$emit("setIsEdit");
        }
        hidePreloader();
        // Remove redundant photos
        for (const photo of this.redundantPhotos) {
          this.removePhoto(photo.fullPath);
        }
        this.redundantPhotos = []

        this.onChangeInvoiceProp("id", doc.id);

        // Check if invoice date is today
        if (
          (invoiceDays === 0 && currentDate.day() === invoiceDate.day()) ||
          (currentDate.isAfter(invoiceDate) &&
            currentDate.day() !== invoiceDate.day())
        ) {
          const timeConvert = new firebase.firestore.Timestamp.fromDate(new Date(convertDateUTCToLocalTimeZone(moment(doc.invoiceDate).toDate())))
          this.onSentEmail({...doc, invoiceDate: timeConvert});
        } else {
          this.showDialogNextAction(doc, newInvoice, invoiceDate);
        }

        if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
          this.navigateToBoard();
        }
      };
      showPreloader();

      if (
        currentDate.isAfter(invoiceDate) &&
        currentDate.day() !== invoiceDate.day()
      ) {
        setTimeout(handleCreateOrUpdate, 1500);
      } else {
        handleCreateOrUpdate();
      }
      this.$emit("done");
    },
    handleCalendarChange(event) {
      this.onChangeInvoiceProp("invoiceDate", event);
      if (this.newInvoice.paymentTerm) {
        this.onChangeNumberOfDays(this.numberOfDays);
      }
    },
    async handleSectionPhoto() {
      const sectionPhoto = [];

      await Promise.all([
        this.processBeforeImages(sectionPhoto),
        this.processAfterImages(sectionPhoto)
      ]);

      const newSectionPhoto = sectionPhoto.map((r, index) => {
        return {
          ...r,
          index: index + 1
        };
      });

      this.sortedSections = this.sortedSections.concat(newSectionPhoto);
    },

    async processBeforeImages(sectionPhoto) {
      if (
        this.newInvoice.beforeImages &&
        this.newInvoice.beforeImages.length > 0
      ) {
        for (let i = 0; i < this.newInvoice.beforeImages.length; i += 3) {
          const htmlValue = this.compileHtmlSectionPhoto("beforeImages", i);

          sectionPhoto.push({
            sectionId: "project-photos-before",
            sectionName: "Project Photos Before",
            htmlValue
          });
        }
      }
    },

    async processAfterImages(sectionPhoto) {
      if (
        this.newInvoice.afterImages &&
        this.newInvoice.afterImages.length > 0
      ) {
        for (let i = 0; i < this.newInvoice.afterImages.length; i += 3) {
          const htmlValue = this.compileHtmlSectionPhoto("afterImages", i);

          sectionPhoto.push({
            sectionId: "project-photos-after",
            sectionName: "Project Photos After",
            htmlValue
          });
        }
      }
    },

    compileHtmlSectionPhoto(type, index) {
      const title = type === "beforeImages" ? "Before:" : "After:";
      return `<div  style="
            padding-left: 40px;
            display: flex;
            flex-direction: column;
            background-color: #fff;
          ">
          ${
            index === 0
              ? `<div style="width:100%">
                  <h4 style="font-weight: 700;color: #000;">${title}</h4>
                </div>`
              : ""
          }
          ${this.newInvoice[type]
            .slice(index, index + 3)
            .map(
              photo => `
          <div key="${type}-${
                photo.id
              }" style="width: 100%; display: flex;flex-direction: column;gap: 15px; padding-bottom: 15px;">
            <div style="display: flex; gap: 10px">
          <div
            style="
              width: 350px;
              height: 400px;
              align-items: flex-end;
              background: #f6f6f6;
            "
          >
            <img
              src="${photo.photoUrl}"
              style="width: 100%; height: 100%; object-fit: contain"
            />
          </div>
          <div style="flex: 1; display: flex; align-items: flex-end">
            <div style="font-family: Arial, sans-serif">
              <p style="font-size: 14px;color: #000">
                <span style="color: #adadad">Date:</span> ${photo.createdAt &&
                  moment(this.convertDateTime(photo.createdAt)).format(
                    "MM/DD/YYYY, hh:mm a"
                  )}
              </p>
              <p style="font-size: 14px;color: #000">
                <span style="color: #adadad">Creator:</span> ${photo.createdBy}
              </p>
              ${
                photo.notes
                  ? `<p style="font-size: 14px;color: #000"><span style="color: #adadad">Notes:</span> ${photo.notes}</p>`
                  : ""
              }
            </div>
          </div>
        </div>
          `
            )
            .join("")}
          </div>`;
    },
    async getDateBeforeSave() {
      if (this.newInvoice.status === "in-overdue") {
        const currentDate = moment(new Date(), "MM/DD/YYYY");
        let dueDate = this.newInvoice.dueDate[0];
        const days = currentDate.diff(dueDate, "day");
        if (days < 0) {
          Vue.set(this.newInvoice, "status", DEFAULT_STATUS_INVOICE);
        }
      }

      let newInvoice = {
        ...this.newInvoice,
        status: this.newInvoice.id
          ? this.newInvoice.status
          : DEFAULT_STATUS_INVOICE, //default status,
        jobName: this.newInvoiceGroup.jobName || "",
        dueDate: this.newInvoice.dueDate[0] || "",
        invoiceDate: this.newInvoice.invoiceDate[0]
      };

      this.sortedSections = await this.compileSectionData();

      await this.handleSectionPhoto();

      newInvoice = {
        ...newInvoice,
        sections: this.sortedSections
      };

      let newInvoiceGroup = {
        ...this.newInvoiceGroup
      };
      return { newInvoice, newInvoiceGroup };
    },

    async showDialogNextAction(doc, newInvoice, invoiceDate) {
      const app = this;
      this.$ri.dialog.openInfoDialog({
        title: "What would you like to do next?",
        content: `<div style="text-align: center;">Your invoice is complete, but the invoice date is in the future.</div>`,
        textButton: "Yes, ready to sent",
        isCustomButton: true,
        cancelButtonCaption: "Save as draft",
        customButton: [
          {
            text: "Schedule email",
            color: "primary",
            cssClass: ""
          },
          {
            text: "Send now",
            color: "gray",
            cssClass: ""
          },
          {
            text: "No, thanks",
            color: "",
            cssClass: "outline-dialog-button"
          }
        ],
        onClick: async (_sefl, index) => {
          if (index === 0) {
            await this.updateInvoice({
              id: doc.id,
              doc: {
                ...newInvoice,
                schedule: true,
                status: "in-complete",
                invoiceAmount: this.total(newInvoice)
              }
            });

            app.$ri.dialog.openInfoDialog({
              title: "",
              content: `<div style="text-align: center;">Invoice will be automatically sent at 8:00AM on ${invoiceDate.format(
                "MM/DD/YYYY"
              )}</div>`,
              textButton: "OK",
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  app.cancel(true, doc.id);
                } else if (index === 1) {
                  app.cancel(true, doc.id);
                }
              }
            });
          } else if (index === 1) {
            const currentDate = moment(new Date(), "MM/DD/YYYY");
            const invoiceDate = moment(newInvoice.invoiceDate, "MM/DD/YYYY");
            const isDifferentDay = !currentDate.isSame(invoiceDate, "day");
            if (isDifferentDay) {
              app.onChangeInvoiceProp("invoiceDate", [new Date()]);
              app.onChangeNumberOfDays(app.numberOfDays);
              app.$f7.dialog.preloader(
                "Changing invoice date to today. Please wait..."
              );
              const newInvoiceSend = {
                ...doc,
                invoiceDate: this.getCurrentTimestamp(),
                dueDate: this.newInvoice.dueDate[0]
              };

              await this.updateInvoice({
                id: doc.id,
                doc: {
                  ...newInvoice,
                  invoiceDate: this.getCurrentTimestamp(),
                  dueDate: this.newInvoice.dueDate[0]
                }
              }).then(() => {
                setTimeout(async () => {
                  this.$f7.dialog.close();
                  app.onSentEmail(newInvoiceSend);
                }, 1500);
              });
            } else {
              app.onSentEmail(doc);
            }
          } else if (index === 2) {
            await this.updateInvoice({
              id: doc.id,
              doc: {
                ...newInvoice,
                schedule: false,
                status: "in-complete",
                invoiceAmount: this.total(newInvoice)
              }
            });
            app.cancel(true, doc.id);
          }
        }
      });
    },

    getCurrentTimestamp() {
      return firebase.firestore.Timestamp.now();
    },

    async createOrUpdate(invoice, invoiceGroup, isComplete = false) {
      // for save existing invoice
      if (invoice.id) {
        const currentDate = moment(new Date(), "MM/DD/YYYY");
        const invoiceDate = moment(invoice.invoiceDate, "MM/DD/YYYY");
        const isAfterAndDifferentDay =
          currentDate.isAfter(invoiceDate) &&
          currentDate.day() !== invoiceDate.day();

        const newInvoice = {
          ...invoice,
          ...(isComplete && { status: "in-complete" }),
          ...(isAfterAndDifferentDay && {
            invoiceDate: this.getCurrentTimestamp()
          })
        };

        if (isAfterAndDifferentDay) {
          this.onChangeInvoiceProp("invoiceDate", [new Date()]);
          this.onChangeNumberOfDays(this.numberOfDays);
        }

        await this.updateInvoice({
          id: invoice.id,
          doc: newInvoice
        });

        return { id: invoice.id };
      } else if (invoiceGroup.id) {
        // for save new invoice with existing invoice group
        let newInvoice = {
          ...invoice,
          ...(isComplete && { status: "in-complete" }),
          invoiceGroupId: invoiceGroup.id
        };
        this.isEdit = true
        return await this.createNewInvoice({
          invoice: newInvoice,
          isInvoiceGrid: this.isInvoiceGrid
        });
      } else {
        // for save new invoice with new invoice group
        const createdInvoiceGroup = await this.createNewInvoiceGroup({
          invoiceGroup: invoiceGroup
        });

        let newInvoice = {
          ...invoice,
          ...(isComplete && { status: "in-complete" }),
          invoiceGroupId: createdInvoiceGroup.id
        };
        this.isEdit = true
        return await this.createNewInvoice({
          invoice: newInvoice,
          isInvoiceGrid: this.isInvoiceGrid
        });
      }
    },

    requireErrorMessageInvoice(prop) {
      if (!this.v$.newInvoice[prop]?.$error) return null;
      if (this.v$.newInvoice[prop]?.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    requireErrorMessageInvoiceGroup(prop) {
      if (!this.v$.newInvoiceGroup[prop]?.$error) return null;
      if (this.v$.newInvoiceGroup[prop]?.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    clientEmailErrorMessage() {
      if (!this.v$.newInvoice.clientEmail.$error) return null;
      if (this.v$.newInvoice.clientEmail.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.newInvoice.clientEmail.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    roofingCompanyEmailErrorMessage() {
      if (!this.v$.newInvoice.roofingCompanyEmail.$error) return null;
      if (this.v$.newInvoice.roofingCompanyEmail.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.newInvoice.roofingCompanyEmail.commonEmailRule.$invalid)
        return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    clientPhoneNumberErrorMessage() {
      if (!this.v$.newInvoice.clientPhoneNumber.$error) return null;
      if (this.v$.newInvoice.clientPhoneNumber.minLength.$invalid)
        return VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT;
      return null;
    },

    onSentEmail(newInvoice) {
      this.$refs.sendEmailPopup.open(newInvoice);
    },

    clientCompanyTaxCodeErrorMessage() {
      if (!this.v$.newInvoice.clientCompanyTaxCode.$error) return null;
      if (this.v$.newInvoice.clientCompanyTaxCode.validateEIN.$invalid)
        return VALIDATION_MESSAGE.INVALID_TAX_CODE;
      return null;
    },
    roofingCompanyTaxCodeErrorMessage() {
      if (!this.v$.newInvoice.roofingCompanyTaxCode.$error) return null;
      if (this.v$.newInvoice.roofingCompanyTaxCode.validateEIN.$invalid)
        return VALIDATION_MESSAGE.INVALID_TAX_CODE;
      return null;
    },

    onChangeTempItemDetails(items) {
      this.tempItemsDetails = items;
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    const timeRules = () => {
      if (!this.newInvoice.invoiceDate || !this.newInvoice.dueDate) return true;
      const fromDate = moment(this.newInvoice.invoiceDate[0]).format(
        "YYYY-MM-DD"
      );
      const toDate = moment(this.newInvoice.dueDate[0]).format("YYYY-MM-DD");
      return !moment(toDate).isBefore(fromDate, "day");
    };
    
     const validateEIN = helpers.withParams(
      { type: "validateEIN" },
      value => {
        if(!value) return true
        return  /^\d{2}-\d{7}$/.test(value)
      }
    );

    return {
      newInvoiceGroup: {
        roofingCompanyName: {
          required
        },
        jobName: {
          required
        }
      },

      newInvoice: {
        invoiceDate: {
          required
        },
        dueDate: {
          required,
          timeRules
        },
        clientName: {
          required
        },
        clientEmail: {
          required,
          commonEmailRule: email
        },
        roofingCompanyEmail: {
          commonEmailRule: email
        },
        clientPhoneNumber: {
          minLength: minLength(17)
        },
        clientCompanyTaxCode: {
          validateEIN
        },
        roofingCompanyTaxCode: {
          validateEIN
        },
        paymentTerm: {
          required
        }
      },
      numberOfDays: {
        required
      }
    };
  }
};
</script>

<style land="scss" scoped>
.flex-start {
  justify-content: flex-start;
}
</style>
