import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import SpreadsheetWriter from "../../../utility/spreadsheet-writer";
import { currencyUSD } from "../../../utility/config";
function getExcelWorkbook({ headers, values, totals, purchaseOrderInfo }) {
  const columns = headers;
  const positionOfCellTotal = columns.length - 2;
  const numberCellMerge = columns.length - 3;
  const { taskNumber } = purchaseOrderInfo;
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Purchase Order");
  var writer = new SpreadsheetWriter(worksheet);
  writer.write("Purchase number:");
  writer.mergeNext();
  writer.right();
  writer.right();
  writer.write(taskNumber);
  writer.mergeNext(numberCellMerge);
  writer.bold();
  writer.enter();

  //header
  columns.forEach(x => {
    writer.write(x["header"]);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.right();
  });
  values.forEach(y => {
    writer.enter();
    [
      "sku",
      "item",
      "manufacturer",
      "uom",
      "unitSize",
      "orderQty",

      "packaging",
      "receivedQty",
      "price",
      "subTotal"
    ].forEach(z => {
      if (y[z] !== undefined) {
        writer.write(y[z]);
        writer.border();
        writer.right();
      }
    });
  });

  writer.enter();
  writer.right(positionOfCellTotal);
  writer.write("Total");
  writer.border();
  writer.right();
  writer.write(currencyUSD(totals));
  writer.border();
  writer.bold();
  writer.enter();
  writer.enter();
  return workbook;
}
function exportPurchaseOrder({ headers, values, totals, purchaseOrderInfo }) {
  const workbook = getExcelWorkbook({
    headers,
    values,
    totals,
    purchaseOrderInfo
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `purchase-order-${new Date().getTime()}.xlsx`
    );
  });
}

function getEstimateSummaryExcelWorkbook({
  headers,
  values,
  summary,
  estimateSalesTax
}) {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Estimate Summary");
  var writer = new SpreadsheetWriter(worksheet);
  //header
  headers.forEach(x => {
    writer.write(x.text);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.mergeNext();
    writer.right();
    writer.right();
  });
  values.forEach(y => {
    writer.enter();
    [
      "estimate",
      "roofType",
      "totalCost",
      "profitMarginRatio",
      "grossProfitMargin",
      "netSales",
      "costPerSqft"
    ].forEach(z => {
      if (
        ["totalCost", "grossProfitMargin", "netSales", "costPerSqft"].includes(
          z
        )
      ) {
        writer.write(currencyUSD(y[z] || 0));
      } else if (z === "profitMarginRatio") {
        writer.write((y[z] || 0) + "%");
      } else {
        writer.write(y[z]);
      }
      writer.border();
      writer.mergeNext();
      writer.right();
      writer.right();
    });
  });

  summary.forEach(y => {
    writer.enter();
    writer.right(6);
    writer.write(y.title);
    writer.border();

    if (y.title === "Sales Tax") {
      writer.mergeNext();
      writer.right(2);
      writer.write((estimateSalesTax || 0).toFixed(2) + "%");
      writer.border();
      writer.mergeNext();
      writer.right(2);
    } else {
      writer.mergeNext(3);
      writer.right(4);
    }
    writer.write(currencyUSD(y.value || 0));
    writer.border();
    writer.mergeNext(3);
    writer.bold();
  });

  return workbook;
}
function exportEstimateSummaryToExcel({
  headers,
  values,
  summary,
  estimateSalesTax
}) {
  const workbook = getEstimateSummaryExcelWorkbook({
    headers,
    values,
    summary,
    estimateSalesTax
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `estimate-summary-${new Date().getTime()}.xlsx`
    );
  });
}

export {
  getExcelWorkbook,
  getEstimateSummaryExcelWorkbook,
  exportEstimateSummaryToExcel
};

export default exportPurchaseOrder;
