import * as types from "./types";
import {
  FirebaseActions,
  firebase
} from "../../../../services/firebase.service";
import userService from "../../../../services/user.service";
import _ from "lodash";
import * as constant from "../../../../utility/const";
import algoliaService from "../../../../services/algolia.service";

export default tenant => {
  //TODO: need to move to contact store
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    "contact"
  );

  const messageActions = new FirebaseActions(
    `/system_client/${tenant}/operation_message`,
    "message"
  );

  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "company"
  );

  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    "property"
  );

  return {
    bindClientData({ state, dispatch }) {
      if (_.isEmpty(state.contactList)) {
        dispatch("bindContactListBys", [
          {
            prop: constant.PROP_IS_DELETED,
            op: "!=",
            val: true
          }
        ]);
      }
      // if (_.isEmpty(state.companyList)) {
      //   dispatch("bindCompanyListBys", [
      //     {
      //       prop: constant.PROP_IS_DELETED,
      //       op: "!=",
      //       val: true
      //     }
      //   ]);
      // }
      // if (_.isEmpty(state.propertyList)) {
      //   dispatch("bindPropertyListBys", [
      //     {
      //       prop: constant.PROP_IS_DELETED,
      //       op: "!=",
      //       val: true
      //     }
      //   ]);
      // }
    },

    /**
     * Add relationship for contact and company
     * @param {*} param0 vuex default params
     * @param {*} data the relationship info
     */
    setContactCompanyRefs({ commit }, company) {
      commit(types.SET_CONTACT_COMPANY_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: company.id,
        companyName: company.companyName
      });
    },

    async removeContactRefsOfCompany(
      { dispatch },
      { companyId, contactIdToRemove }
    ) {
      if (companyId) {
        const company = await dispatch("getCompanyById", companyId);

        let contactRefIds =
          ((company || {}).contactRefs || []).map(r => r.id) || [];

        if (contactRefIds.includes(contactIdToRemove)) {
          const newContactRefs = _.remove(
            _.cloneDeep(company.contactRefs),
            item => {
              return item.id != contactIdToRemove;
            }
          );

          return dispatch("updateCompany", {
            id: companyId,
            doc: {
              contactRefs: newContactRefs
            }
          });
        }
      }
    },

    setCompanyBranchRefs({ commit }, branch) {
      commit(types.SET_COMPANY_BRANCH_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: branch.id,
        companyName: branch.companyName
      });
    },

    async setBranch({ commit, dispatch }, companyId) {
      let company = {};
      if (companyId) {
        company = await dispatch("getCompanyById", companyId);
      }
      commit(types.SET_BRANCH, company);
    },

    async addCompanyContactRef({ dispatch, getters }, data) {
      if (data.contactId) {
        let companyRefs = _.cloneDeep(data.companyRefs) || [];

        // update company refs
        dispatch("updateContact", {
          id: data.contactId,
          doc: {
            companyRefs
          }
        }).then(() => {
          // reload contact
          dispatch("setCurrentContact", data.contactId);
        });

        //update contactRef for company
        for (const ref of companyRefs) {
          const company = getters.companyList.find(item => item.id === ref.id);

          const contactRefs = _.cloneDeep(company.contactRefs || []);
          if (_.isEmpty(contactRefs.find(item => item.id === data.contactId))) {
            contactRefs.push({
              id: data.contactId,
              createdRefAt: firebase.firestore.Timestamp.now()
            });

            dispatch("updateCompany", {
              id: ref.id,
              doc: {
                contactRefs
              }
            });
          }
        }
      }
      // update for company

      if (data.companyId) {
        let contactRefs = _.cloneDeep(data.contactRefs) || [];
        dispatch("updateCompany", {
          id: data.companyId,
          doc: {
            contactRefs: contactRefs
          }
        }).then(() => {
          dispatch("setCurrentCompany", data.companyId);
        });

        //update companyRef for contact
        for (const ref of contactRefs) {
          const contact = getters.contactList.find(item => item.id === ref.id);

          const companyRefs = _.cloneDeep(contact.companyRefs || []);
          if (_.isEmpty(companyRefs.find(item => item.id === data.companyId))) {
            companyRefs.push({
              id: data.companyId,
              createdRefAt: firebase.firestore.Timestamp.now()
            });

            dispatch("updateContact", {
              id: ref.id,
              doc: {
                companyRefs
              }
            });
          }
        }
      }
    },

    setCompanyContactRefs({ commit }, contact) {
      commit(types.SET_COMPANY_CONTACT_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName
      });
    },

    /**
     * Remove relationship for contact and company
     * @param {*} param0 vuex default params
     * @param {*} data the relationship info
     */
    unsetCompanyContactRef({ commit }, data) {
      commit(types.UNSET_COMPANY_CONTACT_REFS, data);
    },

    /**
     * Add relationship for contact and property
     * @param {*} param0 vuex default params
     * @param {*} data the relationship info
     */
    async addPropertyContactRef({ dispatch }, data) {
      let contact = await dispatch("getContactById", data.contactId);
      let property = await dispatch("getPropertyById", data.propertyId);
      if (!contact || !property) return;

      const propertyRefs = _.cloneDeep(contact.propertyRefs) || [];
      const contactRefs = _.cloneDeep(property.contactRefs) || [];
      // update for contact
      if (!propertyRefs.find(r => r.id == data.propertyId)) {
        propertyRefs.push({
          createdRefAt: firebase.firestore.Timestamp.now(),
          id: data.propertyId
        });

        dispatch("updateContact", {
          id: data.contactId,
          doc: {
            propertyRefs: propertyRefs
          }
        }).then(() => {
          dispatch("setCurrentContact", data.contactId);
        });
      }

      // update for company
      if (!contactRefs.find(r => r.id == data.contactId)) {
        contactRefs.push({
          createdRefAt: firebase.firestore.Timestamp.now(),
          id: data.contactId,
          optionalRole: data.optionalRole || ""
        });

        dispatch("updateProperty", {
          id: data.propertyId,
          doc: {
            contactRefs: contactRefs
          }
        }).then(() => {
          dispatch("setCurrentProperty", data.propertyId);
        });
      }
    },

    setCompanyPropertyRefs({ commit }, property) {
      commit(types.SET_COMPANY_PROPERTY_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: property.id,
        propertyName: property.propertyName
      });
    },

    setPropertyContactRefs({ commit }, property) {
      commit(types.SET_PROPERTY_CONTACT_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: property.id,
        propertyName: property.propertyName
      });
    },
    setIsEditProperty({ commit }, value) {
      commit(types.SET_IS_EDIT_PROPERTY, value);
    },
    /**
     * Remove relationship for contact and property
     * @param {*} param0 vuex default params
     * @param {*} data the relationship info
     */
    async removePropertyContactRef({ dispatch }, data) {
      let contact = await dispatch("getContactById", data.contactId);
      let property = await dispatch("getPropertyById", data.propertyId);
      if (contact) {
        const propertyRefs = _.remove(
          _.cloneDeep(contact.propertyRefs),
          item => {
            return item.id != data.propertyId;
          }
        );

        dispatch("updateContact", {
          id: data.contactId,
          doc: {
            propertyRefs: propertyRefs
          }
        }).then(() => {
          dispatch("setCurrentContact", data.contactId);
        });
      }

      if (property) {
        const contactRefs = _.remove(
          _.cloneDeep(property.contactRefs),
          item => {
            return item.id != data.contactId;
          }
        );

        dispatch("updateProperty", {
          id: data.propertyId,
          doc: {
            contactRefs: contactRefs
          }
        }).then(() => {
          dispatch("setCurrentProperty", data.propertyId);
        });
      }
    },

    async addContactRefForProperty({ dispatch }, data) {
      let contactRefs = _.cloneDeep(data.contactRefs) || [];
      dispatch("updateProperty", {
        id: data.propertyId,
        doc: {
          contactRefs: contactRefs
        }
      }).then(() => {
        dispatch("setCurrentProperty", data.propertyId);
      });
    },

    addCompanyRefForProperty({ state, dispatch }, data) {
      const companyRefs = _.cloneDeep(state.property.companyRefs) || [];
      // update for properties
      if (!companyRefs.find(r => r.id == data.companyId)) {
        companyRefs.push({
          createdRefAt: firebase.firestore.Timestamp.now(),
          id: data.companyId
        });
        dispatch("updateProperty", {
          id: data.propertyId,
          doc: {
            companyRefs: companyRefs
          }
        }).then(() => {
          dispatch("setCurrentProperty", data.propertyId);
        });
      }

      const company = state.companyList.find(r => r.id === data.companyId);

      const propertyRefs = _.cloneDeep(company.propertyRefs) || [];
      // update for company
      if (!propertyRefs.find(r => r.id == data.propertyId)) {
        propertyRefs.push({
          createdRefAt: firebase.firestore.Timestamp.now(),
          id: data.propertyId
        });
        dispatch("updateCompany", {
          id: data.companyId,
          doc: {
            propertyRefs: propertyRefs
          }
        }).then(() => {
          dispatch("setCurrentProperty", data.propertyId);
        });
      }
    },
    /**
     *
     * @param {*} param0
     * @param {*} data
     */

    async addPropertyCompanyRef({ dispatch, getters }, data) {
      if (data.companyId) {
        let propertyRefs = _.cloneDeep(data.propertyRefs) || [];

        dispatch("updateCompany", {
          id: data.companyId,
          doc: {
            propertyRefs
          }
        }).then(() => {
          dispatch("setCurrentCompany", data.companyId);
        });

        //update companyRefs for property
        for (const ref of propertyRefs) {
          const property = getters.propertyList.find(
            item => item.id === ref.id
          );

          const companyRefs = _.cloneDeep(property.companyRefs || []);
          if (_.isEmpty(companyRefs.find(item => item.id === data.companyId))) {
            companyRefs.push({
              id: data.companyId,
              createdRefAt: firebase.firestore.Timestamp.now()
            });

            dispatch("updateProperty", {
              id: ref.id,
              doc: {
                companyRefs
              }
            });
          }
        }
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} data
     */
    removePropertyCompanyRef({ commit }, data) {
      commit(types.UNSET_PROPERTY_COMPANY_REFS, data);
    },

    deletePropertyAndRemovePropertyRef({ state, dispatch }, propertyId) {
      //remove property ref from company
      const companyHaveRefTo = state.companyList.filter(
        com =>
          com.propertyRefs &&
          com.propertyRefs.some(prop => prop.id === propertyId)
      );
      let promises = [];
      companyHaveRefTo.forEach(company => {
        const propertyRefs = _.remove(
          _.cloneDeep(company.propertyRefs),
          item => {
            return item.id != propertyId;
          }
        );
        promises.push(
          dispatch("updateCompany", {
            id: company.id,
            doc: {
              propertyRefs: propertyRefs
            }
          })
        );
      });
      //remove property ref from contacts
      const contactHaveRefTo = state.contactList.filter(
        contact =>
          contact.propertyRefs &&
          contact.propertyRefs.some(prop => prop.id === propertyId)
      );
      contactHaveRefTo.forEach(contact => {
        const propertyRefs = _.remove(
          _.cloneDeep(contact.propertyRefs),
          item => {
            return item.id != propertyId;
          }
        );
        promises.push(
          dispatch("updateContact", {
            id: contact.id,
            doc: {
              propertyRefs: propertyRefs
            }
          })
        );
      });
      //delete property
      promises.push(dispatch("deleteProperty", propertyId));
      Promise.all(promises).then(() => {
        dispatch("setCurrentCompany", state.company.id);
      });
    },

    removePropertyCompanyRefList({ state, dispatch }, data) {
      const propertyRefs = _.remove(
        _.cloneDeep(state.company.propertyRefs),
        item => {
          return !data.propertyIds.includes(item.id);
        }
      );

      dispatch("updateCompany", {
        id: data.companyId,
        doc: {
          propertyRefs: propertyRefs
        }
      }).then(() => {
        dispatch("setCurrentCompany", data.companyId);
      });

      let promises = [];
      data.propertyIds.forEach(propertyId => {
        const property = state.propertyList.find(r => r.id === propertyId);

        const companyRefs = _.remove(
          _.cloneDeep(property.companyRefs),
          item => {
            return item.id != data.companyId;
          }
        );
        promises.push(
          dispatch("updateProperty", {
            id: property.id,
            doc: {
              companyRefs: companyRefs
            }
          })
        );
      });
      Promise.all(promises).then(() => {
        dispatch("setCurrentCompany", data.companyId);
      });
    },

    /**
     *
     * @param {*} param0
     * @param {*} data
     */
    addCompanyBranchRef({ dispatch }, data) {
      let branchRefs = _.cloneDeep(data.branchRefs) || [];

      dispatch("updateCompany", {
        id: data.companyId,
        doc: {
          branchRefs: branchRefs
        }
      }).then(() => {
        dispatch("setCurrentCompany", data.companyId);
      });
    },

    /**
     *
     * @param {*} param0
     * @param {*} data
     */
    removeCompanyBranchRef({ commit }, data) {
      commit(types.SET_REMOVE_COMPANY_BRANCH_REFS, data.branchId);
    },

    setCurrentContactData({ commit }, data) {
      commit(types.SET_CURRENT_CONTACT_DATA, data);
    },
    setOldContactId({ commit }, id) {
      commit(types.SET_OLD_CONTACT_ID, id);
    },
    setIsEditContact({ commit }, value) {
      commit(types.SET_IS_EDIT_CONTACT, value);
    },
    setTempContact({ commit }, value) {
      commit(types.SET_TEMP_CONTACT, value);
    },
    setIsNewContact({ commit }, value) {
      commit(types.SET_IS_NEW_CONTACT, value);
    },
    // ------------- Self acrions
    async setCurrentContact({ commit, dispatch }, contactId) {
      let contact = {};
      if (contactId) {
        contact = await dispatch("getContactById", contactId);
      }
      commit(types.SET_CURRENT_CONTACT, contact);
    },

    async setCurrentProperty({ commit, dispatch }, propertyId) {
      let property = {};
      if (propertyId) {
        property = await dispatch("getPropertyById", propertyId);
      }
      commit(types.SET_CURRENT_PROPERTY, property);
    },

    async setCurrentCompany({ commit, dispatch }, companyId) {
      let company = {};
      if (companyId) {
        company = await dispatch("getCompanyById", companyId);
      }
      commit(types.SET_CURRENT_COMPANY, company);
    },

    setCurrentCompanyData({ commit }, data) {
      commit(types.SET_CURRENT_COMPANY_DATA, data);
    },
    setOldCompanyId({ commit }, id) {
      commit(types.SET_OLD_COMPANY_ID, id);
    },
    setIsEditCompany({ commit }, value) {
      commit(types.SET_IS_EDIT_COMPANY, value);
    },
    setIsNewCompany({ commit }, value) {
      commit(types.SET_IS_NEW_COMPANY, value);
    },
    setCurrentHistory({ commit }, historyId) {
      commit(types.SET_CURRENT_HISTORY, historyId);
    },
    setCompanyActiveTab({ commit }, tab) {
      commit(types.SET_COMPANY_ACTIVE_TAB, tab);
    },

    // -------------- Project
    addProjectContactRef({ state, dispatch }, data) {
      const projectRefs = _.cloneDeep(state.contact.projectRefs) || [];
      if (!projectRefs.find(r => r.id == data.projectId)) {
        projectRefs.push({
          id: data.projectId,
          fromDate: data.fromDate
            ? new firebase.firestore.Timestamp.fromDate(new Date(data.fromDate))
            : "",
          toDate: data.toDate
            ? new firebase.firestore.Timestamp.fromDate(new Date(data.toDate))
            : ""
        });

        dispatch("updateContact", {
          id: data.contactId,
          doc: {
            projectRefs: projectRefs
          }
        }).then(() => {
          dispatch("setCurrentContact", data.contactId);
        });
      }
    },

    removeProjectContactRef({ state, dispatch }, data) {
      const projectRefs = _.remove(
        _.cloneDeep(state.contact.projectRefs),
        item => {
          return item.id != data.projectId;
        }
      );

      dispatch("updateContact", {
        id: data.contactId,
        doc: {
          projectRefs: projectRefs
        }
      }).then(() => {
        dispatch("setCurrentContact", data.contactId);
      });
    },

    addProjectCompanyRef({ state, dispatch }, data) {
      const projectRefs = _.cloneDeep(state.company.projectRefs) || [];
      if (!projectRefs.find(r => r.id == data.projectId)) {
        projectRefs.push({
          id: data.projectId,
          fromDate: data.fromDate
            ? new firebase.firestore.Timestamp.fromDate(new Date(data.fromDate))
            : "",
          toDate: data.toDate
            ? new firebase.firestore.Timestamp.fromDate(new Date(data.toDate))
            : ""
        });

        dispatch("updateCompany", {
          id: data.companyId,
          doc: {
            projectRefs: projectRefs
          }
        }).then(() => {
          dispatch("setCurrentCompany", data.companyId);
        });
      }
    },

    removeProjectCompanyRef({ state, dispatch }, data) {
      const projectRefs = _.remove(
        _.cloneDeep(state.company.projectRefs),
        item => {
          return item.id != data.projectId;
        }
      );

      dispatch("updateCompany", {
        id: data.companyId,
        doc: {
          projectRefs: projectRefs
        }
      }).then(() => {
        dispatch("setCurrentCompany", data.companyId);
      });
    },

    addProjectPropertyRef({ state, dispatch }, data) {
      const projectRefs = _.cloneDeep(state.property.projectRefs) || [];
      if (!projectRefs.find(r => r.id == data.projectId)) {
        projectRefs.push({
          id: data.projectId,
          fromDate: data.fromDate
            ? new firebase.firestore.Timestamp.fromDate(new Date(data.fromDate))
            : "",
          toDate: data.toDate
            ? new firebase.firestore.Timestamp.fromDate(new Date(data.toDate))
            : ""
        });

        dispatch("updateProperty", {
          id: data.propertyId,
          doc: {
            projectRefs: projectRefs
          }
        }).then(() => {
          dispatch("setCurrentProperty", data.propertyId);
        });
      }
    },

    removeProjectPropertyRef({ state, dispatch }, data) {
      const projectRefs = _.remove(
        _.cloneDeep(state.property.projectRefs),
        item => {
          return item.id != data.projectId;
        }
      );

      dispatch("updateProperty", {
        id: data.propertyId,
        doc: {
          projectRefs: projectRefs
        }
      }).then(() => {
        dispatch("setCurrentProperty", data.propertyId);
      });
    },

    //remove all refs after delete company
    removeCompanyRefs({ state, dispatch }, data) {
      let promises = [];
      //remove company ref from contacts
      state.contactList.forEach(contact => {
        let companyRefIds = (contact.companyRefs || []).map(r => r.id);
        if (companyRefIds.includes(data.companyId)) {
          const companyRefs = _.remove(
            _.cloneDeep(contact.companyRefs),
            item => {
              return item.id != data.companyId;
            }
          );
          promises.push(
            dispatch("updateContact", {
              id: contact.id,
              doc: {
                companyRefs: companyRefs
              }
            })
          );
        }
      });

      //remove company ref from properties
      state.propertyList.forEach(property => {
        let companyRefIds = (property.companyRefs || []).map(r => r.id);
        if (companyRefIds.includes(data.companyId)) {
          const companyRefs = _.remove(
            _.cloneDeep(property.companyRefs),
            item => {
              return item.id != data.companyId;
            }
          );
          promises.push(
            dispatch("updateProperty", {
              id: property.id,
              doc: {
                companyRefs: companyRefs
              }
            })
          );
        }
      });

      Promise.all(promises).then(() => {
        // console.log("Remove all company refs from others");
      });
    },
    //remove all refs after delete contact
    removeContactRefs({ state, dispatch }, data) {
      let promises = [];
      //remove contact ref from companies
      state.companyList.forEach(company => {
        let contactRefIds = (company.contactRefs || []).map(r => r.id);
        if (contactRefIds.includes(data.contactId)) {
          const contactRefs = _.remove(
            _.cloneDeep(company.contactRefs),
            item => {
              return item.id != data.contactId;
            }
          );
          promises.push(
            dispatch("updateCompany", {
              id: company.id,
              doc: {
                contactRefs: contactRefs
              }
            })
          );
        }
      });
      //remove contact ref from properties
      state.propertyList.forEach(propery => {
        let contactRefIds = (propery.contactRefs || []).map(r => r.id);
        if (contactRefIds.includes(data.contactId)) {
          const contactRefs = _.remove(
            _.cloneDeep(propery.contactRefs),
            item => {
              return item.id != data.contactId;
            }
          );
          promises.push(
            dispatch("updateProperty", {
              id: propery.id,
              doc: {
                contactRefs: contactRefs
              }
            })
          );
        }
      });

      Promise.all(promises).then(() => {
        // console.log("Remove all contact refs from others");
      });
    },
    async bindUserData({ commit }) {
      userService.getUserList().then(users => {
        commit(types.SET_USERS_LIST, users);
      });
    },
    setCreatedPropertyId({ commit }, id) {
      commit(types.SET_CREATED_PROPERTY_ID, id);
    },
    setCreatedContactId({ commit }, id) {
      commit(types.SET_CREATED_CONTACT_ID, id);
    },
    setCreatedCompanyId({ commit }, id) {
      commit(types.SET_CREATED_COMPANY_ID, id);
    },

    bindContactListBys: contactActions.bindCollectionBys,
    bindContactList({ dispatch }) {
      return dispatch("bindContactListBys", [
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]);
    },
    unbindContactList: contactActions.unbindCollection,

    bindCompanyListBys: companyActions.bindCollectionBys,
    bindCompanyList({ dispatch }) {
      return dispatch("bindCompanyListBys", [
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]);
    },
    unbindCompanyList: companyActions.unbindCollection,

    bindPropertyListBys: propertyActions.bindCollectionBys,
    bindPropertyList({ dispatch }) {
      return dispatch("bindPropertyListBys", [
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]);
    },
    unbindPropertyList: propertyActions.unbindCollection,

    addNewContact: contactActions.createDocument,
    updateContact: contactActions.updateDocument,

    async createContact({ dispatch }, contact) {
      const id = await dispatch("addNewContact", contact);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_contact",
        id
      });
      return id;
    },

    deleteContact({ dispatch }, id) {
      return dispatch("updateContact", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
    },

    getContactById: contactActions.getDocument,

    addNewCompany: companyActions.createDocument,
    updateCompany: companyActions.updateDocument,

    async createCompany({ dispatch }, company) {
      const id = await dispatch("addNewCompany", company);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_company",
        id
      });
      return id;
    },

    deleteCompany({ dispatch }, id) {
      return dispatch("updateCompany", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
    },

    getCompanyById: companyActions.getDocument,

    addNewProperty: propertyActions.createDocument,
    updateProperty: propertyActions.updateDocument,

    async createProperty({ dispatch }, property) {
      const id = await dispatch("addNewProperty", property);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_property",
        id
      });
      return id;
    },

    deleteProperty({ dispatch }, id) {
      return dispatch("updateProperty", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
    },

    getPropertyById: propertyActions.getDocument,

    //contact message
    bindMessageList: messageActions.bindCollection,
    unbindMessageList: messageActions.unbindCollection,
    bindMessageListBys: messageActions.bindCollectionBys,
    bindMessageListBy: messageActions.bindCollectionBy,
    createMessage: messageActions.createDocument,
    updateMessage: messageActions.updateDocument,
    deleteMessage: messageActions.deleteDocument,

    setSearchValue({ commit }, value) {
      commit(types.SET_SEARCH_VALUE, value);
    }
  };
};
