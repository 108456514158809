<template>
  <div>
    <f7-list>
      <f7-list-item
        v-show="value.length > 0"
        v-for="(file, index) in value"
        :key="index"
        text-color="blue"
      >
        <div slot="title">
          {{ file.name }}
        </div>
        <f7-button
          v-if="file.description"
          slot="after"
          :popover-open="`.popover-description-${index}`"
        >
          <f7-icon style="margin-bottom: 5px;" f7="ellipses_bubble"></f7-icon>
        </f7-button>
        <f7-popover
          :class="
            $device.desktop
              ? `popover-description-${index} popover-description-desktop`
              : `popover-description-${index} popover-description-mobile`
          "
        >
          <f7-list class="cursor-pointer menu-actions">
            <f7-list-item popover-close>
              <div
                style="word-break: break-word;"
                v-html="file.description"
              ></div>
            </f7-list-item>
          </f7-list>
        </f7-popover>
        <f7-button
          v-if="file.description"
          slot="after"
          @click="openEditAttachmentDescription(file, index, true)"
        >
          <f7-icon
            style="margin-bottom: 10px;"
            f7="pencil_ellipsis_rectangle"
          ></f7-icon>
        </f7-button>
        <f7-button
          v-if="!file.description"
          slot="after"
          @click="openEditAttachmentDescription(file, index, false)"
        >
          <f7-icon
            style="margin-bottom: 10px;"
            f7="pencil_ellipsis_rectangle"
          ></f7-icon>
        </f7-button>
        <f7-link
          @click="handleFileDelete(index)"
          slot="after"
          icon-f7="trash"
          class="cursor-pointer"
        ></f7-link>
      </f7-list-item>
      <f7-list-item class="cursor-pointer" @click.native="openFileInput">
        <div
          slot="media"
          class="cursor-pointer display-flex align-items-center"
        >
          <f7-icon color="red" f7="plus_circle"></f7-icon>
        </div>
        <div class="list-item-title" slot="title">
          {{ title }}
        </div>
        <f7-link slot="after" icon-f7="paperclip"></f7-link>
      </f7-list-item>
    </f7-list>
    <input
      type="file"
      @change="onAddAttachment"
      class="display-none"
      ref="fileInput"
      multiple
    />
    <attachment-popup
      @done="onDone"
      :filesInput="filesInput"
      ref="attachmentPopup"
    ></attachment-popup>
    <edit-attachment-description-popup
      @onEdited="onEditedDescription"
      ref="editAttachmentDescription"
    ></edit-attachment-description-popup>
  </div>
</template>

<script>
import _ from "lodash";
import AttachmentPopup from "@/components/popups/AttachmentPopup.vue";
import EditAttachmentDescriptionPopup from "@/components/popups/EditAttachmentDescriptionPopup.vue";
export default {
  components: {
    AttachmentPopup,
    EditAttachmentDescriptionPopup
  },
  data: () => {
    return {
      filesInput: []
    };
  },
  props: {
    value: { type: Array, default: () => [] },
    title: String
  },

  methods: {
    onAddAttachment() {
      const files = this.$refs.fileInput.files;
      this.filesInput = Array.from(files);
      let attachments = _.cloneDeep(this.value);
      for (let i = 0; i < files.length; i += 1) {
        if (attachments.some(r => r.name === files[i].name)) {
          this.$ri.dialog.openWarningDialog({
            title: "Please choose another one",
            content: "That file name has already existed!",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
          break;
        }
        this.$refs.attachmentPopup.open();
      }
      this.$refs.fileInput.value = "";
    },
    onDone(uploadFiles, descriptions) {
      let value = _.cloneDeep(this.value);
      for (const file of uploadFiles) {
        this.$f7.preloader.show();
        file.description = descriptions[uploadFiles.indexOf(file)];
        value = value.concat(file);
        this.$emit("input", value);
        this.$f7.preloader.hide();
      }
    },

    handleFileDelete(index) {
      const attachments = _.cloneDeep(this.value);
      attachments.splice(index, 1);
      this.$emit("input", attachments);
      this.$refs.fileInput.value = "";
    },

    openLink(url) {
      window.open(url, "_blank");
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },
    openEditAttachmentDescription(item, index, isEdit) {
      this.$refs.editAttachmentDescription.open(item, index, isEdit);
    },
    onEditedDescription(description, index) {
      let value = _.cloneDeep(this.value);
      value[index].description = description;
      this.$emit("input", value);
    }
  }
};
</script>
<style scoped lang="scss">
.popover-description-desktop {
  max-height: 300px;
  width: auto;
  max-width: 500px;
}
.popover-description-mobile {
  width: auto;
  height: auto;
}

.list ::v-deep .item-title {
  white-space: normal;
}
</style>
