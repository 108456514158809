<template>
  <div>
    <f7-block-title
      class="display-flex justify-content-space-between align-items-center"
      style="flex-wrap: wrap; gap: 8px;"
    >
      <div>
        {{
          isMetalAndEdgeSection
            ? `${sectionTitle} Products Summary`
            : sectionTitle
        }}
        <span class="title-alert">{{ alertMessage }}</span>
      </div>
      <div class="display-flex">
        <f7-button
          outline
          class="margin-right"
          @click="openAddNewProductPopup()"
        >
          Quick Create Product
        </f7-button>
        <f7-button fill @click="openAddProductPopup">
          Add Product
        </f7-button>
      </div>
    </f7-block-title>

    <!-- <div class="data-table">
      <div class="data-table-actions margin-left">
        <f7-block-title>bajkshj</f7-block-title>
        <f7-button @click="openAddProductPopup">Add Product</f7-button>
      </div>
    </div> -->

    <div class="card data-table section-product-table">
      <table>
        <thead>
          <tr>
            <th
              v-for="(item, index) in headers"
              :key="index"
              :class="`${item.cssClass} ${textAlign(item.align)}`"
              :style="item.width"
            >
              {{ item.text }}
            </th>
            <th v-if="isEditing" class="text-align-right">
              Actions
            </th>
          </tr>
        </thead>

        <tbody v-if="items && items.length > 0">
          <tr v-for="(product, index) in items" :key="index">
            <!-- <td class="label-cell">
              <f7-link @click="onEdit(product)">{{ product.sku }}</f7-link>
            </td> -->

            <td v-if="$f7.device.desktop || $f7.device.ipad" class="label-cell">
              <span v-if="!isEditing">{{ product.productItem }}</span>
              <product-auto-complete-input
                v-else
                :value="product.productItem"
                :data="
                  productListBy({
                    roofType: roofType,
                    categoryId: product.categoryId,
                    subCategoryId: product.subCategoryId
                  })
                "
                @selected="
                  changeProductItem(product, $event.target.value, index)
                "
                @onClickAddProductPhoto="uploadProductPhoto"
              ></product-auto-complete-input>
            </td>
            <td v-else>
              <a @click="$refs.productSheetRef.openSheet(product)">{{
                product.productItem
              }}</a>
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad">
              <long-content-block
                v-if="!isEditing"
                :seedId="`note-${product.id}`"
                :content="product.proposalNote || ''"
              ></long-content-block>

              <f7-list v-else>
                <f7-list-input
                  class="input-note"
                  type="text"
                  placeholder="Enter proposal note"
                  :value="product.proposalNote"
                  @change="
                    updateProductProperty({
                      propName: 'proposalNote',
                      value: $event.target.value.trim(),
                      productId: product.id,
                      index
                    })
                  "
                ></f7-list-input>
              </f7-list>
            </td>
            <td v-else>
              <long-content-block
                :seedId="`note-${product.id}`"
                :content="product.proposalNote"
              ></long-content-block>
            </td>

            <td class="label-cell">
              {{ subCategoryName(product.subCategoryId) }}
            </td>

            <td
              @mouseover="hoverIndex = index"
              @mouseout="hoverIndex = ''"
              class="text-align-left"
            >
              <div
                :class="hoverIndex === index ? 'stepper-raised' : ''"
                class="stepper-qty stepper stepper-small stepper-init margin-top-half"
              >
                <div
                  :class="
                    hoverIndex === index
                      ? 'display-inline-block'
                      : 'display-none'
                  "
                  class="stepper-button-minus"
                  @click="minusPlusQty(index, 'minus')"
                ></div>
                <cell-number-input
                  :stepper="hoverIndex === index ? 'true' : 'false'"
                  :readonly="!isInlineEdit(product.subCategoryId) || !isEditing"
                  numberType="decimal"
                  :fractionDigits="2"
                  placeholder="Actual Quantity"
                  :allowNegativeNumber="false"
                  :value="product.actualQty"
                  @done="
                    updateProductProperty({
                      propName: 'actualQty',
                      value: $event,
                      productId: product.id,
                      index
                    }).then(() => {
                      return updateProductProperty({
                        propName: 'orderQty',
                        value: calculateOrderQty($event, product),
                        productId: product.id,
                        index
                      });
                    })
                  "
                  :suffixes="product.uom"
                ></cell-number-input>
                <div
                  :class="
                    hoverIndex === index
                      ? 'display-inline-block'
                      : 'display-none'
                  "
                  class="stepper-button-plus"
                  @click="minusPlusQty(index, 'plus')"
                ></div>
              </div>
            </td>

            <!-- <td class="label-cell">{{ product.packaging }}</td>
            <td class="numeric-cell">{{ product.unitPack }}</td>
            <td class="numeric-cell">{{ product.unitSize }}</td>
            <td class="label-cell">{{ product.uom }}</td> -->
            <!-- <td class="numeric-cell">{{ product.wasterFactor }}</td> -->

            <td title="Price">
              <cell-number-input
                :stepper="'true'"
                placeholder="Price"
                numberType="currency"
                :value="product.price"
                :readonly="!isEditing"
                @done="
                  updateProductProperty({
                    propName: 'price',
                    value: $event,
                    productId: product.id,
                    index
                  }).then(() => {
                    updateProductProperty({
                      propName: 'priceOverride',
                      value: true,
                      productId: product.id,
                      index
                    });
                  })
                "
              ></cell-number-input>
            </td>

            <!-- <td class="text-align-right">
              {{
                ((product.orderQty || 0) * (product.price || 0)) | currencyUSD
              }}
            </td> -->

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="text-align-center"
            >
              <f7-checkbox
                :checked="!!product.priceOverride"
                disabled
              ></f7-checkbox>
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad" class="label-cell">
              <f7-link
                v-for="(color, index) in product.colorPhotos || []"
                :key="index"
                @click="clickPhoto(color)"
                :id="`${sectionId}-${product.id}-${color.id}`"
              >
                {{
                  `${color.colorName}${
                    index + 1 === (product.colorPhotos || []).length
                      ? ""
                      : ",&nbsp;"
                  }`
                }}
              </f7-link>
              <!-- {{ product.colorPhotos }} -->
            </td>
            <!-- <td class="label-cell">{{ vendorName(product.vendorId) }}</td> -->
            <td v-if="$f7.device.desktop || $f7.device.ipad" class="label-cell">
              {{ product.manufacturer }}
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad">
              <ul class="technical-data">
                <li
                  v-for="(attach, index) in product.technicalData"
                  :key="index"
                  :id="`${product.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`">
                  <a class="display-block" @click="openUrlLink(product, attach)">
                    {{ attach.name }}
                  </a>
                </li>
              </ul>
            </td>
            <!-- <td class="numeric-cell">{{ product.actualQty }}</td> -->
            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              title="Waster Factor"
            >
              <cell-number-input
                placeholder="Waster Factor"
                numberType="decimal"
                :value="product.wasterFactor"
                :readonly="!isEditing"
                @done="
                  updateProductProperty({
                    propName: 'wasterFactor',
                    value: $event,
                    productId: product.id,
                    index
                  })
                    .then(() => {
                      return updateProductProperty({
                        propName: 'wasterFactorOverride',
                        value: true,
                        productId: product.id,
                        index
                      });
                    })
                    .then(() => {
                      return updateQuantity({ roofType, sectionId });
                    })
                "
              ></cell-number-input>
            </td>

            <td
              v-if="isEditing"
              class="text-align-right"
              @click="removeProductItem(product)"
            >
              <a href="#">
                <i class="f7-icons" style="color: red;">trash</i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="label-cell no-data" :colspan="headers.length + 1">
              <div>No items!</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <building-add-product-popup
      :roofType="roofType"
      :sectionId="sectionId"
      :popupAddNewProductItem="popupAddNewProductItem"
      :productItemList="productItemList"
      :existingProductItems="items"
      @close="popupAddNewProductItem = false"
      @onAddProductItems="addProductItems($event)"
      :manufacturers="[]"
      :vendorIds="[]"
      :estimateId="''"
      @copyProduct="openAddNewProductPopup($event)"
      ref="addProductPopup"
    />
    <product-editor-popup ref="productEditorRef" />
    <product-sheet ref="productSheetRef"></product-sheet>

    <image-uploader
      ref="fileInput"
      :preview="false"
      :quality="0.7"
      class="display-none"
      accept="image/*"
      doNotResize="gif"
      :autoRotate="false"
      outputFormat="verbose"
      @input="handleFileUpload"
    ></image-uploader>

    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>

    <add-new-product-popup ref="addNewProductPopup"></add-new-product-popup>
  </div>
</template>

<script>
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import ProductAutoCompleteInput from "../inputs/ProductAutoCompleteInput.vue";
import BuildingAddProductPopup from "../popup/BuildingAddProductPopup.vue";
import ProductEditorPopup from "../popup/ProductEditorPopup.vue";
import AddNewProductPopup from "../popup/AddNewProductPopup.vue";
import ProductSheet from "../sheets/ProductSheet.vue";
import LongContentBlock from "@/components/blocks/LongContentBlock.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import { ESTIMATE_PRODUCT_TABLE_HEARDER } from "../../utility/const";
import {
  DEFAULT_STATUS_PRODUCT_ITEM,
  // SECTION_TYPE_TPO_MISCELLANEOUS,
  SECTION_TYPE_TPO_LABOR,
  SECTION_TYPE_SHINGLE_METAL_AND_EDGE,
  SECTION_TYPE_TPO_METAL_AND_EDGE,
  SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
  STATUS_PRODUCT_ITEM_WFA
} from "../../../../utility/const";
import { isVideo, isImage, createMediaArray, openAttackLink } from "@/utility/common";
import buildingMixins from "../../utility/building-mixins";

export default {
  components: {
    CellNumberInput,
    ProductAutoCompleteInput,
    BuildingAddProductPopup,
    ProductEditorPopup,
    AddNewProductPopup,
    ProductSheet,
    LongContentBlock
  },

  mixins: [buildingMixins],

  props: {
    isEditing: { type: Boolean, default: true },
    items: { type: Array, default: () => [] },
    roofType: String,
    sectionId: String,
    alertMessage: String
  },

  data() {
    return {
      popupAddNewProductItem: false,
      photoProductId: "",
      hoverIndex: "",
      stepper: false,
      productPhotos: [],
      SECTION_TYPE_SHINGLE_METAL_AND_EDGE,
      SECTION_TYPE_TPO_METAL_AND_EDGE,
      SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE
    };
  },

  computed: {
    ...mapGetters({
      subCategories: "estimate/estimate-page/estimate/sub-category/objectList",
      categories: "estimate/estimate-page/estimate/category/objectList"
      //   vendors: "estimate/estimate-page/estimate/vendor/objectList"
    }),

    ...mapGetters("estimate/estimate-page/price-list", {
      priceListObject: "priceList"
    }),

    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),

    isMetalAndEdgeSection() {
      return (
        this.sectionId === SECTION_TYPE_SHINGLE_METAL_AND_EDGE ||
        this.sectionId === SECTION_TYPE_TPO_METAL_AND_EDGE ||
        this.sectionId === SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE
      );
    },

    // vendorName() {
    //   return id => {
    //     const vendor = this.vendors.find(r => r.id === id);
    //     return (vendor || {}).companyName;
    //   };
    // },

    subCategoryName() {
      return subCategoryId => {
        return (
          (this.subCategories.find(item => item.id === subCategoryId) || {})
            .name || ""
        );
      };
    },

    // allProductsOfPriceList() {
    //   return _.cloneDeep(this.priceListObject.productRefs || [])
    //     .filter(item => item.product.status === DEFAULT_STATUS_PRODUCT_ITEM)
    //     .map(r => r.product);
    // },

    productItemList() {
      return ((this.priceListObject || {}).productRefs || []).map(item => {
        return {
          ...item.product,
          vendorPrice: item.vendorPrice,
          markup: item.markup,
          price: item.price,
          internalNote: item.internalNote || "",
          proposalNote: item.proposalNote || ""
        };
      });
    },

    // priceListProductItemList() {
    //   return this.productItemList.filter(product => {
    //     return !this.items.find(item => item.id === product.id);
    //   });
    // },

    productListBy() {
      return ({ roofType, categoryId, subCategoryId }) => {
        return this.productItemList.filter(
          item =>
            (item.roofTypes || []).includes(roofType) &&
            item.categoryId === categoryId &&
            item.subCategoryId === subCategoryId &&
            item.status === DEFAULT_STATUS_PRODUCT_ITEM
        );
      };
    },

    sectionTitle() {
      return this.templateSection(this.roofType, this.sectionId).category;
    },

    // TODO: double check this busibness
    isInlineEdit() {
      // eslint-disable-next-line no-unused-vars
      return subCategoryId => {
        return true;
      };
    },
    isLabor() {
      return (
        // this.sectionId == SECTION_TYPE_TPO_MISCELLANEOUS ||
        this.sectionId === SECTION_TYPE_TPO_LABOR
      );
    },

    headers() {
      let h = ESTIMATE_PRODUCT_TABLE_HEARDER;
      h = h.filter(
        item => item.value != "orderQty" && item.value != "totalCost"
      );
      if (this.isLabor) {
        h = h.filter(item => item.value != "Package");
      }
      if (!(this.$f7.device.desktop || this.$f7.device.ipad)) {
        h = h.filter(
          item =>
            item.value != "color" &&
            item.value != "manufacturer" &&
            item.value != "technicalData" &&
            item.value != "waster_factor" &&
            // item.value != "actualQty" &&
            item.value != "priceOverride" &&
            item.value != "orderQty"
        );
      }

      return h;
    },
    categoryBy() {
      return categoryId => {
        return this.categories.find(item => item.id === categoryId) || {};
      };
    },

    sectionBy() {
      return categoryName => {
        return (
          this.estimateTemplate.find(item => item.category === categoryName) ||
          {}
        );
      };
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/product-item", [
      "updateProduct",
      "createProduct",
      "uploadPhoto",
      "getProduct"
    ]),
    ...mapActions("estimate/estimate-page/estimate", [
      "deleteEstimateHistoryBys"
    ]),
    ...mapActions("estimate/estimate-page/price-list", ["addProductRefs"]),

    uploadProductPhoto(productId) {
      this.photoProductId = productId;
      this.$refs.fileInput.$el.lastChild.click();
    },

    handleFileUpload(file) {
      const self = this;
      self.$f7.preloader.show();

      this.uploadPhoto(file)
        .then(({ fullPath, url }) => {
          return {
            thumbnailUrl: url,
            thumbnailFullPath: fullPath,
            url: url,
            name: file.info.name,
            fullPath: fullPath
          };
        })
        .then(photo => {
          return self.updateProduct({
            id: self.photoProductId,
            doc: { photos: [photo] }
          });
        })
        .finally(() => {
          self.photoProductId = "";
          self.$refs.fileInput.$el.lastChild.value = "";
          self.$f7.preloader.hide();
        });
    },

    updateEstimateCalculatedFields() {},
    updateProductProperty({ propName, value, productId, index }) {
      const productItem = _.cloneDeep(
        this.items.find(
          (item, itemIndex) => item.id === productId && index === itemIndex
        ) || {}
      );

      if (_.isEmpty(productItem)) {
        return;
      }
      productItem[propName] = value;
      if (propName === "price") {
        let vendorPrice = 0;
        const markup = _.cloneDeep(productItem.markup) || {
          value: 0,
          type: "percent"
        };
        if (markup.type === "percent") {
          vendorPrice = (100 * value) / (100 + markup.value);
        } else {
          vendorPrice = value - markup.value;
        }
        vendorPrice = parseFloat(vendorPrice.toFixed(2));
        productItem.vendorPrice = vendorPrice;
      }
      return this.updateProductItem({
        roofType: this.roofType,
        sectionId: this.sectionId,
        product: productItem,
        index
      });
    },
    updateEstimateField() {},
    // checkQtyInlineEdit() {},

    changeProductItem(oldProduct, newProduct, index) {
      newProduct = _.cloneDeep(newProduct);
      if (oldProduct.isAddManually) {
        newProduct.isAddManually = true;
        newProduct.actualQty = oldProduct.actualQty || 0;
        newProduct.orderQty = oldProduct.orderQty || 0;
        delete newProduct.priceOverride;
      } else {
        delete newProduct.isAddManually;
        delete newProduct.priceOverride;
      }
      this.$emit("onChangeProductItem", { oldProduct, newProduct, index });
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
    },

    openAddProductPopup() {
      this.popupAddNewProductItem = true;
    },

    async openAddNewProductPopup(data) {
      const addData = await this.$refs.addNewProductPopup.startEditor(
        data && data.item
          ? "Copy Product" : "Create New Product",
        data && data.item
          ? {
              ...data.item,
              status: STATUS_PRODUCT_ITEM_WFA,
              priceListRefs: [this.priceListObject.id]
            }
          : {
              sku: "",
              manufacturer: "",
              productItem: "",
              subCategoryId: "",
              categoryId:
                (this.categories.find(r => r.name === this.sectionTitle) || {})
                  .id || "",
              uom: "",
              roofTypes: [this.currentBuilding.roofType],
              price: "",
              wasterFactor: "",
              size: "",
              vendorId: "",
              color: "",
              status: STATUS_PRODUCT_ITEM_WFA,
              priceListRefs: [this.priceListObject.id]
            }
      );
      if (!addData) return;

      this.$f7.preloader.show();
      const productId = await this.createProduct(addData.productData);
      if (data && data.checked) {
        this.$refs.addProductPopup.addProductItem();
      }
      // update productRefs for product
      this.addProductRefs({
        id: this.priceListObject.id,
        product: {
          productId,
          vendorPrice: addData.productData.price,
          price: addData.price,
          markup: addData.markup,
          internalNote: addData.internalNote,
          proposalNote: addData.proposalNote
        }
      }).then(async () => {
        const product = await this.getProduct(productId);
        const sectionProducts = _.cloneDeep(this.items);
        sectionProducts.push({
          ...product,
          vendorPrice: addData.productData.price,
          price: addData.price,
          markup: addData.markup,
          actualQty: 1,
          orderQty: this.calculateOrderQty(1, product),
          isAddManually: true,
          internalNote: addData.internalNote,
          proposalNote: addData.proposalNote
        });
        this.saveProductData({
          sectionId: this.sectionId,
          productList: sectionProducts,
          category: this.templateSection(this.roofType, this.sectionId)
            .category,
          index: this.templateSection(this.roofType, this.sectionId).index
        }).then(() => {
          this.$f7.preloader.hide();
          if (data && data.item) {
            this.popupAddNewProductItem = false;
          }
        });
        // send mail
        this.sendMailAddNewProduct(product);
      });
    },

    /**
     *
     * @param {*} productIds the list of product ids
     * We will do 3 steps:
     *  1. if the new id is not exist => get the real product object and add to the section
     *  2. if the new id is exsit => keep the old product object => do nothing
     *  3. if the old id is not exist on the new list of new id => remove the product from the section
     */

    async addProductItems(products = []) {
      this.$f7.preloader.show();
      const productIds = products.map(item => item.id);

      //remove all products
      if (_.isEmpty(productIds)) {
        if (this.items.length > 0) {
          const productsRemove = _.cloneDeep(this.items);
          for (const prod of productsRemove) {
            await this.handleRemoveProductItem(prod);
          }
        }
        this.popupAddNewProductItem = false;
        this.$f7.preloader.hide();
        return;
      }

      // Step #1:
      const existingProductIds = (this.items || []).map(item => item.id);
      const newProductToAdd = products.filter(
        item => !existingProductIds.includes(item.id)
      );
      // caculate price and save product to section
      newProductToAdd.forEach(item => {
        item.isAddManually = true;
        item.actualQty = 1;
        item.orderQty = this.calculateOrderQty(1, item);
        delete item.priceOverride;
      });

      const sectionProducts = _.cloneDeep(this.items);
      sectionProducts.push(...newProductToAdd);
      await this.saveProductData({
        sectionId: this.sectionId,
        productList: sectionProducts,
        category: this.templateSection(this.roofType, this.sectionId).category,
        index: this.templateSection(this.roofType, this.sectionId).index
      });
      // Step #2: keep existing products => do nothing

      // Step #3: remove the product from the section
      const oldProductIds = existingProductIds.filter(
        id => !productIds.includes(id)
      );
      for (const id of oldProductIds) {
        const orgProduct = await this.getProduct(id);
        if (!_.isEmpty(orgProduct)) {
          await this.handleRemoveProductItem(orgProduct);
        }
      }
      this.popupAddNewProductItem = false;

      this.$f7.preloader.hide();
      return;
    },
    async handleRemoveProductItem(product) {
      const sectionProducts = _.cloneDeep(this.items);
      const sectionId = this.sectionBy(
        this.categoryBy(product.categoryId || "").name
      ).sectionId;
      if (sectionProducts.some(r => r.id === product.id)) {
        _.remove(sectionProducts, item => item.id === product.id);
        await this.saveProductData({
          sectionId: sectionId,
          productList: sectionProducts,
          category: this.templateSection(this.roofType, sectionId).category,
          index: this.templateSection(this.roofType, sectionId).index
        });
        // delete product history of building
        await this.deleteEstimateHistoryBys({
          estimateId: this.estimate.id,
          conditions: [
            {
              prop: "productId",
              val: product.id,
              op: "=="
            },
            {
              prop: "buildingId",
              val: this.currentBuilding.id,
              op: "=="
            },
            {
              prop: "sectionId",
              val: sectionId,
              op: "=="
            }
          ]
        });
      }
    },
    removeProductItem(product) {
      this.$ri.dialog.openWarningDialog({
        title: "Remove Product",
        content: "Do you want to remove this product?",
        textButton: "Remove",
        onClick: (_sefl, indexx) => {
          if (indexx === 0) {
            _sefl.app.dialog.close();
          } else if (indexx === 1) {
            this.handleRemoveProductItem(product);
          }
        }
      });
    },
    async onEdit(item) {
      const editData = await this.$refs.productEditorRef.startEditor(
        "Edit product",
        item
      );

      if (!editData) return;
      const sectionProducts = _.cloneDeep(this.items);
      //update product
      let index = sectionProducts.findIndex(r => r.id == editData.id);
      if (index < 0) {
        return;
      }
      sectionProducts[index] = editData;

      this.saveProductData({
        sectionId: this.sectionId,
        productList: sectionProducts
      });
    },
    minusPlusQty(index, type) {
      const sectionProducts = _.cloneDeep(this.items);

      if (type === "minus") {
        const actualQty = (sectionProducts[index].actualQty || 0) - 1;
        if (actualQty < 0) {
          this.updateProductProperty({
            propName: "actualQty",
            value: 0,
            productId: sectionProducts[index].id,
            index
          }).then(() => {
            return this.updateProductProperty({
              propName: "orderQty",
              value: this.calculateOrderQty(0, sectionProducts[index]),
              productId: sectionProducts[index].id,
              index
            });
          });
        } else {
          this.updateProductProperty({
            propName: "actualQty",
            value: actualQty,
            productId: sectionProducts[index].id,
            index
          }).then(() => {
            return this.updateProductProperty({
              propName: "orderQty",
              value: this.calculateOrderQty(actualQty, sectionProducts[index]),
              productId: sectionProducts[index].id,
              index
            });
          });
        }
      } else if (type === "plus") {
        const actualQty = (sectionProducts[index].actualQty || 0) + 1;
        this.updateProductProperty({
          propName: "actualQty",
          value: actualQty,
          productId: sectionProducts[index].id,
          index
        }).then(() => {
          return this.updateProductProperty({
            propName: "orderQty",
            value: this.calculateOrderQty(actualQty, sectionProducts[index]),
            productId: sectionProducts[index].id,
            index
          });
        });
      }
    },
    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : "text-align-left";
    },
        
    openUrlLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },
    createColorPhotoTooltip() {
      this.items.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const el = this.$f7.tooltip.get(
            `#${this.sectionId}-${item.id}-${color.id}`
          );
          if (el) {
            this.$f7.tooltip.destroy(
              `#${this.sectionId}-${item.id}-${color.id}`
            );
          }
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: photo ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
      this.items.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          if (el1) {
            this.$f7.tooltip.destroy(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)? `<video class="tooltip-image-show" src="${item?.url}" ></video>`:`<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass: isImage(item.url) || isVideo(item.url) ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
    }
  },
  updated() {
    this.createColorPhotoTooltip();
  }
};
</script>

<style lang="scss" scoped>
.title-alert {
  color: var(--f7-theme-color);
}

.no-data {
  text-align: center;
  color: grey;
}
//mobile css
@media (min-width: 320px) and (max-width: 480px) {
  .card.data-table {
    overflow: auto;
  }
}

.section-product-table {
  table:first-child {
    table-layout: fixed;

    //Product Item
    th:nth-child(1) {
      width: 200px;
    }

    //Note
    th:nth-child(2) {
      width: 200px;
    }

    // sub category
    th:nth-child(3) {
      width: 100px;
    }
    // Package Qty
    th:nth-child(4) {
      width: 100px;
    }
    // Price
    th:nth-child(5) {
      width: 100px;
    }
    // Price Override
    th:nth-child(6) {
      width: 110px;
    }

    // Color
    th:nth-child(7) {
      width: 80px;
    }
    // Manufacturer
    th:nth-child(8) {
      width: 120px;
    }
    // Technical Data
    th:nth-child(9) {
      width: 200px;
    }
    // Waste Factor
    th:nth-child(10) {
      width: 150px;
    }
    // Action
    th:nth-child(11) {
      width: 70px;
    }

    td {
      padding: 10px;
      word-wrap: break-word;
    }
  }
}

.section-product-table::v-deep tbody tr td:nth-child(4) {
  ul {
    width: 100%;
    padding-left: 20px;
    li {
      a {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .section-product-table table:first-child {
    th {
      padding: 0px 10px;
    }
    //Product Item
    th:nth-child(1) {
      width: 140px;
    }
    // Package Qty
    th:nth-child(2) {
      width: 100px;
    }
    // Qty
    th:nth-child(3) {
      width: 100px;
    }
    // Price
    th:nth-child(4) {
      width: 120px;
    }
    // Total Cost
    th:nth-child(5) {
      width: 150px;
    }
    // Action
    th:nth-child(6) {
      width: 70px;
    }
  }

  .section-product-table ::v-deep tbody tr {
    td {
      padding: 0px 10px;
    }
  }
}
.input-note ::v-deep .item-content {
  padding-left: 0;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

 .technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
