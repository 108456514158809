<template>
  <!-- step -->
  <div
    class="display-flex navigator-container"
    :style="`background-color: ${workStepBy(currentStep).color}`"
  >
    <div class="pre-btn" @click="onPrevious">
      <span :class="{ hidden: stepIndex == 0 }">
        <i class="icon f7-icons">chevron_left</i>
      </span>
    </div>
    <div class="step-container active">
      <span>{{ workStepBy(currentStep).displayName }} </span>
    </div>
    <div
      v-show="workSteps.length > 1"
      class="next-btn"
      @click="stepIndex != workSteps.length - 1 ? onNext() : onLastStepNext()"
    >
      <span>
        <i class="icon f7-icons">chevron_right</i>
      </span>
    </div>
  </div>
</template>

<script>
import {
  STEP_INFORMATION,
  STEP_SERVICE_INFORMATION,
  COMMON_SERVICE_STEPS
} from "@/utility/const";
import { mapGetters } from "vuex";
import _ from "lodash";
import buildingMixins from "../../utility/building-mixins";
import { BUSINESS_CODE_SERVICE } from "../../../../utility/const";

export default {
  props: {
    roofTypeCode: String,
    isNew: { type: Boolean, default: true }
  },

  mixins: [buildingMixins],

  data() {
    return {
      currentStep: "",
      STEP_INFORMATION,
      STEP_SERVICE_INFORMATION,
      COMMON_SERVICE_STEPS
    };
  },

  mounted() {
    this.currentStep = this.defaultStep.stepId;
  },

  computed: {
    ...mapGetters("common/app-constant", ["roofTypeBy", "workStepBy"]),
    ...mapGetters("estimate/estimate-page/estimate", ["estimate"]),
    ...mapGetters("estimate/estimate-page/estimate", ["currentBuilding"]),
    workSteps() {
      const defaultSteps = [this.defaultStep];

      if (_.isEmpty(this.estimate)) {
        return defaultSteps;
      }

      let steps = this.roofTypeBy(this.roofTypeCode).workSteps || {};

      if (
        _.isEmpty(steps) &&
        this.currentBuilding &&
        this.currentBuilding.roofType === "all-roof-type"
      ) {
        steps = this.COMMON_SERVICE_STEPS;
      }

      return steps[this.estimate.businessCode] || defaultSteps;
    },
    stepIndex() {
      return (
        (this.workSteps.find(r => r.stepId == this.currentStep) || {}).index ||
        0
      );
    },

    stepActives() {
      return this.currentBuilding.stepActives || [this.defaultStep.stepId];
    },

    defaultStep() {
      return {
        stepId:
          this.estimate.businessCode === BUSINESS_CODE_SERVICE
            ? this.STEP_SERVICE_INFORMATION
            : this.STEP_INFORMATION,
        index: 0
      };
    }
  },

  methods: {
    onClickStep(type) {
      this.$emit("onClickStep", type);
    },
    onPrevious() {
      this.$emit("onPrevious");
    },
    onNext() {
      this.$emit("onNext");
    },
    onLastStepNext() {
      this.$emit("onLastStepNext");
    },
    setStepActives(value) {
      this.currentStep = value;
      if (
        !this.stepActives.includes(value) &&
        !_.isEmpty(this.currentBuilding)
      ) {
        this.onSaveStepActives([...this.stepActives, value]);
      }
    },
    onSaveStepActives(steps) {
      this.saveBuildingValue("stepActives", steps);
    },
    isStepActived(value) {
      return this.stepActives.includes(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.navigator-container {
  margin-top: 2px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  color: white;
  font-weight: 800;
  font-size: 16px;
  .step-container {
    width: 100%;
    text-align: center;
  }
}
.pre-btn {
  width: 60px;
  display: flex;
  justify-content: center;
}
.next-btn {
  width: 60px;
  display: flex;
  justify-content: center;
}
.hidden {
  display: none;
}
</style>
