<template>
  <f7-page class="page-main">
    <!-- Navigation bar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Commercial</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <board-toolbar></board-toolbar>
    <dashboard-menu @onToNewProject="toNewProject"></dashboard-menu>

    <project-grid-view-board
      ref="gridView"
      @openCardDetailsPopup="openCardDetailsPopup"
    ></project-grid-view-board>

    <!-- Popup on desktop -->
    <project-details-popup
      ref="projectDetailsPopup"
      :cardNumber="$f7route.params.cardNumber"
      :actionId="$f7route.params.actionId"
      @navigateToBoard="navigateToBoard"
      @showSetting="openSettingPopup"
    ></project-details-popup>

    <project-setting-popup
      ref="projectSettingPopup"
      @settingChanged="handleSettingChanged"
    ></project-setting-popup>

    <!-- <f7-panel ref="rightPanel" right reveal :visibleBreakpoint="1024">
      <f7-view>
        <f7-page>
          <f7-navbar title="Activities"></f7-navbar>
        </f7-page>
      </f7-view>
    </f7-panel> -->
  </f7-page>
</template>

<script>
import BoardToolbar from "../../components/toolbar/BoardToolbar.vue";
import ProjectGridViewBoard from "../board/ProjectGridViewBoard.vue";
import ProjectDetailsPopup from "../../components/popup/ProjectDetailsPopup.vue";
import ProjectSettingPopup from "../../components/popup/ProjectSettingPopup.vue";
import DashboardMenu from "../../components/menu/DashboardMenu.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { ACTION_CODE_CONSTRUCTION } from "../../../../utility/const";
import Menu from "../../../../components/menu/Menu.vue";

export default {
  components: {
    ProjectGridViewBoard,
    BoardToolbar,
    DashboardMenu,
    Menu,
    ProjectSettingPopup,
    ProjectDetailsPopup
  },

  computed: {
    ...mapGetters("dashboard/project", ["boardCode", "cardList"])
  },

  created() {
    this.$f7.preloader.show();
    const promises = [];
    promises.push(this.bindClientData());
    promises.push(this.getConstantTypeList());
    promises.push(
      this.setCurrentBusinessCode(
        this.$f7route.route.meta.businessCode ||
          this.$f7route.params.businessCode
      )
    );

    if (_.isEmpty(this.users)) {
      promises.push(this.bindUserData());
    }
    promises.push(
      this.bindCardList({
        businessCode: this.$f7route.route.meta.businessCode,
        boardCode: this.$f7route.route.meta.boardCode
      })
    );
    Promise.all(promises)
      .then(() => {
        if (
          this.$f7route.params.cardNumber &&
          this.$f7route.params.actionCode
        ) {
          const card = this.cardList.find(
            r => r.cardNumber === this.$f7route.params.cardNumber
          );
          this.getActionBys([
            {
              prop: "code",
              val: Number(this.$f7route.params.actionCode),
              op: "=="
            }
          ]).then(action => {
            if (
              _.isEmpty(action) ||
              (card && !(card.actions || []).includes(action[0].id))
            ) {
              this.$f7.notification
                .create({
                  title: "Not found action",
                  closeTimeout: 15000,
                  closeButton: true,
                  text: `Not found action code <b>${this.$f7route.params.actionCode}</b>. Please choose another action code.`,
                  closeOnClick: true
                })
                .open();
              return;
            }
            this.setCurrentCard(this.$f7route.params.cardNumber);
            this.setCurrentAction(action[0].id);
            this.setCurrentResponse(action[0].id);
            this.$refs.projectDetailsPopup.initCardData(
              this.$f7route.params.cardNumber,
              action[0].id
            );
            this.$refs.projectDetailsPopup.openPopup(false);
          });
        }

        if (this.$f7route.route.meta.isNew) {
          this.$refs.projectDetailsPopup.openPopup(true);
        }
      })
      .finally(() => {
        this.$f7.preloader.hide();
      });
  },

  // mounted() {
  //   if (this.$f7.device.desktop || this.$f7.device.ipad) {
  //     this.$refs.rightPanel.open();
  //   }
  // },

  methods: {
    ...mapActions("dashboard/project", [
      "setCurrentBusinessCode",
      "bindCardList",
      "setCurrentCard",
      "setCurrentAction",
      "setCurrentResponse",
      "bindUserData",
      "getActionBys",
      "getAction"
    ]),
    ...mapActions("dashboard/client", ["bindClientData"]),
    ...mapActions("dashboard/app-constant", ["getConstantTypeList"]),

    toNewProject() {
      window.history.pushState("", "", `/dashboard/grid/commercial/sales/new`);
      this.$refs.projectDetailsPopup.openPopup(true);
    },

    async openCardDetailsPopup({ actionId, cardNumber }) {
      if (_.isEmpty(cardNumber) || _.isEmpty(actionId)) {
        return;
      }
      // get action by id
      const action = await this.getAction(actionId);
      this.setCurrentCard(cardNumber);
      this.$f7router.updateCurrentUrl(
        `/dashboard/grid/${this.$f7route.route.meta.businessCode}/${this.boardCode}/project/${cardNumber}/action/${action.code}`
      );
      this.$refs.projectDetailsPopup.initCardData(cardNumber, actionId);
      this.$refs.projectDetailsPopup.openPopup(false);
    },

    navigateToBoard() {
      window.history.pushState(
        "",
        "",
        `/dashboard/grid/${this.$f7route.route.meta.businessCode}/${this.boardCode}`
      );
    },

    openNewProjectPopup() {
      this.$refs.projectDetailsPopup.openPopup(true);
    },

    openSettingPopup() {
      this.$refs.projectSettingPopup.openPopup();
    },

    handleSettingChanged({ code, data }) {
      const self = this;
      if (code === "re-open-popup") {
        if (data.actionCode === ACTION_CODE_CONSTRUCTION) {
          this.$refs.projectDetailsPopup.closePopup(() => {
            self.$f7router.navigate(
              `/dashboard/${self.$f7route.route.meta.boardType}/commercial/construction/project/${data.cardNumber}`,
              {
                reloadAll: true,
                pushState: true
              }
            );
          });
        } else {
          this.$refs.projectDetailsPopup.closePopup(() => {
            self.openCardDetailsPopup({
              actionId: data.actionId,
              cardNumber: data.cardNumber
            });
          });
        }
      }
    }
  }
};
</script>
