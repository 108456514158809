<template>
  <div>
    <f7-list :inset="$device.desktop">
      <f7-row class="full-row">
        <f7-col width="100" xsmall="50">
          <f7-block-title>
            Metal Diagram
          </f7-block-title>
          <f7-list
            media-list
            :inline-labels="$f7.device.desktop || $f7.device.ipad"
          >
            <f7-list-item
              v-for="(assembly, index) in currentBuilding.assemblyList"
              :key="index"
            >
              <a
                class="cursor-pointer"
                slot="title"
                @click="openAssemblyPopup(assembly)"
              >
                {{
                  assembly.drawingName ||
                    assemblyById(assembly.assemblyItem).displayName
                }}
              </a>
              <div slot="footer">
                {{ assemblyById(assembly.assemblyItem).displayName }}
              </div>
            </f7-list-item>
          </f7-list>
        </f7-col>

        <f7-col width="100" xsmall="50">
          <f7-block-title v-if="currentBuilding.workType != WORK_TYPE_NEW"
            >Core Sample Photo</f7-block-title
          >

          <f7-list
            media-list
            :inline-labels="$f7.device.desktop || $f7.device.ipad"
            v-if="currentBuilding.workType != WORK_TYPE_NEW"
          >
            <f7-list-item
              v-for="(item, index) in currentBuilding.attachmentFiles"
              :key="index"
            >
              <a
                class="cursor-pointer"
                slot="title"
                @click="openLink(item.url)"
              >
                {{ item.fileName }}
              </a>
              <div slot="footer">
                Uploaded on {{ toDisplayDateString(item.createdAt) }} by
                {{ item.createdBy }}
              </div>
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
    </f7-list>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="onPopupClosed"
      @popup:opened="onPopupOpened"
      tablet-fullscreen
    >
      <f7-page>
        <f7-navbar
          :title="
            assembly.assemblyName ||
              assemblyById(assembly.assemblyItem).displayName
          "
        >
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <downspout-transition
            v-if="assembly.assemblyItem === 'downspoutTransition'"
            :ref="`downspoutTransition`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_downspoutTransition`"
          />

          <!-- diagram 2 -->
          <box-downspout
            v-if="assembly.assemblyItem === 'boxDownspout'"
            :ref="`boxDownspout`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_boxDownspout`"
          />

          <!-- diagram 3 -->
          <collector-head
            v-if="assembly.assemblyItem === 'collectorHead'"
            :ref="`collectorHead`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_collectorHead`"
          />

          <!-- diagram 4 -->
          <embedded
            v-if="assembly.assemblyItem === 'embedded'"
            :ref="`embedded`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_embedded`"
          />

          <!-- diagram 5 -->
          <gutter-system
            v-if="assembly.assemblyItem === 'gutterSystem'"
            :ref="`gutterSystem`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_gutterSystem`"
          />

          <!-- diagram 6 -->
          <headwall-flashing
            v-if="assembly.assemblyItem === 'headwallFlashing'"
            :ref="`headwallFlashing`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_headwallFlashing`"
          />

          <!-- diagram 7 -->
          <counter-flashing
            v-if="assembly.assemblyItem === 'counterFlashing'"
            :ref="`counterFlashing`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_counterFlashing`"
          />

          <!-- diagram 8 -->
          <coping-with-cleat-fastener
            v-if="assembly.assemblyItem === 'copingWithCleatFastener'"
            :ref="`copingWithCleatFastener`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_copingWithCleatFastener`"
          />

          <!-- diagram 9 -->
          <pitch-pan
            v-if="assembly.assemblyItem === 'pitchPan'"
            :ref="`pitchPan`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_pitchPan`"
          />

          <!-- diagram 10 -->
          <coping-with-cleat
            v-if="assembly.assemblyItem === 'copingWithCleat'"
            :ref="`copingWithCleat`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_copingWithCleat`"
          />

          <!-- diagram 11 -->
          <coping-with-front-back-cleat
            v-if="assembly.assemblyItem === 'copingWithFrontBackCleat'"
            :ref="`copingWithFrontBackCleat`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_copingWithFrontBackCleat`"
          />

          <!-- diagram standing seam -->
          <valley
            v-if="assembly.assemblyItem === 'valley'"
            :ref="`valley`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_valley`"
          />

          <sidewall-or-headwall
            v-if="assembly.assemblyItem === 'sidewallOrHeadwall'"
            :ref="`sidewallOrHeadwall`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_sidewallOrHeadwall`"
          />

          <ridge
            v-if="assembly.assemblyItem === 'ridge'"
            :ref="`ridge`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_ridge`"
          />

          <zee-trim
            v-if="assembly.assemblyItem === 'zeeTrim'"
            :ref="`zeeTrim`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_zeeTrim`"
          />

          <extended-eave-or-rake
            v-if="assembly.assemblyItem === 'extendedEaveOrRake'"
            :ref="`extendedEaveOrRake`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_extendedEaveOrRake`"
          />
        </f7-block>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { WORK_TYPE_NEW } from "../../../../utility/const";
import { toDisplayDateString } from "../../../../utility/datetime";

import _ from "lodash";

import CopingWithCleatFastener from "../canvas/CopingWithCleatFastener.vue";
import CopingWithCleat from "../canvas/CopingWithCleat.vue";
import CopingWithFrontBackCleat from "../canvas/CopingWithFrontBackCleat.vue";
import Embedded from "../canvas/Embedded.vue";
import CollectorHead from "../canvas/CollectorHead.vue";
import DownspoutTransition from "../canvas/DownspoutTransition.vue";
import BoxDownspout from "../canvas/BoxDownspout.vue";
import GutterSystem from "../canvas/GutterSystem.vue";
import HeadwallFlashing from "../canvas/HeadwallFlashing.vue";
import PitchPan from "../canvas/PitchPan.vue";
import CounterFlashing from "../canvas/CounterFlashing.vue";

// Standing Seam
import Valley from "../canvas/Valley.vue";
import SidewallOrHeadwall from "../canvas/SidewallOrHeadwall.vue";
import Ridge from "../canvas/Ridge.vue";
import ZeeTrim from "../canvas/ZeeTrim.vue";
import ExtendedEaveOrRake from "../canvas/ExtendedEaveOrRake.vue";

export default {
  components: {
    CopingWithCleatFastener,
    CopingWithCleat,
    CopingWithFrontBackCleat,
    Embedded,
    CollectorHead,
    DownspoutTransition,
    BoxDownspout,
    GutterSystem,
    HeadwallFlashing,
    PitchPan,
    CounterFlashing,

    Valley,
    SidewallOrHeadwall,
    Ridge,
    ZeeTrim,
    ExtendedEaveOrRake
  },

  data() {
    return {
      toDisplayDateString,
      WORK_TYPE_NEW,
      popupOpened: false,
      assembly: {}
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),

    ...mapGetters("estimate/estimate-page/assembly-drawing", {
      assemblyById: "assemblyDrawingById"
    })
  },

  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },

    openAssemblyPopup(assembly) {
      this.assembly = _.cloneDeep(assembly);
      this.popupOpened = true;
    },

    onPopupClosed() {
      this.popupOpened = false;
      this.assembly = {};
    },

    onPopupOpened() {
      this.drawAssemply(this.assembly);
    },

    drawAssemply(assembly) {
      switch (assembly.assemblyItem) {
        case "collectorHead":
          this.$refs[`collectorHead`].initCanvas();
          this.$refs[`collectorHead`].drawAll();
          break;

        case "embedded":
          this.$refs[`embedded`].initCanvas();
          this.$refs[`embedded`].drawAll();
          break;

        case "copingWithCleatFastener":
          this.$refs[`copingWithCleatFastener`].initCanvas();
          this.$refs[`copingWithCleatFastener`].drawAll();
          break;

        case "copingWithCleat":
          this.$refs[`copingWithCleat`].initCanvas();
          this.$refs[`copingWithCleat`].drawAll();
          break;

        case "copingWithFrontBackCleat":
          this.$refs[`copingWithFrontBackCleat`].initCanvas();
          this.$refs[`copingWithFrontBackCleat`].drawAll();
          break;

        case "downspoutTransition":
          this.$refs[`downspoutTransition`].initCanvas();
          this.$refs[`downspoutTransition`].drawAll();
          break;

        case "boxDownspout":
          this.$refs[`boxDownspout`].initCanvas();
          this.$refs[`boxDownspout`].drawAll();
          break;

        case "gutterSystem":
          this.$refs[`gutterSystem`].initCanvas();
          this.$refs[`gutterSystem`].drawAll();
          break;

        case "headwallFlashing":
          this.$refs[`headwallFlashing`].initCanvas();
          this.$refs[`headwallFlashing`].drawAll();
          break;

        case "pitchPan":
          this.$refs[`pitchPan`].initCanvas();
          this.$refs[`pitchPan`].drawAll();
          break;

        case "counterFlashing":
          this.$refs[`counterFlashing`].initCanvas();
          this.$refs[`counterFlashing`].drawAll();
          break;

        case "valley":
          this.$refs[`valley`].initCanvas();
          this.$refs[`valley`].drawAll();
          break;

        case "sidewallOrHeadwall":
          this.$refs[`sidewallOrHeadwall`].initCanvas();
          this.$refs[`sidewallOrHeadwall`].drawAll();
          break;

        case "ridge":
          this.$refs[`ridge`].initCanvas();
          this.$refs[`ridge`].drawAll();
          break;

        case "zeeTrim":
          this.$refs[`zeeTrim`].initCanvas();
          this.$refs[`zeeTrim`].drawAll();
          break;

        case "extendedEaveOrRake":
          this.$refs[`extendedEaveOrRake`].initCanvas();
          this.$refs[`extendedEaveOrRake`].drawAll();
          break;
      }
    }
  }
};
</script>
