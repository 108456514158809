<template>
  <f7-popup class="demo-popup" :opened="isShow" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Shift</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">
            Done
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <schedule-detail ref="scheduleDetail" :isShow="isShow" :isInvalid="isInvalid" @onChangeObject="onChangeObject"
        @onChangeChainObject="onChangeChainObject" @onChangeUserIds="onChangeUserIds"></schedule-detail>
    </f7-page>
  </f7-popup>
</template>
<script>
import ScheduleDetail from "../detail/ScheduleDetail.vue";

import { useVuelidate } from '@vuelidate/core'
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import emailNotificationMixins from "../../mixins/email-notifications";

const TIME_FORMAT = "HH:mm";
import {
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  COLLECTION_MY_SCHEDULING
} from "../../../../utility/const";

export default {
  components: {
    ScheduleDetail
  },
  props: {
    isShow: Boolean
  },
  data: () => {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      isInvalid: false,
      currentProjectId: null,
      object: {},
      chainObject: {},
      userIds: []
    };
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  mixins: [emailNotificationMixins],
  methods: {
    ...mapActions("scheduling/project", [
      "handleUpdateAssigneeIdsWhenAddOrCopy",
      "updateDueDate"
    ]),
    ...mapActions("scheduling/chain", ["createChain"]),
    ...mapActions("scheduling/scheduling", [
      "createSchedule",
      "getSchedulesByUserAndProject",
      "getSchedulesByUserAndDate"
    ]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    cancel() {
      this.isInvalid = false;
      this.object = {};
      this.chainObject = {};
      this.$emit("close");
      this.$refs.scheduleDetail.v$.$reset();
      this.$refs.scheduleDetail.clearData();
    },

    onChangeObject(val) {
      this.object = val;
    },

    onChangeChainObject(val) {
      this.chainObject = val;
    },

    onChangeUserIds(val) {
      this.userIds = val;
    },

    showOverlapErrorDialog(userIds) {
      this.$ri.dialog.openErrorDialog({
        title: "Error",
        content: `${this.userByIds(
          userIds
        )} have been overlapping time. Please recheck the time?`,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        }
      });
    },

    handleDataBeforeAdd() {
      let startDate = moment(this.chainObject.startDate[0]);
      const endDate = moment(this.chainObject.endDate[0]);
      let dates = [];
      dates.push(startDate.format("MM/DD/YYYY"));
      while (!startDate.isSame(endDate, "day")) {
        startDate = startDate.add(1, "days");
        dates.push(startDate.format("MM/DD/YYYY"));
      }
      let data = [];
      if (this.chainObject.daysSelected.length > 0) {
        dates = dates.filter(r => {
          return this.chainObject.daysSelected.includes(
            moment(r, "MM/DD/YYYY").format("dd")
          );
        });
      }
      (dates || []).forEach(r => {
        data.push({
          ...this.object,
          date: this.$google.firebase.firestore.Timestamp.fromDate(new Date(r))
        });
      });
      return data;
    },

    async validateForAddChain(startDate, endDate, userIds) {
      let userError = [];
      for (let userId of userIds) {
        const res = await this.getSchedulesByUserAndDate({
          startDate,
          endDate,
          userId
        });
        const data = this.handleDataBeforeAdd();
        if (res.length > 0) {
          for (const currentData of data) {
            const start = moment(currentData.startTime, TIME_FORMAT);
            const end = moment(currentData.finishTime, TIME_FORMAT);
            for (const availableData of res) {
              const startTime = moment(availableData.startTime, TIME_FORMAT);
              const finishTime = moment(availableData.finishTime, TIME_FORMAT);
              if (
                startTime.isBetween(start, end) ||
                start.isBetween(startTime, finishTime) ||
                start.isSame(startTime) ||
                finishTime.isBetween(start, end) ||
                end.isBetween(startTime, finishTime) ||
                end.isSame(finishTime)
              ) {
                userError.push(userId);
              }
            }
          }
        }
      }
      return userError;
    },

    async add() {
      const { startDate, endDate, daysSelected } = this.chainObject;
      this.$refs.scheduleDetail.v$.$touch();
      if (
        this.$refs.scheduleDetail.v$.$invalid ||
        (!moment(startDate[0]).isSame(moment(endDate[0])) &&
          !this.$refs.scheduleDetail.isShowSelectDay() &&
          daysSelected.length === 0)
      ) {
        this.isInvalid = true;
        return;
      } else {
        this.$f7.preloader.show();
        this.isInvalid = false;
        const { timeLogType, projectId, timeOffType, title } = this.object;
        // clean data before add
        this.object = {
          ...this.object,
          projectId: timeLogType === TIME_LOG_TYPE_PROJECT ? projectId : null,
          timeOffType:
            timeLogType === TIME_LOG_TYPE_TIME_OFF ? timeOffType : null,
          title: timeLogType === TIME_LOG_TYPE_OTHERS ? title : ""
        };
        // clean data before add
        this.chainObject.daysSelected = !this.$refs.scheduleDetail.isShowSelectDay()
          ? daysSelected
          : [];
        const startDateTimeStamp = this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(moment(startDate[0]).format("MM/DD/YYYY"))
        );
        const endDateTimeStamp = this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(moment(endDate[0]).format("MM/DD/YYYY"))
        );

        let promises = [];

        // vaildate chain
        const usersInValidChain = await this.validateForAddChain(
          startDateTimeStamp,
          endDateTimeStamp,
          this.userIds
        );
        if (usersInValidChain.length < 1) {
          // assignee for project service
          promises.push(
            this.handleUpdateAssigneeIdsWhenAddOrCopy({
              newSchedule: this.object,
              userIds: this.userIds
            })
          );
          let chainId = null;
          if (!moment(startDate[0]).isSame(moment(endDate[0]))) {
            chainId = await this.createChain({
              ...this.chainObject,
              startDate: startDateTimeStamp,
              endDate: endDateTimeStamp
            });
          }
          for (let uid of this.userIds) {
            this.object = {
              ...this.object,
              userId: uid
            };
            const data = this.handleDataBeforeAdd();
            if (chainId) {
              data.forEach(r => {
                promises.push(
                  new Promise((resolve, reject) => {
                    let scheduleData = {
                      ...r,
                      scheduleChainId: chainId
                    };
                    this.createSchedule(scheduleData)
                      .then(id => {
                        this.addRemindScheduleStartEmailNotification({
                          ...scheduleData,
                          id: id
                        });
                        resolve(id);
                      })
                      .catch(err => reject(err));
                  })
                );
              });

              let chainData = {
                ...this.chainObject,
                startDate: startDateTimeStamp,
                endDate: endDateTimeStamp,
                id: chainId
              };
              this.sendCreatedChainEmailNotification(chainData, data);
            } else {
              promises.push(
                new Promise((resolve, reject) => {
                  this.createSchedule(data[0])
                    .then(id => {
                      this.addRemindScheduleStartEmailNotification({
                        ...data[0],
                        id: id
                      });
                      resolve(id);
                    })
                    .catch(err => reject(err));
                })
              );
              this.sendCreatedEmailNotification(data[0]);
            }
            if (this.object.projectId) {
              promises.push(
                this.updateDueDate({
                  projectId: this.object.projectId,
                  endDate: moment(this.chainObject.endDate[0])
                })
              );
            }
          }

          Promise.all(promises).then(async () => {
            await this.createNotificationByType({
              data: {
                assignees: this.userIds,
                project: {
                  title: this.object.title,
                  id: this.object.projectId,
                  startDate:startDateTimeStamp,
                  entityName: COLLECTION_MY_SCHEDULING
                }
              },
              type: "add-new-shift-user"
            });
            this.$f7.preloader.hide();
            this.cancel();
          });
        } else {
          this.$f7.preloader.hide();
          this.showOverlapErrorDialog(usersInValidChain);
        }
      }
    }
  },

  computed: {
    ...mapGetters("scheduling/scheduling", ["selectedItem", "schedule"]),
    ...mapGetters("scheduling/user", ["userByIds"])
  }
};
</script>
