// pages
export const COVER_PAGE = "Cover Page";
export const TEXT_EDITOR_PAGE = "Text Editor Page";
export const BUILDING_DESCRIPTION_PAGE = "Building Description Page";
export const BUILDING_PRODUCT_PAGE = "Building Product Page";
export const SUMMARY_ESTIMATE_PAGE = "Summary Estimate Page";
export const IMAGE_PAGE = "Image Page";
export const APPENDIX_PAGE = "Appendix Page";
export const FINANCING_PROPOSAL_PAGE = "Financing Proposal Page";

export const BUILDING_PRODUCT_BREAKED = "Building Product Breaked";
export const IMAGE_BREAKED = "Image Breaked";
export const SUMMARY_BREAKED = "Summary Breaked";

// component types
export const HEADER_TYPE = "header";
export const FOOTER_TYPE = "footer";
export const TEXT_EDITOR_TYPE = "text-editor";
export const TEXT_EDITOR_RESIZABLE_TYPE = "text-editor-resizeable";
export const PRODUCT_TABLE_TYPE = "product-table";
export const SUMMARY_ESTIMATE_TABLE_TYPE = "summary-estimate-table";
export const IMAGE_STATUS_TYPE = "image-status";
export const TEXT_AND_PHOTO_TYPE = "text-and-photo";
export const FINANCING_PROPOSAL_TABLE_TYPE = "financing-proposal-table";

export const NUMBER_PRODUCT_PER_PAGE = 24;
