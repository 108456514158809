import * as types from "./types";
import Vue from "vue";

export default {
  setWorkOrderTaskList(state, payload) {
    Vue.set(state, "workOrderTaskList", payload);
  },
  setWorkOrderTaskSubscribe(state, payload) {
    Vue.set(state, "workOrderTaskSubscribe", payload);
  },
  setPurchaseOrderList(state, payload) {
    Vue.set(state, "purchaseOrderList", payload);
  },
  setPurchaseOrderSubscribe(state, payload) {
    Vue.set(state, "purchaseOrderSubscribe", payload);
  },
  [types.SET_WO_TASK_RESPONSE](state, response) {
    Vue.set(state, "response", response);
  },

  [types.SET_TASK_PROPERTY_BY_ID](state, payload) {
    const taskIndex = state.workOrderTaskList.findIndex(
      task => task.id === payload.taskId
    );

    if (taskIndex > -1) {
      Vue.set(state.workOrderTaskList[taskIndex], payload.prop, payload.value);
    }
  },
  [types.SET_FILTER_SEARCH](state, payload) {
    Vue.set(state, "searchText", payload);
  },

  [types.SET_FILTER_WEEK](state, payload) {
    Vue.set(state, "weekFilter", payload);
  },

  [types.SET_PO_RESPONSE](state, response) {
    Vue.set(state, "poResponse", response);
  },

  [types.SET_PO_LIST](state, purchaseOrderList) {
    Vue.set(state, "purchaseOrderList", purchaseOrderList);
  }
};
