<template>
  <div>
    <f7-block-title v-show="$device.desktop"
      >Embedded Edge With Flat Drip</f7-block-title
    >
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <!-- nail -->
          <img
            class="display-none"
            :id="`vertical-nail-1_${buildingId}`"
            :src="imgNail1"
          />
          <img
            class="display-none"
            :id="`vertical-nail-2_${buildingId}`"
            :src="imgNail1"
          />
          <img
            class="display-none"
            :id="`horizontal-nail_${buildingId}`"
            :src="imgNail1"
          />

          <!-- canvas -->
          <canvas
            v-if="$device.desktop"
            :ref="buildingId"
            :id="buildingId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="buildingId"
            :id="buildingId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>
<script>
import paper from "paper";
import { evaluate } from "mathjs";
import canvasMixin from "../../utility/canvas-mixin";
import { mapGetters } from "vuex";
import _ from "lodash";
import imgNail1 from "@/assets/img/metal-diagrams/nail1.png";
const inchToPx = 40;
const radius = 5;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    photoSelected: String,
    buildingId: String,
    items: { type: Array, default: () => [] },
    isDark: { type: Boolean, default: true }
  },

  data() {
    return {
      imgNail1,
      scope: null,
      dataToPx: {},
      draws: {},
      space: 300,
      yBase: 100,
      xBase: 120,
      width: 1200,
      height: 1000,
      dark: localStorage.getItem("themeDark") === "true"
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate/sub-category", [
      "subCategoryById"
    ])
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.buildingId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    getProductNameBySubCategoryName(name) {
      return (
        this.items.find(
          r => this.subCategoryById(r.subCategoryId).name === name
        ) || {}
      ).productItem;
    },

    handleDraw1() {
      const { a1, b1 } = this.dataToPx;
      const pointA = new paper.Point(a1 + this.xBase, this.yBase);
      const pointB = new paper.Point(this.xBase, pointA.y);
      const pointC = new paper.Point(pointB.x, pointB.y + b1 - radius);
      const pointD = new paper.Point(pointC.x + radius * 2, pointC.y);
      const pointE = new paper.Point(pointD.x, pointD.y - 1 * inchToPx);

      const path = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? "white" : "black") : "black",
        strokeWidth: 3
      });

      const centerCD = new paper.Point(pointC.x + radius, pointD.y);
      let throughCD = pointC.rotate(-90, centerCD);

      path.add(pointA, pointB, pointC);
      path.arcTo(throughCD, pointD);
      path.add(pointE);

      this.setDraws("draw1Paths", [path]);
      this.handleDrawDescriptionTop(
        this.isDark,
        pointB,
        pointA,
        `a1: ${this.data.a1}`
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointB,
        throughCD,
        `b1: ${this.data.b1}`
      );

      // draw info product
      const startPoint = new paper.Point(pointB.x, pointB.y + b1 / 2);
      const endPoint = new paper.Point(startPoint.x + 50, startPoint.y);

      if (this.getProductNameBySubCategoryName("Assembly Metal")) {
        this.drawInfoProduct(
          this.isDark,
          [startPoint, endPoint],
          this.getProductNameBySubCategoryName("Assembly Metal"),
          "left",
          "right"
        );
      }
    },

    handleDraw2() {
      const { a1, a2, b2, c2, d2 } = this.dataToPx;
      const x = this.xBase + a1 + this.space;
      const y = this.yBase;
      const pointA = new paper.Point(a2 + x, y);
      const pointB = new paper.Point(x, pointA.y);
      const pointC = new paper.Point(pointB.x, pointB.y + b2 - d2);
      let pointD = new paper.Point(pointC.x, pointC.y - c2);
      pointD = pointD.rotate(-120, pointC);
      const pointE = new paper.Point(pointD.x, pointD.y + d2);

      const path = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? "white" : "black") : "black",
        strokeWidth: 3
      });

      path.add(pointA, pointB, pointC, pointD, pointE);
      this.setDraws("draw2Paths", [path]);

      this.handleDrawDescriptionTop(
        this.isDark,
        pointB,
        pointA,
        `a2: ${this.data.a2}`
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointB,
        pointE,
        `b2: ${this.data.b2}`
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointD,
        pointE,
        `d2: ${this.data.d2}`
      );
      this.handleDrawDescriptionDiagonal(
        this.isDark,
        pointC,
        pointD,
        -120,
        0,
        `c2: ${this.data.c2}`
      );

      // draw info product
      const startPoint = new paper.Point(pointB.x, pointB.y + b2 / 2);
      const endPoint = new paper.Point(startPoint.x + 50, startPoint.y);

      if (this.getProductNameBySubCategoryName("Assembly Metal Cleat")) {
        this.drawInfoProduct(
          this.isDark,
          [startPoint, endPoint],
          this.getProductNameBySubCategoryName("Assembly Metal Cleat"),
          "left",
          "right"
        );
      }
    },

    handleDraw3() {
      const x = this.xBase;
      const y = this.yBase;
      const { draw1Paths, draw2Paths } = this.draws;
      const { a1, b1, b2, d2, a3, b3, c3 } = this.dataToPx;
      let path1 = draw1Paths[0].clone();
      path1.position.y += b1 + y;

      let path2 = draw2Paths[0].clone();
      path2.position.x -= this.space + a1 - 18;
      path2.position.y += b1 + y + b1 - b2 - d2 + 25;

      const pointA = new paper.Point(a1 + x + inchToPx, y);
      const pointB = new paper.Point(x, pointA.y);
      const pointC = new paper.Point(pointB.x, pointB.y + b1);

      const path3 = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? "white" : "black") : "black"
      });

      path3.add(pointA, pointB, pointC);
      path3.position.x = path1.position.x + 45;
      path3.position.y += b1 + y + b1 - b2 - d2 + 35;

      this.setDraws("draw3Paths", [path1, path2]);

      // nail
      const firstPointInPath1 = path1.firstSegment.point;
      const secondPointInPath1 = path1.segments[1].point;
      let nail1 = new paper.Raster(`vertical-nail-1_${this.buildingId}`);
      nail1.size = new paper.Size(70, 20);
      nail1.rotate(90);
      nail1.position = new paper.Point(
        firstPointInPath1.x - a3,
        firstPointInPath1.y + 30
      );

      let nail2 = new paper.Raster(`vertical-nail-2_${this.buildingId}`);
      nail2.size = new paper.Size(70, 20);
      nail2.rotate(90);
      nail2.position = new paper.Point(nail1.position.x - b3, nail1.position.y);

      let nail3 = new paper.Raster(`horizontal-nail_${this.buildingId}`);
      nail3.size = new paper.Size(70, 20);
      nail3.position = new paper.Point(
        secondPointInPath1.x + 38,
        secondPointInPath1.y + b1 - c3
      );

      this.handleDrawDescriptionTop(
        this.isDark,
        new paper.Point(firstPointInPath1.x - a3, firstPointInPath1.y),
        firstPointInPath1,
        `a3: ${this.data.a3}`,
        "topRight"
      );
      this.handleDrawDescriptionTop(
        this.isDark,
        new paper.Point(firstPointInPath1.x - b3 - a3, firstPointInPath1.y),
        new paper.Point(firstPointInPath1.x - a3, firstPointInPath1.y),
        `b3: ${this.data.b3}`,
        "topLeft"
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        new paper.Point(secondPointInPath1.x, secondPointInPath1.y + b1 - c3),
        new paper.Point(secondPointInPath1.x, secondPointInPath1.y + b1),
        `c3: ${this.data.c3}`
      );

      const pointBInPath1 = path1.segments[1].point; // point B
      const pointCInPath1 = path1.segments[2].point; // point C
      const pointDInPath1 = path1.segments[4].point; // point D

      // info product
      const centerPointBandCInPath1 = this.pointOnPathByStart(
        pointBInPath1,
        pointCInPath1,
        2
      );
      const end1 = new paper.Point(
        centerPointBandCInPath1.x + 70,
        centerPointBandCInPath1.y
      );
      this.drawInfoProduct(
        this.isDark,
        [centerPointBandCInPath1, end1],
        "Embedded edge (a-type)",
        "left",
        "right"
      );

      // const between2 = new paper.Point(nail3.position.x, nail3.position.y + 15);
      // const end2 = new paper.Point(between2.x + 50, between2.y);
      // this.drawInfoProduct(
      //   [nail3.position, between2, end2],
      //   '1 1/4" LONG GALVANIZED STEEL ROOFING NAIL AT 6" O.C.',
      //   "top",
      //   "right"
      // );

      const end3 = new paper.Point(pointDInPath1.x + 50, pointDInPath1.y);
      this.drawInfoProduct(
        this.isDark,
        [pointDInPath1, end3],
        "Continuously hand crimp hem over cleat",
        "left",
        "right"
      );

      // const start4 = new paper.Point(nail2.position.x, nail2.position.y + 35);
      // const between4 = new paper.Point(start4.x, start4.y + 15);
      // const end4 = new paper.Point(between4.x + 50, between4.y);
      // this.drawInfoProduct(
      //   [start4, between4, end4],
      //   '1 1/4" LONG GALV. STEEL ROOFING NAILS AT 3" O.C. - STAGGERED 1/2"',
      //   "top",
      //   "right"
      // );
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.handleDraw3();
      this.selectedDraw(this.photoSelected);
      this.resizableLayer();
    },

    selectedDraw(name) {
      if (name) {
        const lastChar = name.charAt(name.length - 1);
        (this.draws[`draw${lastChar}Paths`] || []).forEach(
          path => (path.selected = true)
        );
      } else {
        if (this.scope) {
          this.scope.project.activeLayer.selected = false;
        }
      }
    },

    handleClick(event) {
      this.scope.project.activeLayer.selected = false;
      this.$emit("setPhotoSelected", "");

      const strokeBounds = Object.values(this.draws).map(
        r => r[0].strokeBounds
      );

      for (let i = 0; i < strokeBounds.length; i += 1) {
        if (
          event.offsetX > strokeBounds[i].topLeft.x &&
          event.offsetY > strokeBounds[i].topLeft.y &&
          event.offsetX < strokeBounds[i].bottomRight.x &&
          event.offsetY < strokeBounds[i].bottomRight.y
        ) {
          this.$emit("setPhotoSelected", `photo${i + 1}`);
          this.selectedDraw(`photo${i + 1}`);
        }
      }
    }
  },

  // mounted() {
  //   this.scope = new paper.PaperScope();
  //   this.scope.setup(this.buildingId);
  //   this.drawAll();
  // },

  watch: {
    data: {
      handler(val) {
        const keys = ["a1", "b1", "a2", "b2", "c2", "d2", "a3", "b3", "c3"];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true
    },
    photoSelected: {
      handler(val) {
        this.selectedDraw(val);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
