<template>
  <f7-page v-if="priceListDetail">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ priceListDetail.priceListNumber }}</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <template>
      <f7-block>
        <f7-segmented strong tag="p">
          <f7-button
            :active="activeTab === 'price-detail'"
            @click.native="onTab('price-detail')"
            >Info</f7-button
          >
          <f7-button
            :active="activeTab === 'price-history'"
            @click.native="onTab('price-history')"
            >History</f7-button
          >
        </f7-segmented>
      </f7-block>
      <div
        v-show="activeTab === 'price-detail'"
      >
        <EditView ref="priceDetailTab" />
      </div>
      <div v-show="activeTab === 'price-history'">
        <history ref="priceHistoryTab"></history>
      </div>
    </template>
  </f7-page>
  <f7-page v-else>
    <f7-navbar backLink="Back">
      <f7-nav-title>Not found Pricelist</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The pricelist could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EditView from "../components/details/EditView.vue";
import History from "../components/details/History.vue";
import _ from "lodash";

export default {
  components: {
    EditView,
    History
  },
  data() {
    return {
      activeTab: "price-detail"
    };
  },
  computed: {
    ...mapGetters("price-list/price-list-page/price-list", ["priceListDetail"]),
    ...mapGetters({
      categories: "price-list/price-list-page/category/objectList",
      subCategories: "price-list/price-list-page/sub-category/objectList",
      vendors: "price-list/price-list-page/vendor/objectList"
    })
  },
  methods: {
    ...mapMutations("price-list/price-list-page/price-list", [
      "setPriceListDetailNumber"
    ]),
    ...mapActions({
      getCategories: "price-list/price-list-page/category/getCategories",
      getSubCategories:
        "price-list/price-list-page/sub-category/getSubCategories",
      getVendors: "price-list/price-list-page/vendor/getVendors"
    }),
    ...mapActions("price-list/price-list-page/price-list", ["getPriceBys"]),

    onTab(value) {
      this.activeTab = value;
      if (value === "price-history") {
        this.$refs.priceHistoryTab.initData();
      }
    },
    handleBack() {
      this.$f7router.back("/price-list", { force: true });
    }
  },

  created() {
    const promises = [];

    if (this.$f7route.params.priceListNumber) {
      this.$f7.preloader.show();
      this.setPriceListDetailNumber(this.$f7route.params.priceListNumber);
      // get price list by number
      this.getPriceBys([
        {
          prop: "priceListNumber",
          val: this.$f7route.params.priceListNumber,
          op: "=="
        }
      ]).then(priceList => {
        if (priceList.length > 0) {
          if (_.isEmpty(this.categories)) {
            promises.push(this.getCategories());
          }
          if (_.isEmpty(this.subCategories)) {
            promises.push(this.getSubCategories());
          }
          if (_.isEmpty(this.vendors)) {
            promises.push(this.getVendors());
          }

          return Promise.all(promises)
            .then(() => {
              this.$refs.priceDetailTab.createColorPhotoTooltip();
            })
            .finally(() => {
              this.$f7.preloader.hide();
            });
        } else {
          this.$f7.preloader.hide();
          this.$f7.notification
            .create({
              title: "Not found price list",
              closeTimeout: 15000,
              closeButton: true,
              text: `Not found price list <b class='color-red'>${this.$f7route.params.priceListNumber}</b>. Please choose another price list.`,
              closeOnClick: true
            })
            .open();
        }
      });
    }
  }
};
</script>

<style scoped></style>
