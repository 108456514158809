import _ from "lodash";

import estimateService from "../../../services/estimate.service";

export default {
  methods: {
    getPercentValue(percent, value) {
      return _.round(((percent || 0) * (value || 0)) / 100.0, 2);
    },

    doesApplyTaxForMaterial(project, building, estimate) {
      return estimateService.checkApplyTaxForMaterial({
        project,
        building,
        estimate
      });
    },

    doesApplyTaxForLabor(project, building, estimate) {
      return estimateService.checkApplyTaxForLabor({
        project,
        building,
        estimate
      });
    },

    materialSubTotal(building) {
      let result = 0;
      (building.productData || []).forEach(section => {
        if (section.sectionId !== "labor") {
          result += (section.productList || []).reduce(
            (accumulator, item) =>
              (item.orderQty || 0) * (item.price || 0) + accumulator,
            0
          );
        }
      });
      return result;
    },

    materialMisc(estimate, building) {
      return this.getPercentValue(
        (estimate && estimate.miscPercent) || 0,
        this.materialNetSales(estimate, building)
      );
    },

    materialProfit(estimate, building) {
      return this.getPercentValue(
        (estimate && estimate.crossProfitMarginRatio) || 0,
        this.materialNetSales(estimate, building)
      );
    },

    materialTax(project, building, estimate) {
      return this.doesApplyTaxForMaterial(project, building, estimate)
        ? this.getPercentValue(
            (estimate && building.saleTax) || 0,
            this.materialNetSales(estimate, building)
          )
        : 0.0;
    },

    materialNetSales(estimate, building) {
      const materialSubTotal = this.materialSubTotal(building) || 0;
      const crossProfitMarginRatio =
        (estimate && estimate.crossProfitMarginRatio) || 0;
      const miscPercent = (estimate && estimate.miscPercent) || 0;

      return (
        materialSubTotal /
          ((100 - (crossProfitMarginRatio + miscPercent)) / 100) || 0
      );
    },

    laborSubTotal(building) {
      let subTotals = 0;
      (building.productData || []).forEach(section => {
        if (section.sectionId === "labor") {
          subTotals += (section.productList || []).reduce(
            (currentValue, item) =>
              (item.orderQty || 0) * (item.price || 0) + currentValue,
            0
          );
        }
      });
      return subTotals;
    },

    laborNetSales(estimate, building) {
      const laborSubTotal = this.laborSubTotal(building) || 0;
      const crossProfitMarginRatio =
        (estimate && estimate.crossProfitMarginRatio) || 0;

      return laborSubTotal / ((100 - crossProfitMarginRatio) / 100) || 0;
    },

    laborProfit(estimate, building) {
      return this.getPercentValue(
        (estimate && estimate.crossProfitMarginRatio) || 0,
        this.laborNetSales(estimate, building)
      );
    },

    laborTax(project, building, estimate) {
      return this.doesApplyTaxForLabor(project, building, estimate)
        ? this.getPercentValue(
            (estimate && building.saleTax) || 0,
            this.laborNetSales(estimate, building)
          )
        : 0.0;
    },
    totalSubtotal(estimate, building) {
      return (
        this.materialSubTotal(building) +
        this.materialMisc(estimate, building) +
        this.laborSubTotal(building)
      );
    },

    totalProfit(estimate, building) {
      return (
        this.materialProfit(estimate, building) +
        this.laborProfit(estimate, building)
      );
    },

    totalTax(project, building, estimate) {
      return (
        this.materialTax(project, building, estimate) +
        this.laborTax(project, building, estimate)
      );
    },

    totalCost(project, building, estimate) {
      return (
        this.totalSubtotal(estimate, building) +
        this.totalProfit(estimate, building) +
        this.totalTax(project, building, estimate)
      );
    }
  }
};
