<template>
  <div class="comment">
    <f7-block-title v-show="!isClientView">Add a comment</f7-block-title>
    <div class="text-editor-container">
      <f7-text-editor
        v-show="!isClientView"
        :value="displayContent"
        @texteditor:change="onTextEditorChange"
        placeholder="Enter text..."
        mode="popover"
        resizable
        style="padding-right: 30px"
      />
      <div v-if="isRecognizing" class="loader">
        <span
          :class="isRecognizing ? 'stroke' : ''"
          v-for="i in number"
          :key="i"
        ></span>
      </div>
      <f7-icon
        v-if="!isRecognizing"
        @click.native="startRecognition"
        f7="mic_fill"
        color="primary"
        class="text-editor-icon"
      ></f7-icon>
      <f7-icon
        v-else
        @click.native="stopRecognition"
        f7="stop_circle"
        color="primary"
        class="text-editor-icon icon-animation"
        style="top: 10px"
      ></f7-icon>
    </div>
    <f7-block class="save-comment-block">
      <f7-row tag="p">
        <f7-col tag="span">
          <div
            class="input input-dropdown"
            :style="isRecognizing ? 'top: 15px' : ''"
          >
            <select v-model="languageRecord">
              <option
                v-for="language in langs"
                :key="language.value"
                :value="language.value"
                >{{ language.displayName }}</option
              >
            </select>
          </div>
        </f7-col>
        <f7-col tag="span">
          <div v-if="isShowTranslate" class="input input-dropdown">
            <select
              v-model="languageTranslate"
              @change="languageTranslate = $event.target.value"
            >
              <option
                v-for="language in langs"
                :key="language.value"
                :value="language.value"
                >{{ language.displayName }}</option
              >
            </select>
          </div>
        </f7-col>
        <f7-col tag="span">
          <f7-button
            v-if="commentContent != ''"
            raised
            @click.native="saveComment"
            >Save</f7-button
          >
        </f7-col>
      </f7-row>
    </f7-block>
    <div v-for="(item, index) in sortedCommnets" :key="index">
      <f7-block
        strong
        inset
        style="display: flex; align-items: flex-start;"
        :style="isRecognizing ? 'top: 15px' : ''"
      >
        <user-avatar :avatarSize="35" :imageUrl="item.imageUrl"></user-avatar>
        <div style="margin-left: 10px; flex: 1;">
          <div
            style="display: flex; justify-content: space-between; width: 100%;"
          >
            <strong>{{ item.createdBy }}</strong>
            <div style="color: var(--f7-block-footer-text-color);">
              {{ toLocaleString(item.createdAt) }}
            </div>
          </div>
          <p style="word-break: break-word;" v-html="item.htmlContent"></p>
        </div>
      </f7-block>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { firebase, auth } from "@/services/firebase.service";
import _ from "lodash";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { languages } from "../../../../../lang/languages.js";

export default {
  components: {
    UserAvatar
  },
  props: {
    photoId: String,
    comments: Array,
    isClientView: { type: Boolean, default: false }
  },
  data() {
    return {
      commentContent: "",
      recognition: null,
      isRecognizing: false,
      finalTranscript: "",
      interimTranscript: "",
      number: 25,
      intervalId: null,
      langs: languages,
      languageRecord: "en-US",
      languageTranslate: "en-US",
      isShowTranslate: false,
      timer: "00:00",
      countdown: null,
      isClear: false
    };
  },
  mounted() {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert(
        "Your browser does not support the Web Speech API. Please use a supported browser like Google Chrome or Safari."
      );
      return;
    }
    this.recognition = new SpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = this.languageRecord;

    this.recognition.onstart = () => {
      this.isRecognizing = true;
      this.finalTranscript = "";
      this.interimTranscript = "";
    };

    this.recognition.onresult = event => {
      let interimTranscript = "";
      let finalTranscript = "";
      if (this.$device.android) {
        let resultsLength = event.results.length - 1;
        let ArrayLength = event.results[resultsLength].length - 1;
        const transcript = event.results[resultsLength][ArrayLength].transcript;
        if (event.results[resultsLength].isFinal) {
          finalTranscript += transcript + " ";
        } else {
          interimTranscript += transcript;
        }
      } else {
        for (let i = 0; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript + " ";
          } else {
            interimTranscript += transcript;
          }
        }
      }
      this.interimTranscript = interimTranscript;
      this.finalTranscript = finalTranscript;
      this.commentContent = this.finalTranscript;

      //set countdown for stop recognition after 5s
      if (this.interimTranscript !== "") {
        this.resetCountdown();
      } else {
        this.startCountdown();
      }
    };

    this.recognition.onerror = event => {
      this.$ri.dialog.openWarningDialog({
        title: `${event.type}`,
        content: `Please try again, your mic is ${event.error}`,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        }
      });
    };

    this.recognition.onend = () => {
      this.isRecognizing = false;
      this.stopTimer();
      if (this.isClear) {
        this.commentContent = "";
        this.finalTranscript = "";
        this.interimTranscript = "";
        this.isClear = false;
      }
    };
  },
  computed: {
    sortedCommnets() {
      return (this.comments || [])
        .slice()
        .sort((a, b) => b.createdAt - a.createdAt);
    },
    displayContent() {
      return this.isRecognizing ? `${this.timer}` : this.commentContent;
    }
  },
  methods: {
    ...mapActions("swimlane/photo", ["updateProjectPhoto"]),
    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },
    saveComment() {
      let comments = _.cloneDeep(this.comments) || [];
      comments.push({
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        htmlContent: this.commentContent,
        imageUrl: auth.currentUser.photoURL
      });

      this.updateProjectPhoto({
        id: this.photoId,
        doc: {
          comments: comments
        }
      }).then(() => {
        this.stopRecognition();
        this.finalTranscript = "";
        this.interimTranscript = "";
        this.commentContent = "";
      });
    },
    startRecognition() {
      if (this.recognition) {
        this.commentContent = "";
        this.recognition.start();
        this.startTimer();
      }
    },
    stopRecognition() {
      if (this.recognition) {
        this.recognition.stop();
        this.isRecognizing = false;
        this.stopTimer();
        clearTimeout(this.countdown);
      }
    },
    startTimer() {
      this.timer = "00:00";
      let seconds = 0;
      this.intervalId = setInterval(() => {
        seconds++;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.timer = `${String(minutes).padStart(2, "0")}:${String(
          remainingSeconds
        ).padStart(2, "0")}`;
      }, 1000);
    },
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    startCountdown() {
      clearTimeout(this.countdown);
      this.countdown = setTimeout(() => {
        if (!this.interimTranscript && this.isRecognizing) {
          this.stopRecognition();
        }
      }, 5000);
    },
    resetCountdown() {
      clearTimeout(this.countdown);
      this.startCountdown();
    },
    onTextEditorChange(val) {
      if (this.isRecognizing) {
        this.commentContent = this.finalTranscript;
      } else {
        this.commentContent = val;
      }
    },
    clearComment() {
      if (this.isRecognizing) {
        this.isClear = true;
      } else {
        this.commentContent = "";
        this.finalTranscript = "";
        this.interimTranscript = "";
      }
      this.stopRecognition();
    }
  },
  watch: {
    languageRecord: {
      handler(newVal) {
        if (newVal && this.recognition) {
          this.recognition.lang = newVal;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.input-dropdown {
  border: 1px solid gray;
  border-radius: 2px;
  padding: 2px;
  margin-top: 3px;
}
.timer {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.text-editor-container {
  position: relative;
}
.text-editor-icon {
  position: absolute;
  right: 25px;
  bottom: 10px;
  z-index: 9999;
  cursor: pointer;
}

.loader {
  height: 10px;
  width: max-content;
  display: flex;
  align-items: center;
  margin-top: -35px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .stroke {
  display: block;
  position: relative;
  background: #f05034;
  height: 120%;
  width: 2px;
  border-radius: 50px;
  margin: 0 3px;
  animation: animate 0.8s linear infinite;
}
@keyframes animate {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}
@for $i from 1 through 25 {
  .stroke:nth-child(#{$i}) {
    animation-delay: #{0.2 * (($i - 1) % 4)}s;
  }
}
.icon-animation {
  animation: wave 0.8s infinite;
}

@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.2);
    opacity: 0.5;
  }
}
</style>
