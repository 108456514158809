import * as types from "./types";
import Vue from "vue";
export default {
  [types.SET_PAYMENT_HISTORY](state, paymentHistory) {
    Vue.set(state, "paymentHistory", paymentHistory);
  },
  [types.SET_FAIL_INVOICES](state, listFailInvoice) {
    Vue.set(state, "listFailInvoice", listFailInvoice);
  },
  [types.SET_PAYMENT_METHODS](state, listPaymentMethod) {
    Vue.set(state, "listPaymentMethod", listPaymentMethod);
  },
  [types.SET_DEFAULT_PAYMENT_METHOD_ID](state, defaultPaymentMethod) {
    Vue.set(state, "defaultPaymentMethod", defaultPaymentMethod);
  }

  // [types.SET_SHOWING_PAGE_MASTER_STATUS](state, payload) {
  //   Vue.set(state, "isShowingMasterPage", payload);
  // },

  // [types.SET_MASTER_DETAIL_LAYOUT_STATUS](state, payload) {
  //   Vue.set(state, "isMasterDetailLayout", payload);
  // }
};
