<template>
  <f7-popup
    :opened="showGlobalNotifyPopup"
    @popup:opened="loadMoreNotify"
    @popup:closed="close"
  >
    <div class="page notification-page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Notification</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="handleReadAllNotify">Mark all as read</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="page-content" @scroll="onScroll">
        <f7-list class="no-margin">
          <f7-list-group
            media-list
            v-for="group in groupedNotificationsList"
            :key="group.key"
          >
            <f7-list-item group-title>
              <span class="custom-title">
                {{ group.key }}
              </span>
            </f7-list-item>
            <f7-list-item
              no-chevron
              link
              v-for="(notify, index) in group.data"
              :key="index"
              @click="goToDetail(notify)"
              class="notify-item"
            >
              <div
                class="after-title text-color-primary timeline-notify"
                slot="after-title"
                style
              >
                <timeago
                  :datetime="
                    notify && notify.createdAt ? notify.createdAt.toDate() : {}
                  "
                  :autoUpdate="1"
                  :converterOptions="{ includeSeconds: true }"
                  style="font-size: 12px"
                ></timeago>
                <span
                  class="yellow-dot"
                  v-if="notify && !notify.isRead"
                  @click.stop="handleReadNotify(notify)"
                ></span>
              </div>
              <div slot="title">
                <div v-html="notify.message" class="fixed-width-text"></div>
              </div>
              <div slot="content-start">
                <user-avatar
                  :avatarSize="26"
                  :imageUrl="userInfo(notify.from).photoURL || ''"
                  :name="userInfo(notify.from).displayName || ''"
                  class="margin-right-half"
                ></user-avatar>
              </div>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <f7-block
          class="text-align-center"
          v-show="groupedNotificationsList.length === 0"
        >
          No Notification Found
        </f7-block>
      </div>
    </div>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { groupBy } from "@/utility/filter-tools";
import { getWeekDates } from "@/utility/date-time-tool";

import _ from "lodash";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  COLLECTION_ESTIMATE,
  COLLECTION_OPRATION_CARD,
  COLLECTION_OPRATION_COMPANY,
  COLLECTION_OPRATION_CONTACT,
  COLLECTION_PROPOSAL,
  COLLECTION_MY_SCHEDULING,
  COLLECTION_EMPLOYEE_TIME_LOG,
  COLLECTION_MY_TIME_LOG,
  COLLECTION_RESTORE_ARCHIVE_JOB,
  COLLECTION_PRICE_LIST,
  COLLECTION_CONTRACT,
  COLLECTION_GROUP_ADMIN,
  COLLECTION_WO_TASK,
  COLLECTION_MOVE_ARCHIVE_JOB,
  COLLECTION_TEAM_MANAGEMENT
} from "../../utility/const";

export default {
  components: {
    UserAvatar
  },
  data: () => {
    return {
      searchValue: "",
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_SERVICE,
      COLLECTION_OPRATION_CARD,
      COLLECTION_OPRATION_COMPANY,
      COLLECTION_OPRATION_CONTACT,
      COLLECTION_ESTIMATE,
      COLLECTION_PROPOSAL,
      COLLECTION_MY_SCHEDULING,
      COLLECTION_MY_TIME_LOG,
      COLLECTION_EMPLOYEE_TIME_LOG,
      COLLECTION_RESTORE_ARCHIVE_JOB,
      COLLECTION_PRICE_LIST,
      COLLECTION_CONTRACT,
      COLLECTION_GROUP_ADMIN,
      COLLECTION_WO_TASK,
      COLLECTION_MOVE_ARCHIVE_JOB,
      COLLECTION_TEAM_MANAGEMENT,
      limit: 25,
      order: "desc"
    };
  },

  computed: {
    ...mapGetters("common/notification", [
      "showGlobalNotifyPopup",
      "notificationsList"
    ]),
    ...mapState("common/user", ["userList"]),
    ...mapState("common/notification", [
      "notificationsLastRef",
      "notificationsAllLoaded"
    ]),
    ...mapGetters("setting/app/profile", ["currentUser"]),
    userInfo() {
      return uid =>
        this.userList.find(user => {
          return user.uid === uid;
        }) || {};
    },
    groupedNotificationsList() {
      const notifications = _.cloneDeep(this.notificationsList).sort((a, b) => {
        return a.createdAt && b.createdAt
          ? b.createdAt.seconds - a.createdAt.seconds
          : -1;
      });
      const grouped = groupBy(notifications, notification => {
        return this.isToday(notification.createdAt) ? "Today" : "Older";
      });
      return grouped;
    }
  },

  methods: {
    ...mapActions("common/notification", [
      "openGlobalNotifyPopup",
      "getProjectById",
      "getBoardById",
      "getActionById",
      "getMoreNotification",
      "resetNotificationPage",
      "updateAllNotification",
      "updateNotificationBys"
    ]),
    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMoreContent();
      }
    },
    isToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const compareDate = new Date(date.seconds * 1000);
      compareDate.setHours(0, 0, 0, 0);
      return compareDate.getTime() === today.getTime();
    },

    async goToDetail(item) {
      this.$f7.preloader.show();
      let url = "";
      switch (item.entityName) {
        case COLLECTION_OPRATION_CARD: {
          const project = await this.getProjectById(item.projectId);
          let board = {};
          let action = {};
          if (project.boardId) {
            board = await this.getBoardById(project.boardId);
          }
          if (!_.isEmpty(project.actions)) {
            action = await this.getActionById(project.actions[0]);
          }
          if (project.businessCode === BUSINESS_CODE_COMMERCIAL) {
            url = `/dashboard/swimlane/commercial/${action.boards[0]}/project/${project.cardNumber}`;
            if (action.boards[0] !== "construction") {
              url += `/action/${action.code}`;
            }
          } else if (project.businessCode === BUSINESS_CODE_RESIDENTIAL) {
            url = `/dashboard/swimlane/residential/${project.cardNumber}/action/${action.code}`;
          } else {
            url = `/dashboard/swimlane/service/${board.boardNumber}/card/${project.cardNumber}`;
          }
          break;
        }
        case COLLECTION_WO_TASK: {
          if (this.$device.desktop) {
            url = `/purchase-order-grid?taskNumber=${item.projectName}`;
          } else {
            url = `/purchase-order/${item.projectName}`;
          }
          break;
        }
        case COLLECTION_MOVE_ARCHIVE_JOB: {
          url = `/archive-board`;
          break;
        }
        case COLLECTION_GROUP_ADMIN: {
          url = `/administration/groups/${item.projectId}`;
          break;
        }
        case COLLECTION_TEAM_MANAGEMENT: {
          url = `/team-management/${item.projectId}`;
          break;
        }
        case COLLECTION_EMPLOYEE_TIME_LOG: {
          url = `/employees-time-logs`;
          break;
        }
        case COLLECTION_MY_TIME_LOG: {
          const { startDate, endDate } = getWeekDates(item.createdAt);
          url = `/my-time-log/detail?startDate=${startDate}&endDate=${endDate}`;
          break;
        }
        case COLLECTION_MY_SCHEDULING: {
          const date = item.startDate ? item.startDate : item.createdAt;
          const { startDate, endDate } = getWeekDates(date);
          url = `/my-scheduling?currentView=week&startWeek=${startDate}`;
          break;
        }
        case COLLECTION_PROPOSAL: {
          url = `/proposal/${item.proposalNumber ? item.proposalNumber : ""}`;
          break;
        }
        case COLLECTION_RESTORE_ARCHIVE_JOB: {
          const project = await this.getProjectById(item.projectId);
          let board = {};
          let action = {};
          if (project.boardId) {
            board = await this.getBoardById(project.boardId);
          }
          if (!_.isEmpty(project.actions)) {
            action = await this.getActionById(project.actions[0]);
          }
          if (project.businessCode === BUSINESS_CODE_COMMERCIAL) {
            url = `/dashboard/swimlane/commercial/${action.boards[0]}/project/${project.cardNumber}`;
            if (action.boards[0] !== "construction") {
              url += `/action/${action.code}`;
            }
          } else if (project.businessCode === BUSINESS_CODE_RESIDENTIAL) {
            url = `/dashboard/swimlane/residential/${project.cardNumber}/action/${action.code}`;
          } else {
            url = `/dashboard/swimlane/service/${board.boardNumber}/card/${project.cardNumber}`;
          }
          break;
        }
        case COLLECTION_PRICE_LIST: {
          url = `/price-list/${
            item.priceListNumber ? item.priceListNumber : ""
          }`;
          break;
        }
      }
      await this.updateNotificationBys(item.id).then(() => {
        this.$f7.preloader.hide();
      });
      this.$f7.views.main.router.navigate(url, {
        pushState: true,
        reloadAll: true
      });
      this.$f7.preloader.hide();
      this.close();
    },
    async handleReadNotify(notify) {
      if (notify.isRead) return;
      this.$f7.preloader.show();
      await this.updateNotificationBys(notify.id).then(() => {
        this.$f7.preloader.hide();
      });
    },
    loadMoreContent() {
      if (this.notificationsAllLoaded) return;
      this.$f7.preloader.show();
      this.getMoreNotification({
        limit: this.limit,
        order: this.order,
        andConditions: [
          {
            prop: "to",
            val: this.currentUser.uid,
            op: "=="
          }
        ]
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },
    loadMoreNotify() {
      this.resetNotificationPage();
      this.loadMoreContent();
    },
    async handleReadAllNotify() {
      this.$f7.preloader.show();
      await this.updateAllNotification().then(() => {
        this.$f7.preloader.hide();
      });
    },
    close() {
      this.resetNotificationPage();
      this.openGlobalNotifyPopup(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-page {
  ::v-deep .list-group-title {
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;

    .custom-title {
      border-radius: 40px;
      padding: 2px 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  ::v-deep .item-link em {
    font-style: normal;
    background: var(--f7-color-hight-light-neutral);
  }
}
.notify-item ::v-deep .item-media {
  display: contents;
}

.notify-item ::v-deep .item-title-row::before {
  content: "" !important;
}

.notify-item ::v-deep .inner {
  text-align: end;
  font-size: 11px;
}

.media-list {
  margin-top: 0px;
}
.fixed-width-text ::v-deep div {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.list .item-content {
  gap: 5px;
}
.item-title {
  width: 70%;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: var(--f7-color-text-4-neutral) !important;
}
.yellow-dot {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: yellow;
  display: inline-block;
}
.timeline-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
</style>
