<template>
  <div :class="typeSwitchScreen !== 'step' && 'container-info'">
    <f7-list
      :inset="$device.desktop"
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.INFO
        )
      "
    >
      <div
        :class="
          typeSwitchScreen === 'step' && $device.desktop
            ? 'container-info-step-row'
            : 'container-info-step-column'
        "
      >
        <div style="flex: 1;">
          <f7-list>
            <f7-list-item width="100" header="Number of Skylight">
              <f7-input
                slot="title"
                type="number"
                min="0"
                step="1"
                :value="currentBuilding.numberOfSkylight || 0"
                @change="
                  setField(
                    'numberOfSkylight',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: currentRoofType,
                    sectionId: SECTION_TYPE_TPO_MISCELLANEOUS
                  });

                  updateProducts({
                    roofType: currentRoofType,
                    sectionId: SECTION_TYPE_TPO_LABOR
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="number_square"></input-icon>
              <!-- <span slot="header" class="input-title-desc">
                (Need to double if Overflow Drain is checked)
              </span> -->
            </f7-list-item>
          </f7-list>
        </div>
        <div style="flex: 1;"></div>
      </div>
    </f7-list>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_MISCELLANEOUS)"
      :roofType="currentRoofType"
      :sectionId="SECTION_TYPE_TPO_MISCELLANEOUS"
      :alertMessage="alertMessage"
      @onChangeProductItem="
        onChangeProductItem(
          currentRoofType,
          SECTION_TYPE_TPO_MISCELLANEOUS,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_LABOR)"
      :roofType="currentRoofType"
      :sectionId="SECTION_TYPE_TPO_LABOR"
      @onChangeProductItem="
        onChangeProductItem(currentRoofType, SECTION_TYPE_TPO_LABOR, $event)
      "
    ></section-product-table>
  </div>
</template>

<script>
import SectionProductTable from "../tables/SectionProductTable.vue";
import InputIcon from "@/components/icons/InputIcon.vue";

import {
  SECTION_TYPE_TPO_MISCELLANEOUS,
  SECTION_TYPE_TPO_LABOR,
  JOB_TYPE_NEW,
  CONTENT_TYPE_EST
} from "../../../../utility/const";

import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";
import { mapGetters } from "vuex";
import { checkShowContentScreenSingleInEstimate } from "@/utility/common";

export default {
  components: {
    SectionProductTable,
    InputIcon
  },

  props: {
    showContentType: {
      type: String,
      default: "all"
    }
  },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      SECTION_TYPE_TPO_MISCELLANEOUS,
      SECTION_TYPE_TPO_LABOR,
      JOB_TYPE_NEW,
      CONTENT_TYPE_EST
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "typeSwitchScreen"
    ]),
    ...mapGetters("estimate/estimate-page/project", ["project"]),
    currentRoofType() {
      return (this.currentBuilding || {}).roofType;
    },

    alertMessage() {
      if (
        this.project &&
        this.project.jobType !== JOB_TYPE_NEW &&
        this.project.numberOfRoofSytem === 1 &&
        this.project.looseGravel === true
      ) {
        return "Alert: A Vacuum truck is needed due to loose gravel on the roof";
      } else if (
        this.project &&
        this.project.jobType !== JOB_TYPE_NEW &&
        this.project.numberOfRoofSytem > 1
      ) {
        return `Tear of labor and dumpsters are needed to remove ${this.project.numberOfRoofSytem} existing roof systems`;
      }
      return "";
    }
  },

  methods: {
    validate() {},
    init() {},
    checkShowContentScreenSingleInEstimate
  }
};
</script>

<style>
.container-info {
  background-color: var(--f7-color-bg-dark);
  padding: 8px 0 16px 0;
}
.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 16px;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 5px;
}
</style>
