<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Online Leads</f7-nav-title>
    </f7-navbar>

    <contact-info-grid-table></contact-info-grid-table>
  </f7-page>
</template>

<script>
import ContactInfoGridTable from "../components/table/ContactInfoGridTable.vue";
import Menu from "../../../components/menu/Menu.vue";

import { mapActions } from "vuex";

export default {
  components: {
    ContactInfoGridTable,
    Menu
  },

  methods: {
    ...mapActions("web-contact/web-contact-info", ["bindConstantTypeList"]),
    ...mapActions("web-contact/user", ["getUserList"])
  },

  created() {
    this.$f7.preloader.show();

    const refs = [];
    refs.push(this.bindConstantTypeList());
    refs.push(this.getUserList());

    return Promise.all(refs).finally(() => {
      this.$f7.preloader.hide();
    });
  }
};
</script>
