import moment from "moment";
import {
  FirebaseActions,
  firebase
} from "../../../../services/firebase.service";
import * as types from "./types";

import {
  STATUS_TIME_TRACKING_APPROVED,
  STATUS_TIME_TRACKING_WFA
} from "../../../../utility/const";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/time_tracking`,
    "object"
  );

  return {
    bind: ActionsTemplate.bindCollection,
    bindBys: ActionsTemplate.bindCollectionBys,
    unbind: ActionsTemplate.unbindCollection,
    create: ActionsTemplate.createDocument,
    update: ActionsTemplate.updateDocument,
    delete: ActionsTemplate.deleteDocument,

    getTimeTrackingBys: ActionsTemplate.getDocumentBys,

    async getTimeTrackingList({ dispatch, commit }, payload) {
      const timeTrackingList = await dispatch("getTimeTrackingBys", payload);
      await commit(types.SET_TIME_TRACKING_LIST, timeTrackingList);
      return timeTrackingList;
    },

    async getWeeklyTimeLogs({ dispatch }, { fromDate, toDate, userId }) {
      const startDate = moment(fromDate, "MM/DD/YYYY").startOf("day");
      const endDate = moment(toDate, "MM/DD/YYYY").endOf("day");
      let list = await dispatch("getTimeTrackingBys", [
        {
          prop: "date",
          val: firebase.firestore.Timestamp.fromDate(new Date(startDate)),
          op: ">="
        },
        {
          prop: "date",
          val: firebase.firestore.Timestamp.fromDate(new Date(endDate)),
          op: "<="
        },
        {
          prop: "user_id",
          val: userId,
          op: "=="
        },
        {
          prop: "status",
          val: [STATUS_TIME_TRACKING_WFA, STATUS_TIME_TRACKING_APPROVED],
          op: "in"
        },
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        }
      ]);

      return list.filter(item => item.timeLogType != "lunchtime");
    },

    setCurrentWeek({ commit }, payload) {
      return commit(types.SET_CURRENT_WEEK, payload);
    },
    setTimeLogInfoAvailable({ commit }, value) {
      return commit(types.SET_TIME_LOG_INFO_AVAILABLE, value);
    }
  };
};
