<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Assembly Drawing</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list media-list>
        <!-- assembly drawing name -->
        <f7-list-input label="Assembly Drawing Name" placeholder="Enter Assembly Drawing Name" type="text"
          :value="drawingName" @input="drawingName = $event.target.value.trim()" error-message-force validate
          validate-on-blur :error-message="drawingNameErrorMessage">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="skew"></input-icon>
        </f7-list-input>
        <!-- roof type -->
        <f7-list-input type="select" label="Roof Type" :value="roofType"
          @change="onRoofTypeChanged($event.target.value)" error-message-force validate validate-on-blur
          :error-message="roofTypeErrorMessage">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
          <option value="" hidden>Select roof type</option>
          <option v-for="item in roofTypes" :key="item.id" :value="item.value">{{ item.displayName }}</option>
        </f7-list-input>

        <!-- assembly teamplate -->
        <f7-list-input type="select" label="Assembly Template" :value="assemblyTemplateId"
          @change="assemblyTemplateId = $event.target.value" error-message-force validate validate-on-blur
          :error-message="assemblyTemplateErrorMessage">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="book"></input-icon>
          <option value="" hidden>Select assembly template</option>
          <option v-for="item in assemblyTemplates" :key="item.id" :value="item.id">{{ `${item.templateNumber} -
            ${item.templateName}` }}</option>
        </f7-list-input>

        <!-- Price List -->
        <f7-list-item link @click.native="selectPriceList">
          <div class="list-item-inner-start" slot="inner-start">
            Price List<required-asterisk />
          </div>
          <div class="list-item-title" slot="title">
            {{ (priceList || {}).displayName || "Select price list" }}
          </div>
          <input-icon slot="media" icon="square_list"></input-icon>
          <div slot="text" style="color: red;">
            {{ selectPriceListMessage || priceListErrorMessage }}
          </div>
        </f7-list-item>
      </f7-list>
    </f7-page>
    <price-list-item-list-popup ref="selectPriceListItem"
      @onSelected="priceListId = $event"></price-list-item-list-popup>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PriceListItemListPopup from "./PriceListItemListPopup.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import InputIcon from "@/components/icons/InputIcon.vue";
import { STATUS_ASSEMBLY_DRAWING_DRAFT, VALIDATION_MESSAGE } from "@/utility/const";
import _ from "lodash";

export default {
  components: {
    PriceListItemListPopup,
    InputIcon
  },
  data() {
    return {
      drawingName: "",
      roofType: "",
      assemblyTemplateId: "",
      priceListId: null,
      selectPriceListMessage: "",
      popupOpened: false
    };
  },
  async created() {
    await this.getAssemblyTemplateListDoc([
      {
        prop: "isDeleted",
        val: true,
        op: "!="
      }
    ]);
  },
  computed: {
    ...mapGetters({
      roofTypeList: "common/app-constant/roofTypeList",
      priceListItemList: "common/price-list/priceListItemList",
      user: "setting/app/profile/user",
      assemblyTemplateList: "common/assembly-template/assemblyTemplateList"
    }),
    roofTypes() {
      return this.roofTypeList
        .filter(type => {
          return type.workSteps && type.workSteps.commercial;
        })
        .sort((a, b) => {
          return a.displayName < b.displayName ? -1 : 0;
        });
    },
    assemblyTemplates() {
      return this.assemblyTemplateList
        .filter(r => {
          return this.roofType ? r.roofTypes.includes(this.roofType) : r;
        })
        .sort((a, b) => {
          return a.createdAt < b.createdAt ? -1 : 0;
        });
    },
    priceList() {
      return this.priceListItemList.find(r => r.id === this.priceListId);
    },
    drawingNameErrorMessage() {
      if (!this.v$.$error) return "";
      if (this.v$.drawingName.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    },
    roofTypeErrorMessage() {
      if (!this.v$.$error) return "";
      if (this.v$.roofType.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    },
    assemblyTemplateErrorMessage() {
      if (!this.v$.$error) return "";
      if (this.v$.assemblyTemplateId.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    },
    priceListErrorMessage() {
      if (!this.v$.$error) return "";
      if (this.v$.priceListId.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    }
  },
  methods: {
    ...mapActions({
      getAssemblyTemplateListDoc:
        "common/assembly-template/getAssemblyTemplateListDoc",
      createNewAssemblyDrawing:
        "common/assembly-drawing/createNewAssemblyDrawing"
    }),
    ...mapActions("common/price-list", ["getPriceListItemListBys"]),

    async initData() {
      if (_.isEmpty(this.priceListItemList)) {
        this.getPriceListItemListBys();
      }
    },
    open() {
      this.$f7.preloader.show();
      this.initData();
      this.popupOpened = true;
      this.$f7.preloader.hide();
    },
    cancel() {
      this.drawingName = "";
      this.roofType = "";
      this.assemblyTemplateId = "";
      this.priceListId = null;
      this.selectPriceListMessage = "";
      this.popupOpened = false;
      this.v$.$reset();
    },
    selectPriceList() {
      if (!this.roofType) {
        this.selectPriceListMessage = "Please select roof type first";
        return;
      }
      this.$refs.selectPriceListItem.open(this.roofType);
    },
    async add() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$f7.preloader.show();
      const assemblyTemplate = this.assemblyTemplateList.find(
        r => r.id === this.assemblyTemplateId
      );
      const { drawingName, roofType, assemblyTemplateId, priceListId } = this;
      const data = {
        drawingName,
        roofType,
        assemblyTemplateId,
        priceListId,
        templateCode: assemblyTemplate.templateCode,
        templateName: assemblyTemplate.templateName,
        canvasComponent: assemblyTemplate.canvasComponent,
        formComponent: assemblyTemplate.formComponent,
        cleatNumberOfBend: assemblyTemplate.cleatNumberOfBend,
        numberOfBend: assemblyTemplate.numberOfBend,
        numberOfCleat: assemblyTemplate.numberOfCleat,
        measurement: assemblyTemplate.measurement,
        status: STATUS_ASSEMBLY_DRAWING_DRAFT
      };
      const drawingCreated = await this.createNewAssemblyDrawing(data);
      this.cancel();
      this.$f7router.navigate(`/assembly-drawing/${drawingCreated.docNumber}`);
      this.$f7.preloader.hide();
    },
    onRoofTypeChanged(value) {
      this.roofType = value;
      this.priceListId = null;
      this.assemblyTemplateId = null;
      const priceListAll =
        this.priceListItemList.find(r => r.isDefault === true) || {};
      this.priceListId = priceListAll.id;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations: {
    drawingName: {
      required
    },
    priceListId: {
      required
    },
    roofType: {
      required
    },
    assemblyTemplateId: {
      required
    }
  },
  watch: {
    roofType: {
      handler(val) {
        if (val) {
          this.selectPriceListMessage = "";
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
