import Vue from "vue";
import * as types from "./types";
import _ from "lodash";

export default {
  [types.SET_SCHEDULE_ID](state, id) {
    state.scheduleId = id;
  },
  [types.SELECT_ITEM](state, item) {
    state.selectedItem = item;
  },
  [types.SET_IS_MY_SCHEDULING](state, value) {
    state.isMyScheduling = value;
  },
  [types.SET_EDIT_TYPE](state, value) {
    state.editType = value;
  },
  [types.SET_IS_SHOW_DETAIL](state, value) {
    state.isShowDetail = value;
  },

  [types.SET_SCHEDULE_LIST](state, list) {
    state.scheduleList = list;
  },
  [types.SCHEDULE_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.scheduleList.findIndex(item => item.id === id);

    if (index >= 0) {
      const data = _.cloneDeep(state.scheduleList[index]);

      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          data[key] = doc[key];
        }
      }
      Vue.set(state.scheduleList, index, data);
    }
  },

  [types.SCHEDULE_LIST_ITEM_ADD](state, contact) {
    state.scheduleList.unshift(contact);
  },

  [types.SCHEDULE_LIST_ITEM_REMOVE](state, id) {
    const index = state.scheduleList.findIndex(item => item.id === id);
    if (index >= 0) {
      state.scheduleList.splice(index, 1);
    }
  },

  [types.SET_SCHEDULE_BY_CHAINID_DATE](state, list) {
    state.schedulesByChainIdAndDate = list;
  },

  [types.RESET_DATA](state) {
    Vue.set(state, "scheduleList", []);
    Vue.set(state, "schedule", {});
    Vue.set(state, "scheduleId", null);
    Vue.set(state, "selectedItem", {});
    Vue.set(state, "editType", null);
    Vue.set(state, "isShowDetail", false);
  }
};
