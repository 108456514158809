<template>
  <f7-navbar :sliding="false">
    <f7-nav-left>
      <f7-link
        v-if="isShowBack"
        @click="backToProjectList"
        icon-f7="chevron_left"
      ></f7-link>
    </f7-nav-left>
    <f7-nav-title>{{ project.title }}</f7-nav-title>
    <f7-nav-right> </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import backActionsMixin from "../../mixin/backActions";

export default {
  mixins: [backActionsMixin],
  computed: {
    ...mapGetters("dashboard/project", { project: "constructionProject" }),
    isShowBack() {
      return (
        this.isFromScheduling || (!this.$device.desktop && !this.$device.ipad)
      );
    }
  },

  methods: {
    backToProjectList() {
      if (this.isFromScheduling) {
        this.handleBackToScheduling();
      } else {
        this.$f7router.navigate(
          `/dashboard/${this.$f7route.route.meta.boardType}/commercial/construction/project`
        );
      }
    }
  }
};
</script>

<style></style>
