<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link
      ></f7-nav-left>
      <f7-nav-title>{{ $t("timeTracking.detail.title") }}</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <data-table
      :headers="headers"
      :items="dataTimeTrackingByDateWithSummary"
      :pageSize="dataTimeTrackingByDateWithSummary.length"
      @selected:checkbox="products = $event"
      data-table-collapsible
    >
      <template slot="card-header">
        <f7-list>
          <f7-searchbar
            class="search-detail"
            :placeholder="$t('timeTracking.detail.search')"
            :clear-button="true"
            disable-button-text
            @input="searchText = $event.target.value"
            @searchbar:disable="searchText = ''"
          ></f7-searchbar>
        </f7-list>
      </template>
      <template v-slot:body="{ item }">
        <template v-if="item.parentRow">
          <td :data-collapsible-title="headers[0].text" class="parent-row">
            {{ item.date }}
          </td>
          <td
            :data-collapsible-title="headers[1].text"
            class="parent-row"
            :colspan="5"
          >
            {{
              moment(item.date, "MM/DD/YYYY")
                .format("dddd")
                .substring(0, 3)
            }}
          </td>
          <td
            :data-collapsible-title="headers[6].text"
            class="parent-row"
            :colspan="5"
          >
            {{ formatDuration(item.totalHoursByDate) }}
          </td>
          <td
              :data-collapsible-title="headers[7].text"
              class="parent-row"
              :colspan="5"
            >
            {{ item.overtime ? formatDuration(item.overtime || 0) : "" }}
          </td>
        </template>
        <template v-else-if="!item.footerRow">
          <td :data-collapsible-title="headers[0].text">
            {{ item.date.toDate() | MMDDYYYY }}
          </td>
          <td :data-collapsible-title="headers[1].text">
            {{
              moment(item.date.toDate(), "MM/DD/YYYY")
                .format("dddd")
                .substring(0, 3)
            }}
          </td>

          <td :data-collapsible-title="headers[2].text">
            {{
              item.startTime
                ? moment(item.startTime, "HH:mm").format("h:mm a")
                : ""
            }}
          </td>
          <td :data-collapsible-title="headers[3].text">
            {{
              item.endTime ? moment(item.endTime, "HH:mm").format("h:mm a") : ""
            }}
          </td>

          <td :data-collapsible-title="headers[4].text" class="numeric-cell">
            {{
              formatDuration(
                item.override ? item.overrideHour : item.loggedHour
              )
            }}
          </td>

          <td :data-collapsible-title="headers[5].text" class="numeric-cell">
            {{
              item.hasLunchBreak
                ? formatDuration(item.lunchTimeInfo.hour || 0)
                : ""
            }}
          </td>

          <td :data-collapsible-title="headers[6].text" class="numeric-cell">
            {{
              formatDuration(
                (item.override ? item.overrideHour : item.loggedHour) +
                  (item.hasLunchBreak ? item.lunchTimeInfo.hour || 0 : 0)
              )
            }}
          </td>
          <td class="numeric-cell" :data-collapsible-title="headers[7].text">
            <span>{{
              !!item.hasOvertime ? formatDuration(item.overtime || 0) : ""
            }}</span>
          </td>
          <td :data-collapsible-title="headers[8].text">
            <f7-toggle :disabled="true" :checked="item.billable"></f7-toggle>
          </td>
          <td :data-collapsible-title="headers[9].text">
            <f7-chip
              :text="$t(`timeTracking.status.${item.status}`)"
              :color="getStatus(item.status).color"
            ></f7-chip>
          </td>
          <td :data-collapsible-title="headers[10].text">
            &nbsp;
            <span v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
              {{ item.timeOffTypeValue }}
            </span>
            <span v-else-if="item.timeLogType === TIME_LOG_TYPE_OTHERS">{{
              item.title
            }}</span>

            <span v-else>{{ (item.project || {}).title }}</span>
          </td>
          <td :data-collapsible-title="headers[11].text">
            {{ item.qbClassRef && item.qbClassRef.name }}
          </td>
          <td :data-collapsible-title="headers[12].text">
            {{ item.qbServiceItemRef && item.qbServiceItemRef.name }}
          </td>
          <td :data-collapsible-title="headers[13].text">
            <div class="column-note">
              <f7-chip
                v-if="item.hasLunchBreak"
                :text="item.lunchTimeInfo.title"
                color="yellow"
              ></f7-chip>
              <long-content-block
                :seedId="`note-${item.id}`"
                :content="item.note"
              ></long-content-block>
            </div>
          </td>
          <td :data-collapsible-title="headers[14].text">
            <long-content-block
              :seedId="`rr-${item.id}`"
              :content="item.reject_reason"
            ></long-content-block>
          </td>
          <td class="action-column" :data-collapsible-title="headers[15].text">
            <f7-row class="button-action-edit justify-content-flex-start">
              <f7-button
                v-if="
                  !(
                    item.status === STATUS_TIME_TRACKING_PAID ||
                    item.status === STATUS_TIME_TRACKING_APPROVED ||
                    item.status === STATUS_TIME_TRACKING_REJECTED
                  )
                "
                class="margin-right"
                @click="showDetailPopup(item)"
                large
                small
                outline
                >{{ $t("timeTracking.action.edit") }}</f7-button
              >
              <f7-button
                v-if="
                  !(
                    item.status === STATUS_TIME_TRACKING_PAID ||
                    item.status === STATUS_TIME_TRACKING_APPROVED ||
                    item.status === STATUS_TIME_TRACKING_REJECTED
                  )
                "
                @click="removeItem(item)"
                fill
                small
                color="red"
                >{{ $t("timeTracking.action.delete") }}</f7-button
              >
            </f7-row>
          </td>
        </template>

        <template v-else-if="item.footerRow">
          <td
            class="footer-row numeric-cell"
            :colspan="6"
          >
            {{ `${$t("timeTracking.detail.table.weekTotal")}` }}
          </td>
          <td
            :data-collapsible-title="headers[6].text"
            class="footer-row numeric-cell"
          >
            {{ formatDuration(item.totalHours) }}
          </td>

          <td
            :data-collapsible-title="headers[7].text"
            class="footer-row numeric-cell"
          >
            {{ item.overtime ? formatDuration(item.overtime || 0) : "" }}
          </td>
          <td
            class="footer-row "
            :colspan="headers.length - 7"
          ></td>
        </template>
      </template>
    </data-table>
    <edit-time-tracking
      :popupEditTimeTracking="isShowEditTimeTracking"
      :item="detailTimeTracking"
      @close="isShowEditTimeTracking = false"
    />
  </f7-page>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DataTable from "@/components/datatables";
import { groupBy, applyFilter } from "@/utility/filter-tools";
import LongContentBlock from "../components/blocks/LongContentBlock.vue";

import moment from "moment";
import EditTimeTracking from "../components/popups/EditTimeTracking.vue";
import { sortEarliest, sortBy } from "@/utility/date-time-tool";
import { formatDuration } from "@/utility/datetime";
import { roundNumber } from "@/utility/number-tool";
import { overtimeMixin } from "../mixin/overtime-mixin.js";
import _ from "lodash";

import {
  STATUS_TIME_TRACKING_WFA,
  STATUS_TIME_TRACKING_PAID,
  STATUS_TIME_TRACKING_REJECTED,
  STATUS_TIME_TRACKING_APPROVED,
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  TIME_LOG_TYPE_LUNCHTIME
} from "../../../utility/const";

export default {
  mixins: [overtimeMixin],
  data() {
    return {
      searchText: "",
      isShowEditTimeTracking: false,
      detailTimeTracking: {},

      STATUS_TIME_TRACKING_WFA,
      STATUS_TIME_TRACKING_PAID,
      STATUS_TIME_TRACKING_REJECTED,
      STATUS_TIME_TRACKING_APPROVED,
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      TIME_LOG_TYPE_LUNCHTIME,
      moment,
      formatDuration
    };
  },
  components: {
    DataTable,
    EditTimeTracking,
    LongContentBlock
  },

  methods: {
    ...mapActions({
      deleteTimeTracking: "time-tracking/time-tracking/delete"
    }),

    ...mapMutations({
      setTimeTrackingViewport: "time-tracking/time-tracking/setViewport"
    }),

    getStatus(status) {
      return this.timeTrackingStatusList.find(x => x.value === status) || {};
    },

    showDetailPopup(item) {
      this.detailTimeTracking = {
        ...item,
        overrideHour:
          item.timeLogType === TIME_LOG_TYPE_LUNCHTIME
            ? item.overrideHour * -1
            : item.overrideHour
      };
      this.isShowEditTimeTracking = true;
    },

    removeItem(timelog) {
      const self = this;
      this.$ri.dialog.openInfoDialog({
        title: this.$t("timeTracking.deleteItem.title"),
        content: this.$t("timeTracking.deleteItem.content"),
        textButton: this.$t("timeTracking.button.confirm"),
        onClick: (_self, index) => {
          if (index === 0) {
            _self.app.dialog.close();
          } else if (index === 1) {
            const date = _.cloneDeep(timelog.date);
            return self
              .deleteTimeTracking(timelog.id)
              .then(() => {
                return self.composeOvertime(date.toDate(), timelog.user_id);
              })
              .finally(() => {
                _self.app.dialog.close();
              });
          }
        }
      });
    },

    handleBack() {
      this.$f7router.back("/my-time-log", { force: true });
    }
  },

  computed: {
    ...mapGetters({
      time_trackingList: "time-tracking/time-tracking/objectList"
    }),
    ...mapGetters("time-tracking/app-constant", [
      "timeTrackingStatusList",
      "timeOffTypeList"
    ]),

    dataTimeTrackingByDate() {
      const timeTrackingList = this.time_trackingList
        .filter(item => item.timeLogType != TIME_LOG_TYPE_LUNCHTIME && item.date)
        .map(r => ({
          ...r,
          timeOffTypeValue: r.timeOffType
            ? (
                this.timeOffTypeList.find(
                  timeOffType => timeOffType.value === r.timeOffType
                ) || {}
              ).displayName
            : "",
          startTime: !r.override && r.startTime ? r.startTime : "",
          endTime: !r.override && r.endTime ? r.endTime : ""
        }));
      let results = timeTrackingList.filter(x => {
        const compareDate = moment(
          moment(x.date.toDate()).format("MM-DD-YYYY"),
          "MM-DD-YYYY"
        );
        const startDate = moment(this.$f7route.query.startDate, "MM-DD-YYYY");
        const endDate = moment(this.$f7route.query.endDate, "MM-DD-YYYY");
        return compareDate.isBetween(startDate, endDate, "days", true);
      });
      const list = applyFilter([...results], this.searchText, [
        item => moment(item.date.toDate()).format("MM/DD/YYYY"),
        item => this.getStatus(item.status).displayName,
        "note",
        "reject_reason",
        "project.title",
        "title",
        "timeOffTypeValue",
        "startTime",
        "endTime"
      ]);

      return sortBy(list, "date", false);
    },

    // group data by date
    dataTimeTrackingByDateWithSummary() {
      // group by date
      const groupByDate = groupBy(this.dataTimeTrackingByDate, i =>
        i.date ? moment(i.date.toDate()).format("MM/DD/YYYY") : ""
      ).map(item => ({
        date: item.key,
        data: sortEarliest(item.data),
        totalHoursByDate: item.data
          .filter(item => item.status !== "tt-rejected")
          .map(
            item =>
              (item.approvedHour
                ? parseFloat(item.approvedHour)
                : item.override
                ? parseFloat(item.overrideHour)
                : item.loggedHour) +
              (item.hasLunchBreak ? item.lunchTimeInfo.hour : 0)
          )
          // .map(r => Math.round(r * 100) / 100)
          .map(r => roundNumber(r, 3))
          .reduce((a, b) => a + b, 0),
        // .toFixed(2),
        overtime: item.data
          .filter(item => item.status !== "tt-rejected")
          .map(item => (item.hasOvertime ? item.overtime || 0 : 0))
          // .map(r => Math.round(r * 100) / 100)
          .map(r => roundNumber(r, 3))
          .reduce((a, b) => a + b, 0)
        // .toFixed(2)
      }));

      let firstDate = "";

      if (groupByDate && groupByDate.length > 0) {
        firstDate = this.getWeekOfDate(groupByDate[0].date)[0];
      }

      // create new list
      let listItems = [];
      for (const group of groupByDate) {
        listItems = [...listItems, ...group.data];
        listItems.push({
          parentRow: true,
          date: group.date,
          totalHoursByDate: group.totalHoursByDate,
          overtime: parseFloat(group.overtime) ? group.overtime : ""
        });
      }

      const totalOvertime = listItems
        .filter(item => item.parentRow === true)
        .map(item => parseFloat(item.overtime) || 0)
        .reduce((a, b) => a + b, 0);
      // .toFixed(2);
      return [
        ...listItems,
        {
          footerRow: true,
          date: firstDate,
          totalHours: this.dataTimeTrackingByDate
            .filter(item => item.status !== "tt-rejected")
            .map(
              item =>
                (item.override
                  ? parseFloat(item.overrideHour)
                  : item.loggedHour) +
                +(item.hasLunchBreak ? item.lunchTimeInfo.hour : 0)
            )
            // .map(r => Math.round(r * 100) / 100)
            .map(r => roundNumber(r, 3))
            .reduce((a, b) => a + b, 0),
          // .toFixed(2),
          overtime: totalOvertime
        }
      ];
    },

    headers() {
      return [
        {
          text: this.$t("timeTracking.detail.table.date"),
          align: "left",
          sortable: false,
          value: "date"
        },
        {
          text: this.$t("timeTracking.detail.table.day"),
          align: "left",
          sortable: false,
          value: "day"
        },

        {
          text: this.$t("timeTracking.detail.table.startTime"),
          align: "left",
          sortable: false,
          value: "startTime"
        },
        {
          text: this.$t("timeTracking.detail.table.endTime"),
          align: "left",
          sortable: false,
          value: "endTime"
        },

        {
          text: this.$t("timeTracking.detail.table.duration"),
          value: "duration",
          sortable: false,
          align: "right"
        },

        {
          text: this.$t("timeTracking.detail.table.lunchBreak"),
          align: "right",
          sortable: false,
          value: "lunchBreak"
        },

        {
          text: this.$t("timeTracking.detail.table.hours"),
          value: "workHours",
          sortable: false,
          align: "right"
        },
        {
          text: this.$t("timeTracking.detail.table.overtime"),
          value: "overtime",
          sortable: false,
          align: "right"
        },
        {
          text: this.$t("timeTracking.detail.table.billable"),
          value: "billable",
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("timeTracking.detail.table.status"),
          value: "status",
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("timeTracking.detail.table.timeLogInfo"),
          value: "timeLogInfo",
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("timeTracking.detail.table.class"),
          value: "classItem",
          sortable: false,
          align: "left"
        },
        {
          text: this.$t("timeTracking.detail.table.serviceItem"),
          value: "serviceItem",
          sortable: false,
          align: "left"
        },
        {
          text: this.$t("timeTracking.detail.table.note"),
          value: "note",
          sortable: false,
          align: "left",
          width: "150px"
        },
        {
          text: this.$t("timeTracking.detail.table.rejectReason"),
          value: "reject_reason",
          sortable: false,
          align: "left",
          width: "150px"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "left"
        }
      ];
    }
  },

  mounted() {
    this.setTimeTrackingViewport({
      startDay: this.$f7route.query.startDate,
      endDay: this.$f7route.query.endDate
    });
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-detail.searchbar input").focus();
      }
    });
  }
};
</script>
<style scoped>
.parent-row {
  background-color: var(--f7-color-icon-neutral);
  font-weight: bold;
}
.footer-row {
  background-color: var(--f7-color-img-neutral);
  font-weight: bold;
}
.deleteButton {
  margin-left: 10px;
}
.button-action-edit {
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 480px) and (orientation: portrait) {
  div /deep/ .data-table.data-table-collapsible table tr td {
    min-height: 24px;
    align-items: flex-start;
    padding-top: 4px;
  }
  div /deep/ .data-table.data-table-collapsible table tr td .column-note {
    flex-direction: column;
  }
  div /deep/ .data-table.data-table-collapsible table tr td.total-hours-lable {
    display: none;
  }
  div
    /deep/
    .data-table.data-table-collapsible
    table
    tr
    td.action-column
    .button-action-edit {
    margin-bottom: 10px;
  }
}
</style>
