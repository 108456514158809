<template>
  <signature-popup ref="signaturePopup" @saveSignature="save" :isUserSign="isUserSign"></signature-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { firebase } from "@/services/firebase.service";
import _ from "lodash";
import axios from "axios";

import SignaturePopup from "../popups/SignaturePopup.vue";
import moment from "moment";

export default {
  components: { SignaturePopup },
  props: {
    contract: { type: Object, default: () => { } },
    isUserSign: { type: Boolean, default: false }
  },
  data() {
    return {
      signerRole: "",
      additionalData: {}
    };
  },
  computed: {
    ...mapGetters("setting/app/profile", ["currentUser"]),
    ...mapGetters("common/user-signature", ["signatureList"]),
    signatureSection() {
      return this.contract.sections.find(r => r.sectionId === "main-page");
    },

    indexOfSignatureSection() {
      return this.contract.sections.findIndex(r => r.sectionId === "main-page");
    }
  },
  methods: {
    ...mapActions("common/contract", ["uploadImage", "updateContract"]),
    ...mapActions("common/user-signature", ["getSignListBys"]),

    async openPopup(signerRole) {
      if (!signerRole) return;
      this.$f7.preloader.show();
      this.signerRole = signerRole;
      const contractSign = await this.$refs.signaturePopup.getAdditionalData();
      this.additionalData = this.contract.userIp
        ? {
          signerIp: this.contract.userIp,
          time: firebase.firestore.Timestamp.now()
        }
        : contractSign;
      if (this.currentUser.uid) {
        await this.getSignListBys(this.currentUser.uid);
        let userHasSignature = this.signatureList[0];

        if (userHasSignature && !this.isUserSign) {
          this.$f7.preloader.hide();
          this.checkAskSignBefore(() => {
            this.save({
              imageBase64: userHasSignature.userSignature,
              fullName: userHasSignature.userSignName,
              currentUser: this.currentUser,
              signerIp: this.additionalData,
              callback: () => { }
            });
          }, userHasSignature);
        } else {
          this.$f7.preloader.hide();
          this.$refs.signaturePopup.openPopup();
        }
      } else {
        this.$f7.preloader.hide();
        this.$refs.signaturePopup.openPopup();
      }
    },

    showErrorDialog(errorMessage) {
      this.$ri.dialog.openErrorDialog({
        title: "Error",
        content: errorMessage,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        }
      });
    },

    checkAskSignBefore(callback, userHasSignature) {
      const app = this;
      const content = `<div style="margin-top: 16px">
        <div style="margin-bottom: 14px; width: 100%; border-radius: 4px; overflow: hidden; background-color: var(--f7-color-bg-13-neutral)">
            <img style="max-width: 257px; max-height: 200px; width: 100%; background-color: var(--f7-color-bg-13-neutral)" src="${userHasSignature.userSignature}" alt=""/>
          </div>
          <div style="text-align: center;">${userHasSignature.userSignName}</div>
        </div>`;
      app.$ri.dialog.openInfoDialog({
        title: "Do you want to use the signature that has been saved before?",
        content,
        cancelButtonCaption: "Sign again",
        textButton: "Use it",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
            this.$refs.signaturePopup.openPopup();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            if (callback) callback();
          }
        }
      });
    },

    async uploadSignatureImage(imageBase64) {
      if (this.signerRole === "owner") {
        try {
          const response = await axios.post(
            `contract/upload-file/${this.contract.id}`,
            {
              base64Data: imageBase64.split(",")[1],
              fileName: `${this.signerRole}-signature`,
              meta: { type: "image/png" }
            },
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.contractToken
              }
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.uploadImage({
        contractId: this.contract.id,
        base64Data: imageBase64,
        fileName: `${this.signerRole}-signature`
      });
      return { success: true, data: response };
    },

    async updateContractDoc(doc) {
      if (this.signerRole === "owner") {
        try {
          const response = await axios.post(
            `contract/update/${this.contract.id}`,
            doc,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.contractToken
              }
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      await this.updateContract({
        id: this.contract.id,
        doc
      });
      return { success: true };
    },

    parseSignatureHtmlContent(
      signatureSection,
      signerRole,
      signerIp,
      url,
      fullName
    ) {
      const newHtmlValueSignSection = `<div style="padding: 4px 16px;display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;">
        <img style="max-height: 40px" src="${url}" /><div>${fullName}</div></div>`;

      const newHtmlValueSignAtSection = `<div style="height: 100%;display: flex;justify-content: center;align-items: center;">
        <span>${moment().format("MM/DD/YYYY")}</span></div>`;

      const newHtmlValueSignIpSection = `<div style="height: 100%; font-size: 12px; color: #A0A0A0;">IP: ${signerIp.signerIp}</div>`;
      let htmlValue = _.cloneDeep(signatureSection).htmlValue;
      switch (signerRole) {
        case "owner":
          htmlValue = htmlValue.replaceAll(
            '<div class="owner-sign">&nbsp;</div>',
            `${newHtmlValueSignSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="owner-sign-at">&nbsp;</div>',
            `${newHtmlValueSignAtSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="owner-sign-ip">&nbsp;</div>',
            `${newHtmlValueSignIpSection}`
          );

          htmlValue = htmlValue.replaceAll(
            "<br><!-- signature section -->",
            '<div style="height: 12px"></div>'
          );

          break;
        case "project-manager":
          htmlValue = htmlValue.replace(
            '<div class="project-manager-sign">&nbsp;</div>',
            `${newHtmlValueSignSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="project-manager-sign-at">&nbsp;</div>',
            `${newHtmlValueSignAtSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="project-manager-sign-ip">&nbsp;</div>',
            `${newHtmlValueSignIpSection}`
          );
          break;
        case "contractor":
          htmlValue = htmlValue.replaceAll(
            '<div class="contractor-sign">&nbsp;</div>',
            `${newHtmlValueSignSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="contractor-sign-at">&nbsp;</div>',
            `${newHtmlValueSignAtSection}`
          );
          htmlValue = htmlValue.replaceAll(
            '<div class="contractor-sign-ip">&nbsp;</div>',
            `${newHtmlValueSignIpSection}`
          );
          break;
      }
      return htmlValue;
    },

    async save({ imageBase64, fullName, currentUser, signerIp, callback }) {
      this.$f7.preloader.show();
      if (this.isUserSign) {
        signerIp = _.cloneDeep(this.additionalData);
      }
      const responseUpload = await this.uploadSignatureImage(imageBase64);
      if (!responseUpload.success) {
        this.showErrorDialog(responseUpload.message);
        this.$f7.preloader.hide();
        return;
      }

      const htmlValue = this.parseSignatureHtmlContent(
        this.signatureSection,
        this.signerRole,
        signerIp,
        responseUpload.data.url,
        fullName
      );
      let sections = _.cloneDeep(this.contract.sections);
      sections = sections.filter(
        section => section.sectionId !== this.signatureSection.sectionId
      );
      let newSection = _.cloneDeep(this.signatureSection);
      newSection.htmlValue = htmlValue;
      sections.splice(this.indexOfSignatureSection, 0, newSection);

      let i = 0;
      sections.forEach(section => {
        section.index = i++;
      });
      let doc = {
        sections
      };

      const signer = {
        signatureImageUrl: responseUpload.data.url,
        signatureImageFullPath: responseUpload.data.fullPath,
        signatureAt: firebase.firestore.Timestamp.now(),
        userSignId: currentUser.uid ? currentUser.uid : null,
        signerIp: signerIp,
        fullNameOfSigner: fullName
      };

      switch (this.signerRole) {
        case "owner":
          doc = {
            ...doc,
            ownerSigner: signer,
            status: "signed"
          };
          break;
        case "project-manager":
          doc = {
            ...doc,
            projectManagerSigner: signer
          };
          break;
        case "contractor":
          doc = {
            ...doc,
            contractorSigner: signer
          };
          break;
      }
      const responseUpdateContract = await this.updateContractDoc(doc);
      if (!responseUpdateContract.success) {
        this.showErrorDialog(responseUpdateContract.message);
        this.$f7.preloader.hide();
        return;
      }
      this.$f7.preloader.hide();
      callback();
      this.$emit("reloadPages");
    }
  }
};
</script>
