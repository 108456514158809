import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { auth } from "@/services/firebase.service";
import { getFullAddress } from "@/utility/address";
import {
  APPENDIX_PAGE,
  BUILDING_DESCRIPTION_PAGE,
  FOOTER_TYPE,
  HEADER_TYPE,
  TEXT_EDITOR_TYPE,
  NUMBER_PRODUCT_PER_PAGE
} from "./const";

import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL,
  TABLE_CONTENT_TYPE_ITEMIZE
} from "@/utility/const";

const assemblyTemplateCodeIsQuantityType = [
  "collectorHead",
  "downspoutTransition",
  "pitchPan"
];

import calcEstimateMixin from "../mixins/calc-estimate-mixin";
import financingProposalMixin from "../mixins/financing-proposal-mixin";
import { currencyUSD } from "@/utility/config";
import moment from "moment";

export default {
  mixins: [calcEstimateMixin, financingProposalMixin],
  data() {
    return {
      keyValueDict: {
        "Company Name": "&nbsp;",
        "Company Address": "&nbsp;",
        "Company Phone Number": "&nbsp;",
        "Company Email Address": "&nbsp;",

        "Property Name": "&nbsp;",
        "PROPERTY NAME": "&nbsp;",
        "Property Address": "&nbsp;",
        "Property Phone Number": "&nbsp;",

        "Roofing Company Name": "&nbsp;",
        "ROOFING COMPANY NAME": "&nbsp;",
        "Roofing Company Address": "&nbsp;",
        "Roofing Company Phone Number": "&nbsp;",
        "Roofing Company Website": "&nbsp;",
        "Roofing Company Email Address": "&nbsp;",

        "Proposed Date": "&nbsp;",
        "Proposal Date": "&nbsp;",
        "Date of Proposal": "&nbsp;",
        "Created Date": "&nbsp;",
        "Proposed By": "&nbsp;",
        "Proposed Email": "&nbsp;",
        "Proposed For": "&nbsp;",

        "Contact Name": "&nbsp;",
        "Contact Address": "&nbsp;",
        "Contact Phone Number": "&nbsp;",
        "Contact Email Address": "&nbsp;",

        "Job Title": "&nbsp;",

        "PROJECT NAME": "&nbsp;",
        "Project Name": "&nbsp;",

        "User Full Name &amp; Phone Number": "&nbsp;",

        Location: "&nbsp;",
        "Project Photo #1": "&nbsp;",

        // estimte
        "Description from Estimate": "&nbsp;",
        "Estimate Description": "&nbsp;"
      },
      keyValueDictOfBuilding: {
        "Building/Section #": "&nbsp;",
        "Slope Type": "&nbsp;",
        "Roof Type": "&nbsp;",
        "What type of substrate": "&nbsp;",
        "First Existing Roof Photos": "&nbsp;",
        "Field Area": "&nbsp;",
        Description: "&nbsp;",
        '"GA" GA "Assembly Item" Up to "Length" LF included': "&nbsp;",
        Warranty: "&nbsp;",
        "Warranty Notes": "&nbsp;",
        "Total Cost": "&nbsp;"
      },
      appendixPages: []
    };
  },

  computed: {
    ...mapGetters({
      setting: "setting/app/system/setting"
    }),
    ...mapGetters("proposal/app-constant", [
      "roofTypeBy",
      "slopeType",
      "workType",
      "tpoWarrantyOption",
      "substrate",
      "gaBy",
      "membraneThickness",
      "insulationType",
      "insulationThickness"
    ]),

    ...mapGetters({
      proposalNew: "proposal/proposal/proposalNew"
    }),

    ...mapGetters({
      proposalTemplate: "proposal/template/proposalTemplate"
    }),
    ...mapGetters("proposal/price-list", ["categoryById"]),

    ...mapGetters('common/app-constant', ['tenantId']),

    hasProject() {
      return this.$f7route.params.projectId && this.$f7route.params.actionId;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/project", [
      "getBoardById",
      "getProjectById",
      "getTaskById",
      "getActionById"
    ]),
    ...mapActions({
      bindSetting: "setting/app/system/bindSetting"
    }),

    async initKeysData({ company, contact, property, project, estimate }) {
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }

      if (!_.isEmpty(company)) {
        if (company.companyName) {
          this.keyValueDict["Company Name"] = company.companyName;
        }
        if (!_.isEmpty(company.addresses)) {
          this.keyValueDict["Company Address"] = getFullAddress(
            company.addresses[0]
          );
        }
        if ((company.phones || []).length && company.phones[0].value) {
          this.keyValueDict["Company Phone Number"] = company.phones[0].value;
        }
        const companyEmailAddress = (
          (company.others || []).find(r => r.code == "email") || {}
        ).value;
        if (companyEmailAddress) {
          this.keyValueDict["Company Email Address"] = companyEmailAddress;
        }
      }

      if (!_.isEmpty(contact)) {
        if (contact.firstName || contact.lastName) {
          this.keyValueDict[
            "Contact Name"
          ] = `${contact.firstName} ${contact.lastName}`;
        }
        if (!_.isEmpty(contact.addresses)) {
          this.keyValueDict["Contact Address"] = getFullAddress(
            contact.addresses[0]
          );
        }
        if ((contact.phones || []).length && contact.phones[0].value) {
          this.keyValueDict["Contact Phone Number"] = contact.phones[0].value;
        }
        const contactEmailAddress = (
          (contact.emails || []).find(r => r.code == "main") || {}
        ).value;
        if (contactEmailAddress) {
          this.keyValueDict["Contact Email Address"] = contactEmailAddress;
        }
      }

      if (!_.isEmpty(property)) {
        if (property.propertyName) {
          this.keyValueDict["Property Name"] = property.propertyName;
          this.keyValueDict["PROPERTY NAME"] = property.propertyName;
        }
        if (!_.isEmpty(property.addresses)) {
          this.keyValueDict["Property Address"] = getFullAddress(
            property.addresses[0]
          );
        }
        if ((property.phones || []).length && property.phones[0].value) {
          this.keyValueDict["Property Phone Number"] = property.phones[0].value;
        }
      }

      if (!_.isEmpty(project)) {
        if (project.title) {
          this.keyValueDict["Project Name"] = project.title;
          this.keyValueDict["PROJECT NAME"] = project.title;
        }
      }

      if (!_.isEmpty(this.setting)) {
        if (this.setting.companyName) {
          this.keyValueDict["Roofing Company Name"] = this.setting.companyName;
          this.keyValueDict[
            "ROOFING COMPANY NAME"
          ] = this.setting.companyName.toUpperCase();
        }
        if (this.setting.address) {
          this.keyValueDict["Roofing Company Address"] = this.setting.address;
        }
        if (this.setting.phoneNumber) {
          this.keyValueDict[
            "Roofing Company Phone Number"
          ] = this.setting.phoneNumber;
        }
        if (this.setting.companyWebsite) {
          this.keyValueDict[
            "Roofing Company Website"
          ] = this.setting.companyWebsite;
        }
      }

      if (auth.currentUser.displayName) {
        this.keyValueDict["Proposed By"] = auth.currentUser.displayName;
        if (auth.currentUser.phoneNumber) {
          this.keyValueDict["User Full Name &amp; Phone Number"] = `${
            auth.currentUser.displayName
          } - ${this.formatPhone(auth.currentUser.phoneNumber)}`;
        }
      }
      if (auth.currentUser.email) {
        this.keyValueDict["Roofing Company Email Address"] =
          auth.currentUser.email;
      }

      if (!_.isEmpty(estimate)) {
        if (estimate.description) {
          this.keyValueDict["Description from Estimate"] = estimate.description;
          this.keyValueDict["Estimate Description"] = estimate.description;
        }
      }

      this.keyValueDict["Proposed Date"] = new Date().toDateString();
      this.keyValueDict["Proposal Date"] = new Date().toDateString();
      this.keyValueDict["Date of Proposal"] = new Date().toDateString();
      this.keyValueDict["Create Date"] = moment().format("MMM DD YYYY");
      this.keyValueDict["Project Photo #1"] = `
        <img
          style="max-width: 100%; max-height: 458px"
          src="#"
          alt=""
        />`;
    },

    productList(building) {
      let products = [];
      ((building || {}).productData || []).forEach(element => {
        let sectionProduct = element.productList.map(r => ({
          ...r,
          category: element.category,
          tax: true
        }));
        products = products.concat(sectionProduct);
      });
      return products;
    },

    initBuildingData({
      products,
      building,
      // company,
      // contact,
      // property,
      project,
      estimate
    }) {
      if (!_.isEmpty(building)) {
        this.keyValueDict["Building/Section #"] =
          building.buildingName || "&nbsp;";
        this.keyValueDict["Building Name"] = building.buildingName || "&nbsp;";
        this.keyValueDict["Slope Type"] =
          this.slopeType(building.slopeType).displayName || "&nbsp;";
        this.keyValueDict["Roof Type"] =
          this.roofTypeBy(building.roofType).displayName || "&nbsp;";
        this.keyValueDict["What type of substrate"] =
          this.substrate(building.substrate).displayName || "&nbsp;";
        this.keyValueDict["Substrate Type"] =
          this.substrate(building.substrate).displayName || "&nbsp;";
        this.keyValueDict["Field Area"] = building.fieldArea || 0;
        this.keyValueDict["Field And Wall Area"] =
          (building.fieldArea || 0) + (building.wallArea || 0);
        this.keyValueDict["Description"] = building.description || "&nbsp;";
        this.keyValueDict["Building Description"] =
          building.description || "&nbsp;";
        this.keyValueDict["Warranty"] =
          this.tpoWarrantyOption(building.warrantyType).displayName || "&nbsp;";
        this.keyValueDict["Warranty Notes"] =
          building.warrantyNotes || "&nbsp;";
        this.keyValueDict["Membrane Thickness"] = this.membraneThickness(
          building.membraneThickness
        ).displayName;

        this.keyValueDict["Tapered Area"] = building.taperedArea || 0;

        // this.keyValueDict["Insulation Thickness"] = this.insulationThickness(
        //   building.membraneThickness
        // ).displayName;

        this.keyValueDict["Total Cost"] = `<b>${currencyUSD(
          this.total(project, building, estimate, products) || 0
        )}</b>`;
        // if have data to show in appendix pages
        const firstExistingRoofPhotos = (building.attachmentFiles || []).find(
          r => {
            return (
              r.fileName.toLowerCase().includes(".png") ||
              r.fileName.toLowerCase().includes(".jpg") ||
              r.fileName.toLowerCase().includes(".jpeg")
            );
          }
        );
        if (!_.isEmpty(firstExistingRoofPhotos)) {
          this.keyValueDict[
            "First Existing Roof Photos"
          ] = `First Existing Roof Photos: <i style="color: gray">(See appendix)</i>`;
        } else {
          this.keyValueDict["First Existing Roof Photos"] = "&nbsp;";
        }

        if (
          !_.isEmpty(building.assemblyList) &&
          building.assemblyList.some(r => r.assemblyItem)
        ) {
          this.keyValueDict[
            '"GA" GA "Assembly Item" Up to "Length" LF included'
          ] = `<i style="color: gray">(See appendix)</i>`;
        } else {
          this.keyValueDict[
            '"GA" GA "Assembly Item" Up to "Length" LF included'
          ] = "&nbsp;";
        }
      }
    },

    prepareAppendixPages(building, pageTemplate, appendixTitle) {
      let pages = [];
      // clone header and footer
      let page = _.cloneDeep(pageTemplate);
      page.components = page.components.filter(
        r => r.type == HEADER_TYPE || r.type == FOOTER_TYPE
      );
      if (!_.isEmpty(building.attachmentFiles)) {
        pages.push(
          this.prepareFirstExistRoofPhotoPage(building, page, appendixTitle)
        );
      }
      if (
        !_.isEmpty(building.assemblyList) &&
        building.assemblyList.some(r => r.assemblyItem)
      ) {
        pages.push(...this.prepareAssemblyPage(building, page, appendixTitle));
      }
      return pages;
    },

    prepareFirstExistRoofPhotoPage(building, pageTemplate, appendixTitle) {
      let page = _.cloneDeep(pageTemplate);
      const pageContent = {
        type: TEXT_EDITOR_TYPE,
        data: {
          value: `
            ${appendixTitle}
            <h3>${building.buildingName}: First Existing Roof Photos</h3>
            <img
              style="max-width: 100%; max-height: 755px"
              src="${building.attachmentFiles[0].url}"
              alt="${building.attachmentFiles[0].url}"
            />
          `
        }
      };
      page.name = APPENDIX_PAGE;
      page.components.splice(1, 0, pageContent);
      return page;
    },

    prepareAssemblyPage(building, pageTemplate, appendixTitle) {
      let pages = [];
      building.assemblyList.forEach((assemblyItem, index) => {
        if (!_.isEmpty(assemblyItem)) {
          let page = _.cloneDeep(pageTemplate);
          const titleAppendix =
            _.isEmpty(building.attachmentFiles) && index === 0
              ? appendixTitle
              : "";

          const pageContent = {
            type: TEXT_EDITOR_TYPE,
            data: {
              value: `
                ${titleAppendix}
                <h3>
                  ${building.buildingName}: ${
                assemblyItem.assemblyGA
                  ? this.gaBy(assemblyItem.assemblyGA).displayName
                  : ""
              } GA ${assemblyItem.templateName ||
                assemblyItem.assemblyItem} Up to ${assemblyItem.length ||
                0} LF (linear feet) included.
                </h3>
                <img
                  style="max-width: 100%; max-height: 755px"
                  src="${assemblyItem.diagramUrl}"
                  alt="${
                    assemblyItem.assemblyGA
                      ? this.gaBy(assemblyItem.assemblyGA).displayName
                      : ""
                  }"
                />
              `
            }
          };
          page.name = APPENDIX_PAGE;
          page.components.splice(1, 0, pageContent);
          pages.push(page);
        }
      });
      return pages;
    },

    async parseTemplatePageInfo(
      templatePages,
      company = {},
      contact = {},
      property = {},
      project = {},
      estimate = {},
      buildingList = []
    ) {
      await this.initKeysData({
        company,
        contact,
        property,
        project,
        estimate
      });
      let pages = _.cloneDeep(templatePages);
      let appendixTitle = `<h2 style="text-align: center" class="">APPENDIX</h2>`;
      for (let i = 0; i < pages.length; i++) {
        if (pages[i].name === BUILDING_DESCRIPTION_PAGE) {
          const { buildingId } = pages[i].components[1].data;
          const building = buildingList.find(r => r.id === buildingId) || {};
          // init building data
          this.initBuildingData({ building, estimate, project });
          // prepare appendix pages
          const appendixPages = this.prepareAppendixPages(
            building,
            pages[0],
            appendixTitle
          );
          appendixTitle = "";
          pages.push(...appendixPages);
        }
        this.parsePage(pages[i]);
      }
      return pages;
    },
    parsePage(page) {
      (page.components || []).forEach(component => {
        this.parsePageComponent(component);
      });
    },
    parsePageComponent(component) {
      let keys = Object.keys(component.data);
      keys.forEach(key => {
        component.data[key] = this.parseContent(component.data[key]);
      });
    },
    parseContent(value) {
      if (!value) return "";
      if (typeof value === "object") return value;
      let keys = Object.keys(this.keyValueDict);
      keys.forEach(key => {
        const regex = new RegExp(`{\\s+${key}\\s+}`, "ig");
        value = value.replace(regex, this.keyValueDict[key]);
      });
      return value;
    },
    handleBackAction() {
      if (
        !_.isEmpty(this.$f7route.query) &&
        !_.isEmpty(this.$f7route.query.estimateNumber)
      ) {
        const {
          clientType,
          clientId,
          buildingName,
          estimateNumber
        } = this.$f7route.query;
        if (_.isEmpty(buildingName)) {
          this.$f7router.navigate(
            `/estimate/${clientType ? `${clientType}/` : ""}${
              clientId ? `${clientId}/` : ""
            }${estimateNumber}/summary`,
            {
              pushState: true
            }
          );
        } else {
          this.$f7router.navigate(
            `/estimate/${clientType ? `${clientType}/` : ""}${
              clientId ? `${clientId}/` : ""
            }${estimateNumber}/building/${buildingName}`,
            {
              pushState: true
            }
          );
        }
      } else if (
        this.$f7route.params.projectId &&
        this.$f7route.params.actionId
      ) {
        this.navigateToBoard();
      } else {
        this.$f7router.navigate("/proposal", {
          pushState: true,
          reloadAll: true
        });
      }
    },

    formatPhone(phoneNumber) {
      let numberFormat = (phoneNumber || "").slice(2);
      return phoneNumber
        ? "(+1) " +
            numberFormat.substring(0, 3) +
            "-" +
            numberFormat.substring(3, 6) +
            "-" +
            numberFormat.substring(6)
        : null;
    },

    async navigateToBoard() {
      this.$f7.preloader.show();
      const res = await Promise.all([
        this.$f7route.params.boardId &&
          this.getBoardById(this.$f7route.params.boardId),
        this.$f7route.params.projectId &&
          this.getProjectById(this.$f7route.params.projectId),
        this.$f7route.params.taskId &&
          this.getTaskById(this.$f7route.params.taskId),
        this.$f7route.params.actionId &&
          this.getActionById(this.$f7route.params.actionId)
      ]);
      const board = res[0] || {};
      const card = res[1] || {};
      const task = res[2] || {};
      const action = res[3] || {};

      const businessCode = this.$f7route.params.businessCode || "";
      let url = "";

      switch (businessCode) {
        case "commercial":
          url = `/dashboard/${this.$f7route.params.boardType}/commercial/${this.$f7route.params.boardId}/project/${card.cardNumber}/action/${action.code}`;
          if (this.$f7route.params.boardId === "construction") {
            url += `/task/${task.taskNumber}`;
          }
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true
          });
          break;

        case "residential":
          url = `/dashboard/${this.$f7route.params.boardType}/residential/${card.cardNumber}/action/${action.code}`;

          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true
          });
          break;

        case "service":
          this.$f7router.navigate(
            `/dashboard/${this.$f7route.params.boardType}/${this.$f7route.params.businessCode}/${board.boardNumber}/card/${card.cardNumber}?activeTab=${this.$f7route.query.activeTab}`,
            {
              pushState: true,
              reloadAll: true
            }
          );
          break;
      }

      this.$f7.preloader.hide();
    },

    parseHtmlMetaContent(value) {
      if (!value) return "";
      if (typeof value === "object") return value;
      let keys = Object.keys(this.keyValueDict);
      keys.forEach(key => {
        value = value.replaceAll(
          `<span style="background-color: #f05034;">[${key}]</span>`,
          this.keyValueDict[key]
        );
      });
      return value;
    },

    parseMetaContent(value) {
      if (!value) return "";
      if (typeof value === "object") return value;
      let keys = Object.keys(this.keyValueDict);
      keys.forEach(key => {
        value = value.replaceAll(`[${key}]`, this.keyValueDict[key]);
      });
      return value;
    },

    compileSection(orgSection) {
      let htmlValue = this.parseHtmlMetaContent(
        _.cloneDeep(orgSection.htmlValue)
      );
      return {
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: orgSection.sectionName,
        actions: orgSection.actions || []
      };
    },

    compileBuildingInfo({ products, value, building, project, estimate }) {
      this.initBuildingData({
        products,
        value,
        building,
        project,
        estimate
      });
      const totalCost = this.total(project, building, estimate, products);
      this.keyValueDict["Total Cost"] = currencyUSD(totalCost) || "&nbsp;";
      return this.parseHtmlMetaContent(value);
    },

    // Only for create new action
    compileBuildingInfoSection({ orgSection, building, project, estimate }) {
      const productData = [];
      (building.productData || []).forEach(section => {
        productData.push(
          ...section.productList.map(item => {
            return { ...item, checked: true };
          })
        );
      });

      let htmlValue = "";
      let blockData = [];

      if (project.businessCode === "commercial") {
        blockData = this.initBlockData({ building, orgSection });
        htmlValue = this.compileBuildingInfoByBlocks({
          blockData,
          products: _.cloneDeep(productData),
          value: _.cloneDeep(orgSection.htmlValue),
          building,
          project,
          estimate
        });
      } else {
        htmlValue = this.compileBuildingInfo({
          products: productData,
          value: _.cloneDeep(orgSection.htmlValue),
          building,
          project,
          estimate
        });
      }

      return {
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: `${orgSection.sectionName} of ${building.buildingName}`,
        actions: orgSection.actions || [],
        buildingId: building.id,
        blockData
      };
    },

    calculateProductPrice(product, estimate) {
      const price = product.price || 0;
      const misc = (estimate.miscPercent || 0) / 100;
      const profitMargin = (estimate.crossProfitMarginRatio || 0) / 100;
      return price / (1 - misc - profitMargin);
    },

    getProductTableFooterType(isNew) {
      return isNew
        ? this.proposalNew.tableContentType || TABLE_CONTENT_TYPE_ITEMIZE
        : this.proposal
        ? this.proposal.tableContentType
        : TABLE_CONTENT_TYPE_ITEMIZE;
    },

    getProductTableNumberOfPage(products) {
      return Math.ceil((products.length || 0) / NUMBER_PRODUCT_PER_PAGE);
    },

    compileProductTableBody({
      project,
      building,
      products,
      estimate,
      counter,
      isNew
    }) {
      const subProductList = _.cloneDeep(products).slice(
        counter * NUMBER_PRODUCT_PER_PAGE,
        (counter + 1) * NUMBER_PRODUCT_PER_PAGE
      );
      let rows = "";

      // Build the rows of table
      const doesApplyTaxForMaterial = this.doesApplyTaxForMaterial(
        project,
        building,
        estimate
      );
      const doesApplyTaxForLabor = this.doesApplyTaxForLabor(
        project,
        building,
        estimate
      );
      for (let index = 0; index < subProductList.length; index++) {
        let product = subProductList[index];
        product.tax =
          (this.categoryById(product.categoryId) || {}).name === "Labor"
            ? doesApplyTaxForLabor
              ? this.laborTaxRate(project, building, estimate) + "%"
              : "0%"
            : doesApplyTaxForMaterial
            ? this.materialTaxRate(project, building, estimate) + "%"
            : "0%";
        product.taxAmount = product.tax
          ? currencyUSD(this.taxAmount(project, building, estimate, [product]))
          : "";
        rows +=
          `<tr style="border-bottom: 2px solid #1A79A1;">` +
          // product-description
          (this.showColumn("product-description", isNew)
            ? `<td style="padding: 5px;">${product.productItem}</td>`
            : "") +
          // unit-price
          (this.showColumn("unit-price", isNew)
            ? `<td style="text-align: right; padding: 5px;">${currencyUSD(
                this.calculateProductPrice(product, estimate)
              )}</td>`
            : "") +
          // proposal-note
          (this.showColumn("proposal-note", isNew)
            ? `<td style="padding: 5px;">${product.proposalNote || ""}</td>`
            : "") +
          // manufacturer
          (this.showColumn("manufacturer", isNew)
            ? `<td style="padding: 5px;">${product.manufacturer}</td>`
            : "") +
          // quantity
          (this.showColumn("quantity", isNew)
            ? `<td style="text-align: right; padding: 5px;">${this.orderQty(
                building,
                product
              )}</td>`
            : "") +
          // technical-data
          (this.showColumn("technical-data", isNew)
            ? `<td>
              <ul>
                ${
                  Array.isArray(product.technicalData)
                    ? product.technicalData
                        .map(
                          (attach, index) =>
                            `<li :key="${index}">
                        <a class="display-block link external" href="${attach.url}" target="_blank">
                          ${attach.name}
                        </a>
                      </li>`
                        )
                        .join("")
                    : ""
                }
              </ul>
            </td>`
            : "") +
          // tax
          (this.showColumn("tax", isNew)
            ? `<td style="text-align: right; padding: 5px;">${product.tax}</td>`
            : "") +
          // tax-amount
          (this.showColumn("tax-amount", isNew)
            ? `<td style="text-align: right; padding: 5px;">${product.taxAmount}</td>`
            : "") +
          // price
          (this.showColumn("total-price", isNew)
            ? `<td style="text-align: right; padding: 5px;">${currencyUSD(
                this.calculateProductPrice(product, estimate) *
                  this.orderQty(building, product) +
                  this.taxAmount(project, building, estimate, [product])
              )}</td>`
            : "") +
          `</tr>`;
      }
      const numberOfPage = this.getProductTableNumberOfPage(products);

      // If the last page
      if (counter === numberOfPage - 1) {
        return (
          `<table style="width: 100%; border: 0px; border-collapse:collapse">
                <tr style="background-color: #1A79A1; color: white; font-weight:500; font-size: 16px;">` +
          // product-description
          (this.showColumn("product-description", isNew)
            ? `<th style="width:150px; text-align: left; padding: 5px;">Description</th>`
            : "") +
          // unit-price
          (this.showColumn("unit-price", isNew)
            ? `<th style="text-align: right; padding: 5px;">Price</th>`
            : "") +
          // proposal-note
          (this.showColumn("proposal-note", isNew)
            ? `<th style="text-align: left; padding: 5px;">Note</th>`
            : "") +
          // manufacturer
          (this.showColumn("manufacturer", isNew)
            ? `<th style="text-align: left; padding: 5px;">Manufacturer</th>`
            : "") +
          // quantity
          (this.showColumn("quantity", isNew)
            ? `<th style="text-align: right; padding: 5px;">Qty</th>`
            : "") +
          // technical-data
          (this.showColumn("technical-data", isNew)
            ? `<th style="width:300px; text-align: left; padding: 5px;">Technical Data</th>`
            : "") +
          // tax
          (this.showColumn("tax", isNew)
            ? `<th style="text-align: right; padding: 5px;">Tax%</th>`
            : "") +
          // tax
          (this.showColumn("tax-amount", isNew)
            ? `<th style="text-align: right; padding: 5px;">Tax Amount</th>`
            : "") +
          // price
          (this.showColumn("total-price", isNew)
            ? `<th style="text-align: right; padding: 5px;">Total</th>`
            : "") +
          //
          `</tr>
                ${rows}
                <tr style="border-bottom: 2px solid #1A79A1;">
                    <td colspan="${this.columnCountTotal(
                      isNew
                    )}" style="padding: 5px;"></td>
                </tr>

                <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                    <td colspan="${this.numberOfVisibleColumn(isNew) -
                      1}" style="padding: 5px;">Sub Total</td>
                    <td style="text-align: right; padding: 5px;">${currencyUSD(
                      this.subTotal(estimate, products, building)
                    )}</td>
                </tr>
                <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                    <td colspan="${this.numberOfVisibleColumn(isNew) -
                      1}" style="padding: 5px;">Total</td>
                    <td style="text-align: right; padding: 5px;">${currencyUSD(
                      this.total(project, building, estimate, products)
                    )}</td>
                </tr>
                </table>`
        );
      } else {
        return (
          `<table style="width: 100%; border: 0px; border-collapse:collapse">
        <tr style="background-color: #1A79A1; color: white; font-weight:500; font-size: 16px;">` +
          // product-description
          (this.showColumn("product-description", isNew)
            ? `<th style="text-align: left; padding: 5px;">Description</th>`
            : "") +
          // unit-price
          (this.showColumn("unit-price", isNew)
            ? `<th style="text-align: right; padding: 5px;">Price</th>`
            : "") +
          // proposal-note
          (this.showColumn("proposal-note", isNew)
            ? `<th style="text-align: left; padding: 5px;">Note</th>`
            : "") +
          // manufacturer
          (this.showColumn("manufacturer", isNew)
            ? `<th style="text-align: left; padding: 5px;">Manufacturer</th>`
            : "") +
          // quantity
          (this.showColumn("quantity", isNew)
            ? `<th style="text-align: right; padding: 5px;">Qty</th>`
            : "") +
          // technical-data
          (this.showColumn("technical-data", isNew)
            ? `<th style="text-align: left; padding: 5px;">Technical Data</th>`
            : "") +
          // tax
          (this.showColumn("tax", isNew)
            ? `<th style="text-align: right; padding: 5px;">Tax%</th>`
            : "") +
          // tax-amount
          (this.showColumn("tax-amount", isNew)
            ? `<th style="text-align: right; padding: 5px;">Tax Amount</th>`
            : "") +
          // price
          (this.showColumn("total-price", isNew)
            ? `<th style="text-align: right; padding: 5px;">Total</th>`
            : "") +
          `</tr>
        ${rows}
        </table>`
        );
      }
    },

    showColumn(id, isNew) {
      const proposal = isNew ? this.proposalTemplate : this.proposal;
      const foundItem = (proposal.productTableColumns || []).find(
        item => item.id === id
      );

      if (foundItem) {
        return !!foundItem.checked;
      }

      return false;
    },

    columnCountTotal(isNew) {
      const proposal = isNew ? this.proposalTemplate : this.proposal;
      return (proposal.productTableColumns || []).length;
    },

    numberOfVisibleColumn(isNew) {
      const proposal = isNew ? this.proposalTemplate : this.proposal;
      return (
        (proposal.productTableColumns || []).filter(
          item => item.checked === true
        ) || []
      ).length;
    },

    isCheckedProduct(product) {
      return !(
        product.orderQty === undefined ||
        product.orderQty === null ||
        product.orderQty === 0.0 ||
        Number.isNaN(product.orderQty) ||
        product.price === undefined ||
        product.price === null ||
        product.price === 0.0 ||
        Number.isNaN(product.price)
      );
    },

    buildItemizeTable({
      orgSection,
      project,
      products,
      building,
      estimate,
      isNew
    }) {
      const numberOfPage = this.getProductTableNumberOfPage(products);

      const sections = [];
      let tableProductHtml = "";
      let htmlValue = "";
      if (numberOfPage === 0) {
        htmlValue = orgSection.htmlValue.replaceAll(
          '<span style="background-color: #f05034;">[Product Data Table]</span>',
          `<table style="width: 100%; border: 0px; border-collapse:collapse">
            <tr style="background-color: #1A79A1; color: white; font-weight:500; font-size: 16px;">` +
            // product-description
            (this.showColumn("product-description", isNew)
              ? `<th style="text-align: left; padding: 5px;">Description</th>`
              : "") +
            // unit-price
            (this.showColumn("unit-price", isNew)
              ? `<th style="text-align: right; padding: 5px;">Price</th>`
              : "") +
            // proposal-note
            (this.showColumn("proposal-note", isNew)
              ? `<th style="text-align: right; padding: 5px;">Note</th>`
              : "") +
            // manufacturer
            (this.showColumn("manufacturer", isNew)
              ? `<th style="text-align: left; padding: 5px;">Manufacturer</th>`
              : "") +
            // quantity
            (this.showColumn("quantity", isNew)
              ? `<th style="text-align: right; padding: 5px;">Qty</th>`
              : "") +
            // technical-data
            (this.showColumn("technical-data", isNew)
              ? `<th style="text-align: left; padding: 5px;">Technical Data</th>`
              : "") +
            // tax
            (this.showColumn("tax", isNew)
              ? `<th style="text-align: right; padding: 5px;">Tax%</th>`
              : "") +
            // tax-amount
            (this.showColumn("tax-amount", isNew)
              ? `<th style="text-align: right; padding: 5px;">Tax Amount</th>`
              : "") +
            // price
            (this.showColumn("total-price", isNew)
              ? `<th style="text-align: right; padding: 5px;">Total</th>`
              : "") +
            `</tr>
            <tr style="border-bottom: 2px solid #1A79A1;">` +
            // product-description
            (this.showColumn("product-description", isNew)
              ? `<td style="padding: 5px;"></td>`
              : "") +
            // unit-price
            (this.showColumn("unit-price", isNew)
              ? `<td style="text-align: right; padding: 5px;">${currencyUSD(
                  0
                )}</td>`
              : "") +
            // proposal-note
            (this.showColumn("proposal-note", isNew)
              ? `<td style="padding: 5px;"></td>`
              : "") +
            // manufacturer
            (this.showColumn("manufacturer", isNew)
              ? `<td style="padding: 5px;"></td>`
              : "") +
            // quantity
            (this.showColumn("quantity", isNew)
              ? `<td style="text-align: right; padding: 5px;">0</td>`
              : "") +
            // technical-data
            (this.showColumn("technical-data", isNew)
              ? `<th style="text-align: left; padding: 5px;"></th>`
              : "") +
            // tax
            (this.showColumn("tax", isNew)
              ? `<th style="text-align: right; padding: 5px;"></th>`
              : "") +
            // tax-amount
            (this.showColumn("tax-amount", isNew)
              ? `<th style="text-align: right; padding: 5px;"></th>`
              : "") +
            // price
            (this.showColumn("total-price", isNew)
              ? `<td style="text-align: right; padding: 5px;">${currencyUSD(
                  0
                )}</td>`
              : "") +
            `</tr>
            <tr style="border-bottom: 2px solid #1A79A1;">
                <td colspan="${this.columnCountTotal(
                  isNew
                )}" style="padding: 5px;"></td>
            </tr>
            <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                <td colspan="${this.numberOfVisibleColumn(isNew) -
                  1}" style="padding: 5px;">Sub Total</td>
                <td style="text-align: right; padding: 5px;">${currencyUSD(
                  0
                )}</td>
            </tr>
            <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                <td colspan="${this.numberOfVisibleColumn(isNew) -
                  1}" style="padding: 5px;">Tax ${this.materialTaxRate(
              project,
              building,
              estimate
            ) || this.laborTaxRate(project, building, estimate)}%</td>
                <td style="text-align: right; padding: 5px;">${currencyUSD(
                  0
                )}</td>
            </tr>
            <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                <td colspan="${this.numberOfVisibleColumn(isNew) -
                  1}" style="padding: 5px;">Total</td>
                <td style="text-align: right; padding: 5px;">${currencyUSD(
                  0
                )}</td>
            </tr>
          </table>`
        );

        sections.push({
          htmlValue: _.cloneDeep(htmlValue),
          sectionId: orgSection.sectionId,
          sectionName: `${orgSection.sectionName} of ${building.buildingName}`,
          actions: orgSection.actions || [],
          buildingId: building.id
        });
      } else {
        for (let counter = 0; counter < numberOfPage; counter++) {
          tableProductHtml = this.compileProductTableBody({
            project,
            building,
            products,
            estimate,
            counter,
            isNew
          });

          if (counter === 0) {
            htmlValue = orgSection.htmlValue.replaceAll(
              '<span style="background-color: #f05034;">[Product Data Table]</span>',
              tableProductHtml
            );
          } else {
            htmlValue = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                            <div>
                                <div style="text-align: justify; padding: 56px;">
                                    <div>
                                        ${tableProductHtml}
                                    </div>
                                </div>
                            </div>
                        </font>`;
          }

          sections.push({
            htmlValue: _.cloneDeep(htmlValue),
            sectionId: orgSection.sectionId,
            sectionName: `${orgSection.sectionName} of ${building.buildingName}`,
            actions: orgSection.actions || [],
            buildingId: building.id
          });
        }
      }

      return sections;
    },

    buildSimpleSummaryTable({
      orgSection,
      project,
      products,
      building,
      estimate
    }) {
      const sections = [];
      let tableProductHtml = "";
      let htmlValue = "";
      const rows = `<tr style="border-bottom: 2px solid #1A79A1;">
                      <td style="padding: 5px;">Total Cost
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.subTotal(estimate, products, building)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${this.materialTaxRate(project, building, estimate) ||
                        this.laborTaxRate(project, building, estimate)}%
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.taxAmount(project, building, estimate, products)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.total(project, building, estimate, products)
                      )}
                      </td>
                    </tr>`;

      tableProductHtml = `<table style="width: 100%; border: 0px; border-collapse:collapse">
      <tr style="background-color: #1A79A1; color: white; font-weight:500; font-size: 16px;">
          <th style="text-align: left; padding: 5px;">Description</th>
          <th style="text-align: right; padding: 5px;">Amount</th>
          <th style="text-align: right; padding: 5px;">Tax%</th>
          <th style="text-align: right; padding: 5px;">Tax Amount</th>
          <th style="text-align: right; padding: 5px;">Total</th>
      </tr>
      ${rows}
      </table>`;

      htmlValue = orgSection.htmlValue.replaceAll(
        '<span style="background-color: #f05034;">[Product Data Table]</span>',
        tableProductHtml
      );

      sections.push({
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: `${orgSection.sectionName} of ${building.buildingName}`,
        actions: orgSection.actions || [],
        buildingId: building.id
      });

      return sections;
    },

    buildLaborMaterialTable({
      orgSection,
      project,
      products,
      building,
      estimate
    }) {
      const sections = [];
      let tableProductHtml = "";
      let htmlValue = "";
      const rows = `
      <tr style="border-bottom: 2px solid #1A79A1;">
                      <td style="padding: 5px;">Material Cost
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.materialNetSales(estimate, products, building)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${this.materialTaxRate(project, building, estimate)}%
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.materialTax(project, building, estimate, products)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.materialNetSales(estimate, products, building) +
                          this.materialTax(
                            project,
                            building,
                            estimate,
                            products
                          )
                      )}
                      </td>
                    </tr>

                    <tr style="border-bottom: 2px solid #1A79A1;">
                      <td style="padding: 5px;">Labor Cost
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.laborNetSales(estimate, products, building)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${this.laborTaxRate(project, building, estimate)}%
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.laborTax(project, building, estimate, products)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.laborNetSales(estimate, products, building) +
                          this.laborTax(project, building, estimate, products)
                      )}
                      </td>
                    </tr>

                      <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                      <td style="padding: 5px;">Total Cost
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.subTotal(estimate, products, building)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${this.materialTaxRate(project, building, estimate) ||
                        this.laborTaxRate(project, building, estimate)}%
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.taxAmount(project, building, estimate, products)
                      )}
                      </td>
                      <td style="text-align: right; padding: 5px;">
                      ${currencyUSD(
                        this.total(project, building, estimate, products)
                      )}
                      </td>
                    </tr>`;

      tableProductHtml = `<table style="width: 100%; border: 0px; border-collapse:collapse">
      <tr style="background-color: #1A79A1; color: white; font-weight:500; font-size: 16px;">
          <th style="text-align: left; padding: 5px;">Description</th>
          <th style="text-align: right; padding: 5px;">Amount</th>
          <th style="text-align: right; padding: 5px;">Tax%</th>
          <th style="text-align: right; padding: 5px;">Tax Amount</th>
          <th style="text-align: right; padding: 5px;">Total</th>
      </tr>
      ${rows}
      </table>`;

      htmlValue = orgSection.htmlValue.replaceAll(
        '<span style="background-color: #f05034;">[Product Data Table]</span>',
        tableProductHtml
      );

      sections.push({
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: `${orgSection.sectionName} of ${building.buildingName}`,
        actions: orgSection.actions || [],
        buildingId: building.id
      });

      return sections;
    },

    buildProductSectionContent({
      products,
      orgSection,
      project,
      building,
      estimate,
      isNew
    }) {
      const tableContentType = this.getProductTableFooterType(isNew);
      switch (tableContentType) {
        case TABLE_CONTENT_TYPE_ITEMIZE:
          return this.buildItemizeTable({
            orgSection,
            project,
            products,
            building,
            estimate,
            isNew
          });

        case TABLE_CONTENT_TYPE_LABOR_MATERIAL:
          return this.buildLaborMaterialTable({
            orgSection,
            project,
            products,
            building,
            estimate
          });

        case TABLE_CONTENT_TYPE_SIMPLE_SUMMARY:
          return this.buildSimpleSummaryTable({
            orgSection,
            project,
            products,
            building,
            estimate
          });

        default:
          return this.buildItemizeTable({
            orgSection,
            project,
            products,
            building,
            estimate,
            isNew
          });
      }
    },

    compileFinancingProposal({ financingList, value }) {
      const sortedList = financingList.sort((a, b) => a.priority - b.priority);
      const htmlValue = `<table style="width: 100%; border: 0px; border-collapse:collapse">
                <tr style="background-color: #1A79A1; color: white; font-weight:500; font-size: 16px;">
                    <th style="text-align: left; padding: 5px;">Option</th>
                    ${sortedList.reduce((pre, curr) => {
                      return (pre += `<th style="text-align: center; padding: 5px;">${curr.name}</th>`);
                    }, "")}
                </tr>
                <tr style="border-bottom: 2px solid #1A79A1;">
                  <td style="padding: 5px;">Project Cost</td>
                  ${sortedList.reduce((pre, curr) => {
                    return (pre += `<td style="text-align: center; padding: 5px;">${currencyUSD(
                      curr.projectCost
                    )}</td>`);
                  }, "")}
                </tr>

                <tr style="border-bottom: 2px solid #1A79A1;">
                  <td style="padding: 5px;">Term (months)</td>
                  ${sortedList.reduce((pre, curr) => {
                    return (pre += `<td style="text-align: center; padding: 5px;">${curr.term}</td>`);
                  }, "")}
                </tr>

                <tr style="border-bottom: 2px solid #1A79A1;">
                  <td style="padding: 5px;">Down Payment ($)</td>
                  ${sortedList.reduce((pre, curr) => {
                    return (pre += `<td style="text-align: center; padding: 5px;">${currencyUSD(
                      curr.downPayment
                    )}</td>`);
                  }, "")}
                </tr>

                <tr style="border-bottom: 2px solid #1A79A1;">
                  <td style="padding: 5px;">Interest Rate (%)</td>
                  ${sortedList.reduce((pre, curr) => {
                    return (pre += `<td style="text-align: center; padding: 5px;">${curr.interestRate}%</td>`);
                  }, "")}
                </tr>

                <tr style="background-color: #1A79A1; color: white; font-weight:600; font-size: 16px; border-bottom: 2px solid #1A79A1;">
                  <td style="padding: 5px;">Monthly Payment ($)</td>
                  ${sortedList.reduce((pre, curr) => {
                    return (pre += `<td style="text-align: center; padding: 5px;">${currencyUSD(
                      curr.monthlyPayment
                    )}</td>`);
                  }, "")}
                  
                </tr>
                </table>`;

      return value.replaceAll(
        '<span style="background-color: #f05034;">[Financing Data Table]</span>',
        htmlValue
      );
    },

    // eslint-disable-next-line no-unused-vars
    compileFinancingProposalSection(
      orgSection,
      buildingList,
      project,
      estimate
    ) {
      // console.log("compileFinancingProposalSection", orgSection, buildingList);
      const self = this;
      return this.initFinancingProposal({
        buildingIDs: buildingList.map(item => item.id),
        project,
        estimate,
        isNew: true
      })
        .then(data => {
          return self.compileFinancingProposal({
            financingList: data,
            value: orgSection.htmlValue
          });
        })
        .then(htmlValue => {
          // console.log("htmlValue", htmlValue);
          return {
            htmlValue,
            sectionId: orgSection.sectionId,
            sectionName: orgSection.sectionName,
            actions: orgSection.actions || []
          };
        });
    },

    // Only for create new action
    compileProductTableSection(orgSection, building, estimate, project) {
      const productData = [];

      // get all products
      (building.productData || []).forEach(section => {
        productData.push(
          ...section.productList.map(item => {
            return { ...item };
          })
        );
      });

      // check the qty and price
      productData.forEach(product => {
        product.checked = this.isCheckedProduct(product);
      });
      const checkedProducts = productData.filter(item => item.checked);

      return this.buildProductSectionContent({
        products: checkedProducts,
        orgSection,
        project,
        building,
        estimate,
        isNew: true
      });
    },

    compileAssemblyPhoto(assembly) {
      return `<div>
                <span style="font-weight:600; font-size: 16px; color: #1A79A1;">${assembly.title}</span>
                <br>
              </div>
              <div style="max-height: 850px">
                <img src="${assembly.imgUrl}" alt="${assembly.title}" style="display: block; margin-left: auto; margin-right: auto; max-width: 100%; max-height: 850px">
                <br>
              </div>`;
    },

    // Only for create new action
    compileAssemblyPhotoSection(orgSection, buildingList) {
      const sections = [];
      const photoData = [];

      buildingList.forEach(building => {
        (building.assemblyList || []).forEach(assembly => {
          if (
            assemblyTemplateCodeIsQuantityType.includes(assembly.assemblyItem)
          ) {
            photoData.push({
              title: `${building.buildingName}: ${
                assembly.assemblyGA
                  ? this.gaBy(assembly.assemblyGA).displayName
                  : ""
              } GA ${assembly.templateName ||
                assembly.assemblyItem} up to ${assembly.qty ||
                0} piece(s) included.`,
              imgUrl: `${assembly.diagramUrl}`
            });
          } else {
            photoData.push({
              title: `${building.buildingName}: ${
                assembly.assemblyGA
                  ? this.gaBy(assembly.assemblyGA).displayName
                  : ""
              } GA ${assembly.templateName ||
                assembly.assemblyItem} up to ${assembly.length ||
                0} LF (linear feet) included.`,
              imgUrl: `${assembly.diagramUrl}`
            });
          }
        });
      });

      let index = 0;
      photoData.forEach(assembly => {
        const assemblyPhotosHtml = this.compileAssemblyPhoto(assembly);
        const htmlValue = orgSection.htmlValue.replaceAll(
          '<span style="background-color: #f05034;">[Assembly Drawing]</span>',
          assemblyPhotosHtml
        );
        index++;
        sections.push({
          htmlValue,
          sectionId: `${orgSection.sectionId}_${index}`,
          sectionName: `${orgSection.sectionName} ${index}`,
          actions: orgSection.actions || []
        });
      });

      return sections;
    },

    compilePhotos(photos) {
      let rows = "";
      photos.forEach(item => {
        rows += `
          <tr>
            <td rowspan="2" style="width:45%; height: 400px; background: #f6f6f6; border-radius: 8px; text-align:center">
            <img src="${
              item.photoUrl
            }" alt="pic" style="width: 100%;height: 100%;object-fit: contain;">
            </td>
            <td style="width:55%; padding-left: 20px; vertical-align: top; font-size: 16px">${item.notes ||
              ""}</td>
          </tr>
          <tr style="height: 53px">
            <td style="width:55%; padding-left: 20px; font-size: 16px; vertical-align: bottom;">
              <p><span style="color: #adadad">Date:</span> ${item.createdAt &&
                moment(item.createdAt.toDate()).format(
                  "MM/DD/YYYY, hh:mm a"
                )}</p>
              <p style="margin: 0"><span style="color: #adadad">Creator:</span> ${
                item.createdBy
              }</p>
            </td>
          </tr>
        `;
      });
      return `
        <table style="width:100%; border-spacing: 0 30px">
          ${rows}
        </table>
      `;
    },

    // Only for create new action
    compileProjectPhotoSection(orgSection, photos, album) {
      const sections = [];
      let counter = 1;
      let tempList = [];
      const numberPhotoPerPage = 2; // 2 photos per page

      for (let index = 0; index < photos.length; index++) {
        const photo = photos[index];

        tempList.push(photo);
        if (counter === numberPhotoPerPage || index === photos.length - 1) {
          counter = 1;
          const photosHtml = this.compilePhotos(tempList);
          let htmlValue = orgSection.htmlValue.replace(
            '<span style="font-weight:600; font-size: 24px; color: #1A79A1;">Project Photos</span>',
            `<span style="font-weight:600; font-size: 24px; color: #1A79A1;">${
              !_.isEmpty(album) ? `${album.name} Photos` : "Other Photos"
            }</span>`
          );
          htmlValue = htmlValue.replace(
            '<span style="background-color: #f05034;">[Project Photos]</span>',
            photosHtml
          );

          sections.push({
            htmlValue,
            photos,
            albumId: !_.isEmpty(album) ? album.id : null,
            // index:
            //   sectionIndex + Math.ceil((index + 1) / numberPhotoPerPage) - 1, // increase the index
            sectionId: `${orgSection.sectionId}_${
              !_.isEmpty(album) ? album.id : null
            }`,
            sectionName: !_.isEmpty(album)
              ? `${album.name} Photos`
              : "Other Photos",
            actions: orgSection.actions || []
          });
          tempList = [];
        } else {
          counter++;
        }
      }
      // );

      // Compile photos

      return sections;
    },

    initBlockData({ building, orgSection }) {
      const blockData = _.cloneDeep(orgSection.blockData);
      blockData.forEach(block => {
        block.contents.forEach(item => {
          switch (item.id) {
            case "substrate-repair":
              item.checked = building.workType !== "new";
              break;

            case "membrane-fully-adhered":
              item.checked =
                building.membraneAttached === "tpo-membrane-fully-adhered";

              break;
            case "membrane-mechanically-attached":
              item.checked =
                building.membraneAttached ===
                "tpo-membrane-mechanically-attached";
              break;

            case "membrane-rhino-bond":
              item.checked =
                building.membraneAttached === "tpo-membrane-rhino-bond";
              break;

            case "insulation-loose-lay":
              (building.insulationLayers || []).forEach(layer => {
                if (layer.attached.includes("loose-lay")) {
                  item.checked = true;
                }
              });
              break;
            case "insulation-fully-adhered":
              (building.insulationLayers || []).forEach(layer => {
                if (layer.attached.includes("fully-adhered")) {
                  item.checked = true;
                }
              });
              break;
            case "insulation-mechanically-attached":
              (building.insulationLayers || []).forEach(layer => {
                if (layer.attached.includes("mechanically-attached")) {
                  item.checked = true;
                }
              });
              break;

            case "warranty-labor-only":
              item.checked = building.warrantyType === "tpo-labor_only";
              break;
            case "warranty-material-only":
              item.checked = building.warrantyType === "tpo-material_only";
              break;
            case "warranty-5-ndl":
              item.checked = building.warrantyType === "tpo-ndl-5";
              break;
            case "warranty-10-ndl":
              item.checked = building.warrantyType === "tpo-ndl-10";
              break;
            case "warranty-15-ndl":
              item.checked = building.warrantyType === "tpo-ndl-15";
              break;
            case "warranty-20-ndl":
              item.checked = building.warrantyType === "tpo-ndl-20";
              break;
            case "warranty-25-ndl":
              item.checked = building.warrantyType === "tpo-ndl-25";
              break;
            case "warranty-30-ndl":
              item.checked = building.warrantyType === "tpo-ndl-30";
              break;

            default:
              item.checked = true;
              break;
          }
        });
      });

      return blockData;
    },

    compileBlockContent({ building, block }) {
      const parseInsulationLayerHtml = (item, layer) => {
        let content = item.value.replaceAll(
          `<span style="background-color: #f05034;">[Insulation Thickness]</span>`,
          this.insulationThickness(layer.thickness).displayName
        );
        content = content.replaceAll(
          `<span style="background-color: #f05034;">[Insulation Type]</span>`,
          this.insulationType(layer.insulationType).displayName
        );

        return `<span style="font-weight:500; font-size: 16px;">
                  ${content}
                </span>
                <br/>`;
      };

      const parseAssemblyHtml = (item, assembly) => {
        const measurement = assemblyTemplateCodeIsQuantityType.includes(
          assembly.assemblyItem
        )
          ? "EA"
          : "LF";
        let content = item.value.replaceAll(
          `<span style="background-color: #f05034;">[Quantity]</span>`,
          `${assembly.qty} ${measurement}`
        );
        content = content.replaceAll(
          `<span style="background-color: #f05034;">[Metal Item Name]</span>`,
          assembly.templateName
        );

        content = content.replaceAll(
          `<span style="background-color: #f05034;">[Gauge Size]</span>`,
          (this.gaBy(assembly.assemblyGA) || {}).displayName || ""
        );

        let splited = (assembly.colorAndManufacturer || "").split(",");
        let color = splited[0];

        content = content.replaceAll(
          `<span style="background-color: #f05034;">[With Color]</span>`,
          color ? `with ${color} color` : ""
        );

        return `<li>
                  <span style="font-weight:500; font-size: 16px;">
                    ${content.trim()}.
                  </span>
                  <br/>
                </li>`;
      };

      let result = "";

      for (const part of block.parts) {
        const itemsOfPart = block.contents.filter(
          item => item.partName === part
        );
        switch (part) {
          case "Insulation":
            for (const layer of building.insulationLayers || []) {
              for (const key of [
                "loose-lay",
                "fully-adhered",
                "mechanically-attached"
              ]) {
                if (layer.attached.includes(key)) {
                  const item =
                    itemsOfPart.find(item => item.id.includes(key)) || {};

                  if (item.checked) {
                    result += parseInsulationLayerHtml(item, layer);
                  }
                }
              }
            }
            break;

          case "Sheet Metal Items":
            for (const assembly of building.assemblyList || []) {
              const item =
                itemsOfPart.find(item => item.id === "metal-item") || {};

              if (item.checked) {
                result += parseAssemblyHtml(item, assembly);
              }
            }
            break;

          default:
            for (const item of itemsOfPart) {
              if (item.checked) {
                result += `<li>
                            <span style="font-weight:500; font-size: 16px;">
                              ${this.parseHtmlMetaContent(item.value)}
                            </span>
                            <br/>
                          </li>`;
              }
            }
            break;
        }
      }

      return result;
    },

    compileBuildingInfoByBlocks({
      blockData,
      products,
      value,
      building,
      project,
      estimate
    }) {
      this.initBuildingData({
        products,
        value,
        building,
        project,
        estimate
      });

      let result = "";
      blockData.forEach(block => {
        // console.log(block.name);
        const blockTitle = this.parseMetaContent(block.value);
        const htmlContent = this.compileBlockContent({ building, block });
        result += ` </br>
                      <div>
                        <span style="font-weight:600; font-size: 24px; color: #1A79A1;">
                            ${blockTitle}
                        </span>
                      </div>
                      <div style=" margin-top: 15px;">
                        <ul style="list-style-type:disc; font-size:20px;">
                          ${htmlContent}
                        </ul>
                      </div>
                    `;
      });

      return `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                  <!-- donot delete this div -->
                  <div>
                      <div style="text-align: justify; padding: 56px;">
                          ${result}
                      </div>
                  </div>
              </font>`;
    },

    async compileSectionData({
      orgSections,
      company,
      contact,
      property,
      project,
      estimate,
      buildingList,
      projectPhotoListByAlbum,
      album
    }) {
      await this.initKeysData({
        company,
        contact,
        property,
        project,
        estimate
      });
      let tempSections = [];
      const desSections = [];
      let temp = {};

      // orgSections.forEach(orgSection => {
      for (const orgSection of orgSections) {
        switch (orgSection.sectionId) {
          case "building-info":
            for (
              let buildingIndex = 0;
              buildingIndex < buildingList.length;
              buildingIndex++
            ) {
              desSections.push(
                this.compileBuildingInfoSection({
                  orgSection,
                  building: buildingList[buildingIndex],
                  project,
                  estimate
                })
              );
            }
            break;

          case "product-data":
            for (
              let buildingIndex = 0;
              buildingIndex < buildingList.length;
              buildingIndex++
            ) {
              desSections.push(
                ...this.compileProductTableSection(
                  orgSection,
                  buildingList[buildingIndex],
                  estimate,
                  project
                )
              );
            }
            break;

          case "project-photos":
            tempSections =
              this.compileProjectPhotoSection(
                orgSection,
                projectPhotoListByAlbum,
                album
              ) || [];
            if (tempSections.length > 0) {
              desSections.push(...tempSections);
            }
            break;

          case "appendix-assembly":
            tempSections =
              this.compileAssemblyPhotoSection(orgSection, buildingList) || [];
            if (tempSections.length > 0) {
              desSections.push(...tempSections);
            }
            break;

          case "financing-proposal":
            temp = await this.compileFinancingProposalSection(
              orgSection,
              buildingList,
              project,
              estimate
            );
            if (temp) {
              desSections.push(temp);
            }

            break;

          default:
            desSections.push(this.compileSection(orgSection));
        }
      }
      // });

      // console.log(desSections);

      let i = 0;
      desSections.forEach(section => {
        section.index = i++;
      });

      return desSections;
    }
  }
};
