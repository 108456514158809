<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      @click.prevent="checkbox ? selectCheckbox(!item.checked, item) : () => {}"
    >
      <td v-if="checkbox">
        <f7-checkbox :checked="item.checked"></f7-checkbox>
      </td>
      <td
        v-for="(props, index) in headers"
        :key="index"
        :class="`${textAlign(props.align)}`"
        :style="`${props.style}`"
      >
        <span v-if="props.format && props.format === 'currencyUSD'">{{
          item[props.value] | currencyUSD
        }}</span>
        <span v-else>{{ item[props.value] }}</span>
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  props: {
    checkbox: Boolean,
    headers: Array,
    items: Array
  },
  methods: {
    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : "text-align-left";
    },

    selectCheckbox(checked, item) {
      this.$emit("selectCheckbox", { type: "body", checked }, item);
    }
  }
};
</script>
