export const SET_LAST_ITEM_REF = "SET_LAST_ITEM_REF";
export const MERGE_DATA_BATCH = "MERGE_DATA_BATCH";
export const ALL_DATA_LOADED = "ALL_DATA_LOADED";
export const RESET_PAGE = "RESET_PAGE";
export const SHOW_GLOBAL_NOTIFY_POPUP = "SHOW_GLOBAL_NOTIFY_POPUP";
export const SET_USER_TOKEN_LIST = "SET_USER_TOKEN_LIST";
export const SET_NOTIFY_TEMPLATE_LIST = "SET_NOTIFY_TEMPLATE_LIST";

export const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const SET_PO_LIST = "SET_PO_LIST";
