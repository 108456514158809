<template>
  <div>
    <f7-list accordion-list>
      <f7-list-item accordion-item accordion-item-opened>
        <template #title>
          <div class="title">
            <f7-icon f7="square_list" color="primary"> </f7-icon>
            <div class="info-label">
              Table of Content
            </div>
          </div>
        </template>
        <f7-accordion-content>
          <f7-list class="margin-left-half">
            <f7-block class="scroll-container">
              <draggable
                :list="sortedSections"
                :animation="200"
                class="section-content-container"
                ghost-class="ghost"
                :delay="$device.desktop ? 0 : 100"
                :delayOnTouchOnly="$device.desktop ? true : false"
                :touchStartThreshold="20"
                group="sections"
                draggable=".section-item"
                :move="checkHeader"
                @change="$emit('onDndChange', $event)"
                tag="ul"
              >
                <f7-list-item
                  v-for="(section, index) in sortedSections"
                  :key="index"
                  :title="section.sectionName"
                  class="section-item"
                  link="#"
                  :text-color="
                    index === currentSectionIndex ? 'primary' : 'default'
                  "
                  @click="$emit('sectionClick', { section, index })"
                ></f7-list-item>
              </draggable>
            </f7-block>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item>
        <template #title>
          <div class="title">
            <f7-icon f7="text_badge_checkmark" color="primary"></f7-icon>
            <div class="info-label">Details</div>
          </div>
        </template>
        <f7-accordion-content>
          <f7-list inline-labels class="margin-left-half">
            <f7-list-input
              label="Proposal"
              :value="proposal.proposalNumber || ''"
              readonly
            ></f7-list-input>

            <f7-list-input
              label="Project"
              :value="(proposal.project && proposal.project.title) || ''"
              readonly
            ></f7-list-input>
            <f7-list-item title="Status"
              ><f7-chip
                :text="onlineClass(proposal.status).text"
                :color="onlineClass(proposal.status).color"
              ></f7-chip
            ></f7-list-item>

            <f7-list-input
              label="Property"
              :value="
                (proposal.property && proposal.property.propertyName) || ''
              "
              readonly
            ></f7-list-input>

            <f7-list-input
              label="Estimate"
              :value="
                (proposal.estimate &&
                  `${proposal.estimate.estimateNumber} - ${proposal.estimate.estimateName}`) ||
                  ''
              "
              readonly
            ></f7-list-input>

            <f7-list-input
              v-if="isServiceJob"
              label="Table Content Type"
              type="select"
              placeholder="Select Table Content Type"
              :value="proposal.tableContentType"
              @change="$emit('changeTableContentType', $event.target.value)"
            >
              <option
                v-for="c in TABLE_CONTENT_TYPES"
                :key="c.value"
                :value="c.value"
                >{{ c.name }}</option
              >
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item>
        <template #title>
          <div class="title">
            <f7-icon f7="clock" color="primary"></f7-icon>
            <div class="info-label">History</div>
          </div>
        </template>
        <f7-accordion-content>
          <activity-list></activity-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <!-- <div class="display-flex justify-content-flex-end">
      <f7-button
        outline
        class="margin-right-half"
        color="primary"
        @click="copyProposal"
        >Copy proposal</f7-button
      >
      <f7-button
        outline
        class="margin-right-half"
        color="primary"
        @click="toPreview"
        >Preview</f7-button
      >
    </div> -->

    <!-- tabs -->
    <!-- <f7-block v-if="false">
      <f7-segmented strong tag="p">
        <f7-button
          :active="activeTab === 'proposal-detail'"
          @click.native="activeTab = 'proposal-detail'"
          >Proposal</f7-button
        >
        <f7-button
          v-if="
            proposal &&
              proposal.status === 'draft' &&
              (isServiceJob || isResidentialJob)
          "
          :active="activeTab === 'financing-proposal'"
          @click.native="activeTab = 'financing-proposal'"
          >Financing Proposal</f7-button
        >
        <f7-button
          :active="activeTab === 'proposal-history'"
          @click.native="activeTab = 'proposal-history'"
          >History</f7-button
        >
      </f7-segmented>
    </f7-block> -->

    <!-- tabs content -->
    <!-- <div v-show="activeTab === 'proposal-detail'">
      <div
        v-if="proposal && proposal.status === 'draft'"
        class="page-content"
        ref="pageList"
      >
        <f7-card>
          <f7-card-header style="flex-direction: row-reverse;">
            <div class="">
              <f7-button fill color="primary" @click="copyProposal"
                >Copy proposal</f7-button
              >
              <f7-button fill color="primary" @click="toPreview"
                >Preview</f7-button
              >
            </div>
          </f7-card-header>
          <f7-card-content>
            <f7-row no-gap>
              <f7-col>
                <b class="info-label">Proposal:</b
                >{{ proposal.proposalNumber || "" }}
              </f7-col>
              <f7-col>
                <b class="info-label">Project:</b
                >{{ proposal.project && proposal.project.title }}
              </f7-col>
              <f7-col style="display: flex;">
                <b class="info-label">Status:</b>
                <f7-chip
                  :text="onlineClass(proposal.status).text"
                  :color="onlineClass(proposal.status).color"
                ></f7-chip>
              </f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col>
                <b class="info-label">Property:</b>
                {{ proposal.property && proposal.property.propertyName }}
              </f7-col>
              <f7-col
                ><b class="info-label">Estimate:</b
                >{{
                  proposal.estimate &&
                    `${proposal.estimate.estimateNumber} - ${proposal.estimate.estimateName}`
                }}</f7-col
              >
              <f7-col class="display-flex">
                <template v-if="isServiceJob">
                  <b class="info-label">Table Content Type:</b>
                  <f7-input
                    outline
                    placeholder="Select Table Content Type"
                    type="select"
                    :value="proposal.tableContentType"
                    @change="
                      $emit('changeTableContentType', $event.target.value)
                    "
                  >
                    <option
                      v-for="c in TABLE_CONTENT_TYPES"
                      :key="c.value"
                      :value="c.value"
                      >{{ c.name }}</option
                    >
                  </f7-input>
                </template>
              </f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </div>
      <div v-else class="page-content">
        <f7-card>
          <f7-card-header style="flex-direction: row-reverse;">
            <div class="btn-container">
              <f7-button fill color="primary" @click="copyProposal"
                >Copy proposal</f7-button
              >
              <f7-button fill color="primary" @click="toPreview"
                >Preview</f7-button
              >
            </div>
          </f7-card-header>
          <f7-card-content>
            <f7-row no-gap>
              <f7-col>
                <b class="info-label">Proposal:</b
                >{{ proposal.proposalNumber || "" }}
              </f7-col>
              <f7-col>
                <b class="info-label">Project:</b
                >{{ proposal.project && proposal.project.title }}
              </f7-col>
              <f7-col style="display: flex;">
                <b class="info-label">Status:</b>
                <f7-chip
                  :text="onlineClass(proposal.status).text"
                  :color="onlineClass(proposal.status).color"
                ></f7-chip>
              </f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col>
                <b class="info-label">Property:</b>
                {{ proposal.property && proposal.property.propertyName }}
              </f7-col>
              <f7-col
                ><b class="info-label">Estimate:</b
                >{{
                  proposal.estimate &&
                    `${proposal.estimate.estimateNumber} - ${proposal.estimate.estimateName}`
                }}</f7-col
              >
              <f7-col></f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </div>
    </div>
    <div v-if="activeTab === 'financing-proposal'">
      <financing-proposal-tab />
    </div>
    <div v-show="activeTab === 'proposal-history'">
      <activity-list></activity-list>
    </div> -->
  </div>
</template>

<script>
import ActivityList from "../list/ActivityList.vue";
import draggable from "vuedraggable";
// import FinancingProposalTab from "./tabs/FinancingProposalTab.vue";

import {
  TABLE_CONTENT_TYPES,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE
} from "@/utility/const";
import common from "../../utility/common";
import { mapActions, mapGetters } from "vuex";
// import _ from "lodash";

export default {
  components: {
    ActivityList,
    draggable
    // FinancingProposalTab
  },

  mixins: [common],

  props: {
    sortedSections: { type: Array, default: () => [] },
    currentSectionIndex: { type: Number, default: 0 },
    isEdited: Boolean
  },

  data: () => {
    return {
      TABLE_CONTENT_TYPES,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_SERVICE,
      activeTab: "",
      type: ""
    };
  },

  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal",
      proposalByNumber: "proposal/proposal/proposalByNumber",

      projectById: "proposal/project/projectById",
      estimateById: "proposal/estimate/estimateById"
    }),

    isServiceJob() {
      return (
        this.proposal.project &&
        this.proposal.project.businessCode === BUSINESS_CODE_SERVICE
      );
    },

    isResidentialJob() {
      return (
        this.proposal.project &&
        this.proposal.project.businessCode === BUSINESS_CODE_RESIDENTIAL
      );
    }
  },

  methods: {
    ...mapActions({
      bindProposal: "proposal/proposal/bindProposal",
      updateProposal: "proposal/proposal/updateProposal",
      deleteProposal: "proposal/proposal/deleteProposal",
      removeProposalPhotos: "proposal/proposal/removeProposalPhotos",
      setProposal: "proposal/proposal/setProposal",
      setProposalField: "proposal/proposal/setProposalField",
      getProposalBys: "proposal/proposal/getProposalBys",
      removeRedundantPhotos: "proposal/proposal/removeRedundantPhotos",
      bindHistoryListBy: "proposal/history/bindHistoryListBy",
      createHistory: "proposal/history/createHistory",
      unbindProposalTemplate: "proposal/template/unbindProposalTemplate",
      getPropertyById: "proposal/property/getPropertyById",
      getProjectById: "proposal/project/getProjectById",
      getCompanyById: "proposal/company/getCompanyById",
      getContactById: "proposal/contact/getContactById",
      getEstimateById: "proposal/estimate/getEstimateById",
      copyPhoto: "proposal/proposal/copyPhoto",
      getBuilding: "proposal/estimate/getBuilding",
      bindSubFinancingPropsalList:
        "proposal/proposal/bindSubFinancingPropsalList",
      createNewProposal: "proposal/proposal/createNewProposal"
    }),
    checkHeader(value) {
      if (
        value.draggedContext.element.index === 0 ||
        value.relatedContext.index === 0
      ) {
        return false;
      }
      return true;
    },
    onlineClass(status) {
      return status === "sent"
        ? { color: "green", text: "Sent" }
        : status === "withdraw"
        ? { color: "", text: "Withdrawn" }
        : status === "viewed"
        ? { color: "pink", text: "Viewed" }
        : status === "signed"
        ? { color: "blue", text: "Signed" }
        : { color: "yellow", text: "Draft" };
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
  padding: 0 8px 0 8px;
}
.btn-container {
  display: flex;
  flex-direction: row-reverse;
  // padding: 10px 14px;
  // margin-bottom: 10px;
}
.btn-container .button {
  margin: 0 5px;
  min-width: 120px;
}
.info-label {
  font-weight: 600;
  margin-left: 5px;
  margin-top: -2px;
}
.title {
  display: flex;
  row-gap: 15px;
}
.action-link:hover {
  color: var(--f7-theme-color);
}
.scroll-container {
  max-height: calc(100vh - 270px);
  overflow-x: auto;
  margin: 0px 0px 0px -15px;
  direction: ltr;
  scroll-behavior: smooth;
}
</style>
