<template>
  <f7-fab
    :class="`delete-tooltip-${uuid}`"
    position="right-bottom"
    slot="fixed"
    color="default"
    @click="handleDelete"
  >
    <f7-icon f7="trash"></f7-icon>
  </f7-fab>
</template>
<script>
import { mapActions } from "vuex";
import { uuid } from "vue-uuid";

export default {
  data: () => ({
    uuid: uuid.v4()
  }),
  props: {
    photosSelected: Array
  },
  mounted() {
    this.createTooltip();
  },
  methods: {
    ...mapActions("photo/photo", ["deleteProjectPhoto", "removePhoto"]),
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: `.delete-tooltip-${this.uuid}`,
        cssClass: "tooltip-fab-button",
        text: "Delete photo"
      });
    },
    handleDelete() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: `Delete 
            ${app.photosSelected.length === 1 ? "photo/video" : "photos/videos"}
            `,
        content: `Are you sure you want to delete ?`,
        // hideCancelButton: true,
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.photosSelected.forEach(i => {
              if (
                i.photoUrl.toLowerCase().includes(".mp4") ||
                i.photoUrl.toLowerCase().includes(".mov") ||
                i.photoUrl.toLowerCase().includes(".m4v") ||
                i.photoUrl.toLowerCase().includes(".webm")
              ) {
                if (i.thumbnailFullPath) app.removePhoto(i.thumbnailFullPath);
                if (i.photoFullPath) app.removePhoto(i.photoFullPath);
                app.deleteProjectPhoto(i);
              } else {
                app.deleteProjectPhoto(i);
              }
            });
            _sefl.app.dialog.close();
            app.$emit("cancelSelect");
          }
        }
      });
    }
  }
};
</script>
