<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupClosed"><f7-view>
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="popupOpened = false">Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Edit Company Information</f7-nav-title>
          <f7-nav-right><f7-link @click.stop="save">Save</f7-link></f7-nav-right>
        </f7-navbar>
        <f7-list class="justify-content-center align-items-center display-flex">
          <upload-logo
            :textUpload="isNew ? `Upload` : ``"
            :avatarSize="115"
            :imageUrl="setting.logoUrl"
            @uploadImage="uploadImage"
            :isEdit="true"
            :isEdited="true"
            ref="imageInput"
          />
        </f7-list>
        <f7-list inline-labels no-hairlines-md>
          <f7-list-input label="Company Name" type="text" placeholder="Company name" clear-button :value="companyName"
            @input="
              companyName = $event.target.value;
            isEdited = true;
            " error-message-force :error-message="companyNameErrorMessage" @blur="v$.companyName.$touch()">
          </f7-list-input>
          <f7-list-input label="Address" type="text" placeholder="Address" clear-button :value="address" @input="
            address = $event.target.value;
            isEdited = true;
          ">
          </f7-list-input>
          <f7-list-input label="Phone Number" type="text" placeholder="Phone Number" clear-button :value="phoneNumber"
            @input="
              phoneNumber = $event.target.value;
              isEdited = true;
            "
            error-message-force
            v-mask="'(+1) ###-###-####'"
            :error-message="newPhoneErrorMessage"
          >
          </f7-list-input>
          <f7-list-input label="Email" type="text" placeholder="company email" clear-button :value="companyEmail"
            @input="
              companyEmail = $event.target.value;
              isEdited = true;
            "
            error-message-force
            :error-message="emailErrorMessage"
             @blur="v$.companyEmail.$touch()"
          >
          </f7-list-input>
          <f7-list-input label="Tax Code" type="text" placeholder="tax code" clear-button :value="companyTaxCode"
            @input="
              companyTaxCode = $event.target.value;
              isEdited = true;
            "
            v-mask="'##-#######'"
            error-message-force
            :error-message="companyTaxCodeErrorMessage"
            @blur="v$.companyTaxCode.$touch()"
          >
          </f7-list-input>
          <f7-list-input label="Company Website" type="text" placeholder="Company Website" clear-button
            :value="companyWebsite" @input="
              companyWebsite = $event.target.value;
            isEdited = true;
            ">
          </f7-list-input>
          <f7-list-item ref="timeZonePopup" class="time-zone" :class="themeDark ? 'theme-dark' : 'theme-light'"
            title="Time Zone" smart-select :value="timezone" :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: 'Type to search',
              on: {
                close: selectionChange,
                opened: handlePopupOpened
              }
            }">
            <select name="timezone">
              <option v-for="item in sortedTimeZoneList" :key="item.id" :value="item.value"
                :selected="item.value == timezone">
                {{ item.displayName }}
              </option>
            </select>
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import { useVuelidate, } from '@vuelidate/core'
import { required, helpers, minLength, email } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import ImageTools from "@/utility/image-tools";
import UploadLogo from "../../../../components/uploads/UploadLogo.vue";
import { mask } from "vue-the-mask";

import _ from "lodash";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    UploadLogo
  },

  directives: { mask },

  data() {
    return {
      popupOpened: false,
      companyName: "",
      companyEmail: "",
      companyTaxCode: "",
      companyWebsite: "",
      phoneNumber: "",
      address: "",
      isEdited: false,
      timezone: "",
      isEdit: false,
      isNew: false
    };
  },

  watch: {
    setting: {
      immediate: true,
      handler(setting) {
        this.companyName = setting.companyName || "";
        this.companyWebsite = setting.companyWebsite || "";
        this.companyEmail = setting.companyEmail || "";
        this.companyTaxCode = setting.companyTaxCode || "";
        this.phoneNumber = setting.phoneNumber || "";
        this.address = setting.address || "";
        this.timezone = setting.timezone || "";
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    const validateEIN = helpers.withParams(
      { type: "validateEIN" },
      value => {
        if(!value) return true
        return  /^\d{2}-\d{7}$/.test(value)
      }
    );

    return {
      companyName: { required },
      companyTaxCode: {
        validateEIN
      },
      companyEmail: {
        commonEmailRule: email
      },
      phoneNumber: {
        minLength: minLength(17)
      }
    };
  },

  computed: {
    ...mapGetters("setting/app/system", ["setting"]),
    ...mapGetters("setting/profile-page/app-constant", ["timeZoneList"]),
    ...mapGetters('common/app-constant', ['tenantId']),

    themeDark() {
      return localStorage.getItem("themeDark") === "true";
    },

    companyNameErrorMessage() {
      if (!this.v$.companyName.$error) return null;
      if (this.v$.companyName.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    companyTaxCodeErrorMessage() {
      if (!this.v$.companyTaxCode.$error) return null;
      if (this.v$.companyTaxCode.validateEIN.$invalid) return VALIDATION_MESSAGE.INVALID_TAX_CODE;
      return null;
    },

    emailErrorMessage() {
      if (!this.v$.companyEmail.$error) return null;
      if (this.v$.companyEmail.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    newPhoneErrorMessage() {
      if (!this.v$.phoneNumber.$error) return null;
      if (this.v$.phoneNumber.minLength.$invalid)
        return VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT;
      if (this.v$.phoneNumber.commonPhoneRule.$invalid) return VALIDATION_MESSAGE.INVALID_PHONE_NUMBER;
      return null;
    },

    sortedTimeZoneList() {
      return (this.timeZoneList || [])
        .slice()
        .sort((a, b) => a.displayName - b.displayName);
    }
  },

  methods: {
    ...mapActions("setting/app/system", ["updateSetting"]),

    selectionChange(v) {
      const value = v.getValue();
      if (_.isEqual(value, this.timezone)) {
        return;
      }
      this.isEdited = true;
      this.timezone = value;
    },

    setValueDefault(setting) {
      this.companyName = setting.companyName || "";
      this.companyWebsite = setting.companyWebsite || "";
      this.companyEmail = setting.companyEmail || "";
      this.companyTaxCode = setting.companyTaxCode || "";
      this.phoneNumber = setting.phoneNumber || "";
      this.address = setting.address || "";
      this.timezone = setting.timezone || "";
    },

    save() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return;
      }

      this.$f7.preloader.show();
      this.updateSetting({
        id: this.tenantId,
        doc: {
          companyName: this.companyName,
          companyWebsite: this.companyWebsite,
          companyEmail: this.companyEmail,
          companyTaxCode: this.companyTaxCode,
          address: this.address,
          phoneNumber: this.phoneNumber,
          timezone: this.timezone
        }
      })
        .then(() => {
          this.popupOpened = false;
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.timeZonePopup.f7SmartSelect.searchbar.$el[0]
          .querySelector(".searchbar input")
          .focus();
      }
    },

    openWarningDialogUploadPhoto() {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Invalid file!",
        content: "Please upload again.",
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        }
      });
    },

    uploadImage() {
      const app = this;
      let files =
        app.$refs.imageInput.$el.firstChild.firstChild.lastChild.files;

      if (
        _.isEmpty(
          Array.from(files).filter(file => file.type.startsWith("image/"))
        )
      ) {
        this.openWarningDialogUploadPhoto();
        return;
      }
      this.isEdited = true;
      let imgTool = new ImageTools(this.$f7.device);
      const name = files[0].name;
      imgTool.resize(files[0], { width: 128, height: 128 }).then(file => {
        if (!file.name) file.name = name;
        const reader = new FileReader();
        reader.onload = () => {
          const dataImg = reader.result;
          this.updateSetting({
            id: this.tenantId,
            doc: {
              logoUrl: dataImg
            }
          });
        };
        reader.readAsDataURL(file);
      });
    },

    open(dataSetting) {
      this.setValueDefault(dataSetting);

      const timezoneText =
        this.sortedTimeZoneList.find(e => e.value === this.timezone)
          .displayName || "";

      const element = this.$refs.timeZonePopup.f7SmartSelect.$el[0].querySelector(
        ".item-title"
      );

      element.classList.add("item-label");

      this.$refs.timeZonePopup.f7SmartSelect.$el[0].querySelector(
        ".item-after"
      ).innerHTML = timezoneText;

      this.popupOpened = true;
    },

    popupClosed() {
      this.popupOpened = false;
      this.companyName= ""
      this.companyEmail= ""
      this.companyTaxCode= ""
      this.companyWebsite= ""
      this.phoneNumber= ""
      this.address= ""
      this.isEdited= false
      this.timezone= ""
      this.isEdit= false
      this.isNew= false
    }
  }
};
</script>

<style scoped>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile:focus+label {
  cursor: pointer;
  /* outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px; */
}

.inputfile+label * {
  pointer-events: none;
}

.bg-upload {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 25%;
  position: absolute;
  width: 70px;
  background: black;
  bottom: 0px;
  opacity: 0.5;
  margin-left: -5px;
  padding: 2px;
  z-index: 1;
}

.btn-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
}

.inputImage {
  border: 1px solid;
  border-color: #000;
  border-radius: 50%;
  padding: 17px 7px 17px 7px;
}

.image-logo ::v-deep input[type="file"] {
  /* margin-left: 10px; */
  border-radius: 50%;
  z-index: 0;
}

.image-upload>input {
  display: none;
}

.time-zone ::v-deep .item-after {
  display: flex;
  margin-left: 8px;
  flex: 1;
}

.theme-dark ::v-deep .item-after {
  color: #fff;
}

.theme-light ::v-deep .item-after {
  color: #000;
}
</style>
