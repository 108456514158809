/* eslint-disable no-unused-vars */
// import { quantityCalculator } from "../../utility/calculation";
// import { sortLatest } from "@/utility/date-time-tool";
import _ from "lodash";
import { groupBy } from "@/utility/filter-tools";
import { STATUS_ESTIMATE_ACTIVE } from "../../../../utility/const";

import estimateService from "../../../../services/estimate.service";

function sumArray(arr) {
  let res = 0;
  if (!_.isEmpty(arr)) {
    res = arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
  }
  return res;
}

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

function getPercentValue(percent, value) {
  return _.round(((percent || 0) * (value || 0)) / 100.0, 2);
}

export default {
  estimateList: state => state.estimateList,

  typeSwitchScreen: state => state.typeSwitchScreen,

  estimate: state => state.estimate,

  estimateByNumber: state => estimateNumber => {
    return (
      state.estimateList.find(r => r.estimateNumber === estimateNumber) || {}
    );
  },

  // estimateById: ({ estimateList }) => estimateId => {
  //   return estimateList.find(r => r.id === estimateId) || {};
  // },

  currentEstimate: ({ currentEstimate }) => currentEstimate,

  buildingList: state => state.buildingList,

  building: state => state.building || {},

  clientInfo: state => state.clientInfo || {},

  currentBuilding: state => state.currentBuilding || {},

  estimateHistoryList: (state, getters) => {
    const list = (state.estimateHistoryList || []).map(r => {
      const building =
        (getters.buildingList || []).find(
          building => building.id === r.buildingId
        ) || {};
      if (_.isEmpty(building)) return;
      const sectionIndex = (building.productData || []).findIndex(
        section => section.sectionId === r.sectionId
      );
      let productIndex = building.productData[
        sectionIndex
      ].productList.findIndex(product => product.id === r.productId);
      const product =
        building.productData[sectionIndex].productList[productIndex] || {};
      return {
        ...r,
        product
      };
    });
    return list;
  },

  estimateNumber: state => state.estimateNumber,

  currentStep: state => state.currentStep,

  // Summary Page
  totalSubtotal: (_, getters) => (estimate, building) => {
    return (
      getters.materialSubTotal(building) +
      getters.materialMisc(estimate, building) +
      getters.laborSubTotal(building)
    );
  },

  totalProfit: (_, getters) => (estimate, building) => {
    return (
      getters.materialProfit(estimate, building) +
      getters.laborProfit(estimate, building)
    );
  },

  totalTax: (_, getters) => (project, building, estimate) => {
    return (
      getters.materialTax(project, building, estimate) +
      getters.laborTax(project, building, estimate)
    );
  },

  totalCost: (_, getters) => (project, building, estimate) => {
    return (
      getters.totalSubtotal(estimate, building) +
      getters.totalProfit(estimate, building) +
      getters.totalTax(project, building, estimate)
    );
  },

  summaryEstimateByArea: (state, getters, __, rootGetters) => {
    let estimate = rootGetters["estimate/estimate-page/estimate/estimate"];
    let listRoofs = _.cloneDeep(
      rootGetters["common/app-constant/roofTypeList"]
    );
    listRoofs.push({
      id: "all-roof-type",
      code: "roof-type",
      value: "all-roof-type",
      displayName: "Multiple Roof Types"
    });

    //filter building list by buildingIds exist in estimate
    const buildingIds = (state.estimate.buildings || []).map(
      building => building.buildingId
    );
    const buildingList = state.buildingList.filter(r =>
      buildingIds.includes(r.id)
    );

    const roofTypes = buildingList.map(r => r.roofType);
    let headers =
      listRoofs.filter(r => roofTypes.some(i => i === r.value)) || [];

    let data = [];

    data = buildingList
      .map(building => {
        let object = { totals: 0 };
        for (let h of headers) {
          object[h.value] =
            building.roofType === h.value ? building.fieldArea || 0 : 0;
          object["totals"] += parseFloat(object[h.value]);
        }

        return {
          rooftype: building.roofType,
          name: building.buildingName,
          numberOfCopy: building.numberOfCopy,
          totalCost:
            getters.materialNetSales(estimate, building) +
            getters.laborNetSales(estimate, building),
          ...object
        };
      })
      .sort((a, b) => sortBy(a.name, b.name));

    let totalArea = { name: "Total of area (or LF)", totals: 0, isBold: true };
    for (let h of headers) {
      totalArea[h.value] = sumArray(data.map(r => r[h.value]));

      totalArea["numberOfCopy"] = sumArray(
        data.map(r => {
          return r.numberOfCopy;
        })
      );

      totalArea["totals"] += parseFloat(totalArea[h.value]);
    }
    data.push(totalArea);
    headers.unshift({
      displayName: "Buildings/Sections",
      value: "numberOfCopy"
    });

    headers.unshift({
      displayName: "",
      value: "name"
    });

    headers.push({
      displayName: "Total",
      value: "totals"
    });

    return {
      headers,
      data
    };
  },

  summaryEstimateByRoofType: (state, getters, __, rootGetters) => {
    let estimate = rootGetters["estimate/estimate-page/estimate/estimate"];
    let project = rootGetters["estimate/estimate-page/project/project"];
    let listRoofs = _.cloneDeep(
      rootGetters["common/app-constant/roofTypeList"]
    );
    listRoofs.push({
      id: "all-roof-type",
      code: "roof-type",
      value: "all-roof-type",
      displayName: "Multiple Roof Types"
    });

    //filter building list by buildingIds exist in estimate
    const buildingIds = (state.estimate.buildings || []).map(
      building => building.buildingId
    );
    const buildingList = state.buildingList.filter(r =>
      buildingIds.includes(r.id)
    );

    const roofTypes = buildingList.map(r => r.roofType);
    let headers =
      listRoofs.filter(r => roofTypes.some(i => i === r.value)) || [];
    let data = [];
    data = buildingList
      .map(building => {
        let object = { totals: 0 };
        for (let h of headers) {
          object[h.value] =
            building.roofType === h.value
              ? getters.totalSubtotal(estimate, building)
              : 0;
          object["totals"] += parseFloat(object[h.value]);
        }

        return {
          rooftype: building.roofType,
          name: building.buildingName,
          numberOfCopy: building.numberOfCopy,
          subTotal: getters.totalSubtotal(estimate, building),
          profitMargin: getters.totalProfit(estimate, building),
          taxAmount: getters.totalTax(project, building, estimate),
          totalCost: getters.totalCost(project, building, estimate),
          crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
          ...object
        };
      })
      .sort((a, b) => sortBy(a.name, b.name));

    let total = {
      name: "Total",
      totals: 0,
      profitMargin: 0,
      totalCost: 0,
      isBold: true,
      isCost: true
    };

    for (let h of headers) {
      total[h.value] = sumArray(
        data.map(r => {
          return r.rooftype === h.value ? r.subTotal : 0;
        })
      );

      total["numberOfCopy"] = sumArray(
        data.map(r => {
          return r.numberOfCopy;
        })
      );

      total["profitMargin"] = sumArray(
        data.map(r => {
          return r.profitMargin;
        })
      );
      total["taxAmount"] = sumArray(
        data.map(r => {
          return r.taxAmount;
        })
      );
      total["totalCost"] = sumArray(
        data.map(r => {
          return r.totalCost;
        })
      );
      total["totals"] = sumArray(
        data.map(r => {
          return r.totals;
        })
      );
    }

    data.push(total);

    headers.unshift({
      displayName: "Buildings/Sections",
      value: "numberOfCopy"
    });

    headers.unshift({
      displayName: "",
      value: "name"
    });
    headers.push({
      displayName: "Sub Total",
      value: "totals"
    });
    headers.push({
      displayName: "Profit Margin",
      value: "profitMargin"
    });
    headers.push({
      displayName: "Tax Amount",
      value: "taxAmount"
    });
    headers.push({
      displayName: "Total Cost",
      value: "totalCost"
    });

    return {
      headers,
      data
    };
  },

  summaryEstimateByCostType: (state, getters, __, rootGetters) => {
    let estimate = rootGetters["estimate/estimate-page/estimate/estimate"];
    let project = rootGetters["estimate/estimate-page/project/project"];
    let listRoofs = _.cloneDeep(
      rootGetters["common/app-constant/roofTypeList"]
    );
    listRoofs.push({
      id: "all-roof-type",
      code: "roof-type",
      value: "all-roof-type",
      displayName: "Multiple Roof Types"
    });

    //filter building list by buildingIds exist in estimate
    const buildingIds = (state.estimate.buildings || []).map(
      building => building.buildingId
    );
    const buildingList = state.buildingList.filter(r =>
      buildingIds.includes(r.id)
    );

    const roofTypes = buildingList.map(r => r.roofType);
    let headers =
      listRoofs.filter(r => roofTypes.some(i => i === r.value)) || [];

    let data = [];

    data = buildingList
      .map(building => {
        let object = { totals: 0 };
        for (let h of headers) {
          object[h.value + "-labor"] =
            building.roofType === h.value ? getters.laborSubTotal(building) : 0;
          object[h.value + "-material"] =
            building.roofType === h.value
              ? getters.materialSubTotal(building) +
                getters.materialMisc(estimate, building)
              : 0;
          object["totals"] += parseFloat(
            object[h.value + "-labor"] + object[h.value + "-material"]
          );
          object[h.value + "-labor-percent"] =
            building.roofType === h.value
              ? getters.totalSubtotal(estimate, building)
                ? (getters.laborSubTotal(building) /
                    getters.totalSubtotal(estimate, building)) *
                  100
                : 0
              : 0;
          object[h.value + "-material-percent"] =
            building.roofType === h.value
              ? getters.totalSubtotal(estimate, building)
                ? ((getters.materialSubTotal(building) +
                    getters.materialMisc(estimate, building)) /
                    getters.totalSubtotal(estimate, building)) *
                  100
                : 0
              : 0;
        }

        return {
          rooftype: building.roofType,
          name: building.buildingName,
          numberOfCopy: building.numberOfCopy,
          profitMargin: getters.totalProfit(estimate, building),
          taxAmount: getters.totalTax(project, building, estimate),
          totalCost: getters.totalCost(project, building, estimate),
          crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
          ...object
        };
      })
      .sort((a, b) => sortBy(a.name, b.name));

    let total = {
      name: "Total",
      totals: 0,
      profitMargin: 0,
      totalCost: 0,
      isBold: true,
      isCost: true
    };

    for (let h of headers) {
      total[h.value + "-labor"] = sumArray(
        data.map(r => {
          return r.rooftype === h.value ? r[h.value + "-labor"] : 0;
        })
      );
      total[h.value + "-material"] = sumArray(
        data.map(r => {
          return r.rooftype === h.value ? r[h.value + "-material"] : 0;
        })
      );

      total["numberOfCopy"] = sumArray(
        data.map(r => {
          return r.numberOfCopy;
        })
      );

      total["profitMargin"] = sumArray(
        data.map(r => {
          return r.profitMargin;
        })
      );
      total["taxAmount"] = sumArray(
        data.map(r => {
          return r.taxAmount;
        })
      );
      total["totalCost"] = sumArray(
        data.map(r => {
          return r.totalCost;
        })
      );
      total["totals"] = sumArray(
        data.map(r => {
          return r.totals;
        })
      );
    }

    data.push(total);

    //split colunm to labor and material
    let headersSplited = [];
    headers.forEach(h => {
      headersSplited.push({
        ...h,
        value: h.value + "-labor",
        displayName: h.displayName + " Labor"
      });
      headersSplited.push({
        ...h,
        value: h.value + "-material",
        displayName: h.displayName + " Material"
      });
    });

    headersSplited.unshift({
      displayName: "Buildings/Sections",
      value: "numberOfCopy"
    });

    headersSplited.unshift({
      displayName: "",
      value: "name"
    });
    headersSplited.push({
      displayName: "Sub Total",
      value: "totals"
    });
    headersSplited.push({
      displayName: "Profit Margin",
      value: "profitMargin"
    });
    headersSplited.push({
      displayName: "Tax Amount",
      value: "taxAmount"
    });
    headersSplited.push({
      displayName: "Total Cost",
      value: "totalCost"
    });

    return {
      headers: headersSplited,
      data
    };
  },
  // END Summary Page

  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,

  contactList: state => state.contactList,
  contactGroup: state => (searchValue = "") => {
    let contacts =
      searchValue != ""
        ? state.contactList.filter(contact => {
            let contactName = contact.contactName
              ? contact.contactName.toLowerCase()
              : "";

            return contactName.indexOf(searchValue.toLowerCase()) >= 0;
          }) || []
        : state.contactList;

    let dataGroup = groupBy(contacts, item =>
      ((item.contactName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.contactName, b.contactName))
    }));
  },
  companyList: state => state.companyList,
  companyGroup: state => (searchValue = "") => {
    let companies =
      searchValue != ""
        ? state.companyList.filter(company => {
            let companyName = company.companyName
              ? company.companyName.toLowerCase()
              : "";

            return companyName.indexOf(searchValue.toLowerCase()) >= 0;
          }) || []
        : state.companyList;

    let dataGroup = groupBy(companies, item =>
      ((item.companyName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.companyName, b.companyName))
    }));
  },
  // END Fulltext search

  //! Estimate TAX Calculation
  doesApplyTaxForMaterial: (state, getters, rootStates, rootGetters) => (
    project,
    building,
    estimate
  ) => {
    if (!project) {
      project = rootGetters["estimate/estimate-page/project/project"];
    }
    if (!building) {
      building = rootGetters["estimate/estimate-page/estimate/currentBuilding"];
    }
    if (!estimate) {
      estimate = state.estimate;
    }

    return estimateService.checkApplyTaxForMaterial({
      project,
      building,
      estimate
    });
  },

  //! Estimate TAX Calculation
  doesApplyTaxForLabor: (state, getters, rootStates, rootGetters) => (
    project,
    building,
    estimate
  ) => {
    if (!project) {
      project = rootGetters["estimate/estimate-page/project/project"];
    }
    if (!building) {
      building = rootGetters["estimate/estimate-page/estimate/currentBuilding"];
    }
    if (!estimate) {
      estimate = state.estimate;
    }

    return estimateService.checkApplyTaxForLabor({
      project,
      building,
      estimate
    });
  },

  orderQty: (state, getters, rootStates, rootGetters) => (
    product,
    building
  ) => {
    const roundUpNumber = numberOfFixedRounds => value => {
      value = Math.round(value * 1000) / 1000;
      const denominator = Math.pow(10, numberOfFixedRounds);
      const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
      return Math.ceil(temp) / denominator;
    };
    const productSize = (product.unitSize || 1) * (product.unitPack || 1);
    const numberOfCopy = building
      ? building.numberOfCopy
      : getters.building.numberOfCopy || 1;
    const actualQty = product.actualQty * numberOfCopy;
    const orderQty = actualQty / productSize;
    return roundUpNumber(0)(orderQty);
  },

  materialSubTotal: (state, getters, rootStates, rootGetters) => building => {
    if (!building) building = state.currentBuilding;
    let result = 0;
    (building.productData || []).forEach(section => {
      if (section.sectionId !== "labor") {
        result += (section.productList || []).reduce(
          (accumulator, item) =>
            (getters.orderQty(item, building) || 0) * (item.price || 0) +
            accumulator,
          0
        );
      }
    });

    return result;
  },

  materialMisc: (state, getters) => (estimate, building) => {
    if (!estimate) estimate = state.estimate;
    if (!building) building = state.currentBuilding;
    return getPercentValue(
      estimate.miscPercent,
      getters.materialNetSales(estimate, building)
    );
  },

  materialProfit: (state, getters) => (estimate, building) => {
    if (!estimate) estimate = state.estimate;
    if (!building) building = state.currentBuilding;
    return getPercentValue(
      estimate.crossProfitMarginRatio,
      getters.materialNetSales(estimate, building)
    );
  },

  materialTax: (state, getters, rootStates, rootGetters) => (
    project,
    building,
    estimate
  ) => {
    if (!project) {
      project = rootGetters["estimate/estimate-page/project/project"];
    }
    if (!building) {
      building = rootGetters["estimate/estimate-page/estimate/currentBuilding"];
    }
    if (!estimate) {
      estimate = state.estimate;
    }
    return getters.doesApplyTaxForMaterial(project, building, estimate)
      ? getPercentValue(
          building.saleTax,
          getters.materialNetSales(estimate, building)
        )
      : 0.0;
  },

  materialNetSales: (state, getters) => (estimate, building) => {
    if (!estimate) estimate = state.estimate;
    if (!building) building = state.currentBuilding;
    const materialSubTotal = getters.materialSubTotal(building) || 0;
    const crossProfitMarginRatio = estimate.crossProfitMarginRatio || 0;
    const miscPercent = estimate.miscPercent || 0;

    return (
      materialSubTotal /
        ((100 - (crossProfitMarginRatio + miscPercent)) / 100) || 0
    );
  },

  laborSubTotal: (state, getters) => building => {
    if (!building) building = state.currentBuilding;
    let subTotals = 0;
    (building.productData || []).forEach(section => {
      if (section.sectionId === "labor") {
        subTotals += (section.productList || []).reduce(
          (currentValue, item) =>
            (getters.orderQty(item, building) || 0) * (item.price || 0) +
            currentValue,
          0
        );
      }
    });
    return subTotals;
  },

  laborNetSales: (state, getters) => (estimate, building) => {
    if (!estimate) estimate = state.estimate;
    if (!building) building = state.currentBuilding;
    const laborSubTotal = getters.laborSubTotal(building) || 0;
    const crossProfitMarginRatio = estimate.crossProfitMarginRatio || 0;

    return laborSubTotal / ((100 - crossProfitMarginRatio) / 100) || 0;
  },

  laborProfit: (state, getters) => (estimate, building) => {
    if (!estimate) estimate = state.estimate;
    if (!building) building = state.currentBuilding;
    return getPercentValue(
      estimate.crossProfitMarginRatio,
      getters.laborNetSales(estimate, building)
    );
  },

  laborTax: (state, getters, rootStates, rootGetters) => (
    project,
    building,
    estimate
  ) => {
    if (!project) {
      project = rootGetters["estimate/estimate-page/project/project"];
    }
    if (!building) {
      building = rootGetters["estimate/estimate-page/estimate/currentBuilding"];
    }
    if (!estimate) {
      estimate = state.estimate;
    }

    return getters.doesApplyTaxForLabor(project, building, estimate)
      ? getPercentValue(
          building.saleTax,
          getters.laborNetSales(estimate, building)
        )
      : 0.0;
  },
  retailTotal: (state, getters) => {
    return _.round(
      getters.materialNetSales() +
        getters.laborNetSales() +
        getters.materialTax() +
        getters.laborTax(),
      2
    );
  },

  netSales: (state, getters) => {
    return _.round(getters.materialNetSales() + getters.laborNetSales(), 2);
  },
  // END Estimate Price Calculation,

  //
  estimateListByProject: state => state.estimateListByProject,
  paramsBack: state => state.paramsBack,
  queryBack: state => state.queryBack,
  estimateActiveListByProject: state =>
    state.estimateListByProject.filter(
      item => item.status === STATUS_ESTIMATE_ACTIVE
    ),
  selectedStatusFilter: state => state.selectedStatusFilter,

  isActiveSummary: state => state.isActiveSummary,
  queryFilters: (state, getters, rootState) => {
    let filters = [];

    if (state.clientInfo && state.clientInfo.clientType === "company") {
      filters.push(`companyId:${state.clientInfo.clientId}`);
    } else if (state.clientInfo && state.clientInfo.clientType === "contact") {
      filters.push(`contactId:${state.clientInfo.clientId}`);
    }
    if (state.selectedStatusFilter.length > 0) {
      filters.push(
        state.selectedStatusFilter.map(s => `status:${s}`).join(" OR ")
      );
    }
    return `${filters.join(" AND ")}`;
  }
  //
};
