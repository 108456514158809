<template>
  <f7-page class="contact-edit">
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="cancel">Cancel</f7-link>
      </f7-nav-left>
      <f7-nav-title>New Contact</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="create()">Done</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- Contact Name -->
    <f7-list>
      <f7-list-input class="first-input" type="text" label="First name" placeholder="First name" clear-button
        :value="displayContact.firstName" @input="setContactValue('firstName', $event.target.value.trim())"
        error-message-force :error-message="contactFirstNameErrorMessage" @blur="v$.displayContact.firstName.$touch()">
        <required-asterisk slot="label"></required-asterisk></f7-list-input>

      <f7-list-input type="text" label="Last name" placeholder="Last name" clear-button :value="displayContact.lastName"
        @input="setContactValue('lastName', $event.target.value.trim())" error-message-force
        :error-message="contactLastNameErrorMessage" @blur="v$.displayContact.lastName.$touch()">
        <required-asterisk slot="label"></required-asterisk>
      </f7-list-input>
      <f7-list-input type="text" placeholder="Job Position" clear-button :value="displayContact.jobPosition"
        @input="setContactValue('jobPosition', $event.target.value.trim())"></f7-list-input>
    </f7-list>

    <!-- Phone -->
    <phone-input :id="displayContact.id || 'new-contact'" ref="phoneInput" isRequired :isCompared="false"
      :value="displayContact.phones" :listData="contactList" @input="setContactValue('phones', $event)"></phone-input>
    <!-- Email -->
    <email-input :id="displayContact.id || 'new-contact'" ref="emailInput" :value="displayContact.emails"
      @input="setContactValue('emails', $event)"></email-input>

    <!-- Address -->
    <address-auto-complete-input :id="displayContact.id || 'new-contact'" ref="addressInput"
      :value="displayContact.addresses" @input="setContactValue('addresses', $event)"></address-auto-complete-input>

    <!-- Company -->
    <f7-list media-list>
      <f7-list-item :id="companyRef.id" v-for="companyRef in companyRefs" :key="companyRef.id" swipeout
        :title="companyRef.companyName" :subtitle="companyRef.addresses && companyRef.addresses.length > 0
          ? companyRef.addresses[0].value
          : ''
          " @swipeout:deleted="handleRemoveCompany(companyRef.id)">
        <f7-icon color="red" f7="minus_circle_fill" slot="media" @click.native="onDelete(companyRef.id)"></f7-icon>
        <f7-swipeout-actions right>
          <f7-swipeout-button delete confirm-title="Remove Company"
            confirm-text="Are you sure you want to remove this company?">Delete</f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>

      <f7-list-item class="cursor-pointer" @click.native="selectCompany">
        <f7-icon class="add-button-icon" f7="plus_circle" slot="media"></f7-icon>
        <div slot="title" style="font-weight: 400">add company</div>
      </f7-list-item>
    </f7-list>

    <company-list-popup ref="selectCompany" :isHiddenAddNew="false" @onSelected="handleCompanySelected"
      @onCreated="handleCompanySelected" :isShowSelectAll="true" @onRemoved="handleRemoveCompany"
      :items="companyRefs"></company-list-popup>
  </f7-page>
</template>

<script>
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import EmailInput from "@/components/inputs/EmailInput.vue";
import AddressAutoCompleteInput from "@/components/inputs/AddressAutoCompleteInput.vue";
import CompanyListPopup from "@/components/popups/CompanyListPopup.vue";
// import { HISTORY_TYPE } from "@/utility/const";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Vue from "vue";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    PhoneInput,
    EmailInput,
    AddressAutoCompleteInput,
    CompanyListPopup
  },

  data: () => {
    return {
      oldContactId: "",
      displayContact: {},

      deleleOnCancel: false
    };
  },

  async created() {
    if (this.contact) {
      this.oldContactId = this.contact.id;
    }
    this.clearCompanyRefs();
    await this.getContactListBys();
    if (!_.isEmpty(this.contactSearchText)) {
      let firstName = this.contactSearchText.split(" ")[0];
      let lastName = this.contactSearchText
        .substring(firstName.length, this.contactSearchText.length)
        .trim();
      this.displayContact = {
        firstName: firstName,
        lastName: lastName
      };
    }
  },

  computed: {
    ...mapGetters("common/contact", ["contactSearchText"]),

    ...mapGetters("contact-book/contact", [
      "contact",
      "contactList",
      "companyRefs"
    ]),

    contactFirstNameErrorMessage() {
      if (!this.v$.displayContact.firstName.$error) return null;
      if (this.v$.displayContact.firstName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    contactLastNameErrorMessage() {
      if (!this.v$.displayContact.lastName.$error) return null;
      if (this.v$.displayContact.lastName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    ...mapActions("contact-book/contact", [
      "createContact",
      "updateContact",
      "deleteContact",
      "clearContact",
      "getContactListBys",

      "clearCompanyRefs",
      "getContactById",
      "addCompanyRefs",
      "removeCompanyRefs",
      "updateCompanyRefs"
    ]),

    ...mapActions("contact-book/company", ["getCompany"]),

    setContactValue(key, value) {
      Vue.set(this.displayContact, key, value);
    },

    navigateToDetails({ contactId, reloadAll }) {
      this.$f7router.navigate(`/contactbook/contact/${contactId}`, {
        reloadAll
      });
    },

    async create() {
      if (!this.validateData()) return;
      this.$f7.preloader.show();
      if (this.displayContact.id) {
        await this.updateContact({
          id: this.displayContact.id,
          doc: this.displayContact
        });

        await this.updateCompanyRefs();
        this.navigateToDetails({
          contactId: this.displayContact.id,
          reloadAll: true
        });
      } else {
        const newContactId = await this.createContact({
          ...this.displayContact,
          contactName: `${this.displayContact.firstName} ${this.displayContact.lastName}`
        });

        this.navigateToDetails({ contactId: newContactId, reloadAll: true });
      }

      this.$f7.preloader.hide();
    },

    async cancel() {
      this.$f7.preloader.show();
      this.clearContact();
      if (this.deleleOnCancel) {
        await this.deleteContact(this.displayContact.id);
        await this.getContactById(this.oldContactId);
      }

      var historyLength = this.$f7router.history.length;
      var previousPage = this.$f7router.history[historyLength - 2];

      if (previousPage.includes("/contactbook")) {
        this.$f7router.navigate(previousPage, {
          reloadCurrent: true
        });
      } else {
        this.$f7router.navigate("/contactbook/contacts", {
          reloadCurrent: true
        });
      }

      this.$f7.preloader.hide();
    },

    validateData() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.emailInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.emailInput.v$.$invalid ||
        this.v$.$invalid
      ) {
        return false;
      }

      return true;
    },

    onDelete(id) {
      this.$f7.swipeout.open(`#${id}`, "right", () => { });
    },

    selectCompany() {
      if (!this.validateData()) return;

      const sefl = this;

      if (_.isEmpty(this.displayContact.id)) {
        this.$ri.dialog.openInfoDialog({
          title: "Does not exist contact",
          content:
            "Saving the contact before adding a new company. Keep save contact and continue to add new company?",
          textButton: "Create contact",

          onClick: async (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              sefl.$f7.preloader.show();

              const newContactId = await sefl.createContact({
                ...sefl.displayContact,
                contactName: `${sefl.displayContact.firstName} ${sefl.displayContact.lastName}`
              });

              const newContactDoc = await sefl.getContactById(newContactId);
              sefl.displayContact = _.cloneDeep(newContactDoc);
              sefl.deleleOnCancel = true;

              sefl.$f7.preloader.hide();
              sefl.$refs.selectCompany.open();
            }
          }
        });
      } else {
        sefl.$refs.selectCompany.open();
      }
    },

    async handleCompanySelected(companyId) {
      const company = await this.getCompany(companyId);
      if (!_.isEmpty(company)) {
        await this.addCompanyRefs(company);
      }
    },

    async handleRemoveCompany(companyId) {
      await this.removeCompanyRefs(companyId);
    }
  },

  mounted() {
    if (this.isNew && this.$device.desktop) {
      this.$el.querySelector(".first-input input").focus();
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      displayContact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        // addresses: {
        //   $each: {
        //     address: {
        //       required
        //     },
        //     city: {
        //       required
        //     },
        //     state: {
        //       required
        //     },
        //     zipcode: {
        //       required
        //     },
        //     country: {
        //       required
        //     }
        //   }
        // }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.contact-input {
  &::v-deep .item-label {
    width: 30%;
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.contact-edit .item-title.item-label a {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>
