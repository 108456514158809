import axiosService from "./axios.service";
import "firebase/auth";
import { firebase } from "./firebase.service";

const auth = firebase.auth();

const addInvoiceDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : "";
  data.id = "";
  data.isDeleted = false;
  return axiosService.post("/invoice/add", { tenantId, data }).then(res => {
    return res.data;
  });
};

const addInvoiceGroupDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : "";
  data.id = "";
  data.isDeleted = false;
  return axiosService
    .post("/invoice/add-group", { tenantId, data })
    .then(res => {
      return res.data;
    });
};

const addRecurringInvoiceDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : "";
  data.id = "";
  data.isDeleted = false;
  return axiosService
    .post("/recurring-invoice/add", { tenantId, data })
    .then(res => {
      return res.data;
    });
};

// const generateInvoiceFromRecurring = tenantId => {
//   return axiosService.post("/invoice/generate-invoice-from-recurring", {
//     tenantId
//   });
// };

export default {
  addInvoiceDoc,
  addInvoiceGroupDoc,
  addRecurringInvoiceDoc
  // generateInvoiceFromRecurring
};
