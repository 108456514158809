import * as types from "./types";
import Vue from "vue";

export default {
  setDetailId(state, detailId) {
    state.detailId = detailId;
  },
  [types.SET_BOARD_LIST](state, data) {
    Vue.set(state, "objectList", data);
  }
};
