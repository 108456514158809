import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Contact Book",
    name: "client-main",
    displayRoles: ["owner", "user"],
    routes: [
      // Contact details for desktop
      {
        path: "/contact/new-contact",
        meta: { isNew: true },
        async: checkAuth(() => import("../pages/contact/ContactEditPage.vue"))
      },
      {
        path: "/contact/:contactId",
        meta: { selector: false },
        async: checkAuth(() => {
          return import("../pages/contact/ContactDetailsPage.vue");
        })
      },

      {
        path: "/contact/edit/:contactId",
        meta: { isNew: false },
        async: checkAuth(() => import("../pages/contact/ContactEditPage.vue"))
      },

      // Company details for desktop
      {
        path: "/company/new-company",
        meta: { isNew: true },
        async: checkAuth(() => import("../pages/company/CompanyEditPage.vue"))
      },
      {
        path: "/company/:companyId",
        meta: { selector: false },
        async: checkAuth(() => {
          return import("../pages/company/CompanyDetailsPage.vue");
        })
      },
      {
        path: "/company/edit/:companyId",
        meta: { isNew: false },
        async: checkAuth(() => import("../pages/company/CompanyEditPage.vue"))
      },

      // Contact
      {
        path: "/contactbook/contacts",
        title: "Contacts",
        displayRoles: ["owner", "user"],
        parent: "client-main",
        name: "client-contact",
        icon: "person_crop_square",
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          // if (localStorage.getItem("desktop") === "true") {
          //   routeTo.route.master = false;
          //   return import("../pages/contact/ContactTablePage.vue");
          // }
          return import("../pages/contact/ContactListPage.vue");
        }),
        // component: ContactListPage,
        meta: { selector: false, selectFor: "contact" },
        // beforeEnter: checkAuth,
        options: {
          transition: ""
        },
        master: true,
        detailRoutes: [
          {
            path: "/contactbook/contacts/gmail-login",
            // title: "New Contact",
            // component: ContactEditPage,
            // beforeEnter: checkAuth,
            meta: {},
            async: checkAuth(() => import("../pages/contact/GmailLogin.vue"))
          },
          {
            path: "/contactbook/contact/new-contact",
            // title: "New Contact",
            // component: ContactEditPage,
            // beforeEnter: checkAuth,
            meta: { isNew: true },
            async: checkAuth(() =>
              import("../pages/contact/ContactNewPage.vue")
            )
          },

          {
            path: "/contactbook/contact/new-company",
            // title: "New Company",
            // component: CompanyEditPage,
            // beforeEnter: checkAuth,
            meta: { isNew: true, createFor: "contact" },
            async: checkAuth(() =>
              import("../pages/company/BranchEditPage.vue")
            )
          },
          {
            path: "/contactbook/contact/:contactId",
            // title: "Contact Details",
            // component: ContactDetailsPage,
            meta: { selector: false },
            async: checkAuth(() =>
              import("../pages/contact/ContactDetailsPage.vue")
            )
            // beforeEnter: checkAuth
          },
          {
            path: "/contactbook/contact/:contactId/history/:historyId",
            meta: { selector: false },
            async: checkAuth(() => {
              return import("../pages/contact/ContactHistoryDetailPage.vue");
            })
          },
          {
            path: "/contactbook/contact/edit/:contactId",
            // title: "Contact Edit",
            // component: ContactEditPage,
            meta: { isNew: false },
            async: checkAuth(() =>
              import("../pages/contact/ContactEditPage.vue")
            )
            // beforeEnter: checkAuth
          }
        ]
      },
      // Company
      {
        path: "/contactbook/companies",
        title: "Companies",
        name: "client-company",
        parent: "client-main",
        displayRoles: ["owner", "user"],
        icon: "globe",
        // async: checkAuth(() => import("../pages/company/CompanyListPage.vue")),
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          return import("../pages/company/CompanyListPage.vue");
        }),
        // component: CompanyListPage,
        meta: { selector: false, selectFor: "company" },
        // beforeEnter: checkAuth,
        options: {
          transition: ""
        },
        master: true,
        detailRoutes: [
          {
            path: "/contactbook/company/new-company",
            // title: "New Company",
            // component: CompanyEditPage,
            // beforeEnter: checkAuth,
            meta: { isNew: true },
            async: checkAuth(() =>
              import("../pages/company/CompanyNewPage.vue")
            )
          },
          {
            path: "/contactbook/company/new-branch",
            title: "New Company",
            // component: BranchEditPage,
            // beforeEnter: checkAuth,
            meta: { isNew: true },
            async: checkAuth(() =>
              import("../pages/company/BranchEditPage.vue")
            )
          },

          {
            path: "/contactbook/property/new-contact",
            // title: "New Contact",
            // component: ContactEditPage,
            // beforeEnter: checkAuth,
            meta: { isNew: true, disableRelationship: true },
            async: checkAuth(() =>
              import("../pages/contact/ContactEditPage.vue")
            )
          },
          {
            path: "/contactbook/property/:propertyId",
            // title: "Company Details",
            // component: CompanyDetailsPage,
            meta: { selector: false },
            async: checkAuth(() =>
              import("../pages/property/PropertyDetailsPage.vue")
            )
            // beforeEnter: checkAuth
          },
          {
            path: "/contactbook/company/:companyId/property/:propertyId",
            // title: "Company Details",
            // component: CompanyDetailsPage,
            meta: { selector: false },
            async: checkAuth(() =>
              import("../pages/property/PropertyDetailsPage.vue")
            )
            // beforeEnter: checkAuth
          },

          {
            path: "/contactbook/company/new-contact",
            // title: "New Contact",
            // component: ContactEditPage,
            // beforeEnter: checkAuth,
            meta: { isNew: true, disableRelationship: true },
            async: checkAuth(() =>
              import("../pages/contact/ContactEditPage.vue")
            )
          },
          {
            path: "/contactbook/company/:companyId",
            // title: "Company Details",
            // component: CompanyDetailsPage,
            meta: { selector: false },
            async: checkAuth(() =>
              import("../pages/company/CompanyDetailsPage.vue")
            )
            // beforeEnter: checkAuth
          },
          {
            path: "/contactbook/company/edit/:companyId",
            // title: "Company Edit",
            // component: CompanyEditPage,
            meta: { isNew: false },
            async: checkAuth(() =>
              import("../pages/company/CompanyEditPage.vue")
            )
            // beforeEnter: checkAuth
          }
        ]
      }
    ]
  }
];
