import * as types from "./types";
import Vue from "vue";
import _ from "lodash";

export default {
  [types.PROJECT_PHOTO](state, payload) {
    Vue.set(state, "projectPhoto", payload);
  },

  [types.PROJECT_PHOTOS_BY_ALBUM](state, payload) {
    Vue.set(state, "projectPhotosByAlbum", payload);
  },
  [types.PROJECT_PHOTO_LIST_ITEM_UPDATE](state, { id, doc }) {
    // update item of projectPhotosByAlbum
    const indexProjectPhotosByAlbum = state.projectPhotosByAlbum.findIndex(
      item => item.id === id
    );
    if (indexProjectPhotosByAlbum >= 0) {
      let photo = _.cloneDeep(
        state.projectPhotosByAlbum[indexProjectPhotosByAlbum]
      );
      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          photo[key] = doc[key];
        }
      }
      Vue.set(state.projectPhotosByAlbum, indexProjectPhotosByAlbum, photo);
    }
    // update projectPhoto
    if (state.projectPhoto.id === id) {
      Vue.set(state, "projectPhoto", {
        ...state.projectPhoto,
        ...doc
      });
    }
  },

  [types.PROJECT_PHOTO_LIST_ITEM_ADD](state, data) {
    state.projectPhotosByAlbum.unshift(data);
  },

  [types.PROJECT_PHOTO_LIST_ITEM_REMOVE](state, id) {
    const indexProjectPhotosByAlbum = state.projectPhotosByAlbum.findIndex(
      item => item.id === id
    );
    if (indexProjectPhotosByAlbum >= 0) {
      state.projectPhotosByAlbum.splice(indexProjectPhotosByAlbum, 1);
    }
  },

  [types.RESET_SELECT_STATE](state) {
    Vue.set(state, "selectionMode", false);
    Vue.set(state, "selectedPhotoIds", []);
  },
  [types.CHANGE_SELECT_MODE](state, isSelect) {
    Vue.set(state, "selectionMode", isSelect);
  },
  [types.UPDATE_SELECTED_IDS](state, id) {
    let selectedPhotoIds = state.selectedPhotoIds;
    if (selectedPhotoIds.includes(id)) {
      var index = selectedPhotoIds.indexOf(id);
      if (index > -1) {
        selectedPhotoIds.splice(index, 1);
      }
    } else {
      selectedPhotoIds.push(id);
    }
    Vue.set(state, "selectedPhotoIds", _.cloneDeep(selectedPhotoIds));
  },

  [types.RESET_DATA](state) {
    Vue.set(state, "projectPhoto", {});
    Vue.set(state, "projectPhotosByAlbum", []);
    Vue.set(state, "selectedPhotoIds", []);
    Vue.set(state, "selectionMode", false);
  }
};
