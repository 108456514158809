import Vue from "vue";
import * as types from "./types";

export default {
  setCertificateList(state, payload) {
    Vue.set(state, "certificateList", payload);
  },
  setCertificateSubscribe(state, payload) {
    Vue.set(state, "certificateSubscribe", payload);
  },
  [types.SET_CERTIFICATE_LIST](state, payload) {
    Vue.set(state, "certificateList", payload);
  }
};
