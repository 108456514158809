import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Reports",
    displayRoles: ["owner", "user"],
    name: "report-main",
    routes: [
      {
        path: "/report/labor-cost-analysis",
        title: "Labor Cost Analysis",
        name: "labor-cost-analysis",
        parent: "report-main",
        displayRoles: ["owner", "user"],
        icon: "calendar_today",
        async: checkAuth(() => import("../pages/LaborCostAnalysis.vue"))
      },
      {
        path: "/report/labor-cost-by-project",
        title: "Labor Cost By Project",
        name: "labor-cost-by-project",
        parent: "report-main",
        displayRoles: ["owner", "user"],
        icon: "money_dollar_circle",
        async: checkAuth(() => import("../pages/LaborCostByProject.vue"))
      },
      {
        path: "/report/expenses-by-project",
        title: "Expenses By Project",
        name: "expenses-by-project",
        parent: "report-main",
        displayRoles: ["owner", "user"],
        icon: "creditcard",
        async: checkAuth(() => import("../pages/ExpensesByProject.vue"))
      }
    ]
  }
];
