import * as types from "./types";
import { FirebaseActions } from "../../services/firebase.service";
import * as constant from "../../utility/const";
import { searchClient } from "../../services/search.service";
import _ from "lodash";
import algoliaService from "../../services/algolia.service";

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "company"
  );

  const companyTypeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    "companyType"
  );

  const companyIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );
  return {
    getCompanyListBys: companyActions.getDocumentBys,
    getCompany: companyActions.getDocument,
    addNewCompany: companyActions.createDocument,
    saveCompany: companyActions.updateDocument,

    getCompanyTypeListBys: companyTypeAction.getDocumentBys,

    setCompanyTypeList({ commit }, list) {
      commit(types.SET_COMPANY_TYPE_LIST, list);
    },
    setQueryFilters({ commit }, payload) {
      commit(types.SET_QUERY_FILTER, payload);
    },
    getCompanyTypeList({ dispatch }, companyTypes) {
      let conditions = [
        {
          prop: "code",
          op: "==",
          val: constant.CODE_COMPANY_TYPE
        }
      ];
      if (!_.isEmpty(companyTypes)) {
        conditions.push({
          prop: "value",
          op: "in",
          val: companyTypes
        });
      }
      return dispatch("getCompanyTypeListBys", conditions).then(res => {
        return dispatch("setCompanyTypeList", res);
      });
    },

    // get company email list
    getCompanyEmails() {
      let hits = [];
      return companyIndexAsc
        .browseObjects({
          query: "",
          filters: `entityName:${constant.COLLECTION_OPRATION_COMPANY} AND NOT emailValues:?`,
          attributesToRetrieve: ["emailValues"],
          batch: batch => {
            hits = hits.concat(batch);
          }
        })
        .then(() => {
          hits = hits.reduce((old, current) => {
            const currentMapped = (
              (Array.isArray(current.emailValues) && current.emailValues) ||
              []
            ).map(r => r);
            return old.concat(currentMapped);
          }, []);
          hits = _.uniq(hits);
          return hits;
        });
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchCompany({ state, commit, getters }, { attributesToRetrieve }) {
      const index = companyIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_COMPANY}`,
        attributesToRetrieve:
          constant.OPERATION_COMPANY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: ["companyName", "phoneValues"]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.companySearchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreCompany(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index = companyIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_COMPANY}`,
        attributesToRetrieve:
          constant.OPERATION_COMPANY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: ["companyName"]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.companySearchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setCompanyNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setCompanySearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    setCompanySearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    createNewCompany({ dispatch, commit }, company) {
      return dispatch("addNewCompany", company).then(id => {
        commit(types.COMPANY_LIST_ITEM_ADD, {
          ...company,
          id
        });

        return id;
      });
    },

    updateCompany({ dispatch, commit }, { id, doc }) {
      return dispatch("saveCompany", { id, doc }).then(() => {
        return commit(types.COMPANY_LIST_ITEM_UPDATE, {
          id,
          doc
        });
      });
    },

    deleteCompany({ dispatch, commit }, id) {
      return dispatch("saveCompany", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      }).then(() => {
        return commit(types.COMPANY_LIST_ITEM_REMOVE, id);
      });
    },

    async createCompany({ dispatch }, company) {
      const id = await dispatch("addNewCompany", company);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_company",
        id
      });
      return id;
    }
  };
};
