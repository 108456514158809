import * as types from "./types";
import Vue from "vue";

export default {
  setDetailId(state, id) {
    state.detailId = id;
  },

  [types.SET_CATEGORY_LIST](state, data) {
    Vue.set(state, "objectList", data);
  }
};
