<template>
  <div class="email-input">
    <div class="email-input-icon">
      <slot name="icon">
        <f7-icon f7="envelope"></f7-icon>
      </slot>
    </div>
    <div class="email-input-content">
      <div class="email-input-lable">
        {{ this.title }}
      </div>
      <div class="email-input-wrap">
        <multiselect
          :placeholder="placeholder"
          @input="change"
          v-model="emailValue"
          :multiple="true"
          :options="options"
          :taggable="true"
          :limit="3"
          :limit-text="limitText"
          tag-placeholder="Press enter to select"
          @tag="addTag"
        >
        </multiselect>
        <span class="input-clear-button"></span>
      </div>
      <div class="email-error-message">
        <slot name="error-message"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  props: {
    value: { type: String, default: () => "" },
    title: { type: String, default: () => "" },
    placeholder: { type: String, default: () => "" },
    options: { type: Array, default: () => [] }
  },
  components: {
    Multiselect
  },
  data: () => {
    return {
      emailValue: []
    };
  },
  watch: {
    value(val) {
      this.emailValue = val.length > 0 ? val.split(",") : [];
    }
  },

  methods: {
    limitText(count) {
      return `and ${count} other ${count > 1 ? "emails" : "email"}`;
    },
    addTag(newTag) {
      this.options && this.options.push(newTag);
      this.emailValue && this.emailValue.push(newTag);
      this.$emit("change", this.emailValue.join(","));
    },
    change(value) {
      this.$emit("change", value.join(","));
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style language="scss">
.email-input {
  display: flex;
  padding: 2px;
  border-bottom: 1px solid var(--f7-list-border-color);
}
.email-input .email-input-icon {
  padding: 0.25rem;
  width: 2rem;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  color: var(--f7-theme-color);
}
.email-input .email-input-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.email-input.subject .email-input-content {
  padding-right: 14px;
  box-sizing: border-box;
}
.email-input.subject .email-input-content input {
  padding-top: 8px;
  padding-bottom: 8px;
}
.email-input-content .email-input-lable {
  font-size: 12px;
}
.email-input-content .email-input-wrap {
  width: 100%;
  position: relative;
}
.email-input-content .email-input-wrap input {
  width: 100%;
  border: none;
}
.email-input-content .email-error-message {
  color: red;
  font-size: 12px;
}
.email-input-wrap .multiselect .multiselect__tags {
  border: none;
  background-color: var(--f7-color-bg-10-neutral);
  padding-left: 0px;
}
.email-input-wrap
  .multiselect
  .multiselect__tags
  .multiselect__content-wrapper {
  width: 100%;
}

.email-input-wrap .multiselect .multiselect__content-wrapper {
  color: var(--f7-color-text-6-neutral);
}

.email-input-wrap .multiselect .multiselect__placeholder {
  /* background-color: gray; */
  color: gray;
  line-height: 1.4;
}
/* .multiselect__option {
  padding: 12px;
} */
</style>
