<template>
  <div>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        label="Punchlist Completed Date"
        placeholder="Enter Punchlist Completed Date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="calendar_today"></input-icon>
      </f7-list-input>

      <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy'
        }"
        label="Re-Schedule Warranty Date"
        placeholder="Enter Re-Schedule Warranty Date"
        :value="scheduleWarrantyDate"
        @calendar:change="
          scheduleWarrantyDate = $event;
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="calendar"></input-icon>
      </f7-list-input>
    </f7-list>

    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :is-save-button="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Punchlist Note',
          note,
          callback: () => {
            note = '';
          }
        })
      "
    ></note-editor>

    <date-popup
      ref="dueDatePopup"
      title="Enter Due Date"
      label="Due Date"
      v-model="newDueDate"
      @done="resolveNewDueDate"
      validate
      not-allow-past
    ></date-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import moment from "moment";
import NoteEditor from "../note/NoteEditor.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";
import { mapState, mapActions } from "vuex";

import _ from "lodash";
import methodsMixins from "../../mixin/methods";

export default {
  mixins: [methodsMixins],
  components: { UserInput, AttachmentInput, DatePopup, InputIcon, NoteEditor },

  data: () => {
    return {
      note: "",
      currentResponse: {},

      displayCard: {},
      dueDate: [],

      scheduleWarrantyDate: [],
      newDueDate: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"])
  },
  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.displayCard.retainage = this.displayCard.retainage || false;

        this.scheduleWarrantyDate = toDateCalendar(
          this.displayCard.scheduleWarrantyDate || []
        );
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "inspection-passed":
          this.currentResponse = response;
          this.$ri.dialog.openInfoDialog({
            title: "Warranty Inspection",
            content: "Are you sure to move the project to the Closeout folder?",
            // textButton: "Delete",
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$refs.dueDatePopup.open();
              }
            }
          });
          break;
      }
    },

    resolveNewDueDate() {
      this.dueDate = this.newDueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Punchlist Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;

      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      if (!_.isEmpty(this.scheduleWarrantyDate)) {
        this.displayCard.scheduleWarrantyDate = toDateFirebase(
          this.scheduleWarrantyDate
        );
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            "day"
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) }
          });
        }
      }
    }
  }
};
</script>

<style></style>
