<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:close="close"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="close">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          Preview {{ invoiceDetail ? invoiceDetail.invoiceNumber : "" }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDownloadPDF">Download PDF</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div class="box-invoice-detail">
        <invoice-detail
          :invoiceDetail="invoiceDetail"
          :invoiceGroup="invoiceGroup || {}"
          :invoiceTemplate="invoiceTemplate"
          ref="invoiceDetail"
        >
        </invoice-detail>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import InvoiceDetail from "@/plugins/invoices/components/details/InvoiceDetail.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    InvoiceDetail
  },
  computed: {
    ...mapGetters("common/template", ["invoiceTemplate"]),
  },
  data() {
    return {
      popupOpened: false,
      invoiceDetail: {},
      invoiceGroup: {}
    };
  },

  methods: {
    open(invoiceDetail, invoiceGroup) {
      this.invoiceDetail = invoiceDetail;
      this.invoiceGroup = invoiceGroup;
      this.popupOpened = true;
    },
    handlePopupOpened() {
      const element = this.$el
        .querySelector(".box-invoice-detail")
        .getBoundingClientRect();
      this.$refs.invoiceDetail.lowResDesktopScreen(element.width);
    },
    close() {
      this.popupOpened = false;
      this.itemDetail = {};
      this.invoiceGroup = {};
    },
    onDownloadPDF() {
      this.$refs.invoiceDetail.handleDownloadPDF(
        this.invoiceDetail,
        this.invoiceGroup
      );
    }
  }
};
</script>

<style scoped>
.box-invoice-detail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>
