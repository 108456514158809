<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
    @popup:opened="handlePopupOpened"
  >
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Roof Type</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search roof type"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-item
          no-chevron
          link
          v-for="roofType in roofTypeListWithoutModbit"
          :key="roofType.id"
          :title="roofType.displayName"
          @click.native="select(roofType.value)"
        ></f7-list-item>
      </f7-list>
    </f7-page>

    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Roof Type</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Roof Type Name -->
        <f7-list-input
          type="text"
          placeholder="Roof Type name"
          clear-button
          :value="currentRoofType.value"
          @input="currentRoofType.value = $event.target.value.trim()"
        ></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      popupOpened: false,
      value: "",
      isNew: false,
      currentRoofType: {},
      searchValue: ""
    };
  },

  computed: {
    ...mapGetters("common/app-constant", ["roofTypeGroup", "roofTypeList"]),
    roofTypeListWithoutModbit() {
      const notSupportedList = [
        "modified-bitumen",
        "pvc",
        "liquid-applied",
        "epdm"
      ];

      if (!this.roofTypeList) return [];
      let roofs = this.roofTypeList.filter(
        r => !notSupportedList.includes(r.value)
      );

      if (this.searchValue) {
        roofs = roofs.filter(r =>
          r.displayName
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase() >= 0)
        );
      }
      return roofs;
    }
  },

  methods: {
    initData() {},

    open() {
      this.initData();
      this.popupOpened = true;
    },

    select(roofType) {
      this.$emit("onSelected", roofType);
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  }
};
</script>

<style></style>
