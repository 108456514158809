<template>
  <div>
    <div class="card data-table">
      <table>
        <thead>
          <tr>
            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('taskNumber')"
                >Task Number</span
              >
              <div class="input">
                <input
                  :value="searchTaskNumber"
                  @input="searchTaskNumber = $event.target.value.trim()"
                  type="text"
                  placeholder="Search Task Number"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <!-- <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('workOrder')"
                >WO Number</span
              >
              <div class="input">
                <input
                  :value="searchWONumber"
                  @input="searchWONumber = $event.target.value.trim()"
                  type="text"
                  placeholder="Search WO Number"
                />
                <span class="input-clear-button"></span>
              </div>
            </th> -->

            <!-- <th class="label-cell">
              <span class="table-head-label">Task Action</span>
              <div class="input input-dropdown">
                <select @change="changActionRequired">
                  <option value="0">All</option>
                  <option
                    v-for="action in filterActions(boardCode)"
                    :key="action.id"
                    :value="action.id"
                    >{{ action.title }}</option
                  >
                </select>
              </div>
            </th> -->
            <th
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('taskType')"
            >
              Task Type
            </th>

            <th
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('actionTitle')"
            >
              Action
            </th>

            <th
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('status')"
            >
              PO Status
            </th>

            <th class="label-cell table-head-label">Assignees</th>
            <th
              class="label-cell sortable-cell"
              :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
              @click="sort('dueDate')"
            >
              Due Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="cursor-pointer"
            v-for="(task, index) in workOrderTaskListFiltered"
            :key="`${task.id}-${index}`"
            @click="
              $emit('onOpenPopup', {
                actionId: task.actionId,
                cardID: task.id,
                taskNumber: task.taskNumber
              })
            "
          >
            <td class="label-cell">{{ task.taskNumber }}</td>
            <!-- <td class="label-cell">
              {{ task.workOrder }}
            </td> -->
            <td class="label-cell">
              {{ woTaskTypeBy(task.taskType).displayName }}
            </td>

            <td class="label-cell">{{ task.actionTitle }}</td>

            <td class="label-cell">
              <!-- {{ purchaseOrderStatusBy(task.status).displayName }} -->
              <f7-chip
                v-if="task.taskType === 'purchase-order'"
                slot="after"
                :text="purchaseOrderStatusBy(task.status).displayName"
                :color="purchaseOrderStatusBy(task.status).color"
              ></f7-chip>
            </td>

            <td class="label-cell assignee-cell">
              <!-- <f7-block v-show="users" strong class="display-flex"> -->
              <div
                v-for="uid in task.assigneeIds"
                :key="uid"
                class="assignee-avatar"
              >
                <user-avatar
                  :avatarSize="20"
                  :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
                  :name="userInfo(uid) ? userInfo(uid).displayName : ''"
                ></user-avatar>
              </div>
              <!-- </f7-block> -->
            </td>
            <td class="label-cell">
              <!-- {{ toDisplayDateString(task.dueDate) }} -->
              <f7-chip
                slot="after"
                :text="toDisplayDateString(task.dueDate)"
                :color="dueDateColor(task.dueDate)"
              ></f7-chip>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="data-table-footer">
        <div class="data-table-rows-select">
          Per page:
          <div class="input input-dropdown">
            <select>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="all">All</option>
            </select>
          </div>
        </div>
        <div class="data-table-pagination">
          <span class="data-table-pagination-label">1-5 of 10</span>
          <a href="#" class="link disabled">
            <i class="icon icon-prev color-gray"></i>
          </a>
          <a href="#" class="link">
            <i class="icon icon-next color-gray"></i>
          </a>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { mapGetters } from "vuex";
import methodsMixins from "../../mixin/methods";
import computedsMixins from "../../mixin/computeds";

export default {
  mixins: [computedsMixins, methodsMixins],

  components: { UserAvatar },

  data() {
    return {
      actionId: "",
      cardID: "",
      // filterActionId: "0"
      searchTaskNumber: "",
      searchWONumber: "",
      currentSort: null,
      currentSortDir: "asc"
    };
  },

  computed: {
    ...mapGetters("dashboard/work-order", [
      "workOrderActionList",
      "filterTaskListGrid",
      "workOrderTaskList"
    ]),

    ...mapGetters("dashboard/project", ["boardCode", "users"]),
    ...mapGetters("dashboard/app-constant", [
      "woTaskTypeBy",
      "purchaseOrderStatusBy"
    ]),

    workOrder() {
      return workOrderId => {
        return this.workOrderTaskList.find(wo => wo.id === workOrderId) || {};
      };
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    workOrderTaskListFiltered() {
      const filterTaskListGrid = this.filterTaskListGrid.map(r => ({
        ...r,
        workOrder: this.workOrder(r.workOrderId).woNumber || ""
      }));

      const listSort = filterTaskListGrid.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });

      return listSort.filter(task => {
        return (
          (task.taskNumber
            .toLowerCase()
            .includes(this.searchTaskNumber.toLowerCase()) ||
            this.searchTaskNumber === "") &&
          ((this.workOrder(task.workOrderId).woNumber || "")
            .toLowerCase()
            .includes(this.searchWONumber.toLowerCase()) ||
            this.searchWONumber === "")
        );
      });
    }
  },

  methods: {
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    }
  }
};
</script>

<style lang="scss" scoped>
.assignee-avatar {
  margin-right: 5px;
}

.assignee-cell {
  display: flex;
}
</style>
