import {
  FirebaseActions,
  StorageActions
} from "../../../../services/firebase.service";
import _ from "lodash";
import { searchClient } from "../../../../services/search.service";
import {
  COLLECTION_PRODUCT_PRODUCT_ITEMS,
  PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
  PROP_IS_DELETED
} from "../../../../utility/const";
import * as types from "./types";
import algoliaService from "../../../../services/algolia.service";

export default tenant => {
  const actionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    "object",
    {
      modelSchema: {
        price: "float",
        size: "float",
        wasterFactor: "float"
      }
    }
  );

  const vendorAction = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "vendor"
  );
  const categoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_category`,
    "category"
  );
  const subCategoryActions = new FirebaseActions(
    `/system_client/${tenant}/product_sub_category`,
    "subCategory"
  );

  const productItemIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    getVendor: vendorAction.getDocument,
    getCategory: categoryActions.getDocument,
    getSubCategory: subCategoryActions.getDocument,

    getProductBys: actionsTemplate.getDocumentBys,
    add: actionsTemplate.createDocument,
    save: actionsTemplate.updateDocument,
    remove: actionsTemplate.deleteDocument,

    async composeDataProduct({ dispatch }, doc) {
      let product = _.cloneDeep(doc);
      if (product.vendorId) {
        const vendor = await dispatch("getVendor", product.vendorId);
        product.vendorName = vendor ? vendor.companyName || "" : "";
      }
      if (product.categoryId) {
        const category = await dispatch("getCategory", product.categoryId);
        product.categoryName = category ? category.name || "" : "";
      }
      if (product.subCategoryId) {
        const subCategory = await dispatch(
          "getSubCategory",
          product.subCategoryId
        );
        product.subCategoryName = subCategory ? subCategory.name || "" : "";
      }
      return product;
    },

    async create({ dispatch }, data) {
      const newProduct = await dispatch("composeDataProduct", data);
      const id = await dispatch("add", newProduct);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "product_product_items",
        id
      });
      return id;
    },

    async update({ dispatch }, { id, doc }) {
      const newProduct = await dispatch("composeDataProduct", doc);
      await dispatch("save", { id, doc: newProduct });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "product_product_items",
        id
      });
      return id;
    },

    async delete({ dispatch }, id) {
      await dispatch("save", {
        id,
        doc: { [PROP_IS_DELETED]: true }
      });

      return await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "product_product_items",
        id
      });
    },

    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, fileData) {
      const base64Data = fileData.dataUrl;
      const file = fileData.info;
      const name = +new Date() + file.name || "";
      const fullPath = `product/photos/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, file);
    },

    copyPhoto({ commit },  photo ) {
      const toPath = `product/photos/${Date.now()}-${photo.name}`;
      return StorageActions.copyFile(photo.fullPath, toPath);
    },

    // eslint-disable-next-line no-unused-vars
    async removePhoto({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },

    getAllProductsByFilters(context, { query, filters }) {
      let hits = [];
      return productItemIndexAsc
        .browseObjects({
          query,
          filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}${
            !_.isEmpty(filters) ? ` AND ${filters}` : ""
          }`,
          attributesToRetrieve: ["id", "priceListRefs"],
          batch: batch => {
            hits = hits.concat(batch);
          }
        })
        .then(() => {
          return hits.map(r => ({
            id: r.id,
            priceListRefs: r.priceListRefs === "?" ? [] : r.priceListRefs
          }));
        });
    },

    // get data for filters
    getFilterData({ commit }, { filters }) {
      let hits = [];
      const requestOptions = {
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: [
          "roofTypes",
          "categoryId",
          "subCategoryId",
          "vendorId",
          "status"
        ],
        batch: batch => {
          hits = hits.concat(batch);
        }
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return productItemIndexAsc
        .browseObjects({
          query: "",
          ...requestOptions
        })
        .then(() => {
          const roofTypes = _.uniqBy(
            [].concat.apply(
              [],
              hits.map(r => r.roofTypes).filter(r => r !== "?")
            )
          );
          const categoryIds = _.uniqBy(
            hits.map(r => r.categoryId).filter(r => r !== "?")
          );
          const subCategoryIds = _.uniqBy(
            hits.map(r => r.subCategoryId).filter(r => r !== "?")
          );
          const vendorIds = _.uniqBy(
            hits.map(r => r.vendorId).filter(r => r !== "?")
          );
          const status = _.uniqBy(
            hits.map(r => r.status).filter(r => r !== "?")
          );
          const result = {
            roofTypes,
            categoryIds,
            subCategoryIds,
            vendorIds,
            status
          };
          commit(types.SET_FILTER_DATA_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    searchProduct(
      { state, commit },
      {
        attributesToRetrieve,
        restrictSearchableAttributes,
        filters,
        pageNumber
      }
    ) {
      const index = productItemIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "productItem",
          "manufacturer",
          "sku",
          "price",
          "colorPhotos.colorName"
        ]
      };
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (!_.isEmpty(attributesToRetrieve)) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (!_.isEmpty(filters)) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit },
      { pageName, attributesToRetrieve, filters, restrictSearchableAttributes }
    ) {
      const index = productItemIndexAsc;

      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = state.page + 1;
          break;

        case "prev":
          pageNumber = state.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "productItem",
          "manufacturer",
          "sku",
          "price",
          "colorPhotos.colorName"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setProductNumberOfRows({ commit }, value) {
      commit(types.SET_NUMBER_OF_ROWS, value);
    },
    setSearchText({ commit }, value) {
      commit(types.SET_SEARCH_TEXT, value);
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    }
  };
};
