export default {
  contactList: [],
  contactSubscribe: null,
  companyList: [],
  companySubscribe:null,
  propertyList: [],
  propertySubscribe: null,

  contact: {},
  company: {},
  property: {},

  insuranceCompany: {},

  contactCompanyRefs: [],
  contactPropertyRefs: [],
  contactProjectRefs: [],

  propertyContactRefs: [],
  propertyCompanyRefs: [],
  propertyProjectRefs: [],

  companyContactRefs: [],
  companyPropertyRefs: [],
  companyBranchRefs: [],
  companyProjectRefs: []
};
