import {
  COMPANY_TYPE_VENDOR,
  CODE_COMPANY_TYPE,
  CODE_SCHEDULING_VIEWS,
  CODE_TIME_OFF_TYPE,
  CODE_TIME_LOG_TYPE
} from "../../../../utility/const";

export default {
  viewList: state =>
    state.typeList.filter(item => item.code === CODE_SCHEDULING_VIEWS),

  timeLogTypeList: state =>
    state.typeList.filter(item => item.code === CODE_TIME_LOG_TYPE),

  timeOffTypeList: state =>
    state.typeList.filter(item => item.code === CODE_TIME_OFF_TYPE),

  companyTypeListExceptVendor: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_COMPANY_TYPE && item.value !== COMPANY_TYPE_VENDOR
    )
};
