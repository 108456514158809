<template>
  <f7-page class="employee-scheduling-page">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Employee Scheduling</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <!-- web -->
    <template v-if="$device.desktop">
      <!-- header -->
      <f7-block
        strong
        class="display-flex align-items-center justify-content-space-between py-5"
        style="flex-wrap: wrap; gap: 8px"
      >
        <div class="display-flex">
          <div class="border-shadow btn-container">
            <f7-button
              color="gray"
              icon-f7="arrowtriangle_left_fill"
              class="flex-center btn-container_prev"
              @click="prevWeek"
            ></f7-button>
            <f7-link
              color="black"
              class="btn-container_select"
              @click.native="selectCalendar"
            >
              {{ showWeekRange }}
            </f7-link>
            <f7-button
              color="gray"
              icon-f7="arrowtriangle_right_fill"
              class="flex-center btn-container_next"
              @click="nextWeek"
            ></f7-button>
          </div>

          <div class="border-shadow select-container margin-left">
            <f7-input
              style="width: 100%"
              type="select"
              placeholder="View"
              :value="currentView"
              @change="currentView = $event.target.value"
            >
              <option
                v-for="(view, index) in viewList"
                :key="index"
                :value="view.value"
              >
                {{ view.displayName }}
              </option>
            </f7-input>
          </div>

          <div class="border-shadow select-container margin-left no-padding">
            <f7-list inset class="no-margin" style="max-width: 300px">
              <f7-list-item
                title="Filter by Teams"
                smart-select
                :smart-select-params="{
                  openIn: 'popup',
                  routableModals: false,
                  searchbar: true,
                  searchbarPlaceholder: 'Search Team'
                }"
                ref="teamFilterSmartSelect"
              >
                <select multiple @change="changeTeamFilter">
                  <option
                    v-for="team in teamList"
                    :key="team.id"
                    :value="team.id"
                  >
                    {{ team.teamName }}
                  </option>
                </select>
              </f7-list-item>
            </f7-list>
          </div>

          <div class="border-shadow select-container margin-left no-padding">
            <f7-list inset class="no-margin" style="max-width: 400px">
              <f7-list-item
                title="Filter by Employees"
                smart-select
                :smart-select-params="{
                  openIn: 'popup',
                  routableModals: false,
                  searchbar: true,
                  searchbarPlaceholder: 'Search Employee'
                }"
                ref="employeeFilterSmartSelect"
              >
                <select multiple @change="changeEmployeeFilter">
                  <option
                    v-for="user in usersByTeams"
                    :key="user.uid"
                    :value="user.uid"
                  >
                    {{ user.displayName }}
                  </option>
                </select>
              </f7-list-item>
            </f7-list>
          </div>
        </div>
        <f7-button raised @click.native="openAddPopup()">
          Add New Shift
        </f7-button>
      </f7-block>

      <!-- calendar-->
      <div>
        <week
          :currentWeek="currentWeek"
          @openAddPopup="openAddPopup"
          :isDrag="true"
        ></week>
      </div>
    </template>

    <!-- mobile -->
    <template v-else>
      <!-- header -->
      <f7-block strong class="no-margin" style="min-height: 100%">
        <f7-row class="margin-bottom">
          <f7-col width="50">
            <div class="filter-select" @click="openTeamSelectPopup">
              <span>{{
                displayFilterByTeams ? displayFilterByTeams : "Filter by Teams"
              }}</span>
              <f7-icon f7="chevron_right" size="10"></f7-icon>
            </div>
          </f7-col>
          <f7-col width="50">
            <div class="filter-select" @click="isShowUserListPopup = true">
              <span>
                {{
                  userById(currentEmployeeId)
                    ? userById(currentEmployeeId).displayName
                    : "Select employee"
                }}
              </span>
              <f7-icon f7="chevron_right" size="10"></f7-icon>
            </div>
          </f7-col>
        </f7-row>
        <div
          class="display-flex justify-content-space-between align-items-center margin-bottom"
        >
          <f7-segmented strong tag="p" class="no-margin" style="width: 120px">
            <f7-button
              class="custom-hover"
              :active="currentView === 'day'"
              @click.native="onTabViewMobile('day')"
              >Day</f7-button
            >
            <f7-button
              class="custom-hover"
              :active="currentView === 'week'"
              @click.native="onTabViewMobile('week')"
              >Week</f7-button
            >
          </f7-segmented>
          <div class="display-flex align-items-center" style="gap: 8px">
            <f7-button
              fill
              small
              @click.native="
                currentView === 'day'
                  ? openAddPopup(selectedItem)
                  : selectedItem.userId
                  ? openAddPopup({ userId: selectedItem.userId })
                  : openAddPopup()
              "
            >
              Add New Shift</f7-button
            >
            <f7-button
              v-show="isCopyMobile()"
              outline
              small
              @click.native="cancelPaste"
            >
              Cancel</f7-button
            >
          </div>
        </div>
        <div class="btn-container-mobile margin-bottom">
          <f7-button
            color="gray"
            small
            icon-size="20"
            icon-f7="arrowtriangle_left_fill"
            class="btn-container-mobile_prev"
            @click="prevWeek"
          ></f7-button>
          <f7-link
            color="gray"
            class="btn-container-mobile_select"
            @click.native="selectCalendar"
          >
            {{ showWeekRangeMobile }}
          </f7-link>
          <f7-button
            color="gray"
            small
            icon-size="20"
            icon-f7="arrowtriangle_right_fill"
            class="btn-container-mobile_next"
            @click="nextWeek"
          ></f7-button>
        </div>

        <!-- calendar -->
        <week-mobile
          ref="weekMobile"
          :currentView="currentView"
          :currentWeek="currentWeek"
          :currentEmployeeId="currentEmployeeId"
        ></week-mobile>
      </f7-block>
    </template>

    <!-- calendar input hidden -->
    <input
      class="display-none"
      type="text"
      readonly="readonly"
      id="calendar-input"
    />
    <user-list-popup
      :isShow="isShowUserListPopup"
      @close="isShowUserListPopup = false"
      @onSelected="selectUser"
    ></user-list-popup>

    <add-new-schedule-popup
      :isShow="isShowAddPopup"
      @close="closePopup"
    ></add-new-schedule-popup>
    <team-select-popup
      ref="teamSelectPopup"
      @selectTeam="changeTeamFilterMobile"
    ></team-select-popup>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Week from "@/plugins/scheduling/components/views/Week.vue";
import WeekMobile from "@/plugins/scheduling/components/views/WeekMobile.vue";
import UserListPopup from "@/plugins/scheduling/components/popups/UserListPopup.vue";
import AddNewSchedulePopup from "@/plugins/scheduling/components/popups/AddNewSchedulePopup.vue";
import moment from "moment";
import { auth } from "../../../services/firebase.service";
import Menu from "../../../components/menu/Menu.vue";
import TeamSelectPopup from "../components/popups/TeamSelectPopup.vue";
import _ from "lodash";
import { SESSION_KEY } from "../utility/const";

function getCurrentWeek() {
  const startTime = sessionStorage.getItem(SESSION_KEY.EMPLOYEE_SCHEDULING);

  const weekStart = startTime
    ? moment(startTime).startOf("isoWeek")
    : moment().startOf("isoWeek");

  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push({
      date: moment(weekStart)
        .add(i, "days")
        .format("MM/DD/YYYY"),
      dateHeader: moment(weekStart)
        .add(i, "days")
        .format("ddd Do"),
      dateHeaderShort: moment(weekStart)
        .add(i, "days")
        .format("dd DD"),
      dateFormatddd: moment(weekStart)
        .add(i, "days")
        .format("ddd"),
      dateFormatDD: moment(weekStart)
        .add(i, "days")
        .format("DD")
    });
  }
  return days;
}

export default {
  components: {
    Week,
    WeekMobile,
    UserListPopup,
    AddNewSchedulePopup,
    Menu,
    TeamSelectPopup
  },
  data: () => {
    return {
      currentView: "week",
      currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
      currentEmployeeId: null,
      isShowUserListPopup: false,
      isShowAddPopup: false
    };
  },
  methods: {
    ...mapActions("scheduling/user", [
      "getUserList",
      "resetUser",
      "setTeamFilter",
      "setEmployeeFilter"
    ]),
    ...mapActions("scheduling/team", ["getTeamList", "resetTeam"]),
    ...mapActions("scheduling/scheduling", [
      "setIsMyScheduling",
      "selectItem",
      "getScheduleList",
      "resetScheduling"
    ]),
    ...mapActions("scheduling/app-constant", [
      "getConstantTypeList",
      "resetConstant"
    ]),
    ...mapActions("scheduling/album", ["resetAlbum"]),
    ...mapActions("scheduling/photo", ["resetPhoto"]),
    ...mapActions("scheduling/client", ["resetClient"]),
    ...mapActions("scheduling/project", ["resetProject"]),
    ...mapActions("scheduling/chain", ["resetChain"]),
    ...mapActions("setting/app/profile", ["getUserProfile"]),

    selectedDateInWeek() {
      return this.currentWeek
        .map(r => r.date)
        .includes(moment().format("MM/DD/YYYY"))
        ? moment().format("MM/DD/YYYY")
        : this.currentWeek[0].date;
    },

    onTabViewMobile(value) {
      this.currentView = value;
      if (value === "day") {
        this.selectItem({
          userId: this.currentEmployeeId,
          date: this.selectedDateInWeek()
        });
      }
      this.updateRoute();
    },
    openAddPopup(item = {}) {
      this.isShowAddPopup = true;
      this.selectItem(item);
    },
    openTeamSelectPopup() {
      this.$refs.teamSelectPopup.open();
    },
    closePopup() {
      this.isShowAddPopup = false;
      localStorage.setItem("projectIdService", "");
    },
    updateRoute() {
      const currentView = this.currentView;
      const startWeek = this.currentWeek[0].date;
      const query = {
        currentView: currentView,
        startWeek: moment(startWeek, "MM/DD/YYYY").format("MM-DD-YYYY")
      };
      if (this.$f7route.query.currentUser) {
        query.currentUser = "";
      }
      const url = this.$f7router.generateUrl({
        name: "employee-scheduling",
        query
      });
      this.$f7router.updateCurrentUrl(url);

      // updateUrl again to remove currentUser in query
      if (this.$f7route.query.currentUser == "") {
        const url = this.$f7router.generateUrl({
          name: "employee-scheduling",
          query: {
            ...query,
            currentUser: undefined
          }
        });
        this.$f7router.updateCurrentUrl(url);
      }
    },

    async selectUser(userId) {
      this.$f7.preloader.show();
      this.currentEmployeeId = userId;
      this.selectItem({ userId, date: this.selectedDateInWeek() });
      await this.getSchedulingByCurrentWeek();
      this.$f7.preloader.hide();
    },

    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: "#calendar-input",
          openIn: "customModal",
          backdrop: true,
          cssClass: "calendar-hover",
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: "current-week",
              range: {
                from: new Date(this.currentWeek[0].date),
                to: new Date(this.currentWeek[6].date)
              }
            }
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), "day")
              ) {
                this.currentDate = value;
                sessionStorage.setItem(
                  SESSION_KEY.EMPLOYEE_SCHEDULING,
                  value[0]
                );
                const weekStart = moment(value[0], "MM/DD/YYYY").startOf(
                  "isoWeek"
                );
                this.setCurrentWeek(weekStart);
                calendar.close();
                this.updateRoute();
              }
            }
          }
        })
        .open();
    },
    async setCurrentWeek(weekStart, isShowLoading = true) {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push({
          date: moment(weekStart)
            .add(i, "days")
            .format("MM/DD/YYYY"),
          dateHeader: moment(weekStart)
            .add(i, "days")
            .format("ddd Do"),
          dateHeaderShort: moment(weekStart)
            .add(i, "days")
            .format("dd DD"),
          dateFormatddd: moment(weekStart)
            .add(i, "days")
            .format("ddd"),
          dateFormatDD: moment(weekStart)
            .add(i, "days")
            .format("DD")
        });
      }
      this.currentWeek = days;
      isShowLoading && this.$f7.preloader.show();
      await this.getSchedulingByCurrentWeek();
      this.currentEmployeeId &&
        this.selectItem({
          userId: this.currentEmployeeId,
          date: this.selectedDateInWeek()
        });
      isShowLoading && this.$f7.preloader.hide();
    },
    prevWeek() {
      const weekStart = moment(this.currentWeek[0].date, "MM/DD/YYYY")
        .subtract(1, "weeks")
        .startOf("isoWeek");
      this.currentDate = [new Date(weekStart)];
      this.setCurrentWeek(weekStart);
      this.updateRoute();
      sessionStorage.setItem(SESSION_KEY.EMPLOYEE_SCHEDULING, weekStart);
    },
    nextWeek() {
      const weekStart = moment(this.currentWeek[0].date, "MM/DD/YYYY")
        .add(1, "weeks")
        .startOf("isoWeek");
      this.currentDate = [new Date(weekStart)];
      this.setCurrentWeek(weekStart);
      this.updateRoute();
      sessionStorage.setItem(SESSION_KEY.EMPLOYEE_SCHEDULING, weekStart);
    },
    getSchedulingByCurrentWeek() {
      if (!_.isEmpty(this.currentWeek)) {
        const startDate = this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(this.currentWeek[0].date)
        );
        const endDate = this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(this.currentWeek[6].date)
        );
        let conditions = [
          {
            prop: "date",
            val: startDate,
            op: ">="
          },
          {
            prop: "date",
            val: endDate,
            op: "<="
          }
        ];
        return this.getScheduleList(conditions);
      }
    },

    changeView() {
      const value = this.$refs.viewSmartSelect.f7SmartSelect.getValue();
      this.currentView = value;
    },
    changeTeamFilter() {
      const value = this.$refs.teamFilterSmartSelect.f7SmartSelect.getValue();
      this.setTeamFilter(value);
      localStorage.setItem("schedulingTeamFilter", JSON.stringify(value));
      if (
        this.$refs.employeeFilterSmartSelect &&
        !_.isEmpty(
          this.$refs.employeeFilterSmartSelect.f7SmartSelect.getValue()
        )
      ) {
        this.$refs.employeeFilterSmartSelect.f7SmartSelect.setValue([]);
        this.setEmployeeFilter([]);
        localStorage.setItem("schedulingEmployeeFilter", JSON.stringify([]));
      }
    },
    changeEmployeeFilter() {
      const value = this.$refs.employeeFilterSmartSelect.f7SmartSelect.getValue();
      this.setEmployeeFilter(value);
      localStorage.setItem("schedulingEmployeeFilter", JSON.stringify(value));
    },

    changeTeamFilterMobile(teamIds) {
      this.setTeamFilter(teamIds);
      localStorage.setItem("schedulingTeamFilter", JSON.stringify(teamIds));
      if (!_.isEmpty(teamIds)) {
        this.currentEmployeeId = null;
        this.setEmployeeFilter([]);
        localStorage.setItem("schedulingEmployeeFilter", JSON.stringify([]));
      }
    },

    isCopyMobile() {
      return this.$refs.weekMobile && this.$refs.weekMobile.isCopy;
    },

    cancelPaste() {
      this.$refs.weekMobile.cancelPaste();
    }
  },
  computed: {
    ...mapGetters("scheduling/user", [
      "userById",
      "filterUserList",
      "usersByTeams",
      "teamFilter",
      "employeeFilter"
    ]),
    ...mapGetters("scheduling/app-constant", ["viewList"]),
    ...mapGetters("scheduling/team", ["teamList"]),
    ...mapGetters("setting/app/group", ["isAdminGroup"]),
    ...mapGetters("setting/app/profile", ["currentUser"]),
    ...mapGetters("scheduling/scheduling", ["selectedItem"]),

    showWeekRange() {
      const from = moment(this.currentWeek[0].date, "MM/DD/YYYY").format(
        "MMM Do"
      );
      const to = moment(this.currentWeek[6].date, "MM/DD/YYYY").format(
        "MMM Do"
      );
      return `${from} - ${to}`;
    },

    showWeekRangeMobile() {
      return moment(this.currentWeek[0].date, "MM/DD/YYYY").format("MMMM YYYY");
    },
    displayFilterByTeams() {
      if (_.isEmpty(this.teamFilter)) return "";
      return (
        this.teamList.filter(r => (this.teamFilter || []).includes(r.id)) || []
      )
        .map(r => r.teamName)
        .join(", ");
    }
  },

  async mounted() {
    this.$f7.preloader.show();
    this.setIsMyScheduling(false);
    const promises = [];

    promises.push(
      this.setTeamFilter(
        JSON.parse(localStorage.getItem("schedulingTeamFilter")) || []
      )
    );
    promises.push(
      this.setEmployeeFilter(
        JSON.parse(localStorage.getItem("schedulingEmployeeFilter")) || []
      )
    );

    const currentView = this.$f7route.query.currentView;
    const startWeek = moment(
      this.$f7route.query.startWeek,
      "MM/DD/YYYY"
    ).startOf("isoWeek");
    if (currentView && startWeek) {
      this.currentDate = [new Date(startWeek)];
      this.currentView = currentView;
      promises.push(this.setCurrentWeek(startWeek, false));
    } else {
      promises.push(this.getSchedulingByCurrentWeek());
      setTimeout(() => {
        this.updateRoute();
      }, 100);
    }
    await this.getUserProfile();
    if (
      (this.currentUser.customClaims || {}).role === "owner" ||
      this.isAdminGroup
    ) {
      promises.push(
        this.getTeamList([
          {
            prop: "disabled",
            val: false,
            op: "=="
          }
        ])
      );
    } else {
      promises.push(
        this.getTeamList([
          {
            prop: "managerID",
            val: auth.currentUser.uid,
            op: "=="
          },
          {
            prop: "disabled",
            val: false,
            op: "=="
          }
        ])
      );
    }
    promises.push(this.getUserList());
    promises.push(this.getConstantTypeList());
    Promise.all(promises)
      .then(() => {
        this.$refs.teamFilterSmartSelect &&
          this.$refs.teamFilterSmartSelect.f7SmartSelect.setValue(
            this.teamFilter
          );
        // set before currentEmployeeId
        this.$refs.employeeFilterSmartSelect &&
          this.$refs.employeeFilterSmartSelect.f7SmartSelect.setValue(
            this.employeeFilter
          );
        this.currentEmployeeId = this.$f7route.query.currentUser || null;
        this.currentEmployeeId &&
          this.selectItem({
            userId: this.currentEmployeeId,
            date: this.selectedDateInWeek()
          });
        const projectId = localStorage.getItem("projectIdService");
        if (projectId) {
          this.openAddPopup();
        }
      })
      .finally(() => {
        this.$f7.preloader.hide();
      });
  },

  beforeDestroy() {
    this.resetUser();
    this.resetConstant();
    this.resetScheduling();
    this.resetAlbum();
    this.resetPhoto();
    this.resetClient();
    this.resetProject();
    this.resetChain();
    this.resetTeam();
  }
};
</script>
<style lang="scss" scoped>
@import "../style/common.scss";
.calendar-hover {
  .calendar-months {
    .calendar-row {
      &:hover {
        background-color: #000 !important;
      }
    }
  }
}

.employee-scheduling-page ::v-deep .page-content {
  overflow: unset;
}
</style>
