<template>
  <suggestions
    class="v-suggestions_mine"
    v-model="query"
    :options="options"
    :onInputChange="onInputChange"
    :onItemSelected="onItemSelected"
    :readonly="readonly"
  >
    <div
      slot="item"
      slot-scope="props"
      class="row v-suggestions_row justify-content-center"
    >
      <div
        class="col photo cursor-pointer"
        :class="{ 'center-item': !$device.desktop && !$device.ipad }"
      >
        <div v-if="props.item.img" class="image-preview ">
          <img class="image-show" :src="props.item.img" />
        </div>
        <div
          v-else
          class="image-preview"
          :style="`background-image: url(${NO_IMAGE_AVAILABLE})`"
        ></div>
        <div class="cell-title">
          {{ props.item.displayName }}
        </div>
      </div>
      <!-- <div class="col size">{{ props.item.displayName }}</div> -->
    </div>
  </suggestions>
</template>

<script>
import Suggestions from "@/components/suggestions/Suggestions.vue";
import { NO_IMAGE_AVAILABLE } from "@/utility/const";

export default {
  components: { Suggestions },
  props: {
    placeholder: String,
    readonly: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    value: { type: String, default: "" }
  },

  data() {
    return {
      editing: false,
      currentValue: "",
      options: {
        debounce: 0,
        inputClass: "input",
        placeholder: this.placeholder || ""
      },
      query: "",

      NO_IMAGE_AVAILABLE
    };
  },

  watch: {
    value(newValue) {
      this.query = newValue;
    }
  },

  created() {
    this.query = this.value;
  },

  methods: {
    onInputChange(query) {
      return this.data.filter(item => {
        return item.displayName.toLowerCase().includes(query.toLowerCase());
      });
    },

    onItemSelected(item) {
      this.$emit("selected", { target: { value: item.value } });
    }

    // clear() {
    //   this.$emit("clear");
    // }
  }
};
</script>

<style lang="scss" scoped>
.v-suggestions_mine ::v-deep input {
  border: 1px solid #eee;
  border-radius: 3px;
  width: 100%;
}

.v-suggestions_mine ::v-deep .items {
  border: 1px solid rgb(161, 161, 161);
  // z-index: 14;
}

.v-suggestions_mine ::v-deep .suggestions {
  width: auto;
  top: 25px;
}
.v-suggestions_mine ::v-deep .suggestions ul.items {
  max-height: 420px;
  overflow: auto;
}

.v-suggestions_row ::v-deep .photo {
  width: auto;
  margin: 10px 10px 10px 10px;
}

.v-suggestions_row ::v-deep .size {
  width: auto;
  text-align: left;
  align-items: center;
}

.v-suggestions_row ::v-deep .col {
  word-break: break-word;
}

.image-preview {
  border-radius: 4px;
  width: 300px;
  height: 168px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-color: white;
}

.cell-title {
  font-weight: 800;
  text-align: center;
  margin-bottom: 10px;
}
.center-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-show {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
