<template>
  <div>
    <f7-popup
      :opened="isPopupOpened"
      @popup:closed="handlePopupClosed"
      @popup:opened="() => {}"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Proposal Financing</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="saveAndClose">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <financing-proposal-tab ref="financingProposalTab" />
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import FinancingProposalTab from "../tabs/FinancingProposalTab.vue";
import { mapGetters } from "vuex";

export default {
  components: { FinancingProposalTab },
  data() {
    return {
      isPopupOpened: false,
      estimateId: "",
      sectionId: ""
    };
  },

  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    })
  },

  methods: {
    openPopup(estimateId, sectionId) {
      this.$refs.financingProposalTab.init();
      this.estimateId = estimateId;
      this.sectionId = sectionId;
      this.isPopupOpened = true;
    },

    saveAndClose() {
      this.$f7.preloader.show();
      const self = this;

      this.$refs.financingProposalTab
        .saveOnly()
        .then(currentFinancingProposal => {
          this.$emit("onSave", {
            sectionId: this.sectionId,
            financingList: currentFinancingProposal,
            callback: () => {
              self.$f7.preloader.hide();
              self.isPopupOpened = false;
            }
          });
        });
    },

    handlePopupClosed() {
      this.isPopupOpened = false;
    }
  }
};
</script>

<style></style>
