<template>
  <div :style="styleProp ? styleProp : ''">
    <f7-block-header v-if="isShowTitle" >{{ title }}</f7-block-header>

    <f7-list media-list :class="[classBackground, 'note-editor']">
      <div class="note-editor-content">
        <user-avatar
          v-if="isShowAvatar"
          style="margin-top: 15px; margin-left: 15px"
          :avatarSize="35"
          :imageUrl="userInfo ? userInfo.photoURL : ''"
          :name="userInfo ? userInfo.displayName : ''"
        >
        </user-avatar>
        <f7-text-editor
          ref="noteEditor"
          class="editor"
          style="flex: 1;"
          resizable
          placeholder="Enter note..."
          :value="value"
          @texteditor:change="changeValueNotes"
          :buttons="buttons"
          :custom-buttons="customButtons"
        ></f7-text-editor>
      </div>
      <div class="note-editor-bottom" v-if="isShowSaveButton">
        <f7-button @click="onSave">
          Save
        </f7-button>
      </div>
    </f7-list>
    <select-color-popup ref="selectColor" />
  </div>
</template>

<script>
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { buttonsForTextFormatBar } from "@/utility/const";
import { mapGetters } from "vuex";
import { customButtonForTextFormatBar } from "@/utility/common";
import SelectColorPopup from "@/plugins/proposal/components/popup/SelectColorPopup.vue";

export default {
  data() {
    return {
      currentRange: null,
      customButtons: customButtonForTextFormatBar(this, false),
      buttons: buttonsForTextFormatBar
    };
  },
  props: {
    title: {
      type: String,
      default: "Note"
    },
    value: {
      type: String,
      default: ""
    },
    isSaveButton: {
      type: Boolean,
      default: false
    },
    isShowAvatar: {
      type: Boolean,
      default: true
    },
    isShowTitle: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: "toolbar"
    },
    styleProp:{
      type: String,
      default:""
    }
  },

  components: {
    UserAvatar,
    SelectColorPopup
  },

  computed: {
    ...mapGetters("setting/app/profile", { currentUser: "user" }),

    userInfo() {
      return this.currentUser
    },

    isShowSaveButton() {
      return this.value && this.isSaveButton ? true : false;
    },
    classBackground() {
      return this.isShowAvatar ? "background-color" : "no-background-color";
    }
  },

  methods: {
    openDialogInsertLinkTextEditor() {
      this.$f7.dialog
        .create({
          title: `<p style="font-size: 14px;">Insert Link</p>`,
          content: `<input type="text" id="insert-link" placeholder="Insert link URL" style="width: 100%; border: 1px solid #2a2a2a; padding: 4px; border-radius:3px;">`,
          buttons: [
            {
              text: "Cancel",
              color: "gray"
            },
            {
              text: "OK",
              cssClass: "primary"
            }
          ],

          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              const editor = this.$refs.noteEditor;
              if (this.currentRange) {
                editor.$el.f7TextEditor.setSelectionRange(this.currentRange);
                const inputElement = document.getElementById("insert-link");
                const inputValue = inputElement.value;
                document.execCommand("createLink", true, inputValue);
                _sefl.app.dialog.close();
                this.currentRange = null;
              }
            }
          }
        })
        .open();
    },

    changeValueNotes(value) {
      const convertValue = value.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      this.$emit("onChange", convertValue);
    },

    onSave() {
      if (!this.value) return;
      this.$emit("onSave");
      this.$emit("onChange", this.value);
    }
  }
};
</script>

<style scoped lang="scss">
.editor {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word; 
}
.note-editor {
  background-color: var(--f7-block-strong-bg-color);

  .note-editor-content {
    margin-top: 12px;
    display: flex;
  }

  .note-editor-bottom {
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
    a {
      display: inline;
      margin-right: var(--f7-text-editor-margin);
      margin-bottom: 15px;
    }
  }
}
</style>
<style>
.input-style {
  width: 100%;
  border: 1px solid #ccc;
}
.background-color {
  background-color: var(--f7-block-strong-bg-color);
}
.no-background-color {
  background-color: transparent;
}
</style>
