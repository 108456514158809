<template>
  <div>
    <service-product-table
      :existingProducts="existingProductsList"
      :roofType="roofType"
      @onChangeProductItem="onChangeProductItem"
    ></service-product-table>
  </div>
</template>

<script>
import ServiceProductTable from "../tables/ServiceProductTable.vue";

import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    ServiceProductTable
  },

  props: { roofType: String },

  mixins: [mixins, buildingMixins],

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", ["currentBuilding"]),

    existingProductsList() {
      return this.productBuildingData;
    }
  },

  methods: {
    validate() {},
    init() {},
    onChangeProductItem({ oldProduct, newProduct }) {
      return this.changeProductItem({
        roofType: this.roofType,
        sectionId: oldProduct.sectionId,
        oldProduct,
        newProduct
      });
    }
  }
};
</script>

<style></style>
