<template>
  <div>
    <note-editor :value="noteContent" @onChange="changeValue"></note-editor>

    <f7-block>
      <f7-row tag="p" v-show="noteContent != ''">
        <f7-col tag="span" width="75"></f7-col>
        <f7-col tag="span" width="25">
          <f7-button raised @click.native="saveNote">Save</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-list inset media-list v-for="(item, index) in noteList" :key="index">
      <f7-list-item class="note-content">
        <div slot="media">
          <user-avatar
            :avatarSize="35"
            :imageUrl="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').photoURL
                : ''
            "
            :name="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').displayName
                : item.createdBy
            "
          >
          </user-avatar>
        </div>
        <div slot="title" v-show="$device.desktop || $device.ipad">
          <strong>{{ item.createdBy }}</strong> added a
          {{ item.title }}
        </div>
        <div v-html="item.htmlContent"></div>
        <div slot="after">
          {{ toLocaleString(item.createdAt) }}
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { auth } from "../../../../services/firebase.service";
import _ from "lodash";
import { mapState } from "vuex";
import NoteEditor from "../note/NoteEditor.vue";
import UserAvatar from "../filter/UserAvatar.vue";

export default {
  components: { UserAvatar, NoteEditor },

  data: () => {
    return {
      noteContent: ""
    };
  },

  props: {
    value: { type: Array, default: () => [] }
  },

  computed: {
    ...mapState("dashboard/project", ["users"]),

    noteList() {
      return _.cloneDeep(this.value).sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds;
      });
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    uid() {
      return auth.currentUser.uid;
    }
  },

  methods: {
    changeValue(value) {
      this.noteContent = value;
    },
    saveNote() {
      this.$emit("change", this.noteContent);
      this.noteContent = "";
    },
    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    }
  }
};
</script>

<style lang="scss" scoped>
.note-editer {
  margin: 0px;
}
.note-content {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word; 
}
</style>
