<template>
  <f7-list>
    <f7-list-item
      :title="$t('settings.language')"
      smart-select
      :smart-select-params="{ openIn: 'popover' }"
    >
      <select @change="switchLanguage()" v-model="selected">
        <option
          v-for="lang in languages"
          :key="lang.value"
          :value="lang.value"
          >{{ lang.displayName }}</option
        >
      </select>
    </f7-list-item>
  </f7-list>
</template>

<script>
import { ACTIVE_LANGUAGES } from "@/lang/languages";
import { setRememberLanguage, getActiveLanguage } from "@/lang/translation";

export default {
  data() {
    return {
      selected: getActiveLanguage()
    };
  },

  computed: {
    languages() {
      return ACTIVE_LANGUAGES.getAllActiveLanguage();
    }
  },
  methods: {
    switchLanguage() {
      setRememberLanguage(this.selected);
      const url = this.$f7route.url;
      window.location.href = window.location.origin + url;
    }
  }
};
</script>
