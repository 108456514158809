<template>
  <proposal-detail
    v-if="proposalType === 'proposal'"
    ref="proposalDetail"
  ></proposal-detail>
  <contract-detail
    v-else-if="proposalType === 'contract'"
    ref="contractDetail"
  ></contract-detail>
</template>

<script>
import ProposalDetail from "../components/details/ProposalDetail.vue";
import ContractDetail from "../components/details/ContractDetail.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProposalDetail,
    ContractDetail
  },

  computed: {
    ...mapGetters("proposal/proposal", ["proposalType"])
  },

  methods: {
    ...mapActions("proposal/proposal", ["setProposalType"])
  },

  created() {
    let proposalNumber =
      this.$f7route.params && (this.$f7route.params.proposalNumber || "");
    if (proposalNumber.includes("CON-")) {
      this.setProposalType("contract");
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.proposalType === "proposal") {
        this.$refs.proposalDetail.init();
      } else if (this.proposalType === "contract") {
        this.$refs.contractDetail.init();
      }
    });
  }
};
</script>
