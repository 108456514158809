/* eslint-disable no-unused-vars */
import {
  CALCULATION_NO_METHOD,
  ATTACHMENT_TYPE_TPO_MA_LIST,
  ATTACHMENT_TYPE_TPO_FA_LIST,
  ATTACHMENT_TYPE_TPO_MEMBRANE_MA,
  ATTACHMENT_TYPE_TPO_MEMBRANE_FA,
  SECTION_TYPE_TPO_INSULATION,
  // ATTACHMENT_TYPE_TPO_ISO_FA,
  // ATTACHMENT_TYPE_TPO_ISO_MA,
  WORK_TYPE_NEW,
  WORK_TYPE_RECOVER,
  WORK_TYPE_TEAR_OFF,
  JOB_TYPE_NEW,
  JOB_TYPE_RECOVER,
  JOB_TYPE_REPLACEMENT
} from "@/utility/const";

import _ from "lodash";
import moment from "moment";

import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  ROOF_TYPE_SHINGLE,
  ROOF_TYPE_TPO,
  SLOPE_TYPE_LOW,
  SLOPE_TYPE_STEEP
} from "../../../utility/const";

import { toDateCalendar } from "../../../utility/datetime";
import { FirebaseActions, auth } from "../../../services/firebase.service";

export const roundUpNumber = numberOfFixedRounds => value => {
  value = Math.round(value * 1000) / 1000;
  const denominator = Math.pow(10, numberOfFixedRounds);
  const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
  return Math.ceil(temp) / denominator;
};

const findItemBySubcategory = (sections, subCategory) => {
  let newArray = sections.reduce(
    (allItems, x) => [...allItems, ...x.items],
    []
  );
  return newArray.find(m => m.subCategory === subCategory);
};

const measureHashFunction = new (function() {
  // Shingle

  /**
   * DONE
   */
  this.SHINGLE_MAIN_SQUARE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const fieldArea = building.fieldArea || 0;
    // Convert roof area from SQ to sqft
    const totalSquare =
      productItem.uom.toLowerCase() === "sqft" ? fieldArea * 100 : fieldArea;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: totalSquare * wasterFactor,
      orderQty: (totalSquare / productSize) * wasterFactor
    };
  };
  /**
   * DONE
   */
  this.SHINGLE_HIP_AND_RIDGE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const totalLength = (building.hipLength || 0) + (building.ridgeLength || 0);
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: totalLength * wasterFactor,
      orderQty: (totalLength / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.SHINGLE_STARTER = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const perimeter = building.perimeter || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: perimeter * wasterFactor,
      orderQty: (perimeter / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.SHINGLE_FELT = this.SHINGLE_MAIN_SQUARE;

  /**
   * DONE
   */
  this.SHINGLE_ICE_AND_WATER = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const valleyLength = building.valleyLength || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: valleyLength * wasterFactor,
      orderQty: (valleyLength / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.SHINGLE_FASTENERS = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    // Convert roof area from SQ to sqft
    const fieldArea = (building.fieldArea || 0) * 100;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    const eachPerSqft =
      productSize / (parseFloat(productItem.productHashtag) || 1);

    const actualQty = fieldArea * eachPerSqft;

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: (actualQty / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.SHINGLE_RIDGE_HIP_VENT = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    const ridgeLength = building.ridgeLength || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: ridgeLength * wasterFactor,
      orderQty: (ridgeLength / productSize) * wasterFactor
    };
  };

  this.SHINGLE_SIDEWALL = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    const sideWall = building.sideWall || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: sideWall * wasterFactor,
      orderQty: (sideWall / productSize) * wasterFactor
    };
  };

  this.SHINGLE_DRIP_EDGE_EAVE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    const perimeter = building.perimeter || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: perimeter * wasterFactor,
      orderQty: (perimeter / productSize) * wasterFactor
    };
  };

  this.SHINGLE_HEADWALL = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    const headWall = building.headWall || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: headWall * wasterFactor,
      orderQty: (headWall / productSize) * wasterFactor
    };
  };

  this.SHINGLE_COUNTER_FLASHING = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const chimney = building.chimney === 0 ? 0 : 2 + building.chimney * 2;

    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: chimney * wasterFactor,
      orderQty: (chimney / productSize) * wasterFactor
    };
  };

  this.SHINGLE_VALLEY_FLASHING = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const valleyLength = building.valleyLength || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: valleyLength * wasterFactor,
      orderQty: (valleyLength / productSize) * wasterFactor
    };
  };

  this.SHINGLE_LABOR = ({ building, productItem }) => {
    const shingleSection =
      building.productData.find(item => item.sectionId === "shingles") || {};

    const subTotal = (shingleSection.productList || []).reduce((pre, curr) => {
      return pre + curr.orderQty;
    }, 0);

    const total = subTotal / 3;

    return {
      actualQty: total,
      orderQty: total
    };
  };

  /**
   * DONE
   */
  this.TPO_FIELD_MEMBRANE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    // Convert roof area from SQ to sqft
    const totalSquare =
      productItem.uom.toLowerCase() === "sqft"
        ? (building.fieldArea + building.wallArea) * 100
        : building.fieldArea + building.wallArea;
    const unitSize = productItem.unitSize || 1;

    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: totalSquare * wasterFactor,
      orderQty: (totalSquare / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.TPO_FASTENERS = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    switch (building.fasteningPattern) {
      case "oc-06":
        return {
          actualQty: building.fieldArea * 34 * wasterFactor,
          orderQty: ((building.fieldArea * 34) / productSize) * wasterFactor
        };

      case "oc-12":
        return {
          actualQty: building.fieldArea * 17 * wasterFactor,
          orderQty: ((building.fieldArea * 17) / productSize) * wasterFactor
        };

      default:
        return { actualQty: 0, orderQty: 0 };
    }
  };

  /**
   * DONE
   */
  this.TPO_PLATES = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    switch (building.fasteningPattern) {
      case "oc-06":
        return {
          actualQty: building.fieldArea * 34 * wasterFactor,
          orderQty: ((building.fieldArea * 34) / productSize) * wasterFactor
        };

      case "oc-12":
        return {
          actualQty: building.fieldArea * 17 * wasterFactor,
          orderQty: ((building.fieldArea * 17) / productSize) * wasterFactor
        };

      default:
        return { actualQty: 0, orderQty: 0 };
    }
  };

  /**
   * DONE
   */
  this.TPO_BONDING_ADHESIVE = ({
    building,
    productItem,
    membraneThickness,
    membraneAttachmentType
  }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    let totalSquare = building.wallArea;
    if (
      membraneThickness &&
      membraneThickness.thickness <= 80 &&
      membraneAttachmentType.value === ATTACHMENT_TYPE_TPO_MEMBRANE_FA
    ) {
      totalSquare += building.fieldArea;
    }
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    // return totalSquare * (100 / 60 / productSize) * wasterFactor;
    return {
      actualQty: totalSquare * (100 / 60) * wasterFactor,
      orderQty: totalSquare * (100 / 60 / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_MEMBRANE = ({ building, productItem }) => {
    const unitSize = productItem.unitSize || 1;

    const productSize = unitSize * (productItem.unitPack || 1);

    const wasterFactor = productItem.wasterFactor || 1.0;

    // Convert roof area from SQ to sqft
    const fieldArea =
      productItem.uom.toLowerCase() === "sqft"
        ? building.fieldArea * 100
        : building.fieldArea;

    return {
      actualQty: fieldArea * wasterFactor,
      orderQty: (fieldArea / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_BONDING_ADHESIVE = ({
    building,
    productItem,
    insulationLayers
  }) => {
    let orderQty = 0;
    let actualQty = 0;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    const wasterFactor = productItem.wasterFactor || 1.0;
    for (const insulation of insulationLayers) {
      if (
        ATTACHMENT_TYPE_TPO_FA_LIST.includes(
          insulation.insulationAttachmentType.value
        )
      ) {
        const coefficient =
          25 / (12 / (3 * parseInt(insulation.coverageRate / 3)));
        orderQty += coefficient
          ? building.fieldArea / (coefficient / productSize)
          : 0;
        actualQty += coefficient ? building.fieldArea / coefficient : 0;
      }
    }

    // return qty * wasterFactor;
    return {
      actualQty: actualQty * wasterFactor,
      orderQty: orderQty * wasterFactor
    };
  };

  this.TPO_INSULATION_BONDING_ADHESIVE_PART_A = this.TPO_INSULATION_BONDING_ADHESIVE_PART_B = ({
    building,
    productItem,
    insulationLayers
  }) => {
    let orderQty = 0;
    let actualQty = 0;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    const wasterFactor = productItem.wasterFactor || 1.0;
    for (const insulation of insulationLayers) {
      if (
        ATTACHMENT_TYPE_TPO_FA_LIST.includes(
          insulation.insulationAttachmentType.value
        )
      ) {
        let cornerWidth = building.over35FeelTall === true ? 8 : 4;
        const cornerCoefficient =
          2.5 / (12 / parseInt(insulation.cornerCoverageRate));
        const cornerArea =
          ((building.corner || 0) * cornerWidth * cornerWidth) / 100; //Convert sqft to SQ;
        const cornerAreaQty = cornerArea / cornerCoefficient / 2; //we use both tank A and tank B

        const totalPerimeterArea =
          ((building.perimeter || 0) * cornerWidth) / 100 - cornerArea; //Convert sqft to SQ;

        const perimeterCoefficient =
          2.5 / (12 / parseInt(insulation.perimeterCoverageRate));
        const perimeterArea = totalPerimeterArea - cornerArea; //SQ;
        const perimeterAreaQty = perimeterArea / perimeterCoefficient / 2; //we use both tank A and tank B;

        const fieldCoefficient = 2.5 / (12 / parseInt(insulation.coverageRate));
        const insideFieldArea = (building.fieldArea || 0) - totalPerimeterArea;
        const insideFieldAreaQty = insideFieldArea / fieldCoefficient / 2; //we use both tank A and tank B;

        const totalQty = insideFieldAreaQty + perimeterAreaQty + cornerAreaQty;

        orderQty += totalQty / productSize;
        actualQty += totalQty;
      }
    }

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: orderQty * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_FASTENERS = ({
    building,
    productItem,
    insulationLayers
  }) => {
    let qty = 0;

    // get insulation membrane board
    const section = (building.productData || []).find(
      item => item.sectionId === SECTION_TYPE_TPO_INSULATION
    );
    if (_.isEmpty(section)) {
      return 0;
    }
    const insulationMembrane = (section.productList || []).find(item =>
      (item.productHashtag || "").toString().startsWith("ins-")
    );

    if (_.isEmpty(insulationMembrane)) {
      return 0;
    }

    for (const insulation of insulationLayers) {
      if (
        ATTACHMENT_TYPE_TPO_MA_LIST.includes(
          insulation.insulationAttachmentType.value
        )
      ) {
        const coefficient = 3.114;
        const screwsPerSquares = coefficient * insulation.screwsPerBoard;
        qty += screwsPerSquares * building.fieldArea;
      }
    }

    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    const wasterFactor = productItem.wasterFactor || 1.0;

    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty * wasterFactor) / productSize,
      originQty: qty
    };
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_PLATES = ({
    building,
    productItem,
    insulationLayers
  }) => {
    let qty = 0;

    // get insulation membrane board
    const section = (building.productData || []).find(
      item => item.sectionId === SECTION_TYPE_TPO_INSULATION
    );
    if (_.isEmpty(section)) {
      return 0;
    }
    const insulationMembrane = (section.productList || []).find(item =>
      (item.productHashtag || "").toString().startsWith("ins-")
    );

    if (_.isEmpty(insulationMembrane)) {
      return 0;
    }

    for (const insulation of insulationLayers) {
      if (
        ATTACHMENT_TYPE_TPO_MA_LIST.includes(
          insulation.insulationAttachmentType.value
        )
      ) {
        const coefficient = 3.114;
        const screwsPerSquares = coefficient * insulation.screwsPerBoard;
        qty += screwsPerSquares * building.fieldArea;
      }
    }

    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    const wasterFactor = productItem.wasterFactor || 1.0;

    // return (qty * wasterFactor) / productSize;
    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty * wasterFactor) / productSize
    };
  };

  this.TPO_NON_REINFORCED = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    const fieldArea = building.fieldArea ? building.fieldArea / 200.0 : 0;

    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: fieldArea * wasterFactor,
      orderQty: (fieldArea / productSize) * wasterFactor
    };
  };

  // TPO_FANFOLD: ({ building, productItem }) => building.fieldArea * 1.1,
  // TPO_POLYISO: ({ building, productItem }) => building.fieldArea * 1.1,
  // TPO_COVERBOARD: ({ building, productItem }) => building.fieldArea * 1.1,

  /**
   * DONE
   */
  this.TPO_TAPERED_INSULATION = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    // Convert tapered area from sqft to SQ
    const totalSquare =
      productItem.uom.toLowerCase() === "sqft"
        ? building.taperedArea * 100
        : building.taperedArea;
    const unitSize = productItem.unitSize || 1;

    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: totalSquare * wasterFactor,
      orderQty: (totalSquare / productSize) * wasterFactor
    };
  };

  // this.TPO_POURABLE_SEALER = ({ building, productItem }) => {
  //   let item = findItemBySubcategory(building.data, "Pitch Pans");
  //   return item ? parseFloat(item.QTY) / 2 : 0;
  // };
  this.TPO_CUT_EDGE_SEALANT = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const totalSquare = (building.fieldArea || 0) + (building.wallArea || 0);
    const qty = totalSquare / 1.25;
    const unitSize = productItem.unitSize || 1;

    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  this.TPO_MULTIPURPOSE_SEALANT = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const totalSquare = (building.fieldArea || 0) + (building.wallArea || 0);
    const qty = totalSquare / 0.5;
    const unitSize = productItem.unitSize || 1;

    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  this.TPO_WATER_CUT_OFF = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const qty =
      (building.numberOfDrain || 0) / 2 +
      (building.numberOfScuppers || 0) / 2 +
      (building.terminationBar || 0) / 30;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  this.TPO_PIPE_BOOTS = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const qty = building.numberOfPlumbingPenetrations || 0;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  this.TPO_DRILL_BITS = ({ building, productItem, insulationLayers }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const { originQty } = this.TPO_INSULATION_FASTENERS({
      building,
      productItem,
      insulationLayers
    });

    const qty = originQty / 100;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  this.TPO_MEMBRANE_CLEANER = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const fieldArea = building.fieldArea || 0;
    const qty = fieldArea / 24;
    const unitSize = productItem.unitSize || 1;

    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  // this.TPO_SHEET_METAL_FASTENERS = () => 0; // TODO: ???? coping

  // Standing Seam

  /**
   * DONE
   */
  this.STANDING_SEAM_PANELS = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const panelLinear = building.panelLinear || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);
    return {
      actualQty: panelLinear * wasterFactor,
      orderQty: (panelLinear / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.STANDING_SEAM_RAKE_AND_EAVA_TRIM = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const perimeter = building.perimeter || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: perimeter * wasterFactor,
      orderQty: (perimeter / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.STANDING_SEAM_RIDGE_TRIM = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const length = (building.ridgeLength || 0) + (building.hipLength || 0);
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: length * wasterFactor,
      orderQty: (length / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.STANDING_SEAM_HIP_TRIM = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const hipLength = building.hipLength || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: hipLength * wasterFactor,
      orderQty: (hipLength / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.STANDING_SEAM_SIDE_WALL_FLASHING = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const sideWall = building.sideWall || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: sideWall * wasterFactor,
      orderQty: (sideWall / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.STANDING_SEAM_ZEE_CLOSURE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const length =
      (building.hipLength || 0) * 2 +
      ((building.headWall || 0) + (building.sideWall || 0));
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: length * wasterFactor,
      orderQty: (length / productSize) * wasterFactor
    };
  };

  /**
   * DONE
   */
  this.STANDING_SEAM_PERFORATED_ZEE_CLOUSURE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const length = (building.ridgeLength || 0) * 2;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: length * wasterFactor,
      orderQty: (length / productSize) * wasterFactor
    };
  };

  this.STANDING_SEAM_VALLEY_METAL = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const valleyLength = building.valleyLength || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: valleyLength * wasterFactor,
      orderQty: (valleyLength / productSize) * wasterFactor
    };
  };

  this.STANDING_SEAM_HIGH_TEMP_ICE_AND_WATER = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const fieldArea = building.fieldArea || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: fieldArea * wasterFactor,
      orderQty: (fieldArea / productSize) * wasterFactor
    };
  };

  this.STANDING_SEAM_BUTYL_SEALING_TAPE = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;

    const ZEE_CLOSURE =
      (building.hipLength || 0) * 2 +
      ((building.headWall || 0) + (building.sideWall || 0));

    const PERFORATED_ZEE_CLOUSURE = (building.ridgeLength || 0) * 2;

    const length = ZEE_CLOSURE + PERFORATED_ZEE_CLOUSURE;

    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: length * wasterFactor,
      orderQty: (length / productSize) * wasterFactor
    };
  };

  this.STANDING_SEAM_PANCAKE_FASTENERS = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const length = (building.panelLinear || 0) + (building.perimeter || 0);
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    return {
      actualQty: length * wasterFactor,
      orderQty: (length / productSize) * wasterFactor
    };
  };

  this.MODBIT_FASTENERS = ({ building, productItem }) => {
    let item = findItemBySubcategory(building.data, "Top Sheet");
    return item ? parseFloat(item.QTY) : 0;
  };
  this.MODBIT_FANFOLD = ({ building, productItem }) => {
    let item = findItemBySubcategory(building.data, "Coverboard");
    return item ? parseFloat(item.QTY) : 0;
  };
  this.MODBIT_COVERBOARD = ({ building, productItem }) => {
    let item = findItemBySubcategory(building.data, "Coverboard");
    return item ? parseFloat(item.QTY) : 0;
  };
  this.MODBIT_POLYISO = ({ building, productItem }) => {
    let item = findItemBySubcategory(building.data, "Coverboard");
    return item ? parseFloat(item.QTY) : 0;
  };
  this.MODBIT_INSULATION_PLATES = ({ building, productItem }) => {
    let item = findItemBySubcategory(building.data, "Insulation Fasteners");
    return item ? parseFloat(item.QTY) : 0;
  };

  this.SKYLIGHT = ({ building, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const qty = building.numberOfSkylight || 0;
    const productSize = (productItem.unitSize || 1) * (productItem.unitPack || 1);
    return {
      actualQty: qty * wasterFactor,
      orderQty: (qty / productSize) * wasterFactor
    };
  };

  // --- not calculate
  this.NO_METHOD = () => {
    return { actualQty: 0, orderQty: 0 };
  };
})();

export const productItemHashFunction = new (function() {
  this.sortBestPrice = (a, b) => a.price - b.price;
  this.getBestPriceProductItem = ({
    category,
    subCategory,
    allProductsOfPriceList
  }) => {
    return (
      allProductsOfPriceList.filter(
        prod =>
          prod.categoryId === category.id &&
          prod.subCategoryId === subCategory.id
      ) || []
    ).sort(this.sortBestPrice)[0];
  };

  /**
   * DONE
   */
  this.TPO_MEMBRANE = ({
    category,
    subCategory,
    membraneThickness,
    allProductsOfPriceList
  }) =>
    // Get best price product item with right hashtag
    {
      const bestProduct = (
        allProductsOfPriceList.filter(prod => {
          const hashtagList =
            (prod.productHashtag || "").toString().split(",") || [];
          return (
            prod.categoryId === category.id &&
            prod.subCategoryId === subCategory.id &&
            hashtagList.includes(membraneThickness.productHashtag)
          );
        }) || []
      ).sort(this.sortBestPrice)[0];

      return bestProduct ? [bestProduct] : [];
    };

  /**
   * DONE
   */
  this.TPO_INSULATION_MEMBRANE = ({
    category,
    subCategory,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let bestProducts = [];
    for (const layer of insulationLayers) {
      // find main insulation membrane of layer
      let insulationMembrane = (
        allProductsOfPriceList.filter(prod => {
          const hashtagList =
            (prod.productHashtag || "").toString().split(",") || [];
          const thickness = hashtagList.length > 1 ? hashtagList[1] : null;
          return (
            prod.categoryId === category.id &&
            prod.subCategoryId === subCategory.id &&
            hashtagList.includes(
              (layer.insulationThickness || {}).productHashtag
            ) &&
            (thickness == (layer.insulationThickness || {}).thickness ||
              !(layer.insulationThickness || {}).thickness)
          );
        }) || []
      ).sort(this.sortBestPrice)[0];

      // If does not have any product match with insulation theckness => get the thicker product
      if (_.isEmpty(insulationMembrane)) {
        insulationMembrane = (
          allProductsOfPriceList.filter(prod => {
            const hashtagList =
              (prod.productHashtag || "").toString().split(",") || [];
            const thickness = hashtagList.length > 1 ? hashtagList[1] : null;
            return (
              prod.categoryId === category.id &&
              prod.subCategoryId === subCategory.id &&
              hashtagList.includes(
                (layer.insulationThickness || {}).productHashtag
              ) &&
              (thickness > (layer.insulationThickness || {}).thickness ||
                !(layer.insulationThickness || {}).thickness)
            );
          }) || []
        ).sort(this.sortBestPrice)[0];
      }

      if (!_.isEmpty(insulationMembrane)) {
        bestProducts.push(insulationMembrane);
      }
    }

    return bestProducts;
  };

  /**
   * DONE
   */
  this.TPO_LOW_RISE_FOAM = ({
    category,
    subCategory,
    building,
    membraneThickness,
    membraneAttachmentType,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;
    // Field will depend on the thickness
    // - if the thickness is smaller than or equal to 80, then we will use "Bonding Adhesive"
    // - else we will use "Low Rise Foam"
    if (
      membraneAttachmentType.value === ATTACHMENT_TYPE_TPO_MEMBRANE_FA &&
      building.fieldArea > 0 &&
      membraneThickness.thickness > 80
    ) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }
    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_BONDING_ADHESIVE = ({
    category,
    subCategory,
    building,
    // insulationLayers,
    membraneThickness,
    membraneAttachmentType,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    // Field will depend on the thickness
    // - if the thickness is smaller than or equal to 80, then we will use "Bonding Adhesive"
    // - else we will use "Low Rise Foam"
    // Wall is always using Bonding Adhesive
    if (
      (membraneAttachmentType.value === ATTACHMENT_TYPE_TPO_MEMBRANE_FA &&
        building.fieldArea > 0 &&
        membraneThickness.thickness <= 80) ||
      building.wallArea > 0
    ) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_FASTENERS = ({
    category,
    subCategory,
    building,
    roofThickness,
    // substrate,
    substrateThickness,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let allThickness = roofThickness.thickness || 0;
    allThickness += substrateThickness || 0;
    allThickness += insulationLayers.reduce((acc, currentItem) => {
      return (acc += currentItem.insulationThickness.thickness);
    }, 0);
    allThickness += 0.75; // from 3/4

    let bestProduct = null;
    if (building.membraneAttached === ATTACHMENT_TYPE_TPO_MEMBRANE_MA) {
      bestProduct = (
        allProductsOfPriceList.filter(prod => {
          const hashtagList =
            (prod.productHashtag || "").toString().split(",") || [];
          if (_.isEmpty(hashtagList)) {
            return [];
          }
          const fastenerThickness = parseFloat(hashtagList[0]) || 0;

          return (
            prod.categoryId === category.id &&
            prod.subCategoryId === subCategory.id &&
            fastenerThickness >= allThickness
          );
        }) || []
      ).sort(this.sortBestPrice)[0];
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_PLATES = ({
    category,
    subCategory,
    building,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (building.membraneAttached === ATTACHMENT_TYPE_TPO_MEMBRANE_MA) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_BONDING_ADHESIVE = ({
    building,
    category,
    subCategory,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    // Check for this layer using Fully Adhered (FA)?
    // if using FA we need to check existing Bonding Adhesive product item
    // if not exist => add Bonding Adhesive to product list
    if (!bestProduct) {
      for (const layer of insulationLayers) {
        if (
          layer.insulationAttachmentType &&
          ATTACHMENT_TYPE_TPO_FA_LIST.includes(
            layer.insulationAttachmentType.value
          ) &&
          building.substrate != "tpo-structural-concrete"
        ) {
          bestProduct = this.getBestPriceProductItem({
            category,
            subCategory,
            allProductsOfPriceList
          });
          break;
        }
      }
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_INSULATION_BONDING_ADHESIVE_PART_A = ({
    building,
    category,
    subCategory,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    // Check for this layer using Fully Adhered (FA)?
    // if using FA we need to check existing Bonding Adhesive product item
    // if not exist => add Bonding Adhesive to product list
    if (!bestProduct) {
      for (const layer of insulationLayers) {
        if (
          layer.insulationAttachmentType &&
          ATTACHMENT_TYPE_TPO_FA_LIST.includes(
            layer.insulationAttachmentType.value
          ) &&
          building.substrate === "tpo-structural-concrete"
        ) {
          bestProduct = this.getBestPriceProductItem({
            category,
            subCategory,
            allProductsOfPriceList
          });
          break;
        }
      }
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_INSULATION_BONDING_ADHESIVE_PART_B = ({
    building,
    category,
    subCategory,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    // Check for this layer using Fully Adhered (FA)?
    // if using FA we need to check existing Bonding Adhesive product item
    // if not exist => add Bonding Adhesive to product list
    if (!bestProduct) {
      for (const layer of insulationLayers) {
        if (
          layer.insulationAttachmentType &&
          ATTACHMENT_TYPE_TPO_FA_LIST.includes(
            layer.insulationAttachmentType.value
          ) &&
          building.substrate === "tpo-structural-concrete"
        ) {
          bestProduct = this.getBestPriceProductItem({
            category,
            subCategory,
            allProductsOfPriceList
          });
          break;
        }
      }
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_FASTENERS = ({
    category,
    subCategory,
    building,
    roofThickness,
    substrate,
    substrateThickness,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let allThickness = roofThickness.thickness || 0;
    allThickness += substrateThickness || 0;
    allThickness += insulationLayers.reduce((acc, currentItem) => {
      return (acc += currentItem.insulationThickness.thickness);
    }, 0);
    allThickness += 0.75; // from 3/4

    const isUseFastener = insulationLayers.reduce((acc, currentItem) => {
      return (acc =
        acc ||
        ATTACHMENT_TYPE_TPO_MA_LIST.includes(
          currentItem.insulationAttachmentType.value
        ));
    }, false);

    let bestProduct = null;
    if (building.numberInsulationLayers > 0 && isUseFastener) {
      bestProduct = (
        allProductsOfPriceList.filter(prod => {
          const hashtagList =
            (prod.productHashtag || "").toString().split(",") || [];
          if (_.isEmpty(hashtagList)) {
            return [];
          }

          if (substrate.value === "tpo-structural-concrete") {
            const hashtag = hashtagList[0] || "";

            return (
              prod.categoryId === category.id &&
              prod.subCategoryId === subCategory.id &&
              hashtag === "cd-10"
            );
          }

          const fastenerThickness = parseFloat(hashtagList[0]) || 0;

          return (
            prod.categoryId === category.id &&
            prod.subCategoryId === subCategory.id &&
            fastenerThickness >= allThickness
          );
        }) || []
      ).sort(this.sortBestPrice)[0];
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_INSULATION_PLATES = ({
    category,
    subCategory,
    building,
    insulationLayers,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;
    const isUseFastener = insulationLayers.reduce((acc, currentItem) => {
      return (acc =
        acc ||
        ATTACHMENT_TYPE_TPO_MA_LIST.includes(
          currentItem.insulationAttachmentType.value
        ));
    }, false);

    if (building.numberInsulationLayers > 0 && isUseFastener) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_TAPERED_INSULATION = ({
    category,
    subCategory,
    building,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (building.taperedArea > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  /**
   * DONE
   */
  this.TPO_FLUTE_FILL = ({
    category,
    subCategory,
    building,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (building.fluteFill === true) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_NON_REINFORCED = ({
    category,
    subCategory,
    building,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (building.fieldArea > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_MEMBRANE_CLEANER = this.TPO_NON_REINFORCED;

  this.TPO_CUT_EDGE_SEALANT = ({
    category,
    subCategory,
    building,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (building.fieldArea + building.wallArea > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_MULTIPURPOSE_SEALANT = this.TPO_CUT_EDGE_SEALANT;

  //Water Cut-Off
  this.TPO_WATER_CUT_OFF = ({
    category,
    subCategory,
    building,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;
    if (
      building.numberOfDrain > 0 ||
      building.numberOfScuppers > 0 ||
      building.terminationBar > 0
    ) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }
    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_PIPE_BOOTS = ({
    category,
    subCategory,
    building,

    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (building.numberOfPlumbingPenetrations > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.TPO_DRILL_BITS = ({
    category,
    subCategory,
    // building,
    substrate,
    allProductsOfPriceList
  }) => {
    let bestProduct = null;

    if (substrate.value === "tpo-structural-concrete") {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_FIELD_AREA = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.fieldArea > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_HIP_AND_RIDGE = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if ((building.hipLength || 0) + (building.ridgeLength || 0) > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_PERIMETER = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.perimeter > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_VALLEY = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.valleyLength > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_RIDGE_VENT = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.ridgeVent === true) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_SIDEWALL = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.sideWall > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_HEADWALL = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.headWall > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SHINGLE_CALCULATION_BY_CHIMNEY = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.chimney > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_PANELS = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.panelLinear > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_RAKE_AND_EAVA_TRIM = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.perimeter > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_RIDGE_TRIM = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.ridgeLength + building.hipLength > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_HIP_TRIM = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.hipLength > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_SIDE_WALL_FLASHING = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;
    if (building.sideWall > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_ZEE_CLOSURE = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (
      (building.hipLength || 0) * 2 +
        ((building.headWall || 0) + (building.sideWall || 0)) >
      0
    ) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_PERFORATED_ZEE_CLOUSURE = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.ridgeLength > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_VALLEY_METAL = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.valleyLength > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_HIGH_TEMP_ICE_AND_WATER = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.fieldArea > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_PANCAKE_FASTENERS = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.panelLinear + building.perimeter > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.STANDING_SEAM_BUTYL_SEALING_TAPE = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.fieldArea > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SKYLIGHT = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.numberOfSkylight > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.SKYLIGHT_LABOR = ({
    category,
    subCategory,
    allProductsOfPriceList,
    building
  }) => {
    let bestProduct = null;

    if (building.numberOfSkylight > 0) {
      bestProduct = this.getBestPriceProductItem({
        category,
        subCategory,
        allProductsOfPriceList
      });
    }

    return bestProduct ? [bestProduct] : [];
  };

  this.COMMON_CALCULATION = ({
    category,
    subCategory,
    allProductsOfPriceList
  }) => {
    const bestProduct = this.getBestPriceProductItem({
      category,
      subCategory,
      allProductsOfPriceList
    });

    return bestProduct ? [bestProduct] : [];
  };
})();

export const quantityCalculator = ({
  building,
  productItem,
  subCategory,
  membraneThickness,
  membraneAttachmentType,
  insulationLayers
}) => {
  const subCategoryFormat =
    subCategory.measureFunctionMap || CALCULATION_NO_METHOD;
  if (subCategoryFormat === CALCULATION_NO_METHOD) {
    return {
      actualQty: productItem.actualQty || 0,
      orderQty: productItem.orderQty || 0
    };
  }
  const { actualQty, orderQty } = measureHashFunction[subCategoryFormat]
    ? measureHashFunction[subCategoryFormat]({
        building,
        productItem,
        membraneThickness,
        membraneAttachmentType,
        insulationLayers
      })
    : { actualQty: 0, orderQty: 0 };

  return {
    actualQty: roundUpNumber(2)(actualQty),
    orderQty: roundUpNumber(0)(orderQty)
  };
  // const productSize = productItem.size || 1;

  // const wasterFactor = productItem.wasterFactor || 1.0;
  // return roundUpNumber(0)((measure * wasterFactor) / productSize);
};

export const metalQuantityCalculator = ({
  subCategory,
  assembly,
  productItem
}) => {
  const subCategoryFormat =
    subCategory.measureFunctionMap || CALCULATION_NO_METHOD;

  if (subCategoryFormat === CALCULATION_NO_METHOD) {
    return {
      actualQty: productItem.actualQty || 0,
      orderQty: productItem.orderQty || 0
    };
  }

  const func = metalMeasureHashFunction[subCategoryFormat];

  if (!func) {
    return {
      actualQty: productItem.actualQty || 0,
      orderQty: productItem.orderQty || 0
    };
  }

  const { actualQty, orderQty } = func({
    assembly,
    productItem
  });

  return {
    actualQty: roundUpNumber(2)(actualQty || 0),
    orderQty: roundUpNumber(0)(orderQty || 0)
  };
};

export const metalProductItemHashFunction = new (function() {
  this.sortBestPrice = (a, b) => a.price - b.price;

  this.calculateAssemblyMetal = (assembly, product) => {
    let splited = (assembly.colorAndManufacturer || "").split(",");
    let color = splited[0];
    let manufacturer = splited[1];
    return (
      !_.isEmpty(product.productHashtag) &&
      product.productHashtag === assembly.assemblyGA &&
      (_.isEmpty(color) || color.includes(product.color.trim())) &&
      (_.isEmpty(manufacturer) ||
        manufacturer.includes(product.manufacturer.trim()))
    );
  };

  this.calculateAssemblyMetalCleat = (assembly, product) => {
    let splited = (assembly.colorAndManufacturer || "").split(",");
    let color = splited[0];
    let manufacturer = splited[1];
    return (
      !_.isEmpty(product.productHashtag) &&
      product.productHashtag === assembly.assemblyCleatGA &&
      (_.isEmpty(color) || color.includes(product.color.trim())) &&
      (_.isEmpty(manufacturer) ||
        manufacturer.includes(product.manufacturer.trim()))
    );
  };

  this.calculateAssemblyMetalCoil = (assembly, product) => {
    let splited = (assembly.colorAndManufacturer || "").split(",");
    let color = splited[0];
    let manufacturer = splited[1];
    return (
      !_.isEmpty(product.productHashtag) &&
      product.productHashtag === `${assembly.assemblyGA}-coil` &&
      (_.isEmpty(color) || color.includes(product.color.trim())) &&
      (_.isEmpty(manufacturer) ||
        manufacturer.includes(product.manufacturer.trim()))
    );
  };

  this.calculateAssemblyAccessories = (assembly, product, hashtagList) => {
    for (const hashtag of hashtagList) {
      if (
        (product.productHashtag || "").toString().includes(hashtag) &&
        (hashtag === "fabrication-labor-coping" ||
          hashtag === "fabrication-labor-cleat" ||
          this.checkFasteningSubstrate(
            product.productHashtag,
            assembly.fasteningSubstrates
          ))
      ) {
        return true;
      }
    }
    return false;
  };

  this.checkFasteningSubstrate = (productHashtag, fasteningSubstrates) => {
    if (_.isEmpty(fasteningSubstrates)) {
      return true;
    }
    for (const fasteningSubstrate of fasteningSubstrates) {
      if ((productHashtag || "").toString().includes(fasteningSubstrate)) {
        return true;
      }
    }

    return false;
  };

  this.copingWithCleat = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = [
      "fabrication-labor-coping",
      "fabrication-labor-cleat",
      "1-1/2-hand-nails-roofing",
      "3-sharp-point-long-life-fasteners",
      "ss-rivets",
      "30-drill-bit"
    ];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );
    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    const cleat = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetalCleat(assembly, product)
    );
    if (!_.isEmpty(cleat)) {
      result.push(cleat);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };
  this.copingWithFrontBackCleat = this.copingWithCleat;
  this.copingWithCleatFastener = this.copingWithCleat;

  this.counterFlashing = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = [
      "fabrication-labor-coping",
      "panhead-screws",
      "stich-screws",
      "ss-rivets",
      "30-drill-bit"
    ];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );

    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };

  this.gutterSystem = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = [
      "fabrication-labor-coping",
      "3-sharp-point-long-life-fasteners",
      "stich-screws",
      "1-1/2-hand-nails-roofing",
      "straps",
      "ss-rivets",
      "30-drill-bit"
    ];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );

    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };

  this.boxDownspout = this.gutterSystem;

  this.valley = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = ["fabrication-labor-coping", "caulk"];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );

    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };

  this.sidewallOrHeadwall = this.valley;
  this.ridge = this.valley;
  this.zeeTrim = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = [
      "fabrication-labor-coping",
      "butyl-tape",
      "clip-screw"
    ];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );

    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };

  this.extendedEaveOrRake = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = [
      "fabrication-labor-coping",
      "fabrication-labor-cleat",
      "clip-screw",
      "caulk"
    ];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );

    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    const cleat = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetalCleat(assembly, product)
    );
    if (!_.isEmpty(cleat)) {
      result.push(cleat);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };

  this.collectorHead = ({
    category,
    // subCategory,
    building,
    assembly,
    allProductsOfPriceList
  }) => {
    const hashtagList = [
      "fabrication-labor-coping",
      "1-1/2-hand-nails-roofing",
      "ss-rivets",
      "30-drill-bit"
    ];
    const result = [];

    const metal = allProductsOfPriceList.find(product =>
      this.calculateAssemblyMetal(assembly, product)
    );

    if (!_.isEmpty(metal)) {
      result.push(metal);
    }

    result.push(
      ...allProductsOfPriceList.filter(product =>
        this.calculateAssemblyAccessories(assembly, product, hashtagList)
      )
    );

    return result;
  };

  this.downspoutTransition = this.collectorHead;
  this.pitchPan = this.collectorHead;

  this.COMMON_CALCULATION = ({
    category,
    subCategory,
    allProductsOfPriceList
  }) => {
    return [];
  };
})();

const metalMeasureHashFunction = new (function() {
  this.ASSEMBLY_METAL = ({ assembly, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const stretchOut = (assembly.stretchOut || 0) / 12; //convert inches to foot
    const assemblyLength = assembly.length || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    let actualQty = 0;
    // dimension = WxLxH (RxDxC)
    if (productItem.dimension && stretchOut > 0) {
      const dimensionList = productItem.dimension.split("x").map(item => {
        return parseFloat(item.trim());
      });

      const width = dimensionList[0];
      const factor = Math.floor(width / stretchOut);
      actualQty = assemblyLength / factor;
    }

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: (actualQty / productSize) * wasterFactor
    };
  };

  this.calculateMetalCleat = ({ stretchOut, assemblyLength, width }) => {
    const factor = Math.floor(width / stretchOut);
    return assemblyLength / factor;
  };

  this.ASSEMBLY_METAL_CLEAT = ({ assembly, productItem }) => {
    const cleatStretchOut = (assembly.cleatStretchOut || 0) / 12; //convert inches to foot
    const backCleatStretchOut = (assembly.backCleatStretchOut || 0) / 12; //convert inches to foot

    const wasterFactor = productItem.wasterFactor || 1.0;
    const assemblyLength = assembly.length || 0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);

    let actualQty = 0;
    // dimension = WxLxH (RxDxC)
    if (productItem.dimension) {
      const dimensionList = productItem.dimension.split("x").map(item => {
        return parseFloat(item.trim());
      });

      const width = dimensionList[0];

      if (
        Math.floor(width / cleatStretchOut) === 1 &&
        Math.floor(width / (cleatStretchOut + backCleatStretchOut)) === 1
      ) {
        // Match 2 cleats into one sheet
        actualQty = this.calculateMetalCleat({
          stretchOut: cleatStretchOut + backCleatStretchOut,
          assemblyLength,
          width
        });
      } else {
        // One cleat per sheet
        actualQty += this.calculateMetalCleat({
          stretchOut: cleatStretchOut,
          assemblyLength,
          width
        });

        actualQty += this.calculateMetalCleat({
          stretchOut: backCleatStretchOut,
          assemblyLength,
          width
        });
      }
    }

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: (actualQty / productSize) * wasterFactor
    };
  };

  this.ASSEMBLY_LABOR = ({ assembly, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);
    const per100lf = (assembly.numberOfBend || 0) * 10;
    const length = assembly.length || 0;

    const actualQty = (length / 100) * per100lf;

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: (actualQty / productSize) * wasterFactor
    };
  };

  this.ASSEMBLY_LABOR_CLEAT = ({ assembly, productItem }) => {
    let cleatNumberOfBend = 0;
    if (assembly.numberOfCleat > 1) {
      cleatNumberOfBend = assembly.cleatNumberOfBend.reduce(
        (previous, current) => {
          return previous + current;
        },
        0
      );
    } else {
      cleatNumberOfBend = assembly.cleatNumberOfBend || 0;
    }

    const wasterFactor = productItem.wasterFactor || 1.0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);
    const per100lf = cleatNumberOfBend * 10;

    const length = assembly.length || 0;

    const actualQty = (length / 100) * per100lf;

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: (actualQty / productSize) * wasterFactor
    };
  };

  this.ASSEMBLY_ACCESSORIES = ({ assembly, productItem }) => {
    const wasterFactor = productItem.wasterFactor || 1.0;
    const unitSize = productItem.unitSize || 1;
    const productSize = unitSize * (productItem.unitPack || 1);
    const per100lf = productItem.per100lf || 0;
    const length = assembly.length || 0;

    const actualQty = (length / 100) * per100lf;

    return {
      actualQty: actualQty * wasterFactor,
      orderQty: (actualQty / productSize) * wasterFactor
    };
  };

  // --- not calculate
  this.NO_METHOD = () => {
    return { actualQty: 0, orderQty: 0 };
  };
})();

export const getWorkType = project => {
  let workType = "";
  switch (project.jobType) {
    case JOB_TYPE_NEW:
      workType = WORK_TYPE_NEW;
      break;

    case JOB_TYPE_RECOVER:
      workType = WORK_TYPE_RECOVER;
      break;

    case JOB_TYPE_REPLACEMENT:
      workType = WORK_TYPE_TEAR_OFF;
      break;
  }
  return workType;
};

export const getBuildingInfoDefault = async project => {
  let slopeType = "";
  let roofType = "";
  let workType = "";
  const priceListActions = new FirebaseActions(
    `/system_client/${auth.currentUser.tenantId}/product_price_list`
  );

  let priceListItems = await priceListActions.getDocumentBys(_, [
    { prop: "isDefault", val: true, op: "==" },
    { prop: "status", val: "pl-active", op: "==" }
  ]);
  priceListItems = priceListItems.filter(r => {
    const compareDate = moment();
    const startDate = moment(r.startDate.toDate());
    if (r.endDate) {
      const endDate = moment(r.endDate.toDate());
      return compareDate.isBetween(startDate, endDate, "days", true);
    } else {
      return compareDate.isSameOrAfter(startDate, "days");
    }
  });

  const priceListId = ((priceListItems || [])[0] || {}).id || "";
  switch (project.businessCode) {
    case BUSINESS_CODE_COMMERCIAL:
      slopeType = SLOPE_TYPE_LOW;
      roofType = ROOF_TYPE_TPO;
      workType = WORK_TYPE_NEW;
      break;

    case BUSINESS_CODE_RESIDENTIAL:
      slopeType = SLOPE_TYPE_STEEP;
      roofType = ROOF_TYPE_SHINGLE;
      workType = WORK_TYPE_TEAR_OFF;
      break;

    case BUSINESS_CODE_SERVICE:
      slopeType = SLOPE_TYPE_STEEP;
      roofType = ROOF_TYPE_SHINGLE;
      break;
  }
  return {
    slopeType,
    roofType,
    workType,
    priceListId
  };
};
