<template>
  <f7-popup :opened="popupOpened" @popup:closed="
    popupOpened = false;
  isNew = false;
  " @popup:opened="handlePopupOpened">
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close </f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Vendor</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar disable-button-text placeholder="Search vendor" :clear-button="true" :value="searchValue"
        @input="searchValue = $event.target.value" @searchbar:disable="searchValue = ''"
        class="search-list-popup"></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>

      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-group v-for="(group, index) in dataVendorGroup" :key="index">
          <f7-list-item :title="group.key" group-title></f7-list-item>
          <f7-list-item no-chevron link v-for="vendor in group.data" :key="vendor.id" :title="vendor.companyName"
            @click.native="select(vendor.id)"></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>
    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Company</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Company Name -->
        <f7-list-input class="first-input" type="text" placeholder="Company name" clear-button
          :value="currentCompany.companyName" @input="set('companyName', $event.target.value.trim())"
          error-message-force :error-message="companyNameErrorMessage" @blur="v$.$touch()"></f7-list-input>
      </f7-list>

      <!-- account number -->
      <f7-list>
        <f7-list-input type="text" label="Account Number" placeholder="Your account number with this Vendor"
          clear-button :value="currentCompany.accountNumber" @input="set('accountNumber', $event.target.value.trim())">
        </f7-list-input>
      </f7-list>

      <!-- Phone -->
      <phone-input :id="currentCompany.id" ref="phoneInput" isRequired :value="currentCompany.phones"
        @input="setPhones($event)"></phone-input>

      <!-- Address -->
      <address-auto-complete-input :id="currentCompany.id" ref="addressInput" :value="currentCompany.addresses"
        @input="setAddresses($event)"></address-auto-complete-input>

      <!-- Other -->
      <other-input :id="currentCompany.id" ref="otherInput" :value="currentCompany.others"
        @input="setOthers($event)"></other-input>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import AddressAutoCompleteInput from "@/components/inputs/AddressAutoCompleteInput.vue";
import OtherInput from "@/components/inputs/OtherInput.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import _ from "lodash";
import Vue from "vue";

import { COMPANY_TYPE_VENDOR, VALIDATION_MESSAGE } from "@/utility/const";
import { applyFilter, groupBy } from "@/utility/filter-tools";

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  components: {
    PhoneInput,
    AddressAutoCompleteInput,
    OtherInput
  },
  data() {
    return {
      popupOpened: false,
      searchValue: "",
      isNew: false,
      currentCompany: {
        companyName: ""
      }
    };
  },

  computed: {
    ...mapGetters({
      vendors: "estimate/estimate-page/vendor/objectList",
      vendorGroup: "estimate/estimate-page/vendor/objectGroup",
      currentBuilding: "estimate/estimate-page/estimate/currentBuilding"
    }),
    ...mapGetters("estimate/estimate-page/product-default", ["productsByPL"]),

    vendorFilterOptions() {
      const dataVendorOptions = _.isEmpty(this.currentBuilding.vendorIds)
        ? this.vendors.filter(r =>
          (this.productsByPL.vendorIdFilterList || []).includes(r.id)
        )
        : this.vendors.filter(r =>
          this.currentBuilding.vendorIds.includes(r.id)
        );

      return dataVendorOptions;
    },

    dataVendorGroup() {
      const filteredObjects = applyFilter(
        this.vendorFilterOptions,
        this.searchValue,
        ["companyName"]
      );
      let dataGroup = groupBy(filteredObjects, item =>
        ((item.companyName || "").charAt(0) || "").toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map(i => ({
        ...i,
        data: i.data.sort((a, b) => sortBy(a.companyName, b.companyName))
      }));
    },

    companyNameErrorMessage() {
      if (!this.v$.currentCompany.companyName.$error) return null;
      if (this.v$.currentCompany.companyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD
      return null;
    }
  },

  methods: {
    ...mapActions({
      bindVendors: "estimate/estimate-page/vendor/bind",
      createVendor: "estimate/estimate-page/vendor/create"
    }),

    set(prop, value) {
      Vue.set(this.currentCompany, prop, value);
    },

    initData() {
      if (_.isEmpty(this.vendors)) {
        this.$f7.preloader.show();
        this.bindVendors().then(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    open() {
      this.initData();
      this.popupOpened = true;
    },

    select(vendorId) {
      this.$emit("onSelected", vendorId);
      this.popupOpened = false;
    },

    onAdd() {
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector(".first-input input").focus();
        }
      });
    },

    onAddCancel() {
      this.isNew = false;
      this.currentCompany = {};
      this.v$.$reset();
    },

    setPhones(val) {
      Vue.set(this.currentCompany, "phones", val);
    },

    setAddresses(val) {
      Vue.set(this.currentCompany, "addresses", val);
    },

    setOthers(val) {
      Vue.set(this.currentCompany, "others", val);
    },

    existsCompany() {
      const found = this.vendors.find(company => {
        return company.companyName === this.currentCompany.companyName;
      });

      if (!_.isEmpty(found)) {
        this.$ri.dialog.openWarningDialog({
          title: "Please choose another one",
          content: "That company has already existed!",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      }

      return !_.isEmpty(found);
    },

    create() {
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.otherInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.otherInput.v$.$invalid ||
        this.v$.$invalid ||
        this.existsCompany()
      )
        return;

      this.$f7.preloader.show();
      this.currentCompany.companyTypes = [COMPANY_TYPE_VENDOR];
      this.createVendor(this.currentCompany).then(id => {
        this.$emit("onSelected", id);
        this.popupOpened = false;
        this.onAddCancel();
        this.$f7.preloader.hide();
      });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentCompany: {
        companyName: {
          required
        }
      }
    };
  }
};
</script>
