<template>
  <f7-popup
    class="demo-popup"
    :opened="showPopup"
    @popup:close="closePopup()"
    @popup:closed="handlePopupClosed()"
  >
    <f7-page>
      <!-- Navigate bar -->
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close @click="showPopup = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Photo</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="upload-photo" @click="openUploadPhoto">
        <p class="click-upload">Click to upload</p>
        <i
          class="icon-no-data icon f7-icons"
          style="font-size: 80px; color: #ddd"
          >photo</i
        >
        <image-uploader
          ref="imageInput"
          :preview="false"
          :quality="0.7"
          class="image-uploader"
          accept="image/*"
          doNotResize="gif"
          :autoRotate="false"
          outputFormat="verbose"
          @input="onUploadImage"
          v-show="false"
        ></image-uploader>
      </div>
      <!-- page content -->
      <div v-if="photoList.length === 0" class="camera">
        <div class="bg-no-data">
          <f7-icon f7="photo" class="icon-no-data"></f7-icon>
        </div>
        <p>No photo to load</p>
      </div>
      <h3 v-if="photoList.length > 0" style="padding-left: 20px">
        Recent Uploaded
      </h3>
      <div v-if="photoList.length > 0" class="img-grid">
        <div v-for="(photo, index) in photoList" :key="index">
          <div
            class="img-item"
            @click="
              selectCallback(photo);
              showPopup = false;
            "
          >
            <img class="image-show" :src="photo.url" />
            <div class="img-bg img-bg-not-select"></div>
          </div>
          <span class="img-title">{{
            (photo.createdAt && photo.createdAt.toDate()) | TIME
          }}</span>
        </div>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      showPopup: false,
      selectedValue: "",
      selectCallback: null
    };
  },
  computed: {
    ...mapGetters({
      proposalPhotos: "proposal/proposal/photos",
      proposalPhotosOfBuilding: "proposal/proposal/photosOfBuilding",
      proposalTemplatePhotos: "proposal/template/photos",
      proposal: "proposal/proposal/proposal",
      proposalTemplate: "proposal/template/proposalTemplate"
    }),
    photoList() {
      if (!_.isEmpty(this.proposalTemplate)) {
        return this.proposalTemplatePhotos;
      } else if (!_.isEmpty(this.proposal)) {
        return this.proposalPhotos.concat(this.proposalPhotosOfBuilding);
      }
      return [];
    }
  },
  methods: {
    ...mapActions({
      setProposalTemplateField: "proposal/template/setProposalTemplateField",
      setProposalField: "proposal/proposal/setProposalField",
      uploadPhoto: "proposal/template/uploadPhoto",
      removePhoto: "proposal/template/removePhoto"
    }),
    openPopup() {
      this.showPopup = true;
      return new Promise(resolve => {
        this.selectCallback = resolve;
      });
    },
    closePopup() {
      this.showPopup = false;
    },
    done() {
      this.showPopup = false;
      this.$emit("change", this.selectedValue);
    },
    openUploadPhoto() {
      this.$refs.imageInput.$el.lastChild.click();
    },
    onUploadImage(fileData) {
      const app = this;
      this.$f7.preloader.show();
      this.uploadPhoto({ proposalId: this.proposal.id, fileData }).then(
        ({ fullPath, url }) => {
          this.$f7.preloader.hide();
          const photo = {
            url: url,
            photoName: fileData.info.name,
            fullPath: fullPath
          };
          if (!_.isEmpty(this.proposalTemplate)) {
            let photos = _.cloneDeep(app.proposalTemplatePhotos);
            photos.push(photo);
            app.setProposalTemplateField({
              fieldName: "photos",
              value: photos
            });
          } else if (!_.isEmpty(this.proposal)) {
            let photos = _.cloneDeep(app.proposalPhotos);
            photos.push(photo);
            app.setProposalField({
              fieldName: "photos",
              value: photos
            });
          }
          this.selectCallback(photo);
          this.showPopup = false;
        }
      );
    },

    handlePopupClosed() {},
    onChangeObject(val) {
      this.selectedValue = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-photo {
  height: 80px;
  display: flex;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
  border-color: rgb(207, 212, 219);
  border-radius: 3px;
  margin: 20px;
  position: relative;
}
.click-upload {
  position: absolute;
  top: 20%;
  z-index: 100;
  color: gray;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
  padding: 0 16px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
}
.img-item:hover .img-bg-not-select {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
}
.img-title {
  font-size: 12px;
}
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.camera {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.image-show {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
