<template>
  <div>
    <preview-page ref="previewPage" title="Contract Preview" :itemDetail="buildContractDetail" :isHiddenLeftBtn="true"
      entity="contract" :entityId="contract.id" :token="$f7route.query.contractToken" :pageHeight="1680"
      :isShowOnlineSignature="isShowOnlineSignature" @openSignaturePopup="openSignaturePopup">
      <template v-if="project.id && isShowOnlineSignature" slot="other">
        <f7-link v-if="invalidInitial(1)" @click.native="openInputInitial(1)" class="edit-initial" :style="`top: ${positionInitial1.top}px; left: ${positionInitial1.left}px; width: ${positionInitial1.width}px`
          ">
          <f7-icon :size="$device.desktop ? '26' : '14'" f7="pencil_ellipsis_rectangle"></f7-icon>
        </f7-link>
        <f7-link v-if="invalidInitial(2)" @click.native="openInputInitial(2)" class="edit-initial" :style="`top: ${positionInitial2.top}px; left: ${positionInitial2.left}px; width: ${positionInitial2.width}px`
          ">
          <f7-icon :size="$device.desktop ? '26' : '14'" f7="pencil_ellipsis_rectangle"></f7-icon>
        </f7-link>
      </template>
    </preview-page>
    <build-contract-signature-popup ref="signaturePopup" :contract="contract" @reloadPages="reloadPages"
      :isUserSign="true"></build-contract-signature-popup>
  </div>
</template>
<script>
import PreviewPage from "./PreviewPage.vue";
import BuildContractSignaturePopup from "@/components/contract/BuildContractSignaturePopup.vue";
import _ from "lodash";
import axios from "axios";
import { nowInHumanReadable } from "../../../utility/date-time-tool";
import { firebase } from "@/services/firebase.service";
import { BUSINESS_CODE_RESIDENTIAL } from "../../../utility/const";

export default {
  components: { PreviewPage, BuildContractSignaturePopup },

  data: () => {
    return {
      BUSINESS_CODE_RESIDENTIAL,
      project: {},
      contract: {},
      positionInitial1: {},
      positionInitial2: {}
    };
  },

  computed: {
    buildContractDetail() {
      return this.contract
        ? {
          id: this.contract.id,
          sections: this.contract.sections,
          title: `${this.contract.contractTitle} - ${nowInHumanReadable()}`
        }
        : {};
    },

    signatureSection() {
      return this.buildContractDetail.sections.find(
        r => r.sectionId === "main-page"
      );
    },

    indexOfSignatureSection() {
      return this.buildContractDetail.sections.findIndex(
        r => r.sectionId === "main-page"
      );
    },

    invalidInitial() {
      return number => {
        return this.signatureSection.htmlValue.includes(
          `<div class="owner-sign-initial-${number}"></div>`
        );
      };
    },
    isContractViewToSign() {
      return this.$f7.views.main.router.currentRoute.path.includes(
        "contract-view"
      );
    },
    isShowOnlineSignature() {
      return (
        this.project.status === "open" &&
        this.project.businessCode === BUSINESS_CODE_RESIDENTIAL &&
        this.contract.viewedAt &&
        !this.contract.ownerSigner &&
        this.isContractViewToSign
      );
    }
  },

  async mounted() {
    await this.reloadPages();
    if (this.contract.status === "sent" && this.isContractViewToSign) {
      try {
        // update status to viewed by client
        await axios.post(
          `contract/update/${this.$f7route.query.contractId}`,
          {
            status: "viewed",
            viewedAt: firebase.firestore.Timestamp.now()
          },
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.contractToken
            }
          }
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
      await this.reloadPages();
    }
    this.$refs.previewPage.lowResDesktopScreen();
    setTimeout(() => {
      this.positionInitial1 = this.$el.querySelector(".owner-sign-initial-1")
        ? this.$el
          .querySelector(".owner-sign-initial-1")
          .getBoundingClientRect()
        : {};
      this.positionInitial2 = this.$el.querySelector(".owner-sign-initial-2")
        ? this.$el
          .querySelector(".owner-sign-initial-2")
          .getBoundingClientRect()
        : {};
    }, 100);
  },

  methods: {
    showErrorDialog(errorTitle, errorMessage) {
      this.$ri.dialog.openErrorDialog({
        title: errorTitle,
        content: errorMessage,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
            setTimeout(() => this.setPositionXInitials(), 1);
          } else if (index === 1) {
            _sefl.app.dialog.close();
            setTimeout(() => this.setPositionXInitials(), 1);
          }
        }
      });
    },
    openSignaturePopup() {
      if (this.invalidInitial(1) || this.invalidInitial(2)) {
        this.showErrorDialog(
          "Input Initial",
          "Please input your initial before signing online."
        );
        setTimeout(() => this.setPositionXInitials(), 1);
        return;
      }
      this.$refs.signaturePopup.openPopup("owner");
    },
    async reloadPages() {
      this.$f7.preloader.show();
      try {
        const promises = [];
        // get project
        promises.push(
          axios.get(`project/${this.$f7route.query.projectId}`, {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.projectToken
            }
          })
        );
        // get contract
        promises.push(
          axios.get(`contract/${this.$f7route.query.contractId}`, {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.contractToken
            }
          })
        );

        const response = await Promise.all(promises);
        const projectRes = response[0];
        const contractRes = response[1];

        if (projectRes.status === 200 && projectRes.data.success) {
          this.project = projectRes.data.data;
        }
        if (contractRes.status === 200 && contractRes.data.success) {
          this.contract = contractRes.data.data;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    setPositionXInitials() {
      this.positionInitial1 = {
        ...this.positionInitial1,
        left: this.$el.querySelector(".owner-sign-initial-1")
          ? this.$el
            .querySelector(".owner-sign-initial-1")
            .getBoundingClientRect().left
          : 0
      };
      this.positionInitial2 = {
        ...this.positionInitial2,
        left: this.$el.querySelector(".owner-sign-initial-2")
          ? this.$el
            .querySelector(".owner-sign-initial-2")
            .getBoundingClientRect().left
          : 0
      };
    },
    openInputInitial(number) {
      let buildingName = "";
      this.$f7.dialog.prompt(
        "",
        "Initial",
        name => {
          if (name.trim()) {
            this.saveInitial(number, name.trim()).then(() => {
              setTimeout(() => this.setPositionXInitials(), 1);
            });
          }
          setTimeout(() => this.setPositionXInitials(), 1);
        },
        () => {
          setTimeout(() => this.setPositionXInitials(), 1);
        },
        buildingName
      );
      this.setPositionXInitials();
    },

    async saveInitial(number, name) {
      this.$f7.preloader.show();
      let htmlValue = _.cloneDeep(this.signatureSection).htmlValue;
      if (number === 1) {
        htmlValue = htmlValue.replaceAll(
          '<div class="owner-sign-initial-1"></div>',
          `<div style="color: black; padding: 8px; word-break: break-word;"><strong>${name}</strong></div>`
        );
      } else if (number === 2) {
        htmlValue = htmlValue.replaceAll(
          '<div class="owner-sign-initial-2"></div>',
          `<div style="color: black; padding: 8px; word-break: break-word;"><strong>${name}</strong></div>`
        );
      } else {
        return;
      }
      let sections = _.cloneDeep(this.buildContractDetail.sections);
      sections = sections.filter(
        section => section.sectionId !== this.signatureSection.sectionId
      );
      let newSection = _.cloneDeep(this.signatureSection);
      newSection.htmlValue = htmlValue;
      sections.splice(this.indexOfSignatureSection, 0, newSection);

      let i = 0;
      sections.forEach(section => {
        section.index = i++;
      });

      try {
        await axios.post(
          `contract/update/${this.buildContractDetail.id}`,
          { sections },
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.contractToken
            }
          }
        );
        this.reloadPages();
      } catch (error) {
        this.showErrorDialog("Error", error.message);
      }
      this.$f7.preloader.hide();
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-initial {
  position: absolute;
  z-index: 9999;
}
</style>
