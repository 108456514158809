<template>
  <div class="popup-scroll-bar">
    <estimate-input
      :value="detail"
      :estimateList="estimateList"
      :action="action(detail.columnId)"
      :boardId="detail.boardId"
      activeTab="tab-estimate"
      @closePopup="closeActionPopup"
      @onUpdateEstimate="handleUpdateEstimate"
      @onCreateNewEstimate="handleCreateNewEstimate"
      @onDeleteEstimate="handleDeleteEstimate"
    ></estimate-input>
    <proposal-input
      :value="detail"
      :proposalList="proposalList"
      :action="action(detail.columnId)"
      :boardId="detail.boardId"
      activeTab="tab-estimate"
      @closePopup="closeActionPopup"
      @onUpdateProposal="handleUpdateProposal"
      @onCreateNewProposal="handleCreateNewProposal"
      @onDeleteProposal="handleDeleteProposal"
    ></proposal-input>
    <purchase-order-input
      :value="detail"
      fromServiceBoard
    ></purchase-order-input>
  </div>
</template>

<script>
import EstimateInput from "@/components/inputs/EstimateInput.vue";
import ProposalInput from "@/components/inputs/ProposalInput.vue";
import PurchaseOrderInput from "@/plugins/dashboard/components/input/PurchaseOrderInput.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { EstimateInput, ProposalInput, PurchaseOrderInput },

  data() {
    return {
      activeButton: "estimate"
    };
  },

  computed: {
    ...mapGetters({
      detail: "swimlane/card/detail",
      action: "swimlane/column/action"
    }),

    ...mapGetters({ estimateList: "swimlane/estimate/estimateList" }),
    ...mapGetters({ proposalList: "swimlane/proposal/proposalList" })
  },

  methods: {
    ...mapActions("swimlane/estimate", [
      "updateEstimate",
      "deleteEstimate",
      "getEstimatesByProjectId"
    ]),

    ...mapActions("swimlane/proposal", [
      "updateProposal",
      "deleteProposal",
      "getProposalsByProjectId"
    ]),

    init() {},

    closeActionPopup(callback) {
      this.$emit("closePopup", callback);
    },

    handleUpdateEstimate(data) {
      this.$f7.preloader.show();
      this.updateEstimate({
        id: data.id,
        doc: {
          ...data.doc
        }
      })
        .then(() => {
          this.getEstimatesByProjectId(this.detail.id);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleCreateNewEstimate() {
      this.$f7router.navigate(
        {
          name: this.detail.contactId
            ? "new-estimate-swimlane-service"
            : "new-estimate-swimlane",
          params: {
            boardType: "swimlane",
            businessCode: this.$f7route.route.meta.businessCode,
            boardId: this.detail.boardId,
            actionId: this.detail.columnId,
            projectId: this.detail.id,
            contactId: this.detail.contactId || "-",
            clientType: "contact",
            clientId: this.detail.contactId || "-"
          },
          query: {
            activeTab: "tab-estimate"
          }
        },
        { reloadAll: true }
      );

      this.closeActionPopup();
    },

    handleUpdateProposal(data) {
      this.$f7.preloader.show();
      this.updateProposal({
        id: data.id,
        doc: {
          ...data.doc
        }
      })
        .then(() => this.getProposalsByProjectId(this.detail.id))
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleCreateNewProposal() {
      const self = this;

      this.$f7router.navigate(
        {
          name: "new-proposal-swimlane-service",
          params: {
            boardType: "swimlane", //self.$f7route.route.meta.boardType,
            businessCode: self.$f7route.route.meta.businessCode,
            boardId: this.detail.boardId,
            actionId: this.detail.columnId,
            projectId: this.detail.id,
            contactId: this.detail.contactId || "-"
          },
          query: {
            activeTab: "tab-estimate"
          }
        },
        { reloadAll: true, pushState: true }
      );

      this.closeActionPopup();
    },

    handleDeleteEstimate(data) {
      this.$f7.preloader.show();
      this.deleteEstimate(data.id)
        .then(() => {
          this.getEstimatesByProjectId(this.detail.id);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleDeleteProposal(data) {
      this.$f7.preloader.show();
      this.deleteProposal(data.id)
        .then(() => this.getProposalsByProjectId(this.detail.id))
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
  }
};
</script>

<style></style>
