import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Expenses",
    name: "expenses-main",
    displayRoles: ["owner", "user"],
    routes: [
      {
        path: "/my-expenses",
        title: "My Expenses",
        name: "my-expenses",
        parent: "finance-main",
        displayRoles: ["owner", "user"],
        icon: "creditcard",
        async: checkAuth(() => import("../pages/MyExpenses.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/my-expenses/:id",
            async: checkAuth(() => import("../pages/MyExpensesDetail.vue"))
          }
        ]
      },
      {
        path: "/employee-expenses",
        title: "Employee Expenses",
        name: "employee-expenses",
        parent: "finance-main",
        displayRoles: ["owner", "user"],
        icon: "money_dollar_circle",
        async: checkAuth(() => import("../pages/EmployeeExpenses.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/employee-expenses/:id",
            async: checkAuth(() =>
              import("../pages/EmployeeExpensesDetail.vue")
            )
          }
        ]
      }
    ]
  }
];
