<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select product from Price list</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="addProductItem">Add</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- Price List -->
      <f7-list media-list>
        <f7-list-item link @click.native="selectPriceList"
          ><div class="list-item-inner-start" slot="inner-start">
            Price List
          </div>
          <div class="list-item-title" slot="title">
            {{ priceListItem(priceListId).displayName || "Select price list" }}
          </div>
          <input-icon slot="media" icon="square_list"></input-icon>
        </f7-list-item>
      </f7-list>

      <!-- Products table -->
      <data-table
        :headers="headers"
        :items="applyFilterProductItem"
        :pageSize="applyFilterProductItem.length"
        :checkbox="true"
        @selected:change="checkBoxChange"
      >
        <template slot="card-header">
          <f7-list>
            <f7-searchbar
              placeholder="Search in items"
              :clear-button="true"
              disable-button-text
              @input="setSearchText(($event.target.value || '').trim())"
              @keyup.enter.native="search()"
              @searchbar:disable="setSearchText('')"
              ref="searchProductToPriceListPopup"
            ></f7-searchbar>
          </f7-list>
        </template>
        <template v-slot:body="{ item }">
          <td>{{ vendorName(item.vendorId) }}</td>
          <td>{{ item.manufacturer }}</td>
          <td>{{ subCategoryName(item.subCategoryId) }}</td>
          <td>{{ item.sku }}</td>
          <td>{{ item.productItem }}</td>
          <td>{{ item.uom }}</td>
          <td>{{ item.price | currencyUSD }}</td>
        </template>

        <template slot="paging-footer">
          <div
            v-if="applyFilterProductItem.length > 0"
            class="data-table-footer"
          >
            <div class="data-table-rows-select">
              Per page:
              <div class="input input-dropdown">
                <select
                  @input="onChangeLimit($event.target.value.trim())"
                  :value="hitsPerPage"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                </select>
              </div>
            </div>
            <div class="data-table-pagination">
              <span
                v-if="page === 0"
                class="display-flex align-items-center margin-left color-gray"
                disabled
                ><f7-icon f7="chevron_left"></f7-icon>First</span
              >
              <a
                v-else
                @click="onGoToPage('first')"
                class="display-flex align-items-center margin-left "
                disabled
                ><f7-icon f7="chevron_left"></f7-icon>First</a
              >

              <span v-if="page === 0" class="margin-left color-gray"
                >Previous</span
              >
              <a v-else @click="onGoToPage('prev')" class="margin-left"
                >Previous</a
              >

              <span class="data-table-pagination-label margin-left">{{
                pagingMessage
              }}</span>

              <a
                v-if="page < nbPages - 1"
                @click="onGoToPage('next')"
                class="margin-left"
                >Next</a
              >
              <span v-else class="margin-left color-gray">Next</span>

              <a
                v-if="page < nbPages - 1"
                @click="onGoToPage('last')"
                class="display-flex align-items-center margin-left"
                >Last<f7-icon f7="chevron_right"></f7-icon
              ></a>
              <span
                v-else
                class="display-flex align-items-center margin-left color-gray"
                >Last<f7-icon f7="chevron_right"></f7-icon
              ></span>
            </div>
          </div>
        </template>
      </data-table>
    </f7-page>

    <price-list-item-list-popup
      ref="selectPriceListItem"
      @onSelected="onSelectedPriceList($event)"
    ></price-list-item-list-popup>
  </f7-popup>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import PriceListItemListPopup from "../popups/PriceListItemListPopup.vue";
import InputIcon from "@/components/icons/InputIcon.vue";

import { STATUS_PRODUCT_ITEM_ACTIVE } from "../../../../utility/const";

export default {
  components: {
    DataTable,
    PriceListItemListPopup,
    InputIcon
  },
  props: {
    existingProductItems: { type: Array, default: () => [] }
  },
  data: () => {
    return {
      popupOpened: false,
      headers: [
        {
          text: "Vendor",
          align: "left",
          sortable: false,
          value: "vendor"
        },
        {
          text: "Manufacturer",
          align: "left",
          sortable: false,
          value: "manufacturer"
        },
        {
          text: "Sub Category",
          value: "sub_category",
          sortable: false,
          align: "left"
        },
        {
          text: "SKU",
          value: "sku",
          sortable: false,
          align: "left"
        },
        {
          text: "Product Item",
          value: "productItem",
          sortable: false,
          align: "left"
        },
        {
          text: "UOM",
          value: "UoM",
          sortable: false,
          align: "left"
        },
        {
          text: "Price",
          value: "price",
          sortable: false,
          align: "left"
        }
      ],

      priceListId: null,
      selectedProducts: [],
      searchtext: ""
    };
  },

  computed: {
    ...mapGetters("invoices/priceList", ["priceListItemList", "priceListItem"]),
    ...mapGetters({
      subCategoryObjects: "invoices/subCategory/objectList",
      categories: "invoices/category/objectList",
      vendors: "invoices/vendor/objectList"
    }),

    ...mapGetters("invoices/product", [
      "hits",
      "searchText",
      "hitsPerPage",
      "nbPages",
      "page",
      "nbHits"
    ]),

    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },

    applyFilterProductItem() {
      if (this.priceListId) {
        const productRefs = this.priceListItem(this.priceListId).productRefs;

        return this.hits.map(product => {
          const ref = productRefs.find(ref => ref.productId === product.id);
          if (ref) {
            return {
              ...product,
              ...ref,
              checked: this.selectedProducts.some(p => p.id === product.id)
            };
          }
        });
      }

      return [];
    }
  },

  methods: {
    ...mapActions("invoices/priceList", ["getPriceListItemList"]),
    ...mapActions("invoices/product", [
      "searchProduct",
      "setSearchText",
      "goToPage",
      "setProductNumberOfRows"
    ]),
    ...mapActions({
      getSubCategories: "invoices/subCategory/getSubCategories",
      getCategories: "invoices/category/getCategories",
      getVendors: "invoices/vendor/getVendors"
    }),

    search() {
      this.$f7.preloader.show();
      this.searchProduct({
        query: this.searchText,
        filters: `priceListRefs:${this.priceListId} AND status:${STATUS_PRODUCT_ITEM_ACTIVE}`
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        query: this.searchText,
        pageName,
        filters: `priceListRefs:${this.priceListId} AND status:${STATUS_PRODUCT_ITEM_ACTIVE}`
        // ...this.compileConditionsToSearchByPL
      }).finally(() => {
        // this.createColorPhotoTooltip();
        this.$f7.preloader.hide();
      });
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows(value);
      this.search();
    },

    selectPriceList() {
      this.$refs.selectPriceListItem.open();
    },

    onSelectedPriceList(priceListId) {
      this.priceListId = priceListId;
      this.search();
      // this.getProductsByPriceListRefsList(priceListId);

      if (this.$device.desktop && !_.isEmpty(this.priceListId)) {
        this.$refs.searchProductToPriceListPopup.$el
          .querySelector(".searchbar input")
          .focus();
      }
    },

    initData() {
      this.$f7.preloader.show();
      const refs = [];
      if (_.isEmpty(this.priceListItemList)) {
        refs.push(this.getPriceListItemList());
      }

      if (_.isEmpty(this.subCategory)) {
        refs.push(this.getSubCategories());
      }
      if (_.isEmpty(this.categories)) {
        refs.push(this.getCategories());
      }
      if (_.isEmpty(this.vendors)) {
        refs.push(this.getVendors());
      }

      if (this.priceListId) {
        refs.push(this.search());
        // refs.push(this.getProductsByPriceListRefsList(this.priceListId));
      }
      // this.selectedProducts = [];

      return Promise.all(refs).finally(() => {
        const priceListAll =
          this.priceListItemList.find(r => r.isDefault === true) || {};
        this.onSelectedPriceList(priceListAll.id);
        this.$f7.preloader.hide();
      });
    },

    open() {
      this.popupOpened = true;
      this.initData();
    },

    handlePopupOpened() {
      this.selectedProducts = _.cloneDeep(this.existingProductItems);
      if (this.$device.desktop && !_.isEmpty(this.priceListId)) {
        this.$refs.searchProductToPriceListPopup.$el
          .querySelector(".searchbar input")
          .focus();
      }
    },

    cancel() {
      this.popupOpened = false;
    },

    vendorName(id) {
      const vendor = this.vendors.find(r => r.id === id);
      return (vendor || {}).companyName;
    },

    subCategoryName(subCategoryId) {
      const subCategory = this.subCategoryObjects.find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },

    addProductItem() {
      this.$emit("onAddProductItems", this.selectedProducts);
      this.popupOpened = false;
    },
    checkBoxChange(data) {
      const foundedItem = this.selectedProducts.find(
        item => item.id === data.id
      );
      // remove unchecked item
      if (foundedItem && data.checked === false) {
        this.selectedProducts = this.selectedProducts.filter(
          item => item.id !== foundedItem.id
        );
      }
      // add checked item
      else if (!foundedItem && data.checked === true) {
        const productRefs = this.priceListItem(this.priceListId).productRefs;
        const productToAdd = this.hits.find(item => item.id === data.id);
        if (productToAdd) {
          const ref = productRefs.find(
            ref => ref.productId === productToAdd.id
          );
          this.selectedProducts.push({ ...ref, checked: true });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
