<template>
  <div>
    <div style="padding: 0 10px">
      <f7-segmented strong tag="p">
        <f7-button :active="currentTab === 'invoice'" @click.native="currentTab = 'invoice'">Invoice</f7-button>
        <f7-button :active="currentTab === 'recurring-invoice'"
          @click.native="currentTab = 'recurring-invoice'">Recurring Invoice</f7-button>
      </f7-segmented>
    </div>
    <data-table
      v-if="currentTab === 'invoice'"
      :headers="tableHeaders"
      :items="dataInvoiceList"
      :pageSize="(dataInvoiceList || []).length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ totalPaidInvoiceAmount | currencyUSD }}</i>
            </div>
            <f7-button small fill popover-open=".popover-create-invoice" 
              >{{ !$device.desktop ? "+" : "Create" }} Invoice</f7-button
            >
          </f7-row>
          <f7-popover class="popover-create-invoice">
          <f7-list class="cursor-pointer">
            <f7-list-item
              link
              popover-close
              title="Default Invoice"
              @click="openCreateInvoicePopup"
            >
            </f7-list-item>
            <f7-list-item
                link
                popover-close
                title="Recurring Invoice"
                @click="openPopupNewRecurringInvoice(true)"
              >
          </f7-list-item>
        </f7-list>
      </f7-popover>    
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item }">
        <td class="label-cell">
          <f7-link @click="openInvoiceGroupDetailPopup(item)">{{
            item.invoiceNumber
          }}</f7-link>
        </td>
        <td>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </td>
        <td>{{ total(item) | currencyUSD }}</td>
        <td>{{ item.paidAmount | currencyUSD }}</td>
        <td>{{ item.invoiceDate }}</td>
        <td>{{ item.dueDate }}</td>
        <td>{{ item.paidDate }}</td>
      </template>
    </data-table>
    <!-- Table recurring invoice -->
    <data-table v-if="currentTab === 'recurring-invoice'" :headers="headerRecurringInvoice" :items="recurringInvoice || []"
      :pageSize="(recurringInvoice || []).length || 0" :isHoverRow="false" class="wrap-text-table margin-top">
      <template v-slot:body="{ item }">
        <template>
          <td :class="{ 'p-1': !$device.desktop }">
            {{ item.recurringInvoiceNumber }}
          </td>
          <td>
            <f7-chip slot="after" :text="getStatusRecurringInvoice(item.status).displayName"
              :color="getStatusRecurringInvoice(item.status).color"></f7-chip>
          </td>
          <td>
            {{ item.totalAmount | currencyUSD }}
          </td>
          <td>{{ item.startOn }}</td>
          <td>{{ item.endOn }}</td>
          <td style="text-align: center">
            <f7-link :popover-open="`.popover-actions_${item.id}`" @click.stop="() => { }">View</f7-link>
            <f7-popover :class="`popover-actions_${item.id}`">
              <f7-list class="cursor-pointer menu-actions">
                <f7-list-item title="Invoice#">
                </f7-list-item>
                <f7-list-item v-for="invoice in item.invoiceList" popover-close :key="invoice.id">
                  <div slot="title">{{ invoice.invoiceNumber }}</div>
                </f7-list-item>
              </f7-list>
            </f7-popover>
          </td>
          <td class="text-align-right" @click="openPopupNewRecurringInvoice(false, item.id)">
            <f7-link>Edit</f7-link>
          </td>
        </template>
      </template>
    </data-table>
    <div>
      <new-invoice-popup
        ref="newInvoicePopup"
        :isEdit="false"
        :propProjectId="detail.id || ''"
        :isDisable="true"
        @done="initData"
      ></new-invoice-popup>
      <invoice-group-detail-popup
        ref="invoiceGroupDetailPopup"
        :propInvoiceSelected="invoiceSelected"
        @onClose="initData" 
      />
      <new-recurring-invoice-popup ref="newRecurringInvoicePopup" @closePopup="initData">
      </new-recurring-invoice-popup>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import { sortLatest } from "@/utility/date-time-tool";
import { INVOICE_STATUS_OVERDUE } from "../../../invoices/utility/const";
import moment from "moment";
import NewInvoicePopup from "@/plugins/invoices/components/popups/NewInvoicePopup.vue";
import common from "../../mixin/common";
import _ from "lodash";
import invoiceMixins from "@/plugins/invoices/mixins/invoice-mixin";
import InvoiceGroupDetailPopup from "@/plugins/invoices/components/popups/InvoiceGroupDetailPopup.vue";
import { convertDateUTCToLocalTimeZone } from "@/utility/datetime";
import NewRecurringInvoicePopup from "@/plugins/invoices/components/popups/NewRecurringInvoicePopup.vue";

export default {
  components: { DataTable, NewInvoicePopup, InvoiceGroupDetailPopup, NewRecurringInvoicePopup },
  mixins: [common, invoiceMixins],

  data: () => {
    return {
      invoiceGroupDetail: null,
      invoiceSelected: null,
      currentTab: "invoice"
    };
  },

  computed: {
    ...mapGetters("swimlane/app-constant", ["invoiceStatusList","recurringInvoiceStatusList"]),
    ...mapGetters({
      detail: "swimlane/card/detail"
    }),
    ...mapGetters("swimlane/finance", ["invoiceList", "invoiceIsPayment","recurringInvoiceList"]),
    ...mapGetters("swimlane/estimate", ["estimateList"]),
    ...mapGetters('common/app-constant', ['tenantId']),

    isExistEstimate() {
      return this.estimateList.length > 0;
    },
    tableHeaders() {
      return [
        {
          text: "Invoice#",
          align: "left",
          sortable: false,
          value: "invoiceNumber"
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status"
        },
        {
          text: "Invoice Amount",
          align: "left",
          sortable: false,
          value: "total"
        },
        {
          text: "Paid Amount",
          align: "left",
          sortable: false,
          value: "paidAmount"
        },
        {
          text: "Invoice Date",
          align: "left",
          sortable: false,
          value: "invoiceDate"
        },
        {
          text: "Due Date",
          value: "status",
          align: "left"
        },
        {
          text: "Paid Date",
          align: "left",
          sortable: false,
          value: "paidDate"
        }
      ];
    },

    isDraft() {
      return this.detail && this.detail.isDraft;
    },

    totalPaidInvoiceAmount() {
      return this.invoiceList.reduce((acc, invoice) => {
        return invoice.status === "in-paid" ||
          invoice.status === "in-partial-paid"
          ? acc + invoice.paidAmount
          : acc;
      }, 0);
    },

    headerRecurringInvoice() {
      return [
        {
          text: "Invoice#",
          value: "invoiceNumber",
          align: "left"
        },
        {
          text: "Status",
          value: "status",
          align: "left"
        },
        {
          text: "Invoice Amount",
          value: "totalAmount",
          align: "left"
        },
        {
          text: "Start On",
          value: "startOn",
          align: "left"
        },
        {
          text: "End On",
          value: "endOn",
          align: "left"
        },
        {
          text: "Child Invoice",
          value: "childInvoice",
          align: "left"
        },
        {
          text: "Action",
          align: "right",
          value: "action"
        }
      ];
    },

    recurringInvoice() {
      if (!this.recurringInvoiceList.length) return [];

      const list = this.recurringInvoiceList.map(item => {
        return {
          ...item,
          startOn: item.startOn
            ? item.startOn._seconds
              ? this.convertDateTimeFromAlgolia(item.startOn)
              : convertDateUTCToLocalTimeZone(item.startOn.toDate())
            : "",
          endOn: item.endOn
            ? item.endOn._seconds
              ? this.convertDateTimeFromAlgolia(item.endOn)
              : convertDateUTCToLocalTimeZone(item.endOn.toDate())
            : ""
        };
      });

      return sortLatest(list)
    },

    dataInvoiceList() {
      let list = this.invoiceList?.map(item => ({
        ...item,
        invoiceDate: item.invoiceDate
          ? convertDateUTCToLocalTimeZone(item.invoiceDate.toDate())
          : "",
        dueDate: item.dueDate
          ? convertDateUTCToLocalTimeZone(item.dueDate.toDate())
          : "",
        paidDate: item.paidDate
          ? convertDateUTCToLocalTimeZone(item.paidDate.toDate())
          : "",
        status:
          this.getOverdueDays(item) > 0 ? INVOICE_STATUS_OVERDUE : item.status
      }));

      return sortLatest(list);
    }
  },

  mounted() {
    this.initData();
  },

  methods: {
    ...mapActions("swimlane/finance", [
      "initInvoice",
      "getInvoiceGroupByProjectId",
      "getRecurringInvoiceByInvoiceGroupId"
    ]),

    async initData() {
      this.$f7.preloader.show();

      const invoiceGroupByProjectId = await this.getInvoiceGroupByProjectId({
        projectId: this.detail.id,
        tenant: this.tenantId
      });

      if (_.isEmpty(invoiceGroupByProjectId)) {
        this.$f7.preloader.hide();
        return;
      }

      this.invoiceGroupDetail = _.isEmpty(invoiceGroupByProjectId)
        ? {}
        : invoiceGroupByProjectId.shift();

      await Promise.all([
        this.initInvoice(this.invoiceGroupDetail.id),
        this.getRecurringInvoiceByInvoiceGroupId(this.invoiceGroupDetail.id)
      ])

      this.$f7.preloader.hide();
    },

    openCreateInvoicePopup() {
      this.$refs.newInvoicePopup.open();
    },

    openPopupNewRecurringInvoice(isNewRecurring, recurringInvoiceId="") {
      this.$refs.newRecurringInvoicePopup.open(this.invoiceGroupDetail ? this.invoiceGroupDetail.id : "", isNewRecurring, recurringInvoiceId, this.detail.id);
    },

    openInvoiceGroupDetailPopup(invoice) {
      if (!this.invoiceGroupDetail) return;
      this.invoiceSelected = invoice;

      this.$refs.invoiceGroupDetailPopup.open(this.invoiceGroupDetail, invoice);
    },

    getStatusRecurringInvoice(status) {
      return (
        (this.recurringInvoiceStatusList || []).find(x => x.value === status) || {}
      );
    },

    gotoInvoiceDetail(invoiceNumber) {
      const self = this;
      this.$emit("closePopup", () => {
        const url = `/dashboard/type/swimlane/business/${self.$f7route.route.meta.businessCode}/board/${this.detail.boardId}/action/${this.detail.columnId}/project/${this.detail.id}/invoice/${invoiceNumber}/?activeTab=tab-finance`;

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true
        });
      });
    },
    getCreateNewInvoiceRouteName(type) {
      if (type == "FromEstimate") {
        return this.detail.contactId
          ? "new-invoice-from-estimate-swimlane-service"
          : "new-invoice-from-estimate-swimlane";
      } else {
        return this.detail.contactId
          ? "new-invoice-swimlane-service"
          : "new-invoice-swimlane";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popover-create-invoice-finance {
  width: 250px;
}
</style>
