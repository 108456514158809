<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left> </f7-nav-left>
      <f7-nav-title>RooferIntel</f7-nav-title>
      <f7-nav-right>
        <f7-link
          reloadAll
          panel-close
          @click="handleOpenNotify"
          class="global-notify-btn"
        >
        <f7-icon f7="bell_fill" color="primary">
          <f7-badge v-show="notifyListByUser.length > 0" style="background-color: #EDE9D0; top: -6px; color: #000; font-weight: bold">{{ formattedNotifyCount }}</f7-badge>
        </f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list v-if="user">
      <f7-list-item reloadAll panel-close link="/settings/profile">
        <div slot="title">
          <div class="title number-of-line">{{ user.displayName }}</div>
          <div class="number-of-line">
            {{ user.displayEmail || user.email }}
          </div>
        </div>
        <user-avatar
          slot="media"
          :avatarSize="55"
          :name="user.displayName"
          :imageUrl="user.photoURL"
        />
      </f7-list-item>
    </f7-list>

    <f7-list accordion-list>
      <f7-list-item
        accordion-item
        v-for="(group, index) in getDisplayMenu"
        :key="index"
        v-show="group.routes.length > 0"
      >
        <div slot="title">
          {{ group.title }}
        </div>

        <f7-accordion-content>
          <f7-list>
            <template v-for="(menuItem, index) in group.routes">
              <f7-list-item
                v-if="!menuItem.notALink"
                :key="index"
                :link="menuItem.path"
                reloadAll
                pushState
                panel-close
              >
                <div color="primary" slot="title">{{ menuItem.title }}</div>
                <f7-icon color="primary" slot="media" f7=""></f7-icon
                ><f7-icon
                  v-if="menuItem.icon"
                  color="primary"
                  slot="media"
                  :f7="menuItem.icon || 'arrow_right_square'"
                ></f7-icon>
                <img
                  slot="media"
                  v-if="menuItem.iconImage"
                  :src="'data:image/svg+xml;base64,' + menuItem.iconImage"
                  alt=""
                />
              </f7-list-item>
              <f7-list-item
                v-else
                :key="index"
                :link="true"
                panel-close
                @click="() => menuItem.action($store)"
              >
                <div slot="title">{{ menuItem.title }}</div>
                <f7-icon color="primary" slot="media" f7=""></f7-icon
                ><f7-icon
                  color="primary"
                  slot="media"
                  :f7="menuItem.icon || 'arrow_right_square'"
                ></f7-icon
              ></f7-list-item>
            </template>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <template v-for="group in parentMenuItems">
      <template
        v-for="menuItem in (group.routes || []).filter(
          r => r.notALink && r.component
        )"
      >
        <component :is="menuItem.component" :key="menuItem.name" />
      </template>
    </template>
  </f7-page>
</template>
<script>
import UserAvatar from "@/components/avatars/UserAvatar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    UserAvatar
  },
  mounted() {
    this.createTooltip();
  },
  computed: {
    ...mapGetters("setting/app/profile", [
      "user",
      "getDisplayMenu",
      "parentMenuItems"
    ]),
    formattedNotifyCount() {
      const count = this.notifyListByUser.length;
      return count > 9 ? '9+' : count;
    },
    ...mapGetters("common/notification", ["notifyListByUser"]),
  },
  methods: {
    ...mapActions("common/notification", ["openGlobalNotifyPopup"]),
    createTooltip() {
      this.$f7.tooltip.create({
        targetEl: ".global-notify-btn",
        text: "Notifications"
      });
    },

    handleOpenNotify() {
      this.openGlobalNotifyPopup(true);
    }
  }
};
</script>
<style scoped>
.title {
  font-weight: bold;
  font-size: 18px;
}
.number-of-line {
  width: 95%;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
