<template>
  <f7-popup :opened="popupOpened" @popup:opened="handlePopupOpened" @popup:close="
    closePopup();
  deleteTempleProject();
  ">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Create New Lead</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block-header class="display-flex justify-content-space-between">
        Contact Information
        <div v-if="contactId">
          <f7-button small outline @click="openEditContact">Edit Contact</f7-button>
        </div>
      </f7-block-header>
      <f7-list media-list>
        <contact-name-input
          ref="selectContact"
          media-list
          :displayCard="card"
          :contactName="displayContactName"
          :isRequired="false"
          @onSelected="
            $event => {
              getContact($event).then(() => {
                setCurrentCardValues({ contactId: $event });
                contactId = $event;
              });
            }
          "
          @onDeleteContact="
            () => {
              setCurrentCardValues({ contactId: '' });
              contactId = '';
            }
              " @onCreated="$event => {
                getContact($event).then(contact => {
                  pushContact(contact);
                  setCurrentCardValues({ contactId: $event });
                  contactId = $event;
                });
              }
                " :autoFocus="true" :tabIndex="1">
        </contact-name-input>

        <!-- Phone -->
        <f7-list-item v-for="(phone, index) in (contact || {}).phones" :key="`phone-${index}`">
          <div slot="header">{{ phone.code }}</div>
          <div class="list-item-title" slot="title">{{ phone.value }}</div>
          <input-icon slot="media" icon="phone"></input-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Email -->
        <f7-list-item v-for="(email, index) in (contact || {}).emails" :key="`email-${index}`">
          <div slot="header">{{ email.code }}</div>
          <div class="list-item-title" slot="title">{{ email.value }}</div>
          <input-icon slot="media" icon="envelope"></input-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'mailto:' + email.value"
              icon-f7="envelope_circle_fill"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>

      <company-name-input
        ref="selectCompany"
        media-list
        :displayCard="card"
        :defaultTypesSelected="[COMPANY_TYPE_INSURANCE]"
        :companyName="displayCompanyName"
        :queryFilters="`companyTypes:${COMPANY_TYPE_INSURANCE}`"
        :isOtherCompanyType="true"
        :isRequired="false"
        @onSelected="
          $event => {
            getInsuranceCompany($event).then(() => {
              setCurrentCardValues({ companyId: $event });
              insuranceCompanyId = $event;
            });
          }
            " @onDeleteCompany="() => {
              setCurrentCardValues({ comapnyId: '' });
              insuranceCompanyId = '';
            }
              " 
              @onCreated="$event => {
                getInsuranceCompany($event).then(() => {
                  setCurrentCardValues({ companyId: $event });
                  insuranceCompanyId = $event;
                });
              }" />

      <address-input-residential ref="addressInput" :addressesSuggestion="(contact || {}).addresses || []"
        @input="onInputAddress($event)" @select="onSelectAddress($event)"></address-input-residential>

      <f7-list media-list>
        <f7-list-item link @click.native="navigateToSelectInspector">
          <div class="list-item-inner-start" slot="inner-start">
            Inspector Name
          </div>
          <div class="list-item-title" slot="title">
            {{ inspectorName || "Select Inspector" }}
          </div>
          <input-icon slot="media" icon="person"></input-icon>
        </f7-list-item>
        <f7-list-input label="Inspect Date" type="datepicker" :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }" :value="inspectDate" @calendar:change="
          inspectDate = $event;
        $f7.calendar.close();
        " error-message-force validate validate-on-blur :error-message="requireErrorMessage('inspectDate')">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="calendar"></input-icon>
        </f7-list-input>

        <f7-list-input :class="('inspect-time-web',
        {
          'inspect-time-ios': $device.ios,
          'inspect-time-android': $device.android,
          'inspect-time-desktop': $device.desktop || $device.ipad
        })
          " :style="inputDarkClass" label="Inspect Time" type="time" :value="inspectTime"
          @input="inspectTime = $event.target.value" error-message-force validate validate-on-blur
          :error-message="requireErrorMessage('inspectTime')">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="clock"></input-icon>
        </f7-list-input>
        <f7-list-input label="Lead Source" type="select" placeholder="Please choose..." :value="leadSource"
          @input="leadSource = $event.target.value" error-message-force validate validate-on-blur
          :error-message="requireErrorMessage('leadSource')">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
          <option v-for="(type, index) in leadSourceResidentialList" :key="index" :value="type.value">{{
            type.displayName }}</option>
        </f7-list-input>
      </f7-list>
      <user-input :value="assigneeIDs" :isSendMail="false" @input="assigneeIDs = $event"></user-input>
      <photo-section-residential ref="photoSectionResidential" :actionId="actionLead.id"></photo-section-residential>
      <!-- Note -->
      <note-editor :value="note" @onChange="saveNote($event)"></note-editor>
      
      <div class="display-flex justify-content-flex-end margin-bottom">
        <f7-button color="primary" class="margin-right-half" fill outline @click="onNext()">
          Next step
        </f7-button>
      </div>
    </f7-page>

    <edit-contact-popup ref="editContactPopup"></edit-contact-popup>

    <inspector-popup ref="inspectorPopup" @selectInspector="selectInspector"></inspector-popup>
  </f7-popup>
</template>

<script>
import AddressInputResidential from "@/components/inputs/AddressInputResidential.vue";
import UserInput from "../input/UserInput.vue";
import EditContactPopup from "../popup/EditContactPopup.vue";
import PhotoSectionResidential from "../photo/PhotoSectionResidential.vue";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import InputIcon from "../icon/InputIcon.vue";
import moment from "moment";
import {
  BUSINESS_CODE_RESIDENTIAL,
  LEAD_SOURCE_KNOCK_DOOR,
  CONTRACT_CHECKLIST,
  VALIDATION_MESSAGE
} from "../../../../utility/const";
import _ from "lodash";
import { firebase, auth } from "../../../../services/firebase.service";
import { toDateFirebase } from "../../../../utility/datetime";
import { getFullAddress } from "../../../../utility/address";
import onePageContract from "../../mixin/onePageContract";
import axiosService from "@/services/axios.service";
import {
  COMPANY_TYPE_INSURANCE,
  COLLECTION_OPRATION_CARD
} from "../../../../utility/const";
import InspectorPopup from "./InspectorPopup.vue";
import ContactNameInput from "@/components/inputs/ContactNameInput.vue";
import CompanyNameInput from "@/components/inputs/CompanyNameInput.vue";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  components: {
    InputIcon,
    UserInput,
    PhotoSectionResidential,
    EditContactPopup,
    AddressInputResidential,
    InspectorPopup,
    ContactNameInput,
    CompanyNameInput,
    NoteEditor
  },

  mixins: [onePageContract],

  data: () => {
    return {
      popupOpened: false,
      showListAddress: false,
      contactId: "",
      projectAddress: {
        address: "",
        city: "",
        state: "",
        zipcode: "",
        country: ""
      },
      insuranceCompanyId: "",
      leadSource: LEAD_SOURCE_KNOCK_DOOR,
      inspectDate: [new Date()],
      inspectTime: moment().format("HH:mm"),
      assigneeIDs: [],
      note: "",
      isDeleteDraftProject: true,
      inspectorName: ""
    };
  },

  created() {
    if (_.isEmpty(this.setting)) {
      this.bindSetting(this.tenantId);
    }
    this.bindTemplateList();
  },

  computed: {
    ...mapGetters("dashboard/app-constant", ["leadSourceResidentialList"]),
    ...mapGetters("dashboard/client", ["contact", "insuranceCompany"]),
    ...mapGetters("dashboard/project", ["card", "actionByCode", "activeUsers"]),
    ...mapGetters("dashboard/photo", ["projectPhotoList"]),
    ...mapGetters("setting/app/profile", ["user"]),

    userInfo() {
      return uid =>
        this.activeUsers.find(user => {
          return user.uid === uid;
        });
    },

    displayContactName() {
      return !_.isEmpty(this.contact)
        ? (
          (this.contact.firstName || "") +
          " " +
          (this.contact.lastName || "")
        ).trim()
        : "";
    },
    requireErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },
    actionLead() {
      return this.actionByCode(800) || {};
    },
    COMPANY_TYPE_INSURANCE() {
      return COMPANY_TYPE_INSURANCE;
    },
    displayCompanyName() {
      return this.insuranceCompany ? this.insuranceCompany.companyName : "";
    },
    inputDarkClass() {
      let theme =
        localStorage.getItem("themeDark") === "true" ? "dark" : "light";
      return `color-scheme: ${theme};`;
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "createCard",
      "updateProject",
      "bindCard",
      "unbindCard",
      "deleteTempleCard",
      "setCurrentActionIdsAfterMoveOrAdd",
      "updateCard",
      "setCurrentCardValues"
    ]),
    ...mapActions("dashboard/photo", [
      "bindProjectPhotoListBy",
      "unbindProjectPhotoList",
      "deleteProjectPhoto",
      "removePhoto"
    ]),
    ...mapActions("dashboard/album", [
      "bindProjectPhotoAlbumListBys",
      "unbindProjectPhotoAlbumList"
    ]),
    ...mapActions("common/notification", ["createNotificationByType"]),
    ...mapActions("dashboard/common", ["setResidentialGridCurrentTab"]),
    ...mapActions("dashboard/client", [
      "getContact",
      "getInsuranceCompany",
      "unbindContact",
      "unbindInsuranceCompany",
      "pushContact"
    ]),
    ...mapActions("common/contract", ["createNewContract", "updateContract"]),
    getContactName(contact) {
      return !_.isEmpty(contact)
        ? ((contact.firstName || "") + " " + (contact.lastName || "")).trim()
        : "";
    },
    saveNote(event) {
      this.note = event;
    },
    async openPopup() {
      // create project draft
      this.$f7.preloader.show();

      const id = await this.createCard({
        status: "draft",
        businessCode: this.$f7route.route.meta.businessCode
      });
      await this.bindCard(id);
      await this.$refs.photoSectionResidential.initData(id);
      this.popupOpened = true;

      this.clearData();
      this.$f7.preloader.hide();
    },

    handlePopupOpened() {
      if (this.$refs.selectContact) {
        this.$refs.selectContact.focusInput();
      }
    },
    clearData() {
      this.showListAddress = false;
      this.contactId = "";
      this.insuranceCompanyId = "";
      this.projectAddress = {
        address: "",
        city: "",
        state: "",
        zipcode: "",
        country: ""
      };
      this.unbindContact();
      this.unbindInsuranceCompany();
      this.inspectorName = "";
      this.leadSource = LEAD_SOURCE_KNOCK_DOOR;
      this.inspectDate = [new Date()];
      this.inspectTime = moment().format("HH:mm");
      this.assigneeIDs = [];
      this.note = "";
      this.v$.$reset();
      this.$refs.addressInput.clearData();
      this.$refs.addressInput.v$.$reset();
      this.$f7router.updateCurrentUrl(
        `/dashboard/${this.$f7route.route.meta.boardType}/residential`
      );
    },
    closePopup() {
      this.popupOpened = false;
      this.clearData();
    },
    deleteTempleProject() {
      if (!this.isDeleteDraftProject) {
        this.isDeleteDraftProject = true;
        return;
      }
      if (!_.isEmpty(this.card) || this.card.status === "draft") {
        this.$f7.preloader.show();
        let promises = [];
        if (!_.isEmpty(this.projectPhotoList)) {
          for (const photo of this.projectPhotoList) {
            if (photo.thumbnailFullPath)
              promises.push(this.removePhoto(photo.thumbnailFullPath));
            if (photo.photoFullPath)
              promises.push(this.removePhoto(photo.photoFullPath));
            promises.push(this.deleteProjectPhoto(photo.id));
          }
        }

        promises.push(this.deleteTempleCard(this.card.id));

        Promise.all(promises).then(() => {
          this.$f7.preloader.hide();
          this.unbindCard();
          this.unbindProjectPhotoList();
          this.unbindProjectPhotoAlbumList();
        });
      }
    },
    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    openEditContact() {
      this.$refs.editContactPopup.open(this.contactId);
    },
    onInputAddress({ prop, value }) {
      this.projectAddress[prop] = value;
    },
    onSelectAddress(address) {
      this.projectAddress = address;
    },
    inValid() {
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) {
        return true;
      }
      return false;
    },

    async generateContractWebUrl(contractId) {
      const projectResponse = await axiosService.post(
        "/tenant/get-anonymous-token",
        {
          entity: "project",
          entityId: this.card.id
        }
      );
      const contractResponse = await axiosService.post(
        "/tenant/get-anonymous-token",
        {
          entity: "contract",
          entityId: contractId
        }
      );
      if (
        projectResponse &&
        projectResponse.status === 200 &&
        contractResponse &&
        contractResponse.status === 200
      ) {
        return `${import.meta.env.VITE_HOST_DOMAIN}/contract-view/?projectToken=${projectResponse.data}&projectId=${this.card.id}&contractToken=${contractResponse.data}&contractId=${contractId}`;
      }
      return "";
    },

    async save() {
      if (this.assigneeIDs.length === 0) {
        this.assigneeIDs.push(auth.currentUser.uid);
      }
      this.isDeleteDraftProject = false;
      let commentAndNote = [];
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Leads Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }
      let actionsHistory = [];
      actionsHistory.push({
        nextAction: "Job Created",
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: ""
      });
      let title = "";
      if (!_.isEmpty(this.contact)) {
        title = `${this.getContactName(this.contact)} - `;
      }
      title += getFullAddress(this.projectAddress);
      const data = {
        businessCode: BUSINESS_CODE_RESIDENTIAL,
        title: title,
        contactId: this.contactId || null,
        projectAddress: this.projectAddress,
        leadSource: this.leadSource,
        inspectDate: toDateFirebase(this.inspectDate),
        inspectTime: this.inspectTime,
        assigneeIDs: this.assigneeIDs || [],
        commentAndNote,
        actionsHistory,
        salesRep: auth.currentUser.displayName || auth.currentUser.email,
        dueDate: toDateFirebase(this.inspectDate),
        status: "open",
        actions: [this.actionLead.id], // code 800 is column Leads
        insuranceCompanyId: this.insuranceCompanyId || "",
        inspectorName: this.inspectorName
      };
      // send mail and create activity for user
      let promises = [];
      if (!_.isEmpty(this.assigneeIDs)) {
        for (const uid of this.assigneeIDs) {
          let receiverInfo = this.userInfo(uid);
          let receiver = `${receiverInfo.displayName || ""} <${receiverInfo.email
            }>`;
          promises.push(this.sendMail(receiver));
        }
        promises.push(
          this.createNotificationByType({
            data: {
              assignees: this.assigneeIDs,
              project: {
                title: title,
                id: this.card && this.card.id,
                type: "project",
                entityName: COLLECTION_OPRATION_CARD
              }
            },
            type: "assign-user"
          })
        );
      }

      promises.push(
        this.updateProject({
          tenantId: this.user.tenantId,
          id: this.card && this.card.id,
          project: data
        })
      );
      return Promise.all(promises);
    },
    async onDone() {
      if (this.inValid()) return;
      this.$f7.preloader.show();
      const res = await this.save();

      // create contract
      const contractSections = this.parseContractContent(true);
      const contract = {
        contractChecklist: CONTRACT_CHECKLIST,
        contractTitle: `Roofing Build Contract - ${getFullAddress(
          this.projectAddress
        )}`,
        status: "draft",
        project: {
          id: this.card.id,
          cardNumber: !_.isEmpty(res) ? res[res.length - 1].docNumber : "",
          title: getFullAddress(this.projectAddress) || "",
          businessCode: BUSINESS_CODE_RESIDENTIAL
        },
        projectId: this.card.id,
        sections: contractSections
      };
      if (!_.isEmpty(this.contact)) {
        contract.contactId = this.contact.id;
        contract.contactName =
          `${this.contact.firstName} ${this.contact.lastName}`.trim() || "";
        contract.project.title = `${contract.contactName} - ${contract.project.title}`;
      }
      const newContract = await this.createNewContract(contract);
      // update contractWebUrl for newContract
      const url = await this.generateContractWebUrl(newContract.id);
      await this.updateContract({
        id: newContract.id,
        doc: {
          contractWebUrl: url
        }
      });

      // save contractId, contractNumber for project
      await this.updateCard({
        id: this.card.id,
        doc: {
          contractId: newContract.id,
          contractNumber: newContract.docNumber,
          contractTitle: `Roofing Build Contract - ${getFullAddress(
            this.projectAddress
          )}`
        }
      });

      // scroll to top
      this.setCurrentActionIdsAfterMoveOrAdd([this.actionLead.id]);
      this.closePopup();
      this.$f7.preloader.hide();
      return res;
    },

    async onNext() {
      const res = await this.onDone();
      if (!_.isEmpty(res)) {
        const cardNumber = res[res.length - 1].docNumber;
        if (this.$f7route.route.meta.boardType === "grid") {
          this.setResidentialGridCurrentTab(this.actionLead.code);
        }
        this.$emit("openCardDetailsPopup", {
          actionId: this.actionLead.id,
          cardNumber
        });
      }
    },

    sendMail(receiver) {
      return axiosService
        .post("/notification/push-email", {
          to: receiver,
          subject: {
            assigner: auth.currentUser.displayName
          },
          template: "assign-project",
          content: {
            assigner: auth.currentUser.displayName,
            project_title: getFullAddress(this.projectAddress) || ""
          },
          attachments: [],
          scheduleSendingTime: ""
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },
    navigateToSelectCompany() {
      this.$refs.selectCompany.open();
    },
    openAddNewCompany() {
      this.$refs.selectCompany.open(true);
    },
    navigateToSelectInspector() {
      this.$refs.inspectorPopup.open();
    },
    selectInspector(userName) {
      this.inspectorName = userName;
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      inspectDate: {
        required
      },
      inspectTime: {
        required
      },
      leadSource: {
        required
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.inspect-time-desktop ::v-deep input[type="time"] {
  width: 100px;
}

.inspect-time-android ::v-deep input[type="time"] {
  width: 100%;
}

.inspect-time-ios ::v-deep input[type="time"]::-webkit-date-and-time-value {
  text-align: left;
  width: 100%;
}

.inspect-time-web ::v-deep input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--f7-color-text-neutral);
}
</style>
