<template>
  <div>
    <f7-block-header>Project Checklist</f7-block-header>
    <f7-list accordion-list class="accordion-schedule">
      <f7-list-item accordion-item class="accordion-schedule-item">
        <div class="title-accordion" slot="title">Completed Date</div>
        <f7-checkbox class="schedule-checkbox" :checked="completionTaskStatus.completedDate === true"
          @change="onUpdateTaskStatus('completedDate', $event.target.checked)"></f7-checkbox>
        <f7-accordion-content>
          <f7-list class="no-margin">
            <f7-list-input label="Completed Date" type="datepicker" :calendar-params="{
              backdrop: true,
              header: true,
              footer: false,
              openIn: 'customModal',
              dateFormat: 'mm/dd/yyyy',
              disabled: {
                from: null,
                to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
              }
            }" placeholder="Enter completed date" :value="completedDate || []" @calendar:change="
              onUpdateDate($event);
            $f7.calendar.close();
            " error-message-force validate validate-on-blur :error-message="requireErrorMessage('completedDate')">
              <required-asterisk slot="label"></required-asterisk>
              <input-icon slot="media" icon="timer"></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
        <div slot="after" class="error-message">
          {{ requireErrorMessage("completedDate") }}
        </div>
      </f7-list-item>
      <f7-list-item accordion-item class="accordion-schedule-item">
        <div class="title-accordion" slot="title">QA Checklist</div>
        <f7-checkbox class="schedule-checkbox" :checked="completionTaskStatus.qaChecklist === true"
          @change="onUpdateTaskStatus('qaChecklist', $event.target.checked)"></f7-checkbox>
        <f7-accordion-content>
          <f7-row class="margin">
            <f7-button small outline @click="openAddQaChecklistPopup">Setting QA Checklist</f7-button>
          </f7-row>
          <data-table :headers="headers" :items="qaChecklist || []" :pageSize="(qaChecklist || []).length || 0"
            class="wrap-text-table">
            <template v-slot:body="{ item, index }">
              <template v-if="item.isChoose">
                <td class="label-cell">{{ item.title }}</td>
                <td class="text-align-center" v-for="(v, i) in ['pass', 'fail', 'na']" :key="i">
                  <f7-radio :name="`radio-${index}`" :value="v" :checked="item.value === v" @change="
                    editQaChecklist({ index, field: 'value', value: v })
                    "></f7-radio>
                </td>
              </template>
            </template>
          </data-table>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item class="accordion-schedule-item">
        <div class="title-accordion" slot="title">Photos Completion</div>
        <f7-checkbox class="schedule-checkbox" :checked="completionTaskStatus.photosCompletion === true" @change="
          onUpdateTaskStatus('photosCompletion', $event.target.checked)
          "></f7-checkbox>
        <f7-accordion-content>
          <photo-section-residential ref="photoSectionResidential" :actionId="action.id"></photo-section-residential>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item class="accordion-schedule-item">
        <div class="title-accordion" slot="title">Invoice</div>
        <f7-checkbox class="schedule-checkbox" :checked="completionTaskStatus.invoice === true"
          @change="onUpdateTaskStatus('invoice', $event.target.checked)"></f7-checkbox>
        <f7-accordion-content>
          <invoice-section ref="invoiceSection" @closePopup="closeActionPopup"></invoice-section>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item class="accordion-schedule-item">
        <div class="title-accordion" slot="title">
          Certificate of Completion
        </div>
        <f7-checkbox class="schedule-checkbox" :checked="completionTaskStatus.certificateOfCompletion === true" @change="
          onUpdateTaskStatus('certificateOfCompletion', $event.target.checked)
          "></f7-checkbox>
        <f7-accordion-content>
          <certificate-section ref="certificateSection"></certificate-section>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <!-- set parent div to relative and z-index is 0 to show progress bar when upload photo -->
    <div style="position: relative; z-index: 0">
      <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
        add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
        @input="setAttachment('attachmentFiles', $event)"></attachment-input>
      <user-input @input="setAssigneeIDs($event)" :value="displayCard.assigneeIDs"></user-input>
      <note-input :value="displayCard.commentAndNote" @change="saveNote($event)"></note-input>
    </div>

    <date-popup ref="collectionDueDatePopup" title="Enter Collection Due Date" label="Collection Due Date"
      v-model="dueDate" @done="resolveCompletion" not-allow-past></date-popup>
    <setting-qa-checklist-popup ref="settingQaChecklistPopup" :items="qaChecklist"
      @addNewQaChecklist="addNewQaChecklist" @editQaChecklist="editQaChecklist"
      @removeQaChecklist="removeQaChecklist"></setting-qa-checklist-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import methodsMixins from "../../mixin/methods";
import residentialMixins from "../../mixin/residential";
import NoteInput from "../input/NoteInput.vue";
import UserInput from "../input/UserInput.vue";
import InputIcon from "../icon/InputIcon.vue";
import AttachmentInput from "../../../dashboard/components/input/AttachmentInput.vue";
import _ from "lodash";
import PhotoSectionResidential from "../photo/PhotoSectionResidential.vue";
import InvoiceSection from "../invoice/InvoiceSection.vue";
import DataTable from "@/components/datatables";
import DatePopup from "../popup/DatePopup.vue";
import SettingQaChecklistPopup from "../popup/SettingQaChecklistPopup.vue";
import CertificateSection from "../sections/CertificateSection.vue";
import PurchaseOrderInput from "../input/PurchaseOrderInput.vue";

import moment from "moment";
import { toDateFirebase, toDateCalendar } from "../../../../utility/datetime";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { sortEarliest } from "../../../../utility/date-time-tool";
import { firebase, auth } from "../../../../services/firebase.service";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    UserInput,
    InputIcon,
    NoteInput,
    AttachmentInput,
    PhotoSectionResidential,
    InvoiceSection,
    DataTable,
    DatePopup,
    SettingQaChecklistPopup,
    CertificateSection,
    PurchaseOrderInput
  },
  mixins: [methodsMixins, residentialMixins],
  data: () => {
    return {
      currentResponse: {},
      displayCard: {},

      completedDate: [],
      qaChecklist: [],
      completionTaskStatus: {
        completedDate: false,
        qaChecklist: false,
        photosCompletion: false,
        invoice: false,
        certificateOfCompletion: false
      },

      dueDate: []
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters("dashboard/project", ["card", "action"]),
    ...mapGetters("dashboard/qa-checklist", ["qaChecklistList"]),

    requireErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },
    headers() {
      return [
        {
          text: "Quality Assurance/Post Construction Checklist",
          align: "left"
        },
        {
          text: "Pass",
          align: "center"
        },
        {
          text: "Fail",
          align: "center"
        },
        {
          text: "N/A",
          align: "center"
        }
      ];
    },
    qaChecklistIsChoose() {
      return this.qaChecklist.filter(r => r.isChoose === true);
    }
  },
  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),
    ...mapActions("dashboard/qa-checklist", ["bindQaChecklistListBys"]),
    ...mapActions("dashboard/finance", ["initInvoice"]),

    initData() {
      if (
        !_.isEmpty(this.$refs.photoSectionResidential) &&
        !_.isEmpty(this.$refs.certificateSection)
      ) {
        this.$refs.photoSectionResidential.initData(this.displayCard.id);
        this.$refs.certificateSection.initData(this.displayCard.id);
      }
    },
    async initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        if (_.isEmpty(this.qaChecklistList)) {
          this.$f7.preloader.show();
          await this.bindQaChecklistListBys([
            {
              prop: "isDeleted",
              val: false,
              op: "=="
            },
            {
              prop: "isDefault",
              val: true,
              op: "=="
            }
          ]);
          this.$f7.preloader.hide();
        }
        this.displayCard = _.cloneDeep(this.card);
        this.initData();
        if (
          this.displayCard.completedDate &&
          (_.isEmpty(this.completedDate) ||
            (!_.isEmpty(this.completedDate) &&
              !moment(this.completedDate[0]).isSame(
                moment(toDateCalendar(this.displayCard.completedDate)[0]),
                "day"
              )))
        ) {
          this.completedDate = toDateCalendar(this.displayCard.completedDate);
        }
        if (this.displayCard.completionTaskStatus) {
          this.completionTaskStatus = this.displayCard.completionTaskStatus;
        }
        this.qaChecklist =
          this.displayCard.qaChecklist || this.getDefaultQaChecklist();
      }
    },

    getDefaultQaChecklist() {
      let defaultList = sortEarliest(this.qaChecklistList || []);
      defaultList = defaultList.map(r => ({
        title: r.title,
        isChoose: true,
        value: null
      }));
      return defaultList;
    },

    openAddQaChecklistPopup() {
      this.$refs.settingQaChecklistPopup.open();
    },

    addNewQaChecklist(title) {
      this.qaChecklist.push({
        title,
        isChoose: true,
        value: null
      });
      this.onUpdateCard("qaChecklist", this.qaChecklist);
    },

    editQaChecklist({ index, field, value }) {
      this.qaChecklist[index] = {
        ...this.qaChecklist[index],
        value:
          field === "isChoose" && !value ? null : this.qaChecklist[index].value,
        [field]: value
      };
      this.onUpdateCard("qaChecklist", this.qaChecklist);
    },

    removeQaChecklist(index) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Remove Qa Checklist",
        content: "Are you sure you want to remove this qa checklist?",
        textButton: "Remove",
        onClick: (_sefl, btnIndex) => {
          if (btnIndex === 0) {
            _sefl.app.dialog.close();
          } else if (btnIndex === 1) {
            app.qaChecklist.splice(index, 1);
            app.onUpdateCard("qaChecklist", app.qaChecklist).then(() => {
              _sefl.app.dialog.close();
            });
          }
        }
      });
    },

    onUpdateTaskStatus(field, value) {
      this.completionTaskStatus = {
        ...this.completionTaskStatus,
        [field]: value
      };
      this.onUpdateCard("completionTaskStatus", this.completionTaskStatus);
    },

    onUpdateDate(value) {
      if (!_.isEmpty(value)) {
        if (
          _.isEmpty(this.completedDate) ||
          (!_.isEmpty(this.completedDate) &&
            !moment(value[0]).isSame(moment(this.completedDate[0]), "day"))
        ) {
          this.completedDate = value;
          this.onUpdateCard("completedDate", toDateFirebase(value));
          this.onUpdateCard("dueDate", toDateFirebase(value));
        }
      }
    },
    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      return this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value
        }
      }).finally(() => {
        isShowLoading && this.$f7.preloader.hide();
      });
    },

    // Implement do something before resolve action
    async doAction(response) {
      if (response.code === "move-to-collection") {
        this.v$.$touch();
        if (this.v$.$invalid) return;

        if (
          !this.completionTaskStatus.completedDate ||
          !this.completionTaskStatus.qaChecklist ||
          !this.completionTaskStatus.photosCompletion ||
          !this.completionTaskStatus.invoice ||
          !this.completionTaskStatus.certificateOfCompletion
        ) {
          this.$ri.dialog.openWarningDialog({
            title: "Please make sure all checkboxes are checked.",
            content: "",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
          return;
        }
        this.currentResponse = response;
        this.$refs.collectionDueDatePopup.open();
      }
    },

    resolveCompletion() {
      this.displayCard.dueDate = this.dueDate;
      this.resolve();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      let doc = {};
      doc.dueDate = toDateFirebase(this.displayCard.dueDate);
      await this.updateCard({
        id: this.card.id,
        doc
      }).then(() => {
        this.displayCard = {};
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote =
          _.cloneDeep(this.displayCard.commentAndNote) || [];
        commentAndNote.push({
          code: "note",
          title: "Completion Note",
          htmlContent: event,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

        this.onUpdateCard("commentAndNote", commentAndNote);
      }
    },

    navigateToAddNewPO() {
      this.$refs.addNewPO.openPopup();
    },
    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      completedDate: {
        required
      }
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    },
    "displayCard.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.initInvoice(val);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
//.accordion-schedule ::v-deep {
//.accordion-schedule-item {
//background-color: #f3f3f3;
//}
//}
.schedule-checkbox {
  z-index: 9999;
  margin: -24px 0 0 15px;
  position: absolute;
}

.title-accordion {
  margin-left: 25px;
}

.accordion-schedule-item ::v-deep {
  .header-check-list {
    background-color: #f3f3f3;
  }
}

.wrap-text-table {
  &::v-deep th {
    font-size: 14px;
    font-weight: bold;
  }
}

.error-message {
  font-size: 12px;
  color: var(--f7-theme-color);
}

.accordion-item-content {
  background-color: var(--f7-color-bg-7-neutral);
}
</style>
