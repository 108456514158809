<template>
  <response-button
    ref="responseButton"
    :responses="responses"
    :isShareLink="false"
    v-on="$listeners"
  ></response-button>
</template>

<script>
import ResponseButton from "../button/ResponseButton.vue";
import {
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_WAITING,
  ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
  ACTION_CODE_PO_PARTIAL_RECEIVED,
  ACTION_CODE_PO_RECEIVED,
  ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
  ACTION_CODE_PO_REQUEST_CANCEL,
  ENTITY_PURCHASE_ORDER
} from "../../../../utility/const";

export default {
  components: { ResponseButton },

  props: {
    // actionCodes: { type: Array, default: () => [] },
    workOrderTask: { type: Object, default: () => {} },
    response: { type: Array, default: () => [] }
  },

  computed: {
    /**
     * Calculate the respone of PO task
     */
    responses() {
      return this.getResponses(this.response, this.workOrderTask);
    }
  },

  methods: {
    getResponses(responses, purchaseOrder) {
      return responses.filter(item => {
        return (
          item.taskType === ENTITY_PURCHASE_ORDER &&
          ((purchaseOrder.status === STATUS_PO_WAITING &&
            [
              ACTION_CODE_PO_RECEIVED,
              ACTION_CODE_PO_PARTIAL_RECEIVED,
              ACTION_CODE_PO_REQUEST_CANCEL
            ].includes(item.code)) ||
            (purchaseOrder.status === STATUS_PO_REQUEST_CANCEL &&
              [
                ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
                ACTION_CODE_PO_REJECT_REQUEST_CANCEL
              ].includes(item.code)) ||
            purchaseOrder.status === STATUS_PO_DRAFT)
        );
      });
    }
  }
};
</script>

<style></style>
