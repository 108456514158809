<template>
  <signature-popup ref="signaturePopup" :isShowFullName="false" @saveSignature="save"
    :isUserSign="isUserSign"></signature-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { firebase } from "@/services/firebase.service";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import SignaturePopup from "../../../../components/popups/SignaturePopup.vue";

export default {
  components: { SignaturePopup },
  props: {
    letter: Object,
    isUserSign: { type: Boolean, default: false }
  },
  data() {
    return {
      additionalData: {}
    };
  },
  computed: {
    ...mapGetters("setting/app/profile", ["currentUser"]),
    ...mapGetters("common/user-signature", ["signatureList"]),
    signatureSection() {
      return this.letter.sections.find(r => r.sectionId === "content-section");
    },

    indexOfSignatureSection() {
      return this.letter.sections.findIndex(
        r => r.sectionId === "content-section"
      );
    }
  },
  methods: {
    ...mapActions("common/user-signature", ["getSignListBys"]),

    async openPopup() {
      const letterSign = await this.$refs.signaturePopup.getAdditionalData();
      this.additionalData = this.letter.userIp
        ? {
          signerIp: this.letter.userIp,
          time: firebase.firestore.Timestamp.now()
        }
        : letterSign;
      if (this.currentUser.uid) {
        this.$f7.preloader.show();
        await this.getSignListBys(this.currentUser.uid);
        let userHasSignature = this.signatureList[0];
        if (userHasSignature && !this.isUserSign) {
          this.$f7.preloader.hide();
          this.checkAskSignBefore(() => {
            this.save({
              imageBase64: userHasSignature.userSignature,
              fullName: userHasSignature.userSignName,
              currentUser: this.currentUser,
              signerIp: this.additionalData,
              callback: () => { }
            });
          }, userHasSignature);
        } else {
          this.$f7.preloader.hide();
          this.$refs.signaturePopup.openPopup();
        }
      } else {
        this.$f7.preloader.hide();
        this.$refs.signaturePopup.openPopup();
      }
    },

    showErrorDialog(errorMessage) {
      this.$ri.dialog.openErrorDialog({
        title: "Error",
        content: errorMessage,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        }
      });
    },

    async uploadSignatureImage(imageBase64) {
      try {
        const response = await axios.post(
          `letter/upload-file/${this.letter.id}`,
          {
            projectId: this.letter.projectId,
            base64Data: imageBase64.split(",")[1],
            fileName: "signature",
            meta: { type: "image/png" }
          },
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token
            }
          }
        );
        return response.data;
      } catch (error) {
        return { success: false, message: error.message };
      }
    },

    async updateLetterDoc(doc) {
      try {
        const response = await axios.post(
          `letter/update/${this.letter.id}`,
          doc,
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token
            }
          }
        );
        return response.data;
      } catch (error) {
        return { success: false, message: error.message };
      }
    },

    checkAskSignBefore(callback, userHasSignature) {
      const app = this;
      const content = `<div style="margin-top: 16px">
        <div style="margin-bottom: 14px; width: 100%; border-radius: 4px; overflow: hidden; background-color: var(--f7-color-bg-13-neutral)">
            <img style="max-width: 257px; max-height: 200px; width: 100%; background-color: var(--f7-color-bg-13-neutral)" src="${userHasSignature.userSignature}" alt=""/>
          </div>
          <div style="text-align: center;">${userHasSignature.userSignName}</div>
        </div>`;
      app.$ri.dialog.openInfoDialog({
        title: "Do you want to use the signature that has been saved before?",
        content,
        cancelButtonCaption: "Sign again",
        textButton: "Use it",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
            this.$refs.signaturePopup.openPopup();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            if (callback) callback();
          }
        }
      });
    },

    async save({ imageBase64, fullName, currentUser, signerIp, callback }) {
      this.$f7.preloader.show();
      if (this.isUserSign) {
        signerIp = _.cloneDeep(this.additionalData);
      }
      const replaceSignatureHtml = (html, newValue) => {
        const regex = new RegExp(
          `<div[^>]*style="margin-top: 15px; height: 150px"[^>]*class="signature-image"[^>]*>.*?</div>`,
          "g"
        );
        const matches = html.match(regex);
        if (matches && matches.length > 0) {
          html = html.replace(matches[0], newValue);
          for (let i = 1; i < matches.length; i++) {
            html = html.replace(matches[i], "");
          }
        }
        return html;
      };
      const responseUpload = await this.uploadSignatureImage(imageBase64);
      if (!responseUpload.success) {
        this.showErrorDialog(responseUpload.message);
        this.$f7.preloader.hide();
        return;
      }
      let sections = _.cloneDeep(this.letter.sections);

      let htmlValue = _.cloneDeep(this.signatureSection).htmlValue;
      const newHtmlValue = `<div style="width: 100%; display: flex; align-items: flex-end; justify-content: space-between; padding-bottom: 12px;">
        <div>
          <img style="max-width: 450px; max-height: 200px" src="${
            responseUpload.data.url
          }" alt=""/>
          <div style="text-align: center;">${fullName}</div>
        </div>
        <div style="text-align: right;">
          <span style="font-size: 16px;">Date: ${moment().format(
            "MM/DD/YYYY"
          )}</span>
        <div style="font-size: 12px; color: #A0A0A0;">IP: ${
          signerIp.signerIp
        }</div>
          </div>
      </div>`;
      htmlValue = replaceSignatureHtml(htmlValue, newHtmlValue);
      sections = sections.filter(
        section => section.sectionId !== this.signatureSection.sectionId
      );
      let newSection = _.cloneDeep(this.signatureSection);
      newSection.htmlValue = htmlValue;
      sections.splice(this.indexOfSignatureSection, 0, newSection);
      let i = 0;
      sections.forEach(section => {
        section.index = i++;
      });
      const responseUpdateLetter = await this.updateLetterDoc({
        signatureImageUrl: responseUpload.data.url,
        signatureImageFullPath: responseUpload.data.fullPath,
        signatureAt: firebase.firestore.Timestamp.now(),
        userSignId: currentUser.uid ? currentUser.uid : null,
        signerIp: signerIp,
        status: "signed",
        sections
      });
      if (!responseUpdateLetter.success) {
        this.showErrorDialog(responseUpdateLetter.message);
        this.$f7.preloader.hide();
        return;
      }
      this.$f7.preloader.hide();
      callback();
      this.$emit("reloadPages");
    }
  }
};
</script>
