export default {
  settingList: [],
  setting: {},
  paymentHistory: [],
  listFailInvoice: [],
  listPaymentMethod: {},
  defaultPaymentMethod: null

  // isMasterDetailLayout: true,
  // isShowingMasterPage: true
};
