import * as types from "./types";
import Vue from "vue";
import _ from "lodash";

export default {
  [types.SET_PROPOSAL_SECTION](state, { index, value }) {
    const section = state.proposal.sections.find(
      section => section.index === index
    );
    if (section) {
      section.htmlValue = value;
    }
  },
  [types.SET_ITEM_PROPOSAL](state, { index, value }) {
    const sectionIndex = state.proposal.sections.findIndex(
      section => section.index === index
    );
    if (sectionIndex !== -1) {
      if (value) {
        Vue.set(state.proposal.sections, sectionIndex, {
          ...state.proposal.sections[sectionIndex],
          ...value
        });
      } else {
        const newSection = _.cloneDeep(
          state.proposal.sections.filter(section => section.index !== index)
        );
        newSection.sort((a, b) => a.index - b.index);
        for (let i = 0; i < newSection.length; i++) {
          newSection[i].index = i;
        }
        Vue.set(state.proposal, "sections", newSection);
      }
    }
  },
  [types.SET_SELECTED_STATUS_FILTER](state, payload) {
    Vue.set(state, "selectedStatusFilter", payload);
  },
  [types.SET_PROPOSAL_FIELD](state, payload) {
    Vue.set(state.proposal, payload.fieldName, payload.value);
  },
  [types.SET_PROPOSAL](state, payload) {
    Vue.set(state, "proposal", payload);
  },
  [types.SET_NEW_PROPOSAL_FIELD](state, payload) {
    Vue.set(state.proposalNew, payload.fieldName, payload.value);
  },
  [types.SET_NEW_PROPOSAL](state, payload) {
    Vue.set(state, "proposalNew", payload);
  },
  [types.SET_IMAGES_TO_DELETE](state, payload) {
    Vue.set(state, "imagesToDelete", payload);
  },
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state,
      "hits",
      hits.map(r => {
        delete r._highlightResult;
        delete r.objectID;
        return r;
      })
    );
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, "hitsPerPage", payload);
  },

  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, "searchText", payload);
  },
  [types.SET_PROPOSAL_TYPE](state, payload) {
    Vue.set(state, "proposalType", payload);
  },

  // Allow search desc only
  // [types.SET_SEARCH_ORDER](state, payload) {
  //   Vue.set(state, "order", payload);
  // },

  [types.RESET_SEARCH](state) {
    Vue.set(state, "order", "desc");
    Vue.set(state, "searchText", "");

    Vue.set(state, "hits", []);
    Vue.set(state, "hitsPerPage", 25);
    Vue.set(state, "nbHits", 0);
    Vue.set(state, "nbPages", 0);
    Vue.set(state, "page", 0);
  },
  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },

  [types.SET_PARAMS_BACK](state, payload) {
    Vue.set(state, "paramsBack", payload);
  },
  [types.SET_QUERY_BACK](state, payload) {
    Vue.set(state, "queryBack", payload);
  },
  [types.SET_TEMP_PROPOSAL](state, payload) {
    Vue.set(state, "tempProposal", payload);
  },
  [types.CLEAR_TEMP_PROPOSAL](state) {
    Vue.set(state, "tempProposal", {});
  },
  [types.SET_IS_CHANGE_NAVIGATE](state, payload) {
    Vue.set(state, "isChangeProposalTypeAndNavigate", payload);
  }
};
