import axiosService from "./axios.service";
import "firebase/auth";
import { firebase } from "./firebase.service";

import _ from "lodash";
import {
  TAX_MATERIAL_ONLY,
  TAX_PROFIT,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  WORK_TYPE_RECOVER
} from "@/utility/const";

const auth = firebase.auth();

const addEstimateDoc = (tenantId, data) => {
  data.createdBy = auth.currentUser
    ? auth.currentUser.displayName || auth.currentUser.email
    : "";
  data.id = "";
  data.isDeleted = false;
  return axiosService.post("/estimate/add", { tenantId, data }).then(res => {
    return res.data;
  });
};

const deleteEstimateDoc = (tenantId, estimateId) => {
  return axiosService
    .delete(`/estimate/${estimateId}/tenant/${tenantId}`)
    .then(res => {
      return res.data;
    });
};

const checkApplyTaxForMaterial = ({ project, building, estimate }) => {
  if (!_.isEmpty(project)) {
    const businessCode = project.businessCode || "";
    const taxExempt = !!project.taxExempt;
    const resalesCertificate = !!project.resalesCertificate;

    //! If customer has tax exempt OR resales certificate => NO Material tax
    if (taxExempt || resalesCertificate) {
      return false;
    }

    const workType = building ? building.workType || "" : "";
    const taxApplyType = estimate ? estimate.taxApplyType || "" : "";

    switch (businessCode) {
      //! Only commercial projects with work type == RECOVER have Material TAX
      case BUSINESS_CODE_COMMERCIAL:
        if (workType === WORK_TYPE_RECOVER) {
          return true;
        }
        return false;

      //! All residential projects have NO Material tax
      case BUSINESS_CODE_RESIDENTIAL:
        return false;

      //! All service projects HAVE Material TAX (both Material only and Contract total)
      case BUSINESS_CODE_SERVICE:
        if (taxApplyType === TAX_MATERIAL_ONLY) {
          return true;
        } else if (taxApplyType === TAX_PROFIT) {
          return true;
        }
    }
  }

  return false;
};

const checkApplyTaxForLabor = ({ project, building, estimate }) => {
  if (!_.isEmpty(project)) {
    const businessCode = project.businessCode || "";
    const taxExempt = !!project.taxExempt;
    const resalesCertificate = !!project.resalesCertificate;

    //! If customer has tax exempt OR resales certificate => NO Labor tax
    if (taxExempt || resalesCertificate) {
      return false;
    }

    const workType = building ? building.workType || "" : "";
    const taxApplyType = estimate ? estimate.taxApplyType || "" : "";

    switch (businessCode) {
      //! Only commercial projects with work type == RECOVER have Labor TAX
      case BUSINESS_CODE_COMMERCIAL:
        if (workType === WORK_TYPE_RECOVER) {
          return true;
        }
        return false;

      //! All residential projects have NO Labor tax
      case BUSINESS_CODE_RESIDENTIAL:
        return false;

      //! Service projects only HAVE Labor TAX with tax apply type == Contract total
      case BUSINESS_CODE_SERVICE:
        if (taxApplyType === TAX_MATERIAL_ONLY) {
          return false;
        } else if (taxApplyType === TAX_PROFIT) {
          return true;
        }
    }
  }

  return false;
};

export default {
  addEstimateDoc,
  deleteEstimateDoc,
  checkApplyTaxForMaterial,
  checkApplyTaxForLabor
};
