import axiosService from "../../../services/axios.service";

const updateUserProfile = async userInfo => {
  try {
    return await axiosService.put(`/profile`, { userInfo }).then(result => {
      return result.data.user;
    });
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const getUserProfile = async () => {
  try {
    return await axiosService.get(`/profile`).then(result => {
      return result.data;
    });
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const updateAvatarRef = async fullPath => {
  try {
    return await axiosService
      .put(`/profile/avatar`, { fullPath })
      .then(result => {
        return result.data;
      });
  } catch (error) {
    throw new Error(error.response.data);
  }
};

const changePassword = async password => {
  try {
    return await axiosService
      .put(`/profile/change-password`, { password })
      .then(result => {
        return result.data;
      });
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export default {
  updateUserProfile,
  getUserProfile,
  updateAvatarRef,
  changePassword
};
