<template>
  <f7-row class="header-editable">
    <div class="header-editable-text">
      {{ value }}
    </div>
    <!-- <f7-link class="acts-button" :popover-open="`.popover-menu-${columnId}`">
      <f7-icon f7="ellipsis"></f7-icon>
    </f7-link>
    <f7-popover :class="`popover-menu-${columnId}`">
      <f7-list>
        <f7-list-item
          @click="$emit('edit')"
          link="#"
          popover-close
          title="Edit"
        ></f7-list-item>
        <f7-list-item
          v-if="columnIndex !== 0"
          @click="$emit('move-left')"
          link="#"
          popover-close
          title="Move left"
        ></f7-list-item>
        <f7-list-item
          v-if="columnIndex < maxColumns - 1"
          @click="$emit('move-right')"
          link="#"
          popover-close
          title="Move right"
        ></f7-list-item>
        <f7-list-item
          @click="$emit('delete')"
          link="#"
          popover-close
          title="Delete"
        ></f7-list-item>
      </f7-list>
    </f7-popover> -->
  </f7-row>
</template>
<script>
export default {
  props: {
    columnId: { type: String, default: () => null },
    value: { type: String, default: () => null },
    columnIndex: { type: Number, default: 0 },
    maxColumns: { type: Number, default: 0 }
  }
};
</script>
<style scoped>
.header-editable {
  padding: 12px 16px 0px 16px;
  align-items: center;
}
.header-editable-text {
  font-weight: bold;
}
.acts-button {
  border-radius: 4px;
  padding: 4px;
  border: 1px solid transparent;
  border-color: var(--f7-theme-color);
}
.acts-button:hover {
  opacity: 0.7;
}
</style>
