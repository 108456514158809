<template>
  <f7-popup
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="cancel"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left></f7-nav-left>
        <f7-nav-title>Select Teams</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="save()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-searchbar
        disable-button-text
        placeholder="Search Team"
        :clear-button="true"
        class="text-color-white search-list-popup"
        v-model="searchTeam"
        @input="searchTeam = $event.target.value"
        @searchbar:disable="searchTeam = ''"
      ></f7-searchbar>
      <f7-list>
        <f7-list-item
          v-for="team in data"
          :key="team.id"
          :title="team.teamName"
          @click.native="onSelect(team.id)"
        >
          <f7-checkbox
            slot="media"
            :checked="selectedTeamIds.includes(team.id)"
            @change="onSelect(team.id)"
          ></f7-checkbox>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { applyFilter } from "../../../../utility/filter-tools";

export default {
  data: () => {
    return {
      popupOpened: false,
      searchTeam: "",
      selectedTeamIds: []
    };
  },
  methods: {
    open() {
      this.popupOpened = true;
      this.selectedTeamIds = _.cloneDeep(this.teamFilter);
    },
    cancel() {
      this.popupOpened = false;
      this.searchTeam = "";
      this.selectedTeamIds = [];
    },
    onSelect(id) {
      if (this.selectedTeamIds.includes(id)) {
        var index = this.selectedTeamIds.indexOf(id);
        if (index > -1) {
          this.selectedTeamIds.splice(index, 1);
        }
      } else {
        this.selectedTeamIds.push(id);
      }
    },
    save() {
      this.$emit("selectTeam", this.selectedTeamIds);
      this.cancel();
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  },
  computed: {
    ...mapGetters("scheduling/team", ["teamList"]),
    ...mapGetters("scheduling/user", ["teamFilter"]),

    data() {
      return applyFilter(this.teamList, this.searchTeam, ["teamName"]);
    }
  }
};
</script>
<style scoped>
.mx-5 {
  margin: 0 5px;
}
</style>
