import proposal from "./proposal";
import contract from "./contract";
import estimate from "./estimate";
import property from "./property";
import company from "./company";
import project from "./project";
import proposalTemplate from "./template";
import history from "./proposal-history";
import contact from "./contact";
import photo from "./photo";
import appConstant from "./app-constant";
import priceList from "./price-list";
import album from "./album";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      proposal: proposal(tenant),
      contract: contract(tenant),
      estimate: estimate(tenant),
      property: property(tenant),
      company: company(tenant),
      project: project(tenant),
      template: proposalTemplate(tenant),
      history: history(tenant),
      contact: contact(tenant),
      photo: photo(tenant),
      album: album(tenant),
      "app-constant": appConstant(tenant),
      "price-list": priceList(tenant)
    }
  };
};
