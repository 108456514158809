import * as types from "./types";
import Vue from "vue";
export default {
  [types.SET_DESTROY_FLAG](state, payload) {
    Vue.set(state, "destroyFlag", payload);
  },
  [types.SET_CLIENT_TYPE](state, payload) {
    Vue.set(state, "clientType", payload);
  },
  [types.SET_CURRENT_VIEW](state, payload) {
    Vue.set(state, "currentView", payload);
  }
};
