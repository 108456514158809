<template>
  <f7-list
    accordion-list
    :inset="$device.desktop"
    :class="{ 'no-margin': $device.desktop }"
    class="margin-bottom"
  >
    <f7-list-item
      accordion-item
      :accordion-item-opened="photoSelected === 'photo1'"
      @accordion:opened="$emit('setPhotoSelected', 'photo1')"
      @accordion:closed="$emit('setPhotoSelected', '')"
      title="Embedded Edge"
    >
      <f7-accordion-content
        :style="
          `height: ${photoSelected === 'photo1' ? 'auto' : '0 !important'}`
        "
      >
        <f7-list inline-labels>
          <f7-list-input
            label="a1"
            type="text"
            placeholder="Enter value"
            :value="data.a1"
            @change="setField('a1', $event.target.value.trim())"
          ></f7-list-input>
          <f7-list-input
            label="b1"
            type="text"
            placeholder="Enter value"
            :value="data.b1"
            @change="setField('b1', $event.target.value.trim())"
          ></f7-list-input>
        </f7-list>
      </f7-accordion-content>
    </f7-list-item>

    <f7-list-item
      accordion-item
      :accordion-item-opened="photoSelected === 'photo2'"
      @accordion:opened="$emit('setPhotoSelected', 'photo2')"
      @accordion:closed="$emit('setPhotoSelected', '')"
      title="Flat Drip"
    >
      <f7-accordion-content
        :style="
          `height: ${photoSelected === 'photo2' ? 'auto' : '0 !important'}`
        "
      >
        <f7-list inline-labels>
          <f7-list-input
            label="a2"
            type="text"
            placeholder="Enter value"
            :value="data.a2"
            @change="setField('a2', $event.target.value.trim())"
          ></f7-list-input>
          <f7-list-input
            label="b2"
            type="text"
            placeholder="Enter value"
            :value="data.b2"
            @change="setField('b2', $event.target.value.trim())"
          ></f7-list-input>
          <f7-list-input
            label="c2"
            type="text"
            placeholder="Enter value"
            :value="data.c2"
            @change="setField('c2', $event.target.value.trim())"
          ></f7-list-input>
          <f7-list-input
            label="d2"
            type="text"
            placeholder="Enter value"
            :value="data.d2"
            @change="setField('d2', $event.target.value.trim())"
          ></f7-list-input>
        </f7-list>
      </f7-accordion-content>
    </f7-list-item>

    <f7-list-item
      accordion-item
      :accordion-item-opened="photoSelected === 'photo3'"
      @accordion:opened="$emit('setPhotoSelected', 'photo3')"
      @accordion:closed="$emit('setPhotoSelected', '')"
      title="Embedded Edge With Flat Drip"
    >
      <f7-accordion-content
        :style="
          `height: ${photoSelected === 'photo3' ? 'auto' : '0 !important'}`
        "
      >
        <f7-list inline-labels>
          <f7-list-input
            label="a3"
            type="text"
            placeholder="Enter value"
            :value="data.a3"
            @change="setField('a3', $event.target.value.trim())"
          ></f7-list-input>
          <f7-list-input
            label="b3"
            type="text"
            placeholder="Enter value"
            :value="data.b3"
            @change="setField('b3', $event.target.value.trim())"
          ></f7-list-input>
          <f7-list-input
            label="c3"
            type="text"
            placeholder="Enter value"
            :value="data.c3"
            @change="setField('c3', $event.target.value.trim())"
          ></f7-list-input>
        </f7-list>
      </f7-accordion-content>
    </f7-list-item>
  </f7-list>
</template>

<script>
export default {
  props: {
    data: Object,
    photoSelected: String
  },

  methods: {
    setField(name, value) {
      this.$emit("setFields", { name, value });
    }
  }
};
</script>
