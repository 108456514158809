<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link
      ></f7-nav-left>
      <f7-nav-title>Employee Time Log Detail </f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <time-log-detail></time-log-detail>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TimeLogDetail from "../components/details/TimeLogDetail.vue";
import _ from "lodash";

export default {
  components: {
    TimeLogDetail
  },

  computed: {
    ...mapGetters("setting/app/system", ["setting"]),
    tenantId() {
      let tenantLocal =
        localStorage.getItem("login_tenant") !== "null"
          ? localStorage.getItem("login_tenant")
          : "";
      return tenantLocal;
    }
  },

  methods: {
    ...mapActions("setting/app/system", ["bindSetting"]),
    ...mapMutations({
      setDetailUid: "time-tracking/time-tracking/setDetailUid"
    }),

    handleBack() {
      this.$f7router.back("/employees-time-logs", { force: true });
    }
  },

  created() {
    this.setDetailUid(this.$f7route.params.id);
    if (!_.isEmpty(this.setting)) {
      this.bindSetting(this.tenantId);
    }
  }
};
</script>
