<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="closePopup()"
    @popup:opened="handlePopupOpened()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ title }}
        </f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>

      <card-details-tabs
        ref="tabs"
        modelName="wo-task"
        :afterResolveTask="doAfterResolve"
        @doClosePopup="handleClosePopup"
        v-on="$listeners"
      ></card-details-tabs>
    </f7-page>
  </f7-popup>
</template>

<script>
import CardDetailsTabs from "../../components/tabs/CardDetailsTabs.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { CardDetailsTabs },

  data: () => {
    return {
      popupOpened: false,
      actionId: "",
      projectId: ""
    };
  },

  computed: {
    ...mapGetters("dashboard/project", [
      "actionByCode",
      "constructionProject",
      "constructionActions"
    ]),
    ...mapGetters("dashboard/work-order", [
      "actionByCode",
      // "workOrderAction",
      "workOrderTask"
    ]),
    ...mapGetters("dashboard/client", ["propertyList"]),

    title() {
      return `${this.currentAction(this.actionId).title || ""} - ${
        this.workOrderTask ? this.workOrderTask.taskNumber || "" : ""
      }`;
    },

    currentAction() {
      return actionId => {
        return (
          this.constructionActions.find(action => action.id === actionId) || {}
        );
      };
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "setCurrentCard",
      "setCurrentAction",
      "setCurrentResponse",
      "clearCurrentCard",

      "bindCard",
      "bindConstructionProject"
    ]),

    ...mapActions("dashboard/client", ["getPropertyList"]),

    ...mapActions("dashboard/work-order", [
      "bindWorkOrderAction",
      "bindWorkOrderTask",
      "bindWorkOrderResponse"
      // "bindWorkOrder"
    ]),

    ...mapActions("dashboard/price-list", [
      "unbindPriceListItemList",
      "unbindPriceListItem"
    ]),

    ...mapActions("dashboard/product-item", {
      unbindProductItemList: "unbind"
    }),

    handleClosePopup(cb) {
      this.popupOpened = false;
      if (cb) {
        cb();
      }
    },

    closePopup() {
      this.popupOpened = false;
      this.$f7route.query = {};
      this.unbindPriceListItem();
      this.unbindPriceListItemList();
      this.unbindProductItemList();
      this.$emit("navigateToBoard");
    },

    openPopup() {
      this.popupOpened = true;
    },

    handlePopupOpened() {
      // this.$refs.tabs.showMainTab();
    },

    initCardData({ projectId, actionId, taskId }) {
      const refs = [];
      this.actionId = actionId;
      this.projectId = projectId;
      refs.push(this.bindWorkOrderTask(taskId));
      refs.push(this.bindWorkOrderAction(actionId));
      refs.push(this.bindWorkOrderResponse(actionId));

      refs.push(this.bindCard(projectId));
      refs.push(this.bindConstructionProject(projectId));
      this.setCurrentAction(actionId);

      this.$f7.preloader.show();
      return Promise.all(refs)
        .then(async () => {
          if (_.isEmpty(this.propertyList)) {
            await this.getPropertyList();
          }

          this.$refs.tabs.showMainTab();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    onCreate() {
      if (this.$refs.currentAction) {
        this.$refs.currentAction.showPopup();
      }
    },

    doAfterResolve() {}
  }
};
</script>

<style></style>
