export default {
  productList: [],
  product: {},
  productsByPriceListRefsList: [],

  order: "asc",
  searchText: "",
  numberOfRow: 25,

  hits: [],
  hitsPerPage: 0,
  nbHits: 0,
  nbPages: 0,
  page: 0
};
