<template>
  <f7-popup :opened="popupOpened" @popup:closed="
    popupOpened = false;
  doAction();
  ">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="popupOpened = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ title }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input placeholder="Enter Due Date" :label="label" type="datepicker" :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
          disabled: notAllowPast
            ? {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
            }
            : {}
        }" :value="value" @calendar:change="
          $emit('input', $event);
        $f7.calendar.close();
        " error-message-force :error-message="dueDateErrorMessage" @blur="v$.$touch()">
          <required-asterisk slot="label"></required-asterisk>
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    value: { type: Array, default: () => [] },
    title: String,
    label: String,
    validate: { type: Boolean, default: false },
    notAllowPast: { type: Boolean, default: false }
  },

  data: () => {
    return {
      popupOpened: false,
      isDone: false
    };
  },

  computed: {
    dueDateErrorMessage() {
      if (!this.v$.value.$error) return null;
      if (this.v$.value.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    open() {
      this.popupOpened = true;
      this.isDone = false;
    },

    onDone() {
      if (this.validate) {
        this.v$.$touch();
        if (this.v$.$invalid) return;
      }

      // this.$emit("done");
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.isDone = true;
      this.popupOpened = false;
    },

    doAction() {
      if (this.isDone === true) {
        this.$emit("done");
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      value: {
        required
      }
    };
  }
};
</script>

<style></style>
