import invoices from "./invoices";
import invoiceGroup from "./invoice-group";
import recurringInvoice from "./recurring-invoices";
import invoiceTemplate from "./invoice-template";
import appConstant from "./app-constant";
import project from "./project";
import company from "./company";
import property from "./property";
import priceList from "./price-list";
import product from "./product";
import subCategory from "./sub-category";
import vendor from "./vendor";
import album from "./album";
import photo from "./photo";
import contact from "./contact";
import paymentRecord from "./payment-record";
import estimate from "./estimate";
import category from "./category";
import reportInvoice from "./report-invoice";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      invoices: invoices(tenant),
      "recurring-invoices": recurringInvoice(tenant),
      "invoice-template": invoiceTemplate(tenant),
      "app-constant": appConstant(tenant),
      project: project(tenant),
      company: company(tenant),
      property: property(tenant),
      priceList: priceList(tenant),
      product: product(tenant),
      subCategory: subCategory(tenant),
      vendor: vendor(tenant),
      album: album(tenant),
      photo: photo(tenant),
      contact: contact(tenant),
      estimate: estimate(tenant),
      "payment-record": paymentRecord(tenant),
      category: category(tenant),
      "invoice-group": invoiceGroup(tenant),
      "report-invoice": reportInvoice(tenant)
    }
  };
};
