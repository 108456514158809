import { FirebaseActions } from "../../services/firebase.service";
import { searchClient } from "../../services/search.service";
import * as types from "./types";
import * as constant from "../../utility/const";
import algoliaService from "../../services/algolia.service";
import metalSheetService from "@/services/metal-sheet.service";

export default tenant => {
  const assemblyDrawingActions = new FirebaseActions(
    `/system_client/${tenant}/assembly_drawing`,
    "assemblyDrawing"
  );
  const assemblyDrawingIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );
  return {
    updateAssemblyDrawing: assemblyDrawingActions.updateDocument,
    getAssemblyDrawingListBys: assemblyDrawingActions.getDocumentBys,

    setAssemblyDrawing({ commit }, payload) {
      commit("SET_ASSEMBLY_DRAWING", payload);
    },

    setSearchValue({ commit }, payload) {
      commit(types.SET_SEARCH_VALUE, payload);
    },
    async createNewAssemblyDrawing({ dispatch, rootGetters }, data) {
      const user = rootGetters["setting/app/profile/user"];
      const doc = await metalSheetService.addAssemblyDrawingDoc(
        user.tenantId,
        data
      );
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "assembly_drawing",
        id: doc.id
      });
      await dispatch("searchAssemblyDrawing", {});
      return doc;
    },
    async deleteAssemblyDrawing({ dispatch }, id) {
      await dispatch("updateAssemblyDrawing", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
      const data = await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "assembly_drawing",
        id
      });
      await dispatch("searchAssemblyDrawing", {});
      return data;
    },
    async updateAssemblyDrawingDoc({ dispatch }, results) {
      await dispatch("updateAssemblyDrawing", {
        id: results.id,
        doc: results.doc
      });
      const data = await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "assembly_drawing",
        id: results.id
      });
      await dispatch("searchAssemblyDrawing", {});
      return data;
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchAssemblyDrawing(
      { state, commit },
      // eslint-disable-next-line no-unused-vars
      { query, attributesToRetrieve, restrictSearchableAttributes, filters }
    ) {
      const index = assemblyDrawingIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_ASSEMBLY_DRAWING}`,
        attributesToRetrieve:
          constant.ASSEMBLY_DRAWING_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.ASSEMBLY_DRAWING_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchValue, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreAssemblyDrawing(
      { state, commit },
      {
        // eslint-disable-next-line no-unused-vars
        query,
        attributesToRetrieve,
        restrictSearchableAttributes,
        page,
        filters
      }
    ) {
      const index = assemblyDrawingIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_ASSEMBLY_DRAWING}`,
        attributesToRetrieve:
          constant.ASSEMBLY_DRAWING_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.ASSEMBLY_DRAWING_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchValue, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    }
  };
};
