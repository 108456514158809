<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input type="text" label="Meeting Schedule" readonly
        :value="toDisplayDateString(toDateFirebase(dueDate))">
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
      <f7-list-input type="text" label="Client Budget" placeholder="$ Enter Client Budget" v-model="clientBudget"
        @input="numberClient($event.target.value.trim())">
        <!-- <required-asterisk slot="label"></required-asterisk> -->
        <input-icon slot="media" icon="money_dollar_circle"></input-icon>
      </f7-list-input>

      <!-- <f7-list-input
        label="Number of Buildings/Sections"
        :value="numberOfBuildsOrSections"
        type="number"
        @focus="
          numberOfBuildsOrSections == 0
            ? (numberOfBuildsOrSections = null)
            : numberOfBuildsOrSections
        "
        @blur="numberOfBuildsOrSections = Number(numberOfBuildsOrSections)"
        @input="numberOfBuildsOrSections = $event.target.value.trim()"
        error-message-force
        :error-message="buildsOrSectionsErrorMessage"
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="number_circle"></input-icon>
      </f7-list-input> -->
    </f7-list>

    <f7-list>
      <f7-list-item title="Tax Exempt">
        <f7-toggle slot="after" :checked="displayCard.taxExempt"
          @toggle:change="changeToggle('taxExempt', $event)"></f7-toggle>
        <input-icon slot="media" icon="xmark_seal"></input-icon>
      </f7-list-item>
    </f7-list>
    <attachment-input v-show="displayCard.taxExempt" :project-id="displayCard.id" add-button-title="Upload Document"
      attachment-type="taxExempt" :value="displayCard.attachmentFiles" @input="setAttachment('attachmentFiles', $event)"
      :no-margin-top="true"></attachment-input>

    <f7-list>
      <f7-list-item title="Resale Certificate">
        <f7-toggle slot="after" :checked="displayCard.resalesCertificate"
          @toggle:change="changeToggle('resalesCertificate', $event)"></f7-toggle>
        <input-icon slot="media" icon="viewfinder_circle"></input-icon>
      </f7-list-item>
    </f7-list>
    <attachment-input v-show="displayCard.resalesCertificate" :project-id="displayCard.id"
      add-button-title="Upload Document" attachment-type="resalesCertificate" :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)" :no-margin-top="true"></attachment-input>

    <!-- attachment -->
    <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
      add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)" @save="save"></attachment-input>

    <user-input :value="displayCard.assigneeIDs" @input="setAssigneeIDs($event)"></user-input>

    <f7-list>
      <f7-list-input label="Note" floating-label type="textarea" resizable clear-button :value="note"
        @input="note = $event.target.value.trim()">
        <input-icon slot="media" icon="doc_text"></input-icon>
      </f7-list-input>
    </f7-list>

    <!-- Popup -->
    <date-popup ref="estimateDueDatePopup" title="Enter Estimate Due Date" label="Estimate Due Date"
      v-model="estimateDueDate" @done="resolveEstimate" validate not-allow-past></date-popup>

    <date-popup ref="meetingDueDatePopup" title="Enter Reschedule Meeting Date" label="Reschedule Meeting Date"
      v-model="rescheduleMeetingDate" @done="resolveRescheduleMeeting" validate not-allow-past></date-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import AttachmentInput from "../input/AttachmentInput.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import methodsMixins from "../../mixin/methods";
// import { required, minValue /*helpers*/ } from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    popupOpened: Boolean
  },
  mixins: [methodsMixins],

  components: { UserInput, DatePopup, InputIcon, AttachmentInput },

  data() {
    return {
      note: "",
      currentResponse: {},

      displayCard: {},
      dueDate: [],

      rescheduleMeetingDate: [],
      estimateDueDate: [],

      clientBudget: ""
      // numberOfBuildsOrSections: 0
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"])
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    changeToggle(name, value) {
      if (this.displayCard[name] == value) return;
      this.displayCard = {
        ...this.displayCard,
        [name]: value
      };
      if (this.popupOpened) {
        this.$f7.preloader.show();
        this.updateCard({
          id: this.displayCard.id,
          doc: {
            [name]: value
          }
        }).finally(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    numberClient(val) {
      this.clientBudget = this.formatPriceNumber(val);
    },
    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.displayCard.taxExempt = this.displayCard.taxExempt || false;
        this.displayCard.resalesCertificate =
          this.displayCard.resalesCertificate || false;

        this.clientBudget = this.displayCard.clientBudget
          ? this.formatPriceNumber(this.displayCard.clientBudget.toString())
          : "";
        // this.numberOfBuildsOrSections =
        //   this.displayCard.numberOfBuildsOrSections || 0;
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      // check upload document for taxExempt, resalesCertificate
      const inValidTaxExempt =
        this.displayCard.taxExempt &&
        !this.getAttachmentFilesByType("taxExempt").length;
      const inValidResalesCertificate =
        this.displayCard.resalesCertificate &&
        !this.getAttachmentFilesByType("resalesCertificate").length;
      if (inValidTaxExempt && inValidResalesCertificate) {
        this.showUploadDialog(
          response,
          "Please upload document for Tax Exempt and Resale Certificate"
        );
      } else if (inValidTaxExempt) {
        this.showUploadDialog(
          response,
          "Please upload document for Tax Exempt"
        );
      } else if (inValidResalesCertificate) {
        this.showUploadDialog(
          response,
          "Please upload document for Resale Certificate"
        );
      } else {
        this.handleAction(response);
      }
    },

    resolveRescheduleMeeting() {
      this.dueDate = this.rescheduleMeetingDate;
      this.resolve();
    },

    resolveEstimate() {
      this.dueDate = this.estimateDueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      if (this.currentResponse.code === "move-to-estimate") {
        this.v$.$touch();
        if (this.v$.$invalid) return;
      }

      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Budget Meeting Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);
      if (!_.isEmpty(this.clientBudget)) {
        const cost = this.formatDots(this.clientBudget)
          .replaceAll("$", "")
          .replaceAll(",", "");
        this.displayCard.clientBudget = parseFloat(cost) || null;
      }

      if (this.numberOfBuildsOrSections != null) {
        this.displayCard.numberOfBuildsOrSections = this.numberOfBuildsOrSections;
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    handleAction(response) {
      switch (response.code) {
        case "move-to-estimate":
          this.v$.$touch();
          if (this.v$.$invalid) return;
          this.currentResponse = response;
          this.$refs.estimateDueDatePopup.open();
          break;

        case "revisit":
          this.currentResponse = response;
          this.$refs.meetingDueDatePopup.open();
          break;
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      // clientBudget: {
      //   required,
      //   currency: helpers.regex(
      //     "currency",
      //     /^([^\d\s]{1,}\s?[+-]?)(\d{1,3})(,\d{3})*(\.\d{1,3})?$/
      //   )
      // },
      // numberOfBuildsOrSections: {
      //   required,
      //   minValue: minValue(1)
      // }
    };
  }
};
</script>

<style></style>
