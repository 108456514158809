<template>
  <f7-popup class="demo-popup" :opened="isShowPopup" @popup:closed="
    isShowPopup = false;
  isEdit = false;
  " @popup:opened="handlePopupOpened">
    <f7-page v-show="!isEdit">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Setting Qa Checklist</f7-nav-title>
      </f7-navbar>
      <div class="data-table">
        <data-table :headers="headers" :items="itemList" :pageSize="(itemList || []).length" ref="dataTable">
          <template slot="card-header">
            <f7-list>
              <f7-searchbar placeholder="Search in items" :clear-button="true" disable-button-text :value="searchValue"
                @input="searchValue = $event.target.value" @searchbar:disable="searchValue = ''"
                class="search-list-popup">
              </f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td width="40" class="text-align-left">
              <f7-checkbox :checked="item.isChoose"
                @change="selectQaChecklist(item.index, $event.target.checked)"></f7-checkbox>
            </td>
            <td class="text-align-left">
              {{ item.title }}
            </td>
            <td width="70" class="text-align-center">
              <a href="#" @click="openEditPopup(item.index)">
                <i class="f7-icons margin-right-half" style="color: red;">pencil</i>
              </a>
              <a href="#" @click="$emit('removeQaChecklist', item.index)">
                <i class="f7-icons" style="color: red;">trash</i>
              </a>
            </td>
          </template>
          <template v-slot:footer>
            <f7-list style="border-top: solid 1px var(--f7-table-cell-border-color);">
              <f7-list-item class="custom-input">
                <f7-input slot="title" placeholder="Enter new item to the checklist..." type="text" clear-button
                  :value="newQaChecklist" @input="newQaChecklist = $event.target.value.trim()">
                </f7-input>
                <f7-icon slot="media" f7="plus" color="primary"></f7-icon>
                <f7-button v-show="newQaChecklist" slot="after" small fill @click="addNewQaChecklist">Add</f7-button>
              </f7-list-item>
            </f7-list>
          </template>
        </data-table>
      </div>
    </f7-page>
    <f7-page v-show="isEdit">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="isEdit = false">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Edit Qa Checklist</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="onEdit">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <f7-list-input type="text" placeholder="Enter qa checklist" clear-button error-message-force
          :error-message="titleErrorMessage" :value="itemEdit.value"
          @input="itemEdit.value = $event.target.value.trim()"></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import DataTable from "@/components/datatables";

import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { applyFilter } from "../../../../utility/filter-tools";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    items: Array
  },
  components: {
    DataTable
  },
  data() {
    return {
      isShowPopup: false,
      newQaChecklist: "",
      isEdit: false,
      itemEdit: {},
      searchValue: ""
    };
  },
  methods: {
    open() {
      this.isShowPopup = true;
    },
    close() {
      this.isShowPopup = false;
      this.newQaChecklist = "";
      this.closeEditPopup();
    },
    closeEditPopup() {
      this.isEdit = false;
      this.itemEdit = {};
      this.searchValue = "";
      this.v$.$reset();
    },
    changeField(column, id, value) {
      const data = {
        id,
        column,
        value
      };
      this.$emit("changeField", data);
    },
    selectQaChecklist(index, value) {
      const data = {
        index,
        field: "isChoose",
        value
      };
      this.$emit("editQaChecklist", data);
    },
    addNewQaChecklist() {
      this.$emit("addNewQaChecklist", this.newQaChecklist);
      this.newQaChecklist = "";
    },
    openEditPopup(index) {
      this.isEdit = true;
      this.itemEdit = {
        index,
        field: "title",
        value: this.items[index].title
      };
    },
    onEdit() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.$emit("editQaChecklist", this.itemEdit);
      this.closeEditPopup();
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  },
  computed: {
    itemList() {
      const list = this.items.map((r, index) => ({ ...r, index }));
      return applyFilter(list, this.searchValue, ["title"]) || [];
    },
    titleErrorMessage() {
      if (!this.v$.itemEdit.value.$error) return null;
      if (this.v$.itemEdit.value.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    headers() {
      return [
        {
          text: "",
          align: "left",
          sortable: false
        },
        {
          text: "Item Name",
          value: "title",
          sortable: false,
          align: "left"
        },
        {
          text: "Action",
          sortable: false,
          align: "left"
        }
      ];
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    return {
      itemEdit: {
        value: {
          required
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.custom-input ::v-deep .item-title {
  width: 100%;
}
</style>
