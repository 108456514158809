import _ from "lodash";

import { mapActions } from "vuex";

export default {
  computed: {
    hasProject() {
      return this.$f7route.params.projectId && this.$f7route.params.actionId;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/project", [
      "getBoardById",
      "getProjectById",
      "getTaskById",
      "getActionById"
    ]),
    ...mapActions("estimate/estimate-page/estimate", [
      "addProductData",
      "removeProductData",
      "setCurrentEstimateField",
      "setCurrentView"
    ]),

    setEstimateValue(prop, value) {
      this.setCurrentEstimateField({ fieldName: prop, value });
    },

    handleBackAction() {
      if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
        this.navigateToBoard();
      } else if (
        this.$f7route.params.clientType &&
        this.$f7route.params.clientId
      ) {
        this.$f7router.navigate(
          `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}`,
          {
            pushState: true,
            reloadAll: true
          }
        );
      } else {
        this.$f7router.navigate("/estimate", {
          pushState: true,
          reloadAll: true
        });
      }
    },

    async navigateToBoard() {
      this.$f7.preloader.show();
      const res = await Promise.all([
        this.$f7route.params.boardId &&
          this.getBoardById(this.$f7route.params.boardId),
        this.$f7route.params.projectId &&
          this.getProjectById(this.$f7route.params.projectId),
        this.$f7route.params.taskId &&
          this.getTaskById(this.$f7route.params.taskId),
        this.$f7route.params.actionId &&
          this.getActionById(this.$f7route.params.actionId)
      ]);
      const board = res[0] || {};
      const card = res[1] || {};
      const task = res[2] || {};
      const action = res[3] || {};

      const businessCode = this.$f7route.params.businessCode || "";
      let url = "";
      switch (businessCode) {
        case "commercial":
          url = `/dashboard/${this.$f7route.params.boardType}/commercial/${this.$f7route.params.boardId}/project/${card.cardNumber}/action/${action.code}`;
          if (this.$f7route.params.boardId === "construction") {
            url += `/task/${task.taskNumber}`;
          }
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true
          });
          break;

        case "residential":
          url = `/dashboard/${this.$f7route.params.boardType}/residential/${card.cardNumber}/action/${action.code}`;
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true
          });
          break;

        case "service":
          this.$f7router.navigate(
            `/dashboard/${this.$f7route.params.boardType}/${this.$f7route.params.businessCode}/${board.boardNumber}/card/${card.cardNumber}?activeTab=${this.$f7route.query.activeTab}`,
            {
              pushState: true,
              reloadAll: true
            }
          );
          break;
      }

      this.$f7.preloader.hide();
    },

    changeProfitMarginPercent(value) {
      let valueInFloat = parseFloat(value);
      if (isNaN(valueInFloat)) {
        valueInFloat = 0;
      }
      if (valueInFloat >= 100) {
        valueInFloat = 99;
      }
      if (valueInFloat < 0) {
        valueInFloat = 0;
      }
      // this.changeForm("dataPercentage", valueInFloat);
    },

    /* ---------------------- Estimate business methods ------------------------*/
    addProductData({ products, sectionId }) {
      const section = this.currentEstimate.data.find(
        section => section.id === sectionId
      );
      if (!_.isEmpty(section)) {
        section.items.push(...products);
      }
    },

    removeProductItem({ productId, sectionId }) {
      const data = this.currentEstimate.data;
      const section = data.find(section => section.id === sectionId);

      if (!_.isEmpty(section)) {
        _.remove(section.items, item => item.id === productId);
      }

      this.saveEstimate();
    }
  }
};
