import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { auth } from "../../../services/firebase.service";
import axiosService from "@/services/axios.service";
import {
  toDateFirebase
  // toDisplayDateString
} from "../../../utility/datetime";
import { COLLECTION_WO_TASK, COLLECTION_OPRATION_CARD } from "@/utility/const";

export default {
  computed: {
    ...mapGetters("dashboard/project", ["constructionProject"]),

    ...mapGetters("dashboard/work-order", [
      "workOrderTask",
      "workOrderAction",
      "workOrderTaskList"
    ])
  },

  methods: {
    ...mapActions("common/notification", ["createNotificationByType"]),
    ...mapActions("dashboard/work-order", ["updateWorkOrderTask"]),

    async addWOTaskNotification(user) {
      // Add activity
      await this.createNotificationByType({
        data: {
          assignees: [user.uid],
          project: {
            title: this.workOrderTask.taskNumber,
            id: this.workOrderTask.id,
            type: "task",
            entityName: COLLECTION_WO_TASK
          }
        },
        type: "assign-user"
      });
    },

    // saveAssigneeIds(ids) {
    //   this.updateWorkOrderTask({
    //     id: this.workOrderTask.id,
    //     doc: {
    //       assigneeIds: ids
    //     }
    //   });
    // },

    saveDueDate(prop, newDate) {
      if (_.isEmpty(newDate)) {
        return;
      }

      if (this[prop] && newDate[0] != this[prop][0]) {
        this.updateWorkOrderTask({
          id: this.workOrderTask.id,
          doc: {
            [prop]: toDateFirebase(newDate || "") || ""
          }
        });
      }
    },

    sendAddWOTaskMail(user) {
      const receiver = `${user.displayName || ""} <${user.email}>`;

      return axiosService
        .post("/notification/push-email", {
          to: receiver,
          subject: {
            assigner: auth.currentUser.displayName
          },
          template: "assign-wo-task",
          content: {
            assigner: auth.currentUser.displayName,
            task_number: this.workOrderTask.taskNumber
          },
          attachments: [],
          scheduleSendingTime: ""
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },

    updateWorkOrderTaskProp(prop, value) {
      this.updateWorkOrderTask({
        id: this.workOrderTask.id,
        doc: {
          [prop]: value
        }
      });
    }
  }
};
