<template>
  <f7-block>
    <!-- <div class="display-flex justify-content-flex-end">
      <f7-button
        class="margin-right-half"
        fill
        color="primary"
        @click="saveOnly"
        >Save only
      </f7-button>
      <f7-button fill color="primary" @click="saveAndInsert"
        >Save and Insert to Proposal
      </f7-button>
    </div> -->
    <financing-proposal-table ref="financingProposalTable" :dataTable="currentFinancingProposal"
      @onChangeField="onChangeField" />
  </f7-block>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FinancingProposalTable from "../tables/FinancingProposalTable.vue";
import _ from "lodash";
import financingProposalMixin from "../../mixins/financing-proposal-mixin";
import {
  FINANCING_PROPOSAL_PAGE,
  FINANCING_PROPOSAL_TABLE_TYPE,
  FOOTER_TYPE,
  HEADER_TYPE
} from "../../utility/const";
import { useVuelidate } from '@vuelidate/core'

export default {
  mixins: [financingProposalMixin],
  components: {
    FinancingProposalTable
  },
  data() {
    return {
      currentFinancingProposal: []
    };
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    })
  },
  methods: {
    ...mapActions({
      updateFinancingProposalDefault:
        "proposal/proposal/updateFinancingProposalDefault",
      updateSubFinancingProposal:
        "proposal/proposal/updateSubFinancingProposal",
      createSubFinancingProposal:
        "proposal/proposal/createSubFinancingProposal",
      setProposalField: "proposal/proposal/setProposalField",
      updateProposal: "proposal/proposal/updateProposal"
    }),

    onChangeField(value) {
      this.currentFinancingProposal = _.cloneDeep(value);
      this.currentFinancingProposal = this.currentFinancingProposal.map(r => {
        const rate_per_period = r.interestRate / 100 / 12;
        const number_of_payments = r.term;
        const present_value = -(r.projectCost - r.downPayment);
        return {
          ...r,
          monthlyPayment: parseFloat(
            this.pmt(
              rate_per_period,
              number_of_payments,
              present_value
            ).toFixed(2)
          )
        };
      });
    },

    saveOnly() {
      this.$refs.financingProposalTable.v$.$touch();
      if (this.$refs.financingProposalTable.v$.$invalid) return;

      // save financing proposal default
      let promises = [];
      this.currentFinancingProposal.forEach(financingProposal => {
        promises.push(
          this.updateFinancingProposalDefault({
            id: financingProposal.financingProposalDefaultId,
            doc: {
              name: financingProposal.name,
              term: financingProposal.term,
              interestRate: financingProposal.interestRate
            }
          })
        );
      });

      // save financing proposal
      this.currentFinancingProposal.forEach(financingProposal => {
        if (this.hasFinancingProposal) {
          promises.push(
            this.updateSubFinancingProposal({
              proposalId: this.proposal.id,
              financingProposalId: financingProposal.id,
              financingProposal
            })
          );
        } else {
          promises.push(
            this.createSubFinancingProposal({
              proposalId: this.proposal.id,
              financingProposal
            })
          );
        }
      });

      return Promise.all(promises).then(() => this.currentFinancingProposal);
    },

    async addFinancingProposalPage() {
      let pages = _.cloneDeep((this.proposal || {}).pages).filter(
        r => r.name !== FINANCING_PROPOSAL_PAGE
      );
      let page = _.cloneDeep(pages[0]);
      let components = _.cloneDeep(page.components);
      components = components.filter(
        r => r.type == HEADER_TYPE || r.type == FOOTER_TYPE
      );
      const initComponents = [
        {
          type: FINANCING_PROPOSAL_TABLE_TYPE,
          data: {
            financingProposal: this.currentFinancingProposal
          }
        }
      ];
      components.splice(1, 0, ...initComponents);
      page.components = components;
      page.name = FINANCING_PROPOSAL_PAGE;

      pages.splice(1, 0, page);
      this.setProposalField({ fieldName: "pages", value: pages });
      await this.updateProposal({
        id: this.proposal.id,
        doc: {
          ...this.proposal
        }
      });
    },

    // Deprecated
    async saveAndInsert() {
      this.$f7.preloader.show();
      await this.saveOnly();
      await this.addFinancingProposalPage();
      this.$f7.preloader.hide();
    },

    init() {
      this.currentFinancingProposal = [];
      this.$f7.preloader.show();
      this.initFinancingProposal({
        buildingIDs: this.proposal.buildingIDs,
        project: this.proposal.project,
        estimate: this.proposal.estimate
      })
        .then(data => {
          this.currentFinancingProposal = _.cloneDeep(data);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
  }
};
</script>
