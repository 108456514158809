/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import {
  CODE_TIME_TRACKING_HOURS,
  CODE_TIME_TRACKING_MINUTES,
  CODE_STATUS_OPTION,
  CODE_TIME_LOG_TYPE,
  CODE_TIME_OFF_TYPE
} from "../../../../utility/const";

import * as types from "./types";

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    "type"
  );

  return {
    getConstantTypeListBys: typeAction.getDocumentBys,
    unbindConstantTypeList: typeAction.unbindCollection,
    // Use the in operator to combine up to 10 equality (==) clauses on the same field with a logical OR.
    // An in query returns documents where the given field matches any of the comparison values
    async getConstantTypeList({ commit, dispatch }) {
      const list = await dispatch("getConstantTypeListBys", [
        {
          prop: "code",
          op: "in",
          val: [
            CODE_TIME_TRACKING_HOURS,
            CODE_TIME_TRACKING_MINUTES,
            CODE_STATUS_OPTION,
            CODE_TIME_LOG_TYPE,
            CODE_TIME_OFF_TYPE
          ]
        }
      ]);

      commit(types.SET_CONSTANT_LIST, list);
    }
  };
};
