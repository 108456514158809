<template>
  <f7-popup class="demo-popup" :opened="isShow" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Export Pay Rate</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="exportExcel()">Export</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block-title>Date</f7-block-title>
      <f7-list class="no-margin">
        <f7-list-input type="datepicker" label="Start Date" placeholder="mm/dd/yyyy" error-message-force validate
          validate-on-blur :value="startDate" :error-message="startDateErrorMessage" :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy'
          }" @calendar:change="handleCalendarChange"></f7-list-input>
        <f7-list-input type="datepicker" label="End Date" placeholder="mm/dd/yyyy" error-message-force validate
          validate-on-blur :value="endDate" :error-message="endDateErrorMessage" :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
            disabled: date =>
              startDate && date < new Date(startDate).setHours(0, 0, 0, 0)
          }" @calendar:change="
            endDate = $event;
          $f7.calendar.close();
          "></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import exportEmployeePayRate from "../../excel-export-pay-rate";
import { VALIDATION_MESSAGE } from '@/utility/const';
export default {
  props: {
    isShow: Boolean
  },
  data: () => {
    return {
      startDate: [new Date()],
      endDate: [new Date()]
    };
  },
  methods: {
    ...mapActions("time-tracking/pay-rate", ["getDataPayRateList"]),
    cancel() {
      this.startDate = [new Date()];
      this.endDate = [new Date()];
      this.$emit("close");
    },
    exportExcel() {
      const conditions = [
        {
          prop: "effectiveDate",
          val: this.$google.firebase.firestore.Timestamp.fromDate(
            this.startDate[0]
          ),
          op: ">="
        },
        {
          prop: "effectiveDate",
          val: this.$google.firebase.firestore.Timestamp.fromDate(
            this.endDate[0]
          ),
          op: "<="
        }
      ];
      this.getDataPayRateList(conditions).then(res => {
        const values = this.userList.map(x => {
          let payRates = (res || [])
            .filter(e => e.userId === x.uid)
            .map(r => ({
              payRate: r.payRate,
              payRateOvertime: r.payRateOvertime,
              effectiveDate: r.effectiveDate.toDate()
            }));
          payRates = payRates.sort((a, b) => {
            return moment(b.effectiveDate) - moment(a.effectiveDate);
          });
          return {
            ...x,
            id: x.uid,
            payRates: payRates.map(r => ({
              ...r,
              effectiveDate: moment(r.effectiveDate).format("MM/DD/YYYY")
            }))
          };
        });
        exportEmployeePayRate({
          title: `Pay Rate From ${moment(this.startDate[0]).format(
            "MM/DD/YYYY"
          )} To ${moment(this.endDate[0]).format("MM/DD/YYYY")}`,
          headers: [
            {
              name: "Employee Name",
              key: "displayName"
            },
            {
              name: "Phone#",
              key: "phoneNumber"
            },
            {
              name: "Pay Rate",
              key: "payRate"
            },
            {
              name: "Pay Rate Overtime",
              key: "payRateOvertime"
            },
            {
              name: "Effective Date",
              key: "effectiveDate"
            }
          ],
          values
        });
        this.cancel();
      });
    },
    handleCalendarChange(event) {
      this.startDate = event;
      if (this.endDate[0] < this.startDate[0]) {
        this.endDate = event;
      }
      this.$f7.calendar.close();
    }
  },
  computed: {
    ...mapGetters("time-tracking/user", ["userList"]),

    startDateErrorMessage() {
      if (this.v$.startDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    endDateErrorMessage() {
      if (this.v$.endDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (!this.v$.endDate.timeRules)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    const timeRules = () => {
      const startDate = moment(this.startDate[0]).format("YYYY-MM-DD");
      const endDate = moment(this.endDate[0]).format("YYYY-MM-DD");
      return !moment(endDate).isBefore(startDate, "day");
    };
    return {
      startDate: {
        required
      },
      endDate: {
        required,
        timeRules
      }
    };
  }
};
</script>
