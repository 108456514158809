<template>
  <f7-list>
    <address-auto-complete v-for="(address, index) in value" :key="index" :ref="`addressAutoComplete_${address.code}`"
      :index="index" :id="id" :validationResult="v$.value" :address="address"
      :addressTypeOptions="addressTypeOptions" @touchToValidation="v$.value.$touch()"
      @delete-address="deleteAddress" @open-address-type="openAddressType">
    </address-auto-complete>
    <f7-list-item v-if="addressTypeOptions.length">
      <f7-link :popover-open="value.length && addressTypeOptions.length > 1
        ? `.popover-address-type-${id}`
        : false
        " class="cursor-pointer text-input" slot="title" @click.native="handleAdd">
        add address<required-asterisk v-show="isRequired"></required-asterisk>
      </f7-link>
      <f7-icon class="add-button-icon" f7="plus_circle" slot="media"></f7-icon>
      <div v-show="isRequired" slot="footer" class="theme-color">
        {{ addressesErrorMessage }}
      </div>
    </f7-list-item>

    <f7-popover ref="addressTypePopover" @popover:closed="sheetClosed" :class="`popover-address-type-${id}`">
      <f7-list>
        <f7-list-item v-for="(type, index) in addressTypeOptions" :key="index" :title="type.title" link
          @click.native="selectAddressType(type.code)"></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-list>
</template>

<script>
import _ from "lodash";
import AddressAutoComplete from "./AddressAutoCompleteInput/AddressAutoComplete";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from "@vuelidate/validators";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    AddressAutoComplete
  },
  props: {
    id: String,
    value: { type: Array, default: () => [] },
    isRequired: { type: Boolean, default: false }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  data: () => {
    return {
      addressTypes: [
        { code: "main", title: "Main" },
        { code: "home", title: "Home" },
        { code: "work", title: "Work" }
      ],
      isNew: false,
      currentAddressIndex: ""
    };
  },

  computed: {
    addressTypeOptions() {
      const codes = (this.value || []).map(r => r.code) || [];
      return this.addressTypes.filter(r => !codes.includes(r.code));
    },
    addressesErrorMessage() {
      if (!this.v$.value.$error) return null;
      if (this.v$.value.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    resetData() {
      this.isNew = false;
      this.currentAddressIndex = "";
      this.v$.$reset();
    },
    handleAdd() {
      if (!this.value.length) {
        this.addAddress("main");
      } else if (this.addressTypeOptions.length > 1) {
        this.isNew = true;
      } else if (this.addressTypeOptions.length === 1) {
        this.addAddress(this.addressTypeOptions[0].code);
      }
    },
    deleteAddress(index) {
      let addresses = _.cloneDeep(this.value);
      addresses.splice(index, 1);
      this.$emit("input", addresses);
    },
    addAddress(value) {
      let addresses = _.cloneDeep(this.value);
      const addressType = this.addressTypes.find(r => r.code === value) || {};
      addresses.push({
        code: value,
        title: addressType.title,
        address: "",
        city: "",
        zipcode: "",
        state: "",
        country: ""
      });
      this.$emit("input", addresses);
      this.$nextTick(() => {
        this.$refs[`addressAutoComplete_${value}`][0].onLinkFocus(value);
      });
    },

    sheetClosed() {
      this.isNew = false;
      this.currentAddressIndex = "";
    },

    openAddressType(index) {
      this.currentAddressIndex = index;
    },

    selectAddressType(type) {
      const obj = this.addressTypes.find(item => {
        return item.code === type;
      });
      if (this.isNew) {
        this.addAddress(type);
      } else {
        let addresses = _.cloneDeep(this.value);
        addresses[this.currentAddressIndex].code = obj.code;
        addresses[this.currentAddressIndex].title = obj.title;
        this.$emit("input", addresses);
        this.$nextTick(() => {
          this.$refs[`addressAutoComplete_${type}`][0].onLinkFocus(type);
        });
      }
      this.$refs.addressTypePopover.close();
    }
  },
  validations() {
    return {
      value: {
        required(val) {
          if (!this.isRequired) return true;
          return (val || []).length;
        },
        $each: helpers.forEach({
          address: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          },
          city: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          },
          state: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          },
          zipcode: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          },
          country: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          }
        })
      }
    };
  }
};
</script>

<style lang="scss">
#address .item-title.item-label a {
  font-size: 14px;
  margin-bottom: 10px;
}

.text-input {
  color: var(--f7-color-text-neutral);
}
</style>
