<template>
  <div class="relative">
    <label>
      <input
        type="range"
        :min="min"
        :max="max"
        id="slider-1"
        v-model="rangeValue"
        @input="onChangeRange"
        @mouseup="updateRangeValue(rangeValue)"
      />
      <output id="label-slider-1" for="slider-1" :style="styleLabelRange">{{rangeValue + "%"}}</output>
    </label> 
  </div>
</template>

<script>
export default {
  name: 'RangeSlide',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    value: {
      type: Number,
      default: 0
    },
    changed: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      rangeValue: 0,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.rangeValue = newValue;
      },
      immediate: true,
    },
  },
  computed: {
    styleLabelRange() {
      const widthSlider = document.getElementById("slider-1")?.offsetWidth || 0;

      const widthLabel = document.getElementById("label-slider-1")?.offsetWidth || 0;

      const range = this.rangeValue || 0;

      const left = widthSlider*range/100;

      const marginLeft = widthLabel/2;

      return `--min: ${this.min};--max: ${this.max}; left: ${left-marginLeft}px`
    }
  },
  methods: {
    onChangeRange(event) {
      this.rangeValue = Number(event.target.value);
    },
    updateRangeValue(value) {
      this.changed(value);
    },
   
  },
};
</script>

<style scoped>
.relative {
  position: relative;
  width: 100%;
  margin-top: 42px;
}
label {
  --c: #547980;
  --g: round(.3em,1px);
  --l: round(.2em,1px);
  --s: round(1.3em,1px);
  --t: round(.8em,1px); 
  --r: round(.8em,1px);
  timeline-scope: --thumb-view;
  position: relative;
  font-size: 24px;
}

input {
  width: 100%;
  height: 16px;
  appearance :none;
  background: none;
  cursor: pointer;
  overflow: hidden;
  font-size: inherit;
}

/* chromium */
input[type="range" i]::-webkit-slider-thumb{
  height: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: red;
  border-image: linear-gradient(90deg, red 50%, #ababab 0) 0 1 / calc(50% - var(--l) / 2) 100vw / 0 100vw;
  -webkit-appearance: none;
  appearance: none;
  transition: .3s;
  anchor-name: --thumb;
  view-timeline: --thumb-view inline;
}
/* Firefox */
/* Firefox */
input[type="range"]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  background: none;
  border-radius: 50%;
  background-color: red;
  border-image: linear-gradient(90deg, red 50%, #ababab 0) 0 1 / calc(50% - var(--l) / 2) 100vw / 0 100vw;
  -moz-appearance: none;
  appearance: none;
  transition: .3s;
  anchor-name: --thumb;
  view-timeline: --thumb-view inline;
}
output {
  position-anchor: --thumb;
  position: absolute;
  inset-area: top;
  top: -45px;
  color: #fff;
  font-family: sans-serif;
  padding: 8px;
  font-size: 16px;
  border-bottom: var(--t) solid #0000;
  border-radius: var(--r)/var(--r) var(--r) calc(var(--r) + var(--t)) calc(var(--r) + var(--t));
  --_m: 100%/var(--t) var(--t) no-repeat;
  --_g: 100%,#0000 99%,#000 102%;
  mask:
    linear-gradient(#000 0 0) padding-box,
    radial-gradient(100% 100% at 100% var(--_g)) calc(50% + var(--t)/2) var(--_m),
    radial-gradient(100% 100% at 0    var(--_g)) calc(50% - var(--t)/2) var(--_m);
  background-color: var(--f7-color-bg-14-neutral);
}
output.bottom {
  inset-area: bottom;
  border-top: var(--t) solid #0000;
  border-bottom: none;
  border-radius: var(--r)/calc(var(--r) + var(--t)) calc(var(--r) + var(--t)) var(--r) var(--r);
  --_m: 0%/var(--t) var(--t) no-repeat;
  --_g: 0%,#0000 99%,#000 102%;
}
</style>