<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Service</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <f7-block class="filter-row margin-vertical">
      <cards-filter />
    </f7-block>
    <swimlane-board :boardId="currentBoard.id" />
  </f7-page>
  <!-- <f7-page v-else>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left" icon-f7="menu"></f7-link>
      </f7-nav-left>
      <f7-nav-title>Not found board</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The board could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page> -->
</template>

<script>
import SwimlaneBoard from "../components/board/SwimlaneBoard.vue";
import CardsFilter from "../components/filter/CardsFilter.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    SwimlaneBoard,
    CardsFilter,
    Menu
  },

  async created() {
    // get board by boardNumber to get boardId
    // const boardNumber = this.$f7route.params["boardNumber"];
    this.$f7.preloader.show();
    let promises = [];

    await this.getBoardBys([
      {
        prop: "default",
        val: true,
        op: "=="
      },
      {
        prop: "businessCode",
        val: this.$f7route.route.meta.businessCode,
        op: "=="
      }
    ]);
    if (!_.isEmpty(this.currentBoard)) {
      promises.push(this.bindColumns(this.currentBoard.id));
      promises.push(this.bindCards(this.currentBoard.id));
    }
    const cardNumber = this.$f7route.params["cardNumber"];
    if (cardNumber) {
      // get card by cardNumber
      promises.push(
        this.getCardByNumber([
          {
            prop: "cardNumber",
            val: cardNumber,
            op: "=="
          },
          {
            prop: "businessCode",
            val: this.$f7route.route.meta.businessCode,
            op: "=="
          }
        ]).then(cards => {
          if (cards.length > 0) {
            this.setCardDetailId(cards[0].id);
          } else {
            this.$f7.notification
              .create({
                title: "Not found job",
                closeTimeout: 15000,
                closeButton: true,
                text: `Not found job <b class='color-red'>${this.$f7route.params.cardNumber}</b>. Please choose another job.`,
                closeOnClick: true
              })
              .open();
            return;
          }
        })
      );
    }
    promises.push(this.getConstantTypeList());
    if (_.isEmpty(this.userList)) {
      promises.push(this.getUserList());
    }
    Promise.all(promises).finally(() => {
      this.$f7.preloader.hide();
    });
  },

  methods: {
    ...mapActions({
      bindColumns: "swimlane/column/bind",
      unbindColumns: "swimlane/column/unbind",
      bindCards: "swimlane/card/bind",
      unbindCards: "swimlane/card/unbind",
      getBoardBys: "swimlane/board/getBoardBys",
      getCardByNumber: "swimlane/card/getCardByNumber"
    }),

    ...mapActions("swimlane/user", ["getUserList"]),

    ...mapMutations({
      setBoardDetailId: "swimlane/board/setDetailId",
      setCardDetailId: "swimlane/card/setCardDetailId"
    }),
    ...mapActions("swimlane/app-constant", [
      "getConstantTypeList",
      "resetConstant"
    ]),
    ...mapActions("swimlane/board", ["resetBoard"]),
    ...mapActions("swimlane/email-template", ["resetEmailTemplate"]),
    ...mapActions("swimlane/estimate", ["resetEstimate"]),
    ...mapActions("swimlane/finance", ["resetFinance"]),
    ...mapActions("swimlane/proposal", ["resetProposal"]),
    ...mapActions("swimlane/album", ["resetAlbum"]),
    ...mapActions("swimlane/photo", ["resetPhoto"])
  },

  computed: {
    ...mapGetters({
      detail: "swimlane/card/detail",
      boardDetail: "swimlane/board/detail",
      boardByNumber: "swimlane/board/boardByNumber",
      currentBoard: "swimlane/board/currentBoard"
    }),

    ...mapGetters("swimlane/user", ["userList"])
  },

  beforeDestroy() {
    this.unbindColumns();
    this.unbindCards();
    this.resetAlbum();
    this.resetPhoto();
    this.resetConstant();
    this.resetBoard();
    this.resetEmailTemplate();
    this.resetEstimate();
    this.resetFinance();
    this.resetProposal();
  }
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.kanban-column-container {
  display: flex;
  flex-direction: column;
  margin: 6px;
  background-color: var(--f7-page-bg-color);
}
.column-content-container {
  height: 100%;
  overflow-y: auto;
}
.filter-row {
  min-height: 20px;
}
</style>
