import { sortLatest } from "@/utility/date-time-tool";
import { TEMPLATE_TYPE } from "../../../../utility/const";

export default {
  projectPhotoAlbumList: state => {
    const albumListDefault = (
      state.projectPhotoAlbumList.filter(r => r.templateType === TEMPLATE_TYPE.DEFAULT) ||
      []
    ).sort((a, b) => a.priority - b.priority);
    const albumListSystem = (
      state.projectPhotoAlbumList.filter(r => r.templateType === TEMPLATE_TYPE.SYSTEM) || []
    ).sort((a, b) => a.priority - b.priority);
    const albumListRest = sortLatest(
      state.projectPhotoAlbumList.filter(
        r => r.templateType !== TEMPLATE_TYPE.DEFAULT && r.templateType !== TEMPLATE_TYPE.SYSTEM
      ) || []
    );
    const albumList = albumListDefault.concat(albumListSystem, albumListRest);
    return albumList.map(r => ({
      ...r,
      photoTotals: r.photoTotals || 0,
      photoLasted: r.photoLasted || {}
    }));
  },
  projectPhotoAlbum: state => state.projectPhotoAlbum
};
