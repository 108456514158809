<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-f7="chevron_left" @click.native="handleBackBtn"></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ projectPhotoAlbum.name }}</f7-nav-title>
      <f7-nav-right>
        <f7-link v-if="selectedPhotoIds.length > 0" @click="cancelSelect">
          Cancel
        </f7-link></f7-nav-right
      >
    </f7-navbar>
    <photos
      :selectionMode="selectionMode"
      :selectedPhotoIds="selectedPhotoIds"
      :projectPhotoListGroup="photosByAlbumIdGroup"
      :loading="loadingPhoto"
      :photoFilter="photoFilter"
      :endDateFilterErrorMessage="endDateFilterErrorMessage"
      :hoveredPhotoIds="hoveredPhotoIds"
      @clickPhoto="clickPhoto"
      @clickEdit="clickEdit"
      @handleSelected="handleSelected"
      @cancelSelected="cancelSelect"
      @handleSelectAllDay="handleSelectAllDay"
      @onFilterDate="onFilterDate"
      @handleMouseEnter="createTooltips"
    ></photos>

    <template v-if="selectedPhotoIds.length > 0">
      <delete-photo
        ref="deletePhoto"
        class="tab-btn tab-btn_1"
        :photosSelected="projectPhotoByAlbumSelected(selectedPhotoIds)"
        @cancelSelect="cancelSelect"
      ></delete-photo>
      <f7-fab
        :class="`tab-btn tab-btn_3 move-tooltip-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleMoveToAbum"
      >
        <f7-icon f7="square_arrow_left"></f7-icon>
      </f7-fab>
      <f7-fab
        :class="`tab-btn tab-btn_2 download-tooltip-${uuid}`"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleDownload"
      >
        <f7-icon f7="cloud_download"></f7-icon>
      </f7-fab>
    </template>
    <template v-else>
      <f7-fab
        :class="`tab-btn tab-btn_1 upload-new-tooltip-${uuid} `"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="openFileInput"
      >
        <f7-icon f7="plus"></f7-icon>
      </f7-fab>
      <f7-fab
        v-show="!$device.desktop"
        :class="`tab-btn tab-btn_2 add-new-tooltip-${uuid} `"
        position="right-bottom"
        slot="fixed"
        color="default"
        @click="handleOpenTakePhotosPopup"
      >
        <f7-icon f7="camera"></f7-icon>
      </f7-fab>
    </template>
    <upload-photo
      ref="uploadPhoto"
      :albumId="projectPhotoAlbum.id"
      :showProgressBar="true"
    ></upload-photo>

    <photo-browser
      :photos="photoList"
      theme="dark"
      ref="pageDark"
      @clickEdit="clickEdit"
      @clickDownload="clickDownload"
    ></photo-browser>
    <edit-photo-popup ref="editPhoto"> </edit-photo-popup>
    <!-- Move to Album Popup -->
    <move-to-album-popup
      ref="movePhotoPopup"
      @cancelSelect="cancelSelect"
    ></move-to-album-popup>

    <take-photos-popup
      ref="takePhotosPopup"
      :albumId="projectPhotoAlbum.id"
    ></take-photos-popup>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Photos from "@/plugins/photos/components/list/Photos.vue";
import DeletePhoto from "@/plugins/photos/components/buttons/DeletePhoto.vue";
import UploadPhoto from "@/plugins/photos/components/upload/UploadPhoto.vue";
import EditPhotoPopup from "../components/popups/EditPhotoPopup.vue";
import PhotoBrowser from "../components/popups/PhotoBrowser.vue";
import MoveToAlbumPopup from "../components/popups/MoveToAlbumPopup.vue";
import TakePhotosPopup from "../components/popups/TakePhotosPopup.vue";

import moment from "moment";
import { groupBy } from "@/utility/filter-tools";
import { sortLatest } from "@/utility/date-time-tool";
import _ from "lodash";
import { uuid } from "vue-uuid";

export default {
  components: {
    TakePhotosPopup,
    Photos,
    DeletePhoto,
    UploadPhoto,
    EditPhotoPopup,
    PhotoBrowser,
    MoveToAlbumPopup
  },
  data: () => {
    return {
      loading: null,
      selectionMode: false,
      selectedPhotoIds: [],
      loadingPhoto: null,
      hoveredPhotoIds: [],
      endDateFilterErrorMessage: "",
      uuid: uuid.v4()
    };
  },

  mounted() {
    this.createTooltips();
  },
  methods: {
    ...mapActions("photo/photo", [
      "downloadPhoto",
      "getProjectPhotosByAlbum",
      "setPhotoFilterField"
    ]),
    ...mapActions("photo/album", [
      "getProjectPhotoAlbum",
      "getProjectPhotoAlbumListByProject"
    ]),
    ...mapActions("photo/project", ["getProject"]),
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        { targetEl: `.add-new-tooltip-${this.uuid}`, text: "Add new photo" },
        {
          targetEl: `.upload-new-tooltip-${this.uuid}`,
          text: "Upload photo"
        },
        { targetEl: `.move-tooltip-${this.uuid}`, text: "Move photo" },
        { targetEl: `.download-tooltip-${this.uuid}`, text: "Download photo" }
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: "tooltip-fab-button",
          text: tooltip.text
        });
      });
    },
    cancelSelect() {
      this.selectionMode = false;
      this.selectedPhotoIds = [];
      this.hoveredPhotoIds = [];
      this.$nextTick(() => {
        this.createTooltips();
      });
    },
    clickDownload(id) {
      const existedPhotoId = this.selectedPhotoIds.find(i => i === id);
      if (!existedPhotoId) {
        this.selectedPhotoIds.push(id);
      }
      this.handleDownload();
    },
    onSelect(id) {
      if (this.selectedPhotoIds.includes(id)) {
        var index = this.selectedPhotoIds.indexOf(id);
        if (index > -1) {
          this.selectedPhotoIds.splice(index, 1);
          this.hoveredPhotoIds.splice(index, 1);
        }
      } else {
        this.selectedPhotoIds.push(id);
      }
    },
    clickPhoto(id, isShowPhoto = false) {
      this.destroyTooltips();
      if (isShowPhoto) {
        const index = this.photoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    clickEdit(id) {
      this.$f7.preloader.show();
      const photo = this.projectPhotosByAlbum.find(i => i.id === id);
      this.$refs.editPhoto.open(photo);
    },
    openFileInput() {
      this.$refs.uploadPhoto.click();
    },

    handleDownload() {
      this.$f7.toast
        .create({
          text: "Downloading files...",
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000
        })
        .open();
      const selectedPhotos = this.projectPhotosByAlbum.filter(photo =>
        this.selectedPhotoIds.includes(photo.id)
      );
      selectedPhotos.forEach(i =>
        this.downloadPhoto({ fullPath: i.photoFullPath, name: i.photoName })
      );
      this.cancelSelect();
      this.createTooltips();
    },

    handleMoveToAbum() {
      this.$refs.movePhotoPopup.open(this.selectedPhotoIds);
    },
    handleBackBtn() {
      this.destroyTooltips();
      this.$f7router.navigate(
        `/photo/${this.$f7route.params.id}?currentTab=album`
      );
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltips();
      if (this.selectedPhotoIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.selectedPhotoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    handleSelectAllDay(data) {
      this.createTooltips();
      if (data.event) {
        const photosIds = (data.ids || []).filter(
          i => !this.selectedPhotoIds.includes(i)
        );
        this.selectedPhotoIds = this.selectedPhotoIds.concat(photosIds);
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photosIds);
        this.selectionMode = true;
      } else {
        this.selectedPhotoIds = this.selectedPhotoIds.filter(
          i => !data.ids.includes(i)
        );
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !data.ids.includes(i)
        );
      }
    },
    onFilterDate({ field, value }) {
      if (
        (_.isEmpty(value) && _.isEmpty(this.photoFilter[field])) ||
        (!_.isEmpty(value) &&
          !_.isEmpty(this.photoFilter[field]) &&
          moment(value[0]).isSame(moment(this.photoFilter[field][0]), "day")) ||
        (this.$f7 && !this.$f7.views.main.router.currentRoute.params.albumId)
      )
        return;

      this.setPhotoFilterField({ field, value });
      const { startDate, endDate } = this.photoFilter;
      let fromDate = "";
      let toDate = "";
      if (!_.isEmpty(value) && !_.isEmpty(startDate) && !_.isEmpty(endDate)) {
        if (field === "endDate") {
          fromDate = moment(startDate[0]).format("YYYY-MM-DD");
          toDate = moment(value[0]).format("YYYY-MM-DD");
        } else {
          fromDate = moment(value[0]).format("YYYY-MM-DD");
          toDate = moment(endDate[0]).format("YYYY-MM-DD");
        }
      }
      if (fromDate && toDate && moment(toDate).isBefore(fromDate, "day")) {
        this.endDateFilterErrorMessage =
          "To date must be greater than or equal from date";
        return;
      } else {
        this.endDateFilterErrorMessage = "";
      }
      this.loadPhotos();
    },

    loadPhotos() {
      this.$f7.preloader.show();
      this.getProjectPhotosByAlbum(this.$f7route.params.albumId).then(() => {
        this.$f7.preloader.hide();
      });
    },

    handleOpenTakePhotosPopup() {
      if (!this.$device.desktop) {
        this.$refs.takePhotosPopup.open();
      }
    },
    destroyTooltips() {
      const tooltips = [
        { targetEl: ".add-new-tooltip", text: "Add new photo" },
        { targetEl: ".move-tooltip", text: "Move photo" },
        { targetEl: ".download-tooltip", text: "Download photo" }
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
      });
    }
  },

  computed: {
    ...mapGetters("photo/album", ["projectPhotoAlbum"]),
    ...mapGetters("photo/photo", [
      "projectPhotosByAlbum",
      "photoFilter",
      "projectPhotoByAlbumSelected"
    ]),

    photoList() {
      return (
        this.projectPhotosByAlbum.filter(
          i => i.albumId === this.projectPhotoAlbum.id
        ) || []
      ).map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
        photoFullPath: i.photoFullPath
      }));
    },
    photosByAlbumIdGroup() {
      const photosByAlbumId =
        this.projectPhotosByAlbum.filter(
          i => i.albumId === this.projectPhotoAlbum.id
        ) || [];
      return groupBy(sortLatest(photosByAlbumId), i =>
        i.createdAt
          ? moment(i.createdAt.toDate()).format("dddd, MMMM Do YYYY")
          : ""
      );
    }
  },

  created() {
    if (this.$f7route.params.albumId && this.$f7route.params.id) {
      this.$f7.preloader.show();
      this.loadingPhoto = true;
      const promises = [];
      promises.push(this.getProjectPhotoAlbum(this.$f7route.params.albumId));
      promises.push(this.getProjectPhotosByAlbum(this.$f7route.params.albumId));
      promises.push(this.getProject(this.$f7route.params.id));
      promises.push(
        this.getProjectPhotoAlbumListByProject(this.$f7route.params.id)
      );
      Promise.all(promises).then(() => {
        this.loadingPhoto = false;
        this.$f7.preloader.hide();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) + 16px
    );
  }
  &_2 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) + 16px) * 2
    );
  }
  &_3 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) + 16px) * 3
    );
  }
}
</style>
