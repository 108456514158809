<template>
  <f7-popup
    :opened="isShow"
    @popup:closed="$emit('close')"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select User</f7-nav-title>
        <f7-nav-right
          ><f7-link @click.native="handleSelectUsers"
            >Done</f7-link
          ></f7-nav-right
        >
      </f7-navbar>

      <f7-searchbar
        class="search-list-popup"
        disable-button-text
        placeholder="Search user"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
      ></f7-searchbar>

      <div class="page-nav">
        <div class="select-all-container">
          <f7-checkbox
            :id="'selectAllCheckbox'"
            :checked="selectAllChecked"
            @change="handleSelectAllChange"
          ></f7-checkbox>
          <label :for="'selectAllCheckbox'">Select All</label>
        </div>
      </div>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <f7-list class="search-list searchbar-found contact-list margin-page">
        <f7-list-group
          v-for="(group, index) in listUserTrackingGroup(searchValue)"
          :key="index"
        >
          <f7-list-item :title="group.key" group-title></f7-list-item>
          <f7-list-item
            no-chevron
            v-for="user in group.data"
            :key="user.uid"
            :title="user.displayName"
            checkbox
            @change.native="handleCheckboxChange($event.target.checked, user)"
            :checked="selectedItems.some(p => p.uid === user.uid && p.checked)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters } from "vuex";
import { EMPLOYEES_TIME_LOG } from "../../utility/const";

export default {
  props: {
    isShow: Boolean,
    usersSelected: {
      type: Array,
      default: () => []
    },
    type: String
  },
  data: () => {
    return {
      value: "",
      searchValue: "",
      selectAllChecked: false,
      selectedItems: []
    };
  },

  computed: {
    ...mapGetters("time-tracking/time-tracking", [
      "listUserTrackingGroup",
      "listUserTrackings"
    ])
  },

  methods: {
    /**
     * select
     */
    select(userId) {
      this.$emit("onSelected", userId);
      this.$emit("close");
    },

    clearSelectedUsers() {
      this.selectedItems = [];
      this.selectAllChecked = false;
      this.searchValue = "";
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }

      if (this.type !== EMPLOYEES_TIME_LOG) this.selectedItems = [];
    },

    handleSelectAllChange() {
      this.selectAllChecked = !this.selectAllChecked;
      this.selectedItems = this.selectAllChecked
        ? this.listUserTrackings.map(item => ({ ...item, checked: true }))
        : [];
    },

    handleCheckboxChange(checked, item) {
      const newItem = { ...item, checked };

      if (checked && !this.selectedItems.includes(item)) {
        this.selectedItems.push(newItem);
      } else {
        this.selectedItems = this.selectedItems.filter(r => r.uid !== item.uid);
      }
      this.onCheckIsCheckSelectAll();
    },

    onCheckIsCheckSelectAll() {
      if (this.selectedItems.length === this.listUserTrackings.length) {
        this.selectAllChecked = true;
      } else {
        this.selectAllChecked = false;
      }
    },

    handleSelectUsers() {
      const userIds = this.selectedItems.map(item => item.uid);
      this.$emit("onSelected", userIds);
      this.$emit("close");
    }
  }
};
</script>
<style scoped lang="scss">
.page-nav {
  display: flex;
  padding: 16px 16px 10px 16px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 9999;
  background-color: var(--f7-list-group-title-bg-color);
}
.select-all-container,
.toggle-select-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.select-all-container label,
.toggle-select-container label {
  color: #ef4e3a;
  background-color: var(--f7-list-group-title-bg-color);
}
.item-checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}
.item-checkbox label {
  cursor: pointer;
}
.margin-page {
  margin-top: 50px;
}
</style>
