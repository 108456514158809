import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const emailTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/email_template`,
    "emailTemplate"
  );
  return {
    getEmailTemplateBys: emailTemplateActions.getDocumentBys,
    getOnMyWayEmailTemplate({ dispatch, commit }) {
      return dispatch("getEmailTemplateBys", [
        {
          prop: "template",
          op: "==",
          val: "on-my-way"
        }
      ]).then(templates => {
        const temp = templates && templates.length > 0 ? templates[0] : {};
        commit(types.SET_EMAIL_TEMPLATE, temp);
      });
    },

    updateEmailTemplate: emailTemplateActions.updateDocument,

    resetEmailTemplate({ commit }) {
      commit(types.SET_EMAIL_TEMPLATE, {});
    }
  };
};
