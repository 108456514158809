import Vue from "vue";
import * as types from "./types";
export default {
  setRoofTypeList(state, payload) {
    Vue.set(state, "roofTypeList", payload);
  },
  setRoofTypeSubscribe(state, payload) {
    Vue.set(state, "roofTypeSubscribe", payload);
  },
  [types.SET_ROOFTYPE_LIST](state, payload) {
    Vue.set(state, "roofTypeList", payload);
  }
};
