<template>
  <div>
    <div v-if="$f7.device.desktop || $f7.device.ipad">
      <f7-row tag="p" class="po-row">
        <f7-col tag="span" width="33">
          <f7-list inset>
            <f7-list-item title="Project #" :after="purchaseOrder.projectNumber"></f7-list-item>
            <f7-list-item class="text-project-info" title="Project Name" ref="projectNameItem"
              :after="purchaseOrder.projectName"></f7-list-item>
            <f7-list-item title="Property Name" class="text-project-info" :after="(purchaseOrder || {}).propertyName"
              ref="propertyNameItem"></f7-list-item>
            <f7-list-item v-if="!purchaseOrder.isBuildYourOwn" title="Specification #"
              :after="purchaseOrder.buildingName"></f7-list-item>
            <f7-list-input v-else label="Specification #" type="text" placeholder="Specification #"
              :value="purchaseOrder.buildingName || ''"
              @change="savePOProp('buildingName', $event.target.value.trim())">
              <!-- <input-icon slot="media" icon="map_pin_ellipse"></input-icon> -->
            </f7-list-input>
          </f7-list>
        </f7-col>

        <f7-col tag="span" width="33" class="po-col">
          <f7-list inset>
            <f7-list-item title="Roof Type"
              :after="roofTypeBy(purchaseOrder.roofType).displayName || ''"></f7-list-item>

            <f7-list-item v-if="purchaseOrder.estimateNumber" title="Estimate #"
              :after="purchaseOrder.estimateNumber"></f7-list-item>

            <f7-list-item title="Price List #" v-if="!purchaseOrder.isBuildYourOwn"
              :after="priceListItem && priceListItem.priceListNumber">
            </f7-list-item>
            <f7-list-item v-else title="Vendor" :after="purchaseOrder.vendorName">
            </f7-list-item>
            <f7-list-item title="Price List Name" v-if="!purchaseOrder.isBuildYourOwn"
              :after="priceListItem && priceListItem.displayName">
            </f7-list-item>
          </f7-list>
        </f7-col>

        <f7-col tag="span" width="33">
          <f7-list inset>
            <f7-list-item title="Status">
              <f7-chip slot="after" :text="purchaseOrderStatusBy(purchaseOrder.status).displayName"
                :color="purchaseOrderStatusBy(purchaseOrder.status).color"></f7-chip>
            </f7-list-item>

            <f7-list-item title="Created Date" :after="toDisplayDateString(purchaseOrder.createdAt)">
            </f7-list-item>

            <f7-list-item v-if="
              purchaseOrder.orderedDate && purchaseOrder.orderedDate != ''
            " title="Ordered Date" :after="toDisplayDateString(purchaseOrder.orderedDate)">
            </f7-list-item>

            <f7-list-item v-if="
              purchaseOrder.canceledDate && purchaseOrder.canceledDate != ''
            " title="Canceled Date" :after="toDisplayDateString(purchaseOrder.canceledDate)">
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>

      <f7-list v-if="purchaseOrder.status != STATUS_PO_DRAFT" inset>
        <f7-list-input label="Tracking #" type="text" placeholder="Tracking number"
          :value="purchaseOrder.trackingNumber || ''"
          @change="savePOProp('trackingNumber', $event.target.value.trim())">
          <!-- <input-icon slot="media" icon="map_pin_ellipse"></input-icon> -->
        </f7-list-input>

        <!-- Estimated arrival -->
        <f7-list-input type="datepicker" label="Estimated Arrival Date" placeholder="Estimated arrival date"
          :value="estimatedArrival" :calendar-params="{
            closeOnSelect: true,
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
            },
            closeOnSelect: true
          }" @calendar:change="saveDueDate('estimatedArrival', $event)">
          <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
        </f7-list-input>
        <!-- Delivered date -->
        <f7-list-input ref="deliveredDate" type="datepicker" label="Delivered Date" placeholder="Delivered date"
          :value="deliveredDate" :calendar-params="{
            closeOnSelect: true,
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
            },
            closeOnSelect: true
          }" @calendar:change="saveDueDate('deliveredDate', $event)" :error-message="deliveredDateErrorMessage"
          error-message-force>
          <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
          <required-asterisk slot="label"></required-asterisk>
        </f7-list-input>
      </f7-list>
    </div>

    <div v-else>
      <f7-list inset>
        <f7-list-item title="Project #" :after="purchaseOrder.projectNumber"></f7-list-item>
        <f7-list-item title="Project Name" ref="projectNameItem" class="text-project-info"
          :after="purchaseOrder.projectName"></f7-list-item>
        <f7-list-item title="Property Name" class="text-project-info" :after="purchaseOrder.propertyName"
          ref="propertyNameItem"></f7-list-item>
        <f7-list-item v-if="!purchaseOrder.isBuildYourOwn" title="Specification #"
          :after="purchaseOrder.buildingName"></f7-list-item>
        <f7-list-input v-else label="Specification #" type="text" placeholder="Specification #"
          :value="purchaseOrder.buildingName || ''" @change="savePOProp('buildingName', $event.target.value.trim())">
          <!-- <input-icon slot="media" icon="map_pin_ellipse"></input-icon> -->
        </f7-list-input>
      </f7-list>

      <f7-list>
        <f7-list-item title="Roof Type" :after="roofTypeBy(purchaseOrder.roofType).displayName || ''"></f7-list-item>

        <f7-list-item v-if="purchaseOrder.estimateNumber" title="Estimate #"
          :after="purchaseOrder.estimateNumber"></f7-list-item>

        <f7-list-item v-if="!purchaseOrder.isBuildYourOwn" title="Price List" :after="priceListItem &&
          priceListItem.priceListNumber + ' - ' + priceListItem.displayName
          ">
        </f7-list-item>
        <f7-list-item v-else title="Vendor" :after="purchaseOrder.vendorName">
        </f7-list-item>
      </f7-list>

      <f7-list>
        <f7-list-item title="Created Date" :after="toDisplayDateString(purchaseOrder.createdAt)">
        </f7-list-item>

        <f7-list-item v-if="purchaseOrder.orderedDate && purchaseOrder.orderedDate != ''" title="Ordered Date"
          :after="toDisplayDateString(purchaseOrder.orderedDate)">
        </f7-list-item>

        <f7-list-item v-if="purchaseOrder.canceledDate && purchaseOrder.canceledDate != ''" title="Canceled Date"
          :after="toDisplayDateString(purchaseOrder.canceledDate)">
        </f7-list-item>

        <f7-list-item title="Status">
          <f7-chip slot="after" :text="purchaseOrderStatusBy(purchaseOrder.status).displayName"
            :color="purchaseOrderStatusBy(purchaseOrder.status).color"></f7-chip>
        </f7-list-item>
      </f7-list>

      <f7-list v-if="purchaseOrder.status != STATUS_PO_DRAFT">
        <f7-list-input label="Tracking #" type="text" placeholder="Tracking number"
          :value="purchaseOrder.trackingNumber || ''"
          @change="savePOProp('trackingNumber', $event.target.value.trim())">
          <!-- <input-icon slot="media" icon="map_pin_ellipse"></input-icon> -->
        </f7-list-input>

        <!-- Estimated arrival -->
        <f7-list-input type="datepicker" label="Estimated Arrival Date" placeholder="Estimated arrival date"
          :value="estimatedArrival" :calendar-params="{
            closeOnSelect: true,
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
            },
            closeOnSelect: true
          }" @calendar:change="saveDueDate('estimatedArrival', $event)">
          <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
        </f7-list-input>
        <!-- Delivered date -->
        <f7-list-input ref="deliveredDate" type="datepicker" label="Delivered Date" placeholder="Delivered date"
          :value="deliveredDate" :calendar-params="{
            closeOnSelect: true,
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
            },
            closeOnSelect: true
          }" @calendar:change="saveDueDate('deliveredDate', $event)" :error-message="deliveredDateErrorMessage"
          error-message-force>
          <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
          <required-asterisk slot="label"></required-asterisk>
        </f7-list-input>
      </f7-list>
    </div>

    <div>
      <attachment-input :project-id="purchaseOrder.id" modelType="work-order-tasks" title="Attachments"
        attachment-type="attachment" add-button-title="Add an Attachment" :value="purchaseOrder.attachmentFiles"
        @input="savePOProp('attachmentFiles', $event)"></attachment-input>

      <user-input :value="purchaseOrder.assigneeIds" modelType="wo-task" @input="savePOProp('assigneeIds', $event)"
        @onUserAdded="sendAddWOTaskMail($event);"
        @handleNotify="addWOTaskNotification($event);"
      ></user-input>

      <send-email-input v-show="purchaseOrder.purchaseOrderEmails &&
        purchaseOrder.purchaseOrderEmails.length > 0
        " ref="emailInput" title="PO Email" :value="purchaseOrder.purchaseOrderEmails" :useDefaultTemplate="false">
        <template v-slot:item="{ email }">
          <a class="cursor-pointer" slot="title">{{
            getEmailLinkTitle(email)
          }}</a>
        </template>
      </send-email-input>

      <note-popup ref="poNotePopup" title="Received Purchase Order Note" label="Enter Note" v-model="note"
        @done="handleNotePopupDone" validate></note-popup>

      <purchase-order-wo-response ref="responseButton" :actionCodes="actionCodes"
        @doAction="doAction"></purchase-order-wo-response>

      <f7-link target="_blank" external class="text-color-orange margin-left"
        href="https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/Sales_Tax_chart.pdf?alt=media&token=26c6f681-92c2-4744-a054-c246ec04644f">
        Based on your input information, you will
        <strong v-show="showNoTaxMessage">&nbsp;NOT&nbsp;</strong> pay tax on
        this Purchase Order. Please follow this link for more details.
      </f7-link>

      <product-table ref="purchaseOrder" :roofType="purchaseOrder.roofType" :vendorId="purchaseOrder.vendorId"
        :items="purchaseOrder.items" :isBuildYourOwn="purchaseOrder.isBuildYourOwn"
        :isEditing="purchaseOrder.status === STATUS_PO_DRAFT" :woTaskStatus="purchaseOrder.status" :isPlacedOrder="purchaseOrder.status === STATUS_PO_WAITING ||
          purchaseOrder.status === STATUS_PO_RECEIVED ||
          purchaseOrder.status === STATUS_PO_PARTIAL_RECEIVED
          " :isCompleted="purchaseOrder.status === STATUS_PO_RECEIVED ||
            purchaseOrder.status === STATUS_PO_PARTIAL_RECEIVED
        "
        @onItemsChange="savePOProp('items', $event)"
      ></product-table>

      <p
        class="text-color-orange margin-left"
        v-show="productDeletedList.length > 0"
      >
        Table of non-existing product
      </p>
      <product-table-deleted
        v-show="productDeletedList.length > 0"
        :vendorId="purchaseOrder.vendorId"
        :items="productDeletedList"
        :woTaskStatus="purchaseOrder.status"
      ></product-table-deleted>
    </div>

    <purchase-order-send-email-popup ref="poSendEmailPopup" :email-type="getEmailType()"
      :purchase-order-emails="purchaseOrder.purchaseOrderEmails" :projectId="purchaseOrder.id"
      :attachments="attachmentFiles" @input="handleEmailSent"
      @handleClose="handleUpdatePricePO"></purchase-order-send-email-popup>
  </div>
</template>

<script>
import UserInput from "../../components/inputs/UserInput.vue";
import AttachmentInput from "@/components/inputs/AttachmentInput.vue";
import SendEmailInput from "../../components/inputs/SendEmailInput.vue";
import NotePopup from "../../components/popups/NotePopup.vue";
import PurchaseOrderSendEmailPopup from "../../components/popups/PurchaseOrderSendEmailPopup.vue";

import ProductTable from "../../components/tables/ProductTable.vue";
import ProductTableDeleted from "../tables/ProductTableDeleted.vue";
import PurchaseOrderWoResponse from "../../components/actions/PurchaseOrderResponse.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";

import {
  ACTION_CODE_CONSTRUCTION,
  STATUS_PO_RECEIVED,
  STATUS_PO_PARTIAL_RECEIVED,
  STATUS_PO_CANCEL,
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_WAITING,
  ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
  ACTION_CODE_PO_PARTIAL_RECEIVED,
  ACTION_CODE_PO_RECEIVED,
  ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
  ACTION_CODE_PO_REQUEST_CANCEL,
  ACTION_CODE_PO_CANCEL,
  ACTION_CODE_PO_EMAIL,
  COLLECTION_WO_TASK,
  VALIDATION_MESSAGE
} from "../../../../utility/const";
import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";
import { auth, firebase } from "../../../../services/firebase.service";
import axiosService from "../../../../services/axios.service";

import poMixin from "../../mixins/purchase-order-mixin";

export default {
  components: {
    ProductTable,
    UserInput,
    AttachmentInput,
    SendEmailInput,
    NotePopup,
    PurchaseOrderWoResponse,
    PurchaseOrderSendEmailPopup,
    ProductTableDeleted
  },

  mixins: [poMixin],

  data() {
    return {
      estimatedArrival: [],
      deliveredDate: [],

      ACTION_CODE_CONSTRUCTION,

      note: "",
      currentResponse: {},

      STATUS_PO_RECEIVED,
      STATUS_PO_PARTIAL_RECEIVED,
      STATUS_PO_CANCEL,
      STATUS_PO_REQUEST_CANCEL,
      STATUS_PO_DRAFT,
      STATUS_PO_WAITING
    };
  },

  computed: {
    ...mapGetters("purchase-order/purchase-order", ["purchaseOrder", "hits"]),
    ...mapGetters("purchase-order/purchase-order-details-page/project", [
      "constructionActionCodeById"
    ]),
    ...mapGetters("purchase-order/purchase-order-details-page/product-item", [
      "productItemList"
    ]),
    ...mapGetters("purchase-order/purchase-order-details-page/price-list", [
      "priceListItem"
    ]),
    ...mapGetters("purchase-order/app-constant", [
      "roofTypeBy",
      "purchaseOrderStatusBy"
    ]),

    ...mapGetters("purchase-order/purchase-order-details-page/vendor", [
      "vendorById"
    ]),

    actionCodes() {
      return this.constructionActionCodeById(this.purchaseOrder.actions || []);
    },
    productDeletedList() {
      return !_.isEmpty(this.purchaseOrder.itemsDeleted)
        ? this.purchaseOrder.itemsDeleted
        : [];
    },

    workOrderAction() {
      return {};
    },
    productItemListRestrictSelected() {
      return this.productItemList.filter(
        r =>
          r.vendorId === this.purchaseOrder.vendorId &&
          r.roofTypes.includes(this.purchaseOrder.roofType)
      );
    },

    deliveredDateErrorMessage() {
      if (!this.v$.deliveredDate.$error) return null;
      if (this.v$.deliveredDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    },

    vendorEmails() {
      let result = "";

      const vendor = this.vendorById(this.purchaseOrder.vendorId || "");
      if (!_.isEmpty(vendor)) {
        const emails = (vendor.others || []).filter(
          item => item.code === "email"
        );

        if (!_.isEmpty(emails)) {
          result = emails.map(item => item.value).join("; ");
        }
      }
      return result;
    },

    attachmentFiles() {
      const arr = [];
      if (!_.isEmpty(this.project.attachmentFiles)) {
        arr.push(...this.project.attachmentFiles);
      }
      if (!_.isEmpty(this.purchaseOrder.attachmentFiles)) {
        arr.push(...this.purchaseOrder.attachmentFiles);
      }
      return arr;
    }
  },
  methods: {
    ...mapActions("purchase-order/purchase-order", [
      "updatePurchaseOrder",
      "bindPurchaseOrderResponse"
    ]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    savePOProp(prop, value) {
      this.updatePurchaseOrder({
        id: this.purchaseOrder.id,
        doc: { [prop]: value }
      });
    },
    getEmailType() {
      if (this.purchaseOrder.status === STATUS_PO_DRAFT) {
        return "purchase-order";
      } else if (this.purchaseOrder.status === STATUS_PO_WAITING) {
        return "request-cancel-purchase-order";
      }

      return "";
    },

    getEmailLinkTitle(email) {
      let type = email.template === "purchase-order" ? "Order Date" : "";
      type =
        email.template === "request-cancel-purchase-order"
          ? "Request Cancel Date"
          : type;

      return `${type}: ${toDisplayDateString(email.createdAt)} by ${email.createdBy
        }`;
    },

    handleEmailSent(data) {
      const self = this;

      this.saveEmailData(data)
        .then(() => {
          if (self.currentResponse.code === ACTION_CODE_PO_EMAIL) {
            return this.updatePurchaseOrder({
              id: self.purchaseOrder.id,
              doc: {
                orderedDate: firebase.firestore.Timestamp.now()
              }
            });
          }
        })
        .then(() => {
          return self.resolve(self.currentResponse);
        })
        .then(() => {
          return self.bindPurchaseOrderResponse(self.getActionId());
        });
    },
    handleUpdatePricePO() {
      const arr = this.purchaseOrder.items.map(item => {
        const foundProductItem = this.productItemListRestrictSelected.find(
          productItem => productItem.id === item.productItemId
        );
        if (foundProductItem) {
          return {
            ...item,
            pricePO: foundProductItem.price || item.price || 0
          };
        }
        return item;
      });
      this.updatePurchaseOrder({
        id: this.purchaseOrder.id,
        doc: {
          items: arr
        }
      });
    },
    handleNotePopupDone() {
      const self = this;

      this.saveNote()
        .then(() => {
          if (
            self.currentResponse.code === ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL
          ) {
            this.updatePurchaseOrder({
              id: self.purchaseOrder.id,
              doc: {
                canceledDate: firebase.firestore.Timestamp.now()
              }
            });
          }
          return self.resolve(self.currentResponse);
        })
        .then(() => {
          return self.bindPurchaseOrderResponse(self.getActionId());
        });
    },

    resolve(response) {
      let nextActionIds = this.calculateNextActions(response);

      // Assign the next action
      return this.updatePurchaseOrder({
        id: this.purchaseOrder.id,
        doc: {
          status: response.nextState,
          actions: nextActionIds //response.nextActions,
          // actionsHistory: actionsHistory
        }
      });
    },

    calculateNextActions(response) {
      let currActionId = this.getActionId();

      let currProjectActionIds = this.purchaseOrder.actions;
      let nextResActionIds = response.nextActions;

      // remove current action id from project's actions
      if (currProjectActionIds.includes(currActionId)) {
        currProjectActionIds = currProjectActionIds.filter(
          id => id !== currActionId
        );
      }

      // include new action Ids
      currProjectActionIds = currProjectActionIds.concat(nextResActionIds);

      //  remove duplicates
      currProjectActionIds = currProjectActionIds.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      return currProjectActionIds;
    },

    saveDueDate(prop, newDate) {
      if (_.isEmpty(newDate)) {
        return;
      }

      if (this[prop] && newDate[0] != this[prop][0]) {
        this.updatePurchaseOrder({
          id: this.purchaseOrder.id,
          doc: {
            [prop]: toDateFirebase(newDate || "") || ""
          }
        });
      }
    },

    toDateCalendar(firebaseDate) {
      return toDateCalendar(firebaseDate);
    },

    async addWOTaskNotification({userId, type}) {
      await this.createNotificationByType({
        data: {
          assignees: [userId],
          project: {
            title: this.purchaseOrder.taskNumber,
            id: this.purchaseOrder.projectId,
            type: "task",
            entityName: COLLECTION_WO_TASK
          }
        },
        type: type
      });
    },

    sendAddWOTaskMail(user) {
      const receiver = `${user.displayName || ""} <${user.email}>`;

      return axiosService
        .post("/notification/push-email", {
          to: receiver,
          subject: {
            assigner: auth.currentUser.displayName
          },
          template: "assign-wo-task",
          content: {
            assigner: auth.currentUser.displayName,
            task_number: this.purchaseOrder.taskNumber
          },
          attachments: [],
          scheduleSendingTime: ""
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },

    async doAction(response) {
      this.currentResponse = response;
      this.note = "";
      switch (response.code) {
        case ACTION_CODE_PO_EMAIL:
          this.$refs.poSendEmailPopup.sendMail({
            defaultSubject: `${this.setting.companyName} - ${this.project.title} - ${this.purchaseOrder.taskNumber}`,
            emails: this.vendorEmails,
            vendorName:
              (this.vendorById(this.purchaseOrder.vendorId || "") || {})
                .companyName || "",
            mainMessage: this.generateMainMessage()
          });
          break;

        case ACTION_CODE_PO_CANCEL:
          this.$f7.preloader.show();
          await this.updatePurchaseOrder({
            id: this.purchaseOrder.id,
            doc: {
              canceledDate: firebase.firestore.Timestamp.now(),
              isDeleted: this.purchaseOrder.status === STATUS_PO_DRAFT
            }
          }).then(() => {
            this.$f7.preloader.hide();
          });

          this.resolve(response);

          if (this.purchaseOrder.status === STATUS_PO_DRAFT) {
            this.$emit("closeAndRefresh");
          }
          break;

        case ACTION_CODE_PO_RECEIVED:
          if (this.invalidPO()) {
            return;
          }

          this.$refs.poNotePopup.open();
          break;

        case ACTION_CODE_PO_PARTIAL_RECEIVED:
          this.v$.deliveredDate.$touch();
          if (this.v$.deliveredDate.$invalid) {
            this.$refs.deliveredDate.$el.scrollIntoView();
            return;
          }

          this.$refs.poNotePopup.open();
          break;

        case ACTION_CODE_PO_REQUEST_CANCEL:
          this.$refs.poSendEmailPopup.sendMail({
            defaultSubject: "Request Cancel Purchase Order",
            emails: this.vendorEmails,
            vendorName:
              (this.vendorById(this.purchaseOrder.vendorId || "") || {})
                .companyName || "",
            mainMessage: this.generateMainMessage()
          });
          break;

        case ACTION_CODE_PO_REJECT_REQUEST_CANCEL:
          this.$refs.poNotePopup.open();
          break;

        case ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL:
          this.$refs.poNotePopup.open();
          break;
      }
    },

    generateMainMessage() {
      let poInfo = "";
      let footer = "";

      if (this.showNoTaxMessage) {
        footer += `<div><p>This purchase order does <strong> NOT</strong> pay Sale Tax.</p></div>`;
      }

      if (!_.isEmpty(this.purchaseOrder.trackingNumber)) {
        poInfo += `<div><p><strong>Tracking #: </strong>${this.purchaseOrder.trackingNumber}</p></div>`;
      }

      if (!_.isEmpty(this.estimatedArrival)) {
        poInfo += `<div><p><strong>Estimated Arrival: </strong>${this.toDisplayDateString(
          toDateFirebase(this.estimatedArrival)
        )}</p></div>`;
      }

      if (!_.isEmpty(this.deliveredDate)) {
        poInfo += `<div><p><strong>Delivered Date: </strong>${this.toDisplayDateString(
          toDateFirebase(this.deliveredDate)
        )}</p></div>`;
      }

      let tr = "";
      for (const product of this.$refs.purchaseOrder.poItems) {
        if (!product.footer) {
          // let product = this.$refs.purchaseOrder.getItem(item);

          tr += `<tr>
          <td style="border: 1px solid black;">${product.sku || ""}${product.isDeleted
              ? '<div style="color:red;font-size:11px">This product doesn\'t exist</div>'
              : ""
            }
          </td>
          <td style="border: 1px solid black;">${product.productItem || ""}</td>
          <td style="border: 1px solid black;">${product.manufacturer ||
            ""}</td>
          <td style="border: 1px solid black;">${product.orderQty}</td>

          <td style="border: 1px solid black;">${product.packaging || ""}</td>
          
          <td style="border: 1px solid black;">${product.unitSize || ""}</td>
          <td style="border: 1px solid black;">${product.uom || ""}</td>
          <td style="border: 1px solid black;">$
            ${(product.price || 0).toFixed(2)}
          </td>
          <td style="border: 1px solid black;">$
            ${((product.price || 0) * product.orderQty).toFixed(2)}
          </td>
        </tr>`;
        } else {
          tr += `<tr>
          <td colspan="7" style="border: 1px solid black;"></td>
          <td style="border: 1px solid black;"><strong>Total:</strong></td>
          <td style="border: 1px solid black;"><strong>$
          ${(product.total || 0).toFixed(2)}</strong></td>
        </tr>`;
        }
      }

      return `<div>${poInfo}</div><div><br/></div><div><table style="border-collapse: collapse;">
      <thead >
        <tr >
          <th style="border: 1px solid black;">
            SKU
          </th>
          <th style="border: 1px solid black;">
            Items
          </th>
          <th style="border: 1px solid black;">
            Manufacturer
          </th>
          <th style="border: 1px solid black;">
            Order quantity
          </th>
          <th style="border: 1px solid black;">
            Packaging
          </th>
          
          <th style="border: 1px solid black;">
            Unit Size
          </th>
          <th style="border: 1px solid black;">
            UoM
          </th>
          <th style="border: 1px solid black;">
            Price
          </th>
          <th style="border: 1px solid black;">
            Sub-Total
          </th>
        </tr>
      </thead>
      <tbody>
        ${tr}
      </tbody>
    </table></div>
    <div>${footer}</div>`;
    },

    invalidPO() {
      this.v$.deliveredDate.$touch();
      if (this.v$.deliveredDate.$invalid) {
        this.$refs.deliveredDate.$el.scrollIntoView();
        return true;
      }

      let qtyValid = this.purchaseOrder.items.reduce((acc, curr) => {
        return acc + (parseFloat(curr.receivedQty) || 0.0);
      }, 0.0);

      if (qtyValid === 0.0) {
        this.$ri.dialog.openSuccessDialog({
          title: "Received Purchase Order",
          content: "Received quantity must be greater 0!",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    purchaseOrder: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.estimatedArrival =
            this.toDateCalendar(val.estimatedArrival) || [];
          this.deliveredDate = this.toDateCalendar(val.deliveredDate) || [];
        }
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    deliveredDate: {
      required
    }
  }
};
</script>
<style lang="scss" scoped>
.summary {
  background: white;
  border-radius: 4px;
}

.page-form {
  width: 825px;
  min-height: 1170px;
  padding: 30px 25px 30px 50px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  box-sizing: border-box;
  border-color: #e5e7f2;
  background-color: white;
  box-shadow: 0 4px 4px rgba(87, 100, 126, 0.21);
  border-radius: 2px;
  position: relative;
  padding-bottom: 60px;
}

.text-info {
  line-height: 2;
}

.text-align-right {
  text-align: right;
}

.bold {
  font-weight: 500;
}

.logo {
  width: 92%;

  img {
    width: 100%;
  }
}

.action-bar {
  position: sticky;
  z-index: 502;
  background-color: #eee;
  border: 1px solid rgb(216, 214, 214);
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
}

.text-project-info ::v-deep {
  .item-title {
    width: 100%;
  }

  .item-media {
    justify-content: center;
  }

  .item-after {
    max-width: 70%;
    white-space: break-spaces;

    span {
      text-align: right;
    }
  }
}
.po-row {
  justify-content: space-evenly;
}
.po-col .list.inset {
  margin-left: 0;
  margin-right: 0;
}
.invoice-button {
  padding: 2px 10px;
  font-weight: 600;
  border-left: 1px solid rgb(216, 214, 214);
  box-sizing: border-box;

  a {
    color: gray;
    display: flex;
    align-items: center;

    i {
      padding-right: 4px;
    }
  }

  a:hover {
    font-weight: 600;
    color: var(--f7-theme-color);

    i {
      font-weight: 600;
    }
  }
}
</style>
