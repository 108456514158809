import Vue from "vue";
import * as types from "./types";
export default {
  [types.SET_ASSEMBLY_TEMPLATE](state, payload) {
    Vue.set(state, "assemblyTemplate", payload);
  },
  [types.SET_ASSEMBLY_TEMPLATE_LIST](state, payload) {
    Vue.set(state, "assemblyTemplateList", payload);
  },
  // Fulltext search
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, "hits", hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },
  [types.SET_SEARCH_VALUE](state, payload) {
    Vue.set(state, "searchValue", payload);
  },
  [types.RESET_SEARCH](state) {
    Vue.set(state, "order", "desc");
    Vue.set(state, "hits", []);
    Vue.set(state, "hitsPerPage", 25);
    Vue.set(state, "nbHits", 0);
    Vue.set(state, "nbPages", 0);
    Vue.set(state, "page", 0);
    Vue.set(state, "assemblyTemplate", {});
    Vue.set(state, "searchValue", "");
    Vue.set(state, "assemblyTemplateList", []);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  }
};
