<template>
  <div>
    <f7-block-title>Notes</f7-block-title>

    <f7-list media-list class="note-editor">
      <div class="note-editor-content">
        <user-avatar
          style="margin-top: 15px;"
          :avatarSize="35"
          :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
          :name="userInfo(uid) ? userInfo(uid).displayName : ''"
        >
        </user-avatar>
        <f7-text-editor
          ref="noteEditor"
          class="editor"
          style="flex: 1;"
          resizable
          placeholder="Enter note..."
          :value="noteContent"
          @texteditor:change="changeValue"
          :buttons="buttons"
          :custom-buttons="customButtons"
        ></f7-text-editor>
      </div>
      <div class="note-editor-bottom" v-show="noteContent != ''">
        <f7-button @click.native="saveNote">
          Save
        </f7-button>
      </div>
    </f7-list>

    <f7-list inset media-list v-for="(item, index) in noteList" :key="index">
      <f7-list-item class="note-content">
        <div slot="media">
          <user-avatar
            :avatarSize="35"
            :imageUrl="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').photoURL
                : ''
            "
            :name="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').displayName
                : item.createdBy
            "
          >
          </user-avatar>
        </div>
        <div slot="title" v-show="$device.desktop || $device.ipad">
          <strong>{{ item.createdBy }}</strong> added a
          {{ item.title }}
        </div>
        <div v-html="item.htmlContent"></div>
        <div slot="after">
          {{ toLocaleString(item.createdAt) }}
        </div>
      </f7-list-item>
    </f7-list>

    <select-color-popup ref="selectColor" />
  </div>
</template>

<script>
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { firebase, auth } from "../../../../services/firebase.service";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import SelectColorPopup from "@/plugins/proposal/components/popup/SelectColorPopup.vue";

import { buttonsForTextFormatBar } from "@/utility/const";
import { customButtonForTextFormatBar } from "@/utility/common";

export default {
  components: { UserAvatar, SelectColorPopup },

  data() {
    return {
      noteContent: "",
      customButtons: customButtonForTextFormatBar(this, false),
      buttons: buttonsForTextFormatBar
    };
  },

  props: {
    value: { type: Array, default: () => [] },
    card: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  computed: {
    ...mapState("swimlane/user", ["userList"]),

    noteList() {
      return _.cloneDeep(this.value).sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds;
      });
    },

    userInfo() {
      return uid =>
        this.userList.find(user => {
          return user.uid === uid;
        });
    },

    uid() {
      return auth.currentUser.uid;
    }
  },

  methods: {
    openDialogInsertLinkTextEditor() {
      this.$f7.dialog
        .create({
          title: `<p style="font-size: 14px;">Insert Link</p>`,
          content: `<input type="text" id="insert-link" placeholder="Insert link URL" style="width: 100%; border: 1px solid #2a2a2a; padding: 4px; border-radius:3px;">`,
          buttons: [
            {
              text: "Cancel",
              color: "gray"
            },
            {
              text: "OK",
              cssClass: "primary"
            }
          ],

          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              const editor = this.$refs.noteEditor;
              if (this.currentRange) {
                editor.$el.f7TextEditor.setSelectionRange(this.currentRange);
                const inputElement = document.getElementById("insert-link");
                const inputValue = inputElement.value;
                document.execCommand("createLink", true, inputValue);
                _sefl.app.dialog.close();
                this.currentRange = null;
              }
            }
          }
        })
        .open();
    },
    changeValue(value) {
      const convertValue = value.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      this.noteContent = convertValue;
    },
    saveNote() {
      let noteList = _.cloneDeep(this.value) || [];

      noteList.push({
        title: "Note",
        code: "note",
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        createdById: auth.currentUser.uid,
        htmlContent: this.noteContent
      });
      this.$emit("change", noteList);
      this.noteContent = "";
    },
    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    }
  }
};
</script>

<style lang="scss" scoped>
.editor {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word; 
}
.note-editor {
  background-color: var(--f7-block-strong-bg-color);
  margin: 0;
  .note-editor-content {
    margin-top: 12px;
    display: flex;
    padding: 0 15px;
  }
  .note-editor-bottom {
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
    a {
      display: inline;
      margin-right: var(--f7-text-editor-margin);
      margin-bottom: 15px;
    }
  }
}
.note-content {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word; 
}
</style>
