<template>
  <f7-popup :opened="isShowPopup" @popup:close="closePopup()">
    <f7-page :style="{
      backgroundColor: isUserSign
        ? '#f3f3f3'
        : 'var(--f7-color-bg-12-neutral)'
    }">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Sign Online</f7-nav-title>
        <f7-nav-right><f7-link @click="save">Save </f7-link></f7-nav-right>
      </f7-navbar>
      <div style="padding: 0 20px">
        <f7-block-header style="padding: 0;" class="display-flex justify-content-space-between align-items-center">
          <div class="display-flex justify-content-center" style="gap: 16px">
            <f7-button fill @click="handleSwitchSign('hand')" :style="{
              backgroundColor:
                selectedType !== 'hand'
                  ? isUserSign
                    ? '#fff'
                    : 'var(--f7-color-bg-13-neutral)'
                  : '',
              color:
                selectedType !== 'hand'
                  ? isUserSign
                    ? '#555'
                    : 'var(--f7-color-text-3-neutral)'
                  : ''
            }" v-if="$device.desktop || $device.ipad">
              Draw by hand
            </f7-button>
            <f7-button fill @click="handleSwitchSign('hand')" :style="{
              backgroundColor:
                selectedType !== 'hand'
                  ? isUserSign
                    ? '#fff'
                    : 'var(--f7-color-bg-13-neutral)'
                  : '',
              color:
                selectedType !== 'hand'
                  ? isUserSign
                    ? '#555'
                    : 'var(--f7-color-text-3-neutral)'
                  : ''
            }" v-else>
              Draw
            </f7-button>
            <f7-button fill @click="handleSwitchSign('type')" :style="{
              backgroundColor:
                selectedType !== 'type'
                  ? isUserSign
                    ? '#fff'
                    : 'var(--f7-color-bg-13-neutral)'
                  : '',
              color:
                selectedType !== 'type'
                  ? isUserSign
                    ? '#555'
                    : 'var(--f7-color-text-3-neutral)'
                  : ''
            }">
              Type in
            </f7-button>
          </div>

          <div class="display-flex justify-content-center" style="gap: 16px">
            <div class="display-flex justify-content-center" style="gap: 4px">
              <f7-button :outline="selectedColor === 'black'" class="button-custom" style="height: 32px;" :style="{
                borderColor: isUserSign
                  ? '#555'
                  : 'var(--f7-color-text-3-neutral)'
              }" @click="handleColorClick('black')">
                <div class="circle-chip" style="background-color: #000;"></div>
              </f7-button>
              <f7-button :outline="selectedColor === '#477BFF'" class="button-custom" style="height: 32px;" :style="{
                borderColor: isUserSign
                  ? '#555'
                  : 'var(--f7-color-text-3-neutral)'
              }" @click="handleColorClick('#477BFF')">
                <div class="circle-chip" style="background-color: #477BFF;"></div>
              </f7-button>
            </div>

            <div v-if="selectedType === 'hand'" class="display-flex justify-content-center" style="gap: 4px">
              <f7-button @click="undo" style="padding: 4px 6px; display: flex;">
                <f7-icon f7="arrow_turn_up_left"></f7-icon>
              </f7-button>
              <f7-button @click="clear" style="padding: 4px 6px; display: flex;">
                <f7-icon f7="trash_fill"></f7-icon>
              </f7-button>
            </div>
          </div>
        </f7-block-header>
        <div v-if="selectedType === 'hand'">
          <div class="container" :style="{
            backgroundColor: isUserSign
              ? '#fff'
              : 'var(--f7-color-bg-13-neutral)'
          }">
            <VueSignaturePad id="signature" :width="$device.desktop || $device.ipad
              ? 'calc(50vw - 72px)'
              : 'calc(100vw - 20px)'
              " height="300px" ref="signaturePad" :options="signatureOptions" />
          </div>
          <template v-if="isShowFullName">
            <f7-list class="list-name">
              <f7-list-input :value="fullName" placeholder="Enter full name"
                @input="fullName = $event.target.value.trim()" error-message-force clear-button
                :error-message="requiredErrorMessage" :style="{
                  backgroundColor: isUserSign
                    ? '#fff'
                    : 'var(--f7-color-bg-13-neutral)',
                  color: isUserSign ? 'black' : ''
                }">
                <div class="list-item-inner-start" slot="label">
                  Full Name<required-asterisk />
                </div>
              </f7-list-input>
            </f7-list>
          </template>
        </div>
        <div v-if="selectedType === 'type'">
          <template v-if="isShowFullName">
            <f7-list class="list-name">
              <f7-list-input placeholder="Enter full name" :value="fullName"
                @input="fullName = $event.target.value.trim()" error-message-force clear-button
                :error-message="requiredErrorMessage" :style="{
                  backgroundColor: isUserSign
                    ? '#fff'
                    : 'var(--f7-color-bg-13-neutral)',
                  color: isUserSign ? 'black' : ''
                }">
                <div class="list-item-inner-start" slot="label">
                  Full Name<required-asterisk />
                </div>
              </f7-list-input>
            </f7-list>
          </template>
          <template>
            <f7-list class="list-name">
              <f7-list-input placeholder="Enter your signature" :value="signature"
                @input="handleSignatureInput($event.target.value.trim())" error-message-force clear-button
                :error-message="requiredSignatureErrorMessage" :style="{
                  backgroundColor: isUserSign
                    ? '#fff'
                    : 'var(--f7-color-bg-13-neutral)',
                  color: isUserSign ? 'black' : ''
                }">
                <div class="list-item-inner-start" slot="label">
                  Type your signature<required-asterisk />
                </div>
              </f7-list-input>
            </f7-list>
          </template>
          <div class="display-flex" style="flex-wrap: wrap; gap: 16px; padding-bottom: 16px;">
            <div v-for="(font, index) in listFontSign" :key="index" class="flex-box" 
              :style="{
              width:
                $device.desktop || $device.ipad ? 'calc(50% - 8px)' : '100%',
              backgroundColor: isUserSign
                ? '#fff'
                : 'var(--f7-color-bg-13-neutral)'
            }" :class="[{ 'selected-font': selectedFont === font }]"  @click="handleFontClick(font)">
              <span v-if="signature" style="padding: 6px 12px;" :style="{
                fontFamily: font,
                color: selectedColor,
              }" :ref="`print_${font}`">{{ signature }}</span>
            </div>
          </div>
        </div>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosService from "@/services/axios.service";
import { firebase } from "../../services/firebase.service";
import { useVuelidate } from '@vuelidate/core'
import { VALIDATION_MESSAGE } from '@/utility/const';
import { generateImage } from "../../services/utils";

export default {
  props: {
    isShowFullName: { type: Boolean, default: true },
    isUserSign: { type: Boolean, default: false }
  },
  data() {
    return {
      isShowPopup: false,
      fullName: "",
      signature: "",
      selectedColor: "black",
      selectedType: "hand",
      listFontSign: [
        "Sue_Ellen_Francisco",
        "Sacramento",
        "LaBelleAurore",
        "Gochi_Hand",
        "Sedgwick_Ave_Display",
        "Damion",
        "Voltaire",
        "Niconne"
      ],
      selectedFont: null,
      output: null
    };
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    return {
      fullName: {
        required(val) {
          if (!this.isShowFullName) return true;
          return !!val;
        }
      },
      signature: {
        required: val => {
          if (this.selectedType === "type") {
            return !!val;
          }
          return true;
        }
      }
    };
  },
  computed: {
    ...mapGetters("setting/app/profile", ["currentUser"]),
    ...mapGetters("common/user-signature", ["signatureList"]),
    requiredErrorMessage() {
      if (!this.v$.fullName.$error) return null;
      if (this.v$.fullName.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    requiredSignatureErrorMessage() {
      if (!this.v$.signature.$error) return null;
      if (this.v$.signature.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    signatureOptions() {
      return {
        penColor: this.selectedColor
      };
    }
  },
  methods: {
    ...mapActions("common/user-signature", [
      "getSignListBys",
      "addNewUserSignature",
      "updateUserSignature",
      "deleteUserSignature"
    ]),

    async openPopup() {
      this.isShowPopup = true;
      if (this.currentUser.uid) {
        await this.getSignListBys(this.currentUser.uid);
      }
      this.updateCanvasWH();
    },

    closePopup() {
      this.isShowPopup = false;
      this.fullName = "";
      this.signature = "";
      this.output = null;
      this.selectedColor = "black";
      if (this.$refs.signaturePad) {
        this.clear();
      }
      this.selectedType = "hand";
      this.v$.$reset();
    },

    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    handleColorClick(color) {
      this.selectedColor = color;
    },

    handleSwitchSign(type) {
      this.selectedFont = null;
      this.output = null;
      this.selectedType = type;
    },

    handleFontClick(font) {
      this.selectedFont = font;
    },

    handleSignatureInput(value) {
      this.signature = value;
      if (value.trim() === "") {
        this.selectedFont = null;
      }
    },
    async getAdditionalData() {
      try {
        const response = await axiosService.get("/ip/getSignerIp");
        const ip_address = response.data;
        const additionalData = {
          signerIp: ip_address,
          time: firebase.firestore.Timestamp.now()
        };
        return additionalData;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching signer IP:", error);
      }
    },

    async save() {
      this.$f7.preloader.show();
      let userHasSignature = this.signatureList[0];
      const additionalData = await this.getAdditionalData();
      if (this.selectedType === "type") {
        if (!this.selectedFont) {
          this.$f7.preloader.hide();
          this.$ri.dialog.openErrorDialog({
            title: "Error",
            content: "You have not signed. Please try again.",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
          return;
        }
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.$f7.preloader.hide();
          return;
        }
        const el = this.$refs[`print_${this.selectedFont}`];
        this.output = "data:image/png;base64," + await generateImage(el[0].outerHTML ,{pageWidth: 280,pageHeight: 86, isSign: true});
        if (this.currentUser.uid && !this.isUserSign) {
          if (userHasSignature) {
            this.updateUserSignature({
              id: userHasSignature.id,
              doc: {
                userSignName: this.fullName,
                userSignature: this.output
              }
            });
          } else {
            this.addNewUserSignature({
              userId: this.currentUser.uid,
              userSignName: this.fullName,
              userSignature: this.output
            });
          }
        }
        this.$f7.preloader.hide();
        this.$emit("saveSignature", {
          imageBase64: this.output,
          fullName: this.fullName,
          currentUser: this.currentUser,
          signerIp: additionalData,
          callback: () => this.closePopup()
        });
      } else if (this.selectedType === "hand") {
        var canvas = this.$refs.signaturePad.$refs.signaturePadCanvas;
        const { isEmpty } = this.$refs.signaturePad.saveSignature();
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.$f7.preloader.hide();
          return;
        }
        if (isEmpty) {
          this.$f7.preloader.hide();

          this.$ri.dialog.openErrorDialog({
            title: "Error",
            content: "You have not signed. Please try again.",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
          return;
        }
        if (this.currentUser.uid && !this.isUserSign) {
          if (userHasSignature) {
            this.updateUserSignature({
              id: userHasSignature.id,
              doc: {
                userSignName: this.fullName,
                userSignature: this.removeBlank(canvas)
              }
            });
          } else {
            this.addNewUserSignature({
              userId: this.currentUser.uid,
              userSignName: this.fullName,
              userSignature: this.removeBlank(canvas)
            });
          }
        }
        this.$f7.preloader.hide();
        this.$emit("saveSignature", {
          imageBase64: this.removeBlank(canvas),
          fullName: this.fullName,
          currentUser: this.currentUser,
          signerIp: additionalData,
          callback: () => this.closePopup()
        });
      }
    },

    updateCanvasWH() {
      this.$nextTick(() => {
        let canvas = this.$refs.signaturePad.$refs.signaturePadCanvas;
        if (this.$device.desktop || this.$device.ipad) {
          canvas.width = window.innerWidth * 0.5 - 72;
        } else {
          canvas.width = window.innerWidth - 40;
        }
        canvas.height = 300;
      });
    },

    removeBlank(canvas) {
      var croppedCanvas = document.createElement("canvas"),
        croppedCtx = croppedCanvas.getContext("2d");

      croppedCanvas.width = canvas.width;
      croppedCanvas.height = canvas.height;
      croppedCtx.drawImage(canvas, 0, 0);

      var w = croppedCanvas.width,
        h = croppedCanvas.height,
        pix = { x: [], y: [] },
        imageData = croppedCtx.getImageData(0, 0, w, h),
        x,
        y,
        index;

      for (y = 0; y < h; y++) {
        for (x = 0; x < w; x++) {
          index = (y * w + x) * 4;
          if (imageData.data[index + 3] > 0) {
            pix.x.push(x);
            pix.y.push(y);
          }
        }
      }
      pix.x.sort(function (a, b) {
        return a - b;
      });
      pix.y.sort(function (a, b) {
        return a - b;
      });
      var n = pix.x.length - 1;

      w = pix.x[n] - pix.x[0];
      h = pix.y[n] - pix.y[0];
      var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

      croppedCanvas.width = w;
      croppedCanvas.height = h;
      croppedCtx.putImageData(cut, 0, 0);

      var newCanvas = document.createElement("canvas"),
        newCtx = newCanvas.getContext("2d"),
        margin = 20;

      newCanvas.width = croppedCanvas.width + 2 * margin;
      newCanvas.height = croppedCanvas.height + 2 * margin;

      newCtx.fillStyle = "transparent";
      newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
      newCtx.drawImage(croppedCanvas, margin, margin);

      return newCanvas.toDataURL();
    },
    updateInputColor(isUserSign) {
      this.$nextTick(() => {
        const inputs = this.$el.querySelectorAll(".list-name.list input");
        inputs.forEach(input => {
          input.style.color = isUserSign ? "black" : "";
          if (isUserSign) {
            input.classList.add("black-placeholder");
          } else {
            input.classList.remove("black-placeholder");
          }
        });
      });
    }
  },
  watch: {
    selectedType() {
      this.updateInputColor(this.isUserSign);
    }
  },
  mounted() {
    this.updateCanvasWH();
    this.updateInputColor(this.isUserSign);
  }
};
</script>
<style>
.container {
  width: "100%";
  padding: 8px 16px;
  border-top: solid 1px var(--f7-list-border-color);
  border-bottom: solid 1px var(--f7-list-border-color);
  box-sizing: border-box;
  border-radius: 4px;
}

.button-custom {
  padding: 2px !important;
  border-radius: 100% !important;
  display: flex !important;
}

.circle-chip {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  font-weight: bolder;
}

.selected-font {
  outline: 2px solid #477bff;
}

.black-placeholder::placeholder {
  color: rgb(80, 79, 79) !important;
  opacity: 0.6;
}

.black-placeholder::-ms-input-placeholder {
  color: rgb(80, 79, 79) !important;
  opacity: 0.6;
}

.flex-box {
  text-align: center;
  line-height: 86px;
  font-size: 30px;
  box-sizing: border-box;
  border-radius: 4px;
}

.flex-box:hover {
  opacity: 0.9;
  outline: 2px solid #a6bfff;
  cursor: pointer;
}

.list-name ul::before,
.list-name ul::after {
  height: 0px !important;
}

.list-name ul {
  border-radius: 4px;
}

.list-name ul li {
  border-radius: 4px;
}
</style>
