<template>
  <div>
    <data-table
      :headers="headers"
      :items="items"
      :pageSize="items.length"
      class="label-cell"
      :class="{
        'no-margin-horizontal': $device.desktop,
        'dark-header': isEdit,
        'light-header': !isEdit
      }"
    >
      <template v-slot:body="{ item, index }">
        <td>{{ item.productName }}</td>
        <td class="numeric-cell">
          <div v-if="!isEdit">{{ item.amount | currencyUSD }}</div>
          <cell-number-input
            v-else
            placeholder="Price"
            numberType="currency"
            :value="item.amount"
            @done="updateAmount($event, index)"
            @onFocus="totalAmount = item.amount"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td v-if="!invoiceDetail.syncFromQB" class="text-align-center">
          <f7-checkbox
            :disabled="!isEdit"
            :checked="!!item.discount"
            @change="
              updateProductProperty({
                propName: 'discount',
                value: $event.target.checked,
                index: index
              })
            "
          ></f7-checkbox>
        </td>
        <td class="text-align-center">
          <f7-checkbox
            :checked="!!item.tax"
            @change="
              updateProductProperty({
                propName: 'tax',
                value: $event.target.checked,
                index: index
              })
            "
          ></f7-checkbox>
        </td>
      </template>
    </data-table>
    <f7-row>
      <f7-col v-if="$device.desktop" width="100" medium="40"> </f7-col>
      <f7-col width="100" medium="20">
        <!-- <div
          v-if="isEdit"
          :class="{
            summary: !$device.desktop,
            'margin-bottom': !$device.desktop
          }"
          class="summary-container padding"
        >
          <f7-row class="margin-bottom-half">
            <f7-col width="50">Profit Margin</f7-col>
            <f7-col width="50" class="text-align-right">
              {{ invoiceDetail.crossProfitMarginRatio | percent }}
            </f7-col>
          </f7-row>
          <f7-row class="margin-bottom-half">
            <f7-col width="50">Misc</f7-col>
            <f7-col width="50" class="text-align-right">
              {{ invoiceDetail.miscPercent | percent }}
            </f7-col>
          </f7-row>
        </div> -->
      </f7-col>
      <f7-col width="100" medium="40">
        <div :class="{ summary: !$device.desktop }">
          <slot name="summary"></slot>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import DataTable from "@/components/datatables";
import commonMixin from "../../mixins/common-mixin";
import _ from "lodash";
import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL
} from "@/utility/const";
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import Vue from "vue";

export default {
  mixins: [commonMixin],
  props: {
    isEdit: { type: Boolean, default: true },
    invoiceDetail: { type: Object, default: () => {} }
  },
  components: {
    DataTable,
    CellNumberInput
  },
  data() {
    return {
      TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
      TABLE_CONTENT_TYPE_LABOR_MATERIAL,
      currentProductItems: [],
      tableContentType: "",
      discountSelectAll: false,
      taxSelectAll: false,
      totalAmount: ""
    };
  },
  computed: {
    headers() {
      const self = this;
      return [
        {
          text: "Item Details",
          align: "left",
          sortable: false,
          value: "description"
        },
        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount"
        },
        {
          text: "Discount",
          align: "center",
          sortable: false,
          value: "discount",
          selectAll:
            !_.isEmpty(self.currentProductItems) &&
            this.tableContentType !== TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
              ? {
                  value: self.discountSelectAll,
                  onChange: value => {
                    self.discountSelectAll = value;
                    self.currentProductItems = self.currentProductItems.map(
                      r => ({
                        ...r,
                        discount: value
                      })
                    );
                    self.changeItems();
                  },
                  borderColor: "white"
                }
              : false
        },
        {
          text: "Tax",
          align: "center",
          sortable: false,
          value: "tax",
          selectAll:
            !_.isEmpty(self.currentProductItems) &&
            this.tableContentType !== TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
              ? {
                  value: self.taxSelectAll,
                  onChange: value => {
                    self.taxSelectAll = value;
                    self.currentProductItems = self.currentProductItems.map(
                      r => ({
                        ...r,
                        tax: value
                      })
                    );
                    self.changeItems();
                  },
                  borderColor: "white"
                }
              : false
        }
      ];
    },
    items() {
      return this.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ? this.displaySimpleSummary({
            ...this.invoiceDetail,
            itemDetails: this.currentProductItems
          })
        : this.displayLaborMaterial({
            ...this.invoiceDetail,
            itemDetails: this.currentProductItems
          });
    }
  },
  methods: {
    updateAmount(value, index) {
      if (value !== this.totalAmount) {
        this.$emit("onChangeIsEditAmount", true);
      }
      if (this.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY) {
        this.$emit("onChangeTotalAmount", value);
      } else if (this.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL) {
        let newItem = _.cloneDeep(this.currentProductItems[index]);
        newItem.adjustedItemAmount = value;
        Vue.set(this.currentProductItems, index, newItem);
        this.changeItems();
      }
    },
    changeItems() {
      this.$emit("onChangeItems", this.currentProductItems);
    },
    updateProductProperty({ propName, value, index }) {
      this.currentProductItems = this.currentProductItems.map((r, i) => {
        if (i === index) {
          return {
            ...r,
            [propName]: value
          };
        }
        return r;
      });
      this.changeItems();
    },
    validate() {
      return true;
    },
    selectAllDiscount() {
      !_.isEmpty(this.currentProductItems) &&
      this.tableContentType !== TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ? {
            value: this.discountSelectAll,
            onChange: value => {
              this.discountSelectAll = value;
              this.currentProductItems = this.currentProductItems.map(r => ({
                ...r,
                discount: value
              }));
              self.changeItems();
            },
            borderColor: "white"
          }
        : false;
    }
  },
  watch: {
    invoiceDetail: {
      deep: true,
      immediate: true,
      handler(value) {
        this.currentProductItems = _.cloneDeep(value.itemDetails);
        this.tableContentType = value.tableContentType;
      }
    },
    currentProductItems: {
      deep: true,
      immediate: true,
      handler(value) {
        if (
          !_.isEmpty(value) &&
          this.tableContentType !== TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ) {
          this.discountSelectAll =
            value.findIndex(r => !r.discount) > -1 ? false : true;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .dark-header::v-deep .card-content {
    min-width: 800px;
  }
}

.dark-header::v-deep .card-content table thead {
  background: #bbbbbb;
  th {
    color: white;
    font-weight: 500;
  }
}
.light-header::v-deep .card-content table thead {
  background: #a8a8a8;
  th {
    color: white;
    font-weight: 500;
  }
}
.light-header::v-deep .card-content table tbody {
  background: #fff;
  tr {
    border-bottom: 1px solid #e5e7f2;
  }
}
.summary {
  margin-left: calc(
    var(--f7-card-margin-horizontal) + var(--f7-safe-area-left)
  );
  margin-right: calc(
    var(--f7-card-margin-horizontal) + var(--f7-safe-area-right)
  );
}
.summary-container {
  background: white;
  border-radius: 4px;
}
</style>
