import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("dashboard/client", ["contactById", "companyById"]),

    defaultEmailAddess() {
      let result = [];
      if (!_.isEmpty(this.card)) {
        const contactIDList = [];

        // Get all contacts of company
        const companyId = this.card.companyId || "";
        if (!_.isEmpty(companyId)) {
          const company = this.companyById(companyId) || {};
          const contactRefs = company.contactRefs || [];
          contactRefs.forEach(item => {
            contactIDList.push(item.id);
          });
        }

        // Get contact of project
        if (!_.isEmpty(this.card.contactId)) {
          if (!contactIDList.find(item => item === this.card.contactId)) {
            contactIDList.push(this.card.contactId);
          }
        }

        let contact = {};
        let emails = {};
        let email = {};
        contactIDList.forEach(contactId => {
          contact = this.contactById(contactId) || {};
          emails = contact.emails || [];
          email = emails.find(email => {
            return email.code === "main" || email.code === "work";
          });

          if (_.isEmpty(email) && emails.length > 0) {
            email = emails[0];
          }

          if (!_.isEmpty(email)) {
            result.push(email.value);
          }
        });
      }

      return result.join("; ");
    },

    dueDateColor() {
      return dueDate => {
        return this.expiredDate(dueDate)
          ? "red"
          : this.sameCurrentDate(dueDate)
          ? "orange"
          : "green";
      };
    },

    expiredDate() {
      return dueDate => {
        if (!dueDate) return false;
        return moment(dueDate.toDate()).isBefore(moment(), "day");
      };
    },

    sameCurrentDate() {
      return dueDate => {
        if (!dueDate) return false;
        return moment(dueDate.toDate()).isSame(moment(), "day");
      };
    }
  }
};
