<template>
  <div class="company-detail-tab">
    <!-- Property -->
    <f7-block-title class="button-container">
      <f7-button
        fill
        v-if="checkedIds.length > 0"
        class="btn-delete"
        @click="removeSelectedProperty"
      >
        <f7-icon f7="trash" size="18"></f7-icon>
        Remove {{ checkedIds.length }}
        {{ checkedIds.length == 1 ? "item" : "items" }}
      </f7-button>
      <span v-else></span>
      <f7-button
        fill
        color="blue"
        class="btn-add"
        @click="openNewPropertyPopup"
      >
        <f7-icon f7="plus" size="18"></f7-icon> New Property
      </f7-button>
    </f7-block-title>
    <data-table
      :headers="headers"
      :checkbox="true"
      :items="propertyRefs"
      @selected:change="selectCheckbox"
      data-table-collapsible
      class="only-pc-content wrap-text-table"
      :pageSize="propertyRefs.length"
    >
      <template v-slot:body="{ item }">
        <template>
          <td :data-collapsible-title="headers[0].text">
            <a @click.stop="goToPropertyDetail(item.id)">
              {{ item.propertyName }}</a
            >
          </td>
          <td :data-collapsible-title="headers[1].text">
            {{ getAddressDetail(item.addresses) }}
          </td>
          <td :data-collapsible-title="headers[2].text">
            {{ getAddressLocation(item.addresses) }}
          </td>
          <td :data-collapsible-title="headers[3].text">
            {{ toDisplayDateString(item.createdRefAt) }}
          </td>
          <td class="only-pc-content">
            <f7-link
              class="margin-right-half"
              icon-f7="pencil"
              color="blue"
              @click.native.stop="openEditProperty(item.id)"
            ></f7-link>
            <f7-link
              icon-f7="trash"
              color="red"
              @click.native.stop="openDeleteProperty(item.id)"
            ></f7-link>
          </td>
          <td data-collapsible-title="" class="only-mobile-content">
            <f7-row class="button-action-edit">
              <f7-button
                class="margin-right"
                @click.stop="openEditProperty(item.id)"
                large
                small
                outline
                >Edit</f7-button
              >
              <f7-button
                @click.stop="openDeleteProperty(item.id)"
                fill
                small
                color="red"
              >
                Remove
              </f7-button>
            </f7-row>
          </td>
        </template>
      </template>
    </data-table>

    <f7-list accordion-list class="only-mobile-content">
      <f7-list-item
        v-for="(item, index) in propertyRefs"
        :key="index"
        accordion-item
        :title="item.propertyName"
      >
        <f7-accordion-content>
          <f7-block class="property-item">
            <div class="property-label">{{ headers[1].text }}</div>
            <div class="property-value">
              {{ getAddressDetail(item.addresses) }}
            </div>
          </f7-block>
          <f7-block class="property-item">
            <div class="property-label">{{ headers[2].text }}</div>
            <div class="property-value">
              {{ getAddressLocation(item.addresses) }}
            </div>
          </f7-block>
          <f7-block class="property-item">
            <div class="property-label">{{ headers[3].text }}</div>
            <div class="property-value">
              {{ toDisplayDateString(item.createdRefAt) }}
            </div>
          </f7-block>
          <f7-block class="property-item">
            <div class="property-label"></div>
            <div class="property-value row flex-start">
              <f7-button
                class="margin-right"
                @click="openEditProperty(item.id)"
                large
                small
                outline
                >Edit</f7-button
              >
              <f7-button
                @click="openDeleteProperty(item.id)"
                fill
                small
                color="red"
              >
                Remove
              </f7-button>
            </div>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <property-new-popup ref="newProperty" @onCreated="handlePropertySelected">
    </property-new-popup>

    <property-edit-popup ref="editProperty" @onUpdated="handlePropertyUpdated">
    </property-edit-popup>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import PropertyNewPopup from "../../../components/popups/PropertyNewPopup.vue";
import PropertyEditPopup from "../../../components/popups/PropertyEditPopup.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import {
  toDisplayDateString,
  formatDateRefString
} from "../../../../../utility/datetime";

export default {
  data: () => {
    return {
      checkedIds: []
    };
  },

  components: {
    DataTable,
    PropertyNewPopup,
    PropertyEditPopup
  },

  computed: {
    ...mapGetters("contact-book/company", ["propertyRefs", "company"]),

    headers() {
      return [
        {
          text: "Property Name",
          align: "left",
          value: "propertyName",
          width: "30%"
        },
        {
          text: "Address",
          align: "left",
          value: "addresses",
          width: "20%"
        },
        {
          text: "Location",
          align: "left",
          value: "addresses",
          width: "20%"
        },
        {
          text: "Added Date",
          align: "left",
          value: "createdRefAt",
          width: "20%"
        },
        {
          text: "Action",
          align: "left",
          value: "toDate",
          width: "10%"
        }
      ];
    }
  },
  methods: {
    ...mapActions("contact-book/property", ["getProperty"]),

    ...mapActions("contact-book/company", [
      "addPropertyRefs",
      "removePropertyRefs",
      "updatePropertyRefs"
    ]),

    openNewPropertyPopup() {
      this.$refs.newProperty.open();
    },

    async handlePropertySelected(propertyId) {
      this.$f7.preloader.show();
      const property = await this.getProperty(propertyId);
      if (!_.isEmpty(property)) {
        await this.addPropertyRefs(property);
        await this.updatePropertyRefs();
      }
      this.$f7.preloader.hide();
    },

    async handleRemoveProperty(propertyId) {
      await this.removePropertyRefs(propertyId);
      await this.updatePropertyRefs();
    },

    selectCheckbox(value) {
      if (value.checked && !this.checkedIds.includes(value.id)) {
        this.checkedIds.push(value.id);
      } else if (value.checked == false) {
        this.checkedIds = this.checkedIds.filter(id => id != value.id);
      }
    },

    openDeleteProperty(id) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Remove Property",
        content: "Are you sure you want to remove this property?",
        textButton: "Remove",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();

            await app.handleRemoveProperty(id);

            this.$f7.preloader.hide();
            _sefl.app.dialog.close();
          }
        }
      });
    },

    removeSelectedProperty() {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Remove Property",
        content: `Are you sure you want to remove ${
          app.checkedIds.length
        } selected ${app.checkedIds.length == 1 ? "property" : "properties"}?`,
        textButton: "Remove",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();

            for (const id of app.checkedIds) {
              await app.handleRemoveProperty(id);
            }
            app.resetCheckedIds();

            this.$f7.preloader.hide();
            _sefl.app.dialog.close();
          }
        }
      });
    },

    resetCheckedIds() {
      this.checkedIds = [];
    },

    formatDateRefString(fromDate, toDate) {
      return formatDateRefString(fromDate, toDate);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    getAddressDetail(addresses) {
      if (!addresses || !addresses[0]) return "";
      return addresses[0].address ? addresses[0].address : "";
    },

    getAddressLocation(addresses) {
      if (!addresses || !addresses[0]) return "";
      return `${addresses[0].city ? addresses[0].city : ""}${
        addresses[0].state ? ", " + addresses[0].state : ""
      }`;
    },

    goToPropertyDetail(propertyId) {
      this.$f7router.navigate(
        `/contactbook/company/${this.company.id}/property/${propertyId}`
      );
    },

    openEditProperty(propertyId) {
      this.$refs.editProperty.open(propertyId);
    },

    async handlePropertyUpdated(propertyId) {
      this.$f7.preloader.show();
      this.checkedIds = [];

      const property = await this.getProperty(propertyId);

      if (!_.isEmpty(property)) {
        await this.removePropertyRefs(propertyId); //remove old ref
        await this.addPropertyRefs(property); // add new ref
        await this.updatePropertyRefs(); // save changed
      }
      this.$f7.preloader.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);

  &::v-deep .ps__rail-y {
    visibility: hidden;
  }
}
.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
}
.btn-add {
  width: 10%;
  min-width: 140px;
  margin: 0 0 0 1rem;
  justify-content: space-around;
  display: flex;
}
.btn-delete {
  width: 10%;
  min-width: 140px;
  margin: 0 0 0 1rem;
  justify-content: space-around;
  display: flex;
}
.only-mobile-content {
  display: none;
}
.only-pc-content {
  display: block;
}
.wrap-text-table table {
  table-layout: fixed;
}
.wrap-text-table table td {
  overflow-wrap: anywhere;
}
@media (max-width: 480px) and (orientation: portrait) {
  .only-mobile-content {
    display: block;
  }
  .only-pc-content {
    display: none !important;
  }
  .button-container {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .button-container a {
    margin: 0px;
  }
  .property-item {
    display: flex;
    padding: 0.5rem 0;
    padding-left: 1rem;
  }
  .property-item:after {
    content: "";
    position: absolute;
    background-color: var(--f7-list-item-border-color);
    display: block;
    margin-left: 1rem;
    z-index: 15;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  }
  .property-label {
    width: 30%;
    font-size: var(--f7-table-head-font-size);
    font-weight: var(--f7-table-head-font-weight);
    color: var(--f7-table-head-text-color);
    margin-right: 16px;
  }
  .property-value {
    width: 70%;
    justify-content: flex-start;
  }
}
</style>
<style>
@media (max-width: 480px) and (orientation: portrait) {
  .company-detail-tab tr td:first-child {
    padding-left: 0px !important;
  }
  .company-detail-tab tr td:first-child:before {
    width: 0 !important;
  }
}
</style>
