import moment from "moment";
import { DEFAULT_STATUS_PRICE_LIST } from "../../../../utility/const";

export default {
  priceListItemList: state => {
    return state.priceListItemList.filter(r => {
      const compareDate = moment();
      const startDate = moment(r.startDate.toDate());
      if (r.endDate) {
        const endDate = moment(r.endDate.toDate());
        return (
          r.status === DEFAULT_STATUS_PRICE_LIST &&
          compareDate.isBetween(startDate, endDate, "days", true)
        );
      } else {
        return (
          r.status === DEFAULT_STATUS_PRICE_LIST &&
          compareDate.isSameOrAfter(startDate, "days")
        );
      }
    });
    // return state.priceListItemList;
  },

  priceListItem: (state, _, __, rootGetters) => priceListId => {
    const productList =
      rootGetters["estimate/estimate-page/product-item/productList"] || [];

    const price =
      state.priceListItemList.find(item => item.id === priceListId) || {};
    const productRefs = ((price && price.productRefs) || []).map(productRef => {
      const product =
        productList.find(product => product.id === productRef.productId) || {};
      return {
        ...productRef,
        product: { ...product, price: productRef.price }
      };
    });

    return {
      ...price,
      productRefs
    };
  },

  priceList: (state, _, __, rootGetters) => {
    const productList =
      rootGetters["estimate/estimate-page/product-item/productList"] || [];
    const price = state.priceListItem || {};

    const productRefs = ((price && price.productRefs) || []).map(productRef => {
      const product =
        productList.find(product => product.id === productRef.productId) || {};
      return {
        ...productRef,
        product: { ...product, price: productRef.price }
      };
    });

    return {
      ...price,
      productRefs
    };
  },

  currentPriceList: state => state.priceListItem,

  categoryById: state => categoryId => {
    return state.categoryList.find(item => item.id === categoryId) || {};
  }
};
