<template>
  <div>
    <f7-list accordion-list accordion-opposite>
      <f7-list-item
        v-for="(value, index) in sortedHistoryList"
        :key="index"
        accordion-item
        :class="`accordion-item_${value.date}`"
      >
        <div slot="title" style="font-weight: 600">
          {{ value.date }}
        </div>
        <f7-accordion-content>
          <data-table
            :headers="headers"
            :items="value.data || []"
            :pageSize="(value.data || []).length"
            class="wrap-text-table dark-header"
          >
            <template v-slot:body="{ item }">
              <template v-if="item.parentRow">
                <td class="parent-row" :colspan="headers.length">
                  {{ item.buildingName }}
                </td>
              </template>
              <template v-else>
                <td>{{ vendorName((item.product || {}).vendorId) }}</td>
                <td>{{ (item.product || {}).manufacturer }}</td>
                <td>{{ (item.product || {}).productItem }}</td>
                <td>{{ item.oldPrice | currencyUSD }}</td>
                <td>{{ item.newPrice | currencyUSD }}</td>
                <td>{{ toLocaleString(item.createdAt) }}</td>
                <td>
                  {{ item.createdBy }}
                </td>
              </template>
            </template>
          </data-table>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>
<script>
import DataTable from "@/components/datatables";
import { mapGetters } from "vuex";
import { sortLatest } from "@/utility/date-time-tool";
import { groupBy } from "@/utility/filter-tools";
import moment from "moment";
import _ from "lodash";

export default {
  components: { DataTable },
  data() {
    return {
      searchText: ""
    };
  },
  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "buildingList",
      "estimateHistoryList"
    ]),
    ...mapGetters({
      vendors: "estimate/estimate-page/vendor/objectList"
    }),
    headers() {
      return [
        {
          text: "Vendor",
          align: "left",
          value: "addresses"
        },
        {
          text: "Manufacturer",
          align: "left",
          value: "addresses"
        },
        {
          text: "Product Name",
          align: "left",
          value: "productName"
        },
        {
          text: "Old Price",
          align: "left",
          value: "oldPrice"
        },
        {
          text: "New Price",
          align: "left",
          value: "newPrice"
        },
        {
          text: "Change At",
          align: "left",
          value: "addresses"
        },
        {
          text: "Change By",
          align: "left",
          value: "changeBy"
        }
      ];
    },
    sortedHistoryList() {
      if (_.isEmpty(this.estimateHistoryList)) return [];
      const groupByDate = groupBy(
        sortLatest(this.estimateHistoryList),
        history =>
          history && history.createdAt
            ? moment(history.createdAt.toDate()).format("MM/DD/YYYY")
            : ""
      ).map(item => ({
        date: item.key,
        data: groupBy(sortLatest(item.data), b =>
          b ? b.buildingId : ""
        ).reduce((prev, curr) => {
          const buildingName = (
            (this.buildingList || []).find(b => b.id === curr.key) || {}
          ).buildingName;
          return prev
            .concat([
              {
                parentRow: true,
                buildingName: buildingName
              }
            ])
            .concat(curr.data);
        }, [])
      }));
      return groupByDate;
    }
  },
  methods: {
    vendorName(vendorId) {
      const vendor = (this.vendors || []).find(r => r.id == vendorId);
      return (vendor || {}).companyName;
    },
    toLocaleString(firebaseDate) {
      return firebaseDate ? firebaseDate.toDate().toLocaleString() : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.parent-row {
  background-color: #e2e2e2;
  font-weight: bold;
}
.dark-header::v-deep .card-content table thead {
  background: #9c9c9c;
  th {
    color: white;
    font-weight: 500;
  }
}
</style>
