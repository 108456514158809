export default {
  // eslint-disable-next-line no-unused-vars
  invoiceList: (state, _, __, _rootGetters) => {
    return state.invoiceList || [];
    // .map(invoice => {
    //   return {
    //     ...invoice,
    //       invoiceDate: invoice.invoiceDate
    //       ? moment(invoice.invoiceDate.toDate()).format("MM/DD/YYYY")
    //       : "",
    //     dueDate: invoice.dueDate
    //       ? moment(invoice.dueDate.toDate()).format("MM/DD/YYYY")
    //       : "",
    //     paidDate: invoice.paidDate
    //       ? moment(invoice.paidDate.toDate()).format("MM/DD/YYYY")
    //       : "",
    //     status:
    //       this.getOverdueDays(invoice, true) > 0
    //         ? INVOICE_STATUS_OVERDUE
    //         : invoice.status
    //   };
    // });
  },
  invoice: state => {
    return state.invoice || {};
  },
  invoiceByNumber: state => invoiceByNumber => {
    return state.invoiceList.find(r => r.invoiceNumber === invoiceByNumber);
  },
  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  selectedStatusFilter: state => state.selectedStatusFilter,
  queryFilters: state => {
    return state.selectedStatusFilter.map(s => `status:${s}`).join(" OR ");
  }
};
