import project from "./project";
import timeTracking from "./time-tracking";
import timeTrackingDraf from "./time-tracking-draf";
import payRate from "./employee-pay-rate";
import user from "./user";
import group from "./group";
import appConstant from "./app-constant";
import quickbooks from "./quickbooks";
import serviceItemSetting from "./service-item-setting";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      project: project(tenant),
      "time-tracking": timeTracking(tenant),
      "time-tracking-draf": timeTrackingDraf(tenant),
      "pay-rate": payRate(tenant),
      user,
      group: group(tenant),
      "app-constant": appConstant(tenant),
      quickbooks: quickbooks(tenant),
      "service-item-setting": serviceItemSetting(tenant)
    }
  };
};
