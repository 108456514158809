import {
  CODE_STATUS_OPTION,
  ENTITY_PRODUCT_ITEM,
  ENTITY_PRICE_LIST
} from "@/utility/const";

export default {
  typeList: state => state.typeList,
  productItemStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_PRODUCT_ITEM
    ),

  productItemStatusByValue: state => value =>
    state.typeList.find(item => item.value === value) || {},

  priceListStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_PRICE_LIST
    ),

  priceListStatusByValue: state => value =>
    state.typeList.find(item => item.value === value) || {}
};
