import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import SpreadsheetWriter from "../../utility/spreadsheet-writer";
import { currencyUSD, percentToFixed } from "../../utility/config";
// import moment from "moment";

export const getExcelWorkbook = ({
  title,
  headers,
  values,
  itemTax,
  totals,
  subTotal,
  mobilization
}) => {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Schedule of value results");
  var writer = new SpreadsheetWriter(worksheet);
  const columns = [
    { header: "", key: "name", width: 30 },
    ...headers.map(r => ({
      header: r.name,
      key: r.id,
      width: 10,
      style: { numFmt: '"$"#,##0.00' }
    })),
    { header: "Total", key: "totals", width: 10 }
  ];
  writer.bold();
  writer.textCenter();
  writer.write((title || "").toUpperCase());
  writer.mergeNext(columns.length * 2 - 1);
  writer.enter();
  writer.enter();
  //headers
  columns.forEach(x => {
    writer.write(x["header"]);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.mergeNext();
    writer.right();
    writer.right();
  });
  columns.shift();
  values.forEach(row => {
    writer.enter();
    writer.write(row.name);
    writer.border();
    writer.mergeNext();
    writer.right();
    writer.right();
    columns.forEach(column => {
      if (row.name === "Percentage") {
        writer.write(
          row[column["key"]] ? percentToFixed(row[column["key"]]) : ""
        );
      } else {
        writer.write(currencyUSD(row[column["key"]] || 0));
      }
      writer.border();
      writer.mergeNext();
      writer.right();
      writer.right();
    });
  });

  // tax row
  if (itemTax && itemTax.length > 0) {
    writer.enter();
    writer.write(itemTax[0].name);
    writer.border();
    writer.mergeNext();
    writer.right();
    writer.right();
  }

  columns.forEach(column => {
    if (itemTax && itemTax.length > 0) {
      writer.write(currencyUSD(itemTax[0][column["key"]]));
      writer.border();
      writer.mergeNext();
      writer.right();
      writer.right();
    }
  });

  writer.enter();
  writer.right(headers.length * 2);
  writer.write("Sub-Total");
  writer.border();
  writer.mergeNext();
  writer.right(2);
  writer.write(currencyUSD(subTotal));
  writer.border();
  writer.mergeNext();
  writer.bold();

  writer.enter();
  writer.right(headers.length * 2);
  writer.write("Mobilization");
  writer.border();
  writer.mergeNext();
  writer.right(2);
  writer.write(currencyUSD(mobilization));
  writer.border();
  writer.mergeNext();
  writer.bold();

  writer.enter();
  writer.right(headers.length * 2);
  writer.write("Total");
  writer.border();
  writer.mergeNext();
  writer.right(2);
  writer.write(currencyUSD(totals));
  writer.border();
  writer.mergeNext();
  writer.bold();
  writer.enter();
  writer.enter();
  return workbook;
};

function exportScheduleOfValue({
  title,
  headers,
  values,
  itemTax,
  totals,
  subTotal,
  mobilization
}) {
  const workbook = getExcelWorkbook({
    title,
    headers,
    values,
    itemTax,
    totals,
    subTotal,
    mobilization
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `${title}.xlsx`
    );
  });
}

export default exportScheduleOfValue;
