import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import { searchClient } from "../../../../services/search.service";
import * as types from "./types";
import algoliaService from "../../../../services/algolia.service";

export default tenant => {
  const ContractActions = new FirebaseActions(
    `/system_client/${tenant}/contract`,
    "contract"
  );

  const contractIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const contractIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    getContractBys: ContractActions.getDocumentBys,
    bindContract: ContractActions.bindDocument,
    unbindContract: ContractActions.unbindDocument,
    saveContract: ContractActions.updateDocument,

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchContract(
      { state, commit, getters, rootGetters },
      { attributesToRetrieve }
    ) {
      const index =
        state.order === "asc" ? contractIndexAsc : contractIndexDesc;
      const searchText = rootGetters["proposal/proposal/searchText"];
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_CONTRACT} AND isProjectArchived:false`,
        attributesToRetrieve: constant.CONTRACT_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "contractNumber",
          "contractTitle",
          "status",
          "projectName",
          "contactName"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreContract(
      { state, commit, getters, rootGetters },
      { attributesToRetrieve, page }
    ) {
      const index =
        state.order === "asc" ? contractIndexAsc : contractIndexDesc;
      const searchText = rootGetters["proposal/proposal/searchText"];
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_CONTRACT} AND isProjectArchived:false`,
        attributesToRetrieve: constant.CONTRACT_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "contractNumber",
          "contractTitle",
          "status",
          "projectName",
          "contactName"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },
    setContractNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async updateContract({ dispatch, commit }, { id, doc }) {
      if (doc.projectId) {
        doc.isProjectArchived = await dispatch(
          "common/common/getIsProjectArchived",
          doc.projectId,
          { root: true }
        );
      }
      await dispatch("saveContract", { id, doc });
      // save to algolia
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "contract",
        id
      });
      return commit(types.CONTRACT_LIST_ITEM_UPDATE, {
        id,
        doc
      });
    },
    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    }
  };
};
