<template>
  <div v-if="isTemplate">
    <div style="position: relative">
      <div class="display-flex justify-content-flex-end padding-half">
        <f7-button @click="selectColumns">
          Add/Remove Columns
        </f7-button>
      </div>
      <f7-list-item
        class="select-items"
        title="Select columns to visible"
        ref="selectColumns"
        smart-select
        :smart-select-params="{
          openIn: 'popover',
          on: {
            closed: selectionChangeColumnsTemplate
          }
        }"
      >
        <select name="colunms" multiple>
          <option
            v-for="item in colunmOptions"
            :value="item.value"
            :key="item.value"
            :selected="visibleColumnsTemplate.includes(item.value)"
            :disabled="item.disabled"
          >
            {{ item.displayName }}
          </option>
        </select>
      </f7-list-item>
    </div>
    <basic-table
      name="table-shingle"
      color="#e2e2e2"
      textColor="#000"
      :building="{}"
      :estimate="{}"
      :project="{}"
      :isTemplate="isTemplate"
      :visibleColumns="visibleColumnsTemplate"
    />
  </div>
  <div v-else>
    <div v-show="data.estimateId" style="position: relative">
      <div class="display-flex justify-content-flex-end padding-half">
        <f7-button @click="selectColumns">
          Add/Remove Columns
        </f7-button>
      </div>
      <f7-list-item
        class="select-items"
        title="Select columns to visible"
        ref="selectColumns"
        smart-select
        :smart-select-params="{
          openIn: 'popover',
          on: {
            closed: selectionChange
          }
        }"
      >
        <select name="colunms" multiple>
          <option
            v-for="item in colunmOptions"
            :value="item.value"
            :key="item.value"
            :selected="visibleColumns.includes(item.value)"
            :disabled="item.disabled"
          >
            {{ item.displayName }}
          </option>
        </select>
      </f7-list-item>
    </div>
    <f7-list style="margin-top: 5px">
      <f7-list-item link @click.native="selectBuilding">
        <div class="list-item-title" slot="title">
          <span>
            {{
              (data.building && data.building.buildingName) || "Select building"
            }}
          </span>
        </div>
        <input-icon
          slot="media"
          icon="building_2_fill"
          style="margin-top: 0"
        ></input-icon>
      </f7-list-item>
    </f7-list>
    <basic-table
      name="table-shingle"
      color="#e2e2e2"
      textColor="#000"
      :building="data.building"
      :estimate="proposal.estimate"
      :project="proposal.project"
      :visibleColumns="visibleColumns"
      @removeProductItem="removeProductItem"
    />
    <!-- popups -->
    <div>
      <building-list-popup
        ref="selectBuilding"
        :list="buildingList"
        @onSelected="onSelectBuilding"
      ></building-list-popup>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BuildingListPopup from "../popup/BuildingListPopup.vue";
import InputIcon from "@/components/icons/InputIcon.vue";
import BasicTable from "../tables/basic/BasicTable.vue";
import _ from "lodash";

export default {
  props: {
    data: Object,
    isTemplate: { type: Boolean, default: false }
  },

  components: {
    BasicTable,
    BuildingListPopup,
    InputIcon
  },

  computed: {
    ...mapGetters({
      estimateList: "proposal/estimate/estimateList",
      proposal: "proposal/proposal/proposal",
      proposalTemplate: "proposal/template/proposalTemplate"
    }),
    estimateListByProject() {
      return this.estimateList.filter(
        r => r.projectId == this.proposal.projectId
      );
    },
    buildingList() {
      return (this.proposal.estimate.buildings || []).filter(
        r => !(this.proposal.buildingIDs || []).includes(r.buildingId)
      );
    },
    visibleColumns() {
      return !_.isEmpty(this.proposal) &&
        !_.isEmpty(this.proposal.visibleColumns)
        ? this.proposal.visibleColumns
        : [];
    },
    visibleColumnsTemplate() {
      return !_.isEmpty(this.proposalTemplate) &&
        !_.isEmpty(this.proposalTemplate.visibleColumns)
        ? this.proposalTemplate.visibleColumns
        : [];
    },
    isDisableColumn() {
      return (this.proposal.productIdsRemoved || []).length > 0;
    },
    colunmOptions() {
      return [
        { value: "productItem", displayName: "Product Item", disabled: false },
        { value: "color", displayName: "Color", disabled: false },
        { value: "manufacturer", displayName: "Manufacturer", disabled: false },
        { value: "photos", displayName: "Photo", disabled: false },
        {
          value: "technicalData",
          displayName: "Technical Data",
          disabled: false
        },
        {
          value: "proposalNote",
          displayName: "Proposal Note",
          disabled: false
        },
        { value: "orderQty", displayName: "QTY", disabled: false },
        {
          value: "price",
          displayName: "Price",
          disabled: this.isDisableColumn
        },
        {
          value: "taxAmount",
          displayName: "Tax Amount",
          disabled: this.isDisableColumn
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      getEstimateById: "proposal/estimate/getEstimateById",
      getBuilding: "proposal/estimate/getBuilding",
      setProposalField: "proposal/proposal/setProposalField",
      setProposalTemplateField: "proposal/template/setProposalTemplateField"
    }),
    estimateById(estimateId) {
      this.estimateList.find(r => r.id === estimateId) || {};
    },
    selectBuilding() {
      this.$refs.selectBuilding.open();
    },
    change(props) {
      this.$emit("change", { ...this.data, ...props });
    },
    async onSelectBuilding(id) {
      const { buildingId, estimateId } = this.data;
      let buildingIDs = _.cloneDeep(this.proposal.buildingIDs || []);
      if (buildingId) {
        // remove old buildingId
        buildingIDs = buildingIDs.filter(r => r !== buildingId);
        const productIdsRemoved =
          _.cloneDeep(this.proposal.productIdsRemoved) || [];
        this.setProposalField({
          fieldName: "productIdsRemoved",
          value: productIdsRemoved.filter(r => r.buildingId !== buildingId)
        });
      }
      buildingIDs.push(id);
      this.setProposalField({
        fieldName: "buildingIDs",
        value: [...new Set(buildingIDs)]
      });
      const building = await this.getBuilding({ estimateId, buildingId: id });
      this.change({
        buildingId: id,
        building: {
          id: building.id,
          buildingName: building.buildingName || "",
          slopeType: building.slopeType,
          roofType: building.roofType,
          substrate: building.substrate || "",
          fieldArea: building.fieldArea || 0,
          productData: building.productData || [],
          description: building.description || "",
          warrantyType: building.warrantyType || "",
          warrantyNotes: building.warrantyNotes || "",
          assemblyList: (building.assemblyList || []).map(assembly => ({
            assemblyItem: assembly.assemblyItem || "",
            assemblyGA: assembly.assemblyGA || "",
            length: assembly.length || 0,
            diagramUrl: "",
            diagramFullPath: ""
          })),
          firstExistingRoofPhoto: null
        }
      });
    },
    selectColumns() {
      this.$refs.selectColumns.f7SmartSelect.open();
    },
    selectionChangeColumnsTemplate(v) {
      const value = v.getValue();
      this.setProposalTemplateField({
        fieldName: "visibleColumns",
        value: value
      });
    },
    selectionChange(v) {
      const value = v.getValue();
      this.setProposalField({ fieldName: "visibleColumns", value: value });
    },
    removeProductItem() {
      this.change({});
      if (!this.isDisableColumn) {
        // remove columns price and tax amount
        const visibleColumns = this.visibleColumns.filter(
          r => !["price", "taxAmount"].includes(r)
        );
        this.setProposalField({
          fieldName: "visibleColumns",
          value: visibleColumns
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.select-items {
  width: 10px;
  height: 0px;
  position: absolute;
  visibility: hidden;
  right: 67px;
  top: -18px;
}
</style>
