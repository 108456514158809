<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="displayInvoiceList"
      :pageSize="displayInvoiceList.length"
      class="wrap-text-table"
      @onClickRow="openInvoicePreview"
      :style="
        $device.desktop
          ? { 'max-height': 'calc(100vh - var(--f7-navbar-height) - 32px)' }
          : {}
      "
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ invoiceIsPayment | currencyUSD }}</i>
            </div>
          </f7-row>
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item }">
        <td>
          <f7-link>{{ item.invoiceNumber }}</f7-link>
        </td>
        <td>
          <f7-chip
            slot="after"
            :text="getStatus(item.status).displayName"
            :color="getStatus(item.status).color"
          ></f7-chip>
        </td>
        <td>{{ total(item) | currencyUSD }}</td>
        <td>{{ item.paidAmount | currencyUSD }}</td>
        <td>{{ item.invoiceDate }}</td>
        <td>{{ item.dueDate }}</td>
        <td>{{ item.paidDate }}</td>
      </template>
    </data-table>

    <invoice-preview-popup ref="invoicePreviewPopup"></invoice-preview-popup>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { toDisplayDateString } from "@/utility/datetime";
import { sortLatest } from "@/utility/date-time-tool";
import { INVOICE_STATUS_OVERDUE } from "../../../invoices/utility/const";
import InvoicePreviewPopup from "../popup/InvoicePreviewPopup.vue";
import commonInvoicesMixins from "../../../invoices/mixins/common-mixin";
import moment from "moment";

export default {
  mixins: [commonInvoicesMixins],
  props: {
    invoiceList: { type: Array, default: () => [] },
    invoiceGroup: { type: Object, default: () => {} },
    invoiceStatus: { type: Array, default: () => [] }
  },
  components: { DataTable, InvoicePreviewPopup },

  computed: {
    invoiceIsPayment() {
      return (this.invoiceList || []).reduce((prev, invoice) => {
        return prev + (invoice.paidAmount || 0);
      }, 0);
    },

    tableHeaders() {
      let headers = [
        {
          text: "Invoice#",
          align: "left",
          sortable: false,
          value: "invoiceNumber"
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status"
        },
        {
          text: "Invoice Amount",
          align: "left",
          sortable: false,
          value: "total"
        },
        {
          text: "Paid Amount",
          align: "left",
          sortable: false,
          value: "paidAmount"
        },
        {
          text: "Invoice Date",
          align: "left",
          sortable: false,
          value: "invoiceDate"
        },
        {
          text: "Due Date",
          align: "left",
          sortable: false,
          value: "dueDate"
        },
        {
          text: "Paid Date",
          align: "left",
          sortable: false,
          value: "paidDate"
        }
      ];
      return headers;
    },

    displayInvoiceList() {
      let list = this.invoiceList.map(item => ({
        ...item,
        invoiceDate: toDisplayDateString(item.invoiceDate),
        paidDate: toDisplayDateString(item.paidDate),
        dueDate: toDisplayDateString(item.dueDate),
        status:
          this.getOverdueDays(item) > 0 ? INVOICE_STATUS_OVERDUE : item.status
      }));
      list = sortLatest(list);
      return list;
    }
  },

  methods: {
    openInvoicePreview(item) {
      this.$refs.invoicePreviewPopup.open(item, this.invoiceGroup);
    },
    dateToValue(a) {
      return a._seconds * 1000 + a._nanoseconds / 1000000;
    },
    convertDateTime(date) {
      return moment(new Date(this.dateToValue(date))).toDate();
    },
    getOverdueDays(invoice) {
      // let total = parseFloat(this.total(invoice));
      let paidAmount = invoice.paidAmount || 0;
      const currentDate = moment(new Date(), "MM/DD/YYYY");
      const dueDate = moment(
        this.convertDateTime(invoice.dueDate),
        "MM/DD/YYYY"
      );
      const days = currentDate.diff(dueDate, "day");
      if (paidAmount === 0 && days > 0) {
        return days;
      }
      return 0;
    },
    getStatus(status) {
      return (this.invoiceStatus || []).find(x => x.value === status) || {};
    }
  }
};
</script>
