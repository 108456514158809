import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const TemplateActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "object"
  );

  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    "contact"
  );
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "company"
  );
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    "property"
  );

  return {
    getContact: contactActions.getDocument,
    getCompany: companyActions.getDocument,
    getProperty: propertyActions.getDocument,

    bind: ({ dispatch }, boardId) => {
      dispatch("bindBys", [
        {
          prop: "boardId",
          op: "==",
          val: boardId
        },
        {
          prop: "status",
          op: "==",
          val: "open"
        }
      ]);
    },
    update: async ({ dispatch /*, commit*/ }, { id, doc }) => {
      if (doc.contactId) {
        const contact = await dispatch("getContact", doc.contactId);
        doc.contactName = contact
          ? ((contact.firstName || "") + " " + (contact.lastName || "")).trim()
          : "";
      }
      if (doc.companyId) {
        const company = await dispatch("getCompany", doc.companyId);
        doc.companyName = company ? company.companyName || "" : "";
      }
      if (doc.propertyId) {
        const property = await dispatch("getProperty", doc.propertyId);
        doc.propertyName = property ? property.propertyName || "" : "";
        doc.propertyAddress =
          (property.addresses || []).find(r => r.code === "main") ||
          (property.addresses || [])[0] ||
          {};
      }
      return dispatch("updateCloud", { id, doc });
    },

    setFilterUsers({ commit }, payload) {
      commit(types.SET_FILTER_USERS, payload);
    },

    setFilterSearch({ commit }, payload) {
      commit(types.SET_FILTER_SEARCH, payload);
    },

    setFilterWeek({ commit }, payload) {
      commit(types.SET_FILTER_WEEK, payload);
    },

    setCurrentProject({ commit }, project) {
      commit(types.SET_CURRENT_PROJECT, project);
    },
    unbind: TemplateActions.unbindCollection,
    create: TemplateActions.createDocument,
    updateCloud: TemplateActions.updateDocument,
    delete: TemplateActions.deleteDocument,
    bindBys: TemplateActions.bindCollectionBys,
    getCardByNumber: TemplateActions.getDocumentBys
  };
};
