import { checkAuth } from "../../../services/authentication.service";
import ProjectPhotosClientView from "../pages/ProjectPhotosClientView.vue";
import ProjectAlbumClientView from "../pages/ProjectAlbumClientView.vue";

export default [
  {
    path: "",
    title: "Roofing Cam",
    displayRoles: ["owner", "user"],
    routes: [
      {
        path: "/photo",
        title: "Roofing Cam",
        name: "photo-main",
        parent: "dashboard-main",
        displayRoles: ["owner", "user"],
        icon: "camera",
        async: checkAuth(() => import("../pages/Projects.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/photo/:id",
            async: checkAuth(() => import("../pages/ProjectPhotos.vue"))
          },
          {
            path: "/photo/:id/:albumId",
            async: checkAuth(() => import("../pages/AlbumDetails.vue"))
          }
        ]
      }
    ]
  },
  // list of unauthorized users can view.
  {
    path: "",
    title: "project photo",
    name: "share-photo",
    routes: [
      {
        path: "/share-photo/:tenantId/:projectId",
        title: "Project Photo",
        name: "Project Photo",
        icon: "doc_text",
        component: ProjectPhotosClientView
      },
      {
        path: "/share-photo/:tenantId/:projectId/:albumId",
        title: "Album Photo",
        name: "Album Photo",
        icon: "doc_text",
        component: ProjectAlbumClientView
      }
    ]
  }
];
