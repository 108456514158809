<template>
  <div class="margin-top-half">
    <div v-if="chips.length > 0">
      <chip
        v-for="chip in chips"
        :key="chip.id"
        :name="chip.name"
        :deleteable="deleteable"
        @remove="$emit('remove', chip.id)"
        class="margin-right-half"
      />
      <f7-chip
        :class="showAll ? 'display-none' : 'show'"
        color="red"
        :text="`+${data.length - limit}`"
        v-if="data.length > limit"
        @click="showAll = true"
        style="cursor: pointer;"
      ></f7-chip>
    </div>
    <div v-else class="no-data">No Data!</div>
  </div>
</template>
<script>
import Chip from "./Chip";

export default {
  components: {
    Chip
  },
  data: () => ({
    showAll: false
  }),
  props: {
    limit: Number,
    deleteable: Boolean,
    data: Array
  },
  computed: {
    chips() {
      return this.showAll ? this.data.slice() : this.data.slice(0, this.limit);
    }
  }
};
</script>
<style lang="scss" scoped>
.no-data {
  font-size: 12px;
  font-weight: 200;
  // font-style: italic;
}
</style>
