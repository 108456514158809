import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import * as types from "./types";
import priceListService from "../../../../services/price-list.service";

export default tenant => {
  const priceActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    "price",
    {
      modelSchema: {
        productRefs: [
          {
            price: "float"
          }
        ]
      }
    }
  );
  return {
    getPriceBys: priceActions.getDocumentBys,
    getPrice: priceActions.getDocument,
    addPrice: priceActions.createDocument,
    savePrice: priceActions.updateDocument,

    getPriceList({ dispatch, commit }) {
      return dispatch("getPriceBys", [
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]).then(res => commit(types.PRICE_LIST, res));
    },

    createPrice({ commit, dispatch, rootGetters }, data) {
      const user = rootGetters["setting/app/profile/user"];
      return priceListService
        .addPriceListDoc(user.tenantId, data)
        .then(async doc => {
          const res = await dispatch("getPrice", doc.id);
          commit(types.PRICE_LIST_ITEM_ADD, res);
          return doc;
        });
    },

    updatePrice({ dispatch, commit }, { id, doc }) {
      return dispatch("savePrice", { id, doc }).then(() => {
        return commit(types.PRICE_LIST_ITEM_UPDATE, {
          id,
          doc
        });
      });
    },

    deletePrice({ dispatch, commit }, id) {
      return dispatch("savePrice", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      }).then(() => {
        return commit(types.PRICE_LIST_ITEM_REMOVE, id);
      });
    },

    resetPriceList({ commit }) {
      commit(types.PRICE_LIST, []);
    }
  };
};
