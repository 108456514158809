import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/email_notification`,
    "emailNotification"
  );

  return {
    deleteEmailNotification: ActionsTemplate.deleteDocument,
    getEmailNotification: ActionsTemplate.getDocument,
    getEmailNotificationBys: ActionsTemplate.getDocumentBys
  };
};
