<template>
  <f7-popup :opened="openedPopup" @popup:closed="
    closePopup();
  deleteTempWorkOrderTask();
  ">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="openedPopup = false">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          New Purchase Order
        </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="handleClickGenerate()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input label="Due Date" type="datepicker" :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }" placeholder="Select due date" error-message-force validate validate-on-blur :value="dueDate"
          :error-message="requireErrorMessage('dueDate')" @calendar:change="
            dueDate = $event;
          $f7.calendar.close();
          ">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="clock"></input-icon>
        </f7-list-input>
      </f7-list>
      <attachment-input :project-id="workOrderTaskId" modelType="work-order-tasks" title="Attachments"
        attachment-type="attachment" add-button-title="Add an Attachment" :value="attachmentFiles"
        @input="attachmentFiles = $event"></attachment-input>

      <user-input :value="assigneeIds" modelType="wo-task" @input="assigneeIds = $event"></user-input>

      <f7-block>
        <p v-if="isBuildYourOwn">
          <a @click="isBuildYourOwn = !isBuildYourOwn">
            Create Purchase Order from Estimate
          </a>
          or Create your own Purchase Order
        </p>
        <p v-else>
          Create Purchase Order from Estimate or
          <a @click="isBuildYourOwn = !isBuildYourOwn">Create your own Purchase Order</a>
        </p>
      </f7-block>

      <div v-if="isBuildYourOwn">
        <f7-list class="estimate-list">
          <f7-list-input type="select" label="Roof Type" :value="roofType"
            @change="onRoofTypeChanged($event.target.value)" error-message-force validate validate-on-blur
            :error-message="requireErrorMessage('roofType')">
            <required-asterisk slot="label"></required-asterisk>
            <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
            <option value="" hidden>Select roof type</option>
            <option v-for="item in roofTypes" :key="item.id" :value="item.value">{{ item.displayName }}</option>
          </f7-list-input>
        </f7-list>
        <f7-block-title v-if="filteredVendors && filteredVendors.length > 0">Vendors</f7-block-title>

        <f7-list class="estimate-list">
          <f7-list-item v-for="(item, index) in filteredVendors" :key="index" :title="item.name" :text-color="selectedVendors.some(r => r === item.id) ? 'primary' : 'default'
            " radio name="vendor-list" @change="v => updateSelectedVendors(item, v)" />
        </f7-list>
      </div>

      <div v-else>
        <f7-block-title>Estimate</f7-block-title>
        <f7-list class="estimate-list">
          <f7-list-item v-for="estimate in estimateList" :key="estimate.id" radio :value="estimate.id"
            name="estimate-list" :title="estimate.estimateNumber" @change="() => updateSelectedEstimate(estimate)"
            :checked="estimate.id === (selectedEstimate || {}).id">
            <f7-chip slot="after" :text="estimateStatusBy(estimate.status).displayName"
              :color="estimateStatusBy(estimate.status).color"></f7-chip>
          </f7-list-item>
        </f7-list>

        <f7-block-title v-if="selectedEstimate && selectedEstimate.buildings">Building</f7-block-title>
        <f7-list class="estimate-list">
          <f7-list-item v-for="building in selectedEstimate
            ? selectedEstimate.buildings
            : []" :key="building.buildingId" checkbox name="building-list" :title="building.buildingName" @change="$event =>
              updateSelectedBuilding({
                checked: $event.target.checked,
                buildingId: building.buildingId
              })
              "></f7-list-item>
        </f7-list>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";
import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { removeFile } from "../../../../services/firebase.service";
import { toDateFirebase } from "../../../../utility/datetime";
import dashboardService from "../../../../services/dashboard.service";
import { StorageActions } from "../../../../services/firebase.service";
import { ACTION_CODE_CONSTRUCTION, VALIDATION_MESSAGE } from "../../../../utility/const";
import { getFullAddress } from "@/utility/address";
import poMixin from "../../mixin/purchase-order-mixin";

export default {
  components: {
    InputIcon,
    UserInput,
    AttachmentInput
  },

  mixins: [poMixin],

  props: {
    show: { type: Boolean, default: false },
    jobObject: Object,
    fromServiceBoard: { type: Boolean, default: false }
  },

  data() {
    return {
      openedPopup: false,
      dueDate: [],
      selectedEstimate: {},
      selectedBuildings: [],
      selectedVendors: [],

      isBuildYourOwn: false,
      workOrderTaskId: "",
      roofType: "",

      assigneeIds: [],
      attachmentFiles: [],
      productAllList: [],

      isDeleteDraftPO: true
    };
  },

  computed: {
    ...mapGetters("dashboard/project", [
      "card",
      "constructionActions",
      "activeUsers",
      "actionList"
    ]),
    ...mapGetters("dashboard/estimate", ["estimateList"]),
    ...mapGetters("dashboard/product-item", ["productItemList"]),
    ...mapGetters("dashboard/client", ["companyList", "companyById"]),
    ...mapGetters("dashboard/app-constant", [
      "roofTypeList",
      "estimateStatusBy",
      "roofTypeBy"
    ]),

    project() {
      if (this.fromServiceBoard) {
        return this.jobObject;
      }
      return this.card;
    },

    ...mapGetters("dashboard/price-list", ["priceList", "priceListItemList"]),
    // ...mapGetters("dashboard/app-constant", [""]),
    ...mapGetters("setting/app/profile", ["user"]),

    roofTypes() {
      return this.roofTypeList
        .filter(type => {
          return type.workSteps && type.workSteps.commercial;
        })
        .sort((a, b) => {
          return a.displayName < b.displayName ? -1 : 0;
        });
    },
    /**
     * validate and return true if can create purchase order
     */
    allowCreate() {
      return !_.isEmpty(this.selectedEstimate) || this.isBuildYourOwn;
    },
    /**
     * filteredVendors
     */
    filteredVendors() {
      const vendorIds = [...new Set(this.productAllList.map(r => r.vendorId))];

      return this.companyList
        .filter(m => vendorIds.includes(m.id))
        .map(m => ({
          id: m.id,
          name: m.companyName
        }));
    },
    requireErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },

    currentWorkOrderActionId() {
      return this.constructionActions.find(
        item => item.code === ACTION_CODE_CONSTRUCTION
      ).id;
    },

    userInfo() {
      return uid =>
        this.activeUsers.find(user => {
          return user.uid === uid;
        });
    },
    actualQty() {
      return (product, numberOfCopy) => {
        const actualQty = (product.actualQty || 0) * numberOfCopy;
        return actualQty;
      };
    },
    orderQty() {
      return (product, numberOfCopy) => {
        const roundUpNumber = numberOfFixedRounds => value => {
          value = Math.round(value * 1000) / 1000;
          const denominator = Math.pow(10, numberOfFixedRounds);
          const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
          return Math.ceil(temp) / denominator;
        };
        const productSize = (product.unitSize || 1) * (product.unitPack || 1);
        const actualQty = product.actualQty * numberOfCopy;
        const orderQty = actualQty / productSize;
        return roundUpNumber(0)(orderQty);
      };
    }
  },

  methods: {
    ...mapActions("dashboard/estimate", [
      "getEstimatesByProjectId",
      "getBuilding",
      "updateEstimate"
    ]),
    ...mapActions("dashboard/product-item", {
      bindProductItemList: "bindBys",
      getProductItemBys:"getProductItemBys"
    }),
    ...mapActions("dashboard/product-item", {
      unbindProductItemList: "unbind"
    }),
    ...mapActions("dashboard/price-list", [
      "bindActivePriceList",
      "bindPriceListItem",
      "unbindPriceListItemList",
      "unbindPriceListItem",
      "getPriceListById"
    ]),
    ...mapActions("dashboard/work-order", [
      "createWorkOrderTask",
      "deleteWorkOrderTask",
      "updatePurchaseOrder"
    ]),

    ...mapActions("dashboard/client", ["getCompanyList"]),

    ...mapActions("dashboard/project", ["getActionListBys"]),
    ...mapActions("dashboard/app-constant", ["getConstantTypeList"]),

    async getConstructionAction() {
      const list = await this.getActionListBys([
        {
          prop: "isDeleted",
          op: "==",
          val: false
        },
        {
          prop: "code",
          op: "==",
          val: ACTION_CODE_CONSTRUCTION
        }
      ]);

      if (list && list.length > 0) {
        return list[0];
      }

      return null;
    },

    initData() {
      this.getEstimatesByProjectId(this.project?.id);

      this.getConstantTypeList();

      if (_.isEmpty(this.companyList)) {
        this.getCompanyList();
      }
    },

    openPopup() {
      this.$f7.preloader.show();
      this.openedPopup = true;
      this.initData();
      // create new temp wo task
      this.createWorkOrderTask({
        taskType: "purchase-order",
        status: "draft"
      }).then(id => {
        this.workOrderTaskId = id;
        this.$f7.preloader.hide();
      });
    },

    closePopup() {
      this.openedPopup = false;
      this.dueDate = [];
      this.selectedEstimate = {};
      this.selectedVendors = [];

      this.isBuildYourOwn = false;

      this.roofType = "";

      this.assigneeIds = [];
      this.v$.$reset();
    },

    deleteTempWorkOrderTask() {
      if (!this.isDeleteDraftPO) {
        this.isDeleteDraftPO = true;
        return;
      }
      const promises = [];
      this.$f7.preloader.show();
      if (this.workOrderTaskId) {
        promises.push(this.deleteWorkOrderTask(this.workOrderTaskId));
      }
      if (!_.isEmpty(this.attachmentFiles)) {
        for (const file of this.attachmentFiles) {
          if (file.fullPath) {
            promises.push(removeFile(file.fullPath));
          }
        }
      }
      Promise.all(promises).then(() => {
        this.$f7.preloader.hide();
        this.workOrderTaskId = "";
        this.attachmentFiles = [];
        this.unbindPriceListItemList();
        this.unbindPriceListItem();
      });
    },

    /**
     * updateSelectedEstimate
     */
    updateSelectedEstimate(estimate) {
      const self = this;

      if (estimate.status != "active") {
        this.$ri.dialog.openWarningDialog({
          title: "Generate Purchase Order",
          content: "Do you want to update Estimate status to Active",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.selectedEstimate = null;
              return;
            } else if (index === 1) {
              self
                .updateEstimate({
                  id: estimate.id,
                  doc: { status: "active" }
                })
                .then(() => {
                  self.selectedEstimate = estimate;
                  self.getEstimatesByProjectId(this.project?.id);
                });

              return;
            }
          }
        });
      } else {
        this.selectedEstimate = estimate;
      }
      this.selectedBuildings = [];
    },

    updateSelectedBuilding({ checked, buildingId }) {
      if (checked === true) {
        this.selectedBuildings.push(buildingId);
      }

      if (checked === false) {
        const index = this.selectedBuildings.findIndex(
          item => item === buildingId
        );

        this.selectedBuildings.splice(index, 1);
      }
    },

    updateSelectedVendors(vendor) {
      this.selectedVendors[0] = vendor.id;
    },

    handleClickGenerate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      if (!this.allowCreate) {
        this.$ri.dialog.openWarningDialog({
          title: "Generate Purchase Order",
          content: "Please select at least one estimate/vendor.",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              return;
            }
          }
        });
      } else {
        this.generatePurchaseOrderItems();
      }
    },

    /**
     * generatePurchaseOrder
     */
    generatePurchaseOrderItems() {
      this.$f7.preloader.show();
      let data = [];
      if (this.isBuildYourOwn) {
        // generate from vendor
        data = this.generateItemsFromVendor();

        this.handleCreatePO(data, []);
      } else {
        // generate( from estimate
        this.generateItemsFromEstimates().then(async listPromise => {
          const data = [];
          for (const list of listPromise) {
            data.push(...list);
          }

          if (_.isEmpty(data)) {
            this.$f7.preloader.hide();
            this.$ri.dialog.openWarningDialog({
              title: "Generate Purchase Order",
              content:
                "The estimate of the building you are choosing has no item to purchase. Please choose another one.",
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  return;
                }
              }
            });
          }
          const productByStatus = [];
          for (const po of data) {
            if (po.items[0].vendorId === "") {
              const product = await this.getProductItemBys([
                {
                  prop: "status",
                  val: "pi-waiting-for-approval",
                  op: "=="
                }
              ]);
              productByStatus.push(...product);
            }
          }
          this.handleCreatePO(data, productByStatus);
        });
      }
    },

    async generateItemsFromEstimates() {
      const refs = [];
      for (const buildingId of this.selectedBuildings) {
        refs.push(
          this.getBuilding({
            estimateId: this.selectedEstimate.id,
            buildingId: buildingId
          }).then(building => {
            const productItems = [];
            for (const section of building.productData || []) {
              for (const productItem of section.productList || []) {
                if (productItem.orderQty > 0) {
                  const item = {
                    ...productItem,
                    productItemId: productItem.id,
                    orderQty: this.orderQty(
                      productItem,
                      building.numberOfCopy || 1
                    ),
                    actualQty: this.actualQty(
                      productItem,
                      building.numberOfCopy || 1
                    ),
                    estimateId: this.selectedEstimate.id,
                    estimateNumber: this.selectedEstimate.estimateNumber,
                    buildingId: buildingId,
                    buildingName: building.buildingName,
                    roofType: building.roofType || "",
                    priceListId: building.priceListId || "",
                    vendorId: productItem.vendorId || "" // TODO: check
                  };
                  productItems.push(item);
                }
              }
            }

            return productItems;
          })
        );
      }

      return Promise.all(refs).then(list => {
        const refs = [];
        for (const productItems of list) {
          refs.push(this.createPO(productItems));
        }

        return Promise.all(refs);
      });
    },

    createPO(productItemList) {
      const groupByVendors = productItemList.reduce((a, b) => {
        a[b.vendorId] = a[b.vendorId] || [];
        a[b.vendorId].push(b);
        return a;
      }, {});
      const workOrderTasks = Object.keys(groupByVendors).map((r, index) => ({
        taskIndex: index,
        items: groupByVendors[r],
        vendorId: r,
        isBuildYourOwn: false,
        roofType: groupByVendors[r][0] ? groupByVendors[r][0].roofType : "",
        priceListId: groupByVendors[r][0]
          ? groupByVendors[r][0].priceListId
          : "",
        estimateId: groupByVendors[r][0] ? groupByVendors[r][0].estimateId : "",
        estimateNumber: groupByVendors[r][0]
          ? groupByVendors[r][0].estimateNumber
          : "",
        buildingId: groupByVendors[r][0] ? groupByVendors[r][0].buildingId : "",
        buildingName: groupByVendors[r][0]
          ? groupByVendors[r][0].buildingName
          : ""
      }));
      return workOrderTasks;
    },

    generateItemsFromVendor() {
      return this.selectedVendors.map((r, index) => ({
        taskIndex: index,
        items: [],
        vendorId: r,
        isBuildYourOwn: true,
        roofType: this.roofType
      }));
    },

    async onRoofTypeChanged(value) {
      this.$f7.preloader.show();
      this.roofType = value;
      this.productAllList = [];
      const product = await this.getProductItemBys([
        {
          prop: "status",
          val: "pi-active",
          op: "=="
        },
        {
          prop: "roofTypes",
          val: value,
          op: "array-contains"
        }
      ]);
      this.productAllList.push(...product);
      this.$f7.preloader.hide();
    },

    async prepareWorkOrderTaskData(po = null) {
      const constructionAction = await this.getConstructionAction();
      const newTask = {
        itemsDeleted: [],
        actions: [constructionAction?.id], //[this.currentWorkOrderActionId],
        projectId: this.project?.id,
        projectNumber: this.project?.cardNumber, //for search
        projectName: this.project?.title, //for search
        propertyId: "",
        propertyName: "", //for search
        taskType: "purchase-order",
        status: "draft" //for search
      };
      if (po !== null) {
        newTask.items = po.items;
        newTask.vendorId = po.vendorId || "";
        newTask.vendorName =
          (this.companyById(po.vendorId) || {}).companyName || ""; //for search
        newTask.isBuildYourOwn = po.isBuildYourOwn;
        newTask.estimateId = po.estimateId || "";
        newTask.estimateNumber = po.estimateNumber || ""; //for search
        newTask.roofType = po.roofType || "";
        newTask.roofTypeName =
          (this.roofTypeBy(po.roofType) || {}).displayName || ""; //for search
        newTask.priceListId = po.priceListId || "";
        newTask.buildingId = po.buildingId || "";
        newTask.buildingName = po.buildingName || ""; //for search
        newTask.dueDate = toDateFirebase(po.dueDate);
        newTask.assigneeIds = po.assigneeIds || [];
        newTask.attachmentFiles = po.attachmentFiles || [];
        newTask.propertyId = this.project?.propertyId || po.propertyId || "";
        newTask.propertyName =
          this.project?.propertyName || po.propertyName || getFullAddress(this.project?.projectAddress)|| "";
      }
      return newTask;
    },

    copyFile(file) {
      const toPath = `${file.fullPath}-copy-${Date.now()}`;
      return StorageActions.copyFile(file.fullPath, toPath);
    },

    // create PO
    async handleCreatePO(data, productByStatus) {
      if (data.length === 0) {
        this.$f7.preloader.hide();
        return;
      }
      const arrNewPOs = [];
      let userPromises = [];
      const waitingProduct = [];
      const rejectProduct = [];
      let lastPL = null;
      for (let i = 0; i < data.length; i++) {
        let po = await this.prepareWorkOrderTaskData({
          ...data[i],
          dueDate: this.dueDate,
          assigneeIds: this.assigneeIds || [],
          attachmentFiles: this.attachmentFiles || []
        });
        const PL = !this.isBuildYourOwn
          ? await this.getPriceListById(po.items[0].priceListId)
          : null;
        lastPL = PL;
        if (po.vendorId !== "") {
          if (i === 0) {
            // update PO created at opened popup
            const doc = await dashboardService.updateWOTaskDoc(
              this.user.tenantId,
              this.workOrderTaskId,
              po
            );
            po = {
              ...po,
              taskNumber: doc.docNumber,
              id: this.workOrderTaskId
            };
          } else {
            // copy attach files
            let newAttachmentFiles = [];
            if (!_.isEmpty(this.attachmentFiles)) {
              const filePromises = [];
              for (const file of this.attachmentFiles) {
                filePromises.push(
                  this.copyFile(file).then(res => {
                    return {
                      ...file,
                      fullPath: res.fullPath,
                      url: res.url
                    };
                  })
                );
              }
              newAttachmentFiles = await Promise.all(filePromises);
            }
            // add new PO
            const doc = await dashboardService.addWOTaskDoc(
              this.user.tenantId,
              {
                ...po,
                attachmentFiles: newAttachmentFiles
              }
            );
            const productItems = await this.getProductItemBys([
              {
                prop: "vendorId",
                val: po.vendorId,
                op: "=="
              }
            ]);

            po.items.map(item => {
              const product = productItems.find(
                product => product.id === item.id
              );
              if (product && product.isDeleted) {
                rejectProduct.push(item);
              }
              if (
                product &&
                !product.isDeleted &&
                product.status === "pi-waiting-for-approval"
              ) {
                waitingProduct.push(item);
              }
            });
            po = {
              ...po,
              taskNumber: doc.docNumber,
              id: doc.id
            };
          }
          arrNewPOs.push({
            id: po.id,
            taskNumber: po.taskNumber,
            vendorId: po.vendorId
          });
          if (!_.isEmpty(this.assigneeIds)) {
            for (const uid of this.assigneeIds) {
              const userInfo = this.userInfo(uid);
              userPromises.push(this.addWOTaskNotification(userInfo, po));
              userPromises.push(this.sendAddWOTaskMail(userInfo, po));
            }
            await Promise.all(userPromises);
          }
        } else {
          if (productByStatus.length > 0) {
            for (const item of po.items) {
              const matchedProduct = productByStatus.find(
                product => product.id === item.id
              );
              if (matchedProduct) {
                if (!matchedProduct.isDeleted) {
                  waitingProduct.push(matchedProduct);
                } else {
                  rejectProduct.push(matchedProduct);
                }
              }
            }
          }
        }
      }
      this.isDeleteDraftPO = false;
      this.$f7.preloader.hide();
      if (waitingProduct.length > 0) {
        this.$ri.dialog.openWarningDialog({
          title: "Missing Product",
          content: `Some products require approval (on Price List <strong>${lastPL.priceListNumber
            }_${lastPL.displayName})</strong>.</br>${waitingProduct
              .map((product, index) => `${index + 1}. ${product.productItem}`)
              .join("<br>")}<br>We've notified the admin for review by email.`,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                "approve-product",
                lastPL
              );
            } else if (index === 1) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                "approve-product",
                lastPL
              );
            }
          }
        });
      }
      if (rejectProduct.length > 0) {
        if (!_.isEmpty(arrNewPOs)) {
          for (const po of arrNewPOs) {
            const productDelete = rejectProduct.filter(
              e => e.vendorId === po.vendorId
            );
            if (!_.isEmpty(productDelete)) {
              productDelete.forEach(item => {
                for (const key in item) {
                  if (item[key] === undefined) {
                    item[key] = "";
                  }
                }
              });
              this.updatePurchaseOrder({
                id: po.id,
                doc: {
                  itemsDeleted: productDelete
                }
              });
            }
          }
        }

        this.$ri.dialog.openWarningDialog({
          title: "Missing Product",
          content: `Some products don’t exist.</br>${rejectProduct
            .map(
              (product, index) =>
                `${index + 1}. ${product.productItem}${product.vendorName ? `. Vendor: ${product.vendorName}` : ""
                }`
            )
            .join("<br>")}`,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                "removed-product",
                lastPL
              );
            } else if (index === 1) {
              _sefl.app.dialog.close();
              this.sendMailHandleProduct(
                waitingProduct,
                rejectProduct,
                "removed-product",
                lastPL
              );
            }
          }
        });
      }
      this.goToDetail(arrNewPOs);
      this.closePopup();
    },

    goToDetail(poList) {
      if (this.isBuildYourOwn) {
        this.$emit("openPODetail", poList[0].id);
      } else {
        const dialogButtons = [];
        for (const po of poList) {
          dialogButtons.push({
            text: po.taskNumber,
            onClick: () => {
              this.$emit("openPODetail", po.id);
            }
          });
        }
        this.$f7.dialog
          .create({
            title: "Generate Purchase Order",
            text: `We have created ${poList.length} drafts PO(s): ${poList
              .map(r => r.taskNumber)
              .join(
                ", "
              )}. Please double check quantity before sending it to Vendors`,
            buttons: dialogButtons,
            cssClass: "po-dialog",
            verticalButtons: !this.$f7.device.desktop
          })
          .open();
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    dueDate: {
      required
    },

    roofType: {
      required(val) {
        if (!this.isBuildYourOwn) return true;
        return !!val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.estimate-list.list ::v-deep .item-after {
  color: var(--f7-list-item-title-text-color);
}
</style>
