import * as types from "./types";
import Vue from "vue";

import {
  DEFAULT_STATUS_ESTIMATE,
  DEFAULT_ESTIMATE_MISC
} from "../../../../utility/const";

export default {
  setEstIdLink(state, estimateNumber) {
    state.estimateNumber = estimateNumber;
  },

  // setCurrentTotalEstimate(state, obj) {
  //   state.totalEstimate = obj;
  // },

  updatedStateForm(state, { fieldName, value }) {
    state.editingObject = { ...state.editingObject, [fieldName]: value };
  },

  patchData(state, { sectionId, itemId, operator, value }) {
    if (operator === "update") {
      state.dataPatches = state.dataPatches.filter(
        r =>
          !(
            r.sectionId === sectionId &&
            r.itemId === itemId &&
            r.value.name === value.name
          )
      );
    } else if (operator === "remove") {
      state.dataPatches = state.dataPatches.filter(
        r => !(r.sectionId === sectionId && r.itemId === itemId)
      );
    }
    state.dataPatches.push({ sectionId, itemId, operator, value });
  },

  cleanUpEditingData(state) {
    state.editingObject = {};
    state.dataPatches = [];
  },

  // [types.SET_ESTIMATE_DATA](state, data) {
  //   Vue.set(state.estimate, "data", data);
  // },

  [types.SET_ESTIMATE_FIELD](state, payload) {
    Vue.set(state.estimate, payload.fieldName, payload.value);
  },

  [types.SET_CURRENT_ESTIMATE_FIELD](state, payload) {
    if (!payload) {
      // clear data
      state.currentEstimate = {
        miscPercent: DEFAULT_ESTIMATE_MISC,
        status: DEFAULT_STATUS_ESTIMATE,
        buildings: [
          {
            buildingName: "SPEC_1",
            buildingId: "",
            numberOfCopy: 1
          }
        ]
      };
    } else {
      Vue.set(state.currentEstimate, payload.fieldName, payload.value);
    }
  },

  [types.SET_CURRENT_BUILDING_FIELD](state, { prop, value }) {
    if (!prop) {
      // clear data
      state.currentBuilding = { assemblyList: [] };
    } else {
      Vue.set(state.currentBuilding, prop, value);
    }
  },

  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, "hits", hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, "hitsPerPage", payload);
  },

  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, "searchText", payload);
  },
  [types.SET_CLIENT_INFO](state, payload) {
    Vue.set(state, "clientInfo", payload);
  },
  [types.SET_SEARCH_ORDER](state, payload) {
    Vue.set(state, "order", payload);
  },

  [types.RESET_SEARCH](state) {
    Vue.set(state, "order", "desc");
    Vue.set(state, "searchText", "");

    Vue.set(state, "hits", []);
    Vue.set(state, "hitsPerPage", 25);
    Vue.set(state, "nbHits", 0);
    Vue.set(state, "nbPages", 0);
    Vue.set(state, "page", 0);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },

  [types.SET_ESTIMATES_BY_PROJECT](state, payload) {
    Vue.set(state, "estimateListByProject", payload);
  },

  [types.SET_CURRENT_STEP](state, payload) {
    Vue.set(state, "currentStep", payload);
  },

  [types.SET_CONTACT_FACETS](state, hits) {
    Vue.set(state, "contactList", hits);
  },
  [types.SET_COMPANY_FACETS](state, hits) {
    Vue.set(state, "companyList", hits);
  },

  [types.RESET_COMPANY_CONTACT_FACETS](state) {
    Vue.set(state, "companyList", []);
    Vue.set(state, "contactList", []);
  },
  [types.SET_PARAMS_BACK](state, payload) {
    Vue.set(state, "paramsBack", payload);
  },
  [types.SET_QUERY_BACK](state, payload) {
    Vue.set(state, "queryBack", payload);
  },
  [types.SET_SELECTED_STATUS_FILTER](state, payload) {
    Vue.set(state, "selectedStatusFilter", payload);
  },
  [types.SET_TYPE_SWITCH_SCREEN](state, payload) {
    Vue.set(state, "typeSwitchScreen", payload);
  },
  [types.SET_IS_ACTIVE_SUMMARY](state, payload) {
    Vue.set(state, "isActiveSummary", payload);
  }
};
