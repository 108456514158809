import {
  CODE_STATUS_OPTION,
  ENTITY_INVOICE,
  ENTITY_RECURRING_INVOICE,
  CODE_COMPANY_TYPE,
  CODE_PAYMENT_MODE
} from "@/utility/const";

export default {
  invoiceStatusList: state =>
    state.typeList.filter(
      item => item.code === CODE_STATUS_OPTION && item.entity === ENTITY_INVOICE
    ),
  recurringInvoiceStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_RECURRING_INVOICE
    ),
  companyTypeList: state =>
    state.typeList.filter(item => item.code === CODE_COMPANY_TYPE),
  paymentModeList: state =>
    state.typeList.filter(item => item.code === CODE_PAYMENT_MODE)
};
