import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL,
  BUSINESS_CODE_SERVICE,
  SECTION_TYPE_TPO_LABOR
} from "@/utility/const";
import _ from "lodash";
import { mapGetters } from "vuex";

import estimateService from "../../../services/estimate.service";

export default {
  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    }),
    ...mapGetters("proposal/price-list", ["categoryById"]),
    // Deprecated
    displayLaborMaterial() {
      return (estimate, itemDetails) => {
        let materialCost = {
          productName: "Material Cost",
          tax: false,
          taxAmount: 0,
          amount: 0
        };
        let laborCost = {
          productName: "Labor Cost",
          tax: false,
          taxAmount: 0,
          amount: 0
        };
        (itemDetails || []).forEach(r => {
          const miscPercent = (r.productName || "").includes("Material Cost")
            ? estimate.miscPercent || 0
            : 0;
          let priceWithProfitAndMisc =
            r.price /
              ((100 - ((estimate.crossProfitMarginRatio || 0) + miscPercent)) /
                100) || 0;
          let amount = (r.orderQty || 1) * (priceWithProfitAndMisc || 0);
          let taxAmount = r.tax
            ? this.getPercentValue(building.saleTax || 0, amount)
            : 0;
          if ((r.productName || "").includes("Material Cost")) {
            materialCost.tax = r.tax;
            materialCost.taxAmount += parseFloat(taxAmount.toFixed(2));
            materialCost.amount += parseFloat(amount.toFixed(2));
          }
          if ((r.productName || "").includes("Labor Cost")) {
            laborCost.tax = r.tax;
            laborCost.taxAmount += parseFloat(taxAmount.toFixed(2));
            laborCost.amount += parseFloat(amount.toFixed(2));
          }
        });
        let list = [];
        list.push(materialCost, laborCost);
        return list;
      };
    },

    // Deprecated
    displaySimpleSummary() {
      return (estimate, itemDetails) => {
        let item = {
          productName: "Service Fee",
          tax:
            (itemDetails || []).findIndex(r => r.tax === true) < 0
              ? false
              : true,
          taxAmount: 0,
          amount: 0
        };
        (itemDetails || []).forEach(r => {
          const miscPercent = (r.productName || "").includes("Material Cost")
            ? estimate.miscPercent || 0
            : 0;
          let priceWithProfitAndMisc =
            r.price /
              ((100 - ((estimate.crossProfitMarginRatio || 0) + miscPercent)) /
                100) || 0;
          let amount = (r.orderQty || 1) * (priceWithProfitAndMisc || 0);
          let taxAmount = r.tax
            ? this.getPercentValue(building.saleTax || 0, amount)
            : 0;
          item.taxAmount += parseFloat(taxAmount.toFixed(2));
          item.amount += parseFloat(amount.toFixed(2));
        });
        let list = [];
        list.push(item);
        return list;
      };
    },

    // Deprecated
    items() {
      return (estimate = {}, itemDetails = []) => {
        if (
          this.proposal.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL
        ) {
          return this.displayLaborMaterial(estimate, itemDetails);
        }
        if (
          this.proposal.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ) {
          return this.displaySimpleSummary(estimate, itemDetails);
        }
        return (itemDetails || [])
          .filter(r => !!r.actualQty || !!r.orderQty)
          .map(r => {
            const miscPercent =
              r.category !== "Labor" ? estimate.miscPercent || 0 : 0;
            let priceWithProfitAndMisc =
              r.price /
                ((100 -
                  ((estimate.crossProfitMarginRatio || 0) + miscPercent)) /
                  100) || 0;
            let amount = (r.orderQty || 0) * (priceWithProfitAndMisc || 0);

            let taxAmount = r.tax
              ? this.getPercentValue(building.saleTax || 0, amount)
              : 0;

            return {
              ...r,
              priceWithProfitAndMisc,
              amount,
              taxAmount
            };
          });
      };
    },

    materialSubTotal() {
      return (building, itemDetails) => {
        return itemDetails.reduce(
          (accumulator, item) =>
            accumulator +
            (this.categoryById(item.categoryId).name !== "Labor"
              ? this.orderQty(building, item) * (item.price || 0)
              : 0),
          0
        );
      };
    },

    materialNetSales() {
      return (estimate, itemDetails, building) => {
        const materialSubTotal =
          this.materialSubTotal(building, itemDetails || []) || 0;
        const crossProfitMarginRatio = estimate.crossProfitMarginRatio || 0;
        const miscPercent = estimate.miscPercent || 0;
        return (
          materialSubTotal /
            ((100 - (crossProfitMarginRatio + miscPercent)) / 100) || 0
        );
      };
    },

    materialTaxRate() {
      return (project, building, estimate) => {
        return project.businessCode !== BUSINESS_CODE_SERVICE ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            this.doesApplyTaxForMaterial(project, building, estimate))
          ? building.saleTax || 0
          : 0;
      };
    },

    materialTax() {
      return (project, building, estimate, itemDetails) => {
        return this.getPercentValue(
          this.materialTaxRate(project, building, estimate),
          this.materialNetSales(estimate, itemDetails, building)
        );
      };
    },

    laborSubTotal() {
      return (building, itemDetails) => {
        return itemDetails.reduce(
          (accumulator, item) =>
            accumulator +
            (this.categoryById(item.categoryId).name === "Labor"
              ? this.orderQty(building, item) * (item.price || 0)
              : 0),
          0
        );
      };
    },

    laborNetSales() {
      return (estimate, itemDetails, building) => {
        const laborSubTotal =
          this.laborSubTotal(building, itemDetails || []) || 0;
        const crossProfitMarginRatio = estimate.crossProfitMarginRatio || 0;

        return laborSubTotal / ((100 - crossProfitMarginRatio) / 100) || 0;
      };
    },

    laborTaxRate() {
      return (project, building, estimate) => {
        return project.businessCode !== BUSINESS_CODE_SERVICE ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            this.doesApplyTaxForLabor(project, building, estimate))
          ? building.saleTax || 0
          : 0;
      };
    },

    laborTax() {
      return (project, building, estimate, itemDetails) => {
        return this.getPercentValue(
          this.laborTaxRate(project, building, estimate),
          this.laborNetSales(estimate, itemDetails, building)
        );
      };
    },

    subTotal() {
      return (estimate, itemDetails, building) => {
        return (
          parseFloat(
            this.materialNetSales(estimate, itemDetails, building).toFixed(2)
          ) +
          parseFloat(
            this.laborNetSales(estimate, itemDetails, building).toFixed(2)
          )
        );
      };
    },

    taxAmount() {
      return (project, building, estimate, itemDetails) => {
        return (
          parseFloat(
            this.materialTax(project, building, estimate, itemDetails).toFixed(
              2
            )
          ) +
          parseFloat(
            this.laborTax(project, building, estimate, itemDetails).toFixed(2)
          )
        );
      };
    },

    total() {
      return (project, building, estimate, itemDetails) => {
        return (
          this.subTotal(estimate, itemDetails, building) +
          this.taxAmount(project, building, estimate, itemDetails)
        );
      };
    }
  },
  methods: {
    orderQty(building, product) {
      const roundUpNumber = numberOfFixedRounds => value => {
        value = Math.round(value * 1000) / 1000;
        const denominator = Math.pow(10, numberOfFixedRounds);
        const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
        return Math.ceil(temp) / denominator;
      };
      if (product.qtyOverride || !product.actualQty) return product.orderQty;
      const productSize = (product.unitSize || 1) * (product.unitPack || 1);
      const numberOfCopy = building.numberOfCopy || 1;
      const actualQty = product.actualQty * numberOfCopy;
      const orderQty = actualQty / productSize;
      return roundUpNumber(0)(orderQty);
    },

    // Estimate Price Calculation
    doesApplyTaxForMaterial(project, building, estimate) {
      return estimateService.checkApplyTaxForMaterial({
        project,
        building,
        estimate
      });
    },

    doesApplyTaxForLabor(project, building, estimate) {
      return estimateService.checkApplyTaxForLabor({
        project,
        building,
        estimate
      });
    },

    getPercentValue(percent, value) {
      return _.round(((percent || 0) * (value || 0)) / 100.0, 2);
    },

    sortBy(a, b) {
      return a > b ? 1 : b > a ? -1 : 0;
    },

    materialSubTotalForBuilding(building) {
      let subTotals = 0;
      (building.productData || []).forEach(section => {
        if (section.sectionId !== SECTION_TYPE_TPO_LABOR) {
          subTotals += (section.productList || []).reduce(
            (accumulator, item) =>
              (item.orderQty || 0) * (item.price || 0) + accumulator,
            0
          );
        }
      });
      return subTotals;
    },

    laborSubTotalForBuilding(building) {
      let subTotals = 0;
      (building.productData || []).forEach(section => {
        if (section.sectionId === SECTION_TYPE_TPO_LABOR) {
          subTotals += (section.productList || []).reduce(
            (currentValue, item) =>
              (item.orderQty || 0) * (item.price || 0) + currentValue,
            0
          );
        }
      });
      return subTotals;
    },

    summaryEstimate(project, buildings) {
      return buildings
        .sort((a, b) => this.sortBy(a.name, b.name))
        .reduce((prev, building) => {
          const items = [
            {
              productName: `Material Cost of ${building &&
                building.buildingName}`,
              orderQty: 1,
              price: parseFloat(
                (this.materialSubTotalForBuilding(building) || 0).toFixed(2)
              ),
              tax: true
            },
            {
              productName: `Labor Cost of ${building && building.buildingName}`,
              orderQty: 1,
              price: parseFloat(
                (this.laborSubTotalForBuilding(building) || 0).toFixed(2)
              ),
              tax: true
            }
          ];
          return prev.concat(items);
        }, []);
    }
  }
};
