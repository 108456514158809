export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_COMPANY_LIST = "SET_COMPANY_LIST";
export const SET_PROPERTY_LIST = "SET_PROPERTY_LIST";

export const SET_CONTACT = "SET_CONTACT";
export const SET_COMPANY = "SET_COMPANY";
export const SET_PROPERTY = "SET_PROPERTY";

export const SET_INSURANCE_COMPANY = "SET_INSURANCE_COMPANY";
export const PUSH_CONTACT = "PUSH_CONTACT";
