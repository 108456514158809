<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="$f7router.back()" icon-f7="chevron_left">Back</f7-link>
      </f7-nav-left>
      <f7-nav-title>Contact History Detail</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <!-- Name -->
    <f7-block-title
      v-show="
        contactHistory && (contactHistory.firstName || contactHistory.lastName)
      "
      >Contact Name</f7-block-title
    >
    <f7-list
      v-show="
        contactHistory && (contactHistory.firstName || contactHistory.lastName)
      "
      simple-list
    >
      <f7-list-item
        :title="
          contactHistory !== undefined
            ? (contactHistory.firstName ? contactHistory.firstName : '') +
              ' ' +
              (contactHistory.lastName ? contactHistory.lastName : '')
            : ''
        "
      ></f7-list-item>
    </f7-list>

    <!-- Job Position -->
    <f7-block-title v-show="contactHistory && contactHistory.jobPosition"
      >Job Position</f7-block-title
    >
    <f7-list v-show="contactHistory && contactHistory.jobPosition" simple-list>
      <f7-list-item
        :title="contactHistory ? contactHistory.jobPosition : ''"
      ></f7-list-item>
    </f7-list>

    <!-- Phone -->
    <f7-block-title
      v-show="
        contactHistory
          ? contactHistory.phones
            ? contactHistory.phones.length > 0
            : false
          : false
      "
      >Phone Number</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(phone, index) in contactHistory
          ? contactHistory.phones || []
          : []"
        :key="index"
        :header="phone.title"
        :title="phone.value"
      >
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'tel:' + phone.value"
            icon-f7="phone_circle_fill"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- Email -->
    <f7-block-title
      v-show="
        contactHistory
          ? contactHistory.emails
            ? contactHistory.emails.length > 0
            : false
          : false
      "
      >Email</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(email, index) in contactHistory
          ? contactHistory.emails || []
          : []"
        :key="index"
        :header="email.title"
        :title="email.value"
      >
        <div slot="after-title">
          <f7-link
            class="external icon-link"
            :href="'mailto:' + email.value"
            icon-f7="envelope_circle_fill"
          ></f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- Address -->
    <f7-block-title
      v-show="
        contactHistory
          ? contactHistory.addresses
            ? contactHistory.addresses.length > 0
            : false
          : false
      "
      >Address</f7-block-title
    >
    <f7-list>
      <f7-list-item
        v-for="(address, index) in contactHistory
          ? contactHistory.addresses || []
          : []"
        :key="index"
        :header="address.title"
        :title="getFullAddress(address)"
      ></f7-list-item>
    </f7-list>

    <!-- Company -->
    <f7-block-title v-if="companyList && companyList.length > 0">{{
      companyList.length > 1 ? "Companies" : "Company"
    }}</f7-block-title>
    <f7-list media-list>
      <f7-list-item
        v-for="companyRef in companyList || []"
        :key="companyRef.id"
        :title="companyRef.companyName"
        :subtitle="
          (companyRef.addresses || []).length > 0
            ? companyRef.addresses[0].value
            : ''
        "
        :text="formatDateRefString(companyRef.fromDate, companyRef.toDate)"
      >
      </f7-list-item>
    </f7-list>

    <!-- Property -->
    <!-- <f7-block-title
      v-if="contactPropertyRefs && contactPropertyRefs.length > 0"
      >{{
        contactPropertyRefs.length > 1 ? "Properties" : "Property"
      }}</f7-block-title
    >
    <f7-list media-list>
      <f7-list-item
        v-for="propertyRef in contactPropertyRefs || []"
        :key="propertyRef.id"
        :title="propertyRef.propertyName"
        :subtitle="
          (propertyRef.addresses || []).length > 0
            ? propertyRef.addresses[0].value
            : ''
        "
        :text="formatDateRefString(propertyRef.fromDate, propertyRef.toDate)"
      >
      </f7-list-item>
    </f7-list> -->
    <!-- Created By -->
    <f7-block-title
      v-show="history && history.createdBy && history.createdAt"
      style="font-size: 12px"
      >This version was changed by
      <strong>{{ history && history.createdBy }}</strong> at
      {{ toLocaleString(history && history.createdAt) }}</f7-block-title
    >
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDateRefString } from "@/utility/datetime";
import { getFullAddress } from "@/utility/address";
import _ from "lodash";

export default {
  data() {
    return {
      companyList: []
    };
  },

  computed: {
    ...mapGetters("contact-book/contact-history", ["history"]),

    contactHistory() {
      return (this.history && this.history.data) || {};
    },

    companyRefs() {
      return this.contactHistory.companyRefs || [];
    }
  },

  async mounted() {
    if (_.isEmpty(this.history) && !_.isEmpty(this.$f7route.params.historyId)) {
      await this.getHistoryById(this.$f7route.params.historyId);
    }

    for (const ref of this.companyRefs) {
      const company = await this.getCompany(ref.id);
      if (!_.isEmpty(company)) {
        this.companyList.push(company);
      }
    }
  },

  methods: {
    ...mapActions("contact-book/contact-history", ["getHistoryById"]),
    ...mapActions("contact-book/company", ["getCompany"]),

    getFullAddress(address) {
      return getFullAddress(address);
    },
    formatDateRefString(fromDate, toDate) {
      return formatDateRefString(fromDate, toDate);
    },
    toLocaleString(firebaseDate) {
      return firebaseDate ? firebaseDate.toDate().toLocaleString() : "";
    }
  }
};
</script>

<style></style>
