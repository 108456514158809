import { searchClient } from "../../../../services/search.service";
import algoliaService from "../../../../services/algolia.service";
import {
  COLLECTION_ESTIMATE_SERVICE_DEFAULT_PRODUCT,
  COLLECTION_PRODUCT_PRODUCT_ITEMS,
  PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT
} from "../../../../utility/const";
import * as types from "./types";
import _ from "lodash";

export default tenant => {
  const productItemIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );
  const productItemIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  return {
    // get data for filters
    getFilterData({ commit }, { dataType, filters }) {
      let entityName = "";
      switch (dataType) {
        case "products-by-price-list":
          entityName = COLLECTION_PRODUCT_PRODUCT_ITEMS;
          break;
        case "products-default":
          entityName = COLLECTION_ESTIMATE_SERVICE_DEFAULT_PRODUCT;
          break;
      }
      if (!entityName) return;
      let hits = [];
      const requestOptions = {
        filters: `entityName:${entityName}`,
        attributesToRetrieve: [
          "roofTypes",
          "categoryId",
          "subCategoryId",
          "vendorId"
        ],
        batch: batch => {
          hits = hits.concat(batch);
        }
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return productItemIndexAsc
        .browseObjects({
          query: "",
          ...requestOptions
        })
        .then(() => {
          const roofTypes = _.uniqBy(
            [].concat.apply(
              [],
              hits.map(r => r.roofTypes).filter(r => r !== "?")
            )
          );
          const categoryIds = _.uniqBy(
            hits.map(r => r.categoryId).filter(r => r !== "?")
          );
          const subCategoryIds = _.uniqBy(
            hits.map(r => r.subCategoryId).filter(r => r !== "?")
          );
          const vendorIds = _.uniqBy(
            hits.map(r => r.vendorId).filter(r => r !== "?")
          );
          const result = {
            roofTypes,
            categoryIds,
            subCategoryIds,
            vendorIds
          };

          switch (dataType) {
            case "products-by-price-list":
              commit(types.SET_FILTER_DATA_RESULT_BY_PL, result);
              break;
            case "products-default":
              commit(types.SET_FILTER_DATA_RESULT_BY_DEFAULT, result);
              break;
          }
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchProductItems(
      { state, commit },
      {
        dataType,
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes,
        isGetAll = false,
        pageNumber
      }
    ) {
      let pagingState = {};
      let entityName = "";
      switch (dataType) {
        case "products-by-price-list":
          entityName = COLLECTION_PRODUCT_PRODUCT_ITEMS;
          pagingState = state.productsByPL;
          break;
        case "products-default":
          entityName = COLLECTION_ESTIMATE_SERVICE_DEFAULT_PRODUCT;
          pagingState = state.productsDefault;
          break;
      }
      if (!entityName) return;
      const index = productItemIndexAsc;
      const requestOptions = {
        cacheable: false,
        filters: `entityName:${entityName}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "productItem",
          "manufacturer",
          "sku",
          "price",
          "colorPhotos.colorName"
        ]
      };
      if (!isGetAll) {
        requestOptions.hitsPerPage = pagingState.hitsPerPage;
      }
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }

      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          if (!isGetAll) {
            switch (dataType) {
              case "products-by-price-list":
                commit(types.SET_SEARCH_RESULT_BY_PL, result);
                break;
              case "products-default":
                commit(types.SET_SEARCH_RESULT_BY_DEFAULT, result);
                break;
            }
          }
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit },
      {
        dataType,
        pageName,
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes
      }
    ) {
      const index = productItemIndexAsc;
      let pagingState = {};
      let entityName = "";
      switch (dataType) {
        case "products-by-price-list":
          entityName = COLLECTION_PRODUCT_PRODUCT_ITEMS;
          pagingState = state.productsByPL;
          break;
        case "products-default":
          entityName = COLLECTION_ESTIMATE_SERVICE_DEFAULT_PRODUCT;
          pagingState = state.productsDefault;
          break;
      }
      if (!entityName) return;
      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = pagingState.page + 1;
          break;

        case "prev":
          pageNumber = pagingState.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = pagingState.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: pagingState.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${entityName}`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "productItem",
          "manufacturer",
          "sku",
          "price",
          "colorPhotos.colorName"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          switch (dataType) {
            case "products-by-price-list":
              commit(types.SET_SEARCH_RESULT_BY_PL, result);
              break;
            case "products-default":
              commit(types.SET_SEARCH_RESULT_BY_DEFAULT, result);
              break;
          }
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    getAllDefaultProductIds(
      context,
      { priceListId, roofType, vendorIds = [] }
    ) {
      let hits = [];
      let vendorIdFilter = "";

      if (!_.isEmpty(vendorIds)) {
        vendorIdFilter = "AND ( ";
        for (let i = 0; i < vendorIds.length; i++) {
          vendorIdFilter += `vendorId:${vendorIds[i]}`;
          if (i < vendorIds.length - 1) {
            vendorIdFilter += " OR ";
          }
        }
        vendorIdFilter += " )";
      }

      return productItemIndexAsc
        .browseObjects({
          query: "",
          filters: `entityName:${COLLECTION_ESTIMATE_SERVICE_DEFAULT_PRODUCT} AND priceListId:${priceListId}${
            roofType && roofType !== "all-roof-type"
              ? ` AND estServiceDefaultForTypes:${roofType}`
              : ""
          } ${vendorIdFilter}`,
          attributesToRetrieve: ["id"],
          batch: batch => {
            hits = hits.concat(batch);
          }
        })
        .then(() => {
          hits = _.uniqBy(hits, "id");
          return hits;
        });
    },

    setProductNumberOfRows({ commit }, { dataType, value }) {
      switch (dataType) {
        case "products-by-price-list":
          commit(types.SET_NUMBER_OF_ROWS_BY_PL, value);
          break;
        case "products-default":
          commit(types.SET_NUMBER_OF_ROWS_BY_DEFAULT, value);
          break;
      }
    },

    setProductSearchOrder({ commit }, { dataType, value }) {
      switch (dataType) {
        case "products-by-price-list":
          commit(types.SET_SEARCH_ORDER_BY_PL, value);
          break;
        case "products-default":
          commit(types.SET_SEARCH_ORDER_BY_DEFAULT, value);
          break;
      }
    },

    resetSearch({ commit }, dataType) {
      switch (dataType) {
        case "products-by-price-list":
          commit(types.RESET_SEARCH_BY_PL);
          break;
        case "products-default":
          commit(types.RESET_SEARCH_BY_DEFAULT);
          break;
      }
    },

    // update data on algolia
    algoliaUpdateItemsWithDataList(context, products) {
      return algoliaService.algoliaUpdateItemsWithDataList({
        tenantId: tenant,
        collection: COLLECTION_ESTIMATE_SERVICE_DEFAULT_PRODUCT,
        dataList: products
      });
    },
    algoliaDeleteItems(context, { productIds, priceListId }) {
      return productItemIndexDesc
        .deleteObjects(productIds.map(r => `ESDP_${priceListId}_${r}`))
        .wait();
    },
    algoliaDeleteItem(context, { productId, priceListId }) {
      return productItemIndexDesc
        .deleteObject(`ESDP_${priceListId}_${productId}`)
        .wait();
    }
  };
};
