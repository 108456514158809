<template>
  <f7-list class="contact-input">
    <f7-list-input
      link
      :value="contactValue"
      :clear-button="true"
      @input="
        setContactSearchText($event.target.value);
        onSearch();
        contactValue = $event.target.value;
      "
      @focus="handleFocus"
      @blur="blurAction"
      @input:clear="onDeleteContact"
      placeholder="Input contact name"
      :error-message="errorMessage"
      error-message-force
      ref="inputContactName"
      :tabindex="tabIndex"
      autocomplete="new-password"
      class="input-contact-name"
    >
      <div class="list-item-inner-start" slot="inner-start">
        Contact Name<required-asterisk v-if="isRequired"></required-asterisk>
      </div>
      <input-icon slot="media" icon="person"></input-icon>
    </f7-list-input>
    <f7-list-item v-if="isAddNew">
      <AddNewContactButton @onCreated="$emit('onCreated', $event)" />
    </f7-list-item>

    <div class="dropdown-content" @scroll="onScroll" v-show="isShowDropdown">
      <div class="page-content">
        <f7-list class="no-margin">
          <f7-list-group v-for="(group, index) in contactGroup" :key="index">
            <f7-list-item :title="group.key" group-title></f7-list-item>
            <f7-list-item
              no-chevron
              link
              v-for="(contact, index) in group.data"
              :key="index"
              :title="contact.contactName"
              @click.native="select(contact.id)"
            ></f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
    </div>
  </f7-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputIcon from "../icons/InputIcon.vue";
import _ from "lodash";
import AddNewContactButton from "@/components/buttons/AddNewContactButton.vue";

export default {
  components: {
    InputIcon,
    AddNewContactButton
  },

  props: {
    errorMessage: {
      type: String,
      default: ""
    },

    displayCard: {
      type: Object,
      default: () => {}
    },

    contactName: {
      type: String,
      default: ""
    },

    companyId: String,

    queryFilters: String,

    autoFocus: {
      type: Boolean,
      default: false
    },

    tabIndex: {
      type: Number,
      default: 0
    },

    isAddNew: {
      type: Boolean,
      default: true
    },

    isRequired: { type: Boolean, default: true }
  },

  data() {
    return {
      isNew: false,
      popupOpened: false,
      onlyAddNew: false,

      isFocus: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,

      isNewSelected: false,
      contactValue: ""
    };
  },

  computed: {
    ...mapGetters("common/contact", [
      "contactGroup",
      "hits",
      "nbPages",
      "page",
      "contactSearchText"
    ]),

    isShowDropdown() {
      return this.isFocus && this.contactGroup.length > 0;
    }
  },
  mounted() {
    if (this.queryFilters) {
      this.setQueryFilters(this.queryFilters);
    }
  },
  watch: {
    contactName() {
      this.contactValue = this.contactName;
    },
    contactSearchText: {
      handler: _.debounce(function() {
        return this.handleSearch();
      }, 300),
      immediate: true
    }
  },

  methods: {
    ...mapActions("common/contact", [
      "searchContact",
      "loadMoreContact",
      "resetSearch",
      "setContactSearchText",
      "setQueryFilters"
    ]),

    ...mapActions("common/company", ["getCompany"]),

    handleSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;

      return this.searchContact({}).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
      });
    },

    handleFocus() {
      this.handleSearch();
      setTimeout(() => {
        this.isFocus = true;
      }, 500);
    },

    async autoFillContact() {
      const company = this.companyId
        ? await this.getCompany(this.companyId)
        : null;
      if (company && !_.isEmpty(company.contactRefs)) {
        try {
          this.setQueryFilters(this.queryFilters);
          await this.searchContact({});
          if (!_.isEmpty(this.hits)) {
            this.select(this.hits[0].id);
          } else {
            this.select("");
          }
        } catch (e) {
          this.select("");
        }
      } else {
        this.select("");
      }
    },

    open() {
      this.isFocus = true;
      this.focusInput();
      this.isNewSelected = false;
      this.onSearch("");
    },

    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreContact({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    blurAction() {
      setTimeout(() => {
        if (!this.isNewSelected) this.setContactSearchText(this.contactName);

        if (!this.searchValue) this.setContactSearchText(this.contactName);

        if (!_.isEmpty(this.displayCard) && !this.displayCard?.contactId)
          this.setContactSearchText("");

        this.isNewSelected = false;
        this.isFocus = false;
      }, 500);
    },

    select(contactId) {
      if (contactId) {
        this.$emit("onSelected", contactId);
        this.isNewSelected = true;
        this.close();
      }
    },

    close() {
      this.popupOpened = false;
      this.value = "";
      this.isNew = false;
      this.onlyAddNew = false;

      this.allowInfinite = true;
      this.hasMoreItems = false;
      this.hasData = true;
      this.resetSearch();
    },

    onDeleteContact(event) {
      this.$emit("onDeleteContact", event);
    },

    focusInput() {
      if (this.autoFocus) {
        this.$el.querySelector(".input-contact-name input").focus();
      }
    }
  }
};
</script>

<style scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.icon {
  margin-top: 2px;
}

.contact-input {
  position: relative;
}

.dropdown-content {
  position: absolute;
  width: 85%;
  max-height: 400px;
  min-width: 200px;
  max-width: 600px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  top: 50px;
  left: 40px;
  z-index: 999;
  background: black;
}

.page-content {
  padding-top: 0;
}
</style>
