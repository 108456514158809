import jsPDF from "jspdf";
import "jspdf-autotable";
import { currencyUSD, percent, MMDDYYYY } from "@/utility/config";
function extractContent(s) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

function getDataUri(url) {
  return new Promise(resolve => {
    if (!url) resolve("");
    var image = new Image();
    image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain
    image.src = url;
    image.onload = function() {
      var canvas = document.createElement("canvas");
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      //next three lines for white background in case png has a transparent background
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "#fff"; /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext("2d").drawImage(this, 0, 0);

      resolve(canvas.toDataURL("image/png"));
    };
    setTimeout(() => resolve(""), 5000);
  });
}
class PDFWriter {
  doc = null;
  positionX = 0;
  positionY = 0;
  lineHeight = 18;
  margin = {
    left: 30,
    top: 10
  };
  constructor(doc) {
    if (!doc) {
      this.doc = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "letter",
        floatPrecision: 16 // or "smart", default is 16
      });
    } else {
      this.doc = doc;
    }
    //this.doc.addFont("Arial", "normal");
    //this.doc.setFont("Arial");
  }
  addPage() {
    this.doc.addPage();
  }
  addFooter() {
    let pageCount = this.doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      // console.log
      this.doc.setPage(i);
      this.doc.setFontSize(16);
      this.doc.setDrawColor(0, 0, 0);
      this.doc.setFontSize(10);
      let pageCurrent = this.doc.internal.getCurrentPageInfo().pageNumber;

      if (pageCurrent === pageCount) {
        this.doc.setLineDash([140, 115], 0);
        this.line(30, 560, 440, 560);
        this.setFontType("bold");
        this.doc.text("Thank You For Your Business", 225, 562, {
          align: "center"
        });
      } else {
        this.doc.setLineDash([190, 20], 0);
        this.line(30, 560, 440, 560);
        this.setFontType("bold");
        this.doc.text(String(pageCurrent), 230, 562, { align: "center" });
      }
    }
  }
  // addSignature(by, height = 120) {
  //   if (this.isBreakPage(height)) {
  //     this.doc.addPage();
  //     this.positionY = 30;
  //   }
  //   this.doc.setFontType("bold");
  //   this.doc.text(by, 380, 530, { align: "center" });
  //   this.doc.setFontType("normal");
  //   this.doc.text("Accounting Manager", 380, 540, {
  //     align: "center"
  //   });
  // }

  addTable(data, headers) {
    let columns = headers.map(r => {
      return {
        header: r.text,
        dataKey: r.value
      };
    });
    columns.unshift({
      header: "#",
      dataKey: "index"
    });
    data = [...data].map((r, index) => {
      return {
        ...r,
        price: currencyUSD(r.price || 0),
        discount: r.discount ? "Yes" : "No",
        tax: r.tax ? "Yes" : "No",
        amount: currencyUSD(r.amount || 0),
        index: index + 1
      };
    });
    this.doc.autoTable({
      startY: this.positionY + 20,
      didParseCell: data => {
        if (data.cell && data.cell.section === "head") {
          switch (data.cell.raw) {
            case "#":
            case "Item Details":
              data.cell.styles.halign = "left";
              data.cell.styles.fillColor = [187, 187, 187];
              break;
            default:
              data.cell.styles.halign = "right";
              data.cell.styles.fillColor = [187, 187, 187];
              break;
          }
        }
      },
      // headStyles: {
      //   fillColor: [187, 187, 187],
      //   halign: "right",
      //   valign: "middle",
      // },
      columnStyles: {
        price: { halign: "right" },
        quantity: { halign: "right" },
        amount: { halign: "right" },
        tax: { halign: "right" },
        discount: { halign: "right" }
      },
      body: data,
      columns: columns,
      //theme: 'grid',
      margin: { left: 30, bottom: 50 }
    });
    this.positionY = this.doc.previousAutoTable.finalY;
  }

  addSummaryFooter(
    subTotal,
    discountPercent,
    discountAmount,
    taxPercent,
    taxAmount,
    shippingChargeValue,
    total
  ) {
    if (this.isBreakPage(this.lineHeight * 5)) {
      this.doc.addPage();
      this.positionY = 30;
    }
    this.enter();
    this.setX(250);
    this.addText("Sub Total:");
    this.setX(427);
    this.addText(currencyUSD(subTotal), { align: "right" });
    this.enter();

    this.setX(250);
    this.addText("Discount:");
    if (taxPercent !== null) {
      this.setX(370);
      this.addText(discountPercent, { align: "right" });
    }
    this.setX(427);
    this.addText(
      `${discountAmount > 0 ? "-" : ""}${currencyUSD(discountAmount)}`,
      { align: "right" }
    );
    this.enter();

    this.setX(250);
    this.addText("Tax Amount:");
    if (taxPercent !== null) {
      this.setX(370);
      this.addText(taxPercent, { align: "right" });
    }
    this.setX(427);
    this.addText(currencyUSD(taxAmount), { align: "right" });
    this.enter();

    if (shippingChargeValue !== null) {
      this.setX(250);
      this.addText("Shipping Charge:");
      this.setX(427);
      this.addText(currencyUSD(shippingChargeValue), { align: "right" });
      this.enter();
    }
    this.setFillColor("#F5F5F5");
    this.rect(245, this.positionY - 12, 190, 20, "F");
    this.setFontType("bold");
    this.setX(250);
    this.addText("Total:");
    this.setX(427);
    this.addText(currencyUSD(total), { align: "right" });
    this.enter();
  }

  async addImage(url, x, y, width, height) {
    if (!url) return;
    let imageData = await getDataUri(url);
    if (imageData)
      this.doc.addImage(imageData, "PNG", x, y, width, height, 0, "FAST");
  }
  async addProjectImage(url, x, y, width, height, alias) {
    if (this.isBreakPage(height)) {
      this.doc.addPage();
      this.positionY = 30;
    }
    let imageData = await getDataUri(url);
    if (imageData)
      this.doc.addImage(imageData, "PNG", x, y, width, height, alias, "FAST");
    //this.positionY += height;
  }
  async addImages(images, sectionName, width = 190, height = 120) {
    if (!images || images.length == 0) return;
    if (this.isBreakPage(height)) {
      this.doc.addPage();
      this.positionY = 30;
    }
    this.setX(30);
    this.addText(sectionName || "");
    this.enter();
    for (let i = 0; i < images.length; i++) {
      let photo = images[i];
      if (i % 2 == 0) {
        if (this.isBreakPage(height)) {
          this.doc.addPage();
          this.positionY = 30;
        }
        await this.addProjectImage(
          photo.photoUrl,
          30,
          this.positionY,
          width,
          height,
          photo.id
        );
        this.addText(
          photo.notes || "",
          {},
          30,
          this.positionY + height + this.lineHeight
        );
        if (i == images.length - 1) {
          this.positionY += height + this.lineHeight;
        }
      } else {
        await this.addProjectImage(
          photo.photoUrl,
          250,
          this.positionY,
          width,
          height,
          photo.id
        );
        this.addText(
          photo.notes || "",
          {},
          250,
          this.positionY + height + this.lineHeight
        );
        this.positionY += height + this.lineHeight;
        this.enter();
      }
    }
  }

  enter() {
    this.positionY += this.lineHeight;
  }
  goto(x, y) {
    this.positionX = x;
    this.positionY = y;
  }
  setX(value) {
    this.positionX = value;
  }
  setY(value) {
    this.positionY = value;
  }
  getX() {
    return this.positionX;
  }
  getY() {
    return this.positionY;
  }
  addText(text, option = {}, x, y) {
    text = text || "";
    if (option.format == "currencyUSD") {
      text = currencyUSD(text);
    } else if (option.format == "percent") {
      text = percent(text);
    } else if (option.format == "MMDDYYYY") {
      text = MMDDYYYY(text.toDate ? text.toDate() : "");
    }

    if (option.prefixText) {
      text = option.prefixText + text;
    }
    let newX = x || this.positionX;
    let newY = y || this.positionY;
    this.doc.text(text, newX, newY, option);
  }
  fromHTML(doc) {
    if (!doc) return;
    let textOnly = extractContent(doc);
    let lineNumber = Math.ceil(textOnly.length / 140); //130 charaters per line
    lineNumber += (doc.match(new RegExp("<br>", "g")) || []).length;
    lineNumber += (doc.match(new RegExp("\\n", "g")) || []).length;
    lineNumber += (doc.match(new RegExp("<div", "g")) || []).length;
    let textHeight = lineNumber * this.lineHeight;
    if (this.isBreakPage(textHeight)) {
      this.doc.addPage();
      this.positionY = 30;
    }
    let formatDoc = `<div style="font-size: 14px;line-height: 1.6;">${doc}</div>`;
    this.doc.html(formatDoc, {
      x: 30,
      y: this.positionY,
      width: 400
    });
    this.positionY = this.positionY + textHeight;
  }
  isBreakPage(textHeight) {
    let restHeight = 600 - this.positionY;
    if (textHeight > restHeight) return true;
    else return false;
  }
  line(x1, y1, x2, y2, style) {
    this.doc.line(x1, y1, x2, y2, style);
  }
  setFontSize(size) {
    this.doc.setFontSize(size);
  }
  setFontType(type) {
    this.doc.setFont(type || "normal");
  }
  openNewWindow() {
    this.doc.output("dataurlnewwindow");
  }
  saveDoc(fileName) {
    this.doc.save(fileName);
  }
  getBase64() {
    return this.doc
      .output("datauri")
      .replace("data:application/pdf;filename=generated.pdf;base64,", "");
  }
  setFillColor(ch1, ch2, ch3, ch4) {
    this.doc.setFillColor(ch1, ch2, ch3, ch4);
  }
  setTextColor(ch1, ch2, ch3, ch4) {
    this.doc.setTextColor(ch1, ch2, ch3, ch4);
  }
  rect(x, y, w, h, style) {
    this.doc.rect(x, y, w, h, style);
  }
}
export default PDFWriter;
