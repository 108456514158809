<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Categories</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-f7="plus" @click.native="popupAddNew = true"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search category"
      :clear-button="true"
      class="text-color-white search-list"
      @input="searchText = $event.target.value"
      @searchbar:disable="searchText = ''"
      :value="searchText"
    ></f7-searchbar>
    <div>
      <f7-list media-list class="no-margin">
        <f7-list-item
          v-for="item in displayedList"
          :key="item.id"
          :title="item.name"
          :reload-detail="true"
          :link="`/category/${item.id}`"
          swipeout
          :text-color="item.id === (detail || {}).id ? 'primary' : 'default'"
        >
          <f7-row slot="text">
            <f7-col>
              <div>
                <div class="font-text">
                  Roof types:
                  {{ roofTypesDisplay(item.roofTypes) }}
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>
      </f7-list>
      <add-new-popup :isShow="popupAddNew" @close="popupAddNew = false" />
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddNewPopup from "../components/popup/AddNewCategoryPopup";
import { applyFilter } from "@/utility/filter-tools";
import Menu from "../../../components/menu/Menu.vue";

export default {
  data() {
    return {
      popupAddNew: false,
      searchText: ""
    };
  },
  components: {
    AddNewPopup,
    Menu
  },
  methods: {
    ...mapActions({
      bindCategory: "product/category-page/category/bind",
      unbindCategory: "product/category-page/category/unbindCategory",
      delete: "product/category-page/category/delete",
      update: "product/category-page/category/update",

      unbindSubCategory: "product/category-page/sub-category/unbind"
    }),
    ...mapActions("product/roof-type", ["getRoofTypeList"]),
    roofTypesDisplay(roofTypes) {
      return this.roofTypeList
        .filter(t => (roofTypes || []).includes(t.value))
        .map(t => t.displayName)
        .join(", ");
    }
  },
  computed: {
    ...mapGetters({
      list: "product/category-page/category/objectList",
      detail: "product/category-page/category/detail",

      roofTypeList: "product/roof-type/roofTypeList"
    }),
    displayedList() {
      const filteredList = applyFilter([...this.list], this.searchText, [
        "name"
      ]);
      return filteredList.sort((a, b) => sortBy(a.name, b.name));
    }
  },

  async created() {
    const refs = [];
    this.$f7.preloader.show();
    refs.push(await this.getRoofTypeList());
    refs.push(await this.bindCategory());
    Promise.all(refs).finally(() => {
      this.$f7.preloader.hide();
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  }
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
</script>
