import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const weeklyLaborCostActions = new FirebaseActions(
    `/system_client/${tenant}/weekly_labor_cost`,
    "weeklyLaborCost"
  );

  return {
    bindWeeklyLaborCostList: weeklyLaborCostActions.bindCollection,
    bindWeeklyLaborCostListBys: weeklyLaborCostActions.bindCollectionBys,
    unbindWeeklyLaborCostList: weeklyLaborCostActions.unbindCollection,

    bindWeeklyLaborCost: weeklyLaborCostActions.bindDocument,
    unbindWeeklyLaborCost: weeklyLaborCostActions.unbindDocument
  };
};
