<template>
  <div>
    <f7-block-header>Contact information</f7-block-header>
    <f7-list class="no-margin">
      <!-- contact name -->
      <f7-list-item v-show="contactName" header="Contact Name" :title="contactName">
        <f7-icon slot="media" color="primary" f7="person"></f7-icon>
      </f7-list-item>
      <!-- Phone -->
      <f7-list-item v-for="(phone, index) in (contact || {}).phones" :key="`phone-${index}`">
        <div slot="header">{{ "Phone Number" }}</div>
        <div class="list-item-title" slot="title">{{ phone.value }}</div>
        <f7-icon slot="media" color="primary" f7="phone"></f7-icon>
        <div slot="after-title">
          <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
        </div>
      </f7-list-item>
      <!-- Email -->
      <f7-list-item v-for="(email, index) in (contact || {}).emails" :key="`email-${index}`">
        <div slot="header">{{ "Email" }}</div>
        <div class="list-item-title" slot="title">{{ email.value }}</div>
        <f7-icon slot="media" color="primary" f7="envelope"></f7-icon>
        <div slot="after-title">
          <f7-link class="external icon-link" :href="'mailto:' + email.value" icon-f7="envelope_circle_fill"></f7-link>
        </div>
      </f7-list-item>
      <!-- Address -->
      <f7-list-item v-for="(address, index) in (contact || {}).addresses" :key="`address-${index}`">
        <div slot="header">{{ "Address" }}</div>
        <div class="list-item-title" slot="title">
          {{ getFullAddress(address) }}
        </div>
        <f7-icon slot="media" color="primary" f7="map"></f7-icon>
        <div slot="after-title">
          <f7-link class="external icon-link" :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`"  icon-f7="placemark_fill"></f7-link>
        </div>
      </f7-list-item>
      <!-- company name -->
      <f7-list-item v-show="companyName" header="Company Name" :title="companyName" :footer="companyAddress">
        <f7-icon slot="media" color="primary" f7="globe"></f7-icon>
      </f7-list-item>
      <!-- Property Name -->
      <f7-list-item v-show="propertyName" header="Property Name" :title="propertyName" :footer="propertyAddress">
        <f7-icon slot="media" color="primary" f7="building_2"></f7-icon>
      </f7-list-item>
      <!-- lead source -->
      <f7-list-input label="Lead Source" type="select" placeholder="Please choose..." :value="leadSource"
        @input="leadSource = $event.target.value">
        <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
        <option value="" disabled>Select lead source</option>
        <option v-for="(type, index) in leadSourceCommercialList" :key="index" :value="type.value">{{ type.displayName
          }}</option>
      </f7-list-input>

      <f7-list-input label="Description" type="textarea" placeholder="Description" :value="description"
        @input="description = $event.target.value">
        <input-icon slot="media" icon="doc_text"></input-icon>
      </f7-list-input>
    </f7-list>

    <!-- schedule -->
    <f7-list accordion-list class="accordion-schedule no-margin">
      <f7-list-item accordion-item title="Schedule a Call Date">
        <f7-accordion-content>
          <f7-list>
            <f7-list-input type="datepicker" :calendar-params="{
              backdrop: true,
              openIn: 'customModal',
              header: true,
              footer: false,
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true
            }" label="Contacted Date" placeholder="Enter Contacted Date" :value="contactedDate"
              @calendar:change="contactedDate = $event">
              <input-icon slot="media" icon="flag"></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item title="Schedule Site Visit">
        <div slot="after" class="color-primary">
          {{
            buildsOrSectionsErrorMessage || numberOfRoofSytemErrorMessage
              ? "Please input data!"
              : ""
          }}
        </div>
        <f7-accordion-content>
          <f7-list>
            <f7-list-input type="datepicker" :calendar-params="{
              backdrop: true,
              openIn: 'customModal',
              header: true,
              footer: false,
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true
            }" label="Site Visit Date" placeholder="Enter Site Visit Date" :value="siteVisitDate"
              @calendar:change="siteVisitDate = $event">
              <input-icon slot="media" icon="flag"></input-icon>
            </f7-list-input>

            <f7-list-input label="Job Type" type="select" placeholder="Please choose..." :value="jobType"
              @input="jobType = $event.target.value">
              <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
              <option v-for="(type, index) in jobTypeList" :key="index" :value="type.value">{{ type.displayName }}
              </option>
            </f7-list-input>

            <f7-list-input label="Number of Specifications" :value="numberOfBuildsOrSections" type="number" @focus="
              numberOfBuildsOrSections == 0
                ? (numberOfBuildsOrSections = null)
                : numberOfBuildsOrSections
              " @blur="
                numberOfBuildsOrSections = Number(numberOfBuildsOrSections)
                " @input="handleSpecificationInput" error-message-force :error-message="buildsOrSectionsErrorMessage">
              <required-asterisk slot="label"></required-asterisk>
              <input-icon slot="media" icon="number_circle"></input-icon>
            </f7-list-input>
            <f7-list-item class="block-item" v-for="(building, index) in buildings" :key="index">
              <f7-list-input placeholder="Specification #" type="text" :value="building.buildingName"
                @input="building.buildingName = $event.target.value.trim()" error-message-force validate
                validate-on-blur :error-message="buildingNameErrorMessage(index)">
                <input-icon slot="media" icon="table"></input-icon>
                <div class="list-item-inner-start" slot="inner-start">
                  Specification # {{ index + 1 }}<required-asterisk />
                </div>
              </f7-list-input>
              <f7-list-item class="block-item">
                <f7-list-input label="Number of Building/Sections" :value="building.numberOfCopy"
                  class="number-building-input" placeholder="Enter number of buidings/sections" type="number" @input="
                    building.numberOfCopy = parseInt($event.target.value.trim())
                    " error-message-force validate validate-on-blur
                  :error-message="numberOfBuildingsErrorMessage(index)">
                  <input-icon slot="media" icon="building_2"></input-icon>
                </f7-list-input>
              </f7-list-item>
            </f7-list-item>
            <f7-list-input v-show="jobType !== JOB_TYPE_NEW" label="Number of Roof Systems" :value="numberOfRoofSytem"
              type="number" @focus="numberOfRoofSytem" @blur="numberOfRoofSytem = Number(numberOfRoofSytem)"
              @input="numberOfRoofSytem = $event.target.value.trim()" error-message-force
              :error-message="numberOfRoofSytemErrorMessage">
              <required-asterisk slot="label"></required-asterisk>
              <input-icon slot="media" icon="arrow_2_squarepath"></input-icon>
            </f7-list-input>

            <!-- loose gravel -->
            <f7-list-item title="Loose Gravel" v-show="jobType !== JOB_TYPE_NEW && numberOfRoofSytem < 2">
              <f7-toggle slot="after" :checked="displayCard.looseGravel"
                @toggle:change="changeToggle('looseGravel', $event)"></f7-toggle>
              <input-icon slot="media" icon="xmark_seal"></input-icon>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item title="Schedule Budget Meeting">
        <f7-accordion-content>
          <f7-list>
            <f7-list-input type="datepicker" :calendar-params="{
              backdrop: true,
              openIn: 'customModal',
              header: true,
              footer: false,
              dateFormat: 'mm/dd/yyyy',
              closeOnSelect: true
            }" label="Budget Meeting Date" placeholder="Enter Budget Meeting Date" :value="budgetMeetingDate"
              @calendar:change="budgetMeetingDate = $event">
              <input-icon slot="media" icon="flag"></input-icon>
            </f7-list-input>
            <f7-list-input type="text" label="Client Budget" placeholder="$ Enter Client Budget" v-model="clientBudget"
              @input="
                clientBudget = formatPriceNumber($event.target.value.trim())
                ">
              <!-- <required-asterisk slot="label"></required-asterisk> -->
              <input-icon slot="media" icon="money_dollar_circle"></input-icon>
            </f7-list-input>
            <f7-list-item title="Tax Exempt">
              <f7-toggle slot="after" :checked="displayCard.taxExempt"
                @toggle:change="changeToggle('taxExempt', $event)"></f7-toggle>
              <input-icon slot="media" icon="xmark_seal"></input-icon>
            </f7-list-item>
            <f7-list-item v-show="displayCard.taxExempt">
              <attachment-input :project-id="displayCard.id" add-button-title="Upload Document"
                attachment-type="taxExempt" :value="displayCard.attachmentFiles"
                @input="setAttachment('attachmentFiles', $event)" :no-margin-top="false"></attachment-input>
            </f7-list-item>
            <f7-list-item title="Resale Certificate">
              <f7-toggle slot="after" :checked="displayCard.resalesCertificate"
                @toggle:change="changeToggle('resalesCertificate', $event)"></f7-toggle>
              <input-icon slot="media" icon="viewfinder_circle"></input-icon>
            </f7-list-item>
            <f7-list-item v-show="displayCard.resalesCertificate">
              <attachment-input :project-id="displayCard.id" add-button-title="Upload Document"
                attachment-type="resalesCertificate" :value="displayCard.attachmentFiles"
                @input="setAttachment('attachmentFiles', $event)" :no-margin-top="false"></attachment-input>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <!-- attachment -->
    <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
      add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)" @save="save"></attachment-input>
    <user-input :value="displayCard.assigneeIDs" @input="setAssigneeIDs($event)"></user-input>

    <note-editor @onChange="changeValueNotes" :value="note" :is-save-button="true" @onSave="
      onSaveNote({
        displayCard,
        title: 'Contact Lead Note',
        note,
        callback: () => {
          note = '';
        }
      })
      "></note-editor>

    <!-- <f7-list>
      <f7-list-input
        label="Note"
        floating-label
        type="textarea"
        resizable
        clear-button
        :value="note"
        @input="note = $event.target.value.trim()"
      >
        <input-icon slot="media" icon="doc_text"></input-icon>
      </f7-list-input>
    </f7-list> -->

    <!-- Move to Estimate popup -->
    <date-popup ref="estimateDueDatePopup" title="Enter Estimate Due Date" label="Estimate Due Date"
      v-model="estimateDueDate" @done="resolveEstimate" validate not-allow-past></date-popup>

    <!-- un interested -->
    <note-popup ref="archiveNotePopup" title="Archive Note" label="Note" v-model="archiveNote" @done="onDone"
      validate></note-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import NotePopup from "../popup/NotePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import AttachmentInput from "../input/AttachmentInput.vue";

import { mapActions, mapGetters } from "vuex";
import { firebase, auth } from "../../../../services/firebase.service";
import _ from "lodash";
import { getFullAddress } from "@/utility/address";
import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";
import methodsMixins from "../../mixin/methods";
import { JOB_TYPE_NEW, VALIDATION_MESSAGE } from "../../../../utility/const";
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue ,helpers} from "@vuelidate/validators";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  props: {
    popupOpened: Boolean
  },
  mixins: [methodsMixins],

  components: {
    UserInput,
    DatePopup,
    NotePopup,
    InputIcon,
    AttachmentInput,
    NoteEditor
  },

  data: () => {
    return {
      note: "",
      archiveNote: "",
      currentResponse: {},
      displayCard: {},
      dueDate: [],
      contactedDate: [],

      leadSource: "",
      description: "",

      siteVisitDate: [],
      jobType: "",
      numberOfBuildsOrSections: 0,
      numberOfRoofSytem: 0,

      budgetMeetingDate: [],
      clientBudget: "",

      estimateDueDate: [],
      buildings: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initJobType();
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapGetters("dashboard/project", ["card", "action", "response", "users"]),
    ...mapGetters("dashboard/app-constant", [
      "leadSourceCommercialList",
      "jobTypeList"
    ]),
    ...mapGetters("dashboard/client", ["contact", "company", "property"]),

    // not init at data() because: func clearData() on methods mixin
    JOB_TYPE_NEW() {
      return JOB_TYPE_NEW;
    },

    userInfo() {
      return this.users.find(user => {
        return user.uid === this.uid;
      });
    },

    uid() {
      return auth.currentUser.uid;
    },

    contactName() {
      return this.contact
        ? this.contact.firstName + " " + this.contact.lastName
        : "";
    },

    companyName() {
      return this.company ? this.company.companyName : "";
    },

    propertyName() {
      return this.property ? this.property.propertyName : "";
    },

    propertyAddress() {
      return this.property && this.property.addresses
        ? this.property.addresses[0]
          ? getFullAddress(this.property.addresses[0]) ||
          this.property.addresses[0].value
          : ""
        : "";
    },

    companyAddress() {
      return this.company && this.company.addresses
        ? this.company.addresses[0]
          ? getFullAddress(this.company.addresses[0]) ||
          this.company.addresses[0].value
          : ""
        : "";
    },

    buildsOrSectionsErrorMessage() {
      if (!this.v$.numberOfBuildsOrSections.$error) return null;
      if (this.v$.numberOfBuildsOrSections.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfBuildsOrSections.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ONE;
      return null;
    },

    numberOfRoofSytemErrorMessage() {
      if (!this.v$.numberOfRoofSytem.$error) return null;
      if (this.v$.numberOfRoofSytem.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfRoofSytem.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      return null;
    },

    buildingNameErrorMessage() {
      return index => {
        if (this.v$.buildings.$each.$response.$errors[index].buildingName.length === 0) return null;
        return this.v$.buildings.$each.$response.$errors[index].buildingName[0].$message || ''
      };
    },
    
    numberOfBuildingsErrorMessage() {
      return index => {
        if (this.v$.buildings.$each.$response.$errors[index].numberOfCopy.length === 0) return null;
        return this.v$.buildings.$each.$response.$errors[index].numberOfCopy[0].$message || ''
      };
    }
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    getFullAddress(address) {
      return getFullAddress(address);
    },
    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    changeValueNotes(value) {
      this.note = value;
    },

    changeToggle(name, value) {
      if (this.displayCard[name] == value) return;
      this.displayCard = {
        ...this.displayCard,
        [name]: value
      };
      if (this.popupOpened) {
        this.$f7.preloader.show();
        this.updateCard({
          id: this.displayCard.id,
          doc: {
            [name]: value
          }
        }).finally(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    initJobType() {
      if (!this.jobType) {
        this.jobType = (this.jobTypeList[0] || {}).value;
      }
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        if (this.displayCard.contactedDate) {
          this.contactedDate = toDateCalendar(this.displayCard.contactedDate);
        }
        if (this.displayCard.leadSource) {
          this.leadSource = this.displayCard.leadSource;
        }

        if (this.displayCard.siteVisitDate) {
          this.siteVisitDate = toDateCalendar(this.displayCard.siteVisitDate);
        }

        if (this.displayCard.jobType) {
          this.jobType = this.displayCard.jobType;
        }

        if (this.displayCard.numberOfBuildsOrSections) {
          this.numberOfBuildsOrSections = this.displayCard.numberOfBuildsOrSections;
        }
        if (this.displayCard.numberOfRoofSytem) {
          this.numberOfRoofSytem = this.displayCard.numberOfRoofSytem;
        }
        if (
          this.displayCard.buildings &&
          this.displayCard.buildings.length > 0
        ) {
          this.buildings = this.displayCard.buildings;
        }

        if (this.displayCard.budgetMeetingDate) {
          this.budgetMeetingDate = toDateCalendar(
            this.displayCard.budgetMeetingDate
          );
        }
        this.displayCard.taxExempt = this.displayCard.taxExempt || false;
        this.displayCard.resalesCertificate =
          this.displayCard.resalesCertificate || false;
        if (this.displayCard.clientBudget) {
          this.clientBudget = this.formatPriceNumber(
            this.displayCard.clientBudget.toString()
          );
        }
        if (this.displayCard.description) {
          this.description = this.displayCard.description;
        }
      }
    },
    onDone(){
      this.resolve(() => {});
      this.$f7router.updateCurrentUrl("/dashboard/swimlane/commercial/sales");
    },
    // Implement do something before resolve action
    doAction(response) {
      // check upload document for taxExempt, resalesCertificate
      const inValidTaxExempt =
        this.displayCard.taxExempt &&
        !this.getAttachmentFilesByType("taxExempt").length;
      const inValidResalesCertificate =
        this.displayCard.resalesCertificate &&
        !this.getAttachmentFilesByType("resalesCertificate").length;
      if (inValidTaxExempt && inValidResalesCertificate) {
        this.showUploadDialog(
          response,
          "Please upload document for Tax Exempt and Resale Certificate"
        );
      } else if (inValidTaxExempt) {
        this.showUploadDialog(
          response,
          "Please upload document for Tax Exempt"
        );
      } else if (inValidResalesCertificate) {
        this.showUploadDialog(
          response,
          "Please upload document for Resale Certificate"
        );
      } else {
        this.handleAction(response);
      }
    },

    handleAction(response) {
      switch (response.code) {
        case "follow-up-contact":
          this.currentResponse = response;
          this.resolve();
          break;

        case "un-interested":
          this.currentResponse = response;
          this.$refs.archiveNotePopup.open();
          break;

        case "move-to-estimate":
          this.v$.$touch();
          if (this.v$.$invalid) return;
          this.currentResponse = response;
          this.$refs.estimateDueDatePopup.open();
          break;
      }
    },

    resolveEstimate() {
      this.dueDate = this.estimateDueDate;
      this.resolve();
    },

    // handle specification input
    handleSpecificationInput(event) {
      this.numberOfBuildsOrSections = event.target.value.trim();
      Array.from({ length: this.numberOfBuildsOrSections }, () => {
        if (this.buildings.length > Number(this.numberOfBuildsOrSections)) {
          this.buildings = this.buildings.slice(
            0,
            this.numberOfBuildsOrSections
          );
        } else {
          while (
            this.buildings.length < Number(this.numberOfBuildsOrSections)
          ) {
            let counter = this.buildings.length;
            this.buildings.push({
              buildingName: `SPEC_${counter + 1}`,
              buildingId: "",
              numberOfCopy: 1
            });
          }
        }
      });
      if (Number(this.numberOfBuildsOrSections) === 0) {
        this.buildings = [];
      }
    },

    // Implement  resolve action
    async resolve(callback) {
      if (this.currentResponse.code === "move-to-estimate") {
        this.v$.$touch();
        if (this.v$.$invalid) return;
      }
      this.$f7.preloader.show();

      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup", callback);
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Contact Lead Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      if (this.archiveNote) {
        commentAndNote.push({
          code: "note",
          title: "Archive Note",
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

        this.displayCard.archiveDate = firebase.firestore.Timestamp.now();
      }

      this.displayCard.commentAndNote = commentAndNote;

      this.displayCard.leadSource = this.leadSource || "";
      this.displayCard.description = this.description || "";

      if (!_.isEmpty(this.contactedDate)) {
        this.displayCard.contactedDate = toDateFirebase(this.contactedDate);
        this.displayCard.dueDate = toDateFirebase(this.contactedDate);
      }
      if (!_.isEmpty(this.siteVisitDate)) {
        this.displayCard.siteVisitDate = toDateFirebase(this.siteVisitDate);
        this.displayCard.dueDate = toDateFirebase(this.siteVisitDate);
      }
      if (!_.isEmpty(this.jobType)) {
        this.displayCard.jobType = this.jobType;
      }
      if (this.numberOfBuildsOrSections != null) {
        this.displayCard.numberOfBuildsOrSections = this.numberOfBuildsOrSections;
      }

      if (this.numberOfRoofSytem != null) {
        this.displayCard.numberOfRoofSytem = this.numberOfRoofSytem;
        if (this.numberOfRoofSytem > 1) {
          this.displayCard.looseGravel = false;
        }
      }

      if (!_.isEmpty(this.budgetMeetingDate)) {
        this.displayCard.budgetMeetingDate = toDateFirebase(
          this.budgetMeetingDate
        );
        this.displayCard.dueDate = toDateFirebase(this.budgetMeetingDate);
      }

      if (!_.isEmpty(this.dueDate)) {
        this.displayCard.dueDate = toDateFirebase(this.dueDate);
      }

      if (!_.isEmpty(this.clientBudget)) {
        const cost = this.formatDots(this.clientBudget)
          .replaceAll("$", "")
          .replaceAll(",", "");
        this.displayCard.clientBudget = parseFloat(cost) || null;
      }

      if (this.numberOfBuildsOrSections != null) {
        this.displayCard.numberOfBuildsOrSections = this.numberOfBuildsOrSections;
      }

      if (this.buildings) {
        this.displayCard.buildings = this.buildings;
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    const numberOfRoofSytemRule = () => {
      if (this.jobType !== this.JOB_TYPE_NEW && this.numberOfRoofSytem === null)
        return false;
      return true;
    };
    return {
      numberOfBuildsOrSections: {
        required,
        minValue: minValue(1)
      },

      numberOfRoofSytem: {
        numberOfRoofSytemRule,
        minValue: this.jobType !== this.JOB_TYPE_NEW && minValue(0)
      },

      buildings: {
        $each: helpers.forEach({
          buildingName: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          },
          numberOfCopy: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            minValue: helpers.withMessage(VALIDATION_MESSAGE.GREATER_THAN_ZERO, minValue(1)),
            maxValue: helpers.withMessage(VALIDATION_MESSAGE.LESS_THAN_OR_EQUAL_TO_200, maxValue(200))
          }
        })
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.accordion-schedule ::v-deep {
  ul:first-child::before {
    height: 0;
  }
}

.color-primary {
  color: var(--f7-theme-color);
}

.block-item ::v-deep .item-inner {
  display: block;
  padding-right: 0;
}

.list-item-inner-start {
  color: #f05034;
  font-weight: 600;
}
</style>

<style lang="scss">
.toast-custom {
  .toast-content {
    display: flex !important;
  }

  .toast-icon i {
    margin-right: 10px;
    font-size: 1em !important;
  }
}
</style>
