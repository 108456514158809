import category from "./category";
import contact from "./contact";
import company from "./company";
import estimate from "./estimate";
import vendor from "./vendor";
import priceList from "./price-list";
import productItem from "./product-item";
import productDefault from "./product-default";
import project from "./project";
import property from "./property";
import subCategory from "./sub-category";
import stackct from "./stackct";
import common from "./common";
import assemblyDrawing from "./assembly-drawing";
import user from "./user";
import group from "./group";
import _ from "lodash";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      "estimate-page": {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          category: _.cloneDeep(category(tenant)),
          contact: _.cloneDeep(contact(tenant)),
          company: _.cloneDeep(company(tenant)),
          estimate: _.cloneDeep(estimate(tenant)),
          vendor: _.cloneDeep(vendor(tenant)),
          "price-list": _.cloneDeep(priceList(tenant)),
          "product-item": _.cloneDeep(productItem(tenant)),
          "product-default": _.cloneDeep(productDefault(tenant)),
          project: _.cloneDeep(project(tenant)),
          property: _.cloneDeep(property(tenant)),
          "sub-category": _.cloneDeep(subCategory(tenant)),
          stackct: _.cloneDeep(stackct(tenant)),
          "assembly-drawing": _.cloneDeep(assemblyDrawing(tenant)),
          user: _.cloneDeep(user),
          group: _.cloneDeep(group(tenant))
        }
      }
    }
  };
};
