import * as types from "./types";
import Vue from "vue";
import _ from "lodash";

export default {
  setCardList(state, payload) {
    Vue.set(state, "cardList", payload)
  },
  setCardSubscribe(state, payload) {
    Vue.set(state, "cardSubscribe", payload)
  },
  setConstructionProjectList(state, payload) {
    Vue.set(state, "constructionProjectList", payload)
  },
  setConstructionProjectSubscribe(state, payload) {
    Vue.set(state, "constructionProjectSubscribe", payload)
  },
  [types.SET_CURRENT_CARD](state, cardNumber) {
    let card = state.cardList.find(card => {
      return card.cardNumber === cardNumber;
    });
    Vue.set(state, "card", _.cloneDeep(card));
  },

  [types.SET_CURRENT_ACTION](state, id) {
    let action = state.actionList.find(action => {
      return action.id === id;
    });
    Vue.set(state, "action", action);
  },

  [types.SET_CURRENT_CARD_VALUES](state, values) {
    Object.keys(values).forEach(key => {
      Vue.set(state.card, key, values[key]);
    });
  },

  [types.CLEAR_CURRENT_CARD](state) {
    Vue.set(state, "card", {});
  },

  [types.SET_ACTION_RESPONSE](state, payload) {
    Vue.set(state, "response", payload);
  },

  [types.SET_ACTION_LIST](state, payload) {
    Vue.set(state, "actionList", payload);
  },

  [types.SET_USERS_LIST](state, users) {
    Vue.set(state, "users", users);
  },

  [types.SET_FILTER_USERS](state, payload) {
    Vue.set(state, "userFilter", payload);
  },

  [types.SET_FILTER_SEARCH](state, payload) {
    Vue.set(state, "searchText", payload);
  },

  [types.SET_FILTER_WEEK](state, payload) {
    Vue.set(state, "weekFilter", payload);
  },

  [types.SET_CURRENT_BOARD](state, payload) {
    Vue.set(state, "boardCode", payload);
  },

  [types.SET_CURRENT_BUSINESS_CODE](state, payload) {
    Vue.set(state, "businessCode", payload);
  },

  [types.SET_CONSTRUCTION_PROJECT_ID](state, constructionProjectId) {
    Vue.set(state, "constructionProjectId", constructionProjectId);
  },

  [types.SET_CONSTRUCTION_PROJECT_NUMBER](state, constructionProjectNumber) {
    Vue.set(state, "constructionProjectNumber", constructionProjectNumber);
  },

  [types.SET_IS_DELETE_TEMPLE_PROJECT](state, value) {
    Vue.set(state, "isDeleteTempleProject", value);
  },

  [types.SET_CURRENT_ACTION_IDS_AFTER_MOVE_OR_ADD](state, value) {
    Vue.set(state, "currActionIdsAfterMoveOrAdd", value);
  },

  [types.SET_CONDITION_TO_DISPLAY_RESPONSE_BTN](state, payload) {
    Vue.set(state, "conditionToDisplayResponseBtn", payload);
  },
  [types.SET_CURRENT_SECTION](state, value) {
    Vue.set(state, "currentSection", value);
  }
};
