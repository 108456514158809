export default {
  contactList: [],
  contactSubscribe: null,
  companyList: [],
  companySubscribe: null,
  propertyList: [],
  propertySubscribe: null,
  messageList: [],
  messageSubscribe: null,
  historyList: [],
  users: [],
  createdPropertyId: "",
  createdContactId: "",
  createdCompanyId: "",

  contact: {},
  company: {},
  property: {},
  history: {},

  contactCompanyRefs: [],
  contactPropertyRefs: [],
  contactProjectRefs: [],

  propertyContactRefs: [],
  propertyCompanyRefs: [],
  propertyProjectRefs: [],

  companyContactRefs: [],
  companyPropertyRefs: [],
  companyBranchRefs: [],
  companyProjectRefs: [],

  companyDetailActiveTab: "company-detail",
  isNewContact: false,
  oldCompanyId: "",
  oldContactId: "",
  isEditCompany: false,
  searchValue: "",
  isEditContact: false,
  isNewCompany: false,
  isEditProperty: false,
  branch: {},
  tempContact: {}
};
