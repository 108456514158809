export default {
  productItemList: state => state.productItemList,
  // productItemList: (state, _, __, rootGetters) => {
  //   const priceList =
  //     rootGetters[
  //       "purchase-order/purchase-order-details-page/price-list/itemPriceList"
  //     ];
  //   const updatedObjectList = state.productItemList.map(obj => {
  //     const matchingPrice = priceList.productRefs.find(
  //       price => price.productId === obj.id
  //     );
  //     if (matchingPrice) {
  //       return {
  //         ...obj,
  //         price: matchingPrice.price
  //       };
  //     }
  //     return obj;
  //   });
  //   return updatedObjectList;
  // },
  productItem: state => state.productItem
};
