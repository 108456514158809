<template>
  <div>
    <f7-block-header>Lead Information</f7-block-header>
    <f7-list>
      <f7-list-item
        class="first-input"
        header="Contact Name"
        :title="contactName(detail)"
      >
        <f7-icon color="primary" f7="person_circle" slot="media"></f7-icon>
      </f7-list-item>

      <f7-list-item class="first-input" header="Email" :title="detail.email">
        <f7-icon color="primary" f7="envelope" slot="media"></f7-icon>
      </f7-list-item>

      <f7-list-item
        class="first-input"
        header="Phone Number"
        :title="detail.phoneNumber"
      >
        <f7-icon color="primary" f7="phone_circle" slot="media"></f7-icon>
      </f7-list-item>

      <f7-list-item
        class="first-input"
        header="Company Name"
        :title="detail.companyName"
      >
        <f7-icon color="primary" f7="briefcase" slot="media"></f7-icon>
      </f7-list-item>

      <f7-list-item
        class="first-input"
        header="Reason"
        :title="ONLINE_LEAD_REASON[detail.reason]"
      >
        <f7-icon color="primary" f7="question_diamond" slot="media"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item
        class="first-input"
        header="Message"
        :title="detail.message"
      >
        <f7-icon color="primary" f7="doc_plaintext" slot="media"></f7-icon>
      </f7-list-item>
    </f7-list>

    <note-input
      :value="detail.noteList"
      @change="changeCardValue('noteList', $event)"
      :isNoteContent="isNoteContent"
    ></note-input>
  </div>
</template>

<script>
import NoteInput from "../input/NoteInput.vue";
import { mapActions } from "vuex";
import { ONLINE_LEAD_REASON } from "../../../../utility/const";

export default {
  components: { NoteInput },

  props: {
    detail: { type: Object, default: () => {} },
    isNoteContent: Boolean
  },

  data: () => {
    return {
      ONLINE_LEAD_REASON
    };
  },

  computed: {
    contactName() {
      return contact => {
        return contact
          ? `${contact.firstName || ""} ${contact.lastName || ""}`.trim()
          : "";
      };
    }
  },

  methods: {
    ...mapActions("web-contact/web-contact-info", ["updateContactInfo"]),

    changeCardValue(field, value) {
      return this.updateContactInfo({
        id: this.detail.id,
        doc: {
          [field]: value
        }
      });
    }
  }
};
</script>

<style></style>
