<template>
  <div
    class="data-table data-table-init card table-fixed-container"
    :style="!$device.desktop ? '' : 'margin-bottom: 80px;'"
  >
    <div class="card-header">
      <!-- Default table header -->
      <div class="data-table-header" text-color="primary">
        <div class="data-table-title">
          {{
            selectedItemCount === 0
              ? tableTitle
              : selectedMessage(selectedItemCount)
          }}
        </div>

        <div class="data-table-actions">
          <f7-button
            @click.native="handleAction"
            outline
            v-show="selectedItemCount > 0"
            >{{ actionTitle }}</f7-button
          >
        </div>
      </div>
    </div>

    <div class="card-content">
      <table>
        <thead>
          <tr>
            <th class="checkbox-cell" style="width: 50px">
              <f7-checkbox
                @change="checkBoxAllChange($event.target.checked)"
              ></f7-checkbox>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Company Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Company"
                  @input="searchCompanyName = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell" v-if="importData">
              <span class="table-head-label">Customer Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Customer"
                  @input="searchCustomerName = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Address</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Address"
                  @input="searchAddress = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Phone Number</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Phone Number"
                  @input="searchPhoneNumber = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Email</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Email"
                  @input="searchEmail = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell" v-if="importData">Company Type</th>
            <th class="label-cell" v-if="exportData">
              <span class="table-head-label">Company Type</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Company Type"
                  @input="searchCompanyType = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="company in companiesFiltered"
            :key="company.Id"
            :class="
              `${
                company.checked && !company.indeterminate ? 'selected-cell' : ''
              }`
            "
          >
            <td class="checkbox-cell">
              <!-- indeterminate
                  disabled -->
              <f7-checkbox
                :disabled="company.indeterminate"
                :indeterminate="company.indeterminate"
                :checked="company.checked"
                @change="
                  checkBoxChange(
                    $event.target.checked,
                    company.Id || company.id
                  )
                "
              ></f7-checkbox>
            </td>
            <td class="label-cell">
              {{ company.CompanyName || company.companyName }}
            </td>
            <td class="label-cell" v-if="importData">
              {{ company.DisplayName }}
            </td>
            <td class="label-cell">
              {{ getCustomerAddress(company) }}
            </td>
            <td class="label-cell">
              {{ getCustomerPhone(company) }}
            </td>
            <td class="label-cell">
              {{ getCustomerEmail(company) }}
            </td>

            <td class="input" v-if="importData">
              <div class="item-input-wrap input-dropdown-wrap">
                <select
                  :disabled="company.indeterminate"
                  placeholder="Please choose..."
                  :value="company.companyType"
                  @change="changeCompanyType($event.target.value, company.Id)"
                >
                  <option
                    v-for="type in companyTypeList"
                    :key="type.value"
                    :value="type.value"
                    >{{ type.displayName }}</option
                  >
                </select>
              </div>
            </td>
            <td class="label-cell" v-if="exportData">
              {{ getRICompanyType(company) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import quickbooksService from "../../../../services/quickbooks.service";
import { COMPANY_TYPE_GENERAL_CONTRACTOR } from "../../../../utility/const";
import { getFullAddress } from "../../../../utility/address";

export default {
  props: {
    importData: { type: Boolean, default: false },
    exportData: { type: Boolean, default: false },
    tableTitle: String,
    actionTitle: String,
    companies: { type: Array, default: () => [] },
    qbCompanyId: { type: String, default: "" }
  },

  data() {
    return {
      selectedItemCount: 0,

      searchCompanyName: "",
      searchCustomerName: "",
      searchAddress: "",
      searchPhoneNumber: "",
      searchEmail: "",
      searchCompanyType: ""
    };
  },

  computed: {
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters("quickbooks/app-constant", ["companyTypeList"]),

    getCustomerAddress() {
      return company => {
        if (!_.isEmpty(company.addresses) && _.isArray(company.addresses)) {
          let address =
            company.addresses.find(item => item.code === "main") ||
            company.addresses[0];

          if (address.value) {
            return address.value;
          } else {
            return getFullAddress(address);
          }
        } else {
          let address = company.BillAddr || company.ShipAddr || {};

          if (_.isEmpty(address)) {
            return "";
          }
          return `${address.Line1 ? address.Line1 + ", " : ""}
            ${address.City ? address.City + ", " : ""}
            ${
              address.CountrySubDivisionCode
                ? address.CountrySubDivisionCode + ", "
                : ""
            }
            ${address.PostalCode ? address.PostalCode : ""}
          `;
        }
      };
    },

    getCustomerPhone() {
      return company => {
        if (!_.isEmpty(company.phones) && _.isArray(company.phones)) {
          let phoneNumber =
            company.phones.find(item => item.code === "main") ||
            company.phones[0];
          return phoneNumber ? phoneNumber.value || "" : "";
        } else {
          return (company.PrimaryPhone || {}).FreeFormNumber || "";
        }
      };
    },

    getCustomerEmail() {
      return company => {
        if (!_.isEmpty(company.others) && _.isArray(company.others)) {
          let email = company.others.find(item => item.code === "email");
          return email ? email.value || "" : "";
        } else {
          return (company.PrimaryEmailAddr || {}).Address || "";
        }
      };
    },

    getRICompanyType() {
      return company => {
        let types = (company.companyTypes || []).map(
          type =>
            (this.companyTypeList.find(item => item.value === type) || {})
              .displayName
        );

        return types.join(", ");
      };
    },

    companiesFiltered() {
      return this.companies.filter(company => {
        return (
          // Filter company name
          ((company.CompanyName || company.companyName || "")
            .toLowerCase()
            .includes(this.searchCompanyName.toLowerCase()) ||
            this.searchCompanyName === "") &&
          // Filter customer name
          ((company.DisplayName || "")
            .toLowerCase()
            .includes(this.searchCustomerName.toLowerCase()) ||
            this.searchCustomerName === "") &&
          // Filter address
          (this.getCustomerAddress(company)
            .toLowerCase()
            .includes(this.searchAddress.toLowerCase()) ||
            this.searchAddress === "") &&
          // Filter Phone number
          (this.getCustomerPhone(company)
            .toLowerCase()
            .includes(this.searchPhoneNumber.toLowerCase()) ||
            this.searchPhoneNumber === "") &&
          // Filter Email
          (this.getCustomerEmail(company)
            .toLowerCase()
            .includes(this.searchEmail.toLowerCase()) ||
            this.searchEmail === "") &&
          // Filter Company Type
          (this.getRICompanyType(company)
            .toLowerCase()
            .includes(this.searchCompanyType.toLowerCase()) ||
            this.searchCompanyType === "")
        );
      });
    },

    selectedMessage() {
      return counter => {
        if (counter === 1) {
          return "1 item selected";
        } else {
          return `${counter} items selected`;
        }
      };
    }
  },

  methods: {
    ...mapActions("quickbooks/company", ["createCompany", "updateCompany"]),

    checkBoxChange(checked, id) {
      let item = this.companiesFiltered.find(
        item => item.Id === id || item.id === id
      );
      Vue.set(item, "checked", checked);
      this.selectedItemCount = this.companiesFiltered.filter(
        item => item.checked === true
      ).length;
    },

    checkBoxAllChange(checked) {
      this.companiesFiltered.map(item => {
        Vue.set(item, "checked", checked);
      });
      this.selectedItemCount = this.companiesFiltered.filter(
        item => item.checked === true
      ).length;
    },

    handleAction() {
      if (this.importData) {
        this.importDataToRooferIntel();
      } else if (this.exportData) {
        this.exportDataToQuickbooks();
      } else {
        // eslint-disable-next-line no-console
        console.info("Action not handle!");
      }
    },

    changeCompanyType(companyType, companyId) {
      (
        this.companies.find(item => item.Id === companyId) || {}
      ).companyType = companyType;
    },

    importDataToRooferIntel() {
      let companies = this.companiesFiltered.filter(
        item => item.checked === true
      );

      if (this.invalidCompanyType(companies)) {
        this.$ri.dialog.openInfoDialog({
          title: "Quickbooks Integration",
          content: "Please select company type before import.",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
        return;
      }

      let ref = [];

      for (const company of companies) {
        let mapedCompany = this.mapQuickbooksToRooferIntelCompany(company);
        ref.push(this.createCompany(mapedCompany));
      }

      this.$f7.preloader.show();
      Promise.all(ref).then(() => {
        this.$f7.preloader.hide();
        this.$emit("onDone");
      });
    },

    exportDataToQuickbooks() {
      this.$f7.preloader.show();

      quickbooksService.checkAuth().then(data => {
        if (data.success) {
          let companies = this.companiesFiltered.filter(
            item => item.checked === true
          );

          let ref = [];
          let itemFails = [];
          let successCount = 0;

          for (const company of companies) {
            let mapedCompany = this.mapRooferIntelToQuickbooksCompany(company);

            ref.push(
              quickbooksService
                .createCustomer({
                  customer: mapedCompany
                })
                .then(returnCompany => {
                  successCount++;
                  return this.updateCompany({
                    id: company.id,
                    doc: { quickbooksId: returnCompany.Id }
                  });
                })
                .catch(err => {
                  itemFails.push({
                    companyName: company.companyName,
                    messageError: err.message
                  });
                })
            );
          }

          Promise.all(ref).then(() => {
            this.$f7.preloader.hide();

            let messageFail = "<ul>";
            itemFails.forEach(r => {
              messageFail += `<li>${r.companyName}: ${r.messageError}</li>`;
            });
            messageFail += "</ul>";

            const messageSuccessAndFail = `
              ${successCount} records successfully exported!
              <br>
              ${itemFails.length} records failed to exported. Here are some possible reasons:
              <br>
              ${messageFail}
            `;

            const message =
              successCount === companies.length
                ? `${successCount} records successfully exported!`
                : itemFails.length === companies.length
                ? `
                    ${itemFails.length} records failed to exported. Here are some possible reasons:
                      <br>
                    ${messageFail}
                  `
                : messageSuccessAndFail;
            this.$ri.dialog.openInfoDialog({
              title: "Export Results",
              content: message,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  this.$emit("onDone");
                }
              }
            });
          });
        }
      });
    },

    mapQuickbooksToRooferIntelCompany(company) {
      let mapedCompany = {
        id: "",
        quickbooksId: company.Id,
        companyName: company.CompanyName || company.DisplayName,
        companyTypes: [company.companyType || COMPANY_TYPE_GENERAL_CONTRACTOR],
        addresses: [
          {
            code: "main",
            title: "Main",
            value: this.getCustomerAddress(company)
          }
        ],
        others: [
          {
            code: "email",
            title: "Email",
            value: this.getCustomerEmail(company)
          }
        ],
        phones: [
          { code: "main", title: "Main", value: this.getCustomerPhone(company) }
        ]
      };
      return mapedCompany;
    },

    mapRooferIntelToQuickbooksCompany(company) {
      const addr = this.getCustomerAddress(company);
      let addrArray = addr !== "" ? addr.split(",") : [];

      let addrArray2 = ["", "", "", ""];
      if (!_.isEmpty(addrArray)) {
        addrArray2 = [
          addrArray[0],
          addrArray[1],
          ...(addrArray[2].trim().split(" ") || [])
        ];
      }

      let mapedCompany = {
        PrimaryEmailAddr: {
          Address: this.getCustomerEmail(company)
        },
        DisplayName: company.companyName,

        PrimaryPhone: {
          FreeFormNumber: this.formatQuickbooksPhoneNumber(
            this.getCustomerPhone(company)
          )
        },
        CompanyName: company.companyName,
        BillAddr: {
          CountrySubDivisionCode: addrArray2[2].trim(),
          City: addrArray2[1].trim(),
          PostalCode: addrArray2[3].trim(),
          Line1: addrArray2[0].trim()
        }
      };
      return mapedCompany;
    },

    formatQuickbooksPhoneNumber(phoneNumber) {
      if (_.isEmpty(phoneNumber)) return "";
      let arr = phoneNumber.split(" ")[1].split("-");
      return `(${arr[0]}) ${arr[1]}-${arr[2]}`;
    },

    invalidCompanyType(companies) {
      for (const company of companies) {
        if (_.isEmpty(company.companyType)) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-cell {
  color: var(--f7-theme-color);
}
.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 232px);
  overflow: hidden;
}
thead th {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
thead th::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
</style>
