import {
  FirebaseActions,
  uploadFile,
  removeFile
} from "../../services/firebase.service";
import * as types from "./types";
import * as constant from "../../utility/const";
import algoliaService from "@/services/algolia.service";
import { searchClient } from "@/services/search.service";

export default tenant => {
  const expenseActions = new FirebaseActions(
    `/system_client/${tenant}/employee_expenses`,
    "expense"
  );

  const expenseIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  return {
    bindExpense: expenseActions.bindDocument,
    unbindExpense: expenseActions.unbindDocument,

    getExpenseListBys: expenseActions.getDocumentBys,

    addExpense: expenseActions.createDocument,
    saveExpense: expenseActions.updateDocument,
    removeExpense: expenseActions.deleteDocument,

    // eslint-disable-next-line no-unused-vars
    async uploadAttachment({ commit }, { fullPath, file }) {
      return uploadFile(fullPath, file);
    },

    // eslint-disable-next-line no-unused-vars
    async removeAttachment({ commit }, fullPath) {
      return removeFile(fullPath);
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    async searchExpense(
      { state, commit, getters },
      { attributesToRetrieve, restrictSearchableAttributes }
    ) {
      const index = expenseIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_EMPLOYEE_EXPENSES}`,
        attributesToRetrieve: constant.EXPENSE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.EXPENSE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreExpense(
      { state, commit, getters },
      { attributesToRetrieve, restrictSearchableAttributes, page }
    ) {
      const index = expenseIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_EMPLOYEE_EXPENSES}`,
        attributesToRetrieve: constant.EXPENSE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.EXPENSE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setExpenseNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },
    setExpenseSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },
    setPayeeId({ commit }, payload) {
      commit(types.SET_PAYEE_ID, payload);
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async createExpense({ dispatch }, expense) {
      const id = await dispatch("addExpense", expense);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "employee_expenses",
        id
      });
      await dispatch("searchExpense", {});

      return id;
    },

    async updateExpense({ dispatch }, { id, doc }) {
      await dispatch("saveExpense", { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "employee_expenses",
        id
      });
      await dispatch("searchExpense", {});

      return id;
    },

    async deleteExpense({ dispatch }, id) {
      await dispatch("saveExpense", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "employee_expenses",
        id
      });
      await dispatch("searchExpense", {});
    }
  };
};
