<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-show="!$device.desktop"
          @click="handleBack"
          icon-f7="chevron_left"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title>{{ (proposalTemplate || {}).name }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="onSave">Save</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="page-content" ref="pageList">
      <page
        v-for="(page, index) in (proposalTemplate || {}).pages"
        :key="index"
        :data="page"
        :index="index"
        @change="$event => updatePage($event, index)"
        @addNewPage="addNewPage"
        @removePage="removePage"
        :isTemplate="true"
        :isLastPage="index === (proposalTemplate || {}).pages.length - 1"
      ></page>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Page from "../components/template-components/Page.vue";
import _ from "lodash";
import {
  BUILDING_DESCRIPTION_PAGE,
  BUILDING_PRODUCT_PAGE,
  PRODUCT_TABLE_TYPE,
  FOOTER_TYPE,
  HEADER_TYPE,
  IMAGE_PAGE,
  IMAGE_STATUS_TYPE,
  TEXT_EDITOR_TYPE,
  TEXT_EDITOR_PAGE,
  TEXT_EDITOR_RESIZABLE_TYPE
} from "../utility/const";

export default {
  components: {
    Page
  },
  created() {
    let id = this.$f7route.params.id;
    this.$f7.preloader.show();
    this.bindProposalTemplate(id).then(() => {
      this.$f7.preloader.hide();
    });
  },
  // destroyed() {
  //   this.unbindProposalTemplate();
  // },
  computed: {
    ...mapGetters({
      proposalTemplate: "proposal/template/proposalTemplate"
    })
  },
  methods: {
    ...mapActions({
      bindProposalTemplate: "proposal/template/bindProposalTemplate",
      unbindProposalTemplate: "proposal/template/unbindProposalTemplate",
      updateProposalTemplate: "proposal/template/updateProposalTemplate",
      setProposalTemplateField: "proposal/template/setProposalTemplateField"
    }),
    updatePage($event, index) {
      let pages = _.cloneDeep((this.proposalTemplate || {}).pages);
      pages[index].components = $event;
      this.setProposalTemplateField({ fieldName: "pages", value: pages });
    },
    removePage(indexOfItem) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Delete Page " + (indexOfItem + 1),
        content: "Are you sure you want to delete this page?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            let pages = _.cloneDeep((app.proposalTemplate || {}).pages);
            pages.splice(indexOfItem, 1);
            app.setProposalTemplateField({
              fieldName: "pages",
              value: pages
            });
          }
        }
      });
    },
    addNewPage({ pageType, index }) {
      let pages = _.cloneDeep((this.proposalTemplate || {}).pages);
      let newPage = this.prepareNewPage(pageType, pages);
      pages.splice(index + 1, 0, newPage);
      this.setProposalTemplateField({ fieldName: "pages", value: pages });
      switch (pageType) {
        case TEXT_EDITOR_PAGE:
        case BUILDING_DESCRIPTION_PAGE:
          setTimeout(() => this.focusOnNewPage(index), 1000);
          break;
        case BUILDING_PRODUCT_PAGE:
          break;
        case IMAGE_PAGE:
          break;
      }
    },
    prepareNewPage(pageType, pages) {
      let page = _.cloneDeep(pages[0]);
      let components = _.cloneDeep(page.components);
      components = components.filter(
        r => r.type == HEADER_TYPE || r.type == FOOTER_TYPE
      );

      let initComponents = [];
      switch (pageType) {
        case TEXT_EDITOR_PAGE:
        case BUILDING_DESCRIPTION_PAGE:
          initComponents = this.prepareTextEditorPage();
          break;
        case BUILDING_PRODUCT_PAGE:
          initComponents = this.prepareBuildingProductPage();
          break;
        case IMAGE_PAGE:
          initComponents = this.prepareImagePage();
          break;
      }

      components.splice(1, 0, ...initComponents);
      page.components = components;
      page.name = pageType;

      return page;
    },
    prepareTextEditorPage() {
      return [
        {
          type: TEXT_EDITOR_TYPE,
          data: {
            value: ""
          }
        }
      ];
    },
    prepareBuildingProductPage() {
      const titleComponent = {
        type: TEXT_EDITOR_RESIZABLE_TYPE,
        data: {
          value: ""
        }
      };
      const tableComponent = {
        type: PRODUCT_TABLE_TYPE,
        data: {
          estimateId: "",
          buildingId: ""
        }
      };
      return [titleComponent, tableComponent];
    },
    prepareImagePage() {
      const titleComponent = {
        type: TEXT_EDITOR_RESIZABLE_TYPE,
        data: {
          value: ""
        }
      };
      const imageComponent = {
        type: IMAGE_STATUS_TYPE,
        data: {
          images: []
        }
      };
      return [titleComponent, imageComponent];
    },
    focusOnNewPage(index) {
      let page = this.$refs.pageList.getElementsByClassName("page-form")[
        index + 1
      ];
      let textEditor = page.getElementsByClassName("text-editor-content");
      if (textEditor && textEditor.length > 1) {
        textEditor[1].focus();
      }
    },
    onSave() {
      this.$f7.preloader.show();
      this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: {
          ...this.proposalTemplate
        }
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    handleBack() {
      this.$f7router.navigate("/proposal", {
        pushState: true,
        reloadAll: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  position: relative;
}
</style>
