<template>
  <div class="cell-input">
    <suggestions
      class="v-suggestions_mine"
      v-model="query"
      :options="options"
      :onInputChange="onInputChange"
      :onItemSelected="onItemSelected"
      :readonly="readonly"
      suggestionsWidth="850px"
      @onBlur="onBlur"
    >
      <div slot="item" slot-scope="props" class="row v-suggestions_row">
        <div class="col photo">
          <div
            v-if="(props.item.photos || []).length > 0"
            class="image-preview cursor-pointer"
            @click="clickPhoto(props.item.id)"
          >
            <img
              class="image-show"
              :src="((props.item.photos || [])[0] || {}).thumbnailUrl"
            />
          </div>
          <div
            v-else
            class="image-preview cursor-pointer"
            :style="`background-image: url(${NO_IMAGE_AVAILABLE})`"
            @click="onAddImage(props.item.id)"
          ></div>

          <!-- <f7-photo-browser
            :photos="props.item.photos || []"
            theme="dark"
            :ref="`pageDark_${props.item.id}`"
            type="popup"
          ></f7-photo-browser> -->
        </div>
        <div class="col sku">{{ props.item.sku }}</div>
        <div class="col product-item">{{ props.item.productItem }}</div>
        <div class="col color-id">{{ props.item.color }}</div>
        <div class="col vendor">{{ vendorName(props.item.vendorId) }}</div>
        <div class="col manufacturer">{{ props.item.manufacturer }}</div>
        <div class="col unitSize">{{ props.item.unitSize }}</div>
        <div class="col uom">{{ props.item.uom }}</div>
        <div class="col price">${{ (props.item.price || 0).toFixed(2) }}</div>
        <div class="col size">{{ props.item.size }}</div>

        <!-- Product Item, Color, Vendor, Manufacturer, UoM, Price -->
      </div>
    </suggestions>
  </div>
</template>

<script>
import Suggestions from "@/components/suggestions/Suggestions.vue";
import { mapGetters } from "vuex";
import { NO_IMAGE_AVAILABLE } from "@/utility/const";

export default {
  components: { Suggestions },
  props: {
    placeholder: String,
    readonly: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    value: { type: String, default: "" }
  },

  data() {
    return {
      editing: false,
      currentValue: "",
      options: {},
      query: "",
      NO_IMAGE_AVAILABLE
    };
  },

  watch: {
    value(newValue) {
      this.query = newValue;
    }
  },

  computed: {
    ...mapGetters({
      vendors: "estimate/estimate-page/estimate/vendor/objectList"
    })
  },

  created() {
    this.query = this.value;
  },

  methods: {
    onAddImage(productId) {
      this.$emit("onClickAddProductPhoto", productId);
    },

    clickPhoto(id) {
      this.$refs[`pageDark_${id}`].open();
    },

    vendorName(id) {
      const vendor = this.vendors.find(r => r.id === id);
      return (vendor || {}).companyName;
    },
    onBlur() {
      this.query = this.value;
      // enable scroll table
      if (this.$device.desktop || this.$device.ipad) {
        this.$parent.$el
          .querySelector(".card.data-table")
          .classList.remove("overflow-visible");
        this.$root.$el
          .querySelector(".building-estimate-detail .page-content")
          .classList.remove("overflowX-hidden");
      }
    },

    onInputChange(query) {
      return this.data.filter(product => {
        return (
          (product.sku || "").toLowerCase().includes(query.toLowerCase()) ||
          (product.productItem || "")
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          (product.manufacturer || "")
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          (product.uom || "").toLowerCase().includes(query.toLowerCase()) ||
          (product.price || "").toString().includes(query.toLowerCase())
        );
      });
    },

    onItemSelected(item) {
      this.$emit("selected", { target: { value: item } });
    },

    clear() {
      this.$emit("clear");
    }
  }
};
</script>

<style lang="scss" scoped>
// .cell-input ::v-deep input:read-only {
//   border: none !important;
//   border-radius: none !important;
// }

// .cell-input ::v-deep .input-focused {
//   background-color: var(--f7-page-bg-color);
//   border: 1px solid #ccc;
//   border-radius: 4px;
// }

// .cell-input ::v-deep input:hover {
//   background-color: var(--f7-page-bg-color);
// }

// .cell-input ::v-deep input {
//   text-align: left;
//   float: left;
// }

// .cell-input ::v-deep img.autocomplete__icon {
//   display: none;
// }

// .cell-input ::v-deep .autocomplete__icon {
//   height: 14px;
//   width: 14px;
//   margin-bottom: 3px;
// }

.v-suggestions_mine ::v-deep input {
  border: 1px solid #eee;
  border-radius: 3px;
  padding-right: 16px;
  width: 100%;
}

.v-suggestions_mine ::v-deep .items {
  border: 1px solid rgb(161, 161, 161);
}

.v-suggestions_mine ::v-deep .suggestions {
  width: 850px;
}
.v-suggestions_mine ::v-deep .suggestions ul.items {
  max-height: 350px;
  overflow: auto;
}

.v-suggestions_row ::v-deep .photo,
.v-suggestions_row ::v-deep .sku {
  width: 70px;
}
.v-suggestions_row ::v-deep .product-item {
  width: 150px;
}
.v-suggestions_row ::v-deep .color-id {
  width: 70px;
}
.v-suggestions_row ::v-deep .vendor {
  width: 120px;
}
.v-suggestions_row ::v-deep .manufacturer {
  width: 120px;
}
.v-suggestions_row ::v-deep .uom {
  width: 50px;
}
.v-suggestions_row ::v-deep .price {
  width: 70px;
  text-align: right;
}
.v-suggestions_row ::v-deep .size {
  width: 70px;
  text-align: center;
}
.v-suggestions_row ::v-deep .col {
  word-break: break-word;
}
.image-preview {
  border-radius: 4px;
  width: 70px;
  height: 70px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
</style>
