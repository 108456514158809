<template>
  <f7-popup :opened="popupOpened" @popup:closed="handlePopupClosed">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Project Photos</f7-nav-title>
        <f7-nav-right>
          <f7-link v-if="selectedPhotoIds.length > 0" @click="cancelSelection"
            >Cancel</f7-link
          >
        </f7-nav-right>
      </f7-navbar>

      <div v-show="selectedPhotoIds.length > 0" style="padding: 8px 0 0 16px;">
        {{
          selectedPhotoIds.length > 0
            ? `${selectedPhotoIds.length} photos selected`
            : ""
        }}
      </div>

      <photos
        :projectPhotoListGroup="projectPhotoListGroup"
        :selectionMode="selectionMode"
        :selectedPhotoIds="selectedPhotoIds"
        :loading="loadingPhoto"
        :hoveredPhotoIds="hoveredPhotoIds"
        @clickPhoto="clickPhoto"
        @clickEdit="clickEdit"
        @handleSelected="handleSelected"
        @handleSelectAllDay="handleSelectAllDay"
      ></photos>

      <photo-browser
        :photos="photoList"
        theme="dark"
        ref="pageDark"
      ></photo-browser>
      <edit-photo-popup ref="editPhoto"> </edit-photo-popup>
      <delete-photo
        v-if="selectedPhotoIds.length > 0"
        slot="fixed"
        :class="{ 'btn-photo': $device.desktop }"
        :selectedIds="selectedPhotoIds"
        @cancelSelect="cancelSelection"
      ></delete-photo>
    </f7-page>
  </f7-popup>
</template>

<script>
import Photos from "../../components/photo/components/Photos.vue";
import EditPhotoPopup from "../../components/photo/components/EditPhotoPopup.vue";
import PhotoBrowser from "../../components/photo/components/PhotoBrowser.vue";
import DeletePhoto from "../../components/photo/buttons/DeletePhoto.vue";
import { mapActions, mapGetters } from "vuex";
import { sortLatest } from "../../../../utility/date-time-tool";
import { groupBy } from "../../../../utility/filter-tools";
import moment from "moment";

export default {
  props: {
    loadingPhoto: [Object, Boolean],
    photos: Array,
    albumId: String
  },
  components: {
    Photos,
    EditPhotoPopup,
    PhotoBrowser,
    DeletePhoto
  },

  data: () => {
    return { popupOpened: false, hoveredPhotoIds: [] };
  },

  computed: {
    ...mapGetters("dashboard/project", ["card"]),
    ...mapGetters("dashboard/photo", ["selectionMode", "selectedPhotoIds"]),

    projectPhotoListGroup() {
      return groupBy(sortLatest(this.photos), i =>
        i.createdAt
          ? moment(i.createdAt.toDate()).format("dddd, MMMM Do YYYY")
          : ""
      );
    },

    photoList() {
      return this.photos.map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments
      }));
    }
  },

  methods: {
    ...mapActions("dashboard/photo", [
      "changeSelectionMode",
      "cancelSelectionMode",
      "onSelect",
      "resetPhotoData"
    ]),
    open() {
      this.popupOpened = true;
    },

    handlePopupClosed() {
      this.popupOpened = false;
      this.hoveredPhotoIds = [];
      this.resetPhotoData();
    },

    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.photoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    clickEdit(id) {
      const photo = this.photos.find(i => i.id === id);
      this.$refs.editPhoto.open(photo);
    },
    onSelection() {
      this.changeSelectionMode(true);
    },
    cancelSelection() {
      this.cancelSelectionMode();
    },
    handleSelected(id, isSelectPhoto = false) {
      if (this.selectedPhotoIds.length === 0 && id === null) {
        this.changeSelectionMode(false);
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.selectedPhotoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.changeSelectionMode(true);
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    handleSelectAllDay(data) {
      const photosIds = (data.ids || []).filter(
        i => !this.selectedPhotoIds.includes(i)
      );
      if (data.event) {
        for (const id of photosIds) {
          this.onSelect(id);
        }
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photosIds);
        this.changeSelectionMode(true);
      } else {
        for (const id of photosIds) {
          this.onSelect(id);
        }
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !data.ids.includes(i)
        );
        this.cancelSelectionMode();
      }
    }
  }
};
</script>

<style></style>
