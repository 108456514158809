import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { uuid } from "vue-uuid";
import { auth } from "../../../services/firebase.service";
import axiosService from "@/services/axios.service";
import {
  getCleatStretchOut,
  getBackCleatStretchOut,
  getStretchOut,
  convertStringToValue
} from "./stretch-out-helper";

import {
  roundUpNumber,
  getWorkType,
  getBuildingInfoDefault
} from "./calculation";

import { ALL_ROOFTYPE_ESTIMATE_TEMPLATE } from "./const";

export default {
  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "building",
      "currentBuilding"
    ]),
    ...mapGetters("common/app-constant", ["roofTypeBy"]),
    ...mapGetters({
      subCategories: "estimate/estimate-page/estimate/sub-category/objectList",
      categories: "estimate/estimate-page/estimate/category/objectList"
    }),
    ...mapGetters("estimate/estimate-page/stackct", ["stackTakeOffByPages"]),
    ...mapGetters({
      setting: "setting/app/system/setting"
    }),
    ...mapGetters("estimate/estimate-page/price-list", {
      priceListObject: "priceList",
      currentPriceList: "currentPriceList"
    }),
    ...mapGetters("estimate/estimate-page/group", ["adminGroups"]),
    ...mapGetters("estimate/estimate-page/user", ["users"]),

    ...mapGetters('common/app-constant', ['tenantId']),

    takeoffListOptions() {
      return (
        this.stackTakeOffByPages(this.currentBuilding.stackPageIds) || []
      ).map(r => ({
        id: r.TakeoffTemplateId,
        value: this.getMeasurementValue(r),
        type: r.MeasurementName,
        displayName: r.Name,
        displayValue: `${this.getMeasurementValue(r)} ${this.convertDisplayUnit(
          r.DisplayUnit
        )}`,
        display: `${r.Name} (${this.getMeasurementValue(
          r
        )} ${this.convertDisplayUnit(r.DisplayUnit)})`
      }));
    },
    convertDisplayUnit() {
      return unit => {
        if (unit === "Sq Ft") return "SQ";
        if (unit === "Ln Ft") return "LF";
        return unit || "";
      };
    },
    getMeasurementValue() {
      return r => {
        if (r.MeasurementName === "Count") {
          return r.TotalCount;
        }
        if (r.MeasurementName.toLowerCase().includes("area")) {
          return (r.TotalMeasuredQty / 100).toFixed(2); // 1 SQ equal to 100 SQ Ft
        }
        return r.TotalMeasuredQty.toFixed(2);
      };
    },

    tableData() {
      return sectionId => {
        return (
          (
            (this.currentBuilding.productData || []).find(
              item => item.sectionId === sectionId
            ) || {}
          ).productList || []
        );
      };
    },

    estimateTemplate() {
      // TODO: This lines of code must be remove after move 'all-roof-type' to db
      if (this.currentBuilding.roofType === "all-roof-type") {
        return ALL_ROOFTYPE_ESTIMATE_TEMPLATE;
      }
      return (
        this.roofTypeBy(this.currentBuilding.roofType).estimateTemplate || []
      );
    },

    templateSection() {
      return (roofType, sectionId) => {
        if (roofType === "all-roof-type") {
          // if (this.roofType === "all-roof-type") {
          return (
            ALL_ROOFTYPE_ESTIMATE_TEMPLATE.find(
              item => item.sectionId === sectionId
            ) || {}
          );
          // }
        }
        return (
          (this.roofTypeBy(roofType).estimateTemplate || []).find(
            item => item.sectionId === sectionId
          ) || {}
        );
      };
    },

    subCategoryName() {
      return subCategoryId => {
        return (
          (this.subCategories.find(item => item.id === subCategoryId) || {})
            .name || ""
        );
      };
    },
    categoryBy() {
      return categoryId => {
        return this.categories.find(item => item.id === categoryId) || {};
      };
    },

    sectionBy() {
      return categoryName => {
        return (
          this.estimateTemplate.find(item => item.category === categoryName) ||
          {}
        );
      };
    },
    productBuildingData() {
      const products = [];
      for (const section of this.currentBuilding.productData || []) {
        if (!_.isEmpty(section.productList)) {
          products.push(
            ...section.productList.map(item => {
              return { ...item, sectionId: section.sectionId };
            })
          );
        }
      }

      return products;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "calculateProductItemList",
      "calculateProductQty",

      "calculateMetalProductItemList",
      "calculateMetaProductQty",

      "updateBuilding",
      "setCurrentBuildingValue",

      "updateEstimate",
      "deleteBuilding",
      "createBuilding",
      "getBuilding",
      "deleteEstimateHistoryBys",
      "uploadDiagramPhoto",
      "removeDiagramPhoto"
    ]),

    ...mapActions("estimate/estimate-page/estimate", []),

    ...mapActions({
      bindSetting: "setting/app/system/bindSetting"
    }),
    ...mapActions("estimate/estimate-page/group", ["getAdminGroups"]),
    ...mapActions("estimate/estimate-page/user", ["getUserList"]),
    ...mapActions("estimate/estimate-page/product-item", ["getProduct"]),
    ...mapActions("estimate/estimate-page/product-default", [
      "getAllDefaultProductIds"
    ]),

    // Change product of table
    changeProductItem({ roofType, sectionId, oldProduct, newProduct, index }) {
      const sectionProducts = _.cloneDeep(
        (
          this.currentBuilding.productData.find(
            item => item.sectionId === sectionId
          ) || {}
        ).productList || []
      );

      if (_.isEmpty(sectionProducts)) {
        return;
      }

      let currentProdIndex = sectionProducts.findIndex(
        (item, pIndex) =>
          item.id === oldProduct.id && (index === undefined || pIndex === index)
      );

      if (currentProdIndex < 0) {
        return;
      }

      Vue.set(sectionProducts, currentProdIndex, newProduct);

      return this.calculateProductQty({
        building: this.currentBuilding,
        sectionProducts: sectionProducts
      }).then(sectionProducts => {
        return this.saveProductData({
          sectionId,
          productList: sectionProducts,
          category: this.templateSection(roofType, sectionId).category,
          index: this.templateSection(roofType, sectionId).index
        });
      });
    },
    // Change Metal product of table
    changeMetalProductItem(assembly, { oldProduct, newProduct, index }) {
      const currentProdList = assembly.productList || [];
      if (_.isEmpty(currentProdList)) {
        return;
      }

      let currentProdIndex = currentProdList.findIndex(
        (item, pIndex) =>
          item.id === oldProduct.id && (index === undefined || pIndex === index)
      );
      if (currentProdIndex < 0) {
        return;
      }
      Vue.set(currentProdList, currentProdIndex, newProduct);
      assembly.productList = currentProdList;
      return this.calculateMetaProductQty({
        assembly,
        building: this.currentBuilding
      });
    },

    updateProductItem({ roofType, sectionId, product, index }) {
      const sectionProducts = _.cloneDeep(
        (
          this.currentBuilding.productData.find(
            item => item.sectionId === sectionId
          ) || {}
        ).productList || []
      );

      if (_.isEmpty(sectionProducts)) {
        return;
      }

      let currentProdIndex = sectionProducts.findIndex(
        (item, pIndex) =>
          item.id === product.id && (index === undefined || pIndex === index)
      );
      if (currentProdIndex < 0) {
        return;
      }
      Vue.set(sectionProducts, currentProdIndex, product);

      return this.saveProductData({
        sectionId,
        productList: sectionProducts,
        category: this.templateSection(roofType, sectionId).category,
        index: this.templateSection(roofType, sectionId).index
      });
    },

    processOverrideProduct(currentList, newList) {
      // return newList;
      const result = [];
      for (const newProd of newList) {
        if (newProd) {
          // For the case new product of existing sub-category
          const currentProd = currentList.find(
            item =>
              // !item.isAddManually &&
              item.subCategoryId === newProd.subCategoryId
          );
          // Not exist current product of this sub-category
          if (_.isEmpty(currentProd)) {
            result.push(newProd);
          }
          // exist product of this sub-category but not new product
          else if (newProd.id != currentProd.id) {
            // Change product
            result.push(newProd);
          }
          // exist product of this sub-category and new product is current product:
          // check wasterFactorOverride and priceOverride
          else if (newProd.id === currentProd.id) {
            // const prod = _.cloneDeep(currentProd);
            if (currentProd.priceOverride) {
              newProd.price = currentProd.price;
            }

            if (currentProd.wasterFactorOverride) {
              newProd.wasterFactor = currentProd.wasterFactor;
            }

            result.push(newProd);
          }
        }
      }

      return result;
    },

    // update products (both items and quantity) of section
    updateProducts({ roofType, sectionId }) {
      const section = _.cloneDeep(this.currentBuilding.productData || []).find(
        item => item.sectionId === sectionId
      );

      const currentProductList = section ? section.productList || [] : [];

      const self = this;
      return this.calculateProductItemList({ roofType, sectionId })
        .then(newProductList => {
          if (_.isEmpty(newProductList)) {
            return [];
          }

          // Process the override items
          const sectionProducts = this.processOverrideProduct(
            currentProductList,
            newProductList
          );

          return this.calculateProductQty({
            building: this.currentBuilding,
            sectionProducts
          }).then(sectionProducts => {
            return sectionProducts;
          });
        })
        .then(sectionProducts => {
          const productOfSection = (
            self.currentBuilding.productData || []
          ).find(section => section.sectionId === sectionId);

          const addManuallyProducts = (
            (productOfSection || {}).productList || []
          ).filter(product => product.isAddManually);

          // restore added products by manually
          if (!_.isEmpty(addManuallyProducts)) {
            sectionProducts.push(...addManuallyProducts);
          }

          return this.saveProductData({
            sectionId,
            productList: sectionProducts || [],
            category: this.templateSection(roofType, sectionId).category,
            index: this.templateSection(roofType, sectionId).index
          });
        });
    },

    updateMetalProducts({ roofType, sectionId, assembly }) {
      // console.log("updateMetalProducts", { roofType, sectionId, assembly });
      const currentProdList = assembly.productList || [];

      const addManuallyProducts = currentProdList.filter(
        product => product.isAddManually
      );

      return this.calculateMetalProductItemList({
        roofType,
        sectionId,
        assembly
      }).then(assembly => {
        const newProdList = assembly.productList || [];

        // restore added products by manually
        if (!_.isEmpty(addManuallyProducts)) {
          newProdList.push(...addManuallyProducts);
        }

        assembly.productList = this.processOverrideProduct(
          currentProdList,
          newProdList
        );

        return this.calculateMetaProductQty({
          assembly,
          building: this.currentBuilding
        });
      });
    },

    // update quantity for products of section
    updateQuantity({ roofType, sectionId }) {
      const productData = _.cloneDeep(this.currentBuilding.productData || []);

      let section = productData.find(item => item.sectionId === sectionId);
      if (_.isEmpty(section)) {
        return;
      }

      const sectionProducts = _.cloneDeep(section.productList || []);

      return this.calculateProductQty({
        building: this.currentBuilding,
        sectionProducts: sectionProducts
      }).then(sectionProducts => {
        return this.saveProductData({
          sectionId,
          productList: sectionProducts || [],
          category: this.templateSection(roofType, sectionId).category,
          index: this.templateSection(roofType, sectionId).index
        });
      });
    },

    async getProductListByIds(productIds) {
      const refs = [];
      for (const id of productIds) {
        refs.push(this.getProduct(id));
      }
      const products = await Promise.all(refs);
      return products.map(orgProduct => {
        const sectionId = this.sectionBy(
          this.categoryBy(orgProduct.categoryId || "").name
        ).sectionId;

        if (!sectionId) {
          return {};
        }
        const refProduct = (
          (this.currentPriceList || {}).productRefs || []
        ).find(item => item.productId === orgProduct.id);

        return {
          ...orgProduct,
          sectionId: sectionId,
          vendorPrice: !Number.isNaN(Number.parseFloat(refProduct?.vendorPrice))
            ? refProduct.vendorPrice
            : !Number.isNaN(Number.parseFloat(refProduct?.price))
              ? refProduct.price
              : orgProduct.price,
          markup: refProduct?.markup
            ? refProduct.markup
            : { value: 0, type: "percent" },
          price: !Number.isNaN(Number.parseFloat(refProduct?.price))
            ? refProduct.price
            : orgProduct.price,
          internalNote: refProduct?.internalNote || "",
          proposalNote: refProduct?.proposalNote || ""
        };
      });
    },

    async saveDefaultProducts() {
      // get default products
      const productIds = await this.getAllDefaultProductIds({
        priceListId: this.currentPriceList.id,
        roofType: this.currentBuilding.roofType,
        vendorIds: this.currentBuilding.vendorIds
      });
      let products = await this.getProductListByIds(productIds.map(r => r.id));
      products = products.filter(r => !_.isEmpty(r));

      for (const product of products) {
        const orderQty = this.calculateOrderQty(1, product);

        const temp = {
          ...product,
          sectionId: product.sectionId,
          vendorPrice: product.vendorPrice,
          markup: product.markup,
          price: product.price,
          internalNote: product.internalNote,
          proposalNote: product.proposalNote,
          isAddManually: true,
          actualQty: 1,
          orderQty
        };

        await this.saveProductItemSection(temp);
      }
    },

    async saveProductItemSection(productItem) {
      const sectionProducts =
        _.cloneDeep(
          this.productBuildingData.filter(
            item => item.sectionId === productItem.sectionId
          )
        ) || [];

      if (sectionProducts.some(r => r.id === productItem.id)) return;

      sectionProducts.push(productItem);

      const templateSection = this.templateSection(
        this.currentBuilding.roofType,
        productItem.sectionId
      );

      return await this.saveProductData({
        sectionId: productItem.sectionId,
        productList: sectionProducts,
        category: templateSection.category,
        index: templateSection.index
      });
    },

    // Save product data of a section to database
    saveProductData({ sectionId, productList, category, index }) {
      const productData = _.cloneDeep(this.currentBuilding.productData || []);

      // Save only this section
      let section = productData.find(item => item.sectionId === sectionId);

      if (_.isEmpty(section)) {
        productData.push({ sectionId, productList, category, index });
      } else {
        section.productList = productList;
      }
      return this.saveBuildingValue("productData", productData);
    },

    // save a field to db
    setField(fieldName, value, force = false) {
      if (force || this.currentBuilding[fieldName] !== value) {
        this.saveBuildingValue(fieldName, value);
      }
    },

    //  save a building property (prop of object) to database
    async saveBuildingValue(prop, value) {
      if (!_.isEmpty(this.estimate) && !_.isEmpty(this.building)) {
        this.setCurrentBuildingValue({ prop, value });

        return this.updateBuilding({
          estimateId: this.estimate.id,
          buildingId: this.building.id,
          building: {
            [prop]: value
          }
        });
      }
    },

    // save estimate field from building
    saveEstimateValueFromBuilding(prop, value) {
      return this.updateEstimate({
        id: this.estimate.id,
        doc: {
          [prop]: value
        }
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.log("[Save Estimate info]", error.message);
      });
    },

    showSavedMessage() {
      this.$f7.toast
        .create({
          text: "Building is saved!",
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 2000
        })
        .open();
    },

    onChangeProductItem(
      roofType,
      sectionId,
      { oldProduct, newProduct, index }
    ) {
      return this.changeProductItem({
        roofType,
        sectionId,
        oldProduct,
        newProduct,
        index
      });
    },

    distintProductListFromAssemblyList(assemblyList) {
      const productList = [];
      for (const assembly of assemblyList) {
        let index = 0;
        for (const product of assembly.productList || []) {
          index = productList.findIndex(prod => prod.id === product.id);

          if (index > -1) {
            productList[index].actualQty =
              (productList[index].actualQty || 0) + (product.actualQty || 0);

            const productSize =
              productList[index].unitSize * productList[index].unitPack;

            productList[index].orderQty = roundUpNumber(0)(
              (productList[index].actualQty / productSize) *
              (productList[index].wasterFactor || 1)
            );
          } else {
            productList.push(_.cloneDeep(product));
          }
        }
      }
      return productList;
    },

    addMetalProductToSectionProducts({ roofType, sectionId, assemblyList }) {
      const productList = this.distintProductListFromAssemblyList(assemblyList);

      // if (!_.isEmpty(productList)) { // do not check empty, if empty products will remove out of the product list
      return this.saveProductData({
        sectionId,
        productList,
        category: this.templateSection(roofType, sectionId).category,
        index: this.templateSection(roofType, sectionId).index
      });
      // }
    },

    updateAssemblyStreatchOut(assembly) {
      const stretchOut = getStretchOut.call(
        assembly.measurement || {},
        assembly.assemblyItem
      );
      assembly.stretchOut = parseFloat(
        convertStringToValue(stretchOut).toFixed(2)
      );
      const cleatStretchOut = getCleatStretchOut.call(
        assembly.measurement || {},
        assembly.assemblyItem
      );
      assembly.cleatStretchOut = parseFloat(
        convertStringToValue(cleatStretchOut).toFixed(2)
      );

      const backCleatStretchOut = getBackCleatStretchOut.call(
        assembly.measurement || {},
        assembly.assemblyItem
      );
      assembly.backCleatStretchOut = parseFloat(
        convertStringToValue(backCleatStretchOut).toFixed(2)
      );
    },

    changeTemplate({ index, value, roofType, sectionId }) {
      const self = this;
      const func = (assembly, data) => {
        assembly.id = data.id;
        assembly.measurement = _.cloneDeep(data.measurement);
        assembly.canvasComponent = data.canvasComponent || "";
        assembly.formComponent = data.formComponent || "";
        assembly.numberOfBend = data.numberOfBend || 0;
        assembly.numberOfCleat = data.numberOfCleat || 0;
        assembly.cleatNumberOfBend = data.cleatNumberOfBend || 0;

        assembly.templateName = data.templateName || "";
        assembly.drawingName = data.drawingName || "";
        assembly.drawingNumber = data.drawingNumber || "";

        assembly.assemblyItem = data.templateCode || ""; // This is template code
      };
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      func(assemblyList[index], value);

      this.photoSelected = "";

      this.updateAssemblyStreatchOut(assemblyList[index]);

      return this.updateMetalProducts({
        roofType,
        sectionId,
        assembly: assemblyList[index]
      })
        .then(() => {
          return self.saveBuildingValue("assemblyList", assemblyList);
        })
        .then(() => {
          return self.drawEditPopupAssemply(assemblyList[index], index);
        })
        .then(() => {
          return self.addMetalProductToSectionProducts({
            roofType,
            sectionId,
            assemblyList
          });
        })
        .then(() => {
          return self.saveDiagramUrl(index);
        });
    },

    checkMetalStretchOut(assembly) {
      const stretchOut = assembly.stretchOut || 0;
      // console.log("checkMetalStretchOut", stretchOut);
      const productList = assembly.productList || [];
      const mainMetalProds = productList.filter(
        item => this.subCategoryName(item.subCategoryId) === "Assembly Metal"
      );

      for (const prod of mainMetalProds) {
        if (!_.isEmpty(prod.dimension)) {
          const widthFeet = (prod.dimension.split("x") || [])[0] || "";
          const width = parseFloat(widthFeet) * 12; // Convert to inch

          if (stretchOut > width) {
            const message = `The input value exceeds ${width} inches, which is the maximum width of a metal sheet. Please reenter the input.`;
            this.$ri.dialog.openWarningDialog({
              title: "Metal and Edge Warning!",
              content: message,
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              }
            });
            return;
          }
        }
      }
    },

    checkCleatStretchOut(assembly) {
      if (assembly.numberOfCleat > 0) {
        const cleatStretchOut = assembly.cleatStretchOut || 0;
        const productList = assembly.productList || [];
        const mainMetalProds = productList.filter(
          item =>
            this.subCategoryName(item.subCategoryId) === "Assembly Metal Cleat"
        );

        for (const prod of mainMetalProds) {
          if (!_.isEmpty(prod.dimension)) {
            const widthFeet = (prod.dimension.split("x") || [])[0] || "";
            const width = parseFloat(widthFeet) * 12; // Convert to inch

            if (cleatStretchOut > width) {
              const message = `The input value exceeds ${width} inches, which is the maximum width of a metal sheet. Please reenter the input.`;
              this.$ri.dialog.openWarningDialog({
                title: "Metal and Edge Warning!",
                content: message,
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    _sefl.app.dialog.close();
                  }
                }
              });
              return;
            }
          }
        }
      }

      if (assembly.numberOfCleat === 2) {
        const backCleatStretchOut = assembly.backCleatStretchOut || 0;
        const productList = assembly.productList || [];
        const mainMetalProds = productList.filter(
          item =>
            this.subCategoryName(item.subCategoryId) === "Assembly Metal Cleat"
        );

        for (const prod of mainMetalProds) {
          if (!_.isEmpty(prod.dimension)) {
            const widthFeet = (prod.dimension.split("x") || [])[0] || "";
            const width = parseFloat(widthFeet) * 12; // Convert to inch

            if (backCleatStretchOut > width) {
              const message = `The input value exceeds ${width} inches, which is the maximum width of a metal sheet. Please reenter the input.`;
              this.$ri.dialog.openWarningDialog({
                title: "Metal and Edge Warning!",
                content: message,
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    _sefl.app.dialog.close();
                  }
                }
              });
              return;
            }
          }
        }
      }
    },

    drawAssemply() {
      let index = 0;
      for (const assembly of this.currentBuilding.assemblyList || []) {
        switch (assembly.assemblyItem) {
          case "collectorHead":
            if (_.isEmpty(this.$refs[`${index}_collectorHead`])) {
              break;
            }
            this.$refs[`${index}_collectorHead`][0].initCanvas();
            this.$refs[`${index}_collectorHead`][0].drawAll();
            break;

          case "embedded":
            if (_.isEmpty(this.$refs[`${index}_embedded`])) {
              break;
            }
            this.$refs[`${index}_embedded`][0].initCanvas();
            this.$refs[`${index}_embedded`][0].drawAll();
            break;

          case "copingWithCleatFastener":
            if (_.isEmpty(this.$refs[`${index}_copingWithCleatFastener`])) {
              break;
            }
            this.$refs[`${index}_copingWithCleatFastener`][0].initCanvas();
            this.$refs[`${index}_copingWithCleatFastener`][0].drawAll();
            break;

          case "copingWithCleat":
            if (_.isEmpty(this.$refs[`${index}_copingWithCleat`])) {
              break;
            }
            this.$refs[`${index}_copingWithCleat`][0].initCanvas();
            this.$refs[`${index}_copingWithCleat`][0].drawAll();
            break;

          case "copingWithFrontBackCleat":
            if (_.isEmpty(this.$refs[`${index}_copingWithFrontBackCleat`])) {
              break;
            }
            this.$refs[`${index}_copingWithFrontBackCleat`][0].initCanvas();
            this.$refs[`${index}_copingWithFrontBackCleat`][0].drawAll();
            break;

          case "downspoutTransition":
            if (_.isEmpty(this.$refs[`${index}_downspoutTransition`])) {
              break;
            }
            this.$refs[`${index}_downspoutTransition`][0].initCanvas();
            this.$refs[`${index}_downspoutTransition`][0].drawAll();
            break;

          case "boxDownspout":
            if (_.isEmpty(this.$refs[`${index}_boxDownspout`])) {
              break;
            }
            this.$refs[`${index}_boxDownspout`][0].initCanvas();
            this.$refs[`${index}_boxDownspout`][0].drawAll();
            break;

          case "gutterSystem":
            if (_.isEmpty(this.$refs[`${index}_gutterSystem`])) {
              break;
            }
            this.$refs[`${index}_gutterSystem`][0].initCanvas();
            this.$refs[`${index}_gutterSystem`][0].drawAll();
            break;

          case "headwallFlashing":
            if (_.isEmpty(this.$refs[`${index}_headwallFlashing`])) {
              break;
            }
            this.$refs[`${index}_headwallFlashing`][0].initCanvas();
            this.$refs[`${index}_headwallFlashing`][0].drawAll();
            break;

          case "pitchPan":
            if (_.isEmpty(this.$refs[`${index}_pitchPan`])) {
              break;
            }
            this.$refs[`${index}_pitchPan`][0].initCanvas();
            this.$refs[`${index}_pitchPan`][0].drawAll();
            break;

          case "counterFlashing":
            if (_.isEmpty(this.$refs[`${index}_counterFlashing`])) {
              break;
            }
            this.$refs[`${index}_counterFlashing`][0].initCanvas();
            this.$refs[`${index}_counterFlashing`][0].drawAll();
            break;

          case "valley":
            if (_.isEmpty(this.$refs[`${index}_valley`])) {
              break;
            }
            this.$refs[`${index}_valley`][0].initCanvas();
            this.$refs[`${index}_valley`][0].drawAll();
            break;

          case "sidewallOrHeadwall":
            if (_.isEmpty(this.$refs[`${index}_sidewallOrHeadwall`])) {
              break;
            }
            this.$refs[`${index}_sidewallOrHeadwall`][0].initCanvas();
            this.$refs[`${index}_sidewallOrHeadwall`][0].drawAll();
            break;

          case "ridge":
            if (_.isEmpty(this.$refs[`${index}_ridge`])) {
              break;
            }
            this.$refs[`${index}_ridge`][0].initCanvas();
            this.$refs[`${index}_ridge`][0].drawAll();
            break;

          case "zeeTrim":
            if (_.isEmpty(this.$refs[`${index}_zeeTrim`])) {
              break;
            }
            this.$refs[`${index}_zeeTrim`][0].initCanvas();
            this.$refs[`${index}_zeeTrim`][0].drawAll();
            break;

          case "extendedEaveOrRake":
            if (_.isEmpty(this.$refs[`${index}_extendedEaveOrRake`])) {
              break;
            }
            this.$refs[`${index}_extendedEaveOrRake`][0].initCanvas();
            this.$refs[`${index}_extendedEaveOrRake`][0].drawAll();
            break;
        }

        index += 1;
      }
    },
    drawEditPopupAssemply(assembly, index) {
      switch (assembly.assemblyItem) {
        case "collectorHead":
          if (
            _.isEmpty(this.$refs[`${index}_collectorHead`]) ||
            _.isEmpty(this.$refs[`${index}_collectorHead_hidden`])
          ) {
            if (this.$refs[`${index}_collectorHead`]) {
              this.$refs[`${index}_collectorHead`].initCanvas();
              this.$refs[`${index}_collectorHead`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_collectorHead_hidden`].initCanvas();
          this.$refs[`${index}_collectorHead_hidden`].drawAll();
          this.$refs[`${index}_collectorHead`].initCanvas();
          this.$refs[`${index}_collectorHead`].drawAll();
          break;

        case "embedded":
          if (
            _.isEmpty(this.$refs[`${index}_embedded`]) ||
            _.isEmpty(this.$refs[`${index}_embedded_hidden`])
          ) {
            if (this.$refs[`${index}_embedded`]) {
              this.$refs[`${index}_embedded`].initCanvas();
              this.$refs[`${index}_embedded`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_embedded_hidden`].initCanvas();
          this.$refs[`${index}_embedded_hidden`].drawAll();
          this.$refs[`${index}_embedded`].initCanvas();
          this.$refs[`${index}_embedded`].drawAll();
          break;

        case "copingWithCleatFastener":
          if (
            _.isEmpty(this.$refs[`${index}_copingWithCleatFastener`]) ||
            _.isEmpty(this.$refs[`${index}_copingWithCleatFastener_hidden`])
          ) {
            if (this.$refs[`${index}_copingWithCleatFastener`]) {
              this.$refs[`${index}_copingWithCleatFastener`].initCanvas();
              this.$refs[`${index}_copingWithCleatFastener`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_copingWithCleatFastener_hidden`].initCanvas();
          this.$refs[`${index}_copingWithCleatFastener_hidden`].drawAll();
          this.$refs[`${index}_copingWithCleatFastener`].initCanvas();
          this.$refs[`${index}_copingWithCleatFastener`].drawAll();
          break;

        case "copingWithCleat":
          if (
            _.isEmpty(this.$refs[`${index}_copingWithCleat`]) ||
            _.isEmpty(this.$refs[`${index}_copingWithCleat_hidden`])
          ) {
            if (this.$refs[`${index}_copingWithCleat`]) {
              this.$refs[`${index}_copingWithCleat`].initCanvas();
              this.$refs[`${index}_copingWithCleat`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_copingWithCleat_hidden`].initCanvas();
          this.$refs[`${index}_copingWithCleat_hidden`].drawAll();
          this.$refs[`${index}_copingWithCleat`].initCanvas();
          this.$refs[`${index}_copingWithCleat`].drawAll();
          break;

        case "copingWithFrontBackCleat":
          if (
            _.isEmpty(this.$refs[`${index}_copingWithFrontBackCleat`]) ||
            _.isEmpty(this.$refs[`${index}_copingWithFrontBackCleat_hidden`])
          ) {
            if (this.$refs[`${index}_copingWithFrontBackCleat`]) {
              this.$refs[`${index}_copingWithFrontBackCleat`].initCanvas();
              this.$refs[`${index}_copingWithFrontBackCleat`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_copingWithFrontBackCleat_hidden`].initCanvas();
          this.$refs[`${index}_copingWithFrontBackCleat_hidden`].drawAll();
          this.$refs[`${index}_copingWithFrontBackCleat`].initCanvas();
          this.$refs[`${index}_copingWithFrontBackCleat`].drawAll();
          break;

        case "downspoutTransition":
          if (
            _.isEmpty(this.$refs[`${index}_downspoutTransition`]) ||
            _.isEmpty(this.$refs[`${index}_downspoutTransition_hidden`])
          ) {
            if (this.$refs[`${index}_downspoutTransition`]) {
              this.$refs[`${index}_downspoutTransition`].initCanvas();
              this.$refs[`${index}_downspoutTransition`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_downspoutTransition_hidden`].initCanvas();
          this.$refs[`${index}_downspoutTransition_hidden`].drawAll();
          this.$refs[`${index}_downspoutTransition`].initCanvas();
          this.$refs[`${index}_downspoutTransition`].drawAll();
          break;

        case "boxDownspout":
          if (
            _.isEmpty(this.$refs[`${index}_boxDownspout`]) ||
            _.isEmpty(this.$refs[`${index}_boxDownspout_hidden`])
          ) {
            if (this.$refs[`${index}_boxDownspout`]) {
              this.$refs[`${index}_boxDownspout`].initCanvas();
              this.$refs[`${index}_boxDownspout`].drawAll();
            }
            break;
          }

          this.$refs[`${index}_boxDownspout_hidden`].initCanvas();
          this.$refs[`${index}_boxDownspout_hidden`].drawAll();
          this.$refs[`${index}_boxDownspout`].initCanvas();
          this.$refs[`${index}_boxDownspout`].drawAll();
          break;

        case "gutterSystem":
          if (
            _.isEmpty(this.$refs[`${index}_gutterSystem`]) ||
            _.isEmpty(this.$refs[`${index}_gutterSystem_hidden`])
          ) {
            if (this.$refs[`${index}_gutterSystem`]) {
              this.$refs[`${index}_gutterSystem`].initCanvas();
              this.$refs[`${index}_gutterSystem`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_gutterSystem_hidden`].initCanvas();
          this.$refs[`${index}_gutterSystem_hidden`].drawAll();
          this.$refs[`${index}_gutterSystem`].initCanvas();
          this.$refs[`${index}_gutterSystem`].drawAll();
          break;

        case "headwallFlashing":
          if (
            _.isEmpty(this.$refs[`${index}_headwallFlashing`]) ||
            _.isEmpty(this.$refs[`${index}_headwallFlashing_hidden`])
          ) {
            if (this.$refs[`${index}_headwallFlashing`]) {
              this.$refs[`${index}_headwallFlashing`].initCanvas();
              this.$refs[`${index}_headwallFlashing`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_headwallFlashing_hidden`].initCanvas();
          this.$refs[`${index}_headwallFlashing_hidden`].drawAll();
          this.$refs[`${index}_headwallFlashing`].initCanvas();
          this.$refs[`${index}_headwallFlashing`].drawAll();
          break;

        case "pitchPan":
          if (
            _.isEmpty(this.$refs[`${index}_pitchPan`]) ||
            _.isEmpty(this.$refs[`${index}_pitchPan_hidden`])
          ) {
            if (this.$refs[`${index}_pitchPan`]) {
              this.$refs[`${index}_pitchPan`].initCanvas();
              this.$refs[`${index}_pitchPan`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_pitchPan_hidden`].initCanvas();
          this.$refs[`${index}_pitchPan_hidden`].drawAll();
          this.$refs[`${index}_pitchPan`].initCanvas();
          this.$refs[`${index}_pitchPan`].drawAll();
          break;

        case "counterFlashing":
          if (
            _.isEmpty(this.$refs[`${index}_counterFlashing`]) ||
            _.isEmpty(this.$refs[`${index}_counterFlashing_hidden`])
          ) {
            if (this.$refs[`${index}_counterFlashing`]) {
              this.$refs[`${index}_counterFlashing`].initCanvas();
              this.$refs[`${index}_counterFlashing`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_counterFlashing_hidden`].initCanvas();
          this.$refs[`${index}_counterFlashing_hidden`].drawAll();
          this.$refs[`${index}_counterFlashing`].initCanvas();
          this.$refs[`${index}_counterFlashing`].drawAll();
          break;

        case "valley":
          if (
            _.isEmpty(this.$refs[`${index}_valley`]) ||
            _.isEmpty(this.$refs[`${index}_valley_hidden`])
          ) {
            if (this.$refs[`${index}_valley`]) {
              this.$refs[`${index}_valley`].initCanvas();
              this.$refs[`${index}_valley`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_valley_hidden`].initCanvas();
          this.$refs[`${index}_valley_hidden`].drawAll();
          this.$refs[`${index}_valley`].initCanvas();
          this.$refs[`${index}_valley`].drawAll();
          break;

        case "sidewallOrHeadwall":
          if (
            _.isEmpty(this.$refs[`${index}_sidewallOrHeadwall`]) ||
            _.isEmpty(this.$refs[`${index}_sidewallOrHeadwall_hidden`])
          ) {
            if (this.$refs[`${index}_sidewallOrHeadwall`]) {
              this.$refs[`${index}_sidewallOrHeadwall`].initCanvas();
              this.$refs[`${index}_sidewallOrHeadwall`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_sidewallOrHeadwall_hidden`].initCanvas();
          this.$refs[`${index}_sidewallOrHeadwall_hidden`].drawAll();
          this.$refs[`${index}_sidewallOrHeadwall`].initCanvas();
          this.$refs[`${index}_sidewallOrHeadwall`].drawAll();
          break;

        case "ridge":
          if (
            _.isEmpty(this.$refs[`${index}_ridge`]) ||
            _.isEmpty(this.$refs[`${index}_ridge_hidden`])
          ) {
            if (this.$refs[`${index}_ridge`]) {
              this.$refs[`${index}_ridge`].initCanvas();
              this.$refs[`${index}_ridge`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_ridge_hidden`].initCanvas();
          this.$refs[`${index}_ridge_hidden`].drawAll();
          this.$refs[`${index}_ridge`].initCanvas();
          this.$refs[`${index}_ridge`].drawAll();
          break;

        case "zeeTrim":
          if (
            _.isEmpty(this.$refs[`${index}_zeeTrim`]) ||
            _.isEmpty(this.$refs[`${index}_zeeTrim_hidden`])
          ) {
            if (this.$refs[`${index}_zeeTrim`]) {
              this.$refs[`${index}_zeeTrim`].initCanvas();
              this.$refs[`${index}_zeeTrim`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_zeeTrim_hidden`].initCanvas();
          this.$refs[`${index}_zeeTrim_hidden`].drawAll();
          this.$refs[`${index}_zeeTrim`].initCanvas();
          this.$refs[`${index}_zeeTrim`].drawAll();
          break;

        case "extendedEaveOrRake":
          if (
            _.isEmpty(this.$refs[`${index}_extendedEaveOrRake`]) ||
            _.isEmpty(this.$refs[`${index}_extendedEaveOrRake_hidden`])
          ) {
            if (this.$refs[`${index}_extendedEaveOrRake`]) {
              this.$refs[`${index}_extendedEaveOrRake`].initCanvas();
              this.$refs[`${index}_extendedEaveOrRake`].drawAll();
            }
            break;
          }
          this.$refs[`${index}_extendedEaveOrRake_hidden`].initCanvas();
          this.$refs[`${index}_extendedEaveOrRake_hidden`].drawAll();
          this.$refs[`${index}_extendedEaveOrRake`].initCanvas();
          this.$refs[`${index}_extendedEaveOrRake`].drawAll();
          break;
      }
    },

    drawPopupAssemply(assembly) {
      switch (assembly.assemblyItem) {
        case "collectorHead":
          this.$refs[`collectorHead`].initCanvas();
          this.$refs[`collectorHead`].drawAll();
          break;

        case "embedded":
          this.$refs[`embedded`].initCanvas();
          this.$refs[`embedded`].drawAll();
          break;

        case "copingWithCleatFastener":
          this.$refs[`copingWithCleatFastener`].initCanvas();
          this.$refs[`copingWithCleatFastener`].drawAll();
          break;

        case "copingWithCleat":
          this.$refs[`copingWithCleat`].initCanvas();
          this.$refs[`copingWithCleat`].drawAll();
          break;

        case "copingWithFrontBackCleat":
          this.$refs[`copingWithFrontBackCleat`].initCanvas();
          this.$refs[`copingWithFrontBackCleat`].drawAll();
          break;

        case "downspoutTransition":
          this.$refs[`downspoutTransition`].initCanvas();
          this.$refs[`downspoutTransition`].drawAll();
          break;

        case "boxDownspout":
          this.$refs[`boxDownspout`].initCanvas();
          this.$refs[`boxDownspout`].drawAll();
          break;

        case "gutterSystem":
          this.$refs[`gutterSystem`].initCanvas();
          this.$refs[`gutterSystem`].drawAll();
          break;

        case "headwallFlashing":
          this.$refs[`headwallFlashing`].initCanvas();
          this.$refs[`headwallFlashing`].drawAll();
          break;

        case "pitchPan":
          this.$refs[`pitchPan`].initCanvas();
          this.$refs[`pitchPan`].drawAll();
          break;

        case "counterFlashing":
          this.$refs[`counterFlashing`].initCanvas();
          this.$refs[`counterFlashing`].drawAll();
          break;

        case "valley":
          this.$refs[`valley`].initCanvas();
          this.$refs[`valley`].drawAll();
          break;

        case "sidewallOrHeadwall":
          this.$refs[`sidewallOrHeadwall`].initCanvas();
          this.$refs[`sidewallOrHeadwall`].drawAll();
          break;

        case "ridge":
          this.$refs[`ridge`].initCanvas();
          this.$refs[`ridge`].drawAll();
          break;

        case "zeeTrim":
          this.$refs[`zeeTrim`].initCanvas();
          this.$refs[`zeeTrim`].drawAll();
          break;

        case "extendedEaveOrRake":
          this.$refs[`extendedEaveOrRake`].initCanvas();
          this.$refs[`extendedEaveOrRake`].drawAll();
          break;
      }
    },

    // eslint-disable-next-line no-unused-vars
    saveDiagramUrl(index) {
      const self = this;
      const assembly = this.currentBuilding.assemblyList[index];
      if (_.isEmpty(assembly.assemblyItem)) {
        return;
      }
      const urlBase64 = this.$refs[`${index}_${assembly.assemblyItem}`].$refs[
        `${this.currentBuilding.id}_${index}_${assembly.assemblyItem}`
      ].toDataURL("image/png");
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      assemblyList[index]["diagramUrlBase64"] = urlBase64;
      if (!assemblyList[index].assemblyId) {
        assemblyList[index]["assemblyId"] = uuid.v1();
      }
      return this.setCurrentBuildingValue({
        prop: "assemblyList",
        value: assemblyList
      }).then(() => {
        return self.saveDiagramUrlDebounce(this);
      });
    },

    // saveDiagramUrlDebounce: _.debounce(async self => {
    async saveDiagramUrlDebounce(self) {
      let assemblyList = _.cloneDeep(self.currentBuilding.assemblyList || []);
      for (let i = 0; i < assemblyList.length; i++) {
        let assembly = assemblyList[i];
        if (assembly.diagramUrlBase64) {
          if (assembly.diagramFullPath) {
            self.removeDiagramPhoto(assembly.diagramFullPath);
          }
          const res = await self.uploadDiagramPhoto({
            dataUrl: assembly.diagramUrlBase64,
            info: {
              name: `${self.currentBuilding.id}-${assembly.assemblyId}`,
              type: "image/png"
            }
          });

          assembly.diagramFullPath = res.fullPath;
          assembly.diagramUrl = res.url;
          delete assembly.diagramUrlBase64;
        }
      }
      return self.saveBuildingValue("assemblyList", assemblyList);
    },
    // }, 2000),

    openLink(url) {
      window.open(url, "_blank");
    },

    onDeleteBuilding(currentBuilding) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "",
        content: `Are you sure you want to delete building ${currentBuilding.buildingName} ?`,
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            //navigate to next building
            app
              .deleteBuilding({
                estimateId: app.estimate.id,
                buildingId: currentBuilding.buildingId
              })
              .then(() => {
                // delete product history of building
                app.deleteEstimateHistoryBys({
                  estimateId: app.estimate.id,
                  conditions: [
                    {
                      prop: "buildingId",
                      val: currentBuilding.buildingId,
                      op: "=="
                    }
                  ]
                });
                // delete building ref in estimate
                let buildings = _.cloneDeep(app.estimate.buildings);
                buildings = _.remove(buildings, b => {
                  return b.buildingId != currentBuilding.buildingId;
                });
                app
                  .updateEstimate({
                    id: app.estimate.id,
                    doc: {
                      buildings: buildings
                    }
                  })
                  .then(() => {
                    app.$f7.preloader.hide();
                  });
              });
          }
        }
      });
    },

    async onCopyBuilding(currentBuilding) {
      let buildingName = `${currentBuilding.buildingName}-COPY`;
      let building = await this.getBuilding({
        estimateId: this.estimate.id,
        buildingId: currentBuilding.buildingId
      });
      this.$f7.dialog.prompt(
        "Specification #",
        "Copy Specification",
        newBuildingName => {
          this.$f7.preloader.show();
          this.createBuilding({
            estimateId: this.estimate.id,
            building: {
              ...building,
              id: "",
              buildingName: newBuildingName
            }
          })
            .then(buildingData => {
              const buildings = _.cloneDeep(this.estimate.buildings) || [];
              buildings.push(buildingData);
              return this.updateEstimate({
                id: this.estimate.id,
                doc: {
                  buildings: buildings,
                  numberOfBuildings: buildings.length
                }
              }).then(() => {
                return {
                  estimateNumber: this.estimate.estimateNumber,
                  buildingName: buildingData.buildingName
                };
              });
            })
            .then(data => {
              this.$f7.preloader.hide();
              this.$f7router.navigate(
                this.buildingUrl(data.estimateNumber, data.buildingName)
              );
            })
            .finally(() => {
              this.$f7.preloader.hide();
            });
        },
        () => { },
        buildingName
      );
    },

    calculateOrderQty(actualQty, productItem) {
      const productSize = productItem.unitSize * productItem.unitPack;
      return roundUpNumber(0)(actualQty / (productSize || 1));
    },

    buildingUrl(estimateNumber, buildingName, contactId) {
      return this.$f7route.params.clientType ||
        this.$f7route.params.clientId ||
        contactId
        ? `/estimate/${this.$f7route.params.clientType || "contact"}/${this
          .$f7route.params.clientId ||
        contactId}/${estimateNumber}/building/${encodeURIComponent(
          buildingName
        )}`
        : `/estimate/${estimateNumber}/building/${encodeURIComponent(
          buildingName
        )}`;
    },

    getWorkType(project) {
      return getWorkType(project);
    },

    async getBuildingInfoDefault(project) {
      return await getBuildingInfoDefault(project);
    },

    async sendMailAddNewProduct(product) {
      let promises = [];
      if (_.isEmpty(this.setting)) {
        promises.push(this.bindSetting(this.tenantId));
      }
      if (_.isEmpty(this.users)) {
        promises.push(this.getUserList());
      }
      if (_.isEmpty(this.adminGroups)) {
        promises.push(this.getAdminGroups());
      }
      await Promise.all(promises);
      // receivers is owner and users in group admin
      const adminGroupUserIDs = [].concat.apply(
        [],
        (this.adminGroups || []).map(r => r.userIDs)
      );
      const receivers = (
        this.users.filter(
          user =>
            user.customClaims.role === "owner" ||
            adminGroupUserIDs.includes(user.uid)
        ) || []
      ).map(r => r.email);

      const sendMailPromises = [];
      for (const receiver of receivers) {
        sendMailPromises.push(
          axiosService
            .post("/notification/push-email", {
              to: receiver,
              subject: {
                price_list_number: this.priceListObject.priceListNumber,
                price_list_name: this.priceListObject.displayName
              },
              template: "add-new-product",
              content: {
                price_list_number: this.priceListObject.priceListNumber,
                price_list_name: this.priceListObject.displayName,
                created_by: auth.currentUser.displayName,
                product_name: product.productItem,
                detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/price-list/${this.priceListObject.priceListNumber}?productTab=new-added-product`,
                roofing_company_name: this.setting.companyName || "",
                roofing_company_address: this.setting.address || "",
                roofing_company_phone: this.setting.phoneNumber || ""
              },
              attachments: [],
              scheduleSendingTime: ""
            })
            .then(() => {
              // TODO: this trigger will be removed when implement email mail service scheduler
              return axiosService.post("/notification/trigger-email-service", {
                sender: ""
              });
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.error("[Send Email]", error);
              throw new Error(error.message);
            })
        );
      }
      Promise.all(sendMailPromises);
    }
  }
};
