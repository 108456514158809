<template>
  <div>
    <f7-block>
      <filter-by-date
        @searchByDate="searchExpensesByDate"
        @exportToQuickbook="exportToQuickbook"
        @exportToExcel="exportToExcel"
        :isDisableExportButton="selectedItems.length === 0"
      ></filter-by-date>
    </f7-block>

    <expenses-data-table
      :expensesList="expenseList"
      :isActiveTab="true"
      @selectAllItem="selectAllItem"
      @checkBoxChange="checkBoxChange"
      @dataItemChange="dataItemChange"
    ></expenses-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import FilterByDate from "@/plugins/quickbooks/components/filter/FilterByDate.vue";
import ExpensesDataTable from "../../components/datatable/ExpensesDataTable.vue";
import { mapActions, mapGetters } from "vuex";
import { lastWeekMonday, lastWeekSunday } from "../../../../utility/date-time-tool";
import { toDateFirebase } from "../../../../utility/datetime";
import { getTomorrow } from "../../../../utility/date-time-tool";
import _ from "lodash";
import { STATUS_EXPENSE_APPROVED } from "@/utility/const";

import quickbooksService from "../../../../services/quickbooks.service";

export default {
  components: {
    FilterByDate,
    ExpensesDataTable
  },

  data: () => {
    return {
      selectedItems: []
    };
  },
  created() {
    this.searchExpensesByDate({
      fromDate: [lastWeekMonday()],
      toDate: [lastWeekSunday()]
    });
  },
  computed: {
    ...mapGetters("quickbooks/employee-expenses", [
      "expenseList",
      "expenseById"
    ]),
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters("quickbooks/common", [
      "qbExpenseList",
      "qbBankAccountList",
      "qbVendorList"
    ]),

    tenantId() {
      return this.user.tenantId;
    }
  },

  methods: {
    ...mapActions("quickbooks/employee-expenses", [
      "bindExpenseListBys",
      "updateExpense",
      "changeExpenseItem"
    ]),

    ...mapActions("quickbooks/activity", ["createActivity"]),

    openExportSuccessOrFailPopup(title, content) {
      const self = this;
      this.$ri.dialog.openWarningDialog({
        title: `Export ${title}`,
        content: content,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            if (title === "Complete") {
              self.$emit("onDone");
            }
          }
        }
      });
    },

    exportToQuickbook() {
      const self = this;
      if (this.validateExpenses()) {
        this.$f7.preloader.show();
        quickbooksService
          .checkAuth()
          .then(() => {
            const bills = [];
            for (const expense of self.selectedItems) {
              bills.push({
                id: expense.id,
                Bill: {
                  VendorRef: {
                    value: expense.vendorRef.id
                  },
                  Line: [
                    {
                      DetailType: "AccountBasedExpenseLineDetail",
                      Amount: expense.amount || 0,

                      AccountBasedExpenseLineDetail: {
                        AccountRef: {
                          name: expense.expenseCategoryRef.name,
                          value: expense.expenseCategoryRef.id
                        }
                      }
                    }
                  ],
                  PrivateNote: expense.note || ""
                }
              });
              // .then(bill => {
              //   return {
              //     ...expense,
              //     qbBillId: bill.Id,
              //     qbVendorRef: bill.VendorRef,
              //     qbAccountRef:
              //       bill.Line[0].AccountBasedExpenseLineDetail.AccountRef
              //   };
              // })
              // );
            }
            // return Promise.all(refs);
            return quickbooksService.createBill({ bills }).then(data => {
              const result = [];
              for (const bill of data) {
                result.push({
                  id: bill.id,
                  qbBillId: bill.data.Bill.Id,
                  qbVendorRef: bill.data.Bill.VendorRef,
                  qbAccountRef:
                    bill.data.Bill.Line[0].AccountBasedExpenseLineDetail
                      .AccountRef
                });
              }
              return result;
            });
          })
          .then(data => {
            // update data
            return self.updateBillForExpense(data);
          })
          .then(data => {
            // update history
            return self.updateHistory(data);
          })
          .then(() => {
            // show complete message here
            self.openExportSuccessOrFailPopup(
              "Complete",
              "Export expenses to QuickBooks successfully!"
            );
          })
          .catch(error => {
            // show error message here
            self.openExportSuccessOrFailPopup("Error", error.message);
          })
          .finally(() => {
            self.$f7.preloader.hide();
          });
      } else {
        // show invalid message here
        this.openExportSuccessOrFailPopup(
          "Error",
          "Please chose QB Vendor and Expense Category for all selected items!"
        );
      }
    },

    updateHistory(data) {
      const historyData = [];
      for (const item of data) {
        const expense = this.expenseById(item.id);
        historyData.push({
          ...item,
          ...expense
        });
      }

      this.createActivity({
        activityType: "export-qb-expense",
        data: historyData,
        doneBy: this.user.uid,
        message: "Exported expenses to Quickbooks"
      });
    },

    /**
     * update QB bill Id for expense to know what expense is exported to QB
     */
    updateBillForExpense(data) {
      const refs = [];
      for (const item of data) {
        if (item.id && item.qbBillId) {
          refs.push(
            this.updateExpense({
              id: item.id,
              doc: {
                qbBillId: item.qbBillId,
                qbVendorRef: item.qbVendorRef,
                qbAccountRef: item.qbAccountRef
              }
            }).then(() => {
              return item;
            })
          );
        }
      }

      return Promise.all(refs);
    },

    validateExpenses() {
      for (const item of this.selectedItems) {
        if (_.isEmpty(item.vendorRef) || _.isEmpty(item.expenseCategoryRef)) {
          return false;
        }
      }
      return true;
    },

    exportToExcel() {
      quickbooksService
        .queryEntity("select * from Bill where Id >= '324'")
        .then(result => {
          // eslint-disable-next-line no-console
          console.log(result);
        });
    },

    searchExpensesByDate(data) {
      this.$f7.preloader.show();
      this.bindExpenseListBys([
        {
          prop: "status",
          val: STATUS_EXPENSE_APPROVED,
          op: "=="
        },

        {
          prop: "createdAt",
          val: toDateFirebase([data.fromDate]),
          op: ">="
        },
        {
          prop: "createdAt",
          val: toDateFirebase([getTomorrow(data.toDate)]),
          op: "<"
        }
      ]).finally(() => {
        this.$f7.preloader.hide();
        // eslint-disable-next-line no-console
        // console.log(this.expenseListNotExport);
      });
    },

    selectAllItem(checked, items) {
      this.expenseList.forEach(r => {
        if (items.some(i => i.id === r.id)) {
          if (!r.qbBillId) {
            Vue.set(r, "checked", checked);
            this.changeExpenseItem({ id: r.id, item: r });
          }
        }
      });
      this.selectedItems = this.expenseList.filter(
        item => item.checked === true
      );
    },

    checkBoxChange(checked, id) {
      let item = (this.expenseList || []).find(item => item.id === id);
      Vue.set(item, "checked", checked);
      this.changeExpenseItem({ id, item });
      this.selectedItems = (this.expenseList || []).filter(
        item => item.checked === true
      );
    },

    dataItemChange(prop, value, id) {
      let foundItem = _.cloneDeep(
        (this.expenseList || []).find(item => item.id === id)
      );
      Vue.set(foundItem, prop, value);
      this.changeExpenseItem({ id, item: foundItem });
      this.selectedItems = (this.expenseList || []).filter(
        item => item.checked === true
      );
    }
  },
  watch: {
    selectedItems(newVal) {
      this.$emit("selectedItems", newVal);
    }
  }
};
</script>
