import { FirebaseActions } from "../../../../services/firebase.service";
export default tenant => {
  const ContractActions = new FirebaseActions(
    `/system_client/${tenant}/contract`,
    "contract"
  );

  return {
    bindContract: ContractActions.bindDocument,
    unbindContract: ContractActions.unbindDocument
  };
};
