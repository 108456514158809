/* eslint-disable no-unused-vars */
import _ from "lodash";
import * as constant from "../../../../utility/const";
import { CODE_STATUS_OPTION, ENTITY_PROPOSAL } from "@/utility/const";
const PROP_DISPLAY_NAME = "displayName";
const PROP_VALUE = "value";
const by = prop => (a, b) => (a[prop] > b[prop] ? 1 : -1);

export default {
  proposalStatusList: state =>
    state.constantObjectList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_PROPOSAL
    ),
  constantObjectList: state => state.constantObjectList,
  slopeTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_SLOPE_TYPE
    ),
  slopeType: (state, getters, rootState, rootGetters) => value =>
    getters.slopeTypeList.find(item => item.value === value) || {},

  roofTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_ROOF_TYPE
    ),

  roofTypeBy: (state, getters, rootState, rootGetters) => value =>
    getters.roofTypeList.find(item => item.value === value) || {},

  workTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_WORK_TYPE
    ),
  workType: (state, getters, rootState, rootGetters) => value =>
    getters.workTypeList.find(item => item.value === value) || {},

  substrateList: state =>
    _.cloneDeep(
      state.constantObjectList.filter(
        item =>
          item.code === constant.CODE_SUBSTRATE &&
          item.roofType === constant.ROOF_TYPE_TPO
      )
    ).sort(by(PROP_VALUE)),

  substrate: (state, getters, rootState, rootGetters) => value =>
    getters.substrateList.find(item => item.value === value) || {},

  tpoWarrantyOptionList: state =>
    state.constantObjectList
      .filter(
        item =>
          item.code === constant.CODE_WARRANTY_OPTION &&
          item.roofType === constant.ROOF_TYPE_TPO
      )
      .sort(by(PROP_DISPLAY_NAME)),

  tpoWarrantyOption: (state, getters, rootState, rootGetters) => value =>
    getters.tpoWarrantyOptionList.find(item => item.value === value) || {},

  membraneThicknessList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_MEMBRANE_THICKNESS
    ),

  membraneThickness: (state, getters, rootState, rootGetters) => value =>
    getters.membraneThicknessList.find(item => item.value === value) || {},

  insulationTypeList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_INSULATION_TYPE
    ),

  insulationType: (state, getters, rootState, rootGetters) => value =>
    getters.insulationTypeList.find(item => item.value === value) || {},

  insulationThicknessList: state =>
    state.constantObjectList.filter(
      item => item.code === constant.CODE_INSULATION_THICKNESS
    ),

  insulationThickness: (state, getters, rootState, rootGetters) => value =>
    getters.insulationThicknessList.find(item => item.value === value) || {},

  gaBy: state => value =>
    [
      { id: "ga-22", displayName: "22" },
      { id: "ga-24", displayName: "24" },
      { id: "ga-26", displayName: "26" }
    ].find(item => item.id === value)
};
