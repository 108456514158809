import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import * as types from "./types";
import { searchClient } from "../../../../services/search.service";

export default tenant => {
  const vendorActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "vendor"
  );

  const companyIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindVendor: vendorActions.bindDocument,
    unbindVendor: vendorActions.unbindDocument,
    setSearchText({ commit }, value) {
      commit(types.SET_SEARCH_TEXT, value);
    },
    searchVendor({ state, commit }, { attributesToRetrieve, filters }) {
      const index = companyIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_COMPANY} AND companyTypes:${constant.COMPANY_TYPE_VENDOR}`,
        attributesToRetrieve:
          constant.OPERATION_COMPANY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "companyName",
          "phoneValues",
          "addressValues"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreVendor(
      { state, commit },
      { attributesToRetrieve, filters, page }
    ) {
      const index = companyIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_COMPANY} AND companyTypes:${constant.COMPANY_TYPE_VENDOR}`,
        attributesToRetrieve:
          constant.OPERATION_COMPANY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "companyName",
          "phoneValues",
          "addressValues"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    }
  };
};
