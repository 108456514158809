/* eslint-disable no-unused-vars */
import {
  FirebaseActions,
  firestore
} from "../../../../services/firebase.service";
import * as types from "./types";
import * as constant from "../../../../utility/const";

export default tenant => {
  const timeLogAction = new FirebaseActions(
    `/system_client/${tenant}/weekly_labor_cost`,
    "timeLog"
  );

  const workOrderTaskAction = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "purchaseOrder"
  );

  const expenseActions = new FirebaseActions(
    `/system_client/${tenant}/employee_expenses`,
    "expense"
  );

  const estimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    "estimate"
  );

  const invoiceActions = new FirebaseActions(
    `/system_client/${tenant}/invoice`,
    "invoice"
  );

  const invoiceGroupActions = new FirebaseActions(
    `/system_client/${tenant}/invoice_group`,
    "invoiceGroup"
  );

  const RecurringInvoiceActions = new FirebaseActions(
    `/system_client/${tenant}/recurring-invoice`,
    "recurringInvoice"
  );

  return {
    bindTimeTrackingBys: timeLogAction.bindCollectionBys,
    bindworkOrderTaskBys: workOrderTaskAction.bindCollectionBys,
    bindExpenseListBys: expenseActions.bindCollectionBys,
    bindEstimateListBys: estimateActions.bindCollectionBys,
    bindInvoiceListBys: invoiceActions.bindCollectionBys,
    getInvoiceListBys: invoiceActions.getDocumentBys,
    getInvoiceGroupListBys: invoiceGroupActions.getDocumentBys,
    getRecurringInvoiceBys: RecurringInvoiceActions.getDocumentBys,

    initTimeTracking(
      { commit, dispatch, getters, rootGetters, rootState, state },
      projectId
    ) {
      return dispatch("bindTimeTrackingBys", [
        {
          prop: "projectId",
          op: "==",
          val: projectId
        },
        { prop: "expenseType", op: "==", val: "labor" }
      ]);
    },

    initPurchaseOrder(
      { commit, dispatch, getters, rootGetters, rootState, state },
      projectId
    ) {
      return dispatch("bindworkOrderTaskBys", [
        {
          prop: "projectId",
          op: "==",
          val: projectId
        },
        { prop: "taskType", op: "==", val: "purchase-order" }
      ]);
    },

    initExpense(
      { commit, dispatch, getters, rootGetters, rootState, state },
      projectId
    ) {
      return dispatch("bindExpenseListBys", [
        {
          prop: "projectId",
          op: "==",
          val: projectId
        },
        { prop: "status", op: "==", val: "ex-approved" }
      ]);
    },

    async getInvoiceGroupByProjectId({ commit, dispatch }, projectId) {
      const invoiceGroups = await dispatch("getInvoiceGroupListBys", [
        {
          prop: "projectId",
          op: "==",
          val: projectId
        },
        { prop: "isProjectArchived", op: "==", val: false }
      ]);

      commit(types.SET_INVOICE_GROUP_LIST, invoiceGroups);

      return invoiceGroups;
    },

    initEstimate(
      { commit, dispatch, getters, rootGetters, rootState, state },
      projectId
    ) {
      return dispatch("bindEstimateListBys", [
        {
          prop: "projectId",
          op: "==",
          val: projectId
        },
        { prop: "status", op: "==", val: "active" },
        { prop: "isDefault", op: "==", val: true }
      ]);
    },

    async initInvoice({ commit, dispatch }, invoiceGroupId) {
      if (!invoiceGroupId) return;
      const invoiceList = await dispatch("getInvoiceListBys", [
        { prop: "invoiceGroupId", op: "==", val: invoiceGroupId }
      ]);
      commit(types.SET_INVOICE_LIST, invoiceList);
      return invoiceList;
    },

    resetInvoiceList({ commit }) {
      commit(types.SET_INVOICE_LIST, []);
    },

    getBuildingsByEstimateId(_, estimateId) {
      return FirebaseActions.getCollection(
        `/system_client/${tenant}/estimate/${estimateId}/building`
      );
    },

    async getRecurringInvoiceByInvoiceGroupId(
      { commit, dispatch },
      invoiceGroupId
    ) {
      if (!invoiceGroupId) return;
      const recurringInvoiceList = await dispatch("getRecurringInvoiceBys", [
        { prop: "invoiceGroupId", op: "==", val: invoiceGroupId }
      ]);

      commit(types.SET_RECURRING_INVOICE_LIST, recurringInvoiceList);
    },

    setBuildings({ commit }, buildings) {
      commit(types.SET_BUILDINGS, buildings);
    },

    resetDataInvoice({ commit }){
      commit(types.SET_INVOICE_LIST, []);
      commit(types.SET_RECURRING_INVOICE_LIST, []);
    }
  };
};
