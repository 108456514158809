<template>
  <f7-popup :opened="popupOpened" @popup:closed="close" @popup:opened="handlePopupOpened">
    <div class="page" v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Property</f7-nav-title>
        <f7-nav-right v-if="!isHiddenAddNew">
          <f7-link icon-f7="plus" @click.native="onAdd"></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar disable-button-text placeholder="Search property" :clear-button="true" :value="searchText"
        @input="
          setPropertySearchText($event.target.value);
          onSearch();
        " @searchbar:disable="onSearch()" @searchbar:clear="onSearch()"
        class="search-list-popup"></f7-searchbar>

      <div class="page-content" @scroll="onScroll">
        <f7-list class="search-list searchbar-found contact-list">
          <f7-list-group v-for="(group, index) in propertyGroup" :key="index">
            <f7-list-item :title="group.key" group-title></f7-list-item>
            <f7-list-item no-chevron link v-for="(property, index) in group.data" :key="index"
              :title="property.propertyName" @click.native="select(property.id)"></f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
    </div>

    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Property</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Property Name -->
        <f7-list-input class="first-input" type="text" placeholder="Property name" clear-button
          :value="currentProperty.propertyName" @input="set('propertyName', $event.target.value.trim())"
          error-message-force :error-message="propertyNameErrorMessage" @blur="v$.$touch()"></f7-list-input>
      </f7-list>

      <!-- Address -->
      <address-auto-complete-input ref="addressInput" isRequired :value="currentProperty.addresses"
        @input="setAddresses($event)"></address-auto-complete-input>
    </f7-page>
  </f7-popup>
</template>

<script>
import AddressAutoCompleteInput from "../inputs/AddressAutoCompleteInput.vue";
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import _ from "lodash";
import Vue from "vue";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    AddressAutoCompleteInput
  },
  props: {
    companyId: String,
    queryFilters: String,
    isHiddenAddNew: { type: Boolean, default: false }
  },

  data: () => {
    return {
      popupOpened: false,
      isNew: false,
      currentProperty: { propertyName: "" },
      onlyAddNew: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true
    };
  },

  computed: {
    ...mapGetters("common/property", [
      "propertyGroup",
      "hits",
      "nbPages",
      "page",
      "searchText"
    ]),

    propertyNameErrorMessage() {
      if (!this.v$.currentProperty.propertyName.$error) return null;
      if (this.v$.currentProperty.propertyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    ...mapActions("common/company", ["getCompany"]),
    ...mapActions("common/property", [
      "searchProperty",
      "loadMoreProperty",
      "createProperty",
      "resetSearch",
      "getPropertyListBys",
      "setPropertySearchText",
      "setQueryFilters"
    ]),

    set(prop, value) {
      Vue.set(this.currentProperty, prop, value);
    },

    async autoFillProperty() {
      const company = this.companyId
        ? await this.getCompany(this.companyId)
        : null;
      if (company && !_.isEmpty(company.propertyRefs)) {
        try {
          await this.searchProperty({});
          if (!_.isEmpty(this.hits)) {
            this.select(this.hits[0].id);
          } else {
            this.select("");
          }
        } catch (e) {
          this.select("");
        }
      } else {
        this.select("");
      }
    },
    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),
    handleSearch(isFirst = false) {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchProperty({})
        .then(() => {
          if (isFirst && this.queryFilters && _.isEmpty(this.hits)) {
            this.setQueryFilters("");

            return this.searchProperty({});
          }
        })
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        });
    },

    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;
      this.loadMoreProperty({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    async open(onlyAddNew = false) {
      if (onlyAddNew) {
        this.isNew = true;
        this.onlyAddNew = true;
        this.popupOpened = true;
      } else {
        this.$f7.preloader.show();
        this.setQueryFilters(this.queryFilters);
        this.handleSearch(true)
          .then(() => {
            this.popupOpened = true;
          })
          .finally(() => {
            this.$f7.preloader.hide();
          });
      }
    },

    close() {
      this.popupOpened = false;
      this.isNew = false;
      this.currentProperty = { propertyName: "" };
      this.setPropertySearchText("");
      this.onlyAddNew = false;
      this.setQueryFilters("");
      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.hasData = true;
      this.v$.$reset();
      this.resetSearch();
      this.$refs.addressInput.resetData();
    },

    onAdd() {
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector(".first-input input").focus();
        }
      });
    },

    onAddCancel() {
      this.isNew = false;
      this.currentContact = {};
      if (this.onlyAddNew) {
        this.popupOpened = false;
        this.onlyAddNew = false;
      }
      this.v$.$reset();
      this.$refs.addressInput.resetData();
    },

    select(propertyId) {
      this.$emit("onSelected", propertyId);
      this.close();
    },

    setAddresses(val) {
      Vue.set(this.currentProperty, "addresses", val);
    },

    create() {
      const self = this;
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) return;

      this.$f7.preloader.show();
      return this.createProperty(this.currentProperty)
        .then(propertyId => {
          self.$f7.preloader.hide();
          propertyId && self.$emit("onCreated", propertyId);
        })
        .finally(() => {
          self.close();
        });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
        if (this.onlyAddNew) {
          this.$el.querySelector(".first-input input").focus();
        }
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentProperty: {
        propertyName: {
          required
        }
      }
    };
  }
};
</script>

<style></style>
