<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{
          isEdit ? "Edit Attachment Description" : "Add Attachment Description"
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div media-list class="page-content">
        <f7-list class="margin">
          <f7-list-input
            type="textarea"
            :value="
              description
                ? description.replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ')
                : ''
            "
            @input="updateDescription($event.target.value.trim())"
            resizable
            placeholder="Description..."
            style="min-height: 120px; padding-top: 5px; white-space: pre-wrap;"
          >
            <span slot="label">{{ fileName }}</span>
          </f7-list-input>
        </f7-list>
      </div>
    </div>
  </f7-popup>
</template>

<script>
export default {
  data: () => {
    return {
      popupOpened: false,
      description: "",
      index: 0,
      isEdit: false,
      fileName: ""
    };
  },

  methods: {
    /**
     * open
     */
    async open(item, index, isEdit) {
      this.index = index;
      this.isEdit = isEdit;
      if (item) {
        this.description = item.description;
        this.fileName = item.fileName;
      }
      this.popupOpened = true;
    },

    handlePopupOpened() {},

    handlePopupClosed() {
      this.popupOpened = false;
      this.description = "";
    },
    updateDescription(value) {
      this.description = value.replace(/\n/g, "<br>").replace(/ /g, "&nbsp;");
    },

    onDone() {
      this.$emit("onEdited", this.description, this.index);
      this.popupOpened = false;
    }
  }
};
</script>
<style scoped lang="scss">
.list ::v-deep ul::before,
.list ::v-deep ul::after {
  display: none;
}

.list ::v-deep .item-label {
  font-size: 14px;
  font-weight: bold;
  max-width: 95%;
}
.item-label ::v-deep .icon {
  right: -80%;
}
i.icon-clear {
  margin-right: 2%;
  position: absolute;
  // top: calc(100vh - 885px);
  top: calc(10px);
  right: 0%;
}
.list ::v-deep ul::before,
.list ::v-deep ul::after {
  display: none;
}

.list ::v-deep ul {
  border-radius: 4px;
  border-left: solid 4px red;
}
.list ::v-deep .item-label {
  font-size: 14px;
  font-weight: bold;
  max-width: 95%;
}
.item-label ::v-deep .icon {
  right: -80%;
}
</style>
