import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/time_tracking`,
    "timeTracking"
  );

  return {
    bindTimeTrackingBys: ActionsTemplate.bindCollectionBys,
    updateTimeTracking: ActionsTemplate.updateDocument,
    unbindTimeTracking: ActionsTemplate.unbindCollection,
    getTimeTrackingBys: ActionsTemplate.getDocumentBys,

    async getTimeTrackingList({ commit, dispatch }, payload) {
      const timeTrackingList = await dispatch("getTimeTrackingBys", payload);
      await commit(types.SET_TIME_TRACKING_LIST, timeTrackingList);
      return timeTrackingList;
    },

    changeTimeTrackingItem({ commit }, { id, item }) {
      commit(types.CHANGE_TIME_TRACKING_ITEM, { id, item });
    },

    setDatesToFilterQB({ commit }, payload) {      
      commit(types.SET_DATES_TO_FILTER_QB, payload);
    },
  };
};
