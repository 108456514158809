import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "HR",
    displayRoles: ["owner", "user"],
    name: "hr-main",
    routes: [
      {
        title: "Timer",
        name: "timer-main",
        displayRoles: ["owner", "user"],
        parent: "hr-main",
        icon: "timer",
        notALink: true,
        isShowMenuMobile: true,
        component: () =>
          import("../components/popups/AddNewMyTimeTracking.vue"),
        action: store =>
          store.commit("time-tracking/time-tracking/openTimerPopup")
      },
      {
        path: "/my-time-log",
        title: "My Time Logs",
        name: "my-time-log",
        parent: "hr-main",
        displayRoles: ["owner", "user"],
        icon: "stopwatch",
        async: checkAuth(() => import("../pages/TimeTracking.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/my-time-log/detail",
            title: "Time tracking",
            async: checkAuth(() => import("../pages/TimeTrackingDetail"))
          }
        ]
      },
      {
        path: "/employees-time-logs",
        title: "Employees Time Logs",
        name: "employees-time-logs",
        parent: "hr-main",
        displayRoles: ["owner", "user"],
        icon: "person_2",
        async: checkAuth(routeTo => {
          if (localStorage.getItem("desktop") === "true") {
            routeTo.route.master = false;
            return import("../pages/EmployeesTimeLogsWeek.vue");
          }
          routeTo.route.master = true;
          return import("../pages/EmployeesTimeLogs.vue");
        }),
        detailRoutes: [
          {
            path: "/employees-time-logs/detail/:id",
            title: "Employees Time Logs Detail ",
            async: checkAuth(() =>
              import("../pages/EmployeesTimeLogDetail.vue")
            )
          }
        ]
      },
      {
        path: "/team-management",
        title: "Team management",
        name: "team-management",
        parent: "hr-main",
        displayRoles: ["owner", "user"],
        icon: "person_2_square_stack",
        async: checkAuth(() => import("../pages/TeamManagement.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/team-management/:id",
            title: "Team Detail ",
            async: checkAuth(() => import("../pages/TeamManagementDetail.vue"))
          }
        ]
      },
      {
        path: "/employee-pay-rate",
        title: "Employee Pay Rate",
        name: "employee-pay-rate",
        parent: "hr-main",
        displayRoles: ["owner", "user"],
        icon: "money_dollar_circle",
        async: checkAuth(() => import("../pages/EmployeePayRate.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/employee-pay-rate/:id",
            title: "Employee Pay Rate Detail",
            async: checkAuth(() => import("../pages/EmployeePayRateDetail.vue"))
          }
        ]
      }
    ]
  }
];
