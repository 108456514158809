<template>
  <div>
    <common-list-section
      title=""
      addBtnTitle="Generate Certification"
      :listItems="certificateList"
      @openAddNewPopup="openAddNewPopup"
      @openEditPopup="openEditPopup"
      @openSendEmailPopup="sendCertificate"
      @getPDF="getPDF"
      @onDelete="onDelete"
    ></common-list-section>

    <add-new-certificate-popup
      ref="addNewCertificatePopup"
      @openSendEmailPopup="sendCertificate"
      @onPreview="onPreview"
    ></add-new-certificate-popup>
    <certificate-preview-popup
      ref="certificatePreviewPopup"
    ></certificate-preview-popup>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send Certification"
      templateName="certification"
      :projectId="card && (card.id || '')"
      :attachments="card && (card.attachmentFiles || [])"
      :listEmails="listEmails"
      @closePopup="closePopupDetail"
    ></send-email-popup>

    <!-- to download -->
    <div style="position: relative;z-index: 0;overflow: hidden">
      <preview-page
        ref="certificatePreviewPageHidden"
        :itemDetail="certificateDetail"
      ></preview-page>
    </div>
  </div>
</template>
<script>
import CommonListSection from "./CommonListSection.vue";
import AddNewCertificatePopup from "../popup/AddNewCertificatePopup.vue";
import CertificatePreviewPopup from "../popup/CertificatePreviewPopup.vue";
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import PreviewPage from "../../pages/PreviewPage.vue";
import sendEmail from "../../mixin/sendEmail";
import { toDisplayDateString } from "../../../../utility/datetime";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CommonListSection,
    AddNewCertificatePopup,
    CertificatePreviewPopup,
    SendEmailPopup,
    PreviewPage
  },
  mixins: [sendEmail],
  data() {
    return {
      certificateDetail: {}
    };
  },
  computed: {
    ...mapGetters("dashboard/certificate", [
      "certificateList",
      "certificateById"
    ]),
    ...mapGetters("dashboard/project", ["card"]),
    ...mapGetters("dashboard/client", ["listEmails"])
  },
  methods: {
    ...mapActions("dashboard/certificate", [
      "bindCertificateListBys",
      "updateCertificate",
      "createCertificate"
    ]),

    initData(projectId) {
      if (projectId) {
        this.bindCertificateListBys([
          {
            prop: "projectId",
            val: projectId,
            op: "=="
          },
          {
            prop: "isDeleted",
            val: false,
            op: "=="
          }
        ]);
      }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },
    openAddNewPopup() {
      this.$refs.addNewCertificatePopup.open();
    },
    openEditPopup(id) {
      this.$refs.addNewCertificatePopup.open(id);
    },

    getPDF(id) {
      const certificate = this.certificateById(id);
      this.certificateDetail = certificate;
      this.$nextTick(() => {
        this.$refs.certificatePreviewPageHidden.exportPdf();
      });
    },

    onDelete({ id, callback }) {
      this.$ri.dialog
        .openWarningDialog({
          title: "Delete Certificate",
          content: "Are you sure you want to delete this certificate?",
          textButton: "Delete",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.$f7.preloader.show();
              this.updateCertificate({
                id: id,
                doc: { isDeleted: true }
              }).then(() => {
                this.$f7.preloader.hide();
                callback && callback();
              });
            }
          }
        })
        .open();
    },

    onPreview(itemDetail) {
      this.$refs.certificatePreviewPopup.open(itemDetail);
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    },
    closePopupDetail() {
      this.$refs.addNewCertificatePopup.close();
    }
  }
};
</script>
