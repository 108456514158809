import { getFullAddress } from "@/utility/address";

export default {
  listEmails: state => {
    var listEmails = [];
    //email from contact
    state.contactList &&
      state.contactList.forEach(contact => {
        let emails = contact.emails;
        emails &&
          emails.forEach(email => {
            if (!listEmails.includes(email.value)) {
              listEmails.push(email.value);
            }
          });
      });

    //email from company
    state.companyList &&
      state.companyList.forEach(company => {
        let others = company.others;
        others &&
          others.forEach(other => {
            if (other.code == "email" && !listEmails.includes(other.value)) {
              listEmails.push(other.value);
            }
          });
      });

    //email from useers
    state.users &&
      state.users.forEach(user => {
        if (!listEmails.includes(user.email)) {
          listEmails.push(user.email);
        }
      });

    return listEmails;
  },
  contactList: ({ contactList }) =>
    contactList.map(r => {
      const { phones, addresses } = r;
      return {
        ...r,
        id: r.id,
        companyId:
          r.companyRefs && r.companyRefs.length > 0 ? r.companyRefs[0].id : "",
        contactName: `${r.firstName} ${r.lastName}`,
        phoneNumber: ((phones || [])[0] || {}).value,
        address: getFullAddress((addresses || [])[0]),
        email: (r.others || []).find(r => r.code == "email")
          ? (r.others || []).find(r => r.code == "email").value
          : ""
      };
    }),
  contactGroup: (_, getters) => (searchValue = "") => {
    let group = [];
    let contacts =
      searchValue != ""
        ? getters.contactList.filter(contact => {
            let contactFirstName = contact.firstName
              ? contact.firstName.toLowerCase()
              : "";
            let contactLastName = contact.lastName
              ? contact.lastName.toLowerCase()
              : "";

            let contactName = contactFirstName + " " + contactLastName;
            return (
              contactFirstName.indexOf(searchValue.toLowerCase()) >= 0 ||
              contactLastName.indexOf(searchValue.toLowerCase()) >= 0 ||
              contactName.trim().indexOf(searchValue.toLowerCase()) >= 0
            );
          }) || []
        : getters.contactList;

    contacts.forEach(contact => {
      let letter = contact.firstName
        ? contact.firstName.charAt(0).toUpperCase()
        : "" || contact.lastName
        ? contact.lastName.charAt(0).toUpperCase()
        : "";
      let index = group.findIndex(groupName => {
        return letter === Object.keys(groupName)[0];
      });
      if (index >= 0) {
        group[index][letter].push(contact);
      } else {
        group.push({ [letter]: [contact] });
      }
    });

    group.forEach(data => {
      let letter = Object.keys(data)[0];
      data[letter].sort((a, b) => {
        return (a.firstName ? a.firstName.charAt(1).toUpperCase() : 0) <
          (b.firstName ? b.firstName.charAt(1).toUpperCase() : 0)
          ? -1
          : 0;
      });
    });

    return group.sort((a, b) => {
      return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
    });
  }
};
