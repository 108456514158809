import Vue from "vue";

export default {
    setRoofTypeList(state, payload) {
        Vue.set(state, "roofTypeList", payload);
    },
    setRoofTypeSubscribe(state, payload) {
        Vue.set(state, "roofTypeSubscribe", payload);
    },
};
