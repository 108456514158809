<template>
  <div
    class="data-table-footer"
    :class="$device.desktop ? '' : 'mobile-container'"
    v-if="footerProps && footerProps.pageSizeOption && totals > 0"
  >
    <div class="data-table-rows-select">
      Per page:
      <div class="input input-dropdown">
        <select @change="changeRowPerPage($event.target.value)">
          <option
            v-for="perPage in footerProps.pageSizeOption"
            :key="perPage"
            :value="perPage"
            >{{ perPage }}</option
          >
        </select>
      </div>
    </div>
    <div
      class="data-table-pagination"
      :style="$device.desktop ? '' : 'margin-left:-20px'"
    >
      <span
        v-if="
          pagination.page <= 1 || pagination.totalItems === pagination.pageSize
        "
        :class="$device.desktop ? 'margin-left' : ''"
        class="display-flex align-items-center margin-left color-gray"
        disabled
        ><f7-icon f7="chevron_left"></f7-icon>First</span
      >
      <a
        v-else
        @click="$emit('onGoToPage', 'first')"
        :class="$device.desktop ? 'margin-left' : ''"
        class="display-flex align-items-center margin-left "
        disabled
        ><f7-icon f7="chevron_left"></f7-icon>First</a
      >

      <span
        v-if="
          pagination.page <= 1 || pagination.totalItems === pagination.pageSize
        "
        class="margin-left color-gray"
        >Previous</span
      >
      <a
        v-else
        @click="$emit('paginationNextPrev', 'prevPage')"
        class="margin-left"
        >Previous</a
      >

      <span class="data-table-pagination-label margin-left">
        {{ pageStart }} - {{ pageStop }} of {{ totals }}
      </span>

      <span
        v-if="Math.ceil(totals / pagination.pageSize) <= pagination.page"
        class="margin-left color-gray"
        >Next</span
      >
      <a
        v-else
        @click="$emit('paginationNextPrev', 'nextPage')"
        class="margin-left"
        >Next</a
      >

      <span
        v-if="Math.ceil(totals / pagination.pageSize) <= pagination.page"
        class="display-flex align-items-center margin-left color-gray"
        >Last<f7-icon f7="chevron_right"></f7-icon
      ></span>
      <a
        v-else
        @click="$emit('onGoToPage', 'last')"
        class="display-flex align-items-center margin-left"
        >Last<f7-icon f7="chevron_right"></f7-icon
      ></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    footerProps: Object,
    totals: {
      type: Number,
      default: 5
    },
    pagination: Object
  },
  computed: {
    pageStart() {
      var countStart =
        (this.pagination.page - 1) * this.pagination.pageSize + 1 || 1;
      return countStart > this.pageStop ? this.pageStop : countStart;
    },
    pageStop() {
      var countStop = this.pagination.page * this.pagination.pageSize;
      return countStop > this.totals
        ? countStop - (countStop - this.totals)
        : countStop;
    }
  },
  methods: {
    // Select option Row Per Page
    changeRowPerPage(value) {
      this.$emit("changeRowPerPage", {
        ...this.pagination,
        pageSize:
          value === "All"
            ? parseInt(this.pagination.totalItems)
            : parseInt(value),
        page:
          value === "All"
            ? Math.ceil(this.totals / this.pagination.pageSize)
            : 1
      });
    }
  }
};
</script>

<style>
.mobile-container {
  justify-content: center;
  flex-direction: column-reverse;
}
.mobile-container > * {
  padding: 10px 0;
}
</style>
