<template>
  <div>
    <f7-list class="no-margin">
      <f7-list-input type="datepicker" label="Due Date" :calendar-params="{
        backdrop: true,
        header: true,
        footer: false,
        openIn: 'customModal',
        dateFormat: 'mm/dd/yyyy',
        disabled: {
          from: null,
          to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
        }
      }" :value="dueDate" placeholder="Enter due date" @calendar:change="
        onUpdateDate($event);
      $f7.calendar.close();
      ">
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list>
      <f7-list-input label="Roofer’s Xactimate" type="text" v-model="xactimateBudget"
        placeholder="Enter roofer’s xactimate budget" error-message-force
        :error-message="requireErrorMessage('xactimateBudget')"
        @input="formatBudget('xactimateBudget', $event.target.value.trim())"
        @blur="onUpdateBudget('xactimateBudget', $event.target.value.trim())">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="money_dollar_circle"></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-block-header>Insurance’s Approved Budget</f7-block-header>
    <f7-list>
      <f7-list-input label="Approval Status" :value="approvalStatus" type="select"
        @input="onUpdateCard('approvalStatus', $event.target.value)">
        <input-icon slot="media" icon="person" color="primary"></input-icon>
        <option v-for="(type, index) in insuranceApprovalStatusList" :key="index" :value="type.value">
          {{ type.displayName }}
        </option>
      </f7-list-input>
      <f7-list-input v-if="approvalStatus === 'approved'" label="Approval Amount" type="text" v-model="insuranceBudget"
        placeholder="Enter insurance approved amount" error-message-force
        :error-message="requireErrorMessage('insuranceBudget')"
        @input="formatBudget('insuranceBudget', $event.target.value.trim())"
        @blur="onUpdateBudget('insuranceBudget', $event.target.value.trim())">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="money_dollar_circle"></input-icon>
      </f7-list-input>
      <f7-list-input v-if="approvalStatus === 'supplement-needed'" label="Insurance Initial Approval Amount" type="text"
        v-model="insuranceInitialAmount" placeholder="Enter insurance initial approved amount" @input="
          formatBudget('insuranceInitialAmount', $event.target.value.trim())
          " @blur="
            onUpdateBudget('insuranceInitialAmount', $event.target.value.trim())
            ">
        <input-icon slot="media" icon="money_dollar_circle"></input-icon>
      </f7-list-input>
      <f7-list-input v-if="approvalStatus === 'supplement-needed'" type="datepicker" label="Supplement Submitted Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }" :value="supplementSubmittedDate" placeholder="Enter supplement submitted date" @calendar:change="
          if ($event && $event !== supplementSubmittedDate) {
          saveSubmittedDate($event);
          $f7.calendar.close();
        }
          " clear-button @input:clear="clearDate">
        <input-icon slot="media" icon="calendar"></input-icon>
      </f7-list-input>
      <f7-list-input v-if="approvalStatus === 'supplement-needed'" label="Supplement Amount" type="text"
        v-model="supplementAmount" placeholder="Enter supplement amount"
        @input="formatBudget('supplementAmount', $event.target.value.trim())"
        @blur="onUpdateBudget('supplementAmount', $event.target.value.trim())">
        <input-icon slot="media" icon="money_dollar_circle"></input-icon>
      </f7-list-input>
      <f7-list-input v-if="approvalStatus === 'supplement-needed'" label="Insurance Final Approval Amount" type="text"
        v-model="insuranceFinalAmount" placeholder="Enter insurance final approval amount" error-message-force
        :error-message="requireErrorMessage('insuranceFinalAmount')" @input="
          formatBudget('insuranceFinalAmount', $event.target.value.trim())
          " @blur="
            onUpdateBudget('insuranceFinalAmount', $event.target.value.trim())
            ">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="money_dollar_circle"></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list accordion-list class="accordion-insurance">
      <f7-list-item accordion-item class="accordion-insurance-item">
        <div class="title-accordion" slot="title">Insurance Information</div>
        <f7-accordion-content>
          <f7-list media-list>
            <f7-list-item>
              <div class="list-item-inner-start" slot="inner-start">
                Insurance Company
              </div>
              <div class="list-item-title" slot="title">
                {{ companyName }}
              </div>
              <input-icon slot="media" icon="globe"></input-icon>
            </f7-list-item>
            <f7-block-header class="margin-top-half">Agent Information</f7-block-header>
            <f7-list media-list>
              <f7-list-input type="text" placeholder="Enter agent name" label="Name" clear-button :value="agent.name"
                @input="agent.name = $event.target.value.trim()" @blur="
                  onUpdateObject('agent', 'name', $event.target.value.trim())
                  ">
                <input-icon slot="media" icon="person"></input-icon>
              </f7-list-input>
              <f7-list-input label="Phone" placeholder="Enter agent phone" :value="agent.phone"
                @input="agent.phone = $event.target.value.trim()" @blur="
                  onUpdateObject('agent', 'phone', $event.target.value.trim())
                  " v-mask="'(+1) ###-###-####'" error-message-force :error-message="phoneErrorMessage('agent')">
                <input-icon slot="media" icon="phone"></input-icon>
              </f7-list-input>
              <f7-list-input label="Email" placeholder="Enter agent email" :value="agent.email"
                @input="agent.email = $event.target.value.trim()" @blur="
                  onUpdateObject('agent', 'email', $event.target.value.trim())
                  " error-message-force :error-message="emailErrorMessage('agent')">
                <input-icon slot="media" icon="envelope"></input-icon>
              </f7-list-input>
            </f7-list>
            <f7-block-header class="margin-top-half">Desk Adjuster Information</f7-block-header>
            <f7-list media-list>
              <f7-list-input type="text" placeholder="Enter desk adjuster name" label="Name" clear-button
                :value="deskAdjuster.name" @input="deskAdjuster.name = $event.target.value.trim()" @blur="
                  onUpdateObject(
                    'deskAdjuster',
                    'name',
                    $event.target.value.trim()
                  )
                  ">
                <input-icon slot="media" icon="person"></input-icon>
              </f7-list-input>
              <f7-list-input label="Phone" placeholder="Enter desk adjuster phone" :value="deskAdjuster.phone"
                @input="deskAdjuster.phone = $event.target.value.trim()" @blur="
                  onUpdateObject(
                    'deskAdjuster',
                    'phone',
                    $event.target.value.trim()
                  )
                  " v-mask="'(+1) ###-###-####'" error-message-force
                :error-message="phoneErrorMessage('deskAdjuster')">
                <input-icon slot="media" icon="phone"></input-icon>
              </f7-list-input>
              <f7-list-input label="Email" placeholder="Enter desk adjuster email" :value="deskAdjuster.email"
                @input="deskAdjuster.email = $event.target.value.trim()" @blur="
                  onUpdateObject(
                    'deskAdjuster',
                    'email',
                    $event.target.value.trim()
                  )
                  " error-message-force :error-message="emailErrorMessage('deskAdjuster')">
                <input-icon slot="media" icon="envelope"></input-icon>
              </f7-list-input>
            </f7-list>
            <f7-block-header class="margin-top-half">Field Adjuster Information</f7-block-header>
            <f7-list media-list>
              <f7-list-input type="text" placeholder="Enter field adjuster name" label="Name" clear-button
                :value="fieldAdjuster.name" @input="fieldAdjuster.name = $event.target.value.trim()" @blur="
                  onUpdateObject(
                    'fieldAdjuster',
                    'name',
                    $event.target.value.trim()
                  )
                  ">
                <input-icon slot="media" icon="person"></input-icon>
              </f7-list-input>
              <f7-list-input label="Phone" placeholder="Enter field adjuster phone" :value="fieldAdjuster.phone"
                @input="fieldAdjuster.phone = $event.target.value.trim()" @blur="
                  onUpdateObject(
                    'fieldAdjuster',
                    'phone',
                    $event.target.value.trim()
                  )
                  " v-mask="'(+1) ###-###-####'" error-message-force
                :error-message="phoneErrorMessage('fieldAdjuster')">
                <input-icon slot="media" icon="phone"></input-icon>
              </f7-list-input>
              <f7-list-input label="Email" placeholder="Enter field adjuster email" :value="fieldAdjuster.email"
                @input="fieldAdjuster.email = $event.target.value.trim()" @blur="
                  onUpdateObject(
                    'fieldAdjuster',
                    'email',
                    $event.target.value.trim()
                  )
                  " error-message-force :error-message="emailErrorMessage('fieldAdjuster')">
                <input-icon slot="media" icon="envelope"></input-icon>
              </f7-list-input>
            </f7-list>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
      add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"></attachment-input>
    <user-input @input="setAssigneeIDs($event)" :value="displayCard.assigneeIDs"></user-input>
    <note-input :value="displayCard.commentAndNote" @change="saveNote($event)"></note-input>
  </div>
</template>
<script>
import methodsMixins from "../../mixin/methods";
import residentialMixins from "../../mixin/residential";
import AttachmentInput from "../input/AttachmentInput.vue";
import NoteInput from "../input/NoteInput.vue";
import UserInput from "../input/UserInput.vue";
import InputIcon from "../icon/InputIcon.vue";
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from "@vuelidate/validators";
import _ from "lodash";
import { firebase, auth } from "../../../../services/firebase.service";
import { toDateCalendar, toDateFirebase } from "../../../../utility/datetime";
import { mask } from "vue-the-mask";
import moment from "moment";
import { COMPANY_TYPE_INSURANCE, VALIDATION_MESSAGE } from "../../../../utility/const";

export default {
  components: {
    AttachmentInput,
    UserInput,
    NoteInput,
    InputIcon
  },
  mixins: [methodsMixins, residentialMixins],
  directives: { mask },
  data: () => {
    return {
      insuranceBudget: "",
      xactimateBudget: "",
      insuranceInitialAmount: "",
      insuranceFinalAmount: "",
      supplementAmount: "",
      approvalStatus: "processing",
      currentResponse: {},
      displayCard: {},
      dueDate: [],
      popupOpened: false,
      agent: {
        name: "",
        phone: "",
        email: ""
      },
      deskAdjuster: {
        name: "",
        phone: "",
        email: ""
      },
      fieldAdjuster: {
        name: "",
        phone: "",
        email: ""
      },
      supplementSubmittedDate: []
    };
  },

  computed: {
    ...mapGetters("dashboard/project", ["card"]),
    ...mapGetters("dashboard/app-constant", ["insuranceApprovalStatusList"]),
    ...mapGetters("dashboard/client", ["insuranceCompany"]),

    companyName() {
      return this.insuranceCompany ? this.insuranceCompany.companyName : "";
    },

    requireErrorMessage() {
      return (parent, children) => {
        if (children) {
          if (!this.v$[parent][children].$error) return null;
          if (this.v$[parent][children].required.$invalid)
            return VALIDATION_MESSAGE.REQUIRED_FIELD;
        } else {
          if (!this.v$[parent].$error) return null;
          if (this.v$[parent].required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
        }
        return null;
      };
    },
    emailErrorMessage() {
      return parent => {
        if (!this.v$[parent].email.$error) return null;
        if (this.v$[parent].email.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
        return null;
      };
    },

    phoneErrorMessage() {
      return parent => {
        if (!this.v$[parent].phone.$error) return null;
        if (this.v$[parent].phone.minLength.$invalid)
          return VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT;
        // if (this.v$[parent].phone.commonPhoneRule.$invalid)
        //   return VALIDATION_MESSAGE.INVALID_PHONE_NUMBER;
        return null;
      };
    },

    COMPANY_TYPE_INSURANCE() {
      return COMPANY_TYPE_INSURANCE;
    }
  },
  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        if (
          this.displayCard.dueDate &&
          (_.isEmpty(this.dueDate) ||
            (!_.isEmpty(this.dueDate) &&
              !moment(this.dueDate[0]).isSame(
                moment(toDateCalendar(this.displayCard.dueDate)[0]),
                "day"
              )))
        ) {
          this.dueDate = toDateCalendar(this.displayCard.dueDate);
        }
        if (this.displayCard.xactimateBudget) {
          this.formatBudget(
            "xactimateBudget",
            this.displayCard.xactimateBudget.toString()
          );
        }
        if (this.displayCard.insuranceBudget) {
          this.formatBudget(
            "insuranceBudget",
            this.displayCard.insuranceBudget.toString()
          );
        }
        if (this.displayCard.insuranceInitialAmount) {
          this.formatBudget(
            "insuranceInitialAmount",
            this.displayCard.insuranceInitialAmount.toString()
          );
        }
        if (this.displayCard.supplementAmount) {
          this.formatBudget(
            "supplementAmount",
            this.displayCard.supplementAmount.toString()
          );
        }
        if (this.displayCard.insuranceFinalAmount) {
          this.formatBudget(
            "insuranceFinalAmount",
            this.displayCard.insuranceFinalAmount.toString()
          );
        }
        if (this.displayCard.fieldInspection === "denial") {
          this.approvalStatus = "denied";
        }
        if (this.displayCard.approvalStatus) {
          this.approvalStatus = this.displayCard.approvalStatus;
        }

        this.agent = {
          name: "",
          phone: "",
          email: "",
          ...(this.displayCard.agent || {})
        };
        this.deskAdjuster = {
          name: "",
          phone: "",
          email: "",
          ...(this.displayCard.deskAdjuster || {})
        };
        this.fieldAdjuster = {
          name: "",
          phone: "",
          email: "",
          ...(this.displayCard.fieldAdjuster || {})
        };
        if (this.displayCard.supplementSubmittedDate) {
          this.supplementSubmittedDate = toDateCalendar(
            this.displayCard.supplementSubmittedDate
          );
        }
      }
    },

    formatBudget(prop, val) {
      this[prop] = val ? this.formatPriceNumber(val) : "";
    },

    onUpdateObject(key, prop, value) {
      if (prop != "name") {
        this.v$[key][prop].$touch();
        if (this.v$[key][prop].$invalid) return;
        this[key][prop] = value;
        this.onUpdateCard(key, this[key]);
      } else {
        this[key][prop] = value;
        this.onUpdateCard(key, this[key]);
      }
    },

    onUpdateBudget(prop, val) {
      if (prop === "insuranceInitialAmount" || prop === "supplementAmount") {
        const cost = this.formatDots(val)
          .replaceAll("$", "")
          .replaceAll(",", "");
        this.onUpdateCard(prop, parseFloat(cost) || null);
      } else {
        this.v$[prop].$touch();
        if (this.v$[prop].$invalid) return;
        const cost = this.formatDots(val)
          .replaceAll("$", "")
          .replaceAll(",", "");
        this.onUpdateCard(prop, parseFloat(cost) || null);
      }
    },

    onUpdateDate(value) {
      if (!_.isEmpty(value)) {
        if (
          _.isEmpty(this.dueDate) ||
          (!_.isEmpty(this.dueDate) &&
            !moment(value[0]).isSame(moment(this.dueDate[0]), "day"))
        ) {
          this.dueDate = value;
          this.onUpdateCard("dueDate", toDateFirebase(value));
        }
      }
    },

    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value
        }
      }).finally(() => {
        isShowLoading && this.$f7.preloader.hide();
      });
    },

    // Implement do something before resolve action
    doAction(response) {
      if (response.code === "move-to-proposal") {
        this.v$.$touch();
        if (this.v$.$invalid) return;
        this.currentResponse = response;
        this.displayCard.dueDate = "";
        this.resolve();
      }
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      let doc = {};
      doc.approvalStatus = this.approvalStatus;
      doc.dueDate = toDateFirebase(this.displayCard.dueDate);
      await this.updateCard({
        id: this.card.id,
        doc
      }).then(() => {
        this.displayCard = {};
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote =
          _.cloneDeep(this.displayCard.commentAndNote) || [];
        commentAndNote.push({
          code: "note",
          title: "Insurance Review Note",
          htmlContent: event,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

        this.onUpdateCard("commentAndNote", commentAndNote);
      }
    },

    openFileChooser() {
      this.$refs.fileInput.click();
    },
    saveSubmittedDate(value) {
      this.supplementSubmittedDate = value;
      this.onUpdateCard("supplementSubmittedDate", toDateFirebase(value));
    },
    clearDate() {
      this.supplementSubmittedDate = [];
      this.updateCard({
        id: this.displayCard.id,
        doc: { supplementSubmittedDate: "" }
      });
    }
  },
  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      xactimateBudget: {
        required
      },
      insuranceBudget: {
        required(val) {
          if (this.approvalStatus !== "approved") return true;
          return !!val;
        }
      },
      insuranceFinalAmount: {
        required(val) {
          if (this.approvalStatus !== "supplement-needed") return true;
          return !!val;
        }
      },
      agent: {
        phone: { minLength: minLength(17) },
        email: {
          commonEmailRule: email
        }
      },
      deskAdjuster: {
        phone: { minLength: minLength(17) },
        email: {
          commonEmailRule: email
        }
      },
      fieldAdjuster: {
        phone: { minLength: minLength(17) },
        email: {
          commonEmailRule: email
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.accordion-insurance ::v-deep {
  margin-top: -5px;

  & ul:before {
    height: 0px;
  }
}

.title-accordion {
  color: var(--f7-block-header-text-color);
}
</style>
