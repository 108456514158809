<template>
  <f7-list
    class="address-input"
    @keydown.native="onKeydown"
    @keyup.native="onKeyup"
  >
    <f7-list-input
      id="address"
      :value="address.address"
      @input="searchAddress($event.target.value)"
      @focus="geolocate"
      @blur="blurAddressSearch($event.target.value)"
      placeholder="Address"
      clear-button
      error-message-force
      :error-message="addressErrorMessage"
      class="contact-address-input"
      :ref="`address-input-${address.code}`"
    >
      <div slot="label">
        <f7-link
          :popover-open="
            addressTypeOptions.length ? `.popover-address-type-${id}` : false
          "
          @focus.native="onLinkFocus(address.code)"
          @click="$emit('open-address-type', index)"
          >{{ address.title }}</f7-link
        >
        <f7-icon
          style="float: right; margin-top: 4px"
          size="18"
          color="gray"
          f7="chevron_right"
        ></f7-icon>
      </div>
      <f7-icon
        class="cursor-pointer"
        color="red"
        f7="minus_circle_fill"
        slot="media"
        @click.native="deleteAddress(index)"
      ></f7-icon>
    </f7-list-input>
    <f7-list-input
      :value="address.city"
      @input="address.city = $event.target.value"
      placeholder="City"
      clear-button
      error-message-force
      :error-message="cityErrorMessage"
      @blur="$emit('touchToValidation')"
    >
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input
      :value="address.state"
      @input="address.state = $event.target.value"
      placeholder="State"
      clear-button
      error-message-force
      :error-message="stateErrorMessage"
      @blur="$emit('touchToValidation')"
    >
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input
      :value="address.zipcode"
      @input="address.zipcode = $event.target.value"
      placeholder="ZIP Code"
      clear-button
      error-message-force
      :error-message="zipcodeErrorMessage"
      @blur="$emit('touchToValidation')"
    >
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input
      :value="address.country"
      @input="address.country = $event.target.value"
      placeholder="Country"
      clear-button
      error-message-force
      :error-message="countryErrorMessage"
      @blur="$emit('touchToValidation')"
    >
      <div class="space" slot="media"></div>
    </f7-list-input>
    <div
      class="dropdown-content"
      v-if="predictions.length > 0 && showPredictions"
    >
      <auto-complete-list
        :items="predictions"
        @item:click="onItemClick"
        @item:blur="onItemBlur"
      />
    </div>
  </f7-list>
</template>
<script>
import { googlePlaceApiMixin } from "@/services/place.google.service";
import AutoCompleteList from "./AutoCompleteList";
import { VALIDATION_MESSAGE } from '@/utility/const';

const componentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "short_name",
  country: "long_name",
  postal_code: "short_name",
  neighborhood: "short_name"
};

const KEYCODE = {
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  TAB: 9
};

export default {
  components: {
    AutoCompleteList
  },
  props: {
    id: String,
    address: { type: Object, default: () => {} },
    index: { type: Number, default: () => "" },
    validationResult: { type: Object, default: () => {} },
    addressTypeOptions: { type: Array, default: () => [] }
  },
  mixins: [googlePlaceApiMixin],
  data: () => {
    return {
      predictions: [],
      showPredictions: false,
      isKeyUpOrDown: false,
      isSelectSearch: false
    };
  },
  computed: {
    addressErrorMessage() {
      if (this.validationResult.$each.$response.$errors[this.index].address.length === 0) return null;
      return this.validationResult.$each.$response.$errors[this.index].address[0].$message || ''
    },
    cityErrorMessage() {
      if (this.validationResult.$each.$response.$errors[this.index].city.length === 0) return null;
      return this.validationResult.$each.$response.$errors[this.index].city[0].$message || ''
    },
    stateErrorMessage() {
      if (this.validationResult.$each.$response.$errors[this.index].state.length === 0) return null;
      return this.validationResult.$each.$response.$errors[this.index].state[0].$message || ''
    },
    zipcodeErrorMessage() {
      if (this.validationResult.$each.$response.$errors[this.index].zipcode.length === 0) return null;
      return this.validationResult.$each.$response.$errors[this.index].zipcode[0].$message || ''
    },
    countryErrorMessage() {
      if (this.validationResult.$each.$response.$errors[this.index].country.length === 0) return null;
      return this.validationResult.$each.$response.$errors[this.index].country[0].$message || ''
    }
  },
  methods: {
    onLinkFocus(code) {
      // console.log(this.$refs[`address-input-${code}`].$el);
      this.$refs[`address-input-${code}`].$el
        .querySelector(".contact-address-input input")
        .focus();
    },

    deleteAddress(index) {
      this.$emit("delete-address", index);
    },
    getInputElement() {
      return this.$el.querySelector("#address input");
    },
    select(place) {
      this.geocode({ placeId: place.place_id }).then(response => {
        this.fillData(response[0]);
      });
    },
    fillData(place) {
      let address_components = place.address_components;
      let values = [];
      for (const component of address_components) {
        const addressType = component.types[0];

        if (componentForm[addressType]) {
          const val = component[componentForm[addressType]];
          values.push({ key: addressType, value: val });
        }
      }

      this.address.city = (
        values.find(r => r.key == "locality") || { value: "" }
      ).value;
      this.address.zipcode = (
        values.find(r => r.key == "postal_code") || { value: "" }
      ).value;
      this.address.country = (
        values.find(r => r.key == "country") || { value: "" }
      ).value;
      this.address.state = (
        values.find(r => r.key == "administrative_area_level_1") || {
          value: ""
        }
      ).value;
      this.address.address = this.getAddressDetail(values);
    },
    getAddressDetail(values) {
      let street_number = (
        values.find(r => r.key == "street_number") || { value: "" }
      ).value;
      let route = (values.find(r => r.key == "route") || { value: "" }).value;
      return `${street_number} ${route}`;
    },
    hide() {
      this.showPredictions = false;
    },

    show() {
      this.showPredictions = true;
    },
    onFocus(event) {
      if (this.query) {
        if (!this.predictions.length) {
          this.onKeyup(event);
        }

        this.show();
      }
    },

    onBlur(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.hide();
      }
    },

    onItemBlur(event) {
      this.onBlur(event);
    },

    onItemClick(event, child) {
      this.select(child.item);
      this.isSelectSearch = true;
      this.predictions = [];
    },
    async geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          return geolocation;
        });
      }
    },
    getRequestOptions() {
      const options = {
        input: this.getInputElement().value,
        types: ["geocode"]
      };
      return options;
    },
    search() {
      return new Promise((resolve, reject) => {
        if (!this.getInputElement().value) {
          this.predictions = [];
          this.hide();
        } else {
          this.$autoComplete.getPlacePredictions(
            this.getRequestOptions(),
            (response, status) => {
              this.showActivityIndicator = false;
              switch (status) {
                case window.google.maps.places.PlacesServiceStatus.OK:
                  resolve(response);
                  break;
                default:
                  reject(new Error(`Error with status: ${status}`));
              }
            }
          );
        }
      });
    },
    async searchAddress(value) {
      this.address.address = value;
      this.show();
      this.search().then(
        response => {
          this.predictions = response;
          this.showPredictions = true;
        },
        error => {
          if (error) {
            this.predictions = false;
          }
        }
      );
    },
    blurAddressSearch(value) {
      if (!this.isKeyUpOrDown) {
        this.$emit("touchToValidation");
        setTimeout(() => {
          this.hide();
          if (!this.isSelectSearch) {
            this.address.address = value;
          }
          this.isSelectSearch = false;
        }, 500);
      }
    },
    up() {
      const focused = this.$el.querySelector("a:focus");
      if (focused && focused.parentElement.previousElementSibling) {
        focused.parentElement.previousElementSibling.querySelector("a").focus();
      } else {
        const links = this.$el.querySelectorAll("a");
        links[links.length - 1].focus();
      }
    },

    down() {
      const focused = this.$el.querySelector("a:focus");

      if (focused && focused.parentElement.nextElementSibling) {
        focused.parentElement.nextElementSibling.querySelector("a").focus();
      } else {
        this.$el.querySelector(".dropdown-content a").focus();
      }
    },

    onKeydown(event) {
      this.isKeyUpOrDown = true;
      const element = this.$el.querySelector("[tabindex]");

      if (element && event.keyCode === KEYCODE.TAB) {
        event.preventDefault() && element.focus();
      }
      this.isKeyUpOrDown = false;
    },

    onKeyup(event) {
      this.isKeyUpOrDown = true;
      switch (event.keyCode) {
        case KEYCODE.ENTER:
        case KEYCODE.SPACE:
          if (this.$el.querySelector(".is-focused")) {
            this.$el
              .querySelector(".is-focused a")
              .dispatchEvent(new Event("mousedown"));
          }
          return;
        case KEYCODE.ESC:
          this.hide();
          this.getInputElement().blur();
          event.preventDefault();
          return;
        case KEYCODE.UP:
          this.up();
          event.preventDefault();
          return;
        case KEYCODE.DOWN:
          this.down();
          event.preventDefault();
          return;
      }

      this.search().then(
        response => {
          this.predictions = response;
        },
        error => {
          if (error) {
            this.predictions = [];
          }
        }
      );

      this.isKeyUpOrDown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  position: absolute;
  background-color: #f6f6f6;
  width: 85%;
  min-width: 200px;
  max-width: 600px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  top: 61px;
  left: 40px;
  background-color: #fff;
}
.row {
  background-color: #fff;
}
.address-input {
  margin: 0;
}
.space {
  width: 1em;
  height: 1em;
}
</style>
<style>
.address-input.list ul::after {
  display: none;
}
</style>
