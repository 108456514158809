import _ from "lodash";

export default {
  teamList: state =>
    _.cloneDeep(state.teamList || []).sort((a, b) =>
      sortBy(a.teamName, b.teamName)
    )
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
