<template>
  <f7-page>
    <f7-tabs animated>
      <f7-tab
        ref="tab-action"
        :id="'-tab-1'"
        tab-active
        :class="modelName === 'project' && $device.desktop ? 'action-tab' : ''"
      >
        <action-tab
          v-if="modelName === 'project'"
          ref="actionTab"
          :afterResolve="afterResolve"
          :popupOpened="popupOpened"
          v-on="$listeners"
        ></action-tab>
        <work-order-tab
          v-if="modelName === 'wo-task'"
          ref="woTab"
          :afterResolve="afterResolveTask"
          v-on="$listeners"
        ></work-order-tab>
      </f7-tab>
      <f7-tab ref="tab-info" :id="'-tab-2'">
        <info-tab ref="infoTab" v-on="$listeners"></info-tab>
      </f7-tab>
      <f7-tab ref="tab-files" :id="'-tab-3'">
        <files-tab ref="filesTab" v-on="$listeners"></files-tab>
      </f7-tab>
      <f7-tab ref="tab-note" :id="'-tab-4'">
        <notes-tab ref="notesTab"></notes-tab>
      </f7-tab>
      <f7-tab ref="tab-finance" :id="'-tab-5'">
        <finance-tab
          :initData="false"
          ref="financeTab"
          v-on="$listeners"
        ></finance-tab>
      </f7-tab>
    </f7-tabs>
    <f7-toolbar tabbar bottom labels>
      <f7-link
        @click="showTab($event, 'tab-action')"
        :tab-link="'#' + '-tab-1'"
        :tab-link-active="tabActive === 'tab-action'"
        text="Actions"
        icon-f7="checkmark_alt_circle_fill"
      ></f7-link>
      <f7-link
        @click="showTab($event, 'tab-info')"
        :tab-link="'#' + '-tab-2'"
        text="Info"
        icon-f7="info_circle_fill"
        :tab-link-active="tabActive === 'tab-info'"
      ></f7-link>
      <f7-link
        @click="showTab($event, 'tab-files')"
        :tab-link="'#' + '-tab-3'"
        text="Files"
        icon-f7="doc_circle_fill"
        :tab-link-active="tabActive === 'tab-files'"
      ></f7-link>
      <f7-link
        @click="showTab($event, 'tab-note')"
        :tab-link="'#' + '-tab-4'"
        text="Notes"
        icon-f7="pencil_circle_fill"
        :tab-link-active="tabActive === 'tab-note'"
      ></f7-link>
      <f7-link
        @click="showTab($event, 'tab-finance')"
        :tab-link="'#' + '-tab-5'"
        text="Finance"
        icon-f7="money_dollar_circle_fill"
        :tab-link-active="tabActive === 'tab-finance'"
      ></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import WorkOrderTab from "../tab/WorkOrderTab.vue";
import ActionTab from "../tab/ActionTab.vue";
import InfoTab from "../tab/InfoTab.vue";
import NotesTab from "../tab/NotesTab.vue";
import FinanceTab from "../tab/FinanceTab.vue";
import FilesTab from "../tab/FilesTab.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ActionTab,
    InfoTab,
    NotesTab,
    FinanceTab,
    FilesTab,
    WorkOrderTab
  },

  props: {
    modelName: String,
    afterResolve: Function,
    afterResolveTask: Function,
    popupOpened: Boolean
  },

  computed: {
    ...mapGetters("dashboard/work-order", ["workOrderAction"]),
    ...mapGetters("dashboard/app-constant", ["tabActive"])
  },

  methods: {
    ...mapActions("dashboard/app-constant", ["setTabActive"]),

    /**
     * Auto show main tab when popup opened
     */
    showMainTab() {
      let activeTab = this.$f7route.query && this.$f7route.query.activeTab;

      if (activeTab) {
        this.setTabActive(activeTab);
        this.$refs[activeTab].show(); //f7 framework buil-in function
        this.showTab(null, activeTab);
      } else if (this.$refs["tab-action"]) {
        this.$refs["tab-action"].show();
        this.setTabActive("tab-action");
        if (this.$refs.actionTab) {
          this.$refs.actionTab.showMainContent();
        }

        if (this.$refs.woTab) {
          this.$refs.woTab.showMainContent();
        }

        this.$refs.filesTab.showMainContent();
        this.$refs.infoTab.showMainContent();
        this.$refs.notesTab.showMainContent();
        this.$refs.financeTab.showMainContent();
      }
    },

    /**
     * Clear data when close popup
     */
    clearActionData() {
      this.$refs.actionTab.clearActionData();
      this.$refs.infoTab.clearActionData();
      this.$refs.filesTab.clearActionData();
      this.$refs.notesTab.clearActionData();
      this.$refs.financeTab.clearActionData();
    },

    /**
     * Handle show tab content when click on tab
     * @param {*} event
     * @param {*} name
     */
    showTab(event, name) {
      if (event) {
        event.preventDefault();
      }
      this.$refs[name].show();
      this.setTabActive(name);

      switch (name) {
        case "tab-action":
          if(this.modelName === 'project'){
            this.$refs.actionTab.init();
          }
          break;
        case "tab-info":
          this.$refs.infoTab.init();
          break;
        case "tab-files":
          this.$refs.filesTab.init();
          break;
        case "tab-note":
          this.$refs.notesTab.init();
          break;
        case "tab-finance":
          this.$refs.financeTab.init();
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.popup-scroll-bar {
  height: 100%;
  overflow-y: auto;
}

.action-tab {
  margin-bottom: calc(var(--f7-button-height) + var(--f7-typography-margin));
}
</style>
