<template>
  <div class="summary padding">
    <!-- sub total -->
    <f7-row class="margin-bottom-half">
      <f7-col width="50">Sub Total</f7-col>
      <f7-col width="50" class="text-align-right">{{
        (invoice.adjustedTotalAmount || subTotal(invoice)) | currencyUSD
      }}</f7-col>
    </f7-row>
    <!-- discount -->
    <f7-row class="margin-bottom-half">
      <f7-col :width="!isEdit ? '40' : '30'"
        >Discount
        <span class="text-color-red fs-small" v-if="isShowItemsDiscoutCount">{{
          `(${itemsDiscoutCount} ${itemsDiscoutCount > 1 ? "items" : "item"})`
        }}</span></f7-col
      >
      <f7-col :width="!isEdit ? '30' : '40'">
        <template v-if="!invoice.syncFromQB">
          <div v-if="!isEdit" class="text-align-center">
            {{ displayCarhOrPercent(invoice, "discount") }}
          </div>
          <div v-else>
            <input-percent-or-cash
              ref="discountInputPercentOrCash"
              inputClass="outline"
              limitPercent
              :value="(invoice.discount || {}).value"
              :type="(invoice.discount || {}).type"
              @inputValue="onChangeSummaryFooter('discount', 'value', $event)"
              @changeType="onChangeSummaryFooter('discount', 'type', $event)"
            ></input-percent-or-cash>
          </div>
        </template>
      </f7-col>
      <f7-col width="30" class="text-align-right"
        >{{ discountAmount(invoice) > 0 ? "-" : ""
        }}{{ discountAmount(invoice) | currencyUSD }}</f7-col
      >
    </f7-row>
    <!-- tax -->
    <f7-row class="margin-bottom-half">
      <f7-col :width="!isEdit ? '40' : '30'"
        >Tax
        <f7-icon
          f7="info_circle_fill"
          size="14"
          class="cursor-pointer tax-info-icon"
        ></f7-icon
      ></f7-col>
      <f7-col :width="!isEdit ? '30' : '40'">
        <template v-if="!invoice.syncFromQB">
          <div v-if="isFromEstimate || !isEdit" class="text-align-center">
            {{ displayCarhOrPercent(invoice, "tax") }}
          </div>
          <div v-else>
            <input-percent-or-cash
              ref="taxInputPercentOrCash"
              inputClass="outline"
              limitPercent
              :value="(invoice.tax || {}).value"
              :type="(invoice.tax || {}).type"
              @inputValue="onChangeSummaryFooter('tax', 'value', $event)"
              @changeType="onChangeSummaryFooter('tax', 'type', $event)"
            ></input-percent-or-cash>
          </div>
        </template>
      </f7-col>
      <f7-col width="30" class="text-align-right">{{
        taxAmount(invoice) | currencyUSD
      }}</f7-col>
    </f7-row>
    <!-- Shipping Charge -->
    <f7-row
      v-if="!invoice.syncFromQB"
      class="margin-bottom-half align-items-center"
    >
      <f7-col :width="!isEdit ? '40' : '30'">Shipping Charge</f7-col>
      <f7-col :width="!isEdit ? '30' : '40'">
        <div v-if="!isEdit" class="text-align-center">
          {{ displayCarhOrPercent(invoice, "shippingCharge") }}
        </div>
        <div v-else>
          <input-percent-or-cash
            ref="shippingChargeInputPercentOrCash"
            inputClass="outline"
            :value="(invoice.shippingCharge || {}).value"
            :type="(invoice.shippingCharge || {}).type"
            @inputValue="
              onChangeSummaryFooter('shippingCharge', 'value', $event)
            "
            @changeType="
              onChangeSummaryFooter('shippingCharge', 'type', $event)
            "
          ></input-percent-or-cash>
        </div>
      </f7-col>
      <f7-col width="30" class="text-align-right">{{
        shippingChargeValue(invoice) | currencyUSD
      }}</f7-col>
    </f7-row>
    <!-- total -->
    <f7-row>
      <f7-col width="50"><b>Total</b></f7-col>
      <f7-col width="50" class="text-align-right">{{
        total(invoice) | currencyUSD
      }}</f7-col>
    </f7-row>
  </div>
</template>
<script>
import InputPercentOrCash from "@/components/inputs/InputPercentOrCash.vue";
import commonMixins from "../../mixins/common-mixin";

export default {
  components: {
    InputPercentOrCash
  },
  mixins: [commonMixins],
  props: {
    invoice: Object,
    isFromEstimate: Boolean,
    isEdit: { type: Boolean, default: true }
  },

  computed: {
    isShowItemsDiscoutCount() {
      if (!this.itemsDiscoutCount) return false;
      return this.invoice?.tableContentType === "itemize"
        ? true
        : this.invoice?.tableContentType === "labor-material"
        ? true
        : this.invoice?.tableContentType === "simple-summary"
        ? true
        : false;
    },
    itemsDiscoutCount() {
      return (
        this.invoice?.itemDetails?.filter(item => item.discount).length || 0
      );
    }
  },
  mounted() {
    this.createTooltip();
  },
  methods: {
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: ".tax-info-icon",
        text: "tax-info-icon"
      });
    },
    inValid() {
      return (
        this.$refs.discountInputPercentOrCash.validate() ||
        this.$refs.taxInputPercentOrCash.validate() ||
        this.$refs.shippingChargeInputPercentOrCash.validate()
      );
    },
    onChangeSummaryFooter(prop, subprop, value) {
      this.$emit("onChangeSummaryFooter", { prop, subprop, value });
    }
  }
};
</script>

<style land="scss" scoped>
.summary {
  background: var(--f7-text-editor-toolbar-bg-color);
  border-radius: 4px;
}
.flex-start {
  justify-content: flex-start;
}
.select-type ::v-deep select {
  min-width: 50px;
}
.fs-small {
  font-size: 0.85em;
}
</style>
