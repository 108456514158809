import {
  FirebaseActions,
  firestore
} from "../../../../services/firebase.service";
import firebase from "firebase/app";
import * as types from "./types";
import _ from "lodash";
import { searchClient } from "../../../../services/search.service";
import algoliaService from "../../../../services/algolia.service";
import {
  COLLECTION_PRODUCT_PRICE_LIST_HISTORY,
  PRICE_LIST_HISTORY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
  PRICE_LIST_HISTORY_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
} from "../../../../utility/const";

const auth = firebase.auth();

export default tenant => {
  const historyAction = new FirebaseActions(
    `/system_client/${tenant}/product_price_list_history`,
    "priceListHistory"
  );
  const historyIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  return {
    getHistory: historyAction.getDocument,
    getHistoryBys: historyAction.getDocumentBys,
    deleteHistory: historyAction.deleteDocument,
    // history fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchHistory(
      { state, commit },
      {
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes,
        pageNumber
      }
    ) {
      const index = historyIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRICE_LIST_HISTORY}`,
        attributesToRetrieve: PRICE_LIST_HISTORY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: PRICE_LIST_HISTORY_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit },
      {
        pageName,
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes
      }
    ) {
      const index = historyIndexDesc;
      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = state.page + 1;
          break;

        case "prev":
          pageNumber = state.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRICE_LIST_HISTORY}`,
        attributesToRetrieve: PRICE_LIST_HISTORY_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: PRICE_LIST_HISTORY_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters = filters;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setHistoryNumberOfRows({ commit }, value) {
      commit(types.SET_NUMBER_OF_ROWS, value);
    },

    setHistorySearchOrder({ commit }, value) {
      commit(types.SET_SEARCH_ORDER, value);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    createHistory(context, history) {
      history.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      history.createdBy = auth.currentUser
        ? auth.currentUser.displayName || auth.currentUser.email
        : "";
      return firestore
        .collection(`/system_client/${tenant}/product_price_list_history`)
        .add(history)
        .then(result => {
          result.update({ id: result.id });
          return result.id;
        })
        .then(async id => {
          await algoliaService.algoliaUpdateItem({
            tenantId: tenant,
            collection: "product_price_list_history",
            id
          });
          return id;
        });
    },

    updateHistory(context, { id, doc }) {
      return firestore
        .collection(`/system_client/${tenant}/product_price_list_history`)
        .doc(id)
        .update(doc)
        .then(() => {
          return algoliaService.algoliaUpdateItem({
            tenantId: tenant,
            collection: "product_price_list_history",
            id
          });
        });
    },

    async deleteHistoryBys({ dispatch }, { priceListId, productId }) {
      const list = await dispatch("getHistoryBys", [
        {
          prop: "priceListId",
          val: priceListId,
          op: "=="
        },
        {
          prop: "productId",
          val: productId,
          op: "=="
        }
      ]);
      if (_.isEmpty(list)) return;
      let promises = [];
      for (const h of list) {
        promises.push(dispatch("deleteHistory", h.id));
      }
      promises.push(
        dispatch(
          "algoliaDeleteItems",
          list.map(r => r.id)
        )
      );
      return await Promise.all(promises);
    },

    // delete item on algolia
    algoliaDeleteItems(context, ids) {
      return historyIndexDesc.deleteObjects(ids);
    }
  };
};
