<template>
  <div>
    <f7-block-header
      class="block-header display-flex align-items-center justify-content-space-between"
    >
      <div>Roofing Build Contract</div>
    </f7-block-header>

    <template v-if="contract">
      <f7-list class="no-margin">
        <f7-list-item>
          <f7-link
            style="white-space: break-spaces"
            @click.native="openBuildContractPopup"
            slot="title"
            >{{ contract.contractTitle }}</f7-link
          >
          <div slot="footer">
            {{ contract.status === "draft" ? "Created" : "Sent" }} on
            {{ toDisplayDateString(contract.createdAt) }} by
            {{ contract.createdBy }}
          </div>
          <div slot="after" class="display-flex theme-color">
            <div
              v-show="contract.status === 'draft' && isShowSendEmailButton"
              @click="sendBuildContract(contract.id)"
            >
              <f7-icon
                class="margin-right cursor-pointer"
                f7="envelope"
              ></f7-icon>
            </div>
            <div @click="getPDF()">
              <f7-icon class="cursor-pointer" f7="square_arrow_down"></f7-icon>
            </div>
          </div>
        </f7-list-item>
      </f7-list>
      <build-contract-popup
        @sendEmail="sendEmailBuildContract"
        ref="buildContractPopup"
      ></build-contract-popup>

      <send-email-popup
        ref="sendEmailPopup"
        popup-title="Send Build Contract"
        templateName="build-contract"
        :projectId="card && (card.id || '')"
        :attachments="card && (card.attachmentFiles || [])"
        :listEmails="listEmails"
        @closePopup="closeBuildContractPopup"
      ></send-email-popup>

      <!-- to download -->
      <div style="position: relative;z-index: 0;overflow: hidden">
        <preview-page
          ref="buildContractPreviewPageHidden"
          :itemDetail="buildContractDetail"
          :pageHeight="1680"
        ></preview-page>
      </div>
    </template>
  </div>
</template>

<script>
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import PreviewPage from "../../pages/PreviewPage.vue";
import sendEmail from "../../mixin/sendEmail";
import { toDisplayDateString } from "../../../../utility/datetime";
import { nowInHumanReadable } from "../../../../utility/date-time-tool";
import { mapGetters } from "vuex";
import BuildContractPopup from "../popup/BuildContractPopup.vue";
import { RESIDENTIAL_ACTION_CODES_BLOCK_SEND_EMAIL } from "@/utility/const";

export default {
  components: {
    SendEmailPopup,
    PreviewPage,
    BuildContractPopup
  },

  mixins: [sendEmail],

  data() {
    return {};
  },

  computed: {
    ...mapGetters("dashboard/project", ["card", "action"]),
    ...mapGetters("dashboard/contract", ["contract"]),
    ...mapGetters("dashboard/client", ["listEmails"]),

    buildContractDetail() {
      return this.contract
        ? {
            id: this.contract.id,
            sections: this.contract.sections,
            title: `${this.contract.contractTitle} - ${nowInHumanReadable()}`
          }
        : {};
    },

    isShowSendEmailButton() {
      return !RESIDENTIAL_ACTION_CODES_BLOCK_SEND_EMAIL.includes(
        this.action.code
      );
    }
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    getPDF() {
      this.$nextTick(() => {
        this.$refs.buildContractPreviewPageHidden.exportPdf();
      });
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    },
    openBuildContractPopup() {
      this.$refs.buildContractPopup.open();
    },
    closeBuildContractPopup() {
      this.$refs.buildContractPopup.close();
    },
    sendEmailBuildContract() {
      this.sendBuildContract(this.contract.id);
    }
  }
};
</script>

<style scoped lang="scss">
.block-header {
  margin-bottom: var(--f7-block-margin-vertical);
}
</style>
