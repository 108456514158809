<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Archive Board</f7-nav-title>
    </f7-navbar>

    <project-grid-view-archive-board></project-grid-view-archive-board>
  </f7-page>
</template>

<script>
import { mapActions } from "vuex";
import ProjectGridViewArchiveBoard from "../board/ProjectGridViewArchiveBoard.vue";

import Menu from "../../../../components/menu/Menu.vue";

export default {
  components: {
    ProjectGridViewArchiveBoard,
    Menu
  },

  methods: {
    ...mapActions("dashboard/project", ["bindUserData"])
  },

  created() {
    this.bindUserData();
  }
};
</script>
