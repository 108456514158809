<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
    @popup:opened="handlePopupOpened"
  >
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Proposal Template</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search proposal template"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-item
          no-chevron
          link
          v-for="template in proposalListOptions"
          :key="template.id"
          :title="template.name"
          @click.native="select(template.id)"
        ></f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  props: {
    projectDetail: { type: Object, default: () => {} }
  },
  data: () => {
    return {
      popupOpened: false,
      value: "",
      isNew: false,
      currentRoofType: {},
      searchValue: ""
    };
  },

  computed: {
    ...mapGetters({
      proposalNew: "proposal/proposal/proposalNew",
      proposalTemplateList: "proposal/template/proposalTemplateList"
    }),
    proposalListOptions() {
      if (!this.proposalNew.projectId) return this.proposalTemplateList;
      if (this.projectDetail.businessCode === "service")
        return this.proposalTemplateList.filter(
          r => r.proposalType === "service"
        );
      return this.proposalTemplateList.filter(
        r => r.proposalType !== "service"
      );
    }
  },

  methods: {
    ...mapActions("proposal/template", ["bindProposalTemplateListBy"]),

    initData() {
      if (_.isEmpty(this.proposalTemplateList)) {
        this.$f7.preloader.show();
        this.bindProposalTemplateListBy({
          prop: "status",
          val: "active",
          op: "=="
        }).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    open() {
      this.initData();
      this.popupOpened = true;
    },

    select(id) {
      this.$emit("onSelected", id);
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  }
};
</script>

<style></style>
