import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "vendor"
  );

  return {
    // Product Actions
    bindVendorList: ({ dispatch }) => {
      dispatch("bindBys", [
        {
          prop: "companyTypes",
          op: "array-contains",
          val: "vendor"
        }
      ]);
    },
    bindBys: companyActions.bindCollectionBys,
    unbindVendorList: companyActions.unbindCollection,
    create: companyActions.createDocument,
    update: companyActions.updateDocument,
    delete: companyActions.deleteDocument
  };
};
