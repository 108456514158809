<template>
  <div
    ref="imgsContainer"
    class="imgs-container"
    v-if="projectPhotoListGroup.length > 0 && !loading"
  >
    <div v-for="projectPhoto in projectPhotoListGroup" :key="projectPhoto.key">
      <div
        class="display-flex flex-direction-row align-items-center margin-left-half"
        @click="
          $emit('handleSelectAllDay', {
            event: projectPhoto.data.every(photo =>
              selectedPhotoIds.includes(photo.id)
            )
              ? false
              : true,
            ids: projectPhoto.data.map(photo => photo.id)
          })
        "
        @mouseenter="$emit('handleMouseEnter')"
      >
        <f7-checkbox
          v-if="projectPhoto.key"
          :style="
            $device.desktop
              ? 'transform: scale(1.5);'
              : 'transform: scale(1.2); top: 8px'
          "
          class="margin-top-half margin-left"
          :checked="
            projectPhoto.data.every(photo =>
              selectedPhotoIds.includes(photo.id)
            )
          "
          @click.native.stop
          @change.native.stop="
            $emit('handleSelectAllDay', {
              event: $event.target.checked,
              ids: projectPhoto.data.map(photo => photo.id)
            })
          "
        ></f7-checkbox>
        <f7-block-title style="cursor: pointer;">{{
          projectPhoto.key
        }}</f7-block-title>
      </div>
      <f7-block>
        <div class="img-grid">
          <div
            v-for="photo in projectPhoto.data"
            :key="photo.id"
            @touchstart="handlePressAndHold(photo.id)"
            @touchend="handleTouchEnd"
            @mouseenter="handleMouseEnter(photo.id)"
            @mouseleave="$emit('handleSelected', null)"
          >
            <div
              class="img-item"
              style="border-radius: 4px;"
              @click.stop="$emit('clickPhoto', photo.id, true)"
            >
              <img class="image-show" :src="photo.thumbnailUrl" />
              <div
                v-if="
                  $device.desktop &&
                    selectionMode &&
                    hoveredPhotoIds.includes(photo.id)
                "
                class="img-bg img-bg-select"
              >
                <div class="img-bg-content">
                  <f7-checkbox
                    style="position:absolute;top:10%;left:10%;transform: scale(1.5);"
                    :checked="selectedPhotoIds.includes(photo.id)"
                    @click.native.stop
                    @change.native.stop="$emit('clickPhoto', photo.id)"
                  ></f7-checkbox>
                </div>
              </div>
              <div
                v-if="!$device.desktop && selectionMode"
                class="img-bg img-bg-select"
              >
                <div class="img-bg-content">
                  <f7-checkbox
                    style="position:absolute;top:10%;left:10%;transform: scale(1.2);"
                    :checked="selectedPhotoIds.includes(photo.id)"
                    @click.native.stop
                    @change.native.stop="$emit('clickPhoto', photo.id)"
                  ></f7-checkbox>
                </div>
              </div>
              <div v-else class="img-bg img-bg-not-select">
                <div
                  v-if="photo.photoFullPath && isVideo(photo.photoFullPath)"
                  class="play-button"
                >
                  <i class="icon f7-icons size-20">play_circle_fill</i>
                </div>
              </div>
            </div>
            <span class="img-title">{{
              (photo.createdAt && photo.createdAt.toDate()) | TIME
            }}</span>
          </div>
        </div>
      </f7-block>
    </div>
  </div>
</template>

<script>
import { isVideo } from "@/utility/common";

export default {
  props: {
    selectionMode: Boolean,
    selectedPhotoIds: { type: Array, default: () => [] },
    projectPhotoListGroup: Array,
    loading: Boolean,
    isClientView: { type: Boolean, default: false },
    hoveredPhotoIds: { type: Array, default: () => [] }
  },
  data() {
    return {
      pressAndHold: null
    };
  },
  methods: {
    isVideo,

    handleMouseEnter(id) {
      if (this.$device.desktop) {
        this.$emit("handleSelected", id);
      }
    },
    handlePressAndHold(id) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.$emit("handleSelected", id, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    }
  }
};
</script>
<style scoped>
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-no-data {
  font-size: 100px;
  color: var(--f7-color-icon-neutral);
}
.camera {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: rgba(0, 0, 0, 0.3);
}
.img-item:hover .img-bg-not-select {
  background: rgba(0, 0, 0, 0.2);
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.img-title {
  font-size: 12px;
}
.edit-button {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 24px;
  text-align: center;
  color: white;
  font-size: 12px;
  background: #6666667a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-button:hover {
  font-weight: 600;
  background: #0808087a;
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.checkbox ::v-deep i {
  border-color: white;
}

.play-button {
  position: absolute;
  width: 100%;
  top: 34%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
