<template>
  <div class="margin-bottom-half">
    <div v-if="$device.desktop" class="display-flex justify-content-right">
      <!-- btn archive -->
      <f7-button
        raised
        fill
        :popover-open="`.popover-actions_archive`"
        class="action-button margin-right-half"
      >
        Archive
      </f7-button>
      <f7-popover :class="`popover-actions_archive`">
        <f7-list class="cursor-pointer menu-actions">
          <f7-list-item
            link
            popover-close
            title="Bad Lead"
            @click="$emit('doAction', 'archive-bad-lead')"
          >
          </f7-list-item>
          <f7-list-item
            link
            popover-close
            title="No longer interest"
            @click="$emit('doAction', 'archive-no-longer-interest')"
          >
          </f7-list-item>
        </f7-list>
      </f7-popover>

      <div
        class="action-button margin-right-half"
        v-for="(action, index) in actions"
        :key="index"
      >
        <f7-button raised fill @click="$emit('doAction', action.value)">{{
          action.displayName
        }}</f7-button>
      </div>
    </div>

    <div v-else>
      <f7-block>
        <f7-row tag="p">
          <f7-col tag="span">
            <!-- btn archive -->
            <f7-button raised fill :popover-open="`.popover-actions_archive`">
              Archive
            </f7-button>
            <f7-popover :class="`popover-actions_archive`">
              <f7-list class="cursor-pointer menu-actions">
                <f7-list-item
                  link
                  popover-close
                  title="Bad Lead"
                  @click="$emit('doAction', 'archive-bad-lead')"
                >
                </f7-list-item>
                <f7-list-item
                  link
                  popover-close
                  title="No longer interest"
                  @click="$emit('doAction', 'archive-no-longer-interest')"
                >
                </f7-list-item>
              </f7-list>
            </f7-popover>
          </f7-col>
        </f7-row>
        <f7-row tag="p" v-for="(action, index) in actions" :key="index">
          <f7-col tag="span">
            <f7-button raised fill @click="$emit('doAction', action.value)">{{
              action.displayName
            }}</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div style="height: 1px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actions: [
        {
          id: "1",
          displayName: "Move to Commercial",
          value: "create-commercial-job"
        },
        {
          id: "2",
          displayName: "Move to Service",
          value: "create-service-job"
        },
        {
          id: "3",
          displayName: "Move to Residential",
          value: "create-residential-job"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 120px;
}
</style>
