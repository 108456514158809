import Vue from "vue";
import * as types from "./types";

export default {
  setObjectList(state, payload) {
    Vue.set(state, "objectList", payload);
  },
  setObjectSubscribe(state, payload) {
    Vue.set(state, "objectSubscribe", payload);
  },
  setTimerList(state, payload) {
    Vue.set(state, "timerList", payload);
  },
  setTimerSubscribe(state, payload) {
    Vue.set(state, "timerSubscribe", payload);
  },
  setDetailId(state, id) {
    state.detailId = id;
  },

  [types.SET_TIME_TRACKING_DRAF_LIST](state, payload) {
    Vue.set(state, "objectList", payload);
  }
};
