import {
  FirebaseActions,
  firestore
} from "../../../../services/firebase.service";
import firebase from "firebase/app";
import _ from "lodash";
import * as types from "./types";
import moment from "moment";

const auth = firebase.auth();

export default tenant => {
  const priceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    "priceListItem"
  );

  const vendorAction = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "vendor"
  );
  return {
    getVendor: vendorAction.getDocument,

    bindPriceListItemListBys: priceListActions.bindCollectionBys,
    unbindPriceListItemList: priceListActions.unbindCollection,

    bindPriceListItem: priceListActions.bindDocument,
    unbindPriceListItem: priceListActions.unbindDocument,
    updatePriceList: priceListActions.updateDocument,
    getPriceListById: priceListActions.getDocument,
    getPriceListItemListBys: priceListActions.getDocumentBys,

    async getPriceListDefault({ commit, dispatch }) {
      const plDefault = await dispatch("getPriceListItemListBys", [
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        },
        {
          prop: "isDefault",
          val: true,
          op: "=="
        },
        {
          prop: "status",
          val: "pl-active",
          op: "=="
        }
      ]);
      const compareDate = moment();
      const startDate =
        plDefault[0] &&
        plDefault[0].startDate &&
        moment(plDefault[0].startDate.toDate());
      if (plDefault[0] && plDefault[0].endDate) {
        const endDate = moment(plDefault[0].endDate.toDate());
        if (
          plDefault &&
          plDefault.length > 0 &&
          plDefault[0] &&
          compareDate.isBetween(startDate, endDate, "days", true)
        ) {
          commit(types.SET_DEFAULT_PRICE_LIST, plDefault[0]);
        }
      } else {
        if (
          plDefault &&
          plDefault.length > 0 &&
          plDefault[0] &&
          compareDate.isSameOrAfter(startDate, "days", true)
        ) {
          commit(types.SET_DEFAULT_PRICE_LIST, plDefault[0]);
        }
      }
    },

    async createHistory({ dispatch }, history) {
      history.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      history.createdBy = auth.currentUser
        ? auth.currentUser.displayName || auth.currentUser.email
        : "";
      if (history.vendorId) {
        const vendor = await dispatch("getVendor", history.vendorId);
        history.vendorName = vendor ? vendor.companyName || "" : "";
      }
      return firestore
        .collection(`/system_client/${tenant}/product_price_list_history`)
        .add(history)
        .then(result => {
          result.update({ id: result.id });
          return result.id;
        });
    },

    async getPriceListItem({ commit, dispatch }, priceListId) {
      const priceList = await dispatch("getPriceListById", priceListId);
      commit(types.SET_PRICE_LIST, priceList);
    },

    addProductRefs({ dispatch }, { id, product }) {
      dispatch("getPriceListById", id).then(priceList => {
        if (priceList) {
          let productRefs = _.cloneDeep(priceList.productRefs) || [];
          productRefs.push(product);
          dispatch("updatePriceList", {
            id,
            doc: { productRefs }
          });
        }
      });
    }
  };
};
