import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/schedule-chain`,
    "chain"
  );

  return {
    createChain: ActionsTemplate.createDocument,
    updateChain: ActionsTemplate.updateDocument,
    deleteChain: ActionsTemplate.deleteDocument,

    getScheduleChain: ActionsTemplate.getDocument,

    getChain({ dispatch, commit }, id) {
      return dispatch("getScheduleChain", id).then(res => {
        return commit(types.SET_CHAIN, res);
      });
    },

    resetChain({ commit }) {
      commit(types.RESET_CHAIN);
    }
  };
};
