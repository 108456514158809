import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const groupActions = new FirebaseActions(
    `/system_client/${tenant}/administration_group`,
    "group"
  );
  return {
    bindGroupList: groupActions.bindCollection,
    unbindGroupList: groupActions.unbindCollection,

    bindGroup: groupActions.bindDocument,
    unbindGroup: groupActions.unbindDocument,

    createGroup: groupActions.createDocument,
    updateGroup: groupActions.updateDocument,
    deleteGroup: groupActions.deleteDocument,
    modifyRelationShip: groupActions.modifyRelationShip
  };
};
