<template>
  <div>
    <!-- <div v-if="roofType != 'tpo'">{{ `roofType: ${roofType}` }}</div> -->
    <estimate-info-mobile-form
      v-if="
        estimate.businessCode != 'service' &&
          !$device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
    ></estimate-info-mobile-form>

    <tpo-building-info-form
      v-if="$device.desktop && roofType === ROOF_TYPE_TPO"
    ></tpo-building-info-form>

    <tpo-building-measurement-form
      v-if="
        estimate.businessCode != 'service' &&
          $device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
    ></tpo-building-measurement-form>

    <tpo-building-assembly-form
      v-if="
        estimate.businessCode != 'service' &&
          $device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
    ></tpo-building-assembly-form>

    <tpo-building-photo-form
      v-if="
        estimate.businessCode != 'service' &&
          $device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
    ></tpo-building-photo-form>

    <f7-block-title
      >Product Items for {{ currentBuilding.buildingName || "" }} (for total
      {{ currentBuilding.numberOfCopy || "" }}
      {{
        currentBuilding.numberOfCopy == 1 ? "building" : "buildings"
      }})</f7-block-title
    >

    <summary-product-table
      :isEditing="false"
      color="var(--f7-color-border-neutral)"
      textColor="var(--f7-color-text-neutral)"
    ></summary-product-table>
  </div>
</template>

<script>
import SummaryProductTable from "../tables/SummaryProductTable.vue";
import TpoBuildingInfoForm from "../forms/TpoBuildingInfoForm.vue";
import TpoBuildingMeasurementForm from "../forms/TpoBuildingMeasurementForm.vue";
import TpoBuildingAssemblyForm from "../forms/TpoBuildingAssemblyForm.vue";
import TpoBuildingPhotoForm from "../forms/TpoBuildingPhotoForm.vue";
import EstimateInfoMobileForm from "../forms/EstimateInfoMobileForm.vue";

import { mapGetters } from "vuex";

import { ROOF_TYPE_TPO } from "../../../../utility/const";

export default {
  components: {
    SummaryProductTable,
    TpoBuildingInfoForm,
    TpoBuildingMeasurementForm,
    TpoBuildingAssemblyForm,
    TpoBuildingPhotoForm,
    EstimateInfoMobileForm
  },

  props: { roofType: String },

  data() {
    return { ROOF_TYPE_TPO };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "currentBuilding"
    ])
  },

  methods: {
    validate() {},
    init() {}
  }
};
</script>

<style lang="scss" scoped></style>
