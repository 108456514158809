<template>
  <f7-page class="swimlane-page" :style="backgroundImage">
    <!-- Navigation bar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Residential</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <dashboard-menu @onToNewProject="toNewProject"></dashboard-menu>

    <project-swimlane-board
      style="background: transparent;"
      class="lazy"
      :class="{
        'desktop-margin-top': $device.desktop || $device.ipad,
        'mobile-margin-top': !($device.desktop || $device.ipad)
      }"
      @onOpenPopup="openCardDetailsPopup"
    ></project-swimlane-board>

    <!-- Popup -->
    <project-details-popup
      ref="projectDetailsPopup"
      :cardNumber="$f7route.params.cardNumber"
      :actionId="$f7route.params.actionId"
      @navigateToBoard="navigateToBoard"
      @showSetting="openSettingPopup"
      @reOpenPopup="handleSettingChanged"
    ></project-details-popup>

    <project-setting-popup
      ref="projectSettingPopup"
      @settingChanged="handleSettingChanged"
    ></project-setting-popup>

    <add-new-residential-project-popup
      ref="addNewPopup"
      @openCardDetailsPopup="openCardDetailsPopup"
    ></add-new-residential-project-popup>
  </f7-page>
</template>

<script>
import Menu from "../../../../components/menu/Menu.vue";
import ProjectSwimlaneBoard from "../board/ProjectSwimlaneBoard.vue";
import ProjectDetailsPopup from "../../components/popup/ProjectDetailsPopup.vue";
import ProjectSettingPopup from "../../components/popup/ProjectSettingPopup.vue";
import AddNewResidentialProjectPopup from "../../components/popup/AddNewResidentialProjectPopup.vue";
import DashboardMenu from "../../components/menu/DashboardMenu.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    ProjectSwimlaneBoard,
    ProjectDetailsPopup,
    ProjectSettingPopup,
    AddNewResidentialProjectPopup,
    DashboardMenu,
    Menu
  },

  data() {
    const dark = localStorage.getItem("themeDark") === "true";
    const imgUrl = dark
      ? "https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/dashboard%2Fsales-board-dark.jpg?alt=media&token=2f771661-0968-41e6-bbdc-47f70a3ba912"
      : "https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/dashboard%2Fsales-board-light.jpg?alt=media&token=af0e5a92-6824-400e-8ae0-5b25e9c67cc2";
    return {
      backgroundImage: `background-image: url(${imgUrl}); background-size: cover;`
    };
  },

  computed: {
    ...mapGetters("dashboard/project", ["users", "cardList"])
  },

  async created() {
    this.$f7.preloader.show();
    if(this.cardList?.length !== 0) {
      await this.unbindCardList();
    }
    const promises = [];
    const boardCode =
      this.$f7route.route.meta.boardCode || this.$f7route.params.boardCode;
    const businessCode =
      this.$f7route.route.meta.businessCode ||
      this.$f7route.params.businessCode;

    await this.bindCardList({ businessCode, boardCode })

    promises.push(this.getConstantTypeList());
    promises.push(this.setCurrentBoard(boardCode));
    promises.push(this.setCurrentBusinessCode(businessCode));

    if (_.isEmpty(this.users)) {
      promises.push(this.bindUserData());
    }
  
    Promise.all(promises)
      .then(() => {
        if (
          this.$f7route.params.cardNumber &&
          this.$f7route.params.actionCode
        ) {
          const card = this.cardList.find(
            r => r.cardNumber === this.$f7route.params.cardNumber
          );
          this.getActionBys([
            {
              prop: "code",
              val: Number(this.$f7route.params.actionCode),
              op: "=="
            }
          ]).then(action => {
            if (
              _.isEmpty(action) ||
              (card && !(card.actions || []).includes(action[0].id))
            ) {
              this.$f7.notification
                .create({
                  title: "Not found action",
                  closeTimeout: 15000,
                  closeButton: true,
                  text: `Not found action code <b>${this.$f7route.params.actionCode}</b>. Please choose another action code.`,
                  closeOnClick: true
                })
                .open();
              return;
            }
            this.setCurrentCard(this.$f7route.params.cardNumber);
            this.setCurrentAction(action[0].id);
            this.setCurrentResponse(action[0].id);
            this.$refs.projectDetailsPopup.initCardData(
              this.$f7route.params.cardNumber,
              action[0].id
            );
            this.$refs.projectDetailsPopup.openPopup(false);
          });
        }

        if (this.$f7route.route.meta.isNew) {
          this.$refs.addNewPopup.openPopup(true);
        }
      })
      .finally(() => {
        this.$f7.preloader.hide()
      });
  },

  methods: {
    ...mapActions("dashboard/project", [
      "setCurrentBoard",
      "setCurrentBusinessCode",
      "bindCardList",
      "setCurrentCard",
      "setCurrentAction",
      "setCurrentResponse",
      "bindUserData",
      "getActionBys",
      "getAction",
      "unbindCardList"
    ]),
    // ...mapActions("dashboard/client", ["bindClientData"]),
    ...mapActions("dashboard/app-constant", ["getConstantTypeList"]),

    toNewProject() {
      window.history.pushState("", "", `/dashboard/swimlane/residential/new`);
      this.$refs.addNewPopup.openPopup();
    },

    navigateToBoard() {
      //clear query param
      window.history.pushState(
        "",
        "",
        `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}`
      );
    },

    async openCardDetailsPopup({ actionId, cardNumber }) {
      if (_.isEmpty(cardNumber) || _.isEmpty(actionId)) {
        return;
      }

      // get action by id
      const action = await this.getAction(actionId);
      this.setCurrentCard(cardNumber);
      this.$f7router.updateCurrentUrl(
        `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}/${cardNumber}/action/${action.code}`
      );

      this.$refs.projectDetailsPopup.initCardData(cardNumber, actionId);
      this.$refs.projectDetailsPopup.openPopup(false);
    },

    openSettingPopup() {
      this.$refs.projectSettingPopup.openPopup();
    },

    handleSettingChanged({ code, data }) {
      const self = this;
      if (code === "re-open-popup") {
        this.$refs.projectDetailsPopup.closePopup(() => {
          self.openCardDetailsPopup({
            actionId: data.actionId,
            cardNumber: data.cardNumber
          });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.swimlane-page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.swimlane-page {
  .desktop-margin-top {
    margin-top: calc(32px + var(--swimlane-dashboard-mode-margin-top));
  }
  .mobile-margin-top {
    margin-top: calc(32px + var(--swimlane-dashboard-mode-margin-top) * 3);
  }
}
</style>
