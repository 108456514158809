/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );

  return {
    bindProjectListBys: projectActions.bindCollectionBys,
    updateProject: projectActions.updateDocument,
    unbindProjectList: projectActions.unbindCollection,
    getProjectBys: projectActions.getDocumentBys,

    async getProjects({ commit, dispatch }) {
      const projectList = await dispatch("getProjectBys", [
        {
          prop: "status",
          op: "==",
          val: "open"
        }
      ]);
      await commit(types.SET_PROJECT_LIST, projectList);
      return projectList;
    },

    changeProjectItem({ commit }, { id, item }) {
      commit(types.CHANGE_PROJECT_ITEM, { id, item });
    }
  };
};
