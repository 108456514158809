<template>
  <f7-list media-list class="no-margin">
    <f7-list-input
      :disabled="disabled"
      class="input-date"
      type="datepicker"
      :label="title"
      placeholder="MM/DD/YYYY"
      error-message-force
      validate
      validate-on-blur
      :value="date"
      :error-message="errorMessage"
      :calendar-params="{
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        dateFormat: 'mm/dd/yyyy'
      }"
      @calendar:change="
        $emit('input', $event);
        $f7.calendar.close();
      "
    >
      <input-icon slot="media" icon="calendar"></input-icon>
      <required-asterisk slot="label" />
    </f7-list-input>
  </f7-list>
</template>
<script>
import InputIcon from "@/components/icons/InputIcon.vue";
export default {
  props: {
    date: {
      type: Array,
      default: () => []
    },
    errorMessage: String,
    title: String,
    disabled: Boolean
  },
  components: {
    InputIcon
  }
};
</script>
<style lang="scss">
.input-date .item-media {
  align-self: center !important;
}
</style>
