import _ from "lodash";

import { applyFilter } from "@/utility/filter-tools";

export default {
  projectList: state => state.projectList,
  project: state => state.project,

  projects: state => (searchValue = "") => {
    let projectList = _.cloneDeep(state.projectList);

    const filteredProject = applyFilter(projectList, searchValue, [
      "title",
      "propertyName"
    ]);
    projectList = filteredProject.filter(({ status }) => status === "open");

    projectList.sort((a, b) => sortBy(b.createdAt, a.createdAt));

    return projectList;
  },
  find: ({ projectList }) => id => projectList.find(r => r.id === id),
  latest: state => {
    let sorted = [...state.projectList].sort((a, b) => {
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });

    return sorted[0] || {};
  }
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
