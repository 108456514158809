import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/time_tracking`,
    "timeTracking"
  );

  return {
    bindTimeTrackingList: ActionsTemplate.bindCollection,
    bindTimeTrackingListBys: ActionsTemplate.bindCollectionBys,
    unbindTimeTrackingList: ActionsTemplate.unbindCollection,

    setIsShowBackBtn({ commit }, isShow) {
      commit(types.SET_IS_SHOW_BACK_BTN, isShow);
    }
  };
};
