<template>
  <thead>
    <tr>
      <th v-if="checkbox" :class="{ 'th-sticky-container': isPinHeader }">
        <f7-checkbox
          :indeterminate="indeterminate"
          :checked="checked"
          @change="
            $emit('selectCheckbox', {
              type: 'header',
              checked: $event.target.checked
            })
          "
        ></f7-checkbox>
      </th>
      <th
        v-for="(props, index) in headers"
        :key="index"
        :class="
          `
                ${props.sortable ? iconSort || 'sortable-cell' : ''}
                ${textAlign(props.align)} ${props.cssClass || ''} 
                ${isPinHeader ? 'th-sticky-container' : ''}`
        "
        @click="$emit('sortColumn', props)"
        :width="props.width"
      >
        <div :style="props.style" v-if="props.align === 'custom'">
          {{ props.text }}
        </div>
        <span v-else>
          <span>{{ props.text }} </span>
          <f7-icon
            v-if="hasTooltip && props.tooltip"
            f7="info_circle_fill"
            size="14"
            class="cursor-pointer"
            :class="props.tooltip.id"
          ></f7-icon>
        </span>
      </th>
    </tr>
    <tr v-if="hasFilter || hasSelectAll">
      <th v-if="checkbox"></th>
      <th
        v-for="(props, index) in headers"
        :key="index"
        :class="`input-cell ${hasSelectAll ? 'text-align-center' : ''}`"
      >
        <div v-if="props.filter" class="input">
          <input
            class="search-text-box"
            v-if="props.filter.type === 'text'"
            type="text"
            :placeholder="props.filter.placeholder || 'Filter'"
            :value="props.filter.value"
            @input="
              v =>
                props.filter.onChange && props.filter.onChange(v.target.value)
            "
          />
          <select
            class="search-text-box"
            v-else-if="props.filter.type === 'select'"
            :value="props.filter.value"
            @input="
              v =>
                props.filter.onChange && props.filter.onChange(v.target.value)
            "
          >
            <option
              v-for="opt in props.filter.selectSource"
              :key="opt.value"
              :value="opt.value"
            >
              {{ opt.text }}
            </option>
          </select>
          <div v-else-if="props.filter.type === 'popover'">
            <f7-list-item
              style="list-style: none; border: 1px solid grey; padding: 3px 6px; border-radius: 6px; font-size: 15px;  color: #fff !important"
              link
              class="title-selected"
              :popover-open="`.popover-menu-${randomClassName}`"
            >
              <span slot="title">
                {{ props.filter.title }}
              </span>
            </f7-list-item>
            <f7-popover :class="`popover-menu-${randomClassName}`">
              <f7-list>
                <f7-list-item
                  v-for="(item, index) in props.filter.selectSource"
                  :key="item.id + index"
                  popover-close
                  :title="item.value"
                  @change="
                    v => {
                      props.filter.onChange &&
                        props.filter.onChange(v.target.checked, item.id);
                    }
                  "
                  :checked="
                    props.filter.selectedList
                      ? props.filter.selectedList.includes(item.id)
                      : false
                  "
                  checkbox
                >
                </f7-list-item>
              </f7-list>
            </f7-popover>
          </div>
        </div>
        <div>
          <f7-checkbox
            v-if="props.selectAll"
            :class="`select-border-${props.selectAll.borderColor}`"
            :checked="props.selectAll.value"
            @change="
              v =>
                props.selectAll.onChange &&
                props.selectAll.onChange(v.target.checked)
            "
          ></f7-checkbox>
        </div>
      </th>
    </tr>
  </thead>
</template>
<script>
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      randomClassName: uuid.v4()
    };
  },
  props: {
    headers: Array,
    checkbox: Boolean,
    iconSort: String,
    indeterminate: Boolean,
    checked: Boolean,
    isPinHeader: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.createTooltip();
  },
  methods: {
    createTooltip() {
      if (!this.$device.desktop) return;

      this.headers.forEach(header => {
        if (header.tooltip) {
          this.$f7.tooltip.create({
            targetEl: `.${header.tooltip.id}`,
            text: `${header.tooltip.content}`
          });
        }
      });
    },
    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : align === "custom"
        ? "text-custom"
        : "text-align-left";
    }
  },
  computed: {
    hasFilter() {
      return this.headers.some(header => header.filter);
    },
    hasTooltip() {
      return this.headers.some(header => header.tooltip);
    },
    hasSelectAll() {
      return this.headers.some(header => header.selectAll);
    }
  }
};
</script>

<style scoped>
.search-text-box {
  font-size: var(--f7-table-head-font-size);
  border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 6px;
}
.input-cell {
  padding-top: 0px !important;
}
.select-border-white ::v-deep .icon-checkbox {
  border-color: var(--f7-color-checkbox-color);
}
.th-sticky-container {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
.th-sticky-container::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
.title-selected ::v-deep .item-title {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff !important;
}
</style>
