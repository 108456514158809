import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const groupActions = new FirebaseActions(
    `/system_client/${tenant}/administration_group`,
    "group"
  );
  return {
    bindGroupList: groupActions.bindCollection,
    bindGroupListBy: groupActions.bindCollectionBy,
    bindGroupListBys: groupActions.bindCollectionBy,
    unbindGroupList: groupActions.unbindCollection,

    getGroupListBys: groupActions.getDocumentBys,

    async getGroupList({ dispatch, commit }, conditions) {
      const list = await dispatch("getGroupListBys", conditions);
      commit(types.SET_GROUP_LIST, list);
    },

    getGroupsByUid({ dispatch }, uid) {
      return dispatch("getGroupListBys", [
        {
          prop: "userIDs",
          op: "array-contains",
          val: uid
        },
        { prop: "isDeleted", op: "==", val: false }
      ]);
    },
    getAdminGroups({ dispatch, commit }) {
      return dispatch("getGroupListBys", [
        {
          prop: "code",
          op: "==",
          val: "admin"
        },
        { prop: "isDefault", op: "==", val: true }
      ]).then(res => {
        commit(types.SET_ADMIN_GROUPS, res);
      });
    },
    checkAdminGroup({ dispatch, commit }, uid) {
      return dispatch("getGroupsByUid", uid).then(groups => {
        const isAdminGroup = (groups || []).some(
          group => group.isDefault && group.code === "admin"
        );

        commit(types.SET_IS_ADMIN_GROUP, isAdminGroup);
      });
    }
  };
};
