import moment from "moment";

function getWeeksFromMonth(month, year) {
  let startDate = moment([year, month - 1, 1]);
  let endDate = null;
  if (month == 12) {
    endDate = moment([Number(year) + 1, 0, 1]).add(-1, "days");
  } else {
    endDate = moment([year, month, 1]).add(-1, "days");
  }
  var weeks = [];
  while (startDate.weekday() !== 1) {
    startDate.add(-1, "days");
  }
  while (endDate.weekday() !== 0) {
    endDate.add(1, "days");
  }
  if (startDate.weekday() !== 1) weeks.push([]);
  while (startDate <= endDate) {
    if (startDate.weekday() === 1) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(startDate.clone());
    startDate.add(1, "days");
  }

  return weeks;
}

export default getWeeksFromMonth;
