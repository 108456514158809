import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Purchase Order",
    name: "purchase-order-main",
    displayRoles: ["owner", "user"],
    routes: [
      {
        path: "/purchase-order-grid",
        title: "Purchase Order",
        name: "purchase-order",
        parent: "dashboard-main",
        displayRoles: ["owner", "user"],
        icon: "cart",
        async: checkAuth(routeTo => {
          if (localStorage.getItem("desktop") === "true") {
            routeTo.route.master = false;
            return import("../pages/PurchaseOrderGridPage.vue");
          }
          return import("../pages/PurchaseOrderListPage.vue");
        })
      },
      {
        path: "/purchase-order",
        title: "Purchase Order",
        name: "purchase-order-list",
        displayRoles: ["owner", "user"],
        checkAccessPath: "dashboard-main/purchase-order",
        icon: "doc_plaintext",
        async: checkAuth(() => import("../pages/PurchaseOrderListPage.vue")),
        master: true,
        detailRoutes: [
          // {
          //   path: "/purchase-order/new",
          //   async: checkAuth(() => import("../pages/NewPurchaseOrderPage.vue"))
          // },
          {
            path: "/purchase-order/:purchaseOrderNumber",
            displayRoles: ["owner", "user"],
            checkAccessPath: "dashboard-main/purchase-order",
            async: checkAuth(() =>
              import("../pages/PurchaseOrderDetailPage.vue")
            )
          }
        ]
      }
    ]
  }
];
