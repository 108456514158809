import _ from "lodash";

export default {
  timeTrackingList: (state, getters, rootState, rootGetters) => {
    const projectById = rootGetters["quickbooks/project/projectById"];
    const userById = rootGetters["quickbooks/user/userById"];
    const qbEmployeeById = rootGetters["quickbooks/common/qbEmployeeById"];

    return _.cloneDeep(state.timeTrackingList)
      .map(item => {
        const user = userById(item.user_id);

        let qbEmployeeRef = null;
        if (user && user.customClaims) {
          const qbUser = user.customClaims.qbUser || {};
          qbEmployeeRef = qbEmployeeById(qbUser.Id);
        }
        
        return {
          ...item,
          // project: projectById(item.projectId),
          customerRef:
            item.customerRef || projectById(item.projectId).qbCustomerRef,
          employeeRef:
            item.employeeRef ||
            (qbEmployeeRef
              ? {
                  name: qbEmployeeRef.DisplayName,
                  id: qbEmployeeRef.Id
                }
              : null)
        };
      })
      .sort((a, b) => {
        if (a.user_id < b.user_id) {
          return -1;
        }
        if (a.user_id > b.user_id) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        return a.qbTimeActivityId && !b.qbTimeActivityId ? 0 : -1;
      });
  },

  timeTrackingById: state => id =>
    state.timeTrackingList.find(item => item.id === id),

  datesToFilterQB: state => state.datesToFilterQB
};
