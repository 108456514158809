import { evaluate } from "mathjs";
import { convertStringToExpression } from "./stretch-out-helper";

export const assemblyTemplateCodeIsQuantityType = [
  "collectorHead",
  "downspoutTransition",
  "pitchPan"
];

const assemblyLengthCalculator = new (function() {
  this.noMethod = ({ qty }) => {
    return qty || 0;
  };

  this.downspoutTransition = ({ qty, assembly }) => {
    let parseValue = convertStringToExpression(assembly.measurement.D);
    return evaluate(parseValue) * qty || 0;
  };

  this.collectorHead = ({ qty, assembly }) => {
    const valueB =
      evaluate(convertStringToExpression(assembly.measurement.B)) || 0;
    const valueC =
      evaluate(convertStringToExpression(assembly.measurement.C)) || 0;
    const valueD =
      evaluate(convertStringToExpression(assembly.measurement.D)) || 0;

    const addin = 3.375;
    return (valueB + valueC + valueD + addin) * qty || 0;
  };

  this.pitchPan = ({ qty, assembly }) => {
    const valueA =
      evaluate(convertStringToExpression(assembly.measurement.A)) || 0;
    const valueB =
      evaluate(convertStringToExpression(assembly.measurement.B)) || 0;
    const valueC =
      evaluate(convertStringToExpression(assembly.measurement.C)) || 0;
    const valueD =
      evaluate(convertStringToExpression(assembly.measurement.D)) || 0;
    const value =
      valueA >= valueC
        ? valueA + 2 * (valueB + valueD)
        : valueC + 2 * (valueB + valueD);

    return value * qty || 0;
  };
})();

export const calculateAssemblyLength = ({ qty, assembly }) => {
  if (assembly) {
    const calculator =
      assemblyLengthCalculator[assembly.assemblyItem] ||
      assemblyLengthCalculator["noMethod"];
    if (calculator) {
      return calculator({ assembly, qty });
    }
  }

  return 0;
};
