<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>

      <!-- <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: true,
          dateFormat: 'mm/dd/yyyy'
        }"
        label="Completed Date"
        placeholder="Enter Estimate Completed Date"
        readonly
        :value="estimateCompletedDate"
        @calendar:change="estimateCompletedDate = $event"
      >
        <input-icon slot="media" icon="checkmark"></input-icon>
      </f7-list-input> -->
    </f7-list>

    <!-- <f7-list>
      <f7-list-item title="Manufacturer Verbal Approved System">
        <f7-toggle
          slot="after"
          :checked="displayCard.manufacturerVerbal"
          @toggle:change="changeToggle('manufacturerVerbal', $event)"
        ></f7-toggle>
        <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
      </f7-list-item>
    </f7-list> -->
    <!-- <attachment-input
      v-show="displayCard.manufacturerVerbal"
      :project-id="displayCard.id"
      add-button-title="Upload Document"
      attachment-type="manufacturerVerbal"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
      :no-margin-top="true"
    ></attachment-input> -->

    <f7-list>
      <f7-list-item title="Required System Letter">
        <f7-toggle
          slot="after"
          :checked="displayCard.requiredSystemLetter === true"
          @toggle:change="changeToggle('requiredSystemLetter', $event)"
        ></f7-toggle>
        <input-icon slot="media" icon="envelope_badge"></input-icon>
      </f7-list-item>
    </f7-list>
    <attachment-input
      v-show="displayCard.requiredSystemLetter"
      :project-id="displayCard.id"
      add-button-title="Upload Document"
      attachment-type="requiredSystemLetter"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
      :no-margin-top="true"
    ></attachment-input>

    <estimate-input
      :value="displayCard"
      :estimateList="estimateListByStatus(['draft', 'active'])"
      :action="action"
      boardId="sales"
      @closePopup="closeActionPopup"
      @onUpdateEstimate="handleUpdateEstimate"
      @onCreateNewEstimate="handleCreateNewEstimate"
      @onDeleteEstimate="handleDeleteEstimate"
    ></estimate-input>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      add-button-title="Add an Attachment"
      attachment-type="attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
      @save="save"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="changeValueNotes"
      :value="note"
      :is-save-button="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Estimate Note',
          note,
          callback: () => {
            note = '';
          }
        })
      "
    ></note-editor>

    <!-- Popup -->
    <date-popup
      ref="proposalDueDatePopup"
      title="Enter Proposal Due Date"
      label="Proposal Due Date"
      v-model="dueDateProposal"
      @done="resolveProposal"
      validate
      not-allow-past
    ></date-popup>

    <date-popup
      ref="estimateDueDatePopup"
      title="Enter New Estimate Due Date"
      label="New Estimate Due Date"
      v-model="dueDateNewEstimate"
      @done="resolveRedoEstimate"
      validate
      not-allow-past
    ></date-popup>
  </div>
</template>

<script>
import EstimateInput from "@/components/inputs/EstimateInput.vue";
import PurchaseOrderInput from "../input/PurchaseOrderInput.vue";

import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase } from "../../../../services/firebase.service";
import { auth } from "../../../../services/firebase.service";
import { mapState, mapActions, mapGetters } from "vuex";
// import Vue from "vue";

import _ from "lodash";
import methodsMixins from "../../mixin/methods";
import moment from "moment";
import { PROP_IS_DELETED } from "../../../../utility/const";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  props: {
    popupOpened: Boolean
  },
  mixins: [methodsMixins],

  components: {
    UserInput,
    AttachmentInput,
    DatePopup,
    InputIcon,
    EstimateInput,
    PurchaseOrderInput,
    NoteEditor
  },

  data() {
    return {
      note: "",
      currentResponse: {},

      displayCard: {
        requiredSystemLetter: false
      },
      dueDate: [],
      // estimateCompletedDate: [],

      dueDateNewEstimate: [],
      dueDateProposal: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    },

    popupOpened: {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          val &&
          !_.isEmpty(this.card) &&
          this.card.requiredSystemLetter === undefined
        ) {
          this.updateCard({
            id: this.card.id,
            doc: {
              requiredSystemLetter: false
            }
          });
        }
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"]),
    ...mapGetters("dashboard/estimate", ["estimateListByStatus"])
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),
    ...mapActions("dashboard/estimate", [
      "updateEstimate",
      "deleteEstimate",
      "getEstimatesBys"
    ]),

    changeValueNotes(value) {
      this.note = value;
    },

    changeToggle(name, value) {
      if (this.displayCard[name] == value) return;
      this.displayCard = {
        ...this.displayCard,
        [name]: value
      };
      if (this.popupOpened) {
        this.$f7.preloader.show();
        return this.updateCard({
          id: this.displayCard.id,
          doc: {
            [name]: value
          }
        }).finally(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    getEstimates() {
      if (!_.isEmpty(this.card)) {
        this.getEstimatesBys([
          {
            prop: "projectId",
            val: this.card.id,
            op: "=="
          },
          {
            prop: PROP_IS_DELETED,
            val: false,
            op: "=="
          },
          { prop: "status", val: ["incomplete", "active", "draft"], op: "in" }
        ]);
      }
    },

    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        // this.estimateCompletedDate = toDateCalendar(
        //   this.displayCard.estimateCompletedDate
        // );
        // this.displayCard.manufacturerVerbal =
        //   this.displayCard.manufacturerVerbal || false;

        this.displayCard.requiredSystemLetter =
          this.displayCard.requiredSystemLetter === undefined
            ? false
            : this.displayCard.requiredSystemLetter;

        this.getEstimates();
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      // check upload document for manufacturerVerbal, requiredSystemLetter
      // const inValidManufacturerVerbal =
      //   this.displayCard.manufacturerVerbal &&
      //   !this.getAttachmentFilesByType("manufacturerVerbal").length;
      const inValidRequiredSystemLetter =
        this.displayCard.requiredSystemLetter &&
        !this.getAttachmentFilesByType("requiredSystemLetter").length;
      if (/*inValidManufacturerVerbal &&*/ inValidRequiredSystemLetter) {
        this.showUploadDialog(
          response,
          "Please upload document for Manufacturer Verbal Approved System and Required System Letter"
        );
      }
      // else if (inValidManufacturerVerbal) {
      //   this.showUploadDialog(
      //     response,
      //     "Please upload document for Manufacturer Verbal Approved System"
      //   );
      // }
      else if (inValidRequiredSystemLetter) {
        this.showUploadDialog(
          response,
          "Please upload document for Required System Letter"
        );
      } else {
        this.handleAction(response);
      }
    },

    resolveRedoEstimate() {
      this.dueDate = this.dueDateNewEstimate;
      // Do not save if redo the card
      // this.estimateCompletedDate = [];

      this.resolve();
    },

    resolveProposal() {
      this.dueDate = this.dueDateProposal;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Estimate Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      // if (!_.isEmpty(this.estimateCompletedDate)) {
      //   this.displayCard.estimateCompletedDate = toDateFirebase(
      //     this.estimateCompletedDate
      //   );
      // }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    },

    handleUpdateEstimate(data) {
      this.$f7.preloader.show();
      this.updateEstimate({
        id: data.id,
        doc: {
          ...data.doc
        }
      })
        .then(() => {
          this.getEstimates();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleCreateNewEstimate() {
      const actionId = this.action.id;
      const projectId = this.displayCard.id;
      const propertyId = this.displayCard.propertyId;
      const contactId = this.displayCard.contactId;
      const self = this;

      this.$emit("doClosePopup", () => {
        this.$f7router.navigate(
          {
            name: "new-estimate-swimlane-commercial",
            params: {
              boardType: self.$f7route.route.meta.boardType,
              businessCode: self.$f7route.route.meta.businessCode,
              boardId: "sales",
              actionId,
              projectId,
              propertyId: propertyId || "-",
              clientType: "contact",
              clientId: contactId || "-"
            }
          },
          { reloadAll: true, pushState: true }
        );
      });
    },

    handleDeleteEstimate(data) {
      this.$f7.preloader.show();
      this.deleteEstimate(data.id)
        .then(() => {
          this.getEstimates();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleAction(response) {
      switch (response.code) {
        case "move-to-proposal":
          this.currentResponse = response;
          this.$refs.proposalDueDatePopup.open();
          break;

        case "follow-up":
          this.currentResponse = response;
          this.$refs.estimateDueDatePopup.open();
          break;
      }
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            "day"
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) }
          });
        }
      }
    }
  }
};
</script>

<style></style>
