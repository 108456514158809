<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Roofing Cam</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="isOwner || isAdminGroup"
          icon-f7="gear_alt"
          @click.native="openSettingAlbumTemplatePopup"
        ></f7-link>
        <f7-link icon-f7="plus" @click.native="openNewProject(true)"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search"
      :clear-button="true"
      :value="searchText"
      @input="
        setSearchText($event.target.value);
        onSearch();
      "
      @searchbar:disable="
        setSearchText('');
        onSearch();
      "
      class="search-list"
    >
      <f7-button
        :style="$device.desktop ? '' : 'margin-left: 5px'"
        style="text-overflow: inherit;"
        popover-open=".popover-menu-filter"
      >
        <f7-icon f7="funnel"></f7-icon>
      </f7-button>
      <f7-popover
        style="width: 250px;"
        :class="`popover-menu-filter`"
        :close-by-outside-click="false"
        :close-by-backdrop-click="true"
      >
        <f7-block-header>Job Type</f7-block-header>
        <f7-list inset>
          <f7-list-item
            v-for="(type, index) in BUSINESS_CODES"
            :key="index"
            :title="type.displayName"
            checkbox
            :checked="projectFilter.jobTypes.includes(type.value)"
            @change="onFilterJobTypes($event.target.checked, type.value)"
          >
          </f7-list-item>
        </f7-list>
        <f7-block-header>Collection</f7-block-header>
        <f7-list inset>
          <f7-list-item
            v-for="(collection, index) in collections"
            name="collectionFilter"
            :key="index"
            :title="collection.displayName"
            :value="collection.value"
            radio
            :checked="projectFilter.collection === collection.value"
            @change="onFilterCollection($event.target.value)"
          >
          </f7-list-item>
        </f7-list>
        <f7-block-header>Created Project</f7-block-header>
        <f7-block>
          <div class="input-date-filter margin-bottom">
            <f7-icon color="primary" f7="calendar" size="18"></f7-icon>
            <f7-input
              class="width-100"
              type="datepicker"
              placeholder="From Date"
              clear-button
              :value="projectFilter.createdFromDate"
              :calendar-params="{
                openIn: 'popover',
                backdrop: false,
                dateFormat: 'mm/dd/yyyy',
                closeOnSelect: true
              }"
              @calendar:change="
                onFilterCreatedDate('createdFromDate', $event);
                $f7.calendar.close();
              "
              @calendar:clear="onFilterCreatedDate('createdFromDate', [])"
            ></f7-input>
          </div>
          <div>
            <div class="input-date-filter">
              <f7-icon color="primary" f7="calendar" size="18"></f7-icon>
              <f7-input
                class="width-100"
                type="datepicker"
                placeholder="To Date"
                clear-button
                :value="projectFilter.createdToDate"
                :calendar-params="{
                  openIn: 'popover',
                  backdrop: false,
                  dateFormat: 'mm/dd/yyyy',
                  closeOnSelect: true,
                  disabled: date =>
                    projectFilter.createdFromDate &&
                    date <
                      new Date(projectFilter.createdFromDate).setHours(
                        0,
                        0,
                        0,
                        0
                      )
                }"
                @calendar:change="
                  onFilterCreatedDate('createdToDate', $event);
                  $f7.calendar.close();
                "
                @calendar:clear="onFilterCreatedDate('createdToDate', [])"
              ></f7-input>
            </div>
            <div class="error-message">
              {{ createdToDateFilterErrorMessage }}
            </div>
          </div>
        </f7-block>
      </f7-popover>
    </f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <div>
        <f7-list class="no-margin">
          <f7-list-group media-list>
            <f7-list-item
              v-for="item in hits"
              :title="item.projectName"
              :key="item.id"
              :reload-detail="true"
              swipeout
              :link="`/photo/${item.id}`"
              :text-color="
                item.id === (project || {}).id ? 'primary' : 'default'
              "
            >
              <template
                v-if="
                  item.projectBusinessCode === BUSINESS_CODE_COMMERCIAL ||
                    (item.projectBusinessCode === BUSINESS_CODE_SERVICE &&
                      item.customerType === BUSINESS_CODE_COMMERCIAL)
                "
              >
                <div slot="text">
                  <div class="font-text">
                    Property Name: {{ item.propertyName }}
                  </div>
                </div>
                <div slot="footer">
                  <div class="font-text">
                    Address: {{ item.propertyFullAddress }}
                  </div>
                </div>
              </template>
              <template
                v-else-if="
                  item.projectBusinessCode === BUSINESS_CODE_RESIDENTIAL ||
                    (item.projectBusinessCode === BUSINESS_CODE_SERVICE &&
                      (item.customerType === BUSINESS_CODE_RESIDENTIAL ||
                        item.customerType === 'multifamily'))
                "
              >
                <div slot="text">
                  <div class="font-text">
                    Contact Name: {{ item.contactName }}
                  </div>
                </div>
                <div slot="footer">
                  <div class="font-text">
                    Address: {{ item.projectFullAddress }}
                  </div>
                </div>
              </template>
              <f7-chip
                v-show="item.totalPhotos"
                slot="after"
                color="purple"
                :text="item.totalPhotos"
              ></f7-chip>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <div
          v-show="hasMoreItems"
          class="preloader infinite-scroll-preloader"
        ></div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
      <add-project-popup
        :isShow="isShowAddProjectPopup"
        @cancel="openNewProject(false)"
      ></add-project-popup>
      <setting-album-template-popup
        ref="settingAlbumTemplatePopup"
      ></setting-album-template-popup>
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddProjectPopup from "../components/popups/AddProjectPopup.vue";
import SettingAlbumTemplatePopup from "../components/popups/SettingAlbumTemplatePopup.vue";
import Menu from "../../../components/menu/Menu.vue";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODES
} from "../../../utility/const";
import moment from "moment";
import _ from "lodash";

export default {
  components: {
    AddProjectPopup,
    SettingAlbumTemplatePopup,
    Menu
  },
  data() {
    return {
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODES,
      isShowAddProjectPopup: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,

      createdToDateFilterErrorMessage: ""
    };
  },
  methods: {
    ...mapActions("photo/project", [
      "searchProject",
      "loadMoreProject",
      "resetProject",
      "setProjectFilterField",
      "setSearchText"
    ]),
    ...mapActions("photo/app-constant", ["resetConstant"]),
    ...mapActions("photo/album", ["resetAlbum"]),
    ...mapActions("photo/photo", ["resetPhoto"]),

    openNewProject(show) {
      this.isShowAddProjectPopup = show;
    },
    openSettingAlbumTemplatePopup() {
      this.$refs.settingAlbumTemplatePopup.openPopup();
    },
    onSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchProject({}).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
      });
    },
    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;
      this.loadMoreProject({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
    onFilterJobTypes(checked, value) {
      if (checked) {
        this.setProjectFilterField({
          field: "jobTypes",
          value: this.projectFilter.jobTypes.concat(value)
        });
      } else {
        this.setProjectFilterField({
          field: "jobTypes",
          value: this.projectFilter.jobTypes.filter(item => item !== value)
        });
      }
      this.onSearch();
    },
    async onFilterCollection(value) {
      this.setProjectFilterField({
        field: "collection",
        value
      });
      this.onSearch();
    },
    onFilterCreatedDate(field, value) {
      if (
        (_.isEmpty(value) && _.isEmpty(this.projectFilter[field])) ||
        (!_.isEmpty(value) &&
          !_.isEmpty(this.projectFilter[field]) &&
          moment(value[0]).isSame(moment(this.projectFilter[field][0]), "day"))
      )
        return;
      this.setProjectFilterField({
        field,
        value
      });
      const { createdFromDate, createdToDate } = this.projectFilter;
      let fromDate = "";
      let toDate = "";
      if (
        !_.isEmpty(value) &&
        !_.isEmpty(createdFromDate) &&
        !_.isEmpty(createdToDate)
      ) {
        if (field === "createdToDate") {
          fromDate = moment(createdFromDate[0]);
          toDate = moment(value[0]);
        } else {
          fromDate = moment(value[0]);
          toDate = moment(createdToDate[0]);
        }
      }
      if (
        fromDate &&
        toDate &&
        toDate.isBefore(fromDate, "day") &&
        field === "createdToDate"
      ) {
        this.createdToDateFilterErrorMessage =
          "To date must be greater than or equal from date";
        return;
      } else if (
        fromDate &&
        toDate &&
        toDate.isBefore(fromDate, "day") &&
        field === "createdFromDate"
      ) {
        this.setProjectFilterField({ field: "createdToDate", value });
        this.createdToDateFilterErrorMessage = "";
      } else {
        this.createdToDateFilterErrorMessage = "";
      }
      this.onSearch();
    }
  },

  computed: {
    ...mapGetters("photo/project", [
      "projectList",
      "project",
      "hits",
      "nbPages",
      "page",
      "projectFilter",
      "searchText"
    ]),
    ...mapGetters("setting/app/profile", ["isOwner"]),
    ...mapGetters("setting/app/group", ["isAdminGroup"]),

    collections() {
      return [
        { id: "all", value: "project-open", displayName: "All (in Progress)" },
        { id: "my-project", value: "my-project", displayName: "My Project" },
        { id: "archived", value: "archived", displayName: "Archived" }
      ];
    }
  },

  created() {
    if (!this.hasMoreItems) {
      this.$f7.preloader.show();
    }
    this.onSearch().finally(() => {
      if (!this.hasMoreItems) {
        this.$f7.preloader.hide();
      }
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  },

  beforeDestroy() {
    const currentPath = (
      (this.$f7 && this.$f7.views.main.router.currentRoute.route) ||
      {}
    ).path;
    if (!currentPath.includes("/photo")) {
      this.resetProject();
      this.resetConstant();
      this.resetPhoto();
      this.resetAlbum();
    }
  }
};
</script>
<style lang="scss" scoped>
.input-date-filter {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  gap: 8px;
  box-sizing: border-box;
}
.error-message {
  font-size: var(--f7-list-item-text-font-size);
  font-weight: var(--f7-list-item-text-font-weight);
  color: red;
}
</style>
