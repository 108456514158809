<template>
  <f7-list :inline-labels="$device.desktop" no-hairlines-md>
    <f7-list-input v-for="(other, index) in value" :key="index" :value="other.value"
      @input="other.value = $event.target.value.trim()" type="textarea" class="contact-other-input"
      :placeholder="other.title" clear-button error-message-force :error-message="otherErrorMessage(index)"
      @blur="v$.value.$touch()" :ref="`other-input-${other.code}`">
      <div slot="label">
        <f7-link :popover-open="otherTypeOptions.length ? `.popover-other-type-${id}` : false
          " @click.native="openOtherType(index)" @focus.native="onLinkFocus(other.code)">
          {{ other.title }}
        </f7-link>
        <f7-icon style="float: right; margin-top: 4px;" size="18" color="gray" f7="chevron_right"></f7-icon>
      </div>
      <f7-icon color="red" f7="minus_circle_fill" slot="media" @click.native="deleteOther(index)"></f7-icon>
    </f7-list-input>

    <f7-list-item v-if="otherTypeOptions.length">
      <f7-link :popover-open="value.length && otherTypeOptions.length > 1
        ? `.popover-other-type-${id}`
        : false
        " class="cursor-pointer text-input" slot="title" @click.native="handleAdd">
        add other
      </f7-link>
      <f7-icon class="add-button-icon" f7="plus_circle" slot="media"></f7-icon>
    </f7-list-item>

    <f7-popover ref="otherTypePopover" @popover:closed="sheetClosed" :class="`popover-other-type-${id}`">
      <f7-list>
        <f7-list-item v-for="(type, index) in otherTypeOptions" :key="index" :title="type.title" link
          @click.native="selectOtherType(type.code)"></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-list>
</template>

<script>
import _ from "lodash";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers} from "@vuelidate/validators";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    id: String,
    value: { type: Array, default: () => [] }
  },

  data: () => {
    return {
      otherTypes: [
        { code: "website", title: "Website" },
        { code: "email", title: "Email" },
        { code: "note", title: "Note" }
      ],
      isNew: false,
      currentOtherIndex: ""
    };
  },

  computed: {
    otherTypeOptions() {
      const codes = (this.value || []).map(r => r.code) || [];
      return this.otherTypes.filter(r => !codes.includes(r.code));
    },
    otherErrorMessage() {
      return index => {
        if (this.v$.value.$each.$response.$errors[index].value.length === 0) return null;
        return this.v$.value.$each.$response.$errors[index].value[0].$message || ''
      };
    }
  },

  methods: {
    onLinkFocus(code) {
      this.$refs[`other-input-${code}`][0].$el
        .querySelector(".contact-other-input textarea")
        .focus();
    },
    handleAdd() {
      if (!this.value.length) {
        this.addOther("website");
      } else if (this.otherTypeOptions.length > 1) {
        this.isNew = true;
      } else if (this.otherTypeOptions.length === 1) {
        this.addOther(this.otherTypeOptions[0].code);
      }
    },

    deleteOther(index) {
      let others = _.cloneDeep(this.value);
      others.splice(index, 1);
      this.$emit("input", others);
    },

    addOther(value) {
      let others = _.cloneDeep(this.value);
      const otherType = this.otherTypes.find(r => r.code === value) || {};
      others.push({
        code: value,
        title: otherType.title,
        value: ""
      });
      this.$emit("input", others);
      this.$nextTick(() => {
        this.onLinkFocus(value);
      });
    },

    sheetClosed() {
      this.isNew = false;
      this.currentOtherIndex = "";
    },

    openOtherType(index) {
      this.currentOtherIndex = index;
    },

    selectOtherType(type) {
      const obj = this.otherTypes.find(item => {
        return item.code === type;
      });
      if (this.isNew) {
        this.addOther(type);
      } else {
        let others = _.cloneDeep(this.value);
        others[this.currentOtherIndex].code = obj.code;
        others[this.currentOtherIndex].title = obj.title;
        this.$emit("input", others);
        this.$nextTick(() => {
          this.onLinkFocus(type);
        });
      }
      this.$refs.otherTypePopover.close();
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    const isValidEmail = (email) =>{
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    return {
      value: {
        $each: helpers.forEach({
          value: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            email: helpers.withMessage(VALIDATION_MESSAGE.INVALID_EMAIL, (value, parent) => {
              if (parent.code === 'email') {
                return isValidEmail(value);
              }
              return true; 
            })
          }
        })
      }
    };
  }
};
</script>
<style scoped>
.text-input {
  color: var(--f7-color-text-neutral);
}
</style>
