import { FirebaseActions } from "../../services/firebase.service";
import { searchClient } from "../../services/search.service";
import algoliaService from "../../services/algolia.service";
import * as types from "./types";
import * as constant from "../../utility/const";

export default tenant => {
  const scheduleActions = new FirebaseActions(
    `/system_client/${tenant}/schedule_value`,
    "scheduleValue",
    {
      modelSchema: {
        mobilization: "float",
        tax: "float"
      }
    }
  );

  const scheduleValueIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  return {
    bindScheduleValue: scheduleActions.bindDocument,
    unbindScheduleValue: scheduleActions.unbindDocument,

    saveScheduleValue: scheduleActions.updateDocument,
    removeScheduleValue: scheduleActions.deleteDocument,
    addScheduleValue: scheduleActions.createDocument,

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    async searchScheduleValue(
      { state, commit },
      { attributesToRetrieve, restrictSearchableAttributes, filters }
    ) {
      const index = scheduleValueIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_SCHEDULE_VALUE} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.SCHEDULE_VALUE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.SCHEDULE_VALUE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreScheduleValue(
      { state, commit },
      { attributesToRetrieve, restrictSearchableAttributes, page, filters }
    ) {
      const index = scheduleValueIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_SCHEDULE_VALUE} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.SCHEDULE_VALUE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.SCHEDULE_VALUE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setScheduleValueNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },
    setScheduleOfValueSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async createScheduleValue({ dispatch }, scheduleValue) {
      if (scheduleValue.projectId) {
        scheduleValue.isProjectArchived = await dispatch(
          "common/common/getIsProjectArchived",
          scheduleValue.projectId,
          { root: true }
        );
      }
      const id = await dispatch("addScheduleValue", scheduleValue);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "schedule_value",
        id
      });

      await dispatch("searchScheduleValue", {});

      return id;
    },

    async updateScheduleValue({ dispatch }, { id, doc }) {
      if (doc.projectId) {
        doc.isProjectArchived = await dispatch(
          "common/common/getIsProjectArchived",
          doc.projectId,
          { root: true }
        );
      }
      await dispatch("saveScheduleValue", { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "schedule_value",
        id
      });
      await dispatch("searchScheduleValue", {});

      return id;
    },

    async deleteScheduleValue({ dispatch }, id) {
      await dispatch("saveScheduleValue", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "schedule_value",
        id
      });
      await dispatch("searchScheduleValue", {});
    }
  };
};
