<template>
  <f7-popup tablet-fullscreen :opened="popupOpened" @popup:close="close">
    <preview-page
      ref="previewPage"
      title="Certificate Preview"
      :itemDetail="itemDetail"
      @close="close"
    ></preview-page>
  </f7-popup>
</template>
<script>
import PreviewPage from "../../pages/PreviewPage.vue";

export default {
  components: {
    PreviewPage
  },
  data() {
    return {
      popupOpened: false,
      itemDetail: {}
    };
  },

  methods: {
    open(itemDetail) {
      this.itemDetail = itemDetail;
      this.popupOpened = true;
      this.$refs.previewPage.lowResDesktopScreen();
    },
    close() {
      this.popupOpened = false;
      this.itemDetail = {};
    }
  }
};
</script>
