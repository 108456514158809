import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Schedule Of Value",
    name: "schedule-of-value",
    displayRoles: ["owner", "user"],
    routes: [
      {
        path: "/schedule-of-value",
        title: "Schedule Of Value",
        name: "schedule-of-value-main",
        parent: "finance-main",
        displayRoles: ["owner", "user"],
        icon: "arrow_right_square",
        async: checkAuth(() => import("../pages/ScheduleOfValueList.vue")),
        master: true,
        detailRoutes: [
          {
            path: "/schedule-of-value/detail/:id",
            name: "ScheduleDetails",
            title: "Schedule",
            async: checkAuth(() => import("../pages/ScheduleDetail.vue"))
          },
          {
            path:
              "/schedule/:boardType/:businessCode/pre-construction/project/:projectId/action/:actionId/schedule/:id/:newSchedule",
            name: "Schedule Details",
            async: checkAuth(() => import("../pages/ScheduleDetail.vue"))
          }
        ]
      }
    ]
  }
];
