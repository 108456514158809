import { applyFilter, groupBy } from "@/utility/filter-tools";
import { PAY_TYPE_SALARY } from "../../../../utility/const";
import _ from "lodash";

export default {
  userList: ({ userList }) => userList.filter(r => r.disabled == false),
  userListByHours: ({ userList }) => {
    const users = userList.filter(
      r =>
        r.disabled == false &&
        r.customClaims &&
        r.customClaims.payType !== PAY_TYPE_SALARY
    );
    return users;
  },
  userId: state => state.userId,
  userGroup: (_, getters) => (searchValue = "") => {
    const filteredUsers = applyFilter(getters.userList, searchValue, [
      "displayName"
    ]);
    let dataGroup = groupBy(filteredUsers, item =>
      ((item.displayName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName))
    }));
  },
  userById: (_, getters) => id => getters.userList.find(r => r.uid === id),
  userByIds: ({ userList }) => ids => {
    if (!ids) return "";
    return (userList.filter(r => (ids || []).includes(r.uid)) || [])
      .map(r => r.displayName)
      .join(", ");
  },
  user: state => {
    if (_.isEmpty(state.user)) {
      return state.user;
    }
    const email =
      (state.user.customClaims || {}).loginMethod === "email_by_phone"
        ? state.user.customClaims.displayEmail || ""
        : state.user.email;
    return { ...state.user, email };
  }
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
