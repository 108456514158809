<template>
  <div v-if="currentEmployeeId" style="padding-bottom: 75px;">
    <div class="week-header margin-bottom">
      <div
        class="date-header"
        v-for="(item, index) in dataSchedule"
        :key="index"
      >
        <div
          :class="
            `date-item ${
              selectedItem.date === item.date && currentView === 'day'
                ? 'date-item_active'
                : ''
            }`
          "
          @click="
            currentView === 'day'
              ? getScheduleByUserAndDate({
                  userId: currentEmployeeId,
                  date: item.date
                })
              : () => {}
          "
        >
          <div class="date-ddd">{{ item.dateFormatddd }}</div>
          <div class="date-DD">{{ item.dateFormatDD }}</div>
        </div>

        <f7-button
          v-show="isCopy"
          style="width: fit-content"
          @click.native="paste({ userId: currentEmployeeId, date: item.date })"
          icon-size="18"
          icon-f7="doc_on_clipboard"
        />

        <template v-if="currentView === 'week'">
          <schedule-mobile-popover
            v-for="(schedule, index) in item.details || []"
            :key="index"
            :item="schedule"
            @checkCreator="checkCreator"
            @goToProjectDetail="goToProjectDetail"
            @openAddressOnMap="openAddressOnMap"
            @openEditPopup="openEditPopup"
            @handleDelete="handleDelete"
            @handleAddPhoto="handleAddPhoto"
            @handleAddCopy="handleAddCopy"
            @openTimeLogPopup="openTimeLogPopup(schedule)"
          ></schedule-mobile-popover>
        </template>
      </div>
    </div>

    <template v-if="currentView === 'day'">
      <f7-card
        class="no-margin-horizontal"
        v-for="(item, index) in list"
        :key="index"
      >
        <f7-card-content>
          <div class="fw-600 display-flex align-items-start margin-bottom-half">
            <f7-icon
              size="18"
              class="margin-right-half"
              f7="clock"
              color="red"
            ></f7-icon>
            <div>
              {{ item.startTime | HMMA }} - {{ item.finishTime | HMMA }}
            </div>
          </div>
          <div class="fw-600 display-flex align-items-start">
            <f7-icon
              class="margin-right-half"
              f7="cube_box"
              color="red"
              size="18"
            ></f7-icon>
            <div v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
              {{
                timeOffType(item.timeOffType) &&
                  timeOffType(item.timeOffType).value
              }}
            </div>
            <div v-else-if="item.timeLogType === TIME_LOG_TYPE_PROJECT">
              <f7-link
                @click.native="
                  goToProjectDetail({
                    userId: item.userId,
                    projectId: item.projectId
                  })
                "
              >
                <span
                  v-if="
                    item.project &&
                      (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                        (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                          item.project.customerType ===
                            BUSINESS_CODE_COMMERCIAL))
                  "
                >
                  {{
                    item.project.propertyName
                      ? `${item.project.propertyName}`
                      : ""
                  }}{{
                    item.project.propertyName &&
                    getFullAddress(item.project.propertyAddress)
                      ? " - "
                      : ""
                  }}{{ getFullAddress(item.project.propertyAddress) }}
                </span>
                <span
                  v-else-if="
                    item.project &&
                      (item.project.businessCode ===
                        BUSINESS_CODE_RESIDENTIAL ||
                        (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                          (item.project.customerType ===
                            BUSINESS_CODE_RESIDENTIAL ||
                            item.project.customerType ===
                              BUSINESS_CODE_MULTIFAMILY)))
                  "
                >
                  {{
                    item.project.contactName
                      ? `${item.project.contactName}`
                      : ""
                  }}{{
                    item.project.contactName &&
                    getFullAddress(item.project.projectAddress)
                      ? " - "
                      : ""
                  }}{{ getFullAddress(item.project.projectAddress) }}
                </span>
              </f7-link>
            </div>
            <div v-else>{{ item.title }}</div>
          </div>
          <div
            v-if="item.notes"
            class="fw-600 display-flex align-items-start margin-top-half"
          >
            <f7-icon
              class="margin-right-half"
              f7="square_pencil"
              color="red"
              size="18"
            ></f7-icon>
            <div class="text-notes" v-html="displayNotes(item.notes)"></div>
          </div>
        </f7-card-content>
        <f7-card-footer>
          <f7-button
            v-if="isMyScheduling"
            class="flex-center margin-right-half"
            small
            fill
            popover-close
            @click="openTimeLogPopup(item)"
            >Start</f7-button
          >
          <f7-button
            v-if="
              item.project &&
                getFullAddress(item.project.propertyAddress) &&
                (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    item.project.customerType === BUSINESS_CODE_COMMERCIAL))
            "
            small
            outline
            @click.native="openAddressOnMap(item.project.propertyAddress)"
            >View Map</f7-button
          >
          <f7-button
            v-else-if="
              item.project &&
                getFullAddress(item.project.projectAddress) &&
                (item.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    (item.project.customerType === BUSINESS_CODE_RESIDENTIAL ||
                      item.project.customerType === BUSINESS_CODE_MULTIFAMILY)))
            "
            small
            outline
            @click.native="openAddressOnMap(item.project.projectAddress)"
            >View Map</f7-button
          >
          <div
            class="display-flex align-items-center justify-content-flex-end"
            style="flex:1"
          >
            <div v-if="item.scheduleChainId">
              <chain-popover
                :icon="checkCreator(item.createdBy) ? 'pencil' : 'eye'"
                :iconSize="18"
                popoverClass="popover-edit"
                @clickItem="openEditPopup({ item, type: $event })"
              ></chain-popover>
            </div>
            <div v-else>
              <f7-button
                class="flex-center"
                @click="openEditPopup({ item, type: '' })"
              >
                <f7-icon
                  class="icon"
                  size="18"
                  :f7="checkCreator(item.createdBy) ? 'pencil' : 'eye'"
                ></f7-icon>
              </f7-button>
            </div>

            <div v-if="item.scheduleChainId && checkCreator(item.createdBy)">
              <chain-popover
                icon="trash"
                :iconSize="18"
                popoverClass="popover-delete"
                @clickItem="handleDelete({ item, type: $event })"
              ></chain-popover>
            </div>
            <div
              v-else-if="!item.scheduleChainId && checkCreator(item.createdBy)"
            >
              <f7-button
                class="flex-center"
                @click="handleDelete({ item, type: '' })"
              >
                <f7-icon size="18" class="icon" f7="trash"></f7-icon>
              </f7-button>
            </div>
            <div>
              <f7-button class="flex-center" @click="handleAddCopy(item)">
                <f7-icon size="18" class="icon" f7="doc_on_doc"></f7-icon>
              </f7-button>
            </div>
            <div>
              <f7-button class="flex-center" @click="handleAddPhoto(item)">
                <f7-icon
                  size="18"
                  class="icon"
                  f7="photo_on_rectangle"
                ></f7-icon>
              </f7-button>
            </div>
          </div>
        </f7-card-footer>
      </f7-card>
    </template>

    <edit-schedule-popup
      :isShow="isShowEditPopup"
      @close="isShowEditPopup = false"
    ></edit-schedule-popup>
    <album-photo-popup ref="albumPhoto"></album-photo-popup>
    <view-address-popup ref="viewAddressPopup"></view-address-popup>
  </div>
  <f7-block v-else class="text-align-center">
    Please select Employee!
  </f7-block>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import EditSchedulePopup from "../popups/EditSchedulePopup.vue";
import ChainPopover from "../../components/popover/ChainPopover.vue";
import ScheduleMobilePopover from "../../components/popover/ScheduleMobilePopover.vue";
import moment from "moment";
import AlbumPhotoPopup from "../popups/AlbumPhotoPopup.vue";
import ViewAddressPopup from "../popups/ViewAddressPopup.vue";
import emailNotificationMixins from "../../mixins/email-notifications";
import commonMixin from "../../mixins/common";
import {
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_MULTIFAMILY
} from "../../../../utility/const";
import { getFullAddress } from "../../../../utility/address";
const TIME_FORMAT = "HH:mm";
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  props: {
    currentView: String,
    currentWeek: Array,
    currentEmployeeId: String
  },
  components: {
    EditSchedulePopup,
    ChainPopover,
    AlbumPhotoPopup,
    ViewAddressPopup,
    ScheduleMobilePopover
  },
  data: () => {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_MULTIFAMILY,
      isShowEditPopup: false,
      isCopy: false,
      dataCopy: {}
    };
  },
  mixins: [emailNotificationMixins, commonMixin],
  async mounted() {
    if (!this.currentUser.uid) {
      await this.getUserProfile();
    }
    await this.bindTimeTrackingDraf([
      {
        prop: "user_id",
        val: this.currentUser.uid,
        op: "=="
      }
    ]);
  },
  methods: {
    ...mapActions("scheduling/project", [
      "updateAssigneeIdsProject",
      "updateAdditionalAssigneeIdsProjectAlgolia",
      "handleUpdateAssigneeIdsWhenAddOrCopy",
      "handleUpdateAssigneeIdsWhenDelete"
    ]),
    ...mapActions("scheduling/chain", [
      "getChain",
      "deleteChain",
      "updateChain",
      "resetChain",
      "getScheduleChain"
    ]),
    ...mapActions("scheduling/scheduling", [
      "createSchedule",
      "selectItem",
      "setScheduleId",
      "deleteSchedule",
      "getScheduleListBys",
      "getSchedulesByUserAndProject",
      "setEditType",
      "setIsShowDetail",
      "getSchedulesByChainIdAndDate"
    ]),
    ...mapActions("scheduling/project", [
      "getProject",
      "getProjectById",
      "resetProject"
    ]),
    ...mapMutations("time-tracking/time-tracking", ["openTimerPopup"]),
    ...mapActions("time-tracking/time-tracking", ["setTimeLogInfoAvailable"]),
    ...mapActions({
      bindTimeTrackingDraf: "time-tracking/time-tracking-draf/bindTimerListBys"
    }),
    ...mapActions("setting/app/profile", ["getUserProfile"]),
    checkCreator(createdBy) {
      return (
        createdBy ===
          (this.currentUser.displayName || this.currentUser.email) ||
        (this.currentUser.customClaims || {}).role === "owner" ||
        this.isAdminGroup
      );
    },

    timeOffType(value) {
      return this.timeOffTypeList.find(r => r.value === value);
    },

    getScheduleByUserAndDate(item) {
      this.selectItem(item);
    },

    convertDateToTimestamp(date) {
      return this.$google.firebase.firestore.Timestamp.fromDate(
        new Date(moment(date, "MM/DD/YYYY").format("MM/DD/YYYY"))
      );
    },

    async openEditPopup({ item, type }) {
      this.setIsShowDetail(!this.checkCreator(item.createdBy));
      this.setScheduleId(item.id);
      if (item.scheduleChainId) {
        this.resetChain();
        await this.getChain(item.scheduleChainId);
        switch (type) {
          case "thisShift":
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.convertDateToTimestamp(this.schedule.date),
              chainId: item.scheduleChainId
            });
            break;
          case "allShifts":
            await this.getSchedulesByChainIdAndDate({
              startDate: this.chain.startDate,
              endDate: this.chain.endDate,
              chainId: item.scheduleChainId
            });
            break;
          case "thisShiftAndAllRemaining":
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.chain.endDate,
              chainId: item.scheduleChainId
            });
            break;
        }
      }
      this.setEditType(type);
      this.isShowEditPopup = true;
      this.selectItem(item);
    },

    async handleDelete({ item, type }) {
      this.setScheduleId(item.id);
      this.setEditType(type);
      if (item.scheduleChainId) {
        this.resetChain();
        await this.getChain(item.scheduleChainId);
        switch (type) {
          case "thisShift":
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.convertDateToTimestamp(this.schedule.date),
              chainId: item.scheduleChainId
            });
            break;
          case "allShifts":
            await this.getSchedulesByChainIdAndDate({
              startDate: this.chain.startDate,
              endDate: this.chain.endDate,
              chainId: item.scheduleChainId
            });
            break;
          case "thisShiftAndAllRemaining":
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.chain.endDate,
              chainId: item.scheduleChainId
            });
            break;
        }
      }
      this.$ri.dialog.openWarningDialog({
        title: "Delete shift",
        content: "Are you sure you want to delete?",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            await this.delete(type);
            this.$f7.preloader.hide();
            _sefl.app.dialog.close();
          }
        }
      });
    },

    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },

    sortLatestByDate(list) {
      const listSort = (list || []).slice().sort((a, b) => {
        const aDate = a.date || { nanoseconds: 0, seconds: 0 };
        const bDate = b.date || { nanoseconds: 0, seconds: 0 };
        return (this.dateToValue(aDate) - this.dateToValue(bDate)) * -1;
      });
      return listSort;
    },

    async delete(type) {
      let promises = [];
      let schedulesByChainId = [];
      const currentSchedule = _.cloneDeep(this.schedule);
      if (currentSchedule.scheduleChainId) {
        schedulesByChainId = await this.getScheduleListBys([
          {
            prop: "scheduleChainId",
            val: currentSchedule.scheduleChainId,
            op: "=="
          }
        ]);
      }
      const chain =
        currentSchedule.scheduleChainId &&
        (await this.getScheduleChain(currentSchedule.scheduleChainId));
      switch (type) {
        case "thisShiftAndAllRemaining": {
          const schedulesAfterSelectDate = schedulesByChainId.filter(
            r =>
              !moment(r.date.toDate()).isBefore(
                moment(moment(currentSchedule.date, "MM/DD/YYYY"), "day")
              )
          );

          let remainSchedules = _.differenceWith(
            schedulesByChainId,
            schedulesAfterSelectDate,
            _.isEqual
          );

          remainSchedules = this.sortLatestByDate(remainSchedules);

          if (remainSchedules.length === 0) {
            promises.push(this.deleteChain(currentSchedule.scheduleChainId));
          } else {
            promises.push(
              this.updateChain({
                id: currentSchedule.scheduleChainId,
                doc: {
                  endDate: remainSchedules[0].date
                }
              })
            );
          }

          schedulesAfterSelectDate.forEach(r => {
            promises.push(this.deleteSchedule(r.id));
          });
          await Promise.all(promises);
          this.sendDeleteChainEmailNotification(
            chain,
            schedulesAfterSelectDate[0]
          );
          break;
        }
        case "allShifts": {
          promises.push(this.deleteChain(currentSchedule.scheduleChainId));

          schedulesByChainId.forEach(r => {
            promises.push(this.deleteSchedule(r.id));
          });
          await Promise.all(promises);
          this.sendDeleteChainEmailNotification(chain, schedulesByChainId[0]);
          break;
        }
        case "thisShift": {
          let remainSchedules = schedulesByChainId.filter(
            r => r.id !== this.scheduleId
          );
          remainSchedules = this.sortLatestByDate(remainSchedules);
          if (remainSchedules.length === 0) {
            promises.push(this.deleteChain(currentSchedule.scheduleChainId));
          } else {
            promises.push(
              this.updateChain({
                id: currentSchedule.scheduleChainId,
                doc: {
                  startDate: remainSchedules[remainSchedules.length - 1].date,
                  endDate: remainSchedules[0].date
                }
              })
            );
          }
          let schedule = this.scheduleList.find(r => r.id === this.scheduleId);
          await this.deleteSchedule(this.scheduleId);
          this.sendDeleteScheduleEmailNotification(schedule);
          break;
        }
        default: {
          let schedule = this.scheduleList.find(r => r.id === this.scheduleId);
          await this.deleteSchedule(this.scheduleId);
          this.sendDeleteScheduleEmailNotification(schedule);
          break;
        }
      }
      await this.handleUpdateAssigneeIdsWhenDelete(currentSchedule); // call after delete schedule
    },

    handleAddCopy(item) {
      this.isCopy = true;
      this.dataCopy = item;
    },

    cancelPaste() {
      this.isCopy = false;
      this.dataCopy = {};
    },

    validateOverTimeWhenCopy(data) {
      const start = moment(data.startTime, TIME_FORMAT);
      const end = moment(data.finishTime, TIME_FORMAT);

      const list =
        this.scheduleList.filter(
          s =>
            s.userId === data.userId &&
            s.date === moment(data.date.toDate()).format("MM/DD/YYYY")
        ) || [];

      if (list.length > 0) {
        for (const item of list) {
          const startTime = moment(item.startTime, TIME_FORMAT);
          const finishTime = moment(item.finishTime, TIME_FORMAT);
          if (
            startTime.isBetween(start, end) ||
            start.isBetween(startTime, finishTime) ||
            start.isSame(startTime)
          ) {
            return false;
          } else if (
            finishTime.isBetween(start, end) ||
            end.isBetween(startTime, finishTime) ||
            end.isSame(finishTime)
          ) {
            return false;
          }
        }
      }
      return true;
    },

    paste(item) {
      this.$f7.preloader.show();
      let promises = [];
      const data = {
        userId: item.userId,
        date: this.$google.firebase.firestore.Timestamp.fromDate(
          new Date(item.date)
        ),
        projectId: this.dataCopy.projectId,
        project: this.dataCopy.project,
        startTime: this.dataCopy.startTime,
        finishTime: this.dataCopy.finishTime,
        notes: this.dataCopy.notes,
        color: this.dataCopy.color || null,
        timeLogType: this.dataCopy.timeLogType || "project",
        timeOffType: this.dataCopy.timeOffType || null,
        title: this.dataCopy.title || ""
      };

      if (this.validateOverTimeWhenCopy(data)) {
        // create data and update assignIds
        promises.push(
          this.handleUpdateAssigneeIdsWhenAddOrCopy({
            newSchedule: data,
            userIds: [data.userId]
          })
        );
        promises.push(this.createSchedule(data));
        this.sendCreatedEmailNotification(data);
        Promise.all(promises).then(() => {
          this.$f7.preloader.hide();
        });
      } else {
        this.$f7.preloader.hide();
        this.$ri.dialog.openErrorDialog({
          title: "Error",
          content: "Overlap detected. Please recheck time?",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      }
    },

    handleAddPhoto(item) {
      this.$refs.albumPhoto.openAlbumPhoto(item.projectId);
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    goToProjectDetail({ userId, projectId }) {
      this.navigateToBoard(
        this.isMyScheduling ? "my-scheduling" : "employee-scheduling",
        userId,
        projectId
      );
    },

    openAddressOnMap(address) {
      this.$refs.viewAddressPopup.openPopup(getFullAddress(address));
    },

    getValuesFromLocalStorage(keys, suffix) {
      const data = {};
      keys.forEach(key => {
        const value = localStorage.getItem(`${key}${suffix}`);
        if (value !== null) {
          data[key] = value;
        }
      });
      return data;
    },

    openTimeLogPopup(item) {
      //get data unsubmit

      let timeLogInfoUnsubmit = {
        timeLogType:
          this.lastDrafTimeTracking.timeLogTypeUnsubmittedService || "",
        projectId: this.lastDrafTimeTracking.projectIdUnsubmittedService || "",
        classItem: this.lastDrafTimeTracking.classItemUnsubmittedService || "",
        serviceItem:
          this.lastDrafTimeTracking.serviceItemUnsubmittedService || "",
        title: this.lastDrafTimeTracking.titleUnsubmittedService || ""
      };
      //if all value "" => set timeLogInfoUnsubmit = {}
      if (
        Object.keys(timeLogInfoUnsubmit).every(
          key => timeLogInfoUnsubmit[key] === ""
        )
      ) {
        timeLogInfoUnsubmit = {};
      }

      //show popup submit old time log when !isSubmitTimeLog && timeLogInfoUnsubmit
      //else => don't show popup
      if (
        !this.lastDrafTimeTracking.submitted &&
        !_.isEmpty(timeLogInfoUnsubmit)
      ) {
        this.$ri.dialog.openWarningDialog({
          title: "Submit previous timelog",
          content:
            "Before logging a new time log, please submit your previous time log.",
          textButton: "See Timelog",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.openTimerPopup();
              this.setTimeLogInfoAvailable({ ...item });
            }
          }
        });
      } else {
        this.openTimerPopup();
        this.setTimeLogInfoAvailable({ ...item });
      }
    }
  },
  computed: {
    ...mapGetters("scheduling/user", ["userById"]),
    ...mapGetters("scheduling/scheduling", [
      "schedule",
      "scheduleList",
      "selectedItem",
      "totalWorkTime",
      "isMyScheduling",
      "filterBySelectedItem",
      "scheduleId"
    ]),
    ...mapGetters("scheduling/project", ["project"]),
    ...mapGetters("scheduling/app-constant", ["timeOffTypeList"]),
    ...mapGetters("scheduling/chain", ["chain"]),
    ...mapGetters("setting/app/group", ["isAdminGroup"]),
    ...mapGetters("setting/app/profile", ["currentUser"]),
    ...mapGetters({
      time_tracking_drafList: "time-tracking/time-tracking-draf/timerList"
    }),

    lastDrafTimeTracking() {
      if (
        !this.time_tracking_drafList &&
        this.time_tracking_drafList.length < 1
      )
        return null;
      return this.time_tracking_drafList[0];
    },
    list() {
      return _.cloneDeep(this.filterBySelectedItem);
    },

    dataSchedule() {
      const list = this.currentWeek.map(d => ({
        ...d,
        details: (
          this.scheduleList.filter(
            s => s.userId === this.currentEmployeeId && s.date === d.date
          ) || []
        ).sort((a, b) => sortBy(a.startTime, b.startTime))
      }));
      return list;
    },
    displayNotes() {
      return notes => notes.replace(/\r\n|\r|\n/g, "<br />");
    }
  }
};
</script>
<style lang="scss" scoped>
.week-header {
  display: flex;
  gap: 8px;
}
.date-header {
  width: calc((100% - (6 * 8px)) / 7);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .date-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 4px;
    border-radius: 4px;
    background: var(--f7-color-bg-6-neutral);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .date-ddd {
      color: #878787;
      font-size: 12px;
    }
    .date-DD {
      color: var(--f7-color-text-neutral);
      font-weight: 600;
      font-size: 18px;
    }

    &_active {
      background-color: var(--f7-theme-color);

      .date-ddd {
        color: white;
        font-size: 14px;
      }
      .date-DD {
        color: white;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}
.icon {
  color: var(--f7-theme-color);
  font-size: var(--swimlane-icon-font-size);
}
.fw-600 {
  font-weight: 600;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-notes {
  max-height: 60px;
  width: 100%;
  overflow-y: auto;
}
</style>
