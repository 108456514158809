export const SET_CURRENT_CONTACT = "SET_CURRENT_CONTACT";
export const SET_CURRENT_HISTORY = "SET_CURRENT_HISTORY";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const SET_CURRENT_PROPERTY = "SET_CURRENT_PROPERTY";

export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_CURRENT_CONTACT_DATA = "SET_CURRENT_CONTACT_DATA";
export const SET_CURRENT_COMPANY_DATA = "SET_CURRENT_COMPANY_DATA";

export const SET_COMPANY_ACTIVE_TAB = "SET_COMPANY_ACTIVE_TAB";

export const SET_CREATED_PROPERTY_ID = "SET_CREATED_PROPERTY_ID";
export const SET_CREATED_CONTACT_ID = "SET_CREATED_CONTACT_ID";
export const SET_CREATED_COMPANY_ID = "SET_CREATED_COMPANY_ID";
export const SET_CONTACT_COMPANY_REFS = "SET_CONTACT_COMPANY_REFS";
export const SET_COMPANY_BRANCH_REFS = "SET_COMPANY_BRANCH_REFS";
export const SET_OLD_COMPANY_ID = "SET_OLD_COMPANY_ID";
export const SET_OLD_CONTACT_ID = "SET_OLD_CONTACT_ID";
export const SET_IS_EDIT_COMPANY = "SET_IS_EDIT_COMPANY";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_IS_EDIT_CONTACT = "SET_IS_EDIT_CONTACT";
export const SET_BRANCH = "SET_BRANCH";
export const SET_IS_NEW_COMPANY = "SET_IS_NEW_COMPANY";
export const SET_TEMP_CONTACT = "SET_TEMP_CONTACT";
export const SET_IS_NEW_CONTACT = "SET_IS_NEW_CONTACT";
export const SET_COMPANY_CONTACT_REFS = "SET_COMPANY_CONTACT_REFS";
export const SET_COMPANY_PROPERTY_REFS = "SET_COMPANY_PROPERTY_REFS";
export const SET_PROPERTY_CONTACT_REFS = "SET_PROPERTY_CONTACT_REFS";
export const SET_IS_EDIT_PROPERTY = "SET_IS_EDIT_PROPERTY";
export const SET_REMOVE_COMPANY_BRANCH_REFS = "SET_REMOVE_COMPANY_BRANCH_REFS";
export const UNSET_PROPERTY_COMPANY_REFS = "UNSET_PROPERTY_COMPANY_REFS";
export const UNSET_COMPANY_CONTACT_REFS = "UNSET_COMPANY_CONTACT_REFS";
