export default {
  assemblyDrawing: state => state.assemblyDrawing,
  searchValue: state => state.searchValue,
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page
};
