import Vue from "vue";
import App from "./App.vue";
import store from "./services/store.service";

import Framework7 from "framework7/framework7.esm.bundle.js";
import i18n from "./lang/i18n";

import Framework7Vue from "framework7-vue";
import VTooltip from "v-tooltip";
import VueSignature from "vue-signature-pad";
import VueObserveVisibility from "vue-observe-visibility";

import "framework7-icons";
// Init plugin
Framework7.use(Framework7Vue);
Vue.use(VueSignature);
Vue.use(VTooltip);
Vue.use(VueObserveVisibility);

import GoogleMapPlacesPlugin from "./services/place.google.service";
import GoogleFirebaseJavascriptSdk from "./services/firebase.js-sdk.js";
import GoogleApisPlugin from "./services/google.api.plugin.service";

Vue.use(GoogleMapPlacesPlugin, {
  key: "AIzaSyDiJ1MoZTMEW9Fg4waFGL8-yTpUB32Fo0U"
});
Vue.use(GoogleFirebaseJavascriptSdk);
Vue.use(GoogleApisPlugin);
// Register components
import "./services/components.service";

// Register virtual scroller
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
Vue.use(VueVirtualScroller);

// Register Vue perfect scroller
// import PerfectScrollbar from "vue2-perfect-scrollbar";
// import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
// Vue.use(PerfectScrollbar);

// Validation
// import "./services/validation.service";
import "./filters";
import "./utility/timeago";
import "./utility/required-asterisk";
Vue.config.productionTip = false;
import { getTenant } from "./services/authentication.service";
import registerSW from "./registerServiceWorker.js";
import {
  openInfoDialog,
  openSuccessDialog,
  openWarningDialog,
  openErrorDialog
} from "./dialog";

var app = {};
getTenant(tenant => {
  app = new Vue({
    // router,
    i18n,
    store: store(tenant),
    render: h => h(App)
  }).$mount("#app");

  Vue.prototype.$ri = {
    dialog: {
      openInfoDialog,
      openSuccessDialog,
      openWarningDialog,
      openErrorDialog
    }
  };
  if (import.meta.env.MODE !== "development") {
    registerSW();

    // if (!app.notificationUpdate) {
    //   app.notificationUpdate = app.$f7.notification.create({
    //     icon: '<i class="icon icon-f7"></i>',
    //     title: "New update is available",
    //     text: "Please click <a id='link-noti' >here</a> to re-load app.",
    //     closeButton: true
    //   });
    // }

    // registerSW(app.notificationUpdate);
  }
});

// var app = new Vue({
//   // router,
//   store,
//   render: h => h(App)
// }).$mount("#app");

import "framework7/css/framework7.bundle.css";
import "./assets/css/app.css";
export { app };
