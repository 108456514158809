export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_NUMBER_OF_ROWS = "SET_NUMBER_OF_ROWS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER";
export const DELETE_SEARCH_ITEM = "DELETE_SEARCH_ITEM";
export const RESET_SEARCH = "RESET_SEARCH";
export const ADD_MORE_RESULT = "ADD_MORE_RESULT";

export const CONTACT_LIST_ITEM_UPDATE = "CONTACT_LIST_ITEM_UPDATE";
export const CONTACT_LIST_ITEM_ADD = "CONTACT_LIST_ITEM_ADD";
export const CONTACT_LIST_ITEM_REMOVE = "CONTACT_LIST_ITEM_REMOVE";

export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_QUERY_FILTER = "SET_QUERY_FILTER";
