<template>
  <f7-popup
    class="demo-popup"
    :opened="isShowSetting"
    @popup:closed="$emit('cancel')"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Setting schedules</f7-nav-title>
      </f7-navbar>
      <div>
        <f7-block>
          <f7-row class="justify-content-flex-end">
            <f7-button @click="addNewSchedule" fill>Add new column</f7-button>
          </f7-row>
        </f7-block>
      </div>
      <div class="data-table">
        <data-table
          :headers="headers"
          :items="sortedItems"
          :pageSize="(sortedItems || []).length"
          ref="dataTable"
        >
          <template v-slot:body="{ item }">
            <td class="text-align-left" style="font-weight: bold">
              <f7-checkbox
                :checked="scheduleColunms.includes(item.id)"
                @change="changeScheduleColunm(item.id, $event.target.checked)"
              ></f7-checkbox>
            </td>
            <td class="text-align-left">
              <span>
                <EnterInput
                  :value="item.name"
                  placeholder="Enter item name"
                  @change="changeSchedule('name', item.id, $event)"
                />
              </span>
            </td>
            <td class="text-align-left">
              {{ item.createdBy }}
            </td>
            <td
              class="text-align-left"
              @click="$emit('removeDataSchedule', item.id)"
            >
              <a href="#">
                <i class="f7-icons" style="color: red;">trash</i>
              </a>
            </td>
          </template>
        </data-table>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import DataTable from "@/components/datatables";
import EnterInput from "../inputs/EnterInput.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      schedules: []
    };
  },
  props: {
    items: Array,
    isShowSetting: Boolean
  },
  components: {
    DataTable,
    EnterInput
  },
  methods: {
    changeSchedule(column, id, value) {
      const data = {
        id,
        column,
        value
      };
      this.$emit("changeSchedule", data);
    },
    changeScheduleColunm(id, value) {
      const data = {
        id,
        value
      };
      this.$emit("changeScheduleColunm", data);
    },
    focusLastItem() {
      var lastInput = this.$refs.dataTable.$el.querySelector(
        "tbody tr:last-child td:nth-child(2) span input"
      );
      if (lastInput) {
        lastInput.focus();
      }
    },
    addNewSchedule() {
      this.$emit("addNewSchedule", () => this.focusLastItem());
    }
  },
  computed: {
    ...mapGetters("common/schedule-value", ["scheduleColunms"]),
    sortedItems() {
      let sortedList = this.items.slice().sort((a, b) => {
        if (!b.createdAt) return -1;
        return (a.createdAt || {}).seconds - (b.createdAt || {}).seconds;
      });
      return sortedList;
    },
    headers() {
      return [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "profitMargin"
        },
        {
          text: "Item Name",
          value: "totalCost",
          sortable: false,
          align: "left"
        },
        {
          text: "Created by",
          align: "left",
          sortable: false,
          value: "profitMargin"
        },
        {
          text: "Action",
          value: "offical_estimate",
          sortable: false,
          align: "left"
        }
      ];
    }
  }
};
</script>
