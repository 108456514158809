export default {
  expenseList: (state, _, __, rootGetters) => {
    const projectList = rootGetters["reports/project/projectList"];
    const users = rootGetters["reports/user/userList"];
    const expenseTypeList = rootGetters["reports/app-constant/expenseTypeList"];
    const expenseResult = state.expenseList.map(expense => {
      const project =
        projectList.find(project => project.id === expense.projectId) || {};
      const user = users.find(user => user.uid === expense.payeeId) || {};
      const expenseTypeName = (
        expenseTypeList.find(item => item.value === expense.reportType) || {}
      ).displayName;
      return {
        ...expense,
        project,
        user,
        expenseTypeName
      };
    });
    return expenseResult;
  },
  expenseId: state => state.expenseId,
  expense: (state, getters) => {
    const expenseList = getters.expenseList;
    const expense = expenseList.find(r => r.id === state.expenseId);
    if (expense) return expense;
    if (expenseList.length === 0) return {};
    return null;
  }
};
