import { CODE_STATUS_OPTION, ENTITY_PRODUCT_ITEM } from "@/utility/const";

export default {
  productItemStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_PRODUCT_ITEM
    ),

  productItemStatusByValue: state => value =>
    state.typeList.find(item => item.value === value) || {}
};
