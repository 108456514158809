import * as types from "./types";
import Vue from "vue";
import _ from "lodash";

export default {
  setPriceListDetailNumber(state, id) {
    state.priceListDetailNumber = id;
  },
  [types.PRICE_LIST](state, list) {
    Vue.set(state, "priceList", list);
  },
  [types.PRICE_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.priceList.findIndex(item => item.id === id);

    if (index >= 0) {
      const est = _.cloneDeep(state.priceList[index]);

      for (const key in doc) {
        if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
          est[key] = doc[key];
        }
      }
      Vue.set(state.priceList, index, est);
    }
  },

  [types.PRICE_LIST_ITEM_ADD](state, contact) {
    state.priceList.unshift(contact);
  },

  [types.PRICE_LIST_ITEM_REMOVE](state, id) {
    const index = state.priceList.findIndex(item => item.id === id);
    if (index >= 0) {
      state.priceList.splice(index, 1);
    }
  }
};
