<template>
  <f7-popup tablet-fullscreen class="demo-popup" :opened="popupOpened" @popup:closed="close">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close @click.native="close">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ isNewRecurring ? "New" : "Edit" }} Recurring Invoice</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="save()">Save </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <new-recurring-invoice ref="newRecurringInvoice" :invoiceGroupIdProp="invoiceGroupId" :projectIdProp="projectId"
        :isNewRecurring="isNewRecurring" :recurringInvoiceId="recurringInvoiceId" :isPopup="true" @done="done"></new-recurring-invoice>
    </f7-page>
  </f7-popup>
</template>

<script>
import NewRecurringInvoice from '../details/NewRecurringInvoice.vue';
export default {
  components: { NewRecurringInvoice },
  data: () => {
    return {
      popupOpened: false,
      isNewRecurring: true,
      invoiceGroupId: "",
      projectId: "",
      recurringInvoiceId: "",
    };
  },
  methods: {
    done() {
      this.close()
    },

    close() {
      this.isNewRecurring = true
      this.popupOpened = false
      this.invoiceGroupId = ""
      this.recurringInvoiceId = ""
      this.$refs.newRecurringInvoice.cancel()
      this.$emit("closePopup")
    },

    open(invoiceGroupId, isNewRecurring = true,recurringInvoiceId="",projectId="") {
      this.isNewRecurring = isNewRecurring
      this.invoiceGroupId = invoiceGroupId
      this.recurringInvoiceId = recurringInvoiceId
      this.projectId = projectId
      this.popupOpened = true
    },

    save() {
      this.$refs.newRecurringInvoice.save()
    }
  },

};
</script>
<style land="scss" scoped>
.flex-start {
  justify-content: flex-start;
}

.select-type ::v-deep select {
  min-width: 40px;
}
</style>
