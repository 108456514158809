import { TABLE_CONTENT_TYPE_ITEMIZE, TABLE_CONTENT_TYPE_LABOR_MATERIAL } from "@/utility/const";

export default {
  computed: {
    totalInvoice() {
      return invoiceDetail => {
        const shippingCharge = this.getShippingChargeValue(invoiceDetail);
        const totalIntoMoney = this.getTotalIntoMoney(invoiceDetail);

        return totalIntoMoney + shippingCharge;
      };
    }
  },

  methods: {
    getTotalIntoMoney(invoiceDetail) {
      if (!invoiceDetail) return 0;
      const items = invoiceDetail.itemDetails;

      if (!items?.length) return 0;

      return items.reduce((acc, item) => acc + item.intoMoney, 0);
    },

    getShippingChargeValue(invoiceDetail) {
      if (!invoiceDetail) return 0;

      if (invoiceDetail.shippingCharge?.type === "cash") {
        return invoiceDetail.shippingCharge.value;
      } else if (invoiceDetail.shippingCharge?.type === "percent") {
        return (
          (this.getTotalIntoMoney(invoiceDetail) *
            invoiceDetail.shippingCharge.value) /
          100
        );
      }
      return 0;
    },

    getTaxList(invoiceDetail) {
      if (!invoiceDetail || !invoiceDetail.itemDetails) return [];

      const taxMap = {};
      invoiceDetail.itemDetails.forEach(item => {
        const taxPercent = item.taxPercent || 0; // Default to 0 if undefined
        const { netSales } = item;

        const typesAcceptCalculateTax = [TABLE_CONTENT_TYPE_ITEMIZE, TABLE_CONTENT_TYPE_LABOR_MATERIAL]

        const taxAmount =
          !typesAcceptCalculateTax.includes(invoiceDetail.tableContentType)
            ? item.taxAmount
            : netSales * (taxPercent / 100);
        if (taxMap[taxPercent]) {
          taxMap[taxPercent].taxAmount += taxAmount;
          taxMap[taxPercent].netSalesAmount += netSales;
        } else {
          taxMap[taxPercent] = {
            taxAmount: taxAmount,
            netSalesAmount: netSales
          };
        }
      });

      const taxList = Object.keys(taxMap).map(taxPercent => ({
        taxName: taxPercent === "0" ? "0" : taxPercent,
        taxAmount: taxMap[taxPercent].taxAmount
          ? parseFloat(taxMap[taxPercent].taxAmount.toFixed(2))
          : 0,
        netSalesAmount:
          parseFloat(taxMap[taxPercent].netSalesAmount?.toFixed(2)) || 0
      }));

      return taxList;
    },

    getTotalDiscount(invoiceDetail) {
      if (!invoiceDetail || !invoiceDetail.itemDetails) return 0;
      if (invoiceDetail.syncFromQB) return invoiceDetail.discount.value;
      return invoiceDetail.itemDetails.reduce(
        (accumulator, item) =>
          accumulator +
          parseFloat(
            invoiceDetail.discountType === "percent"
              ? item.amount * (item.discountValue / 100) || 0
              : item.discountValue || 0
          ),
        0
      );
    }
  }
};
