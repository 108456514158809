<template>
  <div>
    <f7-block-header>Contact Information</f7-block-header>
    <f7-list media-list>
      <!-- Contact -->
      <contact-name-input ref="selectContact" media-list :displayCard="displayCard"
        :errorMessage="contactNameErrorMessage" :contactName="contactName" :companyId="displayCard.companyId"
        :queryFilters="`${company.contactRefs && company.contactRefs.length
          ? `companyIdRefs:${company.id}`
          : ''
          }`
          " @onSelected="handleContactSelected" @onDeleteContact="onDeleteContact($event)"
        @onCreated="handleContactCreated" :autoFocus="true" :tabIndex="1">
      </contact-name-input>

      <!-- Company -->
      <company-name-input ref="selectCompany" media-list :displayCard="displayCard"
        :errorMessage="companyNameErrorMessage" :companyName="companyName" :contactId="displayCard.contactId"
        :queryFilters="`${contact.companyRefs && contact.companyRefs.length
          ? `contactIdRefs:${contact.id} AND`
          : ''
          } NOT companyTypes:${COMPANY_TYPE_VENDOR}`
          " @onSelected="handleCompanySelected" @onDeleteCompany="onDeleteContact($event)"
        @onCreated="handleCompanyCreated" :tabIndex="2" />

      <f7-list-input label="Lead Source" type="select" placeholder="Please choose..." :value="leadSource"
        @input="leadSource = $event.target.value">
        <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
        <option value="" disabled>Select lead source</option>
        <option v-for="(type, index) in leadSourceCommercialList" :key="index" :value="type.value">{{ type.displayName
          }}</option>
      </f7-list-input>
    </f7-list>

    <f7-block-header>Project Information</f7-block-header>
    <f7-list media-list>
      <!-- Property -->
      <property-name-input ref="selectProperty" media-list :displayCard="displayCard"
        :errorMessage="propertyNameErrorMessage" :propertyName="propertyName" :companyId="displayCard.companyId"
        :queryFilters="`${company.propertyRefs && company.propertyRefs.length
          ? `companyIdRefs:${company.id}`
          : ''
          }`
          " @onSelected="handlePropertySelected" @onDeleteProperty="onDeleteProperty($event)"
        @onCreated="handlePropertyCreated" :tabIndex="3" />

      <!-- title -->
      <f7-list-input label="Project Title" type="text" placeholder="Enter project title" clear-button
        :value="displayCard.title" @input="set('title', $event.target.value.trim())" :error-message="titleErrorMessage"
        error-message-force :tabindex="4">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="ticket"></input-icon>
      </f7-list-input>

      <!-- Actions -->
      <f7-list-input :value="actionId" @input="actionId = $event.target.value" label="Move Project To" type="select"
        :tabindex="5">
        <option v-for="action in actionListSorted" :key="action.id" :value="action.id">{{ action.title }}</option>
        <input-icon slot="media" icon="arrow_2_squarepath"></input-icon>
      </f7-list-input>

      <!-- contact due date -->
      <f7-list-input v-if="!isNew" label="Due Date" type="datepicker" :calendar-params="{
        backdrop: true,
        openIn: 'customModal',
        header: true,
        footer: false,
        dateFormat: 'mm/dd/yyyy',
        disabled: {
          from: null,
          to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
        },
        closeOnSelect: true
      }" :value="dueDate" @calendar:change="dueDate = $event">
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>

      <f7-list-input label="Description" type="textarea" placeholder="Description" :value="description"
        @input="description = $event.target.value" :tabindex="6">
        <input-icon slot="media" icon="doc_text"></input-icon>
      </f7-list-input>
    </f7-list>

    <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
      add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
      @input="uploadedAttachment($event)"></attachment-input>

    <!-- Assignee -->
    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
      :isNew="isNew"
    ></user-input>

    <!-- note -->
    <note-editor @onChange="changeValueNotes" :value="note"></note-editor>

    <!-- Popup -->
    <date-popup ref="dueDatePopup" title="Choose Due Date" label="Due Date" v-model="dueDate" @done="create" validate
      not-allow-past></date-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import AttachmentInput from "../input/AttachmentInput.vue";

import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { firebase, auth } from "../../../../services/firebase.service";
import { getFullAddress } from "@/utility/address";
import { toDateCalendar, toDateFirebase } from "../../../../utility/datetime";
import Vue from "vue";
import methodsMixins from "../../mixin/methods";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import {
  COMPANY_TYPE_INSURANCE,
  COMPANY_TYPE_GENERAL_CONTRACTOR,
  COMPANY_TYPE_PROPERTY_MANAGEMENT,
  COMPANY_TYPE_PROPERTY_OWNER,
  COMPANY_TYPE_VENDOR,
  COMPANY_TYPE_TENANT,
  COLLECTION_OPRATION_CARD,
  VALIDATION_MESSAGE
} from "../../../../utility/const";

import ContactNameInput from "@/components/inputs/ContactNameInput.vue";
import CompanyNameInput from "@/components/inputs/CompanyNameInput.vue";
import PropertyNameInput from "@/components/inputs/PropertyNameInput.vue";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  mixins: [methodsMixins],

  components: {
    UserInput,
    DatePopup,
    InputIcon,
    AttachmentInput,
    ContactNameInput,
    CompanyNameInput,
    PropertyNameInput,
    NoteEditor
    // AddNewContactButton
  },

  props: { isNew: { type: Boolean, default: false } },

  data: () => {
    return {
      COMPANY_TYPE_INSURANCE,
      COMPANY_TYPE_GENERAL_CONTRACTOR,
      COMPANY_TYPE_PROPERTY_MANAGEMENT,
      COMPANY_TYPE_PROPERTY_OWNER,
      COMPANY_TYPE_VENDOR,
      COMPANY_TYPE_TENANT,
      COLLECTION_OPRATION_CARD,
      newContact: false,
      newCompany: false,
      displayCard: {
        contactId: "",
        companyId: "",
        propertyId: ""
        // leadSource: ""
      },
      dueDate: [],
      note: "",
      actionId: "",
      currentResponse: {},
      selectCompanyMessage: "",
      selectPropertyMessage: "",

      leadSource: "",
      description: "",

      newPropertyIds: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler(val) {
        if (_.isEmpty(val)) {
          this.clearActionData();
        } else {
          this.initCardDisplayValues();
        }
      }
    },
    displayCard: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.contactId && this.selectCompanyMessage != "") {
          this.selectCompanyMessage = "";
        }
        if (
          val.contactId &&
          val.companyId &&
          this.selectPropertyMessage != ""
        ) {
          this.selectPropertyMessage = "";
        }
      }
    },
    action: {
      deep: true,
      immediate: true,
      handler() {
        this.initActionDisplayValues();
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    contactName: {
      required
    },
    companyName: {
      required
    },
    propertyName: {
      required
    },
    displayCard: { title: { required } }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"]),
    ...mapGetters("dashboard/project", ["actionListSorted", "actionByCode"]),

    ...mapGetters("dashboard/client", ["contact", "company", "property"]),
    ...mapGetters("setting/app/profile", ["user"]),

    ...mapGetters("dashboard/app-constant", ["leadSourceCommercialList"]),

    contactNameErrorMessage() {
      if (!this.v$.contactName.$error) return null;
      if (this.v$.contactName.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    },
    companyNameErrorMessage() {
      if (!this.v$.companyName.$error) return null;
      if (this.v$.companyName.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    },
    propertyNameErrorMessage() {
      if (!this.v$.propertyName.$error) return null;
      if (this.v$.propertyName.required.$invalid && this.showProperty)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    },

    titleErrorMessage() {
      if (!this.v$.displayCard.title.$error) return null;
      if (this.v$.displayCard.title.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    },

    contactName() {
      return !_.isEmpty(this.contact)
        ? (
          (this.contact.firstName || "") +
          " " +
          (this.contact.lastName || "")
        ).trim()
        : "";
    },

    contactAddress() {
      if (!_.isEmpty(this.contact)) {
        const addresses = this.contact.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },

    companyName() {
      return this.company ? this.company.companyName || "" : "";
    },

    propertyName() {
      return this.property ? this.property.propertyName || "" : "";
    },

    /**
     * Decide to show property input
     *
     * 08/28/2020   Chien Phan    DEV-43: Any companies could be a client.
     */
    showProperty() {
      return true;
    },

    /**
     * Check for this company has property or not
     */
    hasPropertyRefs() {
      return this.company.propertyRefs && this.company.propertyRefs.length > 0;
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "setCurrentCardValues",
      "updateCard",
      "createCard",
      "updateProject",
      "setIsDeleteTempleProject",
      "setCurrentActionIdsAfterMoveOrAdd",
      "clearCurrentCard"
    ]),
    ...mapActions("dashboard/client", [
      "addCompanyContactRelationShip",
      "addPropertyCompanyRelationShip",
      "getContact",
      "setContact",
      "getCompany",
      "setCompany",
      "getProperty",
      "setProperty"
    ]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    changeValueNotes($event) {
      this.note = $event;
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },

    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    navigateToSelectCompany() {
      if (_.isEmpty(this.displayCard.contactId)) {
        this.selectCompanyMessage = "Please select contact first";
        return;
      }
      this.$refs.selectCompany.open();
    },

    navigateToSelectProperty() {
      this.$refs.selectProperty.open();
    },

    initCardDisplayValues() {
      if (!this.displayCard.id) this.$refs.selectContact.focusInput();
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card) || {};

        if (this.displayCard.dueDate) {
          this.dueDate = toDateCalendar(this.displayCard.dueDate);
        }
        if (this.displayCard.leadSource) {
          this.leadSource = this.displayCard.leadSource || "";
        }
        if (this.displayCard.description) {
          this.description = this.displayCard.description || "";
        }

        // TODO: get action ID for new Tenant
        this.actionId = this.actionId || this.actionByCode(110).id;
      }
    },

    initActionDisplayValues() {
      if (!_.isEmpty(this.action)) {
        this.actionTitle = this.action.title;
      }
    },

    async create() {
      this.$f7.preloader.show();
      if (!_.isEmpty(this.note)) {
        this.displayCard.commentAndNote = [
          {
            code: "note",
            title: "Contact Lead Note",
            htmlContent: this.note,
            createdAt: firebase.firestore.Timestamp.now(),
            createdBy: auth.currentUser.displayName || auth.currentUser.email
          }
        ];
      }

      let actionsHistory = [];
      actionsHistory.push({
        nextAction: "Job Created",
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: ""
      });
      this.displayCard.actionsHistory = actionsHistory;

      this.displayCard.status = "open";
      this.displayCard.actions = [this.actionId];

      this.displayCard.dueDate = toDateFirebase(this.dueDate);
      this.displayCard.contactedDate = toDateFirebase(this.dueDate);
      this.displayCard.leadSource = this.leadSource || "";
      this.displayCard.description = this.description || "";
      await this.createNotificationByType({
        data: {
          assignees: this.displayCard.assigneeIDs,
          project: {
            title: this.displayCard.title,
            id: this.displayCard.id,
            type: "project",
            entityName: COLLECTION_OPRATION_CARD
          }
        },
        type: "assign-user"
      });
      await this.updateProject({
        tenantId: this.user.tenantId,
        id: this.displayCard.id,
        project: this.displayCard
      });

      if (!this.contact.companyIdRefs?.includes(this.card.companyId)) {
        await this.addCompanyContactRelationShip({
          contactId: this.card.contactId,
          companyId: this.card.companyId
        });
      }

      // scroll to top
      this.setCurrentActionIdsAfterMoveOrAdd(this.displayCard.actions);
      this.setIsDeleteTempleProject(false);

      this.clearCurrentCard();
      this.clearActionData();
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    showPopup() {
      if (this.showProperty) {
        // validate all
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
      } else {
        // validate except Property
        this.v$.contactName.$touch();
        this.v$.companyName.$touch();
        this.v$.displayCard.title.$touch();
        if (
          this.v$.contactName.$invalid ||
          this.v$.companyName.$invalid ||
          this.v$.displayCard.title.$invalid
        ) {
          return;
        }
      }
      this.$refs.dueDatePopup.open();
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "move-to-contact-lead":
          this.currentResponse = response;
          this.resolve();
          break;

        case "save-and-close":
          this.$f7.preloader.show();
          this.save();
          this.selectPropertyMessage = "";
          this.selectCompanyMessage = "";
          this.$emit("doClosePopup");
          this.$f7.preloader.hide();
          break;
      }
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.selectPropertyMessage = "";
      this.selectCompanyMessage = "";
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    async onDeleteContact(event) {
      event.stopPropagation();

      await this.setContact({});
      await this.setCurrentCardValues({ contactId: "" });
      this.handleCompanySelected("");
      this.handlePropertySelected("");

      this.onDeleteProperty();
    },

    async onDeleteProperty(event) {
      event && event.stopPropagation();
      await this.setProperty({});
      await this.setCurrentCardValues({ propertyId: "" });
    },

    // Save info
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote) || [];
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Pre Qualification Leads Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.actions = [this.actionId];
      this.displayCard.dueDate = toDateFirebase(this.dueDate);
      this.displayCard.leadSource = this.leadSource || "";
      this.displayCard.description = this.description || "";

      await this.updateCard({
        id: this.card.id,
        doc: this.displayCard
      }).then(() => {
        this.displayCard = {};
        this.$f7.preloader.hide();
      });
    },

    setAssigneeIDs(IDs) {
      this.setCurrentCardValues({ assigneeIDs: IDs });
      if (!this.isNew) {
        this.$f7.preloader.show();
        this.updateCard({
          id: this.displayCard.id,
          doc: {
            assigneeIDs: IDs
          }
        }).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    uploadedAttachment(val) {
      if (this.isNew) {
        this.setCurrentCardValues({ attachmentFiles: val });
      } else {
        this.setAttachment("attachmentFiles", val);
      }
    },

    set(prop, val) {
      Vue.set(this.displayCard, prop, val);
    },

    changeProjectTitle() {
      let projectTitle = "";
      if (!_.isEmpty(this.property)) {
        projectTitle = `${this.property.propertyName
          } - ${this.getPropertyAddress(this.property)}`;
      }
      this.setCurrentCardValues({ title: projectTitle });
    },

    async handleContactSelected(id) {
      this.$f7.preloader.show();
      await this.getContact(id);
      await this.setCurrentCardValues({ contactId: id });
      // this.changeProjectTitle();
      this.$refs.selectCompany.autoFillCompany();
      this.newContact = false;
      this.$f7.preloader.hide();
    },

    async handleContactCreated(id) {
      this.$f7.preloader.show();
      await this.getContact(id);
      await this.setCurrentCardValues({ contactId: id });
      this.handleCompanySelected("");
      this.$refs.selectCompany.autoFillCompany();
      this.newContact = true;
      this.changeProjectTitle();
      this.$f7.preloader.hide();
    },

    async handleCompanySelected(id) {
      this.$f7.preloader.show();
      if (id) {
        await this.getCompany(id);
      } else {
        await this.setCompany({});
      }
      await this.setCurrentCardValues({ companyId: id });
      this.newCompany = false;
      this.$refs.selectProperty.autoFillProperty();

      if (!this.displayCard.contactId) {
        this.getContactByCompany();
      }

      if (this.newContact === true && id && this.card.contactId) {
        await this.addCompanyContactRelationShip({
          companyId: id,
          contactId: this.card.contactId
        });
      }

      this.$f7.preloader.hide();
    },

    async handleCompanyCreated(id) {
      this.$f7.preloader.show();

      await this.getCompany(id);
      await this.setCurrentCardValues({ companyId: id });
      this.handlePropertySelected("");
      this.newCompany = true;

      if (this.displayCard.contactId) {
        await this.addCompanyContactRelationShip({
          companyId: id,
          contactId: this.displayCard.contactId
        });
      }

      this.$f7.preloader.hide();
    },

    async handlePropertySelected(id) {
      this.$f7.preloader.show();
      if (id) {
        await this.getProperty(id);
      } else {
        await this.setProperty({});
      }
      await this.setCurrentCardValues({ propertyId: id });

      //check if first select property, handle reverse query logic
      if (!this.displayCard.companyId) {
        this.getCompanyAndContactByProperty();
      }
      this.changeProjectTitle();
      if (this.newCompany && id) {
        await this.addPropertyCompanyRelationShip({
          propertyId: id,
          companyId: this.card.companyId
        });
      }

      this.$f7.preloader.hide();
    },

    async handlePropertyCreated(id) {
      this.$f7.preloader.show();

      await this.getProperty(id);
      await this.setCurrentCardValues({ propertyId: id });

      // check if create property first, call addPropertyCompanyRelationShip when create project
      if (this.card.companyId) {
        await this.addPropertyCompanyRelationShip({
          propertyId: id,
          companyId: this.card.companyId
        });
      } else {
        this.newPropertyIds.push(id);
      }

      this.changeProjectTitle();
      this.$f7.preloader.hide();
    },

    clearActionData() {
      this.displayCard = {};
      this.dueDate = [];
      this.leadSource = "";
      this.description = "";
      this.selectPropertyMessage = "";
      this.selectCompanyMessage = "";
      this.note = "";
    },
    getPropertyAddress(property) {
      if (!_.isEmpty(property)) {
        const addresses = property.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },

    async getCompanyAndContactByProperty() {
      // check if has company then: get first company, catch: return
      if (this.property.companyIdRefs?.length) {
        const companyId = this.property.companyIdRefs[0];
        await this.getCompany(companyId);
        await this.setCurrentCardValues({ companyId: companyId });

        // if company has contact, get firt contact, else return
        if (this.company.contactIdRefs?.length) {
          const contactId = this.company.contactIdRefs[0];
          await this.getContact(contactId);
          await this.setCurrentCardValues({ contactId: contactId });
        }
      }
    },

    async getContactByCompany() {
      if (this.company.contactIdRefs?.length) {
        const contactId = this.company.contactIdRefs[0];

        await this.getContact(contactId);
        await this.setCurrentCardValues({ contactId });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-title {
  flex: 1;
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  line-height: 26px;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.add-new {
  margin-top: 5px;
  font-size: 10px;
  display: flex;
  flex-flow: row;
  gap: 5px;
  color: var(--f7-theme-color);
  cursor: pointer;
}

.icon {
  margin-top: 2px;
}
</style>
