/* eslint-disable no-unused-vars */
import _ from "lodash";

export default {
  contactInfo: state => state.contactInfo || {},

  contactInfoList: state => {
    const sortedList = _.cloneDeep(state.contactInfoList || []).sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
    return sortedList;
  }
};
