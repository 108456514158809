import _ from "lodash";

export default {
  assemblyTemplate: state => state.assemblyTemplate,
  assemblyTemplateList: state =>
    _.cloneDeep(state.assemblyTemplateList).sort((a, b) => {
      return a.createdAt < b.createdAt ? -1 : 0;
    }),
  searchValue: state => state.searchValue,
  assemblyTemplateByNumber: state => templateNumber => {
    return state.assemblyTemplateList.find(
      item => item.templateNumber === templateNumber
    );
  },

  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page
};
