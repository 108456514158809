<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("timeTracking.list.title") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-f7="plus" @click="openTimerPopup('myTimeLog')"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div>
      <f7-list class="no-margin">
        <f7-row class="align-items-center">
          <f7-col width="100" medium="50">
            <f7-list class="no-margin">
              <f7-list-input
                type="select"
                :value="timeTracking.month"
                @change="timeTracking.month = $event.target.value"
              >
                <option
                  v-for="item in months"
                  :key="item.value"
                  :value="item.value"
                  >{{ $t(`timeTracking.month.${item.display}`) }}</option
                >
              </f7-list-input>
            </f7-list>
          </f7-col>
          <f7-col width="100" medium="50">
            <f7-list>
              <f7-list-input
                type="select"
                :value="timeTracking.year"
                @change="timeTracking.year = $event.target.value"
              >
                <option v-for="item in selectYear" :key="item" :value="item">{{
                  item
                }}</option>
              </f7-list-input>
            </f7-list>
          </f7-col>
        </f7-row>
      </f7-list>

      <f7-list class="no-margin-top">
        <f7-list-item
          :title="$t('timeTracking.filter.status')"
          smart-select
          :smart-select-params="{ openIn: 'popover', routableModals: false }"
          ref="statusFilterSmartSelect"
        >
          <select multiple @change="changeStatusFilter">
            <option
              v-for="timeTracking in timeTrackingStatusList"
              :key="timeTracking.id"
              :value="timeTracking.value"
              :selected="
                statusFilter.includes(timeTracking.value) ? true : false
              "
              >{{ $t("timeTracking.status." + timeTracking.value) }}</option
            >
          </select>
        </f7-list-item>
        <f7-list-item
          :title="$t('timeTracking.filter.timeLogType')"
          smart-select
          :smart-select-params="{ openIn: 'popover', routableModals: false }"
          ref="timeLogTypeFilterSmartSelect"
        >
          <select multiple @change="changeTimeLogTypeFilter">
            <option
              v-for="item in timeLogTypeList"
              :key="item.id"
              :value="item.value"
              :selected="timeLogTypeFilter.includes(item.value) ? true : false"
              >{{ $t("timeTracking.timeLogType." + item.value) }}</option
            >
          </select>
        </f7-list-item>
        <f7-list-input
          :placeholder="$t('timeTracking.filter.project')"
          :clear-button="true"
          disable-button-text
          :value="projectFilter"
          @input="v => setProjectFilter(v.target.value)"
        ></f7-list-input>
      </f7-list>
      <f7-list v-if="listWeeks && listWeeks.length" class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in listWeeks"
            :key="item.title"
            :title="$t('timeTracking.list.weekOf') + item.startDay"
            :reload-detail="true"
            :link="
              `/my-time-log/detail?startDate=${item.startDay}&endDate=${item.endDay}`
            "
            swipeout
            :text-color="
              checkRoute(item.startDay, item.endDay) ? 'primary' : 'default'
            "
          >
            <f7-row slot="text">
              <f7-col
                ><div>
                  <div class="font-text">
                    {{ $t("timeTracking.list.start") }}:
                    {{ item.startDay }}
                  </div>
                </div>
                <div>
                  <div class="font-text number-of-line">
                    {{ $t("timeTracking.list.end") }}: {{ item.endDay }}
                  </div>
                </div>
              </f7-col>
            </f7-row>
            <f7-chip
              slot="after"
              :text="
                `${$t('timeTracking.totalHours')}: ${formatDuration(
                  item.totalHours
                )}`
              "
              color="success"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <f7-block v-else>No data</f7-block>
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "../../../lang/i18n";
import getWeeksFromMonth from "../week-calculator";
import { auth } from "../../../services/firebase.service";
import moment from "moment";
import Menu from "../../../components/menu/Menu.vue";
import { formatDuration } from "@/utility/datetime";
import { roundNumber } from "@/utility/number-tool";
import { getActiveLanguage } from "@/lang/translation";

export default {
  components: {
    Menu
  },
  data() {
    return {
      months: [
        {
          value: 1,
          display: "january"
        },
        {
          value: 2,
          display: "february"
        },
        {
          value: 3,
          display: "march"
        },
        {
          value: 4,
          display: "april"
        },
        {
          value: 5,
          display: "may"
        },
        {
          value: 6,
          display: "june"
        },
        {
          value: 7,
          display: "july"
        },
        {
          value: 8,
          display: "august"
        },
        {
          value: 9,
          display: "september"
        },
        {
          value: 10,
          display: "october"
        },
        {
          value: 11,
          display: "november"
        },
        {
          value: 12,
          display: "december"
        }
      ],
      year: [],
      timeTracking: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      locale: getActiveLanguage(),
      formatDuration
    };
  },

  methods: {
    ...mapActions({
      bindTimeTrackingList: "time-tracking/time-tracking/bindBys",
      getTimeTrackingDrafList:
        "time-tracking/time-tracking-draf/getTimeTrackingDrafList"
    }),
    ...mapMutations({
      openTimerPopup: "time-tracking/time-tracking/openTimerPopup",
      setStatusFilter: "time-tracking/time-tracking/setStatusFilter",
      setProjectFilter: "time-tracking/time-tracking/setProjectFilter",
      setTimeLogTypeFilter: "time-tracking/time-tracking/setTimeLogTypeFilter",
      setIsMyTimeLog: "time-tracking/time-tracking/setIsMyTimeLog"
    }),
    ...mapActions("time-tracking/app-constant", ["getConstantTypeList"]),
    ...mapActions("time-tracking/quickbooks", [
      "getClassQBList",
      "getServiceItemQBList"
    ]),
    ...mapActions("time-tracking/service-item-setting", [
      "getServiceItemSettingList"
    ]),

    checkRoute(start, end) {
      if (
        this.detailTimeTracking.startDay === start &&
        this.detailTimeTracking.endDay === end
      ) {
        return true;
      } else {
        return false;
      }
    },
    changeStatusFilter() {
      const value = this.$refs.statusFilterSmartSelect.f7SmartSelect.getValue();
      this.setStatusFilter(value);
    },
    changeTimeLogTypeFilter() {
      const value = this.$refs.timeLogTypeFilterSmartSelect.f7SmartSelect.getValue();
      this.setTimeLogTypeFilter(value);
    }
  },
  computed: {
    ...mapGetters({
      time_trackingList: "time-tracking/time-tracking/objectList",
      detailTimeTracking: "time-tracking/time-tracking/viewport",
      statusFilter: "time-tracking/time-tracking/statusFilter",
      timeLogTypeFilter: "time-tracking/time-tracking/timeLogTypeFilter",
      projectFilter: "time-tracking/time-tracking/projectFilter"
    }),

    ...mapGetters("time-tracking/app-constant", [
      "timeTrackingStatusList",
      "timeLogTypeList"
    ]),

    selectYear() {
      let yearCurrent = new Date().getFullYear();
      let yearGroup = [];
      [...new Array(10)].forEach((y, index) => {
        yearGroup.push(yearCurrent - index);
      });
      return yearGroup;
    },
    weeks() {
      return getWeeksFromMonth(
        this.timeTracking.month,
        this.timeTracking.year
      ).reverse();
    },
    listWeeks() {
      let result = this.weeks.map(x => {
        const startDay = moment(x[0]).format("MM-DD-YYYY");
        const recordsInWeek = this.time_trackingList.filter(r => {
          if (!r.date) return false;
          const d = moment(r.date.toDate());
          return (
            d.isBetween(x[0], x[x.length - 1], "days") ||
            d.isSame(x[0], "day") ||
            d.isSame(x[x.length - 1], "day")
          );
        });

        const totalHours = recordsInWeek
          .filter(r => r.status !== "tt-rejected")
          .map(
            r =>
              (r.approvedHour
                ? parseFloat(r.approvedHour)
                : r.override
                ? parseFloat(r.overrideHour)
                : r.loggedHour) +
              (r.hasLunchBreak ? r.lunchTimeInfo.hour || 0 : 0)
          )
          // .map(r => Math.round(r * 100) / 100)
          .map(r => roundNumber(r, 3))
          .reduce((a, b) => a + b, 0);

        return {
          startDay: startDay,
          endDay: moment(x[x.length - 1]).format("MM-DD-YYYY"),
          totalHours: totalHours,
          hasRecord: recordsInWeek.length > 0
        };
      });
      return result.filter(r => r.hasRecord);
    }
  },

  created() {
    let promises = [];
    this.$f7.preloader.show();
    promises.push(
      this.bindTimeTrackingList([
        {
          prop: "user_id",
          val: auth.currentUser.uid,
          op: "=="
        }
      ])
    );
    promises.push(
      this.getTimeTrackingDrafList([
        {
          prop: "user_id",
          val: auth.currentUser.uid,
          op: "=="
        }
      ])
    );

    promises.push(this.setStatusFilter([]));
    promises.push(this.setTimeLogTypeFilter([]));
    promises.push(this.setProjectFilter(""));
    promises.push(this.setIsMyTimeLog(true));
    promises.push(this.getConstantTypeList());
    promises.push(this.getClassQBList());
    promises.push(this.getServiceItemQBList());
    promises.push(this.getServiceItemSettingList());
    Promise.all(promises).then(() => this.$f7.preloader.hide());
  },

  mounted() {
    this.locale = i18n.locale;
    if (this.$f7route.query && this.$f7route.query.openTimer) {
      this.openTimerPopup();
    }
  }
};
</script>
<style scoped>
.chang-language {
  padding-top: 20px;
}
</style>
