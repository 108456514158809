<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Upload File Attachments</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div media-list class="page-content">
        <f7-list
          v-for="(file, index) in filesInput"
          :key="index"
          class="margin"
        >
          <f7-list-input
            type="textarea"
            :value="descriptions[index]"
            @input="updateDescription($event.target.value.trim(), index)"
            resizable
            placeholder="Description..."
            style="min-height: 120px; padding-top: 5px; white-space: pre-wrap;"
          >
            <span slot="label">{{ file.name }}</span>
            <f7-icon
              @click.native="removeAttachment(index)"
              class="icon-clear"
              style="cursor: pointer;"
              slot="content-end"
              color=""
              f7="xmark"
            ></f7-icon>
          </f7-list-input>
        </f7-list>
      </div>
    </div>
  </f7-popup>
</template>

<script>
export default {
  data: () => {
    return {
      popupOpened: false,
      descriptions: []
    };
  },
  props: {
    filesInput: { type: Array, default: () => [] }
  },

  methods: {
    /**
     * open
     */
    async open() {
      this.popupOpened = true;
    },

    handlePopupOpened() {
      for (let i = 0; i < this.filesInput.length; i++) {
        this.descriptions[i] = "";
      }
    },

    handlePopupClosed() {
      this.popupOpened = false;
      this.descriptions = [];
    },
    updateDescription(value, index) {
      this.descriptions[index] = value
        .replace(/\n/g, "<br>")
        .replace(/ /g, "&nbsp;");
    },
    removeAttachment(indexx) {
      this.$ri.dialog.openWarningDialog({
        title: "Remove Attachment",
        content: "Do you want to remove this attachment?",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            //remove item of filesInput
            this.filesInput.splice(indexx, 1);
            this.descriptions.splice(indexx, 1);
          }
        }
      });
    },
    onDone() {
      this.$emit("done", this.filesInput, this.descriptions);
      this.popupOpened = false;
    }
  }
};
</script>
<style scoped lang="scss">
.list ::v-deep ul::before,
.list ::v-deep ul::after {
  display: none;
}

.list ::v-deep ul {
  border-radius: 4px;
  border-left: solid 4px red;
}
.list ::v-deep .item-label {
  font-size: 14px;
  font-weight: bold;
  max-width: 95%;
}
.item-label ::v-deep .icon {
  right: -80%;
}
i.icon-clear {
  margin-right: 2%;
  position: absolute;
  // top: calc(100vh - 885px);
  top: calc(10px);
  right: 0%;
}
</style>
