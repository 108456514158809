<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDate('dueDate', $event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>

      <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy'
        }"
        label="Proposal Date"
        placeholder="Enter Proposal Date"
        readonly
        :value="proposalDate"
        @calendar:change="
          saveDate('proposalDate', $event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="rectangle_expand_vertical"></input-icon>
      </f7-list-input>
    </f7-list>
    <!-- Proposal Integration -->
    <proposal-input
      :value="displayCard"
      :proposalList="proposalList"
      :action="action"
      boardId="sales"
      @closePopup="closeActionPopup"
      @onUpdateProposal="handleUpdateProposal"
      @onCreateNewProposal="handleCreateNewProposal"
      @onDeleteProposal="handleDeleteProposal"
    ></proposal-input>

    <attachment-input
      :project-id="displayCard.id"
      title="Uploaded Proposals"
      attachment-type="own-proposal"
      add-button-title="Upload your own Proposal"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <!-- attachment -->
    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
      @save="save"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="changeValueNotes"
      :value="note"
      :is-save-button="true"
      @onSave="
        onSaveNote({
          displayCard,
          note,
          title: 'Proposal Note',
          callback: () => {
            note = '';
          }
        })
      "
    ></note-editor>

    <!-- Popup -->
    <date-popup
      ref="presentationDueDatePopup"
      title="Enter Presentation Due Date"
      label="Presentation Due Date"
      v-model="presentationDueDate"
      @done="resolveDueDate(presentationDueDate)"
    ></date-popup>

    <date-popup
      ref="estimateDueDatePopup"
      title="Enter Estimate Due Date"
      label="Estimate Due Date"
      v-model="estimateDueDate"
      @done="resolveDueDate(estimateDueDate)"
    ></date-popup>
  </div>
</template>

<script>
import ProposalInput from "@/components/inputs/ProposalInput.vue";
import PurchaseOrderInput from "../input/PurchaseOrderInput.vue";

import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import moment from "moment";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";
import { mapState, mapActions, mapGetters } from "vuex";

import _ from "lodash";
import methodsMixins from "../../mixin/methods";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  mixins: [methodsMixins],
  components: {
    UserInput,
    AttachmentInput,
    DatePopup,
    InputIcon,
    ProposalInput,
    PurchaseOrderInput,
    NoteEditor
  },
  data: () => {
    return {
      note: "",
      currentResponse: {},

      displayCard: {},
      dueDate: [],
      proposalDate: [],
      presentationDueDate: [],
      estimateDueDate: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"]),
    ...mapGetters("dashboard/proposal", ["proposalList"])
  },
  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),
    ...mapActions("dashboard/proposal", [
      "getProposalsByProjectId",
      "updateProposal",
      "deleteProposal"
    ]),
    changeValueNotes(value) {
      this.note = value;
    },
    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.proposalDate = toDateCalendar(this.displayCard.proposalDate);
        this.getProposalsByProjectId(this.card.id);
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "move-presentation":
          this.currentResponse = response;
          this.$refs.presentationDueDatePopup.open();
          break;

        case "redo-estimate":
          this.currentResponse = response;
          this.$refs.estimateDueDatePopup.open();
          break;
      }
    },

    resolveDueDate(dueDate) {
      this.dueDate = dueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Proposal Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      if (!_.isEmpty(this.proposalDate)) {
        this.displayCard.proposalDate = toDateFirebase(this.proposalDate);
      }
      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    },

    handleUpdateProposal(data) {
      this.$f7.preloader.show();
      this.updateProposal({
        id: data.id,
        doc: {
          ...data.doc
        }
      })
        .then(() => this.getProposalsByProjectId(this.card.id))
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleCreateNewProposal() {
      const actionId = this.action.id;
      const projectId = this.displayCard.id;
      const propertyId = this.displayCard.propertyId;
      const self = this;

      this.$emit("doClosePopup", () => {
        this.$f7router.navigate(
          {
            name: "new-proposal-swimlane-commercial",
            params: {
              boardType: self.$f7route.route.meta.boardType,
              businessCode: self.$f7route.route.meta.businessCode,
              boardId: "sales",
              actionId,
              projectId,
              propertyId: propertyId || "-"
            }
          },
          { reloadAll: true, pushState: true }
        );
      });
    },

    handleDeleteProposal(data) {
      this.$f7.preloader.show();
      this.deleteProposal(data.id)
        .then(() => this.getProposalsByProjectId(this.card.id))
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    saveDate(dateProp, $event) {
      this[dateProp] = $event;
      if (!_.isEmpty(this[dateProp])) {
        if (
          !moment(this[dateProp][0]).isSame(
            moment(toDateCalendar(this.displayCard[dateProp])[0]),
            "day"
          )
        ) {
          let temp = {};
          temp[dateProp] = toDateFirebase(this[dateProp]);
          this.updateCard({
            id: this.card.id,
            doc: { ...temp }
          });
        }
      }
    }
  }
};
</script>

<style></style>
