<template>
  <f7-popup :opened="popupOpened" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Customize</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="save()">Save</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-row no-gap>
        <f7-col :width="$device.desktop ? 40 : 100">
          <f7-block>
            <ol>
              <li>
                To show columns, please click the checkbox or the name column.
              </li>
              <li>
                To reorder, please move the mouse to the line and start the
                drag-and-drop process.
              </li>
            </ol>
          </f7-block>
        </f7-col>
        <f7-col :width="$device.desktop ? 60 : 100">
          <f7-block>
            <f7-card>
              <f7-card-header class="no-padding">
                <f7-list inset>
                  <f7-list-item
                    title="Display all column"
                    checkbox
                    :checked="sortedColumns.every(r => r.checked)"
                    @change="onSelectAll($event.target.checked)"
                  ></f7-list-item>
                </f7-list>
              </f7-card-header>
              <f7-card-content>
                <f7-list inset>
                  <div class="scroll-container">
                    <draggable
                      :list="sortedColumns"
                      :animation="200"
                      class="section-content-container"
                      ghost-class="ghost"
                      :delay="$device.desktop ? 0 : 100"
                      :delayOnTouchOnly="$device.desktop ? true : false"
                      :touchStartThreshold="20"
                      group="columns"
                      draggable=".column-item"
                      tag="ul"
                    >
                      <f7-list-item
                        v-for="(column, index) in sortedColumns"
                        :key="index"
                        :title="column.title"
                        class="column-item"
                        :text-color="column.checked ? 'primary' : 'default'"
                        checkbox
                        :checked="column.checked"
                        @change="onSelectColumn($event.target.checked, column)"
                      >
                        <f7-icon
                          slot="after"
                          f7="line_horizontal_3"
                          size="22"
                          color="--theme-color"
                        ></f7-icon>
                      </f7-list-item>
                    </draggable>
                  </div>
                </f7-list>
              </f7-card-content>
            </f7-card>
          </f7-block>
        </f7-col>
      </f7-row>
    </f7-page>
  </f7-popup>
</template>

<script>
import _ from "lodash";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    customTable: { type: Object, default: () => {} },
    defaultColumns: { type: Array, default: () => [] },
    tableType: String
  },
  components: {
    draggable
  },

  data: () => {
    return {
      popupOpened: false,
      sortedColumns: []
    };
  },

  computed: {
    ...mapGetters("setting/app/profile", { currentUser: "user" })
  },

  methods: {
    ...mapActions("common/custom-table", [
      "createCustomTable",
      "updateCustomTable"
    ]),

    async open() {
      if (this.customTable && !_.isEmpty(this.customTable.sortedColumns)) {
        this.sortedColumns = _.cloneDeep(this.customTable.sortedColumns);
      } else {
        this.sortedColumns = _.cloneDeep(this.defaultColumns);
      }
      this.popupOpened = true;
    },

    cancel() {
      this.popupOpened = false;
    },
    onSelectAll(checked) {
      this.sortedColumns = this.sortedColumns.map(r => ({
        ...r,
        checked
      }));
    },
    onSelectColumn(checked, column) {
      const index = this.sortedColumns.findIndex(r => r.value === column.value);
      this.sortedColumns[index].checked = checked;
    },
    async save() {
      if (this.sortedColumns.filter(r => r.checked).length === 0) {
        this.$ri.dialog.openErrorDialog({
          title: "Error",
          content: "Please select a column to customize",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
        return;
      }
      this.$f7.preloader.show();
      if (this.customTable && this.customTable.id) {
        await this.updateCustomTable({
          id: this.customTable.id,
          doc: { sortedColumns: this.sortedColumns }
        });
      } else {
        await this.createCustomTable({
          userId: this.currentUser.uid,
          tableType: this.tableType,
          sortedColumns: this.sortedColumns
        });
      }
      this.$f7.preloader.hide();
      this.cancel();
    }
  }
};
</script>
<style land="scss" scoped>
.column-item ::v-deep .item-title {
  font-weight: 600;
}
</style>
