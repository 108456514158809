<template>
  <div
    class="data-table data-table-init card table-fixed-container"
    :style="!$device.desktop ? '' : 'margin-bottom: 80px;'"
  >
    <div class="card-header">
      <!-- Default table header -->
      <div class="data-table-header" text-color="primary">
        <div class="data-table-title">
          {{
            selectedItemCount === 0
              ? "Payments from Quickbooks Online"
              : selectedMessage(selectedItemCount)
          }}
        </div>

        <div class="data-table-actions">
          <f7-button
            @click.native="importDataToRooferIntel"
            outline
            v-show="selectedItemCount > 0"
            >Import Payments To RooferIntel</f7-button
          >
        </div>
      </div>
    </div>

    <div class="card-content">
      <table>
        <thead>
          <tr>
            <th class="checkbox-cell" style="width: 50px">
              <f7-checkbox
                @change="checkBoxAllChange($event.target.checked)"
              ></f7-checkbox>
            </th>

            <!-- <th class="label-cell">
              <span class="table-head-label">QB Payment#</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Payment#"
                  @input="searchQbPaymentId = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th> -->
            <th class="label-cell">
              <span class="table-head-label">Invoice#</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Invoice#"
                  @input="searchInvoiceNumber = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="label-cell">
              <span class="table-head-label">Company Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Company Name"
                  @input="searchCompanyName = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="label-cell">
              <span class="table-head-label">Project/Job</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Project/Job"
                  @input="searchProject = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="label-cell">
              <span class="table-head-label">Property Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Property Name"
                  @input="searchProperty = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="label-cell">
              <span class="table-head-label">Payment Mode</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Payment Mode"
                  @input="searchPaymentMode = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>
            <th class="label-cell">Payment Date</th>
            <th class="label-cell">Payment Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="payment in qbPaymentFiltered"
            :key="payment.qbPaymentId"
            :class="
              `${
                payment.checked && !payment.indeterminate ? 'selected-cell' : ''
              }`
            "
          >
            <td class="checkbox-cell">
              <!-- indeterminate
                  disabled -->
              <f7-checkbox
                :disabled="payment.indeterminate"
                :indeterminate="payment.indeterminate"
                :checked="payment.checked"
                @change="
                  checkBoxChange($event.target.checked, payment.qbPaymentId)
                "
              ></f7-checkbox>
            </td>
            <!-- qb payment amount -->
            <td class="label-cell">
              {{ payment.invoiceNumber }}
            </td>
            <td class="label-cell">
              {{ payment.clientName }}
            </td>
            <td class="label-cell">
              {{ payment.jobName }}
            </td>
            <td class="label-cell">
              {{ payment.propertyName }}
            </td>
            <td class="label-cell">
              {{ payment.paymentMode || "" }}
            </td>
            <td class="label-cell">
              {{ payment.paymentDate || "" | MMDDYYYY }}
            </td>
            <td class="label-cell">
              {{ payment.amountReceived || "" | currencyUSD }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    qbPayments: { type: Array, default: () => [] }
  },

  data() {
    return {
      selectedItemCount: 0,
      searchQbPaymentId: "",
      searchPaymentMode: "",
      searchInvoiceNumber: "",
      searchCompanyName: "",
      searchProject: "",
      searchProperty: ""
    };
  },

  computed: {
    qbPaymentFiltered() {
      return this.qbPayments.filter(payment => {
        return (
          // Filter qb payment #
          // ((payment.qbPaymentId || "")
          //   .toLowerCase()
          //   .includes(this.searchQbPaymentId.toLowerCase()) ||
          //   this.searchQbPaymentId === "") &&
          // Filter payment mode
          ((payment.paymentMode || "")
            .toLowerCase()
            .includes(this.searchPaymentMode.toLowerCase()) ||
            this.searchPaymentMode === "") &&
          ((payment.invoiceNumber || "")
            .toLowerCase()
            .includes(this.searchInvoiceNumber.toLowerCase()) ||
            this.searchInvoiceNumber === "") &&
          // Filter companyName
          ((payment.clientName || "")
            .toLowerCase()
            .includes(this.searchCompanyName.toLowerCase()) ||
            this.searchCompanyName === "") &&
          // Filter Job
          ((payment.jobName || "")
            .toLowerCase()
            .includes(this.searchProject.toLowerCase()) ||
            this.searchProject === "") &&
          // Filter Property
          ((payment.propertyName || "")
            .toLowerCase()
            .includes(this.searchProperty.toLowerCase()) ||
            this.searchProperty === "")
        );
      });
    },

    selectedMessage() {
      return counter => {
        if (counter === 1) {
          return "1 item selected";
        } else {
          return `${counter} items selected`;
        }
      };
    }
  },

  methods: {
    checkBoxChange(checked, id) {
      let item = this.qbPaymentFiltered.find(item => item.qbPaymentId === id);
      Vue.set(item, "checked", checked);
      this.selectedItemCount = this.qbPaymentFiltered.filter(
        item => item.checked === true
      ).length;
    },

    checkBoxAllChange(checked) {
      this.qbPaymentFiltered.map(item => {
        if (!item.indeterminate) {
          Vue.set(item, "checked", checked);
        }
      });
      this.selectedItemCount = this.qbPaymentFiltered.filter(
        item => item.checked === true
      ).length;
    },

    importDataToRooferIntel() {
      let payments = this.qbPaymentFiltered.filter(
        item => item.checked === true && !item.indeterminate
      );
      this.$emit("doImport", payments);
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-cell {
  color: var(--f7-theme-color);
}
.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 232px);
  overflow: hidden;
  overflow-y: auto;
}
thead th {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
thead th::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
</style>
