function convertDate(unixTimestamp) {
  const milliseconds = unixTimestamp * 1000;
  const dateObject = new Date(milliseconds);
  return dateObject.toLocaleString();
}

export default {
  setting: state => state.setting,
  settingList: state => state.settingList,
  currentSubscription: state => {
    return state.setting.subscription || {};
  },
  paymentHistory: state => {
    return (state.paymentHistory || []).map(r => ({
      billId: r.id,
      total: r.amount / 100,
      date: convertDate(r.created),
      status: r.status
    }));
  },
  listFailInvoice: state => {
    return (state.listFailInvoice || []).map(r => ({
      id: r.id,
      invoiceNumber: r.number,
      amount: r.amount_due / 100,
      created: convertDate(r.created)
    }));
  },
  defaultPaymentMethod: state => state.defaultPaymentMethod,
  listPaymentMethod: state => state.listPaymentMethod,

  // isMasterDetailLayout: state => state.isMasterDetailLayout,
  // isShowingMasterPage: state => state.isShowingMasterPage,
  isShowMenuCover: state => state.isShowMenuCover
};
