import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import SpreadsheetWriter from "../../../utility/spreadsheet-writer";
import { currencyUSD } from "../../../utility/config";

export const getExcelWorkbook = ({ projectName, headers, values }) => {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Expenses For Project");
  var writer = new SpreadsheetWriter(worksheet);
  writer.bold();
  writer.textCenter();
  writer.write("REPORT: EXPENSES FOR PROJECT");
  writer.mergeNext(headers.length * 2 - 1);
  writer.enter();
  writer.enter();

  writer.enter();
  writer.write("Project Name");
  writer.mergeNext();
  writer.right();
  writer.right();

  writer.write(projectName);
  writer.mergeNext();
  writer.right(2);
  writer.enter();
  writer.enter();

  //headers
  headers.forEach(x => {
    writer.write(x["text"]);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.mergeNext();
    writer.right();
    writer.right();
  });
  headers.shift();
  values.forEach(row => {
    if (!row.footer) {
      writer.enter();
      writer.write(row.user && row.user.displayName);
      writer.border();
      writer.mergeNext();
      writer.right();
      writer.right();

      writer.write(row.expenseTypeName);
      writer.border();
      writer.mergeNext();
      writer.right();
      writer.right();

      writer.write(currencyUSD(row.amount));
      writer.border();
      writer.textRight();
      writer.mergeNext();
      writer.right();
      writer.right();
    } else {
      writer.enter();
      writer.write(row.title);
      writer.border();
      writer.bold();
      writer.mergeNext(headers.length * 2 - 1);
      writer.right(headers.length * 2);

      writer.write(currencyUSD(row.value));
      writer.border();
      writer.bold();
      writer.textRight();
      writer.mergeNext();
      writer.right();
      writer.right();
    }
  });
  return workbook;
};

function exportExcel({ projectName, headers, values }) {
  const workbook = getExcelWorkbook({
    projectName,
    headers,
    values
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "Expenses For Project.xlsx"
    );
  });
}

export default exportExcel;
