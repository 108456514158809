import axiosService from "./axios.service";

export function deprecatedMsg(oldInstanceMethod, newInstanceMethod) {
  let msg = `The ${oldInstanceMethod} Vue instance method is deprecated and will be removed in a future release.`;

  if (newInstanceMethod) {
    msg += ` Please use ${newInstanceMethod} instead.`;
  }

  return msg;
}

export function getObjectCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function thenArgsFromCallbacks(onResolve, onReject) {
  return [
    function onFulfilled(value) {
      if (typeof onResolve === "function") {
        onResolve(value);
      }

      return value;
    },
    function onRejected(error) {
      if (typeof onReject === "function") {
        onReject(error);
      }

      return Promise.reject(error);
    }
  ];
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const encodeUnicode = str => {
  return encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );
};

export const convertUrlToBase64 = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const dataURItoBlob = async url => {
  const dataURI = await convertUrlToBase64(url);
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const generateImage = async (
  htmlContent,
  { token, entity, entityId, pageWidth, pageHeight, isSign } = {}
) => {
  const width = pageWidth || 1020;
  const height = pageHeight || 1320;

  let response = {};
  let urlPath = `/dash-board/generate-image`;
  const config = {
    responseType: "arraybuffer",
    headers: { token }
  };

  if (token) {
    urlPath = `/${entity}/generate-image/${entityId}`;
  }
  try {
    response = await axiosService.post(
      urlPath,
      {
        pageWidth: width,
        pageHeight: height,
        isSign: isSign || false,
        content: htmlContent
      },
      config
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.message);
    return null;
  }

  return btoa(
    new Uint8Array(response.data || "").reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
};
