<template>
  <f7-list :inline-labels="$device.desktop">
    <f7-list-input v-for="(phone, index) in value" :key="index" type="text" :value="phone.value"
      @input="phone.value = $event.target.value.trim()" class="contact-phone-input" placeholder="Phone" clear-button
      v-mask="'(+1) ###-###-####'" error-message-force :error-message="phoneErrorMessage(index)"
      @blur="v$.value.$touch()" :ref="`phone-input-${phone.code}`">
      <div slot="label">
        <f7-link :popover-open="phoneTypeOptions.length ? `.popover-phone-type-${id}` : false
          " @click.native="openPhoneType(index)" @focus.native="onLinkFocus(phone.code)">{{ phone.title }}</f7-link>
        <f7-icon style="float: right; margin-top: 4px;" size="18" color="gray" f7="chevron_right"></f7-icon>
      </div>
      <f7-icon class="cursor-pointer" color="red" f7="minus_circle_fill" slot="media"
        @click.native="deletePhone(index)"></f7-icon>
    </f7-list-input>

    <f7-list-item v-if="phoneTypeOptions.length">
      <f7-link :popover-open="value.length && phoneTypeOptions.length > 1
        ? `.popover-phone-type-${id}`
        : false
        " class="cursor-pointer text-input" slot="title" @click.native="handleAdd">
        add phone<required-asterisk v-show="isRequired"></required-asterisk>
      </f7-link>
      <f7-icon class="add-button-icon" f7="plus_circle" slot="media"></f7-icon>
      <div slot="footer" class="theme-color">
        {{ contactPhoneNumberErrorMessage }}
      </div>
    </f7-list-item>

    <f7-popover ref="phoneTypePopover" @popover:closed="sheetClosed" :class="`popover-phone-type-${id}`">
      <f7-list>
        <f7-list-item v-for="(type, index) in phoneTypeOptions" :key="index" link :title="type.title"
          @click.native="selectPhoneType(type.code)"></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-list>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from "@vuelidate/validators";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    id: String,
    value: {
      type: Array,
      default: () => []
    },
    listData: {
      type: Array,
      default: () => []
    },
    isRequired: { type: Boolean, default: false },
    isCompared: { type: Boolean, default: false }
  },

  directives: { mask },

  data: () => {
    return {
      phoneTypes: [
        { code: "main", title: "Main" },
        { code: "home", title: "Home" },
        { code: "work", title: "Work" },
        { code: "mobile", title: "Mobile" }
      ],
      isNew: false,
      currentPhoneIndex: ""
    };
  },

  computed: {
    phoneTypeOptions() {
      const codes = (this.value || []).map(r => r.code) || [];
      return this.phoneTypes.filter(r => !codes.includes(r.code));
    },
    phoneErrorMessage() {
      return index => {
        if (this.v$.value.$each.$response.$errors[index].value.length === 0) return null;
        return this.v$.value.$each.$response.$errors[index].value[0].$message || ''
      };
    },
    contactPhoneNumberErrorMessage() {
      if (!this.v$.value.$error) return null;
      if (this.v$.value.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    resetData() {
      this.isNew = false;
      this.currentPhoneIndex = "";
      this.v$.$reset();
    },
    handleAdd() {
      if (!this.value.length) {
        this.addPhone("main");
      } else if (this.phoneTypeOptions.length > 1) {
        this.isNew = true;
      } else if (this.phoneTypeOptions.length === 1) {
        this.addPhone(this.phoneTypeOptions[0].code);
      }
    },

    deletePhone(index) {
      let phones = _.cloneDeep(this.value);
      phones.splice(index, 1);
      this.$emit("input", phones);
    },

    addPhone(value) {
      let phones = _.cloneDeep(this.value);
      const phoneType = this.phoneTypes.find(r => r.code === value) || {};
      phones.push({
        code: value,
        title: phoneType.title || "",
        value: ""
      });
      this.$emit("input", phones);
      this.$nextTick(() => {
        this.onLinkFocus(value);
      });
    },

    sheetClosed() {
      this.isNew = false;
      this.currentPhoneIndex = "";
    },

    openPhoneType(index) {
      this.currentPhoneIndex = index;
    },

    selectPhoneType(type) {
      const obj = this.phoneTypes.find(item => {
        return item.code === type;
      });
      if (this.isNew) {
        this.addPhone(type);
      } else {
        let phones = _.cloneDeep(this.value);
        phones[this.currentPhoneIndex].code = obj.code;
        phones[this.currentPhoneIndex].title = obj.title;
        this.$emit("input", phones);
        this.$nextTick(() => {
          this.onLinkFocus(type);
        });
      }
      this.$refs.phoneTypePopover.close();
    },
    comparePhoneNumber(value) {
      let phoneNumberExists = false;
      for (const item of this.listData) {
        if (item.phones && Array.isArray(item.phones)) {
          for (const phone of item.phones) {
            if (phone.value === value) {
              phoneNumberExists = true;
              break;
            }
          }
        }
        if (phoneNumberExists) {
          return false;
        }
      }
      return true;
    },
    onLinkFocus(code) {
      this.$refs[`phone-input-${code}`][0].$el
        .querySelector(".contact-phone-input input")
        .focus();
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    const func = value => {
      if (!this.isCompared) return true;
      return this.comparePhoneNumber(value);
    }

    return {
      value: {
        required(val) {
          if (!this.isRequired) return true;
          return (val || []).length;
        },
        $each: helpers.forEach({
          value: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            minLength: helpers.withMessage(VALIDATION_MESSAGE.PHONE_NUMBER_FORMAT, minLength(17)),
            compare: helpers.withMessage(VALIDATION_MESSAGE.PHONE_NUMBER_EXISTS, helpers.withAsync(func))
          }
        })
      }
    };
  }
};
</script>

<style scoped>
.text-input {
  color: var(--f7-color-text-neutral);
}
</style>
ư