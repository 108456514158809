import * as types from "./types";

import _ from "lodash";

export default {
  getMenus({ commit }, router) {
    // get all routes
    let parentRoutes = router.map(item => {
      return {
        displayRoles: item.displayRoles,
        name: item.name,
        title: item.title,
        routes: [],
        disableInGroup: !!item.disableInGroup,
        disableGrantMenu: !!item.disableGrantMenu
      };
    });

    // get display menu item
    router.forEach(menuItem => {
      (menuItem.routes || []).forEach(route => {
        if (!_.isEmpty(route.parent)) {
          const parent = parentRoutes.find(
            parent =>
              parent.name === route.parent &&
              route.disableInGroup != true &&
              route.disableGrantMenu != true
          );

          if (!_.isEmpty(parent)) {
            parent.routes.push(_.cloneDeep(route));
          }
        }
      });
    });
    // remove routes have empty name of children
    parentRoutes = parentRoutes.filter(
      route =>
        route.displayRoles &&
        route.disableInGroup != true &&
        route.disableGrantMenu != true
    );
    // compile menu item: Parent/Children_route
    const menuItemList = [];
    parentRoutes.forEach(parent => {
      parent.routes.forEach(child => {
        if (child.displayRoles) {
          menuItemList.push({
            displayName: `${parent.title}/${child.title}`,
            // value: `${parent.name}/${child.name}`,
            id: `${parent.name}/${child.name}`,
            displayRoles: child.displayRoles,
            path:
              parent.name === "hr-main" && child.name === "timer-main"
                ? "/my-time-log?openTimer=1"
                : child.path
          });
        }
      });
    });

    commit(types.SET_MENU_LIST, menuItemList);
  }
};
