<template>
  <div v-if="nullSafeContent">
    <f7-link :popover-open="`.${seedId}`" class="text-ellipsis">
      {{ nullSafeContent }}
    </f7-link>
    <f7-popover style="width: 300px" :class="seedId">
      <f7-block>
        <f7-input type="textarea" resizable readonly :value="nullSafeContent" />
      </f7-block>
    </f7-popover>
  </div>
</template>

<script>
export default {
  props: {
    content: { type: String, default: () => null },
    seedId: { type: String, default: () => null }
  },
  computed: {
    nullSafeContent() {
      return (this.content || "").trim();
    }
  }
};
</script>
<style lang="scss" scoped>
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 150px;
}
</style>
