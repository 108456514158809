<template>
  <div :style="`margin-top: ${status === 'draft' ? '-28px' : ''}`">
    <div
      :id="`scroll_id_${section.index}`"
      :ref="`scroll_id_${section.index}`"
      :class="
        `${actionButton} dislay-overlay display-flex justify-content-space-between`
      "
    >
      <div
        v-if="status === 'draft'"
        class="dislay-overlay display-flex justify-content-start"
      >
        <f7-button class="display-flex align-items-center" @click="addSection">
          <f7-icon color="primary" f7="plus_circle_fill"></f7-icon>Add Editor
          Section</f7-button
        >
        <f7-button
          class="display-flex align-items-center"
          @click="addPhotosSection"
        >
          <f7-icon color="primary" f7="plus_circle_fill"></f7-icon>Add Photo
          Section</f7-button
        >
        <f7-button
          class="display-flex align-items-center"
          @click="removeSection"
          :class="{
            'display-none':
              !isDefaultSection && section.isDeleted && isHiddenDelete
          }"
        >
          <f7-icon color="primary" f7="minus_circle_fill"></f7-icon>Remove This
          Section</f7-button
        >
        <!-- <f7-button @click="restoreSection" v-show="section.isDeleted">
          <f7-icon color="primary" f7="minus_circle_fill"></f7-icon>Restore This
          Section</f7-button
        > -->
      </div>

      <div
        v-if="status === 'draft'"
        class="dislay-overlay display-flex justify-content-end"
      >
        <div
          class="margin-left-half"
          v-for="(action, index) in section.actions"
          :key="index"
        >
          <f7-button
            style="box-shadow: 0px 0px 15px gray"
            fill
            @click="handleActionClick(section.index, action.actionCode)"
            >{{ action.actionName }}</f7-button
          >
        </div>
      </div>
    </div>
    <!-- <div v-show="this.section.isDeleted === undefined || this.section.isDeleted === false"> -->
    <f7-text-editor
      v-if="status === 'draft' && !isSigned"
      :id="`${section.sectionId}_${section.index}`"
      :ref="`${section.sectionId}_${section.index}`"
      :class="`text-editor-comp ${sectionEditor}`"
      style="
        --f7-text-editor-height: auto;
        width: 1020px;
        background: #fff;
        color: #000 !important;
      "
      :style="
        section.isDeleted ? 'background-color: var(--f7-color-gray);' : ''
      "
      :value="section.htmlValue"
      mode="popover"
      :custom-buttons="customButtons"
      :buttons="buttons"
      @click.native="handleClickAction"
      @texteditor:change="changeValue"
      @texteditor:blur="handleBlurAction"
      @texteditor:focus="$emit('editorFocus')"
    ></f7-text-editor>
    <!-- padding: 1px to fix old data -->
    <div
      v-else
      :id="`${section.sectionId}_${section.index}`"
      :ref="`${section.sectionId}_${section.index}`"
      style="
        width: 1020px;
        background-color: white;
        color: #000 !important;
        margin: 2px 0px;
        padding: 1px;
      "
      v-html="processedHtmlValue"
    ></div>
    <select-color-popup ref="selectColor" />
    <f7-popover
      ref="addLinkPopover"
      @popover:closed="sheetClosed"
      class="popover-add-link"
    >
      <div style="display: flex; align-items: center;">
        <f7-input
          ref="linkInput"
          placeholder="Insert Link"
          type="text"
          :value="insertLink"
          class="input-insert-link"
          @input="insertLink = $event.target.value.trim()"
          style="flex-grow: 1;"
        ></f7-input>
        <f7-button @click="saveLink">Save</f7-button>
      </div>
    </f7-popover>
  </div>

  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import SelectColorPopup from "../popup/SelectColorPopup.vue";
import {
  clearSelectionHightLight,
  customButtonForTextFormatBar
} from "@/utility/common";
import { buttonsForTextFormatBar } from "@/utility/const";

export default {
  components: { SelectColorPopup },

  props: {
    section: { type: Object, default: () => {} },
    status: { type: String, default: "" },
    isHiddenDelete: { type: Boolean, default: false },
    isSigned: { type: Boolean, default: false }
  },

  data() {
    // let app = this;
    return {
      isRestore: false,
      throttle: false,
      value: "",
      insertLink: "",
      linkRange: "",
      oldProposalId: "",
      actionButton: "action-button-hide",
      sectionEditor: "section-editor-unfocus",
      stylePopoverEditor: "",
      // BUILDING_DESCRIPTION_PAGE,
      currentDoc: null,
      customButtons: customButtonForTextFormatBar(this, true),
      buttons: buttonsForTextFormatBar
    };
  },

  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    }),
    marginForButton() {
      return this.section.actions && this.section.actions.length > 0
        ? "margin-top: -28px;"
        : "";
    },
    processedHtmlValue() {
      return this.section.htmlValue.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
    },
    hasActionButtons() {
      return this.section.actions && this.section.actions.length > 0;
    },

    isDefaultSection() {
      switch (this.section.sectionId) {
        case "cover-page":
        case "header-section":
        case "footer-section":
        case "restore-section":
          return true;

        default:
          return false;
      }
    }
  },
  methods: {
    isInViewport(parentTop) {
      let element = "";
      if (this.status === "draft") {
        element = this.$refs[`${this.section.sectionId}_${this.section.index}`]
          .$el;
      } else {
        element = this.$refs[`${this.section.sectionId}_${this.section.index}`];
      }

      if (element) {
        const rect = element.getBoundingClientRect();
        return (
          rect.top + rect.height / 2 - parentTop >= 0 &&
          rect.top - 99 + rect.height / 2 - parentTop <=
            (window.innerHeight || document.documentElement.clientHeight)
        );
      }

      return false;
    },
    sheetClosed() {
      this.insertLink = "";
    },
    saveLink() {
      const editor = this.$refs[
        `${this.section.sectionId}_${this.section.index}`
      ];
      if (this.linkRange) {
        editor.$el.f7TextEditor.setSelectionRange(this.linkRange);
        document.execCommand("createLink", true, this.insertLink);
      }
      this.linkRange = "";
      this.$refs.addLinkPopover.close();
    },
    addSection() {
      clearSelectionHightLight();
      this.$emit("addSection", this.section.index);
    },

    addPhotosSection() {
      clearSelectionHightLight();
      this.$emit("addPhotosSection", this.section.index);
    },

    removeSection() {
      clearSelectionHightLight();
      this.$emit("removeSection", this.section.index);
    },

    changeValue(value) {
      if (this.oldProposalId !== "" && this.proposal.id !== this.oldProposalId)
        return;
      const newVal = value.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );

      this.$emit("editorChange", {
        index: this.section.index,
        value: newVal
      });
    },

    moveSectionToView(section) {
      this.$refs[`scroll_id_${section.index}`].scrollIntoView();
    },

    handleClickAction() {
      this.actionButton = "action-button-show";
      this.sectionEditor = "section-editor-focus";
      this.oldProposalId = this.proposal.id;
      this.$refs.selectColor.closePopup();
    },

    handleBlurAction() {
      this.actionButton = "action-button-hide";
      this.sectionEditor = "section-editor-unfocus";
    },

    handleActionClick(index, actionCode) {
      clearSelectionHightLight();
      this.$emit("doAction", {
        index,
        actionCode,
        buildingId: this.section.buildingId,
        sectionId: this.section.sectionId
      });
    },

    restoreSection() {
      this.$emit("restoreSection", this.section.index);
    }
  },
  watch: {
    "proposal.id": {
      handler(val, old) {
        this.oldProposalId = old;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.text-editor-comp {
  margin: 0px;
}

.text-editor-comp ::v-deep .text-editor-content {
  padding: 0px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}

.text-editor-comp ::v-deep font > div {
  margin: -23px 0px;
}

.section-editor-unfocus {
  // box-shadow: 0 1px 2px var(--f7-theme-color);
  transition: box-shadow 0.2s ease-in-out;
}

.section-editor-focus {
  box-shadow: 0px 0px 15px gray;
  transition: box-shadow 0.2s ease-in-out;
  z-index: 999;
}

.action-button-show {
  top: -5px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.action-button-hide {
  top: -5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.dislay-overlay {
  position: relative;

  // top: -5px;
  // left: 20px;
  width: 100%;
  height: 100%;
}
.popover-add-link {
  width: 300px;
}
.input-insert-link {
  flex-grow: 1;
  padding: 3px 5px;
  border: 1px solid #b7b7b7;
  margin: 8px;
  border-radius: 3px;
}
.text-editor-comp ::v-deep .text-editor-content a {
  pointer-events: all;
  text-decoration: underline;
}
</style>
