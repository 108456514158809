import * as types from "./types";
import Vue from "vue";

export default {
  setPriceListItemList(state, payload) {
    Vue.set(state, "priceListItemList", payload);
  },
  setPriceListItemSubscribe(state, payload) {
    Vue.set(state, "priceListItemSubscribe", payload);
  },
  [types.SET_PRICE_LIST](state, data) {
    Vue.set(state, "priceList", data);
  }
};
