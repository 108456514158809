<template>
  <f7-page v-if="expense && !loading">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Employee Expenses Details</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="isShowEditPopup = true">
          Edit
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-list>
      <f7-list-item
        header="Expenses name"
        :title="expense.expensesName"
      ></f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-item
        header="Payee name"
        :title="(userById(expense.payeeId) || {}).displayName || ''"
      ></f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item
        header="Building/Section#"
        :title="expense.buildingOrSection"
      ></f7-list-item>
    </f7-list>
    <div v-if="expense.reportType === EXPENSE_TYPE_MILEAGE">
      <f7-list>
        <f7-list-item header="From" :title="expense.from"></f7-list-item>
        <f7-list-item header="To" :title="expense.to"></f7-list-item>
      </f7-list>
      <f7-list>
        <f7-list-item
          header="Mileage"
          :title="parseFloat(expense.mileage) | NUMBER"
        ></f7-list-item>
      </f7-list>
    </div>
    <div v-else>
      <f7-list>
        <f7-list-item
          header="Amount"
          :title="expense.amount | currencyUSD"
        ></f7-list-item>
      </f7-list>
      <f7-list media-list>
        <f7-list-item header="Attachments">
          <div v-for="(file, index) in expense.attachments" :key="index">
            <a
              style="color: #2196f3"
              class="external"
              :href="file.fileUrl"
              download="download"
              target="_blank"
              >{{ file.name }}</a
            >
          </div>
        </f7-list-item>
      </f7-list>
    </div>
    <f7-list>
      <f7-list-item
        header="Project Name"
        :title="projectDetail.title || ''"
      ></f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-input
        label="Notes"
        type="textarea"
        :value="expense.note"
        readonly
      ></f7-list-input>
    </f7-list>
    <f7-list media-list v-if="expense.status === STATUS_EXPENSE_REJECTED">
      <f7-list-input
        label="Rejected Notes"
        type="textarea"
        :value="rejectedNote.note"
        readonly
      ></f7-list-input>
      <f7-list-item>
        <div slot="text">
          <div>By: {{ rejectedNote.createdBy }}</div>
          <div>Created at: {{ rejectedNote.createdAt | DATETIME }}</div>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- action -->
    <f7-row
      v-if="expense.status === STATUS_EXPENSE_WFA"
      class="actions-container justify-content-flex-end"
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
    >
      <f7-button class="margin-right-half" @click="remove" outline color="red">
        Delete
      </f7-button>
      <f7-button
        class="margin-right-half"
        @click="rejectOrApprove(STATUS_EXPENSE_REJECTED)"
        fill
        color="red"
      >
        Reject
      </f7-button>
      <f7-button
        class="margin-right-half"
        @click="rejectOrApprove(STATUS_EXPENSE_APPROVED)"
        fill
        color="green"
      >
        Approve
      </f7-button>
    </f7-row>
    <add-new-employee-expense-popup
      :isShow="isShowEditPopup"
      :isEdit="true"
      @close="isShowEditPopup = false"
    ></add-new-employee-expense-popup>

    <add-note-popup
      :isShow="isShowAddNotePopup"
      @handleReject="handleReject"
      @close="isShowAddNotePopup = false"
    ></add-note-popup>
  </f7-page>

  <f7-page v-else-if="!expense && !loading">
    <f7-navbar backLink="Back">
      <f7-nav-title>Not found Expenses</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The expenses could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
  <f7-page v-else></f7-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddNewEmployeeExpensePopup from "@/plugins/expenses/components/popups/AddNewEmployeeExpensePopup.vue";
import AddNotePopup from "@/plugins/expenses/components/popups/AddNotePopup.vue";
import { firebase, auth } from "@/services/firebase.service";

import {
  financeConsolidateStatus,
  STATUS_EXPENSE_WFA,
  STATUS_EXPENSE_APPROVED,
  STATUS_EXPENSE_REJECTED,
  EXPENSE_TYPE_MILEAGE
} from "../../../utility/const";
export default {
  components: {
    AddNewEmployeeExpensePopup,
    AddNotePopup
  },

  data: () => {
    return {
      STATUS_EXPENSE_WFA,
      STATUS_EXPENSE_APPROVED,
      STATUS_EXPENSE_REJECTED,
      EXPENSE_TYPE_MILEAGE,
      isShowEditPopup: false,
      isShowAddNotePopup: false,
      projectDetail: {},
      loading: true
    };
  },

  methods: {
    ...mapActions("common/expense", [
      "bindExpense",
      "unbindExpense",
      "updateExpense",
      "deleteExpense"
      // "removeAttachment"
    ]),
    ...mapActions("common/project", ["getProjectById"]),
    handleReject(rejectedNote) {
      this.$f7.preloader.show();
      this.updateExpense({
        id: this.expense.id,
        doc: {
          status: STATUS_EXPENSE_REJECTED,
          rejectedNote
        }
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    rejectOrApprove(type) {
      const app = this;
      this.$ri.dialog.openInfoDialog({
        title: `${
          type === STATUS_EXPENSE_APPROVED ? "Approve" : "Reject"
        } Expense`,
        content: `Are you sure want to ${
          type === STATUS_EXPENSE_APPROVED ? "approve" : "reject"
        } this expense?`,
        textButton: `${
          type === STATUS_EXPENSE_APPROVED ? "Approve" : "Reject"
        }`,
        // hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            if (type === STATUS_EXPENSE_REJECTED) {
              app.isShowAddNotePopup = true;
              _sefl.app.dialog.close();
            } else {
              app.$f7.preloader.show();
              let doc = {
                status: STATUS_EXPENSE_APPROVED,
                approvedDate: firebase.firestore.Timestamp.now(),
                approvedBy:
                  auth.currentUser.displayName || auth.currentUser.email
              };
              if (app.expense.reportType === EXPENSE_TYPE_MILEAGE) {
                let updatedYear = app.expense.updatedAt
                  ? app.expense.updatedAt.toDate().getFullYear()
                  : "";
                let createdYear = app.expense.createdAt
                  ? app.expense.createdAt.toDate().getFullYear()
                  : "";
                let mileageRate = app.mileagePayRateByCurrentYear(
                  updatedYear || createdYear
                );
                if (mileageRate) {
                  doc.financeConsolidate = financeConsolidateStatus.CALCULATED;
                  doc.amount = parseFloat(
                    (app.expense.mileage * mileageRate.mileagePayRate).toFixed(
                      2
                    )
                  );
                } else {
                  doc.financeConsolidate =
                    financeConsolidateStatus.NOT_CALCULATED_YET;
                  doc.amount = 0;
                }
              }
              app
                .updateExpense({
                  id: app.expense.id,
                  doc
                })
                .then(() => {
                  _sefl.app.dialog.close();
                  app.$f7.preloader.hide();
                });
            }
          }
        }
      });
    },

    remove() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete Expense",
        content: "Are you sure you want to delete this epxense?",
        textButton: "Delete",
        // hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            // const expenseClone = _.cloneDeep(app.expense);
            // if (
            //   expenseClone.reportType !== EXPENSE_TYPE_MILEAGE &&
            //   expenseClone.attachments.length > 0
            // ) {
            //   expenseClone.attachments.forEach(attach => {
            //     app.removeAttachment(attach.fullPath);
            //   });
            // }
            app.$f7.preloader.show();
            app.deleteExpense(app.expense.id).then(() => {
              app.unbindExpense();
              _sefl.app.dialog.close();
              app.$f7.preloader.hide();
            });
          }
        }
      });
    },
    handleBack() {
      this.$f7router.back("/employee-expenses", { force: true });
    }
  },

  computed: {
    ...mapGetters("common/expense", ["expense"]),
    ...mapGetters("common/user", ["userById"]),
    ...mapGetters("common/mileage-pay-rate", ["mileagePayRateByCurrentYear"]),

    rejectedNote() {
      return this.expense && this.expense.rejectedNote
        ? {
            ...this.expense.rejectedNote,
            createdAt: this.expense.rejectedNote.createdAt.toDate()
          }
        : {};
    }
  },

  async created() {
    this.$f7.preloader.show();
    await this.bindExpense(this.$f7route.params.id);
    if (this.expense.projectId) {
      this.projectDetail = await this.getProjectById(this.expense.projectId);
    }
    this.loading = false;
    this.$f7.preloader.hide();
  }
};
</script>
