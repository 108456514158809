<template>
  <div>
    <f7-block-title v-show="$device.desktop"
      >Coping With Front Face Cleat</f7-block-title
    >
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->

      <f7-card-content>
        <div class="canvas-container">
          <!-- nail -->
          <img
            class="display-none"
            :id="`horizontal-nail-1_${assemblyId}`"
            :src="imgNail1"
            width="70"
            height="20"
          />
          <img
            class="display-none"
            :id="`horizontal-nail-2_${assemblyId}`"
            :src="imgNail2"
            width="100"
            height="27"
          />

          <!-- canvas -->
          <canvas
            v-if="$device.desktop"
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import _ from "lodash";
import paper from "paper";
import { evaluate } from "mathjs";
import canvasMixin from "../../utility/canvas-mixin";
import { mapGetters } from "vuex";
import imgNail1 from "@/assets/img/metal-diagrams/nail1.png";
import imgNail2 from "@/assets/img/metal-diagrams/nail2.png";
const inchToPx = 40;
const radiusL = 5;
const radiusS = 3;

export default {
  mixins: [canvasMixin],

  props: {
    data: { type: Object, default: () => {} },
    photoSelected: String,
    assemblyId: String,
    items: { type: Array, default: () => [] }
  },

  data() {
    return {
      imgNail1,
      imgNail2,
      scope: null,
      dataToPx: {},
      draws: {},
      space: 300,
      yBase: 100,
      xBase: 120,
      width: 1200,
      height: 1000,
      dark: localStorage.getItem("themeDark") === "true"
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate/sub-category", [
      "subCategoryById"
    ])
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.assemblyId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    getProductNameBySubCategoryName(name) {
      return (
        this.items.find(
          r => this.subCategoryById(r.subCategoryId).name === name
        ) || {}
      ).productItem;
    },

    handleDraw1() {
      const {
        a1,
        b1,
        c1,
        d1,
        e1,
        f1,
        angleMinus,
        anglePositive
      } = this.dataToPx;

      // path1
      let path1 = new paper.Path({
        strokeColor: this.dark ? "white" : "black",
        strokeWidth: 3
      });
      const pointA = new paper.Point(this.xBase, this.yBase);
      const pointH = new paper.Point(pointA.x, pointA.y + a1);

      // curve 1
      let pointG = new paper.Point(pointH.x, pointH.y - f1 + radiusL);
      let pointF = new paper.Point(pointG.x - radiusL * 2, pointG.y);
      let pointL = new paper.Point(pointF.x, pointF.y + f1 - radiusL);

      const centerGF = new paper.Point(pointG.x - radiusL, pointF.y);
      let throughGF = pointG.rotate(-90, centerGF);

      pointG = pointG.rotate(angleMinus, pointH);
      pointF = pointF.rotate(angleMinus, pointH);
      throughGF = throughGF.rotate(angleMinus, pointH);
      pointL = pointL.rotate(angleMinus, pointH);

      path1.add(pointA, pointH, pointG);
      path1.arcTo(throughGF, pointF);
      path1.add(pointL);

      const pointB = new paper.Point(pointA.x + b1, pointA.y + c1);
      const pointC = new paper.Point(pointB.x, pointB.y + d1);

      // path2
      let path2 = new paper.Path({
        strokeColor: this.dark ? "white" : "black",
        strokeWidth: 3
      });

      // curve 2
      let pointD = new paper.Point(pointC.x, pointC.y - e1 + radiusS);
      let pointE = new paper.Point(pointD.x + radiusS * 2, pointD.y);
      let pointM = new paper.Point(pointE.x, pointE.y + e1 - radiusS);

      const centerDE = new paper.Point(pointD.x + radiusS, pointE.y);
      let throughDE = pointD.rotate(90, centerDE);

      pointD = pointD.rotate(anglePositive, pointC);
      pointE = pointE.rotate(anglePositive, pointC);
      throughDE = throughDE.rotate(anglePositive, pointC);
      pointM = pointM.rotate(anglePositive, pointC);

      path2.add(pointA, pointB, pointC, pointD);
      path2.arcTo(throughDE, pointE);
      path2.add(pointM);

      this.handleDrawDescriptionTop(pointA, pointB, `b1: ${this.data.b1}`);
      this.handleDrawDescriptionLeft(pointA, pointH, `a1: ${this.data.a1}`);
      this.handleDrawDescriptionRight(pointA, pointB, `c1: ${this.data.c1}`);
      this.handleDrawDescriptionRight(pointB, pointC, `d1: ${this.data.d1}`);
      this.handleDrawDescriptionDiagonal(
        pointC,
        throughDE,
        anglePositive,
        radiusS,
        `e1: ${this.data.e1}`
      );
      this.handleDrawDescriptionDiagonal(
        pointH,
        throughGF,
        angleMinus,
        radiusL,
        `f1: ${this.data.f1}`
      );

      this.drawArc(
        new paper.Point(pointH.x, pointH.y - f1 - radiusL),
        pointH,
        pointG,
        angleMinus
      );

      // draw info product
      const startPoint = new paper.Point(pointA.x, pointA.y + a1 / 2);
      const betweenPoint = new paper.Point(startPoint.x + b1 / 3, startPoint.y);
      const endPoint = new paper.Point(
        betweenPoint.x,
        betweenPoint.y + a1 / 2 + 70
      );

      if (this.getProductNameBySubCategoryName("Assembly Metal")) {
        this.drawInfoProduct(
          [startPoint, betweenPoint, endPoint],
          this.getProductNameBySubCategoryName("Assembly Metal"),
          "left",
          "bottom"
        );
      }

      path1.join(path2);

      this.setDraws("draw1Paths", [path1]);
    },

    handleDraw2() {
      const { b1, c1, a2, b2, c2, angleMinus } = this.dataToPx;
      const x = this.xBase + b1 + this.space;
      const y = this.yBase;

      const pointA = new paper.Point(x, y);
      const pointB = new paper.Point(pointA.x, pointA.y + a2);
      let pointC = new paper.Point(pointB.x, pointB.y - b2);
      pointC = pointC.rotate(angleMinus, pointB);

      const h = Math.sqrt(b1 * b1 + c1 * c1);
      const xD = (b1 * c2) / h;
      const yD = (c1 * c2) / h;
      const pointD = new paper.Point(pointA.x + xD, pointA.y + yD);

      let path1 = new paper.Path({
        strokeColor: this.dark ? "white" : "black",
        strokeWidth: 3
      });

      this.handleDrawDescriptionTop(pointA, pointD, `c2: ${this.data.c2}`);
      this.handleDrawDescriptionLeft(pointA, pointB, `a2: ${this.data.a2}`);
      this.handleDrawDescriptionDiagonal(
        pointB,
        pointC,
        angleMinus,
        0,
        `b2: ${this.data.b2}`
      );

      path1.add(pointD, pointA, pointB, pointC);

      // draw info product
      const startPoint = new paper.Point(pointA.x, pointA.y + a2 / 2);
      const betweenPoint = new paper.Point(startPoint.x - 30, startPoint.y);
      const endPoint = new paper.Point(
        betweenPoint.x,
        betweenPoint.y - a2 / 2 - 70
      );

      if (this.getProductNameBySubCategoryName("Assembly Metal Cleat")) {
        this.drawInfoProduct(
          [startPoint, betweenPoint, endPoint],
          this.getProductNameBySubCategoryName("Assembly Metal Cleat"),
          "right",
          "left"
        );
      }

      this.setDraws("draw2Paths", [path1]);
    },

    handleDraw3() {
      const x = this.xBase;
      const y = this.yBase + 100;

      const { draw1Paths, draw2Paths } = this.draws;
      const { a1, b1, c1, a2, a3, b3 } = this.dataToPx;
      let path1 = draw1Paths[0].clone();
      let path2 = draw2Paths[0].clone();

      path1.position.y += a1 + y;
      path2.position.x -= this.space + b1 - 7;
      path2.position.y += a1 + y + a1 - a2;

      const pointA = new paper.Point(x + 15, y + 10);
      const pointH = new paper.Point(pointA.x, pointA.y + a1 + 50);
      const pointB = new paper.Point(pointA.x + b1 - 25, pointA.y + c1);
      const pointC = new paper.Point(pointB.x, pointH.y);

      const path3 = new paper.Path({
        segments: [pointH, pointA, pointB, pointC],
        strokeColor: this.dark ? "white" : "black"
      });
      path3.position.y += a1 + 105;
      this.setDraws("draw3Paths", [path1, path2]);

      // nail
      const firstPointInPath2 = path2.segments[1].point;
      const secondPointInPath2 = path2.segments[2].point;
      const pointBInPath1 = path1.segments[5].point; // point B
      const pointAInPath1 = path1.segments[6].point; // point A
      const pointFInPath1 = path1.segments[10].point; // point F

      let nail1 = new paper.Raster(`horizontal-nail-1_${this.assemblyId}`);
      nail1.size = new paper.Size(70, 20);
      nail1.position = new paper.Point(
        secondPointInPath2.x + 30,
        secondPointInPath2.y - a3
      );

      this.handleDrawDescriptionLeft(
        new paper.Point(secondPointInPath2.x - 10, secondPointInPath2.y - a3),
        new paper.Point(secondPointInPath2.x - 10, nail1.position.y + a3),
        `a3: ${this.data.a3}`
      );

      let nail2 = new paper.Raster(`horizontal-nail-2_${this.assemblyId}`);
      nail2.size = new paper.Size(100, 27);
      nail2.position = new paper.Point(
        pointBInPath1.x - 33,
        pointBInPath1.y + b3
      );

      this.handleDrawDescriptionRight(
        pointBInPath1,
        new paper.Point(pointBInPath1.x, pointBInPath1.y + b3),
        `b3: ${this.data.b3}`
      );

      // info product
      const centerPointAandBInPath1 = this.pointOnPathByStart(
        pointAInPath1,
        pointBInPath1,
        2
      );
      const end1 = new paper.Point(
        centerPointAandBInPath1.x,
        centerPointAandBInPath1.y - 50
      );
      this.drawInfoProduct(
        [centerPointAandBInPath1, end1],
        "Coping",
        "bottom",
        "top"
      );

      // const end2 = new paper.Point(nail1.position.x, nail1.position.y + 60);
      // this.drawInfoProduct(
      //   [nail1.position, end2],
      //   this.data.nail1,
      //   "top",
      //   "bottom"
      // );

      // const startNail2 = new paper.Point(
      //   nail2.position.x + 45,
      //   nail2.position.y + 10
      // );
      // const end3 = new paper.Point(
      //   nail2.position.x + 50,
      //   nail2.position.y + 60
      // );
      // this.drawInfoProduct(
      //   [startNail2, end3],
      //   this.data.nail2,
      //   "top",
      //   "bottom"
      // );

      const end4 = new paper.Point(pointFInPath1.x, pointFInPath1.y + 70);
      this.drawInfoProduct(
        [pointFInPath1, end4],
        "Continuously hand crimp hem over cleat",
        "top",
        "bottom"
      );

      const betweenPointAandBInPath2 = new paper.Point(
        firstPointInPath2.x,
        firstPointInPath2.y + a2 / 3
      );
      const end5 = new paper.Point(
        betweenPointAandBInPath2.x + b1 / 3,
        betweenPointAandBInPath2.y
      );
      this.drawInfoProduct(
        [betweenPointAandBInPath2, end5],
        "Continuous cleat",
        "left",
        "right"
      );
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.handleDraw3();
      this.selectedDraw(this.photoSelected);
      this.resizableLayer();
    },

    selectedDraw(name) {
      if (name) {
        const lastChar = name.charAt(name.length - 1);
        (this.draws[`draw${lastChar}Paths`] || []).forEach(
          path => (path.selected = true)
        );
      } else {
        if (this.scope) {
          this.scope.project.activeLayer.selected = false;
        }
      }
    },

    handleClick(event) {
      this.scope.project.activeLayer.selected = false;
      this.$emit("setPhotoSelected", "");

      const strokeBounds = Object.values(this.draws).map(
        r => r[0].strokeBounds
      );

      for (let i = 0; i < strokeBounds.length; i += 1) {
        if (
          event.offsetX > strokeBounds[i].topLeft.x &&
          event.offsetY > strokeBounds[i].topLeft.y &&
          event.offsetX < strokeBounds[i].bottomRight.x &&
          event.offsetY < strokeBounds[i].bottomRight.y
        ) {
          this.$emit("setPhotoSelected", `photo${i + 1}`);
          this.selectedDraw(`photo${i + 1}`);
        }
      }
    }
  },

  mounted() {
    this.scope = new paper.PaperScope();
    this.scope.setup(this.assemblyId);
    this.drawAll();
  },

  watch: {
    data: {
      handler(val) {
        if (_.isEmpty(val)) {
          return;
        }
        const keys = [
          "a1",
          "b1",
          "c1",
          "d1",
          "e1",
          "f1",
          "a2",
          "b2",
          "c2",
          "a3",
          "b3"
        ];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
        this.dataToPx.angleMinus = evaluate(_.cloneDeep(val.angle)) * -1;
        this.dataToPx.anglePositive = evaluate(_.cloneDeep(val.angle));
      },
      deep: true,
      immediate: true
    },
    photoSelected: {
      handler(val) {
        this.selectedDraw(val);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
