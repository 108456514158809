<template>
    <div>
        <f7-list :inset="$device.desktop">
            <!-- company info -->
            <div class="row">
                <div class="col-100 medium-50">
                    <f7-list :inline-labels="$device.desktop" no-hairlines-md>
                        <f7-list-input label="Project/Job Name" :value="newRecurringInvoice.invoice.jobName || 'Select a Project/Job'
                            " type="text" readonly @click.native="selectProject"
                            :disabled="invoiceGroupIdProp || projectIdProp ? true : false" error-message-force
                            :error-message="jobNameErrorMessage()">
                            <required-asterisk slot="label" />
                        </f7-list-input>

                        <f7-list-input label="Property Name" placeholder="Type or select a Project/Job first"
                            :value="newRecurringInvoice.invoice.propertyName" type="text" readonly :disabled="true">
                        </f7-list-input>
                        <f7-list-input label="Property Address" placeholder="Type or select a Project/Job first"
                            :value="newRecurringInvoice.invoice.propertyAddress" type="text" readonly :disabled="true">
                        </f7-list-input>

                        <f7-list-input label="Status" placeholder="Select Status" :value="newRecurringInvoice.status"
                            type="select" @change="
                                onChangeRecurringInvoiceProp('status', $event.target.value)
                                " error-message-force :error-message="requireErrorMessage('status')">
                            <required-asterisk slot="label" />
                            <option v-for="item in recurringStatusComputed" :key="item.id" :value="item.value">
                                {{ item.displayName }}
                            </option>
                        </f7-list-input>
                        <!-- payment terms -->
                        <f7-list-input label="Payment Terms" placeholder="Select Payment Terms"
                            :value="checkValueSelectPaymentTerm" type="select" @change="
                                onChangeRecurringInvoiceProp('paymentTerm', $event.target.value)
                                " error-message-force :error-message="requireErrorMessage('paymentTerm')">
                            <required-asterisk slot="label" />
                            <option v-for="item in PAYMENT_TERMS_OPTIONS" :key="item.id" :value="item.value">
                                {{ item.displayName }}
                            </option>
                        </f7-list-input>
                        <f7-list-input :disabled="checkValueSelectPaymentTerm !== 'custom'" label="Number of Days"
                            placeholder="Enter Number of Days" :value="newRecurringInvoice.paymentTerm !== 'custom'
                                ? newRecurringInvoice.paymentTerm
                                : numberOfDays
                                " @change="handleChangeNumberOfDays($event.target.value)" type="number" :min="0"
                            error-message-force :error-message="numberOfDaysErrorMessage">
                            <required-asterisk slot="label" />
                        </f7-list-input>
                    </f7-list>

                    <f7-list :inline-labels="$device.desktop" no-hairlines-md>
                        <f7-list-input label="Repeat Every" placeholder="Select Repeat Every"
                            :value="checkValueSelectRepeatEvery" @change="
                                onChangeRecurringInvoiceProp('repeatEvery', $event.target.value)
                                " type="select" error-message-force
                            :error-message="requireErrorMessage('repeatEvery')">
                            <required-asterisk slot="label" />
                            <option v-for="item in REPEAT_EVERY_OPTIONS" :key="item.id" :value="item.value">
                                {{ item.displayName }}
                            </option>
                        </f7-list-input>

                        <f7-list-input v-show="checkValueSelectRepeatEvery == 'custom'" label="Repeat Value"
                            placeholder="Enter Repeat Value" :value="repeatValue"
                            @change="repeatValue = Math.abs($event.target.value)" type="number" :min="1"
                            error-message-force :error-message="repeatValueErrorMessage">
                            <required-asterisk slot="label" />
                        </f7-list-input>
                        <f7-list-input v-show="checkValueSelectRepeatEvery == 'custom'" label="Repeat Unit"
                            placeholder="Select Repeat Unit" :value="repeatUnit" type="select"
                            @change="repeatUnit = $event.target.value" error-message-force
                            :error-message="repeatUnitErrorMessage">
                            <required-asterisk slot="label" />
                            <option v-for="item in REPEAT_UNIT_OPTIONS" :key="item.id" :value="item.value">
                                {{ item.displayName }}
                            </option>
                        </f7-list-input>
                        <f7-list-input label="Start on" type="datepicker" placeholder="MM/DD/YYYY" :calendar-params="{
                            backdrop: true,
                            openIn: 'customModal',
                            header: true,
                            footer: false,
                            dateFormat: 'mm/dd/yyyy',
                            disabled: date =>
                                newRecurringInvoice.startOn &&
                                date < new Date().setHours(0, 0, 0, 0)
                        }" :value="newRecurringInvoice.startOn || []" @calendar:change="
                            onChangeRecurringInvoiceProp('startOn', $event);
                        $f7.calendar.close();
                        " error-message-force :error-message="requireErrorMessage('startOn')">
                            <required-asterisk slot="label" />
                        </f7-list-input>
                        <f7-list-input label="End on" type="datepicker" placeholder="MM/DD/YYYY" :calendar-params="{
                            backdrop: true,
                            openIn: 'customModal',
                            header: true,
                            footer: false,
                            dateFormat: 'mm/dd/yyyy',
                            disabled: date =>
                                newRecurringInvoice.startOn &&
                                date <
                                new Date(newRecurringInvoice.startOn).setHours(0, 0, 0, 0),
                        }" :value="newRecurringInvoice.endOn || []" error-message-force validate validate-on-blur
                            :error-message="endOnErrorMessage" @calendar:change="
                                onChangeRecurringInvoiceProp('endOn', $event);
                            newRecurringInvoice.endOn.length > 0 &&
                                onChangeRecurringInvoiceProp('neverExpires', false);
                            $f7.calendar.close();
                            "></f7-list-input>
                        <f7-list-item checkbox title="Never Expires" :checked="newRecurringInvoice.neverExpires"
                            @change="
                                onChangeRecurringInvoiceProp(
                                    'neverExpires',
                                    $event.target.checked
                                );
                            onChangeRecurringInvoiceProp('endOn', null);
                            "></f7-list-item>
                    </f7-list>
                </div>

                <div class="col-100 medium-50">
                    <f7-list :inline-labels="$device.desktop" no-hairlines-md>
                        <f7-list-input label="Roofing Company Name" :value="invoiceGroup.roofingCompanyName" type="text"
                            readonly :disabled="true">
                            <required-asterisk slot="label" />
                        </f7-list-input>
                        <f7-list-input label="Address" :value="invoiceGroup.roofingCompanyAddress" type="text" readonly
                            :disabled="true">
                        </f7-list-input>
                        <f7-list-input label="Phone" :value="invoiceGroup.roofingCompanyPhone" type="text" readonly
                            :disabled="true">
                        </f7-list-input>
                        <f7-list-input label="Email" :value="invoiceGroup.roofingCompanyEmail" type="text" readonly
                            :disabled="true">
                        </f7-list-input>
                        <f7-list-input label="Tax Code" :value="invoiceGroup.roofingCompanyTaxCode" type="text" readonly
                            :disabled="true">
                        </f7-list-input>
                    </f7-list>

                    <!-- Client Info -->
                    <f7-list :inline-labels="$device.desktop" no-hairlines-md>
                        <f7-list-input label="Client Name" placeholder="Enter Client Name"
                            :value="newRecurringInvoice.invoice.clientName" type="text"
                            :disabled="isDisableField(newRecurringInvoice.invoice.clientName)" error-message-force
                            :error-message="clientNameErrorMessage()" @change="
                                onChangeInvoiceProp('clientName', $event.target.value.trim())
                                ">
                            <required-asterisk slot="label" />
                        </f7-list-input>
                        <f7-list-input label="Address" placeholder="Enter Address"
                            :value="newRecurringInvoice.invoice.clientAddress" type="text" @change="
                                onChangeInvoiceProp('clientAddress', $event.target.value)
                                " :disabled="isDisableField(newRecurringInvoice.invoice.clientAddress)
                                    ">
                        </f7-list-input>
                        <f7-list-input label="Phone" placeholder="Enter Phone" v-mask="'(+1) ###-###-####'"
                            :value="newRecurringInvoice.invoice.clientPhoneNumber" error-message-force @change="
                                onChangeInvoiceProp('clientPhoneNumber', $event.target.value)
                                " :disabled="isDisableField(newRecurringInvoice.invoice.clientPhoneNumber)
                                    " :error-message="clientPhoneNumberErrorMessage()"
                            @blur="v$.newRecurringInvoice.invoice.clientPhoneNumber.$touch()">
                        </f7-list-input>
                        <f7-list-input label="Email" placeholder="Enter Email"
                            :value="newRecurringInvoice.invoice.clientEmail" @change="
                                onChangeInvoiceProp('clientEmail', $event.target.value.trim())
                            isEditedEmail = true
                                " type="text" error-message-force :error-message="clientEmailErrorMessage()"
                            :disabled="(newRecurringInvoice.invoice.clientEmail !== '' && !isEditedEmail)"
                            @blur="v$.newRecurringInvoice.invoice.clientEmail.$touch()">
                            <required-asterisk slot="label" />
                        </f7-list-input>
                        <f7-list-input label="Tax code" placeholder="Enter Tax Code"
                            :value="newRecurringInvoice.invoice.clientCompanyTaxCode" @change="
                                onChangeInvoiceProp(
                                    'clientCompanyTaxCode',
                                    $event.target.value.trim()
                                )
                                " v-mask="'##-#######'" error-message-force
                            :error-message="clientCompanyTaxCodeErrorMessage()" @blur="
                                v$.newRecurringInvoice.invoice.clientCompanyTaxCode.$touch()
                                ">
                        </f7-list-input>
                    </f7-list>
                </div>
            </div>
            <!-- company info -->
            <div class="row">
                <div class="col-100 medium-50"></div>
            </div>
        </f7-list>
        <!-- item detail -->
        <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
            <simple-summary-table :items="newRecurringInvoice.invoice.itemDetails"
                @onChangeItems="onChangeItemDetails($event)" ref="productItemTable"
                :invoiceDetail="newRecurringInvoice.invoice" @onChangeDiscountType="onChangeDiscountType($event)">
                <FooterTable slot="summary" :invoice="newRecurringInvoice.invoice" ref="summaryFooter"
                    @onChangeSummaryFooter="onChangeSummaryFooter">
                </FooterTable>
            </simple-summary-table>
        </f7-block>
        <!-- Note -->
        <f7-block-title>Notes</f7-block-title>
        <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
            <text-editor class="no-margin-horizontal" :data="newRecurringInvoice.invoice.notes"
                @change="onChangeInvoiceProp('notes', $event)" :isResizable="true"
                placeholder="Enter Notes"></text-editor>
        </f7-block>
        <!-- Term and Condition -->
        <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
            <text-editor :data="newRecurringInvoice.invoice.termsAndConditions"
                @change="onChangeInvoiceProp('termsAndConditions', $event)"></text-editor>
        </f7-block>
        <!-- Photo sections -->
        <f7-block-title>Before</f7-block-title>
        <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
            <div class="row flex-start">
                <image-item v-for="item in invoice.beforeImages" :key="item.id" :photo="item"
                    :projectId="invoice.projectId || ''" :selectedPhotos="invoice.beforeImages"
                    @onDelete="photo => onDeletePhoto(photo, 'beforeImages')" @onChangePhotoProp="(prop, value) =>
                        onChangePhotoProp(prop, value, item.id, 'beforeImages')
                        "></image-item>
                <image-add-item :projectId="invoice.projectId || ''" :selectedPhotos="invoice.beforeImages"
                    @onSelectPhotos="photos => onSelectPhotos(photos, 'beforeImages')"></image-add-item>
            </div>
        </f7-block>
        <f7-block-title>After</f7-block-title>
        <f7-block :class="{ 'no-padding-horizontal': !$device.desktop }">
            <div class="row flex-start">
                <image-item v-for="item in invoice.afterImages" :key="item.id" :selectedPhotos="invoice.afterImages"
                    :photo="item" :projectId="invoice.projectId || ''"
                    @onDelete="photo => onDeletePhoto(photo, 'afterImages')" @onChangePhotoProp="(prop, value) =>
                        onChangePhotoProp(prop, value, item.id, 'afterImages')
                        "></image-item>
                <image-add-item :projectId="invoice.projectId || ''" :selectedPhotos="invoice.afterImages"
                    @onSelectPhotos="photos => onSelectPhotos(photos, 'afterImages')"></image-add-item>
            </div>
        </f7-block>
        <project-list-popup ref="selectProject" @onSelected="onSelectedProject($event)"></project-list-popup>
    </div>
</template>

<script>
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';
import {
    DEFAULT_STATUS_INVOICE,
    SENDER_CONFIG_EMAIL,
    TABLE_CONTENT_TYPE_ITEMIZE,
    VALIDATION_MESSAGE
} from '@/utility/const';
import { toDateFirebase } from "@/utility/datetime";
import {
    email,
    helpers,
    minLength,
    minValue,
    required,
} from '@vuelidate/validators';
import _ from 'lodash';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import commonMixins from '../../mixins/common-mixin';
import recurringInvoiceMixins from '../../mixins/recurring-invoice-mixin';
import ImageAddItem from '../inputs/ImageAddItem.vue';
import ImageItem from '../inputs/ImageItem.vue';
import TextEditor from '../inputs/TextEditor.vue';

import axiosService from '@/services/axios.service';
import { auth, firebase } from '@/services/firebase.service';
import { getFullAddress } from '@/utility/address';
import { currencyUSD, MMDDYYYY } from '@/utility/config';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import summaryFooterMixins from '../../mixins/summary-footer-mixin';
import {
    DEFAULT_RECURRING_INVOICE_STATUS,
    PAYMENT_TERMS_OPTIONS,
    REPEAT_EVERY_OPTIONS,
    REPEAT_UNIT_OPTIONS,
} from '../../utility/const';
import FooterTable from '../tables/FooterTable.vue';
import SimpleSummaryTable from '../tables/SimpleSummaryTable.vue';
import {
    BUSINESS_CODE_COMMERCIAL,
    BUSINESS_CODE_RESIDENTIAL,
    BUSINESS_CODE_SERVICE,
} from "../../../../utility/const";
const initFieldNewProductItem = Object.freeze({
    quantity: 1,
    discount: false,
    tax: false,
    isAutoAdded: true,
    crossProfitMarginRatio: 0,
    miscPercent: 0,
    discountAmount: 0,
    discountValue: 0,
    taxPercent: 0,
    taxAmount: 0,
    netSales: 0,
    intoMoney: 0,
    amount: 0,
});

export default {
    props: {
        recurringInvoiceId: { type: String, default: '' },
        projectIdProp: { type: String, default: '' },
        invoiceGroupIdProp: { type: String, default: '' },
        isNewRecurring: { type: Boolean, default: true },
        isPopup: { type: Boolean, default: false },
    },

    components: {
        TextEditor,
        ImageItem,
        ImageAddItem,
        SimpleSummaryTable,
        FooterTable,
        ProjectListPopup,
    },

    directives: { mask },

    setup: () => ({ v$: useVuelidate({ $scope: false }) }),

    mixins: [commonMixins, recurringInvoiceMixins, summaryFooterMixins],

    async created() {
        const promises = []
        if (!this.recurringInvoiceStatusList.length) {
            promises.push(this.getConstantTypeList())
        }
        if (!this.invoiceTemplate) {
            await this.getTemplateList();
        };

        if (_.isEmpty(this.setting)) {
            promises.push(this.bindSetting(this.tenantId))
        }
        await Promise.all(promises)


    },

    watch: {
        invoiceGroupIdProp: {
            handler(newVal) {
                if (newVal) {
                    this.initDataRecurringInvoice(newVal);
                }
            },
            deep: true,
            immediate: true
        },
        projectIdProp: {
            handler(newVal) {
                if (newVal && !this.invoiceGroupIdProp) {
                    this.initDataRecurringInvoice("", newVal);
                }
            },
            deep: true,
            immediate: true
        },
    },

    data: () => {
        return {
            REPEAT_EVERY_OPTIONS,
            REPEAT_UNIT_OPTIONS,
            PAYMENT_TERMS_OPTIONS,
            sortedSections: [],
            originalInvoice: {},
            keyValueDict: {
                "Invoice Number": "&nbsp;",
                "Invoice Date": "&nbsp;",
                "Due Date": "&nbsp;",

                "Client Name": "",
                "Client Phone": "",
                "Client Address": "",
                "Client Email": "",
                "Client Company Tax Code": "&nbsp;",
                "Total Cost": "&nbsp;",
                "Payment Status": "&nbsp;",

                "Project Name": "&nbsp;",
                "Property Address": "&nbsp;",
                "Property Name": "&nbsp;",

                "Items Detail Table": "&nbsp;",

                Notes: "&nbsp;",
                "Terms And Conditions": "&nbsp;",
                "Project Photos": "&nbsp;",
                "Roofing Company Tax Code": "&nbsp;"
            },
            newRecurringInvoice: {
                invoice: {
                    discount: {
                        type: 'percent',
                        value: 0,
                    },
                    tax: {
                        type: 'percent',
                        value: 0,
                    },
                    shippingCharge: {
                        type: 'cash',
                        value: 0,
                    },
                    itemDetails: [
                        {
                            productName: 'Service',
                            adjustedItemAmount: 0,
                            priceWithProfitAndMisc: 0,
                            netSales: 0,
                            discountType: 'percent',
                            ...initFieldNewProductItem,
                        },
                    ],
                    beforeImages: [],
                    afterImages: [],
                    discountType: 'percent',
                    invoiceType: 'recurring',
                },
                status: DEFAULT_RECURRING_INVOICE_STATUS,
                paymentTerm: '15',
                repeatEvery: '1_week',
                startOn: [new Date()],
            },
            repeatValue: 1,
            repeatUnit: 'week',
            numberOfDays: 0,
            redundantPhotos: [],
            invoiceGroup: {},

            project: {},
            newInvoiceNumber: "",
            isHaveDataClient: false,
            isEditedEmail: false
        };
    },

    computed: {
        ...mapGetters('invoices/project', ['projectList']),

        ...mapGetters('invoices/recurring-invoices', ['recurringInvoice']),

        ...mapGetters('invoices/invoice-template', ['invoiceDefaultTemplate']),

        ...mapGetters({
            setting: 'setting/app/system/setting',
        }),

        ...mapGetters('invoices/app-constant', ['recurringInvoiceStatusList']),

        ...mapGetters('setting/app/profile', ['user']),

        ...mapGetters("common/template", ["invoiceTemplate"]),

        tenantId() {
            let tenantLocal =
                localStorage.getItem('login_tenant') !== 'null'
                    ? localStorage.getItem('login_tenant')
                    : '';
            return tenantLocal;
        },

        checkValueSelectPaymentTerm() {
            const foundOption = PAYMENT_TERMS_OPTIONS.find(
                option => option.value === this.newRecurringInvoice.paymentTerm
            );
            return foundOption ? foundOption.value : 'custom';
        },

        checkValueSelectRepeatEvery() {
            const foundOption = REPEAT_EVERY_OPTIONS.find(
                option => option.value === this.newRecurringInvoice.repeatEvery
            );
            const value = foundOption ? foundOption.value : 'custom';
            if (value !== 'custom') {
                const splitValue = this.newRecurringInvoice.repeatEvery.split('_');
                this.repeatValue = splitValue[0] || 1;
                this.repeatUnit = splitValue[1] || 'week';
            }

            return value;
        },

        recurringStatusComputed() {
            return (
                this.recurringInvoiceStatusList.filter(item => this.isNewRecurring ? item.value !== "ri-expired"
                    && item.value !== "in-sent" : item.value !== "in-sent") || {}
            );
        },

        invoice() {
            return this.newRecurringInvoice.invoice || {};
        },

        repeatUnitErrorMessage() {
            if (!this.v$.repeatUnit.$error) return null;
            if (this.v$.repeatUnit.required.$invalid)
                return VALIDATION_MESSAGE.REQUIRED_FIELD;
            return null;
        },

        repeatValueErrorMessage() {
            if (!this.v$.repeatValue.$error) return null;
            if (this.v$.repeatValue.required.$invalid)
                return VALIDATION_MESSAGE.REQUIRED_FIELD;
            if (this.v$.repeatValue.minValue.$invalid)
                return VALIDATION_MESSAGE.GREATER_THAN_ONE;
            return null;
        },

        numberOfDaysErrorMessage() {
            if (!this.v$.numberOfDays.$error) return null;
            if (this.v$.numberOfDays.required.$invalid)
                return VALIDATION_MESSAGE.REQUIRED_FIELD;
            if (this.v$.numberOfDays.minValue.$invalid)
                return VALIDATION_MESSAGE.VALIDATION_MESSAGE.GREATER_THAN_ZERO;
            return null;
        },
        endOnErrorMessage() {
            if (!this.v$.newRecurringInvoice.endOn.timeRules)
                return END_DATE_GREATER_THAN_START_DATE;
            return null;
        },
    },

    methods: {
        ...mapActions('invoices/app-constant', ['getConstantTypeList']),
        ...mapActions('invoices/project', ['getProjectById', 'unbindProject']),
        ...mapActions('invoices/property', ['getPropertyById']),
        ...mapActions({
            bindSetting: 'setting/app/system/bindSetting',
            getContactById: 'invoices/contact/getContactById',
            getCompanyById: 'invoices/company/getCompanyById',
        }),
        ...mapActions('invoices/recurring-invoices', [
            'createRecurringInvoice',
            'updateRecurringInvoice',
            'bindRecurringInvoice',
        ]),
        ...mapActions({
            bindInvoiceTemplateList:
                'invoices/invoice-template/bindInvoiceTemplateList',
            copyPhoto: 'invoices/photo/copyPhoto',
            removePhoto: 'invoices/photo/removePhoto',
        }),
        ...mapActions('invoices/invoices', ['updateInvoice', 'createNewInvoice']),

        ...mapActions("common/template", ["getTemplateList"]),
        ...mapActions('invoices/invoice-group', [
            'getInvoiceGroupById',
            'getInvoiceGroupByProjectId',
            'createNewInvoiceGroup',
            'saveInvoiceGroup',
        ]),
        async getClientName() {
            if (this.invoiceGroup.clientName) {
                return this.invoiceGroup.clientName;
            }

            if (this.project?.businessCode === BUSINESS_CODE_COMMERCIAL && this.project?.companyId) {
                const company = await this.getCompanyById(this.project.companyId);
                return company.companyName;
            }

            if (
                this.project?.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                (this.project?.businessCode === BUSINESS_CODE_SERVICE && this.project?.customerType === BUSINESS_CODE_RESIDENTIAL)
            ) {
                return this.project.contactName;
            }

            return "";
        },
        async initDataRecurringInvoice(invoiceGroupId, projectId = '') {
            if (!invoiceGroupId && !projectId) return

            this.$f7.preloader.show();
            if (invoiceGroupId) {
                this.invoiceGroup = await this.getInvoiceGroupById(invoiceGroupId);
            }

            // get project info
            this.project = await this.getProjectById(
                projectId && !invoiceGroupId ? projectId : this.invoiceGroup.projectId
            );

            const company = this.project.companyId
                ? await this.getCompanyById(this.project.companyId)
                : {};

            const contact = this.project.contactId
                ? await this.getContactById(this.project.contactId)
                : {};

            const property = this.project.propertyId
                ? await this.getPropertyById(this.project.propertyId)
                : {};

            if (!_.isEmpty(company) || !_.isEmpty(contact)) {
                this.isHaveDataClient = true;
            }
            const clientName = await this.getClientName();
            // update project info to recurring invoice
            const newInvoice = {
                ...this.newRecurringInvoice.invoice,
                projectId: this.project.id || '',
                jobName: this.project.title || '',
                propertyName:
                    property.propertyName ||
                    getFullAddress(this.project.projectAddress) ||
                    '' ||
                    '',
                propertyAddress:
                    getFullAddress((property?.addresses || [])[0]) ||
                    getFullAddress(this.project.projectAddress) ||
                    '',
                clientCompanyId: this.project.companyId || '',
                clientContactId: this.project.contactId || '',
                clientName: clientName || '',
                clientAddress: getFullAddress((contact?.addresses || [])[0]),
                clientPhoneNumber: company.phones
                    ? company.phones[0]?.value
                    : contact.phones
                        ? contact.phones[0]?.value
                        : '',
                clientEmail: contact.emails
                    ? contact.emails[0]?.value
                    : company.email
                        ? company.emails[0].value
                        : '',
                clientCompanyTaxCode: company?.taxCode || '',
                invoiceGroupId: this.invoiceGroup.id || '',
            };
            this.newRecurringInvoice.invoice = newInvoice;
            this.$f7.preloader.hide();

            if (this.isNewRecurring) {
                this.open();
            } else {
                this.open(this.recurringInvoiceId ? this.recurringInvoiceId : this.$f7route.params.recurringInvoiceNumber, true);
            }
        },

        async onSelectedProject(projectId) {
            if (!projectId) return;
            let invoiceGroup = await this.getInvoiceGroupByProjectId(projectId);
            this.initDataRecurringInvoice(
                invoiceGroup ? invoiceGroup.id : '',
                projectId
            );
            this.isEditedEmail = false;
        },

        createInvoiceGroup() {
            const invoiceGroup = {
                projectId: this.project.id,
                projectNumber: this.project.cardNumber,
                propertyAddress: this.newRecurringInvoice.invoice.propertyAddress,
                propertyName: this.newRecurringInvoice.invoice.propertyName,
                jobName: this.newRecurringInvoice.invoice.jobName,
                clientName: this.newRecurringInvoice.invoice.clientName,
                roofingCompanyName: this.setting ? this.setting.companyName : "",
                roofingCompanyAddress: this.setting ? this.setting.address : "",
                roofingCompanyPhone: this.setting ? this.setting.phoneNumber : "",
                roofingCompanyEmail: this.setting ? this.setting.companyEmail : "",
                roofingCompanyTaxCode: this.setting ? this.setting.companyTaxCode : "",
            };

            const createdInvoiceGroup = this.createNewInvoiceGroup({
                invoiceGroup: invoiceGroup,
            });

            return createdInvoiceGroup;
        },

        isDisableField(value) {
            return value && this.isHaveDataClient ? true : false;
        },

        handleChangeNumberOfDays(value) {
            this.numberOfDays = Math.abs(value)
            this.newRecurringInvoice.paymentTerm = 'custom';
        },

        onSelectPhotos(photos, propName) {
            this.$f7.preloader.show();
            let items = _.cloneDeep(this.invoice[propName]) || [];
            const beforeIds = items.map(r => r.id);
            const afterIds = photos.map(r => r.id);

            const idsRemove = _.differenceWith(beforeIds, afterIds, _.isEqual);

            const idsAdd = _.differenceWith(afterIds, beforeIds, _.isEqual);

            let promises = [];

            // delete photo in storage
            for (const id of idsRemove) {
                const photo = items.find(r => r.id === id);
                this.redundantPhotos.push(photo);
                items = _.remove(items, item => item.id != id);
            }

            // add photo
            for (const id of idsAdd) {
                const photo = photos.find(r => r.id === id);
                const date = new Date(
                    photo.createdAt.seconds * 1000 + photo.createdAt.nanoseconds / 1000000
                );
                promises.push(
                    this.copyPhoto(photo).then(({ fullPath, url }) => {
                        items.push({
                            id: photo.id,
                            photoUrl: url,
                            fullPath: fullPath,
                            notes: photo.notes,
                            createdAt: firebase.firestore.Timestamp.fromDate(new Date(date)),
                            createdBy: photo.createdBy,
                        });
                    })
                );
            }

            Promise.all(promises).then(() => {
                this.onChangeInvoiceProp(propName, items);
                this.$f7.preloader.hide();
            });
        },
        onDeletePhoto(photo, propName) {
            //remote photo from storage
            this.redundantPhotos.push(photo);
            let items = this.invoice[propName] || [];
            items = _.remove(items, item => item.id != photo.id);
            this.onChangeInvoiceProp(propName, items);
        },
        onChangePhotoProp(prop, value, id, invoiceProp) {
            let items = this.invoice[invoiceProp] || [];
            let index = items.findIndex(r => r.id == id);
            let photo = { ...items[index] };
            if (!photo) return;
            photo[prop] = value;
            Vue.set(items, index, photo);
            this.onChangeInvoiceProp(invoiceProp, items);
        },
        showToastMessage(message) {
            this.$f7.toast
                .create({
                    text: message,
                    closeOnClick: true,
                    closeButton: true,
                    closeTimeout: 5000,
                })
                .open();
        },
        getStatus(status) {
            return (
                this.recurringInvoiceStatusList.find(x => x.value === status) || {}
            );
        },

        async open(id, isNeedBindDetail = false) {
            this.$f7.preloader.show();

            if (id) {
                if (isNeedBindDetail) {
                    await this.bindRecurringInvoice(id);
                }
                this.newRecurringInvoice = {
                    ...this.recurringInvoice,
                    startOn: this.recurringInvoice.startOn
                        ? [new Date(this.recurringInvoice.startOn.toDate())]
                        : [],
                    endOn: this.recurringInvoice.endOn
                        ? [new Date(this.recurringInvoice.endOn.toDate())]
                        : [],
                    invoice: {
                        ...this.recurringInvoice.invoice,
                    },
                };
                this.originalInvoice = _.cloneDeep(this.newRecurringInvoice.invoice);
            } else {
                const refs = [];
                if (_.isEmpty(this.setting)) {
                    refs.push(this.bindSetting(this.tenantId));
                }
                if (_.isEmpty(this.invoiceDefaultTemplate)) {
                    refs.push(this.bindInvoiceTemplateList());
                }
                await Promise.all(refs);
                //populate data

                //from templte
                this.onChangeInvoiceProp('logo', this.invoiceDefaultTemplate.logo);
                let userEmail = auth.currentUser.email || 'example@email.com';
                let userName = auth.currentUser.displayName || '{ User Name }';
                let termsAndConditions = this.invoiceDefaultTemplate.termsAndConditions.replace(
                    '{user email}',
                    userEmail
                );
                termsAndConditions = termsAndConditions.replace(
                    '{user name}',
                    userName
                );
                this.onChangeInvoiceProp('termsAndConditions', termsAndConditions);
            }
            if (_.isEmpty(this.projectList)) {
                await this.unbindProject();
            }
            this.$f7.preloader.hide();
        },

        cancel() {
            this.originalInvoice = {}
            this.sortedSections = [];
            this.newRecurringInvoice = {
                invoice: {
                    discount: {
                        type: 'percent',
                        value: 0,
                    },
                    tax: {
                        type: 'percent',
                        value: 0,
                    },
                    shippingCharge: {
                        type: 'cash',
                        value: 0,
                    },
                    itemDetails: [
                        {
                            productName: 'Service',
                            adjustedItemAmount: 0,
                            priceWithProfitAndMisc: 0,
                            netSales: 0,
                            discountType: 'percent',
                            ...initFieldNewProductItem,
                        },
                    ],
                    beforeImages: [],
                    afterImages: [],
                    discountType: 'percent',
                    invoiceType: 'recurring',
                },
                status: DEFAULT_RECURRING_INVOICE_STATUS,
                paymentTerm: '15',
                repeatEvery: '1_week',
                startOn: [new Date()]
            },
                this.repeatValue = 1
            this.repeatUnit = 'week'
            this.numberOfDays = 0
            this.redundantPhotos = []
            this.invoiceGroup = {}
            this.isEditedEmail = false;

            this.project = {}
            this.isHaveDataClient = false,
                this.v$.$reset();
        },

        selectProject() {
            this.$refs.selectProject.open();
        },
        toDateFirebase(val) {
            return toDateFirebase(val);
        },
        onChangeInvoiceProp(prop, value) {
            let invoice = _.cloneDeep(this.newRecurringInvoice.invoice) || {};
            Vue.set(invoice, prop, value);
            Vue.set(this.newRecurringInvoice, 'invoice', invoice);
        },
        async initKeyData() {
            if (!_.isEmpty(this.newInvoice)) {
                if (this.newInvoice.invoiceNumber) {
                    this.keyValueDict["Invoice Number"] = this.newInvoice.invoiceNumber;
                }
                if (this.newInvoice.invoiceDate) {
                    this.keyValueDict["Invoice Date"] = toDateFirebase(
                        this.newInvoice.invoiceDate
                    );
                }
                if (this.newInvoice.dueDate) {
                    this.keyValueDict["Due Date"] = toDateFirebase(
                        this.newInvoice.dueDate
                    );
                }
                if (this.newInvoice.clientName) {
                    this.keyValueDict["Client Name"] = this.newInvoice.clientName;
                }
                if (this.newInvoice.clientAddress) {
                    this.keyValueDict["Client Address"] = this.newInvoice.clientAddress;
                }
                if (this.newInvoice.clientPhoneNumber) {
                    this.keyValueDict["Client Phone"] = this.newInvoice.clientPhoneNumber;
                }
                if (this.newInvoice.clientEmail) {
                    this.keyValueDict["Client Email"] = this.newInvoice.clientEmail;
                }
                if (this.newInvoice.clientCompanyTaxCode) {
                    this.keyValueDict[
                        "Client Company Tax Code"
                    ] = this.newInvoice.clientCompanyTaxCode;
                }
                if (
                    this.$f7route.params.invoiceGroupId &&
                    this.invoiceGroup.roofingCompanyTaxCode
                ) {
                    this.keyValueDict[
                        "Roofing Company Tax Code"
                    ] = this.invoiceGroup.roofingCompanyTaxCode;
                }
                if (this.newInvoice) {
                    const totalCost = this.totalInvoice(this.newInvoice);
                    const formattedTotalCost = Vue.filter("currencyUSD")(totalCost);
                    this.keyValueDict["Total Cost"] = formattedTotalCost;
                }
                if (this.paymentStatus) {
                    this.keyValueDict[
                        "Payment Status"
                    ] = `<span style="border: 1px solid;border-radius: 9999px;padding: 3px 8px 3px 8px;font-weight: 700;border-color: ${this.paymentStatus.color}; background-color: ${this.paymentStatus.color}">${this.paymentStatus.text}</span>`;
                }
                if (this.invoiceGroup && this.invoiceGroup.jobName) {
                    this.keyValueDict["Project Name"] =
                        this.invoiceGroup && this.invoiceGroup.jobName;
                }
                if (this.invoiceGroup && this.invoiceGroup.propertyAddress) {
                    this.keyValueDict["Property Address"] =
                        this.invoiceGroup && this.invoiceGroup.propertyAddress;
                }
                if (this.invoiceGroup && this.invoiceGroup.propertyName) {
                    this.keyValueDict["Property Name"] =
                        this.invoiceGroup && this.invoiceGroup.propertyName;
                }
                if (this.newInvoice.termsAndConditions) {
                    this.keyValueDict[
                        "Terms And Conditions"
                    ] = this.newInvoice.termsAndConditions;
                }
                if (this.newInvoice.notes) {
                    this.keyValueDict["Notes"] = this.newInvoice.notes;
                }
            }
        },
        async compileSectionData() {
            await this.initKeyData();

            if (!this.invoiceTemplate) return [];

            const templateSections = _.cloneDeep(this.invoiceTemplate.sections).sort(
                (a, b) => a.index - b.index
            );

            const desSections = [];

            for (const orgSection of templateSections) {
                desSections.push(this.compileSection(orgSection));
            }

            return desSections;
        },

        compileSection(orgSection) {
            let htmlValue = this.parseHtmlMetaContent(
                _.cloneDeep(orgSection.htmlValue)
            );

            return {
                htmlValue,
                sectionId: orgSection.sectionId,
                sectionName: orgSection.sectionName,
                index: orgSection.index
            };
        },

        async handleSectionPhoto() {
            const sectionPhoto = [];

            await Promise.all([
                this.processBeforeImages(sectionPhoto),
                this.processAfterImages(sectionPhoto)
            ]);

            const newSectionPhoto = sectionPhoto.map((r, index) => {
                return {
                    ...r,
                    index: index + 1
                };
            });

            this.sortedSections = this.sortedSections.concat(newSectionPhoto);
        },

        async processBeforeImages(sectionPhoto) {
            if (
                this.newInvoice.beforeImages &&
                this.newInvoice.beforeImages.length > 0
            ) {
                for (let i = 0; i < this.newInvoice.beforeImages.length; i += 3) {
                    const htmlValue = this.compileHtmlSectionPhoto("beforeImages", i);

                    sectionPhoto.push({
                        sectionId: "project-photos-before",
                        sectionName: "Project Photos Before",
                        htmlValue
                    });
                }
            }
        },

        async processAfterImages(sectionPhoto) {
            if (
                this.newInvoice.afterImages &&
                this.newInvoice.afterImages.length > 0
            ) {
                for (let i = 0; i < this.newInvoice.afterImages.length; i += 3) {
                    const htmlValue = this.compileHtmlSectionPhoto("afterImages", i);

                    sectionPhoto.push({
                        sectionId: "project-photos-after",
                        sectionName: "Project Photos After",
                        htmlValue
                    });
                }
            }
        },

        compileHtmlSectionPhoto(type, index) {
            const title = type === "beforeImages" ? "Before:" : "After:";
            return `<div  style="
            padding-left: 40px;
            display: flex;
            flex-direction: column;
            background-color: #fff;
          ">
          ${index === 0
                    ? `<div style="width:100%">
                  <h4 style="font-weight: 700;color: #000;">${title}</h4>
                </div>`
                    : ""
                }
          ${this.newInvoice[type]
                    .slice(index, index + 3)
                    .map(
                        photo => `
          <div key="${type}-${photo.id
                            }" style="width: 100%; display: flex;flex-direction: column;gap: 15px; padding-bottom: 15px;">
            <div style="display: flex; gap: 10px">
          <div
            style="
              width: 350px;
              height: 400px;
              align-items: flex-end;
              background: #f6f6f6;
            "
          >
            <img
              src="${photo.photoUrl}"
              style="width: 100%; height: 100%; object-fit: contain"
            />
          </div>
          <div style="flex: 1; display: flex; align-items: flex-end">
            <div style="font-family: Arial, sans-serif">
              <p style="font-size: 14px;color: #000">
                <span style="color: #adadad">Date:</span> ${photo.createdAt &&
                            moment(this.convertDateTime(photo.createdAt)).format(
                                "MM/DD/YYYY, hh:mm a"
                            )}
              </p>
              <p style="font-size: 14px;color: #000">
                <span style="color: #adadad">Creator:</span> ${photo.createdBy}
              </p>
              ${photo.notes
                                ? `<p style="font-size: 14px;color: #000"><span style="color: #adadad">Notes:</span> ${photo.notes}</p>`
                                : ""
                            }
            </div>
          </div>
        </div>
          `
                    )
                    .join("")}
          </div>`;
        },
        convertDateTime(date) {
            return moment(new Date(this.dateToValue(date))).toDate();
        },
        dateToValue(a) {
            return a.seconds * 1000 + a.nanoseconds / 1000000;
        },
        parseHtmlMetaContent(value) {
            if (!value) return "";
            if (typeof value === "object") return value;
            let keys = Object.keys(this.keyValueDict);
            keys.forEach(key => {
                if (key === "Items Detail Table") {
                    let rows = "";
                    let rowsNetSales = "";
                    let rowsTax = "";

                    const displayItems = _.cloneDeep(this.newInvoice.itemDetails || []);
                    const taxList = this.getTaxList(this.newInvoice || {}) || [];
                    const totalNetSales = taxList.reduce(
                        (acc, item) => acc + item.netSalesAmount,
                        0
                    );
                    const totalTax = taxList.reduce(
                        (acc, item) => acc + item.taxAmount,
                        0
                    );
                    const totalDiscount = this.getTotalDiscount(this.newInvoice) || 0;
                    const shippingChargeType =
                        this.newInvoice &&
                            this.newInvoice.shippingCharge &&
                            this.newInvoice.shippingCharge.type === "cash"
                            ? "$"
                            : "%";
                    // Render Total Net Sales
                    for (let index = 0; index < taxList.length; index++) {
                        let taxItem = taxList[index];
                        rowsNetSales += `
              <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                <div style="width: 40%;">Total Net Sales ${taxItem.taxName
                            }</div>
                <div style="width: 50%; text-align: right;">$${taxItem.netSalesAmount.toFixed(
                                2
                            )}</div>
              </div>
            `;
                    }

                    // Render Total Tax
                    for (let index = 0; index < taxList.length; index++) {
                        let taxItem = taxList[index];
                        rowsTax += `
              <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                <div style="width: 40%;">Total Tax ${taxItem.taxName}</div>
                <div style="width: 50%; text-align: right;">$${taxItem.taxAmount.toFixed(
                            2
                        )}</div>
              </div>
            `;
                    }

                    for (let index = 0; index < displayItems.length; index++) {
                        let product = displayItems[index];
                        rows +=
                            `<tr style="border-bottom: 1px solid #e5e7f2; font-size:14px">` +
                            `<td style="padding: 5px;">${product.productName}</td>` +
                            (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                                ? `<td style="text-align: right;padding: 5px;">${product.category}</td>`
                                : "") +
                            (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                                ? `<td style="text-align: right;padding: 5px;">${product.quantity}</td>`
                                : "") +
                            (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                                ? `<td style="text-align: right;padding:5px">${Vue.filter(
                                    "currencyUSD"
                                )(product.priceWithProfitAndMisc)}</td>`
                                : "") +
                            `<td style="text-align: right;padding:5px;">${Vue.filter(
                                "currencyUSD"
                            )(product.amount)}</td>` +
                            `<td style="text-align: right;padding:5px;">${Vue.filter(
                                "currencyUSD"
                            )(product.discountAmount)}</td>` +
                            `<td style="text-align: right;padding:5px;">${Vue.filter(
                                "currencyUSD"
                            )(product.netSales)}</td>` +
                            `<td style="text-align: right;padding:5px;">${(product.taxPercent
                                ? product.taxPercent.toLocaleString("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                                : "0") + "%"}</td>` +
                            `<td style="text-align: right;padding:5px;">${Vue.filter(
                                "currencyUSD"
                            )(product.intoMoney)}</td>`;
                    }
                    const htmlValue =
                        `<table style="width: 100%; border: 0px; border-collapse:collapse;box-shadow:0px 1px 2px rgba(0,0,0,0.15);border-radius:4px">
        <tr style="background-color: #a8a8a8; color: white; font-weight:500; font-size: 14px;">` +
                        `<th style="text-align: left; padding: 5px;">Item Details</th>` +
                        (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                            ? `<th style="text-align: right; padding: 5px;">Category</th>`
                            : "") +
                        (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                            ? `<th style="text-align: right; padding: 5px;">Quantity</th>`
                            : "") +
                        (this.newInvoice.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
                            ? `<th style="text-align: right; padding: 5px;">Price</th>`
                            : "") +
                        `<th style="text-align: right; padding: 5px;">Amount</th>` +
                        `<th style="text-align: right; padding: 5px;">Discount</th>` +
                        `<th style="text-align: right; padding: 5px;">Net Sales</th>` +
                        `<th style="text-align: right; padding: 5px;">Tax</th>` +
                        `<th style="text-align: right; padding: 5px;">Total</th>` +
                        `</tr>
        ${rows}
        </table>
        <div style="display: flex; justify-content: flex-end; font-size:14px">
          <div style="padding-top: 20px; width:40%;">
            <!-- net sale -->
            ${rowsNetSales}
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Net Sales</div>
              <div style="width: 50%; text-align: right;">$${totalNetSales.toFixed(
                            2
                        )}</div>
            </div>

            <!-- tax -->
            ${rowsTax}
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Tax</div>
              <div style="width: 50%; text-align: right;">$${totalTax.toFixed(
                            2
                        )}</div>
            </div>

            <!-- discount -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Discount</div>
              <div style="width: 50%; text-align: right;">$${totalDiscount.toFixed(
                            2
                        )}</div>
            </div>

            <!-- shipping charge -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Shipping Charge</div>
              <div style="width: 20%;text-align: right;">${shippingChargeType === "%"
                            ? this.newInvoice.shippingCharge &&
                            this.newInvoice.shippingCharge.value + "%"
                            : "$" + this.newInvoice.shippingCharge &&
                            this.newInvoice.shippingCharge.value
                        }</div>
              <div style="width: 30%; text-align: right;">$${this.getShippingChargeValue(
                            this.newInvoice
                        ) || 0}</div>
            </div>

            <!-- total -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;"><b>Total</b></div>
              <div style="width: 50%; text-align: right;"><b>$${Vue.filter(
                            "currencyUSD"
                        )(this.totalInvoice(this.newInvoice))}</b></div>
            </div>
          </div>
        </div>`;

                    value = value.replace(
                        `<span style="background-color: #f05034;">[${key}]</span>`,
                        htmlValue
                    );
                } else {
                    value = value.replaceAll(
                        `<span style="background-color: #f05034;">[${key}]</span>`,
                        this.keyValueDict[key]
                    );
                    if (
                        key === "Client Company Tax Code" &&
                        !this.newInvoice.clientCompanyTaxCode
                    ) {
                        const searchValue = `<div>Tax code:&nbsp;</div>`;
                        const replaceValue = ``;

                        value = this.replaceKeyInContent(value, searchValue, replaceValue);
                    }
                }
            });
            return value;
        },
        replaceKeyInContent(value, searchValue, replaceValue) {
            const firstIndex = value.indexOf(searchValue);
            if (firstIndex === -1) {
                return value;
            }

            const secondIndex = value.indexOf(
                searchValue,
                firstIndex + searchValue.length
            );
            if (secondIndex === -1) {
                return value;
            }

            return (
                value.slice(0, secondIndex) +
                replaceValue +
                value.slice(secondIndex + searchValue.length)
            );
        },
        onChangeRecurringInvoiceProp(prop, value) {
            if (prop === 'startOn' && !_.isEmpty(this.newRecurringInvoice.endOn)) {
                const startOn = moment(value[0]).format('YYYY-MM-DD');
                const endOn = moment(this.newRecurringInvoice.endOn[0]).format(
                    'YYYY-MM-DD'
                );
                if (moment(endOn).isBefore(startOn, 'day')) {
                    Vue.set(this.newRecurringInvoice, 'endOn', value);
                }
            }
            Vue.set(this.newRecurringInvoice, prop, value);
        },
        onChangeSummaryFooter({ prop, subprop, value }) {
            let fieldDetail = this.newRecurringInvoice.invoice[prop];
            fieldDetail = {
                ...fieldDetail,
                [subprop]: value,
            };
            this.onChangeInvoiceProp(prop, fieldDetail);
        },
        onChangeItemDetails(items) {
            this.onChangeInvoiceProp('itemDetails', items);
        },
        validate() {
            this.v$.$touch();
            let isProductItemsValid = this.$refs.productItemTable.validate();

            if (this.v$.$invalid || !isProductItemsValid) {
                return false;
            }

            return true;
        },
        replaceInvoiceNumberInUrl(url, invoiceNumber) {
            const parts = url.split("/");
            parts[parts.length - 1] = invoiceNumber;
            return parts.join("/");
        },
        async save() {
            if (!this.validate()) return;

            let data = {
                ...this.newRecurringInvoice,
                invoiceGroupId: this.invoiceGroup.id || '',
                repeatEvery:
                    this.newRecurringInvoice.repeatEvery == 'custom'
                        ? `${this.repeatValue}_${this.repeatUnit}`
                        : this.newRecurringInvoice.repeatEvery,
                paymentTerm:
                    this.newRecurringInvoice.paymentTerm == 'custom'
                        ? this.numberOfDays
                        : this.newRecurringInvoice.paymentTerm,
                startOn:
                    this.$google.firebase.firestore.Timestamp.fromDate(
                        this.newRecurringInvoice.startOn[0]
                    ) || null,
                endOn: !_.isEmpty(this.newRecurringInvoice.endOn)
                    ? this.$google.firebase.firestore.Timestamp.fromDate(
                        this.newRecurringInvoice.endOn[0]
                    )
                    : null,
                totalAmount: this.totalInvoice(
                    _.cloneDeep(this.newRecurringInvoice.invoice)
                ),
                invoiceAmount: this.totalInvoice(
                    _.cloneDeep(this.newRecurringInvoice.invoice)
                ),
            };

            this.$f7.preloader.show();

            if (!data.invoiceGroupId) {
                const newInvoiceGroup = await this.createInvoiceGroup();
                data = { ...data, invoiceGroupId: newInvoiceGroup.id };
                this.invoiceGroup = { ...this.invoiceGroup, id: newInvoiceGroup.id };
            }

            await this.createOrUpdate(data).then(async res => {
                // if startOn === now => generate invoice
                const invoiceDate = moment(
                    this.newRecurringInvoice.startOn[0],
                    'MM/DD/YYYY'
                );
                if (
                    invoiceDate.isSame(moment(), 'day')) {
                    const dueDate = _.cloneDeep(moment(invoiceDate, 'MM/DD/YYYY')).add(
                        parseInt(data.paymentTerm),
                        'days'
                    );

                    // create invoice and sent mail
                    this.newInvoice = {
                        ...data.invoice,
                        status: DEFAULT_STATUS_INVOICE,
                        invoiceDate: new Date(invoiceDate),
                        dueDate: new Date(dueDate),
                        recurringInvoiceId: data.id ? data.id : res.id,
                        invoiceGroupId: this.invoiceGroup.id,
                        invoiceAmount: this.totalInvoice(_.cloneDeep(data.invoice)),

                    };
                    const status = data.status ? data.status : res.status;
                    if (status !== "ri-inactive") {
                        this.sortedSections = await this.compileSectionData();
                        await this.handleSectionPhoto();

                        await this.createNewInvoice({
                            invoice: {
                                ...this.newInvoice,
                                sections: this.sortedSections,
                            },
                            isInvoiceGrid: this.isInvoiceGrid,
                        }).then(async invoice => {

                            // sent mail
                            const response = await axiosService.post("/tenant/get-anonymous-token", {
                                entity: "invoice",
                                entityId: invoice.id
                            });
                            const emailData = {
                                to: this.newInvoice.clientEmail,
                                template: 'invoice',
                                subject: {
                                    invoice_number: invoice.docNumber || '',
                                    roofing_company_name: this.newInvoice.roofingCompanyName || '',
                                    project_name: this.newInvoice.jobName,
                                },
                                sender: `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`,
                                content: {
                                    invoice_amount: currencyUSD(
                                        this.totalInvoice(data.invoice) || 0
                                    ),
                                    client_company_name: this.newInvoice.clientName || '',
                                    roofing_company_name: this.newInvoice.roofingCompanyName || '',
                                    roofing_company_address: this.newInvoice.roofingCompanyAddress || '',
                                    roofing_company_phone: this.newInvoice.roofingCompanyPhone || '',
                                    invoice_number: invoice.docNumber || '',
                                    invoice_date: MMDDYYYY(this.newInvoice.invoiceDate ? this.newInvoice.invoiceDate : ''),
                                    due_date: MMDDYYYY(this.newInvoice.dueDate ? this.newInvoice.dueDate : ''),
                                    detail_url: `${import.meta.env.VITE_HOST_DOMAIN
                                        }/invoices-view/?token=${response.data}&id=${invoice.id}`,
                                    project_name: this.newInvoice.jobName,
                                },
                                attachments: [],
                                scheduleSendingTime: firebase.firestore.Timestamp.now(),
                            };
                            this.newInvoiceNumber = invoice.invoiceNumber
                            Promise.all([
                                this.sendCreatedEmailNotification(emailData),
                                this.updateInvoice({
                                    id: invoice.id,
                                    doc: {
                                        status: 'in-sent',
                                    },
                                }),
                                this.updateRecurringInvoice({
                                    id: data.id ? data.id : res.id,
                                    doc: {
                                        invoiceList: [
                                            ...(data.invoiceList || []),
                                            {
                                                invoiceId: invoice.id,
                                                invoiceNumber: invoice.docNumber,
                                            },
                                        ],
                                        lastGeneratedInvoiceDate: firebase.firestore.Timestamp.fromDate(
                                            new Date(invoiceDate)
                                        ),
                                    },
                                }),
                                this.updateInvoiceGroupAfterCreateInvoice(invoice),
                            ]);
                        });
                    }
                }
                // remove redundant photos
                for (const photo of this.redundantPhotos) {
                    this.removePhoto(photo.fullPath);
                }
                this.redundantPhotos = []
                this.$f7.preloader.hide();
                // this.cancel();
                this.$emit("done")
                if (!this.isPopup) {
                    let url = this.$f7router.history[this.$f7router.history.length - 2];
                    if (this.newInvoiceNumber && url !== "/invoices-group") {
                        url = this.replaceInvoiceNumberInUrl(url, this.newInvoiceNumber);
                    }
                    this.$f7router.navigate(url, {
                        force: true,
                        clearPreviousHistory: true,
                    });
                }
            });
        },

        async updateInvoiceGroupAfterCreateInvoice(newInvoice) {
            // update invoice group
            const invoiceGroupUpdate = await this.getInvoiceGroupById(
                newInvoice.invoiceGroupId
            );

            let newInvoicesOfGroup = invoiceGroupUpdate.invoices?.length
                ? invoiceGroupUpdate.invoices
                : [];

            newInvoicesOfGroup.push({
                id: newInvoice.id,
                invoiceNumber: newInvoice.invoiceNumber,
            });

            await this.saveInvoiceGroup({
                id: newInvoice.invoiceGroupId,
                doc: {
                    invoices: newInvoicesOfGroup,
                },
            });
        },

        sendCreatedEmailNotification(emailData) {
            return axiosService
                .post('/notification/push-email', emailData)
                .then(() => {
                    // TODO: this trigger will be removed when implement email mail service scheduler
                    return axiosService.post('/notification/trigger-email-service', {
                        sender: '',
                    });
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error('[Send Email]', error);
                    throw new Error(error.message);
                });
        },

        createOrUpdate(data) {
            if (data.id) {
                return this.updateRecurringInvoice({
                    id: data.id,
                    doc: data,
                });
            } else {
                return this.createRecurringInvoice({
                    recurringInvoice: data,
                    isInvoiceGrid: this.isInvoiceGrid,
                });
            }
        },

        requireErrorMessage(prop) {
            if (!this.v$.newRecurringInvoice[prop].$error) return null;
            if (this.v$.newRecurringInvoice[prop].required.$invalid)
                return VALIDATION_MESSAGE.REQUIRED_FIELD;
            return null;
        },

        jobNameErrorMessage() {
            if (!this.v$.newRecurringInvoice.invoice.jobName.$error) return null;
            if (this.v$.newRecurringInvoice.invoice.jobName.required.$invalid)
                return 'This field is required';
            return null;
        },

        clientEmailErrorMessage() {
            if (!this.v$.newRecurringInvoice.invoice.clientEmail.$error) return null;
            if (this.v$.newRecurringInvoice.invoice.clientEmail.required.$invalid)
                return VALIDATION_MESSAGE.REQUIRED_FIELD;
            if (
                this.v$.newRecurringInvoice.invoice.clientEmail.commonEmailRule.$invalid
            )
                return 'Invalid email';
            return null;
        },

        clientNameErrorMessage() {
            if (!this.v$.newRecurringInvoice.invoice.clientName.$error) return null;
            if (this.v$.newRecurringInvoice.invoice.clientName.required.$invalid)
                return VALIDATION_MESSAGE.REQUIRED_FIELD;
            return null;
        },

        clientPhoneNumberErrorMessage() {
            if (!this.v$.newRecurringInvoice.invoice.clientPhoneNumber.$error)
                return null;
            if (
                this.v$.newRecurringInvoice.invoice.clientPhoneNumber.minLength.$invalid
            )
                return 'The phone number is not in the correct format';
            return null;
        },

        clientCompanyTaxCodeErrorMessage() {
            if (!this.v$.newRecurringInvoice.invoice.clientCompanyTaxCode.$error)
                return null;
            if (
                this.v$.newRecurringInvoice.invoice.clientCompanyTaxCode.validateEIN
                    .$invalid
            )
                return VALIDATION_MESSAGE.INVALID_TAX_CODE;
            return null;
        },

        onChangeDiscountType(value) {
            this.onChangeInvoiceProp('discountType', value);
        },

        setAdjustedTotalAmount() {
            this.onChangeInvoiceProp(
                'itemDetails',
                this.totalInvoice(this.newRecurringInvoice.invoice)
            );
        },
    },

    validations() {
        const timeRules = value => {
            if (_.isEmpty(value)) return true;
            const startOn = moment(this.newRecurringInvoice.startOn[0]).format(
                'YYYY-MM-DD'
            );
            const endOn = moment(value[0]).format('YYYY-MM-DD');
            return !moment(endOn).isBefore(startOn, 'day');
        };

        const validateEIN = helpers.withParams({ type: 'validateEIN' }, value => {
            if (!value) return true;
            return /^\d{2}-\d{7}$/.test(value);
        });

        return {
            newRecurringInvoice: {
                startOn: {
                    required,
                },
                status: {
                    required,
                },
                repeatEvery: {
                    required,
                },
                paymentTerm: {
                    required,
                },
                invoice: {
                    jobName: {
                        required,
                    },
                    clientName: {
                        required,
                    },
                    clientEmail: {
                        required,
                        commonEmailRule: email,
                    },
                    clientPhoneNumber: {
                        minLength: minLength(17),
                    },
                    clientCompanyTaxCode: {
                        validateEIN: validateEIN,
                    },
                },
                endOn: {
                    timeRules,
                },
            },
            repeatValue: {
                required,
                minValue: minValue(1),
            },
            repeatUnit: {
                required,
            },
            numberOfDays: {
                required,
                minValue: minValue(0),
            },
        };
    },
    beforeDestroy() {
        this.cancel()
    },

};
</script>
<style land="scss" scoped>
.flex-start {
    justify-content: flex-start;
}

.select-type ::v-deep select {
    min-width: 40px;
}
</style>
