<template>
  <f7-list
    inline-labels
    :inset="$device.desktop"
    :class="{ 'no-margin': $device.desktop }"
    class="margin-bottom"
  >
    <!-- <f7-list-input
      label="A"
      type="text"
      placeholder="Enter value"
      :value="data.A"
      disabled
    ></f7-list-input> -->
    <f7-list-input
      label="B"
      type="text"
      placeholder="Enter value"
      :value="data.B"
      @change="setField('B', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="C"
      type="text"
      placeholder="Enter value"
      :value="data.C"
      @change="setField('C', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="D"
      type="text"
      placeholder="Enter value"
      :value="data.D"
      @change="setField('D', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="E"
      type="text"
      placeholder="Enter value"
      :value="data.E"
      @change="setField('E', $event.target.value.trim())"
    ></f7-list-input>

    <f7-list-input
      label="F"
      type="text"
      placeholder="Enter value"
      :value="data.F"
      @change="setField('F', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="W"
      type="text"
      placeholder="Enter value"
      :value="data.W"
      @change="setField('W', $event.target.value.trim())"
    ></f7-list-input>
    <f7-list-input
      label="X"
      type="text"
      placeholder="Enter value"
      :value="data.X"
      @change="setField('X', $event.target.value.trim())"
    ></f7-list-input>
  </f7-list>
</template>

<script>
export default {
  props: {
    data: Object
  },

  methods: {
    setField(name, value) {
      this.$emit("setFields", { name, value });
    }
  }
};
</script>
