<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handleClose"
    @popup:opened="handlePopupOpened"
  >
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Price List</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search price list"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-group
          v-for="(group, index) in priceListItemGroup"
          :key="index"
        >
          <f7-list-item
            :title="Object.keys(group)[0]"
            group-title
          ></f7-list-item>
          <f7-list-item
            no-chevron
            link
            v-for="priceListItem in priceListItemGroup[index][
              Object.keys(group)[0]
            ]"
            :key="priceListItem.id"
            :title="priceListItem.displayName"
            :after="priceListItem.priceListNumber"
            @click.native="select(priceListItem.id)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>

    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Price List</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- Price List Name -->
        <f7-list-input
          type="text"
          placeholder="Price List name"
          clear-button
          :value="currentPriceListItem.displayName"
          @input="currentPriceListItem.displayName = $event.target.value.trim()"
        ></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import moment from "moment";
// import { DEFAULT_STATUS_PRICE_LIST } from "../../../../utility/const";

export default {
  data: () => {
    return {
      popupOpened: false,
      value: "",
      isNew: false,
      currentPriceListItem: {},
      searchValue: "",

      roofType: ""
    };
  },

  computed: {
    ...mapGetters("common/price-list", ["priceListItemList"]),

    priceListItemGroup() {
      const searchValue = this.searchValue;
      let group = [];
      let priceListItemList = this.priceListItemList;

      if (searchValue) {
        priceListItemList = priceListItemList.filter(priceListItem => {
          let displayName = priceListItem.displayName
            ? priceListItem.displayName.toLowerCase()
            : "";

          return displayName.indexOf(searchValue.toLowerCase()) >= 0;
        });
      }

      priceListItemList.forEach(priceListItem => {
        let letter = priceListItem.displayName
          ? priceListItem.displayName.charAt(0).toUpperCase()
          : "";
        let index = group.findIndex(groupName => {
          return letter === Object.keys(groupName)[0];
        });
        if (index >= 0) {
          group[index][letter].push(priceListItem);
        } else {
          group.push({ [letter]: [priceListItem] });
        }
      });

      group.forEach(data => {
        let letter = Object.keys(data)[0];
        data[letter].sort((a, b) => {
          return (a.displayName ? a.displayName.charAt(1).toUpperCase() : "") <
            (b.displayName ? b.displayName.charAt(1).toUpperCase() : "")
            ? -1
            : 0;
        });
      });

      return group.sort((a, b) => {
        return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
      });
    }
  },

  methods: {
    ...mapActions("common/price-list", ["getPriceListItemListBys"]),

    initData(roofType) {
      this.roofType = roofType;

      this.$f7.preloader.show();
      if (this.roofType === "all-roof-type") {
        this.getPriceListItemListBys([
          {
            prop: "isDeleted",
            val: false,
            op: "=="
          },
          {
            prop: "status",
            val: "pl-active",
            op: "=="
          }
        ]).then(() => {
          this.$f7.preloader.hide();
        });
      } else {
        this.getPriceListItemListBys([
          {
            prop: "isDeleted",
            val: false,
            op: "=="
          },
          {
            prop: "status",
            val: "pl-active",
            op: "=="
          },
          {
            prop: "roofTypes",
            val: roofType,
            op: "array-contains"
          }
        ]).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    handleClose() {
      this.popupOpened = false;
      this.searchValue = "";
    },
    open(roofType) {
      this.initData(roofType);
      this.popupOpened = true;
    },

    select(priceListItemId) {
      this.$emit("onSelected", priceListItemId);
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  }
};
</script>

<style></style>
