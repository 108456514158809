import project from "./project";
import user from "./user";
import timeTracking from "./time-tracking";
import expenses from "./employee-expenses";
import laborCost from "./labor-cost";
import appConstant from "./app-constant";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      project: project(tenant),
      "time-tracking": timeTracking(tenant),
      expenses: expenses(tenant),
      "app-constant": appConstant(tenant),
      "labor-cost": laborCost(tenant),
      user: user
    }
  };
};
