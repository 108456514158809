import { getFullAddress } from "@/utility/address";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TAX_PROFIT,
  TABLE_CONTENT_TYPE_ITEMIZE,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL
} from "@/utility/const";

export default {
  computed: {
    ...mapGetters("swimlane/client", ["contact", "property"]),
    ...mapGetters("swimlane/estimate", ["estimateById"]),
    displayLaborMaterial() {
      return invoiceDetail => {
        let materialCost = {
          productName: "Material Cost",
          discount: false,
          tax: false,
          taxAmount: 0,
          discountAmount: 0,
          amount: 0
        };
        let laborCost = {
          productName: "Labor Cost",
          discount: false,
          tax: false,
          taxAmount: 0,
          discountAmount: 0,
          amount: 0
        };
        (invoiceDetail.itemDetails || []).forEach(r => {
          const miscPercent = r.productName.includes("Material Cost")
            ? invoiceDetail.miscPercent || 0
            : 0;
          let priceWithProfitAndMisc =
            r.price /
              ((100 - (invoiceDetail.crossProfitMarginRatio + miscPercent)) /
                100) || 0;
          let amount = (r.quantity || 1) * (priceWithProfitAndMisc || 0);
          let discountAmount = r.discount
            ? this.getAmountWithPercentOrCash(invoiceDetail.discount, amount)
            : 0;
          let taxAmount = r.tax
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.tax,
                amount - discountAmount
              )
            : 0;
          if (r.productName.includes("Material Cost")) {
            materialCost["discount"] = r.discount;
            materialCost["tax"] = r.tax;
            materialCost["discountAmount"] += parseFloat(
              discountAmount.toFixed(2)
            );
            materialCost["taxAmount"] += parseFloat(taxAmount.toFixed(2));
            materialCost["amount"] += parseFloat(amount.toFixed(2));
          }
          if (r.productName.includes("Labor Cost")) {
            laborCost["discount"] = r.discount;
            laborCost["tax"] = r.tax;
            laborCost["discountAmount"] += parseFloat(
              discountAmount.toFixed(2)
            );
            laborCost["taxAmount"] += parseFloat(taxAmount.toFixed(2));
            laborCost["amount"] += parseFloat(amount.toFixed(2));
          }
        });
        let list = [];
        list.push(materialCost, laborCost);
        return list;
      };
    },
    displaySimpleSummary() {
      return invoiceDetail => {
        let item = {
          productName: "Service Fee",
          discount: true,
          tax:
            (invoiceDetail.itemDetails || []).findIndex(r => r.tax == true) < 0
              ? false
              : true,
          taxAmount: 0,
          discountAmount: 0,
          amount: 0
        };
        (invoiceDetail.itemDetails || []).forEach(r => {
          const miscPercent = r.productName.includes("Material Cost")
            ? invoiceDetail.miscPercent || 0
            : 0;
          let priceWithProfitAndMisc =
            r.price /
              ((100 - (invoiceDetail.crossProfitMarginRatio + miscPercent)) /
                100) || 0;
          let amount = (r.orderQty || 1) * (priceWithProfitAndMisc || 0);
          let discountAmount =
            this.getAmountWithPercentOrCash(invoiceDetail.discount, amount) ||
            0;
          let taxAmount = r.tax
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.tax,
                amount - discountAmount
              )
            : 0;
          item.discountAmount += parseFloat(discountAmount.toFixed(2));
          item.taxAmount += parseFloat(taxAmount.toFixed(2));
          item.amount += parseFloat(amount.toFixed(2));
        });
        let list = [];
        list.push(item);
        return list;
      };
    },
    displayItemize() {
      return invoiceDetail => {
        return (invoiceDetail.itemDetails || []).map(r => {
          const miscPercent =
            r.category !== "Labor" ? invoiceDetail.miscPercent || 0 : 0;
          let priceWithProfitAndMisc =
            r.price /
              ((100 -
                ((invoiceDetail.crossProfitMarginRatio || 0) + miscPercent)) /
                100) || 0;
          let amount = (r.quantity || 0) * (priceWithProfitAndMisc || 0);
          let discountAmount = r.discount
            ? this.getAmountWithPercentOrCash(invoiceDetail.discount, amount)
            : 0;

          let taxAmount = r.tax
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.tax,
                amount - discountAmount
              )
            : 0;
          return {
            ...r,
            priceWithProfitAndMisc,
            amount,
            discountAmount,
            taxAmount
          };
        });
      };
    },
    items() {
      return invoiceDetail => {
        if (
          invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL
        ) {
          return this.displayLaborMaterial(invoiceDetail || {});
        } else if (
          invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ) {
          return this.displaySimpleSummary(invoiceDetail || {});
        } else if (
          invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
        ) {
          return this.displayItemize(invoiceDetail || {});
        } else {
          return (invoiceDetail.itemDetails || []).map(r => {
            let amount = (r.quantity || 0) * (r.price || 0);
            let discountAmount = r.discount
              ? this.getAmountWithPercentOrCash(invoiceDetail.discount, amount)
              : 0;

            let taxAmount = r.tax
              ? this.getAmountWithPercentOrCash(
                  invoiceDetail.tax,
                  amount - discountAmount
                )
              : 0;
            return {
              ...r,
              amount,
              discountAmount,
              taxAmount
            };
          });
        }
      };
    },

    // not service
    totalAmountWithItems() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) => accumulator + parseFloat(item.amount || 0),
          0
        );
      };
    },

    totalTaxWithItems() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) => accumulator + parseFloat(item.taxAmount || 0),
          0
        );
      };
    },

    // for service
    serviceMaterialSubTotal() {
      return itemDetails => {
        return itemDetails.reduce(
          (accumulator, item) =>
            accumulator +
            (item.category !== "Labor"
              ? (item.quantity || 0) * (item.price || 0)
              : 0),
          0
        );
      };
    },

    serviceMaterialNetSales() {
      return invoiceDetail => {
        const materialSubTotal =
          this.serviceMaterialSubTotal(invoiceDetail.itemDetails || []) || 0;
        const crossProfitMarginRatio =
          invoiceDetail.crossProfitMarginRatio || 0;
        const miscPercent = invoiceDetail.miscPercent || 0;
        return (
          materialSubTotal /
            ((100 - (crossProfitMarginRatio + miscPercent)) / 100) || 0
        );
      };
    },

    serviceMaterialDiscountAmount() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) =>
            accumulator +
            (item.category !== "Labor"
              ? parseFloat(item.discountAmount || 0)
              : 0),
          0
        );
      };
    },

    serviceMaterialTax() {
      return invoiceDetail => {
        return (
          this.getAmountWithPercentOrCash(
            invoiceDetail.tax,
            this.serviceMaterialNetSales(invoiceDetail) -
              this.serviceMaterialDiscountAmount(invoiceDetail)
          ) || 0
        );
      };
    },

    serviceLaborSubTotal() {
      return itemDetails => {
        return itemDetails.reduce(
          (accumulator, item) =>
            accumulator +
            (item.category === "Labor"
              ? (item.quantity || 0) * (item.price || 0)
              : 0),
          0
        );
      };
    },

    serviceLaborNetSales() {
      return invoiceDetail => {
        const materialSubTotal =
          this.serviceLaborSubTotal(invoiceDetail.itemDetails) || 0;
        const crossProfitMarginRatio =
          invoiceDetail.crossProfitMarginRatio || 0;

        return materialSubTotal / ((100 - crossProfitMarginRatio) / 100) || 0;
      };
    },

    serviceLaborDiscountAmount() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) =>
            accumulator +
            (item.category === "Labor"
              ? parseFloat(item.discountAmount || 0)
              : 0),
          0
        );
      };
    },

    serviceLaborTax() {
      return invoiceDetail => {
        // get estimate
        const estimate = this.estimateById(invoiceDetail.estimateId) || {};
        return estimate.taxApplyType === TAX_PROFIT
          ? this.getAmountWithPercentOrCash(
              invoiceDetail.tax,
              this.serviceLaborNetSales(invoiceDetail) -
                this.serviceLaborDiscountAmount(invoiceDetail)
            )
          : 0;
      };
    },

    serviceSubTotal() {
      return invoiceDetail => {
        return (
          parseFloat(this.serviceMaterialNetSales(invoiceDetail).toFixed(2)) +
          parseFloat(this.serviceLaborNetSales(invoiceDetail).toFixed(2))
        );
      };
    },

    serviceTax() {
      return invoiceDetail => {
        return (
          parseFloat(this.serviceMaterialTax(invoiceDetail).toFixed(2)) +
          parseFloat(this.serviceLaborTax(invoiceDetail).toFixed(2))
        );
      };
    },

    // display summary footer
    subTotal() {
      return invoiceDetail => {
        return invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
          ? this.serviceSubTotal(invoiceDetail)
          : this.totalAmountWithItems(invoiceDetail);
      };
    },

    taxAmount() {
      return invoiceDetail => {
        if (invoiceDetail.syncFromQB) return invoiceDetail.tax.value;
        return invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
          ? this.serviceTax(invoiceDetail)
          : this.totalTaxWithItems(invoiceDetail);
      };
    },

    discountAmount() {
      return invoiceDetail => {
        if (invoiceDetail.syncFromQB) return invoiceDetail.discount.value;
        return this.items(invoiceDetail).reduce(
          (accumulator, item) =>
            accumulator + parseFloat(item.discountAmount || 0),
          0
        );
      };
    },

    shippingChargeValue() {
      return invoiceDetail => {
        return this.getAmountWithPercentOrCash(
          invoiceDetail.shippingCharge,
          this.subTotal(invoiceDetail) -
            this.discountAmount(invoiceDetail) +
            this.taxAmount(invoiceDetail)
        );
      };
    },

    total() {
      return invoiceDetail => {
        if (invoiceDetail.syncFromQB) return invoiceDetail.totalAmount;

        return (
          this.subTotal(invoiceDetail) -
          this.discountAmount(invoiceDetail) +
          this.taxAmount(invoiceDetail) +
          this.shippingChargeValue(invoiceDetail)
        );
      };
    }
  },
  methods: {
    getContactName(contact) {
      return !_.isEmpty(contact)
        ? ((contact.firstName || "") + " " + (contact.lastName || "")).trim()
        : "";
    },

    getContactAddress(contact) {
      if (!_.isEmpty(contact)) {
        const addresses = contact.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },

    getPropertyAddress(property) {
      if (!_.isEmpty(property)) {
        const addresses = property.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },

    getPropertyName(property) {
      return property ? property.propertyName || "" : "";
    },

    getJobTitle(job) {
      let jobTitle = "";
      if (
        ["residential", "multifamily"].includes(job.customerType) &&
        !_.isEmpty(this.contact)
      ) {
        jobTitle = this.getContactName(this.contact);
        if (!_.isEmpty(job.projectAddress.address)) {
          jobTitle += ` - ${getFullAddress(job.projectAddress)}`;
        }
      } else {
        if (!_.isEmpty(this.property)) {
          jobTitle = `${this.getPropertyName(
            this.property
          )} - ${this.getPropertyAddress(this.property)}`;
        }
      }

      return jobTitle;
    },
    getAmountWithPercentOrCash(item = { value: 0 }, amount) {
      let value = 0;
      if (item.type == "percent") {
        value = (item.value * amount) / 100;
      } else {
        value = item.value;
      }
      return parseFloat(value || 0);
    }
  }
};
