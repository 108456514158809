<template>
  <div
    class="no-padding-top page-content infinite-scroll-content"
    style="overflow: auto;"
    @infinite="loadMore"
  >
    <div>
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in hits"
            :title="item.contractNumber"
            :key="item.id"
            :reload-detail="true"
            :link="toDetail(item)"
            :text-color="
              item.contractNumber === (contract && contract.contractNumber)
                ? 'primary'
                : 'default'
            "
          >
            <div slot="footer">
              <span>Created on: </span>
              <timeago
                v-if="item.createdAt"
                :datetime="convertDateTime(item.createdAt)"
                :autoUpdate="1"
                :converterOptions="{ includeSeconds: true }"
              />
            </div>
            <div slot="text">
              <div class="item-text-slot">
                Project: {{ item.projectName || "" }}
              </div>
              <span class="item-text-slot">
                Contact:
                {{ item.contactName || "" }}
              </span>
            </div>
            <f7-chip
              slot="after"
              :text="onlineClass(item.status).text"
              :color="onlineClass(item.status).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";

export default {
  data: () => {
    return {
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,
      selectedStatuses: []
    };
  },

  methods: {
    ...mapActions("proposal/contract", [
      "searchContract",
      "loadMoreContract",
      "resetSearch",
      "setSelectedStatusFilter"
    ]),

    dateToValue(a) {
      return a._seconds * 1000 + a._nanoseconds / 1000000;
    },
    convertDateTime(createdAt) {
      return moment(new Date(this.dateToValue(createdAt))).toDate();
    },
    init() {
      if (!this.hasMoreItems) {
        this.$f7.preloader.show();
      }
      this.onSearch().then(() => {
        if (!this.hasMoreItems) {
          this.$f7.preloader.hide();
        }
      });
    },

    onSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchContract({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    },
    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreContract({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    onlineClass(status) {
      return status === "sent"
        ? { color: "green", text: "Sent" }
        : status === "withdraw"
        ? { color: "", text: "Withdrawn" }
        : status === "viewed"
        ? { color: "pink", text: "Viewed" }
        : status === "signed"
        ? { color: "blue", text: "Signed" }
        : { color: "yellow", text: "Draft" };
    },

    toDetail(item) {
      if (!_.isEmpty(item)) {
        return `/proposal/${item.contractNumber}`;
      } else {
        return "#";
      }
    },
    filterByStatus(checked, status) {
      // update checked status for statusFilter
      if (checked) {
        this.selectedStatuses.push(status);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          item => item !== status
        );
      }
      this.setSelectedStatusFilter(_.cloneDeep(this.selectedStatuses));
      this.onSearch();
    },
    removeFilter() {
      this.selectedStatuses = [];
      this.setSelectedStatusFilter([]);
      this.onSearch();
    }
  },

  computed: {
    ...mapGetters("proposal/proposal", ["searchText"]),
    ...mapGetters("proposal/contract", [
      "contract",
      "hits",
      "nbPages",
      "page",
      "selectedStatusFilter"
    ])
  },

  beforeDestroy() {
    const currentPath = (
      (this.$f7 && this.$f7.views.main.router.currentRoute.route) ||
      {}
    ).path;
    if (currentPath !== "/proposal") {
      this.removeFilter();
      this.resetSearch();
    }
  }
};
</script>

<style scoped>
.item-text-slot {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
