const languages = [
  { displayName: "Afrikaans", value: "af-ZA" },
  { displayName: "Bahasa Indonesia", value: "id-ID" },
  { displayName: "Bahasa Melayu", value: "ms-MY" },
  { displayName: "Català", value: "ca-ES" },
  { displayName: "Čeština", value: "cs-CZ" },
  { displayName: "Deutsch", value: "de-DE" },
  { displayName: "English (Australia)", value: "en-AU" },
  { displayName: "English (Canada)", value: "en-CA" },
  { displayName: "English (India)", value: "en-IN" },
  { displayName: "English (New Zealand)", value: "en-NZ" },
  { displayName: "English (South Africa)", value: "en-ZA" },
  { displayName: "English (United Kingdom)", value: "en-GB" },
  { displayName: "English (United States)", value: "en-US" },
  { displayName: "Español (Argentina)", value: "es-AR" },
  { displayName: "Español (Bolivia)", value: "es-BO" },
  { displayName: "Español (Chile)", value: "es-CL" },
  { displayName: "Español (Colombia)", value: "es-CO" },
  { displayName: "Español (Costa Rica)", value: "es-CR" },
  { displayName: "Español (Ecuador)", value: "es-EC" },
  { displayName: "Español (El Salvador)", value: "es-SV" },
  { displayName: "Español (España)", value: "es-ES" },
  { displayName: "Español (Estados Unidos)", value: "es-US" },
  { displayName: "Español (Guatemala)", value: "es-GT" },
  { displayName: "Español (Honduras)", value: "es-HN" },
  { displayName: "Español (México)", value: "es-MX" },
  { displayName: "Español (Nicaragua)", value: "es-NI" },
  { displayName: "Español (Panamá)", value: "es-PA" },
  { displayName: "Español (Paraguay)", value: "es-PY" },
  { displayName: "Español (Perú)", value: "es-PE" },
  { displayName: "Español (Puerto Rico)", value: "es-PR" },
  { displayName: "Español (República Dominicana)", value: "es-DO" },
  { displayName: "Español (Uruguay)", value: "es-UY" },
  { displayName: "Español (Venezuela)", value: "es-VE" },
  { displayName: "Euskara", value: "eu-ES" },
  { displayName: "Français", value: "fr-FR" },
  { displayName: "Galego", value: "gl-ES" },
  { displayName: "Hrvatski", value: "hr_HR" },
  { displayName: "IsiZulu", value: "zu-ZA" },
  { displayName: "Íslenska", value: "is-IS" },
  { displayName: "Italiano (Italia)", value: "it-IT" },
  { displayName: "Italiano (Svizzera)", value: "it-CH" },
  { displayName: "Magyar", value: "hu-HU" },
  { displayName: "Nederlands", value: "nl-NL" },
  { displayName: "Norsk bokmål", value: "nb-NO" },
  { displayName: "Polski", value: "pl-PL" },
  { displayName: "Português (Brasil)", value: "pt-BR" },
  { displayName: "Português (Portugal)", value: "pt-PT" },
  { displayName: "Română", value: "ro-RO" },
  { displayName: "Slovenčina", value: "sk-SK" },
  { displayName: "Suomi", value: "fi-FI" },
  { displayName: "Svenska", value: "sv-SE" },
  { displayName: "Türkçe", value: "tr-TR" },
  { displayName: "български", value: "bg-BG" },
  { displayName: "Pусский", value: "ru-RU" },
  { displayName: "Српски", value: "sr-RS" },
  { displayName: "한국어", value: "ko-KR" },
  { displayName: "中文 (普通话, 中国大陆)", value: "cmn-Hans-CN" },
  { displayName: "中文 (普通话, 香港)", value: "cmn-Hans-HK" },
  { displayName: "中文 (台灣)", value: "cmn-Hant-TW" },
  { displayName: "粵語 (香港)", value: "yue-Hant-HK" },
  { displayName: "日本語", value: "ja-JP" },
  { displayName: "Lingua latīna", value: "la" },
  { displayName: "Vietnamese", value: "vi-VN" }
];

const ACTIVE_LANGUAGES = Object.freeze({
  EN: {
    value: "en",
    displayName: "English"
  },
  ESP: {
    value: "esp",
    displayName: "Espanol"
  },

  getAllActiveLanguage() {
    return [this.EN, this.ESP];
  }
});

export { languages, ACTIVE_LANGUAGES };
