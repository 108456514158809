<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Price List</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click.native="popupAddNewPrice = true"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search Price List"
      :clear-button="true"
      class="text-color-white search-list"
      @input="searchText = $event.target.value"
      @searchbar:disable="searchText = ''"
      :value="searchText"
    ></f7-searchbar>
    <div>
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in dataPriceList"
            :key="item.id"
            :title="item.priceListNumber"
            :reload-detail="true"
            :link="`/price-list/${item.priceListNumber}`"
            swipeout
            :text-color="
              item.priceListNumber === (priceListDetail || {}).priceListNumber
                ? 'primary'
                : 'default'
            "
          >
            <f7-swipeout-actions right>
              <f7-swipeout-button
                :close="true"
                color="blue"
                @click="
                  popupCopyOpen = true;
                  itemCopyPrice = item;
                "
              >
                <span>Copy</span>
              </f7-swipeout-button>
              <f7-swipeout-button
                :close="true"
                color="red"
                @click="openConfirmDelete(item)"
                >Delete</f7-swipeout-button
              >
            </f7-swipeout-actions>
            <div slot="subtitle">{{ item.displayName | capitalize }}</div>
            <f7-row slot="text">
              <f7-col width="50"
                ><div>
                  <div class="font-text">
                    Start Date:
                    {{ item.startDateToString }}
                  </div>
                </div>
                <div>
                  <div class="font-text number-of-line">
                    End Date:
                    {{ item.endDateToString }}
                  </div>
                </div></f7-col
              >

              <f7-col
                width="50 "
                class="justify-content-end"
                style="display:grid"
                ><div>
                  <div class="font-text">
                    Create on:
                    {{ item.createAtToString }}
                  </div>
                </div>
                <div>
                  <div class="font-text text number-of-line">
                    Created By: {{ item.createdBy }}
                  </div>
                </div></f7-col
              >
            </f7-row>

            <f7-chip
              slot="after"
              :text="getStatus(item).displayName"
              :color="getStatus(item).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <copy-price-list-popup
        :popupCopyOpen="popupCopyOpen"
        @close="popupCopyOpen = false"
        :itemCopyPrice="itemCopyPrice"
      />
      <add-new-price-list-popup
        :popupAddNewPrice="popupAddNewPrice"
        @close="popupAddNewPrice = false"
      />
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import CopyPriceListPopup from "../components/popup/CopyPriceListPopup.vue";
import AddNewPriceListPopup from "../components/popup/AddNewPriceListPopup.vue";
import { sortLatest } from "@/utility/date-time-tool";
import { applyFilter } from "@/utility/filter-tools";
import _ from "lodash";
import Menu from "../../../components/menu/Menu.vue";
import { COLLECTION_PRICE_LIST } from "@/utility/const";
export default {
  components: {
    CopyPriceListPopup,
    AddNewPriceListPopup,
    Menu
  },
  data: () => {
    return {
      popupCopyOpen: false,
      popupAddNewPrice: false,
      itemCopyPrice: null,
      itemsPrices: [],
      searchText: ""
    };
  },

  created() {
    this.setDestroyFlag(false);
    const refs = [];

    refs.push(this.getPriceList());
    if (_.isEmpty(this.roofTypeList)) {
      refs.push(this.getRoofTypeList());
    }

    if (_.isEmpty(this.typeList)) {
      refs.push(this.getConstantTypeList());
    }

    this.$f7.preloader.show();
    Promise.all(refs).finally(() => {
      this.setDestroyFlag(true);
      this.$f7.preloader.hide();
    });
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.setPriceListDetailNumber("");
      this.resetPriceList();
      this.resetSearchHistory();
      this.resetSearchProduct("products-by-price-list");
      this.resetSearchProduct("products-library");
      this.resetConstant();
      this.resetCategory();
      this.resetSubCategory();
      this.resetVendor();
      this.resetRoofType();
    }
  },

  computed: {
    ...mapGetters("price-list/roof-type", ["roofTypeList"]),
    ...mapGetters("price-list/price-list-page/price-list", [
      "priceList",
      "priceListDetail"
    ]),
    ...mapGetters({
      destroyFlag: "price-list/price-list-page/common/destroyFlag"
    }),
    ...mapGetters("price-list/price-list-page/app-constant", [
      "priceListStatusByValue",
      "typeList"
    ]),
    ...mapGetters("common/user", ["userGroupList"]),

    getStatus() {
      return priceList => {
        if (
          moment(
            priceList && priceList.endDate && priceList.endDate.toDate()
          ).isBefore(moment(), "day")
        )
          return { value: "expired", displayName: "Expired", color: "red" };
        else if (
          moment(priceList && priceList.startDate.toDate()).isAfter(
            moment(),
            "day"
          )
        )
          return {
            value: "not-started",
            displayName: "Not Started",
            color: "blue"
          };
        return this.priceListStatusByValue(priceList.status) || {};
      };
    },

    dataPriceList() {
      let priceActive = this.priceList || [];
      priceActive = priceActive.map(x => ({
        ...x,
        startDateToString: !_.isEmpty(x.startDate)
          ? moment(x.startDate.toDate()).format("MM/DD/YYYY")
          : "",
        endDateToString: !_.isEmpty(x.endDate)
          ? moment(x.endDate.toDate()).format("MM/DD/YYYY")
          : "",
        createAtToString: !_.isEmpty(x.createdAt)
          ? moment(x.createdAt.toDate()).format("MM/DD/YYYY")
          : "",
        statusName: this.getStatus(x).displayName
      }));
      priceActive = applyFilter(priceActive, this.searchText, [
        "displayName",
        "statusName",
        "createdBy",
        "startDateToString",
        "endDateToString",
        "createAtToString",
        "priceListNumber"
      ]);
      priceActive = sortLatest(priceActive);
      return priceActive;
    }
  },

  methods: {
    ...mapActions("price-list/price-list-page/price-list", [
      "getPriceList",
      "deletePrice",
      "updatePrice",
      "resetPriceList"
    ]),
    ...mapActions("price-list/roof-type", ["getRoofTypeList", "resetRoofType"]),
    ...mapActions("price-list/price-list-page/app-constant", [
      "getConstantTypeList",
      "resetConstant"
    ]),
    ...mapActions("price-list/price-list-page/category", ["resetCategory"]),
    ...mapActions("price-list/price-list-page/sub-category", [
      "resetSubCategory"
    ]),
    ...mapActions("price-list/price-list-page/vendor", ["resetVendor"]),

    ...mapActions("common/user", ["checkGroupUsers"]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    ...mapActions({
      setDestroyFlag: "price-list/price-list-page/common/setDestroyFlag",
      resetSearchHistory:
        "price-list/price-list-page/price-list-history/resetSearch",
      resetSearchProduct: "price-list/price-list-page/product-items/resetSearch"
    }),

    ...mapMutations("price-list/price-list-page/price-list", [
      "setPriceListDetailNumber"
    ]),

    openConfirmDelete(item) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete Price List",
        content: "Are you sure you want to delete the price list?",
        textButton: "Delete",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            await app.checkGroupUsers("product-main/price-list");
            app.deletePrice(item.id).then(async () => {
              const arrayOfUids = app.userGroupList.map(
                userGroup => userGroup.uid
              );
              await app.createNotificationByType({
                data: {
                  assignees: arrayOfUids,
                  project: {
                    title: item.displayName,
                    id: item.id,
                    entityName: COLLECTION_PRICE_LIST,
                    priceListNumber: item.priceListNumber
                  }
                },
                type: "remove-price-list"
              });
              _sefl.app.dialog.close();
              app.$f7router.navigate("/price-list", {
                reloadAll: true
              });
            });
          }
        }
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  }
};
</script>
<style scoped>
.number-of-line {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.font-text {
  font-size: 11px;
}
</style>
