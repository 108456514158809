<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Labor Cost By Project</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="exportExcel()">Export Excel</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div class="display-flex flex-direction-column" style="height: 100%">
      <f7-card>
        <f7-card-content>
          <f7-row>
            <f7-col
              class="margin-vertical-half"
              medium="50"
              large="33"
              width="100"
            >
              <div class="display-flex align-items-center">
                <label
                  v-show="$f7.device.desktop || $f7.device.ipad"
                  class="margin-right"
                  >Project Name:</label
                >
                <f7-list style="flex: 1" class="border-shadow btn-container">
                  <f7-list-item link @click.native="openSelectProject">
                    <div slot="title">
                      {{
                        this.projectId
                          ? projectById(this.projectId).title
                          : "Select project"
                      }}
                    </div>
                  </f7-list-item>
                </f7-list>
              </div>
            </f7-col>
            <f7-col
              class="margin-vertical-half"
              medium="50"
              large="33"
              width="100"
            >
              <div class="display-flex align-items-center">
                <label
                  v-show="$f7.device.desktop || $f7.device.ipad"
                  class="margin-right"
                  >View:</label
                >
                <f7-input
                  class="border-shadow btn-container custom-select"
                  style="flex: 1"
                  type="select"
                  placeholder="Select view"
                  :value="view"
                  @change="onChangeView($event.target.value)"
                >
                  <option
                    v-for="(view, index) in VIEW_LIST"
                    :key="index"
                    :value="view.value"
                    >{{ view.title }}</option
                  >
                </f7-input>
              </div>
            </f7-col>
            <f7-col
              class="margin-vertical-half"
              medium="50"
              large="33"
              width="100"
            >
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>

      <template v-if="projectId && view">
        <f7-row v-if="$device.desktop" no-gap class="row-content">
          <template v-if="reportData.length > 0">
            <f7-col class="left-content scroll-content" width="20">
              <data-table
                :headers="headers"
                :items="displayReport || []"
                :pageSize="displayReport.length"
                class="wrap-text-table"
              >
                <!-- Table -->
                <template
                  v-if="displayReport.length > 1"
                  v-slot:body="{ item }"
                >
                  <template v-if="!item.footer">
                    <td class="label-cell">
                      <a @click="goToDetail(item.start)">
                        {{ item.title }}
                      </a>
                    </td>
                    <td class="numeric-cell">
                      {{ item.totalAmount || 0 | currencyUSD }}
                    </td>
                  </template>
                  <template v-else>
                    <td :colspan="headers.length - 1">
                      <b>Total</b>
                    </td>
                    <td class="numeric-cell">
                      <b>{{ item.totalAmount | currencyUSD }}</b>
                    </td>
                  </template>
                </template>
                <template v-else v-slot:body="{}">
                  <td class="text-align-center" :colspan="headers.length">
                    No Data!
                  </td>
                </template>
              </data-table>
            </f7-col>
            <f7-col class="scroll-content" width="80">
              <f7-card>
                <f7-card-content>
                  <bar-chart
                    :chartData="chartData"
                    :options="chartOptions"
                  ></bar-chart>
                </f7-card-content>
              </f7-card>
            </f7-col>
          </template>
          <template v-else>
            <f7-col>
              <f7-block strong class="text-align-center">
                No Data!
              </f7-block>
            </f7-col>
          </template>
        </f7-row>

        <div v-if="!$device.desktop">
          <bar-chart
            v-if="reportData.length > 0"
            :chartData="chartData"
            :options="chartOptions"
          ></bar-chart>
          <data-table
            :headers="headers"
            :items="displayReport || []"
            :pageSize="displayReport.length"
            class="wrap-text-table"
          >
            <template v-if="displayReport.length > 1" v-slot:body="{ item }">
              <template v-if="!item.footer">
                <td class="label-cell">
                  <a @click="goToDetail(item.start)">
                    {{ item.title }}
                  </a>
                </td>
                <td class="numeric-cell">
                  {{ item.totalAmount || 0 | currencyUSD }}
                </td>
              </template>
              <template v-else>
                <td :colspan="headers.length - 1">
                  <b>Total</b>
                </td>
                <td class="numeric-cell">
                  <b>{{ item.totalAmount | currencyUSD }}</b>
                </td>
              </template>
            </template>
            <template v-else v-slot:body="{}">
              <td class="text-align-center" :colspan="headers.length">
                No Data!
              </td>
            </template>
          </data-table>
        </div>
      </template>
      <f7-block v-else strong class="text-align-center">
        Please select Project Name first!
      </f7-block>
    </div>
    <!-- popup -->
    <project-list-popup
      ref="selectProject"
      @onSelected="onChangeProject($event)"
    ></project-list-popup>
  </f7-page>
</template>
<script>
import DataTable from "@/components/datatables";
import ProjectListPopup from "@/components/popups/ProjectListPopup.vue";
import { mapActions, mapGetters } from "vuex";
import { toDisplayDateString } from "@/utility/datetime";
import exportExcel from "../exports/labor-cost-for-project-excel-export";
import moment from "moment";
import { VIEW_LIST } from "../utility/const";
import reportService from "../../../services/report.service";
import _ from "lodash";
import { auth } from "../../../services/firebase.service";
import BarChart from "../components/charts/BarChart.js";
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    DataTable,
    ProjectListPopup,
    BarChart,
    Menu
  },

  data() {
    return {
      VIEW_LIST,
      projectId: null,
      view: "weekly",
      reportData: []
    };
  },

  computed: {
    ...mapGetters("reports/project", ["projectById", "projectList"]),

    headers() {
      let headers = [
        {
          text: `${this.view === "weekly" ? "Week" : "Month"}`,
          align: "left",
          type: "text",
          value: "week"
        },
        {
          text: "Amount",
          align: "right",
          type: "number",
          value: "amount"
        }
      ];
      return headers;
    },

    chartData() {
      return {
        labels: (this.reportData || []).map(r => r.title),
        datasets: [
          {
            label: "Labor Cost ($)",
            backgroundColor: "#f87979",
            data: (this.reportData || []).map(r => r.totalAmount)
          }
        ]
      };
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false
      };
    },

    total() {
      const list = this.reportData.map(r => r.totalAmount) || [];
      return this.sumArray(list);
    },

    displayReport() {
      const list = [...this.reportData];
      list.push({
        footer: true,
        title: "Total",
        totalAmount: this.total
      });
      return list;
    }
  },

  methods: {
    ...mapActions("reports/project", ["bindProjectList"]),
    ...mapActions("reports/time-tracking", ["setIsShowBackBtn"]),

    sumArray(arr) {
      let res = 0;
      if (!_.isEmpty(arr)) {
        res = arr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
      }
      return res;
    },

    goToDetail(startDate) {
      // set state is navigate
      this.setIsShowBackBtn(true);
      this.$f7router.navigate(
        `/report/labor-cost-analysis?startDate=${moment(
          startDate,
          "MM/DD/YYYY"
        ).format("MM/DD/YYYY")}&projectId=${this.projectId}&view=${this.view}`,
        { pushState: true }
      );
    },

    getData() {
      this.$f7.preloader.show();
      this.$f7router.updateCurrentUrl(
        `/report/labor-cost-by-project?projectId=${this.projectId}&view=${this.view}`
      );
      reportService
        .getLaborCostOfProject(auth.currentUser.tenantId, {
          projectId: this.projectId,
          view: this.view
        })
        .then(res => {
          this.reportData = res;
          this.$f7.preloader.hide();
        });
    },

    onChangeProject(id) {
      this.projectId = id;
      this.getData();
    },

    onChangeView(value) {
      this.view = value;
      this.getData();
    },

    openSelectProject() {
      this.$refs.selectProject.open();
    },

    exportExcel() {
      exportExcel({
        projectName: this.projectId
          ? this.projectById(this.projectId).title
          : "No Data!",
        headers: this.headers,
        values: this.displayReport
      });
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    }
  },

  created() {
    this.bindProjectList();
  },

  mounted() {
    if (this.$f7route.query.view) {
      this.view = this.$f7route.query.view;
    }
    if (this.$f7route.query.projectId) {
      this.projectId = this.$f7route.query.projectId;
      this.getData(this.$f7route.query.projectId, this.view);
    }
  }
};
</script>
<style lang="scss" scoped>
.border-shadow {
  border-radius: 4px;
  box-shadow: 0 1px 3px #b7b7b7;
  box-sizing: border-box;
}
.btn-container {
  min-width: 250px;
  min-height: 32px;
  display: flex;
  align-items: center;

  &::v-deep.list ul {
    width: 100%;
  }
}
.custom-select ::v-deep select {
  padding-left: 16px;
}
.row-content {
  flex: 1;
  overflow: auto;
}
.scroll-content {
  height: 100%;
  overflow-y: auto;
}
.left-content {
  border-right: 1px solid #d3d3d3;
}
</style>
