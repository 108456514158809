<template>
  <div
    :class="[noStyle ? 'text-align-start' : 'cell-input', { isDark: isDark }]"
  >
    <input
      ref="cellInput"
      :style="
        stepper === 'true' ? 'width:100px; margin-top:2px;' : 'width:130px'
      "
      :class="{ 'error-input': errorMessage }"
      type="text"
      :readonly="readonly"
      :value="currentValue"
      :placeholder="!editing ? '0' : placeholder"
      @input="input($event.target.value)"
      @blur="blur($event.target.value)"
      @keyup.enter="enter()"
      @click="enableEditing"
      @focus="onFocus"
      @mouseleave="onMouseleave"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    placeholder: String,
    value: [Number, String],
    fractionDigits: { type: Number, default: 2 },
    numberType: { type: String, default: "decimal" }, // decimal, currency, percent
    readonly: { type: Boolean, default: false },
    suffixes: { type: String, default: "" },
    allowNegativeNumber: { type: Boolean, default: true },
    errorMessage: { type: String, default: "" },
    noStyle: { type: Boolean, default: false },
    stepper: { type: String, default: "false" },
    isDark: { type: Boolean, default: false }
  },

  data() {
    return { editing: false, tempValue: "" };
  },

  computed: {
    currentValue() {
      if (this.editing === true) {
        return this.value === 0 || !this.value ? "" : this.value;
      } else {
        return this.value
          ? this.formatNumber(this.value)
          : this.formatNumber(0);
      }
    }
  },

  methods: {
    onMouseleave() {
      if (this.editing) {
        this.$refs.cellInput.blur();
        this.tempValue = "";
      }
    },

    onFocus(event) {
      if (!this.readonly) {
        this.tempValue = (this.value || "").toString();
        event.target.select();
      }
      this.$emit("onFocus");
    },

    input(value) {
      value = value.replace(/,/g, "");
      this.tempValue = value;
      this.$emit(
        "input",
        this.allowNegativeNumber
          ? parseFloat(value)
          : parseFloat(Math.abs(value))
      );
    },

    enter() {
      if (!this.readonly) {
        this.$refs.cellInput.blur();
      }
    },

    enableEditing() {
      if (!this.readonly) {
        this.editing = true;
      }
    },

    blur() {
      if (!this.readonly) {
        this.tempValue = this.tempValue.replace(/,/g, "");
        this.tempValue = this.tempValue.replace("$", "");
        this.tempValue = this.tempValue.replace("%", "");

        this.$emit(
          "done",
          this.allowNegativeNumber
            ? _.ceil(parseFloat(this.tempValue), this.fractionDigits)
            : _.ceil(parseFloat(Math.abs(this.tempValue)), this.fractionDigits)
        );
        this.editing = false;
      }
    },

    formatNumber(value) {
      if (typeof value === "number") {
        switch (this.numberType) {
          case "decimal":
            return `${value.toLocaleString("en-US", {
              style: "decimal",
              minimumFractionDigits: this.fractionDigits,
              maximumFractionDigits: this.fractionDigits
            })} ${this.suffixes}`.trim();

          case "int":
            return `${value.toLocaleString("en-US", {
              style: "decimal"
            })}`.trim();

          case "currency":
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: this.fractionDigits,
              maximumFractionDigits: this.fractionDigits
            });

          case "percent":
            return (
              value.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: this.fractionDigits,
                maximumFractionDigits: this.fractionDigits
              }) + "%"
            );
        }
      } else {
        return value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cell-input ::v-deep input:read-only {
  border: none !important;
  border-radius: none !important;
}

.cell-input ::v-deep .input-focused {
  background-color: var(--f7-page-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cell-input ::v-deep input:hover {
  background-color: var(--f7-page-bg-color);
}

.cell-input ::v-deep input {
  text-align: right;
  float: right;
  width: 100px;
}
.cell-input ::v-deep .error-input {
  color: red;
  border-bottom: 1px solid red;
}
.text-align-start {
  text-align: start;
}

.cell-input.isDark ::v-deep .input-focused {
  background-color: #f7f7f8;
}

.cell-input.isDark ::v-deep input:hover {
  background-color: #f7f7f8;
}
</style>
