<template>
  <div>
    <!-- tabs -->
    <f7-block class="tab-block">
      <f7-segmented strong tag="p">
        <f7-button
          class="custom-hover"
          :active="activeTabId === 'preFabTab'"
          @click="activeTabId = 'preFabTab'"
          >PreFab Metal</f7-button
        >
        <f7-button
          class="custom-hover"
          :active="activeTabId === 'customTab'"
          @click="activeTabId = 'customTab'"
          >Custom Metal</f7-button
        >
      </f7-segmented>
    </f7-block>
    <div v-show="activeTabId === 'preFabTab'">
      <section-product-table
        :items="tableData(SECTION_TYPE_STANDING_SEAM_PREFAB_METAL)"
        :roofType="ROOF_TYPE_STANDING_SEAM"
        :sectionId="SECTION_TYPE_STANDING_SEAM_PREFAB_METAL"
        @onChangeProductItem="
          onChangeProductItem(
            ROOF_TYPE_STANDING_SEAM,
            SECTION_TYPE_STANDING_SEAM_PREFAB_METAL,
            $event
          )
        "
      ></section-product-table>
    </div>
    <div v-show="activeTabId === 'customTab'">
      <f7-block>
        <div class="container-metal">
          <span>Metal</span>
          <f7-button fill @click="addAssemblyList()">Add Metal Work</f7-button>
        </div>
      </f7-block>
      <summary-metal-table
        ref="summaryMetalTable"
        :items="currentBuilding.assemblyList || []"
        :roofType="ROOF_TYPE_STANDING_SEAM"
        :sectionId="SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE"
        @changeAssemblyProp="changeAssemblyProp"
        @changeTemplate="
          changeTemplate({
            index: $event.target.index,
            value: $event.target.value,
            roofType: ROOF_TYPE_STANDING_SEAM,
            sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE
          }).then(() => {
            if ($event.callback) return $event.callback();
          })
        "
        @removeAssembly="removeAssembly"
        @openEditPopup="openEditPopup"
        @setAssemblyIndex="setAssemblyIndex"
        @onAddAssemblyList="handleAddAssemblyList"
        @openPreviewMetalDrawingPopup="openPreviewMetalDrawingPopup"
      ></summary-metal-table>

      <!-- canvas is hidden -->
      <component
        v-if="isRenderCanvasHidden"
        hidden
        :is="assemblyDetail.canvasComponent"
        :ref="`${assemblyIndex}_${assemblyDetail.assemblyItem}`"
        :data="assemblyDetail.measurement || {}"
        :buildingId="
          `${currentBuilding.id}_${assemblyIndex}_${assemblyDetail.assemblyItem}`
        "
        :isDark="false"
      ></component>

      <!-- preview metal drawing popup -->
      <f7-popup
        v-if="isPopupShowMetalDrawing"
        :opened="isPopupShowMetalDrawing"
        @popup:closed="onPreviewMetalDrawingPopupClosed"
        @popup:opened="onEditPopupOpened"
        class="preview-popup"
      >
        <f7-page style="position: relative">
          <f7-navbar
            :title="
              `${assemblyDetail.drawingNumber} - ${assemblyDetail.drawingName}`
            "
          >
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-row class="margin">
            <f7-col width="100" style="position: relative">
              <!-- diagram -->
              <component
                :is="assemblyDetail.canvasComponent"
                :ref="`${assemblyIndex}_${assemblyDetail.assemblyItem}`"
                :data="assemblyDetail.measurement || {}"
                :buildingId="
                  `${currentBuilding.id}_${assemblyIndex}_${assemblyDetail.assemblyItem}`
                "
                :items="productsAreAutoGenerate(assemblyDetail.productList)"
                @setFields="
                  setAssemblyMeasurement(
                    assemblyIndex,
                    $event.name,
                    $event.value,
                    $event.callback
                  )
                "
                :photoSelected="photoSelected"
                @setPhotoSelected="setPhotoSelected"
              ></component>
              <component
                class="hidden"
                :is="assemblyDetail.canvasComponent"
                :ref="`${assemblyIndex}_${assemblyDetail.assemblyItem}_hidden`"
                :data="assemblyDetail.measurement || {}"
                :buildingId="
                  `${currentBuilding.id}_${assemblyIndex}_${assemblyDetail.assemblyItem}_hidden`
                "
                :items="productsAreAutoGenerate(assemblyDetail.productList)"
                @setFields="
                  setAssemblyMeasurement(
                    assemblyIndex,
                    $event.name,
                    $event.value,
                    $event.callback
                  )
                "
                :photoSelected="photoSelected"
                @setPhotoSelected="setPhotoSelected"
              ></component>
              <!-- zoom in/out -->
              <f7-segmented
                v-show="assemblyDetail.canvasComponent"
                raised
                tag="p"
                class="btn-zoom"
              >
                <f7-button
                  @click="
                    handleZoom(
                      'in',
                      `${assemblyIndex}_${assemblyDetail.assemblyItem}`
                    )
                  "
                  >+</f7-button
                >
                <f7-button
                  @click="
                    handleZoom(
                      'out',
                      `${assemblyIndex}_${assemblyDetail.assemblyItem}`
                    )
                  "
                  >-</f7-button
                >
              </f7-segmented>
            </f7-col>
          </f7-row>
          <f7-toolbar
            class="toobar-custom"
            v-if="this.currentBuilding.assemblyList.length > 1"
          >
            <f7-link
              icon-f7="chevron_left"
              @click="handleChangeMetalDrawing(false)"
            ></f7-link>
            <f7-link
              icon-f7="chevron_right"
              @click="handleChangeMetalDrawing(true)"
            ></f7-link>
          </f7-toolbar>
        </f7-page>
      </f7-popup>

      <!-- edit assembly popup -->
      <f7-popup
        v-if="editPopupOpened"
        tablet-fullscreen
        :opened="editPopupOpened"
        @popup:closed="onEditPopupClosed"
        @popup:opened="onEditPopupOpened"
        ref="metalEditPopup"
      >
        <f7-page>
          <f7-navbar
            :title="
              `${assemblyDetail.drawingNumber} - ${assemblyDetail.drawingName}`
            "
          >
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-row class="margin">
            <f7-col
              width="100"
              :xsmall="$device.desktop ? '70' : '100'"
              style="position: relative"
              :hidden="!$device.desktop"
            >
              <component
                class="hidden"
                :is="assemblyDetail.canvasComponent"
                :ref="`${assemblyIndex}_${assemblyDetail.assemblyItem}_hidden`"
                :data="assemblyDetail.measurement || {}"
                :buildingId="
                  `${currentBuilding.id}_${assemblyIndex}_${assemblyDetail.assemblyItem}_hidden`
                "
                :isDark="false"
                :items="productsAreAutoGenerate(assemblyDetail.productList)"
                @setFields="
                  setAssemblyMeasurement(
                    assemblyIndex,
                    $event.name,
                    $event.value,
                    $event.callback
                  )
                "
                :photoSelected="photoSelected"
                @setPhotoSelected="setPhotoSelected"
              ></component>
              <!-- diagram -->
              <component
                :is="assemblyDetail.canvasComponent"
                :ref="`${assemblyIndex}_${assemblyDetail.assemblyItem}`"
                :data="assemblyDetail.measurement || {}"
                :buildingId="
                  `${currentBuilding.id}_${assemblyIndex}_${assemblyDetail.assemblyItem}`
                "
                :items="productsAreAutoGenerate(assemblyDetail.productList)"
                @setFields="
                  setAssemblyMeasurement(
                    assemblyIndex,
                    $event.name,
                    $event.value,
                    $event.callback
                  )
                "
                :photoSelected="photoSelected"
                @setPhotoSelected="setPhotoSelected"
              ></component>

              <!-- zoom in/out -->
              <f7-segmented
                v-show="assemblyDetail.canvasComponent"
                raised
                tag="p"
                class="btn-zoom"
              >
                <f7-button
                  @click="
                    handleZoom(
                      'in',
                      `${assemblyIndex}_${assemblyDetail.assemblyItem}`
                    )
                  "
                  >+</f7-button
                >
                <f7-button
                  @click="
                    handleZoom(
                      'out',
                      `${assemblyIndex}_${assemblyDetail.assemblyItem}`
                    )
                  "
                  >-</f7-button
                >
              </f7-segmented>
            </f7-col>
            <f7-col
              style="padding: 0 15px"
              width="100"
              :xsmall="$device.desktop ? '30' : '100'"
            >
              <!-- common form -->
              <common-measurement-form
                :assembly="assemblyDetail || {}"
                :index="assemblyIndex"
                :roofType="ROOF_TYPE_STANDING_SEAM"
                :canvasId="
                  `${currentBuilding.id}_${assemblyIndex}_${assemblyDetail.assemblyItem}_hidden`
                "
                @changeAssemblyProp="changeAssemblyProp"
                @changeTemplate="
                  changeTemplate({
                    index: $event.target.index,
                    value: $event.target.value,
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE
                  }).then(() => {
                    if ($event.callback) return $event.callback();
                  })
                "
                @removeAssembly="removeAssembly"
              >
              </common-measurement-form>
              <!-- Measurement title -->
              <f7-row class="row-action">
                <f7-col
                  ><f7-row class="justify-content-flex-start"
                    ><f7-block-title>Measurement</f7-block-title></f7-row
                  ></f7-col
                >
                <f7-col
                  ><f7-row class="justify-content-flex-end"
                    ><f7-button
                      v-if="!$device.desktop && assemblyDetail.assemblyItem"
                      outline
                      style="margin-top: 6px; margin-right: 10px;"
                      @click="openAssemblyPopup(assemblyDetail)"
                      >View Diagram</f7-button
                    ></f7-row
                  ></f7-col
                >
              </f7-row>

              <!-- <f7-block-title>Measurement</f7-block-title> -->
              <component
                :is="assemblyDetail.formComponent"
                :data="assemblyDetail.measurement || {}"
                @setFields="
                  setAssemblyMeasurement(
                    assemblyIndex,
                    $event.name,
                    $event.value,
                    $event.callback
                  ).then(res => {
                    if (!res) return;
                    if ($event.name.match(/[a-z]1/)) {
                      checkMetalStretchOut(checkingStretchOutAssembly);
                    } else if (
                      $event.name.match(/[a-z]2/) ||
                      $event.name.match(/[a-z]3/)
                    ) {
                      checkCleatStretchOut(checkingStretchOutAssembly);
                    } else {
                      checkMetalStretchOut(checkingStretchOutAssembly);
                    }
                  })
                "
                :photoSelected="photoSelected"
                @setPhotoSelected="setPhotoSelected"
              ></component>

              <f7-row v-if="$device.desktop">
                <f7-col width="100" xsmall="50">
                  <f7-button fill @click="removeAssembly(assemblyIndex)"
                    >Remove</f7-button
                  >
                </f7-col>
                <f7-col width="100" xsmall="50"></f7-col>
              </f7-row>
            </f7-col>
          </f7-row>

          <f7-row
            ><f7-col>
              <!-- products -->
              <metal-product-table
                :assembly="assemblyDetail"
                :items="assemblyDetail.productList || []"
                :roofType="ROOF_TYPE_STANDING_SEAM"
                :sectionId="SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE"
                @onChangeProductItem="
                  onChangeMetalProductItem(assemblyIndex, $event)
                "
                @onChangeProductItemProp="
                  onChangeProductItemProp(assemblyIndex, $event)
                "
                @onSaveEstimateProduct="
                  handleSaveEstimateProduct(assemblyIndex, $event)
                "
              ></metal-product-table></f7-col
          ></f7-row>
        </f7-page>
      </f7-popup>

      <!-- view assembly on mobile -->
      <f7-popup
        :opened="popupOpened"
        @popup:closed="onPopupClosed"
        @popup:opened="onPopupOpened"
      >
        <f7-page>
          <f7-navbar
            :title="
              popupAssembly.assemblyName ||
                assemblyById(popupAssembly.assemblyItem).displayName
            "
          >
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <div style="position: relative">
            <component
              :is="popupAssembly.canvasComponent"
              :ref="`${popupAssembly.assemblyItem}`"
              :data="popupAssembly.measurement || {}"
              :buildingId="
                `${currentBuilding.id}_${popupAssembly.assemblyItem}`
              "
              :items="productsAreAutoGenerate(popupAssembly.productList)"
            ></component>
            <!-- zoom in/out -->
            <f7-segmented raised tag="p" class="btn-zoom" style="top: 0">
              <f7-button
                @click="handleZoom('in', `${popupAssembly.assemblyItem}`)"
                >+</f7-button
              >
              <f7-button
                @click="handleZoom('out', `${popupAssembly.assemblyItem}`)"
                >-</f7-button
              >
            </f7-segmented>
          </div>
        </f7-page>
      </f7-popup>
    </div>

    <f7-fab
      right-bottom
      style="bottom: 30px"
      v-if="!$device.desktop && activeTabId === 'customTab'"
      text="Metal Work"
      @click="addAssemblyList()"
    >
      <f7-icon ios="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
  </div>
</template>

<script>
import CopingWithCleatFastener from "../canvas/CopingWithCleatFastener.vue";
import CopingWithCleatFastenerForm from "../canvas/forms/CopingWithCleatFastenerForm.vue";
import CopingWithCleat from "../canvas/CopingWithCleat.vue";
import CopingWithCleatForm from "../canvas/forms/CopingWithCleatForm.vue";
import MetalProductTable from "../tables/MetalProductTable.vue";
import CopingWithFrontBackCleat from "../canvas/CopingWithFrontBackCleat.vue";
import CopingWithFrontBackCleatForm from "../canvas/forms/CopingWithFrontBackCleatForm.vue";
// import TableProduct from "../../tables/tpo/TableProducts.vue";
import CommonMeasurementForm from "../forms/CommonMeasurementForm.vue";
import Embedded from "../canvas/Embedded.vue";
import EmbeddedForm from "../canvas/forms/EmbeddedForm.vue";
import CollectorHead from "../canvas/CollectorHead.vue";
import CollectorHeadForm from "../canvas/forms/CollectorHeadForm.vue";
import DownspoutTransition from "../canvas/DownspoutTransition.vue";
import DownspoutTransitionForm from "../canvas/forms/DownspoutTransitionForm.vue";
import BoxDownspout from "../canvas/BoxDownspout.vue";
import BoxDownspoutForm from "../canvas/forms/BoxDownspoutForm.vue";
import GutterSystem from "../canvas/GutterSystem.vue";
import GutterSystemForm from "../canvas/forms/GutterSystemForm.vue";
import HeadwallFlashing from "../canvas/HeadwallFlashing.vue";
import HeadwallFlashingForm from "../canvas/forms/HeadwallFlashingForm.vue";
import PitchPan from "../canvas/PitchPan.vue";
import PitchPanForm from "../canvas/forms/PitchPanForm.vue";
import CounterFlashing from "../canvas/CounterFlashing.vue";
import CounterFlashingForm from "../canvas/forms/CounterFlashingForm.vue";

// Standing Seam
import Valley from "../canvas/Valley.vue";
import ValleyForm from "../canvas/forms/ValleyForm.vue";
import SidewallOrHeadwall from "../canvas/SidewallOrHeadwall.vue";
import SidewallOrHeadwallForm from "../canvas/forms/SidewallOrHeadwallForm.vue";
import Ridge from "../canvas/Ridge.vue";
import RidgeForm from "../canvas/forms/RidgeForm.vue";
import ZeeTrim from "../canvas/ZeeTrim.vue";
import ZeeTrimForm from "../canvas/forms/ZeeTrimForm.vue";
import ExtendedEaveOrRake from "../canvas/ExtendedEaveOrRake.vue";
import ExtendedEaveOrRakeForm from "../canvas/forms/ExtendedEaveOrRakeForm.vue";

import SectionProductTable from "../tables/SectionProductTable.vue";
import SummaryMetalTable from "../tables/SummaryMetalTable.vue";

import {
  ROOF_TYPE_STANDING_SEAM,
  SECTION_TYPE_STANDING_SEAM_PREFAB_METAL,
  SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE
} from "../../../../utility/const";
import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";

import { isValidExpresstion } from "../../utility/stretch-out-helper";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    CopingWithCleatFastener,
    CopingWithCleatFastenerForm,
    CopingWithCleat,
    CopingWithCleatForm,
    MetalProductTable,
    CopingWithFrontBackCleat,
    CopingWithFrontBackCleatForm,
    // TableProduct,
    Embedded,
    CommonMeasurementForm,
    EmbeddedForm,
    CollectorHead,
    CollectorHeadForm,
    DownspoutTransition,
    DownspoutTransitionForm,
    BoxDownspout,
    BoxDownspoutForm,
    GutterSystem,
    GutterSystemForm,
    HeadwallFlashing,
    HeadwallFlashingForm,
    PitchPan,
    PitchPanForm,
    CounterFlashing,
    CounterFlashingForm,
    Valley, //Standing Seam
    ValleyForm, //Standing Seam
    SidewallOrHeadwall, //Standing Seam
    SidewallOrHeadwallForm, //Standing Seam
    Ridge, //Standing Seam
    RidgeForm, //Standing Seam
    ZeeTrim, //Standing Seam
    ZeeTrimForm, //Standing Seam
    ExtendedEaveOrRake, //Standing Seam
    ExtendedEaveOrRakeForm, //Standing Seam

    SectionProductTable,
    SummaryMetalTable
  },

  mixins: [mixins, buildingMixins],

  data: () => ({
    selectedColors: null,
    selectedManufacturers: null,
    selectedFasteningSubstrates: null,
    ROOF_TYPE_STANDING_SEAM,
    SECTION_TYPE_STANDING_SEAM_PREFAB_METAL,
    SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,

    assemblyItem: "",
    photoSelected: "",

    popupOpened: false,
    popupAssembly: {},

    activeTabId: "preFabTab",
    checkingStretchOutAssembly: {},

    editPopupOpened: false,
    assemblyIndex: null,

    isPopupShowMetalDrawing: false
  }),

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "typeSwitchScreen"
    ]),
    ...mapGetters("estimate/estimate-page/product-item", [
      "productColors",
      "productManufacturers"
    ]),

    ...mapGetters("estimate/estimate-page/assembly-drawing", {
      assemblyById: "assemblyDrawingById"
    }),
    assemblyDetail() {
      return (
        (this.currentBuilding.assemblyList || [])[this.assemblyIndex] || {}
      );
    },

    isRenderCanvasHidden() {
      if (this.isPopupShowMetalDrawing) {
        return false;
      }
      if (this.editPopupOpened) {
        return false;
      }
      return true;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "calculateMetaProductQty",
      "setCurrentBuildingValue"
    ]),

    init() {
      //   if ((this.currentBuilding.assemblyList || []).length < 1) return;
      //   this.drawAssemply();
    },

    validate() {},

    reset() {
      this.$refs.childCanvas.reset();
    },

    setAssemblyMeasurement(index, name, value, callback) {
      if (_.isEmpty(name) || _.isEmpty(value) || !isValidExpresstion(value)) {
        return new Promise(res => {
          res(false);
        });
      }
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      assemblyList[index].measurement[name] = value;

      this.updateAssemblyStreatchOut(assemblyList[index]);

      // this.saveBuildingValue("assemblyList", assemblyList).then(() => {
      //   this.drawAssemply();
      // });

      return this.updateMetalProducts({
        roofType: ROOF_TYPE_STANDING_SEAM,
        sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
        assembly: assemblyList[index]
      })
        .then(assembly => {
          this.checkingStretchOutAssembly = assembly;
          return this.saveBuildingValue("assemblyList", assemblyList);
        })
        .then(() => {
          this.drawEditPopupAssemply(assemblyList[index], index);
          this.saveDiagramUrl(index);
          return this.addMetalProductToSectionProducts({
            roofType: ROOF_TYPE_STANDING_SEAM,
            sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
            assemblyList
          });
        })
        .then(() => {
          if (callback) return callback(name, value);
        });
    },

    setPhotoSelected(value) {
      this.photoSelected = value;
    },

    changeAssemblyProp(index, assemblyName, value, cb) {
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      assemblyList[index][assemblyName] = value;

      return this.updateMetalProducts({
        roofType: ROOF_TYPE_STANDING_SEAM,
        sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
        assembly: assemblyList[index]
      })
        .then(() => {
          return this.saveBuildingValue("assemblyList", assemblyList);
        })
        .then(() => {
          return this.addMetalProductToSectionProducts({
            roofType: ROOF_TYPE_STANDING_SEAM,
            sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
            assemblyList
          });
        })
        .then(() => {
          this.drawEditPopupAssemply(assemblyList[index], index);
          if (cb) return cb();
        });
    },

    /**
     * DEV-1230: Est-On the metal section, instead of individually adding each item, can you maybe put a check on each item to select the 4 items that I need and then I can go in and edit the information? (David requried Feb 15)
     */
    async handleAddAssemblyList({ assemblyList, callback }) {
      const orgAssemblyList = this.currentBuilding.assemblyList;
      let orgLength = orgAssemblyList.length;

      for (let index = 0; index < assemblyList.length; index++) {
        const temp = _.cloneDeep(this.currentBuilding.assemblyList);
        temp.push({});
        await this.setCurrentBuildingValue({
          prop: "assemblyList",
          value: temp
        });

        this.setAssemblyIndex(orgLength + index);
        const assembly = assemblyList[index];
        await this.changeTemplate({
          index: orgLength + index,
          value: assembly,
          roofType: ROOF_TYPE_STANDING_SEAM,
          sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE
        });
      }

      if (typeof callback === "function") return callback();
    },

    addAssemblyList() {
      if (!_.isEmpty(this.$refs.summaryMetalTable)) {
        this.$refs.summaryMetalTable.openMetalListPopup();
      }

      return;
    },

    removeAssembly(index) {
      this.$f7.preloader.show();

      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      assemblyList.splice(index, 1);

      this.saveBuildingValue("assemblyList", assemblyList)
        .then(() => {
          return this.addMetalProductToSectionProducts({
            roofType: ROOF_TYPE_STANDING_SEAM,
            sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
            assemblyList
          });
        })
        .finally(() => {
          const metalEditPopup = this.$refs.metalEditPopup;
          if (metalEditPopup && metalEditPopup.opened) {
            this.onEditPopupClosed();
            metalEditPopup.close();
          }
          this.$f7.preloader.hide();
        });
    },

    onChangeMetalProductItem(index, event) {
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      const assembly = assemblyList[index];
      this.changeMetalProductItem(assembly, event).then(assembly => {
        this.checkMetalStretchOut(assembly);
        this.checkCleatStretchOut(assembly);
        assemblyList[index] = assembly;
        return this.saveBuildingValue("assemblyList", assemblyList).then(() => {
          this.drawEditPopupAssemply(assemblyList[index], index);
          return this.addMetalProductToSectionProducts({
            roofType: ROOF_TYPE_STANDING_SEAM,
            sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
            assemblyList
          });
        });
      });
    },
    onChangeProductItemProp(index, event) {
      const { propName, value, productId, cb, productIndex } = event;
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      const assembly = assemblyList[index];
      const productItem =
        assembly.productList.find(
          (item, pIndex) => item.id === productId && pIndex === productIndex
        ) || {};

      if (_.isEmpty(productItem)) {
        return;
      }

      productItem[propName] = value;
      if (propName === "price") {
        let vendorPrice = 0;
        const markup = _.cloneDeep(productItem.markup) || {
          value: 0,
          type: "percent"
        };
        if (markup.type === "percent") {
          vendorPrice = (100 * value) / (100 + markup.value);
        } else {
          vendorPrice = value - markup.value;
        }
        vendorPrice = parseFloat(vendorPrice.toFixed(2));
        productItem.vendorPrice = vendorPrice;
      }

      if (propName === "per100lf" || propName === "wasterFactor") {
        return this.calculateMetaProductQty({
          assembly,
          building: this.currentBuilding
        })
          .then(calculatedAssembly => {
            assemblyList[index] = calculatedAssembly;
            return this.saveBuildingValue("assemblyList", assemblyList);
          })
          .then(() => {
            return this.addMetalProductToSectionProducts({
              roofType: ROOF_TYPE_STANDING_SEAM,
              sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
              assemblyList
            });
          })
          .then(() => {
            if (cb) {
              cb();
            }
          });
      } else {
        return this.saveBuildingValue("assemblyList", assemblyList)
          .then(() => {
            return this.addMetalProductToSectionProducts({
              roofType: ROOF_TYPE_STANDING_SEAM,
              sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
              assemblyList
            });
          })
          .then(() => {
            if (cb) {
              cb();
            }
          });
      }
    },

    handleSaveEstimateProduct(index, { assembly, callBack }) {
      const self = this;
      const assemblyList = _.cloneDeep(this.currentBuilding.assemblyList || []);
      assemblyList[index] = assembly;
      this.saveBuildingValue("assemblyList", assemblyList).then(() => {
        callBack(assemblyList);
        return self.addMetalProductToSectionProducts({
          roofType: ROOF_TYPE_STANDING_SEAM,
          sectionId: SECTION_TYPE_STANDING_SEAM_METAL_AND_EDGE,
          assemblyList
        });
      });
    },

    setAssemblyIndex(index) {
      this.assemblyIndex = index;
    },

    openEditPopup(index) {
      this.setAssemblyIndex(index);
      this.editPopupOpened = true;
    },

    onEditPopupClosed() {
      this.setAssemblyIndex(null);
      this.editPopupOpened = false;
    },

    onEditPopupOpened() {
      this.drawEditPopupAssemply(this.assemblyDetail, this.assemblyIndex);
    },

    openAssemblyPopup(assembly) {
      this.popupAssembly = _.cloneDeep(assembly);
      this.popupOpened = true;
    },

    onPopupClosed() {
      this.popupOpened = false;
      this.popupAssembly = {};
    },

    onPopupOpened() {
      this.drawPopupAssemply(this.popupAssembly);
      this.$refs[`${this.popupAssembly.assemblyItem}`].$refs[
        `${this.currentBuilding.id}_${this.popupAssembly.assemblyItem}`
      ].click();
    },

    handleZoom(type, ref) {
      // if (this.$device.desktop) this.$refs[ref][0].handleZoom(type);
      if (this.$device.desktop) this.$refs[ref].handleZoom(type);
      else this.$refs[ref].handleZoom(type);
    },

    productsAreAutoGenerate(productList) {
      return (productList || []).filter(r => !r.isAddManually);
    },

    openPreviewMetalDrawingPopup(index) {
      this.setAssemblyIndex(index);
      this.isPopupShowMetalDrawing = true;
    },

    onPreviewMetalDrawingPopupClosed() {
      this.setAssemblyIndex(null);
      this.isPopupShowMetalDrawing = false;
    },

    handleChangeMetalDrawing(isNext) {
      const minIndex = 0;
      const maxIndex = this.currentBuilding.assemblyList?.length - 1 || 0;

      let newIndex = this.assemblyIndex;
      if (isNext) {
        newIndex = this.assemblyIndex === maxIndex ? 0 : this.assemblyIndex + 1;
      } else {
        newIndex =
          this.assemblyIndex === minIndex ? maxIndex : this.assemblyIndex - 1;
      }

      this.setAssemblyIndex(newIndex);

      this.$nextTick(() => {
        this.onEditPopupOpened();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-hover:hover {
  background: var(--step-background-color) !important;
}
.page-content {
  padding-top: 10px;
  overflow: visible;
}
.tab-block {
  margin: 15px 5px 5px 5px;
}
.btn-zoom {
  position: absolute;
  width: 70px;
  top: 40px;
  right: 20px;

  .button {
    font-weight: bold;
    font-size: 18px;
  }
}
.hidden {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
.container-metal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.toobar-custom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.toobar-custom ::v-deep .toolbar-inner {
  justify-content: space-around;
  a {
    color: var(--f7-color-gray);
    &:hover {
      color: var(--f7-theme-color);
    }
  }
}
</style>
