import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "company"
  );

  return {
    bindCompanyListBys: companyActions.bindCollectionBys,
    bindCompanyList({ dispatch }) {
      return dispatch("bindCompanyListBys", [
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]);
    },
    unbindCompanyList: companyActions.unbindCollection,

    createCompany: companyActions.createDocument,
    updateCompany: companyActions.updateDocument,
    deleteCompany: companyActions.deleteDocument
  };
};
