<template>
  <f7-row class="header-editable">
    <f7-link class="acts-button" @click="$emit('add')">
      <f7-icon f7="plus"></f7-icon> Add new card
    </f7-link>
  </f7-row>
</template>
<script>
export default {};
</script>
<style scoped>
.header-editable {
  padding: 0px 16px 12px 16px;
  align-items: center;
}
.header-editable-text {
  font-weight: bold;
}
.acts-button {
  border-radius: 4px;
  padding: 4px;
  border: 1px solid transparent;
}
.acts-button:hover {
  opacity: 0.7;
}
</style>
