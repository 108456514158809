import moment from "moment";
import { sortLatest } from "@/utility/date-time-tool";
import { groupBy } from "@/utility/filter-tools";

export default {
  projectPhotoList: state => sortLatest(state.projectPhotoList),
  projectPhotoListGroup: state => {
    return groupBy(sortLatest(state.projectPhotoList), i =>
      i.createdAt
        ? moment(i.createdAt.toDate()).format("dddd, MMMM Do YYYY")
        : ""
    );
  },
  projectPhotoSelected: state => selectedIds => {
    return state.projectPhotoList.filter(r => selectedIds.includes(r.id));
  },
  selectionMode: state => state.selectionMode,
  selectedPhotoIds: state => state.selectedPhotoIds,
  photo: state => state.projectPhoto
};
