<template>
  <f7-row>
    <f7-col :width="$device.desktop ? 70 : 100">
      <f7-list :class="{
        'list-inline-center': $device.desktop,
        'list-inline-center-mobile': !$device.desktop
      }" inset inline-labels>
        <f7-list-item>
          <div class="title">Owner's Address</div>
          <div class="content">
            {{ getFullAddress(project.projectAddress) }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Owner's Name</div>
          <div class="content">{{ project.contactName }}</div>
        </f7-list-item>
        <f7-list-item v-for="(phone, index) in (contact || {}).phones" :key="`phone-${index}`">
          <div class="title">Phone</div>
          <div class="content">{{ phone.value }}</div>
        </f7-list-item>
        <f7-list-item v-for="(email, index) in (contact || {}).emails" :key="`email-${index}`">
          <div class="title">Email</div>
          <div class="content">{{ email.value }}</div>
        </f7-list-item>
      </f7-list>
      <f7-list :class="{
        'list-inline-center': $device.desktop,
        'list-inline-center-mobile': !$device.desktop
      }" inset inline-labels>
        <f7-list-item>
          <div class="title">Inspector Name</div>
          <div class="content">{{ project.inspectorName }}</div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Inspect Date</div>
          <div class="content">
            {{ toDisplayDateString(project.inspectDate) }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Inspect Time</div>
          <div class="content">
            {{ project.inspectTime }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Date of Loss</div>
          <div class="content">
            {{ toDisplayDateString(project.dateOfLoss) }}
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list inset accordion-list>
        <f7-list-item accordion-item :accordion-item-opened="agreementList.length > 0" title="Restoration Agreement">
          <f7-accordion-content>
            <f7-list v-if="agreementList.length != 0" class="no-margin">
              <f7-list-item v-for="(item, index) in agreementList" :key="index">
                <f7-link style="white-space: break-spaces" @click.native="
                  $f7route.query.tenant
                    ? gotoViewAgreementDetail(item.id)
                    : onPreviewAgreement(item)
                  " slot="title">{{ item.title }}</f7-link>
                <div slot="footer">
                  {{ item.status === "draft" ? "Created" : "Sent" }} on
                  {{ toDisplayDateString(item.createdAt) }} by
                  {{ item.createdBy }}
                </div>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>

      <f7-list inset accordion-list>
        <f7-list-item accordion-item :accordion-item-opened="letterList.length > 0" title="Letter of Authorization">
          <f7-accordion-content>
            <f7-list class="no-margin">
              <f7-list-item v-for="(item, index) in letterList" :key="index">
                <f7-link style="white-space: break-spaces" @click.native="
                  $f7route.query.tenant
                    ? gotoViewLetterDetail(item.id)
                    : onPreviewLetter(item)
                  " slot="title">{{ item.title }}</f7-link>
                <div slot="footer">
                  {{ item.status === "draft" ? "Created" : "Sent" }} on
                  {{ toDisplayDateString(item.createdAt) }} by
                  {{ item.createdBy }}
                </div>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list :class="{
        'list-inline-center': $device.desktop,
        'list-inline-center-mobile': !$device.desktop
      }" inset inline-labels>
        <f7-list-item>
          <div class="title">Photo</div>
          <div class="content">
            <f7-link
              v-if="$f7route.query.tenant"
              target="_blank"
              external
              :href="urlViewProjectPhoto()"
              >View</f7-link
            >
            <f7-link v-else @click="openProjectPhotoPopup">View</f7-link>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-col>
    <f7-col :width="$device.desktop ? 30 : 100">
      <f7-list inset accordion-list>
        <f7-list-item accordion-item accordion-item-opened title="Insurance Information">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item title="Insurance Company" :after="insuranceCompany.companyName || ''"></f7-list-item>
              <f7-list-item title="Policy #" :after="project.policy || ''"></f7-list-item>
              <f7-list-item title="Claim #" :after="project.claim || ''"></f7-list-item>
              <f7-list-item title="Approved Budget" :after="(project.approvalStatus === 'approved'
                ? project.insuranceBudget
                : project.approvalStatus === 'supplement-needed'
                  ? project.insuranceFinalAmount
                  : project.approvalStatus === 'denied'
                    ? 0
                    : '') | currencyUSD
                "></f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list inset accordion-list>
        <f7-list-item accordion-item :accordion-item-opened="!!(project.agent && project.agent.name) ||
          !!(project.agent && project.agent.email) ||
          !!(project.agent && project.agent.phone)
          " title="Agent Information">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item title="Name" :after="project.agent && (project.agent.name || '')"></f7-list-item>
              <f7-list-item title="Email" :after="project.agent && (project.agent.email || '')"></f7-list-item>
              <f7-list-item title="Phone" :after="project.agent && (project.agent.phone || '')"></f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list inset accordion-list>
        <f7-list-item accordion-item :accordion-item-opened="!!(project.deskAdjuster && project.deskAdjuster.name) ||
          !!(project.deskAdjuster && project.deskAdjuster.email) ||
          !!(project.deskAdjuster && project.deskAdjuster.phone)
          " title="Desk Adjuster Information">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item title="Name" :after="project.deskAdjuster && (project.deskAdjuster.name || '')
                "></f7-list-item>
              <f7-list-item title="Email" :after="project.deskAdjuster && (project.deskAdjuster.email || '')
                "></f7-list-item>
              <f7-list-item title="Phone" :after="project.deskAdjuster && (project.deskAdjuster.phone || '')
                "></f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list inset accordion-list>
        <f7-list-item accordion-item :accordion-item-opened="!!(project.fieldAdjuster && project.fieldAdjuster.name) ||
          !!(project.fieldAdjuster && project.fieldAdjuster.email) ||
          !!(project.fieldAdjuster && project.fieldAdjuster.phone)
          " title="Field Adjuster Information">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item title="Name" :after="project.fieldAdjuster && (project.fieldAdjuster.name || '')
                "></f7-list-item>
              <f7-list-item title="Email" :after="project.fieldAdjuster && (project.fieldAdjuster.email || '')
                "></f7-list-item>
              <f7-list-item title="Phone" :after="project.fieldAdjuster && (project.fieldAdjuster.phone || '')
                "></f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </f7-col>

    <div>
      <agreement-preview-popup ref="agreementPreviewPopup"></agreement-preview-popup>
      <letter-preview-popup ref="letterPreviewPopup"></letter-preview-popup>
    </div>
  </f7-row>
</template>
<script>
import { getFullAddress } from "@/utility/address";
import { toDisplayDateString } from "@/utility/datetime";
import axiosService from "@/services/axios.service";
import { mapActions } from "vuex";
import AgreementPreviewPopup from "../popup/AgreementPreviewPopup.vue";
import LetterPreviewPopup from "../popup/LetterPreviewPopup.vue";

export default {
  props: {
    project: Object,
    contact: Object,
    insuranceCompany: Object,
    agreementList: Array,
    letterList: Array,
    proposalStatusList: Array
  },

  components: {
    AgreementPreviewPopup,
    LetterPreviewPopup
  },

  data() {
    return {
      getFullAddress,
      toDisplayDateString
    };
  },

  computed: {
    tenantId() {
      let tenantLocal = atob(this.$f7route.query.tenant)
      return tenantLocal;
    }
  },

  methods: {
    ...mapActions("dashboard/app-constant", [
      "setIsOpenProjectPhotosViewPopup"
    ]),

    onPreviewAgreement(itemDetail) {
      this.$refs.agreementPreviewPopup.open(itemDetail);
    },

    onPreviewLetter(itemDetail) {
      this.$refs.letterPreviewPopup.open(itemDetail);
    },

    getStatus(status) {
      return this.proposalStatusList.find(x => x.value === status) || {};
    },

    urlViewProjectPhoto() {
      return `${import.meta.env.VITE_HOST_DOMAIN}/share-photo/${this.$f7route.query.tenant}/${this.project.id}`;
    },

    openProjectPhotoPopup() {
      this.setIsOpenProjectPhotosViewPopup(true);
    },

    async gotoViewAgreementDetail(id) {
      let url = "";
      const response = await axiosService.post("/tenant/get-anonymous-token", {
        entity: "agreement",
        entityId: id,
      }, {
        headers: {
          login_tenant: this.tenantId,
        }
      });
      if (response && response.status === 200) {
        url = `${import.meta.env.VITE_HOST_DOMAIN}/agreement-preview/?token=${response.data}&id=${id}`;
      }
      window.open(url, "_blank");
    },

    async gotoViewLetterDetail(id) {
      let url = "";
      const response = await axiosService.post("/tenant/get-anonymous-token", {
        entity: "letter",
        entityId: id
      }, {
        headers: {
          login_tenant: this.tenantId,
        }
      });
      if (response && response.status === 200) {
        url = `${import.meta.env.VITE_HOST_DOMAIN}/letter-preview/?token=${response.data}&id=${id}`;
      }
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.list-inline-center {
  ::v-deep .item-inner {
    gap: 16px;

    .title {
      flex: 1;
    }

    .content {
      flex: 2;
    }
  }
}

.list-inline-right,
.list-inline-center-mobile {
  ::v-deep .item-inner {
    gap: 16px;

    .title {
      flex: 1;
    }

    .content {
      flex: 1;
    }
  }
}
</style>
