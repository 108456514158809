import moment from "moment";
import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TAX_PROFIT,
  SECTION_TYPE_TPO_LABOR,
  TABLE_CONTENT_TYPE_ITEMIZE,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL
} from "@/utility/const";
import _ from "lodash";
import { convertDateTimeFromAlgoliaToDate } from "@/utility/date-time-tool";
import { mapGetters } from "vuex";

import estimateService from "../../../services/estimate.service";
import { INCURRED_ITEM } from "../utility/const";

export default {
  computed: {
    ...mapGetters({
      estimateById: "invoices/estimate/estimateById"
    }),
    ...mapGetters("invoices/app-constant", ["invoiceStatusList"]),
    isInvoiceGrid() {
      return this.$f7router.currentRoute.path.includes("invoices-group");
    },
    detailTableHeaders() {
      return [
        {
          text: "Item Details",
          align: "left",
          sortable: false,
          value: "productName",
          width: "25%"
        },
        {
          text: "Quantity",
          align: "right",
          sortable: false,
          value: "quantity",
          width: "5%"
        },
        {
          text: "Price",
          align: "right",
          sortable: false,
          value: "price",
          width: "10%"
        },
        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount",
          width: "10%"
        },
        {
          text: "Discount",
          align: "center",
          sortable: false,
          value: "discount",
          width: "10%"
        },
        {
          text: "Tax",
          align: "center",
          sortable: false,
          value: "tax",
          width: "10%"
        }
      ];
    },
    footer() {
      return invoiceDetail => {
        return {
          left: invoiceDetail.roofingCompanyAddress,
          right: invoiceDetail.roofingCompanyPhone
        };
      };
    },
    displayLaborMaterial() {
      return invoiceDetail => {
        let list = [];

        (invoiceDetail.itemDetails || []).forEach((r, index) => {
          const crossProfitMarginRatio = r.crossProfitMarginRatio || 0;
          const miscPercent = r.productName.includes("Material Cost")
            ? r.miscPercent || 0
            : 0;
          let priceWithProfitAndMisc =
            r.price / ((100 - (crossProfitMarginRatio + miscPercent)) / 100) ||
            0;
          let amount = (r.quantity || 1) * (priceWithProfitAndMisc || 0);
          let finalAmount = r.adjustedItemAmount
            ? r.adjustedItemAmount
            : amount;
          let discountAmount = r.discount
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.discount,
                finalAmount
              )
            : 0;
          let taxAmount = r.tax
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.tax,
                finalAmount - discountAmount
              )
            : 0;
          if (r.productName.includes("Material Cost")) {
            list.push({
              index: index,
              productName: r.productName,
              discount: r.discount,
              tax: r.tax,
              taxAmount: parseFloat(taxAmount.toFixed(2)),
              discountAmount: parseFloat(discountAmount.toFixed(2)),
              amount: parseFloat(finalAmount.toFixed(2))
            });
          }
          if (r.productName.includes("Labor Cost")) {
            list.push({
              index: index,
              productName: r.productName,
              discount: r.discount,
              tax: r.tax,
              taxAmount: parseFloat(taxAmount.toFixed(2)),
              discountAmount: parseFloat(discountAmount.toFixed(2)),
              amount: parseFloat(finalAmount.toFixed(2))
            });
          }
          if (r.productName === INCURRED_ITEM) {
            list.push({
              index: index,
              productName: r.productName,
              discount: r.discount,
              tax: r.tax,
              taxAmount: parseFloat(taxAmount.toFixed(2)),
              discountAmount: parseFloat(discountAmount.toFixed(2)),
              amount: parseFloat(finalAmount.toFixed(2))
            });
          }
        });
        return list;
      };
    },
    displaySimpleSummary() {
      return invoiceDetail => {
        let item = {
          productName: "Service Fee",
          discount:
            (invoiceDetail.itemDetails || []).findIndex(
              r => r.discount == true
            ) < 0
              ? false
              : true,
          tax:
            (invoiceDetail.itemDetails || []).findIndex(r => r.tax == true) < 0
              ? false
              : true,
          taxAmount: 0,
          discountAmount: 0,
          amount: 0
        };

        (invoiceDetail.itemDetails || []).forEach(r => {
          const crossProfitMarginRatio = r.crossProfitMarginRatio || 0;
          const miscPercent = r.productName.includes("Material Cost")
            ? r.miscPercent || 0
            : 0;
          let priceWithProfitAndMisc =
            r.price / ((100 - (crossProfitMarginRatio + miscPercent)) / 100) ||
            0;
          let amount = (r.orderQty || 1) * (priceWithProfitAndMisc || 0);
          let discountAmount = r.discount
            ? this.getAmountWithPercentOrCash(invoiceDetail.discount, amount) ||
              0
            : 0;
          let taxAmount = r.tax
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.tax,
                amount - discountAmount
              )
            : 0;
          if (
            invoiceDetail.adjustedTotalAmount &&
            invoiceDetail.isEditedAmount
          ) {
            item.amount = invoiceDetail.adjustedTotalAmount || 0;
            item.discountAmount = r.discount
              ? this.getAmountWithPercentOrCash(
                  invoiceDetail.discount,
                  invoiceDetail.adjustedTotalAmount
                ) || 0
              : 0;
            item.taxAmount = r.tax
              ? this.getAmountWithPercentOrCash(
                  invoiceDetail.tax,
                  item.amount - item.discountAmount
                )
              : 0;
          } else {
            item.amount += parseFloat(amount.toFixed(2));
            item.discountAmount += parseFloat(discountAmount.toFixed(2));
            item.taxAmount += parseFloat(taxAmount.toFixed(2));
          }
        });
        let list = [];
        list.push(item);
        return list;
      };
    },
    displayItemize() {
      return invoiceDetail => {
        return (invoiceDetail.itemDetails || []).map(r => {
          const crossProfitMarginRatio = r.crossProfitMarginRatio || 0;
          const miscPercent = r.category !== "Labor" ? r.miscPercent || 0 : 0;
          let priceWithProfitAndMisc =
            r.price / ((100 - (crossProfitMarginRatio + miscPercent)) / 100) ||
            0;
          let amount = (r.quantity || 1) * (priceWithProfitAndMisc || 0);
          let discountAmount = r.discount
            ? this.getAmountWithPercentOrCash(invoiceDetail.discount, amount)
            : 0;

          let taxAmount = r.tax
            ? this.getAmountWithPercentOrCash(
                invoiceDetail.tax,
                amount - discountAmount
              )
            : 0;
          return {
            ...r,
            priceWithProfitAndMisc,
            amount,
            discountAmount,
            taxAmount
          };
        });
      };
    },
    items() {
      return invoiceDetail => {
        if (
          invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL
        ) {
          return this.displayLaborMaterial(invoiceDetail || {});
        } else if (
          invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY
        ) {
          return this.displaySimpleSummary(invoiceDetail || {});
        } else if (
          invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
        ) {
          return this.displayItemize(invoiceDetail || {});
        } else {
          return (invoiceDetail.itemDetails || []).map(r => {
            let amount = (r.quantity || 0) * (r.price || 0);
            let discountAmount = r.discount
              ? this.getAmountWithPercentOrCash(invoiceDetail.discount, amount)
              : 0;
            let taxAmount = r.taxAmount ? r.taxAmount : r.tax
              ? this.getAmountWithPercentOrCash(
                  invoiceDetail.tax,
                  amount - discountAmount
                )
              : 0;
            return {
              ...r,
              amount,
              discountAmount,
              taxAmount
            };
          });
        }
      };
    },

    // not service
    totalAmountWithItems() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) => accumulator + parseFloat(item.amount || 0),
          0
        );
      };
    },

    totalTaxWithItems() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) => accumulator + parseFloat(item.taxAmount || 0),
          0
        );
      };
    },

    // for service
    serviceMaterialSubTotal() {
      return itemDetails => {
        let total = 0;
        let crossProfitMarginRatio = 0;
        let miscPercent = 0;
        itemDetails.forEach(item => {
          if (item.category !== "Labor") {
            total += (item.quantity || 0) * (item.price || 0);
          }
          crossProfitMarginRatio = item.crossProfitMarginRatio || 0;
          miscPercent = item.miscPercent || 0;
        });
        return (
          total / ((100 - (crossProfitMarginRatio + miscPercent)) / 100) || 0
        );
      };
    },

    serviceMaterialNetSales() {
      return invoiceDetail => {
        const materialSubTotal =
          this.serviceMaterialSubTotal(invoiceDetail.itemDetails || []) || 0;
        return materialSubTotal;
      };
    },

    serviceMaterialDiscountAmount() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) =>
            accumulator +
            (item.category !== "Labor"
              ? parseFloat(item.discountAmount || 0)
              : 0),
          0
        );
      };
    },

    serviceMaterialTax() {
      return invoiceDetail => {
        return (
          this.getAmountWithPercentOrCash(
            invoiceDetail.tax,
            this.serviceMaterialNetSales(invoiceDetail) -
              this.serviceMaterialDiscountAmount(invoiceDetail)
          ) || 0
        );
      };
    },

    serviceLaborSubTotal() {
      return itemDetails => {
        return itemDetails.reduce(
          (accumulator, item) =>
            accumulator +
            (item.category === "Labor"
              ? (item.quantity || 0) * (item.price || 0)
              : 0),
          0
        );
      };
    },

    serviceLaborNetSales() {
      return invoiceDetail => {
        const materialSubTotal =
          this.serviceLaborSubTotal(invoiceDetail.itemDetails) || 0;
        const crossProfitMarginRatio =
          invoiceDetail.crossProfitMarginRatio || 0;

        return materialSubTotal / ((100 - crossProfitMarginRatio) / 100) || 0;
      };
    },

    serviceLaborDiscountAmount() {
      return invoiceDetail => {
        return this.items(invoiceDetail).reduce(
          (accumulator, item) =>
            accumulator +
            (item.category === "Labor"
              ? parseFloat(item.discountAmount || 0)
              : 0),
          0
        );
      };
    },

    serviceLaborTax() {
      return invoiceDetail => {
        // get estimate
        const estimate = this.estimateById(invoiceDetail.estimateId) || {};
        return estimate.taxApplyType === TAX_PROFIT
          ? this.getAmountWithPercentOrCash(
              invoiceDetail.tax,
              this.serviceLaborNetSales(invoiceDetail) -
                this.serviceLaborDiscountAmount(invoiceDetail)
            )
          : 0;
      };
    },

    serviceSubTotal() {
      return invoiceDetail => {
        return (
          parseFloat(this.serviceMaterialNetSales(invoiceDetail).toFixed(2)) +
          parseFloat(this.serviceLaborNetSales(invoiceDetail).toFixed(2))
        );
      };
    },

    serviceTax() {
      return invoiceDetail => {
        return (
          parseFloat(this.serviceMaterialTax(invoiceDetail).toFixed(2)) +
          parseFloat(this.serviceLaborTax(invoiceDetail).toFixed(2))
        );
      };
    },

    // display summary footer
    subTotal() {
      return invoiceDetail => {
        return invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
          ? this.serviceSubTotal(invoiceDetail)
          : this.totalAmountWithItems(invoiceDetail);
      };
    },

    taxAmount() {
      return invoiceDetail => {
        if (invoiceDetail.syncFromQB) return invoiceDetail.tax.value;
        return invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
          ? this.serviceTax(invoiceDetail)
          : this.totalTaxWithItems(invoiceDetail);
      };
    },

    discountAmount() {
      return invoiceDetail => {
        if (invoiceDetail.syncFromQB) return invoiceDetail.discount.value;
        return this.items(invoiceDetail).reduce(
          (accumulator, item) =>
            accumulator + parseFloat(item.discountType === "percent"
                ? item.amount * (item.discountValue / 100)
                : item.discountValue || 0),
          0
        );
      };
    },

    shippingChargeValue() {
      return invoiceDetail => {
        return this.getAmountWithPercentOrCash(
          invoiceDetail.shippingCharge,
          this.subTotal(invoiceDetail) -
            this.discountAmount(invoiceDetail) +
            this.taxAmount(invoiceDetail)
        );
      };
    },

    total() {
      return invoiceDetail => {
        if (invoiceDetail.syncFromQB) return invoiceDetail.totalAmount;
        if(invoiceDetail.adjustedTotalAmount) {
          return invoiceDetail.adjustedTotalAmount -
          this.discountAmount(invoiceDetail) +
          this.taxAmount(invoiceDetail)
        } else {
          return this.subTotal(invoiceDetail) -
          this.discountAmount(invoiceDetail) +
          this.taxAmount(invoiceDetail) +
          this.shippingChargeValue(invoiceDetail)
        }
      };
    },

    displayCarhOrPercent() {
      return (invoiceDetail, prop) => {
        let type = (invoiceDetail[prop] || {}).type;
        let value = (invoiceDetail[prop] || {}).value;
        if (type === "cash") return `$${value || 0}`;
        if (type === "percent") return `${value || 0}%`;
        return "";
      };
    },
    totalInvoice() {
      return invoiceDetail => {
        const shippingCharge = this.getShippingChargeValue(invoiceDetail);
        const totalIntoMoney = this.getTotalIntoMoney(invoiceDetail);

        return totalIntoMoney + shippingCharge;
      };
    }
  },
  methods: {
    getTotalIntoMoney(invoiceDetail) {
      if (!invoiceDetail) return 0;
      const items = invoiceDetail.itemDetails;

      if (!items?.length) return 0;

      return items.reduce((acc, item) => acc + item.intoMoney, 0);
    },

    getShippingChargeValue(invoiceDetail) {
      if (!invoiceDetail) return 0;

      if (invoiceDetail.shippingCharge?.type === "cash") {
        return invoiceDetail.shippingCharge.value;
      } else if (invoiceDetail.shippingCharge?.type === "percent") {
        return (
          (this.getTotalIntoMoney(invoiceDetail) *
            invoiceDetail.shippingCharge.value) /
          100
        );
      }
      return 0;
    },
    // Estimate Price Calculation
    doesApplyTaxForMaterial(project, building, estimate) {
      return estimateService.checkApplyTaxForMaterial({
        project,
        building,
        estimate
      });
    },

    doesApplyTaxForLabor(project, building, estimate) {
      return estimateService.checkApplyTaxForLabor({
        project,
        building,
        estimate
      });
    },

    getPercentValue(percent, value) {
      return _.round(((percent || 0) * (value || 0)) / 100.0, 2);
    },

    sortBy(a, b) {
      return a > b ? 1 : b > a ? -1 : 0;
    },
    orderQty(product, building) {
      const roundUpNumber = numberOfFixedRounds => value => {
        value = Math.round(value * 1000) / 1000;
        const denominator = Math.pow(10, numberOfFixedRounds);
        const temp = parseFloat((value * denominator).toFixed(10)); //Remove zero after device
        return Math.ceil(temp) / denominator;
      };
      const productSize = (product.unitSize || 1) * (product.unitPack || 1);
      const numberOfCopy = building.numberOfCopy || 1;
      const actualQty = product.actualQty * numberOfCopy;
      const orderQty = actualQty / productSize;
      return roundUpNumber(0)(orderQty);
    },
    materialSubTotalForBuilding(building) {
      let subTotals = 0;
      (building.productData || []).forEach(section => {
        if (section.sectionId !== SECTION_TYPE_TPO_LABOR) {
          subTotals += (section.productList || []).reduce(
            (accumulator, item) =>
              (this.orderQty(item, building) || 0) * (item.price || 0) +
              accumulator,
            0
          );
        }
      });
      return subTotals;
    },

    laborSubTotalForBuilding(building) {
      let subTotals = 0;
      (building.productData || []).forEach(section => {
        if (section.sectionId === SECTION_TYPE_TPO_LABOR) {
          subTotals += (section.productList || []).reduce(
            (currentValue, item) =>
              (item.orderQty || 0) * (item.price || 0) + currentValue,
            0
          );
        }
      });
      return subTotals;
    },

    summaryEstimate(project, buildings, estimate) {
      return buildings
        .sort((a, b) => this.sortBy(a.name, b.name))
        .reduce((prev, building) => {
          const items = [
            {
              productName: `Material Cost of ${building.buildingName}`,
              quantity: 1,
              price: parseFloat(
                (this.materialSubTotalForBuilding(building) || 0).toFixed(2)
              ),
              discount: false,
              tax:
                this.doesApplyTaxForMaterial(project, building, estimate) ||
                false,
              isAutoAdded: true,
              crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
              miscPercent: estimate.miscPercent || 0
            },
            {
              productName: `Labor Cost of ${building.buildingName}`,
              quantity: 1,
              price: parseFloat(
                (this.laborSubTotalForBuilding(building) || 0).toFixed(2)
              ),
              discount: false,
              tax:
                this.doesApplyTaxForLabor(project, building, estimate) || false,
              isAutoAdded: true,
              crossProfitMarginRatio: estimate.crossProfitMarginRatio || 0,
              miscPercent: estimate.miscPercent || 0
            }
          ];
          return prev.concat(items);
        }, []);
    },

    sumArray(arr) {
      let res = 0;
      if (!_.isEmpty(arr)) {
        res = arr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
      }
      return res;
    },

    // get amount with item = { type: percent/cash, value: 0 }
    getAmountWithPercentOrCash(item = { value: 0 }, amount) {
      let value = 0;
      if (item.type == "percent") {
        value = (item.value * amount) / 100;
      } else {
        value = item.value;
      }
      return parseFloat(value || 0);
    },

    getOverdueDays(invoice, isFromAlgolia = false) {
      // let total = parseFloat(this.total(invoice));
      let paidAmount = invoice.paidAmount || 0;
      const currentDate = moment(new Date(), "MM/DD/YYYY");
      let dueDate = currentDate;
      if (isFromAlgolia) {
        dueDate =
          invoice.dueDate &&
          moment(
            convertDateTimeFromAlgoliaToDate(invoice.dueDate),
            "MM/DD/YYYY"
          );
      } else {
        dueDate =
          invoice.dueDate && moment(invoice.dueDate.toDate(), "MM/DD/YYYY");
      }
      const days = currentDate.diff(dueDate, "day");
      if (paidAmount === 0 && days > 0) {
        return days;
      }
      return 0;
    },
    getStatus(status) {
      return this.invoiceStatusList.find(x => x.value === status) || {};
    },
    getPaymentStatus(invoice) {
      let total = parseFloat(this.total(invoice));
      if (!invoice) return "";
      let paidAmount = invoice.paidAmount || 0;
      if (paidAmount >= total) {
        return "Paid";
      }
      if (paidAmount == 0) {
        return "Unpaid";
      }
      if (paidAmount > 0) {
        return "Partial";
      }
    }
  }
};
