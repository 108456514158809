export default {
  payRateList: state => {
    return (state.payRateList || []).map(pr => ({
      ...pr,
      effectiveDate: pr.effectiveDate && pr.effectiveDate.toDate()
    }));
  },
  payRateId: state => state.payRateId,
  payRate: ({ payRateId, payRateList }) => {
    var payRate = payRateList.find(r => r.id === payRateId);
    if (payRate) return payRate;
    if (payRateList.length === 0) return {};
    return null;
  }
};
