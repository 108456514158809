<template>
  <f7-skeleton-block
    :style="{
      width: width + '%',
      height: '20px',
      borderRadius: '2px',
      background: '#5151513b !important'
    }"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 60
    }
  }
};
</script>
