<template>
  <div class="kanban-container" :style="containerStyle">
    <div
      v-for="(column, index) in constructionActions"
      :key="column.id"
      class="column-width kanban-column-container swimlane-column-light"
    >
      <header-editable
        :value="column.title"
        :column-id="column.id"
        :column-index="index"
        :max-columns="constructionActions.length"
        @delete="onDeleteColumn(column.id)"
        @edit="onEditColumn(column)"
        @move-left="moveColumn(column.id, index - 1)"
        @move-right="moveColumn(column.id, index + 1)"
      />
      <!-- <draggable
        :list="cardsInColumn(column.id)"
        :animation="200"
        class="column-content-container"
        ghost-class="ghost-card"
        :delay="$device.desktop ? 0 : 100"
        :delayOnTouchOnly="$device.desktop ? true : false"
        :touchStartThreshold="20"
        group="tasks"
        draggable=".item"
        @change="e => onDndChange(e, column.id)"
      > -->
      <div
        class="column-content-container"
        :ref="`column-content-container_${column.id}`"
      >
        <task-card
          v-for="task in cardsInColumn(column.id)"
          :key="task.id"
          :task="task"
          class="item"
          @openCard="openCard($event, column.id, task.taskNumber)"
        ></task-card>
        <footer-add-new-card @add="onAddCardClick(column.id)" slot="footer" />
      </div>
      <!-- </draggable> -->
    </div>
    <!-- <div class="column-width kanban-column-container">
      <header-add-new-column @add="openInputColumnName"></header-add-new-column>
    </div> -->
    <!-- <card-detail-popup /> -->
  </div>
</template>

<script>
import { firebase, auth } from "../../../../services/firebase.service";
import { mapActions, mapGetters, mapMutations } from "vuex";
// import draggable from "vuedraggable";
import _ from "lodash";

import TaskCard from "../kaban/TaskCard.vue";
import HeaderEditable from "../kaban/HeaderEditable.vue";
// import HeaderAddNewColumn from "../kaban/HeaderAddNewColumn.vue";
import FooterAddNewCard from "../kaban/FooterAddNewCard.vue";
import { sortByUpdatedOrCreated } from "../../../../utility/date-time-tool";
// import CardDetailPopup from "../kaban/CardDetailPopup.vue";
const PRIORITY_STEPER = 1000;

function calculateNewPriorityForItemInList(list, index) {
  if (!list || !list.length) {
    return 0;
  }
  if (index === 0) {
    const first = list[0];
    return first.priority - PRIORITY_STEPER;
  }
  if (list.length <= index) {
    const last = list.slice().pop();
    return (last.priority || 0) + PRIORITY_STEPER;
  }
  const previous = list[index - 1];
  const next = list[index];
  return ((previous.priority + next.priority) * 1.0) / 2;
}

export default {
  components: {
    TaskCard,
    // draggable,
    HeaderEditable,
    // HeaderAddNewColumn,
    FooterAddNewCard
    // CardDetailPopup
  },

  // props: {
  //   boardId: { type: String, default: () => null }
  // },

  data() {
    return {
      addingCardForColumnId: null
      // cards: [
      //   {
      //     columnId: "0pI0O9VJVi5MR3EQykQ8",
      //     title: "Task 1st",
      //     assigneeIds: ["IgNWUcVzFke4B3WiwKcNQY4Q73M2"]
      //   },
      //   {
      //     columnId: "0pI0O9VJVi5MR3EQykQ8",
      //     title: "Task 2st",
      //     assigneeIds: ["IgNWUcVzFke4B3WiwKcNQY4Q73M2"]
      //   },
      //   {
      //     columnId: "0pI0O9VJVi5MR3EQykQ8",
      //     title: "Task 3st",
      //     assigneeIds: ["IgNWUcVzFke4B3WiwKcNQY4Q73M2"]
      //   },
      //   {
      //     columnId: "1pI0O9VJVs5MR3EQykQ8",
      //     title: "Task 4st",
      //     assigneeIds: ["IgNWUcVzFke4B3WiwKcNQY4Q73M2"]
      //   }
      // ]
    };
  },

  computed: {
    ...mapGetters("dashboard/project", [
      "constructionActions",
      "currActionIdsAfterMoveOrAdd"
    ]),
    ...mapGetters("dashboard/work-order", ["workOrderTaskList"]),

    ...mapGetters({
      action: "swimlane/column/action"
    }),

    containerStyle() {
      return `width: ${this.constructionActions.length * (320 + 12) + 5}px;`;
    }
  },

  methods: {
    ...mapActions("dashboard/project", ["getActionList"]),
    ...mapActions("dashboard/work-order", [
      "updateWorkOrderTask",
      "editTaskPosition"
    ]),

    cardsInColumn(columnId) {
      const cards =
        this.workOrderTaskList.filter(
          task =>
            !task.isDraft && task.actions && task.actions.includes(columnId)
        ) || [];

      // return cards.slice().sort((a, b) => a.priority || 0 - b.priority || 0);
      return sortByUpdatedOrCreated(cards, true);
    },

    openCard(taskId, actionId, taskNumber) {
      this.$emit("onOpenPopup", {
        actionId: actionId,
        cardId: taskId,
        taskNumber: taskNumber
      });
    },

    ...mapActions({
      createCard: "swimlane/card/create",
      updateCard: "swimlane/card/update",
      bindCards: "swimlane/card/bind",
      unBindCards: "swimlane/card/unbind"
    }),
    ...mapMutations({
      setCardDetailId: "swimlane/card/setCardDetailId"
    }),

    openInputColumnName() {
      const _sefl = this;
      _sefl.$f7.dialog.prompt("Input column name", null, name => {
        const maxColumnsPriority =
          Math.max(...[..._sefl.columns.map(r => r.priority), 0]) || 0;
        _sefl.createColumn({
          name,
          priority: maxColumnsPriority + 1
          // boardId: _sefl.boardId
        });
      });
    },

    onEditColumn(column) {
      const _sefl = this;
      _sefl.$f7.dialog.prompt(
        "Input column name",
        null,
        name => {
          _sefl.updateColumn({
            id: column.id,
            doc: {
              name
            }
          });
        },
        null,
        column.name
      );
    },

    onDeleteColumn(id) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Delete column",
        content: "Are you sure you want to delete this column?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.deleteColumn(id);
            _sefl.app.dialog.close();
          }
        }
      });
    },

    async onAddCardClick(columnId) {
      // this.addingCardForColumnId = columnId;
      const lastPriorityInColumn =
        Math.max(
          ...[...this.cardsInColumn(columnId).map(r => r.priority), 0]
        ) || 0;

      let actionsHistory = this.calculateActionHistory(null, columnId);
      const cardId = await this.createCard({
        title: "",
        description: "",
        priority: lastPriorityInColumn + 1000,
        columnId: columnId,
        // boardId: this.boardId,
        status: "draft",
        actionsHistory,
        businessCode: this.$f7route.route.meta.businessCode,
        isDraft: true
      });
      this.openCard({
        // boardId: this.boardId,
        id: cardId
      });
    },

    async onDndChange(dndEvent, columnId) {
      // console.log("onDndChange", dndEvent, columnId);
      const eventKeys = Object.keys(dndEvent);
      for (let index = 0; index < eventKeys.length; index++) {
        const key = eventKeys[index];
        const event = dndEvent[key];
        // console.log("key", key, "event", event);
        switch (key) {
          case "added":
            await this.addItemToColumn(event.element, columnId, event.newIndex);
            break;
          case "removed":
            this.removeItemFromColumn(event.element, columnId, event.oldIndex);
            break;
          case "moved":
            // await this.moveItem(
            //   event.element,
            //   columnId,
            //   event.newIndex,
            //   event.oldIndex
            // );
            break;
        }
      }
    },

    async addItemToColumn(item, columnId, index) {
      // console.log("addItemToColumn", item, columnId, index);
      // get all card in column
      const itemsInColumns = this.cardsInColumn(columnId);
      // calculate priority
      const newPriority = calculateNewPriorityForItemInList(
        itemsInColumns,
        index
      );
      // console.log("newPriority", newPriority);
      await this.moveCard(item, columnId, newPriority);
    },

    async moveCard(item, columnId, priority) {
      // let actionsHistory = this.calculateActionHistory(item, columnId);
      this.editTaskPosition({
        taskId: item.id,
        actions: [columnId],
        priority
      });
      await this.updateWorkOrderTask({
        id: item.id,
        doc: {
          actions: [columnId],
          priority
          // actionsHistory
        }
      });
    },

    /**
     * Add action history if the card is moved from a column to another column
     */
    calculateActionHistory(project, actionId) {
      // For create new project
      if (project === null) {
        return [
          {
            nextAction: this.action(actionId).name,
            previousAction: "Job Created",
            resolvedAt: firebase.firestore.Timestamp.now(),
            resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
            resolvedById: auth.currentUser.uid
          }
        ];
      }

      let actionsHistory = _.cloneDeep(project.actionsHistory) || [];
      if (project.columnId !== actionId) {
        actionsHistory.push({
          nextAction: this.action(actionId).name,
          previousAction: this.action(project.columnId).name,
          resolvedAt: firebase.firestore.Timestamp.now(),
          resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
          resolvedById: auth.currentUser.uid
        });
      }

      return actionsHistory;
    },

    async moveItem(item, columnId, newIndex) {
      const itemsInColumns = this.cardsInColumn(columnId).filter(
        r => r.id !== item.id
      );
      const newPriority = calculateNewPriorityForItemInList(
        itemsInColumns,
        newIndex
      );
      await this.moveCard(item, columnId, newPriority);
    },

    removeItemFromColumn() {},

    async moveColumn(columnId, newIndex) {
      const columnsWithoutCurrentColumn = this.sortedColumns.filter(
        r => r.id !== columnId
      );
      const newPriority = calculateNewPriorityForItemInList(
        columnsWithoutCurrentColumn,
        newIndex
      );
      await this.updateColumn({
        id: columnId,
        doc: {
          priority: newPriority
        }
      });
    },
    scrollToElement(columnId) {
      if(_.isEmpty(this.$refs[`column-content-container_${columnId}`])) return
      
      const el = this.$refs[`column-content-container_${columnId}`][0];

      if (!_.isEmpty(el)) {
        el.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  },
  watch: {
    workOrderTaskList: {
      handler(val) {
        if (!_.isEmpty(val) && this.currActionIdsAfterMoveOrAdd.length) {
          this.currActionIdsAfterMoveOrAdd.forEach(actionId => {
            this.scrollToElement(actionId);
          });
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
.kanban-container {
  /* display: flex; */
  /* width: 2520px; */

  /* background-color: var(--f7-card-bg-color); */
  /* height: var(--swimlane-scroller-height); */
  /* overflow-x: auto; */
}

.column-width {
  min-width: 320px;
  width: 320px;
  /* background-color: var(--f7-card-bg-color); */
}
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px dashed;
}

.kanban-column-container {
  display: inline-flex;
  /* display: flex; */
  flex-direction: column;
  margin: 6px;

  background-color: var(--swimlane-column-column-background);
  border-radius: var(--f7-card-border-radius);
  /* border: 1px solid; */
  box-shadow: var(--f7-elevation-1);
  /* max-height: 100%; */
  max-height: var(--kaban-scroller-height);
}
.column-content-container {
  /* height: auto; */
  overflow-y: auto;
  max-height: var(--kaban-scroller-height);
}
/* 
.scroller {
  max-height: var(--swimlane-scroller-height);
} */
</style>
