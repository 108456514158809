export const SET_PROPOSAL_FIELD = "SET_PROPOSAL_FIELD";
export const SET_PROPOSAL = "SET_PROPOSAL";
export const SET_NEW_PROPOSAL_FIELD = "SET_NEW_PROPOSAL_FIELD";
export const SET_NEW_PROPOSAL = "SET_NEW_PROPOSAL";
export const SET_IMAGES_TO_DELETE = "SET_IMAGES_TO_DELETE";
export const SET_ITEM_PROPOSAL = "SET_ITEM_PROPOSAL";

export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_NUMBER_OF_ROWS = "SET_NUMBER_OF_ROWS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
// export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER"; // Allow search desc only
export const RESET_SEARCH = "RESET_SEARCH";
export const ADD_MORE_RESULT = "ADD_MORE_RESULT";

export const SET_PROPOSAL_SECTION = "SET_PROPOSAL_SECTION";
export const SET_PROPOSAL_TYPE = "SET_PROPOSAL_TYPE";
export const SET_PARAMS_BACK = "SET_PARAMS_BACK";
export const SET_QUERY_BACK = "SET_QUERY_BACK";
export const SET_TEMP_PROPOSAL = "SET_TEMP_PROPOSAL";
export const CLEAR_TEMP_PROPOSAL = "CLEAR_TEMP_PROPOSAL";
export const SET_IS_CHANGE_NAVIGATE = "SET_IS_CHANGE_NAVIGATE";
export const SET_SELECTED_STATUS_FILTER = "SET_SELECTED_STATUS_FILTER";
