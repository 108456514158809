/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import { CODE_COMPANY_TYPE } from "@/utility/const";

export default tenant => {
  const projectAction = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "prject"
  );

  return {
    getProjectBys: projectAction.getDocumentBys,

    getProjectsByContactId({ dispatch }, payload) {
      return dispatch("getProjectBys", [
        {
          prop: "contactId",
          op: "==",
          val: payload
        },
        { prop: "status", op: "==", val: "open" }
      ]);
    },

    getProjectsByCompanyIds({ dispatch }, payload) {
      return dispatch("getProjectBys", [
        {
          prop: "companyId",
          op: "in",
          val: payload
        },
        { prop: "status", op: "==", val: "open" }
      ]);
    },

    getProjectsByPropertyIds({ dispatch }, payload) {
      return dispatch("getProjectBys", [
        {
          prop: "propertyId",
          op: "in",
          val: payload
        },
        { prop: "status", op: "==", val: "open" }
      ]);
    }
  };
};
