import { sortLatest } from "@/utility/date-time-tool";

export default {
  projectPhotosByAlbum: state => sortLatest(state.projectPhotosByAlbum),
  projectPhotoSelected: state => selectedIds => {
    return state.projectPhotosByAlbum.filter(r => selectedIds.includes(r.id));
  },
  selectionMode: state => state.selectionMode,
  selectedPhotoIds: state => state.selectedPhotoIds,
  photo: state => state.projectPhoto
};
