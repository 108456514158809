<template>
  <div>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
    </f7-list>
    <invoice-section
      ref="invoiceSection"
      @closePopup="closeActionPopup"
    ></invoice-section>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :is-save-button="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Upfront Payment Note',
          note,
          callback: () => {
            note = '';
          }
        })
      "
    ></note-editor>

    <!-- <date-popup
      ref="dueDatePopup"
      title="Enter Due Date"
      label="Due Date"
      v-model="dueDate"
      @done="resolve"
    ></date-popup> -->
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
// import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import moment from "moment";
import InvoiceSection from "../invoice/InvoiceSection.vue";
import PurchaseOrderInput from "../input/PurchaseOrderInput.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";
import { mapState, mapActions } from "vuex";

import _ from "lodash";
import methodsMixins from "../../mixin/methods";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  mixins: [methodsMixins],
  components: {
    UserInput,
    AttachmentInput,
    // DatePopup,
    InputIcon,
    InvoiceSection,
    PurchaseOrderInput,
    NoteEditor
  },

  data: () => {
    return {
      note: "",
      currentResponse: {},

      displayCard: {},
      dueDate: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"])
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);

        if (!_.isEmpty(this.$refs.invoiceSection)) {
          this.$refs.invoiceSection.initData();
        }
      }
    },

    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "payment-received":
          this.currentResponse = response;
          // this.$refs.dueDatePopup.open();
          this.resolve();
          break;

        case "save-and-close":
          this.save();
          this.$emit("doClosePopup");
          break;
      }
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Upfront Payment Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;

      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            "day"
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
