import _ from "lodash";

export default {
  purchaseOrderList: state => state.purchaseOrderList,
  purchaseOrderByNumber: state => poNumber =>
    state.purchaseOrderList.find(item => item.taskNumber === poNumber),

  purchaseOrder: state => state.purchaseOrder || {},
  purchaseOrderPages: state => state.purchaseOrderPages,
  purchaseOrderCurrentPageNumber: state => state.purchaseOrderCurrentPageNumber,

  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  selectedStatusFilter: state => state.selectedStatusFilter,
  queryFilters: state => {
    return state.selectedStatusFilter.map(s => `status:${s}`).join(" OR ");
  },
  // workOrder: state => state.workOrder,
  // workOrderListByType: state => type =>
  //   state.workOrderList.filter(item => item.workOrderType === type),

  // workOrderActionList: state =>
  //   _.cloneDeep(state.workOrderActionList).sort((a, b) => a.code - b.code),
  // workOrderAction: state => state.workOrderAction,

  // workOrderTaskList: state =>
  //   state.workOrderTaskList.map(task => {
  //     return {
  //       ...task,
  //       title: task.taskNumber
  //     };
  //   }),

  // filterTaskList: state => actionId => {
  //   return state.workOrderTaskList.filter(card => {
  //     return card.actions ? card.actions.includes(actionId) : false;
  //   });
  // },

  // filterTaskListGrid: (state, getters) => {
  //   let actions = getters.workOrderActionList;
  //   let cards = [];
  //   for (const action of actions) {
  //     cards.push(
  //       ..._.cloneDeep(getters.filterTaskList(action.id)).map(item => {
  //         item.actionTitle = action.title;
  //         item.actionId = action.id;
  //         item.actionCode = action.code;
  //         return item;
  //       })
  //     );
  //   }

  //   return cards;
  // },

  // workOrderTask: state => state.workOrderTask,
  response: state =>
    _.cloneDeep(state.response || []).sort((a, b) => a.index - b.index)
};
