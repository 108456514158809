export default {
  methods: {
    drawAssemply(assembly) {
      switch (assembly.templateCode) {
        case "collectorHead":
          this.$refs[`collectorHead`].initCanvas();
          this.$refs[`collectorHead`].drawAll();
          break;

        case "embedded":
          this.$refs[`embedded`].initCanvas();
          this.$refs[`embedded`].drawAll();
          break;

        case "copingWithCleatFastener":
          this.$refs[`copingWithCleatFastener`].initCanvas();
          this.$refs[`copingWithCleatFastener`].drawAll();
          break;

        case "copingWithCleat":
          this.$refs[`copingWithCleat`].initCanvas();
          this.$refs[`copingWithCleat`].drawAll();
          break;

        case "copingWithFrontBackCleat":
          this.$refs[`copingWithFrontBackCleat`].initCanvas();
          this.$refs[`copingWithFrontBackCleat`].drawAll();
          break;

        case "downspoutTransition":
          this.$refs[`downspoutTransition`].initCanvas();
          this.$refs[`downspoutTransition`].drawAll();
          break;

        case "boxDownspout":
          this.$refs[`boxDownspout`].initCanvas();
          this.$refs[`boxDownspout`].drawAll();
          break;

        case "gutterSystem":
          this.$refs[`gutterSystem`].initCanvas();
          this.$refs[`gutterSystem`].drawAll();
          break;

        case "headwallFlashing":
          this.$refs[`headwallFlashing`].initCanvas();
          this.$refs[`headwallFlashing`].drawAll();
          break;

        case "pitchPan":
          this.$refs[`pitchPan`].initCanvas();
          this.$refs[`pitchPan`].drawAll();
          break;

        case "counterFlashing":
          this.$refs[`counterFlashing`].initCanvas();
          this.$refs[`counterFlashing`].drawAll();
          break;

        case "valley":
          this.$refs[`valley`].initCanvas();
          this.$refs[`valley`].drawAll();
          break;

        case "sidewallOrHeadwall":
          this.$refs[`sidewallOrHeadwall`].initCanvas();
          this.$refs[`sidewallOrHeadwall`].drawAll();
          break;

        case "ridge":
          this.$refs[`ridge`].initCanvas();
          this.$refs[`ridge`].drawAll();
          break;

        case "zeeTrim":
          this.$refs[`zeeTrim`].initCanvas();
          this.$refs[`zeeTrim`].drawAll();
          break;

        case "extendedEaveOrRake":
          this.$refs[`extendedEaveOrRake`].initCanvas();
          this.$refs[`extendedEaveOrRake`].drawAll();
          break;
      }
    }
  }
};
