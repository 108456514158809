<template>
  <f7-card>
    <f7-card-header>
      {{
        itemSelected.length === 0
          ? "PO Check Number Payable To RooferIntel"
          : selectedMessage(itemSelected.length)
      }}
    </f7-card-header>
    <f7-card-content class="no-padding">
      <f7-toolbar tabbar top class="top-tab">
        <f7-link tab-link="#action" tab-link-active>Action</f7-link>
        <f7-link tab-link="#history">History</f7-link>
      </f7-toolbar>
      <f7-tabs animated>
        <f7-tab id="action" tab-active>
          <action-tab-in-check
            v-on="$listeners"
            @selectedItems="handleItemSelect"
          ></action-tab-in-check>
        </f7-tab>
        <f7-tab id="history">
          <history-tab-in-check></history-tab-in-check>
        </f7-tab>
      </f7-tabs>
    </f7-card-content>
  </f7-card>
</template>
<script>
import ActionTabInCheck from "@/plugins/quickbooks/components/tabs/ActionTabInCheck.vue";
import HistoryTabInCheck from "@/plugins/quickbooks/components/tabs/HistoryTabInCheck.vue";
export default {
  components: {
    ActionTabInCheck,
    HistoryTabInCheck
  },
  data: () => {
    return {
      itemSelected: []
    };
  },
  computed: {
    selectedMessage() {
      return counter => {
        if (counter === 1) {
          return "1 item selected";
        } else {
          return `${counter} items selected`;
        }
      };
    }
  },
  methods: {
    handleItemSelect(updatedData) {
      this.itemSelected = updatedData;
    }
  }
};
</script>
<style scoped>
.top-tab {
  top: 0 !important;
}
</style>
