import { FirebaseActions } from "../../services/firebase.service";
import { searchClient } from "../../services/search.service";
import * as constant from "../../utility/const";
import * as types from "./types";

export default tenant => {
  const proposalTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/proposal-template`,
    "proposalTemplate"
  );
  const proposalTemplateIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const proposalTemplateIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindProposalTemplateList: proposalTemplateActions.bindCollection,
    bindProposalTemplateListBy: proposalTemplateActions.bindCollectionBy,
    unbindProposalTemplateList: proposalTemplateActions.unbindCollection,

    bindProposalTemplate: proposalTemplateActions.bindDocument,
    unbindProposalTemplate: proposalTemplateActions.unbindDocument,

    updateProposalTemplate: proposalTemplateActions.updateDocument,

    updateTemplateSectionValue({ commit }, { sectionId, value }) {
      commit("SET_PROPOSAL_TEMPLATE_SECTION", { sectionId, value });
    },
    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchProposal({ state, commit }, { attributesToRetrieve, filters }) {
      const index =
        state.order === "asc"
          ? proposalTemplateIndexAsc
          : proposalTemplateIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_PROPOSAL_TEMPLATE}`,
        attributesToRetrieve:
          constant.PROPOSAL_TEMPLATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.PROPOSAL_TEMPLATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreProposal(
      { state, commit },
      { attributesToRetrieve, page, filters }
    ) {
      const index =
        state.order === "asc"
          ? proposalTemplateIndexAsc
          : proposalTemplateIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_PROPOSAL_TEMPLATE}`,
        attributesToRetrieve:
          constant.PROPOSAL_TEMPLATE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.PROPOSAL_TEMPLATE_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      // if (!_.isEmpty(restrictSearchableAttributes)) {
      //   requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      // }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setProposalSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    // Allow search desc only
    // setProposalSearchOrder({ commit }, payload) {
    //   commit(types.SET_SEARCH_ORDER, payload);
    // },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    }
  };
};
