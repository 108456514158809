<template>
  <f7-page v-show="contract && Object.keys(contract).length">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          v-if="!$device.desktop"
          icon-f7="chevron_left"
          @click.native="handleBackAction"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Contract Preview</f7-nav-title>
    </f7-navbar>
    <build-contract-detail
      ref="buildContractDetail"
      :pageHeight="1680"
      :contract="contract || {}"
      :card="card || {}"
      :contact="contact || {}"
      :insuranceCompany="insuranceCompany || {}"
      :setting="setting || {}"
      :contractTemplate="contractTemplate"
      @sendEmail="sendEmailBuildContract"
    ></build-contract-detail>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send Build Contract"
      templateName="build-contract"
      :projectId="card && (card.id || '')"
      :attachments="card && (card.attachmentFiles || [])"
      :listEmails="emailList"
    ></send-email-popup>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BuildContractDetail from "@/components/contract/BuildContractDetail.vue";
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import { firebase, auth } from "../../../../services/firebase.service";
import _ from "lodash";
import {
  BUSINESS_CODE_RESIDENTIAL,
  SENDER_CONFIG_EMAIL
} from "../../../../utility/const";

export default {
  components: {
    BuildContractDetail,
    SendEmailPopup
  },

  data: () => {
    return {
      BUSINESS_CODE_RESIDENTIAL,
      card: {},
      contact: {},
      insuranceCompany: {},
      setting: {},
      activeSection: {}
    };
  },
  computed: {
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters("proposal/contract", ["contract"]),
    ...mapGetters("proposal/template", ["contractTemplate"]),
    ...mapGetters("common/common", ["emailList"]),
    ...mapGetters('common/app-constant', ['tenantId']),
  },
  created() {
    this.getEmailList();
  },
  methods: {
    ...mapActions("proposal/contract", [
      "getContractBys",
      "updateContract",
      "bindContract"
    ]),
    ...mapActions("proposal/template", ["getContractTemplate"]),
    ...mapActions("proposal/project", ["getProjectById", "getActionById"]),
    ...mapActions("proposal/contact", ["getContactById"]),
    ...mapActions("proposal/company", ["getCompanyById"]),
    ...mapActions("setting/app/system", ["getSetting"]),
    ...mapActions("common/email-notification", [
      "getEmailTemplateByTemplateName"
    ]),
    ...mapActions("common/common", ["getEmailList"]),

    async init() {
      let contractNumber =
        this.$f7route.params && (this.$f7route.params.proposalNumber || "");
      if (!contractNumber) return;
      this.$f7.preloader.show();
      const promises = [];
      const contracts = await this.getContractBys([
        {
          prop: "contractNumber",
          val: contractNumber,
          op: "=="
        }
      ]);
      if (contracts && contracts.length) {
        const contract = contracts[0];
        if (!_.isEmpty(contract)) {
          await this.bindContract(contract.id);
          promises.push(this.getContractTemplate());
          promises.push(this.getProjectById(contract.projectId));
          promises.push(this.getSetting(this.tenantId));
          const res = await Promise.all(promises);
          if (res && res.length) {
            this.card = res[1];
            this.setting = res[2];
            if (this.card.contactId) {
              this.contact = await this.getContactById(this.card.contactId);
            }
            if (this.card.insuranceCompanyId) {
              this.insuranceCompany = await this.getCompanyById(
                this.card.insuranceCompanyId
              );
            }
            if (this.card.actions && this.card.actions[0]) {
              this.activeSection = await this.getActionById(
                this.card.actions[0]
              );
            }
          }
        }
      }
      this.$refs.buildContractDetail.lowResDesktopScreen();
      this.$f7.preloader.hide();
    },

    handleBackAction() {
      this.$f7router.navigate("/proposal", {
        pushState: true,
        reloadAll: true
      });
    },

    parseEmailTemplate(htmlContent, valueObject) {
      for (const key in valueObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (valueObject.hasOwnProperty(key)) {
          const value = valueObject[key];
          const reg = new RegExp(`{${key}}`, "g");
          htmlContent = htmlContent.replace(reg, value);
        }
      }
      return htmlContent;
    },

    async getComposer({ templateName, detailUrl }) {
      const composer = {};

      const clientName =
        this.contact &&
        `${this.contact.firstName || ""} ${this.contact.lastName || ""}`;
      const contactEmailAddresses = (this.contact.emails || []).map(
        r => r.value
      );
      const clientEmail = contactEmailAddresses.join(",");

      composer.to = clientEmail || "";
      composer.from = `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`;
      composer.cc = this.user.email || "";

      let templateEmail = await this.getEmailTemplateByTemplateName(
        templateName
      );

      let valueObject = {
        //TODO: Support update client logo in setting (DEV-987)
        logo_url:
          "https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/proposal%2F1618341271487Swadley%20Logo.webp?alt=media&token=87d2a6c8-bc88-4225-84f9-de8f321503b0", //(proposal.pages[0] || {}).components[0].data.logo || "",
        client_name: clientName || "",
        roofing_company_name: this.setting.companyName || "",
        roofing_company_address: this.setting.address || "",
        roofing_company_phone: this.setting.phoneNumber || "",
        detail_url: detailUrl,
        project_title: this.card.title
      };

      composer.message = this.parseEmailTemplate(
        templateEmail.htmlContent,
        valueObject
      );
      composer.subject = this.parseEmailTemplate(
        templateEmail.subject,
        valueObject
      );

      composer.template = templateName;

      composer.attachments = [];
      composer.scheduleSendingTime = firebase.firestore.Timestamp.now();
      composer.isSent = false;
      composer.numberOfAttempt = 0;

      return composer;
    },

    async sendEmailBuildContract() {
      if (
        !this.contract.projectManagerSigner ||
        !this.contract.contractorSigner
      ) {
        return this.$ri.dialog.openWarningDialog({
          title: "Sign Online",
          content: `Please have the project manager and contractor sign before sending an email to the owner.`,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      }

      this.$f7.preloader.show();
      const self = this;
      const composer = await this.getComposer({
        templateName: "build-contract",
        detailUrl: this.contract.contractWebUrl
      });

      await this.$refs.sendEmailPopup.open({
        composer,
        callback: () => {
          self.updateContract({
            id: this.contract.id,
            doc: {
              status: "sent",
              sentAt: firebase.firestore.Timestamp.now(),
              sentBy: auth.currentUser.displayName || auth.currentUser.email
            }
          });
        }
      });

      this.$f7.preloader.hide();
    }
  }
};
</script>
