<template>
  <f7-popup class="demo-popup" :opened="popupAddNewPrice" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Price List</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Next</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <f7-list-input placeholder="Price List Name" label="Price List Name" :value="currentPriceList.displayName"
          @input="currentPriceList.displayName = $event.target.value.trim()" error-message-force clear-button
          :error-message="displayNameErrorMessage">
          <required-asterisk slot="label" />
        </f7-list-input>
      </f7-list>
      <f7-block-title>Date</f7-block-title>
      <f7-list class="no-margin">
        <f7-list-input type="datepicker" placeholder="Start Date" label="Start Date" error-message-force validate
          validate-on-blur :value="currentPriceList.startDate" :error-message="startDateErrorMessage" :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy'
          }" @calendar:change="handleCalendarChange"><required-asterisk slot="label" /></f7-list-input>
        <f7-list-input type="datepicker" label="End Date" placeholder="MM/DD/YYYY" error-message-force validate
          validate-on-blur :value="currentPriceList.endDate" :error-message="endDateErrorMessage" :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
            disabled: date =>
              currentPriceList.startDate &&
              date < new Date(currentPriceList.startDate).setHours(0, 0, 0, 0)
          }" @calendar:change="
            currentPriceList.endDate = $event;
          $f7.calendar.close();
          " clear-button></f7-list-input>
      </f7-list>

      <f7-block-title>Roof Types <required-asterisk /></f7-block-title>
      <multi-select listClass="no-margin" v-model="currentPriceList.roofTypes" :error-message="roofTypeErrorMessage"
        placeholder="Select roof types" :selectionSource="roofTypeList.map(item => ({
          key: item.value,
          value: item.displayName
        }))
          "></multi-select>

      <f7-block-title>Markup <required-asterisk /></f7-block-title>
      <f7-list class="no-margin">
        <f7-list-item>
          <div slot="title">
            <input-percent-or-cash ref="markupInputPercentOrCash" inputClass="bottom-line"
              :value="(currentPriceList.markup || {}).value" :type="(currentPriceList.markup || {}).type"
              :isResetData="!popupAddNewPrice" @inputValue="changeMarkup('value', $event)"
              @changeType="changeMarkup('type', $event)"></input-percent-or-cash>
          </div>
        </f7-list-item>
      </f7-list>

      <f7-block-title>Status <required-asterisk /></f7-block-title>
      <f7-list class="no-margin">
        <f7-list-input type="select" :value="currentPriceList.status"
          @change="currentPriceList.status = $event.target.value">
          <option v-for="item in priceListStatusList" :key="item.value" :value="item.value">{{ item.displayName }}
          </option>
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";

import moment from "moment";
import MultiSelect from "@/components/inputs/MultiSelect.vue";
import InputPercentOrCash from "@/components/inputs/InputPercentOrCash.vue";

import {
  DEFAULT_STATUS_PRICE_LIST, VALIDATION_MESSAGE,
  COLLECTION_PRICE_LIST
} from "@/utility/const";
import Vue from "vue";

export default {
  components: { MultiSelect, InputPercentOrCash },
  props: {
    popupAddNewPrice: Boolean
  },
  created() {
    this.$f7.on("calendarOpen", () => {
      this.$f7.$("input").blur();
    });
  },
  data: () => ({
    product: [],
    currentPriceList: {
      displayName: "",
      startDate: [new Date()],
      endDate: [],
      status: DEFAULT_STATUS_PRICE_LIST,
      roofTypes: [],
      markup: {
        value: 0,
        type: "percent"
      }
    }
  }),
  methods: {
    ...mapActions("price-list/price-list-page/price-list", ["createPrice"]),
    ...mapActions("common/user", ["checkGroupUsers"]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    changeMarkup(key, value) {
      const markup = {
        ...this.currentPriceList.markup,
        [key]: value
      };
      Vue.set(this.currentPriceList, "markup", markup);
    },

    cancel() {
      this.currentPriceList = {
        displayName: "",
        startDate: [new Date()],
        endDate: [],
        status: DEFAULT_STATUS_PRICE_LIST,
        roofTypes: [],
        markup: {
          value: 0,
          type: "percent"
        }
      };
      this.v$.$reset();
      this.$emit("close");
    },

    async add() {
      this.v$.$touch();
      this.$refs.markupInputPercentOrCash?.validate();
      if (this.v$.$invalid || this.$refs.markupInputPercentOrCash?.validate()) {
        return;
      } else {
        var {
          startDate,
          endDate,
          displayName,
          status,
          roofTypes,
          markup
        } = this.currentPriceList;
        if (this.priceList.some(item => item.displayName === displayName)) {
          this.$ri.dialog.openWarningDialog({
            title: "Please choose another one",
            content: "That price list has already existed!",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
        } else {
          this.$f7.preloader.show();
          await this.checkGroupUsers("product-main/price-list");
          let data = {
            displayName,
            status,
            startDate: startDate[0],
            endDate: endDate[0] || "",
            roofTypes: roofTypes || [],
            markup: markup || { value: 0, type: "percent" },
            // products: [],
            isDeleted: false
          };
          this.createPrice(data).then(async res => {
            const arrayOfUids = this.userGroupList.map(
              userGroup => userGroup.uid
            );
            await this.createNotificationByType({
              data: {
                assignees: arrayOfUids,
                project: {
                  title: res.displayName,
                  id: res.id,
                  priceListNumber: res.docNumber,
                  entityName: COLLECTION_PRICE_LIST
                }
              },
              type: "add-price-list"
            });
            this.$f7router.navigate(`/price-list/${res.docNumber}`);
            this.cancel();
            this.$f7.preloader.hide();
          });
        }
      }
    },
    handleCalendarChange(event) {
      this.currentPriceList.startDate = event;
      if (
        this.currentPriceList.endDate[0] < this.currentPriceList.startDate[0]
      ) {
        this.currentPriceList.endDate = event;
      }
      this.$f7.calendar.close();
    },
    // Notifications
    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: "RooferIntel",
          titleRightText: "now",
          subtitle,
          text,
          closeTimeout: 3000
        });
      }
      self.notificationFull.open();
    }
  },

  computed: {
    ...mapGetters("price-list/price-list-page/price-list", ["priceList"]),
    ...mapGetters("price-list/roof-type", ["roofTypeList"]),
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters("common/user", ["userGroupList"]),
    ...mapGetters({
      priceListStatusList:
        "price-list/price-list-page/app-constant/priceListStatusList"
    }),

    displayNameErrorMessage() {
      if (!this.v$.currentPriceList.displayName.$error) return null;
      if (this.v$.currentPriceList.displayName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    roofTypeErrorMessage() {
      if (!this.v$.currentPriceList.roofTypes.$error) return null;
      if (this.v$.currentPriceList.roofTypes.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    startDateErrorMessage() {
      if (this.v$.currentPriceList.startDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    endDateErrorMessage() {
      if (!this.v$.currentPriceList.endDate.timeRules)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE;
      return null;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    const timeRules = () => {
      const startDate = moment(this.currentPriceList.startDate[0]).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(this.currentPriceList.endDate[0]).format(
        "YYYY-MM-DD"
      );
      return !moment(endDate).isBefore(startDate, "day");
    };
    return {
      currentPriceList: {
        displayName: {
          required
        },
        roofTypes: {
          required
        },
        startDate: {
          required
        },
        endDate: {
          timeRules
        },
        markup: {
          value: {
            required
          },
          type: {
            required
          }
        }
      }
    };
  }
};
</script>
