import _ from "lodash";
import { searchClient } from "../../../../services/search.service";
import {
  COLLECTION_OPRATION_CARD,
  OPERATION_CARD_INACTIVE_ATTRIBUTES_TO_RETRIEVE_DEFAULT
} from "../../../../utility/const";
import * as types from "./types";
import algoliaService from "../../../../services/algolia.service";

export default tenant => {
  const projectInactiveIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const ENTITY_NAME = `${COLLECTION_OPRATION_CARD}_inactive`;

  return {
    // get data for filters
    getFilterData({ commit }, { filters }) {
      let hits = [];
      const requestOptions = {
        filters: `entityName:${ENTITY_NAME}`,
        attributesToRetrieve: ["projectStatus"],
        batch: batch => {
          hits = hits.concat(batch);
        }
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return projectInactiveIndexDesc
        .browseObjects({
          query: "",
          ...requestOptions
        })
        .then(() => {
          const status = _.uniqBy(
            hits.map(r => r.projectStatus).filter(r => r !== "?")
          );
          const result = {
            status
          };
          commit(types.SET_FILTER_DATA_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    searchProjectInactive(
      { state, commit },
      {
        query,
        attributesToRetrieve,
        restrictSearchableAttributes,
        filters,
        pageNumber
      }
    ) {
      const index = projectInactiveIndexDesc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${ENTITY_NAME}`,
        attributesToRetrieve: OPERATION_CARD_INACTIVE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "projectNumber",
          "projectName",
          "contactName",
          "companyName",
          "contactPhoneNumber"
        ]
      };
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (!_.isEmpty(attributesToRetrieve)) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (!_.isEmpty(filters)) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit },
      {
        pageName,
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes
      }
    ) {
      const index = projectInactiveIndexDesc;

      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = state.page + 1;
          break;

        case "prev":
          pageNumber = state.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${ENTITY_NAME}`,
        attributesToRetrieve: OPERATION_CARD_INACTIVE_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "projectNumber",
          "projectName",
          "contactName",
          "companyName",
          "contactPhoneNumber"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setProjectInactiveNumberOfRows({ commit }, value) {
      commit(types.SET_NUMBER_OF_ROWS, value);
    },

    async algoliaUpdateProjectInactiveItem(context, id) {
      return await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_card",
        collectionNameCopy: "operation_card_inactive",
        id
      });
    },

    algoliaDeleteProjectInactiveItem(context, id) {
      return projectInactiveIndexDesc.deleteObject(`PINACTIVE_${id}`).wait();
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    }
  };
};
