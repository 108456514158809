<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-show="!$device.desktop || hasProject"
          @click="handleBackAction"
          icon-f7="chevron_left"
        >
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Schedule Of Value</f7-nav-title>
      <f7-nav-right><f7-link @click.native="save">Save</f7-link> </f7-nav-right>
    </f7-navbar>

    <div>
      <schedule-mobilization></schedule-mobilization>
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ScheduleMobilization from "../components/tables/ScheduleMobilization.vue";

export default {
  components: { ScheduleMobilization },

  data() {
    return { hasProject: false, newSchedule: "" };
  },

  computed: {
    ...mapState("schedule/schedule-setting", [
      "mobilization",
      "tax",
      "scheduleInfomation"
    ])
  },

  created() {
    this.hasProject = this.$f7route.params.projectId ? true : false;
    this.newSchedule = this.$f7route.params.newSchedule;
    this.setScheduleInfo({});
    if (this.$f7route.params.id) {
      this.bindScheduleValue(this.$f7route.params.id);
    }
  },

  methods: {
    ...mapActions("common/schedule-value", [
      "updateScheduleValue",
      "deleteScheduleValue",
      "bindScheduleValue"
    ]),
    ...mapActions("schedule/schedule-setting", ["setScheduleInfo"]),
    ...mapActions("common/project", ["getProjectById", "getActionById"]),

    handleBackAction() {
      if (this.$f7route.params.projectId && this.$f7route.params.actionId) {
        if (this.newSchedule === "new") {
          this.deleteScheduleValue(this.$f7route.params.id);
        }
        this.navigateToBoard();
      } else {
        this.$f7router.navigate("/schedule-of-value", {
          pushState: true
        });
      }
    },

    async navigateToBoard() {
      this.$f7.preloader.show();
      const res = await Promise.all([
        this.$f7route.params.projectId &&
          this.getProjectById(this.$f7route.params.projectId),
        this.$f7route.params.actionId &&
          this.getActionById(this.$f7route.params.actionId)
      ]);
      const card = res[0] || {};
      const action = res[1] || {};
      this.$f7router.navigate(
        `/dashboard/${this.$f7route.params.boardType}/commercial/pre-construction/project/${card.cardNumber}/action/${action.code}`,
        {
          pushState: true,
          reloadAll: true
        }
      );
      this.$f7.preloader.hide();
    },

    save() {
      this.$f7.preloader.show();
      // do not delete when navigate back
      this.newSchedule = "";

      this.updateScheduleValue({
        id: this.$f7route.params.id,
        doc: {
          mobilization: this.mobilization,
          tax: this.tax,
          scheduleInfomation: this.scheduleInfomation
        }
      })
        .then(() => {
          if (this.hasProject) {
            this.handleBackAction();
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
  }
};
</script>
