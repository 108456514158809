<template>
  <!-- Building Measurement -->
  <f7-list :inset="$device.desktop">
    <f7-row class="full-row">
      <f7-col width="100" xsmall="50">
        <f7-block-title>
          Building Information
        </f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <!-- Building/Section# -->
          <f7-list-input
            label="Specification #"
            :value="currentBuilding.buildingName"
            readonly
          ></f7-list-input>
          <f7-list-input
            label="Number of Building/Sections"
            :value="currentBuilding.numberOfCopy"
            readonly
          ></f7-list-input>

          <!-- Slope Type -->
          <f7-list-input
            label="Slope Type"
            :value="
              isAllSlopeType
                ? 'All'
                : slopeType(currentBuilding.slopeType).displayName
            "
            readonly
          ></f7-list-input>

          <!-- Roof Type -->
          <f7-list-input
            label="Roof Type"
            :value="
              isAllRoofType
                ? 'All'
                : roofTypeBy(currentBuilding.roofType).displayName
            "
            readonly
          ></f7-list-input>

          <!-- Tax Apply -->
          <f7-list-input
            label="Tax Apply"
            :value="taxApplyTypeBy(estimate.taxApplyType).displayName"
            readonly
          ></f7-list-input>
        </f7-list>
      </f7-col>

      <f7-col width="100" xsmall="50">
        <f7-block-title> &nbsp;</f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <!-- Price list -->
          <f7-list-input
            label="Price List"
            :value="priceListText"
            readonly
          ></f7-list-input>

          <!-- Manufacturers -->
          <f7-list-input
            label="Manufacturers"
            :value="manufacturerList"
            readonly
          ></f7-list-input>

          <!-- Warranty -->
          <f7-list-input
            label="Warranty"
            :value="tpoWarrantyOption(currentBuilding.warrantyType).displayName"
            readonly
          >
          </f7-list-input>

          <!-- Warranty notes -->
          <f7-list-input
            label="Warranty Notes"
            :value="currentBuilding.warrantyNotes"
            readonly
          ></f7-list-input>

          <!-- What type of substrate? (optional) -->
          <!-- <f7-list-input
            label="Type of substrate"
            :value="substrate(currentBuilding.substrate).displayName"
            readonly
          ></f7-list-input> -->
        </f7-list>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col
        ><f7-list media-list v-if="currentBuilding.description">
          <f7-block-title>
            Description
          </f7-block-title>
          <f7-list-input
            type="textarea"
            :value="currentBuilding.description"
            readonly
            resizable
          ></f7-list-input> </f7-list></f7-col
    ></f7-row>
  </f7-list>
</template>

<script>
// import DynamicMutiSelect from "../inputs/DynamicMultiSelect.vue";

import { mapGetters } from "vuex";
import _ from "lodash";
import { ROOF_TYPE_TPO, TAX_APPLY_TYPE } from "@/utility/const";

import buildingMixins from "../../utility/building-mixins";

export default {
  // components: { DynamicMutiSelect },

  props: { isEditing: Boolean },

  mixins: [buildingMixins],

  data() {
    return {
      insulationLayer: [],
      tpoWarrantyOptionList: [],
      ROOF_TYPE_TPO,
      TAX_APPLY_TYPE
    };
  },

  computed: {
    ...mapGetters("common/app-constant", [
      "roofTypeBy",
      "slopeType",
      "tpoWarrantyOption",
      "substrate"
    ]),

    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),

    ...mapGetters("estimate/estimate-page/price-list", ["priceList"]),

    manufacturerList() {
      let manus = (this.currentBuilding.productData || []).reduce(
        (manufacturers, r) => {
          let mns = [
            ...new Set(
              r.productList.map(x => (x || {}).manufacturer).filter(z => z)
            )
          ];
          _.remove(mns, r => manufacturers.includes(r));
          return (manufacturers || []).concat(mns || []);
        },
        []
      );
      return manus.join(", ");
    },

    priceListText() {
      if (
        _.isEmpty(this.priceList.priceListNumber) &&
        _.isEmpty(this.priceList.displayName)
      ) {
        return "";
      }

      return this.priceList.priceListNumber + ": " + this.priceList.displayName;
    },

    taxApplyTypeBy() {
      return type => {
        return this.TAX_APPLY_TYPE.find(item => item.value === type) || {};
      };
    },
    isAllSlopeType() {
      return (this.estimate || {}).slopeType === "all-slope-type";
    },
    isAllRoofType() {
      return (this.estimate || {}).roofType === "all-roof-type";
    }
  },

  methods: {
    changeManufacturers() {}
  }
};
</script>

<style></style>
