<template>
  <div>
    <f7-list :inset="$device.desktop">
      <!-- select options -->
      <f7-list media-list>
        <!-- Project # -->
        <template v-if="hasProject">
          <f7-list-item>
            <div class="list-item-inner-start" slot="inner-start">
              Project Title<required-asterisk />
            </div>
            <div class="list-item-title" slot="title">
              {{ project ? project.title : "" }}
            </div>
            <input-icon slot="media" icon="cube_box"></input-icon>
            <div slot="text" style="color: red;">
              {{ projectErrorMessage }}
            </div>
          </f7-list-item>
          <f7-list-item>
            <div class="list-item-inner-start" slot="inner-start">
              Job Title<required-asterisk />
            </div>
            <div class="list-item-title" slot="title">
              {{ project ? `${project.cardNumber} - ${project.title}` : "" }}
            </div>
            <input-icon slot="media" icon="cube_box"></input-icon>
            <div slot="text" style="color: red;">
              {{ projectErrorMessage }}
            </div>
          </f7-list-item>
        </template>

        <f7-list-input v-else placeholder="Select Job" type="text" :value="currentProject && currentProject.cardNumber && currentProject.title
          ? currentProject.cardNumber + ' - ' + currentProject.title
          : ''
          " @focus="selectProject" error-message-force validate validate-on-blur :error-message="projectErrorMessage"
          class="job-title">
          <input-icon slot="media" icon="cube_box"></input-icon>
          <div class="list-item-inner-start" slot="label">
            Job Title<required-asterisk />
          </div>
        </f7-list-input>

        <!-- Estimate Name -->
        <f7-list-input class="first-input" label="Estimate Name" placeholder="Enter Estimate Name" type="text"
          :value="currentEstimate.estimateName" @input="setEstimateValue('estimateName', $event.target.value)">
          <input-icon slot="media" icon="doc_text"></input-icon>
        </f7-list-input>

        <!-- Property -->
        <f7-list-item v-if="currentEstimate.businessCode === 'commercial'">
          <div class="list-item-inner-start" slot="inner-start">
            Property Name
          </div>
          <div class="list-item-title" slot="title">
            <span>
              {{ currentEstimate.propertyName || "" }}
            </span>
          </div>
          <input-icon slot="media" icon="building_2_fill"></input-icon>
        </f7-list-item>

        <f7-list-item v-if="
          currentEstimate.businessCode === 'residential' ||
          currentEstimate.businessCode === 'service'
        ">
          <div class="list-item-inner-start" slot="inner-start">
            Contact Name
          </div>
          <div class="list-item-title" slot="title">
            {{ currentEstimate.contactName || "" }}
          </div>
          <input-icon slot="media" icon="person"></input-icon>
        </f7-list-item>
      </f7-list>

      <!-- Status -->
      <f7-list media-list>
        <f7-list-input type="select" label="Status" :value="currentEstimate.status"
          @change="setEstimateValue('status', $event.target.value)">
          <input-icon class="status-icon" slot="media" icon="checkmark_seal"></input-icon>
          <option v-for="item in estimateStatusOptionList" :key="item.id" :value="item.value">{{ item.displayName }}
          </option>
        </f7-list-input>
      </f7-list>

      <!-- Description -->
      <f7-list media-list>
        <f7-list-input label="Description" type="textarea" placeholder="Enter description..."
          :value="currentEstimate.description" @input="setEstimateValue('description', $event.target.value)"><input-icon
            class="desc-icon" slot="media" icon="doc"></input-icon></f7-list-input>
      </f7-list>
    </f7-list>

    <!-- popups -->
    <div>
      <roof-type-list-popup ref="selectRoofType" @onSelected="roofTypeChanged($event)"></roof-type-list-popup>
      <project-list-popup ref="selectProject" :queryFilters="queryFilters"
        @onSelected="onSelectedProject"></project-list-popup>
    </div>
  </div>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";

import RoofTypeListPopup from "../popup/RoofTypeListPopup.vue";
import ProjectListPopup from "@/components/popups/ProjectListPopup.vue";

import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import _ from "lodash";

import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";

import { getWorkType, getBuildingInfoDefault } from "../../utility/calculation";

import { TAX_APPLY_TYPE, VALIDATION_MESSAGE } from "@/utility/const";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE
} from "../../../../utility/const";

export default {
  components: {
    RoofTypeListPopup,
    ProjectListPopup,
    InputIcon
  },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      TAX_APPLY_TYPE,
      isEndDate: false,
      currentProject: {}
    };
  },
  async created() {
    if (_.isEmpty(this.priceListDefault)) {
      await this.getPriceListDefault();
    }
  },
  computed: {
    ...mapGetters({
      currentEstimate: "estimate/estimate-page/estimate/currentEstimate"
    }),
    ...mapGetters("common/app-constant", ["estimateStatusOptionList"]),
    ...mapGetters("estimate/estimate-page/project", [
      "project",
      "latestProject",
      "projectById",
      "projectList"
    ]),
    ...mapGetters("estimate/estimate-page/price-list", ["priceListDefault"]),
    projectErrorMessage() {
      if (!this.v$.currentEstimate.$error) return "";
      if (this.v$.currentEstimate.projectId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    },
    queryFilters() {
      let estimateFilter = "";
      if (this.$f7route.params.clientType === "contact") {
        estimateFilter += `contactId:${this.$f7route.params.clientId}`;
      } else if (this.$f7route.params.clientType === "company") {
        estimateFilter += `companyId:${this.$f7route.params.clientId}`;
      }
      const queryFilter = `${estimateFilter}`;

      return queryFilter;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/property", ["getPropertyById"]),
    ...mapActions("estimate/estimate-page/contact", ["getContactById"]),
    ...mapActions("estimate/estimate-page/company", ["getCompanyById"]),
    ...mapActions("estimate/estimate-page/project", [
      "bindProjectsByProperty",
      "unbindProjectList",
      "getProject",
      "setProject"
    ]),
    ...mapActions("estimate/estimate-page/estimate", [
      "setCurrentEstimateField",
      "initTax"
    ]),

    ...mapActions("estimate/estimate-page/price-list", [
      "bindPriceListItem",
      "unbindPriceListItem",
      "getPriceListDefault"
    ]),

    setEstimateValue(prop, value) {
      this.setCurrentEstimateField({ fieldName: prop, value });
    },

    validate() {
      this.v$.$touch();
      return !this.v$.$invalid;
    },

    // Select project
    async onSelectedProject(projectId = "") {
      const project = await this.getProject(projectId);
      this.currentProject = project;
      if (!project) {
        return;
      }

      this.setProject(project);

      this.setEstimateValue("projectId", projectId);
      this.setEstimateValue("projectName", project.title);
      this.setEstimateValue("projectNumber", project.cardNumber);
      this.setEstimateValue("businessCode", project.businessCode);
      if (this.currentEstimate.businessCode === BUSINESS_CODE_SERVICE) {
        this.initTax();
      }

      if (project.contactId) {
        this.getContactById(project.contactId).then(contact => {
          if (contact) {
            this.setEstimateValue("contactId", contact.id);
            this.setEstimateValue(
              "contactName",
              (
                (contact.firstName || "") +
                " " +
                (contact.lastName || "")
              ).trim()
            );
          }
        });
      }
      if (project.companyId) {
        this.getCompanyById(project.companyId).then(company => {
          if (company) {
            this.setEstimateValue("companyId", company.id);
            this.setEstimateValue("companyName", company.companyName || "");
          }
        });
      }

      if (
        project.propertyId &&
        (project.businessCode === BUSINESS_CODE_COMMERCIAL ||
          (project.businessCode === BUSINESS_CODE_SERVICE &&
            project.customerType === BUSINESS_CODE_COMMERCIAL))
      ) {
        this.getPropertyById(project.propertyId).then(property => {
          if (property) {
            this.setEstimateValue("propertyId", property.id);
            this.setEstimateValue("propertyName", property.propertyName || "");
            this.setEstimateValue("estimateName", property.propertyName || "");
          }
        });
      } else {
        this.setEstimateValue("estimateName", project ? project.title : "");
      }

      const workType = getWorkType(project);
      const buildingInfoDefault = await getBuildingInfoDefault(project);

      this.saveBuildingValue("roofType", buildingInfoDefault.roofType || "");
      this.saveBuildingValue("slopeType", buildingInfoDefault.slopeType || "");
      this.saveBuildingValue(
        "workType",
        workType || buildingInfoDefault.workType || ""
      );

      this.saveBuildingValue("priceListId", this.priceListDefault.id || "");

      if (this.priceListDefault.id) {
        await this.bindPriceListItem(this.priceListDefault.id);
      }

      this.$emit("onSelectedProject");
    },

    // Set building name
    setBuildingName(index, name) {
      const buildings = _.cloneDeep(this.currentEstimate.buildings) || [];
      if (index < 0 || index > buildings.length - 1) {
        return;
      }

      buildings[index].buildingName = name;
      this.setEstimateValue("buildings", buildings);
    },

    // Open selecting project popup
    selectProject() {
      this.$el.querySelector(".job-title input").blur();
      this.$refs.selectProject.open();
    }

    // Open selecting property popup
    // selectProperty() {
    //   this.$refs.selectProperty.open();
    // }
  },

  beforeDestroy() {
    this.currentProject = {};
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    currentEstimate: {
      projectId: {
        required
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.btn-step {
  margin: 0 5px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
