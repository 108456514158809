import _ from "lodash";
import moment from "moment";

export default {
  priceList: state => state.priceList,
  itemPriceList: state => state.priceListItem,
  priceListItemList: state =>
    state.priceListItemList
      .filter(item => {
        const compareDate = moment();
        const startDate =
          item && item.startDate && moment(item.startDate.toDate());
        const isStatusValid =
          !item.status.includes("pl-inactive") &&
          !item.status.includes("pl-incomplete");
        if (item && item.endDate) {
          const endDate = moment(item.endDate.toDate());
          return (
            isStatusValid &&
            compareDate.isBetween(startDate, endDate, "days", true)
          );
        } else {
          return isStatusValid && compareDate.isSameOrAfter(startDate, "days");
        }
      })
      .sort((a, b) => a.createdAt - b.createdAt),

  priceListItem: (state, getters, rootState, rootGetters) => {
    if (_.isEmpty(state.priceListItem)) {
      return null;
    }
    const productList =
      rootGetters["purchase-order/product-item/productItemList"] || [];

    const productRefs = (
      (state.priceListItem && state.priceListItem.productRefs) ||
      []
    ).map(productRef => {
      const product =
        productList.find(product => product.id === productRef.productId) || {};
      return {
        ...productRef,
        product: { ...product, price: productRef.price }
      };
    });
    return {
      ...state.priceListItem,
      productRefs
    };
  }
};
