<template>
  <data-table
    :headers="headers"
    :items="checkPayableList"
    :pageSize="(checkPayableList || []).length"
    :isPinHeader="true"
  >
    <template v-slot:body="{ item }">
      <td>{{ item.Id }}</td>
      <td>{{ item.paidDate | MMDDYYYY }}</td>
      <td>{{ item.checkAmount | currencyUSD }}</td>
      <td>{{ item.invoiceNumber }}</td>
      <td>{{ item.Balance | currencyUSD }}</td>
      <td v-if="isActiveTab" class="checkbox-cell" style="width: 50px">
        <f7-checkbox
          :checked="item.checked"
          @change="$emit('checkBoxChange', $event.target.checked, item.id)"
        ></f7-checkbox>
      </td>
    </template>
  </data-table>
</template>
<script>
import DataTable from "@/components/datatables";
export default {
  components: {
    DataTable
  },
  props: {
    checkPayableList: Array,
    isActiveTab: Boolean
  },
  computed: {
    headers() {
      return [
        {
          text: "Check#",
          value: "Id",
          sortable: false,
          align: "left"
        },
        {
          text: "Paid Date",
          value: "paidDate",
          sortable: false,
          align: "left"
        },
        {
          text: "Check Amount",
          value: "checkAmount",
          sortable: false,
          align: "left"
        },
        {
          text: "Invoice Number",
          value: "invoiceNumber",
          sortable: false,
          align: "left"
        },
        {
          text: "Invoice Amount",
          value: "Balance",
          sortable: false,
          align: "left"
        },
        {
          text: "",
          value: "",
          sortable: false,
          align: "left"
        }
      ];
    }
  }
};
</script>
