<template>
  <div>
    <input
      ref="imageInput"
      type="file"
      @change="onUploadImage"
      class="display-none"
      multiple
      accept=".mp4, .png, .jpg, .jpeg, .webp, .mov, .webM, .m4v"
    />
    <progress-bar ref="progressBar"></progress-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ImageTools from "@/utility/image-tools";
import ProgressBar from "@/components/progress-bar/ProgressBar.vue";

export default {
  components: { ProgressBar },
  props: {
    albumId: String,
    showProgressBar: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions("swimlane/photo", ["uploadPhoto", "createProjectPhoto"]),

    openUploadPhoto() {
      this.$refs.imageInput.click();
    },
    onStartUpload() {
      this.$refs.progressBar.show();
    },
    onUploadProgress({ photoCount, videoCount, photoTotal, videoTotal }) {
      this.$refs.progressBar.setText(
        `${photoTotal > 0 ? `${photoCount}/${photoTotal} photos` : ""}
        ${videoTotal > 0 && photoTotal > 0 ? ", " : ""}
        ${videoTotal > 0 ? `${videoCount}/${videoTotal} videos` : ""}`
      );
      this.$refs.progressBar.setValue(
        ((photoCount + videoCount) * 100) / (photoTotal + videoTotal)
      );
      if (photoCount == photoTotal && videoCount == videoTotal) {
        this.$refs.progressBar.hide();
      }
    },
    async onUploadImage(event) {
      const app = this;
      let files = app.$refs.imageInput.files;
      let photoCount = 0;
      let videoCount = 0;
      const failedVideos = [];
      let imgTool = new ImageTools(this.$f7.device);
      const imageFilesCount = Array.from(files).filter(file =>
        file.type.startsWith("image/")
      ).length;
      const videoFilesCount = Array.from(files).filter(file =>
        file.type.startsWith("video/")
      ).length;
      if (files.length > 0 && this.showProgressBar) this.onStartUpload();
      if (!this.showProgressBar) app.$f7.preloader.show();

      const uploadPromises = [];

      for (let i = 0; i < files.length; i += 1) {
        if (
          files[i].type === "image/png" ||
          files[i].type === "image/jpeg" ||
          files[i].type === "image/jpg" ||
          files[i].type === "image/webp"
        ) {
          const uploadPromise = (async () => {
            let photo = {};
            const name = files[i].name;
            const resizedFile = await imgTool.resize(files[i], {
              width: 256,
              height: 256
            });
            if (!resizedFile.name) resizedFile.name = name;

            const thumbnailData = await app.uploadPhoto({
              file: resizedFile,
              projectId: app.card.id
            });
            photo = {
              thumbnailUrl: thumbnailData.url,
              thumbnailFullPath: thumbnailData.fullPath
            };

            if (app.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount
              });
            }

            if (photo.thumbnailUrl) {
              const fullSizeFile = await imgTool.resize(files[i], {
                width: 1920,
                height: 1080
              });
              if (!fullSizeFile.name) fullSizeFile.name = name;

              const photoData = await app.uploadPhoto({
                file: fullSizeFile,
                projectId: app.card.id
              });
              photo = {
                ...photo,
                projectId: app.card.id,
                photoUrl: photoData.url,
                photoName: name,
                albumId: app.albumId ? app.albumId : null,
                photoFullPath: photoData.fullPath
              };

              await app.createProjectPhoto(photo);
              photoCount += 1;

              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount
                });
              }
            }
          })().catch(() => {
            photoCount += 1;
            if (this.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount
              });
            }
            failedVideos.push(files[i].name);
          });

          uploadPromises.push(uploadPromise);
        } else if (files[i].type.startsWith("video/")) {
          const uploadPromise = (async () => {
            try {
              let photo = {};
              const name = files[i].name;
              const cover = await imgTool.getVideoThumbnail(files[i]);
              const fileThumb = new File([cover], name + ".webp", {
                type: "image/webp"
              });

              const resizedFile = await imgTool.resize(fileThumb, {
                width: 256,
                height: 256
              });
              if (!resizedFile.name) resizedFile.name = name;

              const thumbnailData = await app.uploadPhoto({
                file: resizedFile,
                projectId: app.card.id
              });
              photo = {
                thumbnailUrl: thumbnailData.url,
                thumbnailFullPath: thumbnailData.fullPath
              };

              if (app.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount
                });
              }

              if (photo.thumbnailUrl) {
                const videoData = await app.uploadPhoto({
                  file: files[i],
                  projectId: app.card.id
                });
                photo = {
                  ...photo,
                  projectId: app.card.id,
                  photoUrl: videoData.url,
                  photoName: name,
                  albumId: app.albumId ? app.albumId : null,
                  photoFullPath: videoData.fullPath
                };

                await app.createProjectPhoto(photo);
                videoCount += 1;

                if (this.showProgressBar) {
                  app.onUploadProgress({
                    photoCount: photoCount,
                    videoCount: videoCount,
                    photoTotal: imageFilesCount,
                    videoTotal: videoFilesCount
                  });
                }
              }
            } catch (error) {
              videoCount += 1;
              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount
                });
              }
              failedVideos.push(files[i].name);
            }
          })();

          uploadPromises.push(uploadPromise);
        } else {
          failedVideos.push(files[i].name);
        }
      }

      await Promise.all(uploadPromises);
      if (photoCount == imageFilesCount && videoCount == videoFilesCount) {
        event.target.value = null;
        if (!this.showProgressBar) app.$f7.preloader.hide();
      }
      if (failedVideos.length > 0) {
        this.$refs.progressBar.hide();
        const failedVideosContent = `There's a problem with the files.</br>${failedVideos
          .map((file, index) => `${index + 1}. ${file}`)
          .join("<br>")}`;
        this.openWarningDialogUploadPhoto(failedVideosContent);
      }
    },
    openWarningDialogUploadPhoto(content) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Invalid file!",
        content: content,
        hideCancelButton: true,
        cssClass: "photo-dialog",
        onClick: (_sefl, index) => {
          if (index === 0) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      card: "swimlane/card/detail"
    })
  }
};
</script>
