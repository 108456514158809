<template>
  <div>
    <f7-block-title>Notes</f7-block-title>

    <f7-list media-list>
      <f7-list-input
        type="texteditor"
        placeholder="Enter note..."
        resizable
        :value="isNoteContent === false ? noteContent : ''"
        @texteditor:change="value => (noteContent = value)"
        ><div slot="media">
          <user-avatar
            :avatarSize="35"
            :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
            :name="userInfo(uid) ? userInfo(uid).displayName : ''"
          >
          </user-avatar>
        </div>
      </f7-list-input>
    </f7-list>

    <f7-block>
      <f7-row tag="p" v-show="noteContent != ''">
        <f7-col tag="span" width="75"></f7-col>
        <f7-col tag="span" width="25">
          <f7-button raised @click.native="saveNote">Save</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-list inset media-list v-for="(item, index) in noteList" :key="index">
      <f7-list-item>
        <div slot="media">
          <user-avatar
            :avatarSize="35"
            :imageUrl="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').photoURL
                : ''
            "
            :name="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').displayName
                : item.createdBy
            "
          >
          </user-avatar>
        </div>
        <div slot="title" v-show="$device.desktop || $device.ipad">
          <strong>{{ item.createdBy }}</strong> added a
          {{ item.title }}
        </div>
        <div v-html="item.htmlContent"></div>
        <div slot="after">
          {{ toLocaleString(item.createdAt) }}
        </div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { firebase, auth } from "../../../../services/firebase.service";
import _ from "lodash";
import { mapState } from "vuex";

export default {
  components: { UserAvatar },

  data: () => {
    return {
      noteContent: ""
    };
  },

  props: {
    value: { type: Array, default: () => [] },
    isNoteContent: { type: Boolean, default: false }
  },

  computed: {
    ...mapState("web-contact/user", ["userList"]),

    noteList() {
      return _.cloneDeep(this.value).sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds;
      });
    },

    userInfo() {
      return uid =>
        this.userList.find(user => {
          return user.uid === uid;
        });
    },

    uid() {
      return auth.currentUser.uid;
    }
  },

  methods: {
    saveNote() {
      let noteList = _.cloneDeep(this.value) || [];

      noteList.push({
        title: "Note",
        code: "note",
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        createdById: auth.currentUser.uid,
        htmlContent: this.noteContent
      });

      this.$emit("change", noteList);
      this.noteContent = "";
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    }
  }
};
</script>

<style lang="scss" scoped>
.note-editer {
  margin: 0px;
}
</style>
