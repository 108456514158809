import * as types from "./types";
import Vue from "vue";
// import _ from "lodash";

export default {
  [types.SET_USERS_LIST](state, users) {
    Vue.set(state, "users", users);
  },

  [types.SET_QB_USERS_LIST](state, users) {
    Vue.set(state, "qbUsers", users);
  },

  [types.SET_USER](state, user) {
    if (user) {
      for (const key in user) {
        if (user.hasOwnProperty(key)) {
          const value = user[key];
          Vue.set(state.user, key, value);
        }
      }
    } else {
      Vue.set(state, "user", user);
    }
  },

  [types.ADD_USER_LIST](state, user) {
    Vue.set(state.users, state.users.length, user);
  },

  [types.REMOVE_USER_LIST](state, uid) {
    const index = state.users.findIndex(user => {
      return user.uid === uid;
    });

    if (index > 0) {
      Vue.delete(state.users, index);
    }
  },
  [types.RESET_USER](state) {
    Vue.set(state, "user", {
      customClaims: {}
    });
  }
};
