<template>
  <div>
    <f7-block-header>{{ title }}</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <template v-if="useDefaultTemplate">
        <f7-list-item
          v-for="(email, index) in value"
          :key="index"
          @click.native="showDetails(email)"
        >
          <a class="cursor-pointer" slot="title">{{ email.subject }}</a>
          <div slot="footer">
            Sent at {{ toDisplayDateString(email.createdAt) }} by
            {{ email.createdBy }}
          </div>
        </f7-list-item>
      </template>
      <template v-else>
        <f7-list-item
          v-for="(email, index) in value"
          :key="index"
          @click.native="showDetails(email)"
        >
          <slot name="item" :email="email"></slot>
        </f7-list-item>
      </template>
    </f7-list>

    <email-details-popup ref="emailDetailsPopup"></email-details-popup>
  </div>
</template>

<script>
import { toDisplayDateString } from "../../../../utility/datetime";
import EmailDetailsPopup from "../popup/EmailDetailsPopup.vue";

export default {
  components: {
    EmailDetailsPopup
  },

  props: {
    value: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    useDefaultTemplate: { type: Boolean, default: true }
  },

  methods: {
    showDetails(email) {
      this.$refs.emailDetailsPopup.show(email);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    }
  }
};
</script>

<style lang="scss" scoped></style>
