import { FirebaseActions } from "../../services/firebase.service";
import _ from "lodash";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/administration_user`,
    "user"
  );

  return {
    getUser: ActionsTemplate.getDocument,
    createUser: ActionsTemplate.createDocumentWithCustomeID,
    updateUser: ActionsTemplate.updateDocument,

    addTeamRef({ dispatch }, { id, teamID }) {
      dispatch("getUser", id).then(user => {
        if (_.isEmpty(user)) {
          const doc = {
            id,
            teamIDs: []
          };
          dispatch("createUser", doc).then(() => {
            dispatch("updateTeamRef", { id, teamID });
          });
        } else {
          dispatch("updateTeamRef", { id, teamID });
        }
      });
    },
    getUserForGroupById({ dispatch, commit }, id) {
      dispatch("getUser", id).then(userForGroup => {
        commit(types.SET_USER, userForGroup);
      });
    },
    updateTeamRef({ dispatch }, { id, teamID }) {
      dispatch("getUser", id).then(user => {
        if (user) {
          const teamIDs = _.union(user.teamIDs || [], [teamID]);
          dispatch("updateUser", {
            id,
            doc: { teamIDs }
          });
        }
      });
    },

    removeTeamRef({ dispatch }, { id, teamID }) {
      dispatch("getUser", id).then(user => {
        if (user) {
          const teamIDs = _.remove(user.teamIDs || [], teamId => {
            return teamId != teamID;
          });
          dispatch("updateUser", {
            id,
            doc: { teamIDs }
          });
        }
      });
    },

    addGroupRef({ dispatch }, { id, groupID }) {
      dispatch("getUser", id).then(user => {
        if (_.isEmpty(user)) {
          const doc = {
            id,
            groupIDs: []
          };
          dispatch("createUser", doc).then(() => {
            dispatch("updateGroupRef", { id, groupID });
          });
        } else {
          dispatch("updateGroupRef", { id, groupID });
        }
      });
    },

    updateGroupRef({ dispatch }, { id, groupID }) {
      dispatch("getUser", id).then(user => {
        if (user) {
          const groupIDs = _.union(user.groupIDs || [], [groupID]);
          dispatch("updateUser", {
            id,
            doc: { groupIDs }
          });
        }
      });
    },

    removeGroupRef({ dispatch }, { id, groupID }) {
      dispatch("getUser", id).then(user => {
        if (user) {
          const groupIDs = _.remove(user.groupIDs || [], groupId => {
            return groupId != groupID;
          });
          dispatch("updateUser", {
            id,
            doc: { groupIDs }
          });
        }
      });
    },
  };
};
