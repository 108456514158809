<template>
  <f7-popup class="demo-popup" :opened="isShow" @popup:closed="$emit('close')">
    <f7-page>
      <f7-navbar :title="title">
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <invoice-data-table :invoiceList="dataSource"></invoice-data-table>
    </f7-page>
  </f7-popup>
</template>
<script>
import InvoiceDataTable from "@/plugins/quickbooks/components/datatable/InvoiceDataTable.vue";
export default {
  props: {
    isShow: Boolean,
    title: String,
    dataSource: Array
  },
  components: {
    InvoiceDataTable
  }
};
</script>
