import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import * as types from "./types";

export default tenant => {
  const letterActions = new FirebaseActions(
    `/system_client/${tenant}/letter`,
    "letter"
  );

  return {
    bindLetterListBys: letterActions.bindCollectionBys,
    unbindLetterListBy: letterActions.unbindCollection,
    bindLetter: letterActions.bindDocument,
    unbindLetter: letterActions.unbindDocument,
    createLetter: letterActions.createDocument,
    updateLetter: letterActions.updateDocument,
    getLetter: letterActions.getDocument,
    getLetterBys: letterActions.getDocumentBys,

    async getLetterByProjectId({ dispatch, commit }, projectId) {
      const list = await dispatch("getLetterBys", [
        {
          prop: "projectId",
          val: projectId,
          op: "=="
        },
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        }
      ]);

      commit(types.SET_LETTER_LIST, list);
      return list;
    },

    deleteLetter({ dispatch }, id) {
      return dispatch("updateLetter", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
    }
  };
};
