<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Pricing Refresh</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="update()">Update</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <p>
          There is/are new pricing impacting the estimate.
        </p>

        <div v-for="(item, index) in contentUpdate" :key="index">
          <p>
            <b>{{ item.buildingName }}: {{ item.title }}</b>
          </p>
          <data-table
            v-if="item.productChangePrice"
            :headers="headers"
            :items="item.productChangePrice || []"
            :pageSize="(item.productChangePrice || []).length || 0"
            :checkbox="true"
            @selected:checkbox="selectedItems($event, item.buildingId)"
          >
            <template v-slot:body="{ item }">
              <td>{{ item.productItem }}</td>
              <td class="numeric-cell">{{ item.orderQty || 0 }}</td>
              <td class="numeric-cell">{{ item.price | currencyUSD }}</td>
              <td class="numeric-cell">{{ item.newPrice | currencyUSD }}</td>
              <td class="numeric-cell">
                {{ (item.newPrice - item.price) | currencyUSD }}
              </td>
              <td class="numeric-cell">
                {{
                  ((item.newPrice - item.price) * (item.orderQty || 0))
                    | currencyUSD
                }}
              </td>
            </template>
            <template v-slot:footer>
              <f7-row no-gap class="table-footer">
                <f7-col width="80"><b>SubTotal</b></f7-col>
                <f7-col width="20">
                  {{ subTotalDiff(item.productChangePrice) | currencyUSD }}
                </f7-col>
              </f7-row>
            </template>
          </data-table>
        </div>
        <p>
          <i>
            <span style="text-decoration-line: underline">Note</span>: Tax and
            Profit margins haven't been including in the calculation.
          </i>
        </p>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from "@/components/datatables";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      popupOpened: false,
      contentUpdate: [],
      selectedProductByBuilding: {}
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", ["estimate"]),

    headers() {
      return [
        {
          text: "Product Item",
          align: "left",
          sortable: false,
          value: "productItem",
          width: "25%"
        },
        {
          text: "Qty",
          align: "right",
          sortable: false,
          value: "qty",
          width: "7%"
        },
        {
          text: "Pre.Price",
          align: "right",
          sortable: false,
          value: "prePrice",
          width: "15%"
        },
        {
          text: "New.Price",
          align: "right",
          sortable: false,
          value: "newPrice",
          width: "15%"
        },
        {
          text: "Pricing Different",
          align: "right",
          sortable: false,
          value: "pricingDiff",
          width: "18%"
        },
        {
          text: "SubTotal Different",
          align: "right",
          sortable: false,
          value: "subTotalDiff",
          width: "20%"
        }
      ];
    },

    subTotalDiff() {
      return productChangePrice => {
        return (productChangePrice || []).reduce(
          (accumulator, item) =>
            (item.newPrice - item.price) * (item.orderQty || 0) + accumulator,
          0
        );
      };
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "updateEstimate",
      "updateBuilding",
      "createEstimateHistory",
      "bindEstimateHistoryListBys",
      "bindBuildingListBys"
    ]),

    cancel() {
      this.popupOpened = false;
      this.selectedProductByBuilding = {};
    },

    openPopup(contentUpdate) {
      this.contentUpdate = contentUpdate;
      this.popupOpened = true;
    },

    selectedItems(data, buildingId) {
      this.selectedProductByBuilding[buildingId] = data;
    },

    update() {
      this.$f7.preloader.show();
      const promises = [];
      for (const [buildingId, products] of Object.entries(
        this.selectedProductByBuilding
      )) {
        const building = this.contentUpdate.find(
          r => r.buildingId === buildingId
        ).building;
        let newProductData = _.cloneDeep(building.productData);
        let newAssemplyList = _.cloneDeep(building.assemblyList) || [];
        products.forEach(r => {
          // update product data
          const sectionIndex = newProductData.findIndex(
            section => section.sectionId === r.sectionId
          );
          let productIndex = newProductData[sectionIndex].productList.findIndex(
            product => product.id === r.id
          );

          newProductData[sectionIndex].productList[productIndex] = {
            ...newProductData[sectionIndex].productList[productIndex],
            price: r.newPrice,
            markup: r.newMarkup,
            vendorPrice: r.newVendorPrice
          };

          if (
            newProductData[sectionIndex].productList[productIndex].priceOverride
          ) {
            newProductData[sectionIndex].productList[
              productIndex
            ].priceOverride = false;
          }

          // update product in assemply list
          if (newAssemplyList.length > 0 && r.sectionId === "metal-and-edge") {
            for (let i = 0; i < newAssemplyList.length; i += 1) {
              if (newAssemplyList[i].productList.length > 0) {
                let assemplyProductIndex = newAssemplyList[
                  i
                ].productList.findIndex(product => product.id === r.id);
                if (assemplyProductIndex > -1) {
                  newAssemplyList[i].productList[assemplyProductIndex] = {
                    ...newAssemplyList[i].productList[assemplyProductIndex],
                    price: r.newPrice,
                    markup: r.newMarkup,
                    vendorPrice: r.newVendorPrice
                  };

                  if (
                    newAssemplyList[i].productList[assemplyProductIndex]
                      .priceOverride
                  ) {
                    newAssemplyList[i].productList[
                      assemplyProductIndex
                    ].priceOverride = false;
                  }
                }
              }
            }
          }

          promises.push(
            this.createEstimateHistory({
              estimateId: this.estimate.id,
              history: {
                buildingId,
                sectionId: r.sectionId,
                productId: r.id,
                oldPrice: r.price,
                newPrice: r.newPrice
              }
            })
          );
        });
        const newBuilding = {
          productData: newProductData,
          priceUpdatedAt: this.$google.firebase.firestore.Timestamp.now()
        };
        if (newAssemplyList.length > 0) {
          newBuilding.assemblyList = newAssemplyList;
        }
        if (products.length > 0) {
          promises.push(
            this.updateBuilding({
              estimateId: this.estimate.id,
              buildingId,
              building: newBuilding
            })
          );
        }
      }

      promises.push(
        this.updateEstimate({
          id: this.estimate.id,
          doc: {
            priceUpdatedAt: this.$google.firebase.firestore.Timestamp.now()
          }
        })
      );

      Promise.all(promises).then(() => {
        this.$f7.preloader.hide();
        this.cancel();
        this.$f7.toast
          .create({
            text: "Pricing updated successfully!",
            closeOnClick: true,
            closeButton: false,
            closeTimeout: 2000
          })
          .open();
        // re-bind data
        this.bindBuildingListBys({
          estimateId: this.estimate.id,
          conditions: [
            {
              prop: "priceUpdatedAt",
              val: "",
              op: "!="
            }
          ]
        });
        this.bindEstimateHistoryListBys({
          estimateId: this.estimate.id,
          conditions: []
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-footer {
  border-top: 1px solid var(--f7-table-cell-border-color);
  ::v-deep div {
    text-align: right;
    --f7-table-cell-padding-left: var(--f7-table-cell-padding-horizontal);
    --f7-table-cell-padding-right: var(--f7-table-cell-padding-horizontal);
    &:first-child {
      --f7-table-cell-padding-left: var(
        --f7-table-edge-cell-padding-horizontal
      );
    }
    &:last-child {
      --f7-table-cell-padding-right: var(
        --f7-table-edge-cell-padding-horizontal
      );
    }
    padding-top: var(--f7-table-cell-padding-vertical);
    padding-bottom: var(--f7-table-cell-padding-vertical);
    padding-left: var(--f7-table-cell-padding-left);
    padding-right: var(--f7-table-cell-padding-right);
  }
}
</style>
