import employeeSchedulingService from "../../../../services/employee-scheduling.service";
import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const scheduleActions = new FirebaseActions(
    `/system_client/${tenant}/employee_scheduling`,
    "schedule"
  );

  return {
    bindScheduleList: scheduleActions.bindCollection,
    bindScheduleListBys: scheduleActions.bindCollectionBys,
    unbindScheduleList: scheduleActions.unbindCollection,

    bindSchedule: scheduleActions.bindDocument,
    unbindSchedule: scheduleActions.unbindDocument,

    createSchedule: scheduleActions.createDocument,
    updateSchedule: scheduleActions.updateDocument,
    deleteSchedule: scheduleActions.deleteDocument,

    getSchedules(_, conditions) {
      const path = `/system_client/${tenant}/employee_scheduling`;
      return FirebaseActions.getCollectionWithConditions(path, conditions).then(
        res => res
      );
    },

    updateAdditionalAssigneeIdsProjectAlgolia(context, array) {
      if (array.length > 0) {
        array.forEach(r => {
          employeeSchedulingService.updateAdditionalAssigneeIDsForProjectAlgolia(
            {
              tenantId: tenant,
              projectId: r.projectId,
              userIds: r.userIds,
              action: r.action
            }
          );
        });
      }
    }
  };
};
