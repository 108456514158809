const roles = [
  {
    value: "owner",
    title: "Owner"
  },
  {
    value: "user",
    title: "User"
  }
];

export default roles;
