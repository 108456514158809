import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default tenant => {
  return {
    namespaced: true,
    state,
    actions: actions(tenant),
    mutations,
    getters,
    modules: {}
  };
};
