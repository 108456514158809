/* eslint-disable no-unused-vars */

import {
  COMPANY_TYPE_VENDOR,
  CODE_PROPERTY_TYPE,
  CODE_JOB_TYPE,
  CODE_COMPANY_TYPE,
  CODE_PREPARATION_TASK_CHECKLIST,
  CODE_WO_TASK_TYPE,
  CODE_EXPENSE_TYPE,
  CODE_STATUS_OPTION,
  ENTITY_EXPENSE,
  ENTITY_ACTION_REQUIRED,
  CODE_ROOF_TYPE,
  ENTITY_PURCHASE_ORDER,
  ENTITY_INVOICE,
  CODE_LEAD_SOURCE,
  ENTITY_INSURANCE_APPROVAL,
  ENTITY_ESTIMATE
} from "@/utility/const";
import { ENTITY_RECURRING_INVOICE } from "../../../../utility/const";

export default {
  propertyTypeList: state =>
    state.typeList.filter(item => item.code === CODE_PROPERTY_TYPE),
  jobTypeList: state =>
    state.typeList.filter(item => item.code === CODE_JOB_TYPE),
  companyTypeList: state =>
    state.typeList.filter(item => item.code === CODE_COMPANY_TYPE),
  companyTypeListExceptVendor: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_COMPANY_TYPE && item.value !== COMPANY_TYPE_VENDOR
    ),
  preparationTaskChecklistList: state =>
    state.typeList.filter(
      item => item.code === CODE_PREPARATION_TASK_CHECKLIST
    ),
  woTaskTypeList: state =>
    state.typeList.filter(item => item.code === CODE_WO_TASK_TYPE),

  woTaskTypeBy: (state, getters, rootState, rootGetters) => type =>
    getters.woTaskTypeList.find(item => item.value === type) || {},

  expenseTypeList: state =>
    state.typeList.filter(item => item.code === CODE_EXPENSE_TYPE),

  expenseStatusList: state =>
    state.typeList.filter(
      item => item.code === CODE_STATUS_OPTION && item.entity === ENTITY_EXPENSE
    ),

  actionRequiredStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_ACTION_REQUIRED
    ),

  roofTypeList: state =>
    state.typeList.filter(item => item.code === CODE_ROOF_TYPE),

  roofTypeBy: (state, getters, rootState, rootGetters) => value =>
    getters.roofTypeList.find(item => item.value === value) || {},

  purchaseOrderStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_PURCHASE_ORDER
    ),

  purchaseOrderStatusBy: (state, getters, rootState, rootGetters) => value =>
    getters.purchaseOrderStatusList.find(item => item.value === value) || {},

  invoiceStatusList: state =>
    state.typeList.filter(
      item => item.code === CODE_STATUS_OPTION && item.entity === ENTITY_INVOICE
    ),

  estimateStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION && item.entity === ENTITY_ESTIMATE
    ),

  insuranceApprovalStatusList: state =>
    state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_INSURANCE_APPROVAL
    ),
  insuranceApprovalStatusBy: (
    state,
    getters,
    rootState,
    rootGetters
  ) => value =>
    getters.insuranceApprovalStatusList.find(item => item.value === value) ||
    {},

  estimateStatusBy: (state, getters, rootState, rootGetters) => value =>
    getters.estimateStatusList.find(item => item.value === value) || {},

  leadSourceList: state =>
    state.typeList
      .filter(item => item.code === CODE_LEAD_SOURCE)
      .sort((a, b) => {
        return b.displayName > a.displayName ? -1 : 0;
      }),

  leadSourceCommercialList: (state, getters, rootState, rootGetters) =>
    getters.leadSourceList.filter(item =>
      (item.businessCodes || []).includes("commercial")
    ),

  leadSourceResidentialList: (state, getters, rootState, rootGetters) =>
    getters.leadSourceList.filter(item =>
      (item.businessCodes || []).includes("residential")
    ),

  getLeadSource: (state, getters, rootState, rootGetters) => value =>
    getters.leadSourceList.find(item => item.value === value) || {},

  tabActive: state => state.tabActive || "tab-action",

  isCloseArchiveBoardProjectDetailPopup: state =>
    state.isCloseArchiveBoardProjectDetailPopup,

  recurringInvoiceStatusList: state =>{
    return state.typeList.filter(
      item =>
        item.code === CODE_STATUS_OPTION &&
        item.entity === ENTITY_RECURRING_INVOICE
    )
  },
     
  isOpenProjectPhotoViewPopup: state => state.isOpenProjectPhotoViewPopup
};
