<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close @click.native="handleBack">Back</f7-link>
      </f7-nav-left>
      <f7-nav-title>{{
        isNewRecurring ? "New Recurring Invoice" : "Edit Recurring Invoice"
      }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="save()">Save </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <new-recurring-invoice ref="newRecurringInvoice" :invoiceGroupIdProp="invoiceGroupIdComputed"
      :isNewRecurring="isNewRecurring"></new-recurring-invoice>
  </f7-page>
</template>

<script>
import NewRecurringInvoice from "../components/details/NewRecurringInvoice.vue";

export default {
  components: {
    NewRecurringInvoice
  },

  computed: {
    isNewRecurring() {
      return this.$f7route.params.recurringInvoiceNumber ? false : true;
    },

    invoiceGroupIdComputed() {
      return this.$f7route.params.invoiceGroupId || '';
    },
  },

  methods: {
    handleBack() {
      const url = this.$f7router.history[this.$f7router.history.length - 2];
      this.$f7router.navigate(url, {
        force: true,
        clearPreviousHistory: true
      });
    },

    save() {
      this.$refs.newRecurringInvoice.save()
    },
  },
};
</script>

<style land="scss" scoped>
.flex-start {
  justify-content: flex-start;
}

.select-type ::v-deep select {
  min-width: 40px;
}
</style>
