<template>
  <div>
    <div class="display-flex justify-content-center">
      <f7-card
        v-show="
          card.status === 'open' &&
            card.businessCode === BUSINESS_CODE_RESIDENTIAL
        "
        :style="`width:${pageWidth}px`"
        ><f7-card-header class="display-flex justify-content-space-between">
          <div>Contract Details</div>
          <div class="display-flex">
            <f7-button v-if="$device.desktop" small outline @click="exportPdf">
              Export PDF
            </f7-button>
            <f7-link
              class="margin-right-half"
              v-if="!$device.desktop"
              icon-f7="arrow_down_doc"
              @click.native="exportPdf"
            ></f7-link>

            <f7-button
              v-if="
                contract.status === 'draft' &&
                  !contract.projectManagerSigner &&
                  !contract.contractorSigner &&
                  $device.desktop
              "
              small
              outline
              class="margin-left-half"
              @click="$refs.editChecklistPopup.open()"
              >Edit Checklist</f7-button
            >
            <f7-link
              class="margin-right-half"
              v-if="
                contract.status === 'draft' &&
                  !contract.projectManagerSigner &&
                  !contract.contractorSigner &&
                  !$device.desktop
              "
              icon-f7="pencil"
              @click.native="$refs.editChecklistPopup.open()"
            ></f7-link>

            <f7-button
              v-if="
                contract.status === 'draft' &&
                  $device.desktop &&
                  isShowSendEmailButton
              "
              small
              outline
              class="margin-left-half"
              @click="sendEmailBuildContract"
              >Send Email</f7-button
            >

            <f7-link
              class="margin-right-half"
              v-if="
                !$device.desktop &&
                  contract.status === 'draft' &&
                  isShowSendEmailButton
              "
              icon-f7="envelope"
              @click.native="sendEmailBuildContract"
            ></f7-link>

            <template
              v-if="
                !contract.projectManagerSigner || !contract.contractorSigner
              "
            >
              <f7-button
                v-if="$device.desktop"
                small
                fill
                class="margin-left-half"
                popover-open=".popover-signer-role"
                >Sign Online</f7-button
              >
              <f7-link
                v-if="!$device.desktop"
                icon-f7="signature"
                popover-open=".popover-signer-role"
              ></f7-link>
              <f7-popover class="popover-signer-role">
                <f7-list>
                  <f7-list-item
                    v-for="(item, index) in signerRoleOptions"
                    :key="index"
                    :title="item.title"
                    :class="{ 'disabled-item-popover': item.disabled }"
                    link
                    popover-close
                    @click="openSignOnline(item.value)"
                  ></f7-list-item>
                </f7-list>
              </f7-popover>
            </template>
          </div>
        </f7-card-header>
      </f7-card>
    </div>
    <div
      :style="styleContent"
      ref="wrapPreviewContent"
      class="display-flex flex-direction-column align-items-center justify-content-flex-start margin-horizontal"
    >
      <div v-for="(page, index) in pdfPages" :key="index">
        <div :class="pagePreviewCssClass" v-html="page.htmlValue"></div>
      </div>
    </div>
    <div style="overflow: hidden; height: 0;">
      <div v-for="(page, index) in pdfPages" :key="index">
        <div
          :class="pagePreviewCssClass"
          :id="`${page.pageId}_${page.index}`"
          :ref="`${page.pageId}_${page.index}`"
          v-html="page.htmlValue"
        ></div>
      </div>
    </div>

    <build-contract-signature-popup
      :contract="contract"
      ref="buildContractSignaturePopup"
    ></build-contract-signature-popup>
    <edit-checklist-popup
      ref="editChecklistPopup"
      :contract="contract"
      @save="saveAndComposeContract"
    ></edit-checklist-popup>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { generateImage } from "../../services/utils";
import BuildContractSignaturePopup from "./BuildContractSignaturePopup.vue";
import EditChecklistPopup from "./EditChecklistPopup.vue";
import { contractDataCalculator } from "../../utility/contract-calculator";
import {
  BUSINESS_CODE_RESIDENTIAL,
  RESIDENTIAL_ACTION_CODES_BLOCK_SEND_EMAIL
} from "../../utility/const";

export default {
  components: {
    BuildContractSignaturePopup,
    EditChecklistPopup
  },
  props: {
    contract: { type: Object, default: () => {} },
    contractTemplate: { type: Object, default: () => {} },
    card: { type: Object, default: () => {} },
    contact: { type: Object, default: () => {} },
    insuranceCompany: { type: Object, default: () => {} },
    setting: { type: Object, default: () => {} },
    pageWidth: { type: Number, default: 1020 }, // 120 dpi, letter size
    pageHeight: { type: Number, default: 1320 }
  },
  data() {
    return {
      pages: {},
      styleContent: "",
      BUSINESS_CODE_RESIDENTIAL
    };
  },

  computed: {
    sortedSections() {
      return _.cloneDeep(this.contract.sections || []).sort(
        (a, b) => a.index - b.index
      );
    },

    ...mapGetters("dashboard/project", ["actionList"]),

    isShowSendEmailButton() {
      const actions = this.card.actions || [];
      for (const id of actions) {
        const item = this.actionList.find(
          data => data.id.toString() === id.toString()
        );

        if (
          item &&
          RESIDENTIAL_ACTION_CODES_BLOCK_SEND_EMAIL.includes(item.code)
        ) {
          return false;
        }
      }

      return true;
    },

    pdfPages() {
      let sections = [];
      this.sortedSections.forEach(section => {
        const a = this.breakSection(section);
        sections = sections.concat(a);
      });

      const pages = [];
      const headerSection = sections.find(
        section => section.sectionId === "header-section"
      );

      const footerSection = sections.find(
        section => section.sectionId === "footer-section"
      );

      for (let index = 0; index < sections.length; index++) {
        const section = sections[index];
        switch (section.sectionId) {
          case "cover-section":
            pages.push({
              htmlValue: section.htmlValue,
              pageId: section.sectionId,
              index
            });
            break;

          case "header-section":
            break;

          case "footer-section":
            break;

          default:
            pages.push({
              htmlValue: `
                <div style="height: 100%; display: flex; flex-direction: column;">
                  <div>
                    ${headerSection ? headerSection.htmlValue : ""}
                  </div>
                  <div style="flex: 1">
                    ${section.htmlValue}
                  </div>
                  <div>
                    ${footerSection ? footerSection.htmlValue : ""}
                  </div>
                </div>`,
              pageId: section.sectionId,
              index
            });
        }
      }

      return pages;
    },

    pagePreviewCssClass() {
      return this.pageHeight === 1680
        ? "page-preview-content-legal"
        : "page-preview-content";
    },

    signerRoleOptions() {
      return [
        {
          title: "Project Manager",
          value: "project-manager",
          disabled: !!this.contract.projectManagerSigner
        },
        {
          title: "Contractor",
          value: "contractor",
          disabled: !!this.contract.contractorSigner
        }
      ];
    },

    tenantId() {
      let tenantLocal =
        localStorage.getItem("login_tenant") !== "null"
          ? localStorage.getItem("login_tenant")
          : "";
      return tenantLocal;
    },
    contactName() {
      if (!this.card || !this.card.contactId) return "";
      return this.contact
        ? this.contact.firstName + " " + this.contact.lastName
        : "";
    },

    contactPhone() {
      if (!this.card || !this.card.contactId) return "";
      const phones = this.contact.phones;
      if (!_.isEmpty(phones) && _.isArray(phones)) {
        let phone = phones.find(item => item.code === "main");
        phone = phone || phones[0] || [];

        if (!_.isEmpty(phone)) {
          return phone.value;
        }
      }
      return "";
    },

    contactEmail() {
      if (!this.card || !this.card.contactId) return "";
      if (!_.isEmpty(this.contact)) {
        const emails = this.contact.emails;
        if (!_.isEmpty(emails) && _.isArray(emails)) {
          let address = emails.find(item => item.code === "main");
          address = address || emails[0] || [];

          if (!_.isEmpty(address)) {
            return address.value;
          }
        }
      }

      return "";
    },

    insuranceCompanyName() {
      return this.insuranceCompany.companyName || "";
    },

    insuranceCompanyPhone() {
      const phones = this.insuranceCompany.phones || [];
      if (!_.isEmpty(phones) && _.isArray(phones)) {
        let phone = phones.find(item => item.code === "main");
        phone = phone || phones[0] || [];

        if (!_.isEmpty(phone)) {
          return phone.value;
        }
      }
      return "";
    },

    isRemoveRoof() {
      return false;
    },

    isReplaceRoof() {
      return false;
    }
  },

  created() {
    // check actionsList
    !this.actionList.length && this.getActionList();
  },

  methods: {
    ...mapActions("common/contract", ["updateContract"]),
    ...mapActions("dashboard/project", ["getActionList"]),

    sendEmailBuildContract() {
      this.$emit("sendEmail");
    },

    lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = this.pageWidth;
        const width = this.$refs.wrapPreviewContent.clientWidth;
        const height = this.$refs.wrapPreviewContent.clientHeight;
        const spaceHeight = ((width * height) / letterFullWidth - height) / 2;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;
          this.styleContent = `transform: scale(${scaleRatio}); margin: ${spaceHeight}px 0`;
        } else {
          this.styleContent = "";
        }
      });
    },

    // TODO: break section
    breakSection(section) {
      const arr = section.htmlValue.split(
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      if (arr.length > 1) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (i === 0) {
            arr[i] = `${item}</div></div></font>`;
          } else if (i === arr.length - 1) {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; margin: 56px;" class="">
                          <div class="">${item}`;
          } else {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; margin: 56px;" class="">
                          <div class="">${item}</div></div></font>`;
          }
        }
      }
      return arr.map(r => ({
        ...section,
        htmlValue: r
      }));
    },

    async exportPdf() {
      this.$f7.dialog.preloader("Downloading PDF. Please wait...");

      // const width = 1020; //120 dpi
      // const height = 1320;
      const doc = new jsPDF("p", "pt", [this.pageWidth, this.pageHeight]); //850x1100

      try {
        const images = await Promise.all(
          this.pdfPages.map(page =>
            generateImage(
              this.$refs[`${page.pageId}_${page.index}`][0].innerHTML,
              {
                pageWidth: this.pageWidth,
                pageHeight: this.pageHeight
              }
            )
          )
        );
        for (let index = 0; index < images.length; index++) {
          const image = images[index];
          if (!_.isEmpty(image)) {
            if (index > 0) {
              doc.addPage([this.pageWidth, this.pageHeight], "p");
            }
            doc.setPage(index + 1);
            doc.addImage(
              image,
              "PNG",
              0,
              0,
              this.pageWidth,
              this.pageHeight,
              `page-${index}`,
              "FAST"
            );
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            const pageNumber = index + 1;
            const pageNumberString = `${pageNumber}`;
            const xPosition = this.pageWidth / 2;
            doc.text(pageNumberString, xPosition, this.pageHeight - 30);
          }
        }

        doc.save(`${this.contract.contractTitle}.pdf`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
        this.$f7.dialog.close();
      }
      this.$f7.dialog.close();
    },

    openSignOnline(value) {
      const pairs = this.composeContractData();

      let hasConditionMet = false;

      for (const key in pairs) {
        if (Object.hasOwnProperty.call(pairs, key)) {
          const val = pairs[key];

          // Check if value is empty or contains `_`
          if (val === "" || val.includes("_")) {
            hasConditionMet = true;
            this.$ri.dialog.openWarningDialog({
              title: "You have not filled in all the information",
              content: "Do you want to sign with the missing information?",
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  this.$refs.buildContractSignaturePopup.openPopup(value);
                }
              }
            });
            break;
          }
        }
      }

      // If there are no satisfied cases, open popup sign online
      if (!hasConditionMet) {
        this.$refs.buildContractSignaturePopup.openPopup(value);
      }
    },

    composeContractData(isNew) {
      const pairs = {};
      for (const key in contractDataCalculator) {
        if (Object.hasOwnProperty.call(contractDataCalculator, key)) {
          const func = contractDataCalculator[key];
          if (typeof func === "function") {
            pairs[key] = func(this, isNew);
          }
        }
      }
      return pairs;
    },

    parseContractContent(isNew) {
      if (_.isEmpty(this.contractTemplate)) {
        return; //TODO: show error message
      }
      const contractSections = [];
      for (const section of this.contractTemplate.sections || []) {
        const tempSection = _.cloneDeep(section);

        // build data to replace
        const pairs = this.composeContractData(isNew);

        // replace data
        for (const key in pairs) {
          if (Object.hasOwnProperty.call(pairs, key)) {
            const value = pairs[key];
            const temp = _.cloneDeep(tempSection.htmlValue);
            tempSection.htmlValue = temp.replaceAll(
              `<span style="background-color: #f05034;">[${key}]</span>`,
              value
            );

            if (key === "Roofing Logo URL") {
              tempSection.htmlValue = temp.replaceAll(`[${key}]`, value);
            }
          }
        }

        contractSections.push(tempSection);
      }
      return contractSections;
    },

    saveChangeContractContent() {
      if (this.contract.status !== "draft") return;
      this.$f7.preloader.show();
      const sections = this.parseContractContent();
      this.updateContract({
        id: this.contract.id,
        doc: { sections }
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    saveAndComposeContract(contractChecklist) {
      this.$f7.preloader.show();
      this.updateContract({
        id: this.contract.id,
        doc: {
          contractChecklist
        }
      })
        .then(() => {
          return this.saveChangeContractContent();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-preview-content {
  width: 1020px;
  height: 1320px;
  background-color: white;
  margin: auto auto;
  margin-top: 23px;
  color: black;
}

.page-preview-content-legal {
  width: 1020px;
  height: auto;
  background-color: white;
  margin: auto auto;
  margin-top: 23px;
  color: black;
}

.page-preview-content ::v-deep font > div {
  padding-top: 1px;
}

.page-preview-content-legal ::v-deep font > div {
  padding-top: 1px;
}

.disabled-item-popover {
  pointer-events: none;
  cursor: default;
  background: var(--f7-color-border-neutral);
  color: var(--f7-color-text-3-neutral);
}
</style>
