<template>
  <div>Login to google account in processing...</div>
</template>
<script>
export default {
  mounted() {
    var result = this.$f7route.hash.split("&").reduce(function(res, item) {
      var parts = item.split("=");
      res[parts[0]] = parts[1];
      return res;
    }, {});
    let contactId = decodeURIComponent(result.state).split("=")[1];
    this.$gapi.setSession().then(() => {
      if (contactId) {
        this.$f7router.navigate(
          `/contactbook/contact/${contactId}?activeTab=contact-email`
        );
      } else {
        this.$f7router.navigate(`/contactbook/contacts`);
      }
    });
  }
};
</script>
