import Vue from "vue";
import * as types from "./types";

export default {
  [types.SET_CONTACT_MESSAGE_LIST](state, payload) {
    Vue.set(state, "messageList", payload);
  },

  [types.SET_CONTACT_MESSAGE](state, payload) {
    Vue.set(state, "message", payload);
  }
};
