<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Projects</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>

    <board-toolbar
      v-if="!$f7.device.desktop && !$f7.device.ipad"
    ></board-toolbar>

    <f7-searchbar
      class="search-list"
      disable-button-text
      placeholder="Search"
      :clear-button="true"
      :value="searchText"
      @input="searchText = $event.target.value"
      @searchbar:disable="searchText = ''"
    ></f7-searchbar>
    <f7-list class="no-margin">
      <f7-list-group media-list>
        <f7-list-item
          v-for="item in searchedList"
          :title="item.cardNumber"
          :subtitle="item.title"
          :key="item.id"
          :reload-detail="true"
          swipeout
          :link="
            `/dashboard/${$f7route.route.meta.boardType}/commercial/construction/project/${item.cardNumber}`
          "
          :text-color="
            item.id === constructionProjectId ? 'primary' : 'default'
          "
        >
          <div slot="text">
            <div class="font-text">
              Property Name: {{ item.property.propertyName }}
            </div>
          </div>
          <div slot="footer">
            <div class="font-text">
              Address: {{ getAddressOfProperty(item.property) }}
            </div>
          </div>

          <f7-swipeout-actions right v-if="isAdmin">
            <f7-swipeout-button
              :close="true"
              color="primary"
              @click="openSettingPopup"
              ><f7-icon f7="gear_alt"></f7-icon
            ></f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list-group>
    </f7-list>
    <construction-project-setting-popup
      ref="projectSettingPopup"
      @settingChanged="handleSettingChanged"
    ></construction-project-setting-popup>
  </f7-page>
</template>
<script>
import BoardToolbar from "../../components/toolbar/BoardToolbar.vue";
import ConstructionProjectSettingPopup from "../../components/popup/ConstructionProjectSettingPopup.vue";

import { mapActions, mapGetters } from "vuex";
import { sortLatest } from "@/utility/date-time-tool";
import { applyFilter } from "@/utility/filter-tools";
import { getFullAddress } from "@/utility/address";
import _ from "lodash";
import { ACTION_CODE_CONSTRUCTION } from "@/utility/const";
import Menu from "../../../../components/menu/Menu.vue";

export default {
  components: { BoardToolbar, ConstructionProjectSettingPopup, Menu },

  data() {
    return {
      searchText: ""
    };
  },

  created() {
    this.setConstructionProjectId("");
    if (_.isEmpty(this.constructionProjectList)) {
      this.getActionList()
        .then(() => {
          return this.bindConstructionProjectListBys([
            {
              prop: "status",
              val: "open",
              op: "=="
            },
            {
              prop: "businessCode",
              val: "commercial",
              op: "=="
            },
            {
              prop: "actions",
              val: this.actionByCode(ACTION_CODE_CONSTRUCTION).id, // Contruction Action
              op: "array-contains"
            }
          ]);
        })
        .then(() => {
          // this.setDefaultProjectView();
        });

      if (_.isEmpty(this.propertyList)) {
        this.getPropertyList();
      }
    } else {
      if (this.$f7.device.desktop || this.$f7.device.ipad) {
        // this.setDefaultProjectView();
      }
    }
  },

  computed: {
    ...mapGetters("dashboard/project", [
      "constructionProjectList",
      "constructionProjectId",
      "actionByCode"
    ]),

    ...mapGetters("setting/app/profile", { currentUser: "user" }),

    ...mapGetters("dashboard/client", ["propertyList"]),

    searchedList() {
      let list = applyFilter(this.constructionProjectList, this.searchText, [
        "title",
        i => i.property.propertyName,
        i => ((i.property.addresses || [])[0] || { value: "" }).value
      ]);
      list = sortLatest(list);
      return list;
    },

    isAdmin() {
      return this.currentUser
        ? (this.currentUser.customClaims || {}).role === "owner"
        : false;
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "bindConstructionProjectListBys",
      "getActionList",
      "setConstructionProjectId"
    ]),
    ...mapActions("dashboard/client", ["getPropertyList"]),

    getAddressOfProperty(property) {
      if (!property) return null;
      return getFullAddress((property.addresses || [])[0]);
    },

    /**
     * Set default view for construction board
     */
    setDefaultProjectView() {
      if (!_.isEmpty(this.searchedList)) {
        const firstProject = this.searchedList[0];
        this.$f7router.navigate(
          `/dashboard/${this.$f7route.route.meta.boardType}/${this.$f7route.route.meta.businessCode}/${this.$f7route.route.meta.boardCode}/project/${firstProject.cardNumber}`,
          {
            animate: false
          }
        );
      } else {
        return this.$ri.dialog.openWarningDialog({
          title: "Construction Board",
          content: "The Construction board has no project, please visit later.",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.$f7router.navigate(
                `/dashboard/${this.$f7route.route.meta.boardType}/${this.$f7route.route.meta.businessCode}/sales`,
                {
                  reloadAll: true,
                  pushState: true
                }
              );
            }
          }
        });
      }
    },

    openSettingPopup() {
      this.$refs.projectSettingPopup.openPopup();
    },

    handleSettingChanged({ code, data }) {
      const self = this;
      if (code === "re-open-popup") {
        this.$f7router.navigate(
          `/dashboard/${self.$f7route.route.meta.boardType}/commercial/${data.boardName}/project/${data.cardNumber}/action/${data.actionCode}`,
          {
            reloadAll: true,
            pushState: true
          }
        );
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  }
};
</script>
