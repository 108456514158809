import Vue from "vue";
import _ from "lodash";
import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../utility/datetime.js";
import { auth, firebase } from "../../../services/firebase.service";
import { mapActions } from "vuex";
import { TOAST_MESSAGES } from "@/utility/toast-messages.js";
import { f7 } from "framework7-vue";
import { COLLECTION_OPRATION_CARD } from "@/utility/const";
export default {
  methods: {
    ...mapActions("common/notification", ["createNotificationByType"]),

    setAssigneeIDs(IDs) {
      Vue.set(this.displayCard, "assigneeIDs", IDs);

      this.$f7.preloader.show();
      this.updateCard({
        id: this.displayCard.id,
        doc: {
          assigneeIDs: IDs
        }
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    setAttachment(attachmentType, val, displayCard) {
      if (this.displayCard) {
        Vue.set(this.displayCard, attachmentType, val);
        this.updateCard({
          id: this.displayCard.id,
          doc: {
            [attachmentType]: val
          }
        });
      } else {
        Vue.set(displayCard, attachmentType, val);
        this.updateCard({
          id: displayCard.id,
          doc: {
            [attachmentType]: val
          }
        });
      }
    },

    clearData() {
      const data = this.$data;
      if(this.v$){
        this.v$.$reset();
      }
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key];
          if (_.isBoolean(value)) {
            this.$data[key] = false;
          } else if (_.isArray(value)) {
            this.$data[key] = [];
          } else if (_.isObject(value)) {
            this.$data[key] = {};
          } else if (_.isNumber(value)) {
            this.$data[key] = 0;
          } else {
            this.$data[key] = "";
          }
        }
      }
    },

    formatCurrency(val) {
      val = val.replace("$", "");
      val = val.replace(/,/g, "");

      if (!Number.isNaN(Number.parseFloat(val))) {
        val = "$" + Number.parseFloat(val).toLocaleString();
      } else {
        val = "$";
      }
      return val;
    },

    formatPriceNumber(val) {
      val = val.replace("$", "");
      val = val.replace(/,/g, "");
      let r = 0;
      let active = 0;
      let isText = false;
      for (let i = 0; i < val.toString().length; i++) {
        if (val.toString()[i] === ".") {
          r++;
          if (r <= 1) {
            active = 1;
          } else {
            active = 2;
          }
        }
      }
      for (let j = 0; j < val.length; j++) {
        if (val[j].search(/[^0-9.]/g) == 0) {
          isText = true;
        }
      }
      if (!Number.isNaN(Number.parseFloat(val))) {
        if (active === 1) {
          let stringCurrency = val.toString().split(".");
          if (
            stringCurrency[1] !== undefined &&
            stringCurrency[1].length > 2 &&
            isText === false
          ) {
            let delTwoNumber = val
              .toString()
              .substring(0, val.toString().length - 1);
            val =
              "$" +
              delTwoNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
          if (
            stringCurrency[1] !== undefined &&
            stringCurrency[1].length <= 2
          ) {
            val = "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
        if (active === 2) {
          let delPoint = val.toString().substring(0, val.toString().length - 1);
          val = "$" + delPoint.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else if (active === 0) {
          val = "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (isText == true) {
          let delText = val.toString().substring(0, val.toString().length - 1);
          if (active === 1) {
            let stringCurrency = val.toString().split(".");
            val = "$" + delText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (
              stringCurrency[1] !== undefined &&
              stringCurrency[1].length <= 2
            ) {
              val = delText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          } else {
            val = delText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      } else {
        val = "";
      }
      return val;
    },
    formatDots(val) {
      const arr = val.split(".");
      if (!arr[arr.length - 1]) {
        val = val.substring(0, val.length - 1);
      }
      return this.formatPriceNumber(val);
    },
    formatFloatNumber(val) {
      val = val.replace(/,/g, "");

      if (!Number.isNaN(Number.parseFloat(val))) {
        val = Number.parseFloat(val).toLocaleString();
      }

      return val;
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },

    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    getProjectId(constructionProject, card) {
      if (this.$f7route.route.meta.boardCode === "construction") {
        return constructionProject?.id || "";
      } else {
        return card?.id || "";
      }
    },

    getProject(constructionProject, card) {
      if (this.$f7route.route.meta.boardCode === "construction") {
        return constructionProject || {};
      } else {
        return card || {};
      }
    },

    getAttachmentFilesByType(type) {
      return (this.displayCard.attachmentFiles || []).filter(
        r => r.attachmentType === type
      );
    },

    showUploadDialog(response, content) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Missing Upload Documents",
        content: content,
        onClick: (_sefl, index) => {
          if (index === 0) {
            app.handleAction(response);
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        }
      });
    },

    showToast({ message, position = "center", closeTimeout = 2000, icon }) {
      const self = this;
      // Create toast
      if (!self.toastCenter) {
        self.toastCenter = f7.toast.create({
          text: message,
          position: position,
          closeTimeout: closeTimeout,
          icon: icon,
          cssClass: "toast-custom"
        });
      }
      // Open it
      self.toastCenter.open();
    },

    async onSaveNote({ title, note, displayCard, callback }) {
      const commentAndNote = _.cloneDeep(displayCard.commentAndNote || []);
      commentAndNote.push({
        code: "note",
        title: title,
        htmlContent: note,
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email
      });

      await this.updateCard({
        id: displayCard.id,
        doc: {
          commentAndNote
        }
      })
        .then(() => {
          this.showToast({
            message: TOAST_MESSAGES.getToastMessage("SAVE_NOTE_SUCCESS"),
            icon:
              '<i class="f7-icons" style="color: green">checkmark_circle_fill</i>'
          });
          callback();
        })
        .catch(() => {
          this.showToast({
            message: TOAST_MESSAGES.getToastMessage("SAVE_NOTE_ERROR"),
            icon:
              '<i class="f7-icons" style="color: red">exclamationmark_circle</i>'
          });
        });
    }
  }
};
