<template>
  <f7-popup tablet-fullscreen :opened="popupOpened" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ purchaseOrder.taskNumber || "" }}</f7-nav-title>
        <f7-nav-right></f7-nav-right>
      </f7-navbar>
      <purchase-order-detail
        @closePopup="popupOpened = false"
        :jobObject="jobObject"
        :fromServiceBoard="fromServiceBoard"
      />
    </f7-page>
  </f7-popup>
</template>

<script>
import PurchaseOrderDetail from "../details/PurchaseOrderDetail.vue";
import poMixin from "../../mixin/purchase-order-mixin";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    PurchaseOrderDetail
  },

  props: {
    jobObject: Object,
    fromServiceBoard: { type: Boolean, default: false }
  },

  mixins: [poMixin],

  data: () => {
    return {
      popupOpened: false
    };
  },

  computed: {
    ...mapGetters("dashboard/work-order", ["purchaseOrder"]),
    ...mapGetters("setting/app/system", ["setting"]),
    ...mapGetters("dashboard/client", ["companyList"]),
    ...mapGetters('common/app-constant', ['tenantId']),
  },

  methods: {
    ...mapActions("dashboard/work-order", [
      "bindPurchaseOrderResponse",
      "bindPurchaseOrder",
      "unbindPurchaseOrder"
    ]),
    ...mapActions("setting/app/system", ["bindSetting"]),
    ...mapActions("dashboard/product-item", {
      bindProductItemList: "bindBys"
    }),
    ...mapActions("dashboard/product-item", {
      unbindProductItemList: "unbind"
    }),
    ...mapActions("dashboard/client", ["getCompanyList"]),

    ...mapActions("dashboard/price-list", [
      "bindPriceListItem",
      "unbindPriceListItem"
    ]),

    open(id) {
      this.popupOpened = true;
      this.$f7.preloader.show();
      const self = this;
      this.bindPurchaseOrder(id)
        .then(async () => {
          const promises = [];
          if (_.isEmpty(this.purchaseOrder)) {
            return;
          }
          if (_.isEmpty(this.setting)) {
            promises.push(this.bindSetting(this.tenantId));
          }
          if (this.purchaseOrder.priceListId) {
            promises.push(
              this.bindPriceListItem(this.purchaseOrder.priceListId)
            );
          }
          promises.push(
            this.bindProductItemList([
              {
                prop: "status",
                val: "pi-active",
                op: "=="
              },
              {
                prop: "vendorId",
                val: this.purchaseOrder.vendorId,
                op: "=="
              }
            ])
          );
          await Promise.all(promises);
          const actionId = await self.getActionId();
          return await self.bindPurchaseOrderResponse(actionId);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });

      if (_.isEmpty(this.companyList)) {
        this.getCompanyList();
      }
    },

    closePopup() {
      this.popupOpened = false;
      this.unbindPurchaseOrder();
      this.unbindProductItemList();
      this.unbindPriceListItem();
    }
  }
};
</script>
