import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const actionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_category`,
    "category"
  );

  return {
    bindCategoryListBys: actionsTemplate.bindCollectionBys,
    unbindCategoryList: actionsTemplate.unbindCollection,
    bindCategories: ({ dispatch }) => {
      return dispatch("bindCategoryListBys", [
        {
          prop: "isDeleted",
          op: "==",
          val: false
        }
      ]);
    }
  };
};
