<template>
  <div
    class="data-table data-table-init card table-fixed-container"
    :style="!$device.desktop ? '' : 'margin-bottom: 80px;'"
  >
    <div class="card-header">
      <!-- Default table header -->
      <div class="data-table-header" text-color="primary">
        <div class="data-table-title">
          {{
            selectedItemCount === 0
              ? "Invoices from Quickbooks Online"
              : selectedMessage(selectedItemCount)
          }}
        </div>

        <div class="data-table-actions">
          <f7-button
            @click.native="importDataToRooferIntel"
            outline
            v-show="selectedItemCount > 0"
            >Import Invoice To RooferIntel</f7-button
          >
        </div>
      </div>
    </div>

    <div class="card-content">
      <table>
        <thead>
          <tr>
            <th class="checkbox-cell" style="width: 50px">
              <f7-checkbox
                @change="checkBoxAllChange($event.target.checked)"
              ></f7-checkbox>
            </th>

            <th class="label-cell">
              <span class="table-head-label">QB Invoice#</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Invoice#"
                  @input="searchQbInvoiceId = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Status</span>
              <div class="input">
                <select
                  placeholder="Please choose..."
                  :value="searchStatus"
                  @change="searchStatus = $event.target.value"
                >
                  <option key="" value="">All status</option>
                  <option
                    v-for="status in invoiceStatusList"
                    :key="status.value"
                    :value="status.value"
                    >{{ status.displayName }}</option
                  >
                </select>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Company Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Company Name"
                  @input="searchQBCompanyName = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Project/Job Number</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Project/Job Number"
                  @input="searchProject = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span class="table-head-label">Property Name</span>
              <div class="input">
                <input
                  type="text"
                  placeholder="Search Property Name"
                  @input="searchProperty = $event.target.value.trim()"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">Invoice Amount</th>
            <th class="label-cell">Paid Amount</th>
            <th class="label-cell">Invoice Date</th>
            <th class="label-cell">Paid Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="invoice in qbInvoiceFiltered"
            :key="invoice.qbInvoiceId"
            :class="
              `${
                invoice.checked && !invoice.indeterminate ? 'selected-cell' : ''
              }`
            "
          >
            <td class="checkbox-cell">
              <!-- indeterminate
                  disabled -->
              <f7-checkbox
                :disabled="invoice.indeterminate"
                :indeterminate="invoice.indeterminate"
                :checked="invoice.checked"
                @change="
                  checkBoxChange($event.target.checked, invoice.qbInvoiceId)
                "
              ></f7-checkbox>
            </td>
            <!-- qb invoice number -->
            <td class="label-cell">
              {{ invoice.qbDocNumber }}
            </td>
            <!-- status -->
            <td class="label-cell">
              <f7-chip
                slot="after"
                :text="getStatus(invoice.status).displayName"
                :color="getStatus(invoice.status).color"
              ></f7-chip>
            </td>
            <!-- company Name -->
            <td class="label-cell">
              {{ invoice.clientName }}
            </td>
            <!-- project/job -->
            <td class="input">
              <div class="item-input-wrap input-dropdown-wrap">
                <select
                  :disabled="invoice.indeterminate"
                  placeholder="Please choose..."
                  :value="invoice.projectId"
                  @change="
                    changeProject($event.target.value, invoice.qbInvoiceId)
                  "
                >
                  <option key="" value="" hidden></option>
                  <option
                    v-for="project in projectListFitered(
                      invoice.propertyId || ''
                    )"
                    :key="project.value"
                    :value="project.value"
                    >{{
                      `${project.projectNumber} - ${project.displayName}`
                    }}</option
                  >
                </select>
              </div>
            </td>
            <!-- property -->
            <td class="input">
              <div class="item-input-wrap input-dropdown-wrap">
                <select
                  :disabled="invoice.indeterminate"
                  placeholder="Please choose..."
                  :value="invoice.propertyId"
                  @change="
                    changeProperty($event.target.value, invoice.qbInvoiceId)
                  "
                >
                  <option key="" value=""></option>
                  <option
                    v-for="property in propertyList"
                    :key="property.value"
                    :value="property.value"
                    >{{ property.displayName }}</option
                  >
                </select>
              </div>
            </td>
            <td class="label-cell">
              {{ invoice.totalAmount || "" | currencyUSD }}
            </td>
            <td class="label-cell">
              {{ invoice.paidAmount || "" | currencyUSD }}
            </td>
            <td class="label-cell">
              {{ invoice.invoiceDate || "" | MMDDYYYY }}
            </td>
            <td class="label-cell">
              {{ invoice.paidDate || "" | MMDDYYYY }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getFullAddress } from "../../../../utility/address";

export default {
  props: {
    qbInvoices: { type: Array, default: () => [] },
    projectList: { type: Array, default: () => [] },
    propertyList: { type: Array, default: () => [] }
  },

  data() {
    return {
      selectedItemCount: 0,
      searchQbInvoiceId: "",
      searchStatus: "",
      searchQBCompanyName: "",
      searchProject: "",
      searchProperty: ""
    };
  },

  computed: {
    ...mapGetters("quickbooks/app-constant", ["invoiceStatusList"]),
    projectListFitered() {
      return propertyId => {
        if (!propertyId) return this.projectList;
        return (
          (this.projectList || []).filter(r => r.propertyId == propertyId) || []
        );
      };
    },
    getCustomerAddress() {
      return invoice => {
        if (!_.isEmpty(invoice.addresses) && _.isArray(invoice.addresses)) {
          let address =
            invoice.addresses.find(item => item.code === "main") ||
            invoice.addresses[0];

          if (address.value) {
            return address.value;
          } else {
            return getFullAddress(address);
          }
        } else {
          let address = invoice.BillAddr || invoice.ShipAddr || {};

          if (_.isEmpty(address)) {
            return "";
          }
          return (
            address.Line1 +
            ", " +
            address.City +
            ", " +
            address.CountrySubDivisionCode +
            " " +
            address.PostalCode
          );
        }
      };
    },
    qbInvoiceFiltered() {
      return this.qbInvoices.filter(invoice => {
        return (
          // Filter qb Invoice #
          ((invoice.qbDocNumber || "")
            .toLowerCase()
            .includes(this.searchQbInvoiceId.toLowerCase()) ||
            this.searchQbInvoiceId === "") &&
          // Filter status
          ((invoice.status || "")
            .toLowerCase()
            .includes(this.searchStatus.toLowerCase()) ||
            this.searchStatus === "") &&
          // Filter QBCompanyName
          ((invoice.clientName || "")
            .toLowerCase()
            .includes(this.searchQBCompanyName.toLowerCase()) ||
            this.searchQBCompanyName === "") &&
          // Filter Job
          ((
            (this.projectList.find(r => r.value == invoice.projectId) || {})
              .displayName || ""
          )
            .toLowerCase()
            .includes(this.searchProject.toLowerCase()) ||
            this.searchProject === "") &&
          // Filter Property
          ((
            (this.propertyList.find(r => r.value == invoice.propertyId) || {})
              .displayName || ""
          )
            .toLowerCase()
            .includes(this.searchProperty.toLowerCase()) ||
            this.searchProperty === "")
        );
      });
    },

    selectedMessage() {
      return counter => {
        if (counter === 1) {
          return "1 item selected";
        } else {
          return `${counter} items selected`;
        }
      };
    }
  },

  methods: {
    getStatus(status) {
      return this.invoiceStatusList.find(x => x.value === status) || {};
    },
    checkBoxChange(checked, id) {
      let item = this.qbInvoiceFiltered.find(item => item.qbInvoiceId === id);
      Vue.set(item, "checked", checked);
      this.selectedItemCount = this.qbInvoiceFiltered.filter(
        item => item.checked === true
      ).length;
    },

    checkBoxAllChange(checked) {
      this.qbInvoiceFiltered.map(item => {
        if (!item.indeterminate) {
          Vue.set(item, "checked", checked);
        }
      });
      this.selectedItemCount = this.qbInvoiceFiltered.filter(
        item => item.checked === true
      ).length;
    },

    changeProject(value, invoiceId) {
      let item =
        this.qbInvoices.find(item => item.qbInvoiceId === invoiceId) || {};
      let projectObject = this.projectList.find(r => r.value == value) || {};
      item.projectId = value;
      item.jobName = projectObject.displayName;
      if (projectObject.propertyId) {
        const property =
          this.propertyList.find(r => r.value == projectObject.propertyId) ||
          {};
        item.propertyId = property.value;
        item.propertyName = property.displayName;
        item.propertyAddress = property.address;
      }
      this.updateInvoiceItem(item, invoiceId);
    },
    changeProperty(value, invoiceId) {
      let item =
        this.qbInvoices.find(item => item.qbInvoiceId === invoiceId) || {};
      const property = this.propertyList.find(r => r.value == value) || {};
      item.propertyId = value;
      item.propertyName = property.displayName;
      item.propertyAddress = property.address;
      item.projectId = "";
      this.updateInvoiceItem(item, invoiceId);
    },
    updateInvoiceItem(item, id) {
      let index = this.qbInvoices.findIndex(r => r.qbInvoiceId == id);
      Vue.set(this.qbInvoices, index, item);
    },
    importDataToRooferIntel() {
      let invoices = this.qbInvoiceFiltered.filter(
        item => item.checked === true && !item.indeterminate
      );
      if (this.invalidProject(invoices)) {
        this.$ri.dialog.openInfoDialog({
          title: "Quickbooks Integration",
          content: "Please select project.",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
        return;
      }
      this.$emit("doImport", invoices);
    },

    invalidProject(invoices) {
      for (const invoice of invoices) {
        if (_.isEmpty(invoice.projectId)) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-cell {
  color: var(--f7-theme-color);
}
.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 232px);
  overflow: hidden;
  overflow-y: auto;
}
thead th {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
thead th::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
</style>
