import { FirebaseActions } from "../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/user_signature`,
    "userSignature"
  );
  return {
    addNewUserSignature: ActionsTemplate.createDocument,
    updateUserSignature: ActionsTemplate.updateDocument,

    getUserSignatureListBys: ActionsTemplate.getDocumentBys,

    async getSignListBys({ commit, dispatch }, value) {
      const userSignatureList = await dispatch("getUserSignatureListBys", [
        {
          prop: "userId",
          val: value,
          op: "=="
        }
      ]);
      await commit(types.SET_SIGNATURE_LIST, userSignatureList || []);
      return userSignatureList;
    }
  };
};
