<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Pay Rate</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="isShowExportPopup = true">Export Excel</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      :value="searchValue"
      disable-button-text
      placeholder="Search user"
      :clear-button="true"
      @input="onSearch"
      @searchbar:disable="searchValue = ''"
      class="text-color-white search-list"
    ></f7-searchbar>
    <div>
      <f7-list class="no-margin users-list">
        <f7-list-group
          v-for="item in groupedUsers"
          :key="item.title"
          media-list
        >
          <f7-list-item :title="item.key" group-title></f7-list-item>
          <f7-list-item
            v-for="user in item.data"
            :key="user.uid"
            :title="user.displayName"
            reloadDetail
            :link="`/employee-pay-rate/${user.uid}`"
            :subtitle="displayEmail(user)"
            :text-color="user.uid === selectedId ? 'primary' : 'default'"
          >
            <div slot="text">
              <div>{{ user.customClaims ? user.customClaims.role : "" }}</div>
            </div>
            <f7-chip
              slot="after"
              :text="onlineClass(user.disabled).text"
              :color="onlineClass(user.disabled).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <export-pay-rate-popup
        :isShow="isShowExportPopup"
        @close="isShowExportPopup = false"
      ></export-pay-rate-popup>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { applyFilter, groupBy } from "@/utility/filter-tools";
import ExportPayRatePopup from "@/plugins/time-tracking/components/popups/ExportPayRatePopup.vue";

import Menu from "../../../components/menu/Menu.vue";

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
export default {
  components: {
    ExportPayRatePopup,
    Menu
  },

  data: () => {
    return {
      isShowExportPopup: false,
      searchValue: ""
    };
  },

  computed: {
    ...mapGetters("time-tracking/user", ["userListByHours", "user"]),
    filteredUsers() {
      let list = this.userListByHours.map(x => ({
        ...x,
        active: x.disabled == true ? "Inactive" : "Active"
      }));
      return applyFilter(list, this.searchValue, [
        "displayName",
        "active",
        "email",
        i => i.customClaims.role
      ]);
    },
    groupedUsers() {
      let dataGroup = groupBy(this.filteredUsers, item =>
        ((item.displayName || "").charAt(0) || "").toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map(i => ({
        ...i,
        data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName))
      }));
    },
    selectedId() {
      return (this.user || {}).uid;
    },

    displayEmail() {
      return user => {
        let email = user.email;
        if (user.customClaims.displayEmail) {
          email = user.customClaims.displayEmail;
        }
        return email;
      };
    }
  },

  methods: {
    ...mapActions("time-tracking/group", ["bindGroupList", "unbindGroupList"]),
    ...mapActions("time-tracking/user", ["getUserList", "getUser"]),
    onlineClass(status) {
      return status == true
        ? { color: "", text: "Inactive" }
        : status === false
        ? { color: "green", text: "Active" }
        : { color: "yellow", text: "Pending" };
    },
    selectListIndex(val) {
      return val;
    },
    onSearch(event) {
      this.searchValue = event.target.value;
    }
  },

  mounted() {
    this.getUserList();
    this.bindGroupList();
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  },

  destroyed() {
    this.unbindGroupList();
  }
};
</script>
