/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const historyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_history`,
    "history"
  );

  return {
    //history
    getHistory: historyActions.getDocument,
    getHistoryBys: historyActions.getDocumentBys,
    createHistory: historyActions.createDocument,

    async getHistoryListBys({ commit, dispatch }, payload) {
      const historyList = await dispatch("getHistoryBys", payload);
      commit(types.SET_CONTACT_HISTORY_LIST, historyList || []);
      return historyList;
    },

    async getHistoryById({ commit, dispatch }, id) {
      const historyDoc = await dispatch("getHistory", id);
      commit(types.SET_CONTACT_HISTORY, historyDoc || {});
      return historyDoc;
    }
  };
};
