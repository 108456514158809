<template>
  <div>
    <f7-block-header>{{ title }}</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in value"
        :key="index"
        swipeout
        text-color="blue"
      >
        <a class="cursor-pointer" slot="title" @click="openLink(item.url)">{{
          item.fileName
        }}</a>

        <div slot="footer">
          Uploaded on {{ toDisplayDateString(item.createdAt) }} by
          {{ item.createdBy }}
        </div>
        <div slot="after" v-if="item.default">Official</div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { toDisplayDateString } from "../../../../utility/datetime";
export default {
  props: {
    value: { type: Array, default: () => [] },
    title: String
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(url) {
      window.open(url, "_blank");
    }
  }
};
</script>

<style></style>
