import Vue from "vue";

export default {
    setExpenseList(state, payload) {
        Vue.set(state, "expenseList", payload);
    },
    setExpenseSubscribe(state, payload) {
        Vue.set(state, "expenseSubscribe", payload);
    },
};
