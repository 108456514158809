<template>
  <response-button
    ref="responseButton"
    :responses="responses"
    :isShareLink="false"
    v-on="$listeners"
  ></response-button>
</template>

<script>
import ResponseButton from "../button/ResponseButton.vue";
import { mapGetters } from "vuex";

export default {
  components: { ResponseButton },

  props: {
    actionCodes: { type: Array, default: () => [] }
  },

  computed: {
    ...mapGetters("dashboard/work-order", ["response"]),
    /**
     * Calculate the respone of preparation task
     */
    responses() {
      return this.response.filter(item => item.taskType === "preparation");
    }
  }
};
</script>

<style></style>
