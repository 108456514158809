<template>
  <div class="menu-container">
    <f7-link
      v-for="(menu, index) in menuList"
      :key="index"
      class="menu-item-link"
      :class="{ active: isActiveMenu(menu) }"
      :href="!menu.notALink ? menu.path : ''"
      @click="menu.notALink ? menu.action($store) : () => {}"
    >
      <f7-icon size="18" :f7="menu.icon"></f7-icon>
      <div>{{ menu.title }}</div>
    </f7-link>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("setting/app/profile", ["getDisplayMenu"]),

    menuList() {
      let menuActive = [];
      for (const parentMenu of this.getDisplayMenu) {
        for (const route of parentMenu.routes || []) {
          if (route.isShowMenuMobile) {
            menuActive.push(route);
          }
        }
      }
      return menuActive;
    },
    isActiveMenu() {
      return menu => {
        const currentPath =
          this.$f7?.views?.main?.router?.currentRoute?.route?.path || "";
        switch (menu.title) {
          case "Commercial":
            return (
              currentPath.includes("dashboard/swimlane/commercial") ||
              currentPath.includes("dashboard/grid/commercial")
            );
          case "Residential":
            return (
              currentPath.includes("dashboard/swimlane/residential") ||
              currentPath.includes("dashboard/grid/residential")
            );
          case "Service":
            return (
              currentPath.includes("dashboard/swimlane/service") ||
              currentPath.includes("dashboard/grid/service")
            );
          case "Timer":
            return false;
          case "My Scheduling":
            return (
              currentPath.includes("my-scheduling") &&
              !currentPath.includes("dashboard")
            );
        }
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--menu-mobile-height);
  width: 100%;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: var(--f7-color-bg-neutral);
  bottom: 0;
  z-index: 9999;
  .menu-item-link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
    row-gap: 3px;
    width: 0;
    font-size: 10px;
    color: #626262;
    box-sizing: border-box;
    &.active {
      color: var(--f7-theme-color);
      border-top: 2px solid var(--f7-theme-color);
    }
  }
}
</style>
