export default {
  proposalTemplateList: state => state.proposalTemplateList,

  proposalTemplate: state => state.proposalTemplate,

  proposalTemplateById: ({ proposalTemplateList }) => Id => {
    return proposalTemplateList.find(r => r.id === Id) || {};
  },

  proposalTemplateByType: ({ proposalTemplateList }) => type => {
    return proposalTemplateList.find(r => r.proposalType === type) || {};
  },

  photos: state => {
    return state.proposalTemplate.photos || [];
  },

  contractTemplate: state => state.contractTemplate
};
