<template>
  <send-email-popup ref="sendEmailPopup" popup-title="Send Proposal Email" templateName="proposal"
    :projectId="project && (project.id || '')" :listEmails="emailList"
    :attachments="project && (project.attachmentFiles || [])"></send-email-popup>
</template>

<script>
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import { mapActions, mapGetters } from "vuex";
import axiosService from "@/services/axios.service";
import { firebase } from "@/services/firebase.service";
import _ from "lodash";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  SENDER_CONFIG_EMAIL
} from "../../../../utility/const";

export default {
  components: {
    SendEmailPopup
  },
  data: () => {
    return {
      proposalId: "",
      project: {}
    };
  },
  computed: {
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters({
      setting: "setting/app/system/setting"
    }),
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    }),
    ...mapGetters("common/common", ["emailList"]),
    ...mapGetters('common/app-constant', ['tenantId']),
  },
  created() {
    this.getEmailList();
  },
  methods: {
    ...mapActions("proposal/template", [
      "getProposalEmailTemplate",
      "createEmailNotification"
    ]),
    ...mapActions({
      updateProposal: "proposal/proposal/updateProposal",
      getProposalById: "proposal/proposal/getProposalById",
      setProposal: "proposal/proposal/setProposal",
      createHistory: "proposal/history/createHistory",
      getProjectById: "proposal/project/getProjectById",
      getCompanyById: "proposal/company/getCompanyById",
      getContactById: "proposal/contact/getContactById"
    }),
    ...mapActions({
      bindSetting: "setting/app/system/bindSetting"
    }),
    ...mapActions("common/common", ["getEmailList"]),

    async getComposer(proposal) {
      this.proposalId = proposal.id;
      if (_.isEmpty(this.setting)) {
        await this.bindSetting(this.tenantId);
      }
      this.project = proposal.projectId
        ? await this.getProjectById(proposal.projectId)
        : {};

      const composer = {};
      let clientName = "";
      let clientEmail = "";
      const contact = this.project.contactId
        ? (await this.getContactById(this.project.contactId)) || {}
        : {};
      clientName = contact && `${contact.firstName} ${contact.lastName}`;
      const contactEmailAddresses = (contact.emails || []).map(r => r.value);
      if (
        this.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
        (this.project.businessCode === BUSINESS_CODE_SERVICE &&
          this.project.customerType === BUSINESS_CODE_COMMERCIAL)
      ) {
        const company = this.project.companyId
          ? (await this.getCompanyById(this.project.companyId)) || {}
          : {};

        const companyEmailAddress = (
          (company.others || []).filter(r => r.code == "email") || []
        ).map(r => r.value);
        clientEmail = contactEmailAddresses
          .concat(companyEmailAddress)
          .join(",");
      } else {
        clientEmail = contactEmailAddresses.join(",");
      }

      composer.to = clientEmail || "";
      composer.from = `${this.user.displayName} <${SENDER_CONFIG_EMAIL}>`;
      // popuplate info of user
      composer.cc = this.user.email || "";
      //parse email template info
      let templateEmail = await this.getProposalEmailTemplate();

      //tenantid to base64
      const response = await axiosService.post("/tenant/get-anonymous-token", {
        entity: "proposal",
        entityId: proposal.id
      });
      let valueObject = {};
      if (response && response.status === 200) {
        valueObject = {
          //TODO: Support update client logo in setting (DEV-987)
          logo_url:
            "https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/proposal%2F1618341271487Swadley%20Logo.webp?alt=media&token=87d2a6c8-bc88-4225-84f9-de8f321503b0", //(proposal.pages[0] || {}).components[0].data.logo || "",
          client_name: clientName || "",
          roofing_company_name: this.setting.companyName || "",
          roofing_company_address: this.setting.address || "",
          roofing_company_phone: this.setting.phoneNumber || "",
          proposal_number: proposal.proposalNumber || "",
          detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/proposal-view/?token=${response.data}&id=${proposal.id}`
        };
      }
      composer.message = this.parseEmailTemplate(
        templateEmail.htmlContent,
        valueObject
      );
      composer.subject = this.parseEmailTemplate(
        templateEmail.subject,
        valueObject
      );
      composer.template = "proposal";

      composer.attachments = [];
      composer.scheduleSendingTime = firebase.firestore.Timestamp.now();
      composer.isSent = false;
      composer.numberOfAttempt = 0;
      return composer;
    },

    parseEmailTemplate(htmlContent, valueObject) {
      for (const key in valueObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (valueObject.hasOwnProperty(key)) {
          const value = valueObject[key];
          const reg = new RegExp(`{${key}}`, "g");
          htmlContent = htmlContent.replace(reg, value);
        }
      }
      return htmlContent;
    },

    async sendMail(proposal) {
      this.$f7.preloader.show();
      const self = this;
      const composer = await this.getComposer(proposal);

      await this.$refs.sendEmailPopup.open({
        composer,
        callback: () => {
          self
            .updateProposal({
              id: self.proposalId,
              doc: {
                status: "sent"
              }
            })
            .then(() => {
              return self.getProposalById(self.proposalId);
            })
            .then(proposal => {
              return self.setProposal(proposal);
            })
            .then(() => {
              self.$ri.dialog
                .openSuccessDialog({
                  title: "Send proposal successful",
                  content:
                    "Proposal- " +
                    self.proposal.proposalNumber +
                    " has been successfully sent to the email address: " +
                    composer.to,
                  hideCancelButton: true,
                  onClick: (_sefl, index) => {
                    if (index === 0) {
                      _sefl.app.dialog.close();
                    } else if (index === 1) {
                      _sefl.app.dialog.close();
                    }
                  }
                })
                .open();
            })
            .then(() => {
              // save history
              self.createHistory({
                proposalId: self.proposalId,
                note: "Sent"
              });
            });
        }
      });
      this.$f7.preloader.hide();
    }
  }
};
</script>
