<template>
  <div>
    <data-table
      :headers="isEdit ? productTableHeaders : detailHeader"
      :items="items"
      :pageSize="items.length"
      class="label-cell"
      :class="{
        'dark-header': isEdit,
        'light-header': !isEdit,
        'no-margin-horizontal': $device.desktop
      }"
    >
      <template v-slot:header="{ column }">
        <column-type
          :column="column"
          :colType="discountType"
          @change-select="onChangeSelectTypeDiscount"
          v-if="column.selectTypeDiscount"
        />

        <div v-else>
          {{ column.text }}
        </div>
      </template>

      <template v-slot:body="{ item, index }">
        <td>
          <input
            style="width: 100%"
            ref="input"
            type="text"
            placeholder="Type or select from Price list"
            @change="handleChangeProductName($event, index)"
            :value="item.productName"
          />
        </td>
        <td class="numeric-cell">
          <cell-number-input
            placeholder="Amount"
            numberType="currency"
            :value="item.amount"
            @done="
              updateProductProperty({
                propName: 'amount',
                value: $event,
                index
              });
              validate();
            "
            :isDark="isDark"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td>
          <cell-number-input
            placeholder="Discount"
            :fractionDigits="discountType === 'percent' ? 3 : 2"
            :numberType="discountType === 'percent' ? 'percent' : 'currency'"
            :value="item.discountValue"
            @done="
              updateProductProperty({
                propName: 'discountValue',
                value: $event,
                index
              });
              validate();
            "
            :isDark="isDark"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.netSales | currencyUSD }}
        </td>
        <td>
          <cell-number-input
            placeholder="Tax amount"
            numberType="currency"
            :value="item.taxAmount"
            :fractionDigits="3"
            @done="
              updateProductProperty({
                propName: 'taxAmount',
                value: $event,
                index
              });
              validate();
            "
            :isDark="isDark"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.intoMoney | currencyUSD }}
        </td>
      </template>
    </data-table>

    <f7-row class="flex-end">
      <f7-col width="100" medium="40">
        <div :class="{ summary: !$device.desktop }">
          <slot name="summary"></slot>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import _ from "lodash";
import Vue from "vue";
import ColumnType from "./ColumnType.vue";

export default {
  name: "SimpleSummaryTable",
  components: {
    DataTable,
    CellNumberInput,
    ColumnType
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    isDark: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hoverIndex: "",
      currentProductItems: [],
      isEdit: true,
      discountType: "percent"
    };
  },

  computed: {
    productTableHeaders() {
      return [
        {
          text: "Item Details",
          align: "left",
          sortable: false,
          value: "productName",
          width: "15%"
        },

        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount",
          width: "10%"
        },
        {
          text: "Discount",
          align: "right",
          sortable: false,
          value: "discountValue",
          width: "10%",
          selectTypeDiscount: true
        },
        {
          text: "Net Sales",
          align: "right",
          sortable: false,
          value: "netSales",
          width: "10%"
        },
        {
          text: "Tax",
          align: "right",
          sortable: false,
          value: "taxAmount",
          width: "10%"
        },

        {
          text: "Total",
          align: "right",
          sortable: false,
          value: "intoMoney",
          width: "10%"
        }
      ];
    }
  },

  methods: {
    reCalculationProductItemize(item) {
      const discountAmount =
        item.discountValue === 0
          ? 0
          : this.discountType === "percent"
          ? (item.amount * item.discountValue) / 100
          : item.discountValue || 0;

      const netSales = item.amount - (discountAmount || 0);

      const price = item.amount;

      const priceWithProfitAndMisc = price;

      const intoMoney = netSales + (item.taxAmount || 0);

      return {
        ...item,
        netSales,
        intoMoney,
        priceWithProfitAndMisc,
        price,
        discountType: this.discountType
      };
    },

    handleChangeProductName(event, index){
      this.updateProductProperty({
        propName: 'productName',
        value: event.target.value,
        index
      });
      this.validate();
    },

    updateProductProperty({ propName, value, index }) {
      let newItem = _.cloneDeep(this.currentProductItems[index]);

      newItem[propName] = value;

      // update fields relative
      const itemReCalculation = this.reCalculationProductItemize(newItem);

      Vue.set(this.currentProductItems, index, itemReCalculation);
      this.changeItems();
    },

    changeItems() {
      this.$emit("onChangeItems", this.currentProductItems);
    },

    validate() {
      return true;
    },

    onChangeSelectTypeDiscount(value) {
      this.discountType = value;
      this.$emit("onChangeDiscountType", value);

      //update all items with new discount type
      this.currentProductItems = this.currentProductItems.map(item => {
        const newItem = this.reCalculationProductItemize(item);
        return { ...newItem };
      });

      this.changeItems();
    }
  },

  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        this.currentProductItems = _.cloneDeep(value);
      }
    }
  }
};
</script>

<style scoped>
.flex-end {
  justify-content: flex-end !important;
}
</style>
