import { mapActions } from "vuex";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE
} from "../../../utility/const";
import { getFullAddress } from "@/utility/address";
import _ from "lodash";

export default {
  methods: {
    ...mapActions("scheduling/project", [
      "getProjectById",
      "getBoardById",
      "getActionById"
    ]),
    ...mapActions("scheduling/client", [
      "getContactDetailById",
      "getPropertyDetailById"
    ]),

    getContactName(contact) {
      return contact
        ? ((contact.firstName || "") + " " + (contact.lastName || "")).trim()
        : "";
    },

    getContactAddress(contact) {
      if (!_.isEmpty(contact)) {
        const addresses = contact.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },

    getPropertyName(property) {
      return property ? property.propertyName || "" : "";
    },
    getPropertyAddress(property) {
      if (!_.isEmpty(property)) {
        const addresses = property.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },
    async getJobTitle(job) {
      let jobTitle = "";

      if (["residential", "multifamily"].includes(job.customerType)) {
        const contact = await this.getContactDetailById(job.contactId);
        const contactName = this.getContactName(contact);
        const address = getFullAddress(job.projectAddress);

        jobTitle = contactName ? `${contactName} - ${address}` : address;
      } else if (job.propertyId) {
        const property = await this.getPropertyDetailById(job.propertyId);

        if (property) {
          jobTitle = `${property.propertyName} - ${this.getPropertyAddress(
            property
          )}`;
        }
      }

      return jobTitle;
    },

    async navigateToBoard(mainRouteFrom, userId, projectId) {
      const project = await this.getProjectById(projectId);
      let url = "";

      if (
        ["cancel", "close", "lost-deal", "put-a-lien"].includes(project.status)
      ) {
        url = "/archive-board";
      } else {
        let board = {};
        let action = {};

        this.$f7.preloader.show();
        if (project.boardId) {
          board = await this.getBoardById(project.boardId);
        }
        if (!_.isEmpty(project.actions)) {
          action = await this.getActionById(project.actions[0]);
        }

        switch (project.businessCode) {
          case BUSINESS_CODE_COMMERCIAL:
            url = `/${mainRouteFrom}/currentView/${this.$f7route.query.currentView}/startWeek/${this.$f7route.query.startWeek}/currentUser/${userId}/dashboard/swimlane/commercial/${action.boards[0]}/project/${project.cardNumber}`;
            if (action.boards[0] !== "construction") {
              url += `/action/${action.code}`;
            }
            break;

          case BUSINESS_CODE_RESIDENTIAL:
            url = `/${mainRouteFrom}/currentView/${this.$f7route.query.currentView}/startWeek/${this.$f7route.query.startWeek}/currentUser/${userId}/dashboard/swimlane/residential/${project.cardNumber}/action/${action.code}`;
            break;

          case BUSINESS_CODE_SERVICE:
            url = `/${mainRouteFrom}/currentView/${this.$f7route.query.currentView}/startWeek/${this.$f7route.query.startWeek}/currentUser/${userId}/dashboard/swimlane/service/${board.boardNumber}/card/${project.cardNumber}`;
            break;
        }
      }
      this.$f7router.navigate(url, {
        pushState: true,
        reloadAll: true
      });

      this.$f7.preloader.hide();
    }
  }
};
