import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_EXPENSE_HISTORY](state, payload) {
    Vue.set(state, "expenseHistory", payload);
  },

  [types.SET_WORKING_HOURS_HISTORY](state, payload) {
    Vue.set(state, "workingHoursHistory", payload);
  }
};
