import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/time_tracking_draf_2`,
    "object"
  );

  const ActionsTimer = new FirebaseActions(
    `/system_client/${tenant}/time_tracking_draf_2`,
    "timer"
  );

  return {
    bind: ActionsTemplate.bindCollection,
    bindBys: ActionsTemplate.bindCollectionBys,
    unbind: ActionsTemplate.unbindCollection,
    create: ActionsTemplate.createDocument,
    update: ActionsTemplate.updateDocument,
    delete: ActionsTemplate.deleteDocument,
    getBys: ActionsTemplate.getDocumentBys,

    async getTimeTrackingDrafList({ dispatch, commit }, payload) {
      const timeTrackingList = await dispatch("getBys", payload);
      await commit(types.SET_TIME_TRACKING_DRAF_LIST, timeTrackingList);
      return timeTrackingList;
    },

    bindTimerListBys: ActionsTimer.bindCollectionBys
  };
};
