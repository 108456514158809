<template>
  <f7-list-input
    ref="listInput"
    class="list-input"
    :label="label"
    :type="type"
    :value="value"
    :placeholder="!editing ? '0' : placeholder"
    :readonly="readonly"
    @focus="focus()"
    @input="input($event.target.value)"
    @blur="blur($event.target.value)"
    @keyup.native.enter="enter()"
  ></f7-list-input>
</template>

<script>
export default {
  props: {
    label: String,
    type: String,
    value: [String, Number],
    placeholder: String,
    readonly: { type: Boolean, default: false }
  },

  data() {
    return { editing: false };
  },

  methods: {
    focus() {
      if (!this.readonly) {
        this.editing = true;

        if (this.value === 0) {
          this.$emit("input", "");
        }
        this.$emit("focus");
      }
    },

    input(value) {
      if (!this.readonly) {
        this.$emit(
          "input",
          this.type === "number" ? parseFloat(value) || 0 : value
        );
      }
    },

    blur(value) {
      if (!this.readonly) {
        this.editing = false;
        this.$emit(
          "done",
          this.type === "number" ? parseFloat(value) || 0 : value
        );
      }
    },

    enter() {
      if (!this.readonly) {
        this.$refs.listInput.$el.getElementsByTagName("input")[0].blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-input ::v-deep input:read-only {
  border: none !important;
  border-radius: none !important;
}

.list-input ::v-deep .input-focused {
  background-color: var(--f7-page-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
}

li ::v-deep .input-focused {
  background-color: var(--f7-page-bg-color);
}

li ::v-deep .item-input-wrap :hover {
  background-color: var(--f7-page-bg-color);
}
</style>
