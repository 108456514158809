<template>
  <div class="page-component">
    <component
      :is="`${data.type}-component`"
      :data="data.data"
      :pageName="pageName"
      :isTemplate="isTemplate"
      @change="change"
    >
    </component>
  </div>
</template>

<script>
import HeaderComponent from "./HeaderComponent.vue";
import FooterComponent from "./FooterComponent.vue";
import TextEditorComponent from "./TextEditorComponent.vue";
import ProductTableComponent from "./ProductTableComponent.vue";
import SummaryEstimateTableComponent from "./SummaryEstimateTableComponent.vue";
import ImageStatusComponent from "./ImageStatusComponent.vue";
import TextEditorResizeableComponent from "./TextEditorResizeableComponent.vue";
import TextAndPhotoComponent from "./TextAndPhotoComponent.vue";
import FinancingProposalTableComponent from "./FinancingProposalTableComponent.vue";
import {
  HEADER_TYPE,
  TEXT_EDITOR_TYPE,
  FOOTER_TYPE,
  PRODUCT_TABLE_TYPE,
  SUMMARY_ESTIMATE_TABLE_TYPE,
  IMAGE_STATUS_TYPE,
  TEXT_EDITOR_RESIZABLE_TYPE
} from "../../utility/const";

export default {
  components: {
    HeaderComponent,
    TextEditorComponent,
    FooterComponent,
    ProductTableComponent,
    SummaryEstimateTableComponent,
    ImageStatusComponent,
    TextEditorResizeableComponent,
    TextAndPhotoComponent,
    FinancingProposalTableComponent
  },
  props: {
    data: Object,
    pageName: String,
    isTemplate: { type: Boolean, default: false }
  },
  data() {
    return {
      HEADER_TYPE,
      FOOTER_TYPE,
      TEXT_EDITOR_TYPE,
      TEXT_EDITOR_RESIZABLE_TYPE,
      PRODUCT_TABLE_TYPE,
      SUMMARY_ESTIMATE_TABLE_TYPE,
      IMAGE_STATUS_TYPE
    };
  },
  methods: {
    change($event) {
      this.$emit("change", $event);
    }
  }
};
</script>

<style></style>
