import {
  FirebaseActions,
  StorageActions
} from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const expenseActions = new FirebaseActions(
    `/system_client/${tenant}/employee_expenses`,
    "expense"
  );

  return {
    bindExpenseList: expenseActions.bindCollection,
    bindExpenseListBys: expenseActions.bindCollectionBys,
    unbindExpenseList: expenseActions.unbindCollection,

    bindExpense: expenseActions.bindDocument,
    unbindExpense: expenseActions.unbindDocument,

    createExpense: expenseActions.createDocument,
    updateExpense: expenseActions.updateDocument,
    deleteExpense: expenseActions.deleteDocument,

    setExpenseId({ commit }, id) {
      commit(types.SET_EXPENSE_ID, id);
    },

    // eslint-disable-next-line no-unused-vars
    async uploadAttachment({ commit }, file) {
      const name = +new Date() + file.name;
      const fullPath = `expense/attachments/${name}`;
      return StorageActions.uploadFile(fullPath, file);
    },

    // eslint-disable-next-line no-unused-vars
    async removeAttachment({ commit }, fullPath) {
      return StorageActions.removeFile(fullPath);
    },

    changeExpenseItem({ commit }, { id, item }) {
      commit(types.CHANGE_EXPENSE_ITEM, { id, item });
    }
  };
};
