import Vue from "vue";

export default {
    setWeeklyLaborCostList(state, payload) {
        Vue.set(state, "weeklyLaborCostList", payload);
    },
    setWeeklyLaborCostSubscribe(state, payload) {
        Vue.set(state, "weeklyLaborCostSubscribe", payload);
    },
};
