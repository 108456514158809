<template>
  <f7-page v-if="expense && !loading">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>My Expenses Details</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="expense.status !== STATUS_EXPENSE_APPROVED"
          @click="isShowEditPopup = true"
        >
          Edit
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-list>
      <f7-list-item
        header="Expenses name"
        :title="expense.expensesName"
      ></f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item
        header="Building/Section#"
        :title="expense.buildingOrSection"
      ></f7-list-item>
    </f7-list>
    <div v-if="expense.reportType === EXPENSE_TYPE_MILEAGE">
      <f7-list>
        <f7-list-item header="From" :title="expense.from"></f7-list-item>
        <f7-list-item header="To" :title="expense.to"></f7-list-item>
      </f7-list>
      <f7-list>
        <f7-list-item
          header="Mileage"
          :title="parseFloat(expense.mileage) | NUMBER"
        ></f7-list-item>
      </f7-list>
    </div>
    <div v-else>
      <f7-list>
        <f7-list-item
          header="Amount"
          :title="expense.amount | currencyUSD"
        ></f7-list-item>
      </f7-list>
      <f7-list media-list>
        <f7-list-item header="Attachments">
          <div v-for="(file, index) in expense.attachments" :key="index">
            <a
              style="color: #2196f3"
              class="external"
              :href="file.fileUrl"
              download="download"
              target="_blank"
              >{{ file.name }}</a
            >
          </div>
        </f7-list-item>
      </f7-list>
    </div>
    <f7-list>
      <f7-list-item
        header="Project Name"
        :title="projectDetail.title || ''"
      ></f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-input
        label="Notes"
        type="textarea"
        :value="expense.note"
        readonly
      ></f7-list-input>
    </f7-list>
    <f7-list media-list v-if="expense.status === STATUS_EXPENSE_REJECTED">
      <f7-list-input
        label="Rejected Notes"
        type="textarea"
        :value="rejectedNote.note"
        readonly
      ></f7-list-input>
      <f7-list-item>
        <div slot="text">
          <div>By: {{ rejectedNote.createdBy }}</div>
          <div>Created at: {{ rejectedNote.createdAt | DATETIME }}</div>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-button
        v-if="expense.status == STATUS_EXPENSE_WFA"
        color="red"
        @click.native="remove"
      >
        Delete
      </f7-list-button>
    </f7-list>

    <add-new-employee-expense-popup
      :isShow="isShowEditPopup"
      :isEdit="true"
      :isMyExpense="true"
      @close="isShowEditPopup = false"
    ></add-new-employee-expense-popup>
  </f7-page>

  <f7-page v-else-if="!expense && !loading">
    <f7-navbar backLink="Back">
      <f7-nav-title>Not found Expenses</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The expenses could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
  <f7-page v-else></f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNewEmployeeExpensePopup from "@/plugins/expenses/components/popups/AddNewEmployeeExpensePopup.vue";

import {
  EXPENSE_TYPE_MILEAGE,
  STATUS_EXPENSE_REJECTED,
  STATUS_EXPENSE_APPROVED,
  STATUS_EXPENSE_WFA
} from "@/utility/const";

export default {
  components: {
    AddNewEmployeeExpensePopup
  },

  data: () => {
    return {
      EXPENSE_TYPE_MILEAGE,
      STATUS_EXPENSE_REJECTED,
      STATUS_EXPENSE_APPROVED,
      STATUS_EXPENSE_WFA,
      isShowEditPopup: false,
      projectDetail: {},
      loading: true
    };
  },

  methods: {
    ...mapActions("common/expense", [
      "bindExpense",
      "unbindExpense",
      "updateExpense",
      "deleteExpense"
      // "removeAttachment"
    ]),
    ...mapActions("common/project", ["getProjectById"]),
    remove() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete Expense",
        content: "Are you sure you want to delete this epxense?",
        textButton: "Delete",
        // hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            // const expenseClone = _.cloneDeep(app.expense);
            // if (
            //   expenseClone.reportType !== "mileage" &&
            //   expenseClone.attachments.length > 0
            // ) {
            //   expenseClone.attachments.forEach(attach => {
            //     app.removeAttachment(attach.fullPath);
            //   });
            // }
            app.$f7.preloader.show();
            app.deleteExpense(app.expense.id).then(() => {
              app.unbindExpense();
              _sefl.app.dialog.close();
              app.$f7.preloader.hide();
            });
          }
        }
      });
    },
    handleBack() {
      this.$f7router.back("/my-expenses", { force: true });
    }
  },

  computed: {
    ...mapGetters("common/expense", ["expense"]),
    rejectedNote() {
      return this.expense && this.expense.rejectedNote
        ? {
            ...this.expense.rejectedNote,
            createdAt: this.expense.rejectedNote.createdAt.toDate()
          }
        : {};
    }
  },

  async created() {
    this.$f7.preloader.show();
    await this.bindExpense(this.$f7route.params.id);
    if (this.expense.projectId) {
      this.projectDetail = await this.getProjectById(this.expense.projectId);
    }
    this.loading = false;
    this.$f7.preloader.hide();
  }
};
</script>
