<template>
  <!-- Building Measurement -->
  <f7-list :inset="$device.desktop">
    <f7-row class="full-row">
      <f7-col width="100" xsmall="50">
        <f7-block-title>
          Measurement Information
        </f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <!-- Field Area (SQ) -->
          <list-item-input
            label="Field Area (SQ)"
            readonly
            :value="
              (currentBuilding.fieldArea || 0) * currentBuilding.numberOfCopy
            "
          >
          </list-item-input>
          <!-- Wall Area (SQ) -->
          <list-item-input
            label="Wall Area (SQ)"
            readonly
            :value="
              (currentBuilding.wallArea || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>

          <!-- Total SQ's -->
          <f7-list-input
            label="Total (SQ)"
            disabled
            :value="
              (parseFloat(currentBuilding.fieldArea || 0) +
                parseFloat(currentBuilding.wallArea || 0)) *
                currentBuilding.numberOfCopy
            "
          ></f7-list-input>
        </f7-list>
      </f7-col>

      <f7-col width="100" xsmall="50">
        <f7-block-title> &nbsp;</f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <!-- Perimeter (LF) -->
          <list-item-input
            label="Perimeter (LF)"
            readonly
            :value="
              (currentBuilding.perimeter || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>
          <!-- Tapered Area (SQ) -->
          <list-item-input
            label="Tapered Area (SQ)"
            readonly
            :value="
              (currentBuilding.taperedArea || 0) * currentBuilding.numberOfCopy
            "
          ></list-item-input>

          <!-- flute_fill -->
          <f7-list-item
            disabled
            checkbox
            title="Flute Fill"
            :checked="currentBuilding ? currentBuilding.fluteFill : false"
          >
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
    <f7-row class="full-row">
      <f7-col width="100" xsmall="50">
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <f7-list-item
            disabled
            checkbox
            title="Overflow Drain"
            :checked="currentBuilding ? currentBuilding.overflowDrain : false"
          >
          </f7-list-item>

          <f7-list-input
            label="Number of Drain"
            readonly
            :value="
              (currentBuilding.numberOfDrain || 0) *
                currentBuilding.numberOfCopy
            "
          >
          </f7-list-input>

          <f7-list-item
            disabled
            checkbox
            title="Overflow Scuppers"
            :checked="
              currentBuilding ? currentBuilding.overflowScuppers : false
            "
          >
          </f7-list-item>

          <f7-list-input
            label="Number of Scuppers"
            readonly
            :value="
              (currentBuilding.numberOfScuppers || 0) *
                currentBuilding.numberOfCopy
            "
          >
          </f7-list-input>
        </f7-list>
      </f7-col>
      <f7-col width="100" xsmall="50">
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <f7-list-input
            label="Number of Plumbing Penetrations"
            readonly
            :value="
              (currentBuilding.numberOfPlumbingPenetrations || 0) *
                currentBuilding.numberOfCopy
            "
          >
          </f7-list-input>
          <f7-list-input
            label="Termination Bar (LF)"
            readonly
            :value="
              (currentBuilding.terminationBar || 0) *
                currentBuilding.numberOfCopy
            "
          >
          </f7-list-input>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-list>
</template>

<script>
import ListItemInput from "../inputs/ListItemInput.vue";

import { mapGetters } from "vuex";
export default {
  components: { ListItemInput },

  data() {
    return {
      priceListObject: {},
      vendorList: [],
      manufacturerList: [],
      insulationLayer: [],
      tpoWarrantyOptionList: []
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", ["currentBuilding"])
  },

  methods: {
    vendorByIds() {},
    changeVendors() {},
    manufacturersByIds() {},
    changeManufacturers() {}
  }
};
</script>

<style></style>
