import * as types from "./types";
import Vue from "vue";

export default {
  setPropertyList(state, payload) {
    Vue.set(state, "propertyList", payload);
  },
  setPropertySubscribe(state, payload) {
    Vue.set(state, "propertySubscribe", payload);
  },
  selectProperty(state, propertyId) {
    state.projectOfProperty =
      state.propertyList.find(x => x.id == propertyId).project || [];
    state.addressOfProperty =
      state.propertyList.find(x => x.id == propertyId).address || null;
  },

  [types.SET_PROPERTY](state, data) {
    Vue.set(state, "property", data);
  }
};
