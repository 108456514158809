<template>
  <!-- Building Measurement -->
  <f7-list :inset="$device.desktop">
    <f7-row class="full-row">
      <f7-col width="100" xsmall="50">
        <f7-block-title>
          Assembly Information
        </f7-block-title>
        <f7-list :inline-labels="$f7.device.desktop || $f7.device.ipad">
          <!-- Membrane Attached -->
          <list-item-input
            label="Membrane Attached"
            readonly
            :value="
              membraneAttachmentType(currentBuilding.membraneAttached)
                .displayName
            "
          >
          </list-item-input>

          <list-item-input
            label="Membrane Thickness"
            readonly
            :value="
              membraneThickness(currentBuilding.membraneThickness).displayName
            "
          >
          </list-item-input>

          <list-item-input
            label="Fastening Pattern"
            readonly
            :value="
              fasteningPattern(currentBuilding.fasteningPattern).displayName
            "
          >
          </list-item-input>
        </f7-list>
      </f7-col>

      <f7-col width="100" xsmall="50">
        <f7-block-title> &nbsp;</f7-block-title>
        <f7-list
          media-list
          :inline-labels="$f7.device.desktop || $f7.device.ipad"
        >
          <list-item-input
            label="Number of Insulation Layers"
            readonly
            :value="currentBuilding.numberInsulationLayers"
          ></list-item-input>

          <f7-list-item
            v-for="(layer, index) in insulationLayers(
              currentBuilding.insulationLayers
            )"
            :key="index"
            :subtitle="
              'Layer# ' + (index + 1) + ': ' + layer.insulationType.displayName
            "
          >
            <div slot="text">
              {{
                "Insulation Thickness: " +
                  insulationThickness(layer.insulationThickness.value)
                    .displayName
              }}
            </div>
            <div slot="text">
              {{
                "Attachment Type: " +
                  layer.insulationAttachmentType.displayName +
                  insulationPatternText(layer)
              }}
            </div>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>
  </f7-list>
</template>

<script>
import ListItemInput from "../inputs/ListItemInput.vue";

import { mapGetters } from "vuex";

import {
  ATTACHMENT_TYPE_TPO_MA_LIST,
  ATTACHMENT_TYPE_TPO_FA_LIST
} from "../../../../utility/const";

export default {
  components: { ListItemInput },

  data() {
    return {
      priceListObject: {},
      vendorList: [],
      manufacturerList: [],
      insulationLayer: [],
      tpoWarrantyOptionList: []
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", ["currentBuilding"]),
    ...mapGetters("common/app-constant", [
      "membraneAttachmentType",
      "membraneThickness",
      "fasteningPattern",
      "insulationLayers",
      "insulationThickness"
    ]),

    insulationPatternText() {
      return layer => {
        if (
          ATTACHMENT_TYPE_TPO_MA_LIST.includes(
            layer.insulationAttachmentType.value
          )
        ) {
          return "; Screws Per Board: " + layer.screwsPerBoard;
        }

        if (
          ATTACHMENT_TYPE_TPO_FA_LIST.includes(
            layer.insulationAttachmentType.value
          )
        ) {
          return "; Coverage Rate: " + layer.coverageRate;
        }

        return "";
      };
    }
  },

  methods: {
    vendorByIds() {},
    changeVendors() {},
    manufacturersByIds() {},
    changeManufacturers() {}
  }
};
</script>

<style></style>
