<template>
  <div>
    <data-table
      :headers="headers"
      :items="sortedHistoryList"
      :pageSize="sortedHistoryList.length"
      class="table-fixed-container"
    >
      <template slot="card-header">
        <f7-list>
          <f7-searchbar
            placeholder="Search By Vendor, Manufacturer, Product Name"
            :clear-button="true"
            disable-button-text
            :value="searchText"
            @input="
              searchText = $event.target.value;
              onSearch();
            "
            @searchbar:clear="
              searchText = '';
              onSearch();
            "
            @searchbar:disable="
              searchText = '';
              onSearch();
            "
          >
          </f7-searchbar>
        </f7-list>
      </template>
      <template v-slot:body="{ item }">
        <template v-if="item.parentRow">
          <td class="parent-row" :colspan="headers.length">
            {{ item.date }}
          </td>
        </template>
        <template v-else>
          <td>{{ item.vendorName }}</td>
          <td>{{ item.manufacturer }}</td>
          <td>{{ item.productItem }}</td>
          <td>{{ item.oldPrice | currencyUSD }}</td>
          <td>{{ item.newPrice | currencyUSD }}</td>
          <td>
            {{ convertDateTimeFromAlgoliaToDate(item.createdAt) | MMDDYYYY }}
          </td>
          <td>
            {{ item.createdBy }}
          </td>
        </template>
      </template>
      <template slot="paging-footer">
        <div v-if="sortedHistoryList.length > 0" class="data-table-footer">
          <div class="data-table-rows-select">
            Per page:
            <div class="input input-dropdown">
              <select
                @input="onChangeLimit($event.target.value.trim())"
                :value="hitsPerPage"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
          <div class="data-table-pagination">
            <span
              v-if="page === 0"
              class="display-flex align-items-center margin-left color-gray"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</span
            >
            <a
              v-else
              @click="onGoToPage('first')"
              class="display-flex align-items-center margin-left"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</a
            >

            <span v-if="page === 0" class="margin-left color-gray"
              >Previous</span
            >
            <a v-else @click="onGoToPage('prev')" class="margin-left"
              >Previous</a
            >

            <span class="data-table-pagination-label margin-left">{{
              pagingMessage
            }}</span>

            <a
              v-if="page < nbPages - 1"
              @click="onGoToPage('next')"
              class="margin-left"
              >Next</a
            >
            <span v-else class="margin-left color-gray">Next</span>

            <a
              v-if="page < nbPages - 1"
              @click="onGoToPage('last')"
              class="display-flex align-items-center margin-left"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></a>
            <span
              v-else
              class="display-flex align-items-center margin-left color-gray"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></span>
          </div>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import { groupBy } from "@/utility/filter-tools";
import moment from "moment";
import { convertDateTimeFromAlgoliaToDate } from "../../../../utility/date-time-tool";

export default {
  components: { DataTable },
  data() {
    return {
      searchText: ""
    };
  },
  computed: {
    ...mapGetters("price-list/price-list-page/price-list", ["priceListDetail"]),
    ...mapGetters("price-list/price-list-page/price-list-history", [
      "hits",
      "hitsPerPage",
      "nbHits",
      "nbPages",
      "page"
    ]),

    headers() {
      return [
        {
          text: "Vendor",
          align: "left",
          value: "addresses"
        },
        {
          text: "Manufacturer",
          align: "left",
          value: "addresses"
        },
        {
          text: "Product Name",
          align: "left",
          value: "productName"
        },
        {
          text: "Old Price",
          align: "left",
          value: "oldPrice"
        },
        {
          text: "New Price",
          align: "left",
          value: "newPrice"
        },
        {
          text: "Change At",
          align: "left",
          value: "addresses"
        },
        {
          text: "Change By",
          align: "left",
          value: "changeBy"
        }
      ];
    },

    sortedHistoryList() {
      const groupByDate = groupBy(this.hits, i =>
        i.createdAt
          ? moment(
              convertDateTimeFromAlgoliaToDate(i.createdAt),
              "MM/DD/YYYY"
            ).format("MM/DD/YYYY")
          : ""
      );
      let listItems = [];
      for (const group of groupByDate) {
        listItems.push({
          parentRow: true,
          date: group.key
        });
        listItems = [...listItems, ...group.data];
      }

      return listItems;
    },

    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },

    compileConditionsToSearchByPL() {
      const filters = `priceListId:${this.priceListDetail.id}`;

      const restrictSearchableAttributes = [
        "productItem",
        "vendorName",
        "manufacturer"
      ];
      return {
        query: this.searchText,
        filters,
        restrictSearchableAttributes
      };
    }
  },
  methods: {
    ...mapActions("price-list/price-list-page/price-list-history", [
      "searchHistory",
      "goToPage"
    ]),

    initData() {
      this.onSearch();
    },

    onSearch() {
      this.$f7.preloader.show();
      return this.searchHistory(this.compileConditionsToSearchByPL).finally(
        () => {
          this.$f7.preloader.hide();
        }
      );
    },

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        pageName,
        ...this.compileConditionsToSearchByPL
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows(value);
      this.onSearch();
    },

    convertDateTimeFromAlgoliaToDate(value) {
      return convertDateTimeFromAlgoliaToDate(value);
    }
  }
};
</script>

<style scoped>
.parent-row {
  background-color: var(--f7-color-bg-7-neutral);
  font-weight: bold;
}

.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 110px);
  overflow: hidden;
}
</style>
