<template>
  <input
    :placeholder="placeholder"
    type="text"
    name="name"
    :value="value"
    @change="$emit('change', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: String
  }
};
</script>
