<template>
  <div>
    <data-table
      :headers="headers"
      :items="hits"
      :pageSize="hits.length"
    >
      <template slot="card-header">
        <f7-list>
          <f7-searchbar
            placeholder="Search in product items"
            :clear-button="true"
            disable-button-text
            :value="searchText"
            @input="
              setSearchText($event.target.value)
              onSearch();
            "
            @searchbar:clear="
              setSearchText('')
              onSearch();
            "
            @searchbar:disable="
              setSearchText('')
              onSearch();
            "
            class="search-list-detail"
          >
            <f7-button
              v-show="$device.desktop"
              slot="inner-end"
              @click="startAddNewProduct"
              fill
              class="add-button margin-left"
            >
              <f7-icon f7="plus"></f7-icon>
              Product
            </f7-button>
            <f7-button
              v-show="$device.desktop"
              slot="inner-end"
              outline
              @click="startImportProduct"
              class="add-button margin-left"
            >
              Import products
            </f7-button>
            <f7-button
              v-show="$device.desktop"
              slot="inner-end"
              outline
              class="add-button margin-left"
              @click="deleteAll"
              >Delete All</f7-button
            >
            <div v-show="!$device.desktop" class="margin-left">
              <f7-link popover-open=".popover-button-vender">
                <f7-icon f7="bars" color="primary"></f7-icon>
              </f7-link>

              <f7-popover
                style="width:200px; "
                class="popover-button-vender"
                vertical-position="bottom"
                ref="popoverButtonVender"
              >
                <div
                  style="display: flex; flex-direction: column; gap:10px; align-items: center; padding:20px 0"
                >
                  <f7-button
                    slot="inner-end"
                    @click="startAddNewProduct"
                    fill
                    class="add-button margin-left"
                  >
                    <f7-icon f7="plus"></f7-icon>
                    Product
                  </f7-button>
                  <f7-button
                    slot="inner-end"
                    outline
                    @click="startImportProduct"
                    class="add-button margin-left"
                  >
                    Import products
                  </f7-button>
                  <f7-button
                    slot="inner-end"
                    outline
                    class="add-button margin-left"
                    @click="deleteAll"
                    >Delete All</f7-button
                  >
                </div>
              </f7-popover>
            </div>
          </f7-searchbar>
        </f7-list>
      </template>
      <template v-slot:body="{ item }">
        <td>{{ item.sku }}</td>
        <td>{{ item.productItem }}</td>
        <td>{{ item.manufacturer }}</td>
        <td>{{ roofTypeNames(item.roofTypes) }}</td>
        <td>{{ item.categoryName }}</td>
        <td>{{ item.subCategoryName }}</td>
        <td>
          <ul class="technical-data">
            <li v-for="(attach, index) in item.technicalData" :key="index" :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`">
              <a class="display-block"  @click="openLink(item, attach)" >
                {{ attach.name }}
              </a>
            </li>
          </ul>
        </td>
        <td>
          <f7-chip
            :text="productItemStatusByValue(item.status).displayName"
            :color="productItemStatusByValue(item.status).color"
          ></f7-chip>
        </td>
        <td>
          <f7-link
            v-for="(color, index) in item.colorPhotos || []"
            :key="index"
            @click="clickPhoto(color)"
            :id="`${item.id}-${color.id}`"
          >
            {{
              `${color.colorName}${
                index + 1 === (item.colorPhotos || []).length ? "" : ",&nbsp;"
              }`
            }}
          </f7-link>
        </td>
        <td>{{ item.packaging }}</td>
        <td>{{ item.unitPack }}</td>
        <td>{{ item.unitSize }}</td>
        <td>{{ item.uom }}</td>
        <td>{{ item.wasterFactor }}</td>
        <td>{{ item.price | currencyUSD }}</td>
        <td :data-collapsible-title="headers[9].text">
          <long-content-block
            :seedId="`note-${item.id}`"
            :content="item.note"
          ></long-content-block>
        </td>
        <td class="text-align-center">
          <div
            class="display-flex justify-content-center"
            style="width: 70px; gap: 6px"
          >
            <a
              class="display-flex align-items-center"
              href="#"
              @click.stop="onEdit(item)"
            >
              <i
                class="f7-icons text-color-blue"
                style="margin: 2px; font-size: 20px;"
                >pencil</i
              >
            </a>
            <a
              class="display-flex align-items-center"
              href="#"
              @click.stop="copyProduct(item)"
            >
              <i
                class="f7-icons text-color-blue"
                style="margin: 2px; font-size: 20px;"
                >doc_on_doc</i
              >
            </a>
            <a
              class="display-flex align-items-center"
              href="#"
              @click.stop="onDelete(item)"
            >
              <i class="f7-icons" style="color: red; font-size: 20px;">trash</i>
            </a>
          </div>
        </td>
      </template>
      <template slot="paging-footer">
        <div
          v-if="hits.length > 0"
          class="data-table-footer"
          :class="$device.desktop ? '' : 'mobile-container'"
        >
          <div class="data-table-rows-select">
            Per page:
            <div class="input input-dropdown">
              <select
                @input="onChangeLimit($event.target.value.trim())"
                :value="hitsPerPage"
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
          <div
            class="data-table-pagination"
            :style="$device.desktop ? '' : 'margin-left:-20px'"
          >
            <span
              v-if="page === 0"
              class="display-flex align-items-center margin-left color-gray"
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</span
            >
            <a
              v-else
              @click="onGoToPage('first')"
              class="display-flex align-items-center margin-left "
              disabled
              ><f7-icon f7="chevron_left"></f7-icon>First</a
            >

            <span v-if="page === 0" class="margin-left color-gray"
              >Previous</span
            >
            <a v-else @click="onGoToPage('prev')" class="margin-left"
              >Previous</a
            >

            <span class="data-table-pagination-label margin-left">{{
              pagingMessage
            }}</span>

            <a
              v-if="page < nbPages - 1"
              @click="onGoToPage('next')"
              class="margin-left"
              >Next</a
            >
            <span v-else class="margin-left color-gray">Next</span>

            <a
              v-if="page < nbPages - 1"
              @click="onGoToPage('last')"
              class="display-flex align-items-center margin-left"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></a>
            <span
              v-else
              class="display-flex align-items-center margin-left color-gray"
              >Last<f7-icon f7="chevron_right"></f7-icon
            ></span>
          </div>
        </div>
      </template>
    </data-table>
    <product-editor-popup
      ref="productEditorRef"
      :roofTypeList="roofTypeList"
      :categories="categories"
      :subCategories="subCategories"
      :productItemStatusList="productItemStatusList"
      isProductList
    />
    <input
      v-if="showFileInput"
      type="file"
      style="display: none"
      ref="fileUploadControl"
      @change="fileUploadChange"
    />
    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/datatables";
import { comparationSort } from "@/utility/filter-tools";
import ProductEditorPopup from "@/components/popups/ProductEditorPopup.vue";
import LongContentBlock from "@/components/blocks/LongContentBlock.vue";
import xlsxToJson from "@/utility/xlsx-to-json.js";
import { DEFAULT_STATUS_PRODUCT_ITEM } from "@/utility/const";
import _ from "lodash";
import { isVideo, isImage, createMediaArray, openAttackLink } from "@/utility/common";
import { uuid } from "vue-uuid";
export default {
  data() {
    return {
      productPhotos: [],
      gridFilter: {
        roofType: "",
        categoryId: "",
        subCategoryId: "",
        status: ""
      },
      vendorId: null,
      showFileInput: true,

      totalDelete: 0,
      deleteIndex: 0,
      progressDialog: null,
      typeImage: "",
      colorPhotosImport: []
    };
  },
  components: {
    DataTable,
    ProductEditorPopup,
    LongContentBlock
  },

  methods: {
    ...mapActions("product/product-item-page/product-item", [
      "getFilterData",
      "searchProduct",
      "goToPage",
      "setProductNumberOfRows",
      "create",
      "update",
      "delete",
      "uploadPhoto",
      "getProductBys",
      "getAllProductsByFilters",
      "resetSearch",
      "setSearchText"
    ]),
    ...mapActions("product/product-item-page/price-list", [
      "removeProductRefs"
    ]),

    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },
    
    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },
    roofTypeNames(roofTypes) {
      const rt = this.roofTypeList.filter(r =>
        (roofTypes || []).includes(r.value)
      );
      return rt.map(r => r.displayName || "").join(", ");
    },
    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(", ");
    },

    forEachPromise(items, fn, context) {
      return items.reduce((promise, item) => {
        return promise.then(() => {
          return fn(item, context);
        });
      }, Promise.resolve());
    },

    async getFilterDataResult() {
      await this.getFilterData(this.compileConditionsToFilterData);
      const {
        vendorId,
        categoryId,
        subCategoryId,
        roofType,
        status
      } = this.gridFilter;
      if (!this.vendorIdFilterList.includes(vendorId))
        this.gridFilter.vendorId = "";
      if (!this.categoryIdFilterList.includes(categoryId))
        this.gridFilter.categoryId = "";
      if (!this.subCategoryIdFilterList.includes(subCategoryId))
        this.gridFilter.subCategoryId = "";
      if (!this.roofTypeFilterList.includes(roofType))
        this.gridFilter.roofType = "";
      if (!this.statusFilterList.includes(status)) this.gridFilter.status = "";
    },

    onSearch() {
      this.handleSearch();
    },

    handleSearch: _.debounce(function() {
      this.$f7.preloader.show();
      this.searchProduct(this.compileConditionsToSearch)
        .then(() => this.createColorPhotoTooltip())
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }, 500),

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        pageName,
        ...this.compileConditionsToSearch
      }).finally(() => {
        this.createColorPhotoTooltip();
        this.$f7.preloader.hide();
      });
    },

    async loadCurrentProductPage() {
      this.$f7.preloader.show();
      await this.searchProduct({
        pageNumber: this.page,
        ...this.compileConditionsToSearch
      });
      if (!this.hits.length && this.nbPages > 0) {
        await this.searchProduct({
          pageNumber: this.nbPages - 1,
          ...this.compileConditionsToSearch
        });
      }
      this.createColorPhotoTooltip();
      this.$f7.preloader.hide();
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows(value);
      this.onSearch();
    },

    async deleteAll() {
      this.closePopover();
      const self = this;
      // get all product list
      self.$ri.dialog.openWarningDialog({
        title: "Delete All Products",
        content: "Do you want to delete all product?",
        textButton: "Delete",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            const productList = await this.getAllProductsByFilters(
              this.compileConditionsToSearch
            );

            this.totalDelete = productList.length;
            this.progressDialog = this.$f7.dialog.progress(
              "Deleting all product items!",
              this.deleteIndex
            );
            this.progressDialog.setText(this.loadingMessage);

            return this.forEachPromise(productList, this.deleteProduct, this)
              .then(() => {
                self.$f7.toast
                  .create({
                    text: "All product is deleted!",
                    closeOnClick: true,
                    closeButton: false,
                    closeTimeout: 5000
                  })
                  .open();
                this.loadCurrentProductPage();
              })
              .finally(() => {
                this.$f7.dialog.close();
              });
          }
        }
      });
    },
    async copyProduct(item) {
      const addData = await this.$refs.productEditorRef.startEditor(
        "Copy Product",
        item
      );

      if (!addData) return;
      this.$f7.dialog.preloader("Updating product items. Please wait...");

      if (addData.priceListRefs?.length) delete addData.priceListRefs;

      await this.create(addData);
      this.$f7.dialog.close();
      this.loadCurrentProductPage();
    },

    deleteProduct(product, context) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        process.nextTick(() => {
          return this.delete(product.id)
            .then(() => {
              const refs = (product.priceListRefs || []).map(item => {
                return { id: item, productId: product.id };
              });
              return this.forEachPromise(refs, this.removeProductPriceList);
            })
            .then(() => {
              context.deleteIndex++;
              this.progressDialog.setText(context.loadingMessage);
              this.progressDialog.setProgress(
                parseInt((context.deleteIndex / context.totalDelete) * 100)
              );
              resolve();
            });
        });
      });
    },

    removeProductPriceList({ id, productId }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        process.nextTick(() => {
          return this.removeProductRefs({
            id,
            productId
          }).then(() => {
            setTimeout(() => {
              resolve();
            }, 200);
          });
        });
      });
    },

    onDelete(item) {
      const self = this;
      self.$ri.dialog.openWarningDialog({
        title: "Delete 1 Product",
        content: "Do you want to delete this product?",
        textButton: "Delete",
        onClick: async (_self, index) => {
          if (index === 0) {
            _self.app.dialog.close();
          } else if (index === 1) {
            _self.app.dialog.close();
            self.$f7.preloader.show();
            const product = _.cloneDeep(item);
            this.delete(item.id)
              .then(() => {
                // delete price list refs
                (product.priceListRefs || []).forEach(id => {
                  this.removeProductRefs({
                    id,
                    productId: product.id
                  });
                });

                self.$f7.toast
                  .create({
                    text: "Product is deleted!",
                    closeOnClick: true,
                    closeButton: false,
                    closeTimeout: 2000
                  })
                  .open();
                this.loadCurrentProductPage();
              })
              .catch(error => {
                self.$f7.toast
                  .create({
                    text: error.message,
                    closeOnClick: true,
                    closeButton: false,
                    closeTimeout: 2000
                  })
                  .open();
              })
              .finally(() => {
                self.$f7.preloader.hide();
              });
          }
        }
      });
    },
    async startAddNewProduct() {
      this.closePopover();
      const addData = await this.$refs.productEditorRef.startEditor(
        "Add new product",
        {
          sku: "",
          manufacturer: "",
          productItem: "",
          subCategoryId: "",
          categoryId: "",
          uom: "",
          note: "",
          roofTypes: [],
          price: "",
          wasterFactor: "",
          size: "",
          vendorId: this.vendorId,
          color: "",
          status: DEFAULT_STATUS_PRODUCT_ITEM,

          technicalData: []
        }
      );
      if (!addData) return;
      this.$f7.preloader.show();
      await this.create(addData);
      await this.loadCurrentProductPage();
      this.$f7.preloader.hide();
    },
    async onEdit(item) {
      const editData = await this.$refs.productEditorRef.startEditor(
        "Edit product",
        item
      );
      if (!editData) return;
      this.$f7.preloader.show();
      await this.update({
        id: editData.id,
        doc: {
          ...editData
        }
      });
      await this.loadCurrentProductPage();
      this.$f7.preloader.hide();
    },
    async fileUploadChange(e) {
      const jsonResult = await xlsxToJson(e.target.files[0], "Products");
      await this.doImport(jsonResult);
      this.showFileInput = false;
      this.$nextTick(() => {
        this.showFileInput = true;
      });
    },
    async urlToBase64(url) {
      try {
        // download image from url
        const response = await fetch(url);
        const blob = await response.blob();
        this.typeImage = blob.type;
        // convert to base64
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        return null;
      }
    },
    async addPhotos(colors) {
      this.$f7.preloader.show();
      let addedColors = [];
      if (!_.isEmpty(colors)) {
        for (const color of colors) {
          // iterate through the colors array
          const photos = [];
          if (color.photos && color.photos.length > 0) {
            // handle if color.photos is not empty
            for (const file of color.photos) {
              const urlParts = file.url.split("/"); // get url
              const name = urlParts[urlParts.length - 1]; // get name from end of url
              const base64Data = await this.urlToBase64(file.url); //convert file.url to base64

              // update file
              file.dataUrl = `data:${this.typeImage};base64,${base64Data}`;
              file.info = { type: this.typeImage, name };
              file.thumbnailUrl = file.dataUrl;

              const { fullPath, url } = await this.uploadPhoto(file); // upload photo to db

              // push data to photos array
              photos.push({
                thumbnailUrl: url,
                thumbnailFullPath: fullPath,
                url: url,
                name: name,
                fullPath: fullPath
              });
            }
          }
          // push data to addedColors array
          addedColors.push({
            id: uuid.v1(),
            colorName: color.colorName,
            photos: photos
          });
        }
      }
      this.colorPhotosImport = addedColors;
      this.$f7.preloader.hide();
    },
    async doImport(jsonResult) {
      const importableData = [];
      for (let index = 0; index < jsonResult.length; index++) {
        const object = jsonResult[index];

        const categoryId = (
          this.categories.find(r => r.name == object["Product category"]) || {}
        ).id;
        if (!categoryId) {
          this.informImportError(
            `Invalid category ${object["Product category"]} in row ${index + 2}`
          );
          return;
        }
        const subCategoryId = (
          this.subCategories.find(r => r.name == object["Sub category"]) || {}
        ).id;
        if (!subCategoryId) {
          this.informImportError(
            `Invalid sub category ${object["Sub category"]} in row ${index + 2}`
          );
          return;
        }

        const roofType = (
          this.roofTypeList.find(r => r.displayName == object["Roof type"]) ||
          {}
        ).value;
        if (!roofType) {
          this.informImportError(
            `Invalid roof type ${object["Roof type"]} in row ${index + 2}`
          );
          return;
        }

        // If duplicate/exists sku => do nothing
        const productsBySKU = await this.getProductBys([
          { prop: "vendorId", val: this.vendorId, op: "==" },
          { prop: "sku", val: object["SKU"] || "n/a", op: "==" },
          { prop: "isDeleted", val: false, op: "==" }
        ]);
        if (
          object["SKU"] &&
          (importableData.some(r => r.sku === object["SKU"]) ||
            (productsBySKU || []).some(r => r.sku === object["SKU"]))
        ) {
          continue;
          // this.informImportError(
          //   `Duplicate SKU ${object["SKU"]} in row ${index + 2}`
          // );
          // return;
        }

        // add photo to db before import
        await this.addPhotos(object["Color"]);

        const importObject = {
          sku: object["SKU"] || "n/a",
          manufacturer: object["Manufacturer"] || "n/a",
          productItem: object["Product name"] || "n/a",
          subCategoryId,
          categoryId,
          uom:
            object["UoM"]
              .toString()
              .trim()
              .toUpperCase() || "",
          note: object["Note"] || "",
          roofTypes: [roofType],
          price: object["Price"] || 0,
          wasterFactor: object["Waste factor"] || 1,
          packaging: object["Packaging"] || "",
          unitPack: object["Unit packs"] || 1,
          unitSize: object["Unit sizes"] || 1,
          colorPhotos: this.colorPhotosImport,
          vendorId: this.vendorId,
          status: DEFAULT_STATUS_PRODUCT_ITEM,
          productHashtag: object["Product hashtag"] || "TBD",
          per100lf: object["Per 100 LF"] || 0,
          dimension: object["Dimension"] || ""
        };

        importableData.push(importObject);
      }
      this.$f7.preloader.show();
      const promises = [];
      for (let index = 0; index < importableData.length; index++) {
        const product = importableData[index];
        promises.push(this.create(product));
      }
      await Promise.all(promises);
      this.onSearch();
      this.$f7.preloader.hide();
    },
    startImportProduct() {
      this.closePopover();
      this.$refs.fileUploadControl.click();
    },
    closePopover() {
      this.$refs.popoverButtonVender.close();
    },
    informImportError(err) {
      const self = this;
      self.$f7.notification
        .create({
          icon: '<i class="icon icon-f7"></i>',
          title: "RooferIntel",
          titleRightText: "now",
          text: err,
          subtitle: "Import error",
          closeTimeout: 3000
        })
        .open();
    },

    createColorPhotoTooltip() {
      this.hits.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const el = this.$f7.tooltip.get(`#${item.id}-${color.id}`);
          if (el) {
            this.$f7.tooltip.destroy(`#${item.id}-${color.id}`);
          }
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: photo ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
      this.hits.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          if (el1) {
            this.$f7.tooltip.destroy(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)? `<video class="tooltip-image-show" src="${item?.url}" ></video>`:`<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass: isImage(item.url) || isVideo(item.url) ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
    }
  },

  computed: {
    ...mapGetters("product/product-item-page/product-item", [
      "hitsPerPage",
      "hits",
      "nbHits",
      "nbPages",
      "page",
      "searchText",
      "vendorIdFilterList",
      "categoryIdFilterList",
      "subCategoryIdFilterList",
      "roofTypeFilterList",
      "statusFilterList"
    ]),
    ...mapGetters({
      roofTypeList: "product/roof-type/roofTypeList",
      categories: "product/product-item-page/category/objectList",
      subCategories: "product/product-item-page/sub-category/objectList"
    }),
    ...mapGetters("product/product-item-page/app-constant", [
      "productItemStatusList",
      "productItemStatusByValue"
    ]),

    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },

    loadingMessage() {
      return `Deleted ${this.deleteIndex}/${this.totalDelete} products.`;
    },

    categoriesFilterOptions() {
      return this.categories.filter(r =>
        (this.categoryIdFilterList || []).includes(r.id)
      );
    },
    subCategoriesFilterOptions() {
      return this.subCategories.filter(r =>
        (this.subCategoryIdFilterList || []).includes(r.id)
      );
    },
    roofTypeFilterOptions() {
      return this.roofTypeList.filter(r =>
        (this.roofTypeFilterList || []).includes(r.value)
      );
    },
    statusFilterOptions() {
      return this.productItemStatusList.filter(r =>
        (this.statusFilterList || []).includes(r.value)
      );
    },
    headers() {
      let self = this;
      return [
        {
          text: "SKU",
          value: "sku",
          sortable: false,
          align: "left"
        },
        {
          text: "Product item",
          value: "productItem",
          sortable: false,
          align: "left"
        },
        {
          text: "Manufacturer",
          value: "manufacturer",
          sortable: false,
          align: "left"
        },
        {
          text: "Roof type",
          value: "roofType",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.roofType,
            selectSource: [
              { value: "", text: "All roof types" },
              ...this.roofTypeFilterOptions
                .map(r => ({
                  value: r.value,
                  text: r.displayName
                }))
                .sort((a, b) => comparationSort(a, b))
            ],
            onChange: value => {
              self.gridFilter.roofType = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Product category",
          value: "productCategory",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.categoryId,
            selectSource: [
              { value: "", text: "All categories" },
              ...this.categoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.categoryId = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Sub category",
          value: "sub_category",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.subCategoryId,
            selectSource: [
              { value: "", text: "All sub categories" },
              ...this.subCategoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.subCategoryId = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Technical data",
          value: "technicalData",
          sortable: false,
          align: "left"
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.status,
            selectSource: [
              { value: "", text: "All statuses" },
              ...this.statusFilterOptions
                .map(r => ({
                  value: r.value,
                  text: r.displayName
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.status = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Color",
          value: "colorPhotos",
          sortable: false,
          align: "left"
        },
        {
          text: "Packaging",
          value: "packaging",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Packs",
          value: "unitPack",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Size",
          value: "unitSize",
          sortable: false,
          align: "left"
        },
        {
          text: "UoM",
          value: "uom",
          sortable: false,
          align: "left"
        },
        {
          text: "Waste factor",
          value: "wasterFactor",
          sortable: false,
          align: "left"
        },
        {
          text: "Price",
          value: "Price",
          sortable: false,
          align: "left"
        },
        {
          text: "Note",
          value: "note",
          sortable: false,
          align: "left"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: "150px"
        }
      ];
    },

    compileConditionsToFilterData() {
      return {
        filters: `vendorId:${this.vendorId}`
      };
    },

    compileConditionsToSearch() {
      // filter
      const filters = [`vendorId:${this.vendorId}`];
      const {
        vendorId,
        categoryId,
        subCategoryId,
        roofType,
        status
      } = this.gridFilter;

      if (vendorId) filters.push(`vendorId:${vendorId}`);
      if (categoryId) filters.push(`categoryId:${categoryId}`);
      if (subCategoryId) filters.push(`subCategoryId:${subCategoryId}`);
      if (roofType) filters.push(`roofTypes:${roofType}`);
      if (status) filters.push(`status:${status}`);
      return {
        filters: filters.join(" AND ")
      };
    }
  },
  beforeDestroy() {
    this.resetSearch();
  },
  created() {
    this.vendorId = this.$f7route.params.vendorId;
    this.onSearch();
    this.getFilterDataResult();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-detail.searchbar input").focus();
      }
    });
  }
};
</script>

<style scoped>
.fixed-table /deep/ .card-content {
  height: calc(100vh - 96px);
}
.actions-container {
  flex-wrap: nowrap;
}
.add-button {
  min-width: 150px;
}
.image-preview {
  border-radius: 4px;
  width: 70px;
  height: 70px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

 .technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
.image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mobile-container {
  justify-content: center;
  flex-direction: column-reverse;
}
.mobile-container > * {
  padding: 10px 0;
}
</style>
