<template>
  <f7-row class="margin-vertical search-container">
    <f7-col :width="$f7.device.desktop || $f7.device.ipad ? 35 : 100">
      <f7-list inline-labels>
        <f7-list-input :class="!$f7.device.desktop && !$f7.device.ipad && 'custom-input'" type="datepicker"
          label="From Date:" placeholder="mm/dd/yyyy" error-message-force validate validate-on-blur :value="fromDate"
          :error-message="fromDateErrorMessage" :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy'
          }" @calendar:change="handleCalendarChange"></f7-list-input>
      </f7-list>
    </f7-col>
    <f7-col :width="$f7.device.desktop || $f7.device.ipad ? 35 : 100">
      <f7-list inline-labels>
        <f7-list-input :class="!$f7.device.desktop && !$f7.device.ipad && 'custom-input'" type="datepicker"
          label="To Date:" placeholder="mm/dd/yyyy" error-message-force validate validate-on-blur :value="toDate"
          :error-message="toDateErrorMessage" :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
            disabled: date =>
              fromDate && date < new Date(fromDate).setHours(0, 0, 0, 0)
          }" @calendar:change="
            toDate = $event;
            setDatesToFilterQB({fromDate, toDate: $event})
          $f7.calendar.close();
          "></f7-list-input>
      </f7-list>
    </f7-col>
    <f7-col v-if="$f7.device.desktop || $f7.device.ipad" width="15">
      <div class="display-flex justify-content-flex-end">
        <f7-button fill @click="searchByDate" style="padding:0 34px">Search</f7-button>
      </div>
    </f7-col>
    <f7-col v-else width="100" class="margin-horizontal margin-top">
      <f7-button fill @click="searchByDate">Search</f7-button>
    </f7-col>
    <f7-col v-if="$f7.device.desktop || $f7.device.ipad" width="15">
      <export-buttons @exportToQuickbook="exportToQuickbook" @exportToExcel="exportToExcel"
        :isDisableExportButton="isDisableExportButton"></export-buttons>
    </f7-col>
    <f7-col v-else width="100" class="margin-horizontal margin-top">
      <export-buttons @exportToQuickbook="exportToQuickbook" @exportToExcel="exportToExcel"
        :isDisableExportButton="isDisableExportButton"></export-buttons>
    </f7-col>
  </f7-row>
</template>
<script>
import moment from "moment";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { lastWeekMonday } from "../../../../utility/date-time-tool";
import { lastWeekSunday } from "../../../../utility/date-time-tool";
import ExportButtons from "../button/ExportButtons.vue";
import { VALIDATION_MESSAGE } from '@/utility/const';
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ExportButtons
  },
  data: () => {
    return {
      fromDate: null,
      toDate: null
    };
  },
  props: {
    isDisableExportButton: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.searchByDate();
  },
  methods: {
    ...mapActions("quickbooks/time-tracking", [
      "setDatesToFilterQB"
    ]),
    searchByDate() {      
      this.fromDate = this.datesToFilterQB?.fromDate || [lastWeekMonday()];
      this.toDate = this.datesToFilterQB?.toDate || [lastWeekSunday()];
    
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.$emit("searchByDate", {
        fromDate: this.fromDate,
        toDate: this.toDate
      });
    },
    handleCalendarChange(event) {
      this.fromDate = event;      
      if (this.toDate[0] < this.fromDate[0]) {
        this.toDate = event;
      }      
      this.setDatesToFilterQB({fromDate: this.fromDate, toDate: this.toDate});
      this.$f7.calendar.close();
    },
    exportToQuickbook() {
      this.$emit("exportToQuickbook");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    }
  },
  computed: {
    ...mapGetters("quickbooks/time-tracking", [
      "datesToFilterQB"
    ]),
    fromDateErrorMessage() {
      if (this.v$.fromDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD
      return null;
    },
    toDateErrorMessage() {
      if (this.v$.toDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD
      if (!this.v$.toDate.timeRules)
        return VALIDATION_MESSAGE.END_DATE_GREATER_THAN_START_DATE
      return null;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    const timeRules = () => {
      const fromDate = moment(this.fromDate[0]).format("YYYY-MM-DD");
      const toDate = moment(this.toDate[0]).format("YYYY-MM-DD");
      return !moment(toDate).isBefore(fromDate, "day");
    };
    return {
      fromDate: {
        required
      },
      toDate: {
        required,
        timeRules
      }
    };
  }
};
</script>
<style>
.search-container .inline-labels .item-label {
  width: 25%;
}

.search-container .inline-labels .custom-input .item-label {
  width: 35%;
}

.search-container .list input[type="text"] {
  border-bottom: 1px solid;
}
</style>
