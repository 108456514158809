/* eslint-disable no-unused-vars */
import _ from "lodash";

export default {
  user: state => {
    if (_.isEmpty(state.user)) {
      return state.user;
    }
    const email =
      (state.user.customClaims || {}).loginMethod === "email_by_phone"
        ? state.user.customClaims.displayEmail || ""
        : state.user.email;
    return { ...state.user, email };
  },

  currentUser: state => {
    const email =
      (state.user.customClaims || {}).loginMethod === "email_by_phone"
        ? state.user.customClaims.displayEmail || ""
        : state.user.email;
    return { ...state.user, email };
  },

  routes: state => state.routes,

  isOwner: state => {
    return state.user
      ? (state.user.customClaims || {}).role === "owner"
      : false;
  },

  role: (state, getters, rootState, rootGetters) => {
    return getters.user && getters.user.customClaims
      ? getters.user.customClaims.role
      : "";
  },

  currentGroups: state => {
    return state.currentGroups || [];
  },

  parentMenuItems: state => {
    return state.parentMenuItems || [];
  },

  getDisplayMenu: (state, getters, rootState, rootGetters) => {
    const parentMenuItems = getters.parentMenuItems.map(item => {
      return {
        displayRoles: item.displayRoles,
        name: item.name,
        title: item.title,
        routes: []
      };
    });

    getters.parentMenuItems.forEach(menuItem => {
      (menuItem.routes || []).forEach(childMenuItem => {
        if (!_.isEmpty(childMenuItem.parent)) {
          const parent = parentMenuItems.find(
            parent => parent.name === childMenuItem.parent
          );
          let allowGroup = getters.checkGroupGuard(childMenuItem, 1);
          let allowRole = getters.checkRoleGuard(childMenuItem.displayRoles);
          if (!_.isEmpty(parent) && allowRole && allowGroup) {
            parent.routes.push(_.cloneDeep(childMenuItem));
          }
        }
      });
    });
    return parentMenuItems;
  },

  checkRoleGuard: (state, getters, rootState, rootGetters) => roles => {
    if (getters.role === "owner") return true;
    return roles ? roles.includes(getters.role) : false;
  },

  checkGroupGuard: (state, getters, rootState, rootGetters) => (
    route,
    index
  ) => {
    let result = false;
    //for some not check group
    if (route.disableInGroup) return true;
    if (getters.role === "owner") return true;
    if (route && route.name != undefined) {
      getters.currentGroups.forEach(group => {
        let parentList = group.menuIDs.map(item => {
          return item.split("/")[index];
        });

        if (parentList.includes(route.name)) {
          result = true;
        }
      });
    }

    return result;
  }
};
