import { checkAuth } from "../../../services/authentication.service";
// import PriceList from "../pages/PriceList.vue";
// import PriceListEdit from "../pages/PriceListEdit.vue";
// import PriceListDetails from "../pages/PriceListDetails.vue";

export default [
  {
    path: "",
    title: "Price List",
    displayRoles: ["owner", "user"],
    routes: [
      {
        path: "/price-list",
        title: "Price List",
        name: "price-list",
        parent: "product-main",
        displayRoles: ["owner", "user"],
        icon: "square_pencil",
        // component: PriceList,
        // beforeEnter: checkAuth,
        async: checkAuth(() => import("../pages/PriceList.vue")),
        master: true,
        detailRoutes: [
          {
            displayRoles: ["owner", "user"],
            path: "/price-list/:priceListNumber",
            title: "Price List Details",
            // component: PriceListDetails,
            // beforeEnter: checkAuth
            async: checkAuth(() => import("../pages/PriceListDetails.vue"))
          }
        ]
      }
    ]
  }
];
