<template>
  <a href="#" @click="$emit('auditClick')"
    ><f7-icon v-if="overDistance" f7="flag_fill"></f7-icon> View</a
  >
</template>

<script>
import { meterToMile } from "@/utility/distance-tool";
export default {
  props: {
    distance: { type: Number, default: () => 0 }
  },
  computed: {
    overDistance() {
      const mile = meterToMile(this.distance);
      return mile > 1;
    }
  }
};
</script>

<style scoped>
a {
  white-space: nowrap;
}
</style>
