<template>
  <f7-popup class="demo-popup" :opened="isShow" @popup:closed="cancel" @popup:opened="handlePopupOpened">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add Note</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="addNote()">
            Done
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <f7-list-input class="first-input" label="Notes" type="textarea" placeholder="Enter Notes..."
          :value="rejectedNote" @input="rejectedNote = $event.target.value" error-message-force
          :error-message="rejectedNoteErrorMessage"><required-asterisk slot="label" /></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { mapActions } from "vuex";
import { auth } from "../../../../services/firebase.service";
import { VALIDATION_MESSAGE } from '@/utility/const';
export default {
  props: {
    isShow: Boolean
  },
  data: () => {
    return {
      rejectedNote: ""
    };
  },
  methods: {
    ...mapActions("common/expense", ["updateExpense"]),
    cancel() {
      this.rejectedNote = "";
      this.v$.$reset();
      this.$emit("close");
    },
    addNote() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const rejectedNote = {
          note: this.rejectedNote,
          createdAt: this.$google.firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser
            ? auth.currentUser.displayName || auth.currentUser.email
            : ""
        };
        this.$emit("handleReject", rejectedNote);
        this.cancel();
      }
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".first-input textarea").focus();
      }
    }
  },
  computed: {
    rejectedNoteErrorMessage() {
      if (!this.v$.rejectedNote.$error) return "";
      if (this.v$.rejectedNote.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    return {
      rejectedNote: {
        required
      }
    };
  }
};
</script>
