<template>
  <div>
    <f7-block-header v-show="estimateListDisplay.length > 0"
      >Estimates</f7-block-header
    >
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in estimateListDisplay"
        :key="index"
        swipeout
        text-color="blue"
      >
        <a class="cursor-pointer" slot="title" @click="openLink(item)">{{
          item.estimateNumber
        }}</a>

        <div slot="footer">{{ getStatusString(item.status) }}</div>
        <div slot="after" v-if="item.isDefault === true">Official</div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { toDisplayDateString } from "../../../../utility/datetime";
import methods from "../../mixin/methods";
import _ from "lodash";
import { PROP_IS_DELETED } from "../../../../utility/const";

export default {
  mixins: [methods],

  computed: {
    ...mapGetters("dashboard/estimate", ["estimateListByStatus"]),

    ...mapGetters("dashboard/project", [
      "constructionProject",
      "card",
      "action"
    ]),

    ...mapGetters("dashboard/work-order", ["workOrderTask"]),

    estimateListDisplay() {
      return this.estimateListByStatus(["incomplete", "active"]);
    }
  },

  methods: {
    ...mapActions("dashboard/estimate", ["getEstimatesBys"]),

    initData() {
      if (!_.isEmpty(this.card)) {
        this.getEstimatesBys([
          {
            prop: "projectId",
            val: this.card.id,
            op: "=="
          },
          {
            prop: PROP_IS_DELETED,
            val: false,
            op: "=="
          },
          { prop: "status", val: ["incomplete", "active", "draft"], op: "in" }
        ]);
      }
    },

    getStatusString(status) {
      switch (status) {
        case "active":
          return "Active";

        case "inactive":
          return "Inactive";

        default:
          return "Draft";
      }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(estimate) {
      const self = this;
      const actionId = this.action.id;
      const project = this.getProject(this.constructionProject, this.card);
      const estimateNumber = estimate.estimateNumber;

      this.$emit("closePopup", () => {
        let url = `/dashboard/type/${self.$f7route.route.meta.boardType ||
          "swimlane"}/business/${self.$f7route.route.meta.businessCode}/board/${
          self.$f7route.route.meta.boardCode
        }/action/${actionId}/project/${project.id}`;

        if (this.$f7route.route.meta.boardCode === "construction") {
          url += `/task/${this.workOrderTask.id}`;
        }

        url += `/estimate/contact/${estimate.contactId}/${estimateNumber}`;

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true
        });
      });
    }
  }
};
</script>

<style></style>
