export default {
  recurringInvoiceList: [],
  recurringInvoice: {},

  order: "desc",
  searchText: "",

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  selectedStatusFilter: [],

  recurringInvoiceListByInvoiceGroup: []
};
