<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Schedule Of Values</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-f7="plus" @click.native="openNewSchedulePopup"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search"
      :clear-button="true"
      :value="searchText"
      @input="
        setScheduleOfValueSearchText($event.target.value.trim());
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:clear="
        setScheduleOfValueSearchText('');
        onSearch();
      "
      @searchbar:disable="
        setScheduleOfValueSearchText('');
        onSearch();
      "
      class="search-list"
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            :link="`/schedule-of-value/detail/${item.id}`"
            v-for="item in hits"
            :key="item.id"
            :title="item.title"
            :reload-detail="true"
            swipeout
            :text-color="checkRoute(item.id) ? 'primary' : 'default'"
          >
            <div slot="text">
              <div class="font-text">Created by: {{ item.createdBy }}</div>
              <div class="font-text">
                <span>Created on: </span>
                <span
                  ><timeago
                    v-if="item.createdAt"
                    :datetime="convertTimestampToDate(item.createdAt)"
                    :autoUpdate="1"
                    :converterOptions="{ includeSeconds: true }"
                /></span>
              </div>
            </div>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
    </div>

    <create-schedule-of-value-popup
      ref="createScheduleOfValuePopupRef"
    ></create-schedule-of-value-popup>
  </f7-page>
</template>
<script>
import CreateScheduleOfValuePopup from "../components/popup/CreateScheduleOfValuePopup.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Menu from "../../../components/menu/Menu.vue";
import { convertTimestampToDate } from "../../../utility/date-time-tool";

export default {
  components: { CreateScheduleOfValuePopup, Menu },

  data: () => {
    return {
      popupShowListSchedule: false,
      count: 0,
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true
    };
  },

  computed: {
    ...mapGetters("common/schedule-value", [
      "scheduleValue",
      "hits",
      "nbPages",
      "page",
      "searchText"
    ])
  },

  created() {
    this.setScheduleOfValueSearchText("");
    this.onSearch();
  },

  methods: {
    ...mapActions("common/schedule-value", [
      "searchScheduleValue",
      "loadMoreScheduleValue",
      "resetSearch",
      "unbindScheduleValue",
      "setScheduleOfValueSearchText"
    ]),

    onSearch() {
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function() {
      const self = this;
      return self
        .searchScheduleValue({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreScheduleValue({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    checkRoute(id) {
      return id === (this.scheduleValue || {}).id;
    },

    convertTimestampToDate(value) {
      return convertTimestampToDate(value);
    },

    openNewSchedulePopup() {
      this.$refs.createScheduleOfValuePopupRef.open();
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  }
};
</script>
