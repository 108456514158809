<template>
  <tbody>
    <p>Data received from Child1: {{ pinArrayData }}</p>
    <tr
      v-for="(item, index) in items"
      :key="index"
      @click="checkbox ? selectCheckbox(!item.checked, item) : () => {}"
    >
      <td v-if="checkbox">
        <f7-checkbox
          :checked="item.checked"
          @change="selectCheckbox($event.target.checked, item)"
        ></f7-checkbox>
      </td>
      <td
        v-for="(props, index) in headers"
        :key="index"
        :class="`${textAlign(props.align)}`"
      >
        {{ item[props.value] }}
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  props: {
    checkbox: Boolean,
    headers: Array,
    items: Array,
    pinArrayData: Array
  },
  methods: {
    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : "text-align-left";
    },

    selectCheckbox(checked, item) {
      this.$emit("selectCheckbox", { type: "body", checked }, item);
    }
  }
};
</script>
