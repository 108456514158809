import {
  FirebaseActions,
  firestore,
  StorageActions
} from "../../../../services/firebase.service";
import { searchClient } from "../../../../services/search.service";
import {
  COLLECTION_PRODUCT_PRODUCT_ITEMS,
  PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT
} from "../../../../utility/const";
import * as types from "./types";
import _ from "lodash";

export default tenant => {
  const productActions = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    "product"
  );

  const productByPriceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    "productsByPriceListRefs"
  );

  const productItemIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    // Product Actions
    bindProduct: productActions.bindDocument,
    unbindProduct: productActions.unbindDocument,
    createProduct: productActions.createDocument,
    updateProduct: productActions.updateDocument,

    getProduct: productActions.getDocument,
    getProductBys: productActions.getDocumentBys,

    getProductsByPriceListRefsListBys: productByPriceListActions.getDocumentBys,
    unbindProductsByPriceListRefsList:
      productByPriceListActions.unbindCollection,

    async getProductsByPriceListRefsList({ commit, dispatch }, priceListId) {
      const list = await dispatch("getProductsByPriceListRefsListBys", [
        {
          prop: "priceListRefs",
          val: priceListId,
          op: "array-contains"
        },
        {
          prop: "status",
          val: "pi-active",
          op: "=="
        }
      ]);
      commit(types.SET_PRODUCT_BY_PRICELIST_REFS_LIST, list);
    },

    // eslint-disable-next-line no-unused-vars
    async uploadPhoto({ commit }, fileData) {
      const base64Data = fileData.dataUrl;
      const file = fileData.info;
      const name = +new Date() + file.name || "";
      const fullPath = `product/photos/${name}`;
      return StorageActions.uploadFileBase64(fullPath, base64Data, file);
    },

    addPriceListRefs({ dispatch }, { id, priceListId }) {
      dispatch("getProduct", id).then(product => {
        if (product) {
          const priceListRefs = _.union(product.priceListRefs || [], [
            priceListId
          ]);
          dispatch("updateProduct", {
            id,
            doc: { priceListRefs }
          });
        }
      });
    },

    updateCategoryProductItem({ dispatch }, { productId, dataCategory }) {
      dispatch("updateProduct", {
        id: productId,
        doc: dataCategory
      });

      return
    },

    updateBuilding({},{ estimateId, buildingId, building ,tenantId}) {
      return firestore
        .collection(
          `/system_client/${tenantId}/estimate/${estimateId}/building`
        )
        .doc(buildingId)
        .update(building)
    },

    removePriceListRefs({ dispatch }, { id, priceListId }) {
      dispatch("getProduct", id).then(product => {
        if (product) {
          const priceListRefs = _.remove(product.priceListRefs || [], id => {
            return priceListId != id;
          });
          dispatch("updateProduct", {
            id,
            doc: { priceListRefs }
          });
        }
      });
    },

    getProductListBy({ commit, dispatch }, conditions) {
      return dispatch("getProductBys", conditions).then(res => {
        return commit(types.SET_PRODUCT_LIST, res);
      });
    },

    searchProduct(
      { state, commit },
      { query, attributesToRetrieve, restrictSearchableAttributes, filters }
    ) {
      const index = productItemIndexAsc;
      const requestOptions = {
        hitsPerPage: state.numberOfRow,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS} AND status:pi-active`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "productItem",
          "manufacturer",
          "sku",
          "price",
          "vendorName",
          "categoryName",
          "subCategoryName"
        ]
      };
      if (!_.isEmpty(attributesToRetrieve)) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (!_.isEmpty(filters)) {
        requestOptions.filters += ` AND ${filters}`;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage(
      { state, commit },
      {
        pageName,
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes
      }
    ) {
      const index = productItemIndexAsc;

      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = state.page + 1;
          break;

        case "prev":
          pageNumber = state.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }

      const requestOptions = {
        hitsPerPage: state.numberOfRow,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${COLLECTION_PRODUCT_PRODUCT_ITEMS} AND status:pi-active`,
        attributesToRetrieve: PRODUCT_ITEM_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes: [
          "productItem",
          "manufacturer",
          "sku",
          "price",
          "vendorName",
          "categoryName",
          "subCategoryName"
        ]
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }

      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setProductNumberOfRows({ commit }, value) {
      commit(types.SET_NUMBER_OF_ROW, value);
    },

    setSearchText({ commit }, searchText = "") {
      commit(types.SET_SEARCH_TEXT, searchText);
    }
  };
};
