<template>
  <div>
    <f7-block-header>{{ title }}</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in value"
        :key="index"
        @swipeout:deleted="deleteFile(index)"
      >
        <div slot="title">{{ toDisplayDateString(item.date) }}</div>

        <div slot="footer">
          Submit by
          {{ item.createdBy }}
        </div>
      </f7-list-item>

      <!-- Add list item -->
      <f7-list-item @click.native="openDateChooser">
        <!-- <f7-icon
          class="cursor-pointer"
          slot="media"
          f7="plus_circle"
          class="add-button-icon"
        ></f7-icon> -->
        <!-- <span slot="media" class="add-button cursor-pointer"> -->
        <div slot="media" class="add-button-icon cursor-pointer">
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <!-- </span> -->
        <div class="cursor-pointer" slot="title">Add {{ title }}</div>
      </f7-list-item>
      <f7-list-input
        ref="dateInput"
        v-show="false"
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
          on: {
            closed: onClosed
          },
          closeOnSelect: true
        }"
        label="Presentation Date"
        placeholder="Enter Presentation Date"
        readonly
        :value="date"
        @calendar:change="date = $event"
      ></f7-list-input>
    </f7-list>
  </div>
</template>

<script>
import { auth } from "../../../../services/firebase.service";
import {
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import _ from "lodash";

export default {
  props: {
    projectId: String,
    value: { type: Array, default: () => [] },
    title: String
  },

  data: () => {
    return {
      date: []
    };
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openDateChooser() {
      this.$refs.dateInput.$refs.inputEl.click();
    },

    onClosed(val) {
      if (!_.isEmpty(val.value)) {
        let value = _.cloneDeep(this.value);
        const date = {
          date: toDateFirebase(val.value),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        };
        value.push(date);

        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// .add-button {
//   width: 100%;
//   vertical-align: middle;
//   border-radius: 50%;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   background: var(--f7-page-bg-color);
// }
</style>
