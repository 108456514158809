export default {
  teamList: state => state.teamList,
  team: state => state.team,

  // Fulltext search
  order: state => state.order,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  searchText: state => state.searchText,
  queryFilters: state => state.queryFilters
};
