<template>
  <f7-sheet
    close-by-outside-click
    class="demo-sheet"
    :opened="sheetOpened"
    @sheet:closed="
      sheetOpened = false;
      sheetData = {};
    "
  >
    <f7-toolbar>
      <div class="left"></div>
      <div class="right">
        <f7-link sheet-close>Close</f7-link>
      </div>
    </f7-toolbar>
    <!-- Scrollable sheet content -->
    <f7-page-content>
      <f7-list>
        <f7-list-item title="SKU" :after="sheetData.sku"></f7-list-item>
        <f7-list-item
          title="Product Item"
          :after="sheetData.productItem"
        ></f7-list-item>
        <f7-list-item
          title="Manufacturer"
          :after="sheetData.manufacturer"
        ></f7-list-item>
        <f7-list-item title="Qty" :after="sheetData.actualQty"></f7-list-item>
        <f7-list-item
          title="Order Qty"
          :after="sheetData.orderQty"
        ></f7-list-item>
        <f7-list-item
          title="Packaging"
          :after="sheetData.packaging"
        ></f7-list-item>
        <f7-list-item
          title="Unit Size"
          :after="sheetData.unitSize"
        ></f7-list-item>
        <f7-list-item title="UoM" :after="sheetData.uom"></f7-list-item>

        <f7-list-item
          title="Price"
          :after="sheetData.price | currencyUSD"
        ></f7-list-item>

        <f7-list-item
          title="Sub-Total"
          :after="
            ((sheetData.price || 0) * (sheetData.orderQty || 0)) | currencyUSD
          "
        ></f7-list-item>
      </f7-list>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      sheetOpened: false,
      sheetData: {}
    };
  },

  methods: {
    openSheet(data) {
      this.sheetOpened = true;
      this.sheetData = _.cloneDeep(data);
    }
  }
};
</script>

<style></style>
