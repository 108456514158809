import * as types from "./types";
import userService from "../../../../services/user.service";

export default {
  getUserList({ commit }) {
    return userService.getUserList().then(users => {
      commit(types.SET_USERS_LIST, users);
      return;
    });
  },
  setUserId({ commit }, id) {
    commit(types.SET_USER_ID, id);
  }
};
