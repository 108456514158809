import * as types from "./types";
import userProfileService from "../../service/profile.service";
import { StorageActions } from "../../../../services/firebase.service";

export default {
  setRoutes({ commit }, routes) {
    commit(types.SET_ROUTES, routes);
  },

  getUserProfile({ commit }) {
    return userProfileService.getUserProfile().then(userInfo => {
      return commit(types.SET_USER, userInfo);
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateUserProfile({ commit }, userInfo) {
    return userProfileService.updateUserProfile(userInfo).then(() => {
      return commit(types.SET_USER, userInfo);
    });
  },

  // eslint-disable-next-line no-unused-vars
  updateAvatarRef({ commit }, fullPath) {
    return userProfileService.updateAvatarRef(fullPath).then(() => {
      return commit(types.SET_USER_CUSTOM_CLAIMS, { avatarRef: fullPath });
    });
  },

  // eslint-disable-next-line no-unused-vars
  async uploadAvatar({ commit }, fileData) {
    const base64Data = fileData.dataUrl;
    const file = fileData.info;
    const name = +new Date() + file.name || "";
    const fullPath = `users/avatars/${name}`;
    return StorageActions.uploadFileBase64(fullPath, base64Data, file);
  },

  // eslint-disable-next-line no-unused-vars
  async removeAvatar({ commit }, fullPath) {
    return StorageActions.removeFile(fullPath);
  },

  // eslint-disable-next-line no-unused-vars
  changePassword({ commit }, password) {
    return userProfileService.changePassword(password);
  },

  setSettingsThemeDark({ commit }, dark) {
    commit(types.SET_SETTINGS_THEME_DARK, dark);
  },

  calculateListParentMenuItems({ commit, getters }) {
    const result = (getters.routes || []).filter(route => {
      return (
        getters.checkRoleGuard(route.displayRoles) ||
        getters.checkGroupGuard(route, 0) // parent
      );
    });

    commit("SET_LIST_PARENT_MENU_ITEMS", result);
  },

  setCurrentGroups({ commit }, payload) {
    commit("SET_CURRENT_GROUPS", payload);
  }
};
