export default {
  // eslint-disable-next-line no-unused-vars
  recurringInvoiceList: (state, _, __, _rootGetters) => {
    return state.recurringInvoiceList;
  },
  recurringInvoiceById: ({ recurringInvoiceList }) => id => {
    return recurringInvoiceList.find(r => r.id === id);
  },
  recurringInvoice: state => {
    return state.recurringInvoice;
  },

  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  selectedStatusFilter: state => state.selectedStatusFilter,

  recurringInvoiceListByInvoiceGroup: state =>
    state.recurringInvoiceListByInvoiceGroup
};
