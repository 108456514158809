import * as types from "./types";
import stackctServices from "@/services/stackct.service";

// eslint-disable-next-line no-unused-vars
export default tenant => ({
  async setStackctProjects({ commit }) {
    const res = await stackctServices.getProjects();
    const stackctProjects = res.data.collection.items;
    let propertyList = ["ProjectId", "CompanyId", "Name", "TakeoffIds"];
    commit(
      types.SET_STACKCT_PROJECTS,
      mappingItems(stackctProjects, propertyList)
    );
  },
  async setStackctPages({ commit }, takeoffId) {
    const res = await stackctServices.getPages(takeoffId);
    const pages = res.data.collection.items;
    let propertyList = ["PageId", "PageName", "ProjectId"];
    commit(types.SET_STACKCT_PAGES, mappingItems(pages, propertyList));
  },
  async setStackctTakeoffs({ commit }, takeoffId) {
    const res = await stackctServices.getTakeoffs(takeoffId);
    const stackctTakeoffs = res.data.collection.items;
    let propertyList = [
      "TakeoffTemplateId",
      "Name",
      "DisplayUnit",
      "TotalMeasuredQty",
      "TotalScaledArea",
      "MeasurementName",
      "TotalCount",
      "TotalScaledLength",
      "Geometries"
    ];
    commit(
      types.SET_STACKCT_TAKEOFFS,
      mappingItems(stackctTakeoffs, propertyList)
    );
  },
  async setStackPlanByProject({ commit }, projectId) {
    const res = await stackctServices.getPlans(projectId);
    const plans = res.success && (res.data || []);
    commit(types.SET_STACKCT_PLANS, plans);
  }
});

const mappingItems = (items, propertyList) => {
  return items.map(r => {
    return r.data.reduce((obj, prop) => {
      if (propertyList.includes(prop.name)) {
        obj[prop.name] = prop.value || prop.object || prop.array || "";
      }
      return obj;
    }, {});
  });
};
