<template>
  <div class="contact-email-tab">
    <f7-block-title class="status-container no-margin-vertical" v-if="isLoggedInGmail">
      <f7-link class="link-email" popover-open=".popover-menu">
        {{ this.gmailProfile.email }}
        <user-avatar class="margin-left-half" :avatarSize="30" :imageUrl="gmailProfile.avatar" />
        <!-- <f7-icon f7="chevron_down" size="18"></f7-icon> -->
      </f7-link>
      <f7-popover class="popover-menu">
        <f7-card>
          <f7-card-content class="display-flex flex-direction-column align-items-center">
            <img :src="gmailProfile.avatar" alt="Avatar" class="avatar margin-bottom" />
            <div class="header-text">
              {{ gmailProfile.fullName }}
            </div>
            <div>{{ gmailProfile.email }}</div>
          </f7-card-content>
          <f7-card-footer class="display-flex justify-content-center">
            <f7-button v-if="isLoggedInGmail" class="display-flex align-items-center" raised color="black"
              @click="logoutGmail" popover-close>
              <f7-icon f7="power" class="margin-right-half"></f7-icon>
              Logout
            </f7-button>
          </f7-card-footer>
        </f7-card>
      </f7-popover>
    </f7-block-title>
    <f7-block-title class="button-container">
      <f7-button v-show="listThreadDetailMessage.length > 0" fill color="blue" class="btn-add"
        @click="listThreadDetailMessage = []">
        <f7-icon f7="return" size="18"></f7-icon> Back
      </f7-button>

      <f7-button v-show="this.selectedMessageIds.length > 0 &&
        !listThreadDetailMessage.length > 0
        " fill color="red" class="btn-add" @click="openRemoveSelectedMessage()">
        <f7-icon f7="trash" size="18"></f7-icon> Remove
      </f7-button>

      <f7-button v-if="isLoggedInGmail && !listThreadDetailMessage.length > 0" fill color="red" class="btn-add"
        @click="openEmailComposer()">
        <f7-icon f7="envelope_open" size="18"></f7-icon> Compose
      </f7-button>

      <f7-button v-if="isLoggedInGmail && listThreadDetailMessage.length > 0" fill color="red" class="btn-add"
        @click="openEmailComposer(true)">
        <f7-icon f7="arrowshape_turn_up_left" size="18"></f7-icon> Reply
      </f7-button>
      <f7-button v-if="!isLoggedInGmail" fill color="blue" class="btn-add" @click="loginGmail">
        <f7-icon f7="logo_google" size="18"></f7-icon> Login Gmail
      </f7-button>

      <f7-button v-if="isLoggedInGmail && !listThreadDetailMessage.length > 0" fill color="blue" class="btn-add"
        @click="syncEmail(true)">
        <f7-icon f7="arrow_2_circlepath" size="18"></f7-icon> Sync Emails
      </f7-button>
      <f7-button v-if="isLoggedInGmail && !listThreadDetailMessage.length > 0" fill color="blue" class="btn-add"
        @click="openAddThread()">
        <f7-icon f7="plus" size="18"></f7-icon> Add Threads
      </f7-button>
    </f7-block-title>
    <!-- email list -->
    <data-table v-show="!listThreadDetailMessage.length > 0" :pageSize="messageListGroupByThread.length"
      :headers="headersMessage" :checkbox="true" :items="messageListGroupByThread" @selected:change="selectMessage"
      data-table-collapsible class="wrap-text-table">
      <template v-slot:body="{ item }">
        <td :data-collapsible-title="headers[0].text">
          <div @click.stop="getMailByThread(item.threadId)"
            class="flex-wrap-mobile display-flex justify-content-space-between cursor-pointer">
            <div class="nowrap-text">
              <a @click.stop="getMailByThread(item.threadId)" style="color: black" :class="{ bold: item.isUnread }">
                {{ item.from }} {{ item.total > 1 ? `(${item.total})` : "" }}</a>
            </div>
            <f7-chip class="email-new-status" v-show="item.isUnread" text="New" color="orange"></f7-chip>
          </div>
        </td>
        <td :data-collapsible-title="headers[1].text">
          {{ item.subject }}
        </td>
        <td :data-collapsible-title="headers[2].text">
          {{ item.date | MMDDYYYYHHMMSS }}
        </td>
        <td data-collapsible-title="" class="action-column-mobile">
          <f7-row class="button-action-edit">
            <f7-button @click.stop="openRemoveMessageByThread(item.threadId)" fill small color="red">
              Remove
            </f7-button>
          </f7-row>
        </td>
      </template>
    </data-table>
    <!-- email detail thread -->
    <div v-show="sortedListThreadDetailMessage.length > 0">
      <div v-for="(item, index) in sortedListThreadDetailMessage" :key="index">
        <f7-block-title>{{ item.subject }}</f7-block-title>
        <f7-block strong inset>
          <f7-block-title>
            <div>
              From: <strong>{{ item.from }}</strong>
            </div>
            <div class="email-address-item">To: {{ item.to }}</div>
            <div class="email-address-item" v-if="item.cc">
              Cc: {{ item.cc }}
            </div>
            <div class="email-address-item" v-if="item.bcc">
              Bcc: {{ item.bcc }}
            </div>
          </f7-block-title>
          <div class="message-content">
            <div @click="handleHtmlContentClick" v-html="item.body || item.snippet"></div>
          </div>
          <f7-block-title v-if="item.attachments.length > 0">
            <div>Attachment files</div>
            <a style="text-decoration: underline; font-size: 12px; margin-right: 0.5rem"
              v-for="(attachment, index) in item.attachments" :key="index" @click="getAttachment(item.id, attachment)">
              {{ attachment.filename }}
            </a>
          </f7-block-title>
        </f7-block>
        <f7-block-footer class="text-align-right">{{
          item.date | MMDDYYYYHHMMSS
        }}</f7-block-footer>
      </div>
    </div>

    <!-- popup compose email -->
    <f7-popup :opened="openComposer" @popup:closed="openComposer = false">
      <f7-page class="email-composer">
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="closeEmailComposer">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>{{
            isReply ? "Reply email" : "Compose new email"
          }}</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="sendMessage">Send</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list media-list style="z-index: 102; width: calc(100% - 6px);">
          <!-- to -->
          <email-auto-complete-input :options="emailList" :value="composer.to" @change="value => {
            composer.to = value;
            v$.$touch();
          }
            " title="To" placeholder="Enter email">
            <f7-icon slot="icon" f7="envelope"></f7-icon>
            <span slot="error-message">{{ toEmailErrorMessage }}</span>
          </email-auto-complete-input>
          <!-- cc -->
          <email-auto-complete-input :options="emailList" :value="composer.cc" @change="value => {
            composer.cc = value;
            v$.$touch();
          }
            " title="Cc" placeholder="Enter email">
            <f7-icon slot="icon" f7="square_on_square"></f7-icon>
            <span slot="error-message">{{ ccEmailErrorMessage }}</span>
          </email-auto-complete-input>
          <!-- bcc -->
          <email-auto-complete-input :options="emailList" :value="composer.bcc" @change="value => {
            composer.bcc = value;
            v$.$touch();
          }
            " title="Bcc" placeholder="Enter email">
            <f7-icon slot="icon" f7="square_list"></f7-icon>
            <span slot="error-message">{{ bccEmailErrorMessage }}</span>
          </email-auto-complete-input>
          <!-- subject -->
          <div class="email-input subject">
            <div class="email-input-icon">
              <f7-icon f7="square_favorites_alt"></f7-icon>
            </div>
            <div class="email-input-content">
              <div class="email-input-lable">
                Subject
              </div>
              <div class="email-input-wrap">
                <input type="textarea" placeholder="Enter subject" class="input-with-value" @keydown.tab="handleTabKey"
                  @blur="v$.$touch()" @input="composer.subject = $event.target.value" :value="composer.subject" />
                <span class="input-clear-button"></span>
              </div>
              <div class="email-error-message">{{ subjectErrorMessage }}</div>
            </div>
            <!-- <div class="email-input-icon">
                 <span class="input-clear-button"></span>
            </div> -->
          </div>
        </f7-list>
        <!-- Text area -->
        <f7-block-header>Message</f7-block-header>
        <f7-text-editor ref="emailContent" :value="composer.message" placeholder="Enter content"
          @texteditor:change="composer.message = $event"></f7-text-editor>

        <f7-block-title>Select attachments</f7-block-title>
        <f7-list accordion-list inset>
          <f7-list-item v-for="(project, index) in projects" :key="index" accordion-item
            :title="`${project.cardNumber} - ${project.title}`">
            <f7-accordion-content>
              <f7-list>
                <f7-list-item v-for="(file, index) in project.attachmentFiles" :key="`${project.cardNumber}-${index}`"
                  checkbox :title="file.fileName" :value="[project.id, file.fileName]"
                  @change="checkFile"></f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>

        <f7-block-title>Or upload attachments</f7-block-title>
        <f7-block strong inset>
          <input ref="fileInput" class="file-input" type="file" multiple @change="onFileChange" />
        </f7-block>
      </f7-page>
    </f7-popup>

    <!-- add email thread to follow -->
    <f7-popup :opened="isOpenAddThread" @popup:closed="isOpenAddThread = false">
      <f7-page class="email-composer">
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="closeAddThread()">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Add gmail thread to your mailbox</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="doneAddThread()">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list>
          <f7-text-editor :value="query" @texteditor:change="onSearchEmail" placeholder="Search your email"
            mode="popover" resizable />
        </f7-list>
        <data-table :pageSize="listSearchingMessageGroupByThread.length" :headers="headers" :checkbox="true"
          :items="listSearchingMessageGroupByThread" @selected:change="selectThread" data-table-collapsible
          class="wrap-text-table">
          <template v-slot:body="{ item }">
            <td :data-collapsible-title="headers[0].text">
              <a @click="() => { }">
                {{ item.from }} {{ item.total > 1 ? `(${item.total})` : "" }}</a>
            </td>
            <td :data-collapsible-title="headers[1].text">
              {{ item.subject }}
            </td>
            <td :data-collapsible-title="headers[2].text">
              {{ item.date | MMDDYYYYHHMMSS }}
            </td>
          </template>
        </data-table>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import _ from "lodash";
import { useVuelidate } from '@vuelidate/core'
import { required, email } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/datatables";

import EmailAutoCompleteInput from "../../../components/inputs/EmailAutoCompleteInput";

import { saveAs } from "file-saver";
import { toBase64, dataURItoBlob } from "../../../../../services/utils";
import {
  urltoFile,
  getHeader,
  getBody
} from "../../../../../utility/gmail-tool";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { DataTable, UserAvatar, EmailAutoCompleteInput },
  data: () => {
    return {
      listThreadDetailMessage: [],
      listSearchingMessage: [],
      selectedThreadIds: [],
      selectedMessageIds: [],
      query: "",
      isLoggedInGmail: false,
      openComposer: false,
      isReply: false,
      isOpenAddThread: false,
      uploadFiles: [],
      composer: {
        attachmentFiles: [],
        message: "",
        subject: "",
        from: "",
        to: "",
        cc: "",
        bcc: "",
        threadId: false
      },
      gmailProfile: {
        email: "",
        avatar: "",
        fullName: ""
      },
      projects: []
    };
  },
  computed: {
    ...mapGetters("contact-book/contact-message", ["messageList"]),
    ...mapGetters("contact-book/contact", ["contact"]),
    ...mapGetters("common/common", ["emailList"]),

    headers() {
      return [
        {
          text: "From",
          align: "left",
          value: "From",
          width: "30%"
        },
        {
          text: "Subject",
          align: "left",
          value: "addresses",
          width: "40%"
        },
        {
          text: "Date",
          align: "left",
          value: "group",
          width: "25%"
        }
      ];
    },
    headersMessage() {
      return [
        {
          text: "From",
          align: "left",
          value: "From",
          width: "30%"
        },
        {
          text: "Subject",
          align: "left",
          value: "addresses",
          width: "35%"
        },
        {
          text: "Date",
          align: "left",
          value: "group",
          width: "20%"
        },
        {
          text: "Action",
          align: "left",
          value: "toDate",
          width: "10%"
        }
      ];
    },
    sortedListThreadDetailMessage() {
      return _.cloneDeep(this.listThreadDetailMessage).sort(
        (a, b) => b.internalDate - a.internalDate
      );
    },
    listSearchingMessageGroupByThread() {
      var helper = {};
      var result = this.listSearchingMessage.reduce(function (r, o) {
        var key = o.id;
        if (!helper[key]) {
          let newObject = {
            id: o.id,
            from: o.from,
            subject: o.subject,
            date: o.date,
            total: 1,
            internalDate: o.internalDate
          };
          helper[key] = Object.assign({}, newObject);
          r.push(helper[key]);
        } else {
          helper[key].total += 1;
          helper[key].date =
            o.internalDate > helper[key].internalDate
              ? o.date
              : helper[key].date;
          helper[key].internalDate =
            o.internalDate > helper[key].internalDate
              ? o.internalDate
              : helper[key].internalDate;
        }
        return r;
      }, []);
      return result.sort((a, b) => b.internalDate - a.internalDate);
    },
    messageListGroupByThread() {
      var helper = {};
      var result = this.messageList.reduce(function (r, o) {
        var key = o.threadId;
        if (!helper[key]) {
          let newObject = {
            id: o.threadId,
            threadId: o.threadId,
            labelIds: o.labelIds,
            isUnread: o.labelIds.includes("UNREAD"),
            from: o.from,
            subject: o.subject,
            date: o.date,
            total: 1,
            internalDate: o.internalDate
          };
          helper[key] = Object.assign({}, newObject);
          r.push(helper[key]);
        } else {
          helper[key].total += 1;
          helper[key].date =
            o.internalDate > helper[key].internalDate
              ? o.date
              : helper[key].date;
          helper[key].internalDate =
            o.internalDate > helper[key].internalDate
              ? o.internalDate
              : helper[key].internalDate;
          helper[key].isUnread =
            helper[key].isUnread || o.labelIds.includes("UNREAD");
        }
        return r;
      }, []);
      return result.sort((a, b) => b.internalDate - a.internalDate);
    },
    toEmailErrorMessage() {
      if (!this.v$.composer.to.$error) return null;
      if (this.v$.composer.to.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.composer.to.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
    ccEmailErrorMessage() {
      if (!this.v$.composer.cc.$error) return null;
      if (this.v$.composer.cc.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },
    bccEmailErrorMessage() {
      if (!this.v$.composer.bcc.$error) return null;
      if (this.v$.composer.bcc.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    subjectErrorMessage() {
      if (!this.v$.composer.subject.$error) return null;
      if (this.v$.composer.subject.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },
  methods: {
    ...mapActions("contact-book/contact-message", [
      "getMessageListBys",
      "createMessage",
      "updateMessage",
      "removeMessageContactRefList",
      "addMessageContactRef"
    ]),

    ...mapActions("contact-book/contact", ["updateContact", "getContactById"]),

    ...mapActions("contact-book/project", [
      "getProjectsByContactId",
      "getProjectsByCompanyIds",
      "getProjectsByPropertyIds"
    ]),
    ...mapActions("common/common", ["getEmailList"]),

    initData() {
      if (_.isEmpty(this.contact)) return;
      this.isLoggedInGmail = this.$gapi.gmail.isLoggedInGmail();
      Promise.all([
        this.getCurrentEmailProfile(),
        this.getMessageListBys([
          {
            prop: "itemId",
            val: this.contact.id || this.$f7route.params.contactId,
            op: "=="
          }
        ]),
        this.syncEmail(),
        this.getRelatedProjects(this.contact),
        this.getEmailList()
      ]);
    },

    handleTabKey() {
      setTimeout(() => {
        this.$refs.emailContent.$el.lastElementChild.focus();
      }, 100);
    },

    handleHtmlContentClick() {
      let { target } = event;
      //find a tag
      while (target && target.nodeName !== "A") target = target.parentNode;
      if (target && target.nodeName == "A" && target.href) {
        const url = new URL(target.href);
        if (window.location.pathname !== url && event.preventDefault) {
          event.preventDefault();
          window.history.back();
          window.open(url, "_blank");
        }
      }
    },

    getCurrentEmailProfile() {
      this.gmailProfile.email = localStorage.getItem("gapi.email");
      this.gmailProfile.avatar = localStorage.getItem("gapi.image_url");
      this.gmailProfile.fullName = localStorage.getItem("gapi.full_name");
    },

    async onFileChange() {
      const uploadFiles = this.$refs.fileInput.files;
      for (const file of uploadFiles) {
        let attachment = {
          name: file.name,
          type: file.type,
          data: await toBase64(file)
        };
        this.uploadFiles.push(attachment);
      }
    },

    openEmailComposer(isReply = false) {
      this.isReply = isReply;
      if (isReply) {
        let latestEmail =
          this.sortedListThreadDetailMessage &&
          this.sortedListThreadDetailMessage.length > 0 &&
          this.sortedListThreadDetailMessage[0];
        this.composer.to =
          latestEmail && latestEmail.from != this.gmailProfile.email
            ? latestEmail.from
            : latestEmail.to;
        this.composer.cc = latestEmail && latestEmail.cc;
        this.composer.bcc = latestEmail && latestEmail.bcc;
        this.composer.subject = latestEmail ? latestEmail.subject : "";
        this.composer.threadId = latestEmail.threadId;
      } else {
        if (this.contact.emails && this.contact.emails.length > 0) {
          let email = this.contact.emails.find(r => r.code == "main");
          this.composer.to = email ? email.value : this.contact.emails[0].value;
        }
      }

      this.openComposer = true;
    },

    clearOutComposerData() {
      this.composer = {
        attachmentFiles: [],
        message: "",
        subject: "",
        from: "",
        to: "",
        threadId: false
      };
      this.uploadFiles = [];
      this.$refs.fileInput.value = "";
    },

    closeEmailComposer() {
      this.openComposer = false;
    },

    openAddThread() {
      this.isOpenAddThread = true;
      this.onSearchEmail("");
    },

    closeAddThread() {
      this.isOpenAddThread = false;
    },

    async onSearchEmail(val) {
      this.listSearchingMessage = [];
      let result = await this.$gapi.gmail.getListMessages(val, 15);
      if (result.status == 200 && result.data && result.data.messages) {
        result.data.messages.forEach(async element => {
          let res = await this.$gapi.gmail.getDetailMessage(element.id);
          let message = res.data;
          let item = {
            id: element.threadId,
            from: getHeader(message.payload.headers, "From"),
            to: getHeader(message.payload.headers, "To"),
            subject: getHeader(message.payload.headers, "Subject"),
            date: getHeader(message.payload.headers, "Date"),
            snippet: message.snippet,
            historyId: message.historyId
          };
          this.listSearchingMessage.push(item);
        });
      }
    },

    selectThread(value) {
      if (value.checked && !this.selectedThreadIds.includes(value.id)) {
        this.selectedThreadIds.push(value.id);
      } else if (value.checked == false) {
        this.selectedThreadIds = this.selectedThreadIds.filter(
          id => id != value.id
        );
      }
    },

    async doneAddThread() {
      this.$f7.preloader.show();
      let contact = _.cloneDeep(this.contact);
      let threadIds = contact.emailThreadIds || [];
      this.selectedThreadIds.forEach(threadId => {
        if (!(threadIds || []).includes(threadId)) {
          threadIds.push(threadId);
        }
      });
      contact.emailThreadIds = threadIds;
      await this.updateContact({
        id: this.contact.id,
        doc: contact
      });

      await this.getContactById(this.contact.id);
      await this.syncEmail();
      await this.getMessageListBys([
        {
          prop: "itemId",
          val: this.contact.id || this.$f7route.params.contactId,
          op: "=="
        }
      ]);

      this.selectedThreadIds = [];
      this.closeAddThread();
      this.$f7.preloader.hide();
    },

    selectAttachmentFile(item, event) {
      const isAddList = event.target.checked;
      const fileName = event.target.value;

      if (isAddList) {
        let file = _.cloneDeep(item);
        file.attachmentType = "email-attachment";
        this.composer.attachmentFiles.push(file);
      } else {
        const index = this.composer.attachmentFiles.findIndex(
          item => item.fileName === fileName
        );

        if (index > -1) {
          this.composer.attachmentFiles.splice(index, 1);
        }
      }
    },

    loginGmail() {
      this.$gapi
        .login(this.contact.id)
        .then(() => {
          this.isLoggedInGmail = true;
          this.getCurrentEmailProfile();
        })
        .catch(error => {
          if (error.error === "popup_blocked_by_browser") {
            this.$ri.dialog.openErrorDialog({
              title: "Gmail Login Error",
              content:
                'Your browser was blocked pop-ups from our site, please turn it to "Always allow all pop-ups and redirects" and try again.',
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              }
            });
          }
        });
    },

    openRemoveMessageByThread(id) {
      const app = this;
      app.$ri.dialog.openErrorDialog({
        title: "Remove email thread",
        content: "Are you sure you want to remove this email thread?",
        textButton: "Remove",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app
              .removeMessageContactRefList({
                contact: app.contact,
                threadIds: [id]
              })
              .then(() => {
                return app.getMessageListBys([
                  {
                    prop: "itemId",
                    val: app.contact.id || app.$f7route.params.contactId,
                    op: "=="
                  }
                ]);
              })
              .finally(() => {
                _sefl.app.dialog.close();
              });
          }
        }
      });
    },

    openRemoveSelectedMessage() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: `Remove ${this.selectedMessageIds.length > 1
          ? " email threads"
          : " email thread"
          }`,
        content: `Are you sure you want to remove ${this.selectedMessageIds.length
          }  ${this.selectedMessageIds.length > 1
            ? " email threads"
            : " email thread"
          }?`,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app
              .removeMessageContactRefList({
                contact: app.contact,
                threadIds: app.selectedMessageIds
              })

              .then(() => {
                return app.getMessageListBys([
                  {
                    prop: "itemId",
                    val: app.contact.id || app.$f7route.params.contactId,
                    op: "=="
                  }
                ]);
              })
              .finally(() => {
                (app.selectedMessageIds = []), _sefl.app.dialog.close();
              });
          }
        }
      });
    },

    async syncEmail(showPreload = false) {
      if (!this.isLoggedInGmail) return;
      var app = this;
      if (showPreload) this.$f7.preloader.show();
      for (let id of this.contact.emailThreadIds || []) {
        try {
          let result = await this.$gapi.gmail.getMessagesByThread(id);
          if (
            result &&
            result.status == 200 &&
            result.data &&
            result.data.messages
          ) {
            for (const element of result.data.messages) {
              let item = await this.getMailFromServerById(element.id);
              if (
                item &&
                !app.messageList.some(
                  r => r.emailId === item.emailId && r.itemId === item.itemId
                )
              ) {
                await this.createMessage(item);
              }
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn("Not found thread email on current login email");
        }
      }
      if (showPreload) this.$f7.preloader.hide();
    },

    async getAttachment(messageId, attachment) {
      this.$f7.preloader.show();
      let result = await this.$gapi.gmail.getAttachment(
        messageId,
        attachment.id
      );
      this.$f7.preloader.hide();
      let data = result.data.data.replace(/-/g, "+").replace(/_/g, "/");
      data = `data:${attachment.type};base64,` + data;
      urltoFile(data, attachment.filename, attachment.type).then(function (
        file
      ) {
        saveAs(
          new Blob([file], { type: attachment.type }),
          attachment.filename
        );
      });
    },

    async getMailByThread(id) {
      this.listThreadDetailMessage = [];
      let messages = this.messageList.filter(r => r.threadId == id);
      this.listThreadDetailMessage = messages;
      // remove unread label from message
      messages.forEach(element => {
        if (element.labelIds.includes("UNREAD")) {
          let lableIds = _.remove(
            _.cloneDeep(element.labelIds),
            item => item != "UNREAD"
          );
          this.updateMessage({
            id: element.id,
            doc: {
              labelIds: lableIds
            }
          });
        }
      });
    },

    selectMessage(value) {
      if (value.checked && !this.selectedMessageIds.includes(value.id)) {
        this.selectedMessageIds.push(value.id);
      } else if (value.checked == false) {
        this.selectedMessageIds = this.selectedMessageIds.filter(
          id => id != value.id
        );
      }
    },

    getAttachmentsList(arr) {
      var attachments = [];
      for (var x = 0; x < arr.length; x++) {
        if (arr[x].filename != "") {
          let item = {
            id: arr[x].body.attachmentId,
            filename: arr[x].filename,
            type: arr[x].mimeType
          };
          attachments.push(item);
        }
      }
      return attachments;
    },

    async getMailFromServerById(id) {
      let res = await this.$gapi.gmail.getDetailMessage(id);
      let message = res.data;
      return {
        emailId: message.id,
        itemId: this.contact.id,
        threadId: message.threadId,
        labelIds: message.labelIds,
        internalDate: message.internalDate,
        from: getHeader(message.payload.headers, "From"),
        to: getHeader(message.payload.headers, "To"),
        cc: getHeader(message.payload.headers, "Cc"),
        bcc: getHeader(message.payload.headers, "Bcc"),
        subject: getHeader(message.payload.headers, "Subject"),
        date: getHeader(message.payload.headers, "Date"),
        snippet: message.snippet,
        historyId: message.historyId,
        body: getBody(message.payload),
        attachments: this.getAttachmentsList(message.payload.parts || [])
      };
    },

    async sendMessage() {
      const self = this;
      this.v$.$touch();
      if (this.v$.$invalid) return;

      let threadId = null;
      this.$f7.preloader.show();
      if (this.isReply) {
        try {
          await this.$gapi.gmail.getMessagesByThread(this.composer.threadId);
          threadId = this.composer.threadId;
        } catch (e) {
          threadId = null;
        }
      }
      this.$gapi.gmail.sendMessage(
        {
          From: this.composer.from,
          To: this.composer.to,
          Cc: this.composer.cc,
          Bcc: this.composer.bcc,
          Subject: this.composer.subject,
          ThreadId: threadId
        },
        this.composer.message,
        this.uploadFiles || [],
        async email => {
          let message = await self.getMailFromServerById(email.data.id);
          await self.createMessage(message);

          if (threadId) {
            await self.getMailByThread(threadId);
          }

          await self.getMessageListBys([
            {
              prop: "itemId",
              val: this.contact.id || this.$f7route.params.contactId,
              op: "=="
            }
          ]);

          self.$f7.preloader.hide();
          self.clearOutComposerData();
          self.closeEmailComposer();
        }
      );
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },

    logoutGmail() {
      this.$gapi.logout().then(() => {
        this.isLoggedInGmail = false;
      });
    },

    async getRelatedProjects(contact) {
      const getProjectProps = project => {
        return {
          id: project.id,
          attachmentFiles: project.attachmentFiles || [],
          businessCode: project.businessCode,
          cardNumber: project.cardNumber,
          title: project.title
        };
      };
      const projectList = [];
      const projectsByContact = await this.getProjectsByContactId(contact.id);

      projectList.push(
        ...projectsByContact
          .filter(
            item => item.attachmentFiles && item.attachmentFiles.length > 0
          )
          .map(getProjectProps)
      );

      if (contact.companyRefs && contact.companyRefs.length > 0) {
        const projectsByCompany = await this.getProjectsByCompanyIds(
          contact.companyRefs.map(item => item.id)
        );
        projectList.push(
          ...projectsByCompany
            .filter(
              item => item.attachmentFiles && item.attachmentFiles.length > 0
            )
            .map(getProjectProps)
        );
      }

      if (contact.propertyRefs && contact.propertyRefs.length > 0) {
        const projectsByProperty = await this.getProjectsByPropertyIds(
          contact.propertyRefs.map(item => item.id)
        );
        projectList.push(
          ...projectsByProperty
            .filter(
              item => item.attachmentFiles && item.attachmentFiles.length > 0
            )
            .map(getProjectProps)
        );
      }

      this.projects = _.uniqBy(projectList, "id");
    },

    async checkFile(event) {
      const project = this.projects.find(
        item => item.id === event.target.value.split(",")[0]
      );

      if (project) {
        const file = project.attachmentFiles.find(
          item => item.fileName === event.target.value.split(",")[1]
        );

        if (file && event.target.checked) {
          const blob = await dataURItoBlob(file.url);

          let attachment = {
            name: file.fileName,
            type: blob.type,
            data: await toBase64(blob)
          };

          this.uploadFiles.push(attachment);
        } else if (file && !event.target.checked) {
          // remove
          const index = this.uploadFiles.findIndex(
            item => item.name === file.fileName
          );
          if (index >= 0) {
            this.uploadFiles.splice(index, 1);
          }
        }
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      composer: {
        subject: {
          required
        },
        to: {
          required,
          commonEmailRule: email
        },
        cc: {
          commonEmailRule: email
        },
        bcc: {
          commonEmailRule: email
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.save-comment-block {
  margin-top: -10px;
  margin-right: 1px;
  display: flex;
  justify-content: space-between;
}

.btn-save {
  width: 18%;
  min-width: 120px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
}

.status-container {
  display: flex;
  justify-content: flex-end;
}

.btn-add {
  width: 10%;
  min-width: 120px;
  margin: 0 0 0 1rem;
  justify-content: space-around;
  display: flex;
}

.btn-delete {
  width: 10%;
  min-width: 120px;
  margin: 0 0 0 1rem;
  justify-content: space-around;
  display: flex;
}

.email-composer .icon {
  color: var(--f7-theme-color);
  margin-top: var(--swimlane-icon-margin-top);
  margin-left: var(--swimlane-icon-margin-left);
  font-size: var(--swimlane-icon-font-size);
  margin-bottom: var(--swimlane-icon-margin-bottom);
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #ddd;
}

.link-email {
  color: var(--f7-color-text-neutral);
  text-decoration: underline;
}

.popover-menu {
  width: fit-content;
}

.header-text {
  font-size: 16px;
  font-weight: 600;
}

.gmail-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gmail-profile * {
  margin: 0.3rem 0rem;
}

.message-content {
  padding: 1rem 0.5rem;
  overflow: auto;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}

.file-input:focus {
  border: gray dotted 0.5px;
}

.email-address-item {
  font-size: 12px;
}

.input-with-value {
  background-color: var(--f7-color-bg-4-neutral);
}

.subject input {
  border: none;
  width: 100%;
  padding: 2px 0;
  color: var(--f7-input-text-color);
  //font-size: var(--f7-input-font-size);
  resize: none;
  line-height: 1.4;
}

.bold {
  font-weight: bold;
}

.nowrap-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.email-new-status {
  margin-left: 1rem;
}

@media (max-width: 480px) and (orientation: portrait) {
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .button-container a {
    flex: 0 20%;
    width: 30%;
    margin-bottom: 2%;
  }

  .nowrap-text {
    white-space: normal;
  }

  .email-new-status {
    margin-left: 0;
  }

  .flex-wrap-mobile {
    flex-wrap: wrap;
  }
}
</style>

<style>
.subject div.item-title {
  width: 100% !important;
}

@media (max-width: 480px) and (orientation: portrait) {
  .contact-email-tab tr td:first-child {
    padding-left: 0px !important;
  }

  .contact-email-tab tr td:first-child:before {
    width: 0 !important;
  }
}

.contact-email-tab .wrap-text-table div table {
  table-layout: fixed !important;
}
</style>
