import { PROJECT_INACTIVE_STATUS_LIST } from "@/utility/const";
import * as types from "./types";
import _ from "lodash";

export default {
  getEmailList({ dispatch, commit }) {
    const promises = [
      dispatch("common/contact/getContactEmails", null, { root: true }),
      dispatch("common/company/getCompanyEmails", null, { root: true }),
      dispatch("common/user/getUserEmails", null, { root: true })
    ];
    return Promise.all(promises).then(res => {
      const mergedEmails = _.uniq([].concat(...res));
      commit(types.SET_EMAIL_LIST, mergedEmails);
    });
  },

  getIsProjectArchived({ dispatch }, projectId) {
    return dispatch("common/project/getProjectById", projectId, {
      root: true
    }).then(project => PROJECT_INACTIVE_STATUS_LIST.includes(project.status));
  },

  resetEmailList({ commit }) {
    commit(types.SET_EMAIL_LIST, []);
  }
};
