<template>
  <div>
    <f7-list
      accordion-list
      :inset="$device.desktop"
      :class="{ 'no-margin': $device.desktop }"
      class="margin-bottom"
    >
      <f7-list-item
        accordion-item
        :accordion-item-opened="photoSelected === 'photo1'"
        @accordion:opened="$emit('setPhotoSelected', 'photo1')"
        @accordion:closed="$emit('setPhotoSelected', '')"
        title="Standing Seam"
      >
        <f7-accordion-content
          :style="
            `height: ${photoSelected === 'photo1' ? 'auto' : '0 !important'}`
          "
        >
          <f7-list inline-labels>
            <f7-list-input
              label="a1"
              type="text"
              placeholder="Enter value"
              :value="data.a1"
              @change="setField('a1', $event.target.value.trim())"
            ></f7-list-input>
            <f7-list-input
              label="b1"
              type="text"
              placeholder="Enter value"
              :value="data.b1"
              @change="setField('b1', $event.target.value.trim())"
            ></f7-list-input>
            <f7-list-input
              label="c1"
              type="text"
              placeholder="Enter value"
              :value="data.c1"
              @change="setField('c1', $event.target.value.trim())"
            ></f7-list-input>
            <f7-list-input
              label="d1"
              type="text"
              placeholder="Enter value"
              :value="data.d1"
              @change="setField('d1', $event.target.value.trim())"
            ></f7-list-input>
            <f7-list-input
              label="Angle"
              type="text"
              placeholder="Enter value"
              :value="data.angle"
              @change="setField('angle', $event.target.value.trim())"
            ></f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item
        accordion-item
        :accordion-item-opened="photoSelected === 'photo2'"
        @accordion:opened="$emit('setPhotoSelected', 'photo2')"
        @accordion:closed="$emit('setPhotoSelected', '')"
        title="Cleat"
      >
        <f7-accordion-content
          :style="
            `height: ${photoSelected === 'photo2' ? 'auto' : '0 !important'}`
          "
        >
          <f7-list inline-labels>
            <f7-list-input
              label="a2"
              type="text"
              placeholder="Enter value"
              :value="data.a2"
              @change="setField('a2', $event.target.value.trim())"
            ></f7-list-input>
            <f7-list-input
              label="b2"
              type="text"
              placeholder="Enter value"
              :value="data.b2"
              @change="setField('b2', $event.target.value.trim())"
            ></f7-list-input>
            <f7-list-input
              label="c2"
              type="text"
              placeholder="Enter value"
              :value="data.c2"
              @change="setField('c2', $event.target.value.trim())"
            ></f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { evaluate } from "mathjs";
import canvasMixin from "../../../utility/canvas-mixin";

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    photoSelected: String
  },

  methods: {
    setField(name, value) {
      this.$emit("setFields", {
        name,
        value,
        callback: (name, value) => {
          switch (name) {
            case "c1":
              this.$emit("setFields", {
                name: "b2",
                value: `${evaluate(this.convertStringToExpression(value)) -
                  evaluate(this.convertStringToExpression(this.data.d1))}"`
              });
              break;
            case "d1":
              this.$emit("setFields", {
                name: "c2",
                value,
                callback: (name, value) => {
                  this.$emit("setFields", {
                    name: "b2",
                    value: `${evaluate(
                      this.convertStringToExpression(this.data.c1)
                    ) - evaluate(this.convertStringToExpression(value))}"`
                  });
                }
              });
              break;
          }
        }
      });
    }
  }
};
</script>
