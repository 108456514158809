import { FirebaseActions } from "../../../../services/firebase.service";
import { PROP_IS_DELETED } from "../../../../utility/const";
import * as types from "./types";

export default tenant => {
  const proposalActions = new FirebaseActions(
    `/system_client/${tenant}/proposal`,
    "proposal"
  );
  return {
    getProposalListBys: proposalActions.getDocumentBys,
    updateProposal: proposalActions.updateDocument,

    getProposalsByProjectId({ dispatch, commit }, projectId) {
      if (!projectId) return;
      return dispatch("getProposalListBys", [
        {
          prop: "projectId",
          val: projectId,
          op: "=="
        },
        { prop: PROP_IS_DELETED, val: true, op: "!=" }
      ]).then(res => {
        commit(types.SET_PROPOSAL_LIST, res);
      });
    },
    deleteProposal({ dispatch }, id) {
      return dispatch("updateProposal", {
        id,
        doc: { [PROP_IS_DELETED]: true }
      });
    },
    resetProposal({ commit }) {
      commit(types.SET_PROPOSAL_LIST, []);
    }
  };
};
