<template>
  <response-button
    ref="responseButton"
    :responses="responses"
    v-on="$listeners"
  ></response-button>
</template>

<script>
import { mapGetters } from "vuex";
import ResponseButton from "../button/ResponseButton.vue";

export default {
  components: { ResponseButton },

  props: {
    actionCodes: { type: Array, default: () => [] }
  },

  computed: {
    ...mapGetters("dashboard/work-order", ["response"]),

    /**
     * Calculate the respone of preparation task
     */
    responses() {
      return this.response.filter(item => item.taskType === "qa-qc");
    }
  }
};
</script>

<style></style>
