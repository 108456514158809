export default {
  scheduleValue: {},

  order: "desc",
  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  searchText: ""
};
