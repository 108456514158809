import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const actionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_category`,
    "object"
  );

  return {
    getBys: actionsTemplate.getDocumentBys,

    async getCategories({ commit, dispatch }) {
      const list = await dispatch("getBys");
      commit(types.SET_CATEGORY_LIST, list);
    },

    resetCategory({ commit }) {
      commit(types.SET_CATEGORY_LIST, []);
    },

    bind: actionsTemplate.bindCollection,
    unbind: actionsTemplate.unbindCollection,
    create: actionsTemplate.createDocument,
    update: actionsTemplate.updateDocument,
    delete: actionsTemplate.deleteDocument,

    unbindCategory({ dispatch, commit }) {
      commit(types.SET_CATEGORY_LIST, []);
      dispatch("unbind");
    }
  };
};
