import { getFullAddress } from "@/utility/address";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  methods: {
    ...mapActions("photo/client", [
      "getContactDetailById",
      "getPropertyDetailById"
    ]),

    getContactName(contact) {
      return contact
        ? ((contact.firstName || "") + " " + (contact.lastName || "")).trim()
        : "";
    },

    getContactAddress(contact) {
      if (!_.isEmpty(contact)) {
        const addresses = contact.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },

    getPropertyName(property) {
      return property ? property.propertyName || "" : "";
    },
    getPropertyAddress(property) {
      if (!_.isEmpty(property)) {
        const addresses = property.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === "main");
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return "";
    },
    async getJobTitle(job) {
      let jobTitle = "";

      if (["residential", "multifamily"].includes(job.customerType)) {
        const contact = await this.getContactDetailById(job.contactId);
        const contactName = this.getContactName(contact);
        const address = getFullAddress(job.projectAddress);

        jobTitle = contactName ? `${contactName} - ${address}` : address;
      } else if (job.propertyId) {
        const property = await this.getPropertyDetailById(job.propertyId);

        if (property) {
          jobTitle = `${property.propertyName} - ${this.getPropertyAddress(
            property
          )}`;
        }
      }

      return jobTitle;
    }
  }
};
