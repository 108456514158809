<template>
  <f7-page>
    <add-new-proposal-popup
      ref="newProposalPopup"
      @toDetail="toDetail($event)"
    ></add-new-proposal-popup>

    <!-- <add-new-proposal-service-popup
      ref="newProposalServicePopup"
      :popupAddNewProposal="popupAddNewServiceProposal"
      @close="popupAddNewServiceProposal = false"
    ></add-new-proposal-service-popup> -->
  </f7-page>
</template>

<script>
import { mapActions } from "vuex";
import AddNewProposalPopup from "../components/popup/AddNewProposalPopup.vue";
export default {
  components: { AddNewProposalPopup },
  data() {
    return {
      popupAddNewServiceProposal: false,
      routeQuery: {}
    };
  },
  mounted() {
    this.routeQuery = this.$f7route.query;
    this.$refs.newProposalPopup.openPopup();
  },
  methods: {
    ...mapActions("proposal/proposal", ["setProposalType"]),
    toDetail({ proposalNumber, hasProject }) {
      this.setProposalType("proposal");

      const meta = this.$f7route.route.meta || {};

      if (meta.fromEstimate === true) {
        const {
          clientType,
          clientId,
          buildingName,
          estimateNumber
        } = this.routeQuery;
        this.$f7router.navigate(
          `/proposal/${proposalNumber}?clientType=${clientType}&clientId=${clientId}&buildingName=${buildingName ||
            ""}&estimateNumber=${estimateNumber}`,
          {
            pushState: true
          }
        );
      } else if (hasProject) {
        this.$f7router.navigate(
          `/dashboard/type/${this.$f7route.params.boardType}/business/${this.$f7route.params.businessCode}/board/${this.$f7route.params.boardId}/action/${this.$f7route.params.actionId}/project/${this.$f7route.params.projectId}/proposal/${proposalNumber}`,
          {
            pushState: true
          }
        );
      } else {
        this.$f7router.navigate(`/proposal/${proposalNumber}`, {
          pushState: true
        });
      }
    }
  }
};
</script>

<style></style>
