import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_CONTACT](state, payload) {
    Vue.set(state, "contact", payload);
  },

  [types.SET_COMPANY](state, payload) {
    Vue.set(state, "company", payload);
  },

  [types.SET_PROPERTY](state, payload) {
    Vue.set(state, "property", payload);
  }
};
