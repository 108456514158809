<template>
  <div>
    <data-table
      :headers="headers"
      :items="sortedHistoryList"
      :pageSize="sortedHistoryList.length"
    >
      <template v-slot:body="{ item }">
        <td>
          {{ toLocaleString(item.createdAt) }}
        </td>
        <td>
          {{ item.createdBy }}
        </td>
        <td>
          {{ item.note }}
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import DataTable from "@/components/datatables";

export default {
  components: { DataTable },

  computed: {
    ...mapGetters("proposal/history/", ["historyList"]),

    headers() {
      return [
        {
          text: "Change At",
          align: "left",
          value: "createdAt",
          width: "40%"
        },
        {
          text: "Change by",
          align: "left",
          value: "createdBy",
          width: "50%"
        },
        {
          text: "Note",
          align: "left",
          value: "note",
          width: "10%"
        }
      ];
    },

    sortedHistoryList() {
      return _.cloneDeep(this.historyList).sort(
        (a, b) => b.createdAt - a.createdAt
      );
    }
  },

  methods: {
    toLocaleString(firebaseDate) {
      return firebaseDate
        ? firebaseDate.toDate().toLocaleDateString("en-US")
        : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.activity-item ::v-deep .item-media {
  display: contents;
}

.activity-item ::v-deep .item-title-row::before {
  content: "" !important;
}

.activity-item ::v-deep .inner {
  text-align: end;
  font-size: 11px;
}

.media-list {
  margin-top: 0px;
}
</style>
