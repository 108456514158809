import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_FILTER_DATA_RESULT_BY_PL](
    state,
    { roofTypes, categoryIds, subCategoryIds, vendorIds, status }
  ) {
    Vue.set(state.productsByPL, "vendorIdFilterList", vendorIds);
    Vue.set(state.productsByPL, "categoryIdFilterList", categoryIds);
    Vue.set(state.productsByPL, "subCategoryIdFilterList", subCategoryIds);
    Vue.set(state.productsByPL, "roofTypeFilterList", roofTypes);
    Vue.set(state.productsByPL, "statusFilterList", status);
  },

  [types.SET_SEARCH_RESULT_BY_PL](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state.productsByPL,
      "hits",
      hits.map(r => {
        delete r.createdAt;
        delete r.updatedAt;
        delete r._highlightResult;
        delete r.objectID;
        return r;
      })
    );
    Vue.set(state.productsByPL, "hitsPerPage", hitsPerPage);
    Vue.set(state.productsByPL, "nbHits", nbHits);
    Vue.set(state.productsByPL, "nbPages", nbPages);
    Vue.set(state.productsByPL, "page", page);
  },

  [types.SET_NUMBER_OF_ROWS_BY_PL](state, payload) {
    Vue.set(state.productsByPL, "hitsPerPage", payload);
  },
  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, "searchText", payload);
  },
  [types.SET_SEARCH_ORDER_BY_PL](state, payload) {
    Vue.set(state.productsByPL, "order", payload);
  },

  [types.SET_FILTER_DATA_RESULT_BY_LIBRARY](
    state,
    { roofTypes, categoryIds, subCategoryIds, vendorIds }
  ) {
    Vue.set(state.productsLibrary, "vendorIdFilterList", vendorIds);
    Vue.set(state.productsLibrary, "categoryIdFilterList", categoryIds);
    Vue.set(state.productsLibrary, "subCategoryIdFilterList", subCategoryIds);
    Vue.set(state.productsLibrary, "roofTypeFilterList", roofTypes);
  },

  [types.SET_SEARCH_RESULT_BY_LIBRARY](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(
      state.productsLibrary,
      "hits",
      hits.map(r => {
        delete r.createdAt;
        delete r.updatedAt;
        delete r._highlightResult;
        delete r.objectID;
        return r;
      })
    );
    Vue.set(state.productsLibrary, "hitsPerPage", hitsPerPage);
    Vue.set(state.productsLibrary, "nbHits", nbHits);
    Vue.set(state.productsLibrary, "nbPages", nbPages);
    Vue.set(state.productsLibrary, "page", page);
  },

  [types.SET_NUMBER_OF_ROWS_BY_LIBRARY](state, payload) {
    Vue.set(state.productsLibrary, "hitsPerPage", payload);
  },

  [types.SET_SEARCH_ORDER_BY_LIBRARY](state, payload) {
    Vue.set(state.productsLibrary, "order", payload);
  },

  [types.RESET_SEARCH_BY_PL](state) {
    Vue.set(state.productsByPL, "order", "desc");
    Vue.set(state.productsByPL, "hits", []);
    Vue.set(state.productsByPL, "hitsPerPage", 25);
    Vue.set(state.productsByPL, "nbHits", 0);
    Vue.set(state.productsByPL, "nbPages", 0);
    Vue.set(state.productsByPL, "page", 0);
  },

  [types.RESET_SEARCH_BY_LIBRARY](state) {
    Vue.set(state.productsLibrary, "order", "desc");
    Vue.set(state.productsLibrary, "hits", []);
    Vue.set(state.productsLibrary, "hitsPerPage", 25);
    Vue.set(state.productsLibrary, "nbHits", 0);
    Vue.set(state.productsLibrary, "nbPages", 0);
    Vue.set(state.productsLibrary, "page", 0);
  }
};
