import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_USERS_LIST](state, userList) {
    Vue.set(state, "userList", userList);
  },
  [types.SET_GROUP_USERS](state, userGroupList) {
    Vue.set(state, "userGroupList", userGroupList);
  }
};
