import _ from "lodash";
import { COMPANY_TYPE_VENDOR } from "../../../../utility/const";
import { getFullAddress } from "@/utility/address";

export default {
  contactGroup: state => (searchValue = "") => {
    let group = [];
    let contacts =
      searchValue != ""
        ? state.contactList.filter(contact => {
            let contactFirstName = contact.firstName
              ? contact.firstName.toLowerCase()
              : "";
            let contactLastName = contact.lastName
              ? contact.lastName.toLowerCase()
              : "";

            let contactName = contactFirstName + " " + contactLastName;
            return (
              contactFirstName.indexOf(searchValue.toLowerCase()) >= 0 ||
              contactLastName.indexOf(searchValue.toLowerCase()) >= 0 ||
              contactName.trim().indexOf(searchValue.toLowerCase()) >= 0
            );
          }) || []
        : state.contactList;

    contacts.forEach(contact => {
      let letter = contact.firstName
        ? contact.firstName.charAt(0).toUpperCase()
        : "" || contact.lastName
        ? contact.lastName.charAt(0).toUpperCase()
        : "";
      let index = group.findIndex(groupName => {
        return letter === Object.keys(groupName)[0];
      });
      if (index >= 0) {
        group[index][letter].push(contact);
      } else {
        group.push({ [letter]: [contact] });
      }
    });

    group.forEach(data => {
      let letter = Object.keys(data)[0];
      data[letter].sort((a, b) => {
        return (a.firstName ? a.firstName.charAt(1).toUpperCase() : 0) <
          (b.firstName ? b.firstName.charAt(1).toUpperCase() : 0)
          ? -1
          : 0;
      });
    });

    return group.sort((a, b) => {
      return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
    });
  },

  companyGroup: state => (
    searchValue = "",
    showAll = true,
    contactId = "",
    companyType = ""
  ) => {
    let group = [];
    // show all except Manufacturer
    let newCompanyList = showAll
      ? state.companyList
      : companyType
      ? state.companyList.filter(item =>
          _.includes(item.companyTypes, companyType)
        )
      : state.companyList.filter(
          item => !_.includes(item.companyTypes, COMPANY_TYPE_VENDOR)
        );

    let contact = state.contactList.find(r => r.id === contactId);

    if (contact && contact.companyRefs) {
      let companyRefs = contact.companyRefs;
      newCompanyList = newCompanyList.filter(r =>
        (companyRefs || []).map(c => c.id).includes(r.id)
      );
    }

    let companies =
      searchValue != ""
        ? newCompanyList.filter(company => {
            let companyName = company.companyName
              ? company.companyName.toLowerCase()
              : "";

            return companyName.indexOf(searchValue.toLowerCase()) >= 0;
          }) || []
        : newCompanyList;

    companies.forEach(company => {
      let letter = company.companyName
        ? company.companyName.charAt(0).toUpperCase()
        : "";
      let index = group.findIndex(groupName => {
        return letter === Object.keys(groupName)[0];
      });
      if (index >= 0) {
        group[index][letter].push(company);
      } else {
        group.push({ [letter]: [company] });
      }
    });

    group.forEach(data => {
      let letter = Object.keys(data)[0];
      data[letter].sort((a, b) => {
        return (a.companyName ? a.companyName.charAt(0).toUpperCase() : "") <
          (b.companyName ? b.companyName.charAt(0).toUpperCase() : "")
          ? -1
          : 0;
      });
    });

    return group.sort((a, b) => {
      return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
    });
  },

  propertyGroup: state => (searchValue = "", companyId = "") => {
    let group = [];

    let properties =
      searchValue != ""
        ? state.propertyList.filter(property => {
            let propertyName = property.propertyName
              ? property.propertyName.toLowerCase()
              : "";
            let propertyAddress = property.addresses
              ? getFullAddress((property.addresses || [])[0]).toLowerCase()
              : "";
            return (
              propertyName.indexOf(searchValue.toLowerCase()) >= 0 ||
              propertyAddress.indexOf(searchValue.toLowerCase()) >= 0
            );
          }) || []
        : state.propertyList;

    let company = state.companyList.find(r => r.id === companyId);
    if (company && company.propertyRefs) {
      let propertyRefs = company.propertyRefs;
      properties = properties.filter(r =>
        (propertyRefs || []).map(c => c.id).includes(r.id)
      );
    }

    properties.forEach(property => {
      let letter = property.propertyName
        ? property.propertyName.charAt(0).toUpperCase()
        : "";
      let index = group.findIndex(groupName => {
        return letter === Object.keys(groupName)[0];
      });
      if (index >= 0) {
        group[index][letter].push(property);
      } else {
        group.push({ [letter]: [property] });
      }
    });

    group.forEach(data => {
      let letter = Object.keys(data)[0];
      data[letter].sort((a, b) => {
        return (a.propertyName ? a.propertyName.charAt(1).toUpperCase() : "") <
          (b.propertyName ? b.propertyName.charAt(1).toUpperCase() : "")
          ? -1
          : 0;
      });
    });

    return group.sort((a, b) => {
      return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
    });
  },

  contact: state => state.contact || {},
  company: state => state.company || {},
  property: state => state.property || {},
  insuranceCompany: state => state.insuranceCompany || {},

  contactById: state => contactId => {
    if (!_.isEmpty(contactId) && !_.isEmpty(state.contactList)) {
      return state.contactList.find(contact => contact.id === contactId);
    }
  },

  companyById: state => companyId => {
    if (!_.isEmpty(companyId) && !_.isEmpty(state.companyList)) {
      return state.companyList.find(company => company.id === companyId);
    }
  },

  propertyById: state => propertyId => {
    if (!_.isEmpty(propertyId) && !_.isEmpty(state.propertyList)) {
      return state.propertyList.find(property => property.id === propertyId);
    }
  },

  propertyList: state => state.propertyList,
  companyList: state => state.companyList,

  listEmails: (state, _, __, rootGetters) => {
    const users = rootGetters["dashboard/project/users"];
    var listEmails = [];
    //email from contact
    state.contactList &&
      state.contactList.forEach(contact => {
        let emails = contact.emails;
        emails &&
          emails.forEach(email => {
            if (!listEmails.includes(email.value)) {
              listEmails.push(email.value);
            }
          });
      });

    //email from company
    state.companyList &&
      state.companyList.forEach(company => {
        let others = company.others;
        others &&
          others.forEach(other => {
            if (other.code == "email" && !listEmails.includes(other.value)) {
              listEmails.push(other.value);
            }
          });
      });

    //email from useers
    users &&
      users.forEach(user => {
        if (!listEmails.includes(user.email)) {
          listEmails.push(user.email);
        }
      });

    return listEmails;
  }
};
