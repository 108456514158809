<template>
  <f7-popup
    tablet-fullscreen
    :opened="popupOpened"
    @popup:closed="handleCloseAndRefresh"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ purchaseOrder.taskNumber || "" }}</f7-nav-title>
        <f7-nav-right></f7-nav-right>
      </f7-navbar>
      <purchase-order-detail @closeAndRefresh="handleCloseAndRefresh" />
    </f7-page>
  </f7-popup>
</template>

<script>
import PurchaseOrderDetail from "../details/PurchaseOrderDetail.vue";
import poMixin from "../../mixins/purchase-order-mixin";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    PurchaseOrderDetail
  },

  mixins: [poMixin],

  data: () => {
    return {
      popupOpened: false
    };
  },

  computed: {
    ...mapGetters("purchase-order/purchase-order", ["purchaseOrder"]),
    ...mapGetters("setting/app/system", ["setting"]),
    ...mapGetters("purchase-order/purchase-order-details-page/common", [
      "destroyFlag"
    ]),
    ...mapGetters("purchase-order/purchase-order-details-page/project", [
      "actionList"
    ]),
    ...mapGetters("purchase-order/purchase-order-details-page/vendor", [
      "vendorList"
    ]),
    ...mapGetters("purchase-order/app-constant", ["roofTypeList"]),
    ...mapGetters('common/app-constant', ['tenantId']),

  },

  methods: {
    ...mapActions("purchase-order/purchase-order", [
      "bindPurchaseOrderResponse",
      "bindPurchaseOrder",
      "unbindWorkOrderTask"
    ]),
    ...mapActions("setting/app/system", ["bindSetting"]),
    ...mapActions("purchase-order/purchase-order-details-page/project", [
      "getProject",
      "getActionList",
      "resetActionList"
    ]),
    ...mapActions("purchase-order/purchase-order-details-page/product-item", [
      "bindProductItemListBys",
      "unbindProductItemList"
    ]),
    ...mapActions("purchase-order/app-constant", ["bindConstantTypeList"]),
    ...mapActions("purchase-order/purchase-order-details-page/common", [
      "setDestroyFlag"
    ]),
    ...mapActions("purchase-order/purchase-order-details-page/vendor", [
      "bindVendorList",
      "unbindVendorList"
    ]),
    ...mapActions("purchase-order/purchase-order-details-page/price-list", [
      "bindPriceListItem",
      "unbindPriceListItem"
    ]),

    open(item) {
      this.popupOpened = true;
      this.$f7.preloader.show();
      this.setDestroyFlag(false);
      const seft = this;
      this.bindPurchaseOrder({
        poNumber: item.taskNumber
      })
        .then(() => {
          const promises = [];
          if (_.isEmpty(this.purchaseOrder)) {
            return;
          }
          if (_.isEmpty(this.setting)) {
            promises.push(this.bindSetting(this.tenantId));
          }
          if (_.isEmpty(this.vendorList)) {
            promises.push(this.bindVendorList());
          }
          if (_.isEmpty(this.roofTypeList)) {
            promises.push(this.bindConstantTypeList());
          }
          if (_.isEmpty(seft.actionList)) {
            promises.push(this.getActionList());
          }
          promises.push(this.getProject(this.purchaseOrder.projectId));
          if (this.purchaseOrder.priceListId) {
            promises.push(
              this.bindPriceListItem(this.purchaseOrder.priceListId)
            );
          }
          promises.push(
            this.bindProductItemListBys([
              {
                prop: "status",
                val: "pi-active",
                op: "=="
              },
              {
                prop: "vendorId",
                val: this.purchaseOrder.vendorId,
                op: "=="
              }
            ])
          );

          return Promise.all(promises).then(() => {
            return this.bindPurchaseOrderResponse(this.getActionId());
          });
        })
        .finally(() => {
          this.$f7.preloader.hide();
          this.setDestroyFlag(true);
        });
    },

    handleCloseAndRefresh() {
      this.popupOpened = false;
      this.$f7router.history.push(
        `/purchase-order-grid`
      );
      history.pushState(
        null,
        "",
        `/purchase-order-grid`
      );
      this.$emit("refreshGridData");
    }
  },
  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.unbindWorkOrderTask();
      this.resetActionList();
      this.unbindPriceListItem();
      this.unbindProductItemList();
      this.unbindVendorList();
    }
  }
};
</script>
