import { FirebaseActions, firebase } from "../../services/firebase.service";
import * as types from "./types";
const auth = firebase.auth();

export default tenant => {
  const ScheduleActions = new FirebaseActions(
    `/system_client/${tenant}/schedule_value_setting`,
    "scheduleSetting"
  );

  return {
    getScheduleSettingListBys: ScheduleActions.getDocumentBys,

    addScheduleSetting: ScheduleActions.createDocument,
    saveScheduleSetting: ScheduleActions.updateDocument,
    removeScheduleSetting: ScheduleActions.deleteDocument,

    async getScheduleSettingList({ dispatch, commit }) {
      const res = await dispatch("getScheduleSettingListBys", []);
      commit(types.SET_SCHEDULE_SETTING_LIST, res || []);
    },

    createScheduleSetting({ dispatch, commit }, schedule) {
      return dispatch("addScheduleSetting", schedule).then(id => {
        return commit(types.SCHEDULE_SETTING_LIST_ITEM_ADD, {
          ...schedule,
          id,
          createdBy: auth.currentUser
            ? auth.currentUser.displayName || auth.currentUser.email
            : ""
        });
      });
    },

    updateScheduleSetting({ dispatch, commit }, { id, doc }) {
      return dispatch("saveScheduleSetting", { id, doc }).then(() => {
        return commit(types.SCHEDULE_SETTING_LIST_ITEM_UPDATE, {
          id,
          doc
        });
      });
    },

    deleteScheduleSetting({ dispatch, commit }, id) {
      return dispatch("removeScheduleSetting", id).then(() => {
        return commit(types.SCHEDULE_SETTING_LIST_ITEM_REMOVE, id);
      });
    },

    resetScheduleSetting({ commit }) {
      commit(types.SET_SCHEDULE_SETTING_LIST, []);
    }
  };
};
