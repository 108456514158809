import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_USERS_LIST](state, userList) {
    Vue.set(state, "userList", userList);
  },
  [types.SET_USER_ID](state, userId) {
    Vue.set(state, "userId", userId);
  }
};
