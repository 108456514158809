import Vue from "vue";

export default {
  setVendorList(state, payload) {
    Vue.set(state, "vendorList", payload);
  },
  setVendorSubscribe(state, payload) {
    Vue.set(state, "vendorSubscribe", payload);
  },
  setDetailId(state, id) {
    state.detailId = id;
  }
};
