export const SET_CURRENT_CARD = "SET_CURRENT_CARD";
export const SET_CURRENT_CARD_VALUES = "SET_CURRENT_CARD_VALUES";
export const CLEAR_CURRENT_CARD = "CLEAR_CURRENT_CARD";
export const SET_CURRENT_ACTION = "SET_CURRENT_ACTION";
export const SET_ACTION_RESPONSE = "SET_ACTION_RESPONSE";
export const SET_ACTION_LIST = "SET_ACTION_LIST";

export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_FILTER_USERS = "SET_FILTER_USERS";
export const SET_FILTER_SEARCH = "SET_FILTER_SEARCH";
export const SET_FILTER_WEEK = "SET_FILTER_WEEK";

export const SET_CURRENT_BOARD = "SET_CURRENT_BOARD";
export const SET_CURRENT_BUSINESS_CODE = "SET_CURRENT_BUSINESS_CODE";
export const SET_CONSTRUCTION_PROJECT_ID = "SET_CONSTRUCTION_PROJECT_ID";
export const SET_CONSTRUCTION_PROJECT_NUMBER =
  "SET_CONSTRUCTION_PROJECT_NUMBER";
export const SET_IS_DELETE_TEMPLE_PROJECT = "SET_IS_DELETE_TEMPLE_PROJECT";
export const SET_CURRENT_ACTION_IDS_AFTER_MOVE_OR_ADD =
  "SET_CURRENT_ACTION_IDS_AFTER_MOVE_OR_ADD";
export const SET_CONDITION_TO_DISPLAY_RESPONSE_BTN =
  "SET_CONDITION_TO_DISPLAY_RESPONSE_BTN";
export const SET_CURRENT_SECTION = "SET_CURRENT_SECTION";
