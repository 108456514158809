import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const actionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_sub_category`,
    "subCategory"
  );

  return {
    bindSubCategoryList: actionsTemplate.bindCollection,
    unbindSubCategoryList: actionsTemplate.unbindCollection,
    bindByCategory: ({ dispatch }, categoryId) => {
      dispatch("bindBys", [
        {
          prop: "categoryId",
          op: "==",
          val: categoryId
        }
      ]);
    }
  };
};
