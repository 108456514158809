export const SET_TEAM_LIST = "SET_TEAM_LIST";

export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_NUMBER_OF_ROWS = "SET_NUMBER_OF_ROWS";
export const RESET_SEARCH = "RESET_SEARCH";
export const ADD_MORE_RESULT = "ADD_MORE_RESULT";

export const TEAM_LIST_ITEM_UPDATE = "TEAM_LIST_ITEM_UPDATE";
export const TEAM_LIST_ITEM_ADD = "TEAM_LIST_ITEM_ADD";
export const TEAM_LIST_ITEM_REMOVE = "TEAM_LIST_ITEM_REMOVE";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_QUERY_FILTERS = "SET_QUERY_FILTERS";
