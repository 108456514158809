export default {
  /**
   * objectList
   */
  objectList: ({ objectList }) => objectList,
  detail: ({ objectList, detailId }) =>
    objectList.find(item => item.id === detailId),

  currentBoard: ({ objectList }) =>
    objectList.find(item => item.default === true) || {},

  boardByNumber: ({ objectList }) => number => {
    return objectList.find(item => item.boardNumber === number) || {};
  }
};
