<template>
  <div>
    <!-- Swimlane Body -->
    <div class="swimlane-body" :style="pageWith">
      <div class="column" v-for="action in actions" :key="action.id">
        <actions-swimlane
          :action="action"
          :cards="filterCards(action.id)"
          @click="$emit('onOpenPopup', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { sortByUpdatedOrCreated } from "../../../../utility/date-time-tool";
import ActionsSwimlane from "./ActionsSwimlane.vue";

export default {
  components: { ActionsSwimlane },

  props: {
    actions: { type: Array, default: () => [] },
    cards: { type: Array, default: () => [] },
    cardWidth: Number
  },

  computed: {
    pageWith() {
      return "width: " + this.actions.length * this.cardWidth + "px;";
    }
  },

  methods: {
    filterCards(actionId) {
      const cards = this.cards.filter(card => {
        return card.actions ? card.actions.includes(actionId) : false;
      });
      return sortByUpdatedOrCreated(cards, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.column {
  display: inline-flex;
}
.swimlane-body {
  padding-right: 10px;
}
</style>
