import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Online Leads",
    displayRoles: ["owner", "user"],
    routes: [
      {
        path: "/online-leads",
        title: "Online Leads",
        name: "online-leads-board",
        parent: "dashboard-main",
        displayRoles: ["owner", "user"],
        icon: "rectangle_stack_person_crop",
        async: checkAuth(() => import("../pages/ContactInfoGridView.vue"))
      }
    ]
  }
];
