<template>
  <div>
    <data-table
      :headers="headers"
      :items="itemList"
      :pageSize="itemList.length"
      class="table-custom"
    >
      <template v-slot:body="{ item, index }">
        <td>
          <div>{{ `${item.drawingNumber} - ${item.drawingName}` }}</div>
        </td>
        <td class="text-align-right">
          <div
            class="text-align-left text-red"
            @click="onShowMetalDrawing(index)"
          >
            View
          </div>
        </td>
        <td>
          <select
            style="width: 100px"
            class="text-align-left"
            :value="item.assemblyGA"
            @change="
              changeAssemblyProp(
                index,
                'assemblyGA',
                $event.target.value.trim(),
                () => {
                  changeAssemblyProp(index, 'colorAndManufacturer', '', () => {
                    checkMetalStretchOut(item);
                  });
                }
              )
            "
          >
            <option value="" hidden>Select GA</option>
            <option
              v-for="(ga, index) in [
                { id: 'ga-22', displayName: '22' },
                { id: 'ga-24', displayName: '24' },
                { id: 'ga-26', displayName: '26' }
              ]"
              :key="index"
              :value="ga.id"
              >{{ ga.displayName }}</option
            >
          </select>
        </td>

        <td>
          <cell-number-input
            numberType="decimal"
            :fractionDigits="0"
            placeholder="Length/Qty"
            :value="item.qty"
            @done="
              onChangeAssemblyQty(
                index,
                'qty',
                parseFloat($event),
                item,
                () => {
                  checkMetalStretchOut(item);
                }
              )
            "
            :suffixes="isQuantity(item) ? 'EA' : 'LF'"
          ></cell-number-input>
        </td>
        <td class="text-align-right">
          {{ totalCost(item.productList) | currencyUSD }}
        </td>
        <td class="text-align-right">
          <div style="display: flex;justify-content: flex-end; gap: 10px">
            <a href="#" @click="openEditPopup(index)">
              <f7-icon f7="pencil" color="blue"></f7-icon>
            </a>
            <a href="#" @click="removeAssembly(index)">
              <f7-icon f7="trash" style="color: red;"></f7-icon>
            </a>
          </div>
        </td>
      </template>
    </data-table>

    <assembly-list-popup
      ref="selectAssemblyListPopup"
      :roofType="roofType"
      @onSelected="handleAddAssemblyList"
    ></assembly-list-popup>
  </div>
</template>
<script>
import DataTable from "@/components/datatables";
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import AssemblyListPopup from "../popup/AssemblyListPopup.vue";
import {
  assemblyTemplateCodeIsQuantityType,
  calculateAssemblyLength
} from "../../utility/assembly-data";
import { mapGetters } from "vuex";
import buildingMixins from "../../utility/building-mixins";

export default {
  mixins: [buildingMixins],
  components: {
    DataTable,
    CellNumberInput,
    AssemblyListPopup
  },
  props: {
    items: Array,
    roofType: String,
    sectionId: String
  },
  computed: {
    ...mapGetters("estimate/estimate-page/assembly-drawing", {
      assemblyList: "assemblyDrawingListByRoofType",
      assemblyDrawingById: "assemblyDrawingById"
    }),

    headers() {
      return [
        {
          text: "Items",
          value: "item",
          sortable: false,
          align: "left"
        },
        {
          text: "Assembly Drawing",
          value: "view",
          sortable: false,
          align: "left"
        },
        {
          text: "GA",
          value: "ga",
          sortable: false,
          align: "left"
        },
        {
          text: "Length/Qty",
          value: "length",
          sortable: false,
          align: "right"
        },
        {
          text: "Total Cost",
          value: "name",
          sortable: false,
          align: "right"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          align: "right"
        }
      ];
    },

    isQuantity() {
      return assembly => {
        return assemblyTemplateCodeIsQuantityType.includes(
          assembly.assemblyItem
        );
      };
    },

    displayAssemblyList() {
      return this.assemblyList(this.roofType).map(r => ({
        displayName: `${r.drawingNumber} - ${r.drawingName}`,
        value: r.id,
        img: r.image
      }));
    },

    itemList() {
      return this.items || [];
    },

    totalCost() {
      return (productList = []) => {
        const total = productList.reduce((b, item) => {
          return b + (item.orderQty || 0) * (item.price || 0);
        }, 0);
        return total;
      };
    }
  },
  methods: {
    // openAssemblyList(index) {
    //   this.$refs[`selectAssembly_${index}`].open();
    // },

    openEditPopup(index) {
      this.$emit("openEditPopup", index);
    },

    removeAssembly(index) {
      this.$emit("removeAssembly", index);
    },

    // onChangeAssemblyDrawing(assemblyId, index) {
    //   const sefl = this;
    //   this.$f7.preloader.show();
    //   this.disableDrawing = true;
    //   this.$emit("setAssemblyIndex", index);
    //   this.$emit("changeTemplate", {
    //     target: { index, value: this.assemblyDrawingById(assemblyId) },
    //     callback: () => {
    //       sefl.disableDrawing = false;
    //       this.$f7.preloader.hide();
    //     }
    //   });
    // },

    /**
     * DEV-1230: Est-On the metal section, instead of individually adding each item, can you maybe put a check on each item to select the 4 items that I need and then I can go in and edit the information? (David requried Feb 15)
     */
    handleAddAssemblyList(assemblyIds) {
      const sefl = this;
      this.$f7.preloader.show();
      this.disableDrawing = true;
      // this.$emit("setAssemblyIndex", index);
      const assemblyList = assemblyIds.map(id => {
        return { ...this.assemblyDrawingById(id), id };
      });
      this.$emit("onAddAssemblyList", {
        assemblyList,
        callback: () => {
          sefl.disableDrawing = false;
          this.$f7.preloader.hide();
        }
      });
    },

    onChangeAssemblyQty(index, prop, value, item, cb) {
      const callback = cb;
      this.changeAssemblyProp(index, prop, value, () => {
        const length = calculateAssemblyLength({ assembly: item, qty: value });
        this.changeAssemblyProp(index, "length", length, callback);
      });
    },

    changeAssemblyProp(index, prop, value, cb) {
      this.$emit("setAssemblyIndex", index);
      this.$emit("changeAssemblyProp", index, prop, value, cb);
    },

    /**
     * DEV-1230: Est-On the metal section, instead of individually adding each item, can you maybe put a check on each item to select the 4 items that I need and then I can go in and edit the information? (David requried Feb 15)
     */
    openMetalListPopup() {
      this.$refs.selectAssemblyListPopup.open();
    },

    onShowMetalDrawing(index) {
      this.$emit("openPreviewMetalDrawingPopup", index);
    }
  }
};
</script>
<style lang="scss" scoped>
.assembly-item {
  cursor: pointer;
  &:hover {
    background-color: var(--f7-page-bg-color);
  }
}
::v-deep select {
  cursor: pointer;
  &:hover {
    background-color: var(--f7-page-bg-color);
  }
}

.text-red {
  color: var(--f7-theme-color);
}

::v-deep .table-custom tbody {
  tr:hover {
    background-color: unset !important;
  }

  td {
    padding: 16px;
  }

  .active-row {
    &:hover {
      background-color: var(--f7-color-bg-select-neutral) !important;
    }
  }
}
.table-custom {
  &::v-deep th {
    padding: 0 16px;
  }
}
</style>
