import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_USERS_LIST](state, userList) {
    Vue.set(state, "userList", userList);
  },
  [types.SET_USER_ID](state, userId) {
    Vue.set(state, "userId", userId);
  },
  [types.SET_USER](state, user) {
    if (user) {
      for (const key in user) {
        if (user.hasOwnProperty(key)) {
          const value = user[key];
          Vue.set(state.user, key, value);
        }
      }
    } else {
      Vue.set(state, "user", user);
    }
  }
};
