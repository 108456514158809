import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "/archive-board",
    title: "Archive Board",
    name: "archive-main",
    parent: "dashboard-main",
    displayRoles: ["owner", "user"],
    icon: "archivebox",
    async: checkAuth(() => import("../pages/layout/ArchiveGridViewLayout.vue"))
  }
];
