/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const roofTypeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    "roofType"
  );

  return {
    getRoofTypeListBys: roofTypeAction.getDocumentBys,

    async getRoofTypeList({ commit, dispatch }) {
      const list = await dispatch("getRoofTypeListBys", [
        {
          prop: "code",
          op: "==",
          val: "roof-type"
        }
      ]);
      commit(types.SET_ROOFTYPE_LIST, list);
    },

    resetRoofType({ commit }) {
      commit(types.SET_ROOFTYPE_LIST, []);
    }
  };
};
