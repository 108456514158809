<template>
  <data-table
    :headers="headers"
    :items="dataSource"
    :pageSize="(dataSource || []).length"
    :isPinHeader="true"
  >
    <template v-slot:body="{ item }">
      <td>
        <a href="javascript:void(0)" @click="$emit('showDetail', item.data)">
          {{ item.createdBy }}
        </a>
      </td>
      <td>{{ toDisplayDateString(item.createdAt) }}</td>
      <td>{{ item.data ? item.data.length : 0 }}</td>
    </template>
  </data-table>
</template>
<script>
import DataTable from "@/components/datatables";
import {
  // toDateCalendar,
  // toDateFirebase
  toDisplayDateString
} from "../../../../utility/datetime";

export default {
  props: {
    dataSource: Array
  },
  components: {
    DataTable
  },

  computed: {
    headers() {
      return [
        {
          text: "Patch By",
          value: "patchName",
          sortable: false,
          align: "left"
        },
        {
          text: "Reported Date",
          value: "reportedDate",
          sortable: false,
          align: "left"
        },
        {
          text: "Number of Items",
          value: "number-of-items",
          sortable: false,
          align: "left"
        }
      ];
    }
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    }
  }
};
</script>
