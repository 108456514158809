<template>
  <f7-list :class="listClass">
    <f7-list-item
      ref="multiSelect"
      :disabled="disabled"
      smart-select
      :smart-select-params="{
        pageTitle: placeholder,
        openIn: 'popup',
        searchbar: true,
        searchbarPlaceholder: 'Search',
        on: {
          closed: selectionChange,
          opened: handlePopupOpened
        },
        formatValueText: function() {
          return null;
        },
        popupCloseLinkText: 'Done'
      }"
      :header="header"
      :title="displayedValues"
    >
      <required-asterisk v-show="required" slot="header" />
      <div slot="footer" class="text-color-red" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <select multiple>
        <option
          v-for="item in selectionSource"
          :key="item.key"
          :value="item.key"
          :selected="value.includes(item.key)"
          >{{ item.value }}</option
        >
      </select>
    </f7-list-item>
  </f7-list>
</template>
<script>
import _ from "lodash";
function compareArray(arr1, arr2) {
  return _.isEqual(arr1.slice().sort(), arr2.slice().sort());
}
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: () => null
    },
    selectionSource: {
      type: Array,
      default: () => []
    },
    header: {
      type: String,
      default: () => null
    },
    placeholder: {
      type: String,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    required: {
      type: Boolean,
      default: () => false
    },
    listClass: {
      type: String,
      default: ""
    }
  },
  methods: {
    selectionChange(v) {
      const value = v.getValue();
      if (compareArray(value, this.value)) {
        return;
      }
      this.$emit("input", value);
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.multiSelect.f7SmartSelect.searchbar.$el[0]
          .querySelector(".searchbar input")
          .focus();
      }
    }
  },
  computed: {
    displayedValues() {
      const values = this.selectionSource
        .filter(r => this.value.includes(r.key))
        .map(r => r.value)
        .join(", ");

      if (values) return values;

      return this.placeholder;
    }
  }
};
</script>
