import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/team_management`,
    "team"
  );

  return {
    getTeamListBys: ActionsTemplate.getDocumentBys,
    getTeamList({ dispatch, commit }, conditions = []) {
      return dispatch("getTeamListBys", conditions).then(res => {
        return commit(types.SET_TEAM_LIST, res);
      });
    },
    resetTeam({ commit }) {
      commit(types.SET_TEAM_LIST, []);
    }
  };
};
