export const SET_PRODUCT_REF_LIST = "SET_PRODUCT_REF_LIST";

export const SET_FILTER_DATA_RESULT_BY_PL = "SET_FILTER_DATA_RESULT_BY_PL";
export const SET_SEARCH_RESULT_BY_PL = "SET_SEARCH_RESULT_BY_PL";
export const SET_NUMBER_OF_ROWS_BY_PL = "SET_NUMBER_OF_ROWS_BY_PL";
export const SET_SEARCH_ORDER_BY_PL = "SET_SEARCH_ORDER_BY_PL";

export const SET_FILTER_DATA_RESULT_BY_LIBRARY =
  "SET_FILTER_DATA_RESULT_BY_LIBRARY";
export const SET_SEARCH_RESULT_BY_LIBRARY = "SET_SEARCH_RESULT_BY_LIBRARY";
export const SET_NUMBER_OF_ROWS_BY_LIBRARY = "SET_NUMBER_OF_ROWS_BY_LIBRARY";
export const SET_SEARCH_ORDER_BY_LIBRARY = "SET_SEARCH_ORDER_BY_LIBRARY";

export const RESET_SEARCH_BY_PL = "RESET_SEARCH_BY_PL";
export const RESET_SEARCH_BY_LIBRARY = "RESET_SEARCH_BY_LIBRARY";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
