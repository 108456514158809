<template>
  <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="popupOpened = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Edit Shingle Type</f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-item v-for="(type, index) in shingleTypeList" :key="index" :value="type.value">
          <div slot="title">{{ type.displayName }}</div>
          <div slot="after-title">
            <input-icon @click.native="openEditPopup(type.value, type.id)" class="pointer" icon="pencil"></input-icon>
            <input-icon @click.native="deleteShingleType(type.id)" class="pointer margin-left-half"
              icon="trash"></input-icon>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { required } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
import InputIcon from "../icon/InputIcon.vue";
export default {
  components: {
    InputIcon
  },
  data: () => {
    return {
      popupOpened: false
    };
  },

  computed: {
    ...mapGetters("dashboard/shingle-type", ["shingleTypeList"]),
    capitalizeFirstLetter() {
      return string => {
        return string && string.charAt(0).toUpperCase() + string.slice(1);
      };
    }
  },

  methods: {
    ...mapActions("dashboard/shingle-type", ["updateShingleType"]),

    open() {
      this.popupOpened = true;
    },
    deleteShingleType(id) {
      this.$ri.dialog.openWarningDialog({
        title: "Delete Shingle Type",
        content: "Are you sure you want to delete this shingle type?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            this.updateShingleType({
              id: id,
              doc: { isDeleted: true }
            }).then(() => {
              this.popupOpened = false;
              this.$f7.preloader.hide();
            });
          }
        }
      });
    },
    openEditPopup(value, id) {
      const _sefl = this;
      _sefl.$f7.dialog.prompt(
        "Edit shingle type",
        null,
        editValue => {
          let sameValue = this.shingleTypeList.find(r => r.value === editValue);
          if (editValue && !sameValue) {
            this.$f7.preloader.show();
            this.updateShingleType({
              id: id,
              doc: {
                value: editValue,
                displayName: this.capitalizeFirstLetter(editValue)
              }
            }).then(() => {
              this.$nextTick(() => {
                this.$emit("editedShingleType", editValue, id);
              });
              this.popupOpened = false;
              this.$f7.preloader.hide();
            });
          } else {
            if (!editValue) {
              _sefl.$ri.dialog.openWarningDialog({
                title: "Please input shingle type",
                content: "This field is required!",
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    _sefl.app.dialog.close();
                  }
                }
              });
            } else if (sameValue) {
              _sefl.$ri.dialog.openWarningDialog({
                title: "Please input another one",
                content: "That shingle type has already existed!",
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    _sefl.app.dialog.close();
                  }
                }
              });
            }
          }
        },
        null,
        value
      );
    }
  },

  validations() {
    return {
      value: {
        required
      }
    };
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
