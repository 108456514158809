import {
  TEMPLATE_TYPE_AGREEMENT,
  TEMPLATE_TYPE_CERTIFICATE,
  TEMPLATE_TYPE_CONTRACT,
  TEMPLATE_TYPE_LETTER_AUTHORIZATION
} from "../../../../utility/const";

export default {
  agreementTemplate: state =>
    state.templateList.find(i => i.templateType === TEMPLATE_TYPE_AGREEMENT),
  letterAuthorizationTemplate: state =>
    state.templateList.find(
      i => i.templateType === TEMPLATE_TYPE_LETTER_AUTHORIZATION
    ),
  certificateTemplate: state =>
    state.templateList.find(i => i.templateType === TEMPLATE_TYPE_CERTIFICATE),
  buildContractTemplate: state =>
    state.templateList.find(i => i.templateType === TEMPLATE_TYPE_CONTRACT)
};
