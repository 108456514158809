<template>
  <div>
    <f7-block>
      <filter-by-date @searchByDate="searchByDate"></filter-by-date>
    </f7-block>

    <history-table
      :dataSource="invoiceHistoryList"
      @showDetail="isShowHistoryPopup = true"
    ></history-table>
    <check-history-popup
      :isShow="isShowHistoryPopup"
      title="Paid Check DateTime"
      :dataSource="checkPayableList"
      @close="isShowHistoryPopup = false"
    ></check-history-popup>
  </div>
</template>
<script>
import FilterByDate from "@/plugins/quickbooks/components/filter/FilterByDate.vue";
import CheckHistoryPopup from "@/plugins/quickbooks/components/popups/CheckHistoryPopup.vue";
import HistoryTable from "@/plugins/quickbooks/components/datatable/HistoryTable.vue";
export default {
  components: {
    FilterByDate,
    CheckHistoryPopup,
    HistoryTable
  },
  data: () => {
    return {
      isShowHistoryPopup: false
    };
  },
  methods: {
    searchByDate() {}
  },
  computed: {
    invoiceHistoryList() {
      return [
        {
          id: "1",
          patchName: "Paid Check DateTime",
          reportedDate: new Date("8/15/2020")
        },
        {
          id: "2",
          patchName: "Paid Check DateTime",
          reportedDate: new Date("8/17/2020")
        },
        {
          id: "2",
          patchName: "Paid Check DateTime",
          reportedDate: new Date("8/19/2020")
        }
      ];
    },
    checkPayableList() {
      return [
        {
          id: "1",
          check: "Chk-3434",
          paidDate: new Date("8/15/2020"),
          checkAmount: "6000",
          invoiceNumber: "INV-6454135",
          invoiceAmount: "6000"
        },
        {
          id: "2",
          check: "Chk-5754",
          paidDate: new Date("8/17/2020"),
          checkAmount: "8500",
          invoiceNumber: "INV-6454136",
          invoiceAmount: "8500"
        }
      ];
    }
  }
};
</script>
