import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    "contact"
  );

  return {
    getContactById: contactActions.getDocument,
    getContact({ commit, dispatch }, id) {
      return dispatch("getContactById", id).then(data => {
        return commit(types.SET_CONTACT, data);
      });
    },
    resetContact({ commit }) {
      return commit(types.SET_CONTACT, {});
    }
  };
};
