import {
  FirebaseActions,
  firestore
} from "../../../../services/firebase.service";

export default tenant => {
  const EstimateActions = new FirebaseActions(
    `/system_client/${tenant}/estimate`,
    "estimate"
  );

  return {
    bindEstimateListBy: EstimateActions.bindCollectionBy,
    unbindEstimateList: EstimateActions.unbindCollection,

    bindEstimateListBys: EstimateActions.bindCollectionBys,

    bindEstimate: EstimateActions.bindDocument,
    unbindEstimate: EstimateActions.unbindDocument,

    createEstimate: EstimateActions.createDocument,
    updateEstimate: EstimateActions.updateDocument,

    getEstimateById: EstimateActions.getDocument,

    getEstimateBys: EstimateActions.getDocumentBys,

    setEstimateField({ commit, state }, payload) {
      if (payload.value != state.estimate[payload.fieldName]) {
        commit("SET_ESTIMATE_FIELD", payload);
      }
    },
    getBuilding(_, { estimateId, buildingId }) {
      let collectionPath = `/system_client/${tenant}/estimate/${estimateId}/building`;
      return firestore
        .collection(collectionPath)
        .doc(buildingId)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    }
  };
};
