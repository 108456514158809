import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import vendor from "../vendor";
import category from "../category";
import subCategory from "../sub-category";

export default tenant => {
  return {
    namespaced: true,
    state,
    actions: actions(tenant),
    mutations,
    getters,
    modules: {
      vendor: vendor(tenant),
      category: category(tenant),
      "sub-category": subCategory(tenant)
    }
  };
};
