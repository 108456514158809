<template>
  <f7-popup :opened="popupOpened" @popup:closed="close" @popup:opened="handlePopupOpened">
    <div class="page" v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Contact</f7-nav-title>
        <f7-nav-right v-if="!isHiddenAddNew">
          <f7-link icon-f7="plus" @click.native="onAdd"></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar disable-button-text placeholder="Search contact" :clear-button="true" :value="contactSearchText"
        @input="
          setContactSearchText($event.target.value);
          onSearch();
        " @searchbar:disable="onSearch()" @searchbar:clear="onSearch()"
        class="search-list-popup"></f7-searchbar>

      <div class="page-content" @scroll="onScroll">
        <f7-list class="no-margin">
          <f7-list-group v-for="(group, index) in contactGroup" :key="index">
            <f7-list-item :title="group.key" group-title></f7-list-item>
            <f7-list-item no-chevron link v-for="(contact, index) in group.data" :key="index"
              :title="contact.contactName" @click.native="select(contact.id)"></f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
    </div>

    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Contact</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- Contact Name -->
      <f7-list>
        <f7-list-input class="first-input" type="text" placeholder="First name" label="First name" clear-button
          :value="currentContact.firstName" @input="set('firstName', $event.target.value.trim())" error-message-force
          :error-message="firstNameErrorMessage" @blur="v$.currentContact.firstName.$touch()"><required-asterisk
            slot="label"></required-asterisk></f7-list-input>
        <f7-list-input type="text" placeholder="Last name" label="Last name" clear-button
          :value="currentContact.lastName" @input="set('lastName', $event.target.value.trim())" error-message-force
          :error-message="lastNameErrorMessage" @blur="v$.currentContact.lastName.$touch()"><required-asterisk
            slot="label"></required-asterisk></f7-list-input>
      </f7-list>

      <!-- Phone -->
      <phone-input :id="currentContact.id" ref="phoneInput" isRequired :isCompared="false"
        :value="currentContact.phones" :listData="contactList" @input="setPhones($event)"></phone-input>
      <!-- Email -->
      <email-input :id="currentContact.id" ref="emailInput" :value="currentContact.emails"
        @input="setEmails($event)"></email-input>

      <!-- Address -->
      <address-auto-complete-input :id="currentContact.id" ref="addressInput" :value="currentContact.addresses"
        @input="setAddresses($event)"></address-auto-complete-input>

      <!-- Delete button -->
      <f7-list v-if="!isNew">
        <f7-list-button color="red" @click.native="del">Delete Contact</f7-list-button>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import EmailInput from "@/components/inputs/EmailInput.vue";
import AddressAutoCompleteInput from "@/components/inputs/AddressAutoCompleteInput.vue";

import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import Vue from "vue";
import _ from "lodash";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { PhoneInput, EmailInput, AddressAutoCompleteInput },
  props: {
    isHiddenAddNew: { type: Boolean, default: false },
    companyId: String,
    queryFilters: String
  },

  data: () => {
    return {
      popupOpened: false,
      value: "",
      isNew: false,
      currentContact: {},
      onlyAddNew: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true
    };
  },
  async created() {
    await this.getContactListBys();
  },

  computed: {
    ...mapGetters("common/contact", [
      "contactGroup",
      "hits",
      "nbPages",
      "page",
      "contactSearchText"
    ]),
    ...mapGetters("contact-book/contact", ["contactList"]),

    firstNameErrorMessage() {
      if (!this.v$.currentContact.firstName.$error) return null;
      if (this.v$.currentContact.firstName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    lastNameErrorMessage() {
      if (!this.v$.currentContact.lastName.$error) return null;
      if (this.v$.currentContact.lastName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    ...mapActions("common/contact", [
      "searchContact",
      "loadMoreContact",
      "createContact",
      "resetSearch",
      "setContactSearchText",
      "setQueryFilters"
    ]),
    ...mapActions("contact-book/contact", ["getContactListBys"]),
    ...mapActions("common/company", ["getCompany"]),

    async autoFillContact() {
      const company = this.companyId
        ? await this.getCompany(this.companyId)
        : null;
      if (company && !_.isEmpty(company.contactRefs)) {
        try {
          await this.searchContact({});
          if (!_.isEmpty(this.hits)) {
            this.select(this.hits[0].id);
          } else {
            this.select("");
          }
        } catch (e) {
          this.select("");
        }
      } else {
        this.select("");
      }
    },
    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),
    handleSearch(isFirst = false) {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchContact({})
        .then(() => {
          if (isFirst && this.queryFilters && _.isEmpty(this.hits)) {
            this.setQueryFilters("");
            return this.searchContact({});
          }
        })
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        });
    },

    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreContact({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    set(prop, value) {
      Vue.set(this.currentContact, prop, value);
    },

    open(onlyAddNew = false) {
      if (onlyAddNew) {
        this.isNew = true;
        this.onlyAddNew = true;
        this.popupOpened = true;
      } else {
        this.$f7.preloader.show();
        this.setQueryFilters(this.queryFilters);
        this.handleSearch()
          .then(() => {
            this.popupOpened = true;
          })
          .finally(() => {
            this.$f7.preloader.hide();
          });
      }
    },

    close() {
      this.popupOpened = false;
      this.value = "";
      this.isNew = false;
      this.currentContact = {};
      this.setContactSearchText("");
      this.onlyAddNew = false;

      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.hasData = true;
      this.v$.$reset();
      this.$refs.phoneInput.resetData();
      this.resetSearch();
    },

    onAdd() {
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector(".first-input input").focus();
        }
      });
    },

    onAddCancel() {
      this.isNew = false;
      this.currentContact = {};
      if (this.onlyAddNew) {
        this.popupOpened = false;
        this.onlyAddNew = false;
      }
      this.v$.$reset();
      this.$refs.phoneInput.resetData();
    },

    select(contactId) {
      if (contactId) {
        this.$emit("onSelected", contactId);
        this.close();
      }
    },

    setPhones(val) {
      Vue.set(this.currentContact, "phones", val);
    },

    setEmails(val) {
      Vue.set(this.currentContact, "emails", val);
    },

    setAddresses(val) {
      Vue.set(this.currentContact, "addresses", val);
    },

    create() {
      const self = this;
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.emailInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.emailInput.v$.$invalid ||
        this.v$.$invalid
      )
        return;

      this.$f7.preloader.show();
      return this.createContact(this.currentContact)
        .then(contactId => {
          self.$f7.preloader.hide();
          contactId && self.$emit("onCreated", contactId);
        })
        .finally(() => {
          self.close();
        });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
        if (this.onlyAddNew) {
          this.$el.querySelector(".first-input input").focus();
        }
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentContact: {
        firstName: {
          required
        },

        lastName: {
          required
        }
      }
    };
  }
};
</script>

<style scoped></style>
