<template>
  <f7-block
    strong
    class="no-margin display-flex justify-content-flex-end width-100"
    style="gap: 16px; position: fixed; height: 53px"
  >
    <div
      @click="
        isShowContent = !isShowContent;
        isShowDetail = false;
      "
    >
      <f7-icon
        size="18"
        class="cursor-pointer"
        f7="square_list"
        color="primary"
      ></f7-icon>
      <div v-show="isShowContent" class="dl-item">
        <f7-list class="no-margin">
          <f7-list-item
            v-for="(section, index) in sortedSections"
            :key="index"
            :title="section.sectionName"
            class="section-item"
            link="#"
            :text-color="index === currentSectionIndex ? 'primary' : 'default'"
            @click="$emit('sectionClick', { section, index })"
          ></f7-list-item>
        </f7-list>
      </div>
    </div>
    <div
      @click="
        isShowDetail = !isShowDetail;
        isShowContent = false;
      "
    >
      <f7-icon
        size="18"
        class="cursor-pointer"
        f7="text_badge_checkmark"
        color="primary"
      ></f7-icon>
      <div v-show="isShowDetail" class="dl-item">
        <f7-list class="no-margin">
          <f7-list-item v-show="itemDetail.title" title="Title:"
            ><div style="width: 80%">
              {{ itemDetail.title || "" }}
            </div></f7-list-item
          >

          <f7-list-item title="Status:"
            ><f7-chip
              :text="onlineClass(itemDetail.status).text"
              :color="onlineClass(itemDetail.status).color"
            ></f7-chip
          ></f7-list-item>
        </f7-list>
      </div>
    </div>
    <div @click="$emit('onPreview')">
      <f7-icon
        size="18"
        class="cursor-pointer"
        f7="eye"
        color="primary"
      ></f7-icon>
    </div>
    <div
      v-if="isNew || (itemDetail && itemDetail.status === 'draft')"
      @click="$emit('sendEmail')"
    >
      <f7-icon
        size="18"
        class="cursor-pointer"
        f7="envelope"
        color="primary"
      ></f7-icon>
    </div>
  </f7-block>
</template>

<script>
export default {
  props: {
    isNew: { type: Boolean, default: false },
    itemDetail: { type: Object, default: () => {} },
    sortedSections: { type: Array, default: () => [] },
    currentSectionIndex: { type: Number, default: 0 }
  },

  data: () => {
    return {
      isShowContent: false,
      isShowDetail: false
    };
  },

  methods: {
    onlineClass(status) {
      return status === "sent"
        ? { color: "green", text: "Sent" }
        : status === "viewed"
        ? { color: "pink", text: "Viewed" }
        : { color: "yellow", text: "Draft" };
    },
    clearData() {
      this.isShowContent = false;
      this.isShowDetail = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dl-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
  max-height: 60vh;
  overflow-y: scroll;
  box-shadow: rgba(33, 35, 38, 0.15) 0px 15px 10px -10px;
}
</style>
