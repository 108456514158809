<template>
  <div>
    <f7-list :inset="$device.desktop">
      <f7-row>
        <f7-col width="100" large="50">
          <f7-list>
            <f7-list-item header="Field Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Field Area"
                :value="currentBuilding.fieldArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'fieldArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  setField(
                    'panelLinear',
                    (Math.abs(parseFloat($event.target.value.trim()) || 0)) * 75
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_FASTENERS
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('fieldArea', 'Area', [
                    SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT,
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_FASTENERS
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-input
              readonly
              label="Panel Linear (LF)"
              placeholder="Enter Panel Linear"
              :value="currentBuilding.panelLinear"
              type="number"
              min="0"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-input>

            <f7-list-item header="Ridge Length (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Ridge Length"
                :value="currentBuilding.ridgeLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'ridgeLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  });
                "
              >
              </f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('ridgeLength', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Hip Length (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Hip Length"
                :value="currentBuilding.hipLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'hipLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('hipLength', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Eave (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Eave"
                :value="currentBuilding.eaveLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'eaveLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  setField(
                    'perimeter',
                    (Math.abs(parseFloat($event.target.value.trim()) || 0)) +
                      (currentBuilding.rakeLength || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_FASTENERS
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('eaveLength', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_FASTENERS
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Rake (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Rake"
                :value="currentBuilding.rakeLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'rakeLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  setField(
                    'perimeter',
                    (currentBuilding.eaveLength || 0) +
                      (Math.abs(parseFloat($event.target.value.trim()) || 0))
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_FASTENERS
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('rakeLength', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_FASTENERS
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>
        </f7-col>

        <f7-col width="100" large="50">
          <f7-list>
            <f7-list-input
              disabled
              label="Perimeter (LF)"
              placeholder="Enter Perimeter"
              :value="currentBuilding.perimeter"
              type="number"
              min="0"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-input>

            <f7-list-item header="Side Wall (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Side Wall"
                :value="currentBuilding.sideWall"
                type="number"
                min="0"
                @change="
                  setField(
                    'sideWall',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('sideWall', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Head Wall (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Head Wall"
                :value="currentBuilding.headWall"
                type="number"
                min="0"
                @change="
                  setField(
                    'headWall',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('headWall', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
                    SECTION_TYPE_STANDING_SEAM_ACCESSORIES
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            ><f7-list-item header="Valley Length (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Valley Length"
                :value="currentBuilding.valleyLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'valleyLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );

                  updateProducts({
                    roofType: ROOF_TYPE_STANDING_SEAM,
                    sectionId: SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  });
                "
              ></f7-input>
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('valleyLength', 'Linear', [
                    SECTION_TYPE_STANDING_SEAM_PANELS_TRIM
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
    </f7-list>

    <section-product-table
      :items="tableData(SECTION_TYPE_STANDING_SEAM_PANELS_TRIM)"
      :roofType="ROOF_TYPE_STANDING_SEAM"
      :sectionId="SECTION_TYPE_STANDING_SEAM_PANELS_TRIM"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_STANDING_SEAM,
          SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      :items="tableData(SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT)"
      :roofType="ROOF_TYPE_STANDING_SEAM"
      :sectionId="SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_STANDING_SEAM,
          SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      :items="tableData(SECTION_TYPE_STANDING_SEAM_FASTENERS)"
      :roofType="ROOF_TYPE_STANDING_SEAM"
      :sectionId="SECTION_TYPE_STANDING_SEAM_FASTENERS"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_STANDING_SEAM,
          SECTION_TYPE_STANDING_SEAM_FASTENERS,
          $event
        )
      "
    ></section-product-table>
    <!-- Takeoff select value -->
    <dynamic-single-select
      ref="selectValue"
      search-placeholder="Search in Takeoffs"
      title="Takeoffs from StackCT"
      :options="takeoffListOptions"
      @changed="changeBuildingValue"
    >
    </dynamic-single-select>
  </div>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";
import SectionProductTable from "../tables/SectionProductTable.vue";
import DynamicSingleSelect from "../inputs/DynamicSingleSelect.vue";

import { mapGetters } from "vuex";

import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";
import {
  ROOF_TYPE_STANDING_SEAM,
  SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
  SECTION_TYPE_STANDING_SEAM_FASTENERS,
  SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT,
  SECTION_TYPE_STANDING_SEAM_ACCESSORIES
} from "../../../../utility/const";

export default {
  components: { InputIcon, SectionProductTable, DynamicSingleSelect },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      ROOF_TYPE_STANDING_SEAM,
      SECTION_TYPE_STANDING_SEAM_PANELS_TRIM,
      SECTION_TYPE_STANDING_SEAM_FASTENERS,
      SECTION_TYPE_STANDING_SEAM_UNDERLAYMENT,
      SECTION_TYPE_STANDING_SEAM_ACCESSORIES
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", ["currentBuilding"])
  },

  methods: {
    validate() {},
    init() {},
    changeBuildingValue(property, value, sections = []) {
      if (!property) return;
      this.setField(property, value || 0);
      // update dependency data
      if (property == "eaveLength" || property == "rakeLength") {
        const perimeter =
          (parseFloat(this.currentBuilding.eaveLength) || 0) +
          (parseFloat(this.currentBuilding.rakeLength) || 0);
        this.setField("perimeter", perimeter);
      }
      if (property == "fieldArea") {
        this.setField("panelLinear", (parseFloat(value) || 0) * 75);
      }
      sections.forEach(sectionId => {
        this.updateProducts({
          sectionId,
          roofType: ROOF_TYPE_STANDING_SEAM
        });
      });
    },
    openSelectValue(propery, type, sections) {
      this.$refs.selectValue.openSelectPopup(propery, type, sections);
    }
  }
};
</script>
