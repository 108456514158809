<template>
  <div
    :style="`${actions.length > 0 ? 'margin-top: -28px' : ''}`"
    :class="`text-editor-container hidden-overflow`"
  >
    <div
      :id="`scroll_id_${section.index}`"
      :ref="`scroll_id_${section.index}`"
      :class="
        `${actionButton} dislay-overlay display-flex justify-content-space-between`
      "
    >
      <div class="dislay-overlay display-flex justify-content-start">
        <!-- <f7-button @click="addSection">
          <f7-icon color="primary" f7="plus_circle_fill"></f7-icon> Add Custom
          Section</f7-button
        >

        <f7-button @click="removeSection" v-show="!isDefaultSection">
          <f7-icon color="primary" f7="minus_circle_fill"></f7-icon> Remove This
          Section</f7-button
        > -->
      </div>

      <div class="dislay-overlay display-flex justify-content-end">
        <div
          class="margin-left-half"
          v-for="(action, index) in actions"
          :key="index"
        >
          <f7-button
            style="box-shadow: 0px 0px 15px gray;"
            fill
            @click="handleActionClick(action.actionCode)"
            >{{ action.actionName }}</f7-button
          >
        </div>
      </div>
    </div>
    <!-- :ref="`${section.sectionId}_${section.index}`" class="text-editor-comp"
    style="--f7-text-editor-height: auto; width: 1020px; "
    :value="section.htmlValue" mode="popover" @texteditor:change="changeValue"
    :custom-buttons="customButtons" :buttons="buttons" -->

    <f7-text-editor
      :id="`${section.sectionId}_${section.index}`"
      :ref="`${section.sectionId}_${section.index}`"
      :class="`text-editor-comp ${sectionEditor}`"
      style="--f7-text-editor-height: auto; width: 1020px; "
      :value="section.htmlValue"
      mode="popover"
      @texteditor:change="changeValue"
      :custom-buttons="customButtons"
      :buttons="buttons"
      @click.native="handleClickAction"
      @texteditor:blur="handleBlurAction"
    ></f7-text-editor>
    <select-color-popup ref="selectColor" />
    <f7-popover
      ref="addLinkPopover"
      @popover:closed="sheetClosed"
      class="popover-add-link"
    >
      <div style="display: flex; align-items: center;">
        <f7-input
          ref="linkInput"
          placeholder="Insert Link"
          type="text"
          :value="insertLink"
          class="input-insert-link"
          @input="insertLink = $event.target.value.trim()"
          style="flex-grow: 1; "
        ></f7-input>
        <f7-button @click="saveLink">Save</f7-button>
      </div>
    </f7-popover>
  </div>
</template>

<script>
import { customButtonForTextFormatBar } from "@/utility/common";
import { buttonsForTextFormatBar } from "@/utility/const";
import { mapGetters } from "vuex";
import SelectColorPopup from "@/plugins/proposal/components/popup/SelectColorPopup.vue";
export default {
  props: {
    section: { type: Object, default: () => {} }
  },

  components: { SelectColorPopup },

  data() {
    return {
      value: "",
      insertLink: "",
      linkRange: "",
      currentDoc: null,
      customButtons: customButtonForTextFormatBar(this, true),
      buttons: buttonsForTextFormatBar,
      actionButton: "action-button-hide",
      sectionEditor: "section-editor-unfocus"
    };
  },

  computed: {
    ...mapGetters("common/proposal-template", ["proposalTemplate"]),

    actions() {
      switch (this.proposalTemplate.proposalType) {
        case "commercial":
          return (this.section.actions || []).filter(
            action =>
              action.actionCode === "edit-verbiage" ||
              action.actionCode === "edit-table"
          );

        default:
          return (this.section.actions || []).filter(
            action => action.actionCode === "edit-table"
          );
      }
    },

    isDefaultSection() {
      switch (this.section.sectionId) {
        case "cover-page":
        case "header-section":
        case "footer-section":
          return true;

        default:
          return false;
      }
    }
  },

  methods: {
    isInViewport(parentTop) {
      let element = "";
      element = this.$refs[`${this.section.sectionId}_${this.section.index}`]
        .$el;

      if (element) {
        const rect = element.getBoundingClientRect();
        return (
          rect.top + rect.height / 2 - parentTop >= 0 &&
          rect.top - 99 + rect.height / 2 - parentTop <=
            (window.innerHeight || document.documentElement.clientHeight)
        );
      }

      return false;
    },

    addSection() {
      this.$emit("addSection", this.section.index);
    },

    removeSection() {
      this.$emit("removeSection", this.section.index);
    },
    sheetClosed() {
      this.insertLink = "";
    },
    changeValue(value) {
      const newVal = value.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      this.$emit("editorChange", {
        sectionId: this.section.sectionId,
        value: newVal
      });
    },
    saveLink() {
      const editor = this.$refs[
        `${this.section.sectionId}_${this.section.index}`
      ];
      if (this.linkRange) {
        editor.$el.f7TextEditor.setSelectionRange(this.linkRange);
        document.execCommand("createLink", true, this.insertLink);
      }
      this.linkRange = "";
      this.$refs.addLinkPopover.close();
    },

    moveSectionToView(section) {
      this.$refs[`scroll_id_${section.index}`].scrollIntoView();
    },

    handleClickAction() {
      // console.log("click");
      this.actionButton = "action-button-show";
      this.sectionEditor = "section-editor-focus";
    },

    handleBlurAction() {
      // console.log("blur");
      this.actionButton = "action-button-hide";
      this.sectionEditor = "section-editor-unfocus";
    },

    handleActionClick(actionCode) {
      // console.log(this.proposalTemplate);
      this.$emit("doAction", {
        actionCode,
        sectionId: this.section.sectionId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-editor-comp {
  margin: 0px;
}

.text-editor-comp ::v-deep .text-editor-content {
  padding: 0px;
  overflow: hidden;
}

.text-editor-comp ::v-deep font > div {
  margin: -23px 0px;
}

.section-editor-unfocus {
  // box-shadow: 0 1px 2px var(--f7-theme-color);
  transition: box-shadow 0.2s ease-in-out;
}

.section-editor-focus {
  box-shadow: 0px 0px 15px gray;
  transition: box-shadow 0.2s ease-in-out;
  z-index: 999;
}

.action-button-show {
  top: -5px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.action-button-hide {
  top: -5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.dislay-overlay {
  position: relative;

  // top: -5px;
  // left: 20px;
  width: 100%;
  height: 100%;
}

.popover-add-link {
  width: 300px;
}

.input-insert-link {
  flex-grow: 1;
  padding: 3px 5px;
  border: 1px solid #b7b7b7;
  margin: 8px;
  border-radius: 3px;
}
</style>
