<template>
  <div>
    <f7-row>
      <f7-col v-if="!$device.desktop" width="100" class="display-flex justify-content-flex-end"
        :class="{ summary: !$device.desktop }">
        <f7-button v-if="isEdit" fill color="primary" class="margin-right" @click="addAnotherLine">
          Add another line
        </f7-button>
        <f7-button v-if="isEdit" fill color="primary" @click="selectFromPriceList">
          Select from Price list
        </f7-button>
      </f7-col>
    </f7-row>
    <data-table :headers="isEdit ? productTableHeaders : detailHeader" :items="dataItems" :pageSize="dataItems.length"
      class="label-cell" :class="{
        'dark-header': isEdit,
        'light-header': !isEdit,
        'no-margin-horizontal': $device.desktop
      }">
      <template v-slot:body="{ item, index }">
        <td>
          <div v-if="!isEdit">
            {{ item.productName }}
          </div>
          <input v-else style="width: 100%" ref="input" type="text" placeholder="Type or select from Price list"
            :value="item.productName" @input="onChangeItemName(index, $event.target.value)"
            :class="{ 'error-input': productNameErrorMessage(index) }" />
        </td>
        <td @mouseover="isEdit ? (hoverIndex = index) : ''" @mouseout="hoverIndex = ''" class="numeric-cell"
          :style="isEdit ? 'height: 55px;' : ''">
          <div v-if="!isEdit">{{ item.quantity }}</div>
          <div v-else :class="hoverIndex === index ? 'stepper-raised stepper' : ''"
            class="stepper-qty stepper-small stepper-init margin-top-half"
            :style="isEdit ? 'width: 180px; height: 28px; float: right;' : ''"
          >
            <div
              :class="
                hoverIndex === index ? 'display-inline-block' : 'display-none'
              "
              class="stepper-button-minus"
              @click="minusPlusQty(index, 'minus')"
            ></div>
            <cell-number-input
              :stepper="hoverIndex === index ? 'true' : 'false'"
              placeholder="Quantity"
              :value="item.quantity"
              :allowNegativeNumber="false"
              :fractionDigits="0"
              @done="
                updateProductProperty({
                  propName: 'quantity',
                  value: $event,
                  index
                });
              validate();"
              :isDark="isDark" 
              :error-message="productQuantityErrorMessage(index)">
            </cell-number-input>

            <div :class="hoverIndex === index ? 'display-inline-block' : 'display-none'
              " class="stepper-button-plus" @click="minusPlusQty(index, 'plus')"></div>
          </div>
        </td>

        <td class="">
          <f7-input type="select" :value="item.category" @change="selectCatgory($event.target.value, index)"
            :disabled="!item.isEditCategory" :class="{ 'error-input': categoryErrorMessage(index) }">
            <option v-for="(category, idx) in productCategories" :key="idx" :value="category.name">{{ category.name }}
            </option>
            <required-asterisk slot="label" />
          </f7-input>
        </td>

        <td class="numeric-cell">
          <div v-if="!isEdit">
            {{ item.price | currencyUSD }}
          </div>
          <cell-number-input v-else placeholder="Price" numberType="currency" :value="item.price" @done="
            updateProductProperty({
              propName: 'price',
              value: $event,
              index
            });
          validate();
          " :isDark="isDark" :error-message="priceErrorMessage(index)"
            :allowNegativeNumber="false"></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.amount | currencyUSD }}
        </td>
        <td v-if="!invoiceDetail.syncFromQB" class="text-align-center">
          <f7-checkbox :disabled="!isEdit" :checked="!!item.discount" @change="
            updateProductProperty({
              propName: 'discount',
              value: $event.target.checked,
              index
            })
            "></f7-checkbox>
        </td>
        <td class="text-align-center">
          <f7-checkbox :disabled="!isEdit || invoiceDetail.syncFromQB" :checked="!!item.tax" @change="
            updateProductProperty({
              propName: 'tax',
              value: $event.target.checked,
              index
            })
            "></f7-checkbox>
        </td>
        <td class="numeric-cell" v-if="isEdit">
          <div class="display-flex align-items-center">
            <f7-link class="margin-right-half" icon-f7="doc_on_doc" color="blue" @click="cloneItem(item)"></f7-link>
            <f7-link icon-f7="xmark_circle" color="red" @click="deleteItem(index)"></f7-link>
          </div>
        </td>
      </template>
    </data-table>
    <f7-row>
      <f7-col v-if="$device.desktop" width="100" medium="40" class="display-flex">
        <f7-button v-if="isEdit" fill color="primary" class="margin-right" @click="addAnotherLine">
          Add another line
        </f7-button>
        <f7-button v-if="isEdit" fill color="primary" @click="selectFromPriceList">
          Select from Price list
        </f7-button>
      </f7-col>
      <f7-col width="100" medium="20"> </f7-col>
      <f7-col width="100" medium="40">
        <div :class="{ summary: !$device.desktop }">
          <slot name="summary"></slot>
        </div>
      </f7-col>
    </f7-row>

    <!-- popup -->
    <add-product-from-price-list-popup @onAddProductItems="selectedProduct" ref="selectFromPriceList"
      :existingProductItems="dataItems">
    </add-product-from-price-list-popup>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import AddProductFromPriceListPopup from "../popups/AddProductFromPriceListPopup.vue";
import _ from "lodash";
import Vue from "vue";
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, helpers } from "@vuelidate/validators";
import commonMixins from "../../mixins/common-mixin";
import { mapGetters, mapActions } from "vuex";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  mixins: [commonMixins],
  components: {
    DataTable,
    CellNumberInput,
    AddProductFromPriceListPopup
  },
  props: {
    isEdit: { type: Boolean, default: true },
    invoiceDetail: { type: Object, default: () => { } },
    isDark: { type: Boolean, default: false }
  },
  data() {
    return {
      currentProductItems: [],
      discountSelectAll: false,
      taxSelectAll: false,
      hoverIndex: ""
    };
  },

  computed: {
    ...mapGetters({
      estimateList: "invoices/estimate/estimateList"
    }),

    ...mapGetters("invoices/category", ["objectList"]),

    productCategories() {
      return this.objectList?.length
        ? [...this.objectList.map((item, index) => ({ ...item, key: index }))]
        : [];
    },

    detailHeader() {
      return this.invoiceDetail.syncFromQB
        ? this.detailTableHeaders.filter(r => r.value !== "discount")
        : this.detailTableHeaders;
    },

    productTableHeaders() {
      const self = this;
      return [
        {
          text: "Item Details",
          align: "left",
          sortable: false,
          value: "productName",
          width: "15%"
        },
        {
          text: "Quantity",
          align: "right",
          sortable: false,
          value: "quantity",
          width: "5%"
        },
        {
          text: "Category",
          align: "left",
          sortable: false,
          value: "category",
          width: "10%"
        },
        {
          text: "Price",
          align: "right",
          sortable: false,
          value: "price",
          width: "10%"
        },

        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amount",
          width: "10%"
        },
        {
          text: "Discount",
          align: "center",
          sortable: false,
          value: "discount",
          width: "10%",
          selectAll: !_.isEmpty(self.currentProductItems)
            ? {
              value: self.discountSelectAll,
              onChange: value => {
                self.discountSelectAll = value;
                self.currentProductItems = self.currentProductItems.map(
                  r => ({
                    ...r,
                    discount: value
                  })
                );
                self.changeItems();
              },
              borderColor: "white"
            }
            : false
        },
        {
          text: "Tax",
          align: "center",
          sortable: false,
          value: "tax",
          width: "10%",
          selectAll: !_.isEmpty(self.currentProductItems)
            ? {
              value: self.taxSelectAll,
              onChange: value => {
                self.taxSelectAll = value;
                self.currentProductItems = self.currentProductItems.map(
                  r => ({
                    ...r,
                    tax: value
                  })
                );
                self.changeItems();
              },
              borderColor: "white"
            }
            : false
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "action",
          width: "10%"
        }
      ];
    },

    dataItems() {
      return this.items({
        ...this.invoiceDetail,
        itemDetails: this.currentProductItems
      });
    },

    isServiceType() {
      return (
        (
          this.estimateList.find(r => r.id == this.invoiceDetail.estimateId) ||
          {}
        ).businessCode === "service"
      );
    },

    productQuantityErrorMessage() {
      return index => {
        if (this.v$.currentProductItems.$each.$response.$errors[index].quantity.length === 0) return null;
        return this.v$.currentProductItems.$each.$response.$errors[index].quantity[0].$message || ""
      };
    },

    priceErrorMessage() {
      return index => {
        if (this.v$.currentProductItems.$each.$response.$errors[index].price.length === 0) return null;
        return this.v$.currentProductItems.$each.$response.$errors[index].price[0].$message || ""
      };
    },

    productNameErrorMessage() {
      return index => {
        if (this.v$.currentProductItems.$each.$response.$errors[index].productName.length === 0) return null;
        return this.v$.currentProductItems.$each.$response.$errors[index].productName[0].$message || ''
      };
    },

    categoryErrorMessage() {
      return index => {
        // if (this.v$.currentProductItems.$each.$response.$errors[index].category.length === 0) return null;
        // return this.v$.currentProductItems.$each.$response.$errors[index].category[0].$message || ''
        return null
      };
    }
  },

  watch: {
    invoiceDetail: {
      deep: true,
      immediate: true,
      handler(value) {
        this.currentProductItems = _.cloneDeep(value.itemDetails);
      }
    },
    currentProductItems: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!_.isEmpty(value)) {
          this.discountSelectAll =
            value.findIndex(r => !r.discount) > -1 ? false : true;
          this.taxSelectAll = value.findIndex(r => !r.tax) > -1 ? false : true;
        }
      }
    }
  },

  methods: {
    ...mapActions("invoices/product", ["getProduct"]),
    validate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      }
      return true;
    },
    changeItems() {
      this.$emit("onChangeItems", this.currentProductItems);
    },
    cloneItem(item) {
      this.currentProductItems.push(item);
      this.changeItems();
    },

    deleteItem(index) {
      this.currentProductItems.splice(index, 1);
      this.changeItems();
    },

    addAnotherLine() {
      this.currentProductItems.push({
        productName: "",
        discount: this.discountSelectAll,
        price: 0,
        quantity: 1,
        tax: this.taxSelectAll,
        category: "",
        crossProfitMarginRatio: 0,
        isEditCategory: true
        // isAutoAdded: true
      });
      this.changeItems();
    },

    selectFromPriceList() {
      this.$refs.selectFromPriceList.open();
    },

    onChangeItemName: _.debounce(function (index, value) {
      this.updateProductProperty({
        propName: "productName",
        value: value,
        index
      });
      this.validate();
    }, 500),

    updateProductProperty({ propName, value, index }) {
      let newItem = _.cloneDeep(this.currentProductItems[index]);
      newItem[propName] = value;
      Vue.set(this.currentProductItems, index, newItem);
      this.changeItems();
    },

    /**
     *
     * @param {*} productIds the list of product ids
     * We will do 3 steps:
     *  1. if the new id is not exist => get the real product object and add to the section
     *  2. if the new id is exsit => keep the old product object => do nothing
     *  3. if the old id is not exist on the new list of new id => remove the product from the section
     */
    async selectedProduct(products = []) {
      this.$f7.preloader.show();
      const productIds = products.map(item => item.id);
      //remove all products
      if (_.isEmpty(productIds)) {
        if (this.currentProductItems.length > 0) {
          const productsRemove = _.cloneDeep(this.currentProductItems);
          for (const prod of productsRemove) {
            this.handleRemoveProductItem(prod);
          }
        }
        this.$f7.preloader.hide();
        return;
      }

      // Step #1:
      const existingProductIds = (this.currentProductItems || []).map(
        item => item.id
      );
      const newProductToAdd = products.filter(
        item => !existingProductIds.includes(item.id)
      );

      // caculate price and save product to section
      let items = newProductToAdd.map(r => {
        const miscPercent =
          r.category !== "Labor" ? this.invoiceDetail.miscPercent || 0 : 0;
        let priceWithProfitAndMisc =
          r.price /
          ((100 -
            ((this.invoiceDetail.crossProfitMarginRatio || 0) +
              miscPercent)) /
            100) || 0;
        return {
          id: r.id,
          productName: r.productItem,
          discount: this.discountSelectAll,
          price: this.isServiceType
            ? r.price
            : parseFloat(priceWithProfitAndMisc.toFixed(2)), // if commercial must include profit and mics
          quantity: 1,
          tax: this.taxSelectAll,
          crossProfitMarginRatio: 0,
          category: r.categoryName,
          categoryId: r.categoryId
        };
      });
      this.currentProductItems = this.currentProductItems.concat(items);
      this.changeItems();

      // Step #2: keep existing products => do nothing

      // Step #3: remove the product from the section
      const oldProductIds = existingProductIds.filter(
        id => !productIds.includes(id)
      );
      for (const id of oldProductIds) {
        const orgProduct = await this.getProduct(id);
        if (!_.isEmpty(orgProduct)) {
          this.handleRemoveProductItem(orgProduct);
        }
      }

      this.$f7.preloader.hide();
      return;
    },

    handleRemoveProductItem(product) {
      this.currentProductItems = this.currentProductItems.filter(
        item => item.id !== product.id
      );
      this.changeItems();
    },
    minusPlusQty(index, type) {
      const sectionProducts = _.cloneDeep(this.currentProductItems);

      if (type === "minus") {
        const quantity = (sectionProducts[index].quantity || 0) - 1;
        if (quantity < 0) {
          this.updateProductProperty({
            propName: "quantity",
            value: 0,
            index
          });
        } else {
          this.updateProductProperty({
            propName: "quantity",
            value: quantity,
            index
          });
        }
      } else if (type === "plus") {
        const quantity = (sectionProducts[index].quantity || 0) + 1;
        this.updateProductProperty({
          propName: "quantity",
          value: quantity,
          index
        });
      }
    },

    selectCatgory(categoryName, index) {
      this.$emit("onSelectCategory", { categoryName, index });
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentProductItems: {
        $each: helpers.forEach({
          quantity: {
            required:helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            minValue:helpers.withMessage(VALIDATION_MESSAGE.GREATER_THAN_ZERO, minValue(1))
          },
          price: {
            required:helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            minValue:helpers.withMessage(VALIDATION_MESSAGE.GREATER_THAN_ZERO, minValue(0.01))
          },
          productName: {
            required:helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required)
          },
          category: {
            required:helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required)
          }
        })
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.error-input {
  border-bottom: 1px solid red;
}

.custom-input {
  background-color: var(--f7-page-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 100px;
  padding: 3px;

  &_label {
    width: 100%;
  }

  &_value {
    text-align: right;
    float: right;
  }
}

@media (max-width: 768px) {
  .dark-header::v-deep .card-content {
    min-width: 800px;
  }
}

.dark-header::v-deep .card-content table thead {
  background: #a8a8a8;

  th {
    color: #fff;
  }
}

.light-header::v-deep .card-content table thead {
  background: #a8a8a8;

  th {
    color: white;
    font-weight: 500;
  }
}

.light-header::v-deep .card-content table tbody {
  background: #fff;

  tr {
    border-bottom: 1px solid #e5e7f2;
  }
}

.summary {
  margin-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left));
  margin-right: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-right));
}
</style>
