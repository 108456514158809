import moment from "moment";
import { DEFAULT_STATUS_PRICE_LIST } from "../../utility/const";

export default {
  priceListItemList: state => {
    return state.priceListItemList.filter(r => {
      const compareDate = moment();
      const startDate = r && r.startDate && moment(r.startDate.toDate());
      if (r.endDate) {
        const endDate = moment(r.endDate.toDate());
        return (
          r.status === DEFAULT_STATUS_PRICE_LIST &&
          compareDate.isBetween(startDate, endDate, "days", true)
        );
      } else {
        return (
          r.status === DEFAULT_STATUS_PRICE_LIST &&
          compareDate.isSameOrAfter(startDate, "days")
        );
      }
    });
  },

  priceListItem: state => state.priceListItem
};
