<template>
  <div class="container">
    <div class="bg-no-data">
      <f7-icon
        f7="photo"
        class="icon-no-data"
        style="font-size: 100px"
      ></f7-icon>
    </div>
    <p class="margin-horizontal text-align-center">
      { Option to include photos of the project with a caption }
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  margin: 10px;
  width: 100%;
  width: 345px;
  height: 300px;
  max-width: 400px;
  border: dotted 1px rgb(78, 77, 77);
  background: #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: rgb(104, 101, 101);
}
.icon-no-data {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
