export default {
  proposalTemplateList: [],
  proposalTemplate: {},
  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  order: "desc",
  searchText: ""
};
