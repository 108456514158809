export default {
  userList: state => state.userList,

  activeUsers: (state, _, __, rootGetters) => {
    const groupListByUserId = rootGetters["swimlane/group/groupListByUserId"];
    const cardList = rootGetters["swimlane/card/objectList"];
    const activeUsers = state.userList.filter(user => !user.disabled);
    const userList = [];
    activeUsers.filter(user => {
      const groupList = groupListByUserId(user.uid);
      const isService = groupList.some(
        group =>
          group.menuIDs &&
          group.menuIDs.includes("dashboard-main/service-board")
      );

      if (cardList[0] && cardList[0].businessCode === "service" && isService) {
        userList.push(user);
      }
    });
    return userList.length > 0 ? userList : activeUsers;
  },
  userById: state => id => state.userList.find(user => user.uid === id) || {}
};
