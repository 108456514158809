<template>
  <div>
    <div v-if="$device.desktop" class="display-flex justify-content-flex-end">
      <div
        class="action-button margin-right-half"
        v-for="response in responses"
        :key="response.id"
      >
        <f7-button raised fill @click.native="$emit('doAction', response)">{{
          response.title
        }}</f7-button>
      </div>
    </div>
    <!-- <f7-block v-if="numberOfResponse >= 2 && numberOfResponse <= 3">
        <f7-row tag="p">
          <f7-col tag="span" v-for="response in responses" :key="response.id">
            <f7-button
              raised
              fill
              @click.native="$emit('doAction', response)"
              >{{ response.title }}</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-block v-else>
        <f7-block>
          <f7-row tag="p" v-for="response in responses" :key="response.id">
            <f7-col tag="span" width="25"></f7-col>
            <f7-col tag="span" width="50">
              <f7-button
                raised
                fill
                @click.native="$emit('doAction', response)"
                >{{ response.title }}</f7-button
              > </f7-col
            ><f7-col tag="span" width="25"></f7-col>
          </f7-row> </f7-block
      ></f7-block> -->

    <div v-else>
      <f7-block>
        <f7-row tag="p" v-for="response in responses" :key="response.id">
          <f7-col tag="span">
            <f7-button
              raised
              fill
              @click.native="$emit('doAction', response)"
              >{{ response.title }}</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div style="height: 1px;"></div>
  </div>
</template>

<script>
export default {
  props: {
    responses: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  computed: {
    numberOfResponse() {
      return this.responses.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 120px;
}
</style>
