<template>
  <f7-list class="company-input">
    <f7-list-input link :value="companyValue" :clear-button="true" @input="
        setCompanySearchText($event.target.value);
        onSearch();
        companyValue = $event.target.value;
      "
      @focus="handleFocus" @blur="blurAction" @input:clear="onDeleteCompany" placeholder="Input company name"
      :error-message="errorMessage" error-message-force :tabindex="tabIndex" autocomplete="new-password">
      <div class="list-item-inner-start" slot="inner-start">
        {{ checkCompanyInsurance ? "Insurance " : "" }}Company
        Name<required-asterisk v-if="isRequired"></required-asterisk>
      </div>

      <input-icon slot="media" icon="globe"></input-icon>
    </f7-list-input>

    <f7-list-item v-if="isAddNew">
      <AddNewCompanyButton :companyTypes="companyTypes" :defaultTypesSelected="defaultTypesSelected"
        @onCreated="$emit('onCreated', $event)" />
    </f7-list-item>

    <div class="dropdown-content" @scroll="onScroll" v-show="isShowDropdown">
      <div class="page-content">
        <f7-list class="no-margin">
          <f7-list-group v-for="(group, index) in companyGroup" :key="index">
            <f7-list-item :title="group.key" group-title></f7-list-item>
            <f7-list-item no-chevron link v-for="(company, index) in group.data" :key="index"
              :title="company.companyName" @click.native="select(company.id)"></f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
    </div>
  </f7-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputIcon from "../icons/InputIcon.vue";
import _ from "lodash";
import Vue from "vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import AddNewCompanyButton from "@/components/buttons/AddNewCompanyButton.vue";
import { COMPANY_TYPE_INSURANCE } from "@/utility/const";

export default {
  components: {
    InputIcon,
    AddNewCompanyButton
  },

  props: {
    errorMessage: {
      type: String,
      default: ""
    },

    displayCard: {
      type: Object,
      default: () => { }
    },

    companyName: {
      type: String,
      default: ""
    },

    contactId: { type: String, default: "" },

    queryFilters: String,

    isShowSelectAll: { type: Boolean, default: false },

    items: { type: Array, default: () => [] },

    forBranch: { type: Boolean, default: false },

    companyTypes: { type: Array, default: () => [] },

    autoFocus: { type: Boolean, default: false },

    tabIndex: { type: Number, default: 0 },

    isAddNew: { type: Boolean, default: true },

    defaultTypesSelected: { type: Array, default: () => [] },

    isRequired: { type: Boolean, default: true }
  },

  data() {
    return {
      isNew: false,

      popupOpened: false,

      onlyAddNew: false,

      currentCompany: {
        companyName: "",
        companyTypes: []
      },

      isFocus: false,

      allowInfinite: true,

      hasMoreItems: true,

      hasData: true,

      selectAllChecked: false,

      toggleSelectChecked: false,

      selectedItems: [],

      isChecked: false,

      isNewSelected: false,

      initQueryFilter: "",
      companyValue: ""
    };
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentCompany: {
        companyName: {
          required
        }
      }
    };
  },

  computed: {
    ...mapGetters("common/company", [
      "companyGroup",
      "hits",
      "nbPages",
      "page",
      "companyTypeList",
      "companySearchText"
    ]),

    isShowDropdown() {
      return this.isFocus && this.companyGroup.length > 0;
    },

    checkCompanyInsurance() {
      return this.queryFilters.includes(
        `companyTypes:${COMPANY_TYPE_INSURANCE}`
      );
    }
  },

  mounted() {
    if (this.queryFilters) {
      this.setQueryFilters(this.queryFilters);
    }
  },

  watch: {
    companyName() {
      this.companyValue = this.companyName;
    },

    companySearchText: {
      handler: _.debounce(function () {
        return this.handleSearch();
      }, 300),
      immediate: true
    },

    contactId: {
      handler(newContactId) {
        if (!newContactId) {
          this.handleSearch();
        }
      }
    }
  },

  methods: {
    ...mapActions("common/contact", ["getContact"]),

    ...mapActions("common/company", [
      "searchCompany",
      "loadMoreCompany",
      "createCompany",
      "resetSearch",
      "getCompanyListBys",
      "getCompanyTypeList",
      "setCompanyTypeList",
      "setCompanySearchText",
      "setQueryFilters"
    ]),

    handleCheckboxChange(checked, item) {
      const newItem = { ...item, checked };

      if (checked && !this.selectedItems.includes(item)) {
        this.selectedItems.push(newItem);
      } else {
        this.selectedItems = this.selectedItems.filter(r => r.id !== item.id);
      }
    },

    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),

    handleSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.hasData = true;

      return this.searchCompany({}).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          self.allowInfinite = false;
        } else {
          this.allowInfinite = true;
        }
      });
    },

    handleSelectCompany() {
      const itemsRemove = this.items.filter(
        item => !this.selectedItems.some(r => item.id === r.id)
      );
      if (itemsRemove.length > 0) {
        itemsRemove.forEach(item => {
          this.$emit("onRemoved", item.id);
        });
      }
      this.selectedItems.forEach(item => {
        this.$emit("onSelected", item.id);
      });
      this.close();
    },

    async autoFillCompany() {
      const contact = this.contactId
        ? await this.getContact(this.contactId)
        : null;
      if (contact && !_.isEmpty(contact.companyRefs)) {
        try {
          this.setQueryFilters(this.queryFilters);
          await this.searchCompany({
          });
          if (!_.isEmpty(this.hits)) {
            this.select(this.hits[0].id);
          } else {
            this.select("");
            // if contact have not company, show all company
            this.handleSearch();
          }
        } catch (e) {
          this.select("");
        }
      } else {
        this.select("");
      }
    },

    async open(onlyAddNew = false) {
      if (onlyAddNew) {
        if (this.companyTypes.length !== 1) {
          await this.getCompanyTypeList(this.companyTypes);
        }
        this.isNew = true;
        this.onlyAddNew = true;
        this.popupOpened = true;
        Vue.set(
          this.currentCompany,
          "companyTypes",
          this.companyTypes.length === 1 ? this.companyTypes : []
        );
      } else {
        this.isFocus = true;
        this.onSearch();
      }
    },

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    handleFocus() {
      setTimeout(() => {
        this.isFocus = true;
      }, 500);
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreCompany({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    blurAction() {
      setTimeout(() => {
        if (!this.isNewSelected) this.setCompanySearchText(this.companyName);

        if (!this.companySearchText)
          this.setCompanySearchText(this.companyName);

        if (!this.displayCard?.companyId) this.setCompanySearchText("");

        this.isFocus = false;

        this.isNewSelected = false;
      }, 500);
    },

    select(companyId) {
      if (companyId) {
        this.$emit("onSelected", companyId);
        this.isNewSelected = true;
        this.close();
      }
    },

    close() {
      this.popupOpened = false;
      this.isNew = false;

      this.onlyAddNew = false;

      this.allowInfinite = true;
      this.hasMoreItems = false;
      this.hasData = true;
      this.v$.$reset();
      this.resetSearch();
      this.setCompanyTypeList([]);
    },

    onDeleteCompany(event) {
      this.$emit("onDeleteCompany", event);
    }
  }
};
</script>

<style scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.icon {
  margin-top: 2px;
}

.contact-input {
  position: relative;
}

.dropdown-content {
  position: absolute;
  width: 85%;
  max-height: 400px;
  min-width: 200px;
  max-width: 600px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  top: 50px;
  left: 40px;
  z-index: 999;
  background: black;
}

.page-content {
  padding-top: 0;
}
</style>
