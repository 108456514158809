<template>
  <f7-popup tablet-fullscreen :opened="popupOpened" @popup:closed="doAfterClosed()" @popup:close="closePopup()">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="popupOpened = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ popupTitle }}
        </f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link v-if="isNew" @click.native="onCreate">Next</f7-link> -->
          <!-- <f7-link v-if="isOwner || isAdminGroup" @click.native="showSetting"
            ><f7-icon f7="gear_alt"></f7-icon
          ></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-row no-gap>
        <f7-col :width="15">
          <f7-card v-if="$device.desktop">
            <f7-card-content>
              <f7-list accordion-list>
                <f7-list-item accordion-item accordion-item-opened>
                  <template #title>
                    <div class="title">
                      <f7-icon f7="square_list" color="primary"> </f7-icon>
                      <div class="info-label">
                        Operation
                      </div>
                    </div>
                  </template>
                  <f7-accordion-content>
                    <f7-list class="margin-left-half">
                      <f7-block class="scroll-container">
                        <f7-list-item v-for="(action, index) in residentialActions" :key="index" :title="action.title"
                          class="section-item" link="#" :text-color="action.title === activeSection.sectionName
                            ? 'primary'
                            : 'default'
                            " @click="setActiveAction(action)"></f7-list-item>
                      </f7-block>
                    </f7-list>
                  </f7-accordion-content>
                </f7-list-item>

                <f7-list-item accordion-item accordion-item-opened>
                  <template #title>
                    <div class="title">
                      <f7-icon f7="square_list" color="primary"> </f7-icon>
                      <div class="info-label">
                        Features
                      </div>
                    </div>
                  </template>
                  <f7-accordion-content>
                    <f7-list class="margin-left-half">
                      <f7-block class="scroll-container">
                        <f7-list-item v-for="(section, index) in featuresMenu" :key="index" :title="section.sectionName"
                          class="section-item" link="#" :text-color="section.sectionName === activeSection.sectionName
                            ? 'primary'
                            : 'default'
                            " @click="setActiveSection(section)"></f7-list-item>
                      </f7-block>
                    </f7-list>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
            </f7-card-content>
          </f7-card>
        </f7-col>

        <f7-col :width="[850, 860].includes(activeSection.code) ? 85 : 25">
          <div class="action-container">
            <div class="info-section" :style="action.title === activeSection.sectionName
              ? 'padding-bottom: calc(var(--f7-button-height) + 16px)'
              : ''
              ">
              <!-- doClosePopup for operation, closePopup for feature -->
              <component ref="currentAction" v-bind:is="activeSection.componentName" :popupOpened="popupOpened"
                v-on="$listeners" :title="activeSection.title" isOnePage @doResolve="doResolve"
                @onChangeHashtag="saveChangeContractContent" @doClosePopup="closePopup" @closePopup="closePopup"
                @updateEditCheckList="updateEditCheckList"></component>
            </div>

            <!-- @doResolve="doResolve" -->
            <div class="response-button-contain">
              <response-button v-show="action.title === activeSection.sectionName" ref="responseButton"
                style="right: 32px;" :responses="responseSorted" @doAction="doAction"></response-button>
            </div>
          </div>
        </f7-col>

        <f7-col v-if="
          activeSection.code !== 850 && activeSection.code !== 860 && contract
        " :width="60" ref="wrapContent" class="display-flex flex-direction-column" :style="`height: ${$device.desktop
          ? 'calc(100vh - var(--f7-navbar-height))'
          : 'calc(100vh - var(--f7-navbar-height) - 53px)'
          };
          margin-top: ${$device.desktop ? '0' : '53px'}`
          ">
          <f7-card v-show="$device.desktop">
            <f7-card-header class="display-flex justify-content-space-between">
              <div>Contract Details</div>
              <div class="display-flex">
                <f7-button small outline @click.stop="getPDF">Export PDF</f7-button>
                <f7-button v-if="
                  contract.status === 'draft' &&
                  !contract.projectManagerSigner &&
                  !contract.contractorSigner
                " small outline class="margin-left-half" @click="$refs.editChecklistPopup.open()">Edit
                  Checklist</f7-button>

                <f7-button v-if="contract.status === 'draft' && isShowSendEmailButton" small outline
                  class="margin-left-half" @click="sendBuildContract(contract.id)">Send Email</f7-button>

                <template v-if="
                  !contract.projectManagerSigner || !contract.contractorSigner
                ">
                  <f7-button small fill class="margin-left-half" popover-open=".popover-signer-role">Sign
                    Online</f7-button>
                  <f7-popover class="popover-signer-role">
                    <f7-list>
                      <f7-list-item v-for="(item, index) in signerRoleOptions" :key="index" :title="item.title"
                        :class="{ 'disabled-item-popover': item.disabled }" link popover-close
                        @click="openSignOnline(item.value)"></f7-list-item>
                    </f7-list>
                  </f7-popover>
                </template>
              </div>
            </f7-card-header>
          </f7-card>
          <f7-block class="display-flex justify-content-center scroll-container" ref="scrollViewer">
            <div class="editor-section">
              <div v-for="(section, index) in contract.sections || []" :key="index">
                <div class="page-preview-content-legal" v-html="section.htmlValue"></div>
              </div>
              <div class="tail-section"></div>
            </div>
          </f7-block>
        </f7-col>
      </f7-row>

      <edit-checklist-popup ref="editChecklistPopup" :contract="contract"
        @save="saveAndComposeContract"></edit-checklist-popup>
      <build-contract-signature-popup :contract="contract || {}"
        ref="buildContractSignaturePopup"></build-contract-signature-popup>

      <build-contract-popup @sendEmail="sendEmailBuildContract" ref="buildContractPopup"></build-contract-popup>

      <send-email-popup ref="sendEmailPopup" popup-title="Send Build Contract" templateName="build-contract"
        :projectId="card && (card.id || '')" :attachments="card && (card.attachmentFiles || [])"
        :listEmails="listEmails" @closePopup="closeBuildContractPopup"></send-email-popup>

      <!-- to download -->
      <div style="position: relative;z-index: 0;overflow: hidden">
        <preview-page ref="buildContractPreviewPageHidden" :itemDetail="buildContractDetail"
          :pageHeight="1680"></preview-page>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from "../icon/InputIcon.vue";
import LeadsAction from "../actions/LeadsAction.vue";
import InsuranceInspectionAction from "../actions/InsuranceInspectionAction.vue";
import InsuranceClaimAction from "../actions/InsuranceClaimAction.vue";
import ScheduleProductionAction from "../actions/ScheduleProductionAction.vue";
import ProposalResidentialAction from "../actions/ProposalResidentialAction.vue";
import CompletionAction from "../actions/CompletionAction.vue";
import CollectionAction from "../actions/CollectionAction.vue";

import ProjectPhoto from "../photo/ProjectPhoto.vue";
import SectionEditor from "../sections/SectionEditor.vue";
import ResponseButton from "../button/ResponseButton.vue";
import AttachmentInputOnePage from "../input/AttachmentInputOnePage.vue";
import NotesTab from "../tab/NotesTab.vue";
import EstimateInputOnePage from "../input/EstimateInputOnePage.vue";
import InvoiceSection from "../invoice/InvoiceSection.vue";
import FinanceTab from "../tab/FinanceTab.vue";
import HistoryTab from "../tab/HistoryTab.vue";

import PreviewPage from "../../pages/PreviewPage.vue";
import EditChecklistPopup from "@/components/contract/EditChecklistPopup.vue";

import actionsMixins from "../../mixin/actions";
import onePageContract from "../../mixin/onePageContract";
import { mapActions, mapState, mapGetters } from "vuex";
import _ from "lodash";
import { nowInHumanReadable } from "../../../../utility/date-time-tool";
import BuildContractSignaturePopup from "@/components/contract/BuildContractSignaturePopup.vue";
import { RESIDENTIAL_ACTION_CODES_BLOCK_SEND_EMAIL } from "@/utility/const";
import sendEmail from "../../mixin/sendEmail";
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import BuildContractPopup from "../popup/BuildContractPopup.vue";
import { COLLECTION_OPRATION_CARD } from "@/utility/const";
import { useVuelidate } from '@vuelidate/core'

export default {
  mixins: [actionsMixins, onePageContract, sendEmail],

  components: {
    InputIcon,

    LeadsAction,
    InsuranceInspectionAction,
    InsuranceClaimAction,
    ScheduleProductionAction,
    ProposalResidentialAction,
    CompletionAction,
    CollectionAction,

    ProjectPhoto,
    SectionEditor,
    ResponseButton,
    AttachmentInputOnePage,
    NotesTab,
    EstimateInputOnePage,
    InvoiceSection,
    FinanceTab,
    HistoryTab,

    PreviewPage,
    EditChecklistPopup,
    BuildContractSignaturePopup,

    SendEmailPopup,
    BuildContractPopup
  },

  props: {
    cardNumber: { type: String, default: "new" },
    actionId: { type: String, default: "" }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  data: () => {
    return {
      popupOpened: false,
      isNew: false,
      tempProjectID: "",

      callback: null,

      activeSection: {},
      isMoveCard: false,
      limit: 25,
      order: "desc"
    };
  },

  mounted() {
    if (!_.isEmpty(this.action)) {
      const action = this.residentialActions.find(
        item => item.code === this.action.code
      );

      if (!_.isEmpty(action) && _.isEmpty(this.currentSection)) {
        this.setActiveAction(action);
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", [
      "action",
      "card",
      "isDeleteTempleProject",
      "users",
      "currentSection"
    ]),
    ...mapGetters("dashboard/project", [
      "filterActions",
      "responseSorted",
      "filterAction",
      "actionByCode"
    ]),
    ...mapGetters("setting/app/profile", ["currentUser", "isOwner"]),
    ...mapGetters("setting/app/group", ["isAdminGroup"]),
    ...mapGetters("dashboard/contract", ["contract"]),
    ...mapGetters("dashboard/client", ["listEmails"]),
    ...mapGetters("dashboard/certificate", ["certificateList"]),
    popupTitle() {
      if (!this.card) {
        return "";
      }
      return `${this.card ? this.card.title || "" : ""} - ${this.action ? this.action.title || "" : ""
        }`;
    },

    residentialActions() {
      return this.filterActions("residential");
    },

    featuresMenu() {
      return [
        {
          sectionName: "Photo",
          componentName: "ProjectPhoto",
          title: "Photo"
        },
        {
          sectionName: "Attachment",
          componentName: "AttachmentInputOnePage",
          title: "Attachment"
        },
        {
          sectionName: "Note",
          componentName: "NotesTab"
        },
        {
          sectionName: "Estimate",
          componentName: "EstimateInputOnePage",
          title: "Estimate"
        },
        {
          sectionName: "Invoice",
          componentName: "InvoiceSection",
          title: "Invoice"
        },
        {
          sectionName: "Project Cost",
          componentName: "FinanceTab",
          title: "Project Cost"
        },
        {
          sectionName: "History",
          componentName: "HistoryTab",
          title: "History"
        }
      ];
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    buildContractDetail() {
      return this.contract
        ? {
          id: this.contract.id,
          sections: this.contract.sections,
          title: `${this.contract.contractTitle} - ${nowInHumanReadable()}`
        }
        : {};
    },
    signerRoleOptions() {
      return [
        {
          title: "Project Manager",
          value: "project-manager",
          disabled: !!this.contract.projectManagerSigner
        },
        {
          title: "Contractor",
          value: "contractor",
          disabled: !!this.contract.contractorSigner
        }
      ];
    },

    isShowSendEmailButton() {
      return !RESIDENTIAL_ACTION_CODES_BLOCK_SEND_EMAIL.includes(
        this.activeSection.code
      );
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "setCurrentCard",
      "setCurrentAction",
      "setCurrentResponse",
      "clearCurrentCard",
      "createCard",
      "deleteTempleCard",
      "bindCard",
      "unbindCard",
      "setIsDeleteTempleProject",
      "updateCard",
      "setCurrentSection"
    ]),
    ...mapActions("dashboard/photo", ["resetPhotoData"]),
    ...mapActions("common/notification", ["createNotificationByType"]),
    ...mapActions("dashboard/common", ["setResidentialGridCurrentTab"]),
    ...mapActions("dashboard/contract", ["bindContract", "unbindContract"]),
    ...mapActions("common/contract", ["updateContract"]),
    ...mapActions("dashboard/photo", ["bindProjectPhotoListBy"]),
    ...mapActions("dashboard/album", ["bindProjectPhotoAlbumListBy"]),
    ...mapActions("dashboard/certificate", [
      "getCertificateList",
      "unbindCertificateListBy"
    ]),
    loadPhoto() {
      this.$f7.preloader.show();
      if (this.card && this.card.id) {
        const promises = [];
        promises.push(
          this.bindProjectPhotoListBy({
            prop: "projectId",
            val: this.card.id,
            op: "=="
          })
        );
        promises.push(
          this.bindProjectPhotoAlbumListBy({
            prop: "projectId",
            val: this.card.id,
            op: "=="
          })
        );
        Promise.all(promises).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    doAction(response) {
      this.$refs.currentAction.doAction(response);
    },

    async doResolve(response) {
      // calc the actions history
      this.isMoveCard = true;
      let nextActionsTitle = response.nextActions
        .map(actionId => {
          return (this.filterAction(actionId) || {}).title || "";
        })
        .join(", ")
        .trim();

      let actionsHistory = this.calculateActionHistory(
        this.card,
        this.action,
        response
      );
      // Calculate next actions
      let nextActionIds = this.calculateNextActions(
        this.card,
        this.action,
        response
      );

      let actionsStatus = this.calculateActionStatus(this.card, this.action);

      // Send email notification
      (this.card.assigneeIDs || []).forEach(uid => {
        let receiverInfo = this.userInfo(uid);
        if (receiverInfo) {
          let receiver = `${receiverInfo.displayName} <${receiverInfo.email}>`;
          this.sendMail(this.card.title, receiver, nextActionsTitle);
        }
      });

      // Assign the next action
      this.updateCard({
        id: this.card.id,
        doc: {
          status: response.nextState,
          actions: nextActionIds, //response.nextActions,
          actionsHistory: actionsHistory,
          actionsStatus: actionsStatus
        }
      });

      // Add activity
      await this.createNotificationByType({
        data: {
          assignees: this.card.assigneeIDs,
          project: {
            title: this.card.title,
            id: this.card.id,
            nextTitle: nextActionsTitle,
            type: "project",
            entityName: COLLECTION_OPRATION_CARD
          }
        },
        type: "resolve-action"
      });

      // set active action
      const action = this.residentialActions.find(
        item => item.id === response.nextActions[0]
      );

      if (!_.isEmpty(action)) {
        this.setActiveAction(action);
      }

      // update url
      this.$f7router.updateCurrentUrl(
        `/dashboard/grid/${this.$f7route.route.meta.businessCode}/${this.card.cardNumber}/action/${action.code}`
      );

      this.setCurrentAction(action.id);
      this.setCurrentResponse(action.id);
      this.setResidentialGridCurrentTab(action.code);
    },
    async onGetCertificates() {
      if(!_.isEmpty(this.certificateList)){
        await this.unbindCertificateListBy();
      }
      await this.getCertificateList([
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        }
      ]);
    },
    async closePopup(callback) {
      await this.onGetCertificates();
      this.$f7route.query = {};
      if (callback) {
        this.callback = callback;
      }
      this.popupOpened = false;
      this.$refs.currentAction &&
        this.$refs.currentAction.v$ &&
        this.$refs.currentAction.v$.$reset();
      // if (!_.isEmpty(this.$refs.tabs)) {
      //   this.$refs.tabs.clearActionData();
      // }
      if (this.callback === null) {
        this.setCurrentSection({});
      }
      this.activeSection = {};
      this.isMoveCard = false;
      this.resetPhotoData();
    },

    openPopup() {
      if (!_.isEmpty(this.card)) {
        this.popupOpened = true;
        if (Object.keys(this.currentSection).length != 0) {
          this.activeSection = this.currentSection;
          if (this.activeSection.sectionName === "Project Cost") {
            this.$nextTick(() => {
              this.$refs.currentAction.changeActiveButton();
            });
          }
        }
      }
      this.loadPhoto();
    },

    initCardData(cardNumber, actionId) {
      this.$f7.preloader.show();
      const refs = [];
      const self = this;

      refs.push(this.setCurrentAction(actionId));
      refs.push(this.setCurrentResponse(actionId));
      refs.push(this.bindCard(this.card.id));
      if(this.card.contractId) {
        refs.push(this.bindContract(this.card.contractId));
      }

      return Promise.all(refs).then(() => {
        if (!_.isEmpty(self.card)) {
          if (!_.isEmpty(self.action)) {
            const action = this.residentialActions.find(
              item => item.code === self.action.code
            );

            if (!_.isEmpty(action) && _.isEmpty(this.currentSection)) {
              this.setActiveAction(action);
            }
          }
        }

        if (!_.isEmpty(this.action) && _.isEmpty(this.currentSection)) {
          this.setActiveAction(this.action);
        }

        this.$f7.preloader.hide();
        return;
      });
    },

    onCreate() {
      if (this.$refs.currentAction) {
        this.$refs.currentAction.showPopup();
      }
    },

    doAfterResolve() { },

    doAfterClosed() {
      if (this.callback) {
        this.callback();
        this.callback = null;
      } else {
        this.$emit("navigateToBoard");
      }
      this.unbindCard();
      this.unbindContract();
    },

    showSetting() {
      this.$emit("showSetting");
    },

    setActiveSection(section) {
      this.activeSection = section;
      this.setCurrentSection(section);
    },

    setActiveAction(action) {
      this.activeSection = {
        code: action.code,
        sectionName: action.title,
        componentName: action.componentName
      };
      this.setCurrentSection({});
    },

    saveChangeContractContent() {
      if (this.contract.status !== "draft") return;
      this.$f7.preloader.show();

      const projectManagerSigner = this.contract.projectManagerSigner;
      const contractorSigner = this.contract.contractorSigner;
      // parse HTML content
      const sections = this.parseContractContent();
      // parse signature
      const signatureSection = _.cloneDeep(
        sections.find(item => item.sectionId === "main-page")
      );
      const signatureSectionIndex = sections.findIndex(
        item => item.sectionId === "main-page"
      );
      sections.splice(signatureSectionIndex, 1);

      for (const role of this.signerRoleOptions) {
        switch (role.value) {
          case "project-manager":
            if (!_.isEmpty(projectManagerSigner)) {
              signatureSection.htmlValue = this.parseSignatureHtmlContent(
                signatureSection,
                role.value,
                projectManagerSigner.signatureImageUrl,
                projectManagerSigner.fullNameOfSigner
              );
            }
            break;

          case "contractor":
            if (!_.isEmpty(contractorSigner)) {
              signatureSection.htmlValue = this.parseSignatureHtmlContent(
                signatureSection,
                role.value,
                contractorSigner.signatureImageUrl,
                contractorSigner.fullNameOfSigner
              );
            }
            break;
        }
      }

      sections.unshift(signatureSection);

      const doc = {
        sections
      };
      if (this.card.title != this.contract.project.title) {
        doc.project = {
          ...this.contract.project,
          title: this.card.title
        };
        doc.contractTitle = `Roofing Build Contract - ${this.card.title}`;
      }
      if (this.card.contactId != this.contract.contactId) {
        doc.contactId = this.card.contactId;
      }
      if (this.card.contactName != this.contract.contactName) {
        doc.contactName = this.card.contactName;
      }
      this.updateContract({
        id: this.contract.id,
        doc
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    getPDF() {
      this.$nextTick(() => {
        this.$refs.buildContractPreviewPageHidden.exportPdf();
      });
    },

    saveAndComposeContract(contractChecklist) {
      this.$f7.preloader.show();
      this.updateContract({
        id: this.contract.id,
        doc: {
          contractChecklist
        }
      })
        .then(() => {
          return this.saveChangeContractContent();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    openSignOnline(value) {
      const pairs = this.composeContractData();

      let hasConditionMet = false;

      for (const key in pairs) {
        if (Object.hasOwnProperty.call(pairs, key)) {
          const val = pairs[key];

          // Check if value is empty or contains `_`
          if (val === "" || val.includes("_")) {
            hasConditionMet = true;
            this.$ri.dialog.openWarningDialog({
              title: "You have not filled in all the information",
              content: "Do you want to sign with the missing information?",
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  this.$refs.buildContractSignaturePopup.openPopup(value);
                }
              }
            });
            break;
          }
        }
      }

      // If there are no satisfied cases, open popup sign online
      if (!hasConditionMet) {
        this.$refs.buildContractSignaturePopup.openPopup(value);
      }
    },

    updateEditCheckList(data) {
      let editCheckList = _.cloneDeep(this.contract.contractChecklist) || [];
      let index = (editCheckList[1].list || []).findIndex(
        item => item.id === data.id
      );
      if (index !== -1 && index < editCheckList[1].list.length - 1) {
        editCheckList[1].list[index + 1].value = data.value;
        this.saveAndComposeContract(editCheckList);
      }
    },

    closeBuildContractPopup() {
      this.$refs.buildContractPopup.close();
    },
    sendEmailBuildContract() {
      this.sendBuildContract(this.contract.id);
    }
  },

  watch: {
    "card.actions": {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          !_.isEmpty(val) &&
          !_.isEmpty(this.action) &&
          !this.isMoveCard &&
          !val.includes(this.action.id)
        ) {
          this.popupOpened = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.action-container {
  position: relative;
  height: calc(100vh - var(--f7-navbar-height) - var(--f7-card-margin-vertical) * 2);
}

.info-label {
  font-weight: 600;
  margin-left: 5px;
  margin-top: -2px;
}

.title {
  display: flex;
  row-gap: 15px;
}

.info-section {
  border: 4px solid white;
  box-sizing: border-box;
  box-shadow: var(--f7-card-box-shadow);
  border-radius: var(--f7-card-border-radius);
  margin-top: var(--f7-card-margin-vertical);
  margin-bottom: var(--f7-card-margin-vertical);
  margin-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left));
  margin-right: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-right));
  height: 100%;
  overflow-y: auto;
  border-color: var(--f7-color-bg-neutral);
}

.scroll-container {
  overflow-x: auto;
  margin: 0px 10px 0px 10px;
  direction: ltr;
  scroll-behavior: smooth;
}

.editor-section {
  height: 100%;
  width: 100%;
  direction: ltr;
}

.tail-section {
  height: calc(100vh * 0.65);
}

.response-button-contain {
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 10px;
  right: 10px;
  background: var(--f7-color-bg-neutral);
  border: 4px solid var(--f7-color-bg-neutral);
  border-radius: var(--f7-card-border-radius);
}

.page-preview-content-legal {
  width: 1020px;
  height: 1680px;
  background-color: #fff;
  color: #000;
  margin: auto auto;
  margin-top: 23px;
}

.disabled-item-popover {
  pointer-events: none;
  cursor: default;
  background: #eeeeee;
  color: gray;
}
</style>
