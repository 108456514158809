import Vue from "vue";

export default {
    setTeamList(state, payload) {
        Vue.set(state, "teamList", payload);
    },
    setTeamSubscribe(state, payload) {
        Vue.set(state, "teamSubscribe", payload);
    },
};
