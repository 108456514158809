<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Contacts</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="square_arrow_down_on_square"
          :popover-open="`.popover-menu-import`"
        ></f7-link>
        <f7-popover :class="`popover-menu-import`">
          <f7-list>
            <f7-list-item
              link
              @click="readContacts"
              popover-close
              title="Import from Android"
            ></f7-list-item>
            <f7-list-item
              link
              @click="openUploadFile"
              popover-close
              title="Import from Iphone"
            ></f7-list-item>
          </f7-list>
        </f7-popover>
        <f7-link
          icon-f7="plus"
          style="margin-left: 10px;"
          @click.native="createNewContact()"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search contact"
      :clear-button="true"
      :value="contactSearchText"
      @input="
        setContactSearchText($event.target.value.trim());
        onSearch();
      "
      @searchbar:clear="
        setContactSearchText('');
        onSearch();
      "
      @searchbar:disable="
        setContactSearchText('');
        onSearch();
      "
      class="search-list"
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <div>
        <f7-list v-show="contactGroup.length === 0 && !loadingData">
          <f7-list-item title="Contact not found!"></f7-list-item>
        </f7-list>

        <!-- contact list for contacts page -->
        <f7-list class="search-list searchbar-found contact-list">
          <f7-list-group v-for="(group, index) in contactGroup" :key="index">
            <f7-list-item :title="group.key" group-title></f7-list-item>
            <f7-list-item
              :link="`/contactbook/contact/${item.id}`"
              v-for="item in group.data"
              :key="item.id"
              :title="item.contactName"
              :text-color="
                item.id === (contact || {}).id ? 'primary' : 'default'
              "
              :reload-detail="true"
              swipeout
            ></f7-list-item>
          </f7-list-group>
        </f7-list>
      </div>
    </div>
    <input
      ref="fileInput"
      type="file"
      @change="handleFileUpload"
      class="display-none"
      multiple
      accept=".vcf"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import _ from "lodash";
import Menu from "../../../../components/menu/Menu.vue";
import vCard from "vcf";
import _ from "lodash";

export default {
  components: {
    Menu
  },

  data: () => {
    return {
      popupOpened: false,
      contactId: "",
      loadingData: false,

      value: "",
      isNew: false,
      currentContact: {},
      onlyAddNew: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,

      numberOfRows: 50,
      companyImport: {
        companyName: "",
        phones: []
      },
      contactImportId: ""
    };
  },

  created() {
    this.$f7.preloader.show();
    this.loadingData = true;
    const refs = [];

    this.setContactNumberOfRows(this.numberOfRows);
    if (this.contactSearchText) {
      this.setContactSearchText(this.contactSearchText);
    } else {
      this.setContactSearchText("");
    }
    refs.push(this.handleSearch());
    Promise.all(refs).finally(() => {
      this.$f7.preloader.hide();
      this.loadingData = false;
    });
  },

  computed: {
    ...mapGetters("common/contact", [
      "contactGroup",
      "hits",
      "nbPages",
      "page",
      "contactSearchText"
    ]),

    ...mapGetters("contact-book/contact", ["contact", "companyRefs"])
  },

  methods: {
    ...mapActions("common/contact", [
      "searchContact",
      "loadMoreContact",
      "setContactNumberOfRows",
      "setContactSearchText"
    ]),
    ...mapActions("common/company", ["createNewCompany"]),
    ...mapActions("contact-book/company", ["getCompany"]),
    ...mapActions("contact-book/contact", ["addCompanyRefs", "updateContact"]),
    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),
    handleSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;

      this.$f7.preloader.show();
      return this.searchContact({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.$f7.preloader.hide();
        });
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.$f7.preloader.show();
      this.loadMoreContact({
        page: this.page + 1
      })
        .then(() => {
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            return;
          }

          if (this.page + 1 === this.nbPages) {
            self.hasMoreItems = false;
            return;
          }
          self.allowInfinite = true;
        })
        .finally(() => {
          self.$f7.preloader.hide();
        });
    },

    ...mapActions("contact-book/contact", ["createContact"]),

    createNewContact() {
      this.$f7router.navigate("/contactbook/contact/new-contact");
    },

    async readContacts() {
      const supported = "contacts" in navigator;
      const props = ["name", "email", "tel", "address"];
      const opts = { multiple: true };
      if (supported) {
        try {
          const contacts = await navigator.contacts.select(props, opts);
          this.importContact(contacts);
        } catch (ex) {
          this.showMessage("Error: " + ex.message);
        }
      } else {
        this.showMessage("Your browser or device not supported.");
      }
    },
    async importContact(contacts) {
      this.$f7.preloader.show();
      await new Promise(resolve => {
        let promiseCount = 0;
        if (contacts.length > 0) {
          contacts.forEach(async item => {
            let contact = this.convertContact(item, true);
            await this.createContact(contact);
            promiseCount++;
            if (promiseCount == contacts.length) resolve();
          });
        } else {
          resolve();
        }
      });
      this.$f7.preloader.hide();
      this.showMessage(
        `Imported ${contacts.length} ${
          contacts.length > 1 ? "contacts" : "contact"
        }`
      );

      this.onSearch();
    },

    getCode(index) {
      switch (index) {
        case 0:
          return "main";
        case 1:
          return "home";
        case 2:
          return "work";
        default:
          return "mobile";
      }
    },

    getTitle(index) {
      switch (index) {
        case 0:
          return "Main";
        case 1:
          return "Home";
        case 2:
          return "Work";
        default:
          return "Mobile";
      }
    },

    convertContact(item, isAndroid = false) {
      const name = item.name;
      let fullName = "";
      let firstName = "";
      let lastName = "";

      if (typeof name === "string") {
        fullName = item.name.split(" ");
        firstName = fullName[0];
        lastName = fullName.slice(1).join(" ");
      } else if (typeof name === "object" && typeof name.map === "function") {
        fullName = item.name[0].split(" ");
        firstName = fullName[0];
        lastName = fullName.slice(1).join(" ");
      }

      let emails = item.email.map((value, index) => {
        return {
          code: this.getCode(index),
          title: this.getTitle(index),
          value: value
        };
      });
      let phones = item.tel.map((value, index) => {
        return {
          code: this.getCode(index),
          title: this.getTitle(index),
          value: this.formatPhoneNumber(value)
        };
      });

      let address = {};
      let addresses = [];

      if (isAndroid) {
        addresses = item.address.map((value, index) => {
          const addressLine = value.addressLine
            ? value.addressLine[0] || ""
            : "";
          const parsedObj = addressLine.split(",");
          const postalCodeObj = (parsedObj[2] || "").trim().split(" ") || [
            "",
            ""
          ];
          return {
            code: this.getCode(index),
            title: this.getTitle(index),
            address: (parsedObj[0] || "").trim(),
            city: (parsedObj[1] || "").trim(),
            country: (parsedObj[3] || "").trim(),
            state: postalCodeObj[0].trim(),
            zipcode: postalCodeObj[1].trim()
          };
        });
      } else {
        for (let i = 0; i < item.address.length; i++) {
          let cleanedAddress = item.address[i].replace(/^;;/, "");

          let addressValues = cleanedAddress.split(";");

          address = {
            address: addressValues[0] || "",
            city: addressValues[1] || "",
            state: addressValues[2] || "",
            zipcode: addressValues[3] || "",
            country: addressValues[4] || ""
          };
          addresses.push(address);
        }
      }

      let contact = {
        firstName,
        lastName,
        emails,
        phones,
        addresses
      };
      return contact;
    },
    showMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          position: "center",
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 2000
        })
        .open();
    },
    async handleFileUpload(event) {
      //up load files
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      let fileCount = 0;
      try {
        await Promise.all(
          Array.from(files).map(async file => {
            const reader = new FileReader();
            reader.onload = () => {
              fileCount++;
              const vcfContent = reader.result;
              this.parseVCF(vcfContent, fileCount);
            };
            reader.readAsText(file);
          })
        );
      } catch (error) {
        return { success: false, message: error.message };
      }
    },
    async parseVCF(vcfContent, fileCount) {
      this.$f7.preloader.show();
      const vCards = vCard.parse(vcfContent);

      await Promise.all(
        vCards.map(async vcard => {
          //get info from vcard
          let name = vcard.get("fn");
          let tel = vcard.get("tel");
          let email = vcard.get("email");
          let company = vcard.get("org");
          let address = vcard.get("adr");
          company = company.valueOf() || "";
          company = company.replace(/;$/, "");
          this.companyImport.companyName = company;
          //convert name
          name = name.valueOf() || "";
          tel = Array.isArray(tel) ? tel : tel ? [tel] : [];
          email = Array.isArray(email) ? email : email ? [email] : [];
          address = Array.isArray(address) ? address : address ? [address] : [];
          //convert phone
          const formattedTel = tel.map(phone => {
            if (phone.type[0] === "work") {
              this.companyImport.phones[0] = {
                code: "main",
                title: "Main",
                value: phone.valueOf()
              };
            }
            const formattedPhone = this.formatPhoneNumber(phone.valueOf());
            return formattedPhone;
          });

          //convert email
          const formattedEmail = email.map(email => email.valueOf());

          //convert address
          const formattedAddress = address.map(address => address.valueOf());
          //convert contact
          let contact = this.convertContact({
            name,
            tel: formattedTel,
            email: formattedEmail,
            address: formattedAddress
          });

          //create contact
          await this.createContact(contact).then(id => {
            this.contactImportId = id || "";
            if (this.companyImport.phones.length > 0) {
              this.createNewCompany(this.companyImport).then(async id => {
                const company = await this.getCompany(id);
                if (!_.isEmpty(company)) {
                  await this.addCompanyRefs(company);
                }
                this.updateContact({
                  id: this.contactImportId,
                  doc: {
                    companyRefs: this.companyRefs
                  }
                });
              });
            } else {
              this.showMessage(
                "Cannot import company. Company does not have a phone number!"
              );
            }
          });
        })
      );
      this.$f7.preloader.hide();
      this.showMessage(
        `Imported ${fileCount} ${fileCount > 1 ? "contacts" : "contact"}`
      );
    },
    formatPhoneNumber(phoneNumber) {
      //format number to +(1) 123-456-7890
      const cleaned = phoneNumber.replace(/\D/g, "");
      const areaCode = cleaned.substring(0, 3);
      const firstPart = cleaned.substring(3, 6);
      const secondPart = cleaned.substring(6, 10);

      return `(+1) ${areaCode}-${firstPart}-${secondPart}`;
    },
    openUploadFile() {
      //open upload file
      this.$refs.fileInput.click();
    }
  },
  mounted() {
    if (this.$device.desktop) {
      this.$nextTick(() => {
        this.$el.querySelector(".search-list.searchbar input").focus();
      });
    }
  }
};
</script>

<style scoped></style>
