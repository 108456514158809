import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import * as types from "./types";
export default tenant => {
  const ProposalActions = new FirebaseActions(
    `/system_client/${tenant}/proposal`,
    "proposal",
    {
      modelSchema: {
        id: "string",
        proposalNumber: "string",
        estimateId: "string",
        buildingIDs: "array",
        status: "string",
        propertyId: "string",
        projectId: "string"
      }
    }
  );

  return {
    getProposalListBys: ProposalActions.getDocumentBys,
    updateProposal: ProposalActions.updateDocument,

    getProposalsByProjectId({ dispatch, commit }, projectId) {
      if (!projectId) return;
      return dispatch("getProposalListBys", [
        {
          prop: "projectId",
          val: projectId,
          op: "=="
        },
        { prop: constant.PROP_IS_DELETED, val: true, op: "!=" }
      ]).then(res => {
        commit(types.SET_PROPOSAL_LIST, res);
      });
    },
    deleteProposal({ dispatch }, id) {
      return dispatch("updateProposal", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
    }
  };
};
