<template>
  <f7-page>
    <f7-navbar title="Permission Denied!"> </f7-navbar>
    <f7-block
      strong
      style="display: flex; flex-direction: column;; justify-content: center; align-items: center;"
    >
      <p>Your account do not have permission to access this page.</p>
      <p>Please try to login with another account.</p>
    </f7-block>
  </f7-page>
</template>

<script>
export default {};
</script>
