import group from "./group";
import menu from "./menu";
import user from "./user";
import appConstant from "./app-constant";
import plan from "./plan";
import common from "./common";
import _ from "lodash";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      "app-constant": appConstant(tenant),
      "user-list-page": {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          group: _.cloneDeep(group(tenant)),
          menu: _.cloneDeep(menu),
          user: _.cloneDeep(user)
        }
      },
      "group-list-page": {
        namespaced: true,
        modules: {
          common: _.cloneDeep(common),
          group: _.cloneDeep(group(tenant)),
          menu: _.cloneDeep(menu),
          user: _.cloneDeep(user)
        }
      },
      plan
    }
  };
};
