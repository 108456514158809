import JSZip from "jszip";
import FileSaver from 'file-saver';
import { StorageActions } from "./firebase.service";

// Function to download the album
async function downloadFolder(folderName, files) {
  const zip = new JSZip();

  for (const file of files) {
    if (file.fullPath) {
      const url =
        file.photoUrl !== ""
          ? file.photoUrl
          : await StorageActions.getDownloadURL(file.fullPath);
      const response = await fetch(url);
      zip.file(
        file.fullPath.split("/").slice(-1)[0] || file.name,
        response.blob()
      );
    }
  }
  const content = await zip.generateAsync({ type: "blob" });
  return FileSaver.saveAs(content, `${folderName}.zip`);
}

export { downloadFolder };
