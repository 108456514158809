import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getExcelWorkbook,
  getEstimateSummaryExcelWorkbook
} from "./excel-export";
// import moment from "moment";
function alphabeToNumberic(val) {
  let base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    i,
    j,
    result = 0;

  for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
    result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
  }

  return result;
}
function excelAddressToColumnIndex(address) {
  const colIndex = address.replace(/\d/g, "");
  return alphabeToNumberic(colIndex);
}

function excelStyleToPdfStyle(style) {
  const pdfStyle = {};
  if (style) {
    const { alignment, font } = style;
    if (alignment) {
      if (alignment.horizontal) {
        pdfStyle.halign = alignment.horizontal;
      }
    }
    if (font) {
      if (font.bold) {
        pdfStyle.fontStyle = "bold";
      }
    }
  }
  return pdfStyle;
}

function convertExcelModelToPdfTableBody(worksheetModel) {
  const { rows } = worksheetModel;
  const body = [];
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    const pdfRow = [];
    for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
      const cell = row.cells[cellIndex];

      const lastPdfCell = [...pdfRow].reverse()[0];
      if (lastPdfCell && lastPdfCell.excelCellAddress == cell.master) {
        lastPdfCell.colSpan++;
        continue;
      }

      if (pdfRow.length === 0) {
        const excelColIndex = excelAddressToColumnIndex(cell.address);
        if (excelColIndex !== 1) {
          pdfRow.push({
            colSpan: excelColIndex - 1,
            rowSpan: 1,
            content: ""
          });
        }
      }

      const pdfCell = {
        content: cell.value,
        colSpan: 1,
        rowSpan: 1,
        excelCellAddress: cell.address,
        styles: excelStyleToPdfStyle(cell.style)
      };
      pdfRow.push(pdfCell);
    }
    body.push(pdfRow);
  }

  return body;
}

function exportWorkOrderToPdf({ headers, values, totals, purchaseOrderInfo }) {
  const workbook = getExcelWorkbook({
    headers,
    values,
    totals,
    purchaseOrderInfo
  });
  const worksheet = workbook.getWorksheet("Purchase Order");
  const pdfTableBody = convertExcelModelToPdfTableBody(worksheet.model);
  const doc = new jsPDF({
    orientation: "landscape"
  });
  doc.autoTable({
    body: pdfTableBody,
    theme: "grid"
  });
  addPageNumbersToPdf(doc);

  doc.save(`purchase-order-${new Date().getTime()}.pdf`);
}

function addPageNumbersToPdf(doc) {
  const pageCount = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(`${i}`, pageWidth / 2, pageHeight - 10);
  }
}

function exportEstimateSummaryToPdf({
  headers,
  values,
  summary,
  estimateSalesTax
}) {
  const workbook = getEstimateSummaryExcelWorkbook({
    headers,
    values,
    summary,
    estimateSalesTax
  });
  const worksheet = workbook.getWorksheet("Estimate Summary");
  const pdfTableBody = convertExcelModelToPdfTableBody(worksheet.model);
  const doc = new jsPDF({
    orientation: "landscape"
  });
  doc.autoTable({
    body: pdfTableBody,
    theme: "grid"
  });

  doc.save(`estimate-summary-${new Date().getTime()}.pdf`);
}
export { exportEstimateSummaryToPdf };

export default exportWorkOrderToPdf;
