<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Proposal Template</f7-nav-title>
      <f7-nav-right>
        <!-- <f7-link icon-f7="plus" @click.native="openNewproposalPopup"></f7-link> -->
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search proposal template"
      :clear-button="true"
      class="search-list"
      @input="
        setProposalSearchText($event.target.value.trim());
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:clear="
        setProposalSearchText('');
        onSearch();
      "
      @searchbar:disable="
        setProposalSearchText('');
        onSearch();
      "
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <div>
        <f7-list class="no-margin">
          <f7-list-group media-list>
            <!-- Proposal Template -->
            <f7-list-item
              v-for="item in sortProposalTemplate"
              :title="item.number"
              :key="item.id"
              :reload-detail="true"
              swipeout
              :link="toDetailTemplate(item)"
              :text-color="
                item.number === proposalTemplate.number ? 'primary' : 'default'
              "
            >
              <f7-swipeout-actions right> </f7-swipeout-actions>
              <div slot="footer">
                <span>Last updated: </span>
                <timeago
                  v-if="item.updatedAt"
                  :datetime="convertDateTime(item.updatedAt)"
                  :autoUpdate="1"
                  :converterOptions="{ includeSeconds: true }"
                />
              </div>
              <div slot="subtitle">
                {{ item.name | capitalize }}
              </div>
              <div slot="text"></div>
              <f7-chip
                slot="after"
                :text="templateOnlineClass(item.status).text"
                :color="templateOnlineClass(item.status).color"
              ></f7-chip>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <!-- <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div> -->
        <!-- <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block> -->
      </div>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    Menu
  },

  data: () => {
    return {
      itemCopyproposal: null,
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true
    };
  },

  created() {
    const refs = [];
    if (!this.hasMoreItems) {
      this.$f7.preloader.show();
    }

    if (_.isEmpty(this.proposalTemplateList)) {
      refs.push(this.bindProposalTemplateList());
    }

    Promise.all(refs).finally(() => {
      if (!this.hasMoreItems) {
        this.$f7.preloader.hide();
      }
    });
  },

  computed: {
    ...mapGetters("common/proposal-template", [
      "proposalTemplateList",
      "proposalTemplate",
      "searchText",
      "hits",
      "nbPages",
      "page"
    ]),
    templateOnlineClass() {
      return status => {
        return status === "active"
          ? { color: "green", text: "Active" }
          : { color: "", text: "Inactive" };
      };
    },
    sortProposalTemplate() {
      if (!this.proposalTemplateList.length) return [];
      const newHits = _.cloneDeep(this.proposalTemplateList);
      return newHits.sort((a, b) => {
        if (a.number < b.number) {
          return -1;
        }
        if (a.number > b.number) {
          return 1;
        }
        return 0;
      });
    }
  },

  methods: {
    ...mapActions("common/proposal-template", [
      "bindProposalTemplateList",
      "setProposalSearchText",
      "resetSearch",
      "loadMoreProposal",
      "searchProposal"
    ]),
    onSearch: _.debounce(function() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchProposal({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    }, 500),
    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreProposal({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },

    convertDateTime(createdAt) {
      return moment(new Date(this.dateToValue(createdAt))).toDate();
    },

    toDetailTemplate(item) {
      // console.log(item.id);
      if (!_.isEmpty(item)) {
        return `/proposal-template-v2/${item.id}`;
      } else {
        return "#";
      }
    }
  },
  beforeDestroy() {
    this.setProposalSearchText("");
    this.resetSearch();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
      this.onSearch();
    });
  }
};
</script>

<style scoped>
.font-text {
  font-size: 11px;
}
</style>
