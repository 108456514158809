<template>
  <div v-if="$device.desktop || $device.ipad" class="v-suggestions">
    <input
      ref="input"
      type="text"
      :class="extendedOptions.inputClass"
      v-bind="$attrs"
      v-on:keydown="onKeyDown"
      v-on:blur="onBlur"
      v-on:focus="onFocus"
      v-model="query"
      :autocomplete="Math.random()"
      :placeholder="extendedOptions.placeholder"
    />
    <f7-icon
      f7="chevron_down"
      class="input-icon"
      @click.native="onFocus"
    ></f7-icon>
    <div
      class="suggestions"
      :style="
        `top: ${calcTop}; left: ${calcLeft}; width:${
          isUseWidthDivSuggest ? widthDivSuggestions : suggestionsWidth
        };`
      "
    >
      <ul
        ref="suggestionItems"
        class="items"
        v-show="items.length > 0 && showItems === true"
      >
        <li
          class="item"
          :key="index"
          v-for="(item, index) in items"
          @mousedown.prevent="selectItem(index)"
          v-bind:class="{ 'is-active': index === activeItemIndex }"
        >
          <slot name="item" :item="item">
            {{ item }}
          </slot>
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="v-suggestions">
    <input
      ref="input"
      type="text"
      readonly
      :class="extendedOptions.inputClass"
      v-bind="$attrs"
      v-on:click="onFocus"
      v-model="query"
      :autocomplete="Math.random()"
      :placeholder="extendedOptions.placeholder"
    />
    <f7-icon
      f7="chevron_down"
      class="input-icon"
      @click.native="onFocus"
    ></f7-icon>
    <f7-popup class="demo-popup" :opened="showItems" @popup:closed="onBlur">
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Select Value</f7-nav-title>
          <f7-nav-right> </f7-nav-right>
        </f7-navbar>

        <f7-searchbar
          disable-button-text
          placeholder="Search"
          :clear-button="true"
          :value="query"
          @input="query = $event.target.value"
          @searchbar:disable="query = value"
        ></f7-searchbar>

        <div class="v-suggestions">
          <div class="suggestions_mobile" :style="`width:${suggestionsWidth}`">
            <ul
              ref="suggestionItems"
              class="items"
              v-show="items.length > 0 && showItems === true"
            >
              <li
                class="item"
                :key="index"
                v-for="(item, index) in items"
                @mousedown.prevent="selectItem(index)"
                v-bind:class="{ 'is-active': index === activeItemIndex }"
              >
                <slot name="item" :item="item">
                  {{ item }}
                </slot>
              </li>
            </ul>
          </div>
        </div>
      </f7-page>
    </f7-popup>
  </div>
</template>
<script>
import debounce from "debounce";
import "./Suggestions.css";

export default {
  inheritAttributes: true,
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    onInputChange: {
      type: Function,
      required: true
    },
    onItemSelected: {
      type: Function
    },
    value: {
      type: String,
      required: true
    },
    suggestionsWidth: { type: String, default: "auto" },
    isUseWidthDivSuggest: { type: Boolean, default: false }
  },
  data() {
    const defaultOptions = {
      debounce: 0,
      placeholder: "Search name",
      inputClass: "input"
    };
    const extendedOptions = Object.assign({}, defaultOptions, this.options);
    return {
      extendedOptions,
      query: this.value,
      lastSetQuery: null,
      items: [],
      activeItemIndex: -1,
      showItems: false,
      calcTop: "",
      calcLeft: "",
      widthDivSuggestions: "auto"
    };
  },

  beforeMount() {
    if (this.extendedOptions.debounce !== 0) {
      this.onQueryChanged = debounce(
        this.onQueryChanged,
        this.extendedOptions.debounce
      );
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    query: function(newValue, oldValue) {
      if (newValue === this.lastSetQuery) {
        this.lastSetQuery = null;
        return;
      }
      this.onQueryChanged(newValue);
      this.$emit("input", newValue);
    },
    // eslint-disable-next-line no-unused-vars
    value: function(newValue, oldValue) {
      this.setInputQuery(newValue);
    },
    items() {
      if (this.showItems && (this.$device.desktop || this.$device.ipad)) {
        this.getPositionElement();
      }
    }
  },
  methods: {
    onItemSelectedDefault(item) {
      if (typeof item === "string") {
        this.$emit("input", item);
        this.setInputQuery(item);
        this.showItems = false;
      }
    },
    onFocus() {
      this.$refs.input.select();
      this.onQueryChanged("");
      if (this.isUseWidthDivSuggest) {
        const inputSuggestionsElement = this.$refs.input.getBoundingClientRect();
        this.widthDivSuggestions = `${inputSuggestionsElement.width}px`;
      }

      window.addEventListener("scroll", this.handleScroll, true);

      setTimeout(() => {
        this.$emit("onFocus");
        if (this.$device.desktop || this.$device.ipad) {
          this.getPositionElement();
        }
        this.showItems = true;
      }, 300);
    },
    onBlur() {
      this.showItems = false;
      window.removeEventListener("scroll", this.handleScroll, true);
      this.hideItems();
    },
    hideItems() {
      setTimeout(() => {
        this.showItems = false;
        this.$emit("onBlur");
      }, 300);
    },
    handleScroll() {
      this.getPositionElement();
    },
    showResults() {
      this.showItems = true;
    },
    setInputQuery(value) {
      this.lastSetQuery = value;
      this.query = value;
    },
    onKeyDown(e) {
      switch (e.keyCode) {
        case 40:
          this.highlightItem("down");
          e.preventDefault();
          break;
        case 38:
          this.highlightItem("up");
          e.preventDefault();
          break;
        case 13:
          this.selectItem();
          e.preventDefault();
          break;
        case 27:
          this.showItems = false;
          e.preventDefault();
          break;
        default:
          return true;
      }
    },
    selectItem(index) {
      let item = null;
      if (typeof index === "undefined") {
        if (this.activeItemIndex === -1) {
          return;
        }
        item = this.items[this.activeItemIndex];
      } else {
        item = this.items[index];
      }
      if (!item) {
        return;
      }
      if (this.onItemSelected) {
        this.onItemSelected(item);
      } else {
        this.onItemSelectedDefault(item);
      }
      this.hideItems();
    },
    highlightItem(direction) {
      if (this.items.length === 0) {
        return;
      }
      let selectedIndex = this.items.findIndex((item, index) => {
        return index === this.activeItemIndex;
      });
      if (selectedIndex === -1) {
        // nothing selected
        if (direction === "down") {
          selectedIndex = 0;
        } else {
          selectedIndex = this.items.length - 1;
        }
      } else {
        this.activeIndexItem = 0;
        if (direction === "down") {
          selectedIndex++;
          if (selectedIndex === this.items.length) {
            selectedIndex = 0;
          }
        } else {
          selectedIndex--;
          if (selectedIndex < 0) {
            selectedIndex = this.items.length - 1;
          }
        }
      }
      this.activeItemIndex = selectedIndex;
    },
    setItems(items) {
      this.items = items;
      this.activeItemIndex = -1;
      this.showItems = true;
    },
    getPositionElement() {
      if (!this.$refs.input) return;
      const rect = this.$refs.input.getBoundingClientRect();
      const inputHeight = rect.height;
      const top = rect.top;
      const left = rect.left;
      const suggestionsHeight = this.$refs.suggestionItems.clientHeight;

      if (top + inputHeight + suggestionsHeight > window.innerHeight) {
        this.calcTop = `${top - suggestionsHeight - 10}px`;
      } else {
        this.calcTop = `${top + inputHeight + 10}px`;
      }
      this.calcLeft = `${left}px`;
    },
    onQueryChanged(value) {
      const result = this.onInputChange(value);
      this.items = [];
      if (
        typeof result === "undefined" ||
        typeof result === "boolean" ||
        result === null
      ) {
        return;
      }
      if (result instanceof Array) {
        this.setItems(result);
      } else if (typeof result.then === "function") {
        result.then(items => {
          this.setItems(items);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  cursor: pointer;
}
.v-suggestions {
  position: relative;
}
.input-icon {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
}
</style>
