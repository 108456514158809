import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/service_item_setting`,
    "serviceItemSetting"
  );

  return {
    bindServiceItemSettingList: ActionsTemplate.bindCollection,
    unbindServiceItemSettingList: ActionsTemplate.unbindCollection,
    createServiceItemSetting: ActionsTemplate.createDocument,
    updateServiceItemSetting: ActionsTemplate.updateDocument,
    deleteServiceItemSetting: ActionsTemplate.deleteDocument,
    getServiceItemSettingListBys: ActionsTemplate.getDocumentBys,
    async getServiceItemSettingList({ commit, dispatch }) {
      const serviceItemList = await dispatch("getServiceItemSettingListBys", [
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        }
      ]);
      await commit(types.SET_SERVICE_ITEM_SETTING_LIST, serviceItemList);
      return serviceItemList;
    },
    async createServiceItemSettingList({ commit, dispatch, state }, payload) {
      await dispatch("createServiceItemSetting", payload);

      const newServiceItemSettingList = state.serviceItemSettingList;

      newServiceItemSettingList.push(payload);

      await commit(
        types.SET_SERVICE_ITEM_SETTING_LIST,
        newServiceItemSettingList
      );

      return;
    },
    async deleteServiceItemSettingList({ commit, dispatch, state }, payload) {
      await dispatch("deleteServiceItemSetting", payload);
      const dataServiceItemSettingList = state.serviceItemSettingList;

      const newDataServiceItemSettingList = dataServiceItemSettingList.filter(
        e => e.id !== payload
      );

      await commit(
        types.SET_SERVICE_ITEM_SETTING_LIST,
        newDataServiceItemSettingList
      );

      return;
    }
  };
};
