<template>
  <f7-popup :opened="popupOpened" @popup:closed="doAfterClosed()" @popup:close="
    closePopup();
  deleteTempleProject();
  ">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onClickClose">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ isNew ? "Create New Lead" : popupTitle }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link v-if="isNew" @click.native="onCreate">Next</f7-link>
          <f7-link v-else-if="isOwner || isAdminGroup" @click.native="showSetting"><f7-icon
              f7="gear_alt"></f7-icon></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <card-details-tabs v-if="!isNew && popupOpened" ref="tabs" modelName="project" :popupOpened="popupOpened"
        :afterResolve="doAfterResolve" @doClosePopup="reOpenPopup"></card-details-tabs>

      <pre-qualification-leads-action v-if="isNew && popupOpened" :isNew="isNew" ref="currentAction" @doClosePopup="closePopup"
        @deleteTempleProject="deleteTempleProject"></pre-qualification-leads-action>
    </f7-page>
  </f7-popup>
</template>

<script>
import CardDetailsTabs from "../../components/tabs/CardDetailsTabs.vue";
import PreQualificationLeadsAction from "../actions/PreQualificationLeadsAction.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import _ from "lodash";
import { removeFile } from "../../../../services/firebase.service";
import backActionsMixin from "../../mixin/backActions";
const CODE_RE_OPEN_POPUP = "re-open-popup";
import { useVuelidate } from '@vuelidate/core'

export default {
  mixins: [backActionsMixin],

  components: { CardDetailsTabs, PreQualificationLeadsAction },

  props: {
    cardNumber: { type: String, default: "new" },
    actionId: { type: String, default: "" }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  data: () => {
    return {
      popupOpened: false,
      isNew: false,
      tempProjectID: "",

      callback: null,
      code: "",
      data: {},
      currentCard: {}
    };
  },

  computed: {
    ...mapState("dashboard/project", [
      "action",
      "card",
      "isDeleteTempleProject"
    ]),
    ...mapGetters("dashboard/project", [
      "actionByCode",
      "currActionIdsAfterMoveOrAdd"
    ]),
    ...mapGetters("setting/app/profile", ["currentUser", "isOwner"]),
    ...mapGetters("setting/app/group", ["isAdminGroup"]),

    popupTitle() {
      return `${this.card ? this.card.title : ""} - ${this.action ? this.action.title : ""
        }`;
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "setCurrentCard",
      "setCurrentAction",
      "setCurrentResponse",
      "clearCurrentCard",
      "createCard",
      "deleteTempleCard",
      "bindCard",
      "unbindCard",
      "setIsDeleteTempleProject",
      "getAction"
    ]),
    ...mapActions("dashboard/photo", ["resetPhotoData"]),
    ...mapActions("dashboard/client", [
      "getContact",
      "unbindContact",
      "getCompany",
      "unbindCompany",
      "getProperty",
      "unbindProperty",
      "getInsuranceCompany",
      "unbindInsuranceCompany"
    ]),
    ...mapActions("dashboard/contract", ["bindContract", "unbindContract"]),
    ...mapActions("dashboard/finance", ["resetDataInvoice"]),

    closePopup(callback) {
      this.$f7route.query = {};
      if (callback) {
        this.callback = callback;
      }

      this.popupOpened = false;

      if (!_.isEmpty(this.$refs.tabs)) {
        this.$refs.tabs.clearActionData();
      }

      if (!_.isEmpty(this.$refs.currentAction)) {
        this.$refs.currentAction.v$.$reset();
        this.$refs.currentAction.clearActionData();
      }
      this.resetPhotoData();
      this.resetDataInvoice()
    },

    deleteTempleProject() {
      const self = this;
      if (!this.isDeleteTempleProject) {
        this.setIsDeleteTempleProject(true);
        return;
      }
      if (
        this.isNew === true &&
        (_.isEmpty(this.card) || this.card.status === "draft")
      ) {
        // Always check isDelete equal "true"
        if (!_.isEmpty(this.tempProjectID)) {
          this.$f7.preloader.show();
          const project = this.$refs.currentAction.displayCard;
          if (!_.isEmpty(project) && !_.isEmpty(project.attachmentFiles)) {
            const files = _.cloneDeep(project.attachmentFiles);

            for (const file of files) {
              removeFile(file.fullPath);
            }
          }

          this.deleteTempleCard(this.tempProjectID).then(() => {
            self.tempProjectID = "";
            self.unbindCard();
            self.$f7.preloader.hide();
          });
        }
      }
    },

    openPopup(isNew) {
      this.isNew = isNew;
      const self = this;

      if (this.isNew) {
        this.popupOpened = true;
        this.$f7.preloader.show();
        // TODO: get action ID for new Tenant
        let actionId = this.actionByCode(110).id;
        this.clearCurrentCard();

        this.createCard({
          status: "draft",
          actions: [actionId],
          businessCode: this.$f7route.route.meta.businessCode
        }).then(id => {
          self.tempProjectID = id;
          self.bindCard(id);
          this.$f7.preloader.hide();
        });

        this.setCurrentAction(actionId);
        this.setCurrentResponse(actionId);
      }
      this.$refs.tabs ? this.$refs.tabs.showMainTab() : "";
      this.currentCard = this.card;
    },

    async initClientData() {
      const refs = [];
      if (!_.isEmpty(this.card)) {
        if (!_.isEmpty(this.card.contactId)) {
          refs.push(this.getContact(this.card.contactId));
        }
        if (!_.isEmpty(this.card.companyId)) {
          refs.push(this.getCompany(this.card.companyId));
        }
        if (!_.isEmpty(this.card.insuranceCompanyId)) {
          refs.push(this.getInsuranceCompany(this.card.insuranceCompanyId));
        }
        if (!_.isEmpty(this.card.propertyId)) {
          refs.push(this.getProperty(this.card.propertyId));
        }
      }

      return Promise.all(refs);
    },

    initCardData(cardNumber, actionId) {
      const refs = [];
      refs.push(this.setCurrentAction(actionId));
      refs.push(this.setCurrentResponse(actionId));
      refs.push(this.bindCard(this.card.id));
      this.card.contractId &&
        refs.push(this.bindContract(this.card.contractId));

      this.$f7.preloader.show();
      return Promise.all(refs)
        .then(() => {
          return this.initClientData();
        })
        .then(() => {
          // check data to show popup
          if (!this.isNew && _.isEmpty(this.card)) {
            this.popupOpened = false;
            this.$f7.notification
              .create({
                title: "Not found project",
                closeTimeout: 15000,
                closeButton: true,
                text: `Not found project <b class='color-red'>${cardNumber}</b>. Please choose another project.`,
                closeOnClick: true
              })
              .open();
            return;
          } else {
            this.popupOpened = true;
          }
          this.$f7.preloader.hide();
          return;
        });
    },

    onCreate() {
      if (this.$refs.currentAction) {
        this.$refs.currentAction.showPopup();
      }
    },

    doAfterResolve() { },

    doAfterClosed() {
      this.unbindCard();

      this.unbindContact();
      this.unbindCompany();
      this.unbindProperty();
      this.unbindInsuranceCompany();
      this.unbindContract();

      if (this.callback) {
        this.callback();
        this.callback = null;
      } else {
        this.$emit("navigateToBoard");
      }
    },
    reOpenPopup(callback) {
      if (callback) {
        this.closePopup(callback);
      } else {
        let action = {};
        this.getAction(this.currActionIdsAfterMoveOrAdd[0])
          .then(res => {
            action = res;
          })
          .finally(() => {
            this.data.actionId = action.id || "";
            this.data.actionCode = action.code || "";
            this.data.cardNumber = this.currentCard.cardNumber || "";
            this.data.boardName = action.boards[0] || "sales";
            this.code = CODE_RE_OPEN_POPUP;
            this.$emit("reOpenPopup", { code: this.code, data: this.data });
          });
      }
    },
    onClickClose() {
      this.popupOpened = false;
      if (this.currentRouteIsScheduling) {
        this.handleBackToScheduling();
      }
    },

    showSetting() {
      this.$emit("showSetting");
    }
  },

  watch: {
    "card.actions": {
      deep: true,
      immediate: true,
      handler(val) {
        if (
          !_.isEmpty(val) &&
          !_.isEmpty(this.action) &&
          !val.includes(this.action.id)
        ) {
          this.popupOpened = false;
        }
      }
    }
  }
};
</script>

<style scoped></style>
