import Vue from "vue";
import VueI18n from "vue-i18n";
import enLanguage from "./en.json";
import espLanguage from "./espanol.json";
import { getActiveLanguage } from "./translation";

Vue.use(VueI18n);

const messages = {
  en: enLanguage,
  esp: espLanguage
};
const lang = getActiveLanguage();
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: "en",
  messages
});

export default i18n;
