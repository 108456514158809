import Vue from "vue";
import * as types from "./types";
import _ from "lodash";

export default {
  [types.SET_COMPANY_LIST](state, payload) {
    Vue.set(state, "companyList", payload);
  },

  [types.SET_COMPANY](state, payload) {
    Vue.set(state, "company", payload);
  },

  //* contact refs
  [types.SET_CONTACT_REFS](state, payload) {
    Vue.set(state, "contactRefs", payload);
  },

  [types.ADD_CONTACT_REFS](state, payload) {
    const foundItem = state.contactRefs.find(item => item.id === payload.id);

    // Not found
    if (_.isEmpty(foundItem)) {
      state.contactRefs.push(payload);
    }
  },

  [types.REMOVE_CONTACT_REFS](state, payload) {
    state.contactRefs = state.contactRefs.filter(item => item.id !== payload);
  },

  //* branch refs
  [types.SET_BRANCH_REFS](state, payload) {
    Vue.set(state, "branchRefs", payload);
  },

  [types.ADD_BRANCH_REFS](state, payload) {
    const foundItem = state.branchRefs.find(item => item.id === payload.id);

    if (_.isEmpty(foundItem)) {
      state.branchRefs.push(payload);
    }
  },

  [types.REMOVE_BRANCH_REFS](state, payload) {
    state.branchRefs = state.branchRefs.filter(item => item.id !== payload);
  },

  //* property refs
  [types.SET_PROPERTY_REFS](state, payload) {
    Vue.set(state, "propertyRefs", payload);
  },

  [types.ADD_PROPERTY_REFS](state, payload) {
    const foundItem = state.propertyRefs.find(item => item.id === payload.id);

    if (_.isEmpty(foundItem)) {
      state.propertyRefs.push(payload);
    }
  },

  [types.REMOVE_PROPERTY_REFS](state, payload) {
    state.propertyRefs = state.propertyRefs.filter(item => item.id !== payload);
  }
};
