import _ from "lodash";

export default {
  users: state => {
    const result = [];
    state.users.forEach(user => {
      const email =
        (user.customClaims || {}).loginMethod === "email_by_phone"
          ? user.customClaims.displayEmail || ""
          : user.email;
      result.push({ ...user, email });
    });
    return result;
  },

  user: state => {
    if (_.isEmpty(state.user)) {
      return state.user;
    }
    const email =
      (state.user.customClaims || {}).loginMethod === "email_by_phone"
        ? state.user.customClaims.displayEmail || ""
        : state.user.email;
    return { ...state.user, email };
  },

  qbUsers: state => state.qbUsers
};
