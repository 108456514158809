<template>
  <div class="popup-scroll-bar">
    <!-- segmented buttons -->
    <f7-block>
      <f7-segmented strong tag="p">
        <f7-button
          :active="activeButton === 'action-required'"
          @click.native="activeButton = 'action-required'"
          >Action Required</f7-button
        >
        <f7-button
          :active="activeButton === 'action-history'"
          @click.native="
            () => {
              activeButton = 'action-history';
              $f7.tooltip.create({
                targetEl: `.icon-info-required-system-letter`,
                text: `System Letter is empty!`
              });
            }
          "
          >Action History</f7-button
        >
      </f7-segmented>
    </f7-block>

    <div v-show="activeButton === 'action-required'">
      <f7-block-title class="popup-card-title">
        Project name: {{ this.card ? this.card.title || "" : "" }}
      </f7-block-title>
      <component
        ref="currentAction"
        v-bind:is="action ? action.componentName : ''"
        @doResolve="doResolve"
        :popupOpened="popupOpened"
        v-on="$listeners"
        @onChangeHashtag="saveChangeContractContent"
        @updateEditCheckList="updateEditCheckList"
      ></component>
      <response-button
        class="response-button-absolute"
        ref="responseButton"
        :responses="responseSorted"
        @doAction="doAction"
      ></response-button>
    </div>
    <!-- ----------------------------------------- -->

    <div v-show="activeButton === 'action-history'">
      <f7-list media-list>
        <f7-list-item
          :class="showSystemLetterWarning(item) ? 'estimate-action' : ''"
          v-for="(item, index) in history"
          :key="index"
          :title="item.nextAction"
          :after="toLocaleString(item.resolvedAt)"
          :subtitle="'Resolved by ' + item.resolvedBy"
          :text="
            item.previousAction ? 'Previous action: ' + item.previousAction : ''
          "
        >
          <f7-icon
            v-show="showSystemLetterWarning(item)"
            text-color="red"
            slot="content"
            f7="info_circle"
            class="padding-right-half icon-info-required-system-letter"
          ></f7-icon>
        </f7-list-item>
      </f7-list>
    </div>
  </div>
</template>

<script>
import ResponseButton from "../button/ResponseButton.vue";

import PreQualificationLeadsAction from "../actions/PreQualificationLeadsAction.vue";
import ContactLeadAction from "../actions/ContactLeadAction.vue";
import SiteVisitAction from "../actions/SiteVisitAction.vue";
import BudgetMeetingAction from "../actions/BudgetMeetingAction.vue";
import EstimateAction from "../actions/EstimateAction.vue";
import ProposalAction from "../actions/ProposalAction.vue";
import PresentationAction from "../actions/PresentationAction.vue";
import FollowUpAction from "../actions/FollowUpAction.vue";
import ClosingAction from "../actions/ClosingAction.vue";

import PreInstallationAction from "../actions/PreInstallationAction.vue";
import SubmittalsAction from "../actions/SubmittalsAction.vue";
import ScheduleValueAction from "../actions/ScheduleValueAction.vue";
import UpfrontPaymentAction from "../actions/UpfrontPaymentAction.vue";
import PreConstructionMettingAction from "../actions/PreConstructionMettingAction.vue";

import ConstructionAction from "../actions/ConstructionAction.vue";

// import MaterialOrderPreparationAction from "../actions/MaterialOrderPreparationAction.vue";
// import ExecutionAction from "../actions/ExecutionAction.vue";
// import QualityAssuranceQualityControlAction from "../actions/QualityAssuranceQualityControlAction.vue";
// import InvoicingAction from "../actions/InvoicingAction.vue";

import WarrantyInspectionAction from "../actions/WarrantyInspectionAction.vue";
import PunchlistAction from "../actions/PunchlistAction.vue";
import LienCloseProjectAction from "../actions/LienCloseProjectAction.vue";
import RetainageAction from "../actions/RetainageAction.vue";
import CloseOutFolderAction from "../actions/CloseOutFolderAction.vue";

import LeadsAction from "../actions/LeadsAction.vue";
import InsuranceInspectionAction from "../actions/InsuranceInspectionAction.vue";
import InsuranceClaimAction from "../actions/InsuranceClaimAction.vue";
import ScheduleProductionAction from "../actions/ScheduleProductionAction.vue";
import ProposalResidentialAction from "../actions/ProposalResidentialAction.vue";
import CompletionAction from "../actions/CompletionAction.vue";
import CollectionAction from "../actions/CollectionAction.vue";
// import MaterialOrderAction from "../actions/MaterialOrderAction.vue";
// import PreparationAction from "../actions/PreparationAction.vue";
// import ProductionAction from "../actions/ProductionAction.vue";
// import QaqcAction from "../actions/QaqcAction.vue";

// import axiosService from "@/services/axios.service";
import actionsMixins from "../../mixin/actions";
import onePageContract from "../../mixin/onePageContract";
import { mapState, mapActions, mapGetters } from "vuex";
import _ from "lodash";
import {
  COLLECTION_OPRATION_CARD,
  COLLECTION_MOVE_ARCHIVE_JOB
} from "@/utility/const";
export default {
  components: {
    ResponseButton,

    // Actions components
    PreQualificationLeadsAction,
    ContactLeadAction,
    SiteVisitAction,
    BudgetMeetingAction,
    EstimateAction,
    ProposalAction,
    PresentationAction,
    FollowUpAction,
    ClosingAction,
    // ------
    PreInstallationAction,
    SubmittalsAction,
    ScheduleValueAction,
    UpfrontPaymentAction,
    PreConstructionMettingAction,

    ConstructionAction,

    // -------
    // MaterialOrderPreparationAction,
    // ExecutionAction,
    // QualityAssuranceQualityControlAction,
    // InvoicingAction,
    // MaterialOrderAction,
    // PreparationAction,
    // ProductionAction,
    // QaqcAction,
    // -------
    WarrantyInspectionAction,
    PunchlistAction,
    LienCloseProjectAction,
    RetainageAction,
    CloseOutFolderAction,

    LeadsAction,
    InsuranceInspectionAction,
    InsuranceClaimAction,
    ScheduleProductionAction,
    ProposalResidentialAction,
    CompletionAction,
    CollectionAction
  },

  data: () => {
    return {
      activeButton: "action-required",
      limit: 25,
      order: "desc"
    };
  },

  props: { afterResolve: Function, popupOpened: Boolean },

  mixins: [actionsMixins, onePageContract],

  computed: {
    ...mapState("dashboard/project", ["users", "card", "action"]),
    ...mapGetters("dashboard/project", ["filterAction", "responseSorted"]),

    ...mapGetters("dashboard/client", { currentProperty: "property" }),
    ...mapGetters("dashboard/contract", ["contract"]),

    showSystemLetterWarning() {
      return item => {
        return (
          this.card.requiredSystemLetter === true &&
          this.requiredSystemLetterAttachmentFiles.length === 0 &&
          item.nextAction === "Estimate"
        );
      };
    },

    requiredSystemLetterAttachmentFiles() {
      //requiredSystemLetter
      return (this.card.attachmentFiles || []).filter(
        r => r.attachmentType === "requiredSystemLetter"
      );
    },

    userInfo() {
      return uid =>
        this.users.find(user => {
          return user.uid === uid;
        });
    },

    history() {
      return this.card
        ? this.card.actionsHistory
          ? _.cloneDeep(this.card.actionsHistory).reverse()
          : {}
        : [];
    }
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard", "clearCurrentCard"]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    ...mapActions("common/contract", ["updateContract"]),

    doAction(response) {
      this.$refs.currentAction.doAction(response);
    },

    async doResolve(response) {
      // calc the actions history
      let nextActionsTitle = response.nextActions
        .map(actionId => {
          return (this.filterAction(actionId) || {}).title || "";
        })
        .join(", ")
        .trim();

      let actionsHistory = this.calculateActionHistory(
        this.card,
        this.action,
        response
      );
      // Calculate next actions
      let nextActionIds = this.calculateNextActions(
        this.card,
        this.action,
        response
      );

      let actionsStatus = this.calculateActionStatus(this.card, this.action);

      // Assign the next action
      this.updateCard({
        id: this.card.id,
        doc: {
          status: response.nextState,
          actions: nextActionIds, //response.nextActions,
          actionsHistory: actionsHistory,
          actionsStatus: actionsStatus
        }
      });

      // Send email notification
      (this.card.assigneeIDs || []).forEach(uid => {
        let receiverInfo = this.userInfo(uid);
        if (receiverInfo) {
          let receiver = `${receiverInfo.displayName} <${receiverInfo.email}>`;
          this.sendMail(this.card.title, receiver, nextActionsTitle);
        }
      });

      // Add activity
      if (
        response.code === "un-interested" ||
        response.code === "unsold" ||
        response.code === "put-a-lien" ||
        response.code === "close-project" ||
        response.code === "archive-job" ||
        response.code === "payment-received"
      ) {
        this.createNotificationByType({
          data: {
            assignees: this.card.assigneeIDs,
            project: {
              title: this.card.title,
              id: this.card.id,
              to: "archive",
              type: "project",
              subTitle: this.action.title,
              entityName: COLLECTION_MOVE_ARCHIVE_JOB
            }
          },
          type: "move-project-sub"
        });
      } else {
        this.createNotificationByType({
          data: {
            assignees: this.card.assigneeIDs,
            project: {
              title: this.card.title,
              id: this.card.id,
              nextTitle: nextActionsTitle,
              type: "project",
              entityName: COLLECTION_OPRATION_CARD
            }
          },
          type: "resolve-action"
        });
      }

      // Close the popup
      this.afterResolve();
      this.clearCurrentCard();
    },

    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },

    saveChangeContractContent() {
      if (_.isEmpty(this.card)) {
        return;
      }
      if (this.contract.status !== "draft") return;
      this.$f7.preloader.show();
      const sections = this.parseContractContent();
      const doc = {
        sections
      };
      if (this.card.title != this.contract.project.title) {
        doc.project = {
          ...this.contract.project,
          title: this.card.title
        };
        doc.contractTitle = `Roofing Build Contract - ${this.card.title}`;
      }
      if (this.card.contactId != this.contract.contactId) {
        doc.contactId = this.card.contactId;
      }
      if (this.card.contactName != this.contract.contactName) {
        doc.contactName = this.card.contactName;
      }
      this.updateContract({
        id: this.contract.id,
        doc
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    showMainContent() {
      this.activeButton = "action-required";
    },

    /**
     * Init data
     */
    init() {},

    /**
     * Clear data
     */
    clearActionData() {
      if (!_.isEmpty(this.$refs.currentAction)) {
        this.$refs.currentAction.clearData();
      }
    },

    saveAndComposeContract(contractChecklist) {
      this.$f7.preloader.show();
      this.updateContract({
        id: this.contract.id,
        doc: {
          contractChecklist
        }
      })
        .then(() => {
          return this.saveChangeContractContent();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    updateEditCheckList(data) {
      let editCheckList = _.cloneDeep(this.contract.contractChecklist) || [];
      let index = (editCheckList[1].list || []).findIndex(
        item => item.id === data.id
      );
      if (index !== -1 && index < editCheckList[1].list.length - 1) {
        editCheckList[1].list[index + 1].value = data.value;
        this.saveAndComposeContract(editCheckList);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-bar {
  height: calc(100%);
}

.popup-card-title {
  font-size: 16px;
}

.estimate-action {
  color: var(--f7-theme-color);
}
</style>
