import _ from "lodash";
const labor = "labor";
const material = "material";
export default {
  scheduleSettingList: state => state.scheduleSettingList,
  mobilization: state => state.mobilization,
  tax: state => state.tax,
  scheduleInfomation: (state, getters, rootStates) => {
    let temp = _.cloneDeep(state.scheduleInfomation) || [];
    const scheduleColunms =
      (rootStates.schedule.scheduleOfValue.schedule || {}).scheduleColunms ||
      [];
    if (temp.length > 1) temp.splice(1, 1);
    const data = temp.map((x, index) => {
      if (index === 0) {
        let totals = 0;
        Object.keys(x).forEach(y => {
          if (["id", "name", "totals"].includes(y)) {
            return;
          }
          if (!scheduleColunms.includes(y)) return;
          totals += x[y];
        });
        x.totals = totals;
        return x;
      }
      return x;
    });
    const clone = JSON.parse(JSON.stringify(data));
    clone.shift();
    let array = [];
    if (data.length) {
      array = [data[0], getters.scheduleInfomationTotals, ...clone];
    }
    return array;
  },

  generateSchedule: (state, getters, rootStates) => {
    const proposed = getters.scheduleInfomation[0] || {};
    const totals = getters.scheduleInfomation[1];
    const items = getters.scheduleInfomation.slice(2) || [];
    const scheduleColunms =
      (rootStates.schedule.scheduleOfValue.schedule || {}).scheduleColunms ||
      [];
    let mobilizationValue = parseFloat(state.mobilization);
    if (isNaN(mobilizationValue)) {
      mobilizationValue = 0;
    }
    const data = items.map(x => {
      const itemGenerate = {};
      (Object.keys(x) || []).forEach(key => {
        if (["id", "action"].includes(key)) return;
        if (key === "name") {
          itemGenerate[key] = x.name;
        } else {
          let countMobilization = (100 - mobilizationValue) / 100;
          itemGenerate[key] = parseFloat(
            ((proposed[key] * countMobilization) / totals[key]) * x[key] || 0
          ).toFixed(2);
        }
      });
      return itemGenerate || {};
    });
    let results = data.map(x => {
      let result = 0;
      Object.keys(x).forEach(y => {
        if (["id", "name"].includes(y)) {
          return;
        }
        if (!scheduleColunms.includes(y)) return;
        result += +x[y];
      });
      x.totals = result;
      return x;
    });
    return results;
  },
  generateScheduleByCostType: (state, getters, rootStates) => {
    const proposed = getters.scheduleInfomation[0] || {};
    const totals = getters.scheduleInfomation[1];
    const percentage = _.cloneDeep(state.scheduleInfomation[1]);
    const scheduleColunms =
      (rootStates.schedule.scheduleOfValue.schedule || {}).scheduleColunms ||
      [];
    let scheduleSplitedColunms = [];
    scheduleColunms.forEach(r => {
      scheduleSplitedColunms.push(r + labor);
      scheduleSplitedColunms.push(r + material);
    });
    const items = getters.scheduleInfomation.slice(2) || [];
    items.unshift(percentage);
    let mobilizationValue = parseFloat(state.mobilization);
    let taxValue = parseFloat(state.tax);
    if (isNaN(mobilizationValue)) {
      mobilizationValue = 0;
    }
    if (isNaN(taxValue)) {
      taxValue = 0;
    }

    const data = items.map(x => {
      if (x.name === "Percentage") {
        return x;
      }

      // let total = 0;
      let countMobilization = (100 - mobilizationValue) / 100;
      // Object.keys(x).forEach(y => {
      //   if (["id", "name", "isInputPercent", "totals"].includes(y)) {
      //     return;
      //   }
      //   if (!scheduleColunms.includes(y)) return;
      //   let value = ((proposed[y] * countMobilization) / totals[y]) * x[y] || 0;
      //   total += value;
      // });
      const itemGenerate = {};
      (Object.keys(x) || []).forEach(key => {
        let value =
          ((proposed[key] * countMobilization) / totals[key]) * x[key] || 0;
        if (["action"].includes(key)) return;
        if (key === "name") {
          itemGenerate[key] = x.name;
        } else if (["id", "isInputPercent"].includes(key)) {
          itemGenerate[key] = x[key];
        } else if (key.includes(labor) || key.includes(material)) {
          let percent = percentage[key] / 100 || 0;
          const result = parseFloat(percent * value).toFixed(2);
          itemGenerate[key] = result;
          if (key.includes(material)) {
            itemGenerate[key + material + "tax"] = (
              (result * taxValue) /
              100
            ).toFixed(2);
          }
        } else {
          let percentMaterial = percentage[key + material] / 100 || 0;
          const materialValue = parseFloat(percentMaterial * value).toFixed(2);
          const materialTax = ((materialValue * taxValue) / 100).toFixed(2);
          itemGenerate[key + material] = (materialValue - materialTax).toFixed(
            2
          );
          itemGenerate[key + material + "tax"] = materialTax;
          let percentLabor = percentage[key + labor] / 100 || 0;
          itemGenerate[key + labor] = parseFloat(percentLabor * value).toFixed(
            2
          );
        }
      });
      // console.log(itemGenerate, 8888)
      return itemGenerate || {};
    });
    let results = data.map(x => {
      if (x.name == "Percentage") return x;
      let result = 0;
      Object.keys(x).forEach(y => {
        if (["id", "name", "isInputPercent", "totals"].includes(y)) {
          return;
        }
        if (!scheduleSplitedColunms.includes(y)) return;
        result += +x[y];
      });
      x.totals = result;
      return x;
    });
    // console.log(results, 99999)
    return results;
  },

  scheduleInfomationTotals: state => {
    let rowResuts = { name: "Total of area (or LF)" };
    const items = state.scheduleInfomation.slice(
      1,
      state.scheduleInfomation.length
    );
    (state.scheduleSettingList || []).forEach(z => {
      rowResuts[z.id] = items.reduce(
        (result, x) => (result += x[z.id] || 0),
        0
      );
    });
    return rowResuts;
  }
};
