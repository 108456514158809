export default {
  // eslint-disable-next-line no-unused-vars
  invoiceList: (state, _, __, _rootGetters) => {
    return state.invoiceList;
  },
  invoice: state => {
    return state.invoice;
  },
  invoiceByNumber: state => invoiceByNumber => {
    return state.invoiceList.find(r => r.invoiceNumber === invoiceByNumber);
  }
};
