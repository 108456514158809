export default {
  props: {
    isOnePage: { type: Boolean, default: false }
  },

  methods: {
    // Implement resolve action
    async resolve(callback) {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      if (!this.isOnePage) {
        this.$emit("doClosePopup", callback);
      }
      this.$f7.preloader.hide();
    }
  }
};
