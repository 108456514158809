import { applyFilter, groupBy } from "@/utility/filter-tools";
import { auth } from "../../../../services/firebase.service";

export default {
  userList: ({ userList }) => userList,
  teamFilter: ({ teamFilter }) => teamFilter,
  employeeFilter: ({ employeeFilter }) => employeeFilter,
  filterUserList: (
    { userList, teamFilter, employeeFilter },
    _,
    __,
    rootGetters
  ) => {
    let users = userList.filter(r => r.disabled == false);
    const teamList = rootGetters["scheduling/team/teamList"];
    const isAdminGroup = rootGetters["setting/app/group/isAdminGroup"];
    const currentUser = users.find(r => r.uid === auth.currentUser.uid) || {};

    if (teamFilter.length > 0) {
      const teamListFiltered = teamList.filter(r => teamFilter.includes(r.id));
      const userIds = teamListFiltered.reduce(
        (a, b) => [...a, ...b.userIDs],
        []
      );
      users = users.filter(user => userIds.includes(user.uid));
    }

    if (employeeFilter.length > 0) {
      users = users.filter(user => employeeFilter.includes(user.uid));
    }

    if (
      (currentUser.customClaims && currentUser.customClaims.role === "owner") ||
      isAdminGroup
    ) {
      return users.sort((a, b) => sortBy(a.displayName, b.displayName));
    }
    let userIds = [];
    teamList.forEach(r => {
      userIds = userIds.concat(r.userIDs);
    });
    userIds = [...new Set(userIds)];
    return users
      .filter(r => userIds.includes(r.uid))
      .sort((a, b) => sortBy(a.displayName, b.displayName));
  },
  usersByTeams: ({ userList, teamFilter }, _, __, rootGetters) => {
    let users = userList.filter(r => r.disabled == false);
    const teamList = rootGetters["scheduling/team/teamList"];
    const isAdminGroup = rootGetters["setting/app/group/isAdminGroup"];
    const currentUser = users.find(r => r.uid === auth.currentUser.uid) || {};

    if (teamFilter.length > 0) {
      const teamListFiltered = teamList.filter(r => teamFilter.includes(r.id));
      const userIds = teamListFiltered.reduce(
        (a, b) => [...a, ...b.userIDs],
        []
      );
      users = users.filter(user => userIds.includes(user.uid));
    }
    if (
      (currentUser.customClaims && currentUser.customClaims.role === "owner") ||
      isAdminGroup
    ) {
      return users.sort((a, b) => sortBy(a.displayName, b.displayName));
    }
    let userIds = [];
    teamList.forEach(r => {
      userIds = userIds.concat(r.userIDs);
    });
    userIds = [...new Set(userIds)];
    return users
      .filter(r => userIds.includes(r.uid))
      .sort((a, b) => sortBy(a.displayName, b.displayName));
  },
  userId: state => state.userId,
  userGroup: (_, getters) => (searchValue = "") => {
    const filteredUsers = applyFilter(getters.usersByTeams, searchValue, [
      "displayName"
    ]);
    let dataGroup = groupBy(filteredUsers, item =>
      ((item.displayName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName))
    }));
  },
  userById: ({ userList }) => id => userList.find(r => r.uid === id),
  userByIds: ({ userList }) => ids => {
    if (!ids) return "";
    return (userList.filter(r => (ids || []).includes(r.uid)) || [])
      .map(r => r.displayName)
      .join(", ");
  }
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
