import { applyFilter, groupBy } from "@/utility/filter-tools";

export default {
  userList: ({ userList }) => {
    const users = userList.filter(r => r.disabled == false);
    return users;
  },
  userId: state => state.userId,
  userGroup: (_, getters) => (searchValue = "") => {
    const filteredUsers = applyFilter(getters.userList, searchValue, [
      "displayName"
    ]);
    let dataGroup = groupBy(filteredUsers, item =>
      ((item.displayName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName))
    }));
  },
  userById: ({ userList }) => id => userList.find(r => r.uid === id),
  userByIds: ({ userList }) => ids => {
    if (!ids) return "";
    return (userList.filter(r => (ids || []).includes(r.uid)) || [])
      .map(r => r.displayName)
      .join(", ");
  }
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
