<template>
  <f7-list media-list class="no-margin">
    <f7-list-item
      :class="{
        'inspect-time-ios': $device.ios,
        'inspect-time-android': $device.android,
        'inspect-time-desktop': $device.desktop || $device.ipad
      }"
      :style="inputDarkClass"
    >
      <div class="list-item-inner-start" slot="inner-start">
        {{ title }}<required-asterisk />
      </div>
      <input
        :readonly="readonly"
        slot="title"
        type="time"
        v-model="internalValue"
      />
      <input-icon slot="media" :icon="icon"></input-icon>
      <div slot="text" style="color: red;">{{ errorMessage }}</div>
    </f7-list-item>
  </f7-list>
</template>
<script>
import InputIcon from "@/components/icons/InputIcon.vue";
export default {
  props: {
    time: String,
    errorMessage: String,
    icon: String,
    title: String,
    readonly: Boolean
  },
  components: {
    InputIcon
  },
  data: () => {
    return {
      internalValue: ""
    };
  },
  computed: {
    inputDarkClass() {
      let theme =
        localStorage.getItem("themeDark") === "true" ? "dark" : "light";
      return `color-scheme: ${theme};`;
    }
  },
  watch: {
    internalValue(val) {
      this.$emit("input", val);
    },
    time(val) {
      this.internalValue = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}
.inspect-time-desktop ::v-deep input[type="time"] {
  width: 100px;
}
.inspect-time-android ::v-deep input[type="time"] {
  width: 100%;
}
.inspect-time-ios ::v-deep input[type="time"]::-webkit-date-and-time-value {
  text-align: left;
  width: 100%;
}
</style>
