<template>
  <div style="position: relative">
    <f7-link
      class="card-item"
      :popover-open="`.info-popover_${item.id}`"
      :style="`background-color: ${hexToRgba(colorTimeLog)}`"
    >
      <div
        class="card-item_left"
        :style="`background-color: ${colorTimeLog}`"
      ></div>
      <div class="card-item_right">
        <div
          class="title text-ellipsis"
          style="margin-right:var(--f7-checkbox-size);"
        >
          <span class="fw-600">
            {{
              item.override && item.overrideHour > 0
                ? formatDuration(
                    (item.override ? item.overrideHour : item.loggedHour) +
                      (item.hasLunchBreak ? item.lunchTimeInfo.hour || 0 : 0)
                  )
                :  `${moment(item.startTime, "HH:mm").format("h:mm a")}
                  - ${moment(item.endTime, "HH:mm").format("h:mm a")}`
            }}
          </span>
        </div>
        <div class="title text-ellipsis">
          <span v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
            {{ item.timeOffTypeValue }}
          </span>
          <span v-else-if="item.timeLogType === TIME_LOG_TYPE_OTHERS">
            {{ item.title }}</span
          >
          <span v-else>{{ (item.project || {}).title }}</span>
        </div>
        <div>
          <f7-chip
            v-if="item.hasLunchBreak"
            class="margin-right-half"
            text="Lunch time"
            color="orange"
          ></f7-chip>
          <f7-chip
            v-if="
              !item.override &&
                (item.project.propertyAddress || item.project.projectAddress) &&
                overDistance
            "
            text="Off-site"
            color="red"
          ></f7-chip>
        </div>
      </div>
    </f7-link>
    <f7-checkbox
      v-if="item.status === STATUS_TIME_TRACKING_WFA"
      class="card-checkbox"
      popover-close
      :checked="selectedTimeLogWFAIds.includes(item.id)"
      @change="onSelectTimeLogId(item.id)"
    ></f7-checkbox>
    <f7-popover
      :ref="`timeLogInfoPopover_${item.id}`"
      :backdrop="false"
      :class="`info-popover info-popover_${item.id}`"
    >
      <f7-card>
        <f7-card-header class="justify-content-space-between">
          <div>{{ (userById(item.user_id) || {}).displayName || "" }}</div>
          <div
            v-if="item.status === STATUS_TIME_TRACKING_WFA"
            class="display-flex align-item-center"
            style="gap: 8px"
          >
            <f7-button small outline popover-close @click="onEdit"
              >Edit</f7-button
            >
            <f7-button small fill popover-close color="red" @click="onReject"
              >Reject</f7-button
            >
            <f7-button small fill popover-close color="green" @click="onApprove"
              >Approve</f7-button
            >
          </div>
          <div class="display-flex" v-else-if="item.status === STATUS_TIME_TRACKING_APPROVED">
            <f7-button v-if="isHasRoleReOpen || isManager" class="margin-right-half" small popover-close outline @click="$emit('onReOpen', item.id)"
              >Re-open</f7-button
            >
            <f7-chip text="Approved" color="green"></f7-chip>
          </div>
          <div v-else-if="item.status === STATUS_TIME_TRACKING_REJECTED">
            <f7-chip text="Rejected" color="red"></f7-chip>
          </div>
        </f7-card-header>
        <f7-card-content>
          <div class="grid-card-content">
            <f7-row>
              <f7-col>
                <f7-icon f7="calendar" class="margin-right-half"></f7-icon>
                Date
              </f7-col>
              <f7-col>{{ showDateDetail }}</f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-icon f7="clock" class="margin-right-half"></f7-icon>
                Time in - out
              </f7-col>
              <f7-col>
                {{
                  item.startTime && item.endTime
                    ? `${moment(item.startTime, "HH:mm").format("h:mm a")}
                      - ${moment(item.endTime, "HH:mm").format("h:mm a")}`
                    : "----"
                }}
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-icon f7="cube_box" class="margin-right-half"></f7-icon>
                Project
              </f7-col>
              <f7-col>
                <span v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
                  {{ item.timeOffTypeValue }}
                </span>
                <span v-else-if="item.timeLogType === TIME_LOG_TYPE_OTHERS">
                  {{ item.title }}</span
                >
                <span v-else>{{ (item.project || {}).title }}</span>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-icon f7="scope" class="margin-right-half"></f7-icon>
                Location Tracking
              </f7-col>
              <f7-col>
                <start-and-end-component
                  v-if="
                    !item.override &&
                      (item.project.propertyAddress ||
                        item.project.projectAddress)
                  "
                  :distance="item.distance"
                  @auditClick="clickOnAudit"
                ></start-and-end-component>
                <span v-else>----</span>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-icon f7="tray" class="margin-right-half"></f7-icon>
                Lunch Break
              </f7-col>
              <f7-col>
                {{
                  item.hasLunchBreak
                    ? formatDuration(item.lunchTimeInfo.hour || 0)
                    : "----"
                }}
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-icon f7="stopwatch" class="margin-right-half"></f7-icon>
                Total Working Hours
              </f7-col>
              <f7-col>
                {{
                  formatDuration(
                    (item.override ? item.overrideHour : item.loggedHour) +
                      (item.hasLunchBreak ? item.lunchTimeInfo.hour || 0 : 0)
                  )
                }}
              </f7-col>
            </f7-row>
            <f7-row v-if="item.status === STATUS_TIME_TRACKING_REJECTED">
              <f7-col>
                <f7-icon
                  f7="text_badge_xmark"
                  class="margin-right-half"
                ></f7-icon>
                Reject Reason
              </f7-col>
              <f7-col>{{ item.reject_reason }}</f7-col>
            </f7-row>
          </div>
        </f7-card-content>
      </f7-card>
    </f7-popover>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { hexToRgba } from "../../../../utility/theme";
import { getFullAddress } from "@/utility/address";
import moment from "moment";
import {
  STATUS_TIME_TRACKING_APPROVED,
  STATUS_TIME_TRACKING_REJECTED,
  STATUS_TIME_TRACKING_WFA,
  TIME_LOG_TYPE_OTHERS,
  TIME_LOG_TYPE_TIME_OFF
} from "@/utility/const";
import { meterToMile } from "@/utility/distance-tool";
import { formatDuration } from "@/utility/datetime";
import StartAndEndComponent from "../location/StartAndEndComponent.vue";
import {
  auth
} from "../../../../services/firebase.service";
export default {
  components: {
    StartAndEndComponent
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    selectedTimeLogWFAIds: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      TIME_LOG_TYPE_OTHERS,
      TIME_LOG_TYPE_TIME_OFF,
      STATUS_TIME_TRACKING_WFA,
      STATUS_TIME_TRACKING_APPROVED,
      STATUS_TIME_TRACKING_REJECTED,
      formatDuration,
      moment,
      isManager: false
    };
  },
  mounted() {
    this.checkIsManager();
  },
  methods: {
    ...mapActions("time-tracking/pay-rate", [
      "bindPayRateListBy"
    ]),
    ...mapActions("common/team-management", [
      "getTeamListBys"
    ]),
    async checkIsManager() {
      let listTeamManager = await this.getTeamListBys([
        {
          prop: "managerID",
          val: auth.currentUser.uid,
          op: "=="
        },
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        }
      ])
      this.isManager = listTeamManager.length > 0;
    },
    hexToRgba(hex) {
      return hexToRgba(hex);
    },
    convertDateToTimestamp(date) {
      return this.$google.firebase.firestore.Timestamp.fromDate(
        new Date(moment(date, "MM/DD/YYYY").format("MM/DD/YYYY"))
      );
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },
    clickOnAudit() {
      this.$refs[`timeLogInfoPopover_${this.item.id}`].close();
      this.$emit("clickOnAudit", this.item);
    },
    onEdit() {
      this.$refs[`timeLogInfoPopover_${this.item.id}`].close();
      this.$emit("openEditPopup", this.item);      
    },
    onReject() {
      this.$refs[`timeLogInfoPopover_${this.item.id}`].close();
      this.$emit("openRejectPopup", this.item);
    },
    async onApprove() {
      const user = this.userById(this.item.user_id);
      const isHourlyPayType = user?.customClaims?.payType === 'hourly';

      if (isHourlyPayType) {
        await this.bindPayRateListBy({
          prop: "userId",
          val: this.item.user_id,
          op: "=="
        });
        const sorted = this.payRateList.sort((a, b) => moment(a.effectiveDate) - moment(b.effectiveDate));
        const itemDate = moment(this.item.date.toDate()).startOf('day');
        const toDates = sorted.map((rate, index) => 
          index < sorted.length - 1 ? moment(sorted[index + 1].effectiveDate).subtract(1, 'days').startOf('day') : null
        );
        const isDateInRange = sorted.some((rate, index) => {
          const effectiveDate = moment(rate.effectiveDate).startOf('day');

          return toDates[index] === null
            ? itemDate.isSameOrAfter(effectiveDate)
            : itemDate.isBetween(effectiveDate, toDates[index], null, '[]');
        });
        
        if (this.payRateList.length === 0 || !isDateInRange) {
          this.$ri.dialog.openWarningDialog({
            title: "Approve Time Log",
            content: "Please verify the user's pay rate before approving the timelog!",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0 || index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
          return;
        }
      }

      this.$refs[`timeLogInfoPopover_${this.item.id}`].close();
      this.$emit("onApprove", this.item);
    },
    onSelectTimeLogId(id) {
      this.$emit("onSelectTimeLogId", id);
    }
  },

  computed: {
    ...mapGetters("time-tracking/user", ["userById"]),
    ...mapGetters("time-tracking/pay-rate", ["payRateList"]),
    ...mapGetters("time-tracking/pay-rate", ["payRateList"]),

    showDateDetail() {
      return moment(this.item.date.toDate(), "MM/DD/YYYY").format(
        "ddd, MM/DD/YYYY"
      );
    },
    colorTimeLog() {
      return this.item.status === STATUS_TIME_TRACKING_APPROVED
        ? "#009500"
        : this.item.status === STATUS_TIME_TRACKING_REJECTED
        ? "#ff3b30"
        : "#ffcc00";
    },
    overDistance() {
      const mile = meterToMile(this.item.distance);
      return mile > 1;
    },
        
    isHasRoleReOpen() {
      const user = this.userById(auth.currentUser.uid);
      return user.customClaims.roles === "admin" || "owner";
    }
  }
};
</script>
<style lang="scss" scoped>
.card-item {
  display: block;
  color: black;
  position: relative;
  margin: 5px 2px;
  padding: 3px 0px;
  border-radius: 4px 0px 0px 4px;
  box-sizing: border-box;

  &_left {
    position: absolute;
    width: 7px;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px 0px 0px 4px;
  }

  &_right {
    padding-left: 10px;
    padding-right: 4px;
  }
}
.card-item:hover {
  background-color: #f1f1f1 !important;
}
.card-checkbox {
  position: absolute;
  top: 6px;
  right: 8px;
}
.title {
  font-size: 12px;
}
.fw-600 {
  font-weight: 600;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-popover {
  width: 500px;

  .grid-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .row {
      .col:first-child {
        display: flex;
        align-items: center;
        color: var(--f7-color-text-3-neutral);
      }
      .col:last-child {
        text-align: right;
      }
    }
  }
}
</style>
