import { FirebaseActions } from "../../../../services/firebase.service";
import { DEFAULT_STATUS_PRICE_LIST } from "@/utility/const";
import * as types from "./types";

export default tenant => {
  const priceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    "priceListItem"
  );
  return {
    bindPriceListItemListBys: priceListActions.bindCollectionBys,
    unbindPriceListItemList: priceListActions.unbindCollection,

    bindPriceListItem: priceListActions.bindDocument,
    unbindPriceListItem: priceListActions.unbindDocument,
    updatePriceList: priceListActions.updateDocument,
    getPriceListById: priceListActions.getDocument,

    getPriceList({ commit, dispatch }, id) {
      return dispatch("getPriceListById", id).then(data => {
        commit(types.SET_PRICE_LIST, data);
      });
    },

    bindActivePriceList({ dispatch }, roofType) {
      return dispatch("bindPriceListItemListBys", [
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        },
        {
          prop: "status",
          op: "==",
          val: DEFAULT_STATUS_PRICE_LIST
        },
        {
          prop: "roofTypes",
          val: roofType,
          op: "array-contains"
        }
      ]);
    }
  };
};
