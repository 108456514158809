<template>
  <f7-list @keydown.native="onKeydown" @keyup.native="onKeyup">
    <f7-list-item id="projectAddress" header="Project Address">
      <required-asterisk slot="header"></required-asterisk>
      <input-icon slot="media" icon="map"></input-icon>
      <div slot="title" class="margin-top-half">
        <f7-button @click="getCurrentLocation" small
          class="btn-fill-gray display-flex justify-content-center align-items-center" style="width: fit-content">
          <f7-icon size="18" f7="plus" class="margin-right-half"></f7-icon>
          Get current location
        </f7-button>
      </div>
    </f7-list-item>
    <f7-list-item v-if="addressesSuggestion.length">
      <div slot="media"></div>
      <div slot="inner-end" class="width-100">
        <div class="display-flex align-items-center">
          <f7-icon class="margin-right-half" color="blue" f7="info_circle_fill" />
          List of address suggestion:
        </div>
        <f7-list class="address-list no-margin margin-top-half">
          <f7-list-item v-for="(addressSuggestion, index) in addressesSuggestion" :key="`address-${index}`" link
            @click.native="selectAddressSuggestion(addressSuggestion)">
            <div slot="header">{{ addressSuggestion.code }}</div>
            <div class="list-item-title" slot="title">
              {{ getFullAddress(addressSuggestion) }}
            </div>
            <input-icon slot="media" icon="map"></input-icon>
            <div slot="after-title">
              <f7-link 
                class="external icon-link"
                target="_blank" 
                :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(addressSuggestion)}`" 
                icon-f7="placemark_fill"
              >
              </f7-link>
            </div>
          </f7-list-item>
        </f7-list>
      </div>
    </f7-list-item>
    <f7-list-input id="address" :value="currentAddress.address" @input="searchAddress($event.target.value)"
      @focus="geolocate" @blur="blurAddressSearch($event.target.value)" placeholder="Address" clear-button
      error-message-force :error-message="addressErrorMessage">
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input :value="currentAddress.city" @input="currentAddress.city = $event.target.value" placeholder="City"
      clear-button error-message-force :error-message="cityErrorMessage" @blur="
        changePropAddress('city', $event.target.value);
      v$.$touch();
      ">
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input :value="currentAddress.state" @input="currentAddress.state = $event.target.value" placeholder="State"
      clear-button error-message-force :error-message="stateErrorMessage" @blur="
        changePropAddress('state', $event.target.value);
      v$.$touch();
      ">
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input :value="currentAddress.zipcode" @input="currentAddress.zipcode = $event.target.value"
      placeholder="ZIP Code" clear-button error-message-force :error-message="zipcodeErrorMessage" @blur="
        changePropAddress('zipcode', $event.target.value);
      v$.$touch();
      ">
      <div class="space" slot="media"></div>
    </f7-list-input>
    <f7-list-input :value="currentAddress.country" @input="currentAddress.country = $event.target.value"
      placeholder="Country" clear-button error-message-force :error-message="countryErrorMessage" @blur="
        changePropAddress('country', $event.target.value);
      v$.$touch();
      ">
      <div class="space" slot="media"></div>
    </f7-list-input>

    <div class="dropdown-content" v-show="predictions.length > 0 && showPredictions" :style="`top: ${calcTop}`">
      <auto-complete-list :items="predictions" @item:click="onItemClick" @item:blur="onItemBlur" />
    </div>
  </f7-list>
</template>
<script>
import { googlePlaceApiMixin } from "@/services/place.google.service";
import AutoCompleteList from "./AddressAutoCompleteInput/AutoCompleteList.vue";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { getFullAddress } from "@/utility/address";
import InputIcon from "@/components/icons/InputIcon.vue";
import _ from "lodash";
import { VALIDATION_MESSAGE } from '@/utility/const';

const componentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "short_name",
  country: "long_name",
  postal_code: "short_name",
  neighborhood: "short_name"
};

const KEYCODE = {
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  TAB: 9
};

export default {
  components: {
    AutoCompleteList,
    InputIcon
  },
  props: {
    address: { type: Object, default: () => { } },
    addressesSuggestion: { type: Array, default: () => [] }
  },
  mixins: [googlePlaceApiMixin],
  data: () => {
    return {
      currentAddress: {
        address: "",
        city: "",
        state: "",
        zipcode: "",
        country: ""
      },
      predictions: [],
      showPredictions: false,
      isKeyUpOrDown: false,
      isSelectSearch: false,
      calcTop: ""
    };
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  computed: {
    addressErrorMessage() {
      if (!this.v$.currentAddress.address.$error) return null;
      if (this.v$.currentAddress.address.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    cityErrorMessage() {
      if (!this.v$.currentAddress.city.$error) return null;
      if (this.v$.currentAddress.city.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    stateErrorMessage() {
      if (!this.v$.currentAddress.state.$error) return null;
      if (this.v$.currentAddress.state.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    zipcodeErrorMessage() {
      if (!this.v$.currentAddress.zipcode.$error) return null;
      if (this.v$.currentAddress.zipcode.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    countryErrorMessage() {
      if (!this.v$.currentAddress.country.$error) return null;
      if (this.v$.currentAddress.country.required.$invalid)
        return "This field is required";
      return null;
    }
  },
  methods: {
    clearData() {
      this.currentAddress = {
        address: "",
        city: "",
        state: "",
        zipcode: "",
        country: ""
      };
      this.predictions = [];
      this.showPredictions = false;
      this.isKeyUpOrDown = false;
      this.isSelectSearch = false;
      this.calcTop = "";
    },
    getFullAddress(address) {
      return getFullAddress(address);
    },
    getInputElement() {
      return this.$el.querySelector("#address input");
    },
    select(place) {
      this.geocode({ placeId: place.place_id }).then(response => {
        this.fillData(response[0]);
      });
    },
    changePropAddress(prop, value) {
      this.currentAddress[prop] = value;
      this.$emit("input", { prop, value });
    },
    onSelectAddress(address) {
      this.currentAddress = _.cloneDeep(address);
      this.$emit("select", _.cloneDeep(address));
    },
    fillData(place) {
      let address_components = place.address_components;
      let values = [];
      for (const component of address_components) {
        const addressType = component.types[0];

        if (componentForm[addressType]) {
          const val = component[componentForm[addressType]];
          values.push({ key: addressType, value: val });
        }
      }

      this.currentAddress.address = this.getAddressDetail(values);
      this.currentAddress.city = (
        values.find(r => r.key == "locality") || { value: "" }
      ).value;
      this.currentAddress.zipcode = (
        values.find(r => r.key == "postal_code") || { value: "" }
      ).value;
      this.currentAddress.country = (
        values.find(r => r.key == "country") || { value: "" }
      ).value;
      this.currentAddress.state = (
        values.find(r => r.key == "administrative_area_level_1") || {
          value: ""
        }
      ).value;
      this.onSelectAddress(this.currentAddress);
    },
    getAddressDetail(values) {
      let street_number = (
        values.find(r => r.key == "street_number") || { value: "" }
      ).value;
      let route = (values.find(r => r.key == "route") || { value: "" }).value;
      return `${street_number} ${route}`;
    },
    hide() {
      this.showPredictions = false;
    },

    show() {
      this.showPredictions = true;
      this.calcTopAutoComplete();
    },
    onFocus(event) {
      if (this.query) {
        if (!this.predictions.length) {
          this.onKeyup(event);
        }

        this.show();
      }
    },

    onBlur(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.hide();
      }
    },

    onItemBlur(event) {
      this.onBlur(event);
    },

    onItemClick(event, child) {
      this.select(child.item);
      this.isSelectSearch = true;
      this.predictions = [];
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position =>
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }),
          reject
        );
      });
    },

    async geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          return geolocation;
        });
      }
    },
    getRequestOptions() {
      const options = {
        input: this.getInputElement().value,
        types: ["geocode"]
      };
      return options;
    },
    search() {
      return new Promise((resolve, reject) => {
        if (!this.getInputElement().value) {
          this.predictions = [];
          this.hide();
        } else {
          this.$autoComplete.getPlacePredictions(
            this.getRequestOptions(),
            (response, status) => {
              this.showActivityIndicator = false;
              switch (status) {
                case window.google.maps.places.PlacesServiceStatus.OK:
                  resolve(response);
                  break;
                default:
                  reject(new Error(`Error with status: ${status}`));
              }
            }
          );
        }
      });
    },
    async searchAddress(value) {
      this.currentAddress["address"] = value;
      this.show();
      this.search().then(
        response => {
          this.predictions = response;
        },
        error => {
          if (error) {
            this.predictions = [];
          }
        }
      );
    },
    blurAddressSearch(value) {
      if (!this.isKeyUpOrDown) {
        this.v$.$touch();
        setTimeout(() => {
          this.hide();
          if (!this.isSelectSearch) {
            this.changePropAddress("address", value);
          }
          this.isSelectSearch = false;
        }, 500);
      }
    },
    up() {
      const focused = this.$el.querySelector("a:focus");
      if (focused && focused.parentElement.previousElementSibling) {
        focused.parentElement.previousElementSibling.querySelector("a").focus();
      } else {
        const links = this.$el.querySelectorAll("a");
        links[links.length - 1].focus();
      }
    },

    down() {
      const focused = this.$el.querySelector("a:focus");

      if (focused && focused.parentElement.nextElementSibling) {
        focused.parentElement.nextElementSibling.querySelector("a").focus();
      } else {
        this.$el.querySelector(".dropdown-content a").focus();
      }
    },

    onKeydown(event) {
      this.isKeyUpOrDown = true;
      const element = this.$el.querySelector("[tabindex]");

      if (element && event.keyCode === KEYCODE.TAB) {
        event.preventDefault() && element.focus();
      }
      this.isKeyUpOrDown = false;
    },

    onKeyup(event) {
      this.isKeyUpOrDown = true;
      switch (event.keyCode) {
        case KEYCODE.ENTER:
        case KEYCODE.SPACE:
          if (this.$el.querySelector(".is-focused")) {
            this.$el
              .querySelector(".is-focused a")
              .dispatchEvent(new Event("mousedown"));
          }
          return;
        case KEYCODE.ESC:
          this.hide();
          this.getInputElement().blur();
          event.preventDefault();
          return;
        case KEYCODE.UP:
          this.up();
          event.preventDefault();
          return;
        case KEYCODE.DOWN:
          this.down();
          event.preventDefault();
          return;
      }

      this.search().then(
        response => {
          this.predictions = response;
        },
        error => {
          if (error) {
            this.predictions = [];
          }
        }
      );

      this.isKeyUpOrDown = false;
    },
    popupRequiresLocation() {
      this.$ri.dialog
        .openInfoDialog({
          title: "Cannot access location service",
          content:
            "This action requires location service, please turn it on and try again!",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        })
        .open();
    },
    async getCurrentLocation() {
      try {
        const location = await this.getCurrentPosition();
        const address = await this.lookupAddress(location.lat, location.lng);
        this.fillData(address);
      } catch (e) {
        this.popupRequiresLocation();
        return;
      }
    },
    selectAddressSuggestion(address) {
      this.onSelectAddress(address);
    },
    calcTopAutoComplete() {
      const projectAddressY = this.$el
        .querySelector("#projectAddress")
        .getBoundingClientRect().y;
      const addressInputY = this.$el
        .querySelector("#address")
        .getBoundingClientRect().y;
      return this.$nextTick(() => {
        this.calcTop = `calc(${addressInputY -
          projectAddressY}px + var(--f7-input-height) + var(--f7-list-item-padding-vertical))`;
      });
    }
  },
  validations: {
    currentAddress: {
      address: {
        required
      },
      city: {
        required
      },
      state: {
        required
      },
      zipcode: {
        required
      },
      country: {
        required
      }
    }
  },
  watch: {
    address: {
      handler(val) {
        if (val) {
          this.currentAddress = _.cloneDeep(val);
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  position: absolute;
  background-color: #f6f6f6;
  width: 85%;
  min-width: 200px;
  max-width: 600px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  left: 40px;
  background-color: #fff;
}

.space {
  width: 1em;
  height: 1em;
}

.address-list {
  ::v-deep ul {
    padding: 0;

    .item-content {
      padding: 0;
    }

    .item-inner {
      padding-right: 0;

      &::before {
        display: none;
      }
    }
  }
}
</style>
