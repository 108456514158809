<template>
  <div class="input">
    <f7-icon f7="searchbar-icon" class="icon-search"></f7-icon>
    <input
      @input="$emit('input', $event.target.value.trim())"
      type="text"
      placeholder="Search Employee"
    />
    <span class="input-clear-button"></span>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.input {
  .icon-search {
    position: absolute;
    top: 0;
    left: 0;
    color: gray;
  }
  input {
    padding: 0 25px;
  }
}
</style>
