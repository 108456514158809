<template>
  <div class="global-search-btn" v-if="$device.desktop" @mousedown="handleMousedown" @click="openPopup">
    <f7-icon color="white" f7="search" style="font-size: 18px;"></f7-icon>
  </div>
  <div class="global-search-btn" v-else @touchstart="handleTouchstart" @touchmove="handleTouchmove" @touchend="handleTouchend" @click="openPopup">
    <f7-icon color="white" f7="search" style="font-size: 18px;"></f7-icon>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      startX: 0,
      startY: 0,
      newX: 0,
      newY: 0,
      isDragging: false
    };
  },
  methods: {
    ...mapActions("common/global-search", ["openGlobalSearchPopup"]),

    handleTouchstart(event) {
      this.isDragging = false;
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      document.addEventListener('touchmove', this.handleTouchmove);
      document.addEventListener('touchend', this.handleTouchend);
    },

    handleTouchmove(event) {
      this.newX = this.startX - event.touches[0].clientX;
      this.newY = this.startY - event.touches[0].clientY;
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      this.isDragging = true;
      this.handlePosition()
    },

    handleTouchend() {
      this.handleDropEnd()
      document.removeEventListener('touchmove', this.handleTouchmove);
      document.removeEventListener('touchend', this.handleTouchend);
    },

    handleMousedown(event) {
      const element = document.querySelector('.global-search-btn')
      this.isDragging = false;
      this.startX = event.clientX
      this.startY = event.clientY
      document.addEventListener('mousemove', this.mouseMove)
      document.addEventListener('mouseup', this.mouseUp)
    },

    mouseMove(event) {   
      this.newX = this.startX - event.clientX
      this.newY = this.startY - event.clientY
      this.startX = event.clientX
      this.startY = event.clientY
      this.isDragging = true
      this.handlePosition()
    },

    mouseUp() {
      this.handleDropEnd()
      document.removeEventListener('mousemove', this.mouseMove);
      document.removeEventListener('mouseup', this.mouseUp);
    },

    handlePosition(){
      const element = document.querySelector('.global-search-btn')
      const newLeft = element.offsetLeft - this.newX;
      const newTop = element.offsetTop - this.newY;
      element.style.bottom = `auto`;
      element.style.right = `auto`;
      element.style.top = `${newTop}px`;
      element.style.left = `${newLeft}px`;
    },

    handleDropEnd() {
      const element = document.querySelector('.global-search-btn')
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const minTop = 50
      const maxTop = this.$device.desktop ? screenHeight - 50 : screenHeight - 150
      const left = element.offsetLeft - this.newX;
      const top = element.offsetTop - this.newY;

      if (left < screenWidth / 2) {
        element.style.left = '20px';
        element.style.right = 'auto'; 
      } else {
        element.style.right = '20px';
        element.style.left = 'auto'; 
      }

      if (top < minTop) {
        element.style.top = `20px`
        element.style.bottom = "auto"
      } else if (top > maxTop) {
        element.style.top = `auto`
        if(this.$device.desktop) {
          element.style.bottom = `20px`
        }else{
          element.style.bottom = `70px`
        }
      }
    },

    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: ".global-search-btn",
        cssClass: "tooltip-fab-button",
        text: "Search information",
      });
    },

    openPopup() {
      if (this.isDragging) return
      this.openGlobalSearchPopup(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.global-search-btn{
  width: 40px;
  height: 40px;
  background-color: var(--f7-theme-color);
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  z-index: 9999;
  bottom: var(--global-search-btn-bottom);
  right: var(--global-search-btn-right);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
</style>
