<template>
  <f7-popup
    class="demo-popup"
    :opened="showPopup"
    @popup:close="closePopup()"
    @popup:closed="handlePopupClosed()"
  >
    <f7-page>
      <progress-bar ref="progressBar"></progress-bar>
      <!-- Navigate bar -->
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close @click="showPopup = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Photo</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done()">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div v-if="projectId" class="upload-photo" @click="openUploadPhoto">
        <p class="click-upload">Click to upload</p>
        <i
          class="icon-no-data icon f7-icons"
          style="font-size: 80px; color: #ddd"
          >photo</i
        >
        <input
          ref="imageInput"
          type="file"
          @change="onUploadImage"
          class="display-none"
          multiple
          accept=".mp4, .png, .jpg, .jpeg, .webp, .mov, .webM, .m4v"
        />
      </div>
      <!-- page content -->
      <div v-if="photoList.length === 0 && projectId" class="camera">
        <div class="bg-no-data">
          <f7-icon f7="photo" class="icon-no-data"></f7-icon>
        </div>
        <p>No photo to load</p>
      </div>
      <!-- page content -->
      <div v-if="!projectId" class="camera project-not-show">
        <div class="bg-no-data">
          <f7-icon f7="photo" class="icon-no-data"></f7-icon>
        </div>
        <p>Please select Project/Job Name first to load photos</p>
      </div>
      <h3 v-if="projectId && photoList.length > 0" style="padding-left: 20px">
        Project Photos
      </h3>
      <div v-if="projectId && photoList.length > 0" class="img-grid">
        <div v-for="(photo, index) in photoList" :key="index">
          <div
            class="img-item"
            @click="
              showPopup = false;
              selectCallback(photo);
            "
          >
            <img class="image-show" :src="photo.photoUrl" />
            <div class="img-bg img-bg-not-select"></div>
          </div>
          <span class="img-title">{{
            (photo.createdAt && photo.createdAt.toDate()) | TIME
          }}</span>
        </div>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageTools from "../../../../utility/image-tools";
import ProgressBar from "@/components/progress-bar/ProgressBar.vue";
import _ from "lodash";

export default {
  props: {
    checkShowItem: { type: Function, default: () => true },
    showProgressBar: { type: Boolean, default: false }
  },
  components: {
    ProgressBar
  },
  data() {
    return {
      showPopup: false,
      selectedValue: "",
      selectCallback: null,
      projectId: ""
    };
  },
  computed: {
    ...mapGetters({
      projectPhotoList: "proposal/photo/projectPhotoList"
    }),
    photoList() {
      return this.projectPhotoList.filter(r => this.checkShowItem(r)) || [];
    }
  },
  methods: {
    ...mapActions({
      bindProjectPhotoListBy: "proposal/photo/bindProjectPhotoListBy",
      createProjectPhoto: "proposal/photo/createProjectPhoto",
      uploadPhoto: "proposal/photo/uploadPhoto"
    }),
    loadPhoto(projectId) {
      if (!projectId) return;
      if (this.projectId == projectId) return;
      this.projectId = projectId;
      if (_.isEmpty(this.projectPhotoList)) {
        this.$f7.preloader.show();
        this.bindProjectPhotoListBy({
          prop: "projectId",
          val: projectId,
          op: "=="
        }).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    openPopup(projectId) {
      this.showPopup = true;
      this.loadPhoto(projectId);
      return new Promise(resolve => {
        this.selectCallback = resolve;
      });
    },
    closePopup() {
      this.showPopup = false;
    },
    done() {
      this.showPopup = false;
      this.$emit("change", this.selectedValue);
    },
    openUploadPhoto() {
      this.$refs.imageInput.click();
    },
    onStartUpload() {
      this.$refs.progressBar.show();
    },
    onUploadProgress({ photoCount, videoCount, photoTotal, videoTotal }) {
      this.$refs.progressBar.setText(
        `${photoTotal > 0 ? `${photoCount}/${photoTotal} photos` : ""}
        ${videoTotal > 0 && photoTotal > 0 ? ", " : ""}
        ${videoTotal > 0 ? `${videoCount}/${videoTotal} videos` : ""}`
      );
      this.$refs.progressBar.setValue(
        ((photoCount + videoCount) * 100) / (photoTotal + videoTotal)
      );
      if (photoCount == photoTotal && videoCount == videoTotal) {
        this.$refs.progressBar.hide();
      }
    },
    async onUploadImage(event) {
      const app = this;
      let files = app.$refs.imageInput.files;
      let photoCount = 0;
      let videoCount = 0;
      const failedVideos = [];
      let imgTool = new ImageTools(this.$f7.device);
      const imageFilesCount = Array.from(files).filter(file =>
        file.type.startsWith("image/")
      ).length;
      const videoFilesCount = Array.from(files).filter(file =>
        file.type.startsWith("video/")
      ).length;
      if (files.length > 0 && this.showProgressBar) this.onStartUpload();
      if (!this.showProgressBar) app.$f7.preloader.show();

      const uploadPromises = [];

      for (let i = 0; i < files.length; i += 1) {
        if (
          files[i].type === "image/png" ||
          files[i].type === "image/jpeg" ||
          files[i].type === "image/jpg" ||
          files[i].type === "image/webp"
        ) {
          const uploadPromise = (async () => {
            let photo = {};
            const name = files[i].name;
            const resizedFile = await imgTool.resize(files[i], {
              width: 256,
              height: 256
            });
            if (!resizedFile.name) resizedFile.name = name;

            const thumbnailData = await app.uploadPhoto({
              file: resizedFile,
              projectId: app.projectId
            });
            photo = {
              thumbnailUrl: thumbnailData.url,
              thumbnailFullPath: thumbnailData.fullPath
            };

            if (app.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount
              });
            }

            if (photo.thumbnailUrl) {
              const fullSizeFile = await imgTool.resize(files[i], {
                width: 1920,
                height: 1080
              });
              if (!fullSizeFile.name) fullSizeFile.name = name;

              const photoData = await app.uploadPhoto({
                file: fullSizeFile,
                projectId: app.projectId
              });
              photo = {
                ...photo,
                projectId: app.projectId,
                photoUrl: photoData.url,
                photoName: name,
                albumId: app.albumId ? app.albumId : null,
                photoFullPath: photoData.fullPath
              };

              await app.createProjectPhoto(photo);
              photoCount += 1;

              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount
                });
              }
            }
          })().catch(() => {
            photoCount += 1;
            if (this.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount
              });
            }
            failedVideos.push(files[i].name);
          });

          uploadPromises.push(uploadPromise);
        } else if (files[i].type.startsWith("video/")) {
          const uploadPromise = (async () => {
            try {
              let photo = {};
              const name = files[i].name;
              const cover = await imgTool.getVideoThumbnail(files[i]);
              const fileThumb = new File([cover], name + ".webp", {
                type: "image/webp"
              });

              const resizedFile = await imgTool.resize(fileThumb, {
                width: 256,
                height: 256
              });
              if (!resizedFile.name) resizedFile.name = name;

              const thumbnailData = await app.uploadPhoto({
                file: resizedFile,
                projectId: app.projectId
              });
              photo = {
                thumbnailUrl: thumbnailData.url,
                thumbnailFullPath: thumbnailData.fullPath
              };

              if (app.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount
                });
              }

              if (photo.thumbnailUrl) {
                const videoData = await app.uploadPhoto({
                  file: files[i],
                  projectId: app.projectId
                });
                photo = {
                  ...photo,
                  projectId: app.projectId,
                  photoUrl: videoData.url,
                  photoName: name,
                  albumId: app.albumId ? app.albumId : null,
                  photoFullPath: videoData.fullPath
                };

                await app.createProjectPhoto(photo);
                videoCount += 1;

                if (this.showProgressBar) {
                  app.onUploadProgress({
                    photoCount: photoCount,
                    videoCount: videoCount,
                    photoTotal: imageFilesCount,
                    videoTotal: videoFilesCount
                  });
                }
              }
            } catch (error) {
              videoCount += 1;
              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount
                });
              }
              failedVideos.push(files[i].name);
            }
          })();

          uploadPromises.push(uploadPromise);
        } else {
          failedVideos.push(files[i].name);
        }
      }

      await Promise.all(uploadPromises);
      if (photoCount == imageFilesCount && videoCount == videoFilesCount) {
        event.target.value = null;
        if (!this.showProgressBar) app.$f7.preloader.hide();
      }
      if (failedVideos.length > 0) {
        this.$refs.progressBar.hide();
        const failedVideosContent = `There's a problem with the files.</br>${failedVideos
          .map((file, index) => `${index + 1}. ${file}`)
          .join("<br>")}`;
        this.openWarningDialogUploadPhoto(failedVideosContent);
      }
    },
    openWarningDialogUploadPhoto(content) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Invalid file!",
        content: content,
        hideCancelButton: true,
        cssClass: "photo-dialog",
        onClick: (_sefl, index) => {
          if (index === 0) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          }
        }
      });
    },
    handlePopupClosed() {},
    onChangeObject(val) {
      this.selectedValue = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-photo {
  height: 80px;
  display: flex;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
  border-color: rgb(207, 212, 219);
  border-radius: 3px;
  margin: 20px;
  position: relative;
}
.click-upload {
  position: absolute;
  top: 20%;
  z-index: 100;
  color: gray;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
  padding: 0 16px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
}
.img-item:hover .img-bg-not-select {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
}
.img-title {
  font-size: 12px;
}
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.camera {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.project-not-show {
  padding: 30px;
  color: orange;
}
.image-show {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
