<template>
  <div>
    <f7-list class="no-margin">
      <f7-list-input type="datepicker" label="Due Date" :calendar-params="{
        backdrop: true,
        header: true,
        footer: false,
        openIn: 'customModal',
        dateFormat: 'mm/dd/yyyy',
        disabled: {
          from: null,
          to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
        }
      }" @calendar:change="
        saveDueDate($event);
      $f7.calendar.close();
      " placeholder="Enter due date" :value="dueDate">
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-block-header class="relative">Roofing Choices
      <f7-button v-show="!rcStatus" raised fill color="primary" class="absolute" @click="openRoofingChoicePopup">
        <f7-icon f7="plus" class="icon-plus"></f7-icon>
        <span class="space" />
        Create Roofing Choices
      </f7-button>

      <f7-button v-show="rcStatus != '' &&
        (rcStatus === ROOFING_CHOICE_CREATED ||
          rcStatus === ROOFING_CHOICE_SENT)
        " class="absolute" @click="$refs.roofingChoicesPopup.openPreview()">
        Preview Roofing Choices
      </f7-button>
    </f7-block-header>

    <f7-list v-show="rcStatus">
      <f7-list-item v-show="rcStatus != ROOFING_CHOICE_SELECTED">
        <f7-link slot="title" @click.native="
          copyRoofingChoicesWebLink($event, card && card.roofingChoicesWebUrl)
          ">Roofing Choices Web Link</f7-link>
        <div slot="footer" v-show="rcStatus === ROOFING_CHOICE_CREATED">
          Created on
          {{ toDisplayDateString(card && card.roofingChoicesCreatedAt) }} by
          {{ card && card.roofingChoicesCreatedBy }}
        </div>
        <div slot="footer" v-show="rcStatus === ROOFING_CHOICE_SENT ||
          rcStatus === ROOFING_CHOICE_SELECTED
          ">
          Sent on
          {{ toDisplayDateString(card && card.roofingChoicesSentAt) }} by
          {{ card && card.roofingChoicesSentBy }}
        </div>
        <div>
          <!-- <f7-icon class="margin-right pointer" f7="pencil_circle"></f7-icon> -->
          <f7-icon @click.native="
            copyRoofingChoicesWebLink(
              $event,
              card && card.roofingChoicesWebUrl
            )
            " class="margin-right pointer" color="primary" f7="doc_on_doc"></f7-icon>
          <f7-icon v-show="rcStatus === ROOFING_CHOICE_CREATED" class="margin-right pointer" color="primary"
            f7="envelope" @click.native="sentRoofingChoices"></f7-icon>

          <f7-icon v-show="rcStatus != ROOFING_CHOICE_SELECTED" class="pointer" f7="trash" color="primary"
            @click.native="removeRoofingChoises"></f7-icon>
        </div>
      </f7-list-item>

      <f7-list-item header="Price List" :title="card && card.roofingChoicesPriceListTitle">
        <f7-icon slot="media" color="primary" f7="square_list"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block-header v-show="rcStatus">
      Final Roofing Choices
    </f7-block-header>

    <f7-list v-show="rcStatus === ROOFING_CHOICE_CREATED" media-list>
      <f7-list-item class="padding-top" text="No data!"></f7-list-item>
    </f7-list>

    <f7-list v-show="rcStatus === ROOFING_CHOICE_SENT" media-list>
      <f7-list-item class="padding-top" text=" Waiting for customer to select!"></f7-list-item>
    </f7-list>

    <div v-show="rcStatus === ROOFING_CHOICE_SELECTED" class="data-table data-table-init card">
      <div class="card-content">
        <div class="margin-top-half margin-left">
          <slot name="total"> </slot>
        </div>
        <table>
          <thead>
            <tr>
              <th v-for="(header, index) in headersRoofing" :key="index" :style="header.align
                ? `text-align-${header.align}`
                : 'text-align-left'
                ">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(choice, index) in card &&
              (card.roofingChoicesSelected || [])" :key="index">
              <td>{{ choice.name }}</td>
              <td>
                <img v-if="choice.data && choice.data.selectedColor" width="70"
                  :src="choice.data.selectedColor.photos[0].thumbnailUrl" :alt="choice.data.selectedColor.colorName" />
                <img v-else width="70" :src="noImageDefault" alt="No Image Available" />
              </td>
              <td>
                {{
                  choice.data && choice.data.selectedColor
                    ? choice.data.selectedColor.colorName
                    : "No color name"
                }}
              </td>
              <td>{{ choice.data.product.productItem }}</td>
              <td>
                <li>Vendor: {{ vendorName(choice.data.product.vendorId) }}</li>
                <li>Manufacturer: {{ choice.data.product.manufacturer }}</li>
                <li>SKU: {{ choice.data.product.sku }}</li>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <f7-list>
      <f7-list-input label="Shingle Type" type="select" placeholder="Please choose..." :value="shingleType"
        @input="saveShingleType($event.target.value)" error-message-force validate validate-on-blur
        :error-message="requireErrorMessage('shingleType')">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
        <option value="" disabled>Select Shingle Type</option>
        <option v-for="item in shingleTypeList" :key="item.id" :value="item.value">
          {{ item.displayName }}
        </option>
        <option value="1">Others</option>
      </f7-list-input>
      <f7-list-item v-if="isNewShingleType" class="custom-input">
        <f7-input slot="title" placeholder="Enter new Shingle Type" type="text" clear-button :value="newShingleType"
          @input="newShingleType = $event.target.value.trim()">
        </f7-input>
        <f7-icon slot="media" f7="plus" color="primary"></f7-icon>
        <f7-button v-show="newShingleType" slot="after" small fill @click="addNewShingleType">Add</f7-button>
      </f7-list-item>
      <f7-list-item>
        <div slot="media"></div>
        <f7-button slot="title" small @click="openEditShingleType"
          class="btn-fill-gray display-flex justify-content-center align-items-center">
          <f7-icon size="18" f7="pencil" class="margin-right-half"></f7-icon>
          Edit Shingle Type
        </f7-button>
      </f7-list-item>
    </f7-list>
    <f7-list>
      <f7-list-input type="text" placeholder="Enter Color" label="Shingle Color" clear-button :value="shingleColor"
        @input="shingleColor = $event.target.value.trim()" @blur="onUpdateCard('shingleColor', $event.target.value)"
        error-message-force :error-message="requireErrorMessage('shingleColor')">
        <required-asterisk slot="label" />
        <input-icon slot="media" icon="person"></input-icon>
      </f7-list-input>
    </f7-list>

    <estimate-input :value="displayCard" :estimateList="estimateListByStatus(['draft', 'active'])" :action="action"
      boardId="residential" @closePopup="closeActionPopup" @onUpdateEstimate="handleUpdateEstimate"
      @onCreateNewEstimate="handleCreateNewEstimate" @onDeleteEstimate="handleDeleteEstimate"></estimate-input>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <invoice-section ref="invoiceSection" @closePopup="closeActionPopup"></invoice-section>
    <build-contract-section ref="buildContractSection"></build-contract-section>
    <!-- attachment -->
    <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
      add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)" @save="save"></attachment-input>

    <user-input :value="displayCard.assigneeIDs" @input="setAssigneeIDs($event)"></user-input>
    <note-input :value="displayCard.commentAndNote" @change="saveNote($event)"></note-input>

    <create-roofing-choices-popup ref="roofingChoicesPopup"
      @sentRoofingChoices="sentRoofingChoices"></create-roofing-choices-popup>

    <send-email-popup ref="sendEmailPopup" popup-title="Send Roofing Choice" templateName="roofing-choices"
      :projectId="card && (card.id || '')" :attachments="card && (card.attachmentFiles || [])"
      :listEmails="listEmails"></send-email-popup>

    <date-popup ref="scheduleProductionDatePopup" title="Schedule Production Date" label="Schedule Production Date"
      v-model="productionDate" @done="resolveScheduleProduction" not-allow-past></date-popup>
    <shingle-type-popup @editedShingleType="editedShingleType" ref="shingleTypePopup"></shingle-type-popup>
  </div>
</template>
<script>
import EstimateInput from "@/components/inputs/EstimateInput.vue";

import AttachmentInput from "../input/AttachmentInput.vue";
import InvoiceSection from "../invoice/InvoiceSection.vue";
import NoteInput from "../input/NoteInput.vue";
import UserInput from "../input/UserInput.vue";
import InputIcon from "../icon/InputIcon.vue";
import CreateRoofingChoicesPopup from "../popup/CreateRoofingChoicesPopup.vue";
import DatePopup from "../popup/DatePopup.vue";

import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import ShingleTypePopup from "../popup/ShingleTypePopup.vue";
import PurchaseOrderInput from "../input/PurchaseOrderInput.vue";

import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

import invoiceMixins from "../../../invoices/mixins/invoice-mixin";
import methodsMixins from "../../mixin/methods";
import residentialMixins from "../../mixin/residential";

import sendEmail from "../../mixin/sendEmail";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import BuildContractSection from "../sections/BuildContractSection.vue";
import { firebase, auth } from "../../../../services/firebase.service";
import {
  toDisplayDateString,
  toDateFirebase,
  toDateCalendar
} from "../../../../utility/datetime";
import moment from "moment";

import {
  PROP_IS_DELETED,
  ROOFING_CHOICE_CREATED,
  ROOFING_CHOICE_SELECTED,
  ROOFING_CHOICE_SENT,
  VALIDATION_MESSAGE
} from "../../../../utility/const";
import noImageDefault from '@/assets/img/no_image_default.png';

export default {
  components: {
    EstimateInput,
    AttachmentInput,
    UserInput,
    NoteInput,
    InputIcon,
    InvoiceSection,
    CreateRoofingChoicesPopup,
    SendEmailPopup,
    DatePopup,
    BuildContractSection,
    ShingleTypePopup,
    PurchaseOrderInput
  },
  mixins: [methodsMixins, invoiceMixins, sendEmail, residentialMixins],
  data() {
    return {
      noImageDefault,
      popupOpened: false,
      currentResponse: {},
      displayCard: {},
      productionDate: [],
      dueDate: [],
      shingleType: "",
      shingleColor: "",
      isNewShingleType: false,
      newShingleType: "",
      currentShingleType: {}
    };
  },

  mounted() {
    this.initData();
  },

  computed: {
    ...mapGetters("dashboard/estimate", ["estimateListByStatus"]),
    ...mapGetters("dashboard/project", ["card", "action"]),
    ...mapGetters({ vendors: "dashboard/client/companyList" }),
    ...mapGetters("dashboard/finance", ["invoiceList"]),
    ...mapGetters("dashboard/shingle-type", ["shingleTypeList"]),
    ...mapGetters("dashboard/client", ["listEmails"]),

    headersRoofing() {
      return [
        {
          text: "Choice",
          align: "left",
          sortable: false,
          value: "totalAmount"
        },
        {
          text: "Photo",
          align: "left",
          sortable: false,
          value: "paidAmount"
        },
        {
          text: "Color",
          align: "left",
          sortable: false,
          value: "invoiceDate"
        },
        {
          text: "Product item",
          align: "left",
          sortable: false,
          value: "paidDate"
        },
        {
          text: "Detail",
          align: "right",
          sortable: false,
          value: "action"
        }
      ];
    },

    rcStatus() {
      return this.card ? this.card.roofingChoicesStatus || "" : "";
    },

    vendorName() {
      return vendorId => {
        const vendor = (this.vendors || []).find(r => r.id == vendorId);
        return (vendor || {}).companyName;
      };
    },
    ROOFING_CHOICE_SELECTED() {
      return ROOFING_CHOICE_SELECTED;
    },

    ROOFING_CHOICE_CREATED() {
      return ROOFING_CHOICE_CREATED;
    },

    ROOFING_CHOICE_SENT() {
      return ROOFING_CHOICE_SENT;
    },
    requireErrorMessage() {
      return (parent, children) => {
        if (children) {
          if (!this.v$[parent][children].$error) return null;
          if (this.v$[parent][children].required.$invalid)
            return VALIDATION_MESSAGE.REQUIRED_FIELD;
        } else {
          if (!this.v$[parent].$error) return null;
          if (this.v$[parent].required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
        }
        return null;
      };
    },
    capitalizeFirstLetter() {
      return string => {
        return string && string.charAt(0).toUpperCase() + string.slice(1);
      };
    }
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),
    ...mapActions("dashboard/estimate", [
      "updateEstimate",
      "deleteEstimate",
      "getEstimatesBys"
    ]),

    ...mapActions("dashboard/finance", ["initInvoice"]),
    ...mapActions("dashboard/shingle-type", [
      "bindShingleTypeListBy",
      "createShingleType",
      "updateShingleType",
      "deleteShingleType",
      "getShingleType"
    ]),

    toDisplayDateString,

    getEstimates() {
      if (!_.isEmpty(this.card)) {
        this.getEstimatesBys([
          {
            prop: "projectId",
            val: this.card.id,
            op: "=="
          },
          {
            prop: PROP_IS_DELETED,
            val: false,
            op: "=="
          },
          { prop: "status", val: ["incomplete", "active", "draft"], op: "in" }
        ]);
      }
    },

    initData() {
      this.getEstimates();
    },

    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        if (
          this.displayCard.dueDate &&
          (_.isEmpty(this.dueDate) ||
            (!_.isEmpty(this.dueDate) &&
              !moment(this.dueDate[0]).isSame(
                moment(toDateCalendar(this.displayCard.dueDate)[0]),
                "day"
              )))
        ) {
          this.dueDate = toDateCalendar(this.displayCard.dueDate);
        }
        this.initData();
        this.shingleColor = this.displayCard.shingleColor || "";
      }
    },

    saveDueDate(value) {
      if (!_.isEmpty(value) && !_.isEqual(value, this.dueDate)) {
        this.dueDate = value;
        this.save();
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      switch (response.code) {
        case "move-to-schedule-production":
          this.currentResponse = response;
          this.$refs.scheduleProductionDatePopup.open();
          break;
      }
    },

    // Implement save action
    resolveScheduleProduction() {
      this.displayCard.productionDate = this.productionDate;
      this.dueDate = this.productionDate;
      this.resolve();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      let doc = {};
      if (!_.isEmpty(this.dueDate)) {
        doc.dueDate = toDateFirebase(this.dueDate);
      }
      if (!_.isEmpty(this.displayCard.productionDate)) {
        doc.productionDate = this.displayCard.productionDate;
      }

      await this.updateCard({
        id: this.card.id,
        doc
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote =
          _.cloneDeep(this.displayCard.commentAndNote) || [];
        commentAndNote.push({
          code: "note",
          title: "Build Contract Note",
          htmlContent: event,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
        this.onUpdateCard("commentAndNote", commentAndNote);
      }
    },

    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    },

    handleUpdateEstimate(data) {
      this.$f7.preloader.show();
      this.updateEstimate({
        id: data.id,
        doc: {
          ...data.doc
        }
      })
        .then(() => this.getEstimates())
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    handleCreateNewEstimate() {
      this.$f7.preloader.show();
      const actionId = this.action.id;
      const projectId = this.displayCard.id;
      const contactId = this.displayCard.contactId || "-";
      // const boardId = this.displayCard.boardId;
      const self = this;

      this.closeActionPopup(() => {
        self.$f7.preloader.hide();
        self.$f7router.navigate(
          {
            name: "new-estimate-swimlane-residential",
            params: {
              boardType: self.$f7route.route.meta.boardType,
              businessCode: self.$f7route.route.meta.businessCode,
              // boardId,
              actionId,
              projectId,
              contactId,
              clientType: "contact",
              clientId: contactId
            },
            query: {
              activeTab: "tab-action"
            }
          },
          { reloadAll: true }
        );
      });
    },

    handleDeleteEstimate(data) {
      this.$f7.preloader.show();
      this.deleteEstimate(data.id)
        .then(() => this.getEstimates())
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    openRoofingChoicePopup() {
      this.$refs.roofingChoicesPopup.open();
    },

    removeRoofingChoises() {
      this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          roofingChoicesPriceListId: "",
          roofingChoicesProducts: "",
          roofingChoicesWebUrl: "",
          roofingChoicesCreatedAt: "",
          roofingChoicesCreatedBy: "",
          roofingChoicesStatus: ""
        }
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },
    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value
        }
      }).finally(() => {
        if (field === "shingleColor") {
          this.$emit("updateEditCheckList", {
            id: "shingle-color",
            value: this.capitalizeFirstLetter(value)
          });
        }
        isShowLoading && this.$f7.preloader.hide();
      });
    },
    addNewShingleType() {
      let sameValue = this.shingleTypeList.find(
        r => r.value === this.newShingleType
      );
      if (sameValue) {
        this.$ri.dialog.openWarningDialog({
          title: "Please input another one",
          content: "That shingle type has already existed!",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      } else {
        this.$f7.preloader.show();
        this.createShingleType({
          value: this.newShingleType,
          displayName: this.capitalizeFirstLetter(this.newShingleType)
        }).then(() => {
          this.isNewShingleType = false;
          this.shingleType = this.newShingleType;
          this.updateCard({
            id: this.card.id,
            doc: {
              shingleType: this.capitalizeFirstLetter(this.newShingleType)
            }
          }).then(() => {
            this.$emit("updateEditCheckList", {
              id: "shingle-type",
              value: this.capitalizeFirstLetter(this.newShingleType)
            });
            this.currentShingleType = this.shingleTypeList.find(
              r => r.displayName === this.shingleType
            );
            this.$f7.preloader.hide();
          });
        });
      }
    },
    saveShingleType(event) {
      if (event === "1") {
        this.isNewShingleType = true;
        this.shingleType = event;
      } else {
        this.shingleType = event;
        this.currentShingleType = this.shingleTypeList.find(
          r => r.displayName === event
        );

        this.updateCard({
          id: this.card.id,
          doc: {
            shingleType: this.capitalizeFirstLetter(event)
          }
        });

        this.$emit("updateEditCheckList", {
          id: "shingle-type",
          value: this.capitalizeFirstLetter(event)
        });
      }
    },
    openEditShingleType() {
      this.$refs.shingleTypePopup.open();
    },
    editedShingleType(value, id) {
      if (this.currentShingleType.id === id) {
        this.shingleType = value;
        this.updateCard({
          id: this.card.id,
          doc: {
            shingleType: this.capitalizeFirstLetter(value)
          }
        });
        this.$emit("updateEditCheckList", {
          id: "shingle-type",
          value: this.capitalizeFirstLetter(value)
        });
      }
    },
    copyRoofingChoicesWebLink(event, link) {
      event.preventDefault();
      const tempInput = document.createElement("input");
      tempInput.value = link;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      this.isCopied = true;
      this.showCopyToast("Copied!");
    },
    showCopyToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      shingleType: {
        required
      },
      shingleColor: {
        required
      }
    };
  },
  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    },

    "displayCard.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.initInvoice(val);
          this.bindShingleTypeListBy({
            prop: "isDeleted",
            val: false,
            op: "=="
          }).then(() => {
            if (this.displayCard.shingleType) {
              this.shingleType = this.displayCard.shingleType;
              this.currentShingleType = this.shingleTypeList.find(
                r => r.displayName === this.displayCard.shingleType
              );
            }
          });
        }
      }
    }
  }
};
</script>
<style scoped>
.relative {
  position: relative;
}

.absolute {
  position: absolute;
  right: 16px;
  bottom: 0px;
  outline: #ff3b30 solid 1px;
  z-index: 100;
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

.space {
  width: 11.3px !important;
}

.icon-plus {
  margin-bottom: 3px;
}

.custom-input ::v-deep .item-title {
  width: 100%;
}
</style>
