<template>
  <div>
    <div
      :id="`scroll_id_${section.index}`"
      :ref="`scroll_id_${section.index}`"
      class="dislay-overlay display-flex justify-content-space-between"
    >
      <div
        v-if="readonly"
        :id="`${section.sectionId}_${section.index}`"
        :ref="`${section.sectionId}_${section.index}`"
        class="section-view"
        v-html="processedHtmlValue"
      ></div>
      <f7-text-editor
        v-else
        :id="`${section.sectionId}_${section.index}`"
        :ref="`${section.sectionId}_${section.index}`"
        :class="`text-editor-comp ${sectionEditor}`"
        style="--f7-text-editor-height: auto; width: 1020px; background: #fff;color: #000;"
        :style="
          section.isDeleted ? 'background-color: var(--f7-color-gray);' : ''
        "
        :value="section.htmlValue"
        mode="popover"
        @texteditor:change="changeValue"
        :custom-buttons="customButtons"
        :buttons="buttons"
        @click.native="handleClickAction"
        @texteditor:blur="handleBlurAction"
      ></f7-text-editor>
    </div>
    <select-color-popup ref="selectColor" />
    <f7-popover
      ref="addLinkPopover"
      @popover:closed="sheetClosed"
      class="popover-add-link"
    >
      <div style="display: flex; align-items: center;">
        <f7-input
          ref="linkInput"
          placeholder="Insert Link"
          type="text"
          :value="insertLink"
          class="input-insert-link"
          @input="insertLink = $event.target.value.trim()"
          style="flex-grow: 1;"
        ></f7-input>
        <f7-button @click="saveLink">Save</f7-button>
      </div>
    </f7-popover>
  </div>
</template>

<script>
import SelectColorPopup from "@/plugins/proposal/components/popup/SelectColorPopup.vue";
import { customButtonForTextFormatBar } from "@/utility/common";
import { buttonsForTextFormatBar } from "@/utility/const";

export default {
  props: {
    section: { type: Object, default: () => {} },
    readonly: { type: Boolean, default: false }
  },

  components: { SelectColorPopup },

  data() {
    return {
      isRestore: false,
      value: "",
      sectionEditor: "section-editor-unfocus",
      currentDoc: null,
      insertLink: "",
      linkRange: "",
      customButtons: customButtonForTextFormatBar(this, true),
      buttons: buttonsForTextFormatBar
    };
  },
  computed: {
    processedHtmlValue() {
      return this.section.htmlValue.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
    }
  },
  methods: {
    isInViewport(parentTop) {
      let element = this.$refs[
        `${this.section.sectionId}_${this.section.index}`
      ].$el;
      if (this.readonly) {
        element = this.$refs[`${this.section.sectionId}_${this.section.index}`];
      }
      if (element) {
        const rect = element.getBoundingClientRect();
        return (
          rect.top + rect.height / 2 - parentTop >= 0 &&
          rect.top - 99 + rect.height / 2 - parentTop <=
            (window.innerHeight || document.documentElement.clientHeight)
        );
      }

      return false;
    },

    changeValue(value) {
      const newVal = value.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      this.$emit("editorChange", {
        index: this.section.index,
        value: newVal
      });
    },
    sheetClosed() {
      this.insertLink = "";
    },
    saveLink() {
      const editor = this.$refs[
        `${this.section.sectionId}_${this.section.index}`
      ];
      if (this.linkRange) {
        editor.$el.f7TextEditor.setSelectionRange(this.linkRange);
        document.execCommand("createLink", true, this.insertLink);
      }
      this.linkRange = "";
      this.$refs.addLinkPopover.close();
    },
    handleClickAction() {
      this.sectionEditor = "section-editor-focus";
      this.$refs.selectColor.closePopup();
    },

    handleBlurAction() {
      this.sectionEditor = "section-editor-unfocus";
    },

    moveSectionToView(section) {
      this.$refs[`scroll_id_${section.index}`].scrollIntoView();
    }
  }
};
</script>

<style lang="scss" scoped>
.text-editor-comp {
  margin: 0px;
}

.text-editor-comp ::v-deep .text-editor-content {
  padding: 0px;
  overflow: hidden;
  background: #fff;
  color: #000;
}

.text-editor-comp ::v-deep font > div {
  margin: -23px 0px;
}

.section-editor-unfocus {
  transition: box-shadow 0.2s ease-in-out;
}

.section-editor-focus {
  box-shadow: 0px 0px 15px gray;
  transition: box-shadow 0.2s ease-in-out;
  z-index: 999;
}

.dislay-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.section-view {
  margin-bottom: 5px;
  --f7-text-editor-height: auto;
  width: 1020px;
  background-color: #fff;
}
.popover-add-link {
  width: 300px;
}
.input-insert-link {
  flex-grow: 1;
  padding: 3px 5px;
  border: 1px solid #b7b7b7;
  margin: 8px;
  border-radius: 3px;
}
.text-editor-comp ::v-deep .text-editor-content a {
  pointer-events: all;
  text-decoration: underline;
}
</style>
