<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="poItems"
      :pageSize="poItems.length"
      class="po-product-table"
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              Vendor:
              <i>{{ (vendorById(vendorId) || {}).companyName }}</i
              ><span>
                / Manufacturer: <i>{{ manufacturers }}</i></span
              >
            </div>
            <f7-row>
              <f7-button
                v-if="!isEditing"
                fill
                @click="excelExport"
                class="button-action margin-right-half"
                >Export to Excel</f7-button
              >
              <f7-button
                v-if="!isEditing"
                fill
                @click="pdfExport"
                class="button-action margin-right-half"
                >Export to Pdf</f7-button
              >

              <f7-button
                v-if="isEditing && isBuildYourOwnTemplate"
                fill
                color="primary"
                @click="popupAddNewProductItem = true"
              >
                Add Product
              </f7-button>
            </f7-row>
          </f7-row>
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item, index }">
        <template v-if="!item.footer">
          <td v-if="$f7.device.desktop || $f7.device.ipad">
            {{ item.sku }}
            <div v-if="item.isDeleted" class="no-product-data">
              This product doesn't exist
            </div>
          </td>
          <td v-if="$f7.device.desktop || $f7.device.ipad">
            {{ item.productItem }}
          </td>
          <td v-else>
            <a @click="openSheet(item)">{{ item.productItem }}</a>
          </td>
          <td v-if="$f7.device.desktop || $f7.device.ipad">
            {{ item.manufacturer || "" }}
          </td>
          <td
            @mouseover="hoverIndex = index"
            @mouseout="hoverIndex = ''"
            style="height: 50px;"
            v-if="isEditQuantity"
          >
            <div
              :class="hoverIndex === index ? 'stepper-raised stepper' : ''"
              class="stepper-qty stepper-small stepper-init margin-top-half"
              style="width: 180px; height: 26px; margin-right: -20px;"
            >
              <div
                :class="
                  hoverIndex === index ? 'display-inline-block' : 'display-none'
                "
                class="stepper-button-minus"
                @click="minusPlusQty(index, 'minus')"
              ></div>
              <CellNumberInput
                style="margin-top: 3px;"
                :stepper="hoverIndex === index ? 'true' : 'false'"
                :value="item.actualQty || 0"
                numberType="decimal"
                :fractionDigits="0"
                placeholder="Actual Quantity"
                :allowNegativeNumber="false"
                :readonly="!(isEditing && !isPlacedOrder)"
                @done="
                  changeProductItem({
                    name: 'actualQty',
                    value: $event,
                    item
                  })
                "
              />
              <div
                :class="
                  hoverIndex === index ? 'display-inline-block' : 'display-none'
                "
                class="stepper-button-plus"
                @click="minusPlusQty(index, 'plus')"
              ></div>
            </div>
          </td>
          <td v-else>
            <CellNumberInput
              style="margin-top: 3px;"
              :stepper="hoverIndex === index ? 'true' : 'false'"
              :value="item.actualQty || 0"
              numberType="decimal"
              :fractionDigits="0"
              placeholder="Actual Quantity"
              :allowNegativeNumber="false"
              :readonly="!(isEditing && !isPlacedOrder)"
              @done="
                changeProductItem({
                  name: 'actualQty',
                  value: $event,
                  item
                })
              "
            />
          </td>
          <td>{{ item.uom }}</td>
          <td>
            {{
              item.unitSize && item.uom && item.packaging
                ? `${item.unitSize} ${item.uom}/${item.packaging}`
                : ""
            }}
          </td>

          <td>
            {{ item.orderQty || 0 }}
          </td>
          <td>
            {{ item.packaging }}
          </td>
          <td v-if="showActualQty(woTaskStatus) && isPlacedOrder">
            <CellNumberInput
              :value="item.receivedQty || 0"
              numberType="decimal"
              :fractionDigits="0"
              placeholder="Received Qty"
              :allowNegativeNumber="false"
              :readonly="isCompleted"
              @done="
                changeProductItem({
                  name: 'receivedQty',
                  value: $event,
                  item
                })
              "
            />
          </td>

          <!-- <td>{{ item.unitPack }}</td> -->
          <td class="numeric-cell">
            {{ item.price | currencyUSD }}
          </td>
          <td class="numeric-cell">
            {{
              ((item.price || 0) *
                getQty(woTaskStatus, item.receivedQty, item.orderQty))
                | currencyUSD
            }}
          </td>
        </template>
        <template v-else>
          <td
            :colspan="tableHeaders.map(r => r.value).indexOf('subTotal') - 1"
          ></td>
          <td class="numeric-cell">
            <strong>Total:</strong>
          </td>
          <td class="numeric-cell">
            <strong>{{ item.total | currencyUSD }}</strong>
          </td>
        </template>
      </template>
    </data-table>

    <AddNewProductItemPopup
      :popupAddNewProductItem="popupAddNewProductItem"
      :productItemList="productItemListRestrictSelected"
      :existingProductItems="poItems"
      @close="popupAddNewProductItem = false"
      @onAddProductItems="addProductItem($event)"
      @removeProduct="handleRemoveProductItem($event)"
    />

    <f7-sheet
      close-by-outside-click
      class="demo-sheet"
      :opened="sheetOpened"
      @sheet:closed="
        sheetOpened = false;
        sheetData = {};
      "
    >
      <f7-toolbar>
        <div class="left"></div>
        <div class="right">
          <f7-link sheet-close>Close</f7-link>
        </div>
      </f7-toolbar>
      <!-- Scrollable sheet content -->
      <f7-page-content>
        <f7-list>
          <f7-list-item title="SKU" :after="sheetData.sku"></f7-list-item>
          <f7-list-item
            title="Product Item"
            :after="sheetData.productItem"
          ></f7-list-item>
          <f7-list-item
            title="Manufacturer"
            :after="sheetData.manufacturer"
          ></f7-list-item>
          <f7-list-item
            title="Ordered Qty"
            :after="sheetData.orderQty"
          ></f7-list-item>
          <f7-list-item
            title="Received Qty"
            :after="sheetData.receivedQty"
          ></f7-list-item>
          <f7-list-item
            title="Packaging"
            :after="sheetData.packaging"
          ></f7-list-item>
          <f7-list-item
            title="Unit Size"
            :after="sheetData.unitSize"
          ></f7-list-item>
          <f7-list-item title="UoM" :after="sheetData.uom"></f7-list-item>

          <f7-list-item
            title="Price"
            :after="sheetData.price | currencyUSD"
          ></f7-list-item>

          <f7-list-item
            title="Sub-Total"
            :after="
              ((sheetData.price || 0) *
                getQty(
                  woTaskStatus,
                  sheetData.receivedQty,
                  sheetData.orderQty
                ))
                | currencyUSD
            "
          ></f7-list-item>
        </f7-list>
      </f7-page-content>
    </f7-sheet>
  </div>
</template>

<script>
// import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/datatables";
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
// import InputIcon from "@/components/icons/InputIcon.vue";
import doExcelExport from "../../utility/excel-export";
import doPdfExport from "../../utility/pdf-export";
import AddNewProductItemPopup from "../popups/AddNewProductItemPopup.vue";
import purchaseOrderMixins from "../../mixins/purchase-order-mixin";

import {
  toDateCalendar,
  toDateFirebase
  // toDisplayDateString
} from "../../../../utility/datetime";

import {
  STATUS_PO_RECEIVED,
  STATUS_PO_PARTIAL_RECEIVED,
  STATUS_PO_CANCEL,
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_WAITING
} from "../../../../utility/const";

import _ from "lodash";
import Vue from "vue";
import { DISALLOWED_QUANTITY_EDIT_STATUSES } from "../../utility/const";

export default {
  props: {
    vendorId: String,
    items: { type: Array, default: () => [] },
    isBuildYourOwn: { type: Boolean, default: false },
    // workOrderTask: {
    //   type: Object,
    //   default: () => null
    // },
    isEditing: {
      type: Boolean,
      default: false
    },
    // isBuildYourOwnTemplate: {
    //   type: Boolean,
    //   default: false
    // },
    isPlacedOrder: {
      type: Boolean,
      default: false
    },
    isCompleted: {
      type: Boolean,
      default: false
    },
    woTaskStatus: { type: String, default: "" },
    roofType: String
  },

  components: {
    DataTable,
    CellNumberInput,
    AddNewProductItemPopup
    // InputIcon
  },
  mixins: [purchaseOrderMixins],
  data() {
    return {
      // items: [],
      searchtext: "",
      popupAddNewProductItem: false,
      editingWorkOrderTask: {},

      estimatedArrival: [],
      deliveredDate: [],
      trackingNumber: "",

      sheetOpened: false,
      sheetData: {},

      STATUS_PO_RECEIVED,
      STATUS_PO_PARTIAL_RECEIVED,
      STATUS_PO_CANCEL,
      STATUS_PO_REQUEST_CANCEL,
      STATUS_PO_DRAFT,
      STATUS_PO_WAITING,
      hoverIndex: ""
    };
  },

  created() {
    if (_.isEmpty(this.vendorList)) {
      this.bindVendorList();
    }
  },

  computed: {
    ...mapGetters("purchase-order/purchase-order-details-page/product-item", [
      "productItemList"
    ]),
    ...mapGetters("purchase-order/purchase-order", ["purchaseOrder"]),
    ...mapGetters("purchase-order/purchase-order-details-page/project", [
      "users"
    ]),
    ...mapGetters("purchase-order/purchase-order-details-page/vendor", [
      "vendorById",
      "vendorList"
    ]),

    isBuildYourOwnTemplate() {
      return this.isBuildYourOwn;
      // return true;
    },

    getQty() {
      return (status, receivedQty, orderQty) => {
        switch (status) {
          case STATUS_PO_DRAFT:
          case STATUS_PO_REQUEST_CANCEL:
          case STATUS_PO_CANCEL:
            return orderQty || 0;

          case STATUS_PO_WAITING:
          case STATUS_PO_RECEIVED:
          case STATUS_PO_PARTIAL_RECEIVED:
            return receivedQty || 0;

          default:
            return 0;
        }
      };
    },

    showActualQty() {
      return woTaskStatus => {
        return (
          woTaskStatus !== STATUS_PO_DRAFT && woTaskStatus !== STATUS_PO_CANCEL
        );
      };
    },

    /**
     * only actived users can be loaded on the list
     */
    // activedUsers() {
    //   return this.users.filter(r => !r.disabled);
    // },

    /**
     * workOrderTaskItemsWithoutTotalRow
     */
    workOrderTaskItemsWithoutTotalRow() {
      return this.items;
    },

    manufacturers() {
      return this.items
        .reduce((array, item) => {
          if (!array.includes(item.manufacturer)) {
            array.push(item.manufacturer);
          }

          return array;
        }, [])
        .join(", ");
    },

    /**
     * poItems
     */
    poItems() {
      const items = this.items;
      const enrichedItems = items.map(item => {
        const foundProductItem = this.productItemListRestrictSelected.find(
          productItem => productItem.id === item.productItemId
        );
        if (foundProductItem) {
          return {
            ...item,
            price: item.pricePO || foundProductItem.price || item.price || 0
          };
        }
        return item;
      });
      const total = enrichedItems.reduce((b, item) => {
        return (
          b +
          (item.price || 0) *
            this.getQty(this.woTaskStatus, item.receivedQty, item.orderQty)
        );
      }, 0);
      return [
        ...enrichedItems,
        {
          footer: true,
          total
        }
      ];
    },

    // allProductItems() {
    //   // console.log("this.purchaseOrder", this.purchaseOrder);
    //   return this.purchaseOrder
    //     ? this.productItems(this.purchaseOrder.priceListId)
    //     : [];
    // },

    /**
     * productItemListRestrictSelected
     */
    productItemListRestrictSelected() {
      return this.productItemList.filter(
        r => r.vendorId === this.vendorId && r.roofTypes.includes(this.roofType)
      );
    },

    /**
     * headers
     */
    headers() {
      let h = [
        {
          text: "SKU",
          align: "left",
          value: "sku"
        },
        {
          text: "Product Item",
          align: "left",
          value: "productItem"
        },
        {
          text: "Manufacturer",
          align: "left",
          value: "manufacturer"
        },
        // {
        //   text: "Prev. Order Qty.",
        //   align: "left",
        //   value: "boughtQuantity",
        //   width: "10%"
        // },
        // {
        //   text: "Remaining Quantity",
        //   align: "left",
        //   value: "remainingQuantity",
        //   width: "10%"
        // },
        {
          text: "Qty.",
          align: this.isEditQuantity ? "custom" : "right",
          style: "width: 180px; text-align: right !important",
          value: "actualQty"
        },
        {
          text: "UoM",
          align: "left",
          value: "uom"
        },
        {
          text: "Unit Size",
          align: "left",
          value: "unitSize"
        },

        {
          text:
            this.isCompleted || this.isPlacedOrder
              ? "Ordered Qty."
              : "Order Qty.",
          align: "left",
          value: "orderQty"
        },
        {
          text: "Packaging",
          align: "left",
          value: "packaging"
        },
        {
          text: "Received Qty.",
          align: "right",
          value: "receivedQty"
        },

        {
          text: "Price",
          align: "right",
          value: "price"
        },
        {
          text: "Sub-Total",
          align: "right",
          value: "subTotal"
        }
      ];

      if (!this.isPlacedOrder) {
        h = h.filter(item => item.value != "receivedQty");
      }

      if (this.isBuildYourOwnTemplate) {
        h = h.filter(
          r => !["boughtQuantity", "remainingQuantity"].includes(r.value)
        );
      }

      if (!(this.$f7.device.desktop || this.$f7.device.ipad)) {
        h = h.filter(
          item => item.value != "sku" && item.value != "manufacturer"
        );
      }
      return h;
    },
    /**
     * tableHeaders
     */
    tableHeaders() {
      let hds = this.headers.slice();
      if (!this.isEditing) {
        hds = hds.filter(
          r => !["remainingQuantity", "boughtQuantity"].includes(r.value)
        );
      }
      return hds;
    },

    /**
     * editing object
     */
    // editingWorkOrderTask() {
    //   return {
    //     ...this.purchaseOrder,
    //     estimatedArrival: this.purchaseOrder.estimatedArrival
    //       ? [new Date(this.purchaseOrder.estimatedArrival)]
    //       : null,
    //     deliveredDate: this.purchaseOrder.deliveredDate
    //       ? [new Date(this.purchaseOrder.deliveredDate)]
    //       : null
    //   };
    // }

    isEditQuantity() {
      return !DISALLOWED_QUANTITY_EDIT_STATUSES.includes(this.woTaskStatus);
    }
  },

  methods: {
    ...mapActions("purchase-order/purchase-order-details-page/vendor", [
      "bindVendorList"
    ]),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    openSheet(data) {
      this.sheetOpened = true;
      this.sheetData = _.cloneDeep(data);
    },

    // ...mapActions("dashboard/work-order", ["updateWorkOrderTask"]),

    // changeWorkOrder(property, value) {
    //   // console.log("changeWorkOrder", property, value);
    //   Vue.set(this.editingWorkOrderTask, property, value);
    // },

    async changeProductItem({ name, value, item }) {
      const arr = _.cloneDeep(this.items);
      const currentProduct = arr.find(
        product => product.productItemId === item.productItemId
      );
      if (name === "actualQty" && currentProduct) {
        let newOrderValue = this.calculateOrderQty(value, currentProduct);
        Vue.set(currentProduct, "orderQty", newOrderValue || 0);
      }
      Vue.set(currentProduct, name, parseFloat(value) || 0);
      this.$emit("onItemsChange", arr);
    },

    // savePurchaseOrder() {
    //   // console.log(this.toDateFirebase(this.editingWorkOrderTask.deliveredDate));
    //   this.updateWorkOrderTask({
    //     id: this.editingWorkOrderTask.id,
    //     doc: {
    //       deliveredDate: this.toDateFirebase(this.deliveredDate || "") || "",
    //       estimatedArrival:
    //         this.toDateFirebase(this.estimatedArrival || "") || "",
    //       items: this.editingWorkOrderTask.items,
    //       trackingNumber: this.trackingNumber
    //     }
    //   });
    // },

    /**
     * getItem
     */
    // getItem(item) {
    // const productItem = this.getProductItem(item.productItemId, false);
    // if (!productItem) {
    //   return this.getItemByEstimate(item.estimateId, item.productItemId);
    // }
    // return productItem;
    //   return item;
    // },

    /**
     * getProductItem
     */
    getProductItem(productItemId, withDefault = {}) {
      return (
        this.productItemList.find(r => r.id === productItemId) || withDefault
      );
    },

    /**
     * getEstimateById
     */
    getEstimateById(estimateId, withDefault = {}) {
      const estimate =
        this.allEstimates.find(r => r.id === estimateId) || withDefault;
      return estimate;
    },

    /**
     * getItemByEstimate
     */
    getItemByEstimate(estimateId, itemId, withDefault = {}) {
      const estimate = this.getEstimateById(estimateId);
      if (estimate) {
        let items = [];
        (estimate.data || []).forEach(e => {
          if (e.items.length) {
            items = items.concat(e.items);
          }
        });
        return items.find(e => e.id === itemId) || withDefault;
      }
      return withDefault;
    },

    /**
     * getItemBoughtQty
     */
    getItemBoughtQty(estimateId, productItemId) {
      const estimate = this.getEstimateById(estimateId);
      if (!estimate || !estimate.data) return;
      const estimateProductItem = estimate.data
        .reduce((all, i) => [...all, ...i.items], [])
        .find(r => r.id === productItemId);
      if (!estimateProductItem || !estimateProductItem.boughtQuantity) return 0;

      return parseFloat(estimateProductItem.boughtQuantity) || 0;
    },

    /**
     * getItemQuality
     */
    getItemQuality(estimateId, productItemId) {
      const estimate = this.getEstimateById(estimateId);
      if (!estimate || !estimate.data) return;
      const estimateProductItem = estimate.data
        .reduce((all, i) => [...all, ...i.items], [])
        .find(r => r.id === productItemId);
      if (!estimateProductItem || !estimateProductItem.QTY) return 0;

      return parseFloat(estimateProductItem.QTY) || 0;
    },

    /**
     * calculateTotalPrice
     */
    // calculateTotalPrice(productItemId, qty, estimateId) {
    //   const price = this.getItem({ productItemId, estimateId }).price || 0;
    //   return price * qty;
    // },

    /**
     * changeworkOrderTaskItem
     */
    changeworkOrderTaskItem(changeEvent) {
      // eslint-disable-next-line no-console
      console.log(changeEvent);
      // const { item, value, name } = changeEvent;
      // if (value === item[name]) return;
      // this.patchData({
      //   taskNumber: this.purchaseOrder.taskNumber,
      //   rowId: item.productItemId,
      //   fieldName: name,
      //   value: value,
      //   operator: "update"
      // });
    },

    /**
     *
     * @param {*} productIds the list of product ids
     * We will do 3 steps:
     *  1. if the new id is not exist => get the real product object and add to the section
     *  2. if the new id is exsit => keep the old product object => do nothing
     *  3. if the old id is not exist on the new list of new id => remove the product from the section
     */
    async addProductItem(products = []) {
      this.$f7.preloader.show();
      const productIds = products.map(item => item.id);
      products = products.filter(item => item.id !== undefined);

      //remove all products
      if (_.isEmpty(productIds)) {
        if (this.items.length > 0) {
          const productsRemove = _.cloneDeep(this.items);
          for (const prod of productsRemove) {
            this.handleRemoveProductItem(prod.id);
          }
        }
        this.popupAddNewProductItem = false;
        this.$f7.preloader.hide();
        return;
      }

      // Step #1:
      const existingProductIds = (this.items || []).map(item => item.id);
      const newProductToAdd = products.filter(
        item => !existingProductIds.includes(item.id)
      );

      // Step #2: keep existing products => do nothing

      // Step #3: remove the product from the section
      const oldProductIds = existingProductIds.filter(
        id => !productIds.includes(id)
      );
      for (const id of oldProductIds) {
        if (!_.isEmpty(id)) {
          this.handleRemoveProductItem(id);
        }
      }

      const temp = _.cloneDeep(this.items);

      // caculate price and save product to section
      newProductToAdd.forEach(product => {
        temp.push({
          ...product,
          productItemId: product.id,
          orderQty: 1,
          actualQty: 1,
          vendorId: product.vendorId || "",
          price: product.price || 0
        });
      });
      this.$emit("onItemsChange", temp);

      this.popupAddNewProductItem = false;

      this.$f7.preloader.hide();
      return;
    },

    /**
     * removeProductItem
     */
    removeProductItem(productItemId) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete item",
        content: "Are you sure you want to delete this item from work order?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.handleRemoveProductItem(productItemId);
          }
        }
      });
    },

    handleRemoveProductItem(productId) {
      _.remove(this.items, item => item.id === productId);
      this.$emit("onItemsChange", _.cloneDeep(this.items));
    },

    /**
     * deleteworkOrderTask
     */
    deleteworkOrderTask() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete purchase order",
        content:
          "Are you sure you want to delete this purchase order from work order?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.patchWorkOrderTasks({
              operator: "remove",
              rowId: app.editingWorkOrderTask.taskNumber
            });
          }
        }
      });
    },

    /**
     * get export content
     */
    getExportContent() {
      return {
        headers: this.headers
          .filter(x =>
            [
              "sku",
              "productItem",
              "manufacturer",
              "orderQty",
              "receivedQty",
              "packaging",

              "unitSize",
              "uom",
              "price",
              "subTotal"
            ].includes(x.value)
          )
          .map(r => ({
            header: r.text
          })),
        values: this.poItems
          .filter(r => !r.footer)
          .map(productItem => {
            // const productItem = this.getItem({
            //   productItemId: r.productItemId,
            //   estimateId: r.estimateId
            // });
            return {
              sku: productItem.sku || "",
              item: productItem.productItem,
              manufacturer: productItem.manufacturer || "",
              orderQty: productItem.orderQty || 0,
              ...(this.isPlacedOrder && {
                receivedQty: productItem.receivedQty || 0
              }),
              packaging: productItem.packaging || "",
              // unitPack: productItem.unitPack || "",
              unitSize: `${productItem.unitSize} ${productItem.uom}/${productItem.packaging}`,
              uom: productItem.uom || "",
              price: this.$options.filters["currencyUSD"](productItem.price),
              subTotal: this.$options.filters["currencyUSD"](
                (productItem.price || 0) *
                  this.getQty(
                    this.woTaskStatus,
                    productItem.receivedQty,
                    productItem.orderQty
                  )
              )
            };
          }),
        totals: [...this.poItems].pop().total,
        purchaseOrderInfo: {
          taskNumber: this.purchaseOrder.taskNumber,
          trackingNumber: this.purchaseOrder.trackingNumber,
          estimatedArrival: this.$options.filters["MMDDYYYY"](
            this.purchaseOrder.estimatedArrival,
            "None"
          ),
          deliveredDate: this.$options.filters["MMDDYYYY"](
            this.purchaseOrder.deliveredDate,
            "None"
          ),
          assignees:
            this.findUserDisplayName(this.purchaseOrder.assigneeIds) || "(None)"
        }
      };
    },
    /**
     * excelExport
     */
    excelExport() {
      doExcelExport(this.getExportContent());
    },

    /**
     * pdfExport
     */
    pdfExport() {
      doPdfExport(this.getExportContent());
    },
    /**
     * changeFieldValue
     * Use to patch value to work order task
     */
    // changeFieldValue(fieldName, value) {
    //   this.patchWorkOrderTasks({
    //     operator: "update",
    //     value,
    //     fieldName,
    //     rowId: this.editingWorkOrderTask.taskNumber
    //   });
    // },

    /**
     * changeDateValue
     * Use to patch date value to work order task
     */
    // changeDateValue(field, changes) {
    //   const dateValue = moment(changes[0]).toISOString();
    //   const currentDateValue = this.editingWorkOrderTask[field]
    //     ? moment(this.editingWorkOrderTask[field][0]).toISOString()
    //     : null;
    //   if (currentDateValue === dateValue) return;
    //   this.patchWorkOrderTasks({
    //     operator: "update",
    //     value: dateValue,
    //     fieldName: field,
    //     rowId: this.editingWorkOrderTask.taskNumber
    //   });
    // }

    /**
     * selectAssignee
     * Select assignee in work order task
     */
    // selectAssignee() {
    //   this.$refs.userSelector.f7SmartSelect.open();
    // },

    // onSelectedAssignees() {
    //   this.changeFieldValue(
    //     "assigneeIds",
    //     this.$refs.userSelector.f7SmartSelect.getValue()
    //   );
    // },

    /**
     * findUserDisplayName
     * Find to display in work order task assignee
     */
    findUserDisplayName(uids) {
      const user = this.users.filter(r => uids && uids.includes(r.uid));
      if (!user) return null;
      return user.map(r => r.displayName).join(", ");
    },
    minusPlusQty(index, type) {
      const sectionProducts = _.cloneDeep(this.items);

      if (type === "minus") {
        const actualQty = (sectionProducts[index].actualQty || 0) - 1;
        if (actualQty < 0) {
          this.changeProductItem({
            name: "actualQty",
            value: 0,
            item: { productItemId: sectionProducts[index].id }
          });
        } else {
          this.changeProductItem({
            name: "actualQty",
            value: actualQty,
            item: { productItemId: sectionProducts[index].id }
          });
        }
      } else if (type === "plus") {
        const actualQty = (sectionProducts[index].actualQty || 0) + 1;
        this.changeProductItem({
          name: "actualQty",
          value: actualQty,
          item: { productItemId: sectionProducts[index].id }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.deleteButton {
  margin-left: 10px;
}

.wrap-text-table ::v-deep table {
  /* min-width: 1200px; */
  table-layout: fixed;
}

.wrap-text-table ::v-deep table td {
  overflow-wrap: anywhere;
}

.po-input-number input {
  width: 100%;
}

// .section-product-table {
//   table:first-child {
//     table-layout: fixed;

//     //Product Item
//     th:nth-child(1) {
//       width: 200px;
//     }
//     // Color
//     th:nth-child(2) {
//       width: 80px;
//     }
//     // Manufacturer
//     th:nth-child(3) {
//       width: 120px;
//     }
//     // Technical Data
//     th:nth-child(4) {
//       width: 200px;
//     }
//     // Waste Factor
//     th:nth-child(5) {
//       width: 80px;
//     }
//     // Qty
//     th:nth-child(6) {
//       width: 100px;
//     }
//     // Package Qty
//     th:nth-child(7) {
//       width: 100px;
//     }
//     // Price
//     th:nth-child(8) {
//       width: 100px;
//     }
//     // Total Cost
//     th:nth-child(9) {
//       width: 100px;
//     }
//     // Price Override
//     th:nth-child(10) {
//       width: 100px;
//     }
//     // Action
//     th:nth-child(11) {
//       width: 70px;
//     }

//     td {
//       word-wrap: break-word;
//     }
//   }
// }

// .section-product-table::v-deep tbody tr td:nth-child(4) {
//   ul {
//     width: 100%;
//     padding-left: 20px;
//     li {
//       a {
//         width: 100%;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//       }
//     }
//   }
// }

@media (max-width: 480px) and (orientation: portrait) {
  .po-product-table ::v-deep table {
    table-layout: fixed;
  }

  .po-product-table::v-deep thead tr {
    th {
      padding: 0px 10px;
    }
    //Product Item
    th:nth-child(1) {
      width: 140px;
    }
    // Package Qty
    th:nth-child(2) {
      width: 100px;
    }
    // Price
    th:nth-child(3) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(4) {
      width: 160px;
    }
    // Total Cost
    th:nth-child(5) {
      width: 120px;
    }
    // Total Cost
    th:nth-child(6) {
      width: 120px;
    }
    // Total Cost
    th:nth-child(7) {
      width: 120px;
    }
    // Action
    th:nth-child(8) {
      width: 70px;
    }
  }

  .po-product-table ::v-deep tbody tr {
    td {
      padding: 0px 10px;
    }
  }
}
</style>
