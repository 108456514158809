<template>
  <f7-popup :opened="popupOpened" @popup:close="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="closePopup">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ title }}
        </f7-nav-title>
        <f7-nav-right> </f7-nav-right>
      </f7-navbar>
      <div>
        <div
          v-if="projectPhotoAlbumList.length === 0 && !loading"
          class="camera"
        >
          <div class="bg-no-data">
            <f7-icon f7="photo_on_rectangle" class="icon-no-data"></f7-icon>
          </div>
          <p>No album to load</p>
        </div>
        <div v-if="projectPhotoAlbumList.length > 0 && !loading">
          <f7-block>
            <div class="img-grid">
              <div v-for="album in projectPhotoAlbumList" :key="album.id">
                <div
                  :style="
                    !album.photoLasted.thumbnailUrl &&
                      'background: var(--f7-color-img-neutral)'
                  "
                  class="img-item"
                  @click.stop="clickAlbum(album)"
                >
                  <img
                    v-if="album.photoLasted.thumbnailUrl"
                    class="image-show"
                    :src="album.photoLasted.thumbnailUrl"
                  />
                  <f7-icon
                    v-if="!album.photoLasted.thumbnailUrl"
                    f7="photo"
                    class="no-img"
                  ></f7-icon>
                </div>
                <div style="font-size: 12px" class="album-name">
                  {{ album.name }}
                </div>
                <div style="font-size: 10px" color="gray">
                  Photos: <b>{{ album.photoTotals }}</b>
                </div>
              </div>
            </div>
          </f7-block>
        </div>
        <project-photo-popup ref="projectPhotoPopup"></project-photo-popup>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProjectPhotoPopup from "./ProjectPhotoPopup.vue";

export default {
  components: {
    ProjectPhotoPopup
  },
  props: {
    title: String
  },
  data: () => {
    return {
      popupOpened: false,
      loading: null
    };
  },
  computed: {
    ...mapGetters("swimlane/album", ["projectPhotoAlbumList"]),
    ...mapGetters("swimlane/card", ["detail"])
  },
  methods: {
    ...mapActions("swimlane/album", ["getProjectPhotoAlbumListByProject"]),

    clickAlbum(album) {
      this.$refs.projectPhotoPopup.openPopup(album.id);
    },
    closePopup() {
      this.popupOpened = false;
      this.loading = null;
    },
    openAlbumPhoto() {
      this.popupOpened = true;
      this.$f7.preloader.show();
      this.loading = true;
      const promises = [];
      promises.push(this.getProjectPhotoAlbumListByProject(this.detail.id));
      Promise.all(promises).then(() => {
        this.loading = false;
        this.$f7.preloader.hide();
      });
    }
  }
};
</script>

<style scoped>
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-no-data {
  font-size: 100px;
  color: var(--f7-color-icon-neutral);
}
.camera {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
  border-radius: 4px;
}
.no-img {
  position: absolute;
  font-size: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--f7-color-icon-neutral);
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: rgba(0, 0, 0, 0.3);
}
.img-item:hover .img-bg-not-select {
  background: rgba(0, 0, 0, 0.2);
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.album-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
