import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const historyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_history`,
    "history"
  );

  return {
    createHistory: historyActions.createDocument
  };
};
