<template>
  <div v-if="isTemplate">
    <div class="row flex-start">
      <image-item-template></image-item-template>
    </div>
  </div>
  <div v-else>
    <div class="row flex-start">
      <image-item
        v-for="item in images"
        :key="item.id"
        :photo="item"
        :projectId="proposal.projectId || ''"
        :checkShowItem="i => checkShowItem(i)"
        @onDelete="photo => onDeletePhoto(photo, 'images')"
        @onChangePhoto="$event => onChangePhoto($event, 'images')"
        @onChangePhotoProp="
          (prop, value) => onChangePhotoProp(prop, value, item.id, 'images')
        "
      ></image-item>
      <image-add-item
        :checkShowItem="i => checkShowItem(i)"
        :projectId="proposal.projectId || ''"
        @onSelectPhoto="photo => onSelectPhoto(photo, 'images')"
      ></image-add-item>
    </div>
  </div>
</template>
<script>
import ImageItem from "../inputs/ImageItem.vue";
import ImageAddItem from "../inputs/ImageAddItem.vue";
import ImageItemTemplate from "../inputs/ImageItemTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: {
    data: Object,
    isTemplate: { type: Boolean, default: false }
  },
  components: {
    ImageItem,
    ImageAddItem,
    ImageItemTemplate
  },
  data: () => {
    return {
      images: []
    };
  },
  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal",
      proposalByNumber: "proposal/proposal/proposalByNumber",
      imagesToDelete: "proposal/proposal/imagesToDelete",
      propertyById: "proposal/property/propertyById",
      estimateById: "proposal/estimate/estimateById"
    })
  },
  methods: {
    ...mapActions({
      copyPhoto: "proposal/photo/copyPhoto",
      setImagesToDelete: "proposal/proposal/setImagesToDelete"
    }),
    checkShowItem(item) {
      let items = this.images || [];
      return !items.some(r => r.id == item.id);
    },
    onSelectPhoto(photo) {
      if (!photo) return;
      this.$f7.preloader.show();
      let items = this.images || [];
      let index = items.findIndex(r => r.id == photo.id);
      if (index > 0) {
        this.showToastMessage("This image already added.");
        return;
      }
      this.copyPhoto({ proposalId: this.proposal.id, photo }).then(
        ({ fullPath, url }) => {
          items.push({
            id: photo.id,
            photoUrl: url,
            fullPath: fullPath,
            notes: ""
          });
          this.$emit("change", { ...this.data, images: items });
          this.$f7.preloader.hide();
        }
      );
    },
    onDeletePhoto(photo) {
      this.setImagesToDelete([...this.imagesToDelete, photo.fullPath]);
      let items = this.images || [];
      items = _.remove(items, item => item.id != photo.id);
      this.$emit("change", { ...this.data, images: items });
    },
    onChangePhoto({ oldPhoto, newPhoto }) {
      if (oldPhoto.id == newPhoto.id) return;
      this.$f7.preloader.show();
      this.copyPhoto({ proposalId: this.proposal.id, photo: newPhoto }).then(
        ({ fullPath, url }) => {
          let photoItem = {
            id: newPhoto.id,
            photoUrl: url,
            fullPath: fullPath,
            notes: ""
          };
          let items = this.images || [];
          let index = items.findIndex(r => r.id == oldPhoto.id);
          if (index < 0) return;
          items[index] = photoItem;
          this.$emit("change", { ...this.data, images: items });
          this.$f7.preloader.hide();
        }
      );
    },
    onChangePhotoProp(prop, value, id) {
      let items = this.images || [];
      let index = items.findIndex(r => r.id == id);
      let photo = { ...items[index] };
      if (!photo) return;
      photo[prop] = value;
      items[index] = photo;
      this.$emit("change", { ...this.data, images: items });
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.images = _.cloneDeep(val.images);
      }
    }
  }
};
</script>
