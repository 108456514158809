<template>
  <div>
    <!-- Customer Type -->
    <f7-block-title>Customer Type</f7-block-title>
    <f7-list>
      <f7-list-item radio radio-icon="start" title="Commercial" name="demo-radio-end"
        :checked="job.customerType === 'commercial'" value="commercial" @change="onCustomerTypeChange"></f7-list-item>
      <f7-list-item radio radio-icon="start" title="Residential" name="demo-radio-end"
        :checked="job.customerType === 'residential'" value="residential" @change="onCustomerTypeChange"></f7-list-item>
      <f7-list-item radio radio-icon="start" title="Multi Family" name="demo-radio-end"
        :checked="job.customerType === 'multifamily'" value="multifamily" @change="onCustomerTypeChange"></f7-list-item>
    </f7-list>

    <div v-if="job.customerType === 'commercial'">
      <f7-block-title>Contact Information </f7-block-title>
      <f7-list media-list>
        <!-- Contact -->
        <contact-name-input ref="selectContact" media-list :displayCard="job"
          :errorMessage="requiredErrorMessage('contactId')" :contactName="contactName" :companyId="company.id"
          :queryFilters="`${company.contactRefs &&
            company.contactRefs.length &&
            job.customerType === 'commercial'
            ? `companyIdRefs:${company.id}`
            : ''
            }`
            " @onSelected="handleSelectedContact" @onDeleteContact="onDeleteContact($event)"
          @onCreated="handleContactCreated" :autoFocus="true" />

        <!-- Phone -->
        <f7-list-item v-for="(phone, index) in (contact || {}).phones" :key="`phone-${index}`">
          <div slot="header">{{ phone.code }}</div>
          <div class="list-item-title" slot="title">{{ phone.value }}</div>
          <f7-icon slot="media" color="primary" material="phone"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Email -->
        <f7-list-item v-for="(email, index) in (contact || {}).emails" :key="`email-${index}`">
          <div slot="header">{{ email.code }}</div>
          <div class="list-item-title" slot="title">{{ email.value }}</div>
          <f7-icon slot="media" color="primary" material="email"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'mailto:' + email.value"
              icon-f7="envelope_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Address -->
        <f7-list-item v-for="(address, index) in (contact || {}).addresses" :key="`address-${index}`">
          <div slot="header">{{ address.code }}</div>
          <div class="list-item-title" slot="title">
            {{ getFullAddress(address) }}
          </div>
          <f7-icon slot="media" color="primary" material="map_fill"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`" icon-f7="placemark_fill"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>

      <!-- Company -->
      <f7-list media-list>
        <company-name-input ref="selectCompany" media-list :displayCard="job" :errorMessage="selectCompanyMessage || requiredErrorMessage('companyId')
          " :companyName="companyName" :contactId="contact.id" :queryFilters="`${contact.companyRefs && contact.companyRefs.length
            ? `contactIdRefs:${contact.id} AND`
            : ''
            } NOT companyTypes:${COMPANY_TYPE_VENDOR}`
            " @onSelected="handleSelectedCompany" @onDeleteCompany="onDeleteContact"
          @onCreated="handleCompanyCreated" />

        <!--Company Phone -->
        <f7-list-item v-for="(phone, index) in (company || {}).phones" :key="`company-phone-${index}`">
          <div slot="header">{{ phone.code }}</div>
          <div class="list-item-title" slot="title">{{ phone.value }}</div>
          <f7-icon slot="media" color="primary" material="phone"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!--Company Address -->
        <f7-list-item v-for="(address, index) in (company || {}).addresses" :key="`company-address-${index}`">
          <div slot="header">{{ address.code }}</div>
          <div class="list-item-title" slot="title">
            {{ getFullAddress(address) }}
          </div>
          <f7-icon slot="media" color="primary" material="map_fill"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`" icon-f7="placemark_fill"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>

      <!-- Property -->
      <f7-list media-list>
        <property-name-input ref="selectProperty" media-list :displayCard="job" :errorMessage="selectPropertyMessage || requiredErrorMessage('propertyId')
          " :propertyName="propertyName" :companyId="company.id" :queryFilters="`${company.propertyRefs && company.propertyRefs.length
            ? `companyIdRefs:${company.id}`
            : ''
            }`
            " @onSelected="handleSelectedProperty" @onDeleteProperty="onDeleteProperty"
          @onCreated="handlePropertyCreated" />

        <!--Property Phone -->
        <f7-list-item v-for="(phone, index) in (property || {}).phones" :key="`property-phone-${index}`">
          <div slot="header">{{ phone.code }}</div>
          <div class="list-item-title" slot="title">{{ phone.value }}</div>
          <f7-icon slot="media" color="primary" material="phone"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!--Property Address -->
        <f7-list-item v-for="(address, index) in (property || {}).addresses" :key="`property-address-${index}`">
          <div slot="header">{{ address.code }}</div>
          <div class="list-item-title" slot="title">
            {{ getFullAddress(address) }}
          </div>
          <f7-icon slot="media" color="primary" material="map_fill"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`" icon-f7="placemark_fill"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>
    </div>

    <div v-if="job.customerType === 'residential'">
      <!-- Contact -->
      <f7-block-title class="display-flex justify-content-space-between">
        Contact Information
      </f7-block-title>
      <f7-list media-list>
        <!-- Contact -->

        <contact-name-input ref="selectContact" media-list :displayCard="job"
          :errorMessage="requiredErrorMessage('contactId')" :contactName="contactName"
          @onSelected="handleSelectedContact" @onDeleteContact="() => {
            setContact({});
            job.contactId = '';
          }
            " @onCreated="handleContactCreated" :autoFocus="true" />
        <!-- Phone -->
        <f7-list-item v-for="(phone, index) in (contact || {}).phones" :key="`phone-${index}`">
          <div slot="header">{{ phone.code }}</div>
          <div class="list-item-title" slot="title">{{ phone.value }}</div>
          <f7-icon slot="media" color="primary" material="phone"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Email -->
        <f7-list-item v-for="(email, index) in (contact || {}).emails" :key="`email-${index}`">
          <div slot="header">{{ email.code }}</div>
          <div class="list-item-title" slot="title">{{ email.value }}</div>
          <f7-icon slot="media" color="primary" material="email"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'mailto:' + email.value"
              icon-f7="envelope_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Address -->
        <f7-list-item v-for="(address, index) in (contact || {}).addresses" :key="`address-${index}`">
          <div slot="header">{{ address.code }}</div>
          <div class="list-item-title" slot="title">
            {{ getFullAddress(address) }}
          </div>
          <f7-icon slot="media" color="primary" material="map_fill"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`" icon-f7="placemark_fill"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>

      <address-input-residential ref="addressInput" :address="job.projectAddress"
        :addressesSuggestion="contact.addresses || []" @input="onInputAddress($event)"
        @select="onSelectAddress($event)"></address-input-residential>
    </div>
    <div v-if="job.customerType === 'multifamily'">
      <!-- Contact -->
      <f7-block-title class="display-flex justify-content-space-between">
        Contact Information
      </f7-block-title>
      <f7-list media-list>
        <!-- Contact -->
        <contact-name-input ref="selectContact" media-list :displayCard="job"
          :errorMessage="requiredErrorMessage('contactId')" :contactName="contactName" :companyId="company.id"
          @onSelected="handleSelectedContact" @onDeleteContact="() => {
            job.contactId = '';
          }
            " @onCreated="handleContactCreated" :autoFocus="true" />

        <!-- Phone -->
        <f7-list-item v-for="(phone, index) in (contact || {}).phones" :key="`phone-${index}`">
          <div slot="header">{{ phone.code }}</div>
          <div class="list-item-title" slot="title">{{ phone.value }}</div>
          <f7-icon slot="media" color="primary" material="phone"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'tel:' + phone.value" icon-f7="phone_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Email -->
        <f7-list-item v-for="(email, index) in (contact || {}).emails" :key="`email-${index}`">
          <div slot="header">{{ email.code }}</div>
          <div class="list-item-title" slot="title">{{ email.value }}</div>
          <f7-icon slot="media" color="primary" material="email"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="'mailto:' + email.value"
              icon-f7="envelope_circle_fill"></f7-link>
          </div>
        </f7-list-item>

        <!-- Address -->
        <f7-list-item v-for="(address, index) in (contact || {}).addresses" :key="`address-${index}`">
          <div slot="header">{{ address.code }}</div>
          <div class="list-item-title" slot="title">
            {{ getFullAddress(address) }}
          </div>
          <f7-icon slot="media" color="primary" material="map_fill"></f7-icon>
          <div slot="after-title">
            <f7-link class="external icon-link" :href="`https://www.google.com/maps/search/?api=1&query=${getFullAddress(address)}`" icon-f7="placemark_fill"></f7-link>
          </div>
        </f7-list-item>
      </f7-list>

      <address-input-residential ref="addressInput" :address="job.projectAddress"
        :addressesSuggestion="contact.addresses || []" @input="onInputAddress($event)"
        @select="onSelectAddress($event)"></address-input-residential>
    </div>
    <!-- Job Info -->
    <f7-block-title>Job Information</f7-block-title>
    <f7-list>
      <!-- Title -->
      <f7-list-input class="first-input" label="Title" type="text" placeholder="Card title" clear-button
        :value="job.title" @input="changeCardValue('title', $event.target.value)" error-message-force
        :error-message="requiredErrorMessage('title')">
        <required-asterisk slot="label"></required-asterisk>
        <f7-icon color="primary" material="title" slot="media"></f7-icon>
      </f7-list-input>

      <!-- Desc -->
      <f7-list-input label="Description" type="textarea" placeholder="Description" :value="job.description"
        @input="$event => changeCardValue('description', $event.target.value)">
        <f7-icon color="primary" material="description" slot="media"></f7-icon>
      </f7-list-input>

      <!-- Due Date -->
      <f7-list-input label="Due Date" type="datepicker" placeholder="MM/DD/YYYY" :calendar-params="{
        backdrop: true,
        openIn: 'customModal',
        dateFormat: 'mm/dd/yyyy',
        disabled: {
          from: null,
          to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
        }
      }" :value="job.dueDate" @calendar:change="
        changeCardValue('dueDate', $event);
      $f7.calendar.close();
      ">
        <f7-icon color="primary" slot="media" f7="timer"></f7-icon>
      </f7-list-input>
    </f7-list>

    <!-- Note -->
    <note-editor :value="job.noteList && job.noteList[0] && job.noteList[0].htmlContent" @onChange="saveNote($event)" :styleProp="`margin-top: 10px`"></note-editor>

  </div>
</template>

<script>
import InputIcon from "./icon/InputIcon.vue";
import AddressInputResidential from "@/components/inputs/AddressInputResidential.vue";
import { mapActions, mapGetters } from "vuex";
import { getFullAddress } from "@/utility/address";
import { toDateFirebase } from "@/utility/datetime";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { firebase, auth } from "@/services/firebase.service";
import commonMixin from "../../../mixin/common";
import {
  BUSINESS_CODE_SERVICE,
  COMPANY_TYPE_VENDOR,
  VALIDATION_MESSAGE
} from "../../../../../utility/const";
import _ from "lodash";
import ContactNameInput from "@/components/inputs/ContactNameInput.vue";
import CompanyNameInput from "@/components/inputs/CompanyNameInput.vue";
import PropertyNameInput from "@/components/inputs/PropertyNameInput.vue";
import NoteEditor from "@/plugins/dashboard/components/note/NoteEditor.vue";

export default {
  components: {
    InputIcon,
    AddressInputResidential,
    ContactNameInput,
    CompanyNameInput,
    PropertyNameInput,
    NoteEditor
  },
  mixins: [commonMixin],
  data() {
    return {
      job: {
        title: "",
        description: "",
        dueDate: [],
        contactId: null,
        companyId: null,
        propertyId: null,
        noteList: [],
        customerType: "commercial",
        priority: 1000,
        status: "open",
        isDraft: false,
        projectAddress: {
          address: "",
          city: "",
          state: "",
          zipcode: "",
          country: ""
        }
      },

      selectCompanyMessage: "",
      selectPropertyMessage: "",
      boardId: "",
      COMPANY_TYPE_VENDOR,

      newContact: false,
      newCompany: false
    };
  },

  computed: {
    ...mapGetters("photo/client", ["contact"]),
    ...mapGetters("photo/client", ["company"]),
    ...mapGetters("photo/client", ["property"]),

    contactName() {
      return this.getContactName(this.contact);
    },

    companyName() {
      return this.company ? this.company.companyName || "" : "";
    },

    propertyName() {
      return this.getPropertyName(this.property);
    },

    requiredErrorMessage() {
      return prop => {
        if (!this.v$.job[prop].$error) return null;
        if (this.v$.job[prop].required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },

    /**
     * Check for this company has property or not
     */
    hasPropertyRefs() {
      return this.company.propertyRefs && this.company.propertyRefs.length > 0;
    }
  },

  methods: {
    ...mapActions("photo/client", [
      "getContact",
      "getCompany",
      "getProperty",
      "setCompany",
      "setProperty",
      "setContact",

      "addPropertyCompanyRelationShip",
      "addCompanyContactRelationShip"
    ]),
    ...mapActions({
      getBoardBys: "photo/board/getBys",
      getColumnBys: "photo/column/getBys"
    }),
    ...mapActions("photo/project", ["createNewProject", "getCardInColumn"]),

    saveNote(event) {
      this.changeCardValue('noteList', event)
    },

    reset() {
      this.job = {
        title: "",
        description: "",
        dueDate: [],
        contactId: null,
        companyId: null,
        propertyId: null,
        noteList: [],
        customerType: "commercial",
        status: "open",
        isDraft: false,
        projectAddress: {
          address: "",
          city: "",
          state: "",
          zipcode: "",
          country: ""
        }
      };

      this.selectCompanyMessage = "";
      this.selectPropertyMessage = "";
      this.boardId = "";

      this.$nextTick(() => {
        if (this.$refs.addressInput) {
          this.$refs.addressInput.clearData();
        }
      });

      this.$refs.addressInput &&
        this.$refs.addressInput.v$ &&
        this.$refs.addressInput.v$.$reset();

      this.v$.$reset();
    },

    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    navigateToSelectCompany() {
      this.$refs.selectCompany.open();
    },

    navigateToSelectProperty() {
      this.$refs.selectProperty.open();
    },

    async handleSelectedContact(contactId) {
      if (contactId) {
        await this.getContact(contactId);
      } else {
        await this.setContact({});
      }
      this.changeCardValue("contactId", contactId);
      if (this.$refs.selectCompany) {
        this.$refs.selectCompany.autoFillCompany();
      }
      this.changeJobTitle();
      this.newContact = false;
    },

    async handleSelectedCompany(companyId) {
      this.$f7.preloader.show();

      this.changeCardValue("companyId", companyId);
      if (!companyId) {
        await this.setCompany({});
      } else {
        await this.getCompany(companyId);
      }

      if (this.$refs.selectProperty) {
        this.$refs.selectProperty.autoFillProperty();
      }

      if (
        this.newContact === true &&
        companyId &&
        this.contact &&
        this.contact.id
      ) {
        await this.addCompanyContactRelationShip({
          companyId,
          contactId: this.contact.id
        });
      }
      if (!this.job.contactId) {
        this.getContactByCompany();
      }

      this.$f7.preloader.hide();
      this.newCompany = false;
    },

    async handleSelectedProperty(propertyId) {
      this.$f7.preloader.show();

      this.changeCardValue("propertyId", propertyId);
      if (!propertyId) await this.setProperty({});
      else await this.getProperty(propertyId);
      this.changeJobTitle();

      if (this.newCompany && propertyId && this.company && this.company.id) {
        await this.addPropertyCompanyRelationShip({
          propertyId,
          companyId: this.company.id
        });
      }

      if (!this.job.companyId) {
        this.getCompanyAndContactByProperty();
      }

      this.$f7.preloader.hide();
    },

    async handleContactCreated(contactId) {
      await this.getContact(contactId);
      this.changeCardValue("contactId", contactId);
      this.handleSelectedCompany("");
      this.$refs.selectCompany.autoFillCompany();
      this.newContact = true;
      this.changeJobTitle();
    },

    async handleCompanyCreated(companyId) {
      this.$f7.preloader.show();

      await this.getCompany(companyId);
      this.changeCardValue("companyId", companyId);
      // this.handlePropertySelected("");
      this.newCompany = true;
      if (_.isEmpty(this.contact)) {
        this.$refs.selectContact.autoFillContact();
      }
      if (companyId && this.contact && this.contact.id) {
        await this.addCompanyContactRelationShip({
          companyId,
          contactId: this.contact.id
        });
      }

      this.$f7.preloader.hide();
    },

    async handlePropertyCreated(propertyId) {
      this.$f7.preloader.show();

      await this.getProperty(propertyId);
      this.changeCardValue("propertyId", propertyId);
      if (propertyId && this.company && this.company.id) {
        await this.addPropertyCompanyRelationShip({
          propertyId,
          companyId: this.company.id
        });
      }
      this.changeJobTitle();

      this.$f7.preloader.hide();
    },
    async onDeleteContact(event) {
      event.stopPropagation();

      await this.setContact({});

      this.changeCardValue("contactId", "");
      this.handleSelectedCompany("");
      this.handleSelectedProperty("");
    },

    async changeJobTitle() {
      const jobTitle = await this.getJobTitle(this.job);
      this.changeCardValue("title", jobTitle);
    },

    changeCardValue(field, value) {
      if (field === "noteList") {
        const noteList = [
          {
            title: "Note",
            code: "note",
            createdAt: firebase.firestore.Timestamp.now(),
            createdBy: auth.currentUser.displayName || auth.currentUser.email,
            createdById: auth.currentUser.uid,
            htmlContent: value
          }
        ];
        this.job[field] = noteList;
      } else {
        this.job[field] = value;
      }
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    async getDefaultBoard() {
      let docs = await this.getBoardBys([
        {
          prop: "businessCode",
          op: "==",
          val: BUSINESS_CODE_SERVICE
        },
        {
          prop: "isDeleted",
          op: "==",
          val: false
        },
        {
          prop: "default",
          op: "==",
          val: true
        }
      ]);
      if (docs.length > 0) {
        return docs[0].id;
      }
    },

    async getDefaultColunm(boardId) {
      let docs = await this.getColumnBys([
        {
          prop: "boardId",
          op: "==",
          val: boardId
        },
        {
          prop: "isDeleted",
          op: "==",
          val: false
        }
      ]);
      if (docs.length > 0) {
        docs.sort((a, b) => a.priority - b.priority);
        return docs[0].id;
      }
    },
    invalid() {
      this.v$.$touch();
      if (
        ["residential", "multifamily"].includes(this.job.customerType) &&
        !_.isEmpty(this.$refs.addressInput)
      ) {
        this.$refs.addressInput.v$.$touch();
        if (this.$refs.addressInput.v$.$invalid) {
          return true;
        }
      }
      return this.v$.$invalid;
    },
    async create() {
      this.$f7.preloader.show();
      let actionsHistory = [];
      actionsHistory.push({
        nextAction: "Job Created From Roofing Cam",
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: ""
      });
      let boardId = await this.getDefaultBoard();
      let columnId = await this.getDefaultColunm(boardId);
      const cardsInColumn = await this.getCardInColumn(columnId);
      const minPriorityInColumn =
        Math.min(...[...cardsInColumn.map(r => r.priority), 0]) || 0;

      let data = {
        ...this.job,
        businessCode: BUSINESS_CODE_SERVICE,
        dueDate: toDateFirebase(this.job.dueDate),
        columnId,
        boardId,
        actionsHistory,
        priority: minPriorityInColumn - 1000
      };

      this.createNewProject(data).then(() => {
        this.reset();
        this.$emit("doClosePopup");
        this.$f7.preloader.hide();
      });
    },

    onCustomerTypeChange(event) {
      this.changeCardValue("customerType", event.target.value);
    },

    onInputAddress({ prop, value }) {
      this.job.projectAddress[prop] = value;
      this.changeJobTitle();
    },
    onSelectAddress(address) {
      this.changeCardValue("projectAddress", address);
      this.changeJobTitle();
    },

    async getCompanyAndContactByProperty() {
      // check if has company then: get first company, catch: return
      if (this.property.companyIdRefs?.length) {
        const companyId = this.property.companyIdRefs[0];
        await this.getCompany(companyId);
        this.job.companyId = companyId;

        // if company has contact, get firt contact, else return
        if (this.company.contactIdRefs.length) {
          const contactId = this.company.contactIdRefs[0];
          await this.getContact(contactId);
          this.job.contactId = contactId;
        }
      }
    },

    async onDeleteProperty(event) {
      event.stopPropagation();
      await this.setProperty({});
      this.job.propertyId = "";
    },

    async getContactByCompany() {
      if (this.company.contactIdRefs?.length) {
        const contactId = this.company.contactIdRefs[0];
        await this.getContact(contactId);
        this.job.contactId = contactId;
      }
    }
  },

  watch: {
    job: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.contactId && this.selectCompanyMessage != "") {
          this.selectCompanyMessage = "";
        }
        if (
          val.contactId &&
          val.companyId &&
          this.selectPropertyMessage != ""
        ) {
          this.selectPropertyMessage = "";
        }
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      job: {
        title: {
          required
        },
        contactId: {
          required
        },
        companyId: {
          required(val) {
            if (this.job.customerType !== "commercial") return true;
            return !!val;
          }
        },
        propertyId: {
          required(val) {
            if (this.job.customerType !== "commercial") return true;
            return !!val;
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-title {
  flex: 1;
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  line-height: 26px;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}

.actions-create {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: -3px;
  right: 16px;
}
</style>
