import Vue from "vue";

export default {
    setObjectList(state, payload) {
        Vue.set(state, "objectList", payload);
    },
    setObjectSubscribe(state, payload) {
        Vue.set(state, "objectSubscribe", payload);
    },
};
