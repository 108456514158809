import Vue from "vue";
import * as types from "./types";

export default {
  setproductDetailId(state, id) {
    state.productDetailId = id;
  },

  [types.SET_SUB_CATEGORY_LIST](state, payload) {
    Vue.set(state, "objectList", payload);
  }
};
