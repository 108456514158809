<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Expenses By Project</f7-nav-title>
      <f7-nav-right>
        <f7-link @click.native="exportExcel()">Export Excel</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-card>
      <f7-card-content>
        <f7-row>
          <f7-col
            class="margin-vertical-half"
            medium="50"
            large="30"
            width="100"
          >
            <div class="display-flex align-items-center">
              <label
                v-show="$f7.device.desktop || $f7.device.ipad"
                class="margin-right"
              >
                Project Name:
              </label>
              <f7-list
                style="flex: 1"
                inline-labels
                class="border-shadow btn-container"
              >
                <f7-list-item link @click.native="openSelectProject">
                  <div slot="title">
                    {{
                      this.projectId
                        ? projectById(this.projectId).title
                        : "Select project"
                    }}
                  </div>
                </f7-list-item>
              </f7-list>
            </div>
          </f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>

    <data-table
      v-if="projectId"
      :headers="headers"
      :items="dataReport || []"
      :pageSize="dataReport.length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-list>
          <f7-searchbar
            placeholder="Search by Submitted By, Expenses Type"
            :clear-button="true"
            disable-button-text
            @input="searchtext = $event.target.value"
            @searchbar:disable="searchtext = ''"
          ></f7-searchbar>
        </f7-list>
      </template>
      <template v-if="dataReport.length > 1" v-slot:body="{ item }">
        <template v-if="!item.footer">
          <td class="label-cell">{{ item.user && item.user.displayName }}</td>
          <td>{{ item.expenseTypeName }}</td>
          <td class="numeric-cell">{{ item.amount | currencyUSD }}</td>
        </template>
        <template v-else>
          <td :colspan="headers.length - 1">
            <b>{{ item.title }}</b>
          </td>
          <td class="numeric-cell">
            <b>{{ item.value | currencyUSD }}</b>
          </td>
        </template>
      </template>
      <template v-else v-slot:body="{}">
        <td class="text-align-center" :colspan="headers.length">No Data!</td>
      </template>
    </data-table>
    <f7-block v-else strong class="text-align-center">
      Please select Project Name first!
    </f7-block>

    <!-- popup -->
    <project-list-popup
      ref="selectProject"
      @onSelected="onChangeProject($event)"
    ></project-list-popup>
  </f7-page>
</template>
<script>
import DataTable from "@/components/datatables";
import ProjectListPopup from "@/components/popups/ProjectListPopup.vue";
import { mapActions, mapGetters } from "vuex";
import { STATUS_EXPENSE_APPROVED } from "../../../utility/const";
import exportExcel from "../exports/expenses-for-project-excel-export";
import { applyFilter } from "@/utility/filter-tools";
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    DataTable,
    ProjectListPopup,
    Menu
  },

  data() {
    return {
      projectId: null,
      searchtext: ""
    };
  },

  computed: {
    ...mapGetters("reports/project", ["projectById", "projectList"]),
    ...mapGetters("reports/user", ["userById", "userList", "userByIds"]),
    ...mapGetters("reports/expenses", ["expenseList"]),
    ...mapGetters("reports/app-constant", ["expenseTypeList"]),

    headers() {
      return [
        {
          text: "Submitted By",
          align: "left",
          type: "text",
          value: "employeeName"
        },
        {
          text: "Expenses Type",
          align: "left",
          type: "text",
          value: "expensesType"
        },
        {
          text: "Amount",
          align: "right",
          type: "number",
          value: "amount"
        }
      ];
    },

    dataReport() {
      let list = applyFilter([...this.expenseList], this.searchtext, [
        i => i.user.displayName,
        "expenseTypeName"
      ]);
      const amount = list.map(r => r.amount) || [];
      const totalAmount = amount.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      list.push({
        footer: true,
        title: "Total Amount",
        value: totalAmount
      });
      return list;
    }
  },

  methods: {
    ...mapActions("reports/user", ["getUserList"]),
    ...mapActions("reports/project", ["bindProjectList"]),
    ...mapActions("reports/expenses", [
      "bindExpenseListBys",
      "unbindExpenseList"
    ]),
    ...mapActions("reports/app-constant", ["bindConstantTypeList"]),

    onChangeProject(id) {
      this.projectId = id;
      this.$f7.preloader.show();
      this.bindExpenseListBys([
        {
          prop: "projectId",
          op: "==",
          val: this.projectId
        },
        {
          prop: "status",
          op: "==",
          val: STATUS_EXPENSE_APPROVED
        }
      ]).then(() => {
        this.$f7.preloader.hide();
      });
    },

    openSelectProject() {
      this.$refs.selectProject.open();
    },

    exportExcel() {
      exportExcel({
        projectName: this.projectId
          ? this.projectById(this.projectId).title
          : "No Data!",
        headers: this.headers,
        values: this.dataReport
      });
    }
  },

  created() {
    this.getUserList();
    this.bindProjectList();
    this.bindConstantTypeList();
  },

  beforeDestroy() {
    this.unbindExpenseList();
  }
};
</script>
<style lang="scss" scoped>
.border-shadow {
  border-radius: 4px;
  box-shadow: 0 1px 3px #b7b7b7;
  box-sizing: border-box;
}
.btn-container {
  min-width: 250px;
  display: flex;

  &::v-deep.list ul {
    width: 100%;
  }
}
</style>
