<template>
  <div>
    <f7-block>
      <filter-by-date
        @searchByDate="searchByDate"
        @exportToQuickbook="exportToQuickbook"
        @exportToExcel="exportToExcel"
      ></filter-by-date>
    </f7-block>

    <invoice-data-table
      :invoiceList="woTaskList"
      :isActiveTab="true"
      @checkBoxChange="checkBoxChange"
    ></invoice-data-table>
  </div>
</template>
<script>
import Vue from "vue";
import FilterByDate from "@/plugins/quickbooks/components/filter/FilterByDate.vue";
import InvoiceDataTable from "@/plugins/quickbooks/components/datatable/InvoiceDataTable.vue";
import { mapActions, mapGetters } from "vuex";
import {
  // toDateCalendar,
  toDateFirebase
  // toDisplayDateString
} from "../../../../utility/datetime";

export default {
  components: {
    FilterByDate,
    InvoiceDataTable
  },
  data: () => {
    return {
      selectedItems: []
    };
  },
  methods: {
    ...mapActions("quickbooks/work-order-task", ["bindWorkOrderTaskBys"]),

    exportToQuickbook() {},
    exportToExcel() {},

    searchByDate(data) {
      this.$f7.preloader.show();
      this.bindWorkOrderTaskBys([
        {
          prop: "taskType",
          val: "purchase-order",
          op: "=="
        },
        {
          prop: "isProjectArchived",
          val: false,
          op: "=="
        },
        {
          prop: "createdAt",
          val: toDateFirebase([data.fromDate]),
          op: ">="
        },
        {
          prop: "createdAt",
          val: toDateFirebase([data.toDate]),
          op: "<="
        }
      ]).then(() => {
        this.$f7.preloader.hide();
      });
    },

    checkBoxChange(checked, id) {
      let item = this.woTaskList.find(item => item.id === id);
      Vue.set(item, "checked", checked);
      this.selectedItems = this.woTaskList.filter(
        item => item.checked === true
      );
    }
  },
  computed: {
    ...mapGetters("quickbooks/work-order-task", ["woTaskList"])

    // invoiceList() {
    //   return [
    //     {
    //       id: "1",
    //       invoiceNumber: "INV-6454135",
    //       invoiceDate: new Date("8/15/2020"),
    //       amount: "6000",
    //       vendorName: "ABC Supply"
    //     },
    //     {
    //       id: "2",
    //       invoiceNumber: "INV-6454136",
    //       invoiceDate: new Date("8/17/2020"),
    //       amount: "8500",
    //       vendorName: "John Manufacturer"
    //     }
    //   ];
    // }
  },
  watch: {
    selectedItems(newVal) {
      this.$emit("selectedItems", newVal);
    }
  }
};
</script>
