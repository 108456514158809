import * as types from "./types";
import Vue from "vue";
import Cookies from "js-cookie";

export default {
  [types.SET_ROUTES](state, routes) {
    Vue.set(state, "routes", routes);
  },

  [types.SET_USER](state, user) {
    for (const key in user) {
      if (user.hasOwnProperty(key)) {
        const value = user[key];
        Vue.set(state.user, key, value);
      }
    }
  },

  [types.SET_USER_CUSTOM_CLAIMS](state, customClaims) {
    for (const key in customClaims) {
      if (customClaims.hasOwnProperty(key)) {
        const value = customClaims[key];
        Vue.set(state.user.customClaims, key, value);
      }
    }
  },

  [types.SET_SETTINGS_THEME_DARK](state, dark) {
    Vue.set(state, "dark", dark);
    Cookies.set("themeDark", dark);
  },

  [types.SET_LIST_PARENT_MENU_ITEMS](state, parentMenuItems) {
    Vue.set(state, "parentMenuItems", parentMenuItems);
  },

  [types.SET_CURRENT_GROUPS](state, currentGroups) {
    Vue.set(state, "currentGroups", currentGroups);
  }
};
