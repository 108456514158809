<template>
  <f7-popup
    :opened="showGlobalSearchPopup"
    @popup:closed="close"
    @popup:opened="handlePopupOpened"
  >
    <div class="page global-search-page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Search Information</f7-nav-title>
        <f7-nav-right></f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search..."
        :clear-button="true"
        :value="searchValue"
        @input="onSearch($event.target.value.trim())"
        @searchbar:disable="onSearch('')"
        @searchbar:clear="onSearch('')"
        class="search-list-popup"
      ></f7-searchbar>

      <div class="page-content" @scroll="onScroll">
        <f7-list class="no-margin">
          <f7-list-group
            media-list
            v-for="(group, index) in searchGroup"
            :key="index"
          >
            <f7-list-item group-title>
              <span
                class="custom-title"
                :style="displayEntity(group.key).style"
              >
                {{ displayEntity(group.key).entityName }}
              </span>
            </f7-list-item>
            <f7-list-item
              no-chevron
              link
              v-for="(item, index) in group.data"
              :key="index"
              @click="goToDetail(item)"
            >
              <template v-if="item.entityName === COLLECTION_OPRATION_CARD">
                <div
                  slot="title"
                  v-html="getHtmlResult('projectName', item)"
                ></div>
                <div slot="text">
                  <template
                    v-if="
                      item.projectBusinessCode === BUSINESS_CODE_COMMERCIAL ||
                        (item.projectBusinessCode === BUSINESS_CODE_SERVICE &&
                          item.customerType === BUSINESS_CODE_COMMERCIAL)
                    "
                  >
                    <div
                      v-if="isIncludeSearchValue('propertyFullAddress', item)"
                      v-html="
                        `Address: ${getHtmlResult('propertyFullAddress', item)}`
                      "
                    ></div>
                    <div
                      v-if="isIncludeSearchValue('companyName', item)"
                      v-html="
                        `Company Name: ${getHtmlResult('companyName', item)}`
                      "
                    ></div>
                  </template>
                  <template
                    v-else-if="
                      item.projectBusinessCode === BUSINESS_CODE_RESIDENTIAL ||
                        (item.projectBusinessCode === BUSINESS_CODE_SERVICE &&
                          item.customerType === BUSINESS_CODE_RESIDENTIAL)
                    "
                  >
                    <div
                      v-if="isIncludeSearchValue('contactName', item)"
                      v-html="
                        `Contact Name: ${getHtmlResult('contactName', item)}`
                      "
                    ></div>
                  </template>
                </div>
                <div
                  slot="after"
                  v-html="getHtmlResult('projectNumber', item)"
                ></div>
                <f7-icon color="primary" f7="bag" slot="media"></f7-icon>
              </template>
              <template
                v-else-if="
                  item.entityName === `${COLLECTION_OPRATION_CARD}_photo`
                "
              >
                <div
                  slot="title"
                  v-html="getHtmlResult('projectName', item)"
                ></div>
                <div slot="text">
                  <template
                    v-if="
                      item.projectPhotoBusinessCode ===
                        BUSINESS_CODE_COMMERCIAL ||
                        (item.projectPhotoBusinessCode ===
                          BUSINESS_CODE_SERVICE &&
                          item.customerType === BUSINESS_CODE_COMMERCIAL)
                    "
                  >
                    <div
                      v-if="isIncludeSearchValue('propertyFullAddress', item)"
                      v-html="
                        `Address: ${getHtmlResult('propertyFullAddress', item)}`
                      "
                    ></div>
                    <div
                      v-if="isIncludeSearchValue('companyName', item)"
                      v-html="
                        `Company Name: ${getHtmlResult('companyName', item)}`
                      "
                    ></div>
                  </template>
                  <template
                    v-else-if="
                      item.projectPhotoBusinessCode ===
                        BUSINESS_CODE_RESIDENTIAL ||
                        (item.projectPhotoBusinessCode ===
                          BUSINESS_CODE_SERVICE &&
                          item.customerType === BUSINESS_CODE_RESIDENTIAL)
                    "
                  >
                    <div
                      v-if="isIncludeSearchValue('contactName', item)"
                      v-html="
                        `Contact Name: ${getHtmlResult('contactName', item)}`
                      "
                    ></div>
                  </template>
                </div>
                <div
                  slot="after"
                  v-html="getHtmlResult('projectNumber', item)"
                ></div>
                <f7-icon color="primary" f7="bag" slot="media"></f7-icon>
              </template>
              <template
                v-else-if="item.entityName === COLLECTION_OPRATION_CONTACT"
              >
                <div
                  slot="title"
                  v-html="getHtmlResult('contactName', item)"
                ></div>
                <f7-icon color="primary" f7="person" slot="media"></f7-icon>
              </template>
              <template
                v-else-if="item.entityName === COLLECTION_OPRATION_COMPANY"
              >
                <div
                  slot="title"
                  v-html="getHtmlResult('companyName', item)"
                ></div>
                <f7-icon color="primary" f7="globe" slot="media"></f7-icon>
              </template>
              <template v-else-if="item.entityName === COLLECTION_ESTIMATE">
                <div
                  slot="title"
                  v-html="getHtmlResult('estimateName', item)"
                ></div>
                <f7-icon
                  color="primary"
                  f7="minus_slash_plus"
                  slot="media"
                ></f7-icon>
                <div
                  slot="after"
                  v-html="getHtmlResult('estimateNumber', item)"
                ></div>
              </template>
              <template v-else-if="item.entityName === COLLECTION_PROPOSAL">
                <div
                  slot="title"
                  v-html="getHtmlResult('projectName', item)"
                ></div>
                <f7-icon color="primary" f7="doc_text" slot="media"></f7-icon>
                <div
                  slot="after"
                  v-html="getHtmlResult('proposalNumber', item)"
                ></div>
              </template>
              <template v-else-if="item.entityName === COLLECTION_CONTRACT">
                <div
                  slot="title"
                  v-html="getHtmlResult('contractTitle', item)"
                ></div>
                <f7-icon color="primary" f7="doc_text" slot="media"></f7-icon>
                <div
                  slot="after"
                  v-html="getHtmlResult('contractNumber', item)"
                ></div>
              </template>
              <template v-else-if="item.entityName === COLLECTION_INVOICE">
                <div slot="title" v-html="getHtmlResult('jobName', item)"></div>
                <f7-icon color="primary" f7="doc_text" slot="media"></f7-icon>
                <div
                  slot="after"
                  v-html="getHtmlResult('invoiceNumber', item)"
                ></div>
              </template>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!searchValue">
          You can search to find Project/Job, Roofing Cam, Contact, Company,
          Estimate, Proposal, Invoice.
        </f7-block>
        <f7-block class="text-align-center" v-show="searchValue && !hasData">
          No Data
        </f7-block>
      </div>
    </div>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  COLLECTION_ESTIMATE,
  COLLECTION_OPRATION_CARD,
  COLLECTION_OPRATION_COMPANY,
  COLLECTION_OPRATION_CONTACT,
  COLLECTION_PROPOSAL,
  COLLECTION_INVOICE,
  COLLECTION_CONTRACT
} from "../../utility/const";
import _ from "lodash";

export default {
  data: () => {
    return {
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_SERVICE,
      COLLECTION_OPRATION_CARD,
      COLLECTION_OPRATION_COMPANY,
      COLLECTION_OPRATION_CONTACT,
      COLLECTION_ESTIMATE,
      COLLECTION_PROPOSAL,
      COLLECTION_INVOICE,
      COLLECTION_CONTRACT,

      searchValue: "",

      allowInfinite: true,
      hasMoreItems: false,
      hasData: false
    };
  },

  computed: {
    ...mapGetters("common/global-search", [
      "showGlobalSearchPopup",
      "searchGroup",
      "hits",
      "nbPages",
      "page"
    ])
  },

  methods: {
    ...mapActions("common/global-search", [
      "openGlobalSearchPopup",
      "searchEntity",
      "loadMoreEntity",
      "resetSearch",
      "getProjectById",
      "getBoardById",
      "getActionById",
      "getInvoiceGroupByProjectId"
    ]),

    displayEntity(entity) {
      switch (entity) {
        case COLLECTION_OPRATION_CARD:
          return {
            entityName: "Project/Job",
            style: "color:#AF3500; background-color:#F5DBB3"
          };
        case `${COLLECTION_OPRATION_CARD}_photo`:
          return {
            entityName: "Roofing Cam",
            style: "color:#840DBC; background-color:#F1E3FF"
          };
        case COLLECTION_OPRATION_CONTACT:
          return {
            entityName: "Contact",
            style: "color:#006A17; background-color:#B5E2BC"
          };
        case COLLECTION_OPRATION_COMPANY:
          return {
            entityName: "Company",
            style: "color:#EC002A; background-color:#FFC6C9"
          };
        case COLLECTION_ESTIMATE:
          return {
            entityName: "Estimate",
            style: "color:#848F13; background-color:#F0F4C3"
          };
        case COLLECTION_PROPOSAL:
          return {
            entityName: "Proposal",
            style: "color:#FF4A11; background-color:#FFE2B7"
          };
        case COLLECTION_CONTRACT:
          return {
            entityName: "Contract",
            style: "color:#6E1400; background-color:#D9BFBC"
          };
        case COLLECTION_INVOICE:
          return {
            entityName: "Invoice",
            style: "color:#4F11FF; background-color:#CCE8FC"
          };
      }
    },

    onSearch(value) {
      this.searchValue = value;
      if (!this.searchValue) {
        this.resetDataSearch();
        return;
      }
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function() {
      const self = this;
      return self
        .searchEntity({
          query: self.searchValue
        })
        .then(() => {
          if (!self.searchValue) {
            self.resetDataSearch();
            return;
          }
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        });
    }, 500),

    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.searchValue) {
        this.resetDataSearch();
        return;
      }
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;
      this.loadMoreEntity({
        query: this.searchValue,
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    isIncludeSearchValue(prop, item) {
      return !!(
        item._highlightResult &&
        item._highlightResult[prop] &&
        item._highlightResult[prop].matchLevel === "full"
      );
    },

    getHtmlResult(prop, item) {
      if (this.isIncludeSearchValue(prop, item)) {
        return `<span>${item._highlightResult[prop].value}</span>`;
      }

      return item[prop] ? `<span>${item[prop]}</span>` : "";
    },

    async goToDetail(item) {
      this.$f7.preloader.show();
      let url = "";
      switch (item.entityName) {
        case COLLECTION_OPRATION_CARD: {
          const project = await this.getProjectById(item.id);
          let board = {};
          let action = {};
          if (project.boardId) {
            board = await this.getBoardById(project.boardId);
          }
          if (!_.isEmpty(project.actions)) {
            action = await this.getActionById(project.actions[0]);
          }

          if (project.businessCode === BUSINESS_CODE_COMMERCIAL) {
            url = `/dashboard/swimlane/commercial/${action.boards[0]}/project/${project.cardNumber}`;
            if (action.boards[0] !== "construction") {
              url += `/action/${action.code}`;
            }
          } else if (project.businessCode === BUSINESS_CODE_RESIDENTIAL) {
            url = `/dashboard/swimlane/residential/${project.cardNumber}/action/${action.code}`;
          } else {
            url = `/dashboard/swimlane/service/${board.boardNumber}/card/${project.cardNumber}`;
          }
          break;
        }
        case `${COLLECTION_OPRATION_CARD}_photo`: {
          url = `/photo/${item.id}`;
          break;
        }
        case COLLECTION_OPRATION_CONTACT: {
          url = `/contactbook/contact/${item.id}`;
          break;
        }
        case COLLECTION_OPRATION_COMPANY: {
          url = `/contactbook/company/${item.id}`;
          break;
        }
        case COLLECTION_ESTIMATE: {
          url = `/estimate/contact/${item.contactId}/${item.estimateNumber}`;
          break;
        }
        case COLLECTION_PROPOSAL: {
          url = `/proposal/${item.proposalNumber}`;
          break;
        }
        case COLLECTION_CONTRACT: {
          url = `/proposal/${item.contractNumber}`;
          break;
        }
        case COLLECTION_INVOICE: {
          if (!item.invoiceGroupId && item.projectId) {
            const invoiceGroup = await this.getInvoiceGroupByProjectId(
              item.projectId
            );
            if (!_.isEmpty(invoiceGroup)) {
              item.invoiceGroupId = invoiceGroup[0].id;
            }
          }

          url = `/invoices-group/${item.invoiceGroupId}/invoice/${item.invoiceNumber}`;
          break;
        }
      }
      this.$f7.views.main.router.navigate(url, {
        pushState: true,
        reloadAll: true
      });
      this.$f7.preloader.hide();
      this.close();
    },

    resetDataSearch() {
      this.allowInfinite = true;
      this.hasMoreItems = false;
      this.hasData = false;
      this.resetSearch();
    },

    close() {
      this.searchValue = "";
      this.resetDataSearch();
      this.openGlobalSearchPopup(false);
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
        if (this.onlyAddNew) {
          this.$el.querySelector(".first-input input").focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.global-search-page {
  ::v-deep .list-group-title {
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    //    background-color: #f9fbe7;

    .custom-title {
      border-radius: 40px;
      padding: 2px 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  ::v-deep .item-link em {
    font-style: normal;
    background: var(--f7-color-hight-light-neutral);
  }
}
</style>
