import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/team_management`,
    "team"
  );

  return {
    bindTeamListBy: ActionsTemplate.bindCollectionBy,
    bindTeamListBys: ActionsTemplate.bindCollectionBys,
    unbindTeamList: ActionsTemplate.unbindCollection,

    bindTeam: ActionsTemplate.bindDocument,
    unbindTeam: ActionsTemplate.unbindDocument
  };
};
