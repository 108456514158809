<template>
  <div>
    <f7-block class="tab-block">
      <f7-segmented strong tag="p">
        <f7-button
          :active="residentialGridCurrentTab == 'all'"
          @click.native="setResidentialGridCurrentTab('all')"
          >All</f7-button
        >
        <f7-button
          v-for="(action, index) in filterActions(boardCode)"
          :key="index"
          :active="residentialGridCurrentTab == action.code"
          @click.native="setResidentialGridCurrentTab(action.code)"
          >{{ index + 1 }}. {{ action.title }}</f7-button
        >
      </f7-segmented>
    </f7-block>
    <f7-tabs animated>
      <residential-grid-view-board
        @openCardDetailsPopup="$emit('openCardDetailsPopup', $event)"
        :projectList="projectList"
      ></residential-grid-view-board>
    </f7-tabs>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ResidentialGridViewBoard from "../../pages/board/ResidentialGridViewBoard.vue";
import { sortByUpdatedOrCreated } from "../../../../utility/date-time-tool";

export default {
  components: {
    ResidentialGridViewBoard
  },
  created() {
    const currentTab = this.$f7route.params.actionCode
      ? parseInt(this.$f7route.params.actionCode)
      : "all";
    this.setResidentialGridCurrentTab(currentTab);
  },
  computed: {
    ...mapGetters("dashboard/project", [
      "filterActions",
      "boardCode",
      "filterCardsGrid",
      "actionByCode"
    ]),
    ...mapGetters("dashboard/common", ["residentialGridCurrentTab"]),

    cardList() {
      return this.filterCardsGrid(this.boardCode);
    },
    projectList() {
      if (this.residentialGridCurrentTab === "all") return this.cardList;
      const action = this.actionByCode(this.residentialGridCurrentTab);
      const cardsByAction = this.filterCards(action.id);
      const arrayUniqueByKey = [
        ...new Set(cardsByAction.map(r => r.id))
      ].map(r => cardsByAction.find(y => y.id === r));
      return arrayUniqueByKey;
    }
  },
  methods: {
    ...mapActions("dashboard/common", ["setResidentialGridCurrentTab"]),
    onTab(obj) {
      this.setResidentialGridCurrentTab(obj.value);
    },
    filterCards(actionId) {
      const cards = this.cardList.filter(card => {
        return card.actions ? card.actions.includes(actionId) : false;
      });
      return sortByUpdatedOrCreated(cards, true);
    }
  }
};
</script>
