<template>
  <div>
    <f7-row>
      <f7-col
        ><f7-row class="justify-content-flex-end">
          <f7-button
            v-show="estimate.businessCode !== BUSINESS_CODE_RESIDENTIAL"
            fill
            :class="isMarginTop ? 'margin-top margin-right' : ''"
            @click="onClickCreateProposal()"
            >Create Proposal
          </f7-button>
        </f7-row></f7-col
      >
    </f7-row>

    <estimate-change-status-popup
      ref="estimateChangeStatusPopup"
      @onClosedPopup="onEstimateChangeStatusPopupClosed"
    ></estimate-change-status-popup>
  </div>
</template>

<script>
import EstimateChangeStatusPopup from "../popup/EstimateChangeStatusPopup.vue";

import { mapActions, mapGetters } from "vuex";
import {
  BUSINESS_CODE_RESIDENTIAL,
  STATUS_ESTIMATE_ACTIVE
} from "../../../../utility/const";

export default {
  components: {
    EstimateChangeStatusPopup
  },

  props: {
    isMarginTop: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      BUSINESS_CODE_RESIDENTIAL
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "estimateActiveListByProject"
    ])
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "updateEstimate",
      "getEstimatesByProject"
    ]),

    onClickCreateProposal() {
      this.$emit("callBack");
      const self = this;
      if (this.estimate.status != STATUS_ESTIMATE_ACTIVE) {
        this.$ri.dialog.openWarningDialog({
          title: "",
          content:
            "Have you completed the Estimate? If so, do you want to update the status to Active?",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
              self
                .updateEstimate({
                  id: self.estimate.id,
                  doc: {
                    status: STATUS_ESTIMATE_ACTIVE
                  }
                })
                .then(() => {
                  return self.getEstimatesByProject(self.estimate.projectId);
                })
                .then(() => {
                  if (self.estimateActiveListByProject.length <= 1) {
                    // go to new proposal
                    self.gotoNewProposal();
                  } else if (self.estimateActiveListByProject.length > 1) {
                    //show popup
                    self.$refs.estimateChangeStatusPopup.open();
                  }
                });
            }
          }
        });
      } else {
        self.gotoNewProposal();
      }
    },

    gotoNewProposal() {
      const {
        clientType,
        clientId,
        buildingName,
        estimateNumber
      } = this.$f7route.params;

      this.$f7router.navigate(
        `/proposal/business/${this.estimate.businessCode}/projectId/${
          this.estimate.projectId
        }/estimateId/${this.estimate.id}/new?${
          clientType ? `clientType=${clientType}&` : ""
        }${
          clientId ? `clientId=${clientId}&` : ""
        }buildingName=${buildingName || ""}&estimateNumber=${estimateNumber}`,
        {
          pushState: true,
          reloadAll: true
        }
      );
    },

    onEstimateChangeStatusPopupClosed() {
      this.gotoNewProposal();
    }
  }
};
</script>

<style></style>
