/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

import _ from "lodash";

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    "board"
  );

  const woTaskActions = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "task"
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "action"
  );

  return {
    bindProjectListBy: projectActions.bindCollectionBy,
    bindProjectListBys: projectActions.bindCollectionBys,
    unbindProjectList: projectActions.unbindCollection,

    getProjectListBys: projectActions.getDocumentBys,

    bindProject: projectActions.bindDocument,
    unbindProject: projectActions.unbindDocument,
    updateProject: projectActions.updateDocument,

    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getTaskById: woTaskActions.getDocument,
    getActionById: actionActions.getDocument,

    getProject({ commit, dispatch }, id) {
      return dispatch("getProjectById", id).then(data => {
        commit(types.SET_PROJECT, data);
        return data;
      });
    },

    setProject({ commit }, data) {
      commit(types.SET_PROJECT, data);
    },

    bindProjectsByProperty(
      { rootGetters, commit, dispatch, getters, rootState, state },
      payload
    ) {
      return dispatch("bindProjectListBy", {
        prop: "propertyId",
        val: payload || "",
        op: "=="
      });
    },

    async getProjectList(
      { rootGetters, commit, dispatch, getters, rootState, state },
      conditions
    ) {
      const data = await dispatch("getProjectListBys", conditions);
      if (_.isEmpty(data)) {
        return;
      }

      commit(types.SET_PROJECT_LIST, data);
    }
  };
};
