import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "",
    title: "Metal Sheet",
    displayRoles: ["owner", "user"],
    name: "metal-sheet-main",
    routes: [
      {
        path: "/assembly-template",
        title: "Metal Templates",
        parent: "product-main",
        name: "assembly-template-main",
        displayRoles: ["owner", "user"],
        icon: "square_arrow_right",
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          return import("../pages/AssemblyTemplateList.vue");
        }),
        master: true,
        detailRoutes: [
          // detail
          {
            path: "/assembly-template/:templateNumber",
            title: "Assembly Template",
            async: checkAuth(() =>
              import("../pages/AssemblyTemplateDetail.vue")
            )
          }
        ]
      },

      {
        path: "/assembly-drawing",
        title: "Metal Drawings",
        parent: "product-main",
        name: "assembly-drawing-main",
        displayRoles: ["owner", "user"],
        icon: "rectangle_stack_badge_plus",
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          return import("../pages/AssemblyDrawingList.vue");
        }),
        master: true,
        detailRoutes: [
          // detail
          {
            path: "/assembly-drawing/:drawingNumber",
            title: "Assembly Drawing",
            async: checkAuth(() => import("../pages/AssemblyDrawingDetail.vue"))
          }
        ]
      }
    ]
  }
];
