<template>
  <table>
    <tr>
      <th
        v-for="(column, index) in headersFitered"
        :class="
          `
            ${textAlign(column.align)}
            ${column.value}`
        "
        :key="index"
      >
        {{ column.text }}
      </th>
    </tr>
    <tr v-for="(item, index) in displayProductList" :key="index">
      <template v-if="!item.isFooter">
        <td
          :key="column.value"
          v-for="column in headersFitered"
          :class="
            `
              ${textAlign(column.align)}`
          "
        >
          <!-- price -->
          <div v-if="column.value === 'price'">
            {{ ((item || {}).amount || 0) | currencyUSD }}
          </div>
          <!-- tax amount -->
          <div v-else-if="column.value === 'taxAmount'">
            {{ ((item || {}).taxAmount || 0) | currencyUSD }}
          </div>
          <!-- technicalData -->
          <ul v-else-if="column.value === 'technicalData'">
            <li v-for="(attach, index) in item.technicalData" :key="index">
              <a class="display-block" @click="openLink(attach.url)">
                {{ attach.name }}
              </a>
            </li>
          </ul>
          <!-- photos -->
          <div v-else-if="column.value === 'photos'">
            <div
              v-show="(item.photos || []).length > 0"
              class="image-preview cursor-pointer"
              @click="clickPhoto(index)"
            >
              <img
                class="image-show"
                :src="((item.photos || [])[0] || {}).thumbnailUrl"
              />
            </div>
            <f7-photo-browser
              :photos="item.photos || []"
              theme="dark"
              :ref="`pageDark_${index}`"
              type="popup"
            ></f7-photo-browser>
          </div>
          <!-- action -->
          <div
            class="cursor-pointer text-align-center"
            v-else-if="column.value === 'action'"
            @click="removeProductItem(item, index)"
          >
            <i class="f7-icons" style="color: red;">trash</i>
          </div>
          <!-- others -->
          <span v-else>{{
            item[column.value] || (column.type == "number" ? 0 : "")
          }}</span>
        </td>
      </template>
      <template v-else>
        <td
          style="background-color: white"
          :colspan="headersFitered.length - 2"
        >
          <b>{{ item.title }}</b>
        </td>
        <td style="background-color: white" colspan="2" class="text-align-end">
          <b>{{ item.value | currencyUSD }}</b>
        </td>
      </template>
    </tr>
  </table>
</template>
<script>
import _ from "lodash";
import calcEstimateMixin from "../../../mixins/calc-estimate-mixin";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [calcEstimateMixin],
  props: {
    building: Object,
    estimate: Object,
    project: Object,
    visibleColumns: { type: Array, default: () => [] },
    preview: { type: Boolean, default: false },
    isTemplate: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions({
      setProposalField: "proposal/proposal/setProposalField"
    }),
    openLink(url) {
      window.open(url, "_blank");
    },
    clickPhoto(id) {
      let browser = this.$refs[`pageDark_${id}`];
      browser && browser[0].open();
    },
    removeProductItem(item) {
      this.itemIdsRemoved.push(item.id);
      this.$emit("removeProductItem");
      this.setProposalField({
        fieldName: "productIdsRemoved",
        value: [
          ...(this.proposal.productIdsRemoved || []).filter(
            r => r.buildingId !== this.currentBuilding.id
          ),
          {
            buildingId: this.currentBuilding.id,
            productIds: _.cloneDeep(this.itemIdsRemoved)
          }
        ]
      });
    },
    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : "text-align-left";
    }
  },
  data() {
    return {
      currentBuilding: {},
      data: [],
      itemIdsRemoved: [],
      headers: [
        {
          text: "Product Item",
          align: "left",
          sortable: false,
          value: "productItem",
          type: "text"
        },
        {
          text: "Color",
          value: "color",
          sortable: false,
          align: "left",
          type: "text"
        },
        {
          text: "Manufacturer",
          value: "manufacturer",
          sortable: false,
          align: "left",
          type: "text"
        },
        {
          text: "Technical Data",
          value: "technicalData",
          sortable: false,
          align: "left",
          type: "meta"
        },
        {
          text: "Photo",
          value: "photos",
          sortable: false,
          align: "left",
          type: "meta"
        },
        {
          text: "Proposal Note",
          value: "proposalNote",
          sortable: false,
          align: "left",
          type: "text"
        },
        {
          text: "QTY",
          value: "orderQty",
          sortable: false,
          align: "right",
          type: "number"
        },
        {
          text: "Price",
          value: "price",
          sortable: false,
          align: "right",
          type: "number"
        },
        {
          text: "Tax Amount",
          value: "taxAmount",
          sortable: false,
          align: "right",
          type: "number"
        }
      ]
    };
  },

  watch: {
    building: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!_.isEmpty(val)) {
          this.currentBuilding = _.cloneDeep(val);
          this.data = _.cloneDeep(
            (this.currentBuilding || {}).productData || []
          );
          if (!_.isEmpty(this.proposal.productIdsRemoved)) {
            this.itemIdsRemoved = _.cloneDeep(
              (
                this.proposal.productIdsRemoved.find(
                  r => r.buildingId === this.currentBuilding.id
                ) || {}
              ).productIds || []
            );
          }
        } else {
          this.data = [];
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    }),
    productList() {
      let products = [];
      (this.data || []).forEach(element => {
        let sectionProduct = element.productList.map(r => ({
          ...r,
          category: element.category,
          tax: true
        }));
        products = products.concat(sectionProduct);
      });
      return products;
    },
    displayProductList() {
      let products = this.items(this.estimate, this.productList);
      if (this.isTemplate) products = [{}];
      products = [
        ...products,
        {
          isFooter: true,
          title: "Sub Total",
          value: this.subTotal(this.estimate, this.productList)
        },
        {
          isFooter: true,
          title: "Tax Amount",
          value: this.taxAmount(
            this.project,
            this.currentBuilding,
            this.estimate,
            this.productList
          )
        },
        {
          isFooter: true,
          title: "Total",
          value: this.total(
            this.project,
            this.currentBuilding,
            this.estimate,
            this.productList
          )
        }
      ];
      products = products.filter(r => !this.itemIdsRemoved.includes(r.id));
      return products;
    },
    headersFitered() {
      let headers =
        this.headers.filter(r => this.visibleColumns.includes(r.value)) || [];
      //if preview mode remove technical data
      if (this.preview) {
        headers = headers.filter(r => r.value != "technicalData");
      }
      if (!this.isTemplate) {
        headers.push({
          text: "",
          value: "action",
          sortable: false,
          align: "right",
          type: "text"
        });
      }
      return headers;
    }
  }
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* Color */
.color {
  width: 80px;
}
/* Photo */
.photos {
  width: 80px;
}
/* QTY */
.QTY {
  width: 80px;
}
/* Price */
.price {
  width: 80px;
}

table td ul {
  padding-inline-start: 20px;
  margin-block-end: 0;
  margin-block-start: 0;
}
table td {
  word-break: break-word;
}

tr:nth-child(even) {
  background-color: #f1f1f1;
}
.image-preview {
  border-radius: 4px;
  width: 70px;
  height: 70px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.text-align-end {
  text-align: end;
}
.image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
