<template>
  <f7-list class="address-input" @keydown.native="onKeydown" @keyup.native="onKeyup">
    <f7-list-input id="address" :value="value" @input="searchAddress($event.target.value)" @focus="geolocate"
      @blur="blurAddressSearch" @input:clear="$emit('input', '')" :placeholder="placeholder || 'Enter address'"
      :label="label || 'Address'" clear-button error-message-force :error-message="errorMessage"><required-asterisk
        slot="label"></required-asterisk>
    </f7-list-input>
    <div class="dropdown-content" v-if="predictions.length > 0 && showPredictions">
      <auto-complete-list :items="predictions" @item:click="onItemClick" @item:blur="onItemBlur" />
    </div>
  </f7-list>
</template>
<script>
import { googlePlaceApiMixin } from "@/services/place.google.service";
import { useVuelidate } from '@vuelidate/core'

import AutoCompleteList from "./AutoCompleteList";
const KEYCODE = {
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  TAB: 9
};
export default {
  components: {
    AutoCompleteList
  },
  props: {
    value: String,
    placeholder: String,
    label: String,
    errorMessage: String
  },
  mixins: [googlePlaceApiMixin],
  data: () => {
    return {
      predictions: [],
      showPredictions: true,
      isKeyUpOrDown: false
    };
  },
  computed: {},
  methods: {
    getInputElement() {
      return this.$el.querySelector("#address input");
    },
    select(place) {
      this.geocode({ placeId: place.place_id }).then(response => {
        this.fillData(response[0]);
      });
    },
    fillData(place) {
      let value = place.formatted_address;
      this.$emit("input", value);
      this.$emit("change", value);
    },
    hide() {
      this.showPredictions = false;
    },
    show() {
      this.showPredictions = true;
    },
    onFocus(event) {
      if (this.query) {
        if (!this.predictions.length) {
          this.onKeyup(event);
        }

        this.show();
      }
    },

    onBlur(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.hide();
      }
    },

    onItemBlur(event) {
      this.onBlur(event);
    },

    onItemClick(event, child) {
      this.select(child.item);
      this.predictions = false;
    },
    async geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          return geolocation;
          // const circle = new google.maps.Circle({
          //   center: geolocation,
          //   radius: position.coords.accuracy
          // });
          // this.$autoComplete.setBounds(circle.getBounds());
        });
      }
    },
    getRequestOptions() {
      const options = {
        input: this.getInputElement().value,
        types: ["geocode"]
      };
      return options;
    },
    search() {
      return new Promise((resolve, reject) => {
        if (!this.getInputElement().value) {
          this.predictions = false;
          this.showPredictions = false;
        } else {
          this.$autoComplete.getPlacePredictions(
            this.getRequestOptions(),
            (response, status) => {
              this.showActivityIndicator = false;
              switch (status) {
                case window.google.maps.places.PlacesServiceStatus.OK:
                  resolve(response);
                  break;
                default:
                  reject(new Error(`Error with status: ${status}`));
              }
            }
          );
        }
      });
    },
    async searchAddress(value) {
      this.$emit("input", value);
      this.search().then(
        response => {
          this.predictions = response;
          this.showPredictions = true;
        },
        error => {
          if (error) {
            this.predictions = false;
          }
        }
      );
    },
    blurAddressSearch() {
      this.v$.$touch();
      if (!this.isKeyUpOrDown) {
        setTimeout(() => this.hide(), 500);
      }
    },
    up() {
      const focused = this.$el.querySelector("a:focus");
      if (focused && focused.parentElement.previousElementSibling) {
        focused.parentElement.previousElementSibling.querySelector("a").focus();
      } else {
        const links = this.$el.querySelectorAll("a");
        links[links.length - 1].focus();
      }
    },

    down() {
      const focused = this.$el.querySelector("a:focus");

      if (focused && focused.parentElement.nextElementSibling) {
        focused.parentElement.nextElementSibling.querySelector("a").focus();
      } else {
        this.$el.querySelector("a").focus();
      }
    },

    onKeydown(event) {
      this.isKeyUpOrDown = true;
      const element = this.$el.querySelector("[tabindex]");

      if (element && event.keyCode === KEYCODE.TAB) {
        event.preventDefault() && element.focus();
      }
      this.isKeyUpOrDown = false;
    },

    onKeyup(event) {
      this.isKeyUpOrDown = true;
      switch (event.keyCode) {
        case KEYCODE.ENTER:
        case KEYCODE.SPACE:
          if (this.$el.querySelector(".is-focused")) {
            this.$el
              .querySelector(".is-focused a")
              .dispatchEvent(new Event("mousedown"));
          }
          return;
        case KEYCODE.ESC:
          this.hide();
          this.getInputElement().blur();
          event.preventDefault();
          return;
        case KEYCODE.UP:
          this.up();
          event.preventDefault();
          return;
        case KEYCODE.DOWN:
          this.down();
          event.preventDefault();
          return;
      }

      this.search().then(
        response => {
          this.predictions = response;
          this.showPredictions = true;
        },
        error => {
          if (error) {
            this.predictions = false;
          }
        }
      );

      this.isKeyUpOrDown = false;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations: {}
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  position: absolute;
  background-color: #f6f6f6;
  width: 85%;
  min-width: 200px;
  max-width: 600px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1000;
  top: 45px;
  left: 18px;
  background-color: #fff;
}

.row {
  background-color: #fff;
}

.address-input {
  margin: 0;
}

.space {
  width: 1em;
  height: 1em;
}
</style>
<style>
.address-input.list ul::after {
  display: none;
}
</style>
