<template>
  <div>
    <f7-list accordion-list>
      <f7-list-item
        accordion-item
        title="Building Information"
        id="building-information"
        @click="handleScrollTop"
      >
        <f7-accordion-content>
          <f7-list media-list class="text-content">
            <f7-list-item
              header="Building/Section#"
              :title="currentBuilding.buildingName"
            >
              <input-icon slot="media" icon="building_2_fill"></input-icon>
            </f7-list-item>
            <f7-list-item
              header="Number of Building/Sections"
              :title="currentBuilding.numberOfCopy"
            >
              <input-icon slot="media" icon="number"></input-icon>
            </f7-list-item>
            <f7-list-item
              header="Slope Type"
              :title="slopeType(currentBuilding.slopeType).displayName"
            >
              <input-icon slot="media" icon="arrow_down_right"></input-icon>
            </f7-list-item>
            <f7-list-item
              header="Roof Type"
              :title="roofTypeBy(currentBuilding.roofType).displayName"
            >
              <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Work Type"
              :title="workType(currentBuilding.workType).displayName"
            >
              <input-icon slot="media" icon="keyboard"></input-icon>
            </f7-list-item>

            <f7-list-item header="Price List" :title="priceListText">
              <input-icon slot="media" icon="square_list"></input-icon>
            </f7-list-item>

            <f7-list-item header="Manufacturers" :title="manufacturerList">
              <input-icon slot="media" icon="briefcase_fill"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Warranty"
              :title="
                tpoWarrantyOption(currentBuilding.warrantyType).displayName
              "
            >
              <input-icon
                slot="media"
                icon="pencil_ellipsis_rectangle"
              ></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Warranty Notes"
              :title="currentBuilding.warrantyNotes"
            >
              <input-icon slot="media" icon="text_justifyleft"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Type of substrate"
              :title="substrate(currentBuilding.substrate).displayName"
            >
              <input-icon slot="media" icon="map"></input-icon>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item accordion-item title="Description" @click="handleScrollTop">
        <f7-accordion-content>
          <f7-list media-list class="text-content">
            <f7-list-input
              type="textarea"
              :value="currentBuilding.description"
              readonly
              resizable
            ></f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Measurement Information"
        @click="handleScrollTop"
      >
        <f7-accordion-content>
          <f7-list media-list class="text-content">
            <f7-list-item
              header="Field Area (SQ)"
              :title="currentBuilding.fieldArea || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Panel Linear (LF)"
              :title="currentBuilding.panelLinear || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Ridge Length (LF)"
              :title="currentBuilding.ridgeLength || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Hip Length (LF)"
              :title="currentBuilding.hipLength || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Perimeter (LF)"
              :title="currentBuilding.perimeter || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Side Wall (LF)"
              :title="currentBuilding.sideWall || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Head Wall (LF)"
              :title="currentBuilding.headWall || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Valley Length (LF)"
              :title="currentBuilding.valleyLength || '0'"
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Eave (LF)"
              :title="
                (currentBuilding.eaveLength || 0) * currentBuilding.numberOfCopy
              "
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              header="Rake (LF)"
              :title="
                (currentBuilding.rakeLength || 0) * currentBuilding.numberOfCopy
              "
            >
              <input-icon slot="media" icon="arrow_right_square"></input-icon>
            </f7-list-item>

            <f7-list-item
              title="Flute Fill"
              checkbox
              disabled
              :checked="currentBuilding ? currentBuilding.flute_fill : false"
            >
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Metal Diagram"
        @click="handleScrollTop"
      >
        <f7-accordion-content>
          <f7-list
            media-list
            class="text-content"
            :inline-labels="$f7.device.desktop || $f7.device.ipad"
          >
            <f7-list-item
              v-for="(assembly, index) in currentBuilding.assemblyList"
              :key="index"
              @click="openAssemblyPopup(assembly)"
            >
              <a class="cursor-pointer" slot="title">
                {{
                  assembly.drawingName ||
                    assemblyById(assembly.assemblyItem).displayName
                }}
              </a>
              <div slot="footer">
                {{ assemblyById(assembly.assemblyItem).displayName }}
              </div>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Core Sample Photo"
        @click="handleScrollTop"
      >
        <f7-accordion-content>
          <f7-list
            media-list
            class="text-content"
            :inline-labels="$f7.device.desktop || $f7.device.ipad"
            v-if="currentBuilding.workType != WORK_TYPE_NEW"
          >
            <f7-list-item
              v-for="(item, index) in currentBuilding.attachmentFiles"
              :key="index"
              @click="openLink(item.url)"
            >
              <a class="cursor-pointer" slot="title">
                {{ item.fileName }}
              </a>
              <div slot="footer">
                Uploaded on {{ toDisplayDateString(item.createdAt) }} by
                {{ item.createdBy }}
              </div>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="onPopupClosed"
      @popup:opened="onPopupOpened"
      tablet-fullscreen
    >
      <f7-page>
        <f7-navbar
          :title="
            assembly.assemblyName ||
              assemblyById(assembly.assemblyItem).displayName
          "
        >
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <downspout-transition
            v-if="assembly.assemblyItem === 'downspoutTransition'"
            :ref="`downspoutTransition`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_downspoutTransition`"
          />

          <!-- diagram 2 -->
          <box-downspout
            v-if="assembly.assemblyItem === 'boxDownspout'"
            :ref="`boxDownspout`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_boxDownspout`"
          />

          <!-- diagram 3 -->
          <collector-head
            v-if="assembly.assemblyItem === 'collectorHead'"
            :ref="`collectorHead`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_collectorHead`"
          />

          <!-- diagram 4 -->
          <embedded
            v-if="assembly.assemblyItem === 'embedded'"
            :ref="`embedded`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_embedded`"
          />

          <!-- diagram 5 -->
          <gutter-system
            v-if="assembly.assemblyItem === 'gutterSystem'"
            :ref="`gutterSystem`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_gutterSystem`"
          />

          <!-- diagram 6 -->
          <headwall-flashing
            v-if="assembly.assemblyItem === 'headwallFlashing'"
            :ref="`headwallFlashing`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_headwallFlashing`"
          />

          <!-- diagram 7 -->
          <counter-flashing
            v-if="assembly.assemblyItem === 'counterFlashing'"
            :ref="`counterFlashing`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_counterFlashing`"
          />

          <!-- diagram 8 -->
          <coping-with-cleat-fastener
            v-if="assembly.assemblyItem === 'copingWithCleatFastener'"
            :ref="`copingWithCleatFastener`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_copingWithCleatFastener`"
          />

          <!-- diagram 9 -->
          <pitch-pan
            v-if="assembly.assemblyItem === 'pitchPan'"
            :ref="`pitchPan`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_pitchPan`"
          />

          <!-- diagram 10 -->
          <coping-with-cleat
            v-if="assembly.assemblyItem === 'copingWithCleat'"
            :ref="`copingWithCleat`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_copingWithCleat`"
          />

          <!-- diagram 11 -->
          <coping-with-front-back-cleat
            v-if="assembly.assemblyItem === 'copingWithFrontBackCleat'"
            :ref="`copingWithFrontBackCleat`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_copingWithFrontBackCleat`"
          />

          <!-- diagram standing seam -->
          <valley
            v-if="assembly.assemblyItem === 'valley'"
            :ref="`valley`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_valley`"
          />

          <sidewall-or-headwall
            v-if="assembly.assemblyItem === 'sidewallOrHeadwall'"
            :ref="`sidewallOrHeadwall`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_sidewallOrHeadwall`"
          />

          <ridge
            v-if="assembly.assemblyItem === 'ridge'"
            :ref="`ridge`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_ridge`"
          />

          <zee-trim
            v-if="assembly.assemblyItem === 'zeeTrim'"
            :ref="`zeeTrim`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_zeeTrim`"
          />

          <extended-eave-or-rake
            v-if="assembly.assemblyItem === 'extendedEaveOrRake'"
            :ref="`extendedEaveOrRake`"
            :data="assembly.measurement || {}"
            :buildingId="`${currentBuilding.id}_extendedEaveOrRake`"
          />
        </f7-block>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";

import CopingWithCleatFastener from "../canvas/CopingWithCleatFastener.vue";
import CopingWithCleat from "../canvas/CopingWithCleat.vue";
import CopingWithFrontBackCleat from "../canvas/CopingWithFrontBackCleat.vue";
import Embedded from "../canvas/Embedded.vue";
import CollectorHead from "../canvas/CollectorHead.vue";
import DownspoutTransition from "../canvas/DownspoutTransition.vue";
import BoxDownspout from "../canvas/BoxDownspout.vue";
import GutterSystem from "../canvas/GutterSystem.vue";
import HeadwallFlashing from "../canvas/HeadwallFlashing.vue";
import PitchPan from "../canvas/PitchPan.vue";
import CounterFlashing from "../canvas/CounterFlashing.vue";

// Standing Seam
import Valley from "../canvas/Valley.vue";
import SidewallOrHeadwall from "../canvas/SidewallOrHeadwall.vue";
import Ridge from "../canvas/Ridge.vue";
import ZeeTrim from "../canvas/ZeeTrim.vue";
import ExtendedEaveOrRake from "../canvas/ExtendedEaveOrRake.vue";

import {
  ATTACHMENT_TYPE_TPO_MA_LIST,
  ATTACHMENT_TYPE_TPO_FA_LIST,
  WORK_TYPE_NEW
} from "../../../../utility/const";
import { toDisplayDateString } from "../../../../utility/datetime";

import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    InputIcon,

    CopingWithCleatFastener,
    CopingWithCleat,
    CopingWithFrontBackCleat,
    Embedded,
    CollectorHead,
    DownspoutTransition,
    BoxDownspout,
    GutterSystem,
    HeadwallFlashing,
    PitchPan,
    CounterFlashing,

    Valley,
    SidewallOrHeadwall,
    Ridge,
    ZeeTrim,
    ExtendedEaveOrRake
  },

  data() {
    return {
      WORK_TYPE_NEW,
      toDisplayDateString,

      popupOpened: false,
      assembly: {}
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),
    ...mapGetters("estimate/estimate-page/price-list", ["priceList"]),

    ...mapGetters("common/app-constant", [
      "roofTypeBy",
      "slopeType",
      "workType",
      "tpoWarrantyOption",
      "membraneAttachmentType",
      "membraneThickness",
      "fasteningPattern",
      "insulationLayers",
      "substrate"
    ]),

    ...mapGetters("estimate/estimate-page/assembly-drawing", {
      assemblyById: "assemblyDrawingById"
    }),

    manufacturerList() {
      let manus = (this.currentBuilding.productData || []).reduce(
        (manufacturers, r) => {
          let mns = [
            ...new Set(
              r.productList.map(x => (x || {}).manufacturer).filter(z => z)
            )
          ];
          _.remove(mns, r => manufacturers.includes(r));
          return (manufacturers || []).concat(mns || []);
        },
        []
      );
      return manus.join(", ");
    },

    priceListText() {
      if (
        _.isEmpty(this.priceList.priceListNumber) &&
        _.isEmpty(this.priceList.displayName)
      ) {
        return "";
      }

      return this.priceList.priceListNumber + ": " + this.priceList.displayName;
    },

    insulationPatternText() {
      return layer => {
        if (
          ATTACHMENT_TYPE_TPO_MA_LIST.includes(
            layer.insulationAttachmentType.value
          )
        ) {
          return "; Screws Per Board: " + layer.screwsPerBoard;
        }

        if (
          ATTACHMENT_TYPE_TPO_FA_LIST.includes(
            layer.insulationAttachmentType.value
          )
        ) {
          return "; Coverage Rate: " + layer.coverageRate;
        }

        return "";
      };
    }
  },

  methods: {
    handleScrollTop() {
      const targetElement = document.getElementById("building-information");
      targetElement.scrollIntoView({ behavior: "smooth" });
    },
    openLink(url) {
      window.open(url, "_blank");
    },

    openAssemblyPopup(assembly) {
      this.assembly = _.cloneDeep(assembly);
      this.popupOpened = true;
    },

    onPopupClosed() {
      this.popupOpened = false;
      this.assembly = {};
    },

    onPopupOpened() {
      this.drawAssemply(this.assembly);
    },

    drawAssemply(assembly) {
      switch (assembly.assemblyItem) {
        case "collectorHead":
          this.$refs[`collectorHead`].initCanvas();
          this.$refs[`collectorHead`].drawAll();
          break;

        case "embedded":
          this.$refs[`embedded`].initCanvas();
          this.$refs[`embedded`].drawAll();
          break;

        case "copingWithCleatFastener":
          this.$refs[`copingWithCleatFastener`].initCanvas();
          this.$refs[`copingWithCleatFastener`].drawAll();
          break;

        case "copingWithCleat":
          this.$refs[`copingWithCleat`].initCanvas();
          this.$refs[`copingWithCleat`].drawAll();
          break;

        case "copingWithFrontBackCleat":
          this.$refs[`copingWithFrontBackCleat`].initCanvas();
          this.$refs[`copingWithFrontBackCleat`].drawAll();
          break;

        case "downspoutTransition":
          this.$refs[`downspoutTransition`].initCanvas();
          this.$refs[`downspoutTransition`].drawAll();
          break;

        case "boxDownspout":
          this.$refs[`boxDownspout`].initCanvas();
          this.$refs[`boxDownspout`].drawAll();
          break;

        case "gutterSystem":
          this.$refs[`gutterSystem`].initCanvas();
          this.$refs[`gutterSystem`].drawAll();
          break;

        case "headwallFlashing":
          this.$refs[`headwallFlashing`].initCanvas();
          this.$refs[`headwallFlashing`].drawAll();
          break;

        case "pitchPan":
          this.$refs[`pitchPan`].initCanvas();
          this.$refs[`pitchPan`].drawAll();
          break;

        case "counterFlashing":
          this.$refs[`counterFlashing`].initCanvas();
          this.$refs[`counterFlashing`].drawAll();
          break;

        case "valley":
          this.$refs[`valley`].initCanvas();
          this.$refs[`valley`].drawAll();
          break;

        case "sidewallOrHeadwall":
          this.$refs[`sidewallOrHeadwall`].initCanvas();
          this.$refs[`sidewallOrHeadwall`].drawAll();
          break;

        case "ridge":
          this.$refs[`ridge`].initCanvas();
          this.$refs[`ridge`].drawAll();
          break;

        case "zeeTrim":
          this.$refs[`zeeTrim`].initCanvas();
          this.$refs[`zeeTrim`].drawAll();
          break;

        case "extendedEaveOrRake":
          this.$refs[`extendedEaveOrRake`].initCanvas();
          this.$refs[`extendedEaveOrRake`].drawAll();
          break;
      }
    }
  }
};
</script>

<style scoped>
.text-content ::v-deep .item-title-row {
  margin-top: 10px;
}
</style>
