/* eslint-disable no-unused-vars */
import {
  FirebaseActions,
  firebase
} from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import algoliaService from "../../../../services/algolia.service";
import * as types from "./types";
import _ from "lodash";

export default tenant => {
  const contactActions = new FirebaseActions(
    `/system_client/${tenant}/operation_contact`,
    "contact"
  );

  return {
    // * Get contact doc only
    getContact: contactActions.getDocument,
    getContactBys: contactActions.getDocumentBys,
    createContactDoc: contactActions.createDocument,
    updateContactDoc: contactActions.updateDocument,

    async createContact({ dispatch }, contact) {
      const id = await dispatch("createContactDoc", contact);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_contact",
        id
      });
      return id;
    },

    async updateContact({ dispatch }, { id, doc }) {
      await dispatch("updateContactDoc", { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_contact",
        id
      });
      return id;
    },

    /**
     * ! Importance!!! This action gets data and sets it on Vuex store.
     * * If get data only, using 'getContactBys' instead
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    async getContactListBys({ commit, dispatch }, payload) {
      const contactList = await dispatch("getContactBys", payload);
      commit(types.SET_CONTACT_LIST, contactList || []);
      return contactList;
    },

    /**
     * ! Importance!!! This action gets data and sets it on Vuex store.
     * * If get data only, using 'getContact' instead
     * @param {*} param0
     * @param {*} id
     * @returns
     */
    async getContactById({ commit, dispatch }, id) {
      if (!id) {
        return {};
      }
      const contactDoc = await dispatch("getContact", id);
      commit(types.SET_CONTACT, contactDoc || {});
      const companyRefs = _.cloneDeep((contactDoc || {}).companyRefs || []);
      commit(types.SET_COMPANY_REFS, companyRefs);
      return contactDoc;
    },

    clearContact({ commit }) {
      commit(types.SET_CONTACT, {});
      commit(types.SET_COMPANY_REFS, []);
    },

    /**
     * Add the company ref of contact to Vuex store
     * @param {*} param0
     * @param {*} company
     */
    addCompanyRefs({ commit }, company) {
      commit(types.ADD_COMPANY_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: company.id,
        companyName: company.companyName
      });
    },

    removeCompanyRefs({ commit }, companyId) {
      commit(types.REMOVE_COMPANY_REFS, companyId);
    },

    clearCompanyRefs({ commit }) {
      commit(types.SET_COMPANY_REFS, []);
    },

    setIsEditContact({ commit }, value) {
      commit(types.SET_IS_EDIT_CONTACT, value);
    },

    /**
     * * Calculate the company refs (add or remove) and save to database
     * @param {*} param0
     * @param {*} param1
     * @returns
     */
    async updateCompanyRefs({ dispatch, getters }) {
      if (!_.isEmpty(getters.contact)) {
        const latestContact = await dispatch("getContact", getters.contact.id);
        if (_.isEmpty(latestContact)) {
          return;
        }

        // * get removed company refs
        let refsRemoved = _.differenceWith(
          latestContact.companyRefs,
          getters.companyRefs,
          _.isEqual
        );
        for (const ref of refsRemoved) {
          // get company data
          const company = await dispatch(
            "contact-book/company/getCompany",
            ref.id,
            { root: true }
          );

          const contactRefs = ((company || {}).contactRefs || []).filter(
            item => item.id !== latestContact.id
          );

          await dispatch(
            "contact-book/company/updateCompany",
            {
              id: ref.id,
              doc: {
                contactRefs
              }
            },
            { root: true }
          );
        }

        // * get added company refs
        const refsAdded = _.differenceWith(
          getters.companyRefs,
          latestContact.companyRefs,
          _.isEqual
        );
        for (const ref of refsAdded) {
          // get company data
          const company = await dispatch(
            "contact-book/company/getCompany",
            ref.id,
            { root: true }
          );

          const contactRefs = _.cloneDeep((company || {}).contactRefs || []);
          if (
            _.isEmpty(contactRefs.find(item => item.id === latestContact.id))
          ) {
            contactRefs.push({
              id: latestContact.id,
              createdRefAt: firebase.firestore.Timestamp.now(),
              contactName: latestContact.contactName
                ? latestContact.contactName
                : `${latestContact.firstName} ${latestContact.lastName}`,
              phones: latestContact.phones || []
            });

            await dispatch(
              "contact-book/company/updateCompany",
              {
                id: ref.id,
                doc: {
                  contactRefs
                }
              },
              { root: true }
            );
          }
        }

        // * update company refs
        await dispatch("updateContactDoc", {
          id: latestContact.id,
          doc: {
            companyRefs: getters.companyRefs
          }
        });
      }
    },

    /**
     * * Remove contact ref of the company when delete contact
     * @param {*} param0
     */
    async removeContactRefForCompany({ dispatch, getters }) {
      const latestContact = await dispatch("getContact", getters.contact.id);
      if (_.isEmpty(latestContact)) {
        return;
      }

      const refsRemoved = latestContact.companyRefs || [];
      for (const ref of refsRemoved) {
        // get company data
        const company = await dispatch(
          "contact-book/company/getCompany",
          ref.id,
          { root: true }
        );

        const contactRefs = ((company || {}).contactRefs || []).filter(
          item => item.id !== latestContact.id
        );

        await dispatch(
          "contact-book/company/updateCompany",
          {
            id: ref.id,
            doc: {
              contactRefs
            }
          },
          { root: true }
        );
      }
    },

    async deleteContact({ dispatch }, id) {
      await dispatch("updateContactDoc", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });

      return await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_contact",
        id
      });
    }
  };
};
