<template>
  <div>
    <f7-list :inset="$device.desktop" :class="typeSwitchScreen !== 'step' && 'container-info-content'">
      <f7-list media-list>
        <!-- slope type -->
        <f7-list-input type="select" label="Slope Type" :value="currentBuilding.slopeType"
          @change="onSlopeTypeChanged($event.target.value)" error-message-force validate validate-on-blur
          :error-message="slopeTypeErrorMessage">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="arrow_down_right"></input-icon>
          <option value="" hidden>Select slope type</option>
          <option v-for="item in slopeTypeList" :key="item.id" :value="item.value">{{ item.displayName }}</option>
        </f7-list-input>

        <!-- Roof Type -->
        <f7-list-item v-if="!currentBuilding.slopeType" @click.native="selectRoofType">
          <div class="list-item-inner-start" slot="inner-start">
            Roof Type<required-asterisk />
          </div>
          <div class="list-item-title" slot="title">
            Select roof type
          </div>
          <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
          <div slot="text" style="color: red;">
            {{ roofTypeErrorMessage || selectRoofTypeMessage }}
          </div>
        </f7-list-item>
        <f7-list-input v-else type="select" label="Roof Type" :value="currentBuilding.roofType"
          @click.native="selectRoofType" @change="onRoofTypeChanged($event.target.value)" error-message-force validate
          validate-on-blur :error-message="roofTypeErrorMessage || selectRoofTypeMessage">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
          <option value="" hidden>Select roof type</option>
          <option v-for="item in roofTypeList" :key="item.id" :value="item.value">{{ item.displayName }}</option>
        </f7-list-input>

        <!-- Work type -->
        <f7-list-input v-if="estimate.businessCode !== 'service'" type="select" label="Work Type"
          :value="currentBuilding.workType" @change="onWorkTypeChanged($event.target.value)" error-message-force
          validate validate-on-blur :error-message="workTypeErrorMessage">
          <required-asterisk slot="label"></required-asterisk>
          <input-icon slot="media" icon="keyboard"></input-icon>
          <option value="" hidden>Select work type</option>
          <option v-for="item in workTypeList" :key="item.id" :value="item.value">{{ item.displayName }}</option>
        </f7-list-input>

        <!-- Price List -->
        <f7-list-item link @click.native="selectPriceList">
          <div class="list-item-inner-start" slot="inner-start">
            Price List<required-asterisk />
          </div>
          <div class="list-item-title" slot="title">
            <!-- bind price list -->
            {{
              currentBuilding.priceListId
                ? (priceList || {}).displayName
                : "Select price list"
            }}
          </div>
          <input-icon slot="media" icon="square_list"></input-icon>
          <div slot="footer" style="color: red;">
            {{ selectPriceListMessage || priceListErrorMessage }}
          </div>
        </f7-list-item>

        <!-- Vendor -->
        <f7-list-item link @click.native="openPopupSelectVendor">
          <div class="list-item-inner-start" slot="inner-start">
            Vendor<required-asterisk />
          </div>
          <div class="list-item-title" slot="title">
            {{ vendorTitle }}
          </div>
          <input-icon slot="media" icon="tray_full"></input-icon>
        </f7-list-item>
      </f7-list>
      <!-- <f7-block>
        <span class="text-color-orange"
          >Each estimate covers one roof type. For different roof types in the
          project, please create separate estimates.</span
        >
      </f7-block> -->

      <f7-list media-list>
        <!-- Warranty -->
        <f7-list-input label="Warranty" type="select" placeholder="Please choose..."
          :value="currentBuilding.warrantyType"
          @input="saveBuildingValue('warrantyType', $event.target.value)"><input-icon slot="media"
            icon="pencil_ellipsis_rectangle"></input-icon>
          <option value="">Select warranty</option>
          <option v-for="item in tpoWarrantyOptionList" :key="item.id" :value="item.value">
            {{ item.displayName }}
          </option>
        </f7-list-input>

        <!-- Warranty notes -->
        <f7-list-input label="Warranty Notes" type="textarea" placeholder="Enter notes"
          :value="currentBuilding.warrantyNotes" @change="
            saveBuildingValue('warrantyNotes', $event.target.value.trim())
            "><input-icon slot="media" icon="text_justifyleft"></input-icon></f7-list-input>
      </f7-list>
    </f7-list>

    <!-- popups -->
    <div>
      <roof-type-list-popup ref="selectRoofType" @onSelected="roofTypeChanged($event)"></roof-type-list-popup>

      <price-list-item-list-popup ref="selectPriceListItem"
        @onSelected="onSelectedPriceList($event)"></price-list-item-list-popup>

      <dynamic-multi-select ref="openPopupSelectVendor" search-placeholder="Search vendor" title="Select vendor"
        :value="vendorIds" :options="vendorOptions" @changed="onSelectedVendor"></dynamic-multi-select>
    </div>
  </div>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";
import RoofTypeListPopup from "../popup/RoofTypeListPopup.vue";
import PriceListItemListPopup from "../popup/PriceListItemListPopup.vue";

import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";
import DynamicMultiSelect from "../popup/DynamicMultiSelect.vue";
import { STATUS_PRODUCT_ITEM_ACTIVE, VALIDATION_MESSAGE } from "@/utility/const";

export default {
  components: {
    RoofTypeListPopup,
    PriceListItemListPopup,
    InputIcon,
    DynamicMultiSelect
  },

  props: {
    slopeTypeList: { type: Array, default: () => [] },
    roofTypeList: { type: Array, default: () => [] },
    workTypeList: { type: Array, default: () => [] }
  },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      isGoingToDetails: false,
      selectPriceListMessage: "",
      selectRoofTypeMessage: "",
      vendorIds: []
    };
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    currentBuilding: {
      priceListId: {
        required
      },
      roofType: {
        required
      },
      slopeType: {
        required
      },
      workType: {
        required(val) {
          if (this.estimate.businessCode === "service") return true;
          return !!val;
        }
      }
    }
  },

  async created() {
    if (_.isEmpty(this.priceListDefault)) {
      await this.getPriceListDefault();
    }
  },

  computed: {
    ...mapGetters({
      currentEstimate: "estimate/estimate-page/estimate/currentEstimate"
    }),

    ...mapGetters({
      vendors: "estimate/estimate-page/vendor/objectList"
    }),
    ...mapGetters("estimate/estimate-page/product-default", ["productsByPL"]),
    ...mapGetters("common/app-constant", ["tpoWarrantyOptionList"]),

    ...mapGetters("estimate/estimate-page/project", ["project"]),

    ...mapGetters("estimate/estimate-page/price-list", [
      "priceList",
      "priceListDefault"
    ]),

    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "typeSwitchScreen"
    ]),

    ...mapGetters({
      categories: "estimate/estimate-page/estimate/category/objectList",
      subCategories: "estimate/estimate-page/estimate/sub-category/objectList",
      currentBuilding: "estimate/estimate-page/estimate/currentBuilding"
    }),
    ...mapGetters("setting/app/profile", ["user"]),

    priceListErrorMessage() {
      if (!this.v$.currentBuilding.$error) return "";
      if (this.v$.currentBuilding.priceListId.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD
      return "";
    },

    typeSwitchScreenComputed() {
      return this.typeSwitchScreen;
    },

    vendorFilterOptions() {
      return this.vendors.filter(r =>
        (this.productsByPL.vendorIdFilterList || []).includes(r.id)
      );
    },

    vendorOptions() {
      return (this.vendorFilterOptions || []).map(r => ({
        id: r.id,
        value: r.companyName
      }));
    },

    compileConditionsToFilterDataByPL() {
      // filter
      return (roofType = "", priceListId = "") => {
        const filters = [`status:${STATUS_PRODUCT_ITEM_ACTIVE}`];

        if (
          (roofType && roofType != "all-roof-type") ||
          (this.currentBuilding.roofType &&
            this.currentBuilding.roofType != "all-roof-type")
        ) {
          filters.push(
            `roofTypes:${roofType ? roofType : this.currentBuilding.roofType}`
          );
        }

        if (priceListId || this.currentBuilding.priceListId) {
          filters.push(
            `priceListRefs:${priceListId ? priceListId : this.currentBuilding.priceListId
            }`
          );
        }

        return {
          filters: `${filters.join(" AND ")}`
        };
      };
    },

    vendorTitle() {
      if (
        _.isEmpty(this.vendorIds) ||
        _.isEmpty(this.vendorOptions) ||
        this.vendorFilterOptions.every(item => this.vendorIds.includes(item.id))
      )
        return "All vendors";

      const vendorListName = [];

      for (let id of this.vendorIds) {
        const item = this.vendorOptions.find(e => e.id === id);
        if (item) vendorListName.push(item.value);
      }

      if (_.isEmpty(vendorListName)) {
        return "All vendors";
      }

      return vendorListName.join(",");
    },

    roofTypeErrorMessage() {
      if (!this.v$.currentBuilding.$error) return "";
      if (this.v$.currentBuilding.roofType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD
      return "";
    },

    slopeTypeErrorMessage() {
      if (!this.v$.currentBuilding.$error) return "";
      if (this.v$.currentBuilding.slopeType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD
      return "";
    },

    workTypeErrorMessage() {
      if (!this.v$.currentBuilding.$error) return "";
      if (this.v$.currentBuilding.workType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD
      return "";
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "getProductDataTemplate",
      "getTax"
    ]),

    ...mapActions("estimate/estimate-page/product-item", [
      "getActiveProductItemsByPriceListId"
    ]),

    ...mapActions("estimate/estimate-page/price-list", [
      "bindPriceListItem",
      "unbindPriceListItem",
      "getPriceListDefault"
    ]),

    ...mapActions("estimate/estimate-page/assembly-drawing", [
      "getAssemblyDrawingByPriceList"
    ]),

    ...mapActions("estimate/estimate-page/product-default", [
      "searchProductItems",
      "getFilterData"
    ]),

    onSearch(roofType = "", priceListId = "") {
      this.$f7.preloader.show();
      this.getFilterData({
        dataType: "products-by-price-list",
        ...this.compileConditionsToFilterDataByPL(roofType, priceListId)
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    onSelectedPriceList(priceListId) {
      this.resetDataVendorsInBuilding();

      if (_.isEmpty(priceListId)) {
        return;
      }
      this.saveBuildingValue("priceListId", priceListId);

      this.$emit("onSelectedPriceList", priceListId);
      this.onSearch("", priceListId);
      return;
    },

    onSelectedVendor(newVendorIds) {
      const isEqual = _.isEqual(newVendorIds, this.vendorIds);
      if (isEqual) return;

      this.vendorIds = newVendorIds;
      this.saveBuildingValue("productData", []);
      this.saveBuildingValue("vendorIds", newVendorIds);

      this.$emit("onSelectedVendor", newVendorIds);
    },

    openPopupSelectVendor() {
      this.$refs.openPopupSelectVendor.openSelectPopup();
    },

    validate() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    init() { },

    toDetails(estimateNumber) {
      this.isGoingToDetails = true;
      this.estimateNumber = estimateNumber;
    },

    async onRoofTypeChanged(roofType) {
      this.resetDataVendorsInBuilding();

      if (roofType === this.currentBuilding.roofType) return;
      this.saveBuildingValue("roofType", roofType);
      //TODO: need to implement init product list???
      await this.saveBuildingValue("productData", []);

      await this.onSelectedPriceList(this.priceListDefault.id);
      await this.onSearch(roofType, this.priceListDefault.id);
    },

    async onSlopeTypeChanged(slopeType) {
      if (slopeType === this.currentBuilding.slopeType) return;

      this.saveBuildingValue("slopeType", slopeType);
      this.saveBuildingValue("roofType", "");
      this.saveBuildingValue("priceListId", "");
      this.resetDataVendorsInBuilding();
      this.unbindPriceListItem();
      this.onSearch("", "");
    },

    async onWorkTypeChanged(workType) {
      if (workType === this.currentBuilding.workType) return;
      await this.saveBuildingValue("workType", workType);

      const tax = await this.getTax();
      this.saveBuildingValue("saleTax", tax);
    },

    selectRoofType() {
      if (!this.currentBuilding.slopeType) {
        this.selectRoofTypeMessage = "Please select slope type first";
        return;
      }
    },

    resetDataVendorsInBuilding() {
      this.vendorIds = [];
      this.saveBuildingValue("vendorIds", []);
    },

    selectPriceList() {
      if (!this.currentBuilding.roofType) {
        this.selectPriceListMessage = "Please select roof type first";
        return;
      }

      this.$refs.selectPriceListItem.open(this.currentBuilding.roofType);
    }
  },

  mounted() {
    if (!_.isEmpty(this.currentBuilding)) {
      this.vendorIds = this.currentBuilding.vendorIds || [];
    }
  },

  watch: {
    "currentBuilding.id": {
      async handler(val) {
        if (val) {
          await this.onSearch();
          this.vendorIds = this.currentBuilding.vendorIds || [];
        }
      },
      deep: true
    },

    currentEstimate: {
      async handler(val) {
        if (!_.isEmpty(val) && val.slopeType) {
          this.selectRoofTypeMessage = "";
        }
        if (!_.isEmpty(val) && val.roofType) {
          this.selectPriceListMessage = "";
        }

        //unselect default PL when the end date come
        // const endDatePL = toDateCalendar(this.priceListDefault.endDate) || "";
        // const currentDate = moment(new Date(), "MM/DD/YYYY");
        // this.isEndDate = currentDate.isAfter(endDatePL[0], "day");
        // if (
        //   this.isEndDate ||
        //   (this.priceListDefault.status || "").includes("pl-inactive") ||
        //   (this.priceListDefault.status || "").includes("pl-incomplete")
        // ) {
        //   await this.onSelectedPriceList("");
        // } else {
        //   await this.onSelectedPriceList(this.priceListDefault.id);
        // }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.container-info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
