<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input type="text" label="Schedule Visit" placeholder="Enter Due Date" readonly
        :value="toDisplayDateString(toDateFirebase(dueDate))" @calendar:change="dueDate = $event">
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>

      <f7-list-input label="Job Type" type="select" placeholder="Please choose..." :value="jobType"
        @input="jobType = $event.target.value">
        <input-icon slot="media" icon="selection_pin_in_out"></input-icon>
        <option v-for="(type, index) in jobTypeList" :key="index" :value="type.value">{{ type.displayName }}</option>
      </f7-list-input>

      <f7-list-input label="Number of Buildings/Sections" :value="numberOfBuildsOrSections" type="number" @focus="
        numberOfBuildsOrSections == 0
          ? (numberOfBuildsOrSections = null)
          : numberOfBuildsOrSections
        " @blur="numberOfBuildsOrSections = Number(numberOfBuildsOrSections)"
        @input="numberOfBuildsOrSections = $event.target.value.trim()" error-message-force
        :error-message="buildsOrSectionsErrorMessage">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="number_circle"></input-icon>
      </f7-list-input>

      <f7-list-input v-show="jobType !== JOB_TYPE_NEW" label="Number of Roof Systems" :value="numberOfRoofSytem"
        type="number" @focus="
          numberOfRoofSytem == 0
            ? (numberOfRoofSytem = null)
            : numberOfRoofSytem
          " @blur="numberOfRoofSytem = Number(numberOfRoofSytem)"
        @input="numberOfRoofSytem = $event.target.value.trim()" error-message-force
        :error-message="numberOfRoofSytemErrorMessage">
        <required-asterisk slot="label"></required-asterisk>
        <input-icon slot="media" icon="arrow_2_squarepath"></input-icon>
      </f7-list-input>

      <!-- loose gravel -->
      <f7-list-item title="Loose Gravel" v-show="jobType !== JOB_TYPE_NEW && numberOfRoofSytem < 2">
        <f7-toggle slot="after" :checked="displayCard.looseGravel"
          @toggle:change="changeToggle('looseGravel', $event)"></f7-toggle>
        <input-icon slot="media" icon="xmark_seal"></input-icon>
      </f7-list-item>
    </f7-list>

    <!-- attachment -->
    <attachment-input :project-id="displayCard.id" title="Attachments" attachment-type="attachment"
      add-button-title="Add an Attachment" :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)" @save="save"></attachment-input>

    <user-input :value="displayCard.assigneeIDs" @input="setAssigneeIDs($event)"></user-input>

    <f7-list>
      <f7-list-input label="Note" floating-label type="textarea" resizable clear-button :value="note"
        @input="note = $event.target.value.trim()">
        <input-icon slot="media" icon="doc_text"></input-icon>
      </f7-list-input>
    </f7-list>

    <!-- Popup -->
    <date-popup ref="bmsDueDatePopup" title="Enter Budget Meeting Schedule" label="Budget Meeting Schedule"
      v-model="budgetMeetingDate" @done="resolveBudgetMeeting" validate not-allow-past></date-popup>

    <date-popup ref="ssvDueDatePopup" title="Enter New Schedule Site Visit" label="New Schedule Site Visit"
      v-model="siteVisitDate" @done="resolveSiteVisit" validate not-allow-past></date-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import AttachmentInput from "../input/AttachmentInput.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import methodsMixins from "../../mixin/methods";
import { JOB_TYPE_NEW, VALIDATION_MESSAGE } from "../../../../utility/const";
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from "@vuelidate/validators";

export default {
  mixins: [methodsMixins],
  components: { UserInput, DatePopup, InputIcon, AttachmentInput },

  data: () => {
    return {
      note: "",
      currentResponse: {},
      displayCard: {},

      contactedDate: [],
      dueDate: [],

      budgetMeetingDate: [],
      siteVisitDate: [],

      jobType: "",
      numberOfBuildsOrSections: 0,
      numberOfRoofSytem: 0,

      JOB_TYPE_NEW
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapGetters("dashboard/project", ["card", "action", "response", "users"]),
    ...mapGetters("dashboard/app-constant", ["jobTypeList"]),

    buildsOrSectionsErrorMessage() {
      if (!this.v$.numberOfBuildsOrSections.$error) return null;
      if (this.v$.numberOfBuildsOrSections.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfBuildsOrSections.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ONE;
      return null;
    },

    numberOfRoofSytemErrorMessage() {
      if (!this.v$.numberOfRoofSytem.$error) return null;
      if (this.v$.numberOfRoofSytem.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfRoofSytem.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ONE;
      return null;
    }
  },
  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);

        this.contactedDate = toDateCalendar(this.displayCard.contactedDate);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.jobType =
          this.displayCard.jobType || (this.jobTypeList[0] || {}).displayName;

        this.numberOfBuildsOrSections =
          this.displayCard.numberOfBuildsOrSections || 0;

        this.numberOfRoofSytem = this.displayCard.numberOfRoofSytem || 0;
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "move-to-budget-meeting":
          this.v$.$touch();
          if (this.v$.$invalid) return;

          this.currentResponse = response;
          this.$refs.bmsDueDatePopup.open();
          break;

        case "revisit":
          this.currentResponse = response;
          this.$refs.ssvDueDatePopup.open();
          break;
      }
    },

    resolveBudgetMeeting() {
      this.dueDate = this.budgetMeetingDate;
      this.resolve();
    },

    resolveSiteVisit() {
      this.dueDate = this.siteVisitDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();

      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Site Visit Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);
      if (!_.isEmpty(this.contactedDate)) {
        this.displayCard.contactedDate = toDateFirebase(this.contactedDate);
      }

      if (!_.isEmpty(this.jobType)) {
        this.displayCard.jobType = this.jobType;
      }

      if (this.numberOfBuildsOrSections != null) {
        this.displayCard.numberOfBuildsOrSections = this.numberOfBuildsOrSections;
      }

      if (this.numberOfRoofSytem != null) {
        this.displayCard.numberOfRoofSytem = this.numberOfRoofSytem;
        if (this.numberOfRoofSytem > 1) {
          this.displayCard.looseGravel = false;
        }
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    changeToggle(name, value) {
      if (this.displayCard[name] == value) return;
      this.displayCard = {
        ...this.displayCard,
        [name]: value
      };
      if (this.popupOpened) {
        this.$f7.preloader.show();
        this.updateCard({
          id: this.displayCard.id,
          doc: {
            [name]: value
          }
        }).finally(() => {
          this.$f7.preloader.hide();
        });
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      numberOfBuildsOrSections: {
        required,
        minValue: minValue(1)
      },

      numberOfRoofSytem: {
        required,
        minValue: minValue(1)
      }
    };
  }
};
</script>

<style></style>
