import Vue from "vue";

export default {
    setProductItemList(state, payload) {
        Vue.set(state, "productItemList", payload);
    },
    setProductItemSubscribe(state, payload) {
        Vue.set(state, "productItemSubscribe", payload);
    },
};
