export const REPEAT_UNIT_OPTIONS = [
  {
    id: "day",
    value: "day",
    displayName: "Day (s)"
  },
  {
    id: "week",
    value: "week",
    displayName: "Week (s)"
  },
  {
    id: "month",
    value: "month",
    displayName: "Month (s)"
  },
  {
    id: "year",
    value: "year",
    displayName: "Year (s)"
  }
];
export const REPEAT_EVERY_OPTIONS = [
  {
    id: "1_week",
    value: "1_week",
    displayName: "Week"
  },
  {
    id: "2_week",
    value: "2_week",
    displayName: "2 Weeks"
  },
  {
    id: "1_month",
    value: "1_month",
    displayName: "Month"
  },
  {
    id: "2_month",
    value: "2_month",
    displayName: "2 Months"
  },
  {
    id: "3_month",
    value: "3_month",
    displayName: "3 Months"
  },
  {
    id: "6_month",
    value: "6_month",
    displayName: "6 Months"
  },
  {
    id: "1_year",
    value: "1_year",
    displayName: "Year"
  },
  {
    id: "2_year",
    value: "2_year",
    displayName: "2 Years"
  },
  {
    id: "custom",
    value: "custom",
    displayName: "Custom"
  }
];

export const PAYMENT_TERMS_OPTIONS = [
  {
    id: "1",
    value: "15",
    displayName: "Net 15"
  },
  {
    id: "2",
    value: "30",
    displayName: "Net 30"
  },
  {
    id: "3",
    value: "45",
    displayName: "Net 45"
  },
  {
    id: "4",
    value: "60",
    displayName: "Net 60"
  },
  {
    id: "5",
    value: "custom",
    displayName: "Custom by number of days"
  }
];

export const DEFAULT_RECURRING_INVOICE_STATUS = "ri-active";
export const INVOICE_STATUS_OVERDUE = "in-overdue";

export const INCURRED_ITEM = "Incurred Item";
