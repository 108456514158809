<template>
  <div>
    <f7-block-title v-show="$device.desktop">Collector Head</f7-block-title>

    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <img
            class="display-none"
            :id="isDark && dark ? 'collection-head-dark' : 'collection-head'"
            :src="
              `${
                isDark
                  ? dark
                    ? collectiorHeadDark
                    : collectiorHead
                  : collectiorHead
              }`
            "
          />
          <canvas
            v-if="$device.desktop"
            :ref="buildingId"
            :id="buildingId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="buildingId"
            :id="buildingId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import paper from "paper";
import { evaluate } from "mathjs";
import canvasMixin from "../../utility/canvas-mixin";
import _ from "lodash";
import collectiorHeadDark from '@/assets/img/metal-diagrams/collectior-head-dark.jpeg';
import collectiorHead from '@/assets/img/metal-diagrams/collectior-head.png'
const inchToPx = 25;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    buildingId: String,
    isDark: { type: Boolean, default: true }
  },

  data: () => ({
    collectiorHeadDark,
    collectiorHead,
    scope: null,
    dataToPx: {},
    draws: {},
    space: 300,
    yBase: 200,
    xBase: 120,
    width: 1200,
    height: 1000,
    dataFixed: {
      H: '1"',
      G: '1/2"',
      J: '1 1/4"',
      K: '5/8"'
    },
    dark: localStorage.getItem("themeDark") === "true"
  }),

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.buildingId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    setDraws(name, value) {
      this.draws[name] = value;
    },

    handleDraw1() {
      let path = new paper.Path({
        strokeColor: this.isDark ? (this.dark ? "white" : "black") : "black",
        strokeWidth: "2"
      });

      const { B, C, D, F, X, H, G, J, K } = this.dataToPx;

      const I = X - J - G * 2 - F;

      const pointA = new paper.Point(X + this.xBase, this.yBase);
      const pointB = new paper.Point(pointA.x, pointA.y + H);
      const pointC = new paper.Point(pointB.x, pointB.y + B + C + H);
      const pointD = new paper.Point(pointC.x - G, pointC.y);
      const pointE = new paper.Point(pointD.x, pointD.y + D);
      const pointF = new paper.Point(pointE.x - F, pointE.y);
      const pointH = new paper.Point(pointF.x, pointF.y - D);
      const pointJ = new paper.Point(pointH.x - G, pointH.y);
      const pointL = new paper.Point(pointJ.x - I, pointJ.y - C);
      const pointM = new paper.Point(pointL.x, pointL.y - B);
      const pointN = new paper.Point(pointM.x - J, pointM.y);
      const pointO = new paper.Point(pointN.x, pointN.y - H);
      const pointQ = new paper.Point(pointO.x + K, pointO.y);
      const pointP = new paper.Point(pointA.x, pointM.y);
      const pointT = new paper.Point(pointA.x, pointL.y);

      path.add(
        pointA,
        pointB,
        pointC,
        pointD,
        pointE,
        pointF,
        pointH,
        pointJ,
        pointL,
        pointM,
        pointN,
        pointO,
        pointQ
      );

      // inner path
      new paper.Path({
        segments: [pointO, pointB],
        strokeColor: "grey"
      });
      new paper.Path({
        segments: [pointM, pointP],
        strokeColor: "grey"
      });
      new paper.Path({
        segments: [pointL, pointT],
        strokeColor: "grey"
      });
      new paper.Path({
        segments: [pointH, pointD],
        strokeColor: "grey"
      });

      // description
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointO,
        pointF,
        "A",
        100,
        false
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointN,
        pointL,
        "B",
        50,
        false
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointL,
        pointJ,
        "C",
        50 + J,
        false
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointJ,
        pointF,
        "D",
        50 + J + I,
        false
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointF,
        pointE,
        "F",
        "bottomCenter",
        50
      );
      this.handleDrawDescriptionTop(
        this.isDark,
        pointO,
        pointA,
        "X",
        "topCenter",
        100
      );

      this.handleDrawDescriptionTop(
        this.isDark,
        pointO,
        pointQ,
        this.dataFixed.K,
        "topRight",
        50,
        false
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointN,
        pointM,
        this.dataFixed.J,
        "bottomRight",
        50,
        false
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointJ,
        pointH,
        this.dataFixed.G,
        "bottomLeft",
        50,
        false
      );
      this.handleDrawDescriptionBottom(
        this.isDark,
        pointD,
        pointC,
        this.dataFixed.G,
        "bottomRight",
        50,
        false
      );
      this.handleDrawDescriptionLeft(
        this.isDark,
        pointO,
        pointN,
        this.dataFixed.H,
        50,
        false
      );
      this.handleDrawDescriptionRight(
        this.isDark,
        pointA,
        pointB,
        this.dataFixed.H,
        50
      );
    },

    handleDraw2() {
      let img = new paper.Raster(
        this.isDark && this.dark ? "collection-head-dark" : "collection-head"
      );
      img.size = new paper.Size(453, 745);
      img.position = new paper.Point(
        this.xBase + this.dataToPx.X + this.space + 150,
        this.yBase + 250
      );
    },

    handleDrawValue() {
      // draw value
      let showValue = new paper.PointText(120, 50);
      showValue.content = `A = ${this.valueA(this.data)}; B = ${
        this.data.B
      }; C = ${this.data.C}; D = ${this.data.D}; E = ${this.data.E}; F = ${
        this.data.F
      }; X = ${this.data.X}; W = ${this.data.W}`;
      showValue.fillColor = this.isDark
        ? this.dark
          ? "white"
          : "black"
        : "black";
      showValue.fontSize = 18;
    },

    drawAll() {
      this.handleDraw1();
      this.handleDraw2();
      this.handleDrawValue();
      this.resizableLayer();
    },

    valueA(val) {
      return this.convertExpesstionToString(`${val.B} ${val.C} ${val.D} 1"`);
    }
  },

  mounted() {
    for (let i of ["H", "G", "J", "K"]) {
      // parse data fixed
      let parseValue = this.convertStringToExpression(this.dataFixed[i]);
      this.dataToPx[i] = evaluate(parseValue) * inchToPx;
    }
    // this.scope = new paper.PaperScope();
    // this.scope.setup(this.buildingId);
    // this.drawAll();
  },

  watch: {
    data: {
      handler(val) {
        const keys = ["B", "C", "D", "E", "F", "W", "X"];

        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
