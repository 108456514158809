<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Quickbooks Integration</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title>{{ message }}</f7-block-title>
  </f7-page>
</template>

<script>
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    Menu
  },

  data() {
    return {
      message: "Quickbooks' response has an error!"
    };
  },
  created() {
    if (
      this.$f7route.params.error &&
      this.$f7route.params.error === "access_denied"
    ) {
      this.message = "Access denied.";
    }
  }
};
</script>

<style></style>
