import * as types from "./types";
import Vue from "vue";

export default {
  setTimeTrackingList(state, payload) {
    Vue.set(state, "timeTrackingList", payload);
  },
  setTimeTrackingSubscribe(state, payload) {
     Vue.set(state, "timeTrackingSubscribe", payload);
  },
  [types.SET_IS_SHOW_BACK_BTN](state, isShow) {
    Vue.set(state, "isShowBackBtn", isShow);
  }
};
