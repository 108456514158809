<template>
  <f7-page v-if="group">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Group Details</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="popupOpened = true">Edit</f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title>Information</f7-block-title>
    <f7-list>
      <f7-list-item
        header="Group name"
        :title="group.displayName"
      ></f7-list-item>
      <f7-list-item
        header="Description"
        :title="group.description"
      ></f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item title="Active">
        <f7-toggle slot="after" disabled :checked="!group.disabled"></f7-toggle>
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item header="Menu Item">
        <chip-more
          slot="title"
          :deleteable="false"
          :limit="3"
          :data="groupMenus"
        />
      </f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-item header="Assignee User">
        <chip-more
          slot="title"
          :deleteable="false"
          :limit="3"
          :data="groupUsers"
        />
      </f7-list-item>
    </f7-list>
    <f7-list v-if="!isDefaultGroup">
      <f7-list-button color="red" @click.native="remove">Delete</f7-list-button>
    </f7-list>
    <!-- <f7-block>{{ group.id }}</f7-block> -->
    <group-details-popup
      :isNew="false"
      :popupOpened="popupOpened"
      @close="popupOpened = false"
      @back="$f7router.back()"
    ></group-details-popup>
  </f7-page>
  <f7-page v-else>
    <f7-navbar backLink="Back">
      <f7-nav-title>Not found Group</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium>The group could not be found</f7-block-title>
    <f7-block strong>
      <p>It may have been moved or deleted by another user.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import GroupDetailsPopup from "../components/popup/GroupDetailsPopup.vue";
import ChipMore from "@/components/chips/ChipMore.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
export default {
  components: { GroupDetailsPopup, ChipMore },

  data: () => {
    return {
      popupOpened: false,
      limitMenuIDs: 3,
      limitUserIDs: 3
    };
  },

  computed: {
    ...mapGetters("administration/group-list-page/group", ["group", "groups"]),
    ...mapGetters("administration/group-list-page/menu", ["menus"]),
    ...mapGetters("administration/group-list-page/user", ["users"]),
    isDefaultGroup() {
      return this.group && !!this.group.isDefault;
    },
    groupMenus() {
      const menus = this.menus.filter(r =>
        ((this.group || {}).menuIDs || []).includes(r.id)
      );
      return menus.map(r => ({
        id: r.id,
        name: r.displayName
      }));
    },

    groupUsers() {
      const userIds = (this.group || {}).userIDs || [];
      const users = this.users.filter(r => userIds.includes(r.uid));
      return users.map(({ displayName: name, uid: id }) => ({
        id,
        name
      }));
    }
  },

  created() {
    this.setDetailId(this.$f7route.params.id);
  },

  methods: {
    ...mapActions("administration/group-list-page/group", [
      "updateGroup",
      "deleteGroup"
    ]),

    ...mapMutations("administration/group-list-page/group", ["setDetailId"]),

    update(name, value) {
      var options = { ...this.group, [name]: value };
      this.updateGroup({ id: this.$f7route.params.id, doc: options });
    },

    remove() {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete group",
        content: "Are you sure you want to delete the group?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            const groupID = this.group.id;
            // const menuIDs = _.cloneDeep(this.group.menuIDs);
            const userIDs = _.cloneDeep(this.group.userIDs);
            this.deleteGroup(this.group.id).then(() => {
              // for (const menuID of menuIDs) {
              //   this.modifyRelationShip({
              //     id: menuID,
              //     action: "remove",
              //     doc: {
              //       id: groupID,
              //       key: "groupIDs"
              //     }
              //   });
              // }

              for (const uid of userIDs) {
                this.removeGroupRef({
                  uid,
                  groupID
                });
              }

              app.$f7router.navigate("/administration/groups", {
                reloadAll: true
              });
              _sefl.app.dialog.close();
            });
          }
        }
      });
    },
    handleBack() {
      this.$f7router.back("/administration/groups", { force: true });
    }
  }
};
</script>

<style scoped>
.list /deep/ .item-title {
  white-space: normal;
}
</style>
