import * as types from "./types";
import Vue from "vue";

export default {
  [types.PROJECT_PHOTO_ALBUM_LIST](state, payload) {
    Vue.set(state, "projectPhotoAlbumList", payload);
  },
  [types.PROJECT_PHOTO_ALBUM](state, payload) {
    Vue.set(state, "projectPhotoAlbum", payload);
  },
  [types.SET_FIELD_ALBUM](state, { albumId, photoTotals, photoLasted }) {
    const index = state.projectPhotoAlbumList.findIndex(
      item => item.id === albumId
    );
    if (index >= 0) {
      Vue.set(state.projectPhotoAlbumList, index, {
        ...state.projectPhotoAlbumList[index],
        photoTotals,
        photoLasted
      });
    }
  },
  [types.RESET_DATA](state) {
    Vue.set(state, "projectPhotoAlbumList", []);
    Vue.set(state, "projectPhotoAlbum", {});
  }
};
