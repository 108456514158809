import { sortLatest } from "@/utility/date-time-tool";

export default {
  certificateList: state => sortLatest(state.certificateList),
  certificate: state => state.certificate || {},
  certificateById: state => id => {
    return state.certificateList.find(r => r.id === id);
  },
  certificatesByProjectId: state => projectId => {
    return state.certificateList.filter(r => r.projectId === projectId);
  }
};
