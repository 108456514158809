import * as types from "./types";
import Vue from "vue";

export default {
  setContactList(state, payload) {
    Vue.set(state, "contactList", payload);
  },
  setContactSubscribe(state, payload) {
    Vue.set(state, "contactSubscribe", payload);
  },
  setCompanyList(state, payload) {
    Vue.set(state, "companyList", payload);
  },
  setCompanySubscribe(state, payload) {
    Vue.set(state, "companySubscribe", payload);
  },
  [types.SET_USERS_LIST](state, users) {
    Vue.set(state, "users", users);
  }
};
