import { groupBy } from "@/utility/filter-tools";
import {
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_SERVICE,
  COLLECTION_CONTRACT,
  COLLECTION_ESTIMATE,
  COLLECTION_INVOICE,
  COLLECTION_OPRATION_CARD,
  COLLECTION_OPRATION_COMPANY,
  COLLECTION_OPRATION_CONTACT,
  COLLECTION_PROPOSAL
} from "../../utility/const";

const MENU_TITLES_FOR_SEARCH = [
  "Commercial",
  "Residential",
  "Service",
  "Contacts",
  "Companies",
  "Roofing Cam",
  "Estimate",
  "Proposal/Contract",
  "Invoices"
];

export default {
  showGlobalSearchPopup: state => state.showGlobalSearchPopup,
  // Fulltext search
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,

  searchGroup: state => {
    let dataGroup = groupBy(state.hits, item => item.entityName || "");
    return dataGroup.map(i => ({
      ...i,
      data: i.data
    }));
  },

  menuTitlesActive: (state, getters, rootState, rootGetters) => {
    const getDisplayMenu = rootGetters["setting/app/profile/getDisplayMenu"];
    let menuActive = getDisplayMenu
      .filter(r => (r.routes || []).length > 0)
      .map(r => r.routes);
    menuActive = [].concat.apply([], menuActive);
    return menuActive.map(r => r.title);
  },

  isShowGlobalSearch: (state, getters) => {
    const menuTitlesActive = getters.menuTitlesActive;
    return !!menuTitlesActive.some(r => MENU_TITLES_FOR_SEARCH.includes(r));
  },

  filterForSearch: (state, getters) => {
    const menuTitlesActive = getters.menuTitlesActive;
    if (menuTitlesActive.some(r => MENU_TITLES_FOR_SEARCH.includes(r))) {
      let projectStatusFilters = "";
      let projectBusinessCodes = ["projectBusinessCode:?"];
      let projectPhotoStatusFilters = "";
      let entityNameFilters = [];
      for (const title of menuTitlesActive) {
        switch (title) {
          case "Commercial":
            entityNameFilters.push(`entityName:${COLLECTION_OPRATION_CARD}`);
            projectBusinessCodes.push(
              `projectBusinessCode:${BUSINESS_CODE_COMMERCIAL}`
            );
            projectStatusFilters = "(projectStatus:open OR projectStatus:?)";
            break;
          case "Residential":
            entityNameFilters.push(`entityName:${COLLECTION_OPRATION_CARD}`);
            projectBusinessCodes.push(
              `projectBusinessCode:${BUSINESS_CODE_RESIDENTIAL}`
            );
            projectStatusFilters = "(projectStatus:open OR projectStatus:?)";
            break;
          case "Service":
            entityNameFilters.push(`entityName:${COLLECTION_OPRATION_CARD}`);
            projectBusinessCodes.push(
              `projectBusinessCode:${BUSINESS_CODE_SERVICE}`
            );
            projectStatusFilters = "(projectStatus:open OR projectStatus:?)";
            break;
          case "Roofing Cam":
            entityNameFilters.push(
              `entityName:${COLLECTION_OPRATION_CARD}_photo`
            );
            projectPhotoStatusFilters =
              "(projectPhotoStatus:open OR projectPhotoStatus:?)";
            break;
          case "Contacts":
            entityNameFilters.push(`entityName:${COLLECTION_OPRATION_CONTACT}`);
            break;
          case "Companies":
            entityNameFilters.push(`entityName:${COLLECTION_OPRATION_COMPANY}`);
            break;
          case "Estimate":
            entityNameFilters.push(`entityName:${COLLECTION_ESTIMATE}`);
            break;
          case "Proposal/Contract":
            entityNameFilters.push(`entityName:${COLLECTION_PROPOSAL}`);
            entityNameFilters.push(`entityName:${COLLECTION_CONTRACT}`);
            break;
          case "Invoices":
            entityNameFilters.push(`entityName:${COLLECTION_INVOICE}`);
            break;
        }
      }
      entityNameFilters = [...new Set(entityNameFilters)].join(" OR ");
      projectBusinessCodes = projectBusinessCodes.join(" OR ");
      return `(${entityNameFilters})${
        projectBusinessCodes.length ? ` AND (${projectBusinessCodes})` : ""
      }${projectStatusFilters ? ` AND ${projectStatusFilters}` : ""}${
        projectPhotoStatusFilters ? ` AND ${projectPhotoStatusFilters}` : ""
      } AND NOT isProjectArchived:true`;
    }
    return "";
  }
};
