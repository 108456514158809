/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";

export default tenant => {
  const constantAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    "constantObject"
  );

  return {
    bindConstantObjectListBys: constantAction.bindCollectionBys,
    unbindConstantObject: constantAction.unbindCollection,

    bindConstantObjectList(
      { rootGetters, commit, dispatch, getters, rootState, state },
      payload
    ) {
      return dispatch("bindConstantObjectListBys", [
        {
          prop: "code",
          op: "in",
          val: [
            constant.CODE_SLOPE_TYPE,
            constant.CODE_ROOF_TYPE,
            constant.CODE_WORK_TYPE,
            constant.CODE_SUBSTRATE,
            constant.ROOF_TYPE_TPO,
            constant.CODE_WARRANTY_OPTION,
            constant.CODE_MEMBRANE_THICKNESS,
            constant.CODE_INSULATION_THICKNESS,
            constant.CODE_INSULATION_TYPE,
            constant.CODE_STATUS_OPTION
          ]
        }
      ]);
    }
  };
};
