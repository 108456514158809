import { FirebaseActions } from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";

export default tenant => {
  const scheduleActions = new FirebaseActions(
    `/system_client/${tenant}/schedule_value`,
    "schedule"
  );

  return {
    bindSchedule: scheduleActions.bindDocument,
    unbindSchedule: scheduleActions.unbindDocument,
    createSchedule: scheduleActions.createDocument,
    updateSchedule: scheduleActions.updateDocument,
    deleteSchedule({ dispatch }, id) {
      return dispatch("updateSchedule", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
    }
  };
};
