import { applyFilter, groupBy } from "@/utility/filter-tools";
import { auth } from "../../../../services/firebase.service";
import _ from "lodash";

export default {
  userList: ({ userList }, getters, rootState, rootGetters) => {
    if (_.isEmpty(userList)) return [];
    const users = userList.filter(r => r.disabled == false);
    const teamList = rootGetters["scheduling/team/teamList"];
    const isAdminGroup = rootGetters["setting/app/group/isAdminGroup"];
    const currentUser = users.find(r => r.uid === auth.currentUser.uid) || {};
    if (
      (currentUser.customClaims && currentUser.customClaims.role === "owner") ||
      isAdminGroup
    ) {
      return users;
    }
    let userIds = [];
    teamList.forEach(r => {
      userIds = userIds.concat(r.userIDs);
    });
    userIds = [...new Set(userIds)];
    return users.filter(r => userIds.includes(r.uid));
  },
  userId: state => state.userId,
  userGroup: (state, getters) => (searchValue = "") => {
    const filteredUsers = applyFilter(getters.userList, searchValue, [
      "displayName"
    ]);
    let dataGroup = groupBy(filteredUsers, item =>
      ((item.displayName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.displayName, b.displayName))
    }));
  },
  userById: state => id => (state.userList || []).find(r => r.uid === id)
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
