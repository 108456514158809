<template>
  <div class="container">
    <div class="bg-no-data">
      <f7-icon
        f7="photo"
        class="icon-no-data"
        style="font-size: 100px"
      ></f7-icon>
    </div>
    <p>Click to add more</p>
    <div class="overlay">
      <a href="#" @click="openSelectPhoto" class="icon" title="Add Image">
        <i class="icon f7-icons">plus</i>
      </a>
    </div>
    <select-project-photo-popup
      ref="selectPhoto"
      :showProgressBar="true"
      @change="onSelectedPhoto"
      :checkShowItem="checkShowItem"
    ></select-project-photo-popup>
  </div>
</template>

<script>
import SelectProjectPhotoPopup from "../popup/SelectProjectPhotoPopup.vue";

export default {
  data() {
    return {};
  },
  props: {
    projectId: { type: String, default: "" },
    checkShowItem: { type: Function, default: () => true }
  },
  components: {
    SelectProjectPhotoPopup
  },
  methods: {
    onEdit() {
      //this.$emit("onDelete",this.id);
    },
    async openSelectPhoto() {
      let photo = await this.$refs.selectPhoto.openPopup(this.projectId);
      this.onSelectedPhoto(photo);
    },
    onSelectedPhoto(photo) {
      this.$emit("onSelectPhoto", photo);
    }
  }
};
</script>

<style lang="scss" scoped>
/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  margin: 10px;
  width: 340px;
  height: 250px;
  max-width: 400px;
  border: dotted 1px rgb(78, 77, 77);
  background: #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: rgb(104, 101, 101);
}
.icon-no-data {
  color: #fff;
}
/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 30%;
  left: 20%;
  height: 40%;
  width: 60%;
  opacity: 0;
  transition: 0.3s ease;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  border-radius: 5px;
}

/* When you mouse over the container, fade in the overlay icon*/
.container:hover .overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  font-size: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.fa-user:hover {
  color: #eee;
}
</style>
