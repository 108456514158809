<template>
  <div>
    <f7-row>
      <f7-col width="100" large="50">
        <est-info-form ref="estimateInfoForm" @onSelectedProject="onSelectedProject"></est-info-form>
      </f7-col>

      <f7-col width="100" large="50">
        <!-- number of spec  -->
        <f7-list :inset="$device.desktop" v-if="
          currentEstimate.buildings && currentEstimate.buildings.length > 0
        ">
          <template v-for="(spec, index) in currentEstimate.buildings">
            <f7-list-input :key="index" :value="spec.buildingName" text-color="primary" placeholder="Specification Name"
              @input="changeSpecName(index, $event.target.value)" error-message-force validate validate-on-blur
              :error-message="buildingNameErrorMessage(index)">
              <div slot="media" class="add-button-icon cursor-pointer display-flex" @click="removeSpec(index)">
                <f7-icon f7="minus_circle_fill"></f7-icon>
              </div>
              <div class="cursor-pointer" slot="inner-start" @click="removeSpec(index)">
                <strong>Specification # {{ index + 1 }}</strong>
              </div>
            </f7-list-input>

            <f7-list-input :key="`${index}_`" :value="spec.numberOfCopy"
              class="number-building-input margin-left padding-left" placeholder="Enter number of buidings/sections"
              type="number" @input="
                changeSpecNumberOfCopy(
                  index,
                  parseInt($event.target.value.trim())
                )
                " error-message-force validate validate-on-blur :error-message="numberOfBuildingsErrorMessage(index)">
              <div class="cursor-pointer" slot="inner-start" @click="() => { }">
                {{ "Number of Building/Sections" }}
              </div>
              <input-icon slot="media" icon="building_2"></input-icon>
            </f7-list-input>
          </template>

          <f7-list-item class="cursor-pointer" @click.native="addSpec" title="Add Specification" clear-button>
            <f7-icon class="add-button-icon" f7="plus_circle" slot="media"></f7-icon>
          </f7-list-item>
        </f7-list>

        <!-- Tax Apply -->
        <f7-list media-list :inset="$device.desktop">
          <template v-if="currentEstimate.businessCode === 'service'">
            <f7-list-input label="Tax Apply" type="select" :value="currentEstimate.taxApplyType"
              @change="setEstimateValue('taxApplyType', $event.target.value)" error-message-force validate
              validate-on-blur :error-message="applyTaxErrorMessage">
              <required-asterisk slot="label"></required-asterisk>
              <input-icon slot="media" icon="keyboard"></input-icon>
              <option value="" hidden>Please choose...</option>
              <option v-for="item in TAX_APPLY_TYPE" :key="item.id" :value="item.value">
                {{ item.displayName }}
              </option>
            </f7-list-input>
            <!-- tax -->
            <f7-list-item>
              <div class="list-item-inner-start" slot="inner-start">
                Tax
              </div>
              <cell-number-input slot="title" numberType="percent" placeholder="Sales Tax" noStyle
                :value="currentEstimate.saleTax" :fractionDigits="3"
                @done="setEstimateValue('saleTax', parseFloat($event))"></cell-number-input>
              <input-icon slot="media" icon="percent"></input-icon>
            </f7-list-item>
          </template>
        </f7-list>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import EstInfoForm from "./EstInfoForm.vue";
// import RoofInfoForm from "./RoofInfoForm.vue";

import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue, helpers } from "@vuelidate/validators";
import _ from "lodash";

import mixins from "../../utility/mixins";

import { BUSINESS_CODE_SERVICE, TAX_APPLY_TYPE, VALIDATION_MESSAGE } from "@/utility/const";

export default {
  components: {
    InputIcon,
    EstInfoForm,

    CellNumberInput
    // RoofInfoForm
  },

  mixins: [mixins],

  data() {
    return { TAX_APPLY_TYPE };
  },

  mounted() {
    this.initEstimate();
  },

  computed: {
    ...mapGetters({
      currentEstimate: "estimate/estimate-page/estimate/currentEstimate"
    }),

    ...mapGetters("estimate/estimate-page/project", [
      "project",
      "latestProject",
      "projectById",
      "projectList"
    ]),

    buildingNameErrorMessage() {
      return index => {
        if (this.v$.currentEstimate.buildings.$each.$response.$errors[index].buildingName.length === 0) return null;
        return  this.v$.currentEstimate.buildings.$each.$response.$errors[index].buildingName[0].$message
      };
    },

    numberOfBuildingsErrorMessage() {
      return index => {
        if (this.v$.currentEstimate.buildings.$each.$response.$errors[index].numberOfCopy.length === 0) return null;
        return  this.v$.currentEstimate.buildings.$each.$response.$errors[index].numberOfCopy[0].$message
      };
    },

    applyTaxErrorMessage() {
      if (!this.v$.currentEstimate.$error) return "";
      if (this.v$.currentEstimate.taxApplyType.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/property", ["getPropertyById"]),
    ...mapActions("estimate/estimate-page/contact", ["getContactById"]),
    ...mapActions("estimate/estimate-page/company", ["getCompanyById"]),
    ...mapActions("estimate/estimate-page/project", [
      "bindProjectsByProperty",
      "unbindProjectList",
      "getProject",
      "setProject"
    ]),
    ...mapActions("estimate/estimate-page/estimate", [
      "setCurrentEstimateField",
      "initTax"
    ]),

    setEstimateValue(prop, value) {
      this.setCurrentEstimateField({ fieldName: prop, value });
    },

    initEstimate() {
      const self = this;

      if (this.$f7route.params.businessCode) {
        this.setEstimateValue(
          "businessCode",
          this.$f7route.params.businessCode
        );
      }
      if (this.$f7route.params.projectId) {
        this.getProject(this.$f7route.params.projectId).then(project => {
          this.setEstimateValue("projectId", project.id);
          this.setEstimateValue("projectName", project.title);
          this.setEstimateValue("projectNumber", project.cardNumber);
          this.setEstimateValue("businessCode", project.businessCode);
          if (!_.isEmpty(project.buildings)) {
            this.setEstimateValue("buildings", project.buildings);
          }
          if (!this.$f7route.params.propertyId) {
            this.setEstimateValue("estimateName", project.title || "");
          }

          //TODO: will be implemented by case https://rooferintel.atlassian.net/browse/DEV-1854
          // this.onChangenumberOfBuildings(
          //   self.project.numberOfBuildsOrSections || 1
          // );

          if (self.project.contactId) {
            this.getContactById(self.project.contactId).then(contact => {
              if (contact) {
                this.setEstimateValue("contactId", contact.id);
                this.setEstimateValue(
                  "contactName",
                  (
                    (contact.firstName || "") +
                    " " +
                    (contact.lastName || "")
                  ).trim()
                );
              }
            });
          }
          if (self.project.companyId) {
            this.getCompanyById(self.project.companyId).then(company => {
              if (company) {
                this.setEstimateValue("companyId", company.id);
                this.setEstimateValue("companyName", company.companyName || "");
              }
            });
          }
          if (this.currentEstimate.businessCode === BUSINESS_CODE_SERVICE) {
            this.initTax();
          }
        });
      }

      if (this.$f7route.params.contactId) {
        this.getContactById(this.$f7route.params.contactId).then(contact => {
          if (contact) {
            this.setEstimateValue("contactId", contact.id);
            this.setEstimateValue(
              "contactName",
              (
                (contact.firstName || "") +
                " " +
                (contact.lastName || "")
              ).trim()
            );
          }
        });
      }

      if (this.$f7route.params.propertyId) {
        this.getPropertyById(this.$f7route.params.propertyId).then(property => {
          if (property) {
            this.setEstimateValue("propertyId", property.id);
            this.setEstimateValue("propertyName", property.propertyName || "");
            this.setEstimateValue("estimateName", property.propertyName || "");
          }
        });
      }
    },

    validate() {
      this.$refs.estimateInfoForm.validate();
      // this.$refs.roofInfoForm.validate();
      this.v$.$touch();
      return (
        !this.v$.$invalid && !this.$refs.estimateInfoForm.v$.$invalid //&&
        // !this.$refs.roofInfoForm.v$.$invalid
      );
    },

    addSpec() {
      const buildings = _.cloneDeep(this.currentEstimate.buildings || []);
      const numberOfBuildings = buildings.length;
      let counter = 0;
      if (buildings[numberOfBuildings - 1].buildingName.split("_").length > 1) {
        counter =
          parseInt(
            buildings[numberOfBuildings - 1].buildingName.split("_")[1]
          ) || numberOfBuildings;
      } else {
        counter = numberOfBuildings;
      }

      buildings.push({
        buildingName: `SPEC_${counter + 1}`,
        buildingId: "",
        numberOfCopy: 1
      });
      this.setEstimateValue("buildings", buildings);
    },

    removeSpec(index) {
      const buildings = _.cloneDeep(this.currentEstimate.buildings || []);
      buildings.splice(index, 1);
      this.setEstimateValue("buildings", buildings);
    },

    changeSpecName(index, value) {
      const buildings = _.cloneDeep(this.currentEstimate.buildings || []);
      buildings[index].buildingName = value;
      this.setEstimateValue("buildings", buildings);
    },

    changeSpecNumberOfCopy(index, value) {
      const buildings = _.cloneDeep(this.currentEstimate.buildings || []);
      buildings[index].numberOfCopy = value;
      this.setEstimateValue("buildings", buildings);
    },

    onSelectedProject() {
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector(".number-building-input input").focus();
        }
      });
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    currentEstimate: {
      // projectId: {
      //   required
      // },
      taxApplyType: {
        required(val) {
          if (this.currentEstimate.businessCode !== "service") return true;
          return !!val;
        }
      },

      buildings: {
        $each: helpers.forEach({
          buildingName: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          },
          numberOfCopy: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            minValue: helpers.withMessage(VALIDATION_MESSAGE.GREATER_THAN_ZERO, minValue(1)),
            maxValue: helpers.withMessage(VALIDATION_MESSAGE.LESS_THAN_OR_EQUAL_TO_200, maxValue(200))
          }
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.btn-step {
  margin: 0 5px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
