<template>
  <!-- step -->

  <div class="display-flex margin-top navigator-container">
    <div
      v-for="(step, index) in workSteps"
      :key="index"
      class="step-item"
      @click="onClickStep(step.stepId)"
    >
      <div
        :class="
          `step-container  ${
            step && currentStep === step.stepId ? 'active' : ''
          }`
        "
        :style="
          `background-color: ${
            step && isStepActived(step.stepId)
              ? workStepBy(step.stepId).color
              : ''
          }`
        "
      >
        <div
          :class="
            `arrow-background  ${
              step && currentStep === step.stepId ? 'active' : ''
            }`
          "
        ></div>
        <div
          :class="
            `arrow-right  ${
              step && currentStep === step.stepId ? 'active' : ''
            }`
          "
          :style="
            `border-left-color: ${
              step && isStepActived(step.stepId)
                ? workStepBy(step.stepId).color
                : '#aaaaaa'
            }`
          "
        ></div>

        <span>{{ step ? workStepBy(step.stepId).displayName : "" }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  STEP_INFORMATION,
  STEP_SERVICE_INFORMATION,
  COMMON_SERVICE_STEPS
} from "@/utility/const";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import buildingMixins from "../../utility/building-mixins";
import { BUSINESS_CODE_SERVICE } from "../../../../utility/const";

export default {
  props: {
    roofTypeCode: String,
    isNew: { type: Boolean, default: true }
  },
  mixins: [buildingMixins],
  data() {
    return {
      // currentStep: "",
      STEP_INFORMATION,
      STEP_SERVICE_INFORMATION,
      COMMON_SERVICE_STEPS
    };
  },

  computed: {
    ...mapGetters("common/app-constant", ["roofTypeBy", "workStepBy"]),
    ...mapGetters("estimate/estimate-page/estimate", ["estimate"]),
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "currentStep"
    ]),
    workSteps() {
      const defaultSteps = [this.defaultStep];

      if (_.isEmpty(this.estimate)) {
        return defaultSteps;
      }

      let steps = this.roofTypeBy(this.roofTypeCode).workSteps || {};

      if (
        _.isEmpty(steps) &&
        this.currentBuilding &&
        this.currentBuilding.roofType === "all-roof-type"
      ) {
        steps = this.COMMON_SERVICE_STEPS;
      }

      return _.cloneDeep(
        steps[this.estimate.businessCode] || defaultSteps
      ).sort((a, b) => a.index - b.index);
    },
    stepActives() {
      return this.currentBuilding.stepActives || [this.defaultStep.stepId];
    },

    defaultStep() {
      return {
        stepId:
          this.estimate.businessCode === BUSINESS_CODE_SERVICE
            ? this.STEP_SERVICE_INFORMATION
            : this.STEP_INFORMATION,
        index: 0
      };
    }
  },
  methods: {
    ...mapActions("estimate/estimate-page/estimate", ["setCurrentStep"]),

    onClickStep(type) {
      this.$emit("onClickStep", type);
    },
    setStepActives(value) {
      this.setCurrentStep(value);
      if (
        !this.stepActives.includes(value) &&
        !_.isEmpty(this.currentBuilding)
      ) {
        this.onSaveStepActives([...this.stepActives, value]);
      }
    },
    onSaveStepActives(steps) {
      this.saveBuildingValue("stepActives", steps);
    },

    isStepActived(value) {
      return this.stepActives.includes(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.navigator-container {
  margin-right: 20px;
  width: calc(100% - 23px);
}

.step-item {
  flex-grow: 1;
  cursor: pointer;
}

.step-container {
  // Main container
  --step-background-color: #aaaaaa;
  --step-line-color: #f3f3f3;
  --step-height: 60px;
  --step-var: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: var(--step-height);
  color: white;
  font-weight: bold;
  font-size: 16px;
  background-color: var(--step-background-color);

  @media (min-width: 1400px) {
    min-width: 150px;
  }

  span {
    margin-left: 30px;
    text-align: center;
  }

  &.active {
    text-decoration: underline solid 1px;
    font-size: 20px;
    margin-top: calc(var(--step-var) * -0.5);
    margin-left: calc(var(--step-var) * -0.25);
    height: calc(var(--step-height) + var(--step-var));
  }
}

.arrow-background {
  // the white background
  --step-line-color: #f3f3f3;
  --step-height: 60px;
  --step-var: 12px;
  --step-arrow-border-height: calc((var(--step-height) + var(--step-var)) / 2);
  --step-arrow-width: 26px;

  position: absolute;
  top: calc(var(--step-var) * -0.5);
  bottom: 0;
  width: 0px;
  height: 0px;
  right: -23px;

  border-bottom: var(--step-arrow-border-height) solid transparent;
  border-top: var(--step-arrow-border-height) solid transparent;
  border-left: var(--step-arrow-width) solid var(--f7-color-bg-11-neutral);
  z-index: 1;

  &.active {
    margin-top: 0px;
    right: -26px;
    border-bottom: calc((var(--step-height) / 2 + var(--step-var))) solid
      transparent;
    border-top: calc((var(--step-height) / 2 + var(--step-var))) solid
      transparent;
  }
}
.arrow-right {
  // The arrow
  --step-line-color: #f3f3f3;
  --step-height: 60px;
  --step-var: 12px;
  --step-arrow-border-height: calc(var(--step-height) / 2);
  --step-arrow-width: 22px;

  position: absolute;
  top: 0;
  bottom: 0;
  width: 0px;
  height: 0px;
  right: -19px;
  border-top: var(--step-arrow-border-height) solid transparent;
  border-bottom: var(--step-arrow-border-height) solid transparent;
  border-left: var(--step-arrow-width) solid var(--f7-color-bg-11-neutral);
  z-index: 1;

  &.active {
    margin-top: 0px;
    right: -22px;
    border-top: calc((var(--step-height) + var(--step-var)) / 2) solid
      transparent;
    border-bottom: calc((var(--step-height) + var(--step-var)) / 2) solid
      transparent;
    border-left: var(--step-arrow-width) solid var(--f7-color-bg-11-neutral);
  }
}
</style>
