<template>
  <f7-view :class="`page ${dark ? 'theme-dark' : ''}`">
    <f7-page no-toolbar no-navbar no-swipeback login-screen>
      <!-- <form> -->
      <f7-login-screen-title>Register</f7-login-screen-title>
      <f7-list>
        <f7-list-input label="First Name" type="text" placeholder="Your first name" :value="firstName"
          error-message-force :error-message="errorMessages.firstName"
          @change="firstName = $event.target.value"><required-asterisk slot="label" />
        </f7-list-input>
        <f7-list-input label="Last Name" type="text" placeholder="Your last name" :value="lastName" error-message-force
          :error-message="errorMessages.lastName" @change="lastName = $event.target.value"><required-asterisk
            slot="label" /></f7-list-input>
        <f7-list-input label="Email" type="text" placeholder="Your email" :value="email" error-message-force
          :error-message="errorMessages.email" @change="email = $event.target.value"><required-asterisk
            slot="label" /></f7-list-input>
        <f7-list-input label="Password" type="password" placeholder="Login password" :value="password"
          error-message-force :error-message="errorMessages.password"
          @change="password = $event.target.value"><required-asterisk slot="label" /></f7-list-input>
        <f7-list-input label="Confirm Password" type="password" placeholder="Re-type login password"
          :value="confirmPassword" error-message-force :error-message="errorMessages.confirmPassword"
          @change="confirmPassword = $event.target.value"><required-asterisk slot="label" /></f7-list-input>
        <f7-list-input label="Phone Number" type="text" placeholder="Your phone number" :value="phoneNumber"
          error-message-force :error-message="errorMessages.phoneNumber" @change="phoneNumber = $event.target.value"
          v-mask="'(+1) ###-###-####'"></f7-list-input>
        <f7-list-input label="Company" type="text" placeholder="Company name" :value="company" error-message-force
          :error-message="errorMessages.company" @change="company = $event.target.value"><required-asterisk
            slot="label" /></f7-list-input>
      </f7-list>
      <f7-list>
        <f7-block>Choose pricing plan</f7-block>
        <f7-list-item checkbox :checked="yearlyPlan" title="Save up to 10% with the yearly plan"
          @change="yearlyPlan = $event.target.checked"></f7-list-item>

        <f7-list-input label="Pricing plan" type="select" error-message-force :error-message="errorMessages.size"
          :value="size" @change="size = $event.target.value">
          <required-asterisk slot="label" />
          <option v-for="item in businessSizes" :key="item.id" :value="item.id">{{
            `${item.displayName} - ${calculatePrice(item)} / ${yearlyPlan ? "year" : "month"
            }`
          }}</option>
        </f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button large round raised fill @click="submitRegister">Next</f7-button>
        <br />
        <f7-button large round raised outline @click="toLoginPage">Login</f7-button>
      </f7-block>
      <!-- </form> -->
    </f7-page>
  </f7-view>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import { submitRegisterForm } from "../services/register.service";
// import { loadStripe } from "@stripe/stripe-js";
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, sameAs, email } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { VALIDATION_MESSAGE } from '@/utility/const';

function extractErrorMessage(error, fieldName) {
  if (!error.$error) return "";
  if (error.required === false) return VALIDATION_MESSAGE.REQUIRED_FIELD;
  if (fieldName === "password") {
    if (error.minLength === false) return VALIDATION_MESSAGE.PASSWORD_MIN_LENGTH;
    if (error.passwordRule === false)
      return VALIDATION_MESSAGE.PASSWORD_COMPLEXITY;
  }
  if (fieldName === "email") {
    if (error.commonEmailRule === false) return VALIDATION_MESSAGE.INVALID_EMAIL;
  }
  if (fieldName === "confirmPassword") {
    if (error.sameAsPassword === false)
      return VALIDATION_MESSAGE.PASSWORD_MISMATCH;
  }
  return "";
}

export default {
  directives: { mask },
  data: () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      yearlyPlan: false,
      size: "",
      password: "",
      confirmPassword: ""
    };
  },
  mounted() {
    this.bindPlans().then(() => {
      this.size =
        this.businessSizes && this.businessSizes.length > 0
          ? this.businessSizes[0].id
          : "";
    });
  },
  computed: {
    ...mapState("setting/app/profile", ["dark"]),
    ...mapGetters("administration/plan", ["planList"]),
    firstNameErrorMessage() {
      if (!this.v$.currentEstimate.$error) return "";
      if (this.v$.currentEstimate.roofTypeID.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    },
    errorMessages() {
      const results = [];
      for (const key in this.v$.$params) {
        results[key] = extractErrorMessage(this.v$[key], key);
      }
      return results;
    },
    businessSizes() {
      return ([...this.planList] || [])
        .sort((a, b) => a.price - b.price)
        .map(r => ({
          ...r,
          displayName: r.features && r.features.length > 0 ? r.features[0] : ""
        }));
    },
    priceId() {
      const plan = this.planList.find(r => r.id === this.size);
      if (!plan) return "";
      return this.yearlyPlan ? plan.yearly : plan.monthly;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    const checkPassword=(password) => {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      return passwordRegex.test(password);
    }
    return {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        commonEmailRule: email
      },
      company: {
        required
      },
      password: {
        required,
        minLength: minLength(8),
        passwordRule: password => checkPassword(password)
      },
      confirmPassword: {
        sameAsPassword: (value) => value === this.password
      }
    }
  },

  methods: {
    ...mapActions("administration/plan", ["bindPlans"]),
    async submitRegister() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$f7.preloader.show();
      // const stripe = await loadStripe("pk_test_OmIxkC1cmTAoxbM11az9X8dp");
      // const stripe = await loadStripe(process.env.VITE_STRIPE_PUBLISHABLE_KEY);
      // var checkoutSession = await submitRegisterForm({
      //   firstName: this.firstName,
      //   lastName: this.lastName,
      //   email: this.email,
      //   company: this.company,
      //   size: this.size,
      //   priceId: this.priceId,
      //   phoneNumber: this.phoneNumber.replace(/\(|\)|-| /g, ""),
      //   yearlyPlan: this.yearlyPlan,
      //   password: this.password,
      //   frontendUrl: window.location.origin
      // });
      // await stripe.redirectToCheckout({
      //   sessionId: checkoutSession.checkoutSessionId.id
      // });
    },

    toLoginPage() {
      this.$f7router.navigate("/login", {
        pushState: true,
        reloadAll: true
      });
    },
    calculatePrice(item) {
      return this.$options.filters.currencyUSD(
        item.price * (this.yearlyPlan ? 12 : 1) * (this.yearlyPlan ? 0.9 : 1)
      );
    }
  }
};
</script>

<style></style>
