<template>
  <div class="display-flex">
    <div
      v-for="(user, index) in dataUsers"
      :key="index"
      :class="index < maxUserDisplay ? 'assignee-avatar' : ''"
    >
      <user-avatar
        class="cursor"
        border
        :selected="isSelectedUser(user.uid)"
        v-if="index < maxUserDisplay"
        :avatarSize="30"
        :imageUrl="user.userphotoURL || ''"
        :name="user.displayName || user.email"
        :uid="user.uid"
        @onClick="toggleSelectedUser(user)"
      ></user-avatar>
    </div>
    <div
      v-if="dataUsers.length > maxUserDisplay"
      class="assignee-avatar more-assignee-avatar cursor more-user-tooltip"
      @click="onOpenPopup"
    >
      <span>{{ `+${dataUsers.length - maxUserDisplay}` }}</span>
    </div>

    <f7-popup
      :opened="popupOpened"
      @popup:closed="onClose"
      @popup:opened="handlePopupOpened"
    >
      <f7-page>
        <f7-navbar title="Select Users">
          <f7-nav-right>
            <f7-link @click="onDone">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-searchbar
          disable-button-text
          placeholder="Search user"
          :clear-button="true"
          :value="searchValue"
          @input="onSearch($event.target.value)"
          @searchbar:disable="onSearch('')"
          @searchbar:clear="onSearch('')"
          ref="searchUserPopup"
        ></f7-searchbar>

        <f7-list>
          <f7-list-item
            checkbox
            v-for="(user, index) in filteredUsers"
            :key="index"
            :checked="isSelectedUser(user.uid)"
            :title="user.displayName || user.email"
            :value="user.uid"
            @change="onChangeUser"
          >
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import UserAvatar from "./UserAvatar.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import { applyFilter } from "@/utility/filter-tools";

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  components: { UserAvatar },

  data() {
    return {
      maxUserDisplay: 5,
      filteredUserIds: [],
      popupOpened: false,
      searchValue: ""
    };
  },

  mounted() {
    this.$f7.tooltip.create({
      targetEl: `.more-user-tooltip`,
      text: "Select more users"
    });
  },

  computed: {
    ...mapGetters("swimlane/user", ["activeUsers"]),
    ...mapGetters("setting/app/profile", { currentUser: "user" }),
    dataUsers() {
      const myUid = this.currentUser.uid;
      const myUser = this.activeUsers.find(user => user.uid === myUid);
      let users = _.cloneDeep(this.activeUsers);
      if (myUser) {
        users = users.filter(user => user.uid !== myUid);
        users.unshift(myUser);
      }
      return users;
    },
    isSelectedUser() {
      return uid => {
        return this.filteredUserIds.findIndex(item => item === uid) >= 0;
      };
    },
    filteredUsers() {
      const list =
        applyFilter(this.dataUsers, this.searchValue, ["displayName"]) || [];
      return list.sort((a, b) => sortBy(a.displayName, b.displayName));
    }
  },

  methods: {
    toggleSelectedUser(user) {
      const index = this.filteredUserIds.findIndex(uid => uid === user.uid);
      if (index < 0) {
        this.filteredUserIds.push(user.uid);
      } else {
        this.filteredUserIds.splice(index, 1);
      }
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.searchUserPopup.$el
          .querySelector(".searchbar input")
          .focus();
      }
    },

    onChangeUser(event) {
      if (event.target.checked) {
        this.filteredUserIds.push(event.target.value);
      } else {
        const index = this.filteredUserIds.findIndex(
          uid => uid === event.target.value
        );
        this.filteredUserIds.splice(index, 1);
      }
    },

    onSearch(value) {
      this.searchValue = value;
    },

    onDone() {
      this.popupOpened = false;
    },

    onClose() {
      this.popupOpened = false;
      this.searchValue = "";
    },

    onOpenPopup() {
      this.popupOpened = true;
    }
  },

  watch: {
    filteredUserIds: {
      handler(value) {
        this.$emit("changeFilterUsers", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.more-assignee-avatar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--f7-color-chip-neutral);
  font-size: 14px;
}
.assignee-avatar {
  margin-left: -10px;
}
.cursor {
  cursor: pointer;
}
</style>
