<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Purchase Orders</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <search-pagination-table
      :data="hits"
      :showTitle="false"
      :showSearchBar="true"
      class="search-fixed-container"
    >
      <template v-slot:search>
        <div class="search-fixed-header">
          <div
            class="display-flex justify-content-space-between align-items-center data-table-title po-table-title"
          >
            <f7-searchbar
              style="flex: 1"
              placeholder="Search Purchase Order"
              :clear-button="true"
              disable-button-text
              @input="
                setPOSearchText($event.target.value.trim());
                onSearch();
              "
              @searchbar:clear="
                setPOSearchText('');
                onSearch();
              "
              @searchbar:disable="
                setPOSearchText('');
                onSearch();
              "
            >
            </f7-searchbar>
            <f7-button
              raised
              fill
              class="display-flex align-items-center margin-left-half width-250"
              style="min-width: 150px"
              @click="openAddPopup"
            >
              <f7-icon f7="plus"></f7-icon>
              <span>Add New PO</span>
            </f7-button>
            <f7-button
              popover-open=".popover-menu-filter"
              style="margin: 0px 5px 0px 5px"
            >
              <f7-icon f7="funnel"></f7-icon>
            </f7-button>
            <f7-popover :class="`popover-menu-filter`">
              <f7-list>
                <f7-list-item
                  v-for="(status, index) in purchaseOrderStatusList"
                  :key="index"
                  popover-close
                  :title="status.displayName"
                  style="cursor: pointer;"
                  @change="filterByStatus($event.target.checked, status.value)"
                  :checked="selectedStatusFilter.includes(status.value)"
                  checkbox
                >
                </f7-list-item>
              </f7-list>
            </f7-popover>
          </div>
        </div>
      </template>

      <template v-slot:header>
        <th class="label-cell">
          PO #
        </th>
        <th class="label-cell">Status</th>
        <th class="label-cell">Project #</th>
        <th class="label-cell">Project Name</th>
        <th class="label-cell">Specification #</th>
        <th class="label-cell">Roof Type</th>
        <th class="label-cell">Estimate #</th>
        <th class="label-cell">Tracking #</th>
        <th class="label-cell">Vendor</th>
        <th class="numeric-cell">Actions</th>
      </template>

      <template v-slot:body="{ item }">
        <td class="label-cell">
          <a
            href="javascript:void(0)"
            @click="openPurchaseDetailPopup(item)"
            v-html="getHtmlRerult('taskNumber', item)"
          ></a>
        </td>
        <td class="label-cell">
          <f7-chip
            slot="after"
            :text="purchaseOrderStatusBy(item.status).displayName"
            :color="purchaseOrderStatusBy(item.status).color"
          ></f7-chip>
        </td>
        <td
          class="label-cell"
          v-html="getHtmlRerult('projectNumber', item)"
        ></td>
        <td class="label-cell" v-html="getHtmlRerult('projectName', item)"></td>
        <td
          class="label-cell"
          v-html="getHtmlRerult('buildingName', item)"
        ></td>
        <td
          class="label-cell"
          v-html="getHtmlRerult('roofTypeName', item)"
        ></td>
        <td
          class="label-cell"
          v-html="getHtmlRerult('estimateNumber', item)"
        ></td>
        <td
          class="label-cell"
          v-html="getHtmlRerult('trackingNumber', item)"
        ></td>
        <td class="label-cell" v-html="getHtmlRerult('vendorName', item)"></td>
        <td class="numeric-cell">
          <f7-link icon-f7="trash" @click.native="handleDelete(item)"></f7-link>
        </td>
      </template>

      <template v-slot:footer
        ><div class="data-table-rows-select">
          Per page:
          <div class="input input-dropdown">
            <select
              @input="onChangeLimit($event.target.value.trim())"
              :value="hitsPerPage"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
            </select>
          </div>
        </div>
        <div class="data-table-pagination">
          <span
            v-if="page === 0"
            class="display-flex align-items-center margin-left color-gray"
            disabled
            ><f7-icon f7="chevron_left"></f7-icon>First</span
          >
          <a
            v-else
            @click="onGoToPage('first')"
            class="display-flex align-items-center margin-left "
            disabled
            ><f7-icon f7="chevron_left"></f7-icon>First</a
          >

          <span v-if="page === 0" class="margin-left color-gray">Previous</span>
          <a v-else @click="onGoToPage('prev')" class="margin-left">Previous</a>

          <span class="data-table-pagination-label margin-left">{{
            pagingMessage
          }}</span>

          <a
            v-if="page < nbPages - 1"
            @click="onGoToPage('next')"
            class="margin-left"
            >Next</a
          >
          <span v-else class="margin-left color-gray">Next</span>

          <a
            v-if="page < nbPages - 1"
            @click="onGoToPage('last')"
            class="display-flex align-items-center margin-left"
            >Last<f7-icon f7="chevron_right"></f7-icon
          ></a>
          <span
            v-else
            class="display-flex align-items-center margin-left color-gray"
            >Last<f7-icon f7="chevron_right"></f7-icon
          ></span>
        </div>
      </template>
    </search-pagination-table>

    <!-- popup -->
    <new-purchase-order-popup
      ref="newPurchaseOrderPopup"
      isFromGridView
      @onCreated="openPurchaseDetailPopup"
    ></new-purchase-order-popup>
    <purchase-oder-detail-popup
      ref="purchaseOrderDetailPopup"
      @refreshGridData="onGoToPage(page)"
    />
  </f7-page>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchPaginationTable from "../../../components/datatables/SearchPaginationTable.vue";
import NewPurchaseOrderPopup from "../components/popups/NewPurchaseOrderPopup.vue";
import _ from "lodash";
import PurchaseOderDetailPopup from "../components/popups/PurchaseOderDetailPopup.vue";
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    SearchPaginationTable,
    NewPurchaseOrderPopup,
    PurchaseOderDetailPopup,
    Menu
  },

  data: () => {
    return {
      isActiveOrder: false,
      selectedStatuses: []
    };
  },

  async created() {
    this.setDestroyFlag(false);
    if (_.isEmpty(this.purchaseOrderStatusList)) {
      this.bindConstantTypeList();
    }
    if (_.isEmpty(this.users)) {
      this.getUserData();
    }
    this.selectedStatuses = _.cloneDeep(this.selectedStatusFilter);
    this.handleSearch().finally(() => {
      this.setDestroyFlag(true);
    });
    if (this.$f7route.query.taskNumber) {
      const taskData = await this.getPOList(this.$f7route.query.taskNumber)
      this.$refs.purchaseOrderDetailPopup.open(taskData[0]);
    }
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.resetSearch();
    }
  },

  computed: {
    ...mapGetters("purchase-order/purchase-order", [
      "order",
      "searchText",

      "hits",
      "hitsPerPage",
      "nbHits",
      "nbPages",
      "page",
      "selectedStatusFilter"
    ]),

    ...mapGetters("purchase-order/purchase-order-grid-page/common", [
      "destroyFlag"
    ]),

    ...mapGetters("purchase-order/app-constant", [
      "purchaseOrderStatusList",
      "purchaseOrderStatusBy"
    ]),
    ...mapGetters("purchase-order/purchase-order-details-page/project", [
      "users"
    ]),

    // Allow search desc only
    // classHeaderSortable() {
    //   return `sortable-cell sortable-${this.order} ${
    //     this.isActiveOrder ? "sortable-cell-active" : ""
    //   }`.trim();
    // },
    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },
    isGridView() {
      return this.$f7router.currentRoute.path.includes("purchase-order-grid");
    }
  },

  methods: {
    ...mapActions("purchase-order/purchase-order", [
      "deletePurchaseOrder",
      "searchPurchaseOrder",
      "setPONumberOfRows",
      "setPOSearchText",
      "setPOSearchOrder",
      "goToPage",
      "resetSearch",
      "setSelectedStatusFilter",
      "getPOList"
    ]),
    ...mapActions("purchase-order/purchase-order-grid-page/common", [
      "setDestroyFlag"
    ]),
    ...mapActions("purchase-order/app-constant", ["bindConstantTypeList"]),
    ...mapActions("purchase-order/purchase-order-details-page/project", [
      "getUserData"
    ]),

    getHtmlRerult(prop, item) {
      // console.log(prop, item._highlightResult);
      if (
        item._highlightResult &&
        item._highlightResult[prop] &&
        item._highlightResult[prop].matchLevel === "full"
      ) {
        return `<span>${item._highlightResult[prop].value}</span>`;
      }

      return item[prop] ? `<span>${item[prop]}</span>` : "";
    },

    // Allow search desc only
    // onSortOrderChange() {
    //   this.isActiveOrder = true;
    //   const order = this.order === "asc" ? "desc" : "asc";
    //   this.setPOSearchOrder(order);
    //   this.onSearch();
    // },

    openAddPopup() {
      this.$refs.newPurchaseOrderPopup.openPopup();
    },

    handleDelete(item) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Delete purchase order",
        content: `Are you sure you want to delete purchase order # ${item.taskNumber}?`,
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            app
              .deletePurchaseOrder({
                id: item.id,
                isGridView: this.isGridView
              })
              .then(() => {
                _sefl.app.dialog.close();
              })
              .finally(() => {
                app.$f7.preloader.hide();
              });
          }
        }
      });
    },
    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),
    async handleSearch() {
      this.$f7.preloader.show();
      return this.searchPurchaseOrder({}).finally(() => {
        this.$f7.preloader.hide();
      });
    },
    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({ pageName }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    onChangeLimit(value) {
      this.setPONumberOfRows(value);
      this.onSearch();
    },

    openPurchaseDetailPopup(item) {
      this.$refs.purchaseOrderDetailPopup.open(item);
    },
    filterByStatus(checked, status) {
      if (checked) {
        this.selectedStatuses.push(status);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          item => item !== status
        );
      }
      this.setSelectedStatusFilter(_.cloneDeep(this.selectedStatuses));
      this.onSearch();
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-hover:hover {
  background: var(--step-background-color) !important;
}
.tab-block {
  margin: 15px 5px 5px 5px;
}
.tab-content-container {
  padding-top: 10px;
  overflow: visible;
}
.po-table-title {
  width: 100%;
  background-color: var(--f7-searchbar-bg-color, var(--f7-bars-bg-color));
}

.label-cell ::v-deep em {
  font-style: normal;
  background: orange;
}
.searchbar:after {
  height: 0;
}
.po-table-title {
  border-bottom: 1px solid
    var(--f7-searchbar-border-color, var(--f7-bars-border-color));
}
.search-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
  overflow: hidden;
}

.search-fixed-header {
  flex: 0 0 auto;
  background-color: white;
  z-index: 1;
}
.list ::v-deep .item-link .item-inner {
  display: none;
}
</style>
