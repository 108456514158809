<template>
  <div>
    <!-- <div v-if="roofType != 'tpo'">{{ `roofType: ${roofType}` }}</div> -->
    <service-info-mobile-form
      v-if="!$device.desktop"
    ></service-info-mobile-form>

    <service-building-info-form
      v-if="$device.desktop"
      :isEditing="false"
    ></service-building-info-form>

    <!-- <tpo-building-measurement-form
      v-if="
        estimate.businessCode != 'service' &&
          $device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
      :isEditing="false"
    ></tpo-building-measurement-form> -->

    <!-- <tpo-building-assembly-form
      v-if="
        estimate.businessCode != 'service' &&
          $device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
      :isEditing="false"
    ></tpo-building-assembly-form> -->

    <!-- <tpo-building-photo-form
      v-if="
        estimate.businessCode != 'service' &&
          $device.desktop &&
          roofType === ROOF_TYPE_TPO
      "
      :isEditing="false"
    ></tpo-building-photo-form> -->

    <f7-block-title class="row-action_left"
      >Product Items {{ currentBuilding.buildingName || "" }} (for total
      {{ currentBuilding.numberOfCopy || "" }}
      {{
        currentBuilding.numberOfCopy == 1 ? "building" : "buildings"
      }})</f7-block-title
    >

    <summary-product-table
      :isEditing="false"
      color="var(--f7-color-border-neutral)"
      textColor="var(--f7-color-text-neutral)"
    ></summary-product-table>
  </div>
</template>

<script>
import SummaryProductTable from "../tables/SummaryProductTable.vue";
import ServiceBuildingInfoForm from "../forms/ServiceBuildingInfoForm.vue";
import ServiceInfoMobileForm from "../forms/ServiceInfoMobileForm.vue";

import { mapGetters } from "vuex";

import { ROOF_TYPE_TPO } from "../../../../utility/const";

export default {
  components: {
    SummaryProductTable,
    ServiceBuildingInfoForm,
    ServiceInfoMobileForm
  },

  props: { roofType: String },

  data() {
    return { ROOF_TYPE_TPO };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "currentBuilding"
    ])
  },

  methods: {
    validate() {},
    init() {}
  }
};
</script>

<style lang="scss" scoped>
.row-action {
  margin-top: 30px;
  &_right {
    margin-right: 2px;
  }

  &_left {
    margin-left: 10px;
    margin-bottom: 0px;
  }
}
</style>
