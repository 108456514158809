<template>
  <div>
    <f7-list>
      <f7-list-item title="Project Title" :after="project.title"></f7-list-item>
      <f7-list-item title="Property Name" :after="property.propertyName"></f7-list-item>
      <f7-list-item v-if="woTask.buildingName" title="Specification #" :after="woTask.buildingName"></f7-list-item>
    </f7-list>

    <f7-list>
      <f7-list-input label="Description" :value="description" type="textarea" @change="
        description = $event.target.value;
      updateWorkOrderTaskProp('description', $event.target.value);
      " :error-message="descriptionErrorMessage" error-message-force><required-asterisk
          slot="label"></required-asterisk>
      </f7-list-input>

      <f7-list-input placeholder="Enter Due Date" label="Due Date" type="datepicker" :calendar-params="{
        backdrop: true,
        openIn: 'customModal',
        header: true,
        footer: false,
        dateFormat: 'mm/dd/yyyy',
        disabled: {
          from: null,
          to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
        },
        closeOnSelect: true
      }" :value="dueDate" @calendar:change="saveDueDate('dueDate', $event)" :error-message="dueDateErrorMessage"
        error-message-force><required-asterisk slot="label"></required-asterisk></f7-list-input>
    </f7-list>

    <attachment-input :project-id="woTask.id" modelType="work-order-tasks" title="Attachments"
      attachment-type="attachment" add-button-title="Add an Attachment" :value="attachmentFiles" @input="
        attachmentFiles = $event;
      updateWorkOrderTaskProp('attachmentFiles', $event);
      "></attachment-input>

    <user-input :value="assigneeIds" modelType="wo-task" @input="updateWorkOrderTaskProp('assigneeIds', $event)"
      @onUserAdded="
        addWOTaskNotification($event);
        sendAddWOTaskMail($event);
      "
    ></user-input>

    <slot name="response"></slot>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput";

import { mapGetters, mapActions, mapState } from "vuex";
import _ from "lodash";
import { useVuelidate } from '@vuelidate/core'

import {
  toDateCalendar,
  toDateFirebase
  // toDisplayDateString
} from "../../../../utility/datetime";
import { required } from "@vuelidate/validators";

import constBoard from "../../mixin/constructionBoard";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { UserInput, AttachmentInput },

  mixins: [constBoard],

  data() {
    return {
      woTask: {},
      description: "",
      dueDate: "",
      assigneeIds: [],
      attachmentFiles: []
    };
  },

  watch: {
    workOrderTask: {
      immediate: true,
      deep: true,
      handler(val) {
        this.woTask = _.cloneDeep(val);
        this.description = this.woTask.description || "";
        this.dueDate = this.toDateCalendar(this.woTask.dueDate) || [];
        this.assigneeIds = this.woTask.assigneeIds || [];
        this.attachmentFiles = this.woTask.attachmentFiles || [];
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    description: {
      required
    },
    dueDate: {
      required
    }
  },

  computed: {
    ...mapGetters("dashboard/work-order", ["workOrderTask"]),
    ...mapGetters("dashboard/project", { project: "constructionProject" }),
    ...mapState("dashboard/client", ["property"]),

    descriptionErrorMessage() {
      if (!this.v$.description.$error) return null;
      if (this.v$.description.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    },

    dueDateErrorMessage() {
      if (!this.v$.dueDate.$error) return null;
      if (this.v$.dueDate.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return "";
    }
  },

  methods: {
    ...mapActions("dashboard/work-order", ["updateWorkOrderTask"]),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "start":
        case "completed":
          this.v$.$touch();
          if (this.v$.$invalid) {
            return;
          }

          this.resolve(response);
          this.$emit("doClosePopup");
          break;
      }
    },

    // Implement  resolve action
    async resolve(response) {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", response);
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      // console.log("save");
      await this.updateWorkOrderTask({
        id: this.woTask.id,
        doc: {
          dueDate: this.toDateFirebase(this.dueDate || "") || "",
          description: this.description,
          assigneeIds: this.assigneeIds,
          attachmentFiles: this.attachmentFiles
        }
      });
    },

    initData() { }
  }
};
</script>

<style></style>
