/* eslint-disable no-unused-vars */
import {
  FirebaseActions,
  firebase
} from "../../../../services/firebase.service";
import algoliaService from "../../../../services/algolia.service";
import * as constant from "../../../../utility/const";
import * as types from "./types";
import _ from "lodash";

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "company"
  );

  return {
    // * Get company doc only
    getCompany: companyActions.getDocument,

    getCompanyBys: companyActions.getDocumentBys,
    createCompanyDoc: companyActions.createDocument,
    updateCompanyDoc: companyActions.updateDocument,

    async updateCompany({ dispatch }, { id, doc }) {
      await dispatch("updateCompanyDoc", { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_company",
        id
      });
      return id;
    },

    async createCompany({ dispatch }, company) {
      const id = await dispatch("createCompanyDoc", company);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_company",
        id
      });
      return id;
    },

    async deleteCompany({ dispatch }, id) {
      await dispatch("updateCompanyDoc", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });

      return await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "operation_company",
        id
      });
    },

    /**
     * ! Importance!!! This action gets data and sets it on Vuex store.
     * * If get data only, using 'getCompanyBys' instead
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    async getCompanyListBys({ commit, dispatch }, payload) {
      const companyList = await dispatch("getCompanyBys", payload);
      commit(types.SET_COMPANY_LIST, companyList || []);
      return companyList;
    },

    /**
     * ! Importance!!! This action gets data and sets it on Vuex store.
     * * If get data only, using 'getCompany' instead
     * @param {*} param0 Vuex params
     * @param {*} id company id
     * @returns company document
     */
    async getCompanyById({ commit, dispatch }, id) {
      if (!id) {
        return {};
      }
      const companyDoc = await dispatch("getCompany", id);
      commit(types.SET_COMPANY, companyDoc || {});

      const contactRefs = _.cloneDeep((companyDoc || {}).contactRefs || []);
      commit(types.SET_CONTACT_REFS, contactRefs);

      const branchRefs = _.cloneDeep((companyDoc || {}).branchRefs || []);
      commit(types.SET_BRANCH_REFS, branchRefs);

      const propertyRefs = _.cloneDeep((companyDoc || {}).propertyRefs || []);
      commit(types.SET_PROPERTY_REFS, propertyRefs);

      return companyDoc;
    },

    clearCompany({ commit, dispatch }) {
      commit(types.SET_COMPANY, {});
      commit(types.SET_CONTACT_REFS, []);
      commit(types.SET_BRANCH_REFS, []);
      commit(types.SET_PROPERTY_REFS, []);
    },

    //! Contact Refs
    addContactRefs({ commit }, contact) {
      commit(types.ADD_CONTACT_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: contact.id,
        contactName: `${contact.firstName} ${contact.lastName}`.trim(),
        phones: contact.phones || []
      });
    },

    removeContactRefs({ commit }, contactId) {
      commit(types.REMOVE_CONTACT_REFS, contactId);
    },

    clearContactRefs({ commit }) {
      commit(types.SET_CONTACT_REFS, []);
    },

    /**
     * * Calculate the contact refs (add or remove) and save to database
     * @param {*} param0
     * @param {*} param1
     * @returns
     */
    async updateContactRefs({ dispatch, getters }) {
      if (!_.isEmpty(getters.company)) {
        const latestCompany = await dispatch("getCompany", getters.company.id);
        if (_.isEmpty(latestCompany)) {
          return;
        }

        // * get removed contact refs
        let refsRemoved = _.differenceWith(
          latestCompany.contactRefs,
          getters.contactRefs,
          _.isEqual
        );
        for (const ref of refsRemoved) {
          // get contact data
          const contact = await dispatch(
            "contact-book/contact/getContact",
            ref.id,
            { root: true }
          );

          const companyRefs = ((contact || {}).companyRefs || []).filter(
            item => item.id !== latestCompany.id
          );

          await dispatch(
            "contact-book/contact/updateContact",
            {
              id: ref.id,
              doc: {
                companyRefs
              }
            },
            { root: true }
          );
        }

        // * get added contact refs
        const refsAdded = _.differenceWith(
          getters.contactRefs,
          latestCompany.contactRefs,
          _.isEqual
        );
        for (const ref of refsAdded) {
          // get contact data
          const contact = await dispatch(
            "contact-book/contact/getContact",
            ref.id,
            { root: true }
          );

          const companyRefs = _.cloneDeep((contact || {}).companyRefs || []);
          if (
            _.isEmpty(companyRefs.find(item => item.id === latestCompany.id))
          ) {
            companyRefs.push({
              id: latestCompany.id,
              createdRefAt: firebase.firestore.Timestamp.now(),
              companyName: latestCompany.companyName
            });

            await dispatch(
              "contact-book/contact/updateContact",
              {
                id: ref.id,
                doc: {
                  companyRefs
                }
              },
              { root: true }
            );
          }
        }

        // * update contact refs
        await dispatch("updateCompanyDoc", {
          id: latestCompany.id,
          doc: {
            contactRefs: getters.contactRefs
          }
        });
      }
    },

    //! Branch Refs
    addBranchRefs({ commit }, branch) {
      commit(types.ADD_BRANCH_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: branch.id,
        companyName: branch.companyName
      });
    },

    removeBranchRefs({ commit }, branchId) {
      commit(types.REMOVE_BRANCH_REFS, branchId);
    },

    clearBranchRefs({ commit }) {
      commit(types.SET_BRANCH_REFS, []);
    },

    /**
     * * Calculate the branch refs (add or remove) and save to database
     * @param {*} param0
     * @param {*} param1
     * @returns
     */
    async updateBranchRefs({ dispatch, getters }) {
      if (!_.isEmpty(getters.company)) {
        const latestCompany = await dispatch("getCompany", getters.company.id);
        if (_.isEmpty(latestCompany)) {
          return;
        }

        // * get removed branch refs
        let refsRemoved = _.differenceWith(
          latestCompany.branchRefs,
          getters.branchRefs,
          _.isEqual
        );
        for (const ref of refsRemoved) {
          // get branch data
          const branch = await dispatch("getCompany", ref.id);

          const branchRefs = ((branch || {}).branchRefs || []).filter(
            item => item.id !== latestCompany.id
          );

          await dispatch("updateCompanyDoc", {
            id: ref.id,
            doc: {
              branchRefs
            }
          });
        }

        // * get added branch refs
        const refsAdded = _.differenceWith(
          getters.branchRefs,
          latestCompany.branchRefs,
          _.isEqual
        );
        for (const ref of refsAdded) {
          // get branch data
          const branch = await dispatch("getCompany", ref.id);

          const branchRefs = _.cloneDeep((branch || {}).branchRefs || []);
          if (
            _.isEmpty(branchRefs.find(item => item.id === latestCompany.id))
          ) {
            branchRefs.push({
              id: latestCompany.id,
              createdRefAt: firebase.firestore.Timestamp.now(),
              companyName: latestCompany.companyName
            });

            await dispatch("updateCompanyDoc", {
              id: ref.id,
              doc: {
                branchRefs
              }
            });
          }
        }

        // * update branch refs
        await dispatch("updateCompanyDoc", {
          id: latestCompany.id,
          doc: {
            branchRefs: getters.branchRefs
          }
        });
      }
    },

    //! Property Refs
    addPropertyRefs({ commit }, property) {
      commit(types.ADD_PROPERTY_REFS, {
        createdRefAt: firebase.firestore.Timestamp.now(),
        id: property.id,
        propertyName: property.propertyName,
        addresses: property.addresses
      });
    },

    removePropertyRefs({ commit }, propertyId) {
      commit(types.REMOVE_PROPERTY_REFS, propertyId);
    },

    clearPropertyRefs({ commit }) {
      commit(types.SET_PROPERTY_REFS, []);
    },

    /**
     * * Calculate the property refs (add or remove) and save to database
     * @param {*} param0
     * @param {*} param1
     * @returns
     */
    async updatePropertyRefs({ dispatch, getters }) {
      if (!_.isEmpty(getters.company)) {
        const latestCompany = await dispatch("getCompany", getters.company.id);
        if (_.isEmpty(latestCompany)) {
          return;
        }

        // * get removed property refs
        let refsRemoved = _.differenceWith(
          latestCompany.propertyRefs,
          getters.propertyRefs,
          _.isEqual
        );
        for (const ref of refsRemoved) {
          // get property data
          const property = await dispatch(
            "contact-book/property/getProperty",
            ref.id,
            { root: true }
          );

          const companyRefs = ((property || {}).companyRefs || []).filter(
            item => item.id !== latestCompany.id
          );

          await dispatch(
            "contact-book/property/updateProperty",
            {
              id: ref.id,
              doc: {
                companyRefs
              }
            },
            { root: true }
          );
        }

        // * get added property refs
        const refsAdded = _.differenceWith(
          getters.propertyRefs,
          latestCompany.propertyRefs,
          _.isEqual
        );
        for (const ref of refsAdded) {
          // get property data
          const property = await dispatch(
            "contact-book/property/getProperty",
            ref.id,
            { root: true }
          );

          const companyRefs = _.cloneDeep((property || {}).companyRefs || []);
          if (
            _.isEmpty(companyRefs.find(item => item.id === latestCompany.id))
          ) {
            companyRefs.push({
              id: latestCompany.id,
              createdRefAt: firebase.firestore.Timestamp.now(),
              companyName: latestCompany.companyName
            });

            await dispatch(
              "contact-book/property/updateProperty",
              {
                id: ref.id,
                doc: {
                  companyRefs
                }
              },
              { root: true }
            );
          }
        }

        // * update property refs
        await dispatch("updateCompanyDoc", {
          id: latestCompany.id,
          doc: {
            propertyRefs: getters.propertyRefs
          }
        });
      }
    },

    /**
     * * Remove company ref of the contact when delete company
     * @param {*} param0
     */
    async removeCompanyRefForContact({ dispatch, getters }) {
      const latestCompany = await dispatch("getCompany", getters.company.id);
      if (_.isEmpty(latestCompany)) {
        return;
      }

      const refsRemoved = latestCompany.contactRefs || [];
      for (const ref of refsRemoved) {
        // get contact data
        const contact = await dispatch(
          "contact-book/contact/getContact",
          ref.id,
          { root: true }
        );

        const companyRefs = ((contact || {}).companyRefs || []).filter(
          item => item.id !== latestCompany.id
        );

        await dispatch(
          "contact-book/contact/updateContact",
          {
            id: ref.id,
            doc: {
              companyRefs
            }
          },
          { root: true }
        );
      }
    },

    async removeCompanyRefForBranch({ dispatch, getters }) {
      const latestCompany = await dispatch("getCompany", getters.company.id);
      if (_.isEmpty(latestCompany)) {
        return;
      }

      const refsRemoved = latestCompany.branchRefs || [];
      for (const ref of refsRemoved) {
        // get branch data
        const branch = await dispatch("getCompany", ref.id);

        const branchRefs = ((branch || {}).branchRefs || []).filter(
          item => item.id !== latestCompany.id
        );

        await dispatch("updateCompanyDoc", {
          id: ref.id,
          doc: {
            branchRefs
          }
        });
      }
    },

    async removeCompanyRefForProperty({ dispatch, getters }) {
      const latestCompany = await dispatch("getCompany", getters.company.id);
      if (_.isEmpty(latestCompany)) {
        return;
      }

      const refsRemoved = latestCompany.propertyRefs || [];
      for (const ref of refsRemoved) {
        // get property data
        const property = await dispatch(
          "contact-book/property/getProperty",
          ref.id,
          { root: true }
        );

        const companyRefs = ((property || {}).companyRefs || []).filter(
          item => item.id !== latestCompany.id
        );

        await dispatch(
          "contact-book/property/updateProperty",
          {
            id: ref.id,
            doc: {
              companyRefs
            }
          },
          { root: true }
        );
      }
    }
  };
};
