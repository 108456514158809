<template>
  <div>
    <iframe
      :src="viewableMapUrl"
      allowfullscreen
      frameborder="0"
      style="border:0"
      width="100%"
      :height="`${iframeHeight}px`"
    ></iframe>
    <p>
      <a target="_blank" :href="sharableMapUrl" class="external">
        View in Google map
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    propertyAddress: { type: String, default: () => null },
    startedLocation: { type: Object, default: () => null },
    endedLocation: { type: Object, default: () => null }
  },
  data() {
    return { iframeHeight: 400 };
  },
  fetchingTimer: null,
  computed: {
    viewableMapUrl() {
      return (
        `https://www.google.com/maps/embed/v1/directions?key=${this.$google.maps.apiKey}` +
        `&origin=${this.propertyAddress}&waypoints=${this.startedLocation.formattedAddress}&destination=${this.endedLocation.formattedAddress}`
      );
    },
    sharableMapUrl() {
      return `https://www.google.com/maps/dir/?api=1&origin=${this.propertyAddress}&waypoints=${this.startedLocation.formattedAddress}&destination=${this.endedLocation.formattedAddress}`;
    }
  }
};
</script>
