import * as types from "./types";
import Vue from "vue";
import _ from "lodash";

export default {
  setPurchaseOrderList(state, payload) {
    Vue.set(state, "purchaseOrderList", payload);
  },
  setPurchaseOrderSubscribe(state, payload) {
    Vue.set(state, "purchaseOrderSubscribe", payload);
  },
  [types.SET_PO_RESPONSE](state, response) {
    Vue.set(state, "response", response);
  },

  [types.PAGINATION_SET_PAGE](state, { key, value }) {
    Vue.set(state[key], value.pageNumber - 1, value);
  },

  [types.PAGINATION_SET_CURRENT_PAGE_NUMBER](state, { key, value }) {
    Vue.set(state, key, value);
  },

  [types.RESET_PAGE](state) {
    Vue.set(state, "purchaseOrderList", []);
    Vue.set(state, "purchaseOrderPages", []);
    Vue.set(state, "purchaseOrderCurrentPageNumber", 0);
  },

  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, "hits", hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, "hitsPerPage", payload);
  },

  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, "searchText", payload);
  },

  // Allow search desc only
  // [types.SET_PO_SEARCH_ORDER](state, payload) {
  //   Vue.set(state, "order", payload);
  // },

  [types.RESET_SEARCH](state) {
    Vue.set(state, "order", "desc");
    Vue.set(state, "searchText", "");

    Vue.set(state, "hits", []);
    Vue.set(state, "hitsPerPage", 25);
    Vue.set(state, "nbHits", 0);
    Vue.set(state, "nbPages", 0);
    Vue.set(state, "page", 0);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, "hitsPerPage", hitsPerPage);
    Vue.set(state, "nbHits", nbHits);
    Vue.set(state, "nbPages", nbPages);
    Vue.set(state, "page", page);
  },

  [types.PO_LIST_ITEM_REMOVE](state, id) {
    const index = state.hits.findIndex(item => item.id === id);
    if (index >= 0) {
      state.hits.splice(index, 1);
    }
  },

  [types.PO_LIST_ITEM_ADD](state, po) {
    if (
      state.selectedStatusFilter.length === 0 ||
      state.selectedStatusFilter.includes(po.status)
    ) {
      state.hits.unshift(po);
    }
  },
  [types.SET_SELECTED_STATUS_FILTER](state, payload) {
    Vue.set(state, "selectedStatusFilter", payload);
  },

  [types.PO_LIST_ITEM_UPDATE](state, { id, doc }) {
    const index = state.hits.findIndex(item => item.id === id);
    if (index >= 0) {
      const po = _.cloneDeep(state.hits[index]);

      if (
        doc.status &&
        state.selectedStatusFilter.length > 0 &&
        !state.selectedStatusFilter.includes(doc.status)
      ) {
        state.hits.splice(index, 1);
      } else {
        for (const key in doc) {
          if (Object.hasOwnProperty.call(doc, key) && doc[key] !== undefined) {
            po[key] = doc[key];
          }
        }
        Vue.set(state.hits, index, po);
      }
    }
  }
};
