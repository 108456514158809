<template>
  <div class="letter-section">
    <common-list-section
      title="Letter of Authorization"
      addBtnTitle="Create New"
      :listItems="letterList"
      :requiredErrorMessage="requiredErrorMessage"
      @openAddNewPopup="openAddNewPopup"
      @openEditPopup="openEditPopup"
      @openSendEmailPopup="sendLetter"
      @getPDF="getPDF"
      @onDelete="onDelete"
    ></common-list-section>

    <add-new-letter-popup
      ref="addNewLetterPopup"
      @openSendEmailPopup="sendLetter"
      @onPreview="onPreview"
    ></add-new-letter-popup>
    <letter-preview-popup ref="letterPreviewPopup"></letter-preview-popup>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send Letter"
      templateName="letter-authorization"
      :projectId="card && (card.id || '')"
      :attachments="card && (card.attachmentFiles || [])"
      :listEmails="listEmails"
      @closePopup="closePopupDetail"
    ></send-email-popup>

    <!-- to download -->
    <div
      class="letter-detail"
      style="position: relative;z-index: 0;overflow: hidden"
    >
      <preview-page
        ref="letterPreviewPageHidden"
        :itemDetail="letterDetail"
      ></preview-page>
    </div>
    <div
      style="height: 1px; background-color: var(--f7-block-strong-border-color)"
    ></div>
  </div>
</template>
<script>
import CommonListSection from "./CommonListSection.vue";
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import PreviewPage from "../../pages/PreviewPage.vue";
import sendEmail from "../../mixin/sendEmail";
import mixinMethods from "../../mixin/methods";
import { toDisplayDateString } from "../../../../utility/datetime";
import { mapGetters, mapActions } from "vuex";
import AddNewLetterPopup from "../popup/AddNewLetterPopup.vue";
import LetterPreviewPopup from "../popup/LetterPreviewPopup.vue";

export default {
  components: {
    CommonListSection,
    LetterPreviewPopup,
    SendEmailPopup,
    PreviewPage,
    AddNewLetterPopup
  },
  mixins: [sendEmail, mixinMethods],
  data() {
    return {
      letterDetail: {},
      requiredErrorMessage: ""
    };
  },
  props: {
    contactName: { type: String, default: "" },
    companyName: { type: String, default: "" }
  },
  computed: {
    ...mapGetters("dashboard/letter", ["letterList", "letterById"]),
    ...mapGetters("dashboard/project", ["card"]),
    ...mapGetters("dashboard/client", ["listEmails"])
  },
  methods: {
    ...mapActions("dashboard/letter", [
      "bindLetterListBys",
      "updateLetter",
      "createLetter"
    ]),

    initData(projectId) {
      if (projectId) {
        this.bindLetterListBys([
          {
            prop: "projectId",
            val: projectId,
            op: "=="
          },
          {
            prop: "isDeleted",
            val: false,
            op: "=="
          }
        ]);
      }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },
    openAddNewPopup() {
      this.requiredErrorMessage =
        this.contactName === "" && this.companyName === ""
          ? "Please select contact and insurance company first"
          : this.companyName === ""
          ? "Please select insurance company first"
          : this.contactName === ""
          ? "Please select contact first"
          : "";

      if (!this.requiredErrorMessage) {
        this.$refs.addNewLetterPopup.open();
      }
    },
    clearData() {
      this.requiredErrorMessage = "";
    },
    openEditPopup(id) {
      this.$refs.addNewLetterPopup.open(id);
    },

    getPDF(id) {
      const letter = this.letterById(id);
      this.letterDetail = letter;
      this.$nextTick(() => {
        this.$refs.letterPreviewPageHidden.exportPdf();
      });
    },

    onDelete({ id, callback }) {
      this.$ri.dialog
        .openWarningDialog({
          title: "Delete Letter",
          content: "Are you sure you want to delete this letter?",
          textButton: "Delete",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.$f7.preloader.show();
              this.updateLetter({
                id: id,
                doc: { isDeleted: true }
              }).then(() => {
                this.$f7.preloader.hide();
                callback && callback();
              });
            }
          }
        })
        .open();
    },

    onPreview(itemDetail) {
      this.$refs.letterPreviewPopup.open(itemDetail);
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    },
    closePopupDetail() {
      this.$refs.addNewLetterPopup.close();
    }
  }
};
</script>
