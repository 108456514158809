import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "/dashboard/swimlane/commercial/sales/new",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      isNew: true,
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "sales"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path: "/dashboard/grid/commercial/sales/new",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      isNew: true,
      businessCode: "commercial",
      boardType: "grid",
      boardCode: "sales"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialGridViewLayout.vue")
    )
  },

  {
    path:
      "/dashboard/swimlane/commercial/sales/project/:cardNumber/action/:actionCode",
    name: "commercial-card-detail",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "sales"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path:
      "/dashboard/swimlane/commercial/pre-construction/project/:cardNumber/action/:actionCode",
    name: "commercial-card-detail",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "pre-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path:
      "/dashboard/swimlane/commercial/post-construction/project/:cardNumber/action/:actionCode",
    name: "commercial-card-detail",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "post-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },

  // Swimlane
  {
    path: "/dashboard/swimlane/commercial/sales",
    title: "Commercial",
    name: "commercial-board",
    displayRoles: ["owner", "user"],
    parent: "dashboard-main",
    icon: "building_2",
    isShowMenuMobile: true,
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "sales"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path: "/dashboard/swimlane/commercial/pre-construction",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "pre-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path: "/dashboard/swimlane/commercial/post-construction",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "post-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  // Gridview
  {
    path:
      "/dashboard/grid/commercial/:boardCode/project/:cardNumber/action/:actionCode",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: { businessCode: "commercial", boardType: "grid" },
    async: checkAuth(() =>
      import("../pages/layout/CommercialGridViewLayout.vue")
    )
  },
  {
    path: "/dashboard/grid/commercial/sales",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "grid",
      boardCode: "sales"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialGridViewLayout.vue")
    )
  },
  {
    path: "/dashboard/grid/commercial/pre-construction",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "grid",
      boardCode: "pre-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialGridViewLayout.vue")
    )
  },
  {
    path: "/dashboard/grid/commercial/post-construction",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "grid",
      boardCode: "post-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialGridViewLayout.vue")
    )
  },

  // from my scheduling / employee scheduling
  {
    path:
      "/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/commercial/sales/project/:cardNumber/action/:actionCode",
    name: "commercial-card-detail",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "sales"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path:
      "/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/commercial/pre-construction/project/:cardNumber/action/:actionCode",
    name: "commercial-card-detail",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "pre-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  },
  {
    path:
      "/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/commercial/post-construction/project/:cardNumber/action/:actionCode",
    name: "commercial-card-detail",
    checkAccessPath: "dashboard-main/commercial-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "commercial",
      boardType: "swimlane",
      boardCode: "post-construction"
    },
    async: checkAuth(() =>
      import("../pages/layout/CommercialSwimlaneLayout.vue")
    )
  }
];
