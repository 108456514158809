<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="displayLaborList"
      :pageSize="displayLaborList.length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ totalLaborAmount | currencyUSD }}</i>
            </div>
          </f7-row>
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item }">
        <td class="label-cell">
          {{ "Week of " + toDisplayDateString(item.startWeek) }}
        </td>
        <!-- <td class="numeric-cell">{{ item.approvedHour }}</td> -->
        <td class="numeric-cell">{{ item.amount || 0 | currencyUSD }}</td>
        <td class="numeric-cell">
          {{ toDisplayDateString(item.createdDate) }}
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import methods from "../../mixin/methods";

export default {
  components: { DataTable },
  mixins: [methods],

  computed: {
    ...mapGetters("dashboard/project", ["userById"]),
    ...mapGetters("dashboard/project", ["constructionProject", "card"]),
    ...mapGetters("dashboard/finance", ["totalLaborAmount", "laborList"]),

    tableHeaders() {
      return [
        {
          text: "Week",
          align: "left",
          value: "week",
          width: "10%"
        },

        {
          text: "Amount",
          align: "right",
          value: "amount",
          width: "10%"
        },

        {
          text: "Generated Date",
          align: "right",
          value: "generated-date",
          width: "10%"
        }
      ];
    },

    displayLaborList() {
      return this.sortLatestByStartWeek(this.laborList);
    }
  },

  methods: {
    ...mapActions("dashboard/finance", ["initTimeTracking"]),

    initData() {
      this.$f7.preloader.show();
      this.initTimeTracking(
        this.getProjectId(this.constructionProject, this.card)
      ).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },

    sortLatestByStartWeek(list) {
      const listSort = (list || []).slice().sort((a, b) => {
        const aDate = a.startWeek || { nanoseconds: 0, seconds: 0 };
        const bDate = b.startWeek || { nanoseconds: 0, seconds: 0 };
        return (this.dateToValue(aDate) - this.dateToValue(bDate)) * -1;
      });
      return listSort;
    }
  }
};
</script>

<style lang="scss" scoped>
.label-cell {
  padding-left: 10px;
}
</style>
