import * as types from "./types";
import { searchClient } from "../../services/search.service";
import _ from "lodash";
import { FirebaseActions } from "../../services/firebase.service";

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    "board"
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "action"
  );

  const entityIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const invoiceGroupActions = new FirebaseActions(
    `/system_client/${tenant}/invoice_group`,
    "invoiceGroup"
  );

  return {
    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getActionById: actionActions.getDocument,
    getInvoiceGroupListBys: invoiceGroupActions.getDocumentBys,

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchEntity(
      { state, commit, getters },
      { query, attributesToRetrieve, filters, restrictSearchableAttributes }
    ) {
      const index = entityIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: getters.filterForSearch
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters = filters;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    async getInvoiceGroupByProjectId({ dispatch }, projectId) {
      const invoiceGroups = await dispatch("getInvoiceGroupListBys", [
        {
          prop: "projectId",
          op: "==",
          val: projectId
        },
        { prop: "isProjectArchived", op: "==", val: false }
      ]);

      return invoiceGroups;
    },

    loadMoreEntity(
      { state, commit, getters },
      {
        query,
        attributesToRetrieve,
        filters,
        restrictSearchableAttributes,
        page
      }
    ) {
      const index = entityIndexAsc;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: getters.filterForSearch
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters = filters;
      }
      if (!_.isEmpty(restrictSearchableAttributes)) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      return index
        .search(query, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setEntityNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    openGlobalSearchPopup({ commit }, payload) {
      commit(types.SHOW_GLOBAL_SEARCH_POPUP, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    }
  };
};
