export default {
  estimateList: state =>
    state.estimateList.filter(item => item.isDeleted === false),

  estimate: state => state.estimate,

  estimateByNumber: (state, getters) => estimateNumber => {
    return (
      getters.estimateList.find(r => r.estimateNumber === estimateNumber) || {}
    );
  },

  estimateById: (state, getters) => estimateId => {
    return getters.estimateList.find(r => r.id === estimateId) || {};
  }
};
