<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="cancelNewGroup" @popup:opened="handlePopupOpened">
    <f7-view>
      <f7-page>
        <form>
          <f7-navbar>
            <f7-nav-left>
              <f7-link popup-close>Cancel</f7-link>
            </f7-nav-left>
            <f7-nav-title>{{
              isNew ? "New Group" : "Edit Group"
              }}</f7-nav-title>
            <f7-nav-right>
              <f7-link @click.native="isNew ? add() : save()">Done</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <f7-list form class="components-list searchbar-found" @submit.prevent="add">
            <f7-list-input class="first-input" placeholder="Group Name" :disabled="isDefaultGroup" label="Group Name"
              :value="currentGroup.displayName" @input="currentGroup.displayName = $event.target.value"
              error-message-force :clear-button="!isDefaultGroup" validate :error-message="nameGroupErrorMessage">
              <required-asterisk slot="label" />
            </f7-list-input>
          </f7-list>
          <f7-list>
            <f7-list-input type="textarea" placeholder="Description" label="Description"
              :value="currentGroup.description" @input="currentGroup.description = $event.target.value"
              error-message-force clear-button validate :error-message="descGroupErrorMessage">
              <required-asterisk slot="label" />
            </f7-list-input>
          </f7-list>

          <f7-list>
            <f7-list-item title="Active">
              <f7-toggle :disabled="isDefaultGroup" slot="after" :checked="!currentGroup.disabled"
                @toggle:change="currentGroup.disabled = !$event"></f7-toggle>
            </f7-list-item>
          </f7-list>
          <f7-list>
              <f7-list-item link @click.native="openPopupGroupSelect('menu')">
                <div slot="title">Menu Item</div>
                <div slot="after-title" style="white-space: nowrap;overflow: hidden;text-overflow:ellipsis; width:70%;color:var(--f7-list-item-after-text-color);">
                  {{ menuList }}
                </div>
              </f7-list-item>
          </f7-list>
          <f7-list>
            <f7-list-item link @click.native="openPopupGroupSelect('user')">
              <div slot="title">Assign User</div>
              <div slot="after-title" style="white-space: nowrap;overflow: hidden;text-overflow:ellipsis; width:70%;color:var(--f7-list-item-after-text-color);">
                {{ userList }}
              </div>
            </f7-list-item>
          </f7-list>
        </form>
      </f7-page>
    </f7-view>
    <group-select ref="groupSelect" :selected="currentGroup" :menus="menus" :users="users.map(x => ({ ...x, id: x.uid }))"
    @select="handleSelected" ></group-select>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import _ from "lodash";
import GroupSelect from "@/components/selects/GroupSelect.vue";
import { COLLECTION_GROUP_ADMIN } from "@/utility/const";
import { VALIDATION_MESSAGE } from '@/utility/const';
import InputIcon from "@/components/icons/InputIcon.vue";

export default {
  components: {
    GroupSelect,
    InputIcon
  },

  props: {
    popupOpened: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters("administration/group-list-page/group", ["group", "groups"]),
    ...mapGetters("administration/group-list-page/menu", ["menus"]),
    ...mapGetters("administration/group-list-page/user", ["users"]),
    isDefaultGroup() {
      if (this.group && this.group.isDefault && !this.isNew) {
        return !!this.group.isDefault;
      }
      return false;
    },
    nameGroupErrorMessage() {
      if (!this.v$.currentGroup.displayName.$error) return null;
      if (this.v$.currentGroup.displayName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    descGroupErrorMessage() {
      if (!this.v$.currentGroup.description.$error) return null;
      if (this.v$.currentGroup.description.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    menuList() {
      if (!this.currentGroup.menuIDs) return "";      
      return (this.menus.filter(r => (this.currentGroup.menuIDs || []).includes(r.id)) || [])
        .map(r => r.displayName)
        .join(", ");
    },
    userList() {
      if (!this.currentGroup.userIDs) return "";
      return (this.users.filter(r => (this.currentGroup.userIDs || []).includes(r.uid)) || [])
        .map(r => r.displayName)
        .join(", ");
    }
  },

  data: () => {
    return {
      currentGroup: {
        displayName: "",
        description: "",
        disabled: false,
        menuIDs: [],
        userIDs: []
      }
    };
  },

  methods: {
    ...mapActions("administration/group-list-page/group", [
      "createGroup",
      "deleteGroup",
      "updateGroup"
    ]),

    ...mapActions("common/administration-user", [
      "addGroupRef",
      "removeGroupRef"
    ]),
    ...mapActions("common/notification", ["createNotificationByType"]),

    cancelNewGroup() {
      this.v$.$reset();
      this.$emit("close");
    },
    handleSelected(value, listType) {      
      if (listType === "user") {
        this.currentGroup.userIDs = value;
      } else {
        this.currentGroup.menuIDs = value;        
      }
    },
    existsGroup() {
      const found = this.groups.find(group => {
        return group.displayName === this.currentGroup.displayName;
      });

      if (!_.isEmpty(found)) {
        this.$ri.dialog.openWarningDialog({
          title: "That group has already existed!",
          content: "Please choose another one.",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      }

      return !_.isEmpty(found);
    },

    add() {
      this.v$.$touch();
      if (this.v$.$invalid || this.existsGroup()) {
        return;
      }

      this.$f7.preloader.show();

      this.createGroup(this.currentGroup).then(async groupID => {
        // for (const menuID of this.currentGroup.menuIDs) {
        //   this.modifyRelationShip({
        //     id: menuID,
        //     action: "add",
        //     doc: {
        //       id: groupID,
        //       key: "groupIDs"
        //     }
        //   });
        // }
        if (!this.currentGroup.disabled) {
          await this.createNotificationByType({
            data: {
              assignees: this.currentGroup.userIDs,
              project: {
                title: this.currentGroup.displayName,
                id: groupID,
                type: "group",
                entityName: COLLECTION_GROUP_ADMIN
              }
            },
            type: "assign-user"
          });
        }

        const batchSize = 10;

        for (let i = 0; i < this.currentGroup.userIDs; i += batchSize) {
          const batch = this.currentGroup.userIDs.slice(i, i + batchSize);

          await Promise.all(
            batch.map(userID => this.addGroupRef({
              uid: userID,
              groupID
            }))
          );
        }
        this.cancelNewGroup();
        this.$f7.preloader.hide();
        this.$f7router.navigate(`/administration/groups/${groupID}`);
      });
    },

    save() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      // let menuIDsBefore = _.cloneDeep(this.group.menuIDs);
      // let menuIDsAfter = _.cloneDeep(this.currentGroup.menuIDs);
      this.$f7.preloader.show();

      let userIDsBefore = _.cloneDeep(this.group.userIDs);
      let groupNameBefore = _.cloneDeep(this.group.displayName);
      let statusBefore = _.cloneDeep(this.group.disabled);

      let userIDsAfter = _.cloneDeep(this.currentGroup.userIDs);
      let groupNameAfter = _.cloneDeep(this.currentGroup.displayName);
      let statusAfter = _.cloneDeep(this.currentGroup.disabled);

      this.updateGroup({
        id: this.currentGroup.id,
        doc: {
          ...this.currentGroup,
          menuIDs: this.currentGroup.menuIDs.map(x => {
            return x.uid ? x.uid : x;
          }),
          userIDs: this.currentGroup.userIDs.map(x => {
            return x.uid ? x.uid : x;
          })
        }
      }).then(async () => {
        // let menuIDsRemove = _.differenceWith(
        //   menuIDsBefore,
        //   menuIDsAfter,
        //   _.isEqual
        // );
        // let menuIDsAdd = _.differenceWith(
        //   menuIDsAfter,
        //   menuIDsBefore,
        //   _.isEqual
        // );
        // remove|add menu - group ref
        // for (const menuID of menuIDsAdd) {
        //   this.modifyRelationShip({
        //     id: menuID,
        //     action: "add",
        //     doc: {
        //       id: this.currentGroup.id,
        //       key: "groupIDs"
        //     }
        //   });
        // }

        // for (const menuID of menuIDsRemove) {
        //   this.modifyRelationShip({
        //     id: menuID,
        //     action: "remove",
        //     doc: {
        //       id: this.currentGroup.id,
        //       key: "groupIDs"
        //     }
        //   });
        // }

        let userIDsRemove = _.differenceWith(
          userIDsBefore,
          userIDsAfter,
          _.isEqual
        );
        let userIDsAdd = _.differenceWith(
          userIDsAfter,
          userIDsBefore,
          _.isEqual
        );

        // remove|add user - group ref
        const batchSize = 100;

        for (let i = 0; i < userIDsAdd.length; i += batchSize) {
          const batch = userIDsAdd.slice(i, i + batchSize);

          await Promise.all(
            batch.map(userID => this.addGroupRef({
              id: userID,
              groupID: this.currentGroup.id
            }))
          );
        }
        for (let i = 0; i < userIDsRemove.length; i += batchSize) {
          const batch = userIDsRemove.slice(i, i + batchSize); 

          await Promise.all(
            batch.map(userID => this.removeGroupRef({
              id: userID,
              groupID: this.currentGroup.id
            }))
          );
        }

        if (statusBefore !== statusAfter) {
          await this.createNotificationByType({
            data: {
              assignees: userIDsAfter,
              disabled: statusAfter,
              project: {
                title: groupNameAfter,
                id: this.currentGroup.id,
                type: "group",
                entityName: COLLECTION_GROUP_ADMIN
              }
            },
            type: statusAfter ? "inactive-group" : "active-group"
          });
        }
        if (groupNameBefore !== groupNameAfter) {
          await this.createNotificationByType({
            data: {
              assignees: userIDsAfter,
              oldName: groupNameBefore,
              project: {
                title: groupNameAfter,
                id: this.currentGroup.id,
                type: "group",
                entityName: COLLECTION_GROUP_ADMIN
              }
            },
            type: "change-group-name"
          });
        }
        if (userIDsAdd.length > 0) {
          await this.createNotificationByType({
            data: {
              assignees: userIDsAdd,
              project: {
                title: groupNameAfter,
                id: this.currentGroup.id,
                type: "group",
                entityName: COLLECTION_GROUP_ADMIN
              }
            },
            type: "assign-user"
          });
        }
        if (userIDsRemove.length > 0) {
          await this.createNotificationByType({
            data: {
              assignees: userIDsRemove,
              project: {
                title: groupNameAfter,
                id: this.currentGroup.id,
                type: "group",
                entityName: COLLECTION_GROUP_ADMIN
              }
            },
            type: "remove-user"
          });
        }
        this.$f7.preloader.hide();
        this.$emit("close");
      });
    },

    mergerSelect(array) {
      if (array.length > 0) {
        return (array || []).map(item => {
          return item.id ? item.id : item;
        });
      }
    },

    handlePopupOpened() {
      if (this.isNew && this.$device.desktop) {
        this.$el.querySelector(".first-input input").focus();
      }
    },
    openPopupGroupSelect(listType) {
      this.$refs.groupSelect.open(listType);
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    currentGroup: {
      displayName: { required },
      description: { required }
    }
  },

  watch: {
    group: {
      handler(val) {
        if (!_.isEmpty(val) && !this.isNew) {
          this.currentGroup = _.cloneDeep(val);
          // console.log(this.currentGroup);
        }
      },
      immediate: true,
      deep: true
    },
    popupOpened() {
      if (this.isNew) {
        this.currentGroup = {
          displayName: "",
          description: "",
          disabled: false,
          menuIDs: [],
          userIDs: []
        };
      }
    }
  }
};
</script>
