<template>
  <div>
    <f7-block-header>{{ title }}</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <template v-if="useDefaultTemplate">
        <f7-list-item v-for="(email, index) in value" :key="index" @click.native="showDetails(email)">
          <a class="cursor-pointer" slot="title">{{ email.subject }}</a>
          <div slot="footer">
            Sent at {{ toDisplayDateString(email.createdAt) }} by
            {{ email.createdBy }}
          </div>
        </f7-list-item>
      </template>
      <template v-else>
        <f7-list-item v-for="(email, index) in value" :key="index" @click.native="showDetails(email)">
          <slot name="item" :email="email"></slot>
          <!-- <a class="cursor-pointer" slot="title">{{ email.subject }}</a>
          <div slot="footer">
            Sent at {{ toDisplayDateString(email.createdAt) }} by
            {{ email.createdBy }}
          </div> -->
        </f7-list-item>
      </template>

      <!-- Add list item -->
      <f7-list-item v-show="!readOnly" @click.native="
        emailPopupOpened = true;
      initData();
      ">
        <div slot="media" class="add-button-icon cursor-pointer">
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <div class="cursor-pointer" slot="title">Compose new email</div>
      </f7-list-item>
    </f7-list>

    <email-details-popup ref="emailDetailsPopup"></email-details-popup>

    <f7-popup :opened="emailPopupOpened" @popup:closed="emailPopupOpened = false">
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="emailPopupOpened = false">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Compose new email</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="onSend">Send</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list>
          <f7-list-input label="To" type="textarea" resizable clear-button :value="emailAddress"
            @input="emailAddress = $event.target.value" error-message-force :error-message="emailErrorMessage"
            @blur="v$.$touch()"><input-icon slot="media" icon="envelope"></input-icon></f7-list-input>
          <!-- subject -->
          <f7-list-input label="Subject" type="textarea" resizable clear-button :value="subject"
            @input="subject = $event.target.value" error-message-force :error-message="subjectErrorMessage"
            @blur="v$.$touch()"><input-icon slot="media" icon="square_favorites_alt"></input-icon></f7-list-input>
          <!--  message -->
          <!-- <f7-list-input
            label="Message"
            floating-label
            type="texteditor"
            resizable
            clear-button
            :value="message"
            @texteditor:change="message = $event"
            ><input-icon slot="media" icon="text_quote"></input-icon
          ></f7-list-input> -->
        </f7-list>
        <!-- Text area -->
        <f7-block-header>Message</f7-block-header>
        <f7-text-editor :value="message" @texteditor:change="message = $event"></f7-text-editor>

        <f7-block-header v-if="useAttachment" v-show="attachments.length > 0">Select Files to Email</f7-block-header>
        <f7-list v-if="useAttachment">
          <f7-list-item v-for="(item, index) in attachments" :key="index" checkbox :title="item.fileName"
            :value="item.fileName" @change="selectAttachmentFile(item, $event)"></f7-list-item>
        </f7-list>
        <attachment-input :project-id="projectId" :title="attachmentTitle" attachment-type="email-attachment"
          add-button-title="Add an Attachment" :value="attachmentFiles"
          @input="attachmentFiles = $event"></attachment-input>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { firebase, auth } from "../../../../services/firebase.service";
import { toDisplayDateString } from "../../../../utility/datetime";
import _ from "lodash";

import { useVuelidate } from '@vuelidate/core'
import { required, email } from "@vuelidate/validators";
import axiosService from "../../../../services/axios.service";

import AttachmentInput from "./AttachmentInput.vue";
import InputIcon from "../icon/InputIcon.vue";
import EmailDetailsPopup from "../popup/EmailDetailsPopup.vue";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { AttachmentInput, InputIcon, EmailDetailsPopup },

  props: {
    value: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    emailType: { type: String, default: "" },
    projectId: { type: String, default: "" },
    defaultSubject: { type: String, default: "" },
    defaultMessage: { type: String, default: "" },
    defaultEmailAddress: { type: String, default: "" },
    attachmentTitle: { type: String, default: "Attachments" },
    useAttachment: { type: Boolean, default: false },
    attachments: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
    useDefaultTemplate: { type: Boolean, default: true }
  },

  data: () => {
    return {
      emailPopupOpened: false,
      emailAddress: "",
      subject: "",
      message: "",
      attachmentFiles: []
    };
  },

  // watch: {
  //   message(val) {
  //     console.log(val);
  //   }
  // },

  computed: {
    emailErrorMessage() {
      if (!this.v$.emailAddress.$error) return null;
      if (this.v$.emailAddress.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.emailAddress.commonEmailRule.$invalid) return VALIDATION_MESSAGE.INVALID_EMAIL;
      return null;
    },

    subjectErrorMessage() {
      if (!this.v$.subject.$error) return null;
      if (this.v$.subject.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    showDetails(email) {
      this.$refs.emailDetailsPopup.show(email);
    },

    selectAttachmentFile(item, event) {
      const isAddList = event.target.checked;
      const fileName = event.target.value;

      if (isAddList) {
        let file = _.cloneDeep(item);
        file.attachmentType = "email-attachment";
        this.attachmentFiles.push(file);
      } else {
        const index = this.attachmentFiles.findIndex(
          item => item.fileName === fileName
        );

        if (index > -1) {
          this.attachmentFiles.splice(index, 1);
        }
      }
    },

    initData() {
      this.subject = this.defaultSubject || "";
      this.message = this.defaultMessage || "";
      this.emailAddress = this.defaultEmailAddress || "";
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    onSend() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$f7.preloader.show();
      let value = _.cloneDeep(this.value);
      let emailList = [];
      this.emailAddress
        .trim()
        .split(";")
        .forEach(email => {
          if (email.trim() !== "") {
            emailList.push({
              subject: this.subject,
              receiver: email.trim(),
              template: this.emailType,
              content: this.message,
              attachments: this.attachmentFiles
            });
          }
        });

      const refs = [];
      emailList.forEach(email => {
        refs.push(this.sendMail(email));
      });

      Promise.all(refs)
        .then(emails => {
          this.emailPopupOpened = false;
          value.push(...emails);
          this.$ri.dialog.openSuccessDialog({
            title: "The email is sent successfully",
            content: "",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.$emit("input", value);
                this.clearData();
                _sefl.app.dialog.close();
              }
            }
          });
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    clearData() {
      this.emailAddress = ";";
      this.subject = "";
      this.message = "";
      this.attachmentFiles = [];
    },

    sendMail(email) {
      // return axiosService
      //   .post("/notification/send-emails", {
      //     emailAddresses: email.emailAddresses,
      //     subject: email.subject,
      //     template: email.template,
      //     content: email.content
      //   })
      //   .catch(error => {
      //     // eslint-disable-next-line no-console
      //     console.error("[Send Email]", error);
      //     throw new Error(error.message);
      //   });
      return axiosService
        .post("/notification/push-email", {
          to: email.receiver,
          subject: {
            message: email.subject
          },
          template: email.template,
          content: {
            message: email.content
          },
          attachments: email.attachments,
          scheduleSendingTime: ""
        })
        .then(data => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          // return axiosService.get("/notification/trigger-email-service");
          return {
            ...email,
            id: data.data,
            createdAt: firebase.firestore.Timestamp.now(),
            createdBy: auth.currentUser.displayName || auth.currentUser.email
          };
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },

    openPopup(defaultSubject, emails, message) {
      this.emailAddress = emails;
      this.subject = defaultSubject;
      this.message = message;

      this.emailPopupOpened = true;
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      subject: {
        required
      },
      emailAddress: {
        required,
        commonEmailRule: email
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
