import {
  FirebaseActions,
  firestore
} from "../../../../services/firebase.service";
import * as constant from "../../../../utility/const";
import invoiceService from "../../../../services/invoice.service";
import algoliaService from "../../../../services/algolia.service";
import { searchClient } from "../../../../services/search.service";
import * as types from "./types";

import { COLLECTION_INVOICE_GROUP } from "../../../../utility/const";

export default tenant => {
  const invoiceGroupActions = new FirebaseActions(
    `/system_client/${tenant}/${COLLECTION_INVOICE_GROUP}`,
    "invoiceGroup"
  );

  const invoiceGroupIndexDesc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );

  const invoiceGroupIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    createInvoiceGroup: invoiceGroupActions.createDocument,
    saveInvoiceGroup: invoiceGroupActions.updateDocument,

    getInvoiceGroupById: invoiceGroupActions.getDocument,
    getInvoiceGroupBys: invoiceGroupActions.getDocumentBys,

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchInvoiceGroup(
      { state, commit, getters },
      { attributesToRetrieve, pageNumber }
    ) {
      const index =
        state.order === "asc" ? invoiceGroupIndexAsc : invoiceGroupIndexDesc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_INVOICE_GROUP} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.INVOICE_GROUP_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.INVOICE_GROUP_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (pageNumber) {
        requestOptions.page = pageNumber;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    goToPage({ state, commit, getters }, { pageName }) {
      const index =
        state.order === "asc" ? invoiceGroupIndexAsc : invoiceGroupIndexDesc;
      const filters = getters.queryFilters;

      let pageNumber = 0;
      switch (pageName) {
        case "next":
          pageNumber = state.page + 1;
          break;

        case "prev":
          pageNumber = state.page - 1;
          break;

        case "first":
          pageNumber = 0;
          break;

        case "last":
          pageNumber = state.nbPages - 1;
          break;

        default:
          pageName = 0;
          break;
      }
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: pageNumber,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_INVOICE_GROUP} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.INVOICE_GROUP_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.INVOICE_GROUP_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreInvoiceGroup(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index =
        state.order === "asc" ? invoiceGroupIndexAsc : invoiceGroupIndexDesc;
      const filters = getters.queryFilters;

      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_INVOICE_GROUP} AND isProjectArchived:false`,
        attributesToRetrieve:
          constant.INVOICE_GROUP_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.INVOICE_GROUP_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setInvoiceGroupNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },

    setInvoiceGroupSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    // Allow search desc only
    // setInvoiceSearchOrder({ commit }, payload) {
    //   commit(types.SET_SEARCH_ORDER, payload);
    // },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async loadCurrentPage({ state, dispatch }) {
      const statusFilters = state.selectedStatusFilter
        .map(s => `status:${s}`)
        .join(" OR ");
      await dispatch("searchInvoiceGroup", {
        query: state.searchText,
        pageNumber: state.page,
        filters: statusFilters
      });
      const { hits, nbPages } = state;
      if (!hits.length && nbPages > 0) {
        await dispatch("searchInvoiceGroup", {
          query: state.searchText,
          pageNumber: nbPages - 1,
          filters: statusFilters
        });
      }
    },

    async createNewInvoiceGroup(
      { dispatch, commit, rootGetters },
      { invoiceGroup, isInvoiceGrid = false }
    ) {
      const user = rootGetters["setting/app/profile/user"];
      const doc = await invoiceService.addInvoiceGroupDoc(
        user.tenantId,
        invoiceGroup
      );
      if (isInvoiceGrid) {
        await algoliaService.algoliaUpdateItem({
          tenantId: tenant,
          collection: `${COLLECTION_INVOICE_GROUP}`,
          id: doc.id
        });
        await dispatch("loadCurrentPage");
      } else {
        commit(types.INVOICE_GROUP_LIST_ITEM_ADD, {
          ...invoiceGroup,
          id: doc.id,
          createdAt: doc.createdAt
        });
      }
      return doc;
    },

    async getInvoiceGroupByProjectId({ dispatch }, projectId) {
      if (!projectId) return;
      const invoiceGroupList = await dispatch("getInvoiceGroupBys", [
        { prop: "projectId", op: "==", val: projectId }
      ]);

      if (invoiceGroupList?.length > 0) {
        return invoiceGroupList[0];
      }
      return {};
    },

    async updateInvoiceGroup({ dispatch }, { id, doc }) {
      await dispatch("saveInvoiceGroup", { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: `${COLLECTION_INVOICE_GROUP}`,
        id
      });
      return id;
    },

    deleteInvoiceGroup({ dispatch, commit }, id) {
      return dispatch("saveInvoiceGroup", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      }).then(() => {
        return commit(types.INVOICE_GROUP_LIST_ITEM_REMOVE, id);
      });
    },

    setSelectedStatusFilter({ commit }, payload) {
      commit(types.SET_SELECTED_STATUS_FILTER, payload);
    },

    getInvoiceGroupByTenant(context, { id, tenant }) {
      let collectionPath = `/system_client/${tenant}/${COLLECTION_INVOICE_GROUP}`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    }
  };
};
