import Cookies from "js-cookie";

// get language from cookie
export function getActiveLanguage() {
  const lang = getRememberLanguage() || getBrowserLanguage();
  const included = langs.some(function(item) {
    return item.id === lang;
  });
  return included ? lang : "en";
}

export const langs = [
  { id: "en", name: "English" },
  { id: "esp", name: "Tay ban nha" }
];

function getRememberLanguage() {
  return Cookies.get("lang");
}

export function setRememberLanguage(lang) {
  Cookies.set("lang", lang);
}

// get language from browser
function getBrowserLanguage() {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  return navigatorLocale.trim().split(/-|_/)[0];
}
