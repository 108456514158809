<template>
  <f7-link
    :style="
      `background-color: ${item.color ? hexToRgba(item.color) : '#EFEDED'}`
    "
    class="schedule-item"
    :popover-open="`.info-popover_${item.id}`"
  >
    <div style="font-weight: 700;">{{ item.startTime | HMMA }}</div>
    <div class="schedule-title">
      <span v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">{{
        timeOffType.value
      }}</span>
      <span v-else-if="item.timeLogType === TIME_LOG_TYPE_PROJECT">{{
        item.project && item.project.title
      }}</span>
      <span v-else>{{ item.title }}</span>
    </div>

    <f7-popover :class="`info-popover info-popover_${item.id}`">
      <f7-card class="no-margin">
        <f7-card-content>
          <div class="fw-600 display-flex align-items-start margin-bottom-half">
            <f7-icon
              class="margin-right-half"
              f7="calendar"
              color="red"
              size="18"
            ></f7-icon>
            <div>{{ displayDate(item.date) }}</div>
          </div>
          <div class="fw-600 display-flex align-items-start margin-bottom-half">
            <f7-icon
              class="margin-right-half"
              f7="clock"
              color="red"
              size="18"
            ></f7-icon>
            <div>
              {{ item.startTime | HMMA }} - {{ item.finishTime | HMMA }}
            </div>
          </div>
          <div class="fw-600 display-flex align-items-start">
            <f7-icon
              class="margin-right-half"
              f7="cube_box"
              color="red"
              size="18"
            ></f7-icon>
            <div v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
              {{
                timeOffType(item.timeOffType) &&
                  timeOffType(item.timeOffType).value
              }}
            </div>
            <div v-else-if="item.timeLogType === TIME_LOG_TYPE_PROJECT">
              <f7-link
                popover-close
                @click.native="
                  $emit('goToProjectDetail', {
                    userId: item.userId,
                    projectId: item.projectId
                  })
                "
              >
                <span
                  v-if="
                    item.project &&
                      (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                        (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                          item.project.customerType ===
                            BUSINESS_CODE_COMMERCIAL))
                  "
                >
                  {{
                    item.project.propertyName
                      ? `${item.project.propertyName}`
                      : ""
                  }}{{
                    item.project.propertyName &&
                    getFullAddress(item.project.propertyAddress)
                      ? " - "
                      : ""
                  }}{{ getFullAddress(item.project.propertyAddress) }}
                </span>
                <span
                  v-else-if="
                    item.project &&
                      (item.project.businessCode ===
                        BUSINESS_CODE_RESIDENTIAL ||
                        (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                          (item.project.customerType ===
                            BUSINESS_CODE_RESIDENTIAL ||
                            item.project.customerType ===
                              BUSINESS_CODE_MULTIFAMILY)))
                  "
                >
                  {{
                    item.project.contactName
                      ? `${item.project.contactName}`
                      : ""
                  }}{{
                    item.project.contactName &&
                    getFullAddress(item.project.projectAddress)
                      ? " - "
                      : ""
                  }}{{ getFullAddress(item.project.projectAddress) }}
                </span>
              </f7-link>
            </div>
            <div v-else>{{ item.title }}</div>
          </div>
          <div
            v-if="item.notes"
            class="fw-600 display-flex align-items-start margin-top-half"
          >
            <f7-icon
              class="margin-right-half"
              f7="square_pencil"
              color="red"
              size="18"
            ></f7-icon>
            <div class="text-notes" v-html="displayNotes"></div>
          </div>
        </f7-card-content>
        <f7-card-footer>
          <f7-button
            v-if="isMyScheduling"
            class="flex-center margin-right-half"
            small
            fill
            popover-close
            @click="$emit('openTimeLogPopup')"
            >Start</f7-button
          >
          <f7-button
            v-if="
              item.project &&
                getFullAddress(item.project.propertyAddress) &&
                (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    item.project.customerType === BUSINESS_CODE_COMMERCIAL))
            "
            small
            outline
            popover-close
            @click.native="
              $emit('openAddressOnMap', item.project.propertyAddress)
            "
            >View Map</f7-button
          >
          <f7-button
            v-else-if="
              item.project &&
                getFullAddress(item.project.projectAddress) &&
                (item.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    (item.project.customerType === BUSINESS_CODE_RESIDENTIAL ||
                      item.project.customerType === BUSINESS_CODE_MULTIFAMILY)))
            "
            small
            outline
            popover-close
            @click.native="
              $emit('openAddressOnMap', item.project.projectAddress)
            "
            >View Map</f7-button
          >
          <div
            class="display-flex align-items-center justify-content-flex-end"
            style="flex:1"
          >
            <div v-if="item.scheduleChainId">
              <chain-popover
                :icon="$emit('checkCreator', item.createdBy) ? 'pencil' : 'eye'"
                :iconSize="18"
                popoverClass="popover-edit"
                @clickItem="$emit('openEditPopup', { item, type: $event })"
              ></chain-popover>
            </div>
            <div v-else>
              <f7-button
                class="flex-center"
                popover-close
                @click="$emit('openEditPopup', { item, type: '' })"
              >
                <f7-icon
                  class="icon"
                  size="18"
                  :f7="$emit('checkCreator', item.createdBy) ? 'pencil' : 'eye'"
                ></f7-icon>
              </f7-button>
            </div>

            <div
              v-if="
                item.scheduleChainId && $emit('checkCreator', item.createdBy)
              "
            >
              <chain-popover
                icon="trash"
                :iconSize="18"
                popoverClass="popover-delete"
                @clickItem="$emit('handleDelete', { item, type: $event })"
              ></chain-popover>
            </div>
            <div
              v-else-if="
                !item.scheduleChainId && $emit('checkCreator', item.createdBy)
              "
            >
              <f7-button
                class="flex-center"
                popover-close
                @click="$emit('handleDelete', { item, type: '' })"
              >
                <f7-icon class="icon" size="18" f7="trash"></f7-icon>
              </f7-button>
            </div>
            <div>
              <f7-button
                class="flex-center"
                popover-close
                @click="$emit('handleAddCopy', item)"
              >
                <f7-icon class="icon" size="18" f7="doc_on_doc"></f7-icon>
              </f7-button>
            </div>
            <div>
              <f7-button
                class="flex-center"
                popover-close
                @click="$emit('handleAddPhoto', item)"
              >
                <f7-icon
                  class="icon"
                  size="18"
                  f7="photo_on_rectangle"
                ></f7-icon>
              </f7-button>
            </div>
          </div>
        </f7-card-footer>
      </f7-card>
    </f7-popover>
  </f7-link>
</template>
<script>
import {
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_MULTIFAMILY
} from "../../../../utility/const";
import ChainPopover from "./ChainPopover.vue";
import { hexToRgba } from "../../../../utility/theme";
import { getFullAddress } from "../../../../utility/address";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    ChainPopover
  },
  props: {
    item: Object
  },
  data: () => {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_MULTIFAMILY
    };
  },
  computed: {
    ...mapGetters("scheduling/app-constant", ["timeOffTypeList"]),
    ...mapGetters("scheduling/scheduling", ["isMyScheduling"]),
    displayNotes() {
      return this.item.notes.replace(/\r\n|\r|\n/g, "<br />");
    }
  },
  methods: {
    hexToRgba(hex) {
      return hexToRgba(hex);
    },
    getFullAddress(address) {
      return getFullAddress(address);
    },
    displayDate(date) {
      return moment(date, "MM/DD/YYYY").format("dddd, MMMM DD, YYYY");
    },
    timeOffType(value) {
      return this.timeOffTypeList.find(r => r.value === value);
    }
  }
};
</script>

<style lang="scss" scoped>
.schedule-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px;
  gap: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 9px;
  color: var(--f7-color-text-neutral);

  .schedule-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
.info-popover {
  width: 95%;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-notes {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
}
</style>
