import { FirebaseActions } from "../../services/firebase.service";
import * as types from "./types";
import * as constant from "../../utility/const";
import { searchClient } from "../../services/search.service";
import algoliaService from "../../services/algolia.service";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/team_management`,
    "team"
  );

  const teamIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__asc`
  );

  return {
    bindTeam: ActionsTemplate.bindDocument,
    unbindTeam: ActionsTemplate.unbindDocument,

    addNewTeam: ActionsTemplate.createDocument,
    saveTeam: ActionsTemplate.updateDocument,

    getTeamListBys: ActionsTemplate.getDocumentBys,

    async getTeamList({ commit, dispatch }) {
      const teamList = await dispatch("getTeamListBys", [
        {
          prop: "isDeleted",
          val: false,
          op: "=="
        }
      ]);
      await commit(types.SET_TEAM_LIST, teamList);
      return teamList;
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchTeam(
      { state, commit, getters },
      { attributesToRetrieve, restrictSearchableAttributes }
    ) {
      const index = teamIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_TEAM_MANAGEMENT}`,
        attributesToRetrieve:
          constant.TEAM_MANAGEMENT_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.TEAM_MANAGEMENT_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    loadMoreTeam(
      { state, commit, getters },
      { attributesToRetrieve, restrictSearchableAttributes, page }
    ) {
      const index = teamIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_TEAM_MANAGEMENT}`,
        attributesToRetrieve:
          constant.TEAM_MANAGEMENT_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.TEAM_MANAGEMENT_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (restrictSearchableAttributes) {
        requestOptions.restrictSearchableAttributes = restrictSearchableAttributes;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error.message);
          return {};
        });
    },

    setTeamNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },
    setSearchText({ commit }, value) {
      commit(types.SET_SEARCH_TEXT, value);
    },
    setQueryFilters({ commit }, payload) {
      commit(types.SET_QUERY_FILTERS, payload);
    },
    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },

    async createTeam({ dispatch, commit }, team) {
      const manager = await dispatch(
        "common/user/getUserNotCommit",
        team.managerID,
        { root: true }
      );
      const id = await dispatch("addNewTeam", team);
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "team_management",
        id
      });
      commit(types.TEAM_LIST_ITEM_ADD, {
        ...team,
        id,
        managerName: manager.displayName || ""
      });

      return id;
    },

    async updateTeam({ dispatch, commit }, { id, doc }) {
      const manager = await dispatch(
        "common/user/getUserNotCommit",
        doc.managerID,
        { root: true }
      );
      await dispatch("saveTeam", { id, doc });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "team_management",
        id
      });
      commit(types.TEAM_LIST_ITEM_UPDATE, {
        id,
        doc: {
          ...doc,
          managerName: manager.displayName || ""
        }
      });

      return id;
    },

    async deleteTeam({ dispatch, commit }, id) {
      await dispatch("saveTeam", {
        id,
        doc: { [constant.PROP_IS_DELETED]: true }
      });
      await algoliaService.algoliaUpdateItem({
        tenantId: tenant,
        collection: "team_management",
        id
      });
      return commit(types.TEAM_LIST_ITEM_REMOVE, id);
    }
  };
};
