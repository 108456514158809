<template>
  <div
    class="page-header"
    :style="
      `background-color: ${data.color}; border-bottom: solid ${data.borderColor} 7px`
    "
  >
    <div class="logo" @click="clickChangeLogo">
      <img class="image" :src="data.logo || defautLogo" />
      <div class="middle">
        <div class="text">Click to change</div>
      </div>
    </div>
    <div class="info">
      <f7-text-editor
        mode="popover"
        style="height: 100%; margin: 0"
        :value="data.value"
        @texteditor:change="changeValue"
        :custom-buttons="customButtons"
        :buttons="buttons"
      ></f7-text-editor>
    </div>
    <f7-popover class="popover-menu">
      <f7-list>
        <f7-list-item
          link
          popover-close
          title="Change background"
          @click.native="clickChangeColor"
        >
          <f7-list-input
            style="width: 0px; 
          height: 0px; 
          visibility: hidden;
          position: absolute;
          "
            ref="changeColor"
            type="colorpicker"
            @change="changeColorValue"
            :value="{ hex: data.color }"
            :color-picker-params="{
              cssClass: 'remove-angle',
              openIn: 'auto',
              openInPhone: 'sheet',
              modules: ['palette'],
              palette: palette
            }"
          />
        </f7-list-item>
        <f7-list-item
          link="#"
          popover-close
          title="Change bottom line"
          @click.native="clickBorderColor"
        >
          <f7-list-input
            style="width: 0px; 
          height: 0px; 
          visibility: hidden;
          position: absolute;
          "
            ref="changeBorderColor"
            type="colorpicker"
            @change="changeBorderColor"
            :value="{ hex: data.borderColor }"
            :color-picker-params="{
              cssClass: 'remove-angle',
              openIn: 'auto',
              openInPhone: 'sheet',
              modules: ['palette'],
              palette: palette
            }"
          />
        </f7-list-item>
      </f7-list>
    </f7-popover>
    <f7-button class="change-color" popover-open=".popover-menu">
      <f7-icon f7="gear_alt_fill"></f7-icon>
    </f7-button>

    <!-- this is invisible element -->
    <image-uploader
      ref="imageInput"
      :preview="false"
      :quality="0.7"
      class="image-uploader"
      accept="image/*"
      doNotResize="gif"
      :autoRotate="false"
      outputFormat="verbose"
      @input="onUploadImage"
    ></image-uploader>
    <select-color-popup ref="selectColor" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import SelectColorPopup from "../popup/SelectColorPopup.vue";
import { HEADER_TYPE } from "../../utility/const";

const palette = [
  [
    "#FFFFFF",
    "#FFCDD2",
    "#EF9A9A",
    "#E57373",
    "#EF5350",
    "#F44336",
    "#E53935",
    "#D32F2F",
    "#C62828",
    "#B71C1C"
  ],
  [
    "#F3E5F5",
    "#E1BEE7",
    "#CE93D8",
    "#BA68C8",
    "#AB47BC",
    "#9C27B0",
    "#8E24AA",
    "#7B1FA2",
    "#6A1B9A",
    "#4A148C"
  ],
  [
    "#E8EAF6",
    "#C5CAE9",
    "#9FA8DA",
    "#7986CB",
    "#5C6BC0",
    "#3F51B5",
    "#3949AB",
    "#303F9F",
    "#283593",
    "#1A237E"
  ],
  [
    "#E1F5FE",
    "#B3E5FC",
    "#81D4FA",
    "#4FC3F7",
    "#29B6F6",
    "#03A9F4",
    "#039BE5",
    "#0288D1",
    "#0277BD",
    "#01579B"
  ],
  [
    "#E0F2F1",
    "#B2DFDB",
    "#80CBC4",
    "#4DB6AC",
    "#26A69A",
    "#009688",
    "#00897B",
    "#00796B",
    "#00695C",
    "#004D40"
  ],
  [
    "#F1F8E9",
    "#DCEDC8",
    "#C5E1A5",
    "#AED581",
    "#9CCC65",
    "#8BC34A",
    "#7CB342",
    "#689F38",
    "#558B2F",
    "#33691E"
  ],
  [
    "#FFFDE7",
    "#FFF9C4",
    "#FFF59D",
    "#FFF176",
    "#FFEE58",
    "#FFEB3B",
    "#FDD835",
    "#FBC02D",
    "#F9A825",
    "#F57F17"
  ],
  [
    "#FFF3E0",
    "#FFE0B2",
    "#FFCC80",
    "#FFB74D",
    "#FFA726",
    "#FF9800",
    "#FB8C00",
    "#F57C00",
    "#EF6C00",
    "#E65100"
  ],
  [
    "#edeff2",
    "#d2d2d4",
    "#b6b6b8",
    "#939396",
    "#78787a",
    "#575759",
    "#39393b",
    "#252526",
    "#141414",
    "#000000"
  ]
];

const buttons = [
  ["bold", "italic", "underline", "textColor"],
  ["orderedList", "unorderedList"],
  // ["link", "image"],
  ["h1", "h2", "h3"],
  ["alignLeft", "alignCenter", "alignRight", "alignJustify"],
  ["subscript", "superscript"],
  ["indent", "outdent"]
];

export default {
  components: { SelectColorPopup },
  props: {
    data: Object
  },
  data() {
    let app = this;
    return {
      palette,
      fullPath: "",
      defautLogo: "",
      customButtons: {
        textColor: {
          content: '<i class="f7-icons">pencil</i>',
          async onClick(editor) {
            const currentRange = editor.getSelectionRange();
            let color = await app.$refs.selectColor.openPopup();
            if (color && currentRange) {
              editor.setSelectionRange(currentRange);
              document.execCommand("foreColor", true, color);
            }
          }
        }
      },
      buttons
    };
  },
  computed: {
    ...mapGetters({
      proposalTemplate: "proposal/template/proposalTemplate",
      proposal: "proposal/proposal/proposal"
    })
  },
  methods: {
    ...mapActions({
      setProposalTemplateField: "proposal/template/setProposalTemplateField",
      setProposalField: "proposal/proposal/setProposalField",
      uploadPhoto: "proposal/template/uploadPhoto",
      removePhoto: "proposal/template/removePhoto"
    }),
    changeValue($event) {
      if (this.data.value === $event) return;
      this.updateHeaderForAllPages({ ...this.data, value: $event });
      this.$emit("change", { ...this.data, value: $event });
    },
    changeLogo($event) {
      this.updateHeaderForAllPages({ ...this.data, logo: $event });
      this.$emit("change", { ...this.data, logo: $event });
    },
    changeColorValue() {
      let value = this.$refs.changeColor.f7ColorPicker.getValue();
      value &&
        this.updateHeaderForAllPages({
          ...this.data,
          color: value.hex || "#ffffff"
        });
    },
    changeBorderColor() {
      let value = this.$refs.changeBorderColor.f7ColorPicker.getValue();
      value &&
        this.updateHeaderForAllPages({
          ...this.data,
          borderColor: value.hex || "#ffffff"
        });
    },
    updateHeaderForAllPages(data) {
      if (!_.isEmpty(this.proposalTemplate)) {
        this.updateTemplateHeaderForAllPages(data);
      }
      if (!_.isEmpty(this.proposal)) {
        this.updateProposalHeaderForAllPages(data);
      }
    },
    updateTemplateHeaderForAllPages(data) {
      let pages = _.cloneDeep((this.proposalTemplate || {}).pages);
      (pages || []).forEach(page => {
        let index = page.components.findIndex(e => e.type == HEADER_TYPE);
        if (index > -1) {
          page.components[index].data = data;
        }
      });
      this.setProposalTemplateField({ fieldName: "pages", value: pages });
    },
    updateProposalHeaderForAllPages(data) {
      let pages = _.cloneDeep((this.proposal || {}).pages);
      (pages || []).forEach(page => {
        let index = page.components.findIndex(e => e.type == HEADER_TYPE);
        if (index > -1) {
          page.components[index].data = data;
        }
      });
      this.setProposalField({ fieldName: "pages", value: pages });
    },
    onUploadImage(fileData) {
      const app = this;
      if (this.fullPath) {
        this.removePhoto(this.fullPath).then(() => {
          this.fullPath = "";
        });
      }
      this.$f7.preloader.show();
      this.uploadPhoto({ proposalId: this.proposal.id, fileData }).then(
        ({ fullPath, url }) => {
          this.fullPath = fullPath;
          this.changeLogo(url);
          app.$f7.preloader.hide();
        }
      );
    },
    clickChangeLogo() {
      this.$refs.imageInput.$el.lastChild.click();
    },
    clickChangeColor() {
      this.$refs.changeColor.f7ColorPicker.open();
    },
    clickBorderColor() {
      this.$refs.changeBorderColor.f7ColorPicker.open();
    }
  }
};
</script>

<style scoped>
.page-header {
  max-height: 150px;
  display: flex;
  padding: 15px;
  position: relative;
  margin-top: 35px;
}

.page-header .logo {
  width: 200px;
  cursor: pointer;
  position: relative;
}
.page-header .info {
  flex: 1;
}
.image-uploader {
  width: 0;
  height: 0;
  visibility: hidden;
}
.image {
  width: 100%;
  height: 100%;
}

.text {
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.logo .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.page-header .logo:hover .image {
  opacity: 0.3;
}

.page-header .logo:hover .middle {
  opacity: 1;
}

.page-header:hover .change-color {
  opacity: 1;
}

.change-color {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  top: -35px;
  right: 10px;
  background-color: rgb(219, 219, 219);
  justify-content: center;
  align-content: center;
  display: flex;
}
.text-editor {
  background: transparent;
  border: none;
}
</style>
<style>
.remove-angle .popover-angle {
  visibility: hidden;
}
</style>
