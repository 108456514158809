<template>
  <f7-popup :opened="popupOpened" @popup:closed="handlePopupClosed">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ title }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input :label="label" type="textarea" resizable clear-button :value="value"
          @input="$emit('input', $event.target.value.trim())" :error-message-force="validate"
          :error-message="validate ? errorMessage : ''" @blur="validate && v$.$touch()">
          <input-icon slot="media" icon="doc_text"></input-icon><required-asterisk v-show="validate"
            slot="label"></required-asterisk></f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import InputIcon from "../icon/InputIcon.vue";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  props: {
    value: { type: String, default: "" },
    title: { type: String, default: "Note" },
    label: { type: String, default: "Note" },
    validate: { type: Boolean, default: false }
  },

  components: {
    InputIcon
  },

  data: () => {
    return { popupOpened: false, emitDone: false };
  },

  computed: {
    errorMessage() {
      if (!this.v$.value.$error) return null;
      if (this.v$.value.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return null;
    }
  },

  methods: {
    open() {
      this.popupOpened = true;
      this.emitDone = false;
    },

    onDone() {
      if (this.validateNote()) {
        this.emitDone = true;
        this.popupOpened = false;
      }
    },

    validateNote() {
      this.v$.$touch();
      return !this.validate || !this.v$.$invalid;
    },

    handlePopupClosed() {
      this.popupOpened = false;
      if (this.emitDone) {
        this.$emit("done");
      }else {
        this.$emit("cancelPopup");
      }
      this.v$.$reset();
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      value: {
        required
      }
    };
  }
};
</script>

<style></style>
