import firebase from "firebase/app";
import "firebase/firestore";

let VuePlugin = {};

VuePlugin.install = function(Vue) {
  Vue.prototype.$google = Vue.prototype.$google || {};
  Vue.prototype.$google.firebase = firebase;
};

export default VuePlugin;
