<template>
  <div :class="typeSwitchScreen !== 'step' && 'container-info'" v-if="
    checkShowContentScreenSingleInEstimate(
      showContentType,
      CONTENT_TYPE_EST.INFO
    )
  ">
    <f7-list>
      <div :class="typeSwitchScreen === 'step' && $device.desktop
        ? 'container-info-step-row'
        : 'container-info-step-column'
        ">
        <div style="flex: 1;" :class="typeSwitchScreen !== 'step' && 'container-info-content'">
          <f7-list media-list :inset="$device.desktop">
            <f7-list-input label="Specification #" placeholder="Enter Specification #" type="text" :value="buildingName"
              @input="
                buildingName = $event.target.value.trim();
              v$.buildingName.$touch();
              if (v$.buildingName.$invalid) return;
              saveBuildingValue('buildingName', buildingName);
              updateEstimateBuildingName({
                buildingId: currentBuilding.id,
                newBuildingName: buildingName
              });
              " error-message-force validate validate-on-blur :error-message="buildingNameErrorMessage">
              <required-asterisk slot="label"></required-asterisk>
              <input-icon slot="media" icon="building_2"></input-icon>
            </f7-list-input>

            <f7-list-input label="Number of Building/Sections" :value="numberOfCopy" class="number-building-input"
              placeholder="Enter number of buidings/sections" type="number"
              @input="onChangeNumberOfCopy($event.target.value.trim())" error-message-force validate validate-on-blur
              :error-message="numberOfBuildingsErrorMessage">
              <input-icon slot="media" icon="building_2"></input-icon>
            </f7-list-input>
          </f7-list>

          <!-- Description -->
          <f7-list :inset="$device.desktop">
            <f7-list media-list>
              <f7-list-input label="Description" type="textarea" placeholder="Enter description..."
                :value="currentBuilding.description" @change="
                  saveBuildingValue('description', $event.target.value.trim())
                  "><input-icon class="desc-icon" slot="media" icon="doc"></input-icon></f7-list-input>
            </f7-list>
          </f7-list>
        </div>

        <div :style="typeSwitchScreen === 'step' && $device.desktop
          ? 'flex: 1; max-width: 50%;'
          : 'flex: 1;'
          ">
          <roof-info-form ref="roofInfoForm" :slopeTypeList="slopeTypeListAll" :roofTypeList="roofTypeListAll"
            @onSelectedPriceList="onSelectedPriceList" @onSelectedVendor="onSelectedVendor"></roof-info-form>
        </div>
      </div>
    </f7-list>

    <!-- popups -->
    <div>
      <dynamic-muti-select ref="selectPages" search-placeholder="Search in drawings"
        :title="`Select Drawings of ${currentBuilding.buildingName}`" :value="currentBuilding.stackPageIds || []"
        :options="pageListOptions" @changed="changePages">
      </dynamic-muti-select>
    </div>
  </div>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";
import DynamicMutiSelect from "../inputs/DynamicMultiSelect.vue";

import RoofInfoForm from "../forms/RoofInfoForm.vue";

import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";
import stackctServices from "@/services/stackct.service";
import {
  CONTENT_TYPE_EST,
  SLOPE_TYPE_LOW,
  TAX_APPLY_TYPE,
  VALIDATION_MESSAGE
} from "@/utility/const";
import { checkShowContentScreenSingleInEstimate } from "@/utility/common";

export default {
  components: {
    InputIcon,
    DynamicMutiSelect,

    RoofInfoForm
  },

  mixins: [mixins, buildingMixins],

  props: {
    showContentType: {
      type: String,
      default: "all"
    }
  },

  data() {
    return {
      buildingName: "",
      numberOfCopy: 0,
      isGoingToDetails: false,
      // estimateNumber: "",
      selectPriceListMessage: "",
      selectRoofTypeMessage: "",
      isLoadStackctProject: null,
      isEndDate: false,
      SLOPE_TYPE_LOW,
      TAX_APPLY_TYPE,
      CONTENT_TYPE_EST,

      slopeTypeAll: {
        id: "all-slope-type",
        code: "slope-type",
        value: "all-slope-type",
        displayName: "All"
      },

      roofTypeAll: {
        id: "all-roof-type",
        code: "roof-type",
        value: "all-roof-type",
        displayName: "All",
        slopeType: "all-slope-type",
        estimateTemplate: [],
        workSteps: {
          service: [
            { stepId: "service-information", index: 0 },
            { stepId: "service-product", index: 1 },
            { stepId: "service-review", index: 2 }
          ]
        }
      }
    };
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    buildingName: { required },
    numberOfCopy: { required, minValue: minValue(1), maxValue: maxValue(200) }
  },

  async created() {
    if (
      _.isEmpty(this.priceListDefault) &&
      checkShowContentScreenSingleInEstimate(
        this.showContentType,
        CONTENT_TYPE_EST.INFO
      )
    ) {
      await this.getPriceListDefault();
    }
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "typeSwitchScreen"
    ]),
    ...mapGetters("common/app-constant", [
      "roofTypeList",
      "slopeType",
      "slopeTypeList",
      "workType",
      "tpoWarrantyOptionList"
    ]),
    ...mapGetters("estimate/estimate-page/project", ["project"]),

    ...mapGetters("estimate/estimate-page/price-list", [
      "priceList",
      "priceListDefault",
      "currentPriceList"
    ]),

    ...mapGetters("estimate/estimate-page/estimate", ["estimate"]),

    ...mapGetters({
      categories: "estimate/estimate-page/estimate/category/objectList",
      subCategories: "estimate/estimate-page/estimate/sub-category/objectList",
      currentBuilding: "estimate/estimate-page/estimate/currentBuilding"
    }),

    ...mapGetters("estimate/estimate-page/stackct", [
      "stackctProjects",
      "stackctTakeoffs",
      "stackctPages"
    ]),
    pageListOptions() {
      return (this.stackctPages || []).map(r => ({
        id: r.PageId,
        value: r.PageName
      }));
    },

    roofTypeListAll() {
      const listRoof = this.roofTypeList
        .filter(type => {
          return (
            type.workSteps &&
            type.workSteps.service &&
            (this.currentBuilding.slopeType === "all-slope-type" ||
              type.slopeType === this.currentBuilding.slopeType)
          );
        })
        .sort((a, b) => {
          return a.displayName < b.displayName ? -1 : 0;
        });

      if (this.currentBuilding.slopeType === "all-slope-type") {
        listRoof.unshift(this.roofTypeAll);
      }

      return listRoof;
    },

    slopeTypeListAll() {
      if (!this.slopeTypeList) {
        return [];
      }
      const list = _.cloneDeep(this.slopeTypeList);
      list.unshift(this.slopeTypeAll);
      return list;
    },

    taxApplyTypeBy() {
      return type => {
        return this.TAX_APPLY_TYPE.find(item => item.value === type) || {};
      };
    },

    buildingNameErrorMessage() {
      if (!this.v$.buildingName.$error) return "";
      if (this.v$.buildingName.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return "";
    },

    numberOfBuildingsErrorMessage() {
      if (!this.v$.numberOfCopy.$error) return "";
      if (this.v$.numberOfCopy.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfCopy.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      if (this.v$.numberOfCopy.maxValue.$invalid) {
        return VALIDATION_MESSAGE.LESS_THAN_OR_EQUAL_TO_200;
      }
      return "";
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "updateEstimate",
      "updateNumberOfCopyEstimateManually",
      "getProductDataTemplate"
    ]),

    ...mapActions("estimate/estimate-page/product-item", [
      "getActiveProductItemsByPriceListId"
    ]),

    ...mapActions("estimate/estimate-page/price-list", [
      "bindPriceListItem",
      "unbindPriceListItem",
      "getPriceListDefault"
    ]),
    ...mapActions("estimate/estimate-page/stackct", [
      "setStackctProjects",
      "setStackctTakeoffs",
      "setStackctPages"
    ]),
    ...mapActions("estimate/estimate-page/product-default", [
      "getAllDefaultProductIds"
    ]),

    checkShowContentScreenSingleInEstimate,

    updateEstimateBuildingCopy({ buildingId, newNumberOfCopy }) {
      const buildings = _.cloneDeep(this.estimate.buildings);
      if (_.isEmpty(buildings)) {
        return;
      }
      const building = buildings.find(item => item.buildingId === buildingId);
      if (_.isEmpty(building)) {
        return;
      }

      building.numberOfCopy = newNumberOfCopy;
      this.updateEstimate({
        id: this.estimate.id,
        doc: {
          buildings: buildings
        }
      });
    },

    async onChangeNumberOfCopy(value) {
      this.numberOfCopy = parseInt(value);
      this.v$.numberOfCopy.$touch();
      if (this.v$.numberOfCopy.$invalid) return;
      this.updateNumberOfCopyEstimateManually({
        estId: this.estimate.id,
        buildingId: this.currentBuilding.id,
        value
      });
      await this.saveBuildingValue("numberOfCopy", this.numberOfCopy);
      await this.updateEstimateBuildingCopy({
        buildingId: this.currentBuilding.id,
        newNumberOfCopy: this.numberOfCopy
      });
    },

    onSelectedVendor() {
      this.saveDefaultProducts();
    },

    onSelectedPriceList(priceListId) {
      if (_.isEmpty(priceListId)) {
        return;
      }
      const self = this;
      // bind product by price list id
      this.$f7.preloader.show();
      this.saveBuildingValue("productData", []);
      this.bindPriceListItem(priceListId)
        .then(() => {
          return self.getActiveProductItemsByPriceListId(priceListId);
        })
        .then(() => {
          if (_.isEmpty(this.currentBuilding.productData)) {
            return this.saveDefaultProducts();
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    validate() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    init() { },
    initStackData() {
      this.$f7.preloader.show();
      this.stackCTCheckAuthMiddleware(() => {
        this.isLoadStackctProject = true;
        this.setStackctProjects().finally(() => {
          this.isLoadStackctProject = false;
          this.$f7.preloader.hide();
        });
      });
    },
    stackCTCheckAuthMiddleware(callback, openLogin = 1) {
      return stackctServices.checkAuth().then(data => {
        if (data.isAuthorized) {
          return callback();
        } else {
          this.$f7.preloader.hide();
          openLogin && this.openLoginPopup();
        }
      });
    },
    openLoginPopup() {
      let app = this;
      this.$ri.dialog.openInfoDialog({
        title: "Connect to StackCT.",
        content:
          "You need to set up a connection between RooferIntel and StackCT. It will take about 5 minutes. Do you want to proceed now?",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            stackctServices.getAuthUri(app.$f7router.url).then(uri => {
              window.location.href = uri;
            });
          }
        }
      });
    },
    onChangeStackCTProject($event) {
      this.saveBuildingValue("stackTakeOffId", $event.target.value);
      this.saveBuildingValue("stackPageIds", []);
    },
    onChangeStackIntergration(value) {
      if (!value) {
        // reset old value
        this.saveBuildingValue("stackTakeOffId", "");
        this.saveBuildingValue("stackPageIds", []);
        this.isLoadStackctProject = null;
      } else {
        this.initStackData();
      }
      this.saveBuildingValue("stackctIntegration", value);
    },
    pageByIds(ids) {
      if (!ids) return "";
      return (
        this.pageListOptions.filter(r => (ids || []).includes(r.id)) || []
      )
        .map(r => r.value)
        .join(", ");
    },
    openSelectPages() {
      if (this.currentBuilding.stackTakeOffId) {
        this.$refs.selectPages.openSelectPopup();
      }
    },
    changePages(values) {
      this.saveBuildingValue("stackPageIds", values);
    },
    toDetails(estimateNumber) {
      this.isGoingToDetails = true;
      this.estimateNumber = estimateNumber;
    },

    updateEstimateBuildingName({ buildingId, newBuildingName }) {
      const buildings = _.cloneDeep(this.estimate.buildings);
      if (_.isEmpty(buildings)) {
        return;
      }
      const building = buildings.find(item => item.buildingId === buildingId);
      if (_.isEmpty(building)) {
        return;
      }

      building.buildingName = newBuildingName;
      this.updateEstimate({
        id: this.estimate.id,
        doc: {
          buildings: buildings
        }
      });
    }
  },
  watch: {
    currentBuilding: {
      handler(val) {
        if (!_.isEmpty(val)) {
          if (val.slopeType) {
            this.selectRoofTypeMessage = "";
          }

          if (val.roofType) {
            this.selectPriceListMessage = "";
          }

          if (val.buildingName) {
            this.buildingName = val.buildingName;
          }

          if (val.numberOfCopy) {
            this.numberOfCopy = val.numberOfCopy;
          }
        }
      },
      deep: true,
      immediate: true
    },
    "currentBuilding.stackTakeOffId": {
      async handler(value) {
        if (value) {
          await this.setStackctPages(value);
          await this.setStackctTakeoffs(value);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  margin-top: 5px;
  gap: 10px;
}

.md .status-icon {
  margin-bottom: 16px;
}

.md .desc-icon {
  margin-bottom: 80px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}

.container-info {
  background-color: var(--f7-color-bg-dark);
  padding: 8px 0 16px 0;
}

.container-info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
