<template>
  <f7-popup :opened="isShow" @popup:closed="$emit('close')">
    <f7-page>
      <f7-navbar title="Time log audit details">
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <p v-if="isSameLocation">
          <strong>{{ username }}</strong> started and eneded the job close to
          the location:
          <strong>{{ (startedLocation || "").formattedAddress }}</strong>
          <br />
          that is <strong>{{ distanceInMile | number }}</strong> mile from
          <strong>the job location</strong>
        </p>
        <p v-else>
          <strong>{{ username }}</strong> started close to the location:
          <strong>{{ (startedLocation || "").formattedAddress }}</strong>
          <br />
          and ended the job close to the location:
          <strong>{{ (endedLocation || "").formattedAddress }}</strong>
          <br />
          that is <strong>{{ distanceInMile | number }}</strong> mile from
          <strong>the job location</strong>
        </p>
        <show-audit-on-map
          :propertyAddress="propertyAddress"
          :startedLocation="startedLocation"
          :endedLocation="endedLocation"
        ></show-audit-on-map>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { meterToMile } from "@/utility/distance-tool";
import ShowAuditOnMap from "./ShowAuditOnMap.vue";
export default {
  props: {
    username: { type: String, default: () => "" },
    propertyAddress: { type: String, default: () => "" },
    startedLocation: { type: Object, default: () => null, required: true },
    endedLocation: { type: Object, default: () => null, required: true },
    distance: { type: Number, default: () => 0 },
    isShow: { type: Boolean, default: false }
  },
  components: {
    ShowAuditOnMap
  },
  computed: {
    isSameLocation() {
      if (!this.startedLocation || !this.endedLocation) return false;
      return this.startedLocation.placeId === this.endedLocation.placeId;
    },
    distanceInMile() {
      return meterToMile(this.distance);
    }
  }
};
</script>
