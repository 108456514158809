<template>
  <div>
    <f7-block-title v-show="$device.desktop"
      >Downspout Transition</f7-block-title
    >
    <f7-card :class="{ 'margin-top-half': !$device.desktop }">
      <!-- <f7-card-header>Drawing board</f7-card-header> -->
      <f7-card-content>
        <div class="canvas-container">
          <img
            class="display-none"
            id="downspout-transition"
            :src="
              `${
                dark
                  ? downspoutTransitionDark
                  : downspoutTransition
              }`
            "
          />
          <canvas
            v-if="$device.desktop"
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @mousedown="handleMouseDown"
            @mousewheel="handleMouseWheel"
          />
          <canvas
            v-else
            :ref="assemblyId"
            :id="assemblyId"
            class="canvas-style"
            :width="$device.desktop ? width : widthMobile"
            :height="$device.desktop ? height : heightMobile"
            resize
            @click="handleMouseDown"
            @pointerdown="handlePointerDown"
            @pointermove="handlePointerMove"
            @pointerup="handlePointerUp"
            @pointercancel="handlePointerUp"
            @pointerleave="handlePointerUp"
            @pointerout="handlePointerUp"
          />
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import paper from "paper";
import { evaluate } from "mathjs";
import canvasMixin from "../../utility/canvas-mixin";
import _ from "lodash";
import downspoutTransitionDark from '@/assets/img/metal-diagrams/downspout-transition-dark.jpg';
import downspoutTransition from '@/assets/img/metal-diagrams/downspout-transition.png';
const inchToPx = 25;

export default {
  mixins: [canvasMixin],

  props: {
    data: Object,
    assemblyId: String
  },

  data: () => ({
    downspoutTransitionDark,
    downspoutTransition,
    scope: null,
    dataToPx: {},
    yBase: 450,
    xBase: 400,
    width: 800,
    height: 800,
    dark: localStorage.getItem("themeDark") === "true"
  }),

  mounted() {
    this.scope = new paper.PaperScope();
    this.scope.setup(this.assemblyId);
    this.drawAll();
  },

  methods: {
    initCanvas() {
      this.removeProject(this.scope);
      this.scope = new paper.PaperScope();
      this.scope.setup(this.assemblyId);
      if (!_.isEmpty(this.scope.view)) {
        this.scope.view.viewSize = new paper.Size(
          this.$device.desktop ? this.width : this.widthMobile,
          this.$device.desktop ? this.height : this.heightMobile
        );
      }
    },

    reset() {
      this.scope && this.scope.project.activeLayer.removeChildren();
    },

    handleDraw() {
      let img = new paper.Raster("downspout-transition");
      img.size = new paper.Size(582, 687);
      img.position = new paper.Point(this.xBase, this.yBase);

      // draw value
      let showValue = new paper.PointText(200, 50);
      showValue.content = `A = ${this.data.A}; B = ${this.data.B}; C = ${this.data.C}, D = ${this.data.D}; E = ${this.data.E}`;
      showValue.fillColor = this.dark ? "white" : "black";
      showValue.fontSize = 18;
    },

    drawAll() {
      this.handleDraw();
      this.resizableLayer(this.xBase / 2, this.yBase / 3);
    }
  },

  watch: {
    data: {
      handler(val) {
        const keys = ["A", "B", "C", "D", "E"];
        for (let i of keys) {
          let parseValue = this.convertStringToExpression(val[i]);
          this.dataToPx[i] = evaluate(parseValue) * inchToPx;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.canvas-container {
  position: relative;
}
canvas[resize] {
  width: 100%;
  height: 100%;
}
</style>
