<template>
  <div>
    <f7-block>
      <filter-by-date
        @searchByDate="searchByDate"
        @exportToQuickbook="exportToQuickbook"
        @exportToExcel="exportToExcel"
        :isDisableExportButton="selectedItems.length === 0"
      ></filter-by-date>
    </f7-block>

    <working-hours-data-table
      :workingHoursList="timeTrackingList"
      :isActiveTab="true"
      @checkBoxChange="checkBoxChange"
      @selectAllItem="selectAllItem"
      @dataItemChange="dataItemChange"
    ></working-hours-data-table>

    <LinkEmployeeAndProjectToQBPopup
      ref="linkEmployeeProjectToQB"
      :employeeToLinkList="employeeToLinkList"
      :projectToLinkList="projectToLinkList"
      @dataItemChange="dataItemChange"
      @handleLinkDataQB="handleLinkDataQB"
    ></LinkEmployeeAndProjectToQBPopup>
  </div>
</template>
<script>
import Vue from "vue";
import FilterByDate from "../filter/FilterByDate.vue";
import WorkingHoursDataTable from "../datatable/WorkingHoursDataTable.vue";
import LinkEmployeeAndProjectToQBPopup from "../popups/LinkEmployeeAndProjectToQBPopup.vue";

import { mapActions, mapGetters } from "vuex";
import { toDateCalendar, toDateFirebase } from "../../../../utility/datetime";
import { roundNumber } from "@/utility/number-tool";
import {
  getTomorrow,
  startDateOf90DaysAgo,
  lastWeekMonday,
  lastWeekSunday
} from "../../../../utility/date-time-tool";
import { STATUS_TIME_TRACKING_APPROVED } from "../../../../utility/const";

import quickbooksService from "../../../../services/quickbooks.service";
import _ from "lodash";

export default {
  components: {
    FilterByDate,
    WorkingHoursDataTable,
    LinkEmployeeAndProjectToQBPopup
  },

  data: () => {
    return {
      selectedItems: [],
      employeeIdsToLink: [],
      projectIdsToLink: []
    };
  },

  mounted() {
    const fromDate = this.datesToFilterQB?.fromDate ||  [lastWeekMonday()];
    const toDate = this.datesToFilterQB?.toDate || [lastWeekSunday()];
    
    this.searchByDate({ fromDate, toDate });
  },

  computed: {
    ...mapGetters("quickbooks/time-tracking", [
      "timeTrackingList",
      "timeTrackingById",
      "datesToFilterQB"
    ]),
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters("quickbooks/user", ["userList", "userById"]),
    ...mapGetters("quickbooks/common", ["qbEmployeeList", "qbEmployeeById"]),

    tenantId() {
      return this.user.tenantId;
    },

    employeeToLinkList() {
      return (
        this.employeeIdsToLink.map(r =>
          this.timeTrackingList.find(i => i.user_id === r)
        ) || []
      );
    },

    projectToLinkList() {      
      return (
        this.projectIdsToLink.map(
          r => this.timeTrackingList.find(i => i.id === r) // Map by time tracking id, because time tracking with type Others has no projectId
        ) || []
      );
    }

    // mappedTimeTrackingList() {
    //   return this.timeTrackingList.map(timeItem => {
    //     if (!timeItem.qbTimeActivityId) {
    //       const user = this.userList.find(
    //         item => item.uid === timeItem.user_id
    //       );
    //       if (user && user.customClaims) {
    //         const qbUser = user.customClaims.qbUser || {};
    //         const qbEmployee = this.qbEmployeeList.find(
    //           item => item.Id === qbUser.Id
    //         );

    //         if (qbEmployee) {
    //           timeItem.qbEmployeeRef = {
    //             name: qbEmployee.DisplayName,
    //             value: qbEmployee.Id
    //           };
    //         }
    //       }
    //     }
    //     return { ...timeItem };
    //   });
    // }
  },

  methods: {
    ...mapActions("quickbooks/time-tracking", [
      "getTimeTrackingList",
      "updateTimeTracking",
      "changeTimeTrackingItem"
    ]),

    ...mapActions("quickbooks/activity", ["createActivity"]),
    ...mapActions("quickbooks/project", ["updateProject"]),
    ...mapActions("quickbooks/user", ["getUserList", "updateUser"]),

    openExportSuccessOrFailPopup(title, content) {
      const self = this;
      this.$ri.dialog.openWarningDialog({
        title: `${title}`,
        content: content,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            if (title === "Export Complete") {
              self.$emit("onDone");
            }
          }
        }
      });
    },

    exportToQuickbook() {
      const self = this;      
      if (this.validate()) {
        this.$f7.preloader.show();
        quickbooksService
          .checkAuth()
          .then(() => {
            const timeActivities = [];
            for (const timeTracking of self.selectedItems) {              
              const totalHour =
                timeTracking.approvedHour +
                (timeTracking.hasLunchBreak
                  ? timeTracking.lunchTimeInfo.hour
                  : 0);

              const hour = roundNumber(
                totalHour -
                  (timeTracking.hasOvertime ? timeTracking.overtime || 0 : 0),
                3
              );

              if (hour > 0) {
                timeActivities.push({
                  id: timeTracking.id,
                  TimeActivity: {
                    BillableStatus: timeTracking.payRate
                      ? "Billable"
                      : "NotBillable",

                    TxnDate: self.formatDate(
                      toDateCalendar(timeTracking.date)[0]
                    ),
                    Hours: self.getHours(hour),
                    Minutes: self.getMinutes(hour),
                    HourlyRate: parseFloat(timeTracking.payRate || 0),
                    CustomerRef: {
                      name: timeTracking.customerRef.name,
                      value: timeTracking.customerRef.id
                    },
                    EmployeeRef: {
                      name: timeTracking.employeeRef.name,
                      value: timeTracking.employeeRef.id
                    },
                    ClassRef: timeTracking.qbClassRef && {
                      name: timeTracking.qbClassRef.name,
                      value: timeTracking.qbClassRef.value
                    },
                    ItemRef: timeTracking.qbServiceItemRef && {
                      name: timeTracking.qbServiceItemRef.name,
                      value: timeTracking.qbServiceItemRef.value
                    },
                    NameOf: "Employee"
                  }
                });
              }

              if (timeTracking.hasOvertime) {
                timeActivities.push({
                  id: timeTracking.id,
                  TimeActivity: {
                    BillableStatus: timeTracking.payRateOvertime
                      ? "Billable"
                      : "NotBillable",

                    TxnDate: self.formatDate(
                      toDateCalendar(timeTracking.date)[0]
                    ),
                    Hours: self.getHours(timeTracking.overtime),
                    Minutes: self.getMinutes(timeTracking.overtime),
                    HourlyRate: parseFloat(timeTracking.payRateOvertime || 0),
                    CustomerRef: {
                      name: timeTracking.customerRef.name,
                      value: timeTracking.customerRef.id
                    },
                    EmployeeRef: {
                      name: timeTracking.employeeRef.name,
                      value: timeTracking.employeeRef.id
                    },
                    ClassRef: timeTracking.qbClassRef && {
                      name: timeTracking.qbClassRef.name,
                      value: timeTracking.qbClassRef.value
                    },
                    ItemRef: timeTracking.qbServiceItemRef && {
                      name: timeTracking.qbServiceItemRef.name,
                      value: timeTracking.qbServiceItemRef.value
                    },
                    NameOf: "Employee"
                  }
                });
              }
            }

            return quickbooksService
              .createTimeActivity({
                timeActivities
              })
              .then(data => {
                const result = [];
                for (const timeActivity of data) {
                  result.push({
                    id: timeActivity.id,
                    qbTimeActivityId: timeActivity.data.TimeActivity.Id,
                    qbCustomerRef: timeActivity.data.TimeActivity.CustomerRef,
                    qbEmployeeRef: timeActivity.data.TimeActivity.EmployeeRef
                  });
                }
                return result;
              });
          })
          .then(data => {
            return self.updateTimeActivityForTimeTracking(data);
          })
          .then(data => {
            // update history
            return self.updateHistory(data);
          })
          .then(() => {
            // show complete message here
            self.openExportSuccessOrFailPopup(
              "Export Complete",
              "Export time tracking to QuickBooks successfully!"
            );
          })
          .catch(error => {
            if (error.message) {
              const errorObject = JSON.parse(error.message);
              
              if (errorObject.Fault && errorObject.Fault.Error && errorObject.Fault.Error instanceof Array) {
                const errorDetail = errorObject.Fault.Error[0];
               
                self.openExportSuccessOrFailPopup(errorDetail.Message, `${errorDetail.Detail}. Property: ${errorDetail.element}. Code: ${errorDetail.code}`);
              } else {
                self.openExportSuccessOrFailPopup("Export Error", error.message);
              }
            } else {
              // show error message here
              self.openExportSuccessOrFailPopup("Export Error");
            }
          })
          .finally(() => {
            self.$f7.preloader.hide();
          });
      } else {
        let employeeIds = this.selectedItems
          .filter(r => !r.employeeRef)
          .map(r => r.user_id);
        let projectIds = this.selectedItems
          .filter(r => !r.customerRef)
          .map(r => r.id); // Map by time tracking id, because time tracking with type Others has no projectId          
        employeeIds = [...new Set(employeeIds)];
        projectIds = [...new Set(projectIds)];
        this.employeeIdsToLink = employeeIds;
        this.projectIdsToLink = projectIds;
        this.$refs.linkEmployeeProjectToQB.open();
      }
    },

    updateHistory(data) {
      const historyData = [];
      for (const item of data) {
        const timeActivity = this.timeTrackingById(item.id);
        historyData.push({
          ...item,
          ...timeActivity
        });
      }

      this.createActivity({
        activityType: "export-qb-working-hours",
        data: historyData,
        doneBy: this.user.uid,
        message: "Exported working hours to Quickbooks"
      });
    },

    updateTimeActivityForTimeTracking(data) {
      const refs = [];
      for (const item of data) {
        if (item.id && item.qbTimeActivityId) {
          refs.push(
            this.updateTimeTracking({
              id: item.id,
              doc: {
                qbTimeActivityId: item.qbTimeActivityId,
                qbCustomerRef: item.qbCustomerRef,
                qbEmployeeRef: item.qbEmployeeRef
              }
            }).then(() => {
              return item;
            })
          );
        }
      }

      return Promise.all(refs);
    },

    validate() {
      for (const item of this.selectedItems) {        
        if (_.isEmpty(item.employeeRef) || _.isEmpty(item.customerRef)) {
          return false;
        }
      }
      return true;
    },

    handleLinkDataQB() {
      if (!this.validate()) {
        this.openExportSuccessOrFailPopup(
          "Export Error",
          "Please link all data before exporting!"
        );
        return;
      } else {
        this.exportToQuickbook();
        this.$refs.linkEmployeeProjectToQB.closePopup();
      }
    },

    exportToExcel() {
      quickbooksService
        .queryEntity("select * from TimeActivity where TxnDate >= '2020-12-01'")
        .then(result => {
          // eslint-disable-next-line no-console
          console.log(result);
        });
    },

    searchByDate(data) {
      this.$f7.preloader.show();
      this.getTimeTrackingList([
        {
          prop: "status",
          val: STATUS_TIME_TRACKING_APPROVED,
          op: "=="
        },
        {
          prop: "date",
          val: toDateFirebase([data.fromDate]),
          op: ">="
        },
        {
          prop: "date",
          val: toDateFirebase([getTomorrow(data.toDate)]),
          op: "<"
        }
      ]).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    selectAllItem(checked, items) {
      this.timeTrackingList.forEach(r => {
        if (items.some(i => i.id === r.id)) {
          if (!r.qbTimeActivityId) {
            Vue.set(r, "checked", checked);
            this.changeTimeTrackingItem({ id: r.id, item: r });
          }
        }
      });
      this.selectedItems = this.timeTrackingList.filter(
        item => item.checked === true
      );
    },

    checkBoxChange(checked, id) {
      let item = this.timeTrackingList.find(item => item.id === id);
      Vue.set(item, "checked", checked);
      this.changeTimeTrackingItem({ id, item });
      this.selectedItems = this.timeTrackingList.filter(
        item => item.checked === true
      );
    },

    async dataItemChange(prop, value, item) {
      this.timeTrackingList.forEach(r => {
        if (
          prop === "employeeRef" &&
          r.user_id === item.user_id &&
          _.isEmpty(r.qbTimeActivityId)
        ) {          
          Vue.set(r, prop, value);
          this.changeTimeTrackingItem({ id: r.id, item: r });
        }
        if (
          prop === "customerRef" &&
         ((r.projectId === null && r.title === item.title) ||
          (r.projectId !== null && r.projectId === item.projectId)) &&
          _.isEmpty(r.qbTimeActivityId)
        ) {
          Vue.set(r, prop, value);
          this.changeTimeTrackingItem({ id: r.id, item: r });
        }
      });

      if (prop === "employeeRef") {
        // update qbUser
        const user = this.userById(item.user_id);
        await this.updateUser({
          uid: item.user_id,
          user: {
            customClaims: {
              ...user.customClaims,
              qbUser: this.qbEmployeeById(value.id)
            }
          }
        });
        await this.getUserList();
      } else if (prop === "customerRef") {
        // update qbCustomerRef
        if (!_.isEmpty(item.projectId)) {
          const qbCustomerRef = _.isEmpty(item.qbCustomerRef) ? value : {};
          await this.updateProject({
            id: item.projectId,
            doc: { qbCustomerRef }
          });
        }
      }

      this.selectedItems = this.timeTrackingList.filter(
        item => item.checked === true
      );
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    getHours(value) {
      return parseInt(value || 0);
    },

    getMinutes(value) {
      const floatValue = parseFloat(value || 0);
      return roundNumber((floatValue % 1) * 60, 0);
    }
  },
  watch: {
    selectedItems(newVal) {
      this.$emit("selectedItems", newVal);
    }
  }
};
</script>
