import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "object"
  );

  return {
    // Product Actions
    bind: ({ dispatch }) => {
      dispatch("bindBys", [
        {
          prop: "companyTypes",
          op: "array-contains",
          val: "vendor"
        }
      ]);
    },
    bindBys: companyActions.bindCollectionBys,
    unbind: companyActions.unbindCollection,
    create: companyActions.createDocument,
    update: companyActions.updateDocument,
    getBys: companyActions.getDocumentBys,

    async getVendors({ commit, dispatch }) {
      const list = await dispatch("getBys");
      commit(types.SET_VENDOR_LIST, list);
    }
  };
};
