import * as types from "./types";

export default {
  [types.SET_CHAIN](state, data) {
    state.chain = data;
  },
  [types.RESET_CHAIN](state) {
    state.chain = {};
  }
};
