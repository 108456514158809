<template>
  <div class="container-view-table">
    <div class="box-button">
      <div style="display: flex; gap:10px">
        <f7-button
          :style="
            checkTypeContent('review') &&
              'background-color: var(--f7-color-bg-dark)'
          "
          :large="$device.desktop"
          tonal
          :fill="checkTypeContent('table')"
          @click="changeTypeContent('table')"
          >Product Table</f7-button
        >
        <f7-button
          :style="
            checkTypeContent('table') &&
              'background-color: var(--f7-color-bg-dark)'
          "
          :large="$device.desktop"
          tonal
          :fill="checkTypeContent('review')"
          @click="changeTypeContent('review')"
          >Review</f7-button
        >
      </div>
      <div>
        <div
          class="box-next-spec"
          @click="onNextSpecification"
          v-if="isShowButtonNextSpecification"
        >
          <p style="font-weight: 700;">
            {{ $device.desktop ? "Next Specification" : "Next Spec" }}
          </p>
        </div>

        <div
          v-show="!isShowButtonNextSpecification && $device.desktop"
          class="box-btn-right"
        >
          <f7-button outline @click="gotoSummary">Summary</f7-button>
          <f7-button
            fill
            @click="activeEstimate"
            v-show="estimate.status != STATUS_ESTIMATE_ACTIVE"
            >Audit Estimate</f7-button
          >
          <new-proposal-button :isMarginTop="false"></new-proposal-button>
        </div>

        <div v-show="!isShowButtonNextSpecification && !$device.desktop">
          <f7-link popover-open=".box-button-on-mobile">
            <f7-icon f7="bars" color="primary"></f7-icon>
          </f7-link>

          <f7-popover
            style="width:150px; "
            class="box-button-on-mobile"
            vertical-position="bottom"
            ref="popoverButtonEstimate"
          >
            <div
              style="display: flex; flex-direction: column; gap:10px; align-items: center; padding:10px; "
            >
              <f7-button style="width:100%; " outline @click="gotoSummary"
                >Summary</f7-button
              >
              <f7-button
                outline
                style="width:100%; "
                v-show="estimate.status != STATUS_ESTIMATE_ACTIVE"
                @click="activeEstimate"
                >Audit Estimate</f7-button
              >
              <new-proposal-button
                :isMarginTop="false"
                @callBack="closePopover"
              ></new-proposal-button>
            </div>
          </f7-popover>
        </div>
      </div>
    </div>

    <div
      v-show="workSteps.length === 1"
      class="container-view-table-content-no-data"
      :style="isThemDart"
    >
      <p>No data!</p>
    </div>

    <div class="box-product">
      <div
        v-show="workSteps.length != 1"
        class="container-view-table-content"
        v-for="(step, index) in stepList"
        :key="index"
        :style="isThemDart"
      >
        <template>
          <component
          :ref="stepComponent(step.stepId)"
          :is="stepComponent(step.stepId)"
          :key="index"
          :roofType="currentBuilding.roofType || ''"
          :showContentType="CONTENT_TYPE_EST.TABLE"
          />
        </template>
      </div>
    </div>

    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  STEP_INFORMATION,
  STEP_SERVICE_INFORMATION,
  CONTENT_TYPE_EST,
  STATUS_ESTIMATE_ACTIVE
} from "@/utility/const";
import RoofAndSubstrateInfoStep from "../steps/RoofAndSubstrateInfoStep.vue";
import TpoRoofAndSubstrateInfoStep from "../steps/TpoRoofAndSubstrateInfoStep.vue";
import MembraneInfoStep from "../steps/MembraneInfoStep.vue";
import RakeOrEdgeInfoStep from "../steps/RakeOrEdgeInfoStep.vue";
import InsulationInfoStep from "../steps/InsulationInfoStep.vue";
import InformationStep from "../steps/InformationStep.vue";
import ReviewStep from "../steps/ReviewStep.vue";

import MiscAndLaborStep from "../steps/MiscAndLaborStep.vue";
import LeadsVentsStep from "../steps/LeadsVentsStep.vue";
import PipeBootsAccessoriesStep from "../steps/PipeBootsAccessoriesStep.vue";

import ShingleRoofComponentsStep from "../steps/ShingleRoofComponentsStep.vue";
import StandingSeamRoofComponentsStep from "../steps/StandingSeamRoofComponentsStep.vue";

import TpoMetalStep from "../steps/TpoMetalStep.vue";
import ShingleMetalStep from "../steps/ShingleMetalStep.vue";
import ShingleReviewStep from "../steps/ShingleReviewStep.vue";
import StandingSeamReviewStep from "../steps/StandingSeamReviewStep.vue";
import StandingSeamMetalStep from "../steps/StandingSeamMetalStep.vue";

import ShingleRoofStep from "../steps/ShingleRoofStep.vue";
import StandingSeamRoofStep from "../steps/StandingSeamRoofStep.vue";

import ServiceInformationStep from "../steps/ServiceInformationStep.vue";
import ServiceReviewStep from "../steps/ServiceReviewStep.vue";
import ServiceProductStep from "../steps/ServiceProductStep.vue";

import buildingMixin from "../../utility/building-mixins";
import NewProposalButton from "../buttons/NewProposalButton.vue";

import _ from "lodash";

export default {
  mixins: [buildingMixin],
  components: {
    RoofAndSubstrateInfoStep,
    TpoRoofAndSubstrateInfoStep,
    InformationStep,
    MembraneInfoStep,
    RakeOrEdgeInfoStep,
    InsulationInfoStep,
    MiscAndLaborStep,
    ReviewStep,

    ShingleRoofComponentsStep,
    LeadsVentsStep,
    PipeBootsAccessoriesStep,

    StandingSeamRoofComponentsStep,

    TpoMetalStep,
    ShingleMetalStep,
    ShingleReviewStep,
    StandingSeamReviewStep,
    StandingSeamMetalStep,

    ShingleRoofStep,
    StandingSeamRoofStep,

    ServiceInformationStep,
    ServiceProductStep,
    ServiceReviewStep,

    NewProposalButton
  },
  props: {
    workSteps: Array,
    isLastBuilding: {
      type: Boolean,
      default: false
    },
    isLastStep: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeContent: "table",
      CONTENT_TYPE_EST,
      STEP_INFORMATION,
      STEP_SERVICE_INFORMATION,
      STATUS_ESTIMATE_ACTIVE,

      stepList: []
    };
  },
  computed: {
    ...mapGetters("common/app-constant", ["roofTypeBy", "workStepBy"]),
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),
    checkTypeContent() {
      return type => this.typeContent === type;
    },
    isThemDart() {
      return localStorage.getItem("themeDark") === "true"
        ? "background-color: var(--f7-color-bg-dark);"
        : "background-color: var(--f7-color-light-gray);";
    },
    isShowButtonNextSpecification() {
      if (_.isEmpty(this.estimate.buildings)) return;
      const index = (this.estimate.buildings || []).findIndex(
        e => e.buildingId === this.currentBuilding.id
      );
      return !(index + 1 === this.estimate.buildings.length);
    },
    stepComponent() {
      return stepId => {
        return (this.workStepBy(stepId) || {}).componentName || "";
      };
    }
  },
  methods: {
    changeTypeContent(type) {
      if (!type) return;
      this.typeContent = type;
      this.convertStepList(this.workSteps);

      if (this.workSteps.length > 1) {
        this.$emit("setOpenStepView", type === "review" ? "review" : "product");
      }
    },
    onNextSpecification() {
      const index = (this.estimate.buildings || []).findIndex(
        e => e.buildingId === this.currentBuilding.id
      );
      const buildingName = this.estimate.buildings.at(index + 1)?.buildingName;

      this.$ri.dialog.openWarningDialog({
        title: "",
        content: `The estimate of ${this.currentBuilding.buildingName} has been saved. Do you want to start estimate ${buildingName} ?`,
        textButton: "Yes",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            this.$f7router.navigate(
              this.buildingUrl(this.estimate.estimateNumber, buildingName),
              {
                reloadCurrent: true
              }
            );
          }
        }
      });
    },
    activeEstimate() {
      this.closePopover();
      this.$emit("handleActiveEstimate");
    },
    gotoSummary() {
      this.closePopover();
      this.$emit("onGotoSummary");
    },
    closePopover() {
      this.$refs.popoverButtonEstimate.close();
    },
    convertStepList(workSteps) {
      if (
        workSteps.length === 1 &&
        workSteps.at(0).stepId === STEP_INFORMATION
      ) {
        this.stepList = [workSteps[0]];
      } else {
        this.stepList =
          this.typeContent === "table"
            ? workSteps.slice(1, -1)
            : [workSteps.at(-1)];
      }
    }
  },
  watch: {
    isLastStep: {
      handler(val) {
        if (val) {
          this.changeTypeContent("review");
        }
      },
      immediate: true,
      deep: true
    },
    workSteps: {
      handler(val) {
        this.changeTypeContent("table");
        this.convertStepList(val);
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss">
.container-view-table {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 38px);
  max-width: calc(100% - 8px);
}

.box-button {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.box-next-spec {
  display: flex;
  align-items: center;
  color: var(--f7-theme-color);
  cursor: pointer;
  margin-right: 10px;
}

.box-product{
  flex: 1;
  overflow-y: auto;
}

.container-view-table-content {
  flex: 1;
}

.container-view-table-content-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.box-btn-right {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
