<template>
  <div>
    <div class="card data-table">
      <table>
        <thead>
          <tr>
            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('contactName')"
                >Lead Info</span
              >
              <div class="input">
                <input
                  @input="searchContact = $event.target.value.trim()"
                  type="text"
                  placeholder="Search lead"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('email')"
                >Email</span
              >
              <div class="input">
                <input
                  @input="searchEmail = $event.target.value.trim()"
                  type="text"
                  placeholder="Search email"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('phoneNumber')"
                >Phone Number</span
              >
              <div class="input">
                <input
                  @input="searchPhoneNumber = $event.target.value.trim()"
                  type="text"
                  placeholder="Search phone number"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('companyName')"
                >Company Name</span
              >
              <div class="input">
                <input
                  @input="searchCompanyName = $event.target.value.trim()"
                  type="text"
                  placeholder="Search company name"
                />
                <span class="input-clear-button"></span>
              </div>
            </th>

            <th class="label-cell">
              <span>Reason</span>
            </th>

            <th class="label-cell">
              <span>Message</span>
            </th>

            <!-- <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('businessCode')"
                >Business</span
              >
            </th> -->

            <th class="label-cell">
              <span
                class="sortable-cell"
                :class="currentSortDir === 'asc' ? 'table-head-label' : ''"
                @click="sort('createdAt')"
                >Submitted Date</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(contact, index) in contactList"
            :key="index"
            class="cursor-pointer"
          >
            <td class="label-cell">
              <f7-link @click="open(contact)">
                {{ contactName(contact) }}
              </f7-link>
            </td>
            <td class="label-cell">{{ contact.email }}</td>
            <td class="label-cell">{{ contact.phoneNumber }}</td>
            <td class="label-cell">{{ contact.companyName }}</td>
            <td class="label-cell">{{ ONLINE_LEAD_REASON[contact.reason] }}</td>

            <td class="label-cell">
              <long-content-block
                :seedId="`note-${contact.id}`"
                :content="contact.message"
              ></long-content-block>
            </td>

            <!-- <td class="label-cell">{{ startCase(contact.businessCode) }}</td> -->
            <td class="label-cell">
              {{ toDisplayDateString(contact.createdAt) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <contact-detail-popup ref="contactInfoPopup"></contact-detail-popup>
  </div>
</template>

<script>
import LongContentBlock from "@/components/blocks/LongContentBlock.vue";
import ContactDetailPopup from "../popup/ContactDetailPopup.vue";
import { mapGetters, mapActions } from "vuex";
import { toDisplayDateString } from "../../../../utility/datetime.js";
import { ONLINE_LEAD_REASON } from "../../../../utility/const";
import _ from "lodash";

export default {
  components: {
    LongContentBlock,
    ContactDetailPopup
  },

  data() {
    return {
      filterActionId: "0",
      searchContact: "",
      searchEmail: "",
      searchPhoneNumber: "",
      searchCompanyName: "",

      currentSort: null,
      currentSortDir: "asc",
      toDisplayDateString,
      ONLINE_LEAD_REASON
    };
  },

  methods: {
    ...mapActions("web-contact/web-contact-info", ["bindContactInfo"]),

    open(contact) {
      this.$f7.preloader.show();
      this.bindContactInfo(contact.id)
        .then(() => {
          this.$refs.contactInfoPopup.open();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    startCase(str) {
      return _.startCase(str);
    }
  },

  computed: {
    ...mapGetters("web-contact/web-contact-info", ["contactInfoList"]),

    contactList() {
      const filterCardsGrid = this.contactInfoList.map(contact => ({
        ...contact,
        contactName: this.contactName(contact)
      }));
      const listSort = filterCardsGrid.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      return listSort.filter(contact => {
        return (
          (this.contactName(contact)
            .toLowerCase()
            .includes(this.searchContact.toLowerCase()) ||
            this.searchContact === "") &&
          ((contact.email || "")
            .toLowerCase()
            .includes(this.searchEmail.toLowerCase()) ||
            this.searchEmail === "") &&
          ((contact.phoneNumber || "")
            .toLowerCase()
            .includes(this.searchPhoneNumber.toLowerCase()) ||
            this.searchPhoneNumber === "") &&
          ((contact.companyName || "")
            .toLowerCase()
            .includes(this.searchCompanyName.toLowerCase()) ||
            this.searchCompanyName === "")
        );
      });
    },

    contactName() {
      return contact => {
        return contact
          ? `${contact.firstName || ""} ${contact.lastName || ""}`.trim()
          : "";
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.assignee-avatar {
  margin-right: 5px;
}

.assignee-cell {
  display: flex;
}
</style>
