<template>
  <f7-row style="row-gap: 10px">
    <f7-col width="100" small="50">
      <div class="filter-navbar">
        <div class="display-flex input-search-filter">
          <f7-icon color="primary" f7="search"></f7-icon>
          <f7-input
            style="flex: 1"
            placeholder="Search..."
            type="text"
            :clear-button="true"
            :value="searchText"
            @input="onSearch($event.target.value.trim())"
          ></f7-input>
        </div>
        <div class="input-date-filter">
          <f7-icon color="primary" f7="calendar"></f7-icon>
          <f7-link @click.native="selectCalendar" class="date-link">
            <span
              v-if="isChangeWeek"
              style="color: var(--f7-color-text-neutral)"
            >
              {{ showWeekRange }}
            </span>
            <span v-else style="color: gray">Filter Due Date</span>
          </f7-link>
          <div class="display-flex align-items-center" @click="clearFilterWeek">
            <f7-icon
              class="clear-button"
              :class="isChangeWeek ? '' : 'display-none'"
              f7="xmark_circle_fill"
            ></f7-icon>
          </div>
        </div>
      </div>
    </f7-col>
    <f7-col width="100" small="50">
      <f7-row class="justify-content-end">
        <user-filter @changeFilterUsers="onChangeFilter"></user-filter>
      </f7-row>
    </f7-col>
  </f7-row>
</template>
<script>
import UserFilter from "./UserFilter.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";

function getCurrentWeek() {
  const weekStart = moment().startOf("isoWeek");
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(
      moment(weekStart)
        .add(i, "days")
        .format("MM/DD/YYYY")
    );
  }
  return days;
}
export default {
  components: { UserFilter },
  data: () => {
    return {
      currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
      searchText: "",
      filterWeek: [],
      isChangeWeek: false
    };
  },
  computed: {
    ...mapGetters({
      filter: "swimlane/card/filter"
    }),
    showWeekRange() {
      const from = moment(this.currentWeek[0], "MM/DD/YYYY").format("MMM Do");
      const to = moment(this.currentWeek[6], "MM/DD/YYYY").format("MMM Do");
      return `${from} - ${to}`;
    }
  },
  methods: {
    ...mapActions("swimlane/card", [
      "setFilterUsers",
      "setFilterSearch",
      "setFilterWeek"
    ]),

    onChangeFilter(listUserId) {
      this.setFilterUsers(_.cloneDeep(listUserId));
    },
    onFilterWeek(days) {
      this.filterWeek = days;
      this.setFilterWeek(days);
    },
    onSearch(value) {
      this.searchText = value;
      this.setFilterSearch(value);
    },
    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: "#calendar-input",
          openIn: "customModal",
          backdrop: true,
          cssClass: "calendar-filter",
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: "current-week",
              range: {
                from: new Date(this.currentWeek[0]),
                to: new Date(this.currentWeek[6])
              }
            }
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), "day")
              ) {
                this.currentDate = value;
                const weekStart = moment(value[0], "MM/DD/YYYY").startOf(
                  "isoWeek"
                );
                this.setCurrentWeek(weekStart);
                calendar.close();
              }
            }
          }
        })
        .open();
    },

    setCurrentWeek(weekStart) {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(
          moment(weekStart)
            .add(i, "days")
            .format("MM/DD/YYYY")
        );
      }
      this.isChangeWeek = true;
      this.currentWeek = days;
      this.onFilterWeek(days);
    },

    clearFilterWeek() {
      this.isChangeWeek = false;
      this.currentWeek = getCurrentWeek();
      this.currentDate = [new Date()];
      this.onFilterWeek([]);
    }
  },
  destroyed() {
    this.onSearch("");
    this.clearFilterWeek();
    this.setFilterUsers([]);
  }
};
</script>
<style scoped>
.searchbar-container {
  min-width: 260px;
  border-bottom: 1px solid;
  padding-bottom: 2px;
}

.filter-navbar ::v-deep .input-search-filter {
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding: 0 10px;
  background-color: var(--f7-md-surface);
  min-width: 220px;
  box-sizing: border-box;
  gap: 8px;
}
.input-date-filter {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  min-width: 220px;
  box-sizing: border-box;
  gap: 8px;
}
.input-date-filter .date-link {
  flex: 1;
  justify-content: flex-start;
}
.input-search-filter input {
  height: 100%;
}
.current-week {
  background-color: #dc5858;
}
.input-search-filter::after {
  height: 0;
}
.filter-navbar {
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
  padding: 0 10px;
}
.clear-button {
  cursor: pointer;
  color: var(--f7-input-clear-button-color);
  font-size: calc(var(--f7-input-clear-button-size) + 4px);
}
.calendar {
  color: var(--f7-tabbar-link-inactive-color);
}
</style>
