<template>
  <f7-popup :opened="popupOpened" @popup:closed="cancel">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="cancel">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Share project's photos by Email</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="send">Send</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="display-flex flex-direction-column" style="height: 100%">
        <f7-list style="width: 99%;">
          <custom-auto-complete-input :options="emailOptions" :value="emailAddresses" :hasMoreItems="hasMoreItems"
            title="To Emails" type="email" placeholder="Enter emails address" @change="onChange"
            @searchChange="searchChange" @loadMore="loadMore">
            <f7-icon slot="icon" f7="envelope"></f7-icon>
            <span slot="error-message">{{
              displayEmailErrorMessage || emailErrorMessage
            }}</span>
          </custom-auto-complete-input>
        </f7-list>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import notificationService from "../../services/notifications";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import CustomAutoCompleteInput from "@/components/inputs/CustomAutoCompleteInput.vue";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { CustomAutoCompleteInput },
  data: () => ({
    popupOpened: false,
    emailAddresses: [],
    emailErrorMessage: "",

    allowInfinite: true,
    hasMoreItems: true,

    oldEmailsContact: []
  }),
  methods: {
    ...mapActions("setting/app/system", ["bindSetting"]),
    ...mapActions("common/contact", [
      "searchContact",
      "loadMoreContact",
      "resetSearch",
      "setQueryFilters",
      "setContactSearchText"
    ]),

    autoLoadMore() {
      if (
        ((this.emailsOfContact || []).length > 0 &&
          (this.emailsOfContact || []).length ===
          (this.oldEmailsContact || []).length) ||
        (this.emailsOfContact || []).length * 40 <= 300
      ) {
        if (this.page + 1 < this.nbPages && this.nbPages > 1) {
          this.loadMore();
        }
      }
    },
    onSearch: _.debounce(function(callback) {
      return this.handleSearch(callback);
    }, 300),
    async handleSearch(callback) {
      const self = this;
      this.setQueryFilters("NOT emailValues:?");
      this.hasMoreItems = true;
      this.allowInfinite = true;

      return this.searchContact({
        restrictSearchableAttributes: ["contactName", "emailValues"]
      }).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
          return;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
          return;
        }
        callback;
        this.autoLoadMore();
      });
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;

      this.loadMoreContact({
        page: this.page + 1,
        restrictSearchableAttributes: ["contactName", "emailValues"]
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
        this.autoLoadMore();
      });
    },

    onChange(value) {
      this.emailAddresses = value;
      this.checkAllEmailValidate(this.emailAddresses);
      this.v$.$touch();
    },
    searchChange(data) {
      this.setContactSearchText(data.value);
      this.onSearch(data.callback);
    },
    open() {
      this.$f7.preloader.show();
      if (_.isEmpty(this.setting)) {
        this.bindSetting(this.tenantId);
      }
      this.handleSearch()
        .then(() => {
          this.popupOpened = true;
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    cancel() {
      this.emailAddresses = [];
      this.emailErrorMessage = "";
      this.popupOpened = false;
      this.setContactSearchText("");
      this.setQueryFilters("");

      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.oldEmailsContact = [];
      this.v$.$reset();
      this.resetSearch();
    },
    copyLink() {
      const tenant = btoa(this.tenantId);
      let shareUrl = `${import.meta.env.VITE_HOST_DOMAIN}/share-photo/${tenant}/${this.$f7route.params.id}`;
      event.preventDefault();
      const tempInput = document.createElement("input");
      tempInput.value = shareUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.showToast("Copied!");
    },
    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    },
    send() {
      this.v$.$touch();
      if (
        this.v$.$invalid ||
        !this.checkAllEmailValidate(this.emailAddresses)
      ) {
        return;
      } else {
        const tenant = btoa(this.tenantId);
        let shareUrl = `${import.meta.env.VITE_HOST_DOMAIN}/share-photo/${tenant}/${this.$f7route.params.id}`;
        notificationService.sendEmail({
          mailTo: this.emailAddresses.map(r => r.title).join(";"),
          shareUrl: shareUrl,
          projectName: this.project.title,
          companyName: this.setting.companyName
        });
        this.$f7.notification
          .create({
            icon:
              "<i class='color-green icon f7-icons'>checkmark_alt_circle_fill</i>",
            title: "Share Successful",
            text: `An email was sent to ${this.emailAddresses
              .map(r => r.title)
              .join(", ")}`,
            closeOnClick: true,
            closeButton: true,
            closeTimeout: 4000
          })
          .open();
        this.cancel();
      }
    },
    validateEmail(input_str) {
      var re = /\S+@\S+\.\S+/;
      return re.test(input_str);
    },
    checkAllEmailValidate(phones) {
      let wrongEmails = phones.filter(r => !this.validateEmail(r.title));
      if (wrongEmails && wrongEmails.length > 0) {
        this.emailErrorMessage = `The ${wrongEmails.length > 1 ? "emails" : "email"
          } ${wrongEmails
            .map(r => r.title)
            .join(", ")} is not valid email please remove it.`;
        return false;
      }
      this.emailErrorMessage = "";
      return true;
    }
  },
  computed: {
    ...mapGetters("setting/app/system", ["setting"]),
    ...mapGetters("photo/project", ["project"]),
    ...mapGetters("common/contact", [
      "emailsOfContact",
      "hits",
      "nbPages",
      "page"
    ]),

    emailOptions() {
      return this.emailsOfContact
        .filter(p => this.validateEmail(p.email))
        .map(r => ({ id: r.email + r.name, title: r.email, desc: r.name }));
    },
    ...mapGetters('common/app-constant', ['tenantId']),

    displayEmailErrorMessage() {
      if (!this.v$.emailAddresses.$error) return null;
      if (this.v$.emailAddresses.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    return {
      emailAddresses: {
        required
      }
    };
  },
  watch: {
    emailsOfContact: {
      handler(val, old) {
        this.oldEmailsContact = old;
      }
    }
  }
};
</script>
