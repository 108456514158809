<template>
  <div class="summary padding">
    <div>
      <!-- net sale -->
      <div v-if="taxList.length && invoice.tableContentType === 'itemize'">
        <f7-row
          class="margin-bottom-half"
          v-for="(taxItem, taxIndex) in taxList"
          :key="taxIndex"
        >
          <f7-col :width="40">Net Sales {{ taxItem.taxName }} % </f7-col>

          <f7-col width="50" class="text-align-right">
            {{ taxItem.netSalesAmount | currencyUSD }}
          </f7-col>
        </f7-row>
      </div>

      <f7-row class="margin-bottom-half">
        <f7-col width="40">Total Net Sales</f7-col>
        <f7-col width="50" class="text-align-right">
          {{ totalNetSales | currencyUSD }}
        </f7-col>
      </f7-row>

      <!-- tax -->
      <div
        v-if="taxList.length && invoice.tableContentType === 'itemize'"
        class="mt-2"
      >
        <f7-row
          class="margin-bottom-half"
          v-for="(taxItem, totalIndex) in taxList"
          :key="totalIndex"
        >
          <f7-col :width="40">Tax {{ taxItem.taxName }} % </f7-col>

          <f7-col width="50" class="text-align-right">
            {{ taxItem.taxAmount | currencyUSD }}
          </f7-col>
        </f7-row>
      </div>

      <f7-row class="margin-bottom-half">
        <f7-col width="40">Total Tax</f7-col>
        <f7-col width="50" class="text-align-right">
          {{ totalTax | currencyUSD }}
        </f7-col>
      </f7-row>

      <!-- discount -->
      <f7-row
        :class="[
          invoice.tableContentType === 'itemize' && 'mt-2',
          'margin-bottom-half'
        ]"
      >
        <f7-col width="40">Total Discount</f7-col>
        <f7-col width="50" class="text-align-right">
          {{ totalDiscount | currencyUSD }}</f7-col
        >
      </f7-row>

      <!-- shipping charge -->
      <f7-row class="margin-bottom-half">
        <f7-col width="40"> Shipping Charge</f7-col>

        <f7-col width="30">
          <div>
            <input-percent-or-cash
              ref="shippingChargeInputPercentOrCash"
              inputClass="outline"
              :value="(invoice.shippingCharge || {}).value"
              :type="(invoice.shippingCharge || {}).type"
              @inputValue="
                onChangeSummaryFooter('shippingCharge', 'value', $event)
              "
              @changeType="
                onChangeSummaryFooter('shippingCharge', 'type', $event)
              "
            ></input-percent-or-cash>
          </div>
        </f7-col>
        <f7-col width="30" class="text-align-right">{{
          shippingCharge | currencyUSD
        }}</f7-col>
      </f7-row>

      <!-- total -->
      <f7-row class="margin-bottom-half">
        <f7-col width="40"><b>Total</b></f7-col>
        <f7-col width="50" class="text-align-right">
          {{ totalInvoice(invoice) | currencyUSD }}</f7-col
        >
      </f7-row>
    </div>
  </div>
</template>
<script>
import InputPercentOrCash from "@/components/inputs/InputPercentOrCash.vue";
import summaryFooterMixins from "../../mixins/summary-footer-mixin";

export default {
  name: "FooterTable",

  components: { InputPercentOrCash },

  mixins: [summaryFooterMixins],

  props: {
    invoice: Object,
    isFromEstimate: { Boolean, default: false },
    isEdit: { type: Boolean, default: true }
  },

  data() {
    return {
      shippingCharge: 0
    };
  },

  computed: {
    isShowItemsDiscoutCount() {
      if (!this.itemsDiscoutCount) return false;
      return this.invoice?.tableContentType === "itemize"
        ? true
        : this.invoice?.tableContentType === "labor-material"
        ? true
        : this.invoice?.tableContentType === "simple-summary"
        ? true
        : false;
    },

    itemsDiscoutCount() {
      return (
        this.invoice?.itemDetails?.filter(item => item.discount).length || 0
      );
    },

    taxList() {
      return this.getTaxList(this.invoice);
    },

    totalTax() {
      return this.taxList.reduce((acc, item) => acc + item.taxAmount, 0);
    },

    totalDiscount() {
      return this.getTotalDiscount(this.invoice);
    },

    totalNetSales() {
      return this.taxList.reduce((acc, item) => acc + item.netSalesAmount, 0);
    }
  },

  mounted() {
    this.createTooltip();
  },

  methods: {
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: ".tax-info-icon",
        text: "tax-info-icon"
      });
    },

    onChangeSummaryFooter(prop, subprop, value) {
      this.$emit("onChangeSummaryFooter", { prop, subprop, value });
    },
    updateShippingCharge() {
      const val = this.invoice.shippingCharge;
      let newShippingCharge = 0;
      if (val.type === "percent") {
        newShippingCharge =
          (this.getTotalIntoMoney(this.invoice) * val.value) / 100;
      } else {
        newShippingCharge = val.value;
      }
      this.shippingCharge = newShippingCharge;
    }
  },

  watch: {
    "invoice.itemDetails": {
      handler: function() {
        this.updateShippingCharge();
      },
      deep: true,
      immediate: true
    },
    "invoice.shippingCharge": {
      handler: function() {
        this.updateShippingCharge();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style land="scss" scoped>
.summary {
  background: var(--f7-text-editor-toolbar-bg-color);
  border-radius: 4px;
}
.flex-start {
  justify-content: flex-start;
}
.select-type ::v-deep select {
  min-width: 50px;
}
.fs-small {
  font-size: 0.85em;
}

.custom-row {
  justify-content: unset !important;
}

.mt-2 {
  margin-top: 30px;
}
</style>
