<template>
  <data-table
    :headers="headers"
    :items="invoiceList"
    :pageSize="(invoiceList || []).length"
    :isPinHeader="true"
  >
    <template v-slot:body="{ item }">
      <td>{{ item.taskNumber }}</td>
      <td>{{ item.invoiceNumber }}</td>
      <td>{{ item.invoiceDate | MMDDYYYY }}</td>
      <td>{{ item.amount | currencyUSD }}</td>
      <td>{{ companyById(item.manufacturerId).companyName || "" }}</td>
      <td v-if="isActiveTab" class="checkbox-cell" style="width: 50px">
        <f7-checkbox
          :checked="item.checked"
          @change="$emit('checkBoxChange', $event.target.checked, item.id)"
        ></f7-checkbox>
      </td>
    </template>
  </data-table>
</template>
<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    DataTable
  },

  props: {
    invoiceList: Array,
    isActiveTab: Boolean
  },

  created() {
    if (_.isEmpty(this.bindCompanyList)) {
      this.bindCompanyList();
    }
  },

  computed: {
    ...mapGetters("quickbooks/company", ["companyList", "companyById"]),

    headers() {
      return [
        {
          text: "PO Number",
          value: "poNumber",
          sortable: false,
          align: "left"
        },
        {
          text: "Invoice Number",
          value: "invoiceNumber",
          sortable: false,
          align: "left"
        },
        {
          text: "Invoice Date",
          value: "invoiceDate",
          sortable: false,
          align: "left"
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          align: "left"
        },
        {
          text: "Vendor Name",
          value: "vendorName",
          sortable: false,
          align: "left"
        },
        {
          text: "",
          value: "",
          sortable: false,
          align: "left"
        }
      ];
    }
  },

  methods: {
    ...mapActions("quickbooks/company", ["bindCompanyList"])
  }
};
</script>
