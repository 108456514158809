import { groupBy } from "@/utility/filter-tools";

export default {
  companyTypeList: state => state.companyTypeList,
  companyGroup: state => {
    let dataGroup = groupBy(state.hits, item =>
      ((item.companyName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data
    }));
  },

  // Fulltext search
  order: state => state.order,
  companySearchText: state => state.companySearchText,
  queryFilters: state => state.queryFilters,
  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
