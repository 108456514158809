import axios from "axios";
import { Service } from "axios-middleware";
import "firebase/auth";
import { firebase } from "./firebase.service";

const base_api_url = import.meta.env.VITE_BASE_API;

const instance = axios.create({
  baseURL: base_api_url,
  withCredentials: true,
  headers: {
    id_token: localStorage.getItem("id_token"),
    login_tenant: localStorage.getItem("login_tenant") || ""
  }
});

const auth = firebase.auth();

// Create a new service instance
const service = new Service(instance);

service.register({
  async onResponse(response) {
    try {
      const data = response && response.data ? JSON.parse(response.data) : {};
      // refresh firebase token
      if (
        data.code === 300 &&
        response.config &&
        !response.config.hasRetriedRequest
      ) {
        const idToken = await auth.currentUser.getIdToken(true);
        instance.defaults.headers.id_token = idToken;
        await localStorage.setItem("id_token", idToken);
        let config = {
          ...response.config,
          hasRetriedRequest: true,
          headers: {
            ...response.config.headers,
            id_token: idToken
          }
        };
        return axios(config);
      } else {
        return response;
      }
    } catch (e) {
      return response;
    }
  }
});

export { base_api_url };

export default instance;
