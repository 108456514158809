<template>
  <div class="page-footer">
    <div class="left">
      <input
        type="text"
        name="left"
        :value="data.left"
        @change="changeLeftValue"
      />
    </div>
    <div class="right">
      <input
        type="text"
        name="right"
        @change="changeRightValue"
        :value="data.right"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { FOOTER_TYPE } from "../../utility/const";

export default {
  props: {
    data: Object
  },
  computed: {
    ...mapGetters({
      proposalTemplate: "proposal/template/proposalTemplate",
      proposal: "proposal/proposal/proposal"
    })
  },
  methods: {
    ...mapActions({
      setProposalTemplateField: "proposal/template/setProposalTemplateField",
      setProposalField: "proposal/proposal/setProposalField"
    }),
    changeLeftValue($event) {
      this.updateFooterForAllPages({ ...this.data, left: $event.target.value });
    },
    changeRightValue($event) {
      this.updateFooterForAllPages({
        ...this.data,
        right: $event.target.value
      });
    },
    updateFooterForAllPages(data) {
      if (!_.isEmpty(this.proposalTemplate)) {
        this.updateTemplateFooterForAllPages(data);
      }
      if (!_.isEmpty(this.proposal)) {
        this.updateProposalFooterForAllPages(data);
      }
    },
    updateTemplateFooterForAllPages(data) {
      let pages = _.cloneDeep((this.proposalTemplate || {}).pages);
      (pages || []).forEach(page => {
        let index = page.components.findIndex(e => e.type == FOOTER_TYPE);
        if (index > -1) {
          page.components[index].data = data;
        }
      });
      this.setProposalTemplateField({ fieldName: "pages", value: pages });
    },
    updateProposalFooterForAllPages(data) {
      let pages = _.cloneDeep((this.proposal || {}).pages);
      (pages || []).forEach(page => {
        let index = page.components.findIndex(e => e.type == FOOTER_TYPE);
        if (index > -1) {
          page.components[index].data = data;
        }
      });
      this.setProposalField({ fieldName: "pages", value: pages });
    }
  }
};
</script>

<style scoped>
.page-footer {
  display: flex;
  border-top: solid 1px #333030;
}

.page-footer .left {
  flex: 1;
}

.page-footer .right {
  flex: 1;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.right input {
  text-align: right;
}
.page-footer input {
  width: 100%;
  padding: 15px;
}
</style>
