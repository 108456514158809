<template>
  <div>
    <f7-popup
      :opened="isPopupOpened"
      @popup:closed="handlePopupClosed"
      @popup:opened="() => {}"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Edit Table</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="saveAndClose">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- Products table -->
        <div class="card data-table">
          <div class="card-content">
            <table>
              <thead>
                <tr>
                  <th>
                    <f7-checkbox
                      :checked="true"
                      @change="
                        handleHeaderCheckboxChecked($event.target.checked)
                      "
                    ></f7-checkbox>
                  </th>
                  <th
                    class="label-cell"
                    v-for="(item, index) in headers"
                    :key="index"
                  >
                    {{ item.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in columns" :key="index">
                  <td>
                    <f7-checkbox
                      :class="
                        `${item.id === 'product-description' ? 'disabled' : ''}`
                      "
                      :checked="item.checked"
                      @change="
                        handleCheckboxChecked(item, $event.target.checked)
                      "
                    ></f7-checkbox>
                  </td>
                  <td class="label-cell">{{ item.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      isPopupOpened: false,
      estimateId: "",
      sectionId: "",

      columns: [],

      headers: [
        {
          text: "Column Name",
          value: "value",
          align: "left"
        }
      ]
    };
  },

  computed: {
    ...mapGetters("common/proposal-template", ["proposalTemplate"])
  },

  methods: {
    handleHeaderCheckboxChecked(checked) {
      for (const item of this.columns) {
        item.checked = item.id === "product-description" ? true : checked;
      }
    },

    handleCheckboxChecked(item, checked) {
      const foundItem = this.columns.find(column => column.id === item.id);
      if (foundItem) {
        foundItem.checked = checked;
      }
    },

    openPopup() {
      this.columns = this.proposalTemplate
        ? _.cloneDeep(this.proposalTemplate.productTableColumns)
        : [];
      this.isPopupOpened = true;
    },

    saveAndClose() {
      this.$f7.preloader.show();
      const self = this;

      this.$emit("onSave", {
        columns: this.columns,
        callback: () => {
          self.$f7.preloader.hide();
          self.isPopupOpened = false;
        }
      });
    },

    handlePopupClosed() {
      this.columns = [];
      this.isPopupOpened = false;
    }
  }
};
</script>

<style></style>
