export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_NUMBER_OF_ROWS = "SET_NUMBER_OF_ROWS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
// export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER"; // Allow search desc only
export const RESET_SEARCH = "RESET_SEARCH";
export const ADD_MORE_RESULT = "ADD_MORE_RESULT";

export const RECURRING_INVOICE_LIST_ITEM_UPDATE =
  "RECURRING_INVOICE_LIST_ITEM_UPDATE";
export const RECURRING_INVOICE_LIST_ITEM_ADD =
  "RECURRING_INVOICE_LIST_ITEM_ADD";
export const RECURRING_INVOICE_LIST_ITEM_REMOVE =
  "RECURRING_INVOICE_LIST_ITEM_REMOVE";
export const SET_SELECTED_STATUS_FILTER = "SET_SELECTED_STATUS_FILTER";

export const SET_RECURRING_INVOICE_LIST = "SET_RECURRING_INVOICE_LIST";
