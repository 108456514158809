<template>
  <f7-popup :opened="popupOpened" @popup:closed="handlePopupClosed">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Create Roofing Choices</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="save()">Save </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list media-list>
        <!-- <f7-list-input
          label="Price List"
          placeholder="Please choose..."
          type="select"
          :value="roofingChoicesPriceListId"
          @input="selectPriceList($event.target.value)"
        >
          <input-icon slot="media" icon="square_list"></input-icon>
          <option value="" hidden>Select Price List</option>
          <option
            v-for="(priceList, index) in priceListItemList"
            :key="index"
            :value="priceList.id"
            >{{ priceList.priceListNumber }}&nbsp; &nbsp; &nbsp;
            {{ priceList.displayName }}</option
          >
        </f7-list-input> -->
        <f7-list-item link @click.native="openPopupSelectPriceList">
          <div class="list-item-inner-start" slot="inner-start">
            Price List<required-asterisk />
          </div>
          <div class="list-item-title" slot="title">
            {{
              roofingChoicesPriceListTitle
                ? roofingChoicesPriceListTitle
                : "Select price list"
            }}
          </div>
          <input-icon slot="media" icon="square_list"></input-icon>
        </f7-list-item>
      </f7-list>

      <f7-list>
        <data-table :headers="headers" :checkbox="true" :items="productComputed"
          :pageSize="(productComputed || []).length" @selected:change="selectEvent">
          <template slot="card-header">
            <f7-list>
              <f7-searchbar disable-button-text placeholder="Search in product items" :clear-button="true"
                :value="searchValue" @input="searchValue = $event.target.value" @searchbar:disable="searchValue = ''"
                class="search-list-popup">
                <f7-button slot="inner-end" outline class="add-button-preview margin-left" @click="openPreview">
                  Preview Roofing Choices
                </f7-button>
                <f7-button slot="inner-end" fill class="margin-left add-button-send" @click.native="sentRoofingChoices">
                  Send Email
                </f7-button>
              </f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td>
              {{ vendorName(item.vendorId) }}
            </td>
            <td>{{ item.manufacturer }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.productItem }}</td>
            <td>
              <div v-if="(item.technicalData || []).length > 0" class="technical-data">
                <ul v-for="(attach, index) in item.technicalData || []" :key="index" class="display-block" 
                    :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`">
                  <li @click.stop="openLink(item, attach)">
                    {{ attach.name }}
                  </li>
                </ul>
              </div>
            </td>

            <td>
              <f7-link v-for="(color, index) in item.colorPhotos || []" :key="index"
                @click.native.stop="clickPhoto(color)" :id="`${item.id}-${color.id}`">
                {{
                  `${color.colorName}${index + 1 === (item.colorPhotos || []).length
                    ? ""
                    : ",&nbsp;"
                  }`
                }}
              </f7-link>
            </td>
          </template>
        </data-table>
      </f7-list>

      <f7-photo-browser :photos="productPhotos" theme="dark" :ref="`detail_pageDark`" type="popup"></f7-photo-browser>

      <preview-roofing-choices-popup ref="previewRoofingChoicesPopup"
        @sentRoofingChoices="sentRoofingChoices"></preview-roofing-choices-popup>

      <price-list-item-popup ref="selectPriceListItem" @onSelected="selectPriceList($event)">
      </price-list-item-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from "@/components/icons/InputIcon.vue";
import DataTable from "@/components/datatables";

import PreviewRoofingChoicesPopup from "./PreviewRoofingChoicesPopup.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import { applyFilter, comparationSort } from "@/utility/filter-tools";
import * as constant from "@/utility/const";
import { firebase, auth } from "../../../../services/firebase.service";
import { ROOFING_CHOICE_CREATED } from "../../../../utility/const";
import axiosService from "@/services/axios.service";
import PriceListItemPopup from "./PriceListItemPopup.vue";
import { isVideo, isImage, createMediaArray, openAttackLink } from "@/utility/common";
export default {
  components: {
    InputIcon,
    DataTable,
    PreviewRoofingChoicesPopup,
    PriceListItemPopup
  },

  data: () => {
    return {
      popupOpened: false,
      searchValue: "",

      productPhotos: [],

      gridFilter: {
        vendorId: "",
        manufacturer: "",
        sku: "",
        productItem: "",
        color: "",
        technicalData: "",
        colorName: ""
      },
      roofingChoicesPriceListId: "",
      roofingChoicesPriceListTitle: "",
      defaultRoofingChoicesProductList: [],
      isEdited: false
    };
  },

  computed: {
    ...mapGetters("dashboard/price-list", [
      "priceListItemList",
      "productItems",
      "priceListItem"
    ]),

    ...mapGetters({ project: "dashboard/project/card" }),

    ...mapGetters({ vendors: "dashboard/client/companyList" }),

    vendorFilterOptions() {
      let condition = item =>
        (this.mainProducts || []).some(i => i.vendorId == item.id);
      return this.vendors.filter(condition);
    },

    headers() {
      let self = this;
      return [
        {
          text: "Vendor",
          value: "vendor",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.vendorId,
            selectSource: [
              { value: "", text: "All vendors" },
              ...this.vendorFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.companyName
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.vendorId = value;
            }
          }
        },
        {
          text: "Manufacturer",
          value: "manufacturer",
          sortable: false,
          align: "left",
          filter: {
            type: "text",
            placeholder: " Search manufacturer",
            value: self.gridFilter.manufacturer,
            onChange: value => {
              self.gridFilter.manufacturer = value;
            }
          }
        },
        {
          text: "SKU",
          value: "sku",
          sortable: false,
          align: "left",
          filter: {
            type: "text",
            placeholder: " Search SKU",
            value: self.gridFilter.sku,
            onChange: value => {
              self.gridFilter.sku = value;
            }
          }
        },
        {
          text: "Product items",
          value: "productItem",
          sortable: false,
          align: "left",
          filter: {
            type: "text",
            placeholder: " Search item",
            value: self.gridFilter.productItem,
            onChange: value => {
              self.gridFilter.productItem = value;
            }
          }
        },
        {
          text: "Technical data",
          value: "technicalData",
          excludeExport: true,
          sortable: false,
          align: "left",
          filter: {
            type: "text",
            placeholder: " Search technical data",
            value: self.gridFilter.technicalData,
            onChange: value => {
              self.gridFilter.technicalData = value;
            }
          }
        },
        {
          text: "Color",
          value: "colorPhotos",
          sortable: false,
          align: "left",
          filter: {
            type: "text",
            placeholder: "Search Color",
            value: self.gridFilter.colorName,
            onChange: value => {
              self.gridFilter.colorName = value;
            }
          }
        }
      ];
    },

    mainProducts() {
      // Panels & Trim => Standing Seam Panels
      // Shingles => Main Square
      // Membrane => Field Membrane
      return _.cloneDeep(
        this.productItems(this.roofingChoicesPriceListId) || []
      )
        .filter(product => {
          return (
            (product.categoryName === "Panels & Trim" &&
              product.subCategoryName === "Standing Seam Panels") ||
            (product.categoryName === "Shingles" &&
              product.subCategoryName === "Main Square") ||
            (product.categoryName === "Membrane" &&
              product.subCategoryName === "Field Membrane")
          );
        })
        .map(product => {
          return {
            ...product,
            checked: !!product.defaultRoofingChoices
          };
        });
    },

    productComputed() {
      return applyFilter(
        this.mainProducts,
        this.searchValue,
        [
          "sku",
          "productItem",
          "manufacturer",
          i => (i.colorPhotos || []).map(item => item.colorName).join(" "),
          i => this.technicalDataNames(i.technicalData),
          i => this.vendorName(i.vendorId)
        ],
        {
          vendorId: this.gridFilter.vendorId,
          manufacturer: this.gridFilter.manufacturer,
          sku: this.gridFilter.sku,
          productItem: this.gridFilter.productItem,

          colorPhotos: value => {
            if (!this.gridFilter.colorName) return true;
            const name = (value || []).map(item => item.colorName).join(" ");
            return name
              .toLowerCase()
              .includes(this.gridFilter.colorName.toLowerCase());
          },
          technicalData: value => {
            if (!this.gridFilter.technicalData) return true;
            const name = this.technicalDataNames(value) || "";
            return name
              .toLowerCase()
              .includes(this.gridFilter.technicalData.toLowerCase());
          }
        }
      );
    },

    vendorName() {
      return vendorId => {
        const vendor = (this.vendors || []).find(r => r.id == vendorId);
        return (vendor || {}).companyName;
      };
    },

    technicalDataNames() {
      return (technicalData = []) => {
        return technicalData.map(r => r.name).join(", ");
      };
    },
  },

  methods: {
    ...mapActions("dashboard/price-list", ["bindAllActivePriceList"]),
    ...mapActions("dashboard/product-item", ["bindActiveProductItems"]),
    ...mapActions({ updateProductItem: "dashboard/product-item/update" }),
    ...mapActions("dashboard/sub-category", ["bindSubCategoryList"]),
    ...mapActions("dashboard/category", ["bindCategories"]),
    ...mapActions("dashboard/client", ["bindCompanyListBys"]),

    ...mapActions({ updateProject: "dashboard/project/updateCard" }),

    open() {
      this.popupOpened = true;
      this.isEdited = true;
      this.$f7.preloader.show();
      const refs = [];
      refs.push(this.bindAllActivePriceList());
      refs.push(this.bindSubCategoryList());
      refs.push(this.bindCategories());
      refs.push(
        this.bindCompanyListBys([
          {
            prop: constant.PROP_IS_DELETED,
            op: "!=",
            val: true
          },
          {
            prop: "companyTypes",
            op: "array-contains",
            val: "vendor"
          }
        ])
      );
      return Promise.all(refs)
        .then(() => {
          if (this.project) {
            this.roofingChoicesPriceListId =
              this.project.roofingChoicesPriceListId || "";
          }

          if (this.roofingChoicesPriceListId) {
            return this.bindActiveProductItems(this.roofingChoicesPriceListId);
          }
        })
        .then(() => {
          this.defaultRoofingChoicesProductList = _.cloneDeep(
            this.productComputed
          );
        })
        .finally(() => {
          const priceListAll =
            this.priceListItemList.find(r => r.isDefault === true) || {};
          this.selectPriceList(priceListAll.id);
        });
    },

    openPopupSelectPriceList() {
      this.$refs.selectPriceListItem.open();
    },

    handlePopupClosed() {
      this.roofingChoicesPriceListId = "";
      this.roofingChoicesPriceListTitle = "";
      this.defaultRoofingChoicesProductList = [];
      this.gridFilter = {
        vendorId: "",
        manufacturer: "",
        sku: "",
        productItem: "",
        color: "",
        technicalData: "",
        colorName: ""
      };
      this.popupOpened = false;
    },

    selectPriceList(value) {
      this.$f7.preloader.show();
      this.roofingChoicesPriceListId = value;

      const priceListItem =
        this.priceListItem(this.roofingChoicesPriceListId) || {};
      this.roofingChoicesPriceListTitle = priceListItem
        ? `${priceListItem.displayName}`
        : "";

      return this.bindActiveProductItems(this.roofingChoicesPriceListId)
        .then(() => {
          this.defaultRoofingChoicesProductList = _.cloneDeep(
            this.productComputed
          );
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    selectEvent({ id, checked }) {
      const foundItem = this.defaultRoofingChoicesProductList.find(
        item => item.id === id
      );

      if (foundItem) {
        foundItem.defaultRoofingChoices = checked;
      }
    },

    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },

    createColorPhotoTooltip() {
      this.productComputed.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const el = this.$f7.tooltip.get(`#${item.id}-${color.id}`);
          if (el) {
            this.$f7.tooltip.destroy(`#${item.id}-${color.id}`);
          }
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: "tooltip-image-preview",
            text: tooltipContent
          });
        });
      });
      this.productComputed.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          if (el1) {
            this.$f7.tooltip.destroy(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)? `<video class="tooltip-image-show" src="${item?.url}" ></video>`:`<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass: isImage(item.url) || isVideo(item.url) ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
    },

    async save() {
      this.$f7.preloader.show();
      const refs = [];
      this.defaultRoofingChoicesProductList.forEach(product => {
        refs.push(
          this.updateProductItem({
            id: product.id,
            doc: { defaultRoofingChoices: !!product.defaultRoofingChoices }
          })
        );
      });

      const roofingChoicesProducts = this.defaultRoofingChoicesProductList.filter(
        item => item.defaultRoofingChoices
      );
      const roofingChoicesWebUrl = await this.generateRoofingChoicesWebUrl();
      if (this.project) {
        refs.push(
          this.updateProject({
            id: this.project.id,
            doc: {
              roofingChoicesStatus: ROOFING_CHOICE_CREATED,
              roofingChoicesPriceListId: this.roofingChoicesPriceListId,
              roofingChoicesPriceListTitle: this.roofingChoicesPriceListTitle,
              roofingChoicesProducts,
              roofingChoicesWebUrl,
              roofingChoicesCreatedAt: firebase.firestore.Timestamp.now(),
              roofingChoicesCreatedBy:
                auth.currentUser.displayName || auth.currentUser.email
            }
          })
        );
      }

      return Promise.all(refs).finally(() => {
        this.$f7.preloader.hide();
        this.isEdited = false;
        this.popupOpened = false;
      });
    },

    openPreview() {
      this.checkSavedData(() => {
        this.$refs.previewRoofingChoicesPopup.openPopup(
          this.project.roofingChoicesProducts,
          this.project.roofingChoicesStatus === ROOFING_CHOICE_CREATED
        );
      });
    },

    checkSavedData(callbackFunc) {
      if (this.isEdited) {
        this.save().then(() => {
          callbackFunc();
        });
      } else {
        callbackFunc();
      }
    },

    async generateRoofingChoicesWebUrl() {
      const response = await axiosService.post("/tenant/get-anonymous-token", {
        entity: "project",
        entityId: this.project.id
      });
      if (response && response.status === 200) {
        return `${import.meta.env.VITE_HOST_DOMAIN}/roofing-choices-view/?token=${response.data}&id=${this.project.id}`;
      }
      return "";
    },

    sentRoofingChoices() {
      this.checkSavedData(() => {
        this.$refs.previewRoofingChoicesPopup.closePopup();
        this.$emit("sentRoofingChoices");
      });
    }
  },

  updated() {
    this.createColorPhotoTooltip();
  }
};
</script>

<style scoped lang="scss">
.add-button-preview {
  min-width: 200px;
  border: 1px solid #f24e1e;
}

.add-button-send {
  min-width: 100px;
}

.technical-data {
  width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.technical-data ul {
  list-style-type: disc;
  padding-left: 20px;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

 .technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
