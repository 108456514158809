import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const TemplateActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_column`,
    "object"
  );

  return {
    bind: ({ dispatch }, boardId) => {
      dispatch("bindBys", [
        {
          prop: "boardId",
          op: "==",
          val: boardId
        }
      ]);
    },
    unbind: TemplateActions.unbindCollection,
    create: TemplateActions.createDocument,
    update: TemplateActions.updateDocument,
    delete: TemplateActions.deleteDocument,
    bindBys: TemplateActions.bindCollectionBys
  };
};
