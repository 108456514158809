<template>
  <f7-popup class="demo-popup" :opened="popupRejectTimeTracking" @popup:closed="cancel"
    @popup:opened="handlePopupOpened">
    <f7-page>
      <form>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Rejection reason </f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="save">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list no-hairlines-md>
          <f7-list-input class="first-input" label="Reject reason" type="textarea" placeholder="Reject reason"
            :value="reject_reason" :error-message="RejectReasonErrorMessage" error-message-force
            @input="reject_reason = $event.target.value"><required-asterisk slot="label" /></f7-list-input>
        </f7-list>
      </form>
    </f7-page>
  </f7-popup>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { VALIDATION_MESSAGE } from '@/utility/const';
export default {
  props: {
    popupRejectTimeTracking: Boolean
  },
  data() {
    return {
      reject_reason: ""
    };
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    return {
      reject_reason: {
        required
      }
    };
  },
  methods: {
    save() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.$emit("rejectTimeTracking", this.reject_reason);
        this.cancel();
      }
    },
    cancel() {
      this.reject_reason = "";
      this.v$.$reset();
      this.$emit("close");
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".first-input textarea").focus();
      }
    }
  },
  computed: {
    RejectReasonErrorMessage() {
      if (!this.v$.reject_reason.$error) return null;
      if (this.v$.reject_reason.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  }
};
</script>
