<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Users</f7-nav-title>

      <f7-nav-right>
        <f7-link icon-f7="plus" @click.native="onBtnAddClick"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      :value="searchValue"
      disable-button-text
      placeholder="Search user"
      :clear-button="true"
      @input="onSearch"
      @searchbar:disable="searchValue = ''"
      class="text-color-white search-list"
    ></f7-searchbar>
    <div>
      <f7-list class="no-margin users-list">
        <f7-list-group
          v-for="item in groupedUsers"
          :key="item.title"
          media-list
        >
          <f7-list-item :title="item.title" group-title></f7-list-item>
          <f7-list-item
            v-for="user in item.userGroup"
            :key="user.uid"
            :title="user.displayName"
            reloadDetail
            :link="`/administration/users/${user.uid}`"
            :subtitle="displayEmail(user)"
            :text-color="user.uid === selectedId ? 'primary' : 'default'"
          >
            <div slot="text">
              <div>{{ user.customClaims ? user.customClaims.role : "" }}</div>
            </div>
            <f7-chip
              slot="after"
              :text="onlineClass(user.disabled).text"
              :color="onlineClass(user.disabled).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <user-details-popup ref="userNewPopup" id="new"></user-details-popup>
    </div>
  </f7-page>
</template>

<script>
import UserDetailsPopup from "../components/popup/UserDetailsPopup.vue";
import { mapGetters, mapActions } from "vuex";

import Menu from "../../../components/menu/Menu.vue";

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  components: { UserDetailsPopup, Menu },

  data: () => {
    return {
      // popupOpened: false,
      searchValue: ""
    };
  },

  computed: {
    ...mapGetters({
      destroyFlag: "administration/user-list-page/common/destroyFlag"
    }),

    ...mapGetters("administration/user-list-page/user", ["users", "user"]),
    ...mapGetters("administration/user-list-page/group", ["groups"]),
    ...mapGetters("administration/plan", ["planList"]),
    ...mapGetters("setting/app/system", ["currentSubscription"]),
    ...mapGetters('common/app-constant', ['tenantId']),

    numberOfUsers() {
      return (this.users.filter(r => !r.disabled) || []).length;
    },
    currentPlan() {
      return (
        this.planList.find(r => r.id === this.currentSubscription.size) || {}
      );
    },
    filteredUsers() {
      return this.users
        .map(x => ({
          ...x,
          active: x.disabled === true ? "Active" : "Inactive"
        }))
        .filter(u => {
          let role = "";
          let { displayName = "", email = "", customClaims, active = "" } = u;
          displayName = displayName.toLowerCase();
          email = email.toLowerCase();
          role = (customClaims.role || "").toLowerCase();
          const search = this.searchValue.toLowerCase();
          return (
            displayName.indexOf(search) >= 0 ||
            email.indexOf(search) >= 0 ||
            role.indexOf(search) >= 0 ||
            active == this.searchValue.toLocaleString()
          );
        });
    },

    groupedUsers() {
      let groupBy = {};
      let newGroupUser = this.filteredUsers;
      newGroupUser.forEach(
        user =>
          (groupBy[user.displayName.charAt(0).toUpperCase()] = (
            groupBy[user.displayName.charAt(0).toUpperCase()] || []
          ).concat(user))
      );
      newGroupUser = Object.keys(groupBy)
        .map(item => ({
          title: item.charAt(0).toUpperCase(),
          userGroup: groupBy[item].sort((a, b) =>
            sortBy(a.displayName, b.displayName)
          )
        }))
        .sort((a, b) => sortBy(a.title, b.title));
      return newGroupUser;
    },

    selectedId() {
      return (this.user || {}).uid;
    },

    displayEmail() {
      return user => {
        let email = user.email;
        if (user.customClaims.displayEmail) {
          email = user.customClaims.displayEmail;
        }
        return email;
      };
    }
  },

  methods: {
    ...mapActions("administration/user-list-page/group", [
      "bindGroupList",
      "unbindGroupList"
    ]),
    ...mapActions("administration/user-list-page/user", [
      "getUserList",
      "getQBUserList",
      "resetUser",
      "getUser",
      "removeUserList"
    ]),
    ...mapActions("administration/app-constant", [
      "bindConstantTypeList",
      "unbindConstantTypeList"
    ]),
    ...mapActions({
      setDestroyFlag: "administration/user-list-page/common/setDestroyFlag"
    }),
    ...mapActions("setting/app/system", ["bindSetting"]),
    ...mapActions("administration/plan", ["bindPlans"]),
    ...mapActions("administration/user-list-page/menu", ["getMenus"]),

    onBtnAddClick() {
      this.$refs.userNewPopup.openPopup({ isNew: true });
      if (
        this.currentPlan.limitUsers !== null &&
        this.numberOfUsers + 1 > this.currentPlan.limitUsers
      ) {
        this.showUpgradeSubscriptionMessage(this.numberOfUsers);
        return;
      }
      // this.popupOpened = true;
    },

    showUpgradeSubscriptionMessage(number) {
      this.$ri.dialog.openInfoDialog({
        title: "Upgrade your subscription",
        content: `You cannot create more than ${number} users with current plan. Please go to subscription to upgrade to higher plan.`,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7router.navigate(`/administration/subscription`, {
              pushState: true,
              reloadAll: true
            });
          }
        }
      });
    },
    onlineClass(status) {
      return status == true
        ? { color: "", text: "Inactive" }
        : status === false
        ? { color: "green", text: "Active" }
        : { color: "yellow", text: "Pending" };
    },

    selectListIndex(val) {
      return val;
    },

    onSearch(event) {
      this.searchValue = event.target.value;
    }
  },

  created() {
    this.setDestroyFlag(false);
    const refs = [];
    this.$f7.preloader.show();
    refs.push(this.getUserList());
    refs.push(this.bindConstantTypeList());
    refs.push(this.bindGroupList());
    refs.push(this.getQBUserList());
    refs.push(this.bindPlans());
    refs.push(this.bindSetting(this.tenantId));
    refs.push(this.getMenus(this.$f7router.routes));
    Promise.all(refs).finally(() => {
      this.setDestroyFlag(true);
      this.$f7.preloader.hide();
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.unbindConstantTypeList();
      this.unbindGroupList();
      this.removeUserList();
      this.resetUser();
    }
  }
};
</script>
