<template>
  <div
    :class="
      `data-table data-table-init card${
        dataTableCollapsible ? ' data-table-collapsible' : ''
      }`
    "
  >
    <!-- Custom header -->
    <slot name="card-header"></slot>
    <!-- Table -->
    <div
      class="card-content"
      :class="
        `${dataTable.length === 0 ? 'table-no-data' : ''} ${
          isPinHeader ? 'full-data-table' : ''
        }`
      "
      :style="`width: ${lengthWidth || '100%'}`"
    >
      <table>
        <!-- Header Table -->
        <thead v-if="$scopedSlots['header']">
          <th
            v-for="(column, index) in headers"
            :key="index"
            :class="
              `${isPinHeader && 'th-sticky-container'} ${textAlign(
                column.align
              )} ${column.cssClass || ''}`
            "
          >
            <slot name="header" :column="column"></slot>
          </th>
        </thead>
        <f7-header-table
          v-if="!$scopedSlots['header']"
          :headers="headers"
          :checkbox="checkbox"
          :indeterminate="indeterminate"
          :checked="checked"
          @sortColumn="sortColumn"
          @selectCheckbox="selectCheckbox($event, dataTable)"
          :isPinHeader="isPinHeader"
        />

        <!-- Body Table -->

        <!-- Custom Only row -->
        <tbody
          v-if="
            $scopedSlots[`body.${nameSlotItem($scopedSlots)}`] &&
              dataTable.length > 0
          "
        >
          <tr v-for="(row, index) in displayDataTable" :key="index">
            <td v-if="checkbox">
              <f7-checkbox
                :checked="row.checked"
                @change="
                  selectCheckbox(
                    { type: 'header', checked: $event.target.checked },
                    row
                  )
                "
              ></f7-checkbox>
            </td>
            <template v-for="header in headers">
              <td
                :key="header.value"
                v-if="!$scopedSlots['body.' + header.value]"
              >
                {{ row[header.value] }}
              </td>
              <td v-else :key="header.value">
                <slot :name="`body.${header.value}`" :item="row" />
              </td>
            </template>
          </tr>
        </tbody>

        <!-- Custom multiple row -->
        <tbody v-if="$scopedSlots['body'] && dataTable.length > 0">
          <tr
            v-for="(row, index) in displayDataTable"
            :key="index"
            :class="{
              'active-row': isActiveRow && rowIndexSelected === index,
              'noHover': !isHoverRow,
              'cursor-pointer': isHoverRow
            }"
            @click="onClickRowTable(row, index)"
          >
            <td v-if="checkbox">
              <f7-checkbox
                :checked="row.checked"
                @click.native.stop
                @change.native.stop="
                  selectCheckbox(
                    { type: 'body', checked: $event.target.checked },
                    row
                  )
                "
              ></f7-checkbox>
            </td>
            <slot name="body" :item="row" :index="index"></slot>
          </tr>
        </tbody>

        <!-- Default table -->
        <f7-body-table
          v-else-if="
            !$scopedSlots['body'] &&
              !$scopedSlots[`body.${nameSlotItem($scopedSlots)}`] &&
              dataTable.length > 0
          "
          :headers="headers"
          :checkbox="checkbox"
          :items="displayDataTable"
          @selectCheckbox="selectCheckbox"
        />
      </table>
      <!-- No Data -->
      <div class="no-data" v-if="dataTable.length === 0">No Data!</div>

      <!-- Footer Table -->
      <slot
        name="footer"
        v-if="$scopedSlots['footer'] && !isUsePagingFooterDefault"
      ></slot>
      <f7-footer-table
        v-else-if="!$scopedSlots['footer'] && !isUsePagingFooterDefault"
        :footerProps="footerProps"
        :totals="totals"
        :pagination="pagination"
        @changeRowPerPage="changeRowPerPage"
        @paginationNextPrev="paginationNextPrev"
        @onGoToPage="onGoToPage"
      />
    </div>
    <slot v-if="!isUsePagingFooterDefault" name="paging-footer"></slot>
    <f7-footer-table
      v-if="isUsePagingFooterDefault"
      :footerProps="footerProps"
      :totals="totals"
      :pagination="pagination"
      @changeRowPerPage="changeRowPerPage"
      @paginationNextPrev="paginationNextPrev"
      @onGoToPage="onGoToPage"
    />
  </div>
</template>
<script>
import F7HeaderTable from "./F7HeaderTable.vue";
import F7BodyTable from "./F7BodyTable.vue";
import F7FooterTable from "./F7FooterTable.vue";

export default {
  components: {
    F7HeaderTable,
    F7BodyTable,
    F7FooterTable
  },
  props: {
    headers: Array,
    items: Array,
    checkbox: Boolean,
    iconSort: String,
    footerProps: Object,
    totals: Number,
    pageSize: Number,
    lengthWidth: [String, Number],
    color: String,
    textColor: String,
    dataTableCollapsible: Boolean,
    resetPagination: Boolean,
    isUsePagingFooterDefault: {
      type: Boolean,
      default: false
    },
    rowIndexSelectedDefault: {
      type: Number,
      default: null
    },
    isActiveRow: {
      type: Boolean,
      default: false
    },
    isPinHeader: {
      type: Boolean,
      default: false
    },
    isHoverRow: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      checked: false,
      indeterminate: false,
      rowIndexSelected: null,
      pagination: {
        descending: false,
        page: 1,
        pageSize: 10,
        sortBy: "",
        totalItems: 0
      },
      dataTable: [],
      selected: []
    };
  },

  computed: {
    displayDataTable() {
      return this.dataTable.slice(
        this.startIndexItem,
        this.pagination.pageSize + this.startIndexItem
      );
    },
    startIndexItem() {
      return this.pagination.page === 1
        ? 0
        : this.pagination.page * this.pagination.pageSize -
            this.pagination.pageSize;
    }
  },
  methods: {
    onClickRowTable(row, index) {
      // if (this.rowIndexSelected === index && this.$device.desktop) return;

      this.checkbox
        ? this.selectCheckbox({ type: "body", checked: !row.checked }, row)
        : () => {};

      this.$emit("onClickRow", row);
      this.rowIndexSelected = index;
    },
    // Pagination Common
    changePagination(options) {
      this.$emit("pagination", options);
    },

    // Sort
    sortColumn(props) {
      if (props.sortable) {
        this.pagination = {
          ...this.pagination,
          sortBy: props.value,
          descending: !this.pagination.descending
        };
        this.changePagination(this.pagination);
      }
    },

    // Checkbox
    selectCheckbox(obj, values) {
      if (obj.type === "header") {
        // Check the condition of the checkbox
        this.dataTable = this.dataTable.map(x => ({
          ...x,
          checked: obj.checked
        }));
        this.indeterminate = null;
        this.checked = obj.checked;
        // The value is taken to emit outside
        if (obj.checked === true) {
          this.selected = values;
        } else {
          this.selected = [];
        }
        this.dataTable.forEach(x => {
          this.$emit("selected:change", {
            id: x.id,
            checked: obj.checked
          });
        });
      } else if (obj.type === "body") {
        // Check the condition of the checkbox
        this.dataTable = this.dataTable.map(x => {
          if (x.id === values.id) {
            return { ...x, checked: obj.checked };
          }
          return x;
        });
        var everyCheckbox = this.dataTable.map(x => x.checked);
        if (
          everyCheckbox.filter(x => x === true).length === this.dataTable.length
        ) {
          this.checked = true;
          this.indeterminate = false;
        } else if (everyCheckbox.filter(x => x === true).length > 0) {
          this.checked = false;
          this.indeterminate = true;
        } else if (
          everyCheckbox.filter(x => x === false).length ===
          this.dataTable.length
        ) {
          this.checked = false;
          this.indeterminate = null;
        }
        // The value is taken to emit outside
        var checkboxExist = this.selected.some(item => item.id === values.id);
        this.$emit("selected:change", {
          id: values.id,
          checked: !checkboxExist
        });
        if (checkboxExist) {
          this.selected = this.selected.filter(item => item.id !== values.id);
        } else {
          this.selected = [...this.selected, values];
        }
      }
      this.$emit("selected:checkbox", this.selected);
    },

    // Next or Prev Pagination
    paginationNextPrev(type) {
      this.pagination = {
        ...this.pagination,
        totalItems: this.items.length,
        page:
          type === "prevPage" ? --this.pagination.page : ++this.pagination.page
      };
      this.changePagination(this.pagination);
    },

    // Next or Prev Pagination
    onGoToPage(type) {
      this.pagination = {
        ...this.pagination,
        totalItems: this.items.length,
        page:
          type === "first"
            ? 1
            : Math.ceil(this.totals / this.pagination.pageSize)
      };
      this.changePagination(this.pagination);
    },

    // Change Row Per Page
    changeRowPerPage(event) {
      this.changePagination(event);
      this.pagination = event;
    },

    nameSlotItem(scopedSlots) {
      return (this.headers || [])
        .map(header => header.value)
        .find(x => scopedSlots[`body.${x}`]);
    },

    // reset checked
    resetChecked() {
      this.dataTable = this.items.map(x => ({ ...x, checked: false }));
      this.selected = [];
      this.indeterminate = null;
      this.checked = false;
      this.$emit("selected:checkbox", this.selected);
    },

    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : align === "custom"
        ? "text-custom"
        : "text-align-left";
    }
  },
  // updated() {
  //   this.changePagination(this.pagination);
  // },
  created() {
    this.dataTable =
      this.items.map(x => ({
        ...x,
        checked: x && x.checked ? x.checked : false
      })) || [];
    this.dataTable
      .filter(r => r.checked)
      .forEach(v => {
        this.selectCheckbox(
          {
            type: "body",
            checked: true
          },
          { ...v }
        );
      });
  },

  watch: {
    pageSize: {
      handler(val) {
        this.pagination = {
          ...this.pagination,
          pageSize: val,
          totalItems: this.items.length
        };
      },
      immediate: true,
      deep: true
    },
    footerProps(val) {
      this.pagination = {
        ...this.pagination,
        pageSize:
          val && val.pageSizeOption && !this.pageSize
            ? val.pageSizeOption[0]
            : this.pageSize,
        totalItems: this.items.length
      };
    },
    items(val) {
      if (
        val &&
        val.length !== this.dataTable.length &&
        this.pagination.page > 1
      ) {
        this.pagination = {
          ...this.pagination,
          page: 1,
          pageSize:
            this.pagination && this.pagination.pageSizeOption && !this.pageSize
              ? this.pagination.pageSizeOption[0]
              : this.pageSize,
          totalItems: this.items.length
        };
      }
      this.dataTable = (val || []).map(x => ({
        ...x,
        checked: x && x.checked ? x.checked : false
      }));
      this.resetChecked();
      val
        .filter(r => r && r.checked)
        .forEach(v => {
          this.selectCheckbox(
            {
              type: "body",
              checked: true
            },
            { ...v }
          );
        });
      this.rowIndexSelected = this.rowIndexSelectedDefault;
    },
    resetPagination(val) {
      if (val) {
        this.pagination = {
          descending: false,
          page: 1,
          pageSize: 10,
          sortBy: "",
          totalItems: 0
        };
      }
    },

    rowIndexSelectedDefault(val) {
      if (val !== undefined && val !== null) {
        this.rowIndexSelected = val;
      }
    }
  }
};
</script>
<style scoped>
.no-data {
  border-top-width: 1px;
  border-top-color: #e5e5e5;
  border-top-style: solid;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: grey;
}
.not-data {
  font-size: 14px;
  color: grey;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section-name {
  font-size: 15px;
  font-weight: bold;
  align-items: center;
}
.table-no-data table {
  table-layout: fixed;
}
.th-sticky-container {
  position: sticky;
  top: 0;
  background-color: var(--f7-color-bg-4-neutral);
  z-index: 20;
}
.table-head-label {
  color: var(--f7-color-text-neutral);
}
.full-data-table {
  height: calc(100vh - 382px);
  overflow-y: auto;
}
.active-row {
  background-color: var(--f7-color-bg-select-neutral);
}
.th-sticky-container::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: var(--f7-color-bg-select-neutral) 1px solid;
}
.noHover{
  background-color: transparent !important;
}
</style>
