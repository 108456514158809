<template>
  <div>
    <data-table
      :headers="headers"
      :items="filteredList"
      :pageSize="filteredList.length"
    >
      <template slot="card-header">
        <f7-row class="align-items-center">
          <f7-searchbar
            class="flex-1 search-list-detail"
            placeholder="Search in sub categories"
            :clear-button="true"
            disable-button-text
            :value="searchText"
            @input="searchText = $event.target.value"
            @searchbar:disable="searchText = ''"
          >
            <f7-button
              slot="inner-end"
              @click="popupAddNew = true"
              fill
              class="add-button"
            >
              <f7-icon f7="plus" size="20"></f7-icon>
              Sub category
            </f7-button></f7-searchbar
          >
        </f7-row>
      </template>
      <template v-slot:body="{ item }">
        <td>
          {{ item.name }}
        </td>
        <td>
          {{ item.productItemFunctionMap || "" }}
        </td>
        <td>
          {{ item.measureFunctionMap || "" }}
        </td>
        <td>
          <div
            style="padding: 5px 0; display: flex;justify-content: center; gap: 15px;"
          >
            <a href="#" @click.stop="onEdit(item)">
              <f7-icon color="blue" size="20" f7="pencil"></f7-icon>
            </a>
            <a href="#" @click.stop="onDelete(item.id)">
              <f7-icon color="red" size="20" f7="trash"></f7-icon>
            </a>
          </div>
        </td>
      </template>
    </data-table>
    <add-new-sub-category-popup
      :isShow="popupAddNew"
      @close="popupAddNew = false"
      :category-id="categoryId"
    />
    <edit-sub-category-popup ref="editPopup" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/datatables";
import { applyFilter } from "@/utility/filter-tools";
import AddNewSubCategoryPopup from "../popup/AddNewSubCategoryPopup.vue";
import EditSubCategoryPopup from "../popup/EditSubCategoryPopup.vue";

export default {
  data() {
    return {
      searchText: "",
      categoryId: "",
      popupAddNew: false
    };
  },
  components: {
    DataTable,
    AddNewSubCategoryPopup,
    EditSubCategoryPopup
  },
  methods: {
    ...mapActions({
      bindByCategory: "product/category-page/sub-category/bindByCategory",
      delete: "product/category-page/sub-category/delete",
      update: "product/category-page/sub-category/update"
    }),
    onDelete(id) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete sub category",
        content: "Are you sure you want to delete this sub category?",
        textButton: "Delete",
        // hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.delete(id);
          }
        }
      });
    },
    async onEdit(object) {
      const editResult = await this.$refs.editPopup.doEdit(object);
      if (!editResult) return;
      const { name, measureFunctionMap, productItemFunctionMap } = editResult;
      await this.update({
        id: object.id,
        doc: {
          name,
          measureFunctionMap,
          productItemFunctionMap
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      list: "product/category-page/sub-category/objectList"
    }),
    filteredList() {
      return applyFilter([...this.list], this.searchText, [
        "name",
        "productItemFunctionMap",
        "measureFunctionMap"
      ]);
    },
    headers() {
      return [
        {
          text: "Name",
          value: "name",
          sortable: false,
          align: "left"
        },
        {
          text: "Product Item Calculation Method",
          value: "productItemFunctionMap",
          align: "left"
        },
        {
          text: "Measurement Calculation Method",
          value: "measureFunctionMap",
          align: "left"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: "150px"
        }
      ];
    }
  },
  created() {
    this.categoryId = this.$f7route.params.categoryId;
    this.bindByCategory(this.categoryId);
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-detail.searchbar input").focus();
      }
    });
  }
};
</script>

<style scoped>
.flex-1 {
  flex: 1;
}
.add-button {
  min-width: 150px;
  margin-left: 10px;
}
</style>
