<template>
  <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false" @popup:opened="handlePopupOpened">
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Contact</f7-nav-title>
        <f7-nav-right>
          <f7-link icon-f7="plus" @click.native="onAdd"></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar disable-button-text placeholder="Search contact" :clear-button="true" :value="searchValue"
        @input="searchValue = $event.target.value" @searchbar:disable="searchValue = ''"
        class="search-list-popup"></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-group v-for="(group, index) in contactGroup(searchValue)" :key="index">
          <f7-list-item :title="Object.keys(group)[0]" group-title></f7-list-item>
          <f7-list-item no-chevron link v-for="contact in contactGroup(searchValue)[index][
            Object.keys(group)[0]
          ]" :key="contact.id" :title="contact.firstName + ' ' + contact.lastName"
            @click.native="select(contact)"></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>

    <f7-page v-show="isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="onAddCancel">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>New Contact</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="create">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- Contact Name -->
      <f7-list>
        <f7-list-input class="first-input" type="text" label="First name" placeholder="First name" clear-button
          :value="currentContact.firstName" @input="set('firstName', $event.target.value.trim())" error-message-force
          :error-message="firstNameErrorMessage" @blur="v$.$touch()"><required-asterisk
            slot="label"></required-asterisk></f7-list-input>
        <f7-list-input type="text" label="Last name" placeholder="Last name" clear-button
          :value="currentContact.lastName" @input="set('lastName', $event.target.value.trim())" error-message-force
          :error-message="lastNameErrorMessage" @blur="v$.$touch()"><required-asterisk
            slot="label"></required-asterisk></f7-list-input>
      </f7-list>

      <!-- Phone -->
      <phone-input :id="currentContact.id" ref="phoneInput" isRequired :value="currentContact.phones"
        @input="setPhones($event)"></phone-input>
      <!-- Email -->
      <email-input :id="currentContact.id" ref="emailInput" :value="currentContact.emails"
        @input="setEmails($event)"></email-input>

      <!-- Address -->
      <address-auto-complete-input :id="currentContact.id" ref="addressInput" :value="currentContact.addresses"
        @input="setAddresses($event)"></address-auto-complete-input>

      <!-- Delete button -->
      <f7-list v-if="!isNew">
        <f7-list-button color="red" @click.native="del">Delete Contact</f7-list-button>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import EmailInput from "@/components/inputs/EmailInput.vue";
import AddressAutoCompleteInput from "@/components/inputs/AddressAutoCompleteInput.vue";

import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import Vue from "vue";
import _ from "lodash";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: { PhoneInput, EmailInput, AddressAutoCompleteInput },

  data: () => {
    return {
      popupOpened: false,
      value: "",
      isNew: false,
      currentContact: {},
      searchValue: ""
    };
  },

  computed: {
    ...mapGetters("invoices/contact", ["contactList", "contactGroup"]),

    firstNameErrorMessage() {
      if (!this.v$.currentContact.firstName.$error) return null;
      if (this.v$.currentContact.firstName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },

    lastNameErrorMessage() {
      if (!this.v$.currentContact.lastName.$error) return null;
      if (this.v$.currentContact.lastName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    ...mapActions("invoices/contact", ["createContact", "bindContactList"]),

    set(prop, value) {
      Vue.set(this.currentContact, prop, value);
    },
    initData() {
      if (_.isEmpty(this.contactList)) {
        this.$f7.preloader.show();
        this.bindContactList().then(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    open() {
      this.initData();
      this.popupOpened = true;
    },

    onDone() {
      this.$emit("done");
      this.popupOpened = false;
    },

    onAdd() {
      this.isNew = true;
      this.$nextTick(() => {
        if (this.$device.desktop) {
          this.$el.querySelector(".first-input input").focus();
        }
      });
    },

    onAddCancel() {
      this.isNew = false;
      this.currentContact = {};
    },

    select(val) {
      this.$emit("onSelected", val);
      this.popupOpened = false;
    },

    setPhones(val) {
      Vue.set(this.currentContact, "phones", val);
    },

    setEmails(val) {
      Vue.set(this.currentContact, "emails", val);
    },

    setAddresses(val) {
      Vue.set(this.currentContact, "addresses", val);
    },

    create() {
      const self = this;
      this.v$.$touch();
      this.$refs.phoneInput.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      this.$refs.emailInput.v$.$touch();
      if (
        this.$refs.phoneInput.v$.$invalid ||
        this.$refs.addressInput.v$.$invalid ||
        this.$refs.emailInput.v$.$invalid ||
        this.v$.$invalid
      )
        return;

      this.$f7.preloader.show();
      this.createContact(this.currentContact).then(val => {
        self.$emit("onSelected", { ...this.currentContact, id: val });
        self.popupOpened = false;
        self.isNew = false;
        self.currentContact = {};

        self.$f7.preloader.hide();
      });
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentContact: {
        firstName: {
          required
        },

        lastName: {
          required
        }
      }
    };
  }
};
</script>

<style scoped></style>
