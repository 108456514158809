export default {
  timeLogList: [],
  timeLogSubscribe: null,
  purchaseOrderList: [],
  purchaseOrderSubscribe: null,
  expenseList: [],
  expenseSubscribe: null,
  estimateList: [],
  estimateSubscribe: null,
  buildings: [],
  invoiceList: [],
  invoiceSubscribe: null,
  invoiceGroupList: [],
  recurringInvoiceList: [],
};
