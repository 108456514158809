<template>
  <div class="margin-vertical-half">
    <div v-if="$device.desktop" class="display-flex justify-content-flex-end">
      <div v-for="response in responses" :key="response.id">
        <template v-if="response.conditionToDisplay">
          <f7-button class="action-button margin-right-half"
            v-if="response.conditionToDisplay === conditionToDisplayResponseBtn" raised fill
            @click="$emit('doAction', response)">{{ response.title }}</f7-button>
        </template>
        <template v-else>
          <f7-button class="action-button margin-right-half" raised fill @click="$emit('doAction', response)">{{
            response.title }}</f7-button>
        </template>
      </div>
      <f7-button v-if="isShareLink" class="action-button margin-right-half" raised outline
        popover-open=".popover-share-link">
        Share Link
      </f7-button>
    </div>
    <div v-else>
      <f7-block>
        <f7-row tag="p" v-for="response in responses" :key="response.id">
          <f7-col tag="span">
            <template v-if="response.conditionToDisplay">
              <f7-button v-if="
                response.conditionToDisplay === conditionToDisplayResponseBtn
              " raised fill @click="$emit('doAction', response)">{{ response.title }}</f7-button>
            </template>
            <template v-else>
              <f7-button raised fill @click="$emit('doAction', response)">{{
                response.title
              }}</f7-button>
            </template>
          </f7-col>
        </f7-row>
        <f7-row tag="p">
          <f7-col>
            <f7-button raised outline popover-open=".popover-share-link">Share Link</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div style="height: 1px;"></div>
    <f7-popover class="popover-share-link">
      <f7-list>
        <f7-list-item title="Copy link" link popover-close @click="copyLink"></f7-list-item>
        <f7-list-item title="Send by email" link popover-close @click="sendLinkToJobCard(card)"></f7-list-item>
      </f7-list>
    </f7-popover>
    <send-email-popup ref="sendEmailPopup" popup-title="Send By Email" templateName="link-to-job-card"
      :projectId="card && (card.id || '')" :attachments="card && (card.attachmentFiles || [])"
      :listEmails="listEmails"></send-email-popup>
  </div>
</template>

<script>
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import { mapGetters } from "vuex";
import axiosService from "@/services/axios.service";
import { BUSINESS_CODE_COMMERCIAL } from "@/utility/const";
import sendEmail from "../../mixin/sendEmail";

export default {
  mixins: [sendEmail],
  components: {
    SendEmailPopup
  },
  props: {
    isShareLink: {
      type: Boolean,
      default: true
    },
    responses: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      BUSINESS_CODE_COMMERCIAL
    };
  },

  computed: {
    ...mapGetters("dashboard/project", [
      "conditionToDisplayResponseBtn",
      "card"
    ]),
    ...mapGetters("dashboard/client", ["listEmails"]),

    numberOfResponse() {
      return this.responses.length;
    },
    ...mapGetters('common/app-constant', ['tenantId']),

  },

  methods: {
    async generateCardWebUrl() {
      const response = await axiosService.post("/tenant/get-anonymous-token", {
        entity: "project",
        entityId: this.card.id
      });
      if (response && response.status === 200) {
        return `${import.meta.env.VITE_HOST_DOMAIN}/${this.card.businessCode
          }-card-view/?token=${response.data}&tenant=${btoa(this.tenantId)}&id=${this.card.id
          }`;
      }
      return "";
    },
    async copyLink(event) {
      const url = await this.generateCardWebUrl();
      event.preventDefault();
      const tempInput = document.createElement("input");
      tempInput.value = url;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.showToast("Copied!");
    },
    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    }
  }
};
</script>

<style lang="scss" scoped>
.action-button {
  min-width: 120px;
}
</style>
