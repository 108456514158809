<template>
  <f7-page v-show="isExistedEstimate" class="estimate">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-show="!this.$device.desktop || hasProject"
          @click="handleBack"
          icon-f7="chevron_left"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ estimate ? estimate.estimateNumber : "" }}</f7-nav-title>
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>

    <f7-row style="justify-content: flex-end; padding: 10px 10px 0px 0px;">
      <f7-button
        raised
        fill
        style="min-width: 120px"
        class="margin-right-half"
        @click="pricingRefresh(estimate.id)"
      >
        Pricing Refresh
      </f7-button>
      <f7-button
        raised
        fill
        style="min-width: 120px"
        @click="copyEstimate(estimate)"
      >
        Copy Estimate
      </f7-button>
      <f7-button
        v-if="this.$device.desktop && estimate.status != 'active'"
        raised
        fill
        class="margin-left-half"
        style="min-width: 120px"
        @click.native="auditEstimate()"
      >
        Audit Estimate
      </f7-button>
    </f7-row>
    <f7-row style="justify-content: flex-end; padding: 10px 10px 0px 0px;">
      <f7-button
        v-if="!this.$device.desktop && estimate.status != 'active'"
        raised
        fill
        class="margin-left-half"
        style="min-width: 120px"
        @click.native="auditEstimate()"
      >
        Audit Estimate
      </f7-button>
    </f7-row>

    <estimate-info-form
      :style="$device.desktop ? '' : 'margin-bottom: 100px;'"
      :currentEstimate="estimate"
      @changeResalesCertificate="
        changeProjectProp('resalesCertificate', $event)
      "
      @changeTaxExemption="changeProjectProp('taxExempt', $event)"
      @changeRidgeven="
        updateEstimateField('ridgeVent', $event).then(() => {
          showSavedMessage();
        })
      "
      @changeStatus="
        updateEstimateField('status', $event).then(() => {
          showSavedMessage();
        })
      "
      @changeEstimateName="updateEstimateField('estimateName', $event)"
      @changeRoofType="updateEstimateField('roofType', $event)"
      @changeDescription="updateEstimateField('description', $event)"
      @changeTaxApplyType="handleChangeTaxApplyType"
      @changeSaleTax="handleChangeTax"
      @changeMiscPercent="updateEstimateField('miscPercent', $event)"
      @changeProfitMargin="
        updateEstimateField('crossProfitMarginRatio', $event)
      "
      :isEditing="true"
    >
    </estimate-info-form>

    <div v-if="estimateHistoryList.length > 0">
      <f7-block-title>History</f7-block-title>
      <history-view></history-view>
    </div>
    <copy-estimate-popup ref="copyEstimatePopup" />
    <!-- <StandingSeamEst
      v-if="currentBuilding.roofType === ROOF_TYPE_STANDING_SEAM"
      :isEditing="isEditing"
      @toggleEditting="toggleEditting"
      @updateDataEst="updateDataEst"
      @removeRowEst="removeRowEst"
    /> -->
    <!-- <form id="novalidatedform" novalidate /> -->
    <update-pricing-popup ref="updatePricingPopup"></update-pricing-popup>
  </f7-page>
</template>

<script>
import EstimateInfoForm from "../components/forms/EstimateInfoForm.vue";
import CopyEstimatePopup from "../components/popup/CopyEstimatePopup.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import mixin from "../utility/mixins";
import buidingMixin from "../utility/building-mixins";
import UpdatePricingPopup from "../components/popup/UpdatePricingPopup.vue";
import HistoryView from "../components/details/HistoryView.vue";
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  components: {
    EstimateInfoForm,
    CopyEstimatePopup,
    UpdatePricingPopup,
    HistoryView
  },
  mixins: [mixin, buidingMixin],

  async created() {
    this.$f7.preloader.show();
    const params = this.$f7route.params;
    if (params.estimateNumber && !params.clientType && !params.clientId) {
      this.setCurrentView("estimate-list");
    } else {
      this.setCurrentView("client");
    }

    // Bind current estimate by Number
    const estimate = this.estimateByNumber(params.estimateNumber);
    const promises = [];
    if (!_.isEmpty(estimate)) {
      promises.push(this.initEstimate(estimate));
    } else {
      const estimates = await this.getEstimateBys([
        {
          prop: "estimateNumber",
          val: params.estimateNumber,
          op: "=="
        }
      ]);
      promises.push(this.initEstimate(estimates[0]));
    }

    if (_.isEmpty(this.vendorList)) {
      promises.push(this.getVendors());
    }
    Promise.all(promises).finally(() => this.$f7.preloader.hide());
  },

  watch: {
    estimate(newVal, old) {
      if (!newVal) return;
      if (old && newVal.id === old.id) return;
    }
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "estimateList",
      "estimateByNumber",
      // "estimateById",
      "buildingList",
      "estimateHistoryList",
      "hits"
    ]),
    ...mapGetters("estimate/estimate-page/product-item", ["productList"]),
    ...mapGetters("estimate/estimate-page/project", ["projectList", "project"]),
    ...mapGetters("estimate/estimate-page/common", ["currentView"]),
    ...mapGetters({ vendorList: "estimate/estimate-page/vendor/objectList" }),
    ...mapGetters("setting/app/profile", ["user"]),
    hasProject() {
      return this.$f7route.params.projectId && this.$f7route.params.actionId;
    },
    isExistedEstimate() {
      return this.estimate.isDeleted === false;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/project", [
      "getProject",
      "bindProjectListBy"
    ]),
    ...mapActions("estimate/estimate-page/property", [
      "bindProperty",
      "unbindProperty"
    ]),

    ...mapActions("estimate/estimate-page/estimate", [
      "bindEstimate",
      "createNewEstimate",
      "updateEstimate",
      "bindEstimateListBy",
      "getEstimateBys",
      "unbindEstimate",
      "getBuildingList",
      "createBuilding",
      "updateBuilding",
      "bindEstimateHistoryListBys",
      "bindBuildingListBys"
    ]),

    ...mapActions("estimate/estimate-page/price-list", ["getPriceListById"]),

    ...mapMutations("estimate/estimate-page/estimate", [
      "setEstIdLink",
      "patchData",
      "cleanUpEditingData"
    ]),

    ...mapActions({ getVendors: "estimate/estimate-page/vendor/getVendors" }),

    ...mapActions("estimate/estimate-page/project", ["updateProject"]),
    ...mapActions("estimate/estimate-page/common", ["setCurrentView"]),

    handleBack() {
      this.handleBackAction();
    },

    async pricingRefresh(estimateId) {
      this.$f7.preloader.show();
      const contentUpdate = [];
      const buildings = await this.getBuildingList({ estimateId: estimateId });
      const sortBuilding = buildings.sort((a, b) =>
        sortBy(a.buildingName, b.buildingName)
      );
      for (const building of sortBuilding) {
        if (_.isEmpty(building.productData)) {
          continue;
        }

        // get price list for building
        let priceList = [];
        if (building.priceListId) {
          priceList = await this.getPriceListById(building.priceListId);
        }
        const productOfPriceList = priceList.productRefs || [];

        if (_.isEmpty(priceList)) {
          continue;
        }
        let productsOfBuilding = [];
        (building.productData || []).forEach(section => {
          if (!_.isEmpty(section.productList)) {
            const products = section.productList.map(product => ({
              ...product,
              sectionId: section.sectionId
            }));
            productsOfBuilding = productsOfBuilding.concat(products);
          }
        });

        const productChangePrice = [];
        productsOfBuilding.forEach(r => {
          const product = productOfPriceList.find(
            i => i.productId === r.id && i.price !== r.price
          );
          if (!_.isEmpty(product)) {
            productChangePrice.push({
              ...r,
              newMarkup: product.markup,
              newVendorPrice: product.vendorPrice,
              newPrice: product.price,
              checked: true
            });
          }
        });

        if (_.isEmpty(productChangePrice)) {
          continue;
        }

        contentUpdate.push({
          buildingId: building.id,
          buildingName: building.buildingName,
          title: "",
          productChangePrice,
          building
        });
      }
      this.$f7.preloader.hide();
      this.showDialogComfirm(contentUpdate);
    },

    showDialogComfirm(contentUpdate) {
      if (!contentUpdate.length) {
        this.$ri.dialog.openInfoDialog({
          title: "Pricing Refresh",
          content: "There is no pricing change impacting the estimate.",
          // textButton: "Delete",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
      } else {
        // show popup update
        this.$refs.updatePricingPopup.openPopup(contentUpdate);
      }
    },

    async copyEstimate(currentEstimate) {
      const newEstimateInfo = await this.$refs.copyEstimatePopup.openPopup({
        businessCode: currentEstimate.businessCode
      });
      if (!newEstimateInfo) return;
      this.$f7.preloader.show();
      let estimate = _.cloneDeep(currentEstimate);

      const doc = {
        ...estimate,
        ...newEstimateInfo,
        buildings: [],
        id: null
      };
      const estimateCreated = await this.createNewEstimate(doc);
      //get building from old est
      const buildings = await this.getBuildingList({ estimateId: estimate.id });
      const buildingList = [];

      for (const buidling of buildings) {
        const buidlingRef = await this.createBuilding({
          estimateId: estimateCreated.id,
          building: {
            ...buidling,
            estimateId: estimateCreated.id,
            id: null
          }
        });

        buildingList.push(buidlingRef);
      }

      const newBuildingList = estimate.buildings;
      buildingList.forEach(building => {
        const index = newBuildingList.findIndex(
          r => r.buildingName == building.buildingName
        );
        if (index > -1) newBuildingList[index].buildingId = building.buildingId;
      });
      //update created building list.
      await this.updateEstimate({
        id: estimateCreated.id,
        doc: {
          buildings: newBuildingList,
          numberOfBuildings: newBuildingList.length
        }
      });

      this.$f7.preloader.hide();
      if (this.currentView === "estimate-list") {
        this.$f7router.navigate(`/estimate/${estimateCreated.estimateNumber}`, {
          pushState: true
        });
      } else {
        this.$f7router.navigate(
          `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${estimateCreated.estimateNumber}`,
          {
            pushState: true
          }
        );
      }
    },
    // Update the value for the row of the table
    updateDataEst(val, sectionId, type) {
      if (val.length) {
        val.forEach(v => {
          this.patchData({
            sectionId,
            itemId: v.id,
            operator: type,
            value: v
          });
        });
      } else {
        this.patchData({
          sectionId,
          itemId: val.rowId,
          operator: type,
          value: val
        });
      }
    },

    // Remove
    removeRowEst(val, type) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete item Estimates",
        content: "Are you sure you want to delete estimates from the table?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.updateDataEst(val, val.sectionId, type);
            _sefl.app.dialog.close();
          }
        }
      });
    },

    initEstimate(estimate) {
      const refs = [];
      refs.push(this.bindEstimate(estimate.id));
      refs.push(
        this.bindBuildingListBys({
          estimateId: estimate.id,
          conditions: [
            {
              prop: "priceUpdatedAt",
              val: "",
              op: "!="
            }
          ]
        })
      );
      // // bind estimate history
      refs.push(
        this.bindEstimateHistoryListBys({
          estimateId: estimate.id,
          conditions: []
        })
      );

      if (estimate.projectId) {
        refs.push(this.getProject(estimate.projectId));
      }

      // if (estimate.propertyId) {
      //   refs.push(this.bindProperty(estimate.propertyId));
      // }

      return Promise.all(refs).then(() => {
        this.setEstIdLink(estimate.estimateNumber);

        if (
          !_.isEmpty(this.estimate) &&
          _.isEmpty(this.estimate.businessCode)
        ) {
          this.$ri.dialog.openInfoDialog({
            title: "Deprecated Estimates",
            content:
              "This is an old estimate. Continuing to operate on it may cause some errors. Please create a new one!",
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            }
          });
        }
      });
    },

    auditEstimate() {
      // console.log(this.currentEst.status);
      if (this.estimate.status !== "active") {
        this.$ri.dialog.openWarningDialog({
          title: "Audit Estimate",
          content: "Do you want to update the estimate status to Active?",
          // hideCancelButton: true,
          // textButton: "Delete",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.updateEstimateField("status", "active");
            }
          }
        });
      }
    },

    changeProjectProp(fieldName, value) {
      this.updateProject({
        id: this.estimate.projectId,
        doc: {
          [fieldName]: value
        }
      }).then(() => {
        this.showSavedMessage();
      });
    },

    updateEstimateField(fieldName, value) {
      return this.updateEstimate({
        id: this.estimate.id,
        doc: {
          [fieldName]: value
        }
      }).then(() => {
        this.showSavedMessage();
      });
    },

    showSavedMessage() {
      this.$f7.toast
        .create({
          text: "Estimate is saved!",
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 2000
        })
        .open();
    },

    async handleChangeTaxApplyType(event) {
      await this.updateEstimateField("taxApplyType", event);

      //update tax for all buildings
      const buildings = this.estimate.buildings || [];
      for (const building of buildings) {
        await this.updateBuilding({
          estimateId: this.estimate.id,
          buildingId: building.buildingId,
          building: {
            taxApplyType: event
          }
        });
      }
    },
    async handleChangeTax(event) {
      await this.updateEstimateField("saleTax", event);

      //update tax for all buildings
      const buildings = this.estimate.buildings || [];
      for (const building of buildings) {
        await this.updateBuilding({
          estimateId: this.estimate.id,
          buildingId: building.buildingId,
          building: {
            saleTax: event
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scope>
.estimate ::v-deep input.input-with-value {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wide-dialog {
  width: 800px !important;
  margin-left: calc(-1 * calc(800px) / 2);

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}
</style>
