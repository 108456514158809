<template>
  <f7-popup class="demo-popup" :opened="isShow" @popup:closed="cancel" @popup:opened="handlePopupOpened">
    <f7-page>
      <form @submit.prevent="add">
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Add new sub category</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="add()">Add</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>Category name <required-asterisk /></f7-block-title>
        <f7-list>
          <f7-list-input class="first-input" placeholder="Input sub category name" :value="object.name"
            @input="object.name = $event.target.value.trim()" error-message-force clear-button
            :error-message="nameErrorMessage"></f7-list-input>
        </f7-list>

        <f7-block-title>Calculation Methods</f7-block-title>
        <f7-list>
          <f7-list-input label="Product Item Calculation Method" placeholder="Enter Product Item Calculation Method"
            :value="object.productItemFunctionMap" @input="object.productItemFunctionMap = $event.target.value.trim()"
            clear-button></f7-list-input>

          <f7-list-input label="Measurement Calculation Method" placeholder="Enter Measurement Calculation Method"
            :value="object.measureFunctionMap" @input="object.measureFunctionMap = $event.target.value.trim()"
            clear-button></f7-list-input>
        </f7-list>
      </form>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import {
  CALCULATION_NO_METHOD,
  CALCULATION_PRODUCT_ITEM_COMMON,
  VALIDATION_MESSAGE
} from "@/utility/const";

export default {
  components: {},
  props: {
    isShow: Boolean,
    categoryId: { type: String, default: () => null }
  },
  data: () => ({
    product: [],
    object: {
      name: "",
      measureFunctionMap: CALCULATION_NO_METHOD,
      productItemFunctionMap: CALCULATION_PRODUCT_ITEM_COMMON
    }
  }),
  methods: {
    ...mapActions({
      create: "product/category-page/sub-category/create"
    }),
    cancel() {
      this.object = {
        name: "",
        measureFunctionMap: "",
        productItemFunctionMap: ""
      };
      this.v$.$reset();
      this.$emit("close");
    },

    async add() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      var { name, measureFunctionMap, productItemFunctionMap } = this.object;
      this.cancel();

      var data = {
        name,
        categoryId: this.categoryId,
        measureFunctionMap,
        productItemFunctionMap
      };
      await this.create(data);
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".first-input input").focus();
      }
    }
  },

  computed: {
    nameErrorMessage() {
      if (!this.v$.object.name.$error) return null;
      if (this.v$.object.name.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      object: {
        name: {
          required
        }
      }
    };
  }
};
</script>
