<template>
  <f7-row>
    <f7-col v-for="(item, index) in currentFinancingProposal" :key="index" :width="$device.desktop ? 33 : 100">
      <f7-card>
        <f7-card-header class="justify-content-center">
          <span v-if="isReadonly"><b>{{ item.name }}</b></span>
          <f7-list v-else>
            <f7-list-input class="input-title" placeholder="Enter Option Name" validate error-message-force
              :error-message="nameErrorMessage(index)" @blur="v$.$touch()" :value="item.name" type="text" @change="
                item.name = $event.target.value;
              changeField();
              ">
            </f7-list-input>
          </f7-list>
        </f7-card-header>
        <f7-card-content>
          <f7-row class="margin-bottom-half">
            <f7-col class="property">
              Project Cost
            </f7-col>
            <f7-col class="text-align-end">
              {{ item.projectCost | currencyUSD }}
            </f7-col>
          </f7-row>
          <f7-row class="margin-bottom-half">
            <f7-col class="property">
              Term (months)
            </f7-col>
            <f7-col class="text-align-end">
              <span v-if="isReadonly">{{ item.term }}</span>
              <cell-number-input v-else class="custom-input" :value="item.term" :fractionDigits="1" numberType="int"
                @done="
                  item.term = $event > 0 ? $event : 1;
                changeField();
                "></cell-number-input>
            </f7-col>
          </f7-row>
          <f7-row class="margin-bottom-half">
            <f7-col class="property">
              Down Payment
            </f7-col>
            <f7-col class="text-align-end">
              <span v-if="isReadonly">{{
                item.downPayment | currencyUSD
              }}</span>
              <input-price v-else class="custom-input" :price="Number(item.downPayment)" @change="
                item.downPayment = $event;
              changeField();
              " />
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col class="property">
              Interest Rate (%)
            </f7-col>
            <f7-col class="text-align-end">
              <span v-if="isReadonly">{{ item.interestRate | percent }}</span>
              <cell-number-input v-else class="custom-input" :value="item.interestRate" :fractionDigits="0"
                numberType="percent" @done="
                  item.interestRate = $event >= 0 ? $event : 0;
                changeField();
                "></cell-number-input>
            </f7-col>
          </f7-row>
        </f7-card-content>
        <f7-card-footer class="display-block">
          <f7-row class="monthly-payment">
            <f7-col>
              Monthly Payment ($)
            </f7-col>
            <f7-col class="text-align-end">
              {{ item.monthlyPayment | currencyUSD }}
            </f7-col>
          </f7-row>
        </f7-card-footer>
      </f7-card>
    </f7-col>
  </f7-row>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from "@vuelidate/validators";
import _ from "lodash";
import InputPrice from "../../../../components/inputs/InputPrice.vue";
import CellNumberInput from "../../../../components/inputs/CellNumberInput.vue";
import { VALIDATION_MESSAGE } from '@/utility/const';
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default {
  components: { InputPrice, CellNumberInput },
  props: {
    dataTable: Array,
    isReadonly: { type: Boolean, default: false }
  },
  data() {
    return {
      currentFinancingProposal: []
    };
  },
  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    }),
    nameErrorMessage() {
      return index => {
        if (this.v$.currentFinancingProposal.$each.$response.$errors[index].name.length === 0) return null;
        return this.v$.currentFinancingProposal.$each.$response.$errors[index].name[0].$message || ''
      };
    }
  },
  methods: {
    changeField() {
      this.$emit("onChangeField", this.currentFinancingProposal);
    }
  },
  watch: {
    dataTable: {
      deep: true,
      immediate: true,
      handler(value) {
        this.currentFinancingProposal = _.cloneDeep(value).sort((a, b) =>
          sortBy(a.priority, b.priority)
        );
      }
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    return {
      currentFinancingProposal: {
        $each: helpers.forEach({
          name: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          }
        })
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.property {
  // font-weight: 600;
}

.text-align-end {
  text-align: end;
}

.input-title ::v-deep .item-input-wrap {
  text-align: center;
}

.input-title ::v-deep input,
.custom-input ::v-deep input {
  width: 100%;
  text-align: end;
  border-bottom: 1px solid;

  &:hover {
    background-color: var(--f7-page-bg-color);
  }

  &.input-focused {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: var(--f7-page-bg-color);
  }
}

.input-title ::v-deep input {
  // font-weight: bold;
  text-align: center;
}

.monthly-payment {
  color: var(--f7-theme-color);
  font-weight: 700;
}
</style>
