<template>
  <div class="discount-col">
    {{ column.text }}
    <div class="label-select">
      (
      <select v-model="selected">
        <option
          :value="option.value"
          v-for="option in options"
          :key="option.value"
          >{{
            displayType === "symbol" ? option.symbol : option.fullText
          }}</option
        >
      </select>
      )
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: Object,
    colType: String,
    displayType: {
      type: String,
      default: "symbol" //fullText or symbol
    }
  },

  data() {
    return {
      selected: "",
      options: {
        percent: {
          symbol: "%",
          fullText: "percent",
          value: "percent"
        },
        fixed: {
          symbol: "$",
          fullText: "fixed",
          value: "fixed"
        }
      }
    };
  },

  created() {
    this.selected = this.colType;
  },

  watch: {
    selected(value) {
      this.onchange(value);
    }
  },

  methods: {
    onchange(value) {
      this.$emit("change-select", value);
    }
  }
};
</script>

<style scoped lang="scss">
.discount-col {
  display: flex;
  justify-content: flex-end;
}

.label-select {
  margin-left: 10px;
  display: flex;
  &:hover {
    cursor: pointer;
    color: var(--f7-theme-color);
  }
}

select {
  text-align: center;
}
</style>
