import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";
import * as constant from "../../../../utility/const";

export default tenant => {
  const assemblyDrawingActions = new FirebaseActions(
    `/system_client/${tenant}/assembly_drawing`,
    "assemblyDrawing"
  );

  return {
    bindAssemblyDrawingListBys: assemblyDrawingActions.bindCollectionBys,
    bindAssemblyDrawingList: assemblyDrawingActions.bindCollection,
    unbindAssemblyDrawingList: assemblyDrawingActions.unbindCollection,

    getAssemblyDrawingListBys: assemblyDrawingActions.getDocumentBys,
    getAssemblyDrawingByPriceList({ dispatch, commit }, priceListId) {
      return dispatch("getAssemblyDrawingListBys", [
        {
          prop: "priceListId",
          op: "==",
          val: priceListId
        },
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]).then(data => {
        commit(types.SET_ASSEMBLY_DRAWING_LIST, data);
        return data;
      });
    }
  };
};
