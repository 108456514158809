<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          icon-f7="chevron_left"
          v-if="!$device.desktop"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ proposalTemplate.number }}</f7-nav-title>
      <f7-nav-right>
        <f7-link v-show="isEdited" @click.native="onSave">Save</f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-row no-gap>
      <f7-col :width="$device.desktop ? 30 : 100">
        <f7-card v-if="$device.desktop">
          <f7-card-header class="justify-content-flex-start">
            <f7-icon
              class="margin-right-half"
              f7="square_list"
              color="primary"
            ></f7-icon>
            Table of Content
          </f7-card-header>
          <f7-card-content>
            <f7-list>
              <draggable
                :list="sortedSections"
                :animation="200"
                class="section-content-container"
                ghost-class="ghost"
                :delay="$device.desktop ? 0 : 100"
                :delayOnTouchOnly="$device.desktop ? true : false"
                :touchStartThreshold="20"
                group="sections"
                draggable=".section-item"
                @change="onDndChange"
                :move="checkHeader"
                tag="ul"
              >
                <f7-list-item
                  v-for="(section, index) in sortedSections"
                  :key="index"
                  :title="section.sectionName"
                  class="section-item"
                  link="#"
                  :text-color="
                    index === currentSectionIndex ? 'primary' : 'default'
                  "
                  @click="sectionClick(section, index)"
                >
                </f7-list-item>
              </draggable>
            </f7-list> </f7-card-content
        ></f7-card>
        <f7-block
          v-else
          strong
          class="no-margin display-flex justify-content-space-between width-100"
          style="gap: 8px; position: fixed; height: 53px; z-index: 9999"
        >
          <div>
            <b>{{ proposalTemplate.name }}</b>
          </div>
          <div @click="isShowContentMobile = !isShowContentMobile">
            <f7-icon
              size="18"
              class="cursor-pointer"
              f7="square_list"
              color="primary"
            ></f7-icon>
            <div v-show="isShowContentMobile" class="dl-item">
              <f7-list class="no-margin">
                <f7-list-item
                  v-for="(section, index) in sortedSections"
                  :key="index"
                  :title="section.sectionName"
                  class="section-item"
                  link="#"
                  :text-color="
                    index === currentSectionIndex ? 'primary' : 'default'
                  "
                  @click="sectionClick(section, index)"
                ></f7-list-item>
              </f7-list>
            </div>
          </div>
        </f7-block>
      </f7-col>
      <f7-col
        :width="$device.desktop ? 70 : 100"
        ref="wrapContent"
        class="display-flex flex-direction-column"
        :style="
          `height: ${
            $device.desktop
              ? 'calc(100vh - var(--f7-navbar-height))'
              : 'calc(100vh - var(--f7-navbar-height) - 53px)'
          };
          margin-top: ${$device.desktop ? '0' : '53px'}`
        "
      >
        <f7-card v-show="$device.desktop"
          ><f7-card-header>
            {{ proposalTemplate.name }}
          </f7-card-header>
        </f7-card>
        <f7-block
          class="display-flex justify-content-center scroll-container"
          @scroll.native="onViewerScroll"
          ref="scrollViewer"
        >
          <div
            ref="scrollContainer"
            class="editor-section align-self-center"
            :style="styleContent"
          >
            <div v-for="(section, index) in sortedSections" :key="index">
              <section-editor
                :ref="`editor_${index}`"
                :section="section"
                @editorChange="handleEditorChange"
                @doAction="doAction"
              ></section-editor>
            </div>
            <div class="tail-section"></div>
          </div>
        </f7-block>
      </f7-col>
    </f7-row>

    <proposal-verbiage-popup
      ref="verbiageProposalPopup"
      @onSave="saveVerbiageProposalTemplate"
    ></proposal-verbiage-popup>

    <edit-table-popup
      ref="editTablePopup"
      @onSave="editTable"
    ></edit-table-popup>
  </f7-page>
</template>

<script>
import ProposalVerbiagePopup from "../components/ProposalVerbiagePopup.vue";
import EditTablePopup from "../components/EditTablePopup.vue";
import sectionEditor from "../components/SectionEditor.vue";
import draggable from "vuedraggable";
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    sectionEditor,
    draggable,
    ProposalVerbiagePopup,
    EditTablePopup
  },

  data: () => {
    return {
      hasProject: false,
      isEdited: false,
      currentSectionIndex: 0,
      sortedSections: [],
      styleContent: "",
      isShowContentMobile: false
    };
  },

  mounted() {
    // this.unbindProposalTemplate();
    let proposalTemplateId = this.$f7route.params.id || "";
    // console.log(proposalTemplateId);
    if (proposalTemplateId != "") {
      const refs = [];
      this.$f7.preloader.show();

      refs.push(this.bindProposalTemplate(proposalTemplateId));

      Promise.all(refs).finally(() => {
        this.$f7.preloader.hide();
        this.lowResDesktopScreen();
        // console.log(this.proposalTemplate);
      });
    }
  },

  computed: {
    ...mapGetters("common/proposal-template", ["proposalTemplate"])
  },

  methods: {
    ...mapActions("common/proposal-template", [
      "bindProposalTemplate",
      "unbindProposalTemplate",
      "updateTemplateSectionValue",
      "updateProposalTemplate"
    ]),

    lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = 1020;
        const width = this.$refs.wrapContent.$el.clientWidth - 30;
        const height = this.$refs.wrapContent.$el.clientHeight;
        const spaceHeight = ((width * height) / letterFullWidth - height) / 2;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;
          this.styleContent = `transform: scale(${scaleRatio}); margin-top: ${spaceHeight}px`;
        } else {
          this.styleContent = "";
        }
      });
    },

    handleEditorChange({ sectionId, value }) {
      this.isEdited = true;
      this.updateTemplateSectionValue({ sectionId, value });
    },

    sectionClick(section, index) {
      this.currentSectionIndex = index;
      this.$refs[`editor_${index}`][0].moveSectionToView(section);
    },

    onSave() {
      this.$f7.preloader.show();
      this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: { sections: this.proposalTemplate.sections }
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },
    checkHeader(value) {
      if (
        value.draggedContext.element.index === 0 ||
        value.relatedContext.index === 0
      ) {
        return false;
      }
      return true;
    },
    async onDndChange(dndEvent) {
      const sections = _.clone(this.sortedSections);
      const eventKeys = Object.keys(dndEvent);
      for (let index = 0; index < eventKeys.length; index++) {
        const key = eventKeys[index];
        const event = dndEvent[key];
        switch (key) {
          case "moved":
            this.$f7.preloader.show();
            if (event.oldIndex < event.newIndex) {
              sections[event.newIndex].index =
                sections[event.newIndex - 1].index;
              for (let i = event.oldIndex; i < event.newIndex; i++) {
                sections[i].index = sections[i].index - 1;
              }
            } else {
              sections[event.newIndex].index =
                sections[event.newIndex + 1].index;
              for (let i = event.newIndex + 1; i <= event.oldIndex; i++) {
                sections[i].index = sections[i].index + 1;
              }
            }

            this.currentSectionIndex = event.newIndex;
            await this.updateProposalTemplate({
              id: this.proposalTemplate.id,
              doc: { sections }
            }).then(() => {
              this.sectionClick(event.element, event.newIndex);
              this.$f7.preloader.hide();
            });
            break;
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    updatePage(event, index) {},

    addNewPage() {},

    removePage() {},

    doAction({ actionCode, sectionId }) {
      switch (actionCode) {
        case "edit-verbiage":
          this.$refs.verbiageProposalPopup.openPopup(sectionId);
          break;

        case "edit-table":
          this.$refs.editTablePopup.openPopup();
          break;
      }
    },

    saveVerbiageProposalTemplate({ sectionId, blockData, callback }) {
      this.$f7.preloader.show();
      const sections = _.cloneDeep(this.proposalTemplate.sections);
      const orgSection = sections.find(
        section => section.sectionId === sectionId
      );
      orgSection.blockData = blockData;

      return this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: { sections }
      }).finally(() => {
        callback();
        this.$f7.preloader.hide();
      });
    },

    editTable({ columns, callback }) {
      this.$f7.preloader.show();

      return this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: { productTableColumns: columns }
      }).finally(() => {
        callback();
        this.$f7.preloader.hide();
      });
    },
    onViewerScroll() {
      const scrollContainer = this.$refs["scrollContainer"];
      const scrollViewer = this.$refs["scrollViewer"].$el;

      if (scrollContainer.getBoundingClientRect().top > this.scrollPos) {
        // Scroll direction UP
        for (let index = this.sortedSections.length - 1; index >= 0; index--) {
          const editor = this.$refs[`editor_${index}`][0];

          if (editor) {
            const isInViewport = editor.isInViewport(
              scrollViewer.getBoundingClientRect().top
            );

            // Set the last section visible
            if (isInViewport === true) {
              this.currentSectionIndex = index;
            }
          }
        }
      } else {
        // Scroll direction DOWN
        for (let index = 0; index < this.sortedSections.length; index++) {
          const editor = this.$refs[`editor_${index}`][0];
          if (editor) {
            const isInViewport = editor.isInViewport(
              scrollViewer.getBoundingClientRect().top
            );

            // Set the first section visible
            if (isInViewport === true) {
              this.currentSectionIndex = index;
              break;
            }
          }
        }
      }
      this.scrollPos = scrollContainer.getBoundingClientRect().top;
    },
    handleBack() {
      this.$f7router.back("/proposal-template-v2", { force: true });
    }
  },

  watch: {
    proposalTemplate: {
      handler(val, old) {
        const oldSections = old ? old.sections || [] : [];
        const newSections = val ? val.sections || [] : [];
        if (_.differenceWith(newSections, oldSections, _.isEqual).length) {
          this.sortedSections = _.cloneDeep(newSections).sort(
            (a, b) => a.index - b.index
          );
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  height: calc(100vh - 110px);
  overflow-x: auto;
  margin: 0px 10px 0px 10px;
  direction: ltr;
  scroll-behavior: smooth;
}

.editor-section {
  height: calc(100vh - 110px);
  direction: ltr;
}
.column-content-container {
  height: 100%;
  overflow-y: auto;
}
.ghost {
  opacity: 0.5;
  background: #f7fafc;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.tail-section {
  height: calc(100vh * 0.8);
}
.dl-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
  max-height: 60vh;
  overflow-y: scroll;
  box-shadow: rgba(33, 35, 38, 0.15) 0px 15px 10px -10px;
}
</style>
