<template>
  <div>
    <data-table
      :headers="headers"
      :items="sortedHistoryList"
      :pageSize="sortedHistoryList.length"
      data-table-collapsible
      class="wrap-text-table"
    >
      <template v-slot:body="{ item }">
        <td :data-collapsible-title="headers[0].text">
          {{ item.createdBy }}
        </td>
        <td :data-collapsible-title="headers[1].text">
          {{ toLocaleString(item.createdAt) }}
        </td>
        <td data-collapsible-title="" class="action-column-mobile">
          <f7-row class="button-action">
            <f7-button @click="view(item.id)" fill small color="red">
              View
            </f7-button>
            <!-- <f7-button
              @click="restore(item.id)"
              fill
              small
              color="red"
            >
              Restore
            </f7-button> -->
          </f7-row>
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: { DataTable },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters("contact-book/contact", ["contact"]),
    ...mapGetters("contact-book/contact-history", ["historyList"]),

    headers() {
      return [
        {
          text: "Change By",
          align: "left",
          value: "From",
          width: "40%"
        },
        {
          text: "Change At",
          align: "left",
          value: "addresses",
          width: "40%"
        },
        {
          text: "Action",
          align: "left",
          value: "group",
          width: "10%"
        }
      ];
    },
    sortedHistoryList() {
      return _.cloneDeep(this.historyList).sort(
        (a, b) => b.createdAt - a.createdAt
      );
    }
  },
  methods: {
    ...mapActions("contact-book/contact-history", [
      "getHistoryListBys",
      "getHistoryById"
    ]),
    initData() {
      if (_.isEmpty(this.contact)) return;
      this.getHistoryListBys([
        {
          prop: "itemId",
          val: this.contact.id,
          op: "=="
        }
      ]);
    },
    async view(id) {
      await this.getHistoryById(id);
      this.$f7router.navigate(
        `/contactbook/contact/${this.contact.id}/history/${id}`
      );
    },
    toLocaleString(firebaseDate) {
      return firebaseDate ? firebaseDate.toDate().toLocaleString() : "";
    }
  }
};
</script>

<style>
.button-action {
  display: flex;
  justify-content: space-around;
}
</style>
