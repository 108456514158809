<template>
  <div>
    <f7-list class="no-margin">
      <f7-list-input
        label="Due Date"
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        placeholder="Enter due date"
        :value="dueDate || []"
        @calendar:change="
          onUpdateDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list>
      <f7-list-item title="Put a Lien">
        <f7-radio
          :value="PUT_A_LIEN"
          :checked="paymentStatus === PUT_A_LIEN"
          name="manufactures"
          slot="media"
          @change="paymentStatus = PUT_A_LIEN"
        ></f7-radio>
      </f7-list-item>
      <f7-list-item title="Received Payment">
        <f7-radio
          :value="RECEIVED_PAYMENT"
          :checked="paymentStatus === RECEIVED_PAYMENT"
          name="manufactures"
          slot="media"
          @change="paymentStatus = RECEIVED_PAYMENT"
        ></f7-radio>
      </f7-list-item>
    </f7-list>
    <div v-show="paymentStatus === RECEIVED_PAYMENT">
      <invoice-section
        ref="invoiceSection"
        @closePopup="closeActionPopup"
      ></invoice-section>
    </div>
    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>
    <user-input
      @input="setAssigneeIDs($event)"
      :value="displayCard.assigneeIDs"
    ></user-input>
    <note-input
      :value="displayCard.commentAndNote"
      @change="saveNote($event)"
    ></note-input>

    <note-popup
      ref="archiveNotePopup"
      title="Archive Note"
      label="Note"
      v-model="archiveNote"
      @done="onDone"
      validate
    ></note-popup>
  </div>
</template>
<script>
import methodsMixins from "../../mixin/methods";
import residentialMixins from "../../mixin/residential";
import InputIcon from "../icon/InputIcon.vue";
import NoteInput from "../input/NoteInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import UserInput from "../input/UserInput";
import InvoiceSection from "../invoice/InvoiceSection.vue";
import _ from "lodash";
import { toDateCalendar, toDateFirebase } from "../../../../utility/datetime";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { firebase, auth } from "../../../../services/firebase.service";
import NotePopup from "../popup/NotePopup.vue";
import {
  PAYMENT_STATUS_RECEIVED_PAYMENT,
  PAYMENT_STATUS_PUT_A_LIEN
} from "../../../../utility/const";

export default {
  components: {
    InputIcon,
    AttachmentInput,
    NoteInput,
    UserInput,
    InvoiceSection,
    NotePopup
  },
  mixins: [methodsMixins, residentialMixins],
  data: () => {
    return {
      currentResponse: {},
      displayCard: {},

      dueDate: [],
      paymentStatus: "",

      archiveNote: ""
    };
  },
  computed: {
    ...mapGetters("dashboard/project", ["card"]),
    RECEIVED_PAYMENT() {
      return PAYMENT_STATUS_RECEIVED_PAYMENT;
    },
    PUT_A_LIEN() {
      return PAYMENT_STATUS_PUT_A_LIEN;
    }
  },
  methods: {
    ...mapActions("dashboard/project", [
      "updateCard",
      "setConditionToDisplayResponseBtn"
    ]),
    ...mapActions("dashboard/finance", ["initInvoice"]),

    initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.displayCard = _.cloneDeep(this.card);
        if (
          this.displayCard.dueDate &&
          (_.isEmpty(this.dueDate) ||
            (!_.isEmpty(this.dueDate) &&
              !moment(this.dueDate[0]).isSame(
                moment(toDateCalendar(this.displayCard.dueDate)[0]),
                "day"
              )))
        ) {
          this.dueDate = toDateCalendar(this.displayCard.dueDate);
        }
        this.paymentStatus = this.displayCard.paymentStatus
          ? this.displayCard.paymentStatus
          : PAYMENT_STATUS_RECEIVED_PAYMENT;
      }
    },

    onUpdateDate(value) {
      if (!_.isEmpty(value)) {
        if (
          _.isEmpty(this.dueDate) ||
          (!_.isEmpty(this.dueDate) &&
            !moment(value[0]).isSame(moment(this.dueDate[0]), "day"))
        ) {
          this.dueDate = value;
          this.onUpdateCard("dueDate", toDateFirebase(value));
        }
      }
    },

    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      return this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value
        }
      }).finally(() => {
        isShowLoading && this.$f7.preloader.hide();
      });
    },
    onDone(){
      this.resolve(() => {});
      this.$f7router.updateCurrentUrl("/dashboard/swimlane/residential");
    },
    // Implement do something before resolve action
    doAction(response) {
      const self = this;
      switch (response.code) {
        case "close-project":
          this.currentResponse = response;
          this.$refs.archiveNotePopup.open();
          break;

        case "put-a-lien":
          this.currentResponse = response;
          this.$ri.dialog.openWarningDialog({
            title: "Put a Lien",
            content:
              "We are going to move the project to the Archive folder with the status of “Put a Lien”. Are you sure you want to do this?",
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                self.$refs.archiveNotePopup.open();
              }
            }
          });
          break;
      }
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      let doc = {};
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote) || [];

      if (this.archiveNote) {
        commentAndNote.push({
          code: "note",
          title: "Archive Note",
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
        doc.archiveDate = firebase.firestore.Timestamp.now();
      }

      doc.commentAndNote = commentAndNote;
      doc.paymentStatus = this.paymentStatus;
      await this.updateCard({
        id: this.card.id,
        doc
      }).then(() => {
        this.displayCard = {};
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote =
          _.cloneDeep(this.displayCard.commentAndNote) || [];
        commentAndNote.push({
          code: "note",
          title: "Collection Note",
          htmlContent: event,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: auth.currentUser.uid,
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

        this.onUpdateCard("commentAndNote", commentAndNote);
      }
    },

    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    }
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    },
    "displayCard.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.initInvoice(val);
        }
      }
    },
    paymentStatus: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.setConditionToDisplayResponseBtn(val);
        }
      }
    }
  }
};
</script>
