import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const actionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_sub_category`,
    "object"
  );

  return {
    bind: actionsTemplate.bindCollection,
    bindBys: actionsTemplate.bindCollectionBys,
    unbind: actionsTemplate.unbindCollection,
    create: actionsTemplate.createDocument,
    update: actionsTemplate.updateDocument,
    getBys: actionsTemplate.getDocumentBys,

    bindByCategory: ({ dispatch }, categoryId) => {
      dispatch("bindBys", [
        {
          prop: "categoryId",
          op: "==",
          val: categoryId
        }
      ]);
    },

    async getSubCategories({ commit, dispatch }) {
      const list = await dispatch("getBys");
      commit(types.SET_SUB_CATEGORY_LIST, list);
    }
  };
};
