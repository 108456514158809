import * as types from "./types";
import Vue from "vue";

export default {
  setProjectList(state, payload) {
    Vue.set(state, "projectList", payload);
  },
  setProjectSubscribe(state, payload) {
    Vue.set(state, "projectSubscribe", payload);
  },
  [types.SET_PROJECT](state, data) {
    Vue.set(state, "project", data);
  },

  [types.SET_PROJECT_LIST](state, data) {
    Vue.set(state, "projectList", data);
  }
};
