import * as types from "./types";
import { FirebaseActions } from "../../../../services/firebase.service";
const settingActions = new FirebaseActions(`/system_settings`, "setting");
import {
  upgradeSubscription,
  getPaymentHistory,
  createCheckoutSession,
  updatePaymentMethod,
  getFailInvoices,
  payInvoice,
  getPaymentMethods,
  updatePaymentMethodFromSession,
  deletePaymentMethod,
  closeAccount
} from "../../../../services/subscription.service";

export default {
  bindSetting: settingActions.bindDocument,
  unbindSetting: settingActions.unbindDocument,
  updateSetting: settingActions.updateDocument,

  getSetting: settingActions.getDocument,

  async upgradeSubscription(_, data) {
    const res = await upgradeSubscription(data);
    return res;
  },

  async createCheckoutSession(_, data) {
    const res = await createCheckoutSession(data);
    return res.data;
  },

  async updatePaymentMethod(_, data) {
    const res = await updatePaymentMethod(data);
    return res;
  },

  async updatePaymentMethodFromSession(_, data) {
    const res = await updatePaymentMethodFromSession(data);
    return res;
  },

  async deletePaymentMethod(_, data) {
    const res = await deletePaymentMethod(data);
    return res;
  },

  async payInvoice(_, data) {
    const res = await payInvoice(data);
    return res;
  },

  async closeAccount(_, data) {
    const res = await closeAccount(data);
    return res;
  },

  async getPaymentHistory({ commit }) {
    const res = await getPaymentHistory();
    if (res && res.success) {
      commit(types.SET_PAYMENT_HISTORY, res.data);
    }
    return res;
  },

  async getFailInvoices({ commit }) {
    const res = await getFailInvoices();
    if (res && res.success) {
      commit(types.SET_FAIL_INVOICES, res.data);
    }
    return res;
  },

  async getPaymentMethods({ commit }) {
    const res = await getPaymentMethods();
    if (res && res.success) {
      commit(types.SET_PAYMENT_METHODS, res.data.list);
      commit(
        types.SET_DEFAULT_PAYMENT_METHOD_ID,
        res.data.defaultPaymentMethod
      );
    }
    return res;
  },

  setDefaultPaymentMethod({ commit }, paymentMethodId) {
    commit(types.SET_DEFAULT_PAYMENT_METHOD_ID, paymentMethodId);
  },

  setListPaymentMethod({ commit }, list) {
    commit(types.SET_PAYMENT_METHODS, list);
  }
};
