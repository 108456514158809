import { sortLatest } from "@/utility/date-time-tool";

export default {
  // eslint-disable-next-line no-unused-vars
  paymentRecordList: (state, _, __, _rootGetters) => {
    return sortLatest(state.paymentRecordList);
  },
  paymentRecord: state => {
    return state.paymentRecord;
  },
  paymentRecordByNumber: state => paymentRecordByNumber => {
    return state.paymentRecordList.find(
      r => r.paymentRecordNumber === paymentRecordByNumber
    );
  }
};
