export const SET_COMPANY = "SET_COMPANY_HISTORY";
export const SET_COMPANY_LIST = "SET_COMPANY_HISTORY_LIST";

export const SET_CONTACT_REFS = "SET_CONTACT_REFS";
export const SET_BRANCH_REFS = "SET_BRANCH_REFS";
export const SET_PROPERTY_REFS = "SET_PROPERTY_REFS";

export const ADD_CONTACT_REFS = "ADD_CONTACT_REFS";
export const ADD_BRANCH_REFS = "ADD_BRANCH_REFS";
export const ADD_PROPERTY_REFS = "ADD_PROPERTY_REFS";

export const REMOVE_CONTACT_REFS = "REMOVE_CONTACT_REFS";
export const REMOVE_BRANCH_REFS = "REMOVE_BRANCH_REFS";
export const REMOVE_PROPERTY_REFS = "REMOVE_PROPERTY_REFS";
