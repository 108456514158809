<template>
  <div>
    <f7-list accordion-list inset>
      <f7-list-item
        accordion-item
        :title="`Payments Received (${items.length})`"
      >
        <f7-accordion-content>
          <f7-block>
            <data-table
              :headers="headers"
              :items="items"
              :pageSize="items.length"
              class="label-cell dark-header no-margin-horizontal"
            >
              <template v-slot:body="{ item }">
                <td class="display-flex align-items-center">
                  {{ item.paymentNumber }}
                </td>
                <td>
                  {{ item.paymentDate.toDate() | MMDDYYYY }}
                </td>
                <td class="numeric-cell">
                  {{ paymentMode(item.paymentMode) }}
                </td>
                <td class="numeric-cell">
                  {{ item.amountReceived | currencyUSD }}
                </td>
                <td class="numeric-cell">
                  <f7-link
                    class="margin-right-half"
                    icon-f7="pencil_circle"
                    color="blue"
                    @click="editItem(item)"
                  ></f7-link>
                </td>
              </template>
            </data-table>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapGetters } from "vuex";

export default {
  components: {
    DataTable
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters("invoices/app-constant", ["paymentModeList"]),
    paymentMode() {
      return mode => {
        return (this.paymentModeList.find(r => r.value == mode) || {})
          .displayName;
      };
    },
    headers() {
      return [
        {
          text: "Payment #",
          align: "left",
          sortable: false,
          value: "paymentNumber",
          width: "10%"
        },
        {
          text: "Date",
          align: "left",
          sortable: false,
          value: "quantity",
          width: "15%"
        },
        {
          text: "Payment Mode",
          align: "right",
          sortable: false,
          value: "paymentMode",
          width: "15%"
        },
        {
          text: "Amount",
          align: "right",
          sortable: false,
          value: "amountReceived",
          width: "15%"
        },
        {
          text: "Action",
          align: "right",
          sortable: false,
          value: "",
          width: "15%"
        }
      ];
    }
  },
  methods: {
    editItem(item) {
      this.$emit("onEdit", item);
    },
    deleteItem(item) {
      this.$emit("onDelete", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-input {
  background-color: var(--f7-page-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 100px;
  padding: 3px;

  &_label {
    width: 100%;
  }

  &_value {
    text-align: right;
    float: right;
  }
}
.dark-header::v-deep .card-content table thead {
  background: rgb(169, 206, 207);
  th {
    color: black;
    font-weight: 500;
  }
}
</style>
