export default {
  propertyList: state => state.propertyList,
  addressOfProperty: state => state.addressOfProperty,
  propertyById: state => id => state.propertyList.find(item => item.id === id),
  property: state => state.property,

  currentProperty: state => state.property,

  // projectOfProperty: (state, _, __, rootGetters) => {
  //   const projectList = rootGetters["project/projectList"];
  //   return projectList.filter(x => {
  //     return state.projectOfProperty.includes(x.id);
  //   });
  // },

  propertyGroup: state => (searchValue = "") => {
    let group = [];

    let properties =
      searchValue != ""
        ? state.propertyList.filter(property => {
            let propertyName = property.propertyName
              ? property.propertyName.toLowerCase()
              : "";

            return propertyName.indexOf(searchValue.toLowerCase()) >= 0;
          }) || []
        : state.propertyList;

    properties.forEach(property => {
      let letter = property.propertyName
        ? property.propertyName.charAt(0).toUpperCase()
        : "";
      let index = group.findIndex(groupName => {
        return letter === Object.keys(groupName)[0];
      });
      if (index >= 0) {
        group[index][letter].push(property);
      } else {
        group.push({ [letter]: [property] });
      }
    });

    group.forEach(data => {
      let letter = Object.keys(data)[0];
      data[letter].sort((a, b) => {
        return (a.propertyName ? a.propertyName.charAt(1).toUpperCase() : "") <
          (b.propertyName ? b.propertyName.charAt(1).toUpperCase() : "")
          ? -1
          : 0;
      });
    });

    return group.sort((a, b) => {
      return Object.keys(b)[0] > Object.keys(a)[0] ? -1 : 0;
    });
  }
};
