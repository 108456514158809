<template>
  <div>
    <f7-block-header>Estimates</f7-block-header>
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in estimateList"
        :key="index"
        swipeout
        text-color="blue"
      >
        <a class="cursor-pointer" slot="title" @click="openLink(item)">{{
          item.estimateNumber
        }}</a>
        <f7-checkbox
          slot="media"
          name="demo-radio-inline"
          :checked="item.isDefault === true"
          @change="onRadioChange(item.id, $event.target.checked)"
        ></f7-checkbox>

        <div slot="footer">{{ getStatusString(item.status) }}</div>
        <div slot="after" v-if="item.isDefault === true">Official</div>
        <f7-swipeout-actions right>
          <f7-swipeout-button
            color="primary"
            :close="true"
            @click="removeEstimate(item)"
            confirm-title="Confirm remove!"
            confirm-text="Are you sure you want to remove this estimate?"
            >Remove</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>

      <!-- Add list item -->
      <f7-list-item @click.native="toNewEstimate">
        <div slot="media" class="add-button-icon cursor-pointer">
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <div class="cursor-pointer" slot="title">{{ title }}</div>
      </f7-list-item>

      <f7-list-item @click="showEstimateSumPopup"
        ><div slot="media" class="add-button-icon cursor-pointer">
          <f7-icon f7="sum"></f7-icon>
        </div>
        <div class="cursor-pointer" slot="title">
          View Estimate Summary
        </div></f7-list-item
      >
    </f7-list>
    <estimate-sum-popup
      :estimateList="estimateList"
      :project="value"
      ref="estimateSumPopup"
    ></estimate-sum-popup>
  </div>
</template>

<script>
import EstimateSumPopup from "../../plugins/dashboard/components/popup/EstimateSumPopup.vue";
import { toDisplayDateString } from "@/utility/datetime";

export default {
  components: { EstimateSumPopup },

  props: {
    value: { type: Object, default: () => {} },
    title: { type: String, default: "Add an Estimate" },
    estimateList: { type: Array, default: () => [] },
    action: { type: Object, default: () => {} },
    boardId: { type: String, default: "" },
    activeTab: { type: String, default: "" }
  },

  methods: {
    getStatusString(status) {
      switch (status) {
        case "active":
          return "Active";

        case "inactive":
          return "Inactive";

        default:
          return "Draft";
      }
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(estimate) {
      const self = this;
      const boardId = this.boardId;
      const actionId = this.action.id;
      const projectId = this.value.id;
      const contactId = estimate.contactId;
      const estimateNumber = estimate.estimateNumber;

      this.$emit("closePopup", () => {
        const url = `/dashboard/type/${self.$f7route.route.meta.boardType ||
          "swimlane"}/business/${
          self.$f7route.route.meta.businessCode
        }/board/${boardId}/action/${actionId}/project/${projectId}/estimate/contact/${contactId}/${estimateNumber}${
          self.activeTab ? `?activeTab=${self.activeTab}` : ""
        }`;

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true
        });
      });
    },

    onRadioChange(estimateId, value) {
      this.$emit("onUpdateEstimate", {
        id: estimateId,
        doc: {
          isDefault: value
        }
      });
    },

    toNewEstimate() {
      this.$emit("onCreateNewEstimate");
    },

    removeEstimate(estimate) {
      this.$emit("onUpdateEstimate", {
        id: estimate.id,
        doc: {
          projectId: ""
        }
      });
      this.$emit("onDeleteEstimate", { id: estimate.id });
    },

    showEstimateSumPopup() {
      this.$refs.estimateSumPopup.show();
    }
  }
};
</script>

<style></style>
