<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Follow-up Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
    </f7-list>

    <multi-date-input
      title="Follow-up Date"
      :value="displayCard.followUpDates"
      @input="setFollowUpDate($event)"
    ></multi-date-input>

    <f7-list no-hairlines-md v-show="postSellDate && postSellDate.length > 0">
      <f7-list-input
        type="text"
        label="Post-sell Date"
        readonly
        :value="toDisplayDateString(toDateFirebase(postSellDate))"
      >
        <input-icon slot="media" icon="calendar_today"></input-icon>
      </f7-list-input>
    </f7-list>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <!-- attachment -->
    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="changeValueNotes"
      :value="note"
      :is-save-button="true"
      @onSave="
        onSaveNote({
          displayCard,
          note,
          title: 'Follow-up & Pre-Sales Note',
          callback: () => {
            note = '';
          }
        })
      "
    ></note-editor>

    <!-- Popup -->
    <date-popup
      ref="closingDueDatePopup"
      title="Enter Closing Date"
      label="Closing Date"
      v-model="closingDueDate"
      @done="resolveClosing"
      validate
      not-allow-past
    ></date-popup>

    <date-popup
      ref="postSellDatePopup"
      title="Enter Follow-up Date"
      label="Follow-up"
      v-model="postSellDate"
      @done="resolve"
      validate
      not-allow-past
    ></date-popup>

    <note-popup
      ref="archiveNotePopup"
      title="Archive Note"
      label="Note"
      v-model="archiveNote"
      @done="onDone"
    ></note-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import MultiDateInput from "../input/MultiDateInput.vue";
import DatePopup from "../popup/DatePopup.vue";
import NotePopup from "../popup/NotePopup.vue";
import InputIcon from "../icon/InputIcon.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import PurchaseOrderInput from "../input/PurchaseOrderInput.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";

import { mapState, mapActions } from "vuex";
import Vue from "vue";
import _ from "lodash";
import methodsMixins from "../../mixin/methods";
import moment from "moment";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  mixins: [methodsMixins],

  components: {
    UserInput,
    MultiDateInput,
    DatePopup,
    NotePopup,
    InputIcon,
    AttachmentInput,
    PurchaseOrderInput,
    NoteEditor
  },

  data: () => {
    return {
      note: "",
      archiveNote: "",
      currentResponse: {},

      displayCard: {},
      dueDate: [],

      postSellDate: [],
      closingDueDate: []
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"])
  },

  methods: {
    ...mapActions("dashboard/project", ["updateCard"]),

    changeValueNotes(value) {
      this.note = value;
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.postSellDate = toDateCalendar(this.displayCard.postSellDate);
      }
    },

    setFollowUpDate(dates) {
      Vue.set(this.displayCard, "followUpDates", dates);
      this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      });
    },
    onDone(){
      this.resolve(() => {});
      this.$f7router.updateCurrentUrl("/dashboard/swimlane/commercial/sales");
    },
    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case "move-to-closing":
          this.currentResponse = response;
          this.$refs.closingDueDatePopup.open();
          break;

        case "schedule-post-sell":
          this.currentResponse = response;
          this.$refs.postSellDatePopup.open();
          break;

        case "unsold":
          this.currentResponse = response;
          this.$refs.archiveNotePopup.open();
          break;
      }
    },

    resolveClosing() {
      this.dueDate = this.closingDueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup");
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Follow Up & Post Sell Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });
      }

      if (this.archiveNote) {
        commentAndNote.push({
          code: "note",
          title: "Archive Note",
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

        this.displayCard.archiveDate = firebase.firestore.Timestamp.now()
      }

      this.displayCard.commentAndNote = commentAndNote;
      if (!_.isEmpty(this.dueDate)) {
        this.displayCard.dueDate = toDateFirebase(this.dueDate);
      }

      if (!_.isEmpty(this.postSellDate)) {
        this.displayCard.postSellDate = toDateFirebase(this.postSellDate);
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            "day"
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) }
          });
        }
      }
    }
  }
};
</script>

<style></style>
