import { FirebaseActions } from "../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const albumTemplateActions = new FirebaseActions(
    `/system_client/${tenant}/album_template`,
    "albumTemplate"
  );

  return {
    getAlbumTemplatesBys: albumTemplateActions.getDocumentBys,

    bindAlbumTemplate: albumTemplateActions.bindDocument,
    unbindAlbumTemplate: albumTemplateActions.unbindDocument,

    addAlbumTemplate: albumTemplateActions.createDocument,
    saveAlbumTemplate: albumTemplateActions.updateDocument,
    removeAlbumTemplate: albumTemplateActions.deleteDocument,

    getAlbumTemplateListBys({ dispatch, commit }, conditions) {
      return dispatch("getAlbumTemplatesBys", conditions).then(
        albumTemplateList => {
          commit(types.SET_ALBUM_TEMPLATE_LIST, albumTemplateList);
          return albumTemplateList;
        }
      );
    },

    createAlbumTemplate({ dispatch, commit }, template) {
      return dispatch("addAlbumTemplate", template).then(id => {
        commit(types.ALBUM_TEMPLATE_LIST_ITEM_ADD, {
          ...template,
          id
        });

        return id;
      });
    },

    updateAlbumTemplate({ dispatch, commit }, { id, doc }) {
      return dispatch("saveAlbumTemplate", { id, doc }).then(() => {
        commit(types.ALBUM_TEMPLATE_LIST_ITEM_UPDATE, {
          id,
          doc
        });

        return id;
      });
    },

    deleteAlbumTemplate({ dispatch, commit }, id) {
      return dispatch("removeAlbumTemplate", id).then(() => {
        return commit(types.ALBUM_TEMPLATE_LIST_ITEM_REMOVE, id);
      });
    },

    resetData({ commit }) {
      commit(types.RESET_DATA);
    }
  };
};
