<template>
  <f7-toolbar tabbar class="dashboard-mode"
      :class="{
        
        'swimland-dashboard-mode': isSwimlane,
      }">

      <f7-menu>
        <a class="menu-item icon-only menu-item-title" @click="onSwitchView">
          <div class="menu-item-content">
            <i class="menu-item-title_icon f7-icons"
              >arrow_right_arrow_left_square</i
            >
            <span class="menu-item-title_only">Switch View</span>
          </div>
        </a>

        <a
          v-show="boardCode === 'sales' || boardCode === 'residential'"
          class="menu-item icon-only menu-item-title"
          @click="$emit('onToNewProject')"
        >
          <div class="menu-item-content">
            <i class="menu-item-title_icon f7-icons">bag_badge_plus</i>
            <span class="menu-item-title_only">Add New Job</span>
          </div>
        </a>
      </f7-menu>
      <div style="display: block;">
        <div class="filter-navbar no-wrap">
          <div
            v-if="boardCode === 'residential'"
            class="display-flex input-search-filter"
          >
            <f7-icon color="primary" f7="search"></f7-icon>
            <f7-input
              style="flex: 1"
              placeholder="Search..."
              type="text"
              :clear-button="true"
              :value="searchText"
              @input="onSearch($event.target.value.trim())"
            ></f7-input>
          </div>
          <div class="input-date-filter" v-if="boardCode === 'residential'">
            <f7-icon color="primary" f7="calendar"></f7-icon>
            <f7-link @click.native="selectCalendar" class="date-link">
              <span
                v-if="isChangeWeek"
                style="color: var(--f7-color-text-neutral)"
              >
                {{ showWeekRange }}
              </span>
              <span v-else style="color: gray">Filter Due Date</span>
            </f7-link>
            <div
              class="display-flex align-items-center"
              @click="clearFilterWeek"
            >
              <f7-icon
                class="clear-button"
                :class="isChangeWeek ? '' : 'display-none'"
                f7="xmark_circle_fill"
              ></f7-icon>
            </div>
          </div>
          <user-filter
            v-if="boardCode === 'residential'"
            @changeFilterUsers="onChangeFilter"
          ></user-filter>
        </div>
      </div>
  </f7-toolbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserFilter from '../../components/filter/UserFilter.vue';
import _ from 'lodash';
import { BUSINESS_CODE_COMMERCIAL } from '../../../../utility/const';
import moment from 'moment';

function getCurrentWeek() {
  const weekStart = moment().startOf('isoWeek');
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .format('MM/DD/YYYY')
    );
  }
  return days;
}
export default {
  components: { UserFilter },
  data: () => {
    return {
      currentWeek: getCurrentWeek(),
      currentDate: [new Date()],
      searchText: '',
      filterWeek: [],
      isChangeWeek: false,
    };
  },
  computed: {
    ...mapGetters('dashboard/project', ['boardCode']),
    isSwimlane() {
      return this.$f7route.route.meta.boardType === 'swimlane';
    },
    showWeekRange() {
      const from = moment(this.currentWeek[0], 'MM/DD/YYYY').format('MMM Do');
      const to = moment(this.currentWeek[6], 'MM/DD/YYYY').format('MMM Do');
      return `${from} - ${to}`;
    },
  },
  methods: {
    ...mapActions('dashboard/project', [
      'setFilterUsers',
      'setFilterSearch',
      'setFilterWeek',
    ]),
    onChangeFilter(listUserId) {
      this.setFilterUsers(_.cloneDeep(listUserId));
    },
    onSwitchView() {
      this.$f7.preloader.show();
      if (this.$f7route.route.meta.boardType === 'swimlane') {
        let url = `/dashboard/grid/${this.$f7route.route.meta.businessCode}`;
        if (
          this.$f7route.route.meta.businessCode === BUSINESS_CODE_COMMERCIAL
        ) {
          url += `/${this.boardCode}`;
        }
        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true,
        });
      } else if (this.$f7route.route.meta.boardType === 'grid') {
        let url = `/dashboard/swimlane/${this.$f7route.route.meta.businessCode}`;
        if (
          this.$f7route.route.meta.businessCode === BUSINESS_CODE_COMMERCIAL
        ) {
          url += `/${this.boardCode}`;
        }
        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true,
        });
      }
      // preloader will hide in created() of page
    },
    onSearch(value) {
      this.searchText = value;
      this.setFilterSearch(value);
    },
    onFilterWeek(days) {
      this.filterWeek = days;
      this.setFilterWeek(days);
    },
    selectCalendar() {
      this.$f7.calendar
        .create({
          inputEl: '#calendar-input',
          openIn: 'customModal',
          backdrop: true,
          cssClass: 'calendar-filter',
          value: this.currentDate,
          rangesClasses: [
            {
              cssClass: 'current-week',
              range: {
                from: new Date(this.currentWeek[0]),
                to: new Date(this.currentWeek[6]),
              },
            },
          ],
          on: {
            change: (calendar, value) => {
              if (
                !moment(this.currentDate[0]).isSame(moment(value[0]), 'day')
              ) {
                this.currentDate = value;
                const weekStart = moment(value[0], 'MM/DD/YYYY').startOf(
                  'isoWeek'
                );
                this.setCurrentWeek(weekStart);
                calendar.close();
              }
            },
          },
        })
        .open();
    },

    setCurrentWeek(weekStart) {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(
          moment(weekStart)
            .add(i, 'days')
            .format('MM/DD/YYYY')
        );
      }
      this.isChangeWeek = true;
      this.currentWeek = days;
      this.onFilterWeek(days);
    },

    clearFilterWeek() {
      this.isChangeWeek = false;
      this.currentWeek = getCurrentWeek();
      this.currentDate = [new Date()];
      this.onFilterWeek([]);
    },
  },
  destroyed() {
    this.onSearch('');
    this.clearFilterWeek();
    this.setFilterUsers([]);
  },
};
</script>

<style lang="scss" scoped>
.dashboard-mode {
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swimland-dashboard-mode {
  width: 100%;
  position: fixed;
  left: 0;
  margin: 0;
  background: var(--f7-color-bg-8-neutral);
  padding: 4px 0;
}

.menu-item {
  background-color: transparent;
  color: var(--f7-theme-color);
}

.menu-item-title {
  font-size: var(--f7-toolbar-font-size);
  margin-right: 5px !important;

  &_only {
    margin-left: 3px;
  }

  &_icon {
    margin-left: 2px;
  }
}
.menu-item.active-state {
  background-color: #ffaeae;
}
.filter-navbar ::v-deep .input-search-filter {
  display: flex;
  align-items: center;
  width: auto;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding: 0 10px;
  background-color: var(--f7-md-surface);
  min-width: 220px;
  box-sizing: border-box;
  gap: 8px;
}
.input-date-filter {
  display: flex;
  align-items: center;
  height: 28px;
  border: 1px solid #cfcfcf;
  border-radius: 22px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--f7-md-surface);
  min-width: 220px;
  box-sizing: border-box;
  gap: 8px;

  .date-link {
    flex: 1;
  }
}
.input-search-filter input {
  height: 100%;
}
.current-week {
  background-color: #dc5858;
}
.input-search-filter::after {
  height: 0;
}
.filter-navbar {
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
  padding: 0 10px;
}
.clear-button {
  cursor: pointer;
  color: var(--f7-input-clear-button-color);
  font-size: calc(var(--f7-input-clear-button-size) + 4px);
}
.calendar {
  color: var(--f7-tabbar-link-inactive-color);
}

::v-deep .toolbar-inner {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 2px;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}
</style>