<template>
  <f7-popup tablet-fullscreen :opened="popupOpened" @popup:close="close">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="popupOpened = false">Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ project ? project.projectName : "" }}
        </f7-nav-title>
      </f7-navbar>

      <residential-card-client-view-page
        v-if="project.projectBusinessCode === 'residential'"
        ref="residentialCardClientViewPage"
        :projectId="project.id"
      ></residential-card-client-view-page>
      <commercial-card-client-view-page
        v-else-if="project.projectBusinessCode === 'commercial'"
        ref="commercialCardClientViewPage"
        :projectId="project.id"
      ></commercial-card-client-view-page>
      <service-card-client-view-page
        v-else-if="project.projectBusinessCode === 'service'"
        ref="serviceCardClientViewPage"
        :projectId="project.id"
      ></service-card-client-view-page>
    </f7-page>
    <project-photos-view-popup
      ref="projectPhotosViewPopup"
      :projectId="isOpenProjectPhotoViewPopup ? project.id : ''"
    ></project-photos-view-popup>
  </f7-popup>
</template>

<script>
import ResidentialCardClientViewPage from "../../pages/ResidentialCardClientViewPage.vue";
import CommercialCardClientViewPage from "../../pages/CommercialCardClientViewPage.vue";
import ServiceCardClientViewPage from "../../pages/ServiceCardClientViewPage.vue";
import ProjectPhotosViewPopup from "./ProjectPhotosViewPopup.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ResidentialCardClientViewPage,
    CommercialCardClientViewPage,
    ServiceCardClientViewPage,
    ProjectPhotosViewPopup
  },
  data: () => {
    return {
      popupOpened: false,
      project: {}
    };
  },

  computed: {
    ...mapGetters("dashboard/app-constant", [
      "isCloseArchiveBoardProjectDetailPopup",
      "isOpenProjectPhotoViewPopup"
    ])
  },

  methods: {
    ...mapActions("dashboard/app-constant", [
      "setIsCloseArchiveBoardProjectDetailPopup"
    ]),
    open(project) {
      this.project = project;
      this.popupOpened = true;
    },

    close() {
      this.popupOpened = false;
      if (this.project.projectBusinessCode === "residential") {
        this.$refs.residentialCardClientViewPage.resetData();
      } else if (this.project.projectBusinessCode === "commercial") {
        this.$refs.commercialCardClientViewPage.resetData();
      } else {
        this.$refs.serviceCardClientViewPage.resetData();
      }
      this.project = {};
    }
  },
  watch: {
    isCloseArchiveBoardProjectDetailPopup: {
      handler(val) {
        if (val) {
          this.close();
          this.setIsCloseArchiveBoardProjectDetailPopup(false);
        }
      },
      deep: true
    },
    isOpenProjectPhotoViewPopup: {
      handler(val) {
        if (val) {
          this.$refs.projectPhotosViewPopup.open();
        } else {
          this.$refs.projectPhotosViewPopup.close();
        }
      },
      deep: true
    }
  }
};
</script>
