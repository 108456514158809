import axiosService from "./axios.service";

const updateAdditionalAssigneeIDsForProjectAlgolia = ({
  tenantId,
  projectId,
  userIds,
  action
}) => {
  return axiosService
    .post(
      "/employee-scheduling/update-additional-assignee-ids-project-algolia",
      { tenantId, projectId, userIds, action }
    )
    .then(res => {
      return res.data;
    });
};

export default {
  updateAdditionalAssigneeIDsForProjectAlgolia
};
