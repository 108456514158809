<template>
  <div class="page-preview-content" v-html="processedHtmlValue"></div>
</template>

<script>
export default {
  props: {
    htmlValue: { type: String, default: "" }
  },
  computed: {
    processedHtmlValue() {
      return this.htmlValue.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.page-preview-content {
  width: 1020px;
  height: 1320px;
  background-color: white;
  margin: auto auto;
  margin-top: 23px;
}
.page-preview-content ::v-deep font > div {
  padding-top: 1px;
}
.page-preview-content ::v-deep a {
  pointer-events: all;
  text-decoration: underline;
}
</style>
