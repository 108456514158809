import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import SpreadsheetWriter from "@/utility/spreadsheet-writer";
import moment from "moment";

export const getExcelWorkbook = ({ headers, values }) => {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Products");
  var writer = new SpreadsheetWriter(worksheet);

  headers = headers.filter(r => !r.excludeExport);
  //headers
  headers.forEach(x => {
    writer.write(x["text"]);
    writer.border();
    writer.fill("D9D9D9");
    writer.right();
  });
  writer.enter();
  values.forEach(row => {
    headers.forEach(column => {
      if (column.value === "price") {
        writer.write(row[column["value"]] || 0);
      } else {
        writer.write(row[column["value"]] || "");
      }
      writer.border();
      writer.right();
    });
    writer.enter();
  });
  return workbook;
};

function exportPriceList({ pricelist, headers, values }) {
  const workbook = getExcelWorkbook({
    headers,
    values
  });
  let currentDate = moment(new Date()).format("MMDDYYYY-hhmmss");
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `${pricelist.priceListNumber}-${currentDate}.xlsx`
    );
  });
}

export default exportPriceList;
