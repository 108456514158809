/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const contactInfoAction = new FirebaseActions(
    `/system_client/${tenant}/web_contact_info`,
    "contactInfo"
  );

  return {
    subcribeCollection: contactInfoAction.subcribeCollection,
    bindContactInfoListBys: contactInfoAction.bindCollectionBys,
    unbindConstantTypeList: contactInfoAction.unbindCollection,

    bindConstantTypeList({ dispatch }) {
      return dispatch("bindContactInfoListBys", [
        {
          prop: "status",
          op: "in",
          val: ["open"]
        }
      ]);
    },

    bindContactInfo: contactInfoAction.bindDocument,
    unbindContactInfo: contactInfoAction.unbindDocument,
    updateContactInfo: contactInfoAction.updateDocument
  };
};
