<template>
  <f7-page v-if="loadingData"></f7-page>
  <preview-page v-else ref="previewPage" title="Certificate Preview" :itemDetail="certificate || {}"
    :isHiddenLeftBtn="true" entity="certificate" :entityId="certificate.id"></preview-page>
</template>
<script>
import PreviewPage from "./PreviewPage.vue";
import axios from "axios";

export default {
  components: { PreviewPage },
  data: () => {
    return {
      certificate: {},
      loadingData: true
    };
  },
  async mounted() {
    const id = this.$f7route.query.id;

    try {
      this.$f7.preloader.show();
      const base_api_url = import.meta.env.VITE_BASE_API;
      const response = await axios.get(`certificate/${id}`, {
        baseURL: base_api_url,
        withCredentials: true,
        headers: {
          token: this.$f7route.query.token
        }
      });
      if (
        response.status === 200 &&
        response.data.success &&
        response.data.data.isDeleted === false
      ) {
        this.certificate = response.data.data;

        // update status to viewed by client
        if (this.certificate.status === "sent") {
          await axios.post(
            `certificate/update-viewed/${id}`,
            { status: "viewed" },
            {
              baseURL: base_api_url,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token
              }
            }
          );
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(response.data.message);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    } finally {
      this.loadingData = false;
      this.$f7.preloader.hide();
    }
  },
  watch: {
    loadingData(newVal) {
      if (newVal === false) {
        this.$nextTick(() => {
          this.$refs.previewPage.lowResDesktopScreen();
        });
      }
    }
  }
};
</script>
