<template>
  <f7-page class="estimate-list">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Estimate</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-f7="plus" @click.native="openNewEstimate"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <estimate-list />
  </f7-page>
</template>

<script>
import { mapActions } from "vuex";
import Menu from "../../../components/menu/Menu.vue";
import EstimateList from "../components/list/EstimateList.vue";

export default {
  components: {
    Menu,
    EstimateList
  },
  created() {
    const params = this.$f7route.params;
    if (!params.clientType && !params.clientId) {
      this.setCurrentView("estimate-list");
    } else {
      this.setClientType(params.clientType);
      this.setCurrentView("client");
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/common", [
      "setCurrentView",
      "setClientType"
    ]),
    ...mapActions("estimate/estimate-page/estimate", [
      "unbindBuilding"]),

    openNewEstimate() {
      this.unbindBuilding();
      this.$f7router.navigate(
        `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/new`
      );
    }
  }
};
</script>
