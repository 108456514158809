import _ from "lodash";

export default {
  companyList: state => state.companyList || [],
  company: state => state.company || {},

  contactRefs: state => state.contactRefs || [],
  branchRefs: state => state.branchRefs || [],
  propertyRefs: state =>
    _.cloneDeep(state.propertyRefs).sort((a, b) =>
      sortBy(a.propertyName, b.propertyName)
    ) || []
};

function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
