import { FirebaseActions } from "../../../../services/firebase.service";
import { DEFAULT_STATUS_PRICE_LIST } from "@/utility/const";
import * as types from "./types";

export default tenant => {
  const priceListActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    "priceListItem"
  );
  return {
    bindPriceListItemListBys: priceListActions.bindCollectionBys,
    getPriceListItemListBys: priceListActions.getDocumentBys,
    unbindPriceListItemList: priceListActions.unbindCollection,
    bindPriceListItem: priceListActions.bindDocument,
    unbindPriceListItem: priceListActions.unbindDocument,
    getPriceListById: priceListActions.getDocument,

    async bindActivePriceList({ commit, dispatch }, { roofType }) {
      const list = await dispatch("getPriceListItemListBys", [
        {
          prop: "roofTypes",
          op: "array-contains",
          val: roofType
        },
        {
          prop: "isDeleted",
          op: "==",
          val: false
        },
        {
          prop: "status",
          op: "==",
          val: DEFAULT_STATUS_PRICE_LIST
        }
      ]);

      commit(types.SET_PRICE_LIST_LIST, list);
    },

    async bindAllActivePriceList({ commit, dispatch }) {
      const list = await dispatch("getPriceListItemListBys", [
        {
          prop: "isDeleted",
          op: "==",
          val: false
        },
        {
          prop: "status",
          op: "==",
          val: DEFAULT_STATUS_PRICE_LIST
        }
      ]);

      commit(types.SET_PRICE_LIST_LIST, list);
    }
  };
};
