import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const companyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_company`,
    "object"
  );

  return {
    getBys: companyActions.getDocumentBys,

    async getVendors({ commit, dispatch }) {
      const list = await dispatch("getBys", [
        {
          prop: "companyTypes",
          op: "array-contains",
          val: "vendor"
        }
      ]);
      commit(types.SET_VENDOR_LIST, list);
    },

    resetVendor({ commit }) {
      commit(types.SET_VENDOR_LIST, []);
    }
  };
};
