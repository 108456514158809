<template>
  <div>
    <f7-popup tablet-fullscreen :opened="popupOpened" @popup:close="close">
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="close">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Project Photos</f7-nav-title>
        </f7-navbar>
        <project-photos-client-view
          :projectId="projectId"
          @openPopupAlbum="openPopupAlbum"
        >
        </project-photos-client-view>
      </f7-page>
    </f7-popup>
    <f7-popup
      tablet-fullscreen
      :opened="popupAlbumOpened"
      @popup:close="closePopupAlbum"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="closePopupAlbum">Close</f7-link>
          </f7-nav-left>
          <f7-nav-title>Project Photos</f7-nav-title>
        </f7-navbar>
        <project-album-client-view :albumId="albumId">
        </project-album-client-view>
      </f7-page>
    </f7-popup>
  </div>
</template>
<script>
import ProjectPhotosClientView from "@/plugins/photos/pages/ProjectPhotosClientView.vue";
import ProjectAlbumClientView from "@/plugins/photos/pages/ProjectAlbumClientView.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ProjectPhotosClientView,
    ProjectAlbumClientView
  },
  props: {
    projectId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      popupOpened: false,
      popupAlbumOpened: false,
      albumId: ""
    };
  },

  methods: {
    ...mapActions("dashboard/app-constant", [
      "setIsOpenProjectPhotosViewPopup"
    ]),
    open() {
      this.popupOpened = true;
    },
    openPopupAlbum(id) {
      this.albumId = id;
      this.popupAlbumOpened = true;
    },
    closePopupAlbum() {
      this.albumId = "";
      this.popupAlbumOpened = false;
    },
    close() {
      this.setIsOpenProjectPhotosViewPopup(false);
      this.$f7router.updateCurrentUrl(`${this.$f7router.currentRoute.path}`);
      this.popupOpened = false;
    }
  }
};
</script>
