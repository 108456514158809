<template>
  <div>
    <f7-block-header v-show="proposalList.length > 0"
      >Proposals</f7-block-header
    >
    <f7-list>
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in proposalList"
        :key="index"
        swipeout
        text-color="blue"
      >
        <a class="cursor-pointer" slot="title" @click="openLink(item)">{{
          item.proposalNumber
        }}</a>

        <div slot="footer">{{ getStatusString(item.status) }}</div>
        <div slot="after" v-if="item.isDefault === true">Official</div>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { toDisplayDateString } from "../../../../utility/datetime";
import _ from "lodash";
import methods from "../../mixin/methods";
import { PROPOSAL_STATUS } from "@/utility/status.js";

export default {
  mixins: [methods],

  computed: {
    ...mapGetters("dashboard/proposal", ["proposalList"]),

    ...mapGetters("dashboard/project", [
      "constructionProject",
      "card",
      "action"
    ]),

    ...mapGetters("dashboard/work-order", ["workOrderTask"])
  },

  methods: {
    ...mapActions("dashboard/proposal", ["getProposalsByProjectId"]),

    initData() {
      if (!_.isEmpty(this.card)) {
        this.getProposalsByProjectId(this.card.id);
      }
    },

    getStatusString(status) {
      return PROPOSAL_STATUS.getStatusName(status);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(proposal) {
      const self = this;
      const actionId = this.action.id;
      const projectId = this.getProject(this.constructionProject, this.card).id;
      const proposalNumber = proposal.proposalNumber;

      this.$emit("closePopup", () => {
        let url = `/dashboard/type/${self.$f7route.route.meta.boardType ||
          "swimlane"}/business/${self.$f7route.route.meta.businessCode}/board/${
          self.$f7route.route.meta.boardCode
        }/action/${actionId}/project/${projectId}`;

        if (this.$f7route.route.meta.boardCode === "construction") {
          url += `/task/${this.workOrderTask.id}`;
        }

        url += `/proposal/${proposalNumber}`;

        this.$f7router.navigate(url, {
          pushState: true,
          reloadAll: true
        });
      });
    }
  }
};
</script>

<style></style>
