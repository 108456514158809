<template>
  <div>
    <f7-popup :opened="popupOpened" @popup:closed="close">
      <f7-page>
        <f7-navbar>
          <f7-nav-right>
            <f7-link @click="popupOpened = false">Close</f7-link>
          </f7-nav-right>
          <f7-nav-title>Assignees</f7-nav-title>
        </f7-navbar>

        <f7-list v-if="card">
          <f7-list-item
            v-for="(uid, index) in card.assigneeIDs"
            :key="index"
            :title="userById(uid) ? userById(uid).displayName : ''"
          >
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      popupOpened: false
    };
  },
  computed: {
    ...mapGetters("dashboard/project", ["card", "userById"])
  },
  methods: {
    ...mapActions("dashboard/project", ["bindCard", "unbindCard"]),
    open(card) {
      if (card && card.id) {
        this.bindCard(card.id);
      }
      this.popupOpened = true;
    },
    close() {
      this.unbindCard();
      this.popupOpened = false;
    }
  }
};
</script>
