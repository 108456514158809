import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    "board"
  );

  const woTaskActions = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "task"
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "action"
  );

  return {
    // bindProjectList: projectActions.bindCollection,
    bindProjectListBy: projectActions.bindCollectionBy,
    unbindProjectList: projectActions.unbindCollection,
    getProjectListBys: projectActions.getDocumentBys,

    bindProject: projectActions.bindDocument,
    unbindProject: projectActions.unbindDocument,
    createProject: projectActions.createDocument,
    updateProject: projectActions.updateDocument,

    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getTaskById: woTaskActions.getDocument,
    getActionById: actionActions.getDocument,

    setProjectId({ commit }, id) {
      commit(types.SET_PROJECT_ID, id);
    },

    async getProjectList({ commit, dispatch }) {
      const list = await dispatch("getProjectListBys", [
        {
          prop: "isDeleted",
          op: "==",
          val: false
        }
      ]);
      commit(types.SET_PROJECT_LIST, list);
    }
  };
};
