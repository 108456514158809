<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="closedPopup"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Product Item</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="add()">Add</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <data-table
        :headers="headers"
        :items="productComputed"
        :pageSize="productComputed.length"
        :checkbox="true"
        @selected:change="selectEvent"
        class="table-fixed-container"
      >
        <template slot="card-header">
          <f7-list>
            <f7-searchbar
              placeholder="Search in Product items"
              :clear-button="true"
              disable-button-text
              class="search-list-popup"
              :value="searchText"
              @input="
                setSearchText($event.target.value);
                onSearch();
              "
              @searchbar:clear="
                setSearchText('');
                onSearch();
              "
              @searchbar:disable="
                setSearchText('');
                onSearch();
              "
            ></f7-searchbar>
          </f7-list>
        </template>
        <template v-slot:body="{ item }">
          <td>{{ item.sku }}</td>
          <td>{{ item.productItem }}</td>
          <td>{{ item.manufacturer }}</td>
          <td>{{ item.vendorName }}</td>
          <td>{{ roofTypeNames(item.roofTypes) }}</td>
          <td>{{ item.categoryName }}</td>
          <td>{{ item.subCategoryName }}</td>
          <td>
            <ul class="technical-data">
              <li v-for="(attach, index) in item.technicalData" :key="index" :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`">
                <a class="display-block" @click.stop="openLink(item, attach)">
                  {{ attach.name }}
                </a>
              </li>
            </ul>
          </td>
          <td>
            <f7-link
              v-for="(color, index) in item.colorPhotos || []"
              :key="index"
              @click.native.stop="clickPhoto(color)"
              :id="`product-library-${color.id}`"
            >
              {{
                `${color.colorName}${
                  index + 1 === (item.colorPhotos || []).length ? "" : ",&nbsp;"
                }`
              }}
            </f7-link>
          </td>
          <td>{{ item.packaging }}</td>
          <td>{{ item.unitPack }}</td>
          <td>{{ item.unitSize }}</td>
          <td>{{ item.uom }}</td>
          <td>{{ item.price | currencyUSD }}</td>
        </template>
        <template slot="paging-footer">
          <div v-if="productComputed.length > 0" class="data-table-footer">
            <div class="data-table-rows-select">
              Per page:
              <div class="input input-dropdown">
                <select
                  @input="onChangeLimit($event.target.value.trim())"
                  :value="productsLibrary.hitsPerPage"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                </select>
              </div>
            </div>
            <div class="data-table-pagination">
              <span
                v-if="productsLibrary.page === 0"
                class="display-flex align-items-center margin-left color-gray"
                disabled
                ><f7-icon f7="chevron_left"></f7-icon>First</span
              >
              <a
                v-else
                @click="onGoToPage('first')"
                class="display-flex align-items-center margin-left "
                disabled
                ><f7-icon f7="chevron_left"></f7-icon>First</a
              >

              <span
                v-if="productsLibrary.page === 0"
                class="margin-left color-gray"
                >Previous</span
              >
              <a v-else @click="onGoToPage('prev')" class="margin-left"
                >Previous</a
              >

              <span class="data-table-pagination-label margin-left">{{
                pagingMessage
              }}</span>

              <a
                v-if="productsLibrary.page < productsLibrary.nbPages - 1"
                @click="onGoToPage('next')"
                class="margin-left"
                >Next</a
              >
              <span v-else class="margin-left color-gray">Next</span>

              <a
                v-if="productsLibrary.page < productsLibrary.nbPages - 1"
                @click="onGoToPage('last')"
                class="display-flex align-items-center margin-left"
                >Last<f7-icon f7="chevron_right"></f7-icon
              ></a>
              <span
                v-else
                class="display-flex align-items-center margin-left color-gray"
                >Last<f7-icon f7="chevron_right"></f7-icon
              ></span>
            </div>
          </div>
        </template>
      </data-table>
    </f7-page>
    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { comparationSort } from "@/utility/filter-tools";
import DataTable from "@/components/datatables";
import { STATUS_PRODUCT_ITEM_ACTIVE } from "../../../../utility/const";
import { isVideo, isImage, createMediaArray, openAttackLink } from "@/utility/common";
import _ from "lodash";

export default {
  components: {
    DataTable
  },

  data: () => ({
    popupOpened: false,
    selectedProductItems: [],
    products: [],
    gridFilter: {
      vendorId: "",
      categoryId: "",
      subCategoryId: "",
      roofType: ""
    },
    productPhotos: []
  }),

  methods: {
    ...mapActions("price-list/price-list-page/product-items", [
      "getProductsByPriceListRefsAndStatus",
      "getFilterData",
      "searchProductItems",
      "goToPage",
      "setProductNumberOfRows",
      "resetSearch",
      "setSearchText"
    ]),

    onSearch: _.debounce(function() {
      this.$f7.preloader.show();
      return this.searchProductItems({
        dataType: "products-library",
        ...this.compileConditionsToSearchByLibrary
      }).finally(() => {
        this.createColorPhotoTooltip();
        this.$f7.preloader.hide();
      });
    }, 500),

    onGoToPage(pageName) {
      this.$f7.preloader.show();
      this.goToPage({
        dataType: "products-library",
        pageName,
        ...this.compileConditionsToSearchByLibrary
      }).finally(() => {
        this.createColorPhotoTooltip();
        this.$f7.preloader.hide();
      });
    },

    onChangeLimit(value) {
      this.setProductNumberOfRows({ dataType: "products-library", value });
      this.onSearch();
    },

    openPopup() {
      const promises = [];
      promises.push(
        this.getProductsByPriceListRefsAndStatus({
          priceListId: this.priceListDetail.id,
          status: STATUS_PRODUCT_ITEM_ACTIVE
        })
      );
      promises.push(
        this.getFilterData({
          dataType: "products-library",
          ...this.compileConditionsToFilterDataByLibrary
        })
      );
      promises.push(this.onSearch());
      Promise.all(promises).then(res => {
        const productsByPL = res[0];
        this.selectedProductItems = (productsByPL || []).map(r => {
          const oldProduct = this.priceListDetail.productRefs.find(
            i => i.productId === r.id
          ) || {};
          const markup = oldProduct.markup ||
            this.priceListDetail.markup || {
              value: 0,
              type: "percent"
            };
          let price = r.price || 0;
          if (markup.type === "percent") {
            price = price * (1 + markup.value / 100);
          } else {
            price += markup.value;
          }
          price = parseFloat(price.toFixed(2));

          return {
            productId: r.id,
            markup,
            price,
            vendorPrice: r.price || 0,
            internalNote: oldProduct.internalNote || "",
            proposalNote: oldProduct.proposalNote || "",
            estServiceDefaultForTypes:
              oldProduct.estServiceDefaultForTypes || []
          };
        });
        this.popupOpened = true;
      });
    },

    closedPopup() {
      this.popupOpened = false;
      this.selectedProductItems = [];
      this.products = [];
      this.gridFilter = {
        vendorId: "",
        categoryId: "",
        subCategoryId: "",
        roofType: ""
      };
      this.productPhotos = [];
      this.setSearchText('');
      this.resetSearch("products-library");
    },
    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    add() {
      this.$emit("addNew", this.selectedProductItems);
      this.closedPopup();
    },

    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: "RooferIntel",
          titleRightText: "now",
          subtitle,
          text,
          closeTimeout: 3000
        });
      }
      self.notificationFull.open();
    },

    selectEvent({ id, checked }) {
      if (checked && !this.selectedProductItems.some(r => r.productId === id)) {
        const product = this.productComputed.find(r => r.id === id);

        const markup = this.priceListDetail.markup || {
          value: 0,
          type: "percent"
        };
        let price = product.price || 0;
        if (markup.type === "percent") {
          price = price * (1 + markup.value / 100);
        } else {
          price += markup.value;
        }
        price = parseFloat(price.toFixed(2));
        this.selectedProductItems.push({
          productId: product.id,
          markup,
          price,
          internalNote: product.internalNote || "",
          proposalNote: product.proposalNote || "",
          estServiceDefaultForTypes: product.estServiceDefaultForTypes || [],
          vendorPrice: product.price || 0
        });
      } else if (checked == false) {
        this.selectedProductItems = this.selectedProductItems.filter(
          r => r.productId != id
        );
      }
    },

    roofTypeNames(roofTypes) {
      const rt = (this.roofTypeList || []).filter(r =>
        roofTypes.includes(r.value)
      );
      return rt.map(r => r.displayName).join(", ");
    },
    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(", ");
    },
    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    },
    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },
    createColorPhotoTooltip() {
      this.productComputed.forEach(item => {
        (item.colorPhotos || []).forEach(color => {
          const photo = (color.photos || [])[0];
          const tooltipContent = photo
            ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
            : `<span>No image !</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${item.id}-${color.id}`,
            cssClass: photo ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
      this.productComputed.forEach(attachMent => {
        (attachMent.technicalData || []).forEach(item => {
          const el1 = this.$f7.tooltip.get(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          if (el1) {
            this.$f7.tooltip.destroy(`#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`);
          }
          const tooltipContent = isImage(item.url)
            ? `<img class="tooltip-image-show" src="${item?.url}" />`
            : isVideo(item.url)? `<video class="tooltip-image-show" src="${item?.url}" ></video>`:`<span>${item?.name}</span>`;
          this.$f7.tooltip.create({
            targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
            cssClass: isImage(item.url) || isVideo(item.url) ? "tooltip-image-preview" : "",
            text: tooltipContent
          });
        });
      });
    }
  },

  computed: {
    ...mapGetters("price-list/roof-type", ["roofTypeList"]),
    ...mapGetters({
      categories: "price-list/price-list-page/category/objectList",
      subCategories: "price-list/price-list-page/sub-category/objectList",
      vendors: "price-list/price-list-page/vendor/objectList"
    }),
    ...mapGetters("price-list/price-list-page/product-items", [
      "productsLibrary",
      "searchText"
    ]),
    ...mapGetters("price-list/price-list-page/price-list", ["priceListDetail"]),

    compileConditionsToFilterDataByLibrary() {
      const statusCondition = `status:${STATUS_PRODUCT_ITEM_ACTIVE}`;
      const roofTypeConditions = [];
      for (const r of this.priceListDetail.roofTypes || []) {
        roofTypeConditions.push(`roofTypes:${r}`);
      }
      let combinedRoofTypesCondition = "";
      if (roofTypeConditions.length > 0) {
        combinedRoofTypesCondition = `(${roofTypeConditions.join(" OR ")})`;
      }
      const filters = combinedRoofTypesCondition
        ? `${statusCondition} AND ${combinedRoofTypesCondition}`
        : statusCondition;
      return { filters };
    },

    compileConditionsToSearchByLibrary() {
      const { vendorId, categoryId, subCategoryId, roofType } = this.gridFilter;
      const filters = [];
      filters.push(`status:${STATUS_PRODUCT_ITEM_ACTIVE}`);
      const roofTypeFilters = [];
      for (const r of this.priceListDetail.roofTypes || []) {
        roofTypeFilters.push(`roofTypes:${r}`);
      }
      if (roofTypeFilters.length > 0) {
        filters.push(`(${roofTypeFilters.join(" OR ")})`);
      }
      if (vendorId) filters.push(`vendorId:${vendorId}`);
      if (categoryId) filters.push(`categoryId:${categoryId}`);
      if (subCategoryId) filters.push(`subCategoryId:${subCategoryId}`);
      if (roofType) {
        filters.push(`roofTypes:${roofType}`);
      }
      let filtersString = filters.join(" AND ");
      return {
        filters: filtersString
      };
    },

    pagingMessage() {
      const fromRow =
        this.productsLibrary.page * this.productsLibrary.hitsPerPage + 1;
      const toRow =
        this.productsLibrary.page * this.productsLibrary.hitsPerPage +
        this.productsLibrary.hits.length;
      return `${fromRow} - ${toRow} of ${this.productsLibrary.nbHits}`;
    },

    vendorFilterOptions() {
      return this.vendors.filter(r =>
        (this.productsLibrary.vendorIdFilterList || []).includes(r.id)
      );
    },
    categoriesFilterOptions() {
      return this.categories.filter(r =>
        (this.productsLibrary.categoryIdFilterList || []).includes(r.id)
      );
    },
    subCategoriesFilterOptions() {
      return this.subCategories.filter(r =>
        (this.productsLibrary.subCategoryIdFilterList || []).includes(r.id)
      );
    },
    roofTypeFilterOptions() {
      return this.roofTypeList.filter(r =>
        (this.productsLibrary.roofTypeFilterList || []).includes(r.value)
      );
    },
    headers() {
      let self = this;
      return [
        {
          text: "SKU",
          value: "sku",
          sortable: false,
          align: "left"
        },
        {
          text: "Product item",
          value: "productItem",
          sortable: false,
          align: "left"
        },
        {
          text: "Manufacturer",
          value: "manufacturer",
          sortable: false,
          align: "left"
        },
        {
          text: "Vendor",
          value: "vendor",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.vendorId,
            selectSource: [
              { value: "", text: "All vendors" },
              ...this.vendorFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.companyName
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.vendorId = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Roof type",
          value: "roofType",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.roofType,
            selectSource: [
              { value: "", text: "All roof types" },
              ...this.roofTypeFilterOptions
                .map(r => ({
                  value: r.value,
                  text: r.displayName
                }))
                .sort((a, b) => comparationSort(a, b))
            ],
            onChange: value => {
              self.gridFilter.roofType = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Product Category",
          value: "productCategory",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.categoryId,
            selectSource: [
              { value: "", text: "All categories" },
              ...this.categoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.categoryId = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Sub Category",
          value: "sub_category",
          sortable: false,
          align: "left",
          filter: {
            type: "select",
            value: self.gridFilter.subCategoryId,
            selectSource: [
              { value: "", text: "All sub categories" },
              ...this.subCategoriesFilterOptions
                .map(r => ({
                  value: r.id,
                  text: r.name
                }))
                .sort((a, b) => comparationSort(a.text, b.text))
            ],
            onChange: value => {
              self.gridFilter.subCategoryId = value;
              this.onSearch();
            }
          }
        },
        {
          text: "Technical data",
          value: "technicalData",
          sortable: false,
          align: "left"
        },
        {
          text: "Color",
          value: "color",
          sortable: false,
          align: "left"
        },
        {
          text: "Packaging",
          value: "packaging",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Packs",
          value: "unitPack",
          sortable: false,
          align: "left"
        },
        {
          text: "Unit Size",
          value: "unitSize",
          sortable: false,
          align: "left"
        },
        {
          text: "UoM",
          value: "uom",
          sortable: false,
          align: "left"
        },
        {
          text: "Price",
          value: "Price",
          sortable: false,
          align: "left"
        }
      ];
    },

    productComputed() {
      const productRefs = this.priceListDetail.productRefs || [];
      return this.productsLibrary.hits.map(r => {
        const overrideProduct =
          productRefs.find(p => p.productId === r.id) || {};
        return {
          ...r,
          checked: this.selectedProductItems.some(p => p.productId === r.id),
          internalNote: overrideProduct.internalNote || "",
          proposalNote: overrideProduct.proposalNote || "",
          estServiceDefaultForTypes:
            overrideProduct.estServiceDefaultForTypes || []
        };
      });
    }
  }
};
</script>

<style scoped>
.image-preview {
  border-radius: 4px;
  width: 70px;
  height: 70px;
  border: 1px solid #e0e0e0;
  position: relative;
  background-position: 50% center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

 .technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
.image-show {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.table-fixed-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
  overflow: hidden;
}
</style>
