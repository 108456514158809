<template>
  <div>
    <f7-block-title
      class="display-flex justify-content-space-between align-items-center"
      style="flex-wrap: wrap; gap: 8px;"
    >
      <div>{{ sectionTitle }}</div>
      <div class="display-flex">
        <f7-button
          outline
          class="margin-right"
          @click="openAddNewProductPopup()"
        >
          Quick Create Product
        </f7-button>
        <f7-button fill @click="openAddProductPopup">
          Add Product
        </f7-button>
      </div>
    </f7-block-title>

    <!-- <div class="data-table">
      <div class="data-table-actions margin-left">
        <f7-block-title>bajkshj</f7-block-title>
        <f7-button @click="openAddProductPopup">Add Product</f7-button>
      </div>
    </div> -->

    <div class="card data-table metal-product-table">
      <table>
        <thead>
          <tr>
            <th
              v-for="(item, index) in headers"
              :key="index"
              :class="item.cssClass"
            >
              {{ item.text }}
            </th>
            <th class="text-align-right" v-if="isEditing">Actions</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length > 0">
          <tr v-for="(product, index) in items" :key="index">
            <td v-if="$f7.device.desktop || $f7.device.ipad" class="label-cell">
              <span v-if="!isEditing">{{ product.productItem }}</span>
              <product-auto-complete-input
                v-else
                :value="product.productItem"
                :data="
                  productListBy({
                    roofType: roofType,
                    categoryId: product.categoryId,
                    subCategoryId: product.subCategoryId
                  })
                "
                @selected="
                  changeProductItem(product, $event.target.value, index)
                "
                @onClickAddProductPhoto="uploadProductPhoto"
              ></product-auto-complete-input>
            </td>
            <td v-else>
              <a @click="$refs.productSheetRef.openSheet(product)">{{
                product.productItem
              }}</a>
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad">
              <f7-list>
                <f7-list-input
                  class="input-note"
                  type="text"
                  placeholder="Enter proposal note"
                  :value="product.proposalNote"
                  @change="
                    updateProductProperty({
                      propName: 'proposalNote',
                      value: $event.target.value.trim(),
                      productId: product.id,
                      index
                    })
                  "
                ></f7-list-input>
              </f7-list>
            </td>
            <td v-else>
              <long-content-block
                :seedId="`note-${product.id}`"
                :content="product.proposalNote"
              ></long-content-block>
            </td>

            <td class="label-cell">
              {{ subCategoryName(product.subCategoryId) }}
            </td>

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="text-align-right"
            >
              <div
                v-if="
                  subCategoryName(product.subCategoryId) === 'Assembly Metal' ||
                    subCategoryName(product.subCategoryId) ===
                      'Assembly Metal Cleat'
                "
              >
                N/A
              </div>
              <cell-number-input
                v-else
                :readonly="!isPer100LFInlineEdit(product)"
                numberType="decimal"
                :fractionDigits="2"
                placeholder="Per 100 LF"
                :value="getPer100LF(product)"
                @done="
                  updateProductProperty({
                    propName: 'per100lf',
                    value: $event,
                    productId: product.id,
                    index
                  })
                "
              ></cell-number-input>
            </td>
            <!-- <td class="numeric-cell">{{ product.actualQty }}</td> -->

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="text-align-right"
            >
              <cell-number-input
                :readonly="!product.isAddManually"
                numberType="decimal"
                :fractionDigits="2"
                placeholder="Actual Quantity"
                :value="product.actualQty"
                @done="
                  updateProductProperty({
                    propName: 'actualQty',
                    value: $event,
                    productId: product.id,
                    index,
                    cb: () => {
                      return updateProductProperty({
                        propName: 'orderQty',
                        value: calculateOrderQty($event, product),
                        productId: product.id,
                        index
                      });
                    }
                  })
                "
                :suffixes="product.uom"
              ></cell-number-input>
            </td>

            <!-- <td class="label-cell">{{ product.packaging }}</td>
            <td class="numeric-cell">{{ product.unitPack }}</td>
            <td class="numeric-cell">{{ product.unitSize }}</td>
            <td class="label-cell">{{ product.uom }}</td> -->
            <!-- <td class="numeric-cell">{{ product.wasterFactor }}</td> -->

            <td title="Price">
              <cell-number-input
                :stepper="'true'"
                placeholder="Price"
                numberType="currency"
                :value="product.price"
                @done="
                  updateProductProperty({
                    propName: 'price',
                    value: $event,
                    productId: product.id,
                    index,
                    cb: () => {
                      updateProductProperty({
                        propName: 'priceOverride',
                        value: true,
                        productId: product.id,
                        index
                      });
                    }
                  })
                "
              ></cell-number-input>
            </td>

            <!-- <td class="text-align-right">
              {{
                ((product.orderQty || 0) * (product.price || 0)) | currencyUSD
              }}
            </td> -->

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              class="text-align-center"
            >
              <f7-checkbox
                :checked="!!product.priceOverride"
                disabled
              ></f7-checkbox>
            </td>

            <td v-if="$f7.device.desktop || $f7.device.ipad" class="label-cell">
              {{ product.color }}
            </td>
            <!-- <td class="label-cell">{{ vendorName(product.vendorId) }}</td> -->
            <td v-if="$f7.device.desktop || $f7.device.ipad" class="label-cell">
              {{ product.manufacturer }}
            </td>

            <td
              v-if="$f7.device.desktop || $f7.device.ipad"
              title="Waster Factor"
            >
              <cell-number-input
                placeholder="Waster Factor"
                numberType="decimal"
                :value="product.wasterFactor"
                :readonly="!isEditing"
                @done="
                  updateProductProperty({
                    propName: 'wasterFactor',
                    value: $event,
                    productId: product.id,
                    index,
                    cb: () => {
                      updateProductProperty({
                        propName: 'wasterFactorOverride',
                        value: true,
                        productId: product.id,
                        index
                      });
                    }
                  })
                "
              ></cell-number-input>
            </td>

            <td
              v-if="isEditing"
              class="text-align-right"
              @click="removeProductItem(product)"
            >
              <a href="#">
                <i class="f7-icons" style="color: red;">trash</i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="label-cell no-data" :colspan="headers.length + 1">
              <div>No items!</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <building-add-product-popup
      :roofType="roofType"
      :sectionId="sectionId"
      :popupAddNewProductItem="popupAddNewProductItem"
      :productItemList="productItemList"
      @close="popupAddNewProductItem = false"
      :existingProductItems="items"
      @onAddProductItems="addProductItems($event)"
      :manufacturers="[]"
      :vendorIds="[]"
      :estimateId="''"
      @copyProduct="openAddNewProductPopup($event)"
      ref="addProductPopup"
    />
    <product-sheet ref="productSheetRef"></product-sheet>

    <image-uploader
      ref="fileInput"
      :preview="false"
      :quality="0.7"
      class="display-none"
      accept="image/*"
      doNotResize="gif"
      :autoRotate="false"
      outputFormat="verbose"
      @input="handleFileUpload"
    ></image-uploader>

    <add-new-product-popup ref="addNewProductPopup"></add-new-product-popup>
  </div>
</template>

<script>
import CellNumberInput from "@/components/inputs/CellNumberInput.vue";
import ProductAutoCompleteInput from "../inputs/ProductAutoCompleteInput.vue";
import BuildingAddProductPopup from "../popup/BuildingAddProductPopup.vue";
import AddNewProductPopup from "../popup/AddNewProductPopup.vue";
import ProductSheet from "../sheets/ProductSheet.vue";
import LongContentBlock from "@/components/blocks/LongContentBlock.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import { METAL_PRODUCT_TABLE_HEARDER } from "../../utility/const";
import {
  DEFAULT_STATUS_PRODUCT_ITEM,
  STATUS_PRODUCT_ITEM_WFA
} from "../../../../utility/const";

import buildingMixins from "../../utility/building-mixins";

export default {
  components: {
    CellNumberInput,
    ProductAutoCompleteInput,
    BuildingAddProductPopup,
    ProductSheet,
    LongContentBlock,
    AddNewProductPopup
  },

  mixins: [buildingMixins],

  props: {
    assembly: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    roofType: String,
    sectionId: String
  },

  data() {
    return {
      METAL_PRODUCT_TABLE_HEARDER,
      isEditing: true,

      popupAddNewProductItem: false,
      photoProductId: ""
    };
  },

  computed: {
    ...mapGetters({
      subCategories: "estimate/estimate-page/estimate/sub-category/objectList",
      vendors: "estimate/estimate-page/estimate/vendor/objectList",
      categories: "estimate/estimate-page/estimate/category/objectList"
    }),

    ...mapGetters("estimate/estimate-page/price-list", {
      priceListObject: "priceList"
    }),

    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),

    vendorName() {
      return id => {
        const vendor = this.vendors.find(r => r.id === id);
        return (vendor || {}).companyName;
      };
    },

    subCategoryName() {
      return subCategoryId => {
        return (
          (this.subCategories.find(item => item.id === subCategoryId) || {})
            .name || ""
        );
      };
    },

    productItemList() {
      return ((this.priceListObject || {}).productRefs || []).map(item => {
        return {
          ...item.product,
          vendorPrice: item.vendorPrice,
          markup: item.markup,
          price: item.price,
          internalNote: item.internalNote,
          proposalNote: item.proposalNote
        };
      });
    },

    priceListProductItemList() {
      return this.productItemList.filter(product => {
        return !this.items.find(item => item.id === product.id);
      });
    },

    productListBy() {
      return ({ roofType, categoryId, subCategoryId }) => {
        return this.productItemList.filter(
          item =>
            (item.roofTypes || []).includes(roofType) &&
            item.categoryId === categoryId &&
            item.subCategoryId === subCategoryId &&
            item.status === DEFAULT_STATUS_PRODUCT_ITEM
        );
      };
    },

    sectionTitle() {
      return this.templateSection(this.roofType, this.sectionId).category;
    },

    // TODO: double check this busibness
    isInlineEdit() {
      // eslint-disable-next-line no-unused-vars
      return subCategoryId => {
        return true;
      };
    },

    getPer100LF() {
      return product => {
        let productSize = 1;
        const subCategoryName = this.subCategoryName(product.subCategoryId);
        switch (subCategoryName) {
          case "Assembly Accessories":
            return product.per100lf;

          case "Assembly Metal":
            productSize = (product.unitSize || 1.0) * (product.unitPack || 1.0);
            return ((this.assembly.stretchOut || 0) / productSize) * 10.0;

          case "Assembly Metal Cleat":
            productSize = (product.unitSize || 1.0) * (product.unitPack || 1.0);
            return ((this.assembly.cleatStretchOut || 0) / productSize) * 10.0;

          case "Assembly Labor":
            return (this.assembly.numberOfBend || 0) * 10.0;

          case "Assembly Labor Cleat":
            return (this.assembly.cleatNumberOfBend || 0) * 10.0;
        }

        return 0;
      };
    },

    isPer100LFInlineEdit() {
      return product => {
        const subCategoryName = this.subCategoryName(product.subCategoryId);
        switch (subCategoryName) {
          case "Assembly Accessories":
            return true;

          case "Assembly Metal":
          case "Assembly Labor":
            return false;
        }

        return true;
      };
    },

    headers() {
      let h = METAL_PRODUCT_TABLE_HEARDER;
      h = h.filter(
        item => item.value != "orderQty" && item.value != "totalCost"
      );
      if (!(this.$f7.device.desktop || this.$f7.device.ipad)) {
        h = h.filter(
          item =>
            item.value != "color" &&
            item.value != "manufacturer" &&
            item.value != "per100lf" &&
            item.value != "waster_factor" &&
            item.value != "actualQty" &&
            item.value != "priceOverride"
        );
      }
      return h;
    }
  },

  methods: {
    ...mapActions("estimate/estimate-page/product-item", [
      "updateProduct",
      "createProduct",
      "uploadPhoto",
      "getProduct"
    ]),

    ...mapActions("estimate/estimate-page/estimate", [
      "deleteEstimateHistoryBys"
    ]),

    ...mapActions("estimate/estimate-page/price-list", ["addProductRefs"]),

    uploadProductPhoto(productId) {
      this.photoProductId = productId;
      this.$refs.fileInput.$el.lastChild.click();
    },

    handleFileUpload(file) {
      const self = this;
      self.$f7.preloader.show();

      this.uploadPhoto(file)
        .then(({ fullPath, url }) => {
          return {
            thumbnailUrl: url,
            thumbnailFullPath: fullPath,
            url: url,
            name: file.info.name,
            fullPath: fullPath
          };
        })
        .then(photo => {
          return self.updateProduct({
            id: self.photoProductId,
            doc: { photos: [photo] }
          });
        })
        .finally(() => {
          self.photoProductId = "";
          self.$refs.fileInput.$el.lastChild.value = "";
          self.$f7.preloader.hide();
        });
    },

    updateEstimateCalculatedFields() {},

    updateProductProperty({ propName, value, productId, cb, index }) {
      this.$emit("onChangeProductItemProp", {
        propName,
        value,
        productId,
        cb,
        productIndex: index
      });
    },

    updateEstimateField() {},
    // checkQtyInlineEdit() {},

    changeProductItem(oldProduct, newProduct, index) {
      newProduct = _.cloneDeep(newProduct);
      if (oldProduct.isAddManually) {
        newProduct.isAddManually = true;
        newProduct.actualQty = oldProduct.actualQty || 0;
        newProduct.orderQty = oldProduct.orderQty || 0;
        delete newProduct.priceOverride;
      } else {
        delete newProduct.isAddManually;
        delete newProduct.priceOverride;
      }
      this.$emit("onChangeProductItem", { oldProduct, newProduct, index });
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
    },

    openAddProductPopup() {
      this.popupAddNewProductItem = true;
    },

    async openAddNewProductPopup(data) {
      const addData = await this.$refs.addNewProductPopup.startEditor(
        "Create New Product",
        data && data.item
          ? {
              ...data.item,
              status: STATUS_PRODUCT_ITEM_WFA,
              priceListRefs: [this.priceListObject.id]
            }
          : {
              sku: "",
              manufacturer: "",
              productItem: "",
              subCategoryId: "",
              categoryId:
                (this.categories.find(r => r.name === this.sectionTitle) || {})
                  .id || "",
              uom: "",
              roofTypes: [this.currentBuilding.roofType],
              price: "",
              wasterFactor: "",
              size: "",
              vendorId: "",
              color: "",
              status: STATUS_PRODUCT_ITEM_WFA,
              priceListRefs: [this.priceListObject.id]
            }
      );
      if (!addData) return;

      this.$f7.preloader.show();
      const productId = await this.createProduct(addData.productData);
      if (data && data.checked) {
        this.$refs.addProductPopup.addProductItem();
      }

      // update productRefs for product
      this.addProductRefs({
        id: this.priceListObject.id,
        product: {
          productId,
          vendorPrice: addData.productData.price,
          price: addData.price,
          markup: addData.markup,
          internalNote: addData.internalNote,
          proposalNote: addData.proposalNote
        }
      }).then(async () => {
        const product = await this.getProduct(productId);
        const sectionProducts = _.cloneDeep(this.items);
        sectionProducts.push({
          ...product,
          vendorPrice: addData.productData.price,
          price: addData.price,
          markup: addData.markup,
          actualQty: 1,
          orderQty: this.calculateOrderQty(1, product),
          isAddManually: true,
          internalNote: addData.internalNote,
          proposalNote: addData.proposalNote
        });
        this.saveProductData({
          sectionId: this.sectionId,
          productList: sectionProducts,
          category: this.templateSection(this.roofType, this.sectionId)
            .category,
          index: this.templateSection(this.roofType, this.sectionId).index
        }).then(() => {
          this.$f7.preloader.hide();
          if (data && data.item) {
            this.popupAddNewProductItem = false;
          }
        });
        // send mail
        this.sendMailAddNewProduct(product);
      });
    },

    async addProductItems(products) {
      const self = this;
      this.$f7.preloader.show();
      const productIds = products.map(item => item.id);
      //remove all products
      if (_.isEmpty(productIds)) {
        if (this.items.length > 0) {
          const productsRemove = this.items;
          for (const prod of productsRemove) {
            await this.handleRemoveProductItem(prod);
          }
        }
        this.popupAddNewProductItem = false;
        this.$f7.preloader.hide();
        return;
      }

      // Step #1:
      const existingProductIds = (this.items || []).map(item => item.id);
      const newProductToAdd = products.filter(
        item => !existingProductIds.includes(item.id)
      );
      // caculate price and save product to section
      newProductToAdd.forEach(item => {
        item.isAddManually = true;
        item.actualQty = 1;
        item.orderQty = this.calculateOrderQty(1, item);
        delete item.priceOverride;
      });

      const tempAssembly = _.cloneDeep(this.assembly);
      if (_.isEmpty(tempAssembly.productList)) {
        tempAssembly.productList = newProductToAdd;
      } else {
        tempAssembly.productList.push(...newProductToAdd);
      }

      this.$emit("onSaveEstimateProduct", {
        assembly: tempAssembly,
        callBack: () => {
          self.popupAddNewProductItem = false;
        }
      });
      // Step #2: keep existing products => do nothing

      // Step #3: remove the product from the section
      const oldProductIds = existingProductIds.filter(
        id => !productIds.includes(id)
      );
      for (const id of oldProductIds) {
        const orgProduct = await this.getProduct(id);
        if (!_.isEmpty(orgProduct)) {
          this.handleRemoveProductItem(orgProduct);
        }
      }
      this.popupAddNewProductItem = false;

      this.$f7.preloader.hide();
      return;
    },
    async handleRemoveProductItem(product) {
      const tempAssembly = _.cloneDeep(this.assembly);
      const sectionProducts = tempAssembly.productList || [];
      if (sectionProducts.some(r => r.id === product.id)) {
        _.remove(sectionProducts, item => item.id === product.id);
        await new Promise(resolve => {
          this.$emit("onSaveEstimateProduct", {
            assembly: tempAssembly,
            callBack: assemblyList => {
              const productList = this.distintProductListFromAssemblyList(
                assemblyList
              );
              if (!productList.some(r => r.id === product.id)) {
                this.deleteEstimateHistoryBys({
                  estimateId: this.estimate.id,
                  conditions: [
                    {
                      prop: "productId",
                      val: product.id,
                      op: "=="
                    },
                    {
                      prop: "buildingId",
                      val: this.currentBuilding.id,
                      op: "=="
                    },
                    {
                      prop: "sectionId",
                      val: this.sectionId,
                      op: "=="
                    }
                  ]
                })
                  .then(() => {
                    resolve();
                  })
                  .catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    resolve();
                  });
              } else {
                resolve();
              }
            }
          });
        });
      }
    },
    removeProductItem(product) {
      this.$ri.dialog.openWarningDialog({
        title: "Remove Product",
        content: "Do you want to remove this product?",
        textButton: "Remove",
        onClick: (_sefl, indexx) => {
          if (indexx === 0) {
            _sefl.app.dialog.close();
          } else if (indexx === 1) {
            this.handleRemoveProductItem(product);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
  color: grey;
}
//mobile css
@media (min-width: 320px) and (max-width: 480px) {
  .card.data-table {
    overflow: auto;
  }
}

.metal-product-table {
  table:first-child {
    table-layout: fixed;

    //Product Item
    th:nth-child(1) {
      width: 200px;
    }

    //Note
    th:nth-child(2) {
      width: 200px;
    }

    // Per 100 LF
    th:nth-child(3) {
      width: 100px;
    }
    // Qty
    th:nth-child(4) {
      width: 100px;
    }
    // Package Qty
    th:nth-child(5) {
      width: 100px;
    }
    // Price
    th:nth-child(6) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(7) {
      width: 100px;
    }
    // Price Override
    th:nth-child(8) {
      width: 110px;
    }

    // Color
    th:nth-child(9) {
      width: 80px;
    }
    // Manufacturer
    th:nth-child(10) {
      width: 120px;
    }

    // Waste Factor
    th:nth-child(11) {
      width: 100px;
    }

    // Action
    th:nth-child(12) {
      width: 70px;
    }

    td {
      word-wrap: break-word;
    }
  }
}

.metal-product-table::v-deep tbody tr td:nth-child(4) {
  ul {
    width: 100%;
    padding-left: 20px;
    li {
      a {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .metal-product-table table:first-child {
    th {
      padding: 0px 10px;
    }
    //Product Item
    th:nth-child(1) {
      width: 140px;
    }
    // Package Qty
    th:nth-child(2) {
      width: 100px;
    }
    // Price
    th:nth-child(3) {
      width: 100px;
    }
    // Total Cost
    th:nth-child(4) {
      width: 120px;
    }

    // Action
    th:nth-child(5) {
      width: 70px;
    }
  }

  .metal-product-table ::v-deep tbody tr {
    td {
      padding: 0px 10px;
    }
  }
}
.input-note ::v-deep .item-content {
  padding-left: 0;
}
</style>

<style>
.data-table tbody td:before {
  z-index: 0;
}
</style>
