<template>
  <f7-page class="contact-list">
    <div class="client-type">
      <div class="display-flex align-items-center">
        <f7-radio
          value="company"
          name="clientType"
          class="margin-right-half"
          :checked="clientType === 'company'"
          @change="onChangeclientType('company')"
        ></f7-radio
        >Company Name
      </div>
      <div class="display-flex align-items-center">
        <f7-radio
          value="contact"
          name="clientType"
          class="margin-right-half"
          :checked="clientType === 'contact'"
          @change="onChangeclientType('contact')"
        ></f7-radio
        >Contact Name
      </div>
    </div>
    <f7-searchbar
      style="width: 100%"
      disable-button-text
      placeholder="Search name"
      :clear-button="true"
      :value="searchValue"
      @input="searchValue = $event.target.value"
      @searchbar:disable="searchValue = ''"
      class="search-list"
    ></f7-searchbar>
    <div class="no-padding-top page-content">
      <f7-list v-if="clientType === 'contact'" class="no-margin-vertical">
        <f7-list-group
          v-for="(group, index) in contactGroup(searchValue)"
          :key="index"
        >
          <f7-list-item :title="group.key" group-title></f7-list-item>
          <f7-list-item
            no-chevron
            link
            v-for="(contact, index) in group.data"
            :key="index"
            :title="contact.contactName"
            @click.native="navigateToEstimateList(contact.id)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
      <f7-list v-else class="no-margin-vertical">
        <f7-list-group
          v-for="(group, index) in companyGroup(searchValue)"
          :key="index"
        >
          <f7-list-item :title="group.key" group-title></f7-list-item>
          <f7-list-item
            no-chevron
            link
            v-for="(company, index) in group.data"
            :key="index"
            :title="company.companyName"
            @click.native="navigateToEstimateList(company.id)"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  data: () => {
    return {
      searchValue: ""
    };
  },

  created() {
    this.$f7.preloader.show();
    if (this.clientType === "contact") {
      this.getContactFacets().finally(() => {
        this.$f7.preloader.hide();
      });
    } else {
      this.getCompanyFacets().finally(() => {
        this.$f7.preloader.hide();
      });
    }
  },

  computed: {
    ...mapGetters("estimate/estimate-page/estimate", [
      "companyList",
      "contactList",
      "companyGroup",
      "contactGroup"
    ]),
    ...mapGetters("estimate/estimate-page/common", ["clientType"])
  },

  methods: {
    ...mapActions("estimate/estimate-page/estimate", [
      "getContactFacets",
      "getCompanyFacets",
      "resetCompanyContactFacets"
    ]),
    ...mapActions("estimate/estimate-page/common", ["setClientType"]),
    onChangeclientType(value) {
      this.setClientType(value);
      this.autoFocusSearchInput();
      if (value === "contact" && _.isEmpty(this.contactList)) {
        this.$f7.preloader.show();
        this.getContactFacets().finally(() => {
          this.$f7.preloader.hide();
        });
      } else if (value === "company" && _.isEmpty(this.companyList)) {
        this.$f7.preloader.show();
        this.getCompanyFacets().finally(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    navigateToEstimateList(id) {
      this.$f7router.navigate(`/estimate/${this.clientType}/${id}`, {
        pushState: true,
        reloadAll: true
      });
    },

    autoFocusSearchInput() {
      if (this.$device.desktop) {
        this.$nextTick(() => {
          this.$el.querySelector(".search-list.searchbar input").focus();
        });
      }
    }
  },
  mounted() {
    this.autoFocusSearchInput();
  },
  beforeDestroy() {
    this.resetCompanyContactFacets();
  }
};
</script>

<style scoped>
.client-type {
  position: absolute;
  left: 0;
  top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
  height: var(--f7-navbar-height);
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-list {
  top: calc(var(--f7-navbar-height) * 2 + var(--f7-safe-area-top)) !important;
  padding: 0 !important;
  margin: 0 !important;
}
.contact-list ::v-deep .page-content {
  padding-top: calc(
    var(--f7-page-navbar-offset, 0px) * 2 +
      var(--f7-page-toolbar-top-offset, 0px) +
      var(--f7-page-subnavbar-offset, 0px) +
      var(--f7-page-searchbar-offset, 0px) +
      var(--f7-page-content-extra-padding-top, 0px)
  );
}
</style>
