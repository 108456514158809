<template>
  <div>
    <data-table
      :headers="tableHeaders"
      :items="laborList"
      :pageSize="laborList.length"
      class="wrap-text-table"
    >
      <template slot="card-header">
        <f7-block class="margin-top-half">
          <f7-row>
            <div>
              <strong>Total: </strong>
              <i>{{ totalLaborAmount | currencyUSD }}</i>
            </div>
          </f7-row>
        </f7-block>
      </template>

      <!-- Table -->
      <template v-slot:body="{ item }">
        <td class="label-cell">
          {{ "Week of " + toDisplayDateString(item.startWeek) }}
        </td>
        <!-- <td class="numeric-cell">{{ item.approvedHour }}</td> -->
        <td class="numeric-cell">{{ item.amount || 0 | currencyUSD }}</td>
        <td class="numeric-cell">
          {{ toDisplayDateString(item.createdDate) }}
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/datatables";
import { mapActions, mapGetters } from "vuex";
import { toDisplayDateString } from "../../../../utility/datetime";

export default {
  components: { DataTable },

  computed: {
    ...mapGetters({
      detail: "swimlane/card/detail"
    }),
    ...mapGetters("swimlane/finance", ["totalLaborAmount", "laborList"]),

    tableHeaders() {
      return [
        {
          text: "Week",
          align: "left",
          value: "week",
          width: "10%"
        },

        {
          text: "Amount",
          align: "right",
          value: "amount",
          width: "10%"
        },

        {
          text: "Generated Date",
          align: "right",
          value: "generated-date",
          width: "10%"
        }
      ];
    }
  },

  methods: {
    ...mapActions("swimlane/finance", ["initTimeTracking"]),

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initData() {
      this.$f7.preloader.show();
      this.initTimeTracking(this.detail.id).finally(() => {
        this.$f7.preloader.hide();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.label-cell {
  padding-left: 10px;
}
</style>
