import * as types from "./types";
import Vue from "vue";

export default {
  setEstimateList(state, payload) {
    Vue.set(state, "estimateList", payload);
  },
  setEstimateSubscribe(state, payload) {
    Vue.set(state, "estimateSubscribe", payload);
  },
  [types.SET_ESTIMATE_FIELD](state, payload) {
    Vue.set(state.estimate, payload.fieldName, payload.value);
  }
};
