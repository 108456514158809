import * as types from "./types";
import Vue from "vue";
import _ from "lodash";

export default {
  [types.RESET_SELECT_STATE](state) {
    Vue.set(state, "selectionMode", false);
    Vue.set(state, "selectedPhotoIds", []);
  },
  [types.CHANGE_SELECT_MODE](state, isSelect) {
    Vue.set(state, "selectionMode", isSelect);
  },
  [types.UPDATE_SELECTED_IDS](state, id) {
    let selectedPhotoIds = state.selectedPhotoIds;
    if (selectedPhotoIds.includes(id)) {
      var index = selectedPhotoIds.indexOf(id);
      if (index > -1) {
        selectedPhotoIds.splice(index, 1);
      }
    } else {
      selectedPhotoIds.push(id);
    }
    Vue.set(state, "selectedPhotoIds", _.cloneDeep(selectedPhotoIds));
  }
};
