import { FirebaseActions } from "../../../../services/firebase.service";
import _ from "lodash";

export default tenant => {
  const priceActions = new FirebaseActions(
    `/system_client/${tenant}/product_price_list`,
    "price"
  );
  return {
    updatePrice: priceActions.updateDocument,

    getPrice: priceActions.getDocument,

    removeProductRefs({ dispatch }, { id, productId }) {
      dispatch("getPrice", id).then(price => {
        if (price) {
          const productRefs = _.remove(price.productRefs || [], product => {
            return productId != product.productId;
          });
          dispatch("updatePrice", {
            id,
            doc: { productRefs }
          });
        }
      });
    }
  };
};
