import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const activityActions = new FirebaseActions(
    `/system_client/${tenant}/activity`,
    "activity"
  );
  return {
    bindActivityList: activityActions.bindCollection,
    unbindActivityList: activityActions.unbindCollection,
    createActivity: activityActions.createDocument
  };
};
