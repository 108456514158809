export default {
  order: "asc",
  contactSearchText: "",

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  contactList: [],
  queryFilters: ""
};
