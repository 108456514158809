import axiosService from "@/services/axios.service";
import { firebase, auth } from "../../../services/firebase.service";
import userService from "../../../services/user.service";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("scheduling/notifications", [
      "getEmailNotificationBys",
      "deleteEmailNotification",
      "getEmailNotification"
    ]),
    ...mapActions("scheduling/project", ["getProjectById"]),

    async sendCreatedEmailNotification(scheduleData) {
      let user = await userService.getUser(scheduleData.userId);
      let project =
        (scheduleData.projectId &&
          (await this.getProjectById(scheduleData.projectId))) ||
        {};
      let createByDisplayName =
        auth.currentUser.displayName == user.displayName
          ? "You"
          : auth.currentUser.displayName;
      return axiosService
        .post("/notification/push-email", {
          to: user.email,
          subject: {
            created_by: createByDisplayName || ""
          },
          template: "created-schedule",
          content: {
            title:
              scheduleData.title ||
              project.title ||
              scheduleData.timeOffType ||
              "",
            time_log_type: scheduleData.timeLogType,
            notes: scheduleData.notes,
            date:
              typeof scheduleData.date.toDate === "function"
                ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
                : scheduleData.date,
            start_time: scheduleData.startTime,
            finish_time: scheduleData.finishTime,
            created_by: createByDisplayName || "",
            user_display_name: user.displayName,
            detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/my-scheduling`
          },
          attachments: [],
          scheduleSendingTime: firebase.firestore.Timestamp.now()
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },
    async sendCreatedChainEmailNotification(chain, scheduleDatas) {
      let scheduleData = scheduleDatas[0];
      let user = await userService.getUser(scheduleData.userId);
      let project =
        (scheduleData.projectId &&
          (await this.getProjectById(scheduleData.projectId))) ||
        {};
      let createByDisplayName =
        auth.currentUser.displayName == user.displayName
          ? "You"
          : auth.currentUser.displayName;
      return axiosService
        .post("/notification/push-email", {
          to: user.email,
          subject: {
            created_by: createByDisplayName || ""
          },
          template: "created-chain",
          content: {
            title:
              scheduleData.title ||
              project.title ||
              scheduleData.timeOffType ||
              "",
            time_log_type: scheduleData.timeLogType,
            notes: scheduleData.notes,
            date:
              typeof scheduleData.date.toDate === "function"
                ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
                : scheduleData.date,
            start_time: scheduleData.startTime,
            finish_time: scheduleData.finishTime,
            start_date: moment(chain.startDate.toDate()).format("MM/DD/YYYY"),
            end_date: moment(chain.endDate.toDate()).format("MM/DD/YYYY"),
            created_by: createByDisplayName || "",
            user_display_name: user.displayName
          },
          attachments: [],
          scheduleSendingTime: firebase.firestore.Timestamp.now()
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },
    async sendUpdatedEmailNotification(scheduleData) {
      let user = await userService.getUser(scheduleData.userId);
      let project =
        (scheduleData.projectId &&
          (await this.getProjectById(scheduleData.projectId))) ||
        {};
      let createByDisplayName =
        auth.currentUser.displayName == user.displayName
          ? "You"
          : auth.currentUser.displayName;

      return axiosService
        .post("/notification/push-email", {
          to: user.email,
          subject: {
            created_by: createByDisplayName || ""
          },
          template: "updated-schedule",
          content: {
            title:
              scheduleData.title ||
              project.title ||
              scheduleData.timeOffType ||
              "",
            time_log_type: scheduleData.timeLogType,
            notes: scheduleData.notes,
            date:
              typeof scheduleData.date.toDate === "function"
                ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
                : scheduleData.date,
            start_time: scheduleData.startTime,
            finish_time: scheduleData.finishTime,
            created_by: createByDisplayName || "",
            user_display_name: user.displayName,
            detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/my-scheduling`
          },
          attachments: [],
          scheduleSendingTime: firebase.firestore.Timestamp.now()
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },
    async sendDeleteChainEmailNotification(chain, scheduleData) {
      let user = await userService.getUser(scheduleData.userId);
      let project =
        (scheduleData.projectId &&
          (await this.getProjectById(scheduleData.projectId))) ||
        {};
      let createByDisplayName =
        auth.currentUser.displayName == user.displayName
          ? "You"
          : auth.currentUser.displayName;
      // delete all reminder emails of schedule
      this.deleteReminderEmailNotificationByChain(chain.id);

      return axiosService
        .post("/notification/push-email", {
          to: user.email,
          subject: {
            created_by: createByDisplayName || ""
          },
          template: "delete-chain",
          content: {
            title:
              scheduleData.title ||
              project.title ||
              scheduleData.timeOffType ||
              "",
            time_log_type: scheduleData.timeLogType,
            notes: scheduleData.notes,
            date:
              typeof scheduleData.date.toDate === "function"
                ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
                : scheduleData.date,
            start_time: scheduleData.startTime,
            finish_time: scheduleData.finishTime,
            start_date:
              chain && moment(chain.startDate.toDate()).format("MM/DD/YYYY"),
            end_date:
              chain && moment(chain.endDate.toDate()).format("MM/DD/YYYY"),
            created_by: createByDisplayName || "",
            user_display_name: user.displayName
          },
          attachments: [],
          scheduleSendingTime: firebase.firestore.Timestamp.now()
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },
    async sendDeleteScheduleEmailNotification(scheduleData) {
      let user = await userService.getUser(scheduleData.userId);
      let project =
        (scheduleData.projectId &&
          (await this.getProjectById(scheduleData.projectId))) ||
        {};
      let createByDisplayName =
        auth.currentUser.displayName == user.displayName
          ? "You"
          : auth.currentUser.displayName;
      // delete all reminder emails of schedule
      this.deleteReminderEmailNotificationBySchedule(scheduleData.id);

      return axiosService
        .post("/notification/push-email", {
          to: user.email,
          subject: {
            created_by: createByDisplayName || ""
          },
          template: "delete-schedule",
          content: {
            title:
              scheduleData.title ||
              project.title ||
              scheduleData.timeOffType ||
              "",
            time_log_type: scheduleData.timeLogType,
            notes: scheduleData.notes,
            date:
              typeof scheduleData.date.toDate === "function"
                ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
                : scheduleData.date,
            start_time: scheduleData.startTime,
            finish_time: scheduleData.finishTime,
            created_by: createByDisplayName || "",
            user_display_name: user.displayName,
            detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/my-scheduling`
          },
          attachments: [],
          scheduleSendingTime: firebase.firestore.Timestamp.now()
        })
        .then(() => {
          // TODO: this trigger will be removed when implement email mail service scheduler
          return axiosService.post("/notification/trigger-email-service", {
            sender: ""
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("[Send Email]", error);
          throw new Error(error.message);
        });
    },
    async addRemindScheduleStartEmailNotification(scheduleData) {
      let user = await userService.getUser(scheduleData.userId);

      let project =
        (scheduleData.projectId &&
          (await this.getProjectById(scheduleData.projectId))) ||
        {};
      let createByDisplayName =
        auth.currentUser.displayName == user.displayName
          ? "You"
          : auth.currentUser.displayName;
      if (!scheduleData.date) return;
      let startDateTime =
        (typeof scheduleData.date.toDate === "function"
          ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
          : scheduleData.date) +
        " " +
        scheduleData.startTime;

      //will not send email when start time less then current time.
      if (moment(startDateTime, "MM/DD/YYYY HH:mm").isBefore(moment())) return;

      let scheduleSendingTime = firebase.firestore.Timestamp.fromDate(
        new Date(moment(startDateTime, "MM/DD/YYYY HH:mm").subtract(60, "m"))
      );
      axiosService.post("/notification/push-email", {
        to: user.email,
        subject: {
          created_by: createByDisplayName || "",
          mins: 30,
          title:
            scheduleData.title ||
            project.title ||
            scheduleData.timeOffType ||
            ""
        },
        template: "start-schedule",
        content: {
          title:
            scheduleData.title ||
            project.title ||
            scheduleData.timeOffType ||
            "",
          time_log_type: scheduleData.timeLogType,
          notes: scheduleData.notes,
          date:
            typeof scheduleData.date.toDate === "function"
              ? moment(scheduleData.date.toDate()).format("MM/DD/YYYY")
              : scheduleData.date,
          start_time: scheduleData.startTime,
          finish_time: scheduleData.finishTime,
          created_by: createByDisplayName || "",
          user_display_name: user.displayName,
          detail_url: `${import.meta.env.VITE_HOST_DOMAIN}/my-scheduling`
        },
        attachments: [],
        scheduleSendingTime: scheduleSendingTime,
        scheduleChainId: scheduleData.scheduleChainId || null,
        scheduleId: scheduleData.id || null
      });
    },
    async deleteReminderEmailNotificationBySchedule(scheduleId) {
      let emails = await this.getEmailNotificationBys([
        {
          prop: "scheduleId",
          val: scheduleId,
          op: "=="
        }
      ]);
      emails &&
        emails.forEach(element => {
          this.deleteEmailNotification(element.id);
        });
    },
    async deleteReminderEmailNotificationByChain(chainId) {
      let emails = await this.getEmailNotificationBys([
        {
          prop: "scheduleChainId",
          val: chainId,
          op: "=="
        }
      ]);
      emails &&
        emails.forEach(element => {
          this.deleteEmailNotification(element.id);
        });
    },
    async updateReminderEmailNotification(scheduleData) {
      this.deleteReminderEmailNotificationBySchedule(scheduleData.id);
      this.addRemindScheduleStartEmailNotification(scheduleData);
    }
  }
};
