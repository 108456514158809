<template>
  <f7-popup
    tablet-fullscreen
    class="demo-popup"
    :opened="popupOpened"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Product Item</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <default-product-table
        ref="addDefaultProductTable"
        id="add-default-table"
        dataType="products-by-price-list"
        :isCheckbox="true"
        :isShowActions="false"
        @onSelectCheckBox="onSelectProduct"
      ></default-product-table>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultProductTable from "../tables/DefaultProductTable.vue";
import _ from "lodash";

export default {
  components: {
    DefaultProductTable
  },

  data() {
    return {
      popupOpened: false,
      selectedProductsMap: {}
    };
  },

  computed: {
    ...mapGetters("estimate/estimate-page/price-list", ["currentPriceList"]),
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ])
  },

  methods: {
    ...mapActions("estimate/estimate-page/price-list", ["updatePriceList"]),
    ...mapActions("estimate/estimate-page/product-default", [
      "algoliaUpdateItemsWithDataList",
      "algoliaDeleteItems"
    ]),

    open() {
      this.popupOpened = true;
      this.$refs.addDefaultProductTable.onSearch();
    },

    close() {
      this.popupOpened = false;
      this.$refs.addDefaultProductTable.resetData();
      this.$emit("doAfterClose");
    },

    onSelectProduct({ products, page }) {
      this.selectedProductsMap[`${page}`] = _.cloneDeep(products);
    },

    getSelectedProducts() {
      const list = [];
      for (const key in this.selectedProductsMap) {
        const elements = this.selectedProductsMap[key] || [];
        if (_.isArray(elements) && elements.length > 0) {
          list.push(...elements);
        }
      }

      return list;
    },

    async done() {
      if (this.getSelectedProducts().length === 0) {
        this.$ri.dialog.openErrorDialog({
          title: "Error",
          content: "Please select a product item to add default",
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          }
        });
        return;
      }
      this.$f7.preloader.show();
      // update estServiceDefaultForTypes field for productRefs in PL
      const promises = [];
      const productIdsRemove = [];
      const productRefs = (
        _.cloneDeep(this.currentPriceList.productRefs) || []
      ).map(r => {
        if (this.getSelectedProducts().some(j => j.id === r.productId)) {
          return {
            ...r,
            estServiceDefaultForTypes: _.uniq([
              ...(r.estServiceDefaultForTypes || []),
              this.currentBuilding.roofType
            ])
          };
        } else if (
          r.estServiceDefaultForTypes &&
          r.estServiceDefaultForTypes.includes(this.currentBuilding.roofType)
        ) {
          const product = _.cloneDeep(r);
          const productUnit = [...product.estServiceDefaultForTypes].filter(
            r => r !== "all-roof-type"
          );
          if (productUnit.length === 1) {
            productIdsRemove.push(product.productId);
          }
          product.estServiceDefaultForTypes = product.estServiceDefaultForTypes.filter(
            r => r !== this.currentBuilding.roofType
          );
          return product;
        } else {
          return r;
        }
      });

      promises.push(
        this.updatePriceList({
          id: this.currentPriceList.id,
          doc: { productRefs }
        })
      );
      // create/update algolia entity: estimate_service_default_product
      promises.push(
        this.algoliaUpdateItemsWithDataList(
          this.getSelectedProducts().map(r => ({
            ...r,
            priceListId: this.currentPriceList.id,
            estServiceDefaultForTypes: _.uniq([
              ...(r.estServiceDefaultForTypes || []),
              this.currentBuilding.roofType
            ])
          }))
        )
      );
      if (productIdsRemove.length) {
        promises.push(
          this.algoliaDeleteItems({
            productIds: productIdsRemove,
            priceListId: this.currentPriceList.id
          })
        );
      }
      Promise.all(promises).then(() => {
        this.$f7.preloader.hide();
        this.close();
      });
    }
  }
};
</script>

<style></style>
