<template>
  <div>
    <!-- add new page first -->
    <!-- <div v-if="index === 0" class="button-container">
      <f7-button
        class="col button color-green"
        :popover-open="`.popover-menu-${-1}`"
      >
        Add New Page
      </f7-button>
      |
      <f7-button class="col button color-red" @click="removeFirstPage">
        Delete This Page
      </f7-button>
      <f7-popover :class="`popover-menu-${-1}`">
        <f7-list>
          <f7-list-item
            link
            @click="addNewPage(TEXT_EDITOR_PAGE, -1)"
            popover-close
            :title="TEXT_EDITOR_PAGE"
          ></f7-list-item>
          <f7-list-item
            v-if="isTemplate"
            link
            @click="addNewPage(BUILDING_DESCRIPTION_PAGE, -1)"
            popover-close
            :title="BUILDING_DESCRIPTION_PAGE"
          ></f7-list-item>
          <f7-list-item
            link
            @click="addNewPage(BUILDING_PRODUCT_PAGE, -1)"
            popover-close
            :title="BUILDING_PRODUCT_PAGE"
          ></f7-list-item>
          <f7-list-item
            link
            @click="addNewPage(IMAGE_PAGE, -1)"
            popover-close
            :title="IMAGE_PAGE"
          ></f7-list-item>
        </f7-list>
      </f7-popover>
    </div> -->
    <div class="page-form">
      <div class="col page-number color-gray">
        <span>Page {{ index + 1 }}</span>
      </div>
      <page-component
        v-for="(item, i) in data.components"
        :key="i"
        :data="item"
        :pageName="data.name"
        :isTemplate="isTemplate"
        @change="$event => change($event, i)"
      >
      </page-component>
    </div>
    <!-- add new page -->
    <div class="button-container">
      <f7-button
        class="col button color-green"
        :popover-open="`.popover-menu-${index}`"
      >
        Add New Page
      </f7-button>
      <div v-if="!isLastPage">|</div>
      <f7-button
        v-if="!isLastPage"
        class="col button color-red"
        @click="removePage"
      >
        Delete This Page
      </f7-button>
      <f7-popover :class="`popover-menu-${index}`">
        <f7-list>
          <f7-list-item
            link
            @click="addNewPage(TEXT_EDITOR_PAGE, index)"
            popover-close
            :title="TEXT_EDITOR_PAGE"
          ></f7-list-item>
          <f7-list-item
            v-if="isTemplate"
            link
            @click="addNewPage(BUILDING_DESCRIPTION_PAGE, index)"
            popover-close
            :title="BUILDING_DESCRIPTION_PAGE"
          ></f7-list-item>
          <f7-list-item
            link
            @click="addNewPage(BUILDING_PRODUCT_PAGE, index)"
            popover-close
            :title="BUILDING_PRODUCT_PAGE"
          ></f7-list-item>
          <f7-list-item
            link
            @click="addNewPage(IMAGE_PAGE, index)"
            popover-close
            :title="IMAGE_PAGE"
          ></f7-list-item>
        </f7-list>
      </f7-popover>
    </div>
  </div>
</template>

<script>
import PageComponent from "./PageComponent.vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import { TABLE_CONTENT_TYPE_SIMPLE_SUMMARY } from "@/utility/const";
import {
  TEXT_EDITOR_PAGE,
  BUILDING_DESCRIPTION_PAGE,
  BUILDING_PRODUCT_PAGE,
  IMAGE_PAGE
} from "../../utility/const";

export default {
  components: {
    PageComponent
  },
  props: {
    data: Object,
    index: Number,
    isTemplate: { type: Boolean, default: false },
    isLastPage: { type: Boolean, default: false }
  },
  data: () => {
    return {
      TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
      TEXT_EDITOR_PAGE,
      BUILDING_DESCRIPTION_PAGE,
      BUILDING_PRODUCT_PAGE,
      IMAGE_PAGE
    };
  },
  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    })
  },
  methods: {
    change($event, index) {
      let { isOverflow } = $event;
      if (isOverflow) {
        this.addNewPage(TEXT_EDITOR_PAGE, this.index);
      } else {
        let components = _.cloneDeep(this.data.components);
        components[index].data = $event;
        this.$emit("change", components);
      }
    },
    addNewPage(pageType, index) {
      this.$emit("addNewPage", {
        pageType,
        index
      });
    },
    removeFirstPage() {
      this.$emit("removePage", 0);
    },
    removePage() {
      this.$emit("removePage", this.index + 1);
    }
  }
};
</script>

<style scoped>
.page-form {
  width: 825px;
  min-height: 1300px;
  padding: 30px 25px 30px 50px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 14px;
  box-sizing: border-box;
  border-color: #e5e7f2;
  background-color: white;
  box-shadow: 0 4px 4px rgba(87, 100, 126, 0.21);
  border-radius: 2px;
  padding-bottom: 140px;
  position: relative;
}
.page-form .page-component {
  margin: 20px 0;
}
.page-form .page-component:hover {
  box-shadow: 0 0 4px 4px rgba(87, 100, 126, 0.21);
}
div.page-form div:last-child {
  position: absolute;
  bottom: 20px;
  width: 90%;
}
div.page-form .page-number {
  position: absolute;
  top: -35px;
  right: 0px;
  width: 100px;
  font-weight: 500;
  color: gray;
  text-align: right;
  padding-right: 10px;
}
/* div.page-form .page-number:hover span {
  display: none;
}
div.page-form .page-number:hover:before {
  content: "Remove This Page";
} */
div.button-container {
  display: flex;
  justify-content: center;
}
div.button-container button {
  max-width: 150px;
}
</style>
