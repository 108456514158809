<template>
  <thead>
    <tr>
      <th v-if="checkbox">
        <f7-checkbox
          :indeterminate="indeterminate"
          :checked="checked"
          @change="
            $emit('selectCheckbox', {
              type: 'header',
              checked: $event.target.checked
            })
          "
        ></f7-checkbox>
      </th>
      <th
        v-for="(props, index) in headers"
        :key="index"
        :ref="`header-${index}`"
        :style="{
          left: pinnedHeaders[index] ? pinWidth[index] : 'auto',
          boxShadow: getLastTrue(pinnedHeaders, index) ? '7px 0px 5px #000' : ''
        }"
        :class="
          `
            
            ${props.sortable ? iconSort || 'sortable-cell' : ''}
            ${textAlign(props.align)} ${props.cssClass || ''}`
        "
        @click="$emit('sortColumn', props)"
        :width="props.width"
      >
        {{ props.text }}
        <span v-if="props.isPin" class="icon-pin">
          <span
            v-if="!pinnedHeaders[index] && !$device.desktop"
            @click="handlePinHeader(index)"
          >
            <f7-icon f7="pin"></f7-icon>
          </span>
          <span
            v-if="pinnedHeaders[index] && !$device.desktop"
            @click="handlePinHeader(index)"
          >
            <f7-icon f7="pin_fill"></f7-icon>
          </span>
        </span>
      </th>
    </tr>
    <tr v-if="hasFilter || hasSelectAll">
      <th v-if="checkbox"></th>
      <th
        v-for="(props, index) in headers"
        :key="index"
        :style="{
          left: pinnedHeaders[index] ? pinWidth[index] : 'auto',
          boxShadow: getLastTrue(pinnedHeaders, index) ? '7px 0px 5px #000' : ''
        }"
        :class="
          `
          input-cell ${hasSelectAll ? 'text-align-center' : ''} 
           ${
             pinnedHeaders[index] && !$device.desktop ? 'blank-input-cell' : ''
           } 
        `
        "
      >
        <div v-if="props.filter" class="input">
          <input
            class="search-text-box"
            v-if="props.filter.type === 'text'"
            type="text"
            :placeholder="props.filter.placeholder || 'Filter'"
            :value="props.filter.value"
            @input="
              v =>
                props.filter.onChange && props.filter.onChange(v.target.value)
            "
          />
          <select
            class="search-text-box"
            v-else-if="props.filter.type === 'select'"
            :value="props.filter.value"
            @input="
              v =>
                props.filter.onChange && props.filter.onChange(v.target.value)
            "
          >
            <option
              v-for="opt in props.filter.selectSource"
              :key="opt.value"
              :value="opt.value"
            >
              {{ opt.text }}
            </option>
          </select>
        </div>
        <div>
          <f7-checkbox
            v-if="props.selectAll"
            :class="`select-border-${props.selectAll.borderColor}`"
            :checked="props.selectAll.value"
            @change="
              v =>
                props.selectAll.onChange &&
                props.selectAll.onChange(v.target.checked)
            "
          ></f7-checkbox>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: {
    headers: Array,
    checkbox: Boolean,
    iconSort: String,
    indeterminate: Boolean,
    dataTable: Array,
    checked: Boolean,
    pinWidth: Array
  },
  data() {
    return {
      pinnedHeaders: [null, true]
    };
  },
  methods: {
    textAlign(align) {
      return align === "left"
        ? "text-align-left"
        : align === "center"
        ? "text-align-center"
        : align === "right"
        ? "text-align-right"
        : "text-align-left";
    },
    handlePinHeader(index) {
      this.$set(this.pinnedHeaders, index, !this.pinnedHeaders[index]);
      this.$emit("pinArray", this.pinnedHeaders);
    },
    getColumnWidth(index) {
      const headerRef = this.$refs[`header-${index}`];
      return headerRef && headerRef[0] ? headerRef[0].offsetWidth : 0;
    },
    getLastTrue(Array, index) {
      let last = -1;
      for (let i = 0; i < Array.length; i++) {
        if (Array[i] === true) {
          last = i;
        }
      }
      if (last === index) {
        return true;
      }
      return false;
    }
  },
  computed: {
    hasFilter() {
      return this.headers.some(header => header.filter);
    },
    hasSelectAll() {
      return this.headers.some(header => header.selectAll);
    },
    getColumnLeft() {
      return index => {
        const firstColWidth = this.getColumnWidth(0);
        const truePinnedHeaders = this.pinnedHeaders.filter(Boolean);
        if (truePinnedHeaders.length < 2) {
          return "0px";
        }
        const pinnedColumns = index === 1 ? firstColWidth : 0;
        return pinnedColumns + "px";
      };
    }
  }
};
</script>

<style scoped>
.search-text-box {
  font-size: var(--f7-table-head-font-size);
  border: 1px solid grey;
  padding: 0px 6px;
  border-radius: 6px;
}
.input-cell {
  padding-top: 0px !important;
}
.select-border-white ::v-deep .icon-checkbox {
  border-color: white;
}
.icon-pin {
  margin-left: 6px;
  color: #f05034;
}
.pinned-column {
  position: sticky;
  z-index: 2;
  background-color: var(--f7-color-bg-4-neutral);
}
.blank-input-cell {
  position: sticky;
  z-index: 2;
  background-color: var(--f7-color-bg-4-neutral);
}
th {
  overflow: visible !important;
}
.pinned-column::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  box-shadow: 7px 0 5px rgba(10, 10, 10, 0.9);
  top: 0;
  right: 0;
}
</style>
