import client from "./client";
import appConstant from "./app-constant";
import project from "./project";
import contactHistory from "./contact-history";
import contact from "./contact";
import company from "./company";
import contactMessage from "./contact-message";
import property from "./property";

export default tenant => {
  return {
    namespaced: true,
    modules: {
      client: client(tenant),
      "app-constant": appConstant(tenant),
      project: project(tenant),
      "contact-history": contactHistory(tenant),
      contact: contact(tenant),
      company: company(tenant),
      "contact-message": contactMessage(tenant),
      property: property(tenant)
    }
  };
};
