export default {
  stackctProjects: state => {
    return state.stackctProjects
      .filter(r => r.TakeoffIds.length > 0)
      .map(r => ({ ...r, TakeOffId: r.TakeoffIds[0] }));
  },
  stackctTakeoffs: state => state.stackctTakeoffs,
  stackctPages: state => state.stackctPages,
  stackTakeOffByPages: state => pageIds => {
    let pages =
      state.stackctPages.filter(r => (pageIds || []).includes(r.PageId)) || [];
    const checkPlan = (pages, geometries) => {
      let geo = geometries && geometries[0];
      let pageId = geo && geo.pageId;
      if (!pageId) return false;
      return pages.some(r => r.PageId == pageId);
    };
    return state.stackctTakeoffs.filter(r => checkPlan(pages, r.Geometries));
  }
};
