export default {
  contactList: state => state.contactList || [],

  contact: state => state.contact || {},

  companyRefs: state => state.companyRefs || [],

  isEditContact: state => state.isEditContact,

  isNewContact: state => state.isNewContact
};
