<template>
  <f7-page class="swimlane-page" :style="backgroundImage">
    <!-- Navigation bar -->
    <construction-navbar></construction-navbar>

    <board-toolbar></board-toolbar>
    <construction-menu
      @onAddNewTask="handleAddNewTask"
      @onResolveConstructionAction="handleDoResolve"
    ></construction-menu>

    <!-- <f7-tabs animated>
      <f7-tab
        ref="swimlane-tab-sales"
        id="swimlane-tab-sales"
        class="page-content"
        tab-active
      > -->
    <workorder-swimlane-board
      v-if="constructionProjectId"
      style="background: transparent;"
      class="custom-margin-top"
      @onOpenPopup="openCardDetailsPopup"
    ></workorder-swimlane-board>
    <!-- </f7-tab>
    </f7-tabs> -->

    <!-- Popup -->
    <wo-task-details-popup
      ref="woTaskDetailsPopup"
      @navigateToBoard="navigateToBoard"
      @gotoDetails="openCardDetailsPopup"
    ></wo-task-details-popup>

    <wo-task-new-popup
      ref="woTaskNewPopup"
      @gotoDetails="openCardDetailsPopup"
      @navigateToBoard="navigateToBoard"
    ></wo-task-new-popup>

    <construction-renderless
      ref="constructionRenderless"
    ></construction-renderless>
  </f7-page>
</template>

<script>
import WorkorderSwimlaneBoard from "../board/WorkorderSwimlaneBoard.vue";
import WoTaskDetailsPopup from "../../components/popup/WoTaskDetailsPopup.vue";
import BoardToolbar from "../../components/toolbar/BoardToolbar.vue";
import WoTaskNewPopup from "../../components/popup/WoTaskNewPopup.vue";
import ConstructionMenu from "../../components/menu/ConstructionMenu.vue";
import ConstructionNavbar from "../../components/navbar/ConstructionNavbar.vue";
import ConstructionRenderless from "../../components/renderless/ConstructionRenderless.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import { PROP_IS_DELETED } from "../../../../utility/const";

export default {
  components: {
    WorkorderSwimlaneBoard,
    WoTaskDetailsPopup,
    BoardToolbar,
    ConstructionMenu,
    WoTaskNewPopup,
    ConstructionNavbar,
    ConstructionRenderless
  },

  data() {
    const dark = localStorage.getItem("themeDark") === "true";
    const imgUrl = dark
      ? "https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/dashboard%2Fsales-board-dark.jpg?alt=media&token=2f771661-0968-41e6-bbdc-47f70a3ba912"
      : "https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/dashboard%2Fsales-board-light.jpg?alt=media&token=af0e5a92-6824-400e-8ae0-5b25e9c67cc2";
    return {
      backgroundImage: `background-image: url(${imgUrl});`
    };
  },

  computed: {
    ...mapGetters("dashboard/project", [
      "users",
      "constructionProjectId",
      "constructionProject"
    ]),
    ...mapGetters("dashboard/work-order", ["workOrderActionList"])
  },

  created() {
    const cardNumber = this.$f7route.params.cardNumber;

    if (!_.isEmpty(cardNumber)) {
      this.$f7.preloader.show();
      let refs = [];
      // get project by cardNumber
      this.getProjectByCardNumber(cardNumber).then(res => {
        if (!_.isEmpty(res)) {
          refs.push(
            this.bindWorkOrderTaskListBys([
              {
                op: "==",
                prop: "projectId",
                val: this.constructionProjectId
              },
              {
                op: "==",
                prop: PROP_IS_DELETED,
                val: false
              }
            ])
          );
          if (_.isEmpty(this.users)) {
            refs.push(this.bindUserData());
          }

          if (_.isEmpty(this.workOrderActionList)) {
            refs.push(this.bindWorkOrderActionList());
          }

          refs.push(this.getConstantTypeList());

          Promise.all(refs).then(() => {
            this.setCurrentCard(cardNumber);
            this.$f7.preloader.hide();

            if (this.$f7route.route.meta.isNewTask === true) {
              // open add new task popup
              this.$refs.woTaskNewPopup.initPopupData();
              this.$refs.woTaskNewPopup.openPopup();
            }
          });
        } else {
          this.$f7.notification
            .create({
              title: "Not found project",
              closeTimeout: 15000,
              closeButton: true,
              text: `Not found project <b class='color-red'>${cardNumber}</b>. Please choose another project.`,
              closeOnClick: true
            })
            .open();
          this.$f7.preloader.hide();
        }
      });
    }
  },

  mounted() {
    if (this.$f7route.params.taskNumber && this.$f7route.params.actionCode) {
      this.$f7.preloader.show();
      // get action by action code
      this.getWorkOrderActionBys([
        {
          prop: "code",
          val: Number(this.$f7route.params.actionCode),
          op: "=="
        }
      ])
        .then(action => {
          if (_.isEmpty(action)) {
            this.$f7.notification
              .create({
                title: "Not found action",
                closeTimeout: 15000,
                closeButton: true,
                text: `Not found action code is <b>${this.$f7route.params.actionCode}</b>. Please choose another action code.`,
                closeOnClick: true
              })
              .open();
            return;
          }
          // open task details popup
          this.getWorkOrderTaskListBys([
            {
              prop: "taskNumber",
              op: "==",
              val: this.$f7route.params.taskNumber
            },
            {
              prop: "actions",
              op: "array-contains",
              val: action[0].id
            }
          ]).then(docs => {
            if (docs.length > 0) {
              this.$refs.woTaskDetailsPopup.initCardData({
                projectId: this.constructionProjectId,
                actionId: action[0].id,
                taskId: docs[0].id
              });
              this.$refs.woTaskDetailsPopup.openPopup();
            } else {
              this.getWorkOrderAction(action[0].id).then(res => {
                this.$f7.notification
                  .create({
                    title: "Not found project",
                    closeTimeout: 15000,
                    closeButton: true,
                    text: `Not found task <b>${this.$f7route.params.taskNumber}</b> in <b>${res.title}</b> action. Please choose another task.`,
                    closeOnClick: true
                  })
                  .open();
              });
            }
          });
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
  },

  methods: {
    ...mapActions("dashboard/project", [
      "bindUserData",
      "setCurrentCard",
      "setConstructionProjectId",
      "getProjectByCardNumber"
    ]),

    ...mapActions("dashboard/work-order", [
      "bindWorkOrderActionList",
      "bindWorkOrderTaskListBys",
      "getWorkOrderTaskListBys",
      "getWorkOrderAction",
      "getWorkOrderActionBys"
    ]),
    ...mapActions("dashboard/app-constant", ["getConstantTypeList"]),

    async openCardDetailsPopup({ actionId, cardId, taskNumber }) {
      // get action by id
      const action = await this.getWorkOrderAction(actionId);
      window.history.pushState(
        "",
        "",
        `/dashboard/swimlane/commercial/construction/project/${this.$f7route.params.cardNumber}/action/${action.code}/task/${taskNumber}`
      );
      this.$refs.woTaskDetailsPopup.initCardData({
        projectId: this.constructionProjectId,
        actionId: actionId,
        taskId: cardId
      });
      this.$refs.woTaskDetailsPopup.openPopup();
    },

    /**
     * open add new task popup
     */
    handleAddNewTask() {
      window.history.pushState(
        "",
        "",
        `/dashboard/swimlane/commercial/construction/project/${this.$f7route.params.cardNumber}/task/new`
      );
      this.$refs.woTaskNewPopup.initPopupData();
      this.$refs.woTaskNewPopup.openPopup();
    },

    navigateToBoard() {
      window.history.pushState(
        "",
        "",
        `/dashboard/swimlane/commercial/construction/project/${this.$f7route.params.cardNumber}`
      );
      this.$refs.woTaskNewPopup.closePopup();
    },

    handleDoResolve() {
      this.$refs.constructionRenderless.doResolve();
    }
  }
};
</script>

<style lang="scss" scoped>
.swimlane-page {
  // background-image: var(--sales-board-bg-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tab-link {
  background-color: var(--f7-tabbar-link-active-bg-color);
}

.tab-link:not(.tab-link-active) {
  background-color: var(--f7-tabbar-link-active-bg-color);
}

.tabs-animated-wrap {
  // max-height: calc(100vh - 240px);
  height: var(--swimlane-body-height);
}
.swimlane-page {
  .custom-margin-top {
    margin-top: calc(28px + var(--swimlane-dashboard-mode-margin-top) * 2);
  }
}
</style>
