<template>
  <div>
    <common-list-section
      title="Restoration Agreement"
      addBtnTitle="Create New"
      :listItems="agreementList"
      :requiredErrorMessage="requiredErrorMessage"
      @openAddNewPopup="openAddNewPopup"
      @openEditPopup="openEditPopup"
      @openSendEmailPopup="sendAgreement"
      @getPDF="getPDF"
      @onDelete="onDelete"
    ></common-list-section>

    <add-new-agreement-popup
      ref="addNewAgreementPopup"
      @openSendEmailPopup="sendAgreement"
      @onPreview="onPreview"
    ></add-new-agreement-popup>
    <agreement-preview-popup
      ref="agreementPreviewPopup"
    ></agreement-preview-popup>
    <send-email-popup
      ref="sendEmailPopup"
      popup-title="Send Agreement"
      templateName="agreement"
      :projectId="card && (card.id || '')"
      :attachments="card && (card.attachmentFiles || [])"
      :listEmails="listEmails"
      @closePopup="closePopupDetail"
    ></send-email-popup>

    <!-- to download -->
    <div style="position: relative;z-index: 0;overflow: hidden">
      <preview-page
        ref="agreementPreviewPageHidden"
        :itemDetail="agreementDetail"
      ></preview-page>
    </div>
    <div
      style="height: 1px; background-color: var(--f7-block-strong-border-color)"
    ></div>
  </div>
</template>
<script>
import CommonListSection from "./CommonListSection.vue";
import AddNewAgreementPopup from "../popup/AddNewAgreementPopup.vue";
import AgreementPreviewPopup from "../popup/AgreementPreviewPopup.vue";
import SendEmailPopup from "@/components/popups/SendEmailPopup.vue";
import PreviewPage from "../../pages/PreviewPage.vue";
import sendEmail from "../../mixin/sendEmail";
import mixinMethods from "../../mixin/methods";
import { toDisplayDateString } from "../../../../utility/datetime";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    CommonListSection,
    AddNewAgreementPopup,
    AgreementPreviewPopup,
    SendEmailPopup,
    PreviewPage
  },
  mixins: [sendEmail, mixinMethods],
  data() {
    return {
      agreementDetail: {},
      requiredErrorMessage: ""
    };
  },
  props: {
    contactName: { type: String, default: "" }
  },
  computed: {
    ...mapGetters("dashboard/agreement", ["agreementList", "agreementById"]),
    ...mapGetters("dashboard/project", ["card"]),
    ...mapGetters("dashboard/template", ["agreementTemplate"]),
    ...mapGetters("dashboard/client", ["listEmails"]),
    ...mapGetters({
      setting: "setting/app/system/setting"
    }),
    ...mapGetters('common/app-constant', ['tenantId']),

  },
  methods: {
    ...mapActions({
      bindSetting: "setting/app/system/bindSetting"
    }),
    ...mapActions("dashboard/agreement", [
      "bindAgreementListBys",
      "updateAgreement",
      "createAgreement"
    ]),
    ...mapActions("dashboard/template", ["bindTemplateList"]),

    async initData(projectId) {
      const promises = [];
      if (projectId) {
        promises.push(
          this.bindAgreementListBys([
            {
              prop: "projectId",
              val: projectId,
              op: "=="
            },
            {
              prop: "isDeleted",
              val: false,
              op: "=="
            }
          ])
        );
      }
      if (_.isEmpty(this.agreementTemplate)) {
        promises.push(this.bindTemplateList());
      }
      if (_.isEmpty(this.setting)) {
        promises.push(this.bindSetting(this.tenantId));
      }
      await Promise.all(promises);
    },

    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },
    openAddNewPopup() {
      if (this.contactName === "") {
        this.requiredErrorMessage = "Please select contact first";
        return;
      } else {
        this.requiredErrorMessage = "";
      }
      this.$refs.addNewAgreementPopup.open();
    },
    openEditPopup(id) {
      this.$refs.addNewAgreementPopup.open(id);
    },

    getPDF(id) {
      const agreement = this.agreementById(id);
      this.agreementDetail = agreement;
      this.$nextTick(() => {
        this.$refs.agreementPreviewPageHidden.exportPdf();
      });
    },
    onDelete({ id, callback }) {
      this.$ri.dialog
        .openWarningDialog({
          title: "Delete Agreement",
          content: "Are you sure you want to delete this agreement?",
          textButton: "Delete",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.$f7.preloader.show();
              this.updateAgreement({
                id: id,
                doc: { isDeleted: true }
              }).then(() => {
                this.$f7.preloader.hide();
                callback && callback();
              });
            }
          }
        })
        .open();
    },

    onPreview(itemDetail) {
      this.$refs.agreementPreviewPopup.open(itemDetail);
    },

    showToastMessage(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000
        })
        .open();
    },
    closePopupDetail() {
      this.$refs.addNewAgreementPopup.close();
    }
  }
};
</script>
