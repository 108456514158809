<template>
  <f7-popup
    class="demo-popup custom-popup"
    :tablet-fullscreen="false"
    :opened="isShow"
    @popup:closed="$emit('close')"
  >
    <f7-block>
      <div style="font-weight: bold">Part of the data was fail to import</div>
      <div>
        <p>20 records successfully imported!</p>
        <p style="color: red">
          5 records failed to imported. Here are some possible reasons:
        </p>
        <ol>
          <li>We couldn't find any matching data in your Quickbook.</li>
          <li>Connection lost while transfering the data.</li>
        </ol>
        <p>
          Click
          <f7-link @click.native="$emit('showFailRecords')">
            <i>here</i>
          </f7-link>
          to get the fail records
        </p>
      </div>
      <f7-row class="justify-content-flex-end align-self-flex-end">
        <f7-button
          style="min-width: 64px"
          fill
          popup-close
          @click="$emit('close')"
        >
          OK
        </f7-button>
      </f7-row>
    </f7-block>
  </f7-popup>
</template>
<script>
export default {
  props: {
    isShow: Boolean
  }
};
</script>
<style scoped>
.custom-popup.modal-in,
.custom-popup.modal-out {
  height: fit-content;
  width: 300px;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
