export default {
  project: {},
  projectId: null,

  actionList: [],
  projectFilter: {
    jobTypes: [],
    collection: "project-open",
    createdFromDate: [],
    createdToDate: []
  },

  searchText: "",
  order: "desc",
  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0
};
