<template>
  <f7-page
    :class="
      `${
        currentView === 'client' ? 'estimate-list' : 'estimate-list-no-client'
      }`
    "
  >
    <div v-if="currentView === 'client'" class="client-title">
      <div class="client-back-btn">
        <f7-link @click="handleBackBtn" icon-f7="chevron_left">Back</f7-link>
      </div>
      <div>
        {{ clientInfo.name }}
        <span style="color: red">{{
          clientInfo.isDeleted ? "(Not exist)" : ""
        }}</span>
      </div>
    </div>
    <f7-searchbar
      style="width: 100%"
      disable-button-text
      placeholder="Search estimate"
      :value="searchText"
      :clear-button="true"
      class="search-list"
      @input="
        setEstimateSearchText($event.target.value);
        onSearch();
      "
      @searchbar:clear="
        setEstimateSearchText('');
        onSearch();
      "
      @searchbar:disable="
        setEstimateSearchText('');
        onSearch();
      "
      ><f7-button
        :style="$device.desktop ? '' : 'margin-left: 5px'"
        style="text-overflow: inherit;"
        popover-open=".popover-menu-filter"
      >
        <f7-icon f7="funnel"></f7-icon> </f7-button
    ></f7-searchbar>
    <f7-popover :class="`popover-menu-filter`">
      <f7-list>
        <f7-list-item
          v-for="(status, index) in estimateStatusList"
          :key="index"
          popover-close
          :title="status.displayName"
          @change="filterByStatus($event.target.checked, status.value)"
          :checked="selectedStatusFilter.includes(status.value)"
          checkbox
        >
        </f7-list-item>
      </f7-list>
    </f7-popover>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      style="overflow: auto;"
      @infinite="loadMore"
    >
      <f7-list
        v-show="hits.length"
        class="no-margin-vertical"
        :accordion-list="$device.desktop"
        media-list
      >
        <f7-list-item
          accordion-item
          v-for="est in hits"
          :title="est.estimateNumber"
          :key="est.id"
          swipeout
          :link="estimateDetailLink(est)"
          :text-color="
            est.estimateNumber === estimateNumber ? 'primary' : 'default'
          "
          @click="handleClickItem"
        >
          <f7-accordion-content opened v-if="$device.desktop">
            <f7-list class="add-building" media-list>
              <f7-list-item
                class="building-title"
                title="Summary"
                :link="summaryLink(est)"
                :class="
                  active.summaryId && active.summaryId === est.id
                    ? 'text-color-primary'
                    : 'text-color'
                "
                @click="handleClickItem('summary', est)"
                ><f7-icon color="primary" slot="media" f7="sum"></f7-icon
              ></f7-list-item>
              <f7-list-item
                v-for="building in est.buildings"
                :key="building.id"
                :link="buildingEstimateDetailLink(est, building.buildingName)"
                :title="building.buildingName"    
                class="building-title"
                :class="
                  active.buildingId && active.buildingId === building.buildingId
                    ? 'text-color-primary'
                    : 'text-color'
                "
                @click="handleClickItem('building', building)"
              >
                <div
                  slot="after"
                  :class="
                    active.buildingId &&
                    active.buildingId === building.buildingId
                      ? 'after-text-color'
                      : ''
                  "
                >
                  {{
                    building.numberOfCopy ? `x ${building.numberOfCopy} ` : ""
                  }}
                </div>

                <!-- <div>{{ building.id }}</div> -->
                <f7-icon color="primary" slot="media" f7="building_2"></f7-icon>
              </f7-list-item>

              <f7-list-item
                class="building-title"
                link="#"
                title="Add new specification"
                :class="
                  active.addBuildingId && active.addBuildingId === est.id
                    ? 'text-color-primary'
                    : 'text-color'
                "
                @click="addNewBuilding(est)"
                ><f7-icon color="primary" slot="media" f7="plus_app"></f7-icon
              ></f7-list-item>
            </f7-list>
          </f7-accordion-content>

          <f7-swipeout-actions right class="swipe-action">
            <f7-swipeout-button
              :close="true"
              color="blue"
              @click="copyEstimate(est.id)"
            >
              <span>Copy</span>
            </f7-swipeout-button>
            <f7-swipeout-button
              :close="true"
              color="red"
              @click="openConfirmDelete(est.id)"
              >Delete</f7-swipeout-button
            >
          </f7-swipeout-actions>
          <div slot="subtitle">{{ est.estimateName }}</div>
          <div slot="footer">
            <span>Updated on: </span>
            <timeago
              v-if="est.updatedAt"
              :datetime="convertDateTime(est.updatedAt)"
              :autoUpdate="1"
              :converterOptions="{ includeSeconds: true }"
            />
          </div>
          <f7-chip
            slot="after"
            :text="estimateStatusOptionBy(est.status).displayName"
            :color="estimateStatusOptionBy(est.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
    </div>

    <copy-estimate-popup ref="copyEstimatePopup" />
    <add-new-specification ref="addNewSpecification" />
  </f7-page>
</template>

<script>
import CopyEstimatePopup from "../popup/CopyEstimatePopup.vue";
import AddNewSpecification from "../popup/AddNewSpecification.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import moment from "moment";
import buidingMixin from "../../utility/building-mixins";

export default {
  components: {
    CopyEstimatePopup,
    AddNewSpecification
  },

  mixins: [buidingMixin],

  data: () => {
    return {
      popupCopyOpen: false,
      itemCopyEstimate: null,
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true,
      active: {
        summaryId: "",
        buildingId: "",
        addBuildingId: ""
      },
      selectedStatuses: []
    };
  },

  async created() {
    if (!this.hasMoreItems) {
      this.$f7.preloader.show();
    }
    this.currentView === "client" && (await this.getClientInfo());
    this.setDestroyFlag(false);
    this.setEstimateSearchText("");
    this.setSelectedStatusFilter([]);
    this.setClientInfo({
      clientType: this.$f7route.params.clientType,
      clientId: this.$f7route.params.clientId
    });
    this.onSearch()
      .then(() => {
        if (
          _.isEmpty(this.companyTypeList) ||
          _.isEmpty(this.estimateStatusList)
        ) {
          return this.initConstant();
        }
      })
      .then(() => {
        const refs = [];

        refs.push(this.getVendors());
        refs.push(this.getCategories());
        refs.push(this.getSubCategories());

        return Promise.all(refs);
      })
      .finally(() => {
        this.setDestroyFlag(true);
        if (!this.hasMoreItems) {
          this.$f7.preloader.hide();
        }
      });
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      // this.unbindConstantObject();

      // this.unbindEstimateList();
      this.unbindVendors();
      this.unbindCategories();
      this.unbindSubCategories();

      this.resetSearch();
      const currentPath = (
        (this.$f7 && this.$f7.views.main.router.currentRoute.route) ||
        {}
      ).path;
      if (!currentPath.includes("/estimate")) {
        this.setSelectedStatusFilter([]);
        this.selectedStatuses = [];
      }
      // this.setEstIdLink(null);
    }
  },

  methods: {
    ...mapActions("common/app-constant", ["initConstant"]),

    handleClickItem(prop, obj) { 
      switch (prop) {
        case "summary":
          this.active = {
            summaryId: obj.id,
            buildingId: "",
            addBuildingId: ""
          };
          break;
        case "building":
          this.active = {
            summaryId: "",
            buildingId: obj.buildingId,
            addBuildingId: ""
          };
          break;
        case "addBuilding":
          this.active = {
            summaryId: "",
            buildingId: "",
            addBuildingId: obj.id
          };
          break;
        default:
          this.active = {
            summaryId: "",
            buildingId: "",
            addBuildingId: ""
          };
      }
    },
    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreEstimate({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    ...mapActions("estimate/estimate-page/estimate", [
      "unbindEstimateList",
      "createNewEstimate",
      "updateEstimate",
      "deleteEstimate",
      "getEstimate",

      "createBuilding",
      "getProductDataTemplate",
      "getBuildingList",

      "searchEstimate",
      "loadMoreEstimate",
      "setEstimateNumberOfRows",
      "setEstimateSearchText",
      "resetSearch",
      "setSelectedStatusFilter",

      "setIsActiveSummary",
      "setClientInfo"
    ]),

    ...mapActions({
      getVendors: "estimate/estimate-page/estimate/vendor/getVendors",
      unbindVendors: "estimate/estimate-page/estimate/vendor/unbind",
      getCategories: "estimate/estimate-page/estimate/category/getCategories",
      unbindCategories: "estimate/estimate-page/estimate/category/unbind",
      getSubCategories:
        "estimate/estimate-page/estimate/sub-category/getSubCategories",
      unbindSubCategories:
        "estimate/estimate-page/estimate/sub-category/unbind",
      setDestroyFlag: "price-list/price-list-page/common/setDestroyFlag"
    }),
    ...mapMutations("estimate/estimate-page/estimate", ["setEstIdLink"]),

    ...mapActions("estimate/estimate-page/project", ["getProjectById"]),
    ...mapActions("estimate/estimate-page/contact", [
      "getContact",
      "resetContact"
    ]),
    ...mapActions("estimate/estimate-page/company", [
      "getCompany",
      "resetCompany"
    ]),
    ...mapActions("estimate/estimate-page/common", ["setClientType"]),

    dateToValue(a) {
      return a._seconds * 1000 + a._nanoseconds / 1000000;
    },
    convertDateTime(value) {
      return moment(
        this.dateToValue(value) ? new Date(this.dateToValue(value)) : new Date()
      ).toDate();
    },

    handleBackBtn() {
      this.selectedStatuses = [];
      this.setSelectedStatusFilter([]);
      this.$f7router.navigate("/estimate", {
        pushState: true,
        reloadAll: true
      });
    },

    async getClientInfo() {
      this.resetContact();
      this.resetCompany();
      const clientType = this.$f7route.params.clientType;
      const clientId = this.$f7route.params.clientId;
      if (clientType === "company") {
        return await this.getCompany(clientId);
      } else if (clientType === "contact") {
        return await this.getContact(clientId);
      }
    },

    openConfirmDelete(estimateId) {
      const self = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete Estimate",
        content: "Are you sure you want to to delete the Estimate?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            self.$f7.preloader.show();
            self.deleteEstimate(estimateId).then(() => {
              if (this.currentView === "estimate-list") {
                self.$f7router.updateCurrentUrl("/estimate");
              } else {
                self.$f7router.updateCurrentUrl(
                  `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}`
                );
              }
              self.$f7.preloader.hide();
            });
          }
        }
      });
    },

    async addNewBuilding(estInfo) {
      this.handleClickItem("addBuilding", estInfo);
      if (_.isEmpty(estInfo)) {
        return;
      }

      const estData = await this.getEstimate(estInfo.id);
      if (_.isEmpty(estData)) {
        return;
      }
      const projData = await this.getProjectById(estData.projectId);
      this.$refs.addNewSpecification.openPopup({
        projectInfo: projData,
        estimateInfo: estData
      });
    },

    async copyEstimate(estimateId) {
      const estimate = await this.getEstimate(estimateId);

      const newEstimateInfo = await this.$refs.copyEstimatePopup.openPopup({
        businessCode: estimate.businessCode
      });
      if (!newEstimateInfo) return;
      this.$f7.preloader.show();

      const doc = {
        ...estimate,
        ...newEstimateInfo,
        buildings: [],
        id: null
      };

      const estimateCreated = await this.createNewEstimate(doc);

      //get building from old est
      const buildings = await this.getBuildingList({ estimateId: estimate.id });
      const buildingList = [];

      for (const buidling of buildings) {
        const buildingRef = await this.createBuilding({
          estimateId: estimateCreated.id,
          building: {
            ...buidling,
            estimateId: estimateCreated.id,
            id: null
          }
        });

        buildingList.push(buildingRef);
      }

      const newBuildingList = estimate.buildings;
      buildingList.forEach(building => {
        const index = newBuildingList.findIndex(
          r => r.buildingName == building.buildingName
        );
        if (index > -1) newBuildingList[index].buildingId = building.buildingId;
      });
      //update created building list.
      await this.updateEstimate({
        id: estimateCreated.id,
        doc: {
          buildings: newBuildingList,
          numberOfBuildings: newBuildingList.length
        }
      });
      this.$f7.preloader.hide();
      if (this.currentView === "estimate-list") {
        this.$f7router.navigate(`/estimate/${estimateCreated.estimateNumber}`, {
          pushState: true
        });
      } else {
        this.$f7router.navigate(
          `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${estimateCreated.estimateNumber}`,
          {
            pushState: true
          }
        );
      }
    },

    onSearch() {
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchEstimate({})
        .then(() => {
          // console.log("[onSearch] load more estimate", data);
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    },

    filterByStatus(checked, status) {
      if (checked) {
        this.selectedStatuses.push(status);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          item => item !== status
        );
      }
      this.setSelectedStatusFilter(_.cloneDeep(this.selectedStatuses));
      this.onSearch();
    }
  },

  computed: {
    ...mapGetters("common/app-constant", [
      "companyTypeList",
      "estimateStatusList"
    ]),

    ...mapGetters("estimate/estimate-page/estimate", [
      "order",
      "searchText",

      "hits",
      "hitsPerPage",
      "nbHits",
      "nbPages",
      "page",
      "selectedStatusFilter",
      "isActiveSummary"
    ]),

    ...mapGetters("estimate/estimate-page/estimate", [
      "estimate",
      "estimateList",
      "estimateNumber",
      "estimateByNumber"
    ]),
    ...mapGetters("common/app-constant", ["estimateStatusOptionBy"]),

    ...mapGetters("setting/app/profile", ["user"]),

    ...mapGetters({
      destroyFlag: "price-list/price-list-page/common/destroyFlag",
      currentBuilding: "estimate/estimate-page/estimate/currentBuilding"
    }),
    ...mapGetters("estimate/estimate-page/contact", ["contact"]),
    ...mapGetters("estimate/estimate-page/company", ["company"]),
    ...mapGetters("estimate/estimate-page/common", ["currentView"]),

    clientInfo() {
      const clientType = this.$f7route.params.clientType;
      if (clientType === "company") {
        return {
          name: this.company.companyName || "",
          isDeleted: this.company.isDeleted
        };
      } else if (clientType === "contact" && !_.isEmpty(this.contact)) {
        return {
          name: `${this.contact.firstName} ${this.contact.lastName}`,
          isDeleted: this.contact.isDeleted
        };
      }
      return "";
    },

    estimateDetailLink() {
      return item => {
        if (!_.isEmpty(item)) {
          return this.currentView === "estimate-list"
            ? `/estimate/${item.estimateNumber}`
            : `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${item.estimateNumber}`;
        } else {
          return "#";
        }
      };
    },

    buildingEstimateDetailLink() {
      return (item, buildingName) => {
        if (!_.isEmpty(item)) {
          return this.buildingUrl(item.estimateNumber, buildingName);
        } else {
          return "#";
        }
      };
    },
    summaryLink() {
      return item => {
        if (!_.isEmpty(item)) {
          return this.currentView === "estimate-list"
            ? `/estimate/${item.estimateNumber}/summary`
            : `/estimate/${this.$f7route.params.clientType}/${this.$f7route.params.clientId}/${item.estimateNumber}/summary`;
        } else {
          return "#";
        }
      };
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        const el = this.$el.querySelector(".search-list.searchbar input");
        if (el && typeof el.focus === "function") {
          el.focus();
        }
      }
    });
  },

  watch: {
    "currentBuilding.id": {
      handler(val, old) {
        if (val != old) {
          this.active = {
            summaryId: "",
            buildingId: val,
            addBuildingId: ""
          };
        }
      },
      immediate: true,
      deep: true
    },
    isActiveSummary: {
      handler(val) {
        if (val && this.estimate) {
          this.active = {
            summaryId: this.estimate.id,
            buildingId: "",
            addBuildingId: ""
          };
          this.setIsActiveSummary(false);
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
.after-text-color {
  color: var(--f7-theme-color);
}

.font-text {
  font-size: 11px;
  width: max-content;
  font-weight: bold;
}

.building-title ::v-deep .item-title {
  font-weight: 400;
}

.swipe-action {
  height: 70px;
}

.text-color {
  color: var(--f7-color-text-neutral);
}

.client-title {
  position: absolute;
  left: 0;
  top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
  height: var(--f7-navbar-height);
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 0 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-back-btn {
  position: absolute;
  left: 8px;
}

.estimate-list .search-list {
  top: calc(var(--f7-navbar-height) * 2 + var(--f7-safe-area-top)) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.estimate-list-no-client .search-list {
  top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top)) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.estimate-list ::v-deep .page-content {
  overflow: hidden;
  padding-top: calc(
    var(--f7-page-navbar-offset, 0px) * 2 +
      var(--f7-page-toolbar-top-offset, 0px) +
      var(--f7-page-subnavbar-offset, 0px) +
      var(--f7-page-searchbar-offset, 0px) +
      var(--f7-page-content-extra-padding-top, 0px)
  );
}

.add-building::after {
  content: "";
  position: absolute;
  background-color: var(--f7-list-item-border-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  margin-left: calc(
    var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left)
  );
}
.list-filter ::v-deep .item-link .item-inner {
  display: none;
}
/* list-filter has a line ::before ane ::after, I want to remove
them */
.list-filter ::v-deep ul::before,
.list-filter ::v-deep ul::after {
  display: none;
}
</style>
