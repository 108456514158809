import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );
  const dashboardActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "action"
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    "board"
  );

  const woTaskActions = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "task"
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "action"
  );
  return {
    bindProjectListBy: projectActions.bindCollectionBy,
    bindProjectListBys: projectActions.bindCollectionBys,
    unbindProjectList: projectActions.unbindCollection,

    bindProject: projectActions.bindDocument,
    unbindProject: projectActions.unbindDocument,
    updateProject: projectActions.updateDocument,
    getProjectById: projectActions.getDocument,

    getAction: dashboardActions.getDocument,

    getBoardById: boardActions.getDocument,
    getTaskById: woTaskActions.getDocument,
    getActionById: actionActions.getDocument
  };
};
