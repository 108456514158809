<template>
  <f7-popup class="demo-popup" :opened="isShow" @popup:closed="cancel">
    <f7-page>
      <form @submit.prevent="save">
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Edit sub category</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="save()">Save</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title>Category name <required-asterisk /></f7-block-title>
        <f7-list>
          <f7-list-input placeholder="Input sub category name" :value="object.name"
            @input="object.name = $event.target.value.trim()" error-message-force clear-button
            :error-message="nameErrorMessage"></f7-list-input>
        </f7-list>

        <f7-block-title>Calculation Methods</f7-block-title>
        <f7-list>
          <f7-list-input label="Product Item Calculation Method" placeholder="Enter Product Item Calculation Method"
            :value="object.productItemFunctionMap" @input="object.productItemFunctionMap = $event.target.value.trim()"
            clear-button></f7-list-input>

          <f7-list-input label="Measurement Calculation Method" placeholder="Enter Measurement Calculation Method"
            :value="object.measureFunctionMap" @input="object.measureFunctionMap = $event.target.value.trim()"
            clear-button></f7-list-input>
        </f7-list>
      </form>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  data: () => ({
    product: [],
    isShow: false,
    object: {
      name: "",
      measureFunctionMap: "",
      productItemFunctionMap: ""
    }
  }),
  callback: null,
  methods: {
    ...mapActions({
      create: "product/category-page/sub-category/create"
    }),
    cancel(ignoreCallback) {
      this.object = {
        name: "",
        measureFunctionMap: "",
        productItemFunctionMap: ""
      };
      this.v$.$reset();
      this.isShow = false;
      !ignoreCallback && this.callback && this.callback();
    },

    save() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      var { name, measureFunctionMap, productItemFunctionMap } = this.object;
      this.cancel(true);

      var data = {
        name,
        measureFunctionMap: measureFunctionMap || "",
        productItemFunctionMap: productItemFunctionMap || ""
      };
      this.callback && this.callback(data);
    },
    doEdit(object) {
      this.object = { ...object };
      this.isShow = true;
      return new Promise(resolve => {
        this.callback = resolve;
      });
    }
  },

  computed: {
    nameErrorMessage() {
      if (!this.v$.object.name.$error) return null;
      if (this.v$.object.name.required.$invalid) return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      object: {
        name: {
          required
        }
      }
    };
  }
};
</script>
