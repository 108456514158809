<template>
  <div>
    <f7-page v-if="loadingData"></f7-page>
    <preview-page v-else ref="previewPage" title="Agreement Preview" :itemDetail="agreement || {}"
      :isHiddenLeftBtn="true" entity="agreement" :entityId="agreement.id" :isShowOnlineSignature="!agreement.isDeleted &&
        agreement.viewedAt &&
        !agreement.ownerSigner &&
        isAgreementViewToSign
        " @openSignaturePopup="openSignaturePopup"></preview-page>
    <agreement-signature-popup ref="signaturePopup" :agreement="agreement || {}" @reloadPages="reloadPages"
      :isCustomer="true"></agreement-signature-popup>
  </div>
</template>
<script>
import PreviewPage from "./PreviewPage.vue";
import AgreementSignaturePopup from "../components/popup/AgreementSignaturePopup.vue";
import { firebase } from "@/services/firebase.service";
import axios from "axios";

export default {
  components: { PreviewPage, AgreementSignaturePopup },
  data: () => {
    return {
      agreement: {},
      loadingData: true
    };
  },
  async mounted() {
    await this.reloadPages();
    if (
      this.agreement &&
      this.agreement.status === "sent" &&
      this.isAgreementViewToSign
    ) {
      try {
        // update status to viewed by client
        await axios.post(
          `agreement/update/${this.$f7route.query.id}`,
          {
            status: "viewed",
            viewedAt: firebase.firestore.Timestamp.now()
          },
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token
            }
          }
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
      await this.reloadPages();
    }
  },

  computed: {
    isAgreementViewToSign() {
      return this.$f7.views.main.router.currentRoute.path.includes(
        "agreement-view"
      );
    }
  },

  methods: {
    openSignaturePopup() {
      this.$refs.signaturePopup.openPopup();
    },
    async reloadPages() {
      this.$f7.preloader.show();
      this.loadingData = true;
      try {
        const response = await axios.get(
          `agreement/${this.$f7route.query.id}`,
          {
            baseURL: import.meta.env.VITE_BASE_API,
            withCredentials: true,
            headers: {
              token: this.$f7route.query.token
            }
          }
        );
        if (
          response.status === 200 &&
          response.data.success &&
          response.data.data.isDeleted === false
        ) {
          this.agreement = response.data.data;
        } else {
          // eslint-disable-next-line no-console
          console.error(response.data.message);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      } finally {
        this.loadingData = false;
        this.$f7.preloader.hide();
      }
    }
  },
  watch: {
    loadingData(newVal) {
      if (newVal === false) {
        this.$nextTick(() => {
          this.$refs.previewPage.lowResDesktopScreen();
        });
      }
    }
  }
};
</script>
