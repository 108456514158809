<template>
  <f7-popup
    class="demo-popup custom-popup"
    :opened="isShow"
    tablet-fullscreen
    @popup:closed="isShow = false"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Error Export</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="$emit('handleLinkDataQB')">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <b>Please link data before export!</b>
      </f7-block>
      <template v-if="employeeToLinkList.length">
        <f7-block>QB Employee link</f7-block>
        <data-table
          :headers="mapEmployeeTableHeaders"
          :items="employeeToLinkList"
          :pageSize="(employeeToLinkList || []).length"
        >
          <template v-slot:body="{ item }">
            <td>{{ (userById(item.user_id || "") || {}).displayName }}</td>
            <td>
              <span v-if="item.qbEmployeeRef">
                {{ item.qbEmployeeRef.name }}
              </span>
              <auto-complete-input
                v-else
                placeholder="Enter Employee"
                :id="`employee_${item.id}`"
                :data="employeeList"
                :value="(item.employeeRef || {}).name"
                @selected="selectedEmployee(item, $event.target.value)"
              ></auto-complete-input>
            </td>
          </template>
        </data-table>
      </template>

      <template v-if="projectToLinkList.length">
        <f7-block>QB Customer/Project link</f7-block>
        <data-table
          :headers="mapProjectTableHeaders"
          :items="projectToLinkList"
          :pageSize="(projectToLinkList || []).length"
        >
          <template v-slot:body="{ item }">
            <td>{{ projectName(item) }}</td>
            <td>
              <span v-if="item.qbCustomerRef">
                {{ item.qbCustomerRef.name }}
              </span>
              <auto-complete-input
                v-else
                placeholder="Enter Customer"
                :id="`customer_${item.id}`"
                :data="customerList"
                :value="(item.customerRef || {}).name"
                @selected="selectedCustomer(item, $event.target.value)"
              ></auto-complete-input>
            </td>
          </template>
        </data-table>
      </template>
    </f7-page>
  </f7-popup>
</template>
<script>
import DataTable from "@/components/datatables";
import AutoCompleteInput from "../input/AutoCompleteInput.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    DataTable,
    AutoCompleteInput
  },
  props: {
    employeeToLinkList: Array,
    projectToLinkList: Array
  },
  data() {
    return {
      isShow: false
    };
  },

  computed: {
    ...mapGetters("quickbooks/common", ["qbEmployeeList", "qbCustomerList"]),
    ...mapGetters("quickbooks/user", ["userById"]),

    projectName() {
      return item => {
        return item.timeLogType === "others"
          ? item.title || ""
          : (item.project || {}).title || "";
      };
    },

    mapEmployeeTableHeaders() {
      return [
        {
          text: "Employee Name",
          value: "employeeName",
          sortable: false,
          align: "left"
        },
        {
          text: "QB Employee",
          value: "qb-employee",
          sortable: false,
          align: "left"
        }
      ];
    },
    mapProjectTableHeaders() {
      return [
        {
          text: "Project Name",
          value: "Project",
          sortable: false,
          align: "left"
        },
        {
          text: "QB Customer/Project",
          value: "qb-customer",
          sortable: false,
          align: "left"
        }
      ];
    },
    employeeList() {
      return this.qbEmployeeList.map(item => {
        return {
          id: item.Id,
          name: item.DisplayName
        };
      });
    },

    customerList() {
      return this.qbCustomerList.map(item => {
        return {
          id: item.Id,
          name: item.DisplayName
        };
      });
    }
  },
  methods: {
    open() {
      this.isShow = true;
    },
    closePopup() {
      this.isShow = false;
    },
    selectedEmployee(item, selected) {
      this.$emit("dataItemChange", "employeeRef", selected, item);
    },

    selectedCustomer(item, selected) {
      this.$emit("dataItemChange", "customerRef", selected, item);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .data-table {
  overflow: visible;
  .card-content {
    overflow: visible;
  }
}
</style>
