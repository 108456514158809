import { mapActions } from "vuex";
import { overtimeMixin } from "./overtime-mixin";
import { auth } from "@/services/firebase.service";
import {
  financeConsolidateStatus,
  STATUS_TIME_TRACKING_APPROVED,
  STATUS_TIME_TRACKING_REJECTED,
  STATUS_TIME_TRACKING_WFA,
  COLLECTION_MY_TIME_LOG
} from "../../../utility/const";
import _ from "lodash";
import moment from "moment";

export const actionMixin = {
  mixins: [overtimeMixin],
  methods: {
    ...mapActions("time-tracking/pay-rate", ["getCurrentPayRateOfUser"]),
    ...mapActions({
      updateTimeTracking: "time-tracking/time-tracking/update"
    }),
    ...mapActions("common/notification", ["createNotificationByType"]),
    ...mapActions("time-tracking/pay-rate", [
      "bindPayRateListBy"
    ]),
    ...mapActions("time-tracking/project", ["updateProject"]),
    async rejectTimeTracking(value, itemReject, callback) {
      await this.createNotificationByType({
        data: {
          assignees: [itemReject.user_id],
          project: {
            title: itemReject.title,
            id: itemReject.id,
            entityName: COLLECTION_MY_TIME_LOG
          }
        },
        type: "reject-timelog-user"
      });
      const self = this;
      return this.updateTimeTracking({
        id: itemReject.id,
        doc: {
          status: STATUS_TIME_TRACKING_REJECTED,
          reject_reason: value
        }
      })
        .then(async () => {
          if (!_.isEmpty(itemReject)) {
            const date = itemReject.date;
            const uid = itemReject.user_id;
            return await self.composeOvertime(date.toDate(), uid);
          }
        })
        .then(async () => {
          if (callback) callback();
          return await this.reloadTimelogList();
        });
    },

    async rejectAllTimeTracking(value, waitingTimelogList, callback) {
      const app = this;
      app.$f7.preloader.show();
      const promise = [];
      for (const itemReject of waitingTimelogList) {
        promise.push(
          this.createNotificationByType({
            data: {
              assignees: [itemReject.user_id],
              project: {
                title: itemReject.title,
                id: itemReject.id,
                entityName: COLLECTION_MY_TIME_LOG
              }
            },
            type: "reject-timelog-user"
          })
        );
        promise.push(
          this.updateTimeTracking({
            id: itemReject.id,
            doc: {
              status: STATUS_TIME_TRACKING_REJECTED,
              reject_reason: value
            }
          }).then(async () => {
            if (!_.isEmpty(itemReject)) {
              const date = itemReject.date;
              const uid = itemReject.user_id;
              await this.composeOvertime(date.toDate(), uid);
            }
          })
        );
      }

      Promise.all(promise).then(async () => {
        if (callback) callback();
        await this.reloadTimelogList();
        app.$f7.preloader.hide();
      });
    },

    async calcAmount(item, currentPayRate, approvedHour, overtime) {
      return (
        (approvedHour - overtime) * currentPayRate.payRate +
        overtime * currentPayRate.payRateOvertime
      );
    },
    getApprovedHour(item) {
      if (item.approvedHour !== 0 && item.approvedHour === item.overrideHour) {
          return item.approvedHour;
      }
  
      if (item.override) {
          return item.overrideHour;
      }
  
      return item.loggedHour;
    },
    async handleDataBeforeApprove(item) {
      let isClearQBCustomerRef = false;
      if(item.qbTimeActivityId === null && !_.isEmpty(item.qbCustomerRef)) {
        if(!_.isEmpty(item.projectId)) {
          await this.updateProject({
            id: item.projectId,
            doc: { qbCustomerRef: null }
          });
        }
        isClearQBCustomerRef = true;
      }
      let approvedHour = this.getApprovedHour(item);
      
      let overtime = item.hasOvertime ? item.overtime || 0 : 0;

      let doc = {
        status: STATUS_TIME_TRACKING_APPROVED,
        approvedHour,
        approvedBy: auth.currentUser.displayName || auth.currentUser.email
      };

      const date = item.date;
      await this.composeOvertime(date.toDate(), item.user_id);

      const currentPayRate = await this.getCurrentPayRateOfUser(item);
      
      if (currentPayRate) {
        doc.financeConsolidate = financeConsolidateStatus.CALCULATED;
        doc.amount = await this.calcAmount(
          item,
          currentPayRate,
          approvedHour,
          overtime
        );
        doc.payRate = currentPayRate.payRate;
        doc.payRateOvertime = currentPayRate.payRateOvertime;
      } else {
        doc.financeConsolidate = financeConsolidateStatus.NOT_CALCULATED_YET;
        doc.amount = 0;
      }
      if(isClearQBCustomerRef) {
        doc.qbCustomerRef = null;
      }
      return doc;
    },
    async onReOpen(itemId) {
      this.$f7.preloader.show();      
      await this.updateTimeTracking({
        id: itemId,
        doc: {
          status: STATUS_TIME_TRACKING_WFA
        }
      });
      await this.reloadTimelogList();
      this.$f7.preloader.hide();
    },

    async approved(item, callback) {      
      this.$f7.preloader.show();
      await this.createNotificationByType({
        data: {
          assignees: [item.user_id],
          project: {
            title: item.title,
            id: item.id,
            entityName: COLLECTION_MY_TIME_LOG
          }
        },
        type: "approve-timelog-user"
      });
      const doc = await this.handleDataBeforeApprove(item);
      await this.updateTimeTracking({
        id: item.id,
        doc
      });     
      if (callback) callback();
      await this.reloadTimelogList();
      this.$f7.preloader.hide();
    },
    approveAll(waitingTimelogList, callback) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Approve Time Tracking",
        content: "Are you sure want to approve these time logs?",
        textButton: "Yes",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            let promises = [];
            for (const item of waitingTimelogList) {              
              const user = app.userById(item.user_id);              
              const isHourlyPayType = user?.customClaims?.payType === 'hourly';
              if (isHourlyPayType) {
                await this.bindPayRateListBy({
                  prop: "userId",
                  val: item.user_id,
                  op: "=="
                });
                const sorted = app.payRateList.sort((a, b) => moment(a.effectiveDate) - moment(b.effectiveDate));
                const itemDate = moment(item.date.toDate()).startOf('day');
                const toDates = sorted.map((rate, index) => 
                  index < sorted.length - 1 ? moment(sorted[index + 1].effectiveDate).subtract(1, 'days').startOf('day') : null
                );
                const isDateInRange = sorted.some((rate, index) => {
                  const effectiveDate = moment(rate.effectiveDate).startOf('day');

                  return toDates[index] === null
                    ? itemDate.isSameOrAfter(effectiveDate)
                    : itemDate.isBetween(effectiveDate, toDates[index], null, '[]');
                });
                
                if (app.payRateList.length === 0 || !isDateInRange) {
                  app.$f7.preloader.hide();
                  app.$ri.dialog.openWarningDialog({
                    title: "Approve Time Log",
                    content: "Please verify the user's pay rate before approving the timelog!",
                    hideCancelButton: true,
                    onClick: (_sefl, index) => {
                      if (index === 0 || index === 1) {
                        _sefl.app.dialog.close();
                      }
                    }
                  });
                  return;
                }
              }
              const doc = await this.handleDataBeforeApprove(item);
              promises.push(
                this.createNotificationByType({
                  data: {
                    assignees: [item.user_id],
                    project: {
                      title: item.title,
                      id: item.id,
                      entityName: COLLECTION_MY_TIME_LOG
                    }
                  },
                  type: "approve-timelog-user"
                })
              );
              promises.push(
                app.updateTimeTracking({
                  id: item.id,
                  doc
                })
              );
            }
            
            Promise.all(promises)
              .then(async () => {
                if (callback) callback();
                return await this.reloadTimelogList();
              })
              .then(() => {
                app.$f7.preloader.hide();
                _sefl.app.dialog.close();
              });
          }
        }
      });
    }
  }
};
