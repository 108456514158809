import { checkAuth } from "../../../services/authentication.service";

export default [
  {
    path: "/dashboard/swimlane/service",
    title: "Service",
    name: "service-board",
    parent: "dashboard-main",
    displayRoles: ["owner", "user"],
    icon: "hammer",
    isShowMenuMobile: true,
    meta: {
      businessCode: "service"
    },
    async: checkAuth(() =>
      import("../../../plugins/swimlane/pages/BoardDetail.vue")
    ),
    master: false
  },
  {
    path: "/dashboard/swimlane/service/:boardNumber/new",
    name: "new",
    title: "Board detail",
    checkAccessPath: "dashboard-main/service-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "service"
    },
    async: checkAuth(() =>
      import("../../../plugins/swimlane/pages/BoardDetail.vue")
    )
  },
  {
    path: "/dashboard/swimlane/service/:boardNumber/card/:cardNumber",
    name: "service-card-detail",
    title: "Card detail",
    checkAccessPath: "dashboard-main/service-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "service"
    },
    async: checkAuth(() =>
      import("../../../plugins/swimlane/pages/BoardDetail.vue")
    )
  },

  // from my scheduling / employee scheduling
  {
    path:
      "/:mainRouteFrom/currentView/:currentView/startWeek/:startWeek/currentUser/:userId/dashboard/swimlane/service/:boardNumber/card/:cardNumber",
    name: "service-card-detail",
    title: "Card detail",
    checkAccessPath: "dashboard-main/service-board",
    displayRoles: ["owner", "user"],
    meta: {
      businessCode: "service"
    },
    async: checkAuth(() =>
      import("../../../plugins/swimlane/pages/BoardDetail.vue")
    )
  }
];
