<template>
  <f7-page v-show="proposal && Object.keys(proposal).length">
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          v-if="hasProject || hasEstimate || !$device.desktop"
          icon-f7="chevron_left"
          @click.native="handleBackAction"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>
        {{
          proposal.project && proposal.project.businessCode === "service"
            ? `${proposal.project.title || ""}`
            : "Proposal Detail"
        }}
      </f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="proposal && proposal.status === 'draft' && isEdited"
          @click.native="
            isShowPopupLoading = true;
            onSave();
          "
          >Save</f7-link
        >
      </f7-nav-right>
    </f7-navbar>

    <f7-row no-gap>
      <f7-col :width="$device.desktop ? 30 : 100">
        <f7-card v-if="$device.desktop">
          <f7-card-content
            ><detail-menu
              @changeTableContentType="changeTableContentType"
              :sortedSections="sortedSections"
              :currentSectionIndex="currentSectionIndex"
              @sectionClick="sectionClick"
              @onDndChange="onDndChange"
              @save="onSave"
              :isEdited="isEdited"
            ></detail-menu
          ></f7-card-content>
        </f7-card>
        <detail-menu-mobile
          v-else
          style="z-index: 9999"
          ref="detailMenuMobile"
          :sortedSections="sortedSections"
          :currentSectionIndex="currentSectionIndex"
          @changeTableContentType="changeTableContentType"
          @sectionClick="sectionClick"
          @onPreview="toPreview"
          @sendEmailProposal="sendEmailProposal"
          @markAsSentProposal="markAsSentProposal"
          @withdrawProposal="withdrawProposal"
          @openSignaturePopup="openSignaturePopup"
          @onCopy="copyProposal"
        ></detail-menu-mobile>
      </f7-col>
      <f7-col
        :width="$device.desktop ? 70 : 100"
        ref="wrapContent"
        class="display-flex flex-direction-column"
        :style="
          `height: ${
            $device.desktop ? 'calc(100vh - var(--f7-navbar-height))' : ''
          };
          `
        "
      >
        <f7-card v-show="$device.desktop"
          ><f7-card-header class="display-flex justify-content-space-between">
            <div>{{ proposal.proposalNumber }}</div>
            <div class="display-flex align-items-center">
              <f7-icon
                @click.native="downloadPdf(true)"
                :class="`margin-right pointer download-doc-tooltip-${uuid}`"
                size="20px"
                color="var(--f7-theme-color);"
                f7="arrow_down_doc"
              ></f7-icon>
              <f7-icon
                @click.native="copyProposal"
                :class="`margin-right pointer copy-doc-tooltip-${uuid}`"
                size="20px"
                color="var(--f7-theme-color);"
                f7="doc_on_doc"
              ></f7-icon>
              <f7-icon
                @click.native="toPreview"
                :class="`margin-right pointer preview-doc-tooltip-${uuid}`"
                size="20px"
                color="var(--f7-theme-color);"
                f7="doc_text_search"
              ></f7-icon>
              <f7-icon
                v-if="proposal.status !== 'withdraw'"
                @click.native="withdrawProposal"
                :class="`margin-right pointer withdraw-doc-tooltip-${uuid}`"
                size="20px"
                color="var(--f7-theme-color);"
                f7="bin_xmark"
              ></f7-icon>
              <f7-icon
                v-if="
                  proposal.status !== 'sent' &&
                    proposal.status !== 'viewed' &&
                    proposal.status !== 'withdraw' &&
                    proposal.status !== 'signed'
                "
                @click.native="markAsSentProposal"
                :class="`margin-right pointer mark-as-sent-doc-tooltip-${uuid}`"
                size="20px"
                color="var(--f7-theme-color);"
                f7="envelope_badge"
              ></f7-icon>
              <f7-button
                v-if="
                  proposal.status !== 'sent' &&
                    proposal.status !== 'viewed' &&
                    proposal.status !== 'withdraw' &&
                    proposal.status !== 'signed'
                "
                small
                outline
                :class="{
                  'margin-right': !proposal.companyRepresentativeSigner
                }"
                @click="sendEmailProposal"
                >Send Email</f7-button
              >
              <f7-button
                v-if="
                  proposal.status === 'draft' &&
                    !proposal.companyRepresentativeSigner
                "
                small
                fill
                @click.native="openSignaturePopup"
                >Sign Online</f7-button
              >
            </div>
          </f7-card-header>
        </f7-card>
        <f7-block
          v-if="!isShowProposal"
          ref="scrollViewer"
          @scroll.native="onViewerScroll"
          :style="styleBox"
        >
          <div ref="scrollContainer" :style="styleContent">
            <div v-for="(section, index) in sortedSections" :key="index">
              <section-editor
                :ref="`editor_${index}`"
                :section="section"
                :status="proposal.status"
                :isHiddenDelete="
                  (proposal.companyRepresentativeSigner &&
                    section.sectionId === 'signature-section') ||
                    section.isDeleted
                "
                :isSigned="!!proposal.companyRepresentativeSigner"
                @doAction="doAction"
                @editorChange="handleEditorChange"
                @addSection="addSection"
                @addPhotosSection="addPhotosSection"
                @removeSection="removeSection"
                @restoreSection="restoreSection"
                @editorFocus="handleEditorFocus"
              ></section-editor>
            </div>
            <div class="tail-section"></div>
          </div>
        </f7-block>
        <div
          v-else
          style="flex:1;  display: flex; flex-direction: column;  align-items: center; gap:5px"
        >
          <f7-skeleton-block
            style="width: 92%; height: 25%; border-radius: 5px;"
          ></f7-skeleton-block>
          <f7-skeleton-block
            style="width: 92%; height: 25%; border-radius: 5px;"
          ></f7-skeleton-block>
          <f7-skeleton-block
            style="width: 92%; height: 48%; border-radius: 5px;"
          ></f7-skeleton-block>
        </div>
      </f7-col>
    </f7-row>

    <proposal-send-email-popup ref="sendEmailPopup"></proposal-send-email-popup>

    <change-product-item-popup
      ref="productItemPopup"
      @onSave="reloadCheckedProducts"
    ></change-product-item-popup>

    <change-photo-popup
      ref="photoPopup"
      :isUploadVideo="false"
      @onSave="reloadCheckedPhotos"
    ></change-photo-popup>

    <proposal-verbiage-popup
      ref="verbiageProposalPopup"
      @onSave="reloadBuildingInfo"
    ></proposal-verbiage-popup>

    <proposal-financing-popup
      ref="financingProposalPopup"
      @onSave="reloadFinancingInfo"
    ></proposal-financing-popup>

    <edit-table-popup
      ref="editTablePopup"
      @onSave="editTable"
    ></edit-table-popup>

    <signature-popup
      :proposalDetail="dataProposal"
      @reloadPages="reloadProposalAfterSignature"
      ref="signaturePopup"
    ></signature-popup>
    <div
      ref="wrapHiddenContent"
      class="display-flex flex-direction-column align-items-center justify-content-flex-start text-color-black"
    >
      <div
        class="visible-hidden"
        v-for="(page, index) in pdfPages"
        :key="index"
      >
        <template>
          <preview-page
            :id="`${page.pageId}_${page.index}`"
            :ref="`${page.pageId}_${page.index}`"
            :htmlValue="page.htmlValue"
          ></preview-page>
        </template>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import _ from "lodash";
import common from "../../utility/common";
import financing from "../../mixins/financing-proposal-mixin";
import ProposalSendEmailPopup from "../popup/ProposalSendEmailPopup.vue";
import SectionEditor from "../editors/SectionEditor.vue";
import DetailMenu from "../menu/DetailMenu.vue";
import DetailMenuMobile from "../menu/DetailMenuMobile.vue";
import ChangeProductItemPopup from "../popup/ChangeProductItemPopup.vue";
import ChangePhotoPopup from "../popup/ChangePhotoPopup.vue";
import ProposalVerbiagePopup from "../popup/ProposalVerbiagePopup.vue";
import ProposalFinancingPopup from "../popup/ProposalFinancingPopup.vue";
import EditTablePopup from "../popup/EditTablePopup.vue";
import SignaturePopup from "../popup/SignaturePopup.vue";
import jsPDF from "jspdf";
import { generateImage } from "../../../../services/utils";
import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL,
  COLLECTION_PROPOSAL
} from "@/utility/const";
import {
  BUILDING_PRODUCT_PAGE,
  PRODUCT_TABLE_TYPE,
  FOOTER_TYPE,
  HEADER_TYPE,
  IMAGE_PAGE,
  IMAGE_STATUS_TYPE,
  SUMMARY_ESTIMATE_PAGE,
  SUMMARY_ESTIMATE_TABLE_TYPE,
  TEXT_EDITOR_PAGE,
  BUILDING_DESCRIPTION_PAGE,
  TEXT_EDITOR_RESIZABLE_TYPE,
  TEXT_EDITOR_TYPE
} from "../../utility/const";
import { groupBy } from "../../../../utility/filter-tools";
import PreviewPage from "../preview-components/PreviewPage.vue";
import { uuid } from "vue-uuid";
import { clearSelectionHightLight } from "@/utility/common";
import { PDF_CONFIG } from "../../../../utility/pdf";

export default {
  components: {
    ProposalSendEmailPopup,
    SectionEditor,
    DetailMenu,
    DetailMenuMobile,
    ChangeProductItemPopup,
    ChangePhotoPopup,
    ProposalVerbiagePopup,
    ProposalFinancingPopup,
    EditTablePopup,
    SignaturePopup,
    PreviewPage
  },

  mixins: [common, financing],

  data() {
    return {
      isChange: false,
      currentSectionIndex: 0,
      sortedSections: [],
      type: "",
      isEdited: false,
      isShowProposal: false,
      dataProposal: {},
      styleContent: "",
      styleBox:
        "display: flex; justify-content: center; flex: 1; padding: 7px 0px; overflow-y: auto;",
      oldProposalId: "",
      dataChangeSize: [],
      isAutoSave: false,
      isShowPopupLoading: false,
      modifiedSections: [],
      uuid: uuid.v4(),
      autoSaveTimeoutId: null
    };
  },

  mounted() {
    this.createTooltips();
  },

  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal",

      proposalByNumber: "proposal/proposal/proposalByNumber",

      projectById: "proposal/project/projectById",
      estimateById: "proposal/estimate/estimateById",
      proposalTemplate: "proposal/template/proposalTemplate"
    }),
    ...mapGetters("setting/app/profile", ["user"]),
    ...mapGetters("proposal/price-list", ["categoryById"]),
    ...mapGetters("proposal/album", ["albumDetail"]),
    ...mapGetters("proposal/proposal", [
      "paramsBack",
      "queryBack",
      "tempProposal"
    ]),
    ...mapGetters("setting/app/profile", ["currentUser"]),

    pdfPages() {
      let sections = [];
      (this.modifiedSections.length > 0
        ? this.modifiedSections
        : this.sortedSections
      ).forEach(section => {
        const a = this.breakSection(section);
        sections = sections.concat(a);
      });
      const pages = [];
      const headerSection = sections.find(
        section => section.sectionId === "header-section"
      );
      const footerSection = sections.find(
        section => section.sectionId === "footer-section"
      );
      for (let index = 0; index < sections.length; index++) {
        const section = sections[index];
        switch (section.sectionId) {
          case "header-section":
          case "footer-section":
            break;
          default:
            pages.push({
              htmlValue: this.addBlankTargetToLinks(`
                <div style="height: 100%; display: flex; flex-direction: column;">
                  <div style="flex: 1; position: relative;">
                    ${section.htmlValue}
                  </div>
                  <div style="margin-bottom:40px;">
                    ${footerSection ? footerSection.htmlValue : ""}
                  </div>
                </div>`),
              pageId: section.sectionId,
              index
            });
        }
        if (index === 1 && headerSection && pages.length > 0) {
          pages[0].htmlValue = this.addBlankTargetToLinks(`
            <div style="height: 100%; display: flex; flex-direction: column;">
              <div>
                ${headerSection.htmlValue}
              </div>
              ${pages[0].htmlValue}
            </div>`);
        }
      }
      return pages;
    },

    hasEstimate() {
      return (
        !_.isEmpty(this.$f7route.query) &&
        !_.isEmpty(this.$f7route.query.estimateNumber)
      );
    }
  },

  methods: {
    ...mapActions({
      bindProposal: "proposal/proposal/bindProposal",
      unbindProposal: "proposal/proposal/unbindProposal",
      updateProposal: "proposal/proposal/updateProposal",
      deleteProposal: "proposal/proposal/deleteProposal",
      removeProposalPhotos: "proposal/proposal/removeProposalPhotos",
      setProposal: "proposal/proposal/setProposal",
      setProposalField: "proposal/proposal/setProposalField",
      getProposalBys: "proposal/proposal/getProposalBys",
      getProposalById: "proposal/proposal/getProposalById",
      removeRedundantPhotos: "proposal/proposal/removeRedundantPhotos",
      bindHistoryListBy: "proposal/history/bindHistoryListBy",
      createHistory: "proposal/history/createHistory",
      bindProjectPhotoListBy: "proposal/photo/bindProjectPhotoListBy",
      bindProjectPhotoAlbumListBy: "proposal/album/bindProjectPhotoAlbumListBy",
      bindProposalTemplate: "proposal/template/bindProposalTemplate",
      getPropertyById: "proposal/property/getPropertyById",
      getProjectById: "proposal/project/getProjectById",
      getCompanyById: "proposal/company/getCompanyById",
      getContactById: "proposal/contact/getContactById",
      getEstimateById: "proposal/estimate/getEstimateById",
      copyPhoto: "proposal/proposal/copyPhoto",
      getBuilding: "proposal/estimate/getBuilding",
      bindSubFinancingPropsalList:
        "proposal/proposal/bindSubFinancingPropsalList",
      updateSectionValue: "proposal/proposal/updateSectionValue",
      createNewProposal: "proposal/proposal/createNewProposal",
      bindConstantObjectList: "proposal/app-constant/bindConstantObjectList"
    }),
    ...mapActions("proposal/proposal", ["setParamsBack", "setQueryBack"]),

    ...mapActions("proposal/price-list", ["bindCategoryList"]),
    ...mapActions("proposal/project", [
      "getBoardById",
      "getProjectById",
      "getTaskById",
      "getActionById"
    ]),
    ...mapActions("proposal/proposal", [
      "saveTempProposal",
      "clearTempProposal"
    ]),
    ...mapActions({
      getSetting: "setting/app/system/getSetting"
    }),
    ...mapActions("proposal/proposal", {
      updateSection: "updateItemProposal"
    }),
    ...mapActions("common/notification", ["createNotificationByType"]),

    init() {
      const params = this.$f7route.params || {};
      const query = this.$f7route.query || {};
      this.setQueryBack(query);
      this.setParamsBack(params);
      this.activeTab =
        (this.$f7route.query && this.$f7route.query.activeTab) ||
        "proposal-detail";
      this.isShowProposal = true;
      let proposalNumber = this.$f7route.params.proposalNumber;
      let proposal = this.proposalByNumber(proposalNumber);
      this.$f7.preloader.show();
      if (!_.isEmpty(proposal)) {
        const promises = this.initData(proposal);
        Promise.all(promises).then(() => {
          this.lowResDesktopScreen();
          this.$f7.preloader.hide();
        });
      } else {
        this.getProposalBys([
          {
            prop: "proposalNumber",
            val: proposalNumber,
            op: "=="
          }
        ]).then(docs => {
          if (docs.length > 0) {
            const promises = this.initData(docs[0]);
            Promise.all(promises).then(() => {
              this.lowResDesktopScreen();
              this.$f7.preloader.hide();
            });
            this.dataProposal = _.cloneDeep(this.proposal);
          } else {
            this.lowResDesktopScreen();
            this.$f7.preloader.hide();
          }
        });
      }
      this.bindConstantObjectList();

      this.isChange = false;
      // this.lowResDesktopScreen();
      // this.unbindProposalTemplate();
    },
    createTooltips() {
      if (!this.$device.desktop) return;

      const tooltips = [
        {
          targetEl: `.download-doc-tooltip-${this.uuid}`,
          text: "Download PDF"
        },
        {
          targetEl: `.copy-doc-tooltip-${this.uuid}`,
          text: "Copy"
        },
        {
          targetEl: `.preview-doc-tooltip-${this.uuid}`,
          text: "Preview"
        },
        {
          targetEl: `.withdraw-doc-tooltip-${this.uuid}`,
          text: "Withdraw"
        },
        {
          targetEl: `.mark-as-sent-doc-tooltip-${this.uuid}`,
          text: "Mark as sent"
        }
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          text: tooltip.text
        });
      });
    },
    addBlankTargetToLinks(htmlString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      doc.querySelectorAll("a").forEach(a => {
        a.setAttribute("target", "_blank");
      });
      return doc.body.innerHTML;
    },
    lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = 1020;
        const letterFullHeight = 1320;
        const width = this.$refs.wrapContent.$el.clientWidth - 30;
        // const height = this.$refs.wrapContent.$el.clientHeight;
        // const spaceHeight = ((width * height) / letterFullWidth - height) / 2;
        if (width < letterFullWidth) {
          this.styleBox = " flex: 1; padding: 7px 0px; overflow-y: auto;";
          const scaleRatio = width / letterFullWidth + 0.008;
          this.styleContent = `transform: scale(${scaleRatio}); transform-origin: top left; margin-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left)); max-height: 80vh;`;
          if (this.$device.desktop) {
            this.styleContent += `margin-top: 20px;`;
          } else {
            this.styleContent += `margin-top: 80px;`;
          }
        } else {
          this.styleContent = "";
        }
        this.isShowProposal = false;
        let divs = Array.from(
          this.$refs.wrapHiddenContent.getElementsByTagName("div")
        );
        let indexCount = 0;
        let newSortedSections = this.sortedSections.map(section => {
          if (
            section.sectionId === "header-section" ||
            section.sectionId === "footer-section" ||
            section.sectionId === "cover-page"
          ) {
            return section;
          }
          let targetDivs = divs.filter(
            div =>
              div.style.textAlign === "justify" && div.style.padding === "56px"
          );
          let pageNumber = 1;
          let childDivs = targetDivs[indexCount].querySelectorAll("div");
          let divSections = section.htmlValue.split("</div>");

          for (let i = 0; i < childDivs.length; i++) {
            let childDiv = childDivs[i];

            let pageHeight =
              pageNumber === 1
                ? section.index !== 1
                  ? letterFullHeight - 100
                  : letterFullHeight - 300
                : section.index !== 1
                ? pageNumber * (letterFullHeight - 100)
                : (pageNumber - 1) * (letterFullHeight - 100) +
                  (letterFullHeight - 300);
            if (childDiv.offsetHeight + childDiv.offsetTop > pageHeight) {
              let strChild = childDiv.outerHTML.replace("</div>", "").trim();
              let trimmedDivSections = divSections.map(section =>
                section.trim()
              );
              let count = -1;
              trimmedDivSections.map((section, index) => {
                if (
                  section.trim().replace(/\s+/g, " ") ===
                  strChild.trim().replace(/\s+/g, " ")
                ) {
                  count = index;
                }
              });
              if (count !== -1) {
                let list = childDiv.querySelector("ul, ol");
                if (list) {
                  let listItems = list.querySelectorAll("li");
                  let newDiv = null;
                  let newUl = null;
                  listItems.forEach(li => {
                    let liOffsetTotal = li.offsetHeight + li.offsetTop;
                    if (liOffsetTotal > pageHeight) {
                      if (!newDiv) {
                        newDiv = document.createElement("div");
                        newDiv.classList.add("break-page");
                        newDiv.style.marginTop = "15px";
                        newUl = document.createElement("ul");
                        newUl.style.listStyleType = "disc";
                        newUl.style.fontSize = "20px";
                        newDiv.appendChild(newUl);
                        childDiv.parentNode.insertBefore(
                          newDiv,
                          childDiv.nextSibling
                        );
                        pageNumber += 1;
                      }
                      newUl.appendChild(li);
                    }
                  });
                  divSections[i] =
                    childDiv.outerHTML + newDiv.outerHTML.replace("</div>", "");
                } else {
                  if (childDiv.classList.length > 0) {
                    childDiv = childDivs[i - 1];
                    childDiv.classList.add("break-page");
                    divSections[i - 1] = childDiv.outerHTML.replace(
                      "</div>",
                      ""
                    );
                  } else {
                    childDiv.classList.add("break-page");
                    divSections[i] = childDiv.outerHTML.replace("</div>", "");
                  }
                  pageNumber += 1;
                }
              }
            }
          }
          indexCount += 1;
          return {
            ...section,
            htmlValue: divSections.join("</div>")
          };
        });
        this.modifiedSections = newSortedSections;
      });
    },
    breakSection(section) {
      const replaced1 = section.htmlValue.replace(
        `<div style="margin-top:15px" class="break-page">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );

      const replaced2 = replaced1.replace(
        `<div style="margin-top:15px;" class="break-page">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      const replaced3 = replaced2.replace(
        `<div class="break-page" style="margin-top: 15px;">`,
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      const arr = replaced3.split(
        `<div style=" margin-top: 15px;" class="break-page">`
      );
      if (arr.length > 1) {
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (i === 0) {
            arr[i] = `${item}</div></div></font>`;
          } else if (i === arr.length - 1) {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; padding: 56px;" class="">
                          <div class="">${item}`;
          } else {
            arr[i] = `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
                      <div class="">
                        <div style="text-align: justify; padding: 56px;" class="">
                          <div class="">${item}</div></div></font>`;
          }
        }
      }
      return arr.map(r => ({
        ...section,
        htmlValue: r
      }));
    },
    initData(proposal) {
      let promises = [];

      promises.push(this.setProposal(proposal));

      if (proposal.proposalTemplateId) {
        promises.push(this.bindProposalTemplate(proposal.proposalTemplateId));
      }

      promises.push(
        this.bindSubFinancingPropsalList({
          proposalId: proposal.id
        })
      );
      promises.push(
        this.bindHistoryListBy({
          prop: "proposalId",
          val: proposal.id,
          op: "=="
        })
      );
      if (proposal.projectId) {
        promises.push(
          this.bindProjectPhotoListBy({
            prop: "projectId",
            val: proposal.projectId,
            op: "=="
          })
        );
        promises.push(
          this.bindProjectPhotoAlbumListBy({
            prop: "projectId",
            val: proposal.projectId,
            op: "=="
          })
        );
      }
      return promises;
    },

    updatePage($event, index) {
      let pages = _.cloneDeep((this.proposal || {}).pages);
      pages[index].components = $event;
      this.setProposalField({ fieldName: "pages", value: pages });
      this.isChange = true;
      //save on change
      //this.onSave();
    },

    async changeTableContentType(value) {
      this.$f7.preloader.show();

      const productSections =
        this.proposal.sections.filter(
          item => item.sectionId === "product-data"
        ) || [];

      this.setProposalField({ fieldName: "tableContentType", value: value });
      await this.updateProposal({
        id: this.proposal.id,
        doc: { tableContentType: value }
      });

      for (let index = 0; index < productSections.length; index++) {
        const section = productSections[index];
        const allProducts =
          (
            (this.proposal.products || []).find(
              item => item.buildingId === section.buildingId
            ) || {}
          ).productData || [];

        await this.reloadCheckedProducts({
          index: 0,
          allProducts,
          sectionId: section.sectionId,
          buildingId: section.buildingId,
          callback: () => {}
        });
      }

      this.$f7.preloader.hide();
    },
    removePage(indexOfItem) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: "Delete Page " + (indexOfItem + 1),
        content: "Are you sure you want to delete this page?",
        textButton: "Delete",
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            let pages = _.cloneDeep((app.proposal || {}).pages);
            const page = pages[indexOfItem];
            if (page.name === BUILDING_PRODUCT_PAGE) {
              const tableComponent = page.components.find(
                r => r.type === PRODUCT_TABLE_TYPE
              );
              const buildingId = tableComponent.data.buildingId;
              const buildingIDs = app.proposal.buildingIDs.filter(
                r => r !== buildingId
              );
              app.setProposalField({
                fieldName: "buildingIDs",
                value: buildingIDs
              });
              const productIdsRemoved =
                _.cloneDeep(app.proposal.productIdsRemoved) || [];
              app.setProposalField({
                fieldName: "productIdsRemoved",
                value: productIdsRemoved.filter(
                  r => r.buildingId !== buildingId
                )
              });
            }
            if (page.name === SUMMARY_ESTIMATE_PAGE) {
              const buildingIds = page.components[1].data.buildingIds || [];
              const buildingIDs = app.proposal.buildingIDs.filter(
                r => !buildingIds.includes(r)
              );
              app.setProposalField({
                fieldName: "buildingIDs",
                value: buildingIDs
              });
              if (!_.isEmpty(buildingIds)) {
                const productIdsRemoved =
                  _.cloneDeep(app.proposal.productIdsRemoved) || [];
                app.setProposalField({
                  fieldName: "productIdsRemoved",
                  value: productIdsRemoved.filter(
                    r => !buildingIds.includes(r.buildingId)
                  )
                });
              }
            }
            pages.splice(indexOfItem, 1);
            app.setProposalField({ fieldName: "pages", value: pages });
            app.isChange = true;
          }
        }
      });
    },

    addNewPage({ pageType, index }) {
      let pages = _.cloneDeep((this.proposal || {}).pages);
      let newPage = this.prepareNewPage(pageType, pages);
      pages.splice(index + 1, 0, newPage);
      this.setProposalField({ fieldName: "pages", value: pages });
      this.isChange = true;
      switch (pageType) {
        case TEXT_EDITOR_PAGE:
          setTimeout(() => this.focusOnNewPage(index), 1000);
          break;
        case BUILDING_DESCRIPTION_PAGE:
          break;
        case BUILDING_PRODUCT_PAGE:
          break;
        case IMAGE_PAGE:
          break;
      }
    },

    prepareNewPage(pageType, pages) {
      let page = _.cloneDeep(pages[0]);
      let components = _.cloneDeep(page.components);
      components = components.filter(
        r => r.type == HEADER_TYPE || r.type == FOOTER_TYPE
      );

      let initComponents = [];
      let pageName = pageType;
      switch (pageType) {
        case TEXT_EDITOR_PAGE:
        case BUILDING_DESCRIPTION_PAGE:
          initComponents = this.prepareTextEditorPage();
          break;
        case BUILDING_PRODUCT_PAGE:
          initComponents = this.prepareBuildingProductPage().initComponents;
          pageName = this.prepareBuildingProductPage().pageName;
          break;
        case IMAGE_PAGE:
          initComponents = this.prepareImagePage();
          break;
      }

      components.splice(1, 0, ...initComponents);
      page.components = components;
      page.name = pageName;

      return page;
    },

    prepareTextEditorPage() {
      return [
        {
          type: TEXT_EDITOR_TYPE,
          data: {
            value: ""
          }
        }
      ];
    },

    prepareBuildingProductPage() {
      const titleComponent = {
        type: TEXT_EDITOR_RESIZABLE_TYPE,
        data: {
          value: ""
        }
      };
      let tableComponent = {
        type: PRODUCT_TABLE_TYPE,
        data: {
          estimateId: this.proposal.estimateId,
          buildingId: "",
          building: {}
        }
      };
      let pageName = BUILDING_PRODUCT_PAGE;
      if (
        this.isServiceJob &&
        (this.proposal.tableContentType === TABLE_CONTENT_TYPE_SIMPLE_SUMMARY ||
          this.proposal.tableContentType === TABLE_CONTENT_TYPE_LABOR_MATERIAL)
      ) {
        tableComponent = {
          type: SUMMARY_ESTIMATE_TABLE_TYPE,
          data: {
            estimateId: this.proposal.estimateId,
            buildingIds: [],
            buildings: []
          }
        };
        pageName = SUMMARY_ESTIMATE_PAGE;
      }
      return {
        initComponents: [titleComponent, tableComponent],
        pageName
      };
    },

    prepareImagePage() {
      const titleComponent = {
        type: TEXT_EDITOR_RESIZABLE_TYPE,
        data: {
          value: ""
        }
      };
      const imageComponent = {
        type: IMAGE_STATUS_TYPE,
        data: {
          images: []
        }
      };
      return [titleComponent, imageComponent];
    },

    focusOnNewPage(index) {
      let page = this.$refs.pageList.getElementsByClassName("page-form")[
        index + 1
      ];
      let textEditor = page.getElementsByClassName("text-editor-content");
      if (textEditor && textEditor.length > 1) {
        textEditor[1].focus();
      }
    },

    handleEditorChange({ index, value }) {
      this.isEdited = true;
      this.isAutoSave = true;
      this.isShowPopupLoading = false;
      this.updateSectionValue({ index, value });
    },

    handleEditorFocus() {
      // Some time, the changed event is not triggered or triggered too slow,
      // so we need to check the focus event
      this.isEdited = true;
      this.isAutoSave = true;
      this.isShowPopupLoading = false;
    },

    openSignaturePopup() {
      this.isShowPopupLoading = true;
      this.onSave(() => {
        this.$refs.signaturePopup.openPopup();
      });
    },

    async onSave(callback, isResetProposal = true) {
      if (this.isEdited) {
        this.cancelAutoSaveTimeout();

        // Filter and sort sections that are not deleted
        const sections = Object.assign(
          [],
          this.proposal.sections
            .filter(section => !section.isDeleted)
            .sort((a, b) => a.index - b.index)
            .map((section, index) => ({ ...section, index }))
        );
        const refs = [];
        refs.push(
          this.updateProposal({
            id: this.proposal.id,
            doc: { sections }
          })
        );
        refs.push(this.removeRedundantPhotos(this.proposal));
        refs.push(
          this.createHistory({
            proposalId: this.proposal.id,
            note: "Edited"
          })
        );

        if (this.isShowPopupLoading) {
          this.$f7.dialog.preloader("Save Proposal. Please wait...");
          // Only clear selection highlight when save proposal by manual; not auto save
          clearSelectionHightLight();
          await Promise.all(refs);
          await this.createNotificationByType({
            data: {
              assignees: [this.proposal.createdById],
              project: {
                title: this.proposal.proposalNumber,
                id: this.proposal.id,
                entityName: COLLECTION_PROPOSAL,
                proposalNumber: this.proposal.proposalNumber
              }
            },
            type: "edit-proposal"
          });
        } else {
          // Auto save
          Promise.all(refs);
        }

        if (isResetProposal) {
          const dataProposalUpdated = {
            ...this.proposal,
            sections
          };

          this.setProposal(dataProposalUpdated);
          this.dataProposal = Object.assign({}, dataProposalUpdated);
          this.sortedSections = Object.assign(
            [],
            dataProposalUpdated.sections
          ).sort((a, b) => a.index - b.index);

          // Reset modified sections for export PDF
          this.modifiedSections = [];
        }

        this.isEdited = false;
        this.isChange = false;
        this.isAutoSave = false;
        if (this.isShowPopupLoading === true) {
          this.isShowPopupLoading = false;
          this.$f7.dialog.close();
        }
      }

      if (typeof callback === "function") {
        return callback();
      }
    },

    cancelAutoSaveTimeout() {
      if (this.autoSaveTimeoutId) {
        clearTimeout(this.autoSaveTimeoutId);
        this.autoSaveTimeoutId = null;
      }
    },

    doActionDontSave(callback) {
      this.isEdited = false;
      if (typeof callback === "function") {
        return callback();
      }
    },

    checkAskSave(callback) {
      clearSelectionHightLight();
      const app = this;
      if (this.isEdited === true) {
        this.isShowPopupLoading = true;
        app.onSave(function() {
          if (typeof callback === "function") {
            return callback();
          }
        });
      } else {
        if (typeof callback === "function") {
          return callback();
        }
      }
    },

    async sendEmailProposal() {
      if (!this.proposal.companyRepresentativeSigner) {
        const company = await this.getSettingDoc();
        return this.$ri.dialog.openWarningDialog({
          title: "Sign Online",
          content: `Please have the ${company.data.companyName ||
            "company"} representative sign before sending an email to the customer.`,
          hideCancelButton: true,
          onClick: _sefl => {
            _sefl.app.dialog.close();
          }
        });
      } else {
        if (this.isEdited && this.oldProposalId === this.proposal.id) {
          const app = this;
          this.isShowPopupLoading = true;

          this.onSave(function() {
            app.$refs.sendEmailPopup.sendMail(_.cloneDeep(app.proposal));
          });
        } else {
          this.$refs.sendEmailPopup.sendMail(_.cloneDeep(this.proposal));
        }
      }
      clearSelectionHightLight();
    },

    markAsSentProposal() {
      if (
        this.proposal.status === "draft" ||
        this.proposal.status === "viewed"
      ) {
        const app = this;
        this.checkAskSave(function() {
          app.$ri.dialog.openInfoDialog({
            title: "Mark as Sent",
            content: "Are you want to Mark as Sent the proposal?",
            textButton: "Confirm",
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                app.$f7.preloader.show();
                app
                  .updateProposal({
                    id: app.proposal.id,
                    doc: {
                      ...app.proposal,
                      status: "sent"
                    }
                  })
                  .then(() => {
                    return app.getProposalById(app.proposal.id);
                  })
                  .then(proposal => {
                    return app.setProposal(proposal);
                  })
                  .then(() => {
                    // save history
                    app.createHistory({
                      proposalId: app.proposal.id,
                      note: "Sent"
                    });
                    app.$f7.preloader.hide();
                  });
              }
            }
          });
        });
      } else {
        const app = this;
        app.$ri.dialog.openInfoDialog({
          title: "Send proposal",
          content: "Are you sure you want to send the proposal?",
          textButton: "Confirm",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              app.$f7.preloader.show();
              app
                .updateProposal({
                  id: app.proposal.id,
                  doc: {
                    ...app.proposal,
                    status: "sent"
                  }
                })
                .then(() => {
                  return app.getProposalById(app.proposal.id);
                })
                .then(proposal => {
                  return app.setProposal(proposal);
                })
                .then(() => {
                  // save history
                  app.createHistory({
                    proposalId: app.proposal.id,
                    note: "Sent"
                  });
                  app.$f7.preloader.hide();
                });
            }
          }
        });
      }
    },

    withdrawProposal() {
      if (
        this.proposal.status === "draft" ||
        this.proposal.status === "viewed"
      ) {
        const app = this;
        this.checkAskSave(function() {
          app.$ri.dialog.openInfoDialog({
            title: "Withdraw proposal",
            content: "Are you sure you want to withdraw from the proposal?",
            textButton: "Confirm",
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                app.$f7.preloader.show();
                app
                  .updateProposal({
                    id: app.proposal.id,
                    doc: {
                      ...app.proposal,
                      status: "withdraw"
                    }
                  })
                  .then(() => {
                    return app.getProposalById(app.proposal.id);
                  })
                  .then(proposal => {
                    return app.setProposal(proposal);
                  })
                  .then(() => {
                    // save history
                    app.createHistory({
                      proposalId: app.proposal.id,
                      note: "Withdrawn"
                    });
                    app.$f7.preloader.hide();
                  });
              }
            }
          });
        });
      } else {
        this.$ri.dialog.openInfoDialog({
          title: "Withdraw proposal",
          content: "Are you sure you want to withdraw from the proposal?",
          textButton: "Confirm",
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.$f7.preloader.show();
              this.updateProposal({
                id: this.proposal.id,
                doc: {
                  ...this.proposal,
                  status: "withdraw"
                }
              })
                .then(() => {
                  return this.getProposalById(this.proposal.id);
                })
                .then(proposal => {
                  return this.setProposal(proposal);
                })
                .then(() => {
                  // save history
                  this.createHistory({
                    proposalId: this.proposal.id,
                    note: "Withdrawn"
                  });
                  this.$f7.preloader.hide();
                });
            }
          }
        });
      }
    },

    toPreview() {
      if (this.isEdited && this.oldProposalId === this.proposal.id) {
        const app = this;
        this.isShowPopupLoading = true;
        this.onSave(function() {
          app.redirectToPreview();
        });
      } else {
        clearSelectionHightLight();
        this.redirectToPreview();
      }
    },

    redirectToPreview() {
      this.$f7router.navigate(
        `/proposal/${this.proposal.proposalNumber}/preview`
      );
    },

    reloadProposalAfterSignature() {
      this.bindProposal(this.proposal.id).then(() => {
        this.sortedSections = _.cloneDeep(this.proposal.sections);
      });
    },

    copyProposal() {
      if (this.isEdited && this.oldProposalId === this.proposal.id) {
        const app = this;
        this.isShowPopupLoading = true;
        this.onSave(function() {
          app.dataProposal = _.cloneDeep(app.proposal);
          app.handleCopyProposal();
        });
      } else {
        this.handleCopyProposal();
      }
    },
    async getSettingDoc() {
      const response = await this.getSetting(this.currentUser.tenantId);
      return { success: true, data: response };
    },

    handleCopyProposal() {
      clearSelectionHightLight();
      const app = this;
      app.$ri.dialog.openInfoDialog({
        title: "Copy proposal.",
        content: "Are you sure you want to copy the proposal?",
        textButton: "Confirm",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            let doc = app.dataProposal;
            doc = {
              ...doc,
              copyFromId: doc.id,
              status: "draft",
              id: null
            };
            if (doc.ownerSigner || doc.companyRepresentativeSigner) {
              const indexOfSignatureSection = doc.sections.findIndex(
                r => r.sectionId === "signature-section"
              );
              // get org signature section in template
              const signatureTemp = app.proposalTemplate.sections.find(
                section => section.sectionId === "signature-section"
              );
              const responseGetSetting = await this.getSettingDoc();
              signatureTemp.htmlValue = signatureTemp.htmlValue.replace(
                '<span style="background-color: #f05034;">[Roofing Company Name]</span>',
                responseGetSetting.data.companyName || ""
              );
              if (indexOfSignatureSection > -1) {
                doc.sections.splice(indexOfSignatureSection, 1, {
                  ...signatureTemp,
                  index: indexOfSignatureSection
                });
              }
            }
            doc.updatedAt && delete doc.updatedAt;
            doc.updatedBy && delete doc.updatedBy;
            doc.signatureImageUrl && delete doc.signatureImageUrl;
            doc.signatureImageFullPath && delete doc.signatureImageFullPath;
            doc.companyRepresentativeSigner &&
              delete doc.companyRepresentativeSigner;
            doc.ownerSigner && delete doc.ownerSigner;
            doc.fullNameOfSigner && delete doc.fullNameOfSigner;

            const proposalCreated = await app.createNewProposal(doc);

            // save history
            app.createHistory({
              proposalId: proposalCreated.id,
              note: "Created"
            });

            app.$f7router.navigate(`/proposal/${proposalCreated.docNumber}`, {
              pushState: true
            });
            app.$f7.preloader.hide();
          }
        }
      });
    },

    sectionClick({ section, index }) {
      this.$refs[`editor_${index}`][0].moveSectionToView(section);
      this.currentSectionIndex = index;
    },

    reloadCheckedPhotos({ isAddNewSection, index, photos, callback }) {
      this.$f7.preloader.show();
      const orgSections = _.cloneDeep(this.proposal.sections);
      let sections = [];
      if (isAddNewSection) {
        // group by album
        const groupPhotosByAlbum = groupBy(photos, i => i.albumId);
        let newSections = [];
        // get org project photo section in template
        const photosSectionTemp = this.proposalTemplate.sections.find(
          section => section.sectionId === "project-photos"
        );
        groupPhotosByAlbum.forEach(item => {
          newSections = newSections.concat(
            this.compileProjectPhotoSection(
              photosSectionTemp,
              item.data,
              item.key === "undefined" || item.key === "null"
                ? null
                : this.albumDetail(item.key)
            )
          );
        });
        // compile
        sections = _.cloneDeep(orgSections);
        sections.splice(index, 0, ...newSections);
      } else {
        // Find the project photo section
        const photosSection = orgSections.find(
          section => section.index === index
        );

        // filter sections of project photo
        const photosSectionList = orgSections.filter(
          section => section.sectionId === photosSection.sectionId
        );

        // filter other items
        sections = orgSections.filter(
          section => section.sectionId !== photosSection.sectionId
        );

        // get the index of the first project photo section
        const sectionIndex = photosSectionList.sort(
          (a, b) => a.index - b.index
        )[0].index;

        // get org project photo section in template
        const photosSectionTemp = this.proposalTemplate.sections.find(
          section => section.sectionId === "project-photos"
        );

        // compile
        sections.splice(
          sectionIndex,
          0,
          ...this.compileProjectPhotoSection(
            photosSectionTemp,
            _.cloneDeep(photos),
            this.albumDetail(photosSection.albumId)
          )
        );
      }

      let i = 0;
      sections.forEach(section => {
        section.index = i++;
      });

      // save to proposal
      return this.updateProposal({
        id: this.proposal.id,
        doc: { sections }
      })
        .then(() => {
          return this.getProposalById(this.proposal.id);
        })
        .then(proposal => {
          return this.initData(proposal);
        })
        .then(() => {
          this.$f7.preloader.hide();
          callback();
        });
    },

    async reloadCheckedProducts({
      // eslint-disable-next-line no-unused-vars
      index,
      allProducts,
      sectionId,
      buildingId,
      callback
    }) {
      this.$f7.preloader.show();
      const checkedProducts = _.cloneDeep(
        allProducts.filter(item => item.checked)
      );

      const refs = [];
      refs.push(
        this.getBuilding({
          estimateId: this.proposal.estimateId,
          buildingId
        })
      );
      refs.push(this.getEstimateById(this.proposal.estimateId));
      refs.push(this.getProjectById(this.proposal.projectId));
      refs.push(this.bindCategoryList());

      return Promise.all(refs)
        .then(data => {
          const building = data[0];
          const estimate = data[1];
          const project = data[2];
          // Find the section
          const sections = _.cloneDeep(this.proposal.sections);
          // get the first index of
          const currentIndex = sections.findIndex(
            section =>
              section.sectionId === sectionId &&
              section.buildingId === buildingId
          );

          // .sort((a, b) => a.index - b.index)[0].index;

          _.remove(
            sections,
            section =>
              section.buildingId === buildingId &&
              section.sectionId === sectionId
          );

          const products = _.cloneDeep(this.proposal.products || []);
          _.remove(products, item => item.buildingId === buildingId);

          products.push({
            buildingId,
            productData: _.cloneDeep(allProducts)
          });

          // Compile products
          const orgProductSection = this.proposalTemplate.sections.find(
            section => section.sectionId === "product-data"
          );

          sections.splice(
            currentIndex,
            0,
            ...this.buildProductSectionContent({
              products: checkedProducts,
              orgSection: orgProductSection,
              project,
              building,
              estimate
            })
          );

          // Update for building info section
          const orgSection = this.proposalTemplate.sections.find(
            section => section.sectionId === "building-info"
          );

          const buildingInfoDataSection = sections.find(
            section =>
              section.sectionId === "building-info" &&
              section.buildingId === buildingId
          );

          const buildingInfoHtmlValue =
            project.businessCode === "commercial"
              ? this.compileBuildingInfoByBlocks({
                  blockData: _.cloneDeep(buildingInfoDataSection.blockData),
                  products: checkedProducts,
                  value: _.cloneDeep(orgSection.htmlValue),
                  building,
                  project,
                  estimate
                })
              : this.compileBuildingInfo({
                  products: checkedProducts,
                  value: _.cloneDeep(orgSection.htmlValue),
                  building,
                  project,
                  estimate
                });

          buildingInfoDataSection.htmlValue = buildingInfoHtmlValue;

          let i = 0;
          sections.forEach(section => {
            section.index = i++;
          });

          // save to proposal
          return this.updateProposal({
            id: this.proposal.id,
            doc: { sections, products }
          })
            .then(() => this.proposal.id)
            .then(proposalId => {
              return this.getProposalById(proposalId).then(proposal => {
                return this.initData(proposal);
              });
            })
            .then(() => {
              return {
                buildingIDs: this.proposal.buildingIDs,
                project,
                estimate
              };
            });
        })
        .then(({ buildingIDs, project, estimate }) => {
          // Update for financing info section
          return this.initFinancingProposal({
            buildingIDs,
            project,
            estimate
          }).then(financingProposal => financingProposal);
        })
        .then(financingProposal => {
          return this.reloadFinancingInfo({
            sectionId: "financing-proposal",
            financingList: financingProposal,
            callback
          });
        });
    },

    // eslint-disable-next-line no-unused-vars
    reloadProducts({ index, buildingId, sectionId }) {
      this.$f7.preloader.show();
      const refs = [];
      refs.push(
        this.getBuilding({
          estimateId: this.proposal.estimateId,
          buildingId
        })
      );
      refs.push(this.getEstimateById(this.proposal.estimateId));
      refs.push(this.getProjectById(this.proposal.projectId));
      refs.push(this.bindCategoryList());

      Promise.all(refs)
        .then(data => {
          const building = data[0];
          const estimate = data[1];
          const project = data[2];

          // get product list and save to section data
          const productData = [];
          building.productData.forEach(section => {
            productData.push(
              ...section.productList.map(product => {
                return {
                  ...product,
                  checked: this.isCheckedProduct(product),
                  category:
                    (this.categoryById(product.categoryId) || {}).name || ""
                };
              })
            );
          });

          const sections = _.cloneDeep(this.proposal.sections);

          // get the first index of
          const currentIndex = sections.findIndex(
            section =>
              section.sectionId === sectionId &&
              section.buildingId === buildingId
          );

          _.remove(
            sections,
            section =>
              section.buildingId === buildingId &&
              section.sectionId === sectionId
          );

          const products = _.cloneDeep(this.proposal.products || []);
          _.remove(products, item => item.buildingId === buildingId);

          products.push({
            buildingId,
            productData
          });

          const checkedProducts = productData.filter(item => item.checked);

          const orgProductSection = this.proposalTemplate.sections.find(
            section => section.sectionId === "product-data"
          );

          sections.splice(
            currentIndex,
            0,
            ...this.buildProductSectionContent({
              products: checkedProducts,
              // currentIndex,
              orgSection: orgProductSection,
              project,
              building,
              estimate
            })
          );

          // Update for building info section
          const orgSection = this.proposalTemplate.sections.find(
            section => section.sectionId === "building-info"
          );

          const buildingInfoDataSection = sections.find(
            section =>
              section.sectionId === "building-info" &&
              section.buildingId === buildingId
          );

          const buildingInfoHtmlValue =
            project.businessCode === "commercial"
              ? this.compileBuildingInfoByBlocks({
                  blockData: _.cloneDeep(buildingInfoDataSection.blockData),
                  products: _.cloneDeep(productData).filter(
                    item => item.checked
                  ),
                  value: _.cloneDeep(orgSection.htmlValue),
                  building,
                  project,
                  estimate
                })
              : this.compileBuildingInfo({
                  products: _.cloneDeep(productData).filter(
                    item => item.checked
                  ),
                  value: _.cloneDeep(orgSection.htmlValue),
                  building,
                  project,
                  estimate
                });

          buildingInfoDataSection.htmlValue = buildingInfoHtmlValue;

          // re-indexing
          let i = 0;
          sections.forEach(section => {
            section.index = i++;
          });

          // save to db
          return this.updateProposal({
            id: this.proposal.id,
            doc: { sections, products }
          })
            .then(() => this.proposal.id)
            .then(proposalId => {
              return this.getProposalById(proposalId).then(proposal => {
                return this.initData(proposal);
              });
            })
            .then(() => {
              return {
                buildingIDs: this.proposal.buildingIDs,
                project,
                estimate
              };
            });
        })
        .then(({ buildingIDs, project, estimate }) => {
          // Update for financing info section
          return this.initFinancingProposal({
            buildingIDs,
            project,
            estimate
          }).then(financingProposal => financingProposal);
        })
        .then(financingProposal => {
          return this.reloadFinancingInfo({
            sectionId: "financing-proposal",
            financingList: financingProposal,
            callback: () => {}
          });
        });
    },

    // eslint-disable-next-line no-unused-vars
    reloadBuildingInfo({ buildingId, sectionId, blockData, callback }) {
      this.$f7.preloader.show();
      const refs = [];
      refs.push(
        this.getBuilding({
          estimateId: this.proposal.estimateId,
          buildingId
        })
      );
      refs.push(this.getEstimateById(this.proposal.estimateId));
      refs.push(this.getProjectById(this.proposal.projectId));

      Promise.all(refs)
        .then(data => {
          const building = data[0];
          const estimate = data[1];
          const project = data[2];

          return this.initKeysData({ project, estimate }).then(() => {
            return {
              building,
              estimate,
              project
            };
          });
        })
        .then(({ building, estimate, project }) => {
          // get product list and save to section data
          const productData =
            (
              this.proposal.products.find(
                item => item.buildingId === buildingId
              ) || {}
            ).productData || [];

          const orgSection = this.proposalTemplate.sections.find(
            section => section.sectionId === "building-info"
          );

          const sections = _.cloneDeep(this.proposal.sections);

          const buildingInfoDataSection = sections.find(
            section =>
              section.sectionId === "building-info" &&
              section.buildingId === buildingId
          );

          const buildingInfoHtmlValue = this.compileBuildingInfoByBlocks({
            // blockData: this.initBlockData({ building, orgSection }),
            blockData,
            products: _.cloneDeep(productData),
            value: _.cloneDeep(orgSection.htmlValue),
            building,
            project,
            estimate
          });

          buildingInfoDataSection.htmlValue = buildingInfoHtmlValue;
          // buildingInfoDataSection.blockData = this.initBlockData({
          //   building,
          //   orgSection
          // });
          buildingInfoDataSection.blockData = blockData;

          return this.updateProposal({
            id: this.proposal.id,
            doc: { sections }
          }).then(() => this.proposal.id);
        })
        .then(proposalId => {
          return this.getProposalById(proposalId).then(proposal => {
            return this.initData(proposal);
          });
        })
        .then(() => {
          callback();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    reloadFinancingInfo({ sectionId, financingList, callback }) {
      const orgSection = this.proposalTemplate.sections.find(
        section => section.sectionId === sectionId
      );

      const sections = _.cloneDeep(this.proposal.sections);
      if (orgSection) {
        const financingSection = sections.find(
          section => section.sectionId === sectionId
        );

        const financingHtmlValue = this.compileFinancingProposal({
          financingList,
          value: _.cloneDeep(orgSection.htmlValue)
        });

        financingSection.htmlValue = financingHtmlValue;
      }

      return this.updateProposal({
        id: this.proposal.id,
        doc: { sections }
      })
        .then(() => this.proposal.id)
        .then(proposalId => {
          return this.getProposalById(proposalId).then(proposal => {
            return this.initData(proposal);
          });
        })
        .then(() => {
          callback();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    doAction({ index, actionCode, buildingId, sectionId }) {
      // console.log({ index, actionCode, buildingId });
      switch (actionCode) {
        case "edit-products":
          this.$refs.productItemPopup.openPopup(
            index,
            this.proposal.estimateId,
            buildingId,
            sectionId
          );
          break;

        case "reload-products":
          this.reloadProducts({ index, buildingId, sectionId });
          break;

        case "change-photo":
          this.$refs.photoPopup.openChangePopup(index);
          break;

        case "edit-verbiage":
          this.$refs.verbiageProposalPopup.openPopup(
            this.proposal.estimateId,
            buildingId,
            sectionId
          );
          break;

        case "edit-financing":
          this.$refs.financingProposalPopup.openPopup(
            this.proposal.estimateId,
            sectionId
          );
          break;

        case "edit-table":
          this.$refs.editTablePopup.openPopup();
          break;
      }
    },

    // eslint-disable-next-line no-unused-vars
    async editTable({ columns, callback }) {
      this.$f7.preloader.show();
      this.setProposalField({
        fieldName: "productTableColumns",
        value: columns
      });
      await this.updateProposal({
        id: this.proposal.id,
        doc: { productTableColumns: columns }
      });

      const productSections =
        this.proposal.sections.filter(
          item => item.sectionId === "product-data"
        ) || [];

      for (let index = 0; index < productSections.length; index++) {
        const section = productSections[index];
        const allProducts =
          (
            (this.proposal.products || []).find(
              item => item.buildingId === section.buildingId
            ) || {}
          ).productData || [];

        await this.reloadCheckedProducts({
          index: 0,
          allProducts,
          sectionId: section.sectionId,
          buildingId: section.buildingId,
          callback: () => {}
        });
      }

      this.$f7.preloader.hide();
      callback();
    },

    async onDndChange(dndEvent) {
      const sections = _.clone(this.sortedSections);
      const eventKeys = Object.keys(dndEvent);
      for (let index = 0; index < eventKeys.length; index++) {
        const key = eventKeys[index];
        const event = dndEvent[key];
        switch (key) {
          case "moved":
            this.$f7.preloader.show();
            if (event.oldIndex < event.newIndex) {
              sections[event.newIndex].index =
                sections[event.newIndex - 1].index;
              for (let i = event.oldIndex; i < event.newIndex; i++) {
                sections[i].index = sections[i].index - 1;
              }
            } else {
              sections[event.newIndex].index =
                sections[event.newIndex + 1].index;
              for (let i = event.newIndex + 1; i <= event.oldIndex; i++) {
                sections[i].index = sections[i].index + 1;
              }
            }
            this.currentSectionIndex = event.newIndex;

            await this.updateProposal({
              id: this.proposal.id,
              doc: { sections }
            })
              .then(() => {
                return this.getProposalById(this.proposal.id);
              })
              .then(proposal => {
                return this.setProposal(proposal);
              })
              .then(() => {
                this.sectionClick({
                  section: event.element,
                  index: event.newIndex
                });
                if (this.isEdited) {
                  this.clearTempProposal();
                }
                this.$f7.preloader.hide();
              });
            break;
        }
      }
    },

    removeSection(index) {
      const newSection = _.cloneDeep(
        this.sortedSections.filter(section => section.index !== index)
      );
      this.sortedSections = this.sortAndUpDateIndexSectionBeforeSave(
        newSection
      );
      this.updateSection({
        index
      });
      this.lowResDesktopScreen();
    },

    restoreSection(index) {
      let proposalNumber = this.$f7route.params.proposalNumber;
      this.getProposalBys([
        {
          prop: "proposalNumber",
          val: proposalNumber,
          op: "=="
        }
      ]).then(docs => {
        this.sortedSections[index].isDeleted = false;
        this.sortedSections[index].sectionName =
          docs[0].sections[index].sectionName;
        this.sortedSections[index].htmlValue =
          docs[0].sections[index].htmlValue;
        // this.removeSections = this.sortedSections;
        this.updateSection({
          index,
          value: { ...docs[0].sections[index], isDeleted: false }
        });
        this.isEdited = false;
      });
    },

    sortAndUpDateIndexSectionBeforeSave(sections) {
      const newSections = _.cloneDeep(
        sections.filter(e => e.isDeleted !== true)
      );
      newSections.sort((a, b) => a.index - b.index);

      for (let i = 0; i < newSections.length; i++) {
        newSections[i].index = i;
      }
      return newSections;
    },

    addSection(index) {
      this.$f7.preloader.show();
      const insertIndex = index;
      const insertSection = {
        index: insertIndex,
        sectionId: "custom-section",
        sectionName: "Custom Section",
        htmlValue: `<font face="'Sofia Pro', Verdana, Geneva, sans-serif">
            <div>
                <div style="text-align: justify; padding: 56px;">
                    <div style=" margin-top: 15px;">
                        <span style="font-weight:500; font-size: 16px;">
                            New Section...
                        </span>
                    </div>
                </div>
            </div>
        </font>`
      };

      const newSections = _.clone(this.sortedSections);

      newSections.splice(insertIndex, 0, insertSection);

      const sections = this.sortAndUpDateIndexSectionBeforeSave(newSections);

      this.updateProposal({
        id: this.proposal.id,
        doc: { sections }
      })
        .then(() => {
          return this.getProposalById(this.proposal.id);
        })
        .then(proposal => {
          this.dataProposal = _.cloneDeep(proposal);
          return this.setProposal(proposal);
        })
        .finally(() => {
          this.$f7.preloader.hide();
          this.isEdited = false;
        });
    },

    addPhotosSection(index) {
      this.$refs.photoPopup.openAddNewSection(index);
    },

    onViewerScroll() {
      const scrollContainer = this.$refs["scrollContainer"];
      const scrollViewer = this.$refs["scrollViewer"].$el;

      if (scrollContainer.getBoundingClientRect().top > this.scrollPos) {
        // Scroll direction UP
        for (let index = this.sortedSections.length - 1; index >= 0; index--) {
          const editor = this.$refs[`editor_${index}`][0];

          if (editor) {
            const isInViewport = editor.isInViewport(
              scrollViewer.getBoundingClientRect().top
            );

            // Set the last section visible
            if (isInViewport === true) {
              this.currentSectionIndex = index;
            }
          }
        }
      } else {
        // Scroll direction DOWN
        for (let index = 0; index < this.sortedSections.length; index++) {
          const editor = this.$refs[`editor_${index}`][0];
          if (editor) {
            const isInViewport = editor.isInViewport(
              scrollViewer.getBoundingClientRect().top
            );

            // Set the first section visible
            if (isInViewport === true) {
              this.currentSectionIndex = index;
              break;
            }
          }
        }
      }
      this.scrollPos = scrollContainer.getBoundingClientRect().top;
      clearSelectionHightLight();
    },
    async downloadPdf(isPreview = false) {
      this.isShowPopupLoading = true;
      await this.onSave();

      this.$f7.dialog.preloader("Downloading PDF. Please wait...");

      const { width, height } = PDF_CONFIG.DOWNLOAD_SIZE;

      if (!isPreview) {
        await this.lowResDesktopScreen();
      }
      const doc = new jsPDF("p", "pt", [width, height]); //850x1100
      try {
        const images = await Promise.all(
          this.pdfPages.map(page =>
            generateImage(
              this.$refs[`${page.pageId}_${page.index}`][0].$el.innerHTML
            )
          )
        );
        for (let index = 0; index < images.length; index++) {
          const image = images[index];
          if (!_.isEmpty(image)) {
            if (index > 0) {
              doc.addPage([width, height], "p");
            }
            doc.setPage(index + 1);

            doc.addImage(
              image,
              "PNG",
              0,
              0,
              width,
              height,
              `page-${index}`,
              "FAST"
            );
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            const pageNumber = index + 1;
            const pageNumberString = `${pageNumber}`;
            const xPosition = width / 2;
            doc.text(pageNumberString, xPosition, height - 10);
          }
        }

        doc.save(
          `PROPOSAL - ${this.proposal.proposalNumber} - ${this.proposal.project.title}.pdf`
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn("Error while downloading PDF", error.message);
      } finally {
        this.$f7.dialog.close();
      }

      this.modifiedSections = [];
    },

    handleBackAction() {
      if (this.hasEstimate) {
        const {
          clientType,
          clientId,
          buildingName,
          estimateNumber
        } = this.$f7route.query;
        if (_.isEmpty(buildingName)) {
          this.$f7router.navigate(
            `/estimate/${clientType ? `${clientType}/` : ""}${
              clientId ? `${clientId}/` : ""
            }${estimateNumber}/summary`,
            {
              pushState: true
            }
          );
        } else {
          this.$f7router.navigate(
            `/estimate/${clientType ? `${clientType}/` : ""}${
              clientId ? `${clientId}/` : ""
            }${estimateNumber}/building/${buildingName}`,
            {
              pushState: true
            }
          );
        }
      } else if (
        this.hasProject ||
        (this.paramsBack.projectId && this.paramsBack.actionId)
      ) {
        this.navigateToBoard();
      } else {
        this.$f7router.navigate("/proposal", {
          pushState: true,
          reloadAll: true
        });
      }
    },

    async navigateToBoard() {
      let board = {};
      let card = {};
      let task = {};
      let action = {};

      this.$f7.preloader.show();
      if (this.paramsBack.boardId) {
        board = await this.getBoardById(this.paramsBack.boardId);
      }
      if (this.paramsBack.projectId) {
        card = await this.getProjectById(this.paramsBack.projectId);
      }
      if (this.paramsBack.taskId) {
        task = await this.getTaskById(this.paramsBack.taskId);
      }
      if (this.paramsBack.actionId) {
        action = await this.getActionById(this.paramsBack.actionId);
      }

      const businessCode = this.paramsBack.businessCode || "";
      let url = "";
      switch (businessCode) {
        case "commercial":
          url = `/dashboard/${this.paramsBack.boardType}/commercial/${this.paramsBack.boardId}/project/${card.cardNumber}/action/${action.code}`;
          if (this.paramsBack.boardId === "construction") {
            url += `/task/${task.taskNumber}`;
          }
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true
          });
          break;

        case "residential":
          url = `/dashboard/${this.paramsBack.boardType}/residential/${card.cardNumber}/action/${action.code}`;
          this.$f7router.navigate(url, {
            pushState: true,
            reloadAll: true
          });
          break;

        case "service":
          this.$f7router.navigate(
            `/dashboard/${this.paramsBack.boardType}/${this.paramsBack.businessCode}/${board.boardNumber}/card/${card.cardNumber}?activeTab=${this.queryBack.activeTab}`,
            {
              pushState: true,
              reloadAll: true
            }
          );
          break;
      }

      this.$f7.preloader.hide();
    }
  },

  watch: {
    isAutoSave: {
      handler(val) {
        if (val && this.isEdited) {
          this.cancelAutoSaveTimeout();
          this.autoSaveTimeoutId = setTimeout(() => {
            this.onSave(null, false); // isResetProposal = false : do not reset proposal after auto save
            this.clearTempProposal();
          }, 5 * 60 * 1000); //After 5 minutes of editing, perform auto save
        }
      }
    },

    proposal: {
      handler(val, old) {
        const newSections = val ? val.sections || [] : [];
        const oldSections = old ? old.sections || [] : [];
        if (val && ((_.differenceWith(newSections, oldSections, _.isEqual).length || this.isEdited) || newSections.length === 0)) {
          this.sortedSections = _.cloneDeep(newSections).sort(
            (a, b) => a.index - b.index
          );
        }
        if (!this.isEdited && !_.isEmpty(this.tempProposal)) {
          this.clearTempProposal();
        }
        if (!_.isEmpty(val) && this.isEdited) {
          this.saveTempProposal(val);
        }
        if (!_.isEmpty(old)) {
          this.oldProposalId = old.id;
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  height: calc(100vh - 110px);
  overflow-x: auto;
  // margin: 0px 10px 0px 10px;
  direction: ltr;
  scroll-behavior: smooth;
}

.editor-section {
  height: calc(100vh - 110px);
  direction: ltr;
}
.column-content-container {
  height: calc(100vh);
  overflow-y: auto;
}
.ghost {
  opacity: 0.5;
  background: #f7fafc;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.editor-section ::v-deep .signature-warning {
  font-style: italic;
  padding-top: 20px;
  color: #808080;
  font-size: 14px;
}
.tail-section {
  height: calc(100vh * 0.65);
}
.pointer {
  cursor: pointer;
}
.visible-hidden {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -9999px;
  z-index: -999;
  scale: 0;
}
</style>
