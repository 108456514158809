import * as types from "./types";
import Vue from "vue";

export default {
  setTimeLogList(state, payload) {
    Vue.set(state, "timeLogList", payload);
  },
  setTimeLogSubscribe(state, payload) {
    Vue.set(state, "timeLogSubscribe", payload);
  },
  setPurchaseOrderList(state, payload) {
    Vue.set(state, "purchaseOrderList", payload);
  },
  setPurchaseOrderSubscribe(state, payload) {
    Vue.set(state, "purchaseOrderSubscribe", payload);
  },
  setExpenseList(state, payload) {
    Vue.set(state, "expenseList", payload);
  },
  setExpenseSubscribe(state, payload) {
    Vue.set(state, "expenseSubscribe", payload);
  },
  setEstimateList(state, payload) {
    Vue.set(state, "estimateList", payload);
  },
  setEstimateSubscribe(state, payload) {
    Vue.set(state, "estimateSubscribe", payload);
  },
  setInvoiceList(state, payload) {
    Vue.set(state, "invoiceList", payload);
  },
  setInvoiceSubscribe(state, payload) {
    Vue.set(state, "invoiceSubscribe", payload);
  },
  [types.SET_BUILDINGS](state, response) {
    Vue.set(state, "buildings", response);
  },
  [types.SET_INVOICE_LIST](state, response) {
    Vue.set(state, "invoiceList", response);
  },
  [types.SET_INVOICE_GROUP_LIST](state, response) {
    Vue.set(state, "invoiceGroupList", response);
  },
  [types.SET_RECURRING_INVOICE_LIST](state, response) {
    Vue.set(state, "recurringInvoiceList", response);
  }
};
