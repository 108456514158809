import * as types from "./types";
import Vue from "vue";

export default {
  setExpenseList(state, payload) {
    Vue.set(state, "expenseList", payload);
  },
  setExpenseSubscribe(state, payload) {
    Vue.set(state, "expenseSubscribe", payload);
  },  
  [types.SET_EXPENSE_ID](state, id) {
    state.expenseId = id;
  },

  [types.CHANGE_EXPENSE_ITEM](state, { id, item }) {
    const list = state.expenseList.map(r => (r.id === id ? item : r));
    Vue.set(state, "expenseList", list);
  }
};
