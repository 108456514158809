export default {
  purchaseOrderList: [],
  purchaseOrderSubscribe: null,
  purchaseOrder: {},
  purchaseOrderPages: [],
  purchaseOrderCurrentPageNumber: 0,

  response: [],

  order: "desc", // Allow search desc only
  searchText: "",

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  selectedStatusFilter: []
};
