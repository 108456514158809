import { getFullAddress } from "@/utility/address";
import { applyFilter, groupBy } from "@/utility/filter-tools";
export default {
  companyList: ({ companyList }) =>
    companyList
      .filter(r => !r.companyTypes || !r.companyTypes.includes("vendor"))
      .map(r => {
        const { phones, addresses } = r;
        return {
          id: r.id,
          contactId:
            r.contactRefs && r.contactRefs.length > 0
              ? r.contactRefs[0].id
              : "",
          companyName: r.companyName,
          phoneNumber: ((phones || [])[0] || {}).value,
          address: getFullAddress((addresses || [])[0]),
          email: (r.others || []).find(r => r.code == "email")
            ? (r.others || []).find(r => r.code == "email").value
            : ""
        };
      }),
  objectGroup: (_, getters) => (searchValue = "") => {
    const filteredObjects = applyFilter(getters.companyList, searchValue, [
      "companyName"
    ]);
    let dataGroup = groupBy(filteredObjects, item =>
      ((item.companyName || "").charAt(0) || "").toUpperCase()
    ).sort((a, b) => sortBy(a.key, b.key));
    return dataGroup.map(i => ({
      ...i,
      data: i.data.sort((a, b) => sortBy(a.companyName, b.companyName))
    }));
  },
  companyById: ({ companyList }) => id => {
    return companyList.find(r => r.id === id);
  }
};
function sortBy(a, b) {
  return a > b ? 1 : b > a ? -1 : 0;
}
