import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_LAST_ITEM_REF](state, payload) {
    Vue.set(state, "notificationsLastRef", payload.ref);
  },

  [types.MERGE_DATA_BATCH](state, payload) {
    Vue.set(
      state,
      "notificationsList",
      state.notificationsList.concat(payload.data)
    );
  },

  [types.ALL_DATA_LOADED](state) {
    Vue.set(state, "notificationsAllLoaded", true);
  },
  [types.UPDATE_NOTIFICATION_LIST](state, payload) {
    if (payload) {
      const updatedNotifications = state.notificationsList.map(notification =>
        notification.id === payload
          ? { ...notification, isRead: true }
          : notification
      );
      Vue.set(state, "notificationsList", updatedNotifications);
    } else {
      const allReadNotifications = state.notificationsList.map(
        notification => ({
          ...notification,
          isRead: true
        })
      );
      Vue.set(state, "notificationsList", allReadNotifications);
    }
  },
  [types.NOTIFICATION_LIST](state, payload) {
    Vue.set(state, "notifyListByUser", payload);
  },
  [types.RESET_PAGE](state) {
    Vue.set(state, "notificationsList", []);
    Vue.set(state, "notificationsLastRef", null);
    Vue.set(state, "notificationsAllLoaded", false);
  },
  [types.SHOW_GLOBAL_NOTIFY_POPUP](state, payload) {
    Vue.set(state, "showGlobalNotifyPopup", payload);
  },
  [types.SET_PO_LIST](state, payload) {
    Vue.set(state, "poList", payload);
  },
  [types.SET_USER_TOKEN_LIST](state, payload) {
    Vue.set(state, "userTokenList", payload);
  },
  [types.SET_NOTIFY_TEMPLATE_LIST](state, payload) {
    Vue.set(state, "notifyTempList", payload);
  }
};
