/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const propertyActions = new FirebaseActions(
    `/system_client/${tenant}/operation_property`,
    "property"
  );

  return {
    //property
    getProperty: propertyActions.getDocument,
    getPropertyBys: propertyActions.getDocumentBys,
    createProperty: propertyActions.createDocument,
    updateProperty: propertyActions.updateDocument,

    async getPropertyListBys({ commit, dispatch }, payload) {
      const propertyList = await dispatch("getPropertyBys", payload);
      commit(types.SET_PROPERTY_LIST, propertyList || []);
      return propertyList;
    },

    async getPropertyById({ commit, dispatch }, id) {
      const propertyDoc = await dispatch("getProperty", id);
      commit(types.SET_PROPERTY, propertyDoc || {});
      return propertyDoc;
    },

    clearProperty({ commit }) {
      commit(types.SET_PROPERTY, {});
    }
  };
};
