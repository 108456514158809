<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Metal Drawings</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-f7="plus" @click.native="openNewDrawingPopup"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-searchbar
      disable-button-text
      placeholder="Search metal drawing"
      :clear-button="true"
      :value="searchValue"
      @input="
        setSearchValue($event.target.value.trim());
        onSearch();
      "
      @keypress.native.enter="onSearch()"
      @searchbar:disable="
        setSearchValue('');
        onSearch();
      "
      @searchbar:clear="
        setSearchValue('');
        onSearch();
      "
      class="search-list"
    ></f7-searchbar>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list media-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="(item, index) in hits"
            :key="index"
            :title="item.drawingNumber"
            :reload-detail="true"
            swipeout
            :link="`/assembly-drawing/${item.drawingNumber}`"
            :text-color="
              item.drawingNumber === assemblyDrawing.drawingNumber
                ? 'primary'
                : 'default'
            "
          >
            <f7-swipeout-actions right>
              <f7-swipeout-button
                :close="true"
                color="red"
                @click="openConfirmDelete(item.id)"
                >Delete</f7-swipeout-button
              >
            </f7-swipeout-actions>
            <div slot="subtitle">{{ item.drawingName }}</div>
            <div slot="text">{{ roofTypeBy(item.roofType).displayName }}</div>
            <f7-chip
              slot="after"
              :text="assemblyDrawingStatusBy(item.status).displayName"
              :color="assemblyDrawingStatusBy(item.status).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
    </div>
    <add-new-assembly-drawing
      ref="addNewAssemblyDrawingPopup"
    ></add-new-assembly-drawing>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNewAssemblyDrawing from "../components/popups/AddNewAssemblyDrawing.vue";
import _ from "lodash";
import Menu from "../../../components/menu/Menu.vue";

export default {
  components: {
    AddNewAssemblyDrawing,
    Menu
  },

  data: () => {
    return {
      allowInfinite: true,
      hasMoreItems: true,
      hasData: true
    };
  },

  created() {
    this.$f7.preloader.show();
    if (this.searchValue) {
      this.setSearchValue(this.searchValue);
    } else {
      this.setSearchValue("");
    }
    Promise.all([this.onSearch()]).finally(() => {
      this.$f7.preloader.hide();
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      }
    });
  },

  computed: {
    ...mapGetters("common/app-constant", [
      "assemblyDrawingStatusBy",
      "roofTypeBy"
    ]),
    ...mapGetters("common/assembly-drawing", [
      "assemblyDrawing",
      "searchValue",
      "hits",
      "nbPages",
      "page"
    ])
  },

  methods: {
    ...mapActions("common/assembly-drawing", [
      "deleteAssemblyDrawing",
      "searchAssemblyDrawing",
      "setSearchValue",
      "loadMoreAssemblyDrawing",
      "resetSearch"
    ]),
    onSearch() {
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function() {
      const self = this;
      return self
        .searchAssemblyDrawing({})
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;
      this.loadMoreAssemblyDrawing({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
    openNewDrawingPopup() {
      this.$refs.addNewAssemblyDrawingPopup.open();
    },

    openConfirmDelete(drawingId) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: "Delete assembly drawing",
        content: "Are you sure you want to to delete the assembly drawing?",
        textButton: "Delete",
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            await app.deleteAssemblyDrawing(drawingId);
            _sefl.app.dialog.close();

            if (this.assemblyDrawing && this.assemblyDrawing.id !== drawingId) {
              app.$f7router.navigate(
                `/assembly-drawing/${this.assemblyDrawing.drawingNumber}`,
                {
                  reloadCurrent: true
                }
              );
            } else {
              app.$f7router.navigate("/assembly-drawing", {
                reloadCurrent: true
              });
            }
            app.$f7.preloader.hide();
          }
        }
      });
    }
  },
  beforeDestroy() {
    this.resetSearch();
  }
};
</script>

<style scoped>
.font-text {
  font-size: 11px;
}
</style>
