export const SET_FILTER_DATA_RESULT_BY_PL = "SET_FILTER_DATA_RESULT_BY_PL";
export const SET_SEARCH_RESULT_BY_PL = "SET_SEARCH_RESULT_BY_PL";
export const SET_NUMBER_OF_ROWS_BY_PL = "SET_NUMBER_OF_ROWS_BY_PL";
export const SET_SEARCH_ORDER_BY_PL = "SET_SEARCH_ORDER_BY_PL";

export const SET_FILTER_DATA_RESULT_BY_DEFAULT =
  "SET_FILTER_DATA_RESULT_BY_DEFAULT";
export const SET_SEARCH_RESULT_BY_DEFAULT = "SET_SEARCH_RESULT_BY_DEFAULT";
export const SET_NUMBER_OF_ROWS_BY_DEFAULT = "SET_NUMBER_OF_ROWS_BY_DEFAULT";
export const SET_SEARCH_ORDER_BY_DEFAULT = "SET_SEARCH_ORDER_BY_DEFAULT";

export const RESET_SEARCH_BY_PL = "RESET_SEARCH_BY_PL";
export const RESET_SEARCH_BY_DEFAULT = "RESET_SEARCH_BY_DEFAULT";
