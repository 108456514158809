<template>
  <div>
    <f7-list :inset="$device.desktop">
      <f7-row>
        <f7-col width="100" large="50">
          <f7-list v-show="currentBuilding.workType === 'recover' ||
            currentBuilding.workType === 'tear-off'
            ">
            <f7-list-input type="select" label="What is the existing roof thickness? (inches)"
              :value="currentBuilding.roofThickness || ''"
              @change="saveBuildingValue('roofThickness', $event.target.value)" error-message-force validate
              validate-on-blur :error-message="roofThicknessErrorMessage">
              <input-icon slot="media" icon="expand"></input-icon>
              <option value="" hidden>Select roof thickness</option>
              <option v-for="item in roofThicknessList" :key="item.id" :value="item.value">{{ item.displayName }}
              </option>
            </f7-list-input>
          </f7-list>

          <f7-list>
            <f7-list-input type="select" :label="`What type of substrate?${currentBuilding.slopeType == 'steep-slope'
              ? ' (optional)'
              : ''
              }`
              " :value="currentBuilding.substrate || ''" @change="onChangeSubtrate($event.target.value)"
              error-message-force validate validate-on-blur :error-message="substrateErrorMessage">
              <input-icon slot="media" icon="map"></input-icon>
              <option value="" :hidden="!(currentBuilding.slopeType == 'steep-slope')">Select type of substrate</option>
              <option v-for="item in substrateList" :key="item.id" :value="item.value">{{ item.displayName }}</option>
            </f7-list-input>
          </f7-list>
        </f7-col>
        <f7-col width="100" large="50">
          <attachment-input v-if="currentBuilding.workType != WORK_TYPE_NEW" :add-button-title="`${roofType === ROOF_TYPE_TPO
            ? 'Core Sample photo attachment'
            : 'Existing Roof Photos'
            }`
            " :estimateId="currentBuilding.estimateId" :buildingId="currentBuilding.id" attachment-type="attachment"
            :value="currentBuilding.attachmentFiles"
            @input="saveBuildingValue('attachmentFiles', $event)"></attachment-input>
        </f7-col>
      </f7-row>
    </f7-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AttachmentInput from "../inputs/AttachmentInput.vue";
import { required } from "@vuelidate/validators";
import InputIcon from "@/components/icons/InputIcon.vue";
import mixins from "../../utility/mixins";
import buildingMixins from "../../utility/building-mixins";
import { useVuelidate } from '@vuelidate/core'

import {
  ROOF_TYPE_TPO,
  WORK_TYPE_NEW,
  SECTION_TYPE_TPO_INSULATION
} from "../../../../utility/const";

export default {
  components: {
    AttachmentInput,
    InputIcon
  },

  props: { roofType: String },

  mixins: [mixins, buildingMixins],

  data() {
    return { ROOF_TYPE_TPO, WORK_TYPE_NEW };
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations: {
    currentBuilding: {
      roofThickness: {
        required(val) {
          return (
            (val &&
              (this.workType === "recover" || this.workType === "tear-off")) ||
            this.workType === "new"
          );
        }
      },
      substrate: {
        required
      }
    }
  },

  computed: {
    ...mapGetters("common/app-constant", [
      "roofThicknessList",
      "substrateList"
    ]),
    ...mapGetters("estimate/estimate-page/estimate", [
      "currentBuilding",
      "estimate"
    ]),
    roofThicknessErrorMessage() {
      // if (!this.v$.currentBuilding.$error) return "";
      // if (!this.v$.currentBuilding.roofThickness.required)
      //   return "This field is required";
      return "";
    },

    substrateErrorMessage() {
      // if (!this.v$.currentBuilding.$error) return "";
      // if (!this.v$.currentBuilding.substrate.required)
      //   return "This field is required";
      return "";
    },

    workType() {
      return this.currentBuilding.workType || "";
    }
  },

  methods: {
    init() { },
    onChangeSubtrate(value) {
      this.saveBuildingValue("substrate", value);
      if (this.currentBuilding.roofType === ROOF_TYPE_TPO) {
        const needFluteFillItems = [
          "tpo-trapazoidal-panel",
          "tpo-u-panel",
          "tpo-r-panel"
        ];
        if (needFluteFillItems.includes(value)) {
          this.saveBuildingValue("fluteFill", true);
        } else {
          this.saveBuildingValue("fluteFill", false);
        }
        this.updateProducts({
          roofType: ROOF_TYPE_TPO,
          sectionId: SECTION_TYPE_TPO_INSULATION
        });
      }
    },
    validate() {
      this.v$.$touch();
      //disble validate for this step
      return false;
      //return this.v$.$invalid;
    }
  }
};
</script>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}
</style>
