import axiosService from "./axios.service";

const algoliaUpdateItem = ({
  tenantId,
  collection,
  collectionNameCopy,
  id
}) => {
  return axiosService
    .post("/algolia/update-item", {
      tenantId,
      collection,
      collectionNameCopy,
      id
    })
    .then(res => {
      return res.data;
    });
};
const algoliaUpdateItems = ({ tenantId, collection, conditions }) => {
  return axiosService
    .post("/algolia/update-items", { tenantId, collection, conditions })
    .then(res => {
      return res.data;
    });
};
const algoliaUpdateItemsWithDataList = ({ tenantId, collection, dataList }) => {
  return axiosService
    .post("/algolia/update-items-with-list", { tenantId, collection, dataList })
    .then(res => {
      return res.data;
    });
};

export default {
  algoliaUpdateItem,
  algoliaUpdateItems,
  algoliaUpdateItemsWithDataList
};
