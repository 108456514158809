<template>
  <div>
    <f7-popup
      class="demo-popup"
      :opened="popupAddNewProductItem"
      @popup:closed="handlePopupClosed"
      @popup:opened="handlePopupOpened"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Select Product Item</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="addProductItem">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <!-- Products table -->
        <data-table
          :headers="headers"
          :items="productItemComputed"
          :pageSize="productItemComputed.length"
          :checkbox="true"
          @selected:checkbox="products = $event"
        >
          <template slot="card-header">
            <f7-list>
              <f7-searchbar
                placeholder="Search in items"
                :clear-button="true"
                disable-button-text
                @input="searchtext = $event.target.value"
                @searchbar:disable="searchtext = ''"
                ref="searchProductPopup"
              ></f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td>{{ item.productItem }}</td>
            <!-- <td>{{ vendorName(item.vendorId) }}</td> -->
            <td>{{ item.manufacturer }}</td>
            <td>{{ item.category }}</td>
            <td>{{ item.sku }}</td>

            <td>
              <ul>
                <li v-for="(attach, index) in item.technicalData" :key="index">
                  <a class="display-block" @click.stop="openLink(attach.url)">
                    {{ attach.name }}
                  </a>
                </li>
              </ul>
            </td>
            <td>{{ item.color }}</td>
            <td>{{ item.packaging }}</td>
            <td>{{ item.unitPack }}</td>
            <td>{{ item.unitSize }}</td>
            <td>{{ item.uom }}</td>
            <td>{{ item.price | currencyUSD }}</td>
          </template>
        </data-table>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
// import AddProductToPriceListPopup from "./AddProductToPriceListPopup.vue";
import { mapGetters } from "vuex";
import DataTable from "@/components/datatables";
import { applyFilter } from "@/utility/filter-tools";
// import { DEFAULT_STATUS_PRODUCT_ITEM } from "@/utility/const";

export default {
  components: {
    DataTable
  },

  props: {
    // popupAddNewProductItem: Boolean,
    productItemList: Array
    // sectionId: String,
    // roofType: String
  },

  data: () => ({
    popupAddNewProductItem: false,
    products: [],
    headers: [
      {
        text: "Product Item",
        value: "productItem",
        sortable: false,
        align: "left"
      },
      //   {
      //     text: "Vendor",
      //     align: "left",
      //     sortable: false,
      //     value: "vendor"
      //   },
      {
        text: "Manufacturer",
        align: "left",
        sortable: false,
        value: "manufacturer"
      },
      {
        text: "Category",
        value: "category",
        sortable: false,
        align: "left"
      },
      {
        text: "SKU",
        value: "sku",
        sortable: false,
        align: "left"
      },

      {
        text: "Technical Data",
        value: "technicalData",
        sortable: false,
        align: "left"
      },
      {
        text: "Color",
        value: "color",
        sortable: false,
        align: "left"
      },
      {
        text: "Packaging",
        value: "packaging",
        sortable: false,
        align: "left"
      },
      {
        text: "Unit Packs",
        value: "unitPack",
        sortable: false,
        align: "left"
      },
      {
        text: "Unit Size",
        value: "unitSize",
        sortable: false,
        align: "left"
      },
      {
        text: "UOM",
        value: "UoM",
        sortable: false,
        align: "left"
      },
      {
        text: "Price",
        value: "price",
        sortable: false,
        align: "left"
      }
    ],

    searchtext: ""
  }),

  computed: {
    ...mapGetters({
      subCategoryObjects: "proposal/estimate/sub-category/objectList",
      vendors: "proposal/estimate/vendor/objectList"
    }),

    ...mapGetters("proposal/app-constant", ["roofTypeBy"]),

    // ...mapGetters({
    //   categories: "proposal/estimate/category/objectList"
    // }),

    // section() {
    //   return (
    //     this.roofTypeBy(this.roofType).estimateTemplate.find(
    //       item => item.sectionId === this.sectionId
    //     ) || {}
    //   );
    // },

    // category() {
    //   return this.categories.find(c => c.name === this.section.category) || {};
    // },

    productItemComputed() {
      let list = this.productItemList;

      //Filter by statu active
      //   list = list.filter(
      //     x =>
      //       x.status === DEFAULT_STATUS_PRODUCT_ITEM &&
      //       x.categoryId === this.category.id
      //   );

      // Filter vendor
      // if (this.vendorIds) {
      //   list = list.filter(x => this.vendorIds.includes(x.vendorId));
      // }

      // Filter manufacturer
      // if (this.manufacturers) {
      //   list = list.filter(x => this.manufacturers.includes(x.manufacturer));
      // }

      return applyFilter([...list], this.searchtext, [
        "productItem",
        "sku",
        i => i.manufacturer,
        "category",
        // i => this.vendorName(i.vendorId),
        // i => this.subCategoryName(i.subCategoryId),
        "uom",
        i => this.technicalDataNames(i.technicalData)
      ]);
    }
  },

  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },

    openPopup() {
      this.popupAddNewProductItem = true;
    },

    handlePopupClosed() {
      this.popupAddNewProductItem = false;
    },

    addProductItem() {
      if (this.products.length === 0) {
        this.showNotification(
          "Add new product item fail",
          "Please select a product item to add"
        );
      } else {
        this.$emit("onAddProductItems", this.products);
        this.popupAddNewProductItem = false;
      }
    },

    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: "RooferIntel",
          titleRightText: "now",
          subtitle,
          text,
          closeTimeout: 3000
        });
      }
      self.notificationFull.open();
    },

    vendorName(id) {
      const vendor = this.vendors.find(r => r.id === id);
      return (vendor || {}).companyName;
    },

    subCategoryName(subCategoryId) {
      const subCategory = this.subCategoryObjects.find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },

    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(", ");
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$refs.searchProductPopup.$el
          .querySelector(".searchbar input")
          .focus();
      }
    }
  }
};
</script>

<style scoped></style>
