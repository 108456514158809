<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="handlePopupOpened"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Project</f7-nav-title>
        <f7-nav-right v-if="!isHiddenAddNew">
          <f7-link icon-f7="plus" @click.native="onAddNew"></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search project"
        :clear-button="true"
        :value="searchText"
        @input="
          setProjectSearchText($event.target.value);
          onSearch();
        "
        @searchbar:disable="onSearch()"
        @searchbar:clear="onSearch()"
        class="search-list-popup"
      ></f7-searchbar>

      <div class="page-content" @scroll="onScroll">
        <f7-list class="search-list searchbar-found">
          <f7-list-item
            no-chevron
            link
            v-for="(project, index) in projectList"
            :key="index"
            :title="`${project.projectName || ''}`"
            @click.native="select(project.id)"
          >
            <div slot="after">{{ project.projectNumber }}</div>
          </f7-list-item>
        </f7-list>
        <div class="text-align-center margin-vertical-half">
          <f7-preloader v-show="hasMoreItems"></f7-preloader>
        </div>
        <f7-block class="text-align-center" v-show="!hasData">No Data</f7-block>
      </div>
    </div>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  props: {
    queryFilters: String,
    isHiddenAddNew: { type: Boolean, default: true }
  },

  data: () => {
    return {
      popupOpened: false,

      allowInfinite: true,
      hasMoreItems: true,
      hasData: true
    };
  },

  computed: {
    ...mapGetters("common/project", [
      "projectList",
      "hits",
      "nbPages",
      "page",
      "searchText"
    ])
  },

  methods: {
    ...mapActions("common/project", [
      "searchProject",
      "loadMoreProject",
      "setProjectSearchText",
      "resetSearch",
      "setQueryFilters"
    ]),

    /**
     * open
     */
    async open() {
      this.$f7.preloader.show();
      this.setQueryFilters(this.queryFilters);
      this.onSearch();
      this.popupOpened = true;
      this.$f7.preloader.hide();
    },

    onSearch(value) {
      this.searchValue = value;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      this.handleSearch();
    },

    handleSearch: _.debounce(function() {
      const self = this;
      return self.searchProject({}).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
      });
    }, 500),

    // Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;

      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreProject({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    /**
     * select
     */
    select(projectId) {
      this.$emit("onSelected", projectId);
      this.popupOpened = false;
    },

    onAddNew() {
      this.$emit("onAddNew");
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector(".search-list-popup.searchbar input").focus();
      }
    },

    handlePopupClosed() {
      this.popupOpened = false;
      this.allowInfinite = true;
      this.hasMoreItems = true;
      this.hasData = true;
      this.setProjectSearchText("");
      this.resetSearch();
      this.setQueryFilters("");
    }
  }
};
</script>
