<template>
  <div>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000) // yesterday
          }
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon slot="media" icon="timer"></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list>
      <f7-list-item title="Put a Lien">
        <f7-radio
          :value="PUT_A_LIEN"
          :checked="paymentStatus === PUT_A_LIEN"
          name="manufactures"
          slot="media"
          @change="paymentStatus = PUT_A_LIEN"
        ></f7-radio>
      </f7-list-item>
      <f7-list-item title="Received Payment">
        <f7-radio
          :value="RECEIVED_PAYMENT"
          :checked="paymentStatus === RECEIVED_PAYMENT"
          name="manufactures"
          slot="media"
          @change="paymentStatus = RECEIVED_PAYMENT"
        ></f7-radio>
      </f7-list-item>
      <!--  @change="$event.target.value" -->
    </f7-list>
    <div v-show="paymentStatus === RECEIVED_PAYMENT">
      <invoice-section
        ref="invoiceSection"
        @closePopup="closeActionPopup"
      ></invoice-section>
    </div>

    <attachment-input
      :project-id="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :is-save-button="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Lien & Close Project Note',
          note,
          callback: () => {
            note = '';
          }
        })
      "
    ></note-editor>

    <note-popup
      ref="archiveNotePopup"
      title="Archive Note"
      label="Note"
      v-model="archiveNote"
      @done="onDone"
      validate
    ></note-popup>
  </div>
</template>

<script>
import UserInput from "../input/UserInput.vue";
import AttachmentInput from "../input/AttachmentInput.vue";
import InputIcon from "../icon/InputIcon.vue";
import NotePopup from "../popup/NotePopup.vue";
import InvoiceSection from "../invoice/InvoiceSection.vue";

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString
} from "../../../../utility/datetime";

import { firebase, auth } from "../../../../services/firebase.service";
import { mapState, mapActions } from "vuex";

import _ from "lodash";
import methodsMixins from "../../mixin/methods";
import moment from "moment";
import {
  PAYMENT_STATUS_PUT_A_LIEN,
  PAYMENT_STATUS_RECEIVED_PAYMENT
} from "../../../../utility/const";
import NoteEditor from "../note/NoteEditor.vue";

export default {
  mixins: [methodsMixins],

  components: {
    UserInput,
    AttachmentInput,
    InputIcon,
    NotePopup,
    InvoiceSection,
    NoteEditor
  },

  data: () => {
    return {
      note: "",
      archiveNote: "",
      currentResponse: {},

      displayCard: {},
      dueDate: [],
      paymentStatus: ""
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      }
    },

    paymentStatus: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.setConditionToDisplayResponseBtn(val);
        }
      }
    }
  },

  computed: {
    ...mapState("dashboard/project", ["card", "action", "response", "users"]),
    RECEIVED_PAYMENT() {
      return PAYMENT_STATUS_RECEIVED_PAYMENT;
    },
    PUT_A_LIEN() {
      return PAYMENT_STATUS_PUT_A_LIEN;
    }
  },
  methods: {
    ...mapActions("dashboard/project", [
      "updateCard",
      "setConditionToDisplayResponseBtn"
    ]),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.paymentStatus = this.displayCard.paymentStatus
          ? this.displayCard.paymentStatus
          : PAYMENT_STATUS_RECEIVED_PAYMENT;

        if (!_.isEmpty(this.$refs.invoiceSection)) {
          this.$refs.invoiceSection.initData();
        }
      }
    },
    onDone(){
      this.resolve(() => {});
      this.$f7router.updateCurrentUrl("/dashboard/swimlane/commercial/sales");
    },
    // Implement do something before resolve action
    doAction(response) {
      const self = this;
      switch (response.code) {
        case "save-and-close":
          this.save();
          this.$emit("doClosePopup");
          break;
        case "payment-received":
          this.currentResponse = response;
          // this.resolve();
          this.$refs.archiveNotePopup.open();
          break;

        case "put-a-lien":
          this.currentResponse = response;
          this.$ri.dialog.openSuccessDialog({
            title: "Lien & Close Project",
            content:
              "We are going to move the project to the Archive folder with the status of “Put a Lien”. Are you sure you want to do this?",
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                self.$refs.archiveNotePopup.open();
              }
            }
          });

          break;
      }
    },

    // Implement  resolve action
    async resolve(callback) {
      this.$f7.preloader.show();
      await this.save();
      this.$emit("doResolve", this.currentResponse);
      this.$emit("doClosePopup", callback);
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: "note",
          title: "Invoice Note",
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

      }

      if (this.archiveNote) {
        commentAndNote.push({
          code: "note",
          title: "Archive Note",
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email
        });

        this.displayCard.archiveDate = firebase.firestore.Timestamp.now();
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.paymentStatus = this.paymentStatus;

      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            "day"
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) }
          });
        }
      }
    },
    closeActionPopup(callback) {
      this.$emit("doClosePopup", callback);
    }
  }
};
</script>

<style lang="scss" scoped></style>
