<template>
  <div class="container-view-information" :style="isThemDart">
    <f7-button
      v-show="!$device.desktop"
      outline
      @click="$emit('openViewProductTablePopup')"
      >View Product Table</f7-button
    >
    <f7-list accordion-list>
      <f7-list-item
        accordion-item
        :reload-detail="true"
        v-for="(step, index) in stepList"
        :key="index"
        :accordion-item-opened="index === 0"
      >
        <div slot="title">
          <span>{{ step ? workStepBy(step.stepId).displayName : "" }}</span>
        </div>

        <f7-accordion-content>
          <template>
            <component
              :ref="stepComponent(step.stepId)"
              :is="stepComponent(step.stepId)"
              :key="index"
              :roofType="currentBuilding.roofType || ''"
              :showContentType="CONTENT_TYPE_EST.INFO"
            />
          </template>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  STEP_INFORMATION,
  STEP_SERVICE_INFORMATION,
  BUSINESS_CODE_SERVICE,
  CONTENT_TYPE_EST
} from "@/utility/const";
import RoofAndSubstrateInfoStep from "../steps/RoofAndSubstrateInfoStep.vue";
import TpoRoofAndSubstrateInfoStep from "../steps/TpoRoofAndSubstrateInfoStep.vue";
import MembraneInfoStep from "../steps/MembraneInfoStep.vue";
import RakeOrEdgeInfoStep from "../steps/RakeOrEdgeInfoStep.vue";
import InsulationInfoStep from "../steps/InsulationInfoStep.vue";
import InformationStep from "../steps/InformationStep.vue";
import ReviewStep from "../steps/ReviewStep.vue";

import MiscAndLaborStep from "../steps/MiscAndLaborStep.vue";
import LeadsVentsStep from "../steps/LeadsVentsStep.vue";
import PipeBootsAccessoriesStep from "../steps/PipeBootsAccessoriesStep.vue";

import ShingleRoofComponentsStep from "../steps/ShingleRoofComponentsStep.vue";
import StandingSeamRoofComponentsStep from "../steps/StandingSeamRoofComponentsStep.vue";

import TpoMetalStep from "../steps/TpoMetalStep.vue";
import ShingleMetalStep from "../steps/ShingleMetalStep.vue";
import ShingleReviewStep from "../steps/ShingleReviewStep.vue";
import StandingSeamReviewStep from "../steps/StandingSeamReviewStep.vue";
import StandingSeamMetalStep from "../steps/StandingSeamMetalStep.vue";

import ShingleRoofStep from "../steps/ShingleRoofStep.vue";
import StandingSeamRoofStep from "../steps/StandingSeamRoofStep.vue";

import ServiceInformationStep from "../steps/ServiceInformationStep.vue";
import ServiceReviewStep from "../steps/ServiceReviewStep.vue";
import ServiceProductStep from "../steps/ServiceProductStep.vue";

export default {
  components: {
    RoofAndSubstrateInfoStep,
    TpoRoofAndSubstrateInfoStep,
    InformationStep,
    MembraneInfoStep,
    RakeOrEdgeInfoStep,
    InsulationInfoStep,
    MiscAndLaborStep,
    ReviewStep,

    ShingleRoofComponentsStep,
    LeadsVentsStep,
    PipeBootsAccessoriesStep,

    StandingSeamRoofComponentsStep,

    TpoMetalStep,
    ShingleMetalStep,
    ShingleReviewStep,
    StandingSeamReviewStep,
    StandingSeamMetalStep,

    ShingleRoofStep,
    StandingSeamRoofStep,

    ServiceInformationStep,
    ServiceProductStep,
    ServiceReviewStep
  },
  props: {
    workSteps: Array
  },
  data() {
    return {
      BUSINESS_CODE_SERVICE,
      STEP_SERVICE_INFORMATION,
      CONTENT_TYPE_EST,
      STEP_INFORMATION,

      stepList: []
    };
  },
  computed: {
    ...mapGetters("common/app-constant", ["workStepBy"]),
    ...mapGetters("estimate/estimate-page/estimate", ["currentBuilding"]),

    stepComponent() {
      return stepId => {
        return (this.workStepBy(stepId) || {}).componentName || "";
      };
    },
    isThemDart() {
      return localStorage.getItem("themeDark") === "true"
        ? "background-color: var(--f7-color-bg-dark);"
        : "background-color: var(--f7-color-light-gray);";
    }
  },
  watch: {
    workSteps: {
      handler(val) {
        if (
          val.at(0).stepId === STEP_SERVICE_INFORMATION ||
          (val.length === 1 && val.at(0).stepId === STEP_INFORMATION)
        ) {
          this.stepList = [val[0]];
        } else {
          this.stepList = val
            .filter(
              e =>
                e.stepId !== "tpo-metal" && e.stepId !== "standing-seam-metal"
            )
            .slice(0, -1);
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style>
.container-view-information {
  max-width: 480px;
  width: 100%;
  height: calc(100vh - 54px);
  border: none;
  padding: 8px;
  border-radius: 2px;
  overflow-y: auto;
}
.container-view-information::-webkit-scrollbar {
  display: none;
}
</style>
