<template>
  <div class="add-new-button">
    <div class="spacing"></div>
    <f7-button slot="title" @click="open" small
      class="btn-fill-gray display-flex justify-content-center align-items-center">
      <f7-icon size="18" f7="plus" class="margin-right-half"></f7-icon>
      Add new property
    </f7-button>

    <f7-popup :opened="popupOpened" @popup:closed="close">
      <f7-page v-show="isNew">
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click.native="close">Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>New Property</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="create">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-list>
          <!-- Property Name -->
          <f7-list-input class="first-input" type="text" placeholder="Property name" clear-button
            :value="currentProperty.propertyName" @input="set('propertyName', $event.target.value.trim())"
            error-message-force :error-message="propertyNameErrorMessage" @blur="v$.$touch()"></f7-list-input>
        </f7-list>

        <!-- Address -->
        <address-auto-complete-input ref="addressInput" isRequired :value="currentProperty.addresses"
          @input="setAddresses($event)"></address-auto-complete-input>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import Vue from "vue";
import AddressAutoCompleteInput from "@/components/inputs/AddressAutoCompleteInput.vue";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";
import { VALIDATION_MESSAGE } from '@/utility/const';

export default {
  components: {
    AddressAutoCompleteInput
  },

  setup: () => ({ v$: useVuelidate({$scope: false}) }),

  validations() {
    return {
      currentProperty: {
        propertyName: {
          required
        }
      }
    };
  },

  data() {
    return {
      currentProperty: { propertyName: "" },

      popupOpened: false,

      isNew: false
    };
  },

  computed: {
    ...mapGetters("common/contact", ["contactList"]),

    propertyNameErrorMessage() {
      if (!this.v$.currentProperty?.propertyName.$error) return null;
      if (this.v$.currentProperty?.propertyName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    }
  },

  methods: {
    ...mapActions("common/property", ["createProperty", "resetSearch"]),

    set(prop, value) {
      Vue.set(this.currentProperty, prop, value);
    },

    open() {
      this.isNew = true;
      this.onlyAddNew = true;
      this.popupOpened = true;
    },

    close() {
      this.popupOpened = false;
      this.isNew = false;
      this.currentProperty = { propertyName: "" };

      this.v$.$reset();
      this.$refs.addressInput.resetData();
    },

    setAddresses(val) {
      Vue.set(this.currentProperty, "addresses", val);
    },

    create() {
      const self = this;
      this.v$.$touch();
      this.$refs.addressInput.v$.$touch();
      if (this.v$.$invalid || this.$refs.addressInput.v$.$invalid) return;

      this.$f7.preloader.show();
      return this.createProperty(this.currentProperty)
        .then(propertyId => {
          self.$f7.preloader.hide();
          propertyId && self.$emit("onCreated", propertyId);
        })
        .finally(() => {
          self.close();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.item-inner {
  .item-title {
    display: flex !important;
  }

  .add-new-button {
    display: flex;

    .spacing {
      width: 30px;
    }
  }
}

.autocomplete-list-item {
  &>a {
    color: white !important;
  }
}
</style>
