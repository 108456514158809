<template>
  <div>
    <div class="display-flex">
      <div style="flex: 1">
        <div class="margin-right-half">
          <f7-text-editor
            ref="textEditorResizable"
            class="no-margin"
            resizable
            :value="data.value"
            @texteditor:change="changeValue"
            :buttons="buttons"
          ></f7-text-editor>
        </div>
      </div>
      <div style="flex:1">
        <div class="margin-left-half photo-container" @click="clickChangeImage">
          <img v-if="data.imageUrl" class="image" :src="data.imageUrl" />
          <div v-else class="bg-no-data">
            <f7-icon
              f7="photo"
              class="absolute-center icon"
              color="white"
              style="font-size: 160px"
            ></f7-icon>
          </div>
          <div class="absolute-center text">
            Click to change
          </div>
        </div>
      </div>
    </div>
    <select-cover-page-photo-popup ref="selectCoverPagePhoto" />
  </div>
</template>

<script>
import SelectCoverPagePhotoPopup from "../popup/SelectCoverPagePhotoPopup.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
const buttons = [
  ["bold", "italic", "underline", "textColor"],
  ["orderedList", "unorderedList"],
  ["paragraph", "h1", "h2", "h3"],
  ["alignLeft", "alignCenter", "alignRight", "alignJustify"],
  ["subscript", "superscript"],
  ["indent", "outdent"]
];

export default {
  components: {
    SelectCoverPagePhotoPopup
  },
  props: {
    data: Object
  },
  data() {
    return {
      buttons,
      currentCoverPagePhoto: {
        id: "",
        url: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      proposal: "proposal/proposal/proposal"
    })
  },
  methods: {
    ...mapActions({
      copyPhoto: "proposal/photo/copyPhoto"
    }),
    changeValue($event) {
      if (this.data.value === $event) return;
      var contanerElement = this.$refs.textEditorResizable.$el.getElementsByClassName(
        "text-editor-content"
      );
      var overFlowContent = this.isOverflown(contanerElement[0]);
      if (!overFlowContent) {
        this.$emit("change", { ...this.data, value: $event });
      } else {
        this.$refs.textEditorResizable.f7TextEditor.setValue(this.data.value);
        this.$emit("change", { ...this.data, isOverflow: true });
      }
    },
    isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
      return scrollHeight > clientHeight || scrollWidth > clientWidth;
    },
    async clickChangeImage() {
      let photo = await this.$refs.selectCoverPagePhoto.openPopup();
      if (_.isEmpty(photo)) return;
      this.$f7.preloader.show();
      if (
        this.currentCoverPagePhoto.url != photo.url &&
        this.currentCoverPagePhoto.id != photo.id
      ) {
        this.currentCoverPagePhoto = {
          id: photo.id || "",
          url: photo.url || ""
        };
        // if photo is from project then clone photo
        if (photo.id) {
          const newPhoto = await this.copyPhoto({
            proposalId: this.proposal.id,
            photo
          });
          photo = {
            url: newPhoto.url,
            photoName: photo.photoName,
            fullPath: newPhoto.fullPath
          };
        }
      }

      this.$emit("change", { ...this.data, imageUrl: photo.url });
      this.$f7.preloader.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.photo-container {
  position: relative;
  height: 100%;
  border: dotted 1px rgb(78, 77, 77);
  background: #eee;
  border-radius: 5px;
  color: rgb(104, 101, 101);
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    opacity: 0;
    transition: 0.5s ease;
    font-weight: bold;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    transition: 0.5s ease;
  }

  .icon {
    transition: 0.5s ease;
  }

  &:hover {
    .icon,
    .image {
      opacity: 0.2;
    }
    .text {
      opacity: 1;
      font-size: 18px;
      color: black;
    }
  }
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
