import {
  FirebaseActions,
  firestore,
  firebase
} from "../../../../services/firebase.service";

export default tenant => {
  const invoiceActions = new FirebaseActions(
    `/system_client/${tenant}/invoice`,
    "invoice"
  );

  return {
    bindInvoiceList: invoiceActions.bindCollection,
    bindInvoiceListBys: invoiceActions.bindCollectionBys,
    unbindInvoiceList: invoiceActions.unbindCollection,
    getDocumentbys: invoiceActions.getDocumentBys,

    bindInvoice: invoiceActions.bindDocument,
    unbindInvoice: invoiceActions.unbindDocument,

    createInvoice: invoiceActions.createDocument,
    updateInvoice: invoiceActions.updateDocument,

    getInvoiceById: invoiceActions.getDocument,

    getInvoiceByTenant(context, { id, tenant }) {
      let collectionPath = `/system_client/${tenant}/invoice`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    },
    updateInvoiceViewed(context, { id, tenant }) {
      let doc = {};
      doc.status = "in-viewed";
      doc.viewedAt = firebase.firestore.FieldValue.serverTimestamp();
      let collectionPath = `/system_client/${tenant}/invoice`;
      return firestore
        .collection(collectionPath)
        .doc(id)
        .update(doc);
    }
  };
};
