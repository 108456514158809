import Vue from "vue";
import * as types from "./types";

export default {
  setLetterList(state, payload) {
    Vue.set(state, "letterList", payload);
  },
  setLetterSubscribe(state, payload) {
    Vue.set(state, "letterSubscribe", payload);
  },
  [types.SET_LETTER_LIST](state, payload) {
    Vue.set(state, "letterList", payload);
  }
};
