<template>
  <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="cancelPopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Set Album Template</f7-nav-title>
        <f7-nav-right />
      </f7-navbar>
      <f7-row no-gap>
        <f7-col :width="$device.desktop ? 40 : 100">
          <f7-card v-if="$device.desktop">
            <f7-card-content>
              <f7-list accordion-list>
                <f7-list-item accordion-item accordion-item-opened>
                  <template #title>
                    <div class="title">
                      <f7-icon f7="photo_on_rectangle" color="primary"></f7-icon>
                      <div class="info-label">
                        Setting album for
                      </div>
                    </div>
                  </template>
                  <f7-accordion-content>
                    <f7-list class="margin-left-half">
                      <f7-block class="scroll-container">
                        <f7-list-item v-for="(item, index) in BUSINESS_CODES" :key="index" :title="item.displayName"
                          link :text-color="item.value === currentBusinessCode
                            ? 'primary'
                            : 'default'
                            " @click="onChangeMenu(item.value)"></f7-list-item>
                      </f7-block>
                    </f7-list>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
            </f7-card-content>
          </f7-card>
          <f7-block class="no-margin-bottom" v-else>
            <f7-segmented strong tag="p">
              <f7-button v-for="(item, index) in BUSINESS_CODES" :key="index" class="custom-hover"
                :active="currentBusinessCode === item.value" @click.native="onChangeMenu(item.value)">{{
                  item.displayName
                }}</f7-button>
            </f7-segmented>
          </f7-block>
        </f7-col>
        <f7-col :width="$device.desktop ? 60 : 100" class="display-flex flex-direction-column"
          :style="$device.desktop ? '' : 'margin-bottom: 60px;'">
          <f7-block-title>Album Name</f7-block-title>
          <f7-list inset class="no-margin-vertical">
            <f7-list-item class="album-item" v-for="(album, index) in currentAlbums" :key="index">
              <template v-if="album.templateType === TEMPLATE_TYPE.SYSTEM">
                <div slot="title">{{ album.name }}</div>
                <i slot="after" style="font-size: 11px;">(This is the system's default album)</i>
              </template>
              <template v-else>
                <f7-input slot="title" type="text" placeholder="Enter name album" :value="album.name"
                  @input="onInputAlbum(index, $event.target.value.trim())" @blur="v$.currentAlbums.$touch()"></f7-input>
                <div slot="after" class="delete-image cursor-pointer" @click="onDeleteAlbum(index)">
                  <f7-icon color="red" f7="trash"></f7-icon>
                </div>
                <div slot="footer" style="color: red;">
                  {{ albumErrorMessage(index) }}
                </div>
              </template>
              <div slot="media">{{ index + 1 }}.</div>
            </f7-list-item>
            <f7-list-item>
              <f7-link slot="title" @click="addAlbum" class="cursor-pointer">Add new album</f7-link>
              <f7-icon slot="media" f7="plus" size="18" color="primary"></f7-icon>
            </f7-list-item>
          </f7-list>
          <f7-block-title class="display-flex justify-content-space-between">
            <span>Album Linking Setup<required-asterisk /></span>
            <span style="color: red; font-size: 12px;">
              {{ albumLikingErrorMessage }}
            </span>
          </f7-block-title>
          <f7-block class="album-linking">
            <f7-row v-for="(item, index) in albumLinkingList" :key="index">
              <f7-col :width="$device.desktop ? 40 : 50">
                {{ item.subTitle }} <b>{{ item.title }}</b>
              </f7-col>
              <f7-col :width="$device.desktop ? 60 : 50">
                <div class="input input-outline input-dropdown" style="padding: 10px 0">
                  <select v-model="item.albumName" @change="onChangeAlbumLinking(index, $event.target.value)">
                    <option value="" hidden>Select album</option>
                    <option v-for="(album, index) in currentAlbumOptions" :key="index" :value="album.name">{{ album.name
                      }}
                    </option>
                  </select>
                </div>
              </f7-col>
            </f7-row>
          </f7-block>
          <f7-block :class="`display-flex no-margin-vertical ${$device.desktop
            ? 'justify-content-flex-end'
            : 'justify-content-center'
            }`
            ">
            <f7-button v-if="isShowBtnApply" @click="onSave" fill>
              Apply for Future Projects
            </f7-button>
          </f7-block>
        </f7-col>
      </f7-row>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  BUSINESS_CODES,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_RESIDENTIAL,
  COMMERCIAL_BOARDS,
  LINK_ALBUM_FROM_ACTION_BOARD,
  LINK_ALBUM_FROM_CREATE_PROPOSAL,
  RESIDENTIAL_BOARDS,
  VALIDATION_MESSAGE,
  TEMPLATE_TYPE
} from "@/utility/const";
import { useVuelidate } from '@vuelidate/core'
import { required ,helpers} from "@vuelidate/validators";
import _ from "lodash";
import Vue from "vue";

export default {
  data() {
    return {
      BUSINESS_CODES,
      popupOpened: false,
      currentBusinessCode: BUSINESS_CODE_COMMERCIAL,
      currentAlbums: [],
      albumLinkingList: [],
      isShowUpdateMessage: false,
      isChangedMenu: false,
      TEMPLATE_TYPE
    };
  },
  methods: {
    ...mapActions("common/album-template", [
      "getAlbumTemplateListBys",
      "deleteAlbumTemplate",
      "createAlbumTemplate"
    ]),
    ...mapActions("common/project", ["getActionListBys"]),

    openPopup() {
      this.initData().then(() => {
        this.popupOpened = true;
      });
    },
    cancelPopup() {
      this.popupOpened = false;
      this.currentBusinessCode = BUSINESS_CODE_COMMERCIAL;
      this.currentAlbums = [];
      this.albumLinkingList = [];
      this.isShowUpdateMessage = false;
      this.isChangedMenu = false;
      this.v$.$reset();
    },
    onChangeMenu(value) {
      this.isChangedMenu = true;
      this.v$.$reset();
      this.currentBusinessCode = value;
      this.initData().then(() => {
        this.isChangedMenu = false;
      });
    },
    initData() {
      this.$f7.preloader.show();
      const promises = [];
      promises.push(
        this.getAlbumTemplateListBys([
          {
            prop: "businessCode",
            val: this.currentBusinessCode,
            op: "=="
          }
        ])
      );
      if (
        [BUSINESS_CODE_COMMERCIAL, BUSINESS_CODE_RESIDENTIAL].includes(
          this.currentBusinessCode
        )
      ) {
        promises.push(
          this.getActionListBys([
            {
              prop: "boards",
              val:
                this.currentBusinessCode === BUSINESS_CODE_COMMERCIAL
                  ? COMMERCIAL_BOARDS
                  : RESIDENTIAL_BOARDS,
              op: "array-contains-any"
            },
            { prop: "isLinkedToAlbum", val: true, op: "==" },
            {
              prop: "isDeleted",
              val: false,
              op: "=="
            }
          ])
        );
      }
      return Promise.all(promises)
        .then(() => {
          this.currentAlbums = _.cloneDeep(this.albumTemplateList).sort(
            (a, b) => a.priority - b.priority
          );
          if (
            [BUSINESS_CODE_COMMERCIAL, BUSINESS_CODE_RESIDENTIAL].includes(
              this.currentBusinessCode
            )
          ) {
            this.albumLinkingList = _.cloneDeep(this.actionList).map(r => ({
              ...r,
              subTitle: "Column",
              from: LINK_ALBUM_FROM_ACTION_BOARD,
              albumName:
                this.currentAlbumOptions.find(
                  album =>
                    album.linkedFroms?.includes(LINK_ALBUM_FROM_ACTION_BOARD) &&
                    album.actionIds?.includes(r.id)
                )?.name || ""
            }));
          } else {
            this.albumLinkingList = [];
          }
          if (this.currentBusinessCode !== BUSINESS_CODE_RESIDENTIAL) {
            this.albumLinkingList.push({
              subTitle: "Create",
              title: "Proposal",
              from: LINK_ALBUM_FROM_CREATE_PROPOSAL,
              albumName:
                this.currentAlbumOptions.find(album =>
                  album.linkedFroms?.includes(LINK_ALBUM_FROM_CREATE_PROPOSAL)
                )?.name || ""
            });
          }
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    onInputAlbum(index, value) {
      const oldAlbumName = this.currentAlbums[index].name;
      Vue.set(this.currentAlbums, index, {
        ...this.currentAlbums[index],
        name: value,
        priority: index + 1
      });
      this.v$.currentAlbums.$touch();
      if (this.v$.currentAlbums.$invalid) {
        this.albumLinkingList = this.albumLinkingList.map(r => ({
          ...r,
          albumName:
            (r.albumName && r.albumName === oldAlbumName) ||
              r.albumName === value
              ? ""
              : r.albumName
        }));
      } else {
        this.albumLinkingList = this.albumLinkingList.map(r => ({
          ...r,
          albumName:
            r.albumName && r.albumName === oldAlbumName ? value : r.albumName
        }));
      }
    },
    addAlbum() {
      this.currentAlbums.push({
        name: "",
        priority: this.currentAlbums.length + 1
      });
    },
    onDeleteAlbum(index) {
      const oldAlbumName = this.currentAlbums[index].name;
      this.currentAlbums.splice(index, 1);
      this.albumLinkingList = this.albumLinkingList.map(r => ({
        ...r,
        albumName:
          r.albumName && r.albumName === oldAlbumName ? "" : r.albumName
      }));
    },
    onChangeAlbumLinking(index, value) {
      const albumLinking = this.albumLinkingList[index];
      const oldAlbumLinkedIndex = this.currentAlbums.findIndex(r =>
        r.linkedFroms?.includes(LINK_ALBUM_FROM_ACTION_BOARD)
          ? r.actionIds?.includes(albumLinking.id)
          : r.linkedFroms?.includes(albumLinking.from)
      );
      if (oldAlbumLinkedIndex !== -1) {
        const oldAlbumLinked = this.currentAlbums[oldAlbumLinkedIndex];
        Vue.set(this.currentAlbums, oldAlbumLinkedIndex, {
          ...oldAlbumLinked,
          linkedFroms:
            oldAlbumLinked.linkedFroms?.length > 1 &&
              (albumLinking.from !== LINK_ALBUM_FROM_ACTION_BOARD ||
                oldAlbumLinked.actionIds?.length === 1)
              ? _.without(oldAlbumLinked.linkedFroms, albumLinking.from)
              : oldAlbumLinked.actionIds?.length > 1
                ? oldAlbumLinked.linkedFroms
                : undefined,
          actionIds:
            albumLinking.from === LINK_ALBUM_FROM_ACTION_BOARD &&
              oldAlbumLinked.actionIds?.length > 1
              ? _.without(oldAlbumLinked.actionIds, albumLinking.id)
              : undefined
        });
      }

      Vue.set(albumLinking, "albumName", value);

      const albumIndex = this.currentAlbums.findIndex(r => r.name === value);
      Vue.set(this.currentAlbums, albumIndex, {
        ...this.currentAlbums[albumIndex],
        linkedFroms: _.uniq([
          ...(this.currentAlbums[albumIndex].linkedFroms || []),
          albumLinking.from
        ]),
        actionIds:
          albumLinking.from === LINK_ALBUM_FROM_ACTION_BOARD
            ? _.uniq([
              ...(this.currentAlbums[albumIndex].actionIds || []),
              albumLinking.id
            ])
            : undefined
      });
    },
    onSave() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.$f7.preloader.show();
      // map priority
      this.currentAlbums = this.currentAlbums.map((r, index) => ({
        ...r,
        priority: index + 1
      }));
      const albumNamesAfter = _.cloneDeep(this.currentAlbums).map(r => ({
        id: r.id,
        name: r.name,
        priority: r.priority,
        linkedFroms: r.linkedFroms,
        actionIds: r.actionIds
      }));
      const albumNamesBefore = _.cloneDeep(this.albumTemplateList).map(r => ({
        id: r.id,
        name: r.name,
        priority: r.priority,
        linkedFroms: r.linkedFroms,
        actionIds: r.actionIds
      }));
      const newAlbums = _.differenceWith(
        albumNamesAfter,
        albumNamesBefore,
        _.isEqual
      );
      const removeAlbums = _.differenceWith(
        albumNamesBefore,
        albumNamesAfter,
        _.isEqual
      );
      const promises = [];
      for (const album of removeAlbums) {
        promises.push(this.deleteAlbumTemplate(album.id));
      }
      for (const album of newAlbums) {
        const doc = {
          businessCode: this.currentBusinessCode,
          name: album.name,
          priority: album.priority
        };
        !_.isEmpty(album.linkedFroms) && (doc.linkedFroms = album.linkedFroms);
        !_.isEmpty(album.actionIds) && (doc.actionIds = album.actionIds);

        promises.push(this.createAlbumTemplate(doc));
      }
      Promise.all(promises)
        .then(() => {
          this.initData();
          this.$f7.toast
            .create({
              text: "Apply successfully!",
              closeOnClick: true,
              closeButton: false,
              closeTimeout: 3000
            })
            .open();
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    }
  },
  computed: {
    ...mapGetters("common/album-template", ["albumTemplateList"]),
    ...mapGetters("common/project", ["actionList"]),

    albumErrorMessage() {
      return index => {
        if (this.v$.currentAlbums.$each.$response.$errors[index].name.length === 0) return null;
        return this.v$.currentAlbums.$each.$response.$errors[index].name[0].$message || ''
      };
    },

    albumLikingErrorMessage() {
      if (!this.v$.albumLinkingList.$error) return null;
      return this.$device.desktop
        ? "Please select album before applying"
        : "Please select album";
    },

    currentAlbumOptions() {
      const errorNames = [];
      this.currentAlbums.forEach((r, index) => {
        if (this.v$.currentAlbums.$each.$response.$errors[index].name[0]?.$message ==="Album name already exists.") {
          errorNames.push(r.name);
        }
      });
      return this.currentAlbums.filter(
        r => r.name && !errorNames.includes(r.name)
      );
    },

    isShowBtnApply() {
      if (this.isChangedMenu) return false;
      const albumNamesAfter = _.cloneDeep(this.currentAlbums).map(r => ({
        name: r.name,
        priority: r.priority,
        linkedFroms: r.linkedFroms,
        actionIds: r.actionIds
      }));
      const albumNamesBefore = _.cloneDeep(this.albumTemplateList).map(r => ({
        name: r.name,
        priority: r.priority,
        linkedFroms: r.linkedFroms,
        actionIds: r.actionIds
      }));
      const newAlbums = _.differenceWith(
        albumNamesAfter,
        albumNamesBefore,
        _.isEqual
      );
      const removeAlbums = _.differenceWith(
        albumNamesBefore,
        albumNamesAfter,
        _.isEqual
      );
      if (newAlbums.length > 0 || removeAlbums.length > 0) return true;
      return false;
    }
  },
  setup: () => ({ v$: useVuelidate({$scope: false}) }),
  validations() {
    const unique = value => {
      const names = this.currentAlbums.map(r => r.name);  
      return names.filter(r => r === value).length <= 1;
    }

    return {
      currentAlbums: {
        $each: helpers.forEach({
          name: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
            unique: helpers.withMessage("Album name already exists.", helpers.withAsync(unique)),
          }
        })
      },
      albumLinkingList: {
        $each: helpers.forEach({
          albumName: {
            required: helpers.withMessage(VALIDATION_MESSAGE.REQUIRED_FIELD, required),
          }
        })
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.info-label {
  font-weight: 600;
  margin-left: 5px;
  margin-top: -2px;
}

.title {
  display: flex;
  row-gap: 15px;
}

.scroll-container {
  max-height: calc(100vh - 270px);
  overflow-x: auto;
  margin: 0px 0px 0px -15px;
  direction: ltr;
  scroll-behavior: smooth;
}

.album-item ::v-deep {
  .item-title {
    width: 100%;
  }

  .item-media {
    justify-content: center;
  }

  .item-after {
    max-width: 80%;
    white-space: break-spaces;
  }
}

.album-linking {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .row {
    height: 100%;
    align-items: center;
  }
}
</style>
