import * as types from "./types";

export default {
  [types.SET_CURRENT_BTN_TYPE](state, currentBtnType) {
    state.currentBtnType = currentBtnType;
  },

  [types.SET_QB_ACCOUNT](state, payload) {
    state.qbAccounts = payload;
  },

  [types.SET_QB_VENDOR](state, payload) {
    state.qbVendors = payload;
  },

  [types.SET_QB_EMPLOYEE](state, payload) {
    state.qbEmployees = payload;
  },

  [types.SET_QB_CUSTOMER](state, payload) {
    state.qbCustomers = payload;
  },

  [types.SET_INTRO_STATE](state, payload) {
    state.isShowIntro = payload;
  }
};
