import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import SpreadsheetWriter from "../../../utility/spreadsheet-writer";
import { currencyUSD } from "../../../utility/config";

const showValueByType = (value, type) => {
  if (!value) return "-";
  if (type === "hourly") {
    return value.approvedHours || "-";
  } else if (type === "laborCost") {
    return value.amounts ? currencyUSD(value.amounts) : "-";
  }
};

export const getExcelWorkbook = ({
  dates,
  projectName,
  headers,
  values,
  view,
  type
}) => {
  var workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Labor Cost Analysis");
  var writer = new SpreadsheetWriter(worksheet);
  writer.bold();
  writer.textCenter();
  writer.write("REPORT: LABOR COST ANALYSIS");
  writer.mergeNext(headers.length - 1);
  writer.enter();
  writer.enter();

  writer.enter();
  writer.write(view === "weekly" ? "Week" : "Month");
  writer.mergeNext();
  writer.right();
  writer.right();

  if (view === "weekly") {
    writer.write(`${dates[0].date} - ${dates[6].date}`);
  } else {
    writer.write(dates[0].dateMoment.format("MMM YYYY"));
  }
  writer.mergeNext();
  writer.right(4);

  writer.write("Project Name");
  writer.mergeNext();
  writer.right();
  writer.right();

  writer.write(projectName);
  writer.mergeNext();
  writer.right(4);
  writer.enter();
  writer.enter();

  //headers
  headers.forEach(x => {
    writer.write(x["text"]);
    writer.border();
    writer.fill("D9D9D9");
    writer.bold();
    writer.right();
  });
  headers.shift();
  values.forEach(row => {
    if (!row.footer) {
      writer.enter();
      writer.write(row.displayName);
      writer.border();
      writer.right();

      writer.write(row.projectName);
      writer.border();
      writer.right();

      dates.forEach(x => {
        writer.write(showValueByType(row[x.id], type));
        writer.border();
        writer.textRight();
        writer.right();
      });

      writer.write(showValueByType(row.total, type));
      writer.border();
      writer.textRight();
      writer.right();
    } else {
      writer.enter();
      writer.write("Sub-total");
      writer.border();
      writer.mergeNext();
      writer.right(2);

      dates.forEach(x => {
        writer.write(showValueByType(row[x.id], type));
        writer.border();
        writer.textRight();
        writer.right();
      });

      writer.write(showValueByType(row.total, type));
      writer.border();
      writer.textRight();
      writer.right();
    }
  });
  return workbook;
};

function exportExcel({ dates, projectName, headers, values, view, type }) {
  const workbook = getExcelWorkbook({
    dates,
    projectName,
    headers,
    values,
    view,
    type
  });
  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "Labor Cost Analysis.xlsx"
    );
  });
}

export default exportExcel;
