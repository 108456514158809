import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_IS_ADMIN_GROUP](state, payload) {
    Vue.set(state, "isAdminGroup", payload);
  },

  [types.SET_GROUP_LIST](state, payload) {
    Vue.set(state, "groupList", payload);
  },
  [types.SET_ADMIN_GROUPS](state, payload) {
    Vue.set(state, "adminGroups", payload);
  }
};
