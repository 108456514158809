<template>
  <f7-page @page:beforeout="handlePageBeforeOut">
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>

      <f7-nav-title>Company</f7-nav-title>

      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click.native="navigateToNewCompanyPage()"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-searchbar
      disable-button-text
      placeholder="Search company"
      :clear-button="true"
      :value="companySearchText"
      @input="
        setCompanySearchText($event.target.value.trim());
        onSearch();
      "
      @searchbar:disable="
        setCompanySearchText('');
        onSearch();
      "
      @searchbar:clear="
        setCompanySearchText('');
        onSearch();
      "
      class="search-list"
    ></f7-searchbar>

    <div class="page-content" @scroll="onScroll">
      <f7-list v-show="companyGroup.length === 0 && !loadingData">
        <f7-list-item title="Company not found!"></f7-list-item>
      </f7-list>

      <!-- company list for companies page -->
      <f7-list class="search-list searchbar-found company-list">
        <f7-list-group v-for="(group, index) in companyGroup" :key="index">
          <f7-list-item :title="group.key" group-title></f7-list-item>
          <f7-list-item
            :link="`/contactbook/company/${item.id}`"
            v-for="item in group.data"
            :key="item.id"
            :title="item.companyName"
            :text-color="item.id === (company || {}).id ? 'primary' : 'default'"
            @click="setCompanyActiveTab('company-detail')"
            :reload-detail="true"
            swipeout
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import Menu from "../../../../components/menu/Menu.vue";

export default {
  components: {
    Menu
  },

  data: () => {
    return {
      loadingData: false,
      numberOfRows: 50
    };
  },

  created() {
    this.$f7.preloader.show();

    this.loadingData = true;
    this.setCompanyNumberOfRows(this.numberOfRows);
    if (this.companySearchText) {
      this.setCompanySearchText(this.companySearchText);
    } else {
      this.setCompanySearchText("");
    }
    this.handleSearch().finally(() => {
      this.loadingData = false;
      this.$f7.preloader.hide();
    });
  },

  computed: {
    ...mapGetters("common/company", [
      "companyGroup",
      "hits",
      "nbPages",
      "page",
      "companySearchText"
    ]),

    ...mapGetters("contact-book/company", ["company"]),

    selector() {
      return this.$f7route.route.meta.selector;
    }
  },

  methods: {
    ...mapActions("common/company", [
      "searchCompany",
      "loadMoreCompany",
      "resetSearch",
      "setCompanyNumberOfRows",
      "setCompanySearchText"
    ]),

    ...mapActions("contact-book/company", ["getCompany", "clearCompany"]),

    ...mapActions("contact-book/contact", ["addCompanyRefs"]),
    onSearch: _.debounce(function() {
      return this.handleSearch();
    }, 300),
    handleSearch() {
      const self = this;

      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchCompany({}).then(() => {
        if (self.page + 1 === self.nbPages) {
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          self.allowInfinite = false;
        }
      });
    },

    //* Can't use @infinite of frameword => use @scroll
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.loadMore();
      }
    },
    handlePageBeforeOut() {
      this.setCompanySearchText("");
    },

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMoreCompany({
        page: this.page + 1
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },

    ...mapActions("contact-book/client", ["setCompanyActiveTab"]),

    navigateToNewCompanyPage() {
      this.$f7router.navigate("/contactbook/company/new-company");
      this.clearCompany();
    }
  },
  mounted() {
    if (this.$device.desktop) {
      if (this.selector) {
        this.$el.querySelector(".search-list.searchbar input").focus();
      } else {
        this.$nextTick(() => {
          this.$el.querySelector(".search-list.searchbar input").focus();
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-content {
  padding-top: 0px;
}
</style>
