import { FirebaseActions } from "../../services/firebase.service";
import {
  TEMPLATE_TYPE_AGREEMENT,
  TEMPLATE_TYPE_CERTIFICATE,
  TEMPLATE_TYPE_CONTRACT,
  TEMPLATE_TYPE_LETTER_AUTHORIZATION,
  TEMPLATE_TYPE_INVOICE
} from "../../utility/const";
import _ from "lodash";

import * as types from "./types";

export default tenant => {
  const templateActions = new FirebaseActions(
    `/system_client/${tenant}/template`,
    "template"
  );
  const emailNotificationActions = new FirebaseActions(
    `/system_client/${tenant}/email_notification`,
    "emailNotification"
  );
  return {
    getTemplateListBys: templateActions.getDocumentBys,
    unbindTemplateList: templateActions.unbindCollection,
    getTemplateBy: templateActions.getDocumentBys,

    createEmailNotification: emailNotificationActions.createDocument,

    async getTemplateList({ state, dispatch, commit }) {
      if (_.isEmpty(state.templateList)) {
        const list = await dispatch("getTemplateListBys", [
          {
            prop: "templateType",
            op: "in",
            val: [
              TEMPLATE_TYPE_AGREEMENT,
              TEMPLATE_TYPE_CERTIFICATE,
              TEMPLATE_TYPE_CONTRACT,
              TEMPLATE_TYPE_LETTER_AUTHORIZATION,
              TEMPLATE_TYPE_INVOICE
            ]
          }
        ]);

        commit(types.SET_TEMPLATE_LIST, list);
      }
    },

    setTemplateList({ commit }, list) {
      commit(types.SET_TEMPLATE_LIST, list);
    }
  };
};
