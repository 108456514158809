import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );

  return {
    getProject: projectActions.getDocument,
    updateProject: projectActions.updateDocument
  };
};
